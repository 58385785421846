import { IDictionaryItemStatus } from "../../../../../common/dictionaries/IUiDictionaryItem";
import InputWrapper from "../../../../../common/input-v2/input-wrapper/InputWrapper";
import styles from './DictionaryItemStatus.module.css';
import IconCheckBox from "../../../../../common/icons/IconCheckBox";
import CombineStyles from "../../../../../../utils/combineStyles";

interface IProps {
  data?: IDictionaryItemStatus;
  isReadOnly?: boolean;
  oneLine?: boolean;
  onUpdate?: (updatedData: IDictionaryItemStatus) => void;
}

export default function DictionaryItemStatus(props: IProps) {
  //---------------------------------------------------------------------------
  const onStaffUpdate = () => {
    if (props.isReadOnly) return;
    if (!props.onUpdate) return;
    const updatedData: IDictionaryItemStatus = props.data ? {
      ...props.data,
      isAvailableToStaffUsers: !props.data.isAvailableToStaffUsers
    } : {
      isAvailableToStaffUsers: true,
      isAvailableToEndUsers: false
    };
    props.onUpdate(updatedData);
  };
  //---------------------------------------------------------------------------
  const onNonStaffUpdate = () => {
    if (props.isReadOnly) return;
    if (!props.onUpdate) return;
    const updatedData: IDictionaryItemStatus = props.data ? {
      ...props.data,
      isAvailableToEndUsers: !props.data.isAvailableToEndUsers
    } : {
      isAvailableToStaffUsers: false,
      isAvailableToEndUsers: true
    };
    props.onUpdate(updatedData);
  };
  //---------------------------------------------------------------------------
  if (props.oneLine) return (
    <span className={styles.oneLine}>
      <span className={props.data?.isAvailableToStaffUsers ? styles.enabled : ''}>
        Editors
      </span>
      &nbsp;/&nbsp;
      <span className={props.data?.isAvailableToEndUsers ? styles.enabled : ''}>
        Readers
      </span>
    </span>
  ); else return (
    <InputWrapper
      caption="Accessibility"
      hint="Editors: users that can edit or translate this or other dictionaries, e.g. administrators, system translators. Readers: users that can use the dictionary."
      hideCaption={false}
      hideHint={false}
    >
      <div className={styles.container}>
        <CheckboxGroupItem
          checked={!!props.data?.isAvailableToStaffUsers}
          caption="Editors"
          isReadOnly={!!props.isReadOnly}
          onSwitch={onStaffUpdate}
        />
        <CheckboxGroupItem
          checked={!!props.data?.isAvailableToEndUsers}
          caption="Readers"
          isReadOnly={!!props.isReadOnly}
          onSwitch={onNonStaffUpdate}
        />
      </div>
    </InputWrapper>
  );
}

interface ICheckboxItemProps {
  checked: boolean;
  caption: string;
  isReadOnly: boolean;
  onSwitch: () => void;
}

function CheckboxGroupItem(props: ICheckboxItemProps) {
  return (
    <div
      className={CombineStyles([
        styles.item,
        props.checked ? styles.selected : '',
        props.isReadOnly ? styles.disabled : ''
      ])}
      onClick={props.onSwitch}>

      {!props.isReadOnly &&
        <IconCheckBox checked={!!props.checked} />}

      <div>
        {props.caption}
      </div>

    </div>
  );
}