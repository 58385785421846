interface IProps {
  onImageSelect?: () => void;
}

export function ImageEditorPlaceholder(props: IProps) {
  return (
    <svg
      style={{ width: "100%", height: "100%", cursor: 'pointer' }}
      onClick={props.onImageSelect}
      //className={styles.statusicon}
      version="1.1"
      viewBox="0 0 1024 1024" // min-x, min-y, width and height // 1024 960
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="scale(1,-1) translate(0,-960)"
        fill="currentColor"
      >
        <path d="M959.884 832c0.040-0.034 0.082-0.076 0.116-0.116v-767.77c-0.034-0.040-0.076-0.082-0.116-0.116h-895.77c-0.040 0.034-0.082 0.076-0.114 0.116v767.772c0.034 0.040 0.076 0.082 0.114 0.114h895.77zM960 896h-896c-35.2 0-64-28.8-64-64v-768c0-35.2 28.8-64 64-64h896c35.2 0 64 28.8 64 64v768c0 35.2-28.8 64-64 64v0zM832 672c0-53.020-42.98-96-96-96s-96 42.98-96 96 42.98 96 96 96 96-42.98 96-96zM896 128h-768v128l224 384 256-320h64l224 192z" />
      </g>
    </svg>
  );
}
