import { IServiceWorkerMessage } from "../context/service-worker-context/ServiceWorkerContext";
import { IApiResponse, apiFetch, getRequestOptions } from "../utils/api";
//----------------------------------------------------------------------------------------------
let tokenPort: MessagePort | undefined;
//----------------------------------------------------------------------------------------------
export function setTokenPort(value: MessagePort | undefined) {
  console.log("setTokenPort.current:", tokenPort);
  console.log("setTokenPort.value:", value);
  if (tokenPort && tokenPort != value) {
    tokenPort.close();
  }
  tokenPort = value;
}
//----------------------------------------------------------------------------------------------
export function postMessageFromSW(message: IServiceWorkerMessage) {
  tokenPort?.postMessage(message);
}
//----------------------------------------------------------------------------------------------
export async function swPostPrivate(
  url: string,
  token?: string,
  data?: any,
  contentType?: string,
  signal?: AbortSignal
) {
  return swFetchPrivate(url, "POST", token, contentType, data, signal);
}
//----------------------------------------------------------------------------------------------
export async function swGetPrivate(
  url: string,
  token?: string,
  data?: any,
  contentType?: string,
  signal?: AbortSignal
) {
  return swFetchPrivate(url, "GET", token, contentType, data, signal);
}
//----------------------------------------------------------------------------------------------
function swGetToken(url: string): Promise<string> {
  const promise = new Promise<string>((resolve, reject) => {
    // Listen to token response

    if (!tokenPort) {
      reject("swGetToken.error: no tokenPort");
    }
    else {
      tokenPort.onmessage = (e) => {
        console.log("swGetToken.tokenPort.onmessage.url:", url);
        resolve(e.data as string);
      };
      // Send a token request to the main thread
      console.log("swGetToken: requesting token for url", url);
      tokenPort.postMessage({type: "tokenRequest"});
    }
  });
  return promise;
}
//----------------------------------------------------------------------------------------------
export async function swGetTokenAsync(): Promise<string | undefined> {
  const promise = new Promise<string>((resolve, reject) => {
    // Listen to token response
    if (tokenPort) {
      console.log("swGetTokenAsync.requesting...");
      tokenPort.onmessage = (e) => {
        console.log("swGetTokenAsync.onMessage:", e);
        resolve(e.data as string);
      };
      // Send a token request to the main thread
      tokenPort.postMessage({type: "tokenRequest"});
    }
    else {
      reject("swGetTokenAsync.error: no tokenPort");
    }
  });
  return promise;
}
//----------------------------------------------------------------------------------------------
export async function swFetchPrivate(
  url: string,
  method: string,
  token?: string,
  contentType?: string,
  payload?: any,
  signal?: AbortSignal
): Promise<IApiResponse | void> {
  try {
    if (!token) {
      token = await swGetToken(url);
      if (!token) {
        throw Error(`unable to get token [${url}]`);
      }
    }
    let options = getRequestOptions({
      method: method,
      token: token,
      contentType: contentType,
      payload: payload,
      signal: signal
    });
    console.log("swGetToken.then.calling apiFetch, url:", url);
    console.log("swGetToken.then.calling apiFetch, options:", options);
    return apiFetch(url, options);
  }
  catch (error) {
    console.error("swFetchPrivate error:", error);
  }
}
