import { Location } from "react-router";

export function GetIdFromLocationHash(location?: Location): string | undefined {
  if (!location || !location.hash)
    return undefined;
  let id = location.hash.replace('#', '');
  //console.log("idFromLocationHash.id1:", id);
  if (id.includes('?')) {
    console.log("GetIdFromLocationHash:", id);
    id = id.split('?')[0];
  }
  //console.log("idFromLocationHash.id2:", id);
  return id;
}

export const categoryParamName = "cat";

export function GetCategoryByPath(location: Location): string | undefined{
  // Determine meaningful search parameters
  const searchParams = new URLSearchParams(location.search);
  const currentCategories = searchParams.get(categoryParamName);
  const currentCategory = currentCategories ? currentCategories.split(",")[0] : undefined;
  return currentCategory;
}
