import { useState } from "react";
import { ScreenType, useAppScreenContext } from "../../context/app-screen-context/AppScreenProvider";
import Icon from "../common/icon/Icon";
import styles from "./HealthStatusHeader.module.css";
import { StatusToggler } from "./StatusToggler";
import React from "react";
import { TogglerWithCaption } from "../common/toggler/TogglerWithCaption";

interface IProps {
  status: string;
  errorPhrase?: string;
  onRefreshStatus: () => void;
}

export function HealthStatusHeader(props: IProps) {
  const screenType = useAppScreenContext();
  const [collapsed, setCollapsed] = useState(true);
  //------------------------------------------------------------------------
  const onClick = () => {
    setCollapsed(!collapsed);
  }
  //------------------------------------------------------------------------
  const onExpand = () => {
    setCollapsed(false);
  }
  //---------------------------------------------------------------------
  const onCollapse = () => {
    setCollapsed(true);
  }
  //---------------------------------------------------------------------
  let displayStatus: string;
  if (props.errorPhrase) {
    displayStatus = `${props.status} (${props.errorPhrase})`
  }
  else {
    displayStatus = `${props.status}`;
  }
  displayStatus = `${props.status}`;

  //---------------------------------------------------------------------------
  // let errors = props.status == "OK" ? undefined :
  //   <StatusToggler
  //     caption="Error(s)"
  //     collapsed={collapsed}
  //     //count={0}
  //     onCollapse={onCollapse}
  //     onExpand={onExpand}
  //     children={<div> {props.errorPhrase} </div>}
  //   />
  const getErrorDivs = () => {
    if (props.status == "OK")
      return undefined;
    let strings = props.errorPhrase?.split("\r\n");
    if (!strings || strings.length == 0)
      return undefined;
    let result: JSX.Element[] = [];
    strings.forEach((item, i) => {
      if (item && item.length > 0)
        result.push(<div key={`errorDiv_${i}`} className={styles.errorDiv}>{item}</div>);
    }
    );
    return result;
  }
  let errorDivs = getErrorDivs();
  let errors = !errorDivs ? undefined :
    <div className={styles.error}>
      <TogglerWithCaption
        //caption={`Error(s)`}
        caption={`Details`}
        showToggler={true}
        collapsed={collapsed}
        onClick={onClick}
        togglerPosition={"Right"}
      />
      {!collapsed && <div> {errorDivs} </div>}
    </div>

  // let errors = props.status == "OK" ? undefined :
  //   <div className={styles.error}>
  //     <TogglerWithCaption
  //       caption={`Error(s)`}
  //       showToggler={true}
  //       collapsed={collapsed}
  //       onClick={onClick}
  //       togglerPosition={"Right"}
  //     />
  //     {!collapsed && <pre> {props.errorPhrase} </pre>}
  //   </div>


  //---------------------------------------------------------------------
  console.log("props.status:", props.status);
  let displayStyle: string = props.status == "OK" ? styles.healthyStatus : styles.errorStatus;
  let refreshStyle: string =
    screenType == ScreenType.Mobile
      ? styles.refreshContainerMobile
      : styles.refreshContainerDesktop;
  //---------------------------------------------------------------------
  return (
    <div className={styles.superContainer}>
      <div className={styles.container} >
        <div className={styles.header} onClick={props.onRefreshStatus}>
          RikiWiki health status
        </div>
        <div className={displayStyle}>
          <div className={styles.statusText}>
            {displayStatus}
          </div>
        </div>
        <div className={refreshStyle}>
          <div className={styles.refreshOption} onClick={props.onRefreshStatus}>
            <Icon imageName="refresh" />
          </div>
        </div>
      </div>
      {errors != undefined && errors}
    </div>
  );
}