import styles from './LanguagesListItem.module.css';
import OptionDelete from '../../../../../../common/options/icon-options/OptionDelete';
import OptionMoveUp from '../../../../../../common/options/icon-options/OptionMoveUp';
import OptionMoveDown from '../../../../../../common/options/icon-options/OptionMoveDown';
import OptionAdd from '../../../../../../common/options/icon-options/OptionAdd';
import CombineStyles from '../../../../../../../utils/combineStyles';

interface IProps {
  data: string; // Locale id
  isFirst?: boolean;
  isLast?: boolean;
  isNotSupported: boolean;
  onAdd: () => void;
  onDelete: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
}

export default function LanguagesListItem(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <tr>

      <td className={CombineStyles([
        styles.caption,
        props.isNotSupported ? styles.disabled : ""
      ])}>
        {props.data}
        {props.isNotSupported &&
          <span>&nbsp;*</span>}
      </td>

      <td className={styles.option}>
        <OptionMoveUp
          disabled={props.isFirst == true}
          onSelect={props.onMoveUp}
        />
      </td>

      <td className={styles.option}>
        <OptionMoveDown
          disabled={props.isLast == true}
          onSelect={props.onMoveDown}
        />
      </td>

      <td className={styles.option}>
        <OptionAdd
          onSelect={props.onAdd}
        />
      </td>

      <td className={styles.option}>
        <OptionDelete
          onSelect={props.onDelete}
        />
      </td>

    </tr>
  );
}