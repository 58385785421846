import React, { useEffect, useState } from "react";
import { useAppScreenContext } from "../../../../context/app-screen-context/AppScreenProvider";
import { ImageList } from "../../../user-console/user-images/image-library/image-list/ImageList";
import { TImageLibraryItem } from "../../../user-console/user-images/image-library/TImageLibrary";
import DialogOption from "../../dialog/dialog-option/DialogOption";
import { TUiMenuOption } from "../../menu/menu-content/menu-option/TUiMenuOption";
import styles from "./ImageSelectorPopup.module.css";
import Toolbar from "../../toolbar/Toolbar";
import { IUiUserImagesList } from "../../../user-console/user-images/image-library/image-list/IUiUserImagesList";
import { IUiInputText } from "../../input-v2/input-text/InputText";

let btnAsCopy: TUiMenuOption = {
  id: "btnAsCopy",
  directoryId: "",
  elementType: "",
  caption: { en: "Copy" },
  hint: { en: "Copy" },
  iconFile: "copy",
  isDefault: true,
  disabled: false,
  visible: true,
  action: undefined,
  index: 0,
  priorityLevel: 0,
  canHideCaption: false
}
let btnAsLink: TUiMenuOption = {
  id: "btnAsLink",
  directoryId: "",
  elementType: "",
  caption: { en: "Link" },
  hint: { en: "Link" },
  iconFile: "linkAdd",
  isDefault: true,
  disabled: false,
  visible: true,
  action: undefined,
  index: 0,
  priorityLevel: 0,
  canHideCaption: false
}
const cancelButton: TUiMenuOption = {
  id: "cancelButton",
  directoryId: "",
  elementType: "",
  caption: { en: "Cancel", ru: "Отмена" },
  hint: { en: "Cancel", ru: "Отмена" },
  iconFile: "close",
  isDefault: false,
  disabled: false,
  visible: true,
  action: undefined,
  index: 0,
  priorityLevel: 0,
  canHideCaption: false
}
const tmpSearchInputText: IUiInputText = {
  id: "uiInputText",
  directoryId: "uiInputText",
  elementType: "uiInputText",
  disabled: false,
  visible: true,
  caption: { en: "Image description" },
  hint: { en: "" },
  placeholder: {
    en: "Type to filter images by description",
    ru: "Фильтр по описанию"
  },
  defaultValue: "Image1",
  validation: undefined
}
const tmpListUi: IUiUserImagesList = {
  inputImageSearch: tmpSearchInputText
}

interface IProps {
  onSelectImage: (item: TImageLibraryItem, asLink: boolean) => void;
  onCancel: () => void;
}

export default function ImageSelector(props: IProps) {
  const screenType = useAppScreenContext();
  const [selectedItem, setSelectedItem] = useState<TImageLibraryItem>();
  //---------------------------------------------------------------------------
  useEffect(() => {

  }, [selectedItem]);
  //---------------------------------------------------------------------------
  const onCancel = () => {
    props.onCancel();
  }
  //---------------------------------------------------------------------------
  const onSelectImageAsLink = async () => {
    // if (!selectedItem?.id)
    //   return;
    //   try {
    //     let loader = await ImageLoader.getLoader(selectedItem.id);
        
    //   } catch (error) {
        
    //   }
    selectedItem && props.onSelectImage(selectedItem, true);
  }
  //---------------------------------------------------------------------------
  const onSelectImageAsCopy = () => {
    selectedItem && props.onSelectImage(selectedItem, false);
  }
  //---------------------------------------------------------------------------
  const onSelectItem = (item: TImageLibraryItem) => {
    setSelectedItem(item);
  }
  //---------------------------------------------------------------------------
  const formOptions =
    <React.Fragment>
      <DialogOption
        ui={{ ...btnAsCopy, disabled: selectedItem == undefined }}
        clickHandler={onSelectImageAsCopy}
      />
      <DialogOption
        ui={{ 
          ...btnAsLink, 
          disabled: selectedItem == undefined
        }}
        clickHandler={onSelectImageAsLink}
      />
      <DialogOption
        ui={cancelButton}
        clickHandler={onCancel}
      />
    </React.Fragment>;
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <div className={styles.scrollContainer2}>
        <ImageList
          ui={tmpListUi}
          canSelectItem={true}
          selectedItemId={selectedItem?.id}
          clearContextOnUnmount={false}
          addImageSignal={false}
          onSelectImage={onSelectItem}
        />
      </div>
      <Toolbar>
        {formOptions}
      </Toolbar>
    </React.Fragment>
  );
}