import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathCreateSystemBusinessRegional } from "../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../utils/common";
import { apiPostPrivate, checkResponse } from "../../../../utils/api";
import { ClassSystemBusinessRegional } from "../regional-homepage-editor/ISystemBusinessRegional";

interface IProps {
  msalInstanse: IPublicClientApplication,
  authConfig?: IAuthConfig,
  regionId?: string,
  abortSignal?: AbortSignal
}

export default async function createRegionalHomepageBusinessAsync(props: IProps): Promise<ClassSystemBusinessRegional> {
  if (!props.authConfig) throw new Error("createSystemBusinessAdCampaignAsync: auth config is not set");
  if (!props.regionId) throw new Error("createSystemBusinessAdCampaignAsync: region id is not provided");
  //---------------------------------------------------------------------------
  const uri = stringFormatter(`${apiBasePath}${pathCreateSystemBusinessRegional}`, [props.regionId]);
  const response = await apiPostPrivate(
    props.msalInstanse,
    props.authConfig,
    uri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "createRegionalHomepageBusinessAsync", props.abortSignal?.aborted);
  const business = new ClassSystemBusinessRegional(response?.content);
  return business;
}