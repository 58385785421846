import { useContext } from 'react';
import { EditFormSection } from '../../common/console-layout/edit-form-sections/EditFormSection';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';
import GetHint from '../../common/functions/GetHint';
import AdModulesEditor from '../ad-modules-editor/AdModulesEditor';
import { IUiSectionBusinessPage } from '../../business-space/adpage-editor/section-business-page/IUiSectionBusinessPage';
import { BusinessContext } from '../../../context/business-context/BusinessContextProvider';
import { ClassAdModule } from '../../common/ad-modules/IAdModule';
import styles from './SectionEmailModules.module.css';
import AdStatus from '../ad-status/AdStatus';

interface IProps {
  ui: IUiSectionBusinessPage;
  isReadonly: boolean;
}

export default function SectionEmailModules(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { businessContext } = useContext(BusinessContext);
  const adLanguageSet = businessContext.adLanguageSetState?.adLanguageSet;
  //---------------------------------------------------------------------------
  const emailModules: ClassAdModule[] = []; /* adLanguageSet?.adModules?.filter(adModule =>
    adModule.adModuleType == "SearchResultsModule"
  ); */
  //---------------------------------------------------------------------------
  return (
    <EditFormSection
      id={props.ui.id}
      caption={GetHint(appUiContext, props.ui.id, props.ui.hint)}
    >
      <div className={styles.container}>
        <AdStatus data={adLanguageSet?.status} />
        <AdModulesEditor
          ui={props.ui.adModule}
          adModules={emailModules}
          isReadonly={props.isReadonly}
          useExternalLayout={false}
        />
      </div>
    </EditFormSection>
  );
}