import { useContext } from 'react';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { IUiDictionaryItem } from '../../../dictionaries/IUiDictionaryItem';
import GetDescription from '../../../functions/GetDescription';
import GetName from '../../../functions/GetName';
import styles from './InputDropdownItem.module.css';
import CombineStyles from '../../../../../utils/combineStyles';
import Checkbox from '../../../checkbox/Checkbox';

interface IProps {
  ui: IUiDictionaryItem;
  isMultiselect?: boolean;
  isSelected?: boolean;
  hideDescription?: boolean;
  isDisabled?: boolean;
  locale?: string; // to display options in certain locale, e.g. 'native'
  onSelect: () => void;
}

export default function InputDropdownItem(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        props.isSelected ? styles.selected : "",
        props.isDisabled ? styles.disabled : ""
      ])}
      onClick={props.onSelect}>

      {/* Checkbox for multi-select mode */}
      {!!props.isMultiselect &&
        <div
          className={CombineStyles([
            styles.checkBox,
            props.isSelected ? styles.selected : ""
          ])}
          onClick={props.onSelect}
        >
          <Checkbox checked={props.isSelected} />
        </div>}

      {/* Caption: name with optional description under */}
      <div className={styles.caption}>
        <div className={CombineStyles([
          styles.name
        ])}>
          {GetName(appUiContext, props.ui.id, props.ui.name, props.locale)}
        </div>
        {!props.hideDescription &&
          <div className={styles.description}>
            {GetDescription(appUiContext, props.ui.id, props.ui.description, props.locale)}
          </div>}
      </div>

    </div>
  );
}