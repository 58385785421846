import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import styles from './MapSpinner.module.css';

export default function MapSpinner() {
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <WikiSpinner show={true} />
    </div>
  );
}