import React from 'react';
import BusinessTagNode from '../business-tag-node/BusinessTagNode';
import { UiBusinessTag } from '../business-tag-node/IUiBusinessTag';
import { IUiBusinessTagsCollection } from './IUiBusinessTagsCollection';

interface IProps {
  ui: IUiBusinessTagsCollection;
  rootPath?: string;
  tags: UiBusinessTag[];
  selectedPaths: string[];
  filterString?: string;
  onUpdate?: (selectedPaths?: string[]) => void;
}

export default function BusinessTagsCollection(props: IProps) {
  const rootBusinessTags = props.tags.filter(tag => tag.path === props.rootPath);
  const tags = props.tags.filter(tag => tag.path?.startsWith(props.rootPath ?? ''));
  //---------------------------------------------------------------------------
  const tagElements = rootBusinessTags.map(tag => {
    return (
      <BusinessTagNode
        key={tag.fullPath}
        ui={props.ui}
        rootTag={tag}
        tags={tags}
        selectedPaths={props.selectedPaths}
        filterString={props.filterString}
        onUpdate={props.onUpdate}
      />
    )
  });
  //---------------------------------------------------------------------------
  if (!props.tags || props.tags.length == 0) return null;
  return (
    <React.Fragment>
      {tagElements}
    </React.Fragment>
  );
}