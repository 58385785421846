import { useContext, useEffect, useState } from 'react';
import ConsoleContent from '../../common/console-layout/console-content/ConsoleContent';
import styles from './TestList.module.css';
import { EManagerMode } from '../../../context/list-manager-context/IListManagerContextData';
import { testListColumns, testListFilterPresets, testListSortPresets } from './ListSettings';
import ListColumnsManager from '../../common/lists/list-columns-manager/ListColumnsManager';
import { ListManagerContext } from '../../../context/list-manager-context/ListManagerContextProvider';
import CombineStyles from '../../../utils/combineStyles';
import { TSortType } from '../../common/lists/sorter-editor/ISorterItem';
import TestListItem from './test-list-item/TestListItem';

export interface ITestListItem {
  id: number;
  name: string;
}

export default function TestList() {
  const { listManagerContext, listManagerContextDispatch } = useContext(ListManagerContext);
  const [listItems, setListItems] = useState<ITestListItem[]>([]);
  type TKey = keyof ITestListItem;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (listItems.length > 0) return;
    //-------------------------------------------------------------------------
    setListItems(getListItems());
    //-------------------------------------------------------------------------
    // Initialize list manager context
    listManagerContextDispatch({
      type: "Initialize",
      configuration: {
        listId: "TestList",
        managerMode: EManagerMode.Basic,
        listModes: ["OptionListModeList", "OptionListModeGrid"],
        listColumns: testListColumns,
        sortPresets: testListSortPresets,
        filterPresets: testListFilterPresets
      }
    });
  }, []);
  //---------------------------------------------------------------------------
  const getListItems = (): ITestListItem[] => {
    const listItems: ITestListItem[] = [];
    for (let i = 0; i < 100; i++) {
      listItems.push({
        id: i,
        name: `Item ${i}`
      });
    };
    return listItems;
  };
  //---------------------------------------------------------------------------
  const compareTestListItems = (
    a: ITestListItem,
    b: ITestListItem,
    propertyName: string,
    sort?: TSortType
  ) => {
    const aValue = a[propertyName as TKey] ?? "";
    const bValue = b[propertyName as TKey] ?? "";
    if (!sort || aValue == bValue) {
      return 0;
    } else if (aValue < bValue) {
      return sort == "Asc" ? -1 : 1;
    } else {
      return sort == "Asc" ? 1 : -1;
    };
  };
  //---------------------------------------------------------------------------
  const sidebarContent =
    <div>
      Sidebar
    </div>
  //---------------------------------------------------------------------------
  let filteredItems = listItems;
  if (listManagerContext.managerMode == EManagerMode.Basic) {
    // Simplified mode
    filteredItems?.sort((a, b) =>
      listManagerContext.sortPresets?.currentOptionId == "SmallestFirst" ? a.id - b.id : b.id - a.id);
  } else {
    // Advanced mode
    listManagerContext.sortableColumns.forEach(column => {
      filteredItems.sort((a, b) => compareTestListItems(a, b, column.id, column.sorter?.sort));
    });
  };
  //---------------------------------------------------------------------------
  const listItemElements = filteredItems.map(item =>
    <div
      key={item.id}
      className={CombineStyles([
        styles.listItem,
        listManagerContext.currentListMode == "OptionListModeGrid" ? styles.grid : ''
      ])}
    >
      <div className={styles.contentItem}>
        <span className={styles.caption}>Id:</span>
        {item.id}
      </div>
      <div className={styles.contentItem}>
        <span className={styles.caption}>Name:</span>
        {item.name}
      </div>
    </div>
  );
 /*  const listItemElements = filteredItems.map(item =>
    <TestListItem
      key={item.id}
      data={item}
    />
  ); */
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={undefined}
      //formOptions={formOptions}
      sidebarChildren={sidebarContent}
      containerWidthPercent={70}
    >
      <div className={styles.listManager}>
        <ListColumnsManager
          numberTotal={listItems?.length}
          numberFiltered={filteredItems.length}
          showBasicModeSwitch={true}
        />
      </div>

      <div
        className={CombineStyles([
          styles.listItems,
          listManagerContext.currentListMode == "OptionListModeGrid" ? styles.grid : ''
        ])}>
        {listItemElements}
      </div>
    </ConsoleContent>
  );
}