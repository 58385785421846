import { createContext, Dispatch, ReactNode, useReducer } from "react";
import { TUiStaffRole } from "../administrator-console/TUiAdministratorConsole";
import { IApprovalRequest } from "../moderator-console/approval-request-form/TApprovalRequest";
import { TDirectoryItem } from "../TUiAdminConsole";
import { Actions, AdminConsoleContextReducer } from "./AdminConsoleContextReducer";

export type TAdminConsoleContext = {
  approvalRequestStatusDictionary: TDirectoryItem[],
  approvalRequestRejectionReasonDictionary: TDirectoryItem[],
  needToRefreshRequestList: boolean;
  approvalRequestList: IApprovalRequest[];
  //approvalRequest: IApprovalRequest | undefined;  // Remove?
  userListFilterRole?: TUiStaffRole;
  //businessTags?: IUiDictionaryEditorItem[];  
}

export const adminConsoleContextDefaultValue: TAdminConsoleContext = {
  approvalRequestStatusDictionary: [],
  approvalRequestRejectionReasonDictionary: [],
  needToRefreshRequestList: true,
  approvalRequestList: [],
  //approvalRequest: undefined,
};

//-----------------------------------------------------------------------------
type TAdminConsoleContextStore = {
  adminConsoleContext: TAdminConsoleContext;
  adminConsoleContextDispatch: Dispatch<Actions>;
}

export const AdminConsoleContext = createContext<TAdminConsoleContextStore>({} as TAdminConsoleContextStore);

//-----------------------------------------------------------------------------
interface IProps {
  children: ReactNode;
}

export function AdminConsoleContextProvider(props: IProps) {
  const [adminConsoleContext, adminConsoleContextDispatch] = useReducer(AdminConsoleContextReducer, adminConsoleContextDefaultValue);
  //---------------------------------------------------------------------------
  return (
    <AdminConsoleContext.Provider value={{ adminConsoleContext, adminConsoleContextDispatch }}>
      {props.children}
    </AdminConsoleContext.Provider>
  );
}
