import { IPreset } from '../../../../context/list-manager-context/IListManagerContextData';
import styles from './PresetOption.module.css';

interface IProps {
  data: IPreset;
  onSelect: (optionId: string) => void;
}

export default function PresetOption(props: IProps) {
  //---------------------------------------------------------------------------
  const onSelect = () => {
    props.onSelect(props.data.id);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      onClick={onSelect}
    >
      {props.data.name}
    </div>
  );
}