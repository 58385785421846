import CombineStyles from '../../../../../../../utils/combineStyles';
import styles from './RequestStatusBadge.module.css';

interface IProps {
  data: string;
  isApproved?: boolean;
  isRejected?: boolean;
}

export default function RequestStatusBadge(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div className={CombineStyles([
      styles.container,
      props.isApproved != undefined ? styles.approved : "",
      props.isRejected != undefined ? styles.rejected : ""
    ])}>
      {props.data}
    </div>
  );
}