import { useState } from 'react';
import { DictionaryDraftItemRejectionReasons } from '../DictionaryDraftItemRejectionReasons';
import { IDictionaryDraftItemStateGroup } from '../../common/settings/IDictionaryDraftItemStateGroup';
import DictionaryNodeWrapper from '../../common/dictionary-node-wrapper/DictionaryNodeWrapper';
import EditFormRejectionReason from '../edit-form/EditFormRejectionReason';
import { tmpUiDictionaryEditorNode } from '../../common/dictionary-node-wrapper/IUiDictionaryEditorNode';
import { IUiOption } from '../../../../../common/options/IUiOption';

interface IProps {
  stateGroups?: IDictionaryDraftItemStateGroup[];
  editModeIsEnabled?: boolean;
  data: DictionaryDraftItemRejectionReasons;
  onUpdate: (updatedDraftItem: DictionaryDraftItemRejectionReasons, oldId?: string) => void;
  onDelete: (deletedDraftItemId: string) => void;
}

export default function DictionaryNodeRejectionReason(props: IProps) {
  const [detailedMode, setDetailedMode] = useState(false);
  //---------------------------------------------------------------------------
  const restoreDefaultMode = () => {
    setDetailedMode(false);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedDraftItem: DictionaryDraftItemRejectionReasons, oldId?: string) => {
    setDetailedMode(false);
    props.onUpdate(updatedDraftItem, oldId);
  };
  //---------------------------------------------------------------------------
  const onMenuOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionEdit":
        setDetailedMode(true);
        break;
      case "OptionDelete":
        props.onDelete(props.data.id);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  tmpUiDictionaryEditorNode.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionEdit":
        updatedOptions.push(option);
        break;
      case "OptionDelete":
        updatedOptions.push({ ...option, isDisabled: !props.data.isNew });
        break;
    };
  });
  //---------------------------------------------------------------------------
  if (props.data) {
    if (detailedMode) return (
      <EditFormRejectionReason
        data={props.data}
        editModeIsEnabled={props.editModeIsEnabled}
        onUpdate={onUpdate}
        onClose={restoreDefaultMode}
      />
    ); else return (
      <DictionaryNodeWrapper
        ui={{
          ...tmpUiDictionaryEditorNode,
          menuContent: {
            ...tmpUiDictionaryEditorNode.menuContent,
            options: updatedOptions
          }
        }}
        stateGroups={props.stateGroups}
        data={props.data}
        editModeIsEnabled={props.editModeIsEnabled}
        showStatus={true}
        status={props.data.status}
        onToggleMode={() => setDetailedMode(true)}
        onOptionSelect={onMenuOptionSelect}
      />
    );
  } else return null;
}