import Checkbox from '../../checkbox/Checkbox';
import InputContainer from '../input-container/InputContainer';
import styles from './InputCheckbox.module.css';
import { TUiInputCheckbox } from './TUiInputCheckbox';

interface IProps {
  ui: TUiInputCheckbox;
  data?: boolean;
  hideHint?: boolean;
  onChange: (updatedValue: boolean) => void;
}

export default function InputCheckbox(props: IProps) {
  const hideHint = props.hideHint == undefined ? false : true;
  //---------------------------------------------------------------------------
  return (
    <InputContainer
      ui={props.ui}
      hideCaption={false}
      hideHint={hideHint}
      isValid={true}
    //validationMessages={props.disabled ? undefined : validationMessage ? [validationMessage] : []}
    >
      <div className={styles.container}>
        <Checkbox
          checked={props.data}
          onClick={() => props.onChange(!props.data)}
        />
      </div>
    </InputContainer>
  );
}