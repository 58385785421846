import EmailListItem from "./EmailListItem";
import styles from "./EmailList.module.css";
import { CSSProperties, useContext, useState } from "react";
import GetCaption from "../../../../common/functions/GetCaption";
import React from "react";
import EmailEditPopup from "./email-edit-popup/EmailEditPopup";
import { IUiInputListUserEmails } from "./IUiInputListUserEmails";
import InputWrapper from "../../../../common/input-v2/input-wrapper/InputWrapper";
import GetHint from "../../../../common/functions/GetHint";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";

interface IProps {
  ui: IUiInputListUserEmails;
  data: string[];
  showAlert?: boolean;
  onUpdate: (value: string[]) => void;
}

export default function EmailList(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [editIndex, setEditIndex] = useState<number>();
  //---------------------------------------------------------------------------
  const onAddClick = () => {
    setEditIndex(-1);
  };
  //---------------------------------------------------------------------------
  const onEdit = (index: number) => {
    if (index >= 0 && index < props.data.length) {
      setEditIndex(index);
    };
  }
  //---------------------------------------------------------------------------
  const onDelete = (indexToDelete: number) => {
    const updatedEmails = props.data.filter((item, index) => index != indexToDelete);
    console.log(updatedEmails)
    //setEmails(updatedEmails);
    props.onUpdate(updatedEmails);
  };
  //---------------------------------------------------------------------------
  const onEmailUpdate = (updatedEmail: string) => {
    setEditIndex(undefined);
    //console.log('onPopupConfirm.emails:', emails);
    if (updatedEmail) {
      let updatedEmails: string[];
      if (editIndex == -1) {
        // New email
        updatedEmails = props.data.concat(updatedEmail);
      } else {
        // Existing email updated
        updatedEmails = props.data.map((email, index) => index == editIndex ? updatedEmail : email);
      };
      //setEmails(updatedEmails);
      props.onUpdate(updatedEmails);
    } else return;
  };
  //---------------------------------------------------------------------------
  const onEmailEditorClose = () => {
    setEditIndex(undefined);
  };
  //---------------------------------------------------------------------------
  const showAlert = (props.showAlert === undefined) ? false : props.showAlert;
  let calculatedStyle: CSSProperties | undefined = undefined;
  if (showAlert) {
    calculatedStyle = { border: '3px solid var(--clr_accent_attention)' };
  };
  //---------------------------------------------------------------------------
  const items = props.data.map((item: string, index: number) => (
    <EmailListItem
      key={item}
      ui={props.ui.menuContent}
      data={item}
      onDelete={() => onDelete(index)}
      onEdit={() => onEdit(index)}
    />
  ));
  const optionAdd = props.ui.menuContent.options.find(option => option.id == "UserEmails.MenuContent.OptionAdd");
  //---------------------------------------------------------------------------
  return (
    <InputWrapper
      caption={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      hint={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      hideHint={false}
      readonly={false}
      disabled={false}
      validation={{ isValid: true, validationMessages: [] }}
    >
      <div className={styles.listContainer} style={calculatedStyle}>
        {items}
        {optionAdd &&
          <div className={styles.button} onClick={onAddClick}>
            {GetCaption(appUiContext, optionAdd.id, optionAdd.caption)}
          </div>}
      </div>

      {(editIndex !== undefined) &&
        <EmailEditPopup
          ui={props.ui.editForm}
          data={props.data[editIndex]}
          onClose={onEmailEditorClose}
          onUpdate={onEmailUpdate}
        />}

    </InputWrapper>
  );
}