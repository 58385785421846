import FormOptions from "../../form-options-bar/FormOptions"
import { TUiMenuContent } from "../../menu/menu-content/TUiMenuContent"
import { TUiMenuOption } from "../../menu/menu-content/menu-option/TUiMenuOption"
//----------------------------------------------------------------------------
export type EOkCancelDialogButtons = 'OkCancel' | 'YesNo' | 'SaveCancel' | 'SelectCancel';
export type EOkCancelDialogType = 'Confirmation' | 'Warning' | 'Error'
//----------------------------------------------------------------------------
export class ButtonIds {
  static ok = 'okButton';
  static yes = 'yesButton';
  static save = 'saveButton';
  static select = 'selectButton';
  static no = 'noButton';
  static cancel = 'cancelButton';
  //-------------------------------------
  static isOk(buttonId: string) {
    switch (buttonId) {
      case ButtonIds.ok:
      case ButtonIds.yes:
      case ButtonIds.save:
      case ButtonIds.select:
        return true;
    }
    return false;
  }
  //-------------------------------------
  static isCancel(buttonId: string) {
    switch (buttonId) {
      case ButtonIds.cancel:
      case ButtonIds.no:
        return true;
    }
    return false;
  }
}
//----------------------------------------------------------------------------
const buttonOk: TUiMenuOption = {
  id: ButtonIds.ok,
  directoryId: "",
  elementType: "",
  caption: { en: "Save" },
  hint: { en: "Save" },
  iconFile: "check",
  isDefault: true,
  disabled: false,
  visible: true,
  action: undefined,
  index: 0,
  priorityLevel: 0,
  canHideCaption: false
}
//----------------------------------------------------------------------------
const buttonCancel: TUiMenuOption = {
  id: ButtonIds.cancel,
  directoryId: "",
  elementType: "",
  caption: { en: "Cancel" },
  hint: { en: "Cancel" },
  iconFile: "close",
  isDefault: false,
  disabled: false,
  visible: true,
  action: undefined,
  index: 0,
  priorityLevel: 0,
  canHideCaption: false
}
//----------------------------------------------------------------------------
const dialogMenuContent: TUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  options: [],
  optionGroups: [],
  visible: true,
  disabled: false
}
//----------------------------------------------------------------------------
interface IProps {
  dialogType: EOkCancelDialogType;
  dialogButtons: EOkCancelDialogButtons;
  disabledButtonIds?: string[];
  btnOptional?: TUiMenuOption | null;
  onOk: () => void;
  onCancel: () => void;
  onOptional?: () => void;
}
//----------------------------------------------------------------------------
export function OkCancelToolbar(props: IProps) {
  //--------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    if (props.btnOptional && props.btnOptional.id == selectedOptionId) {
      return props.onOptional?.();
    }
    if (ButtonIds.isCancel(selectedOptionId)) {
      return props.onCancel();
    }
    props.onOk();
  };
  //--------------------------------------------------------------------------
  let btnOk = {...buttonOk };
  let btnCancel = {...buttonCancel };
  let ui = {
    ...dialogMenuContent,
    options: props.btnOptional ? [props.btnOptional, btnCancel, btnOk] : [btnCancel, btnOk],
  };

  //--------------------------------------------------------------------------
  switch (props.dialogButtons) {
    case "OkCancel":
      btnOk.caption = { en: 'OK' };
      btnCancel.caption = { en: 'Cancel' };
      break;
    case "SaveCancel":
      btnOk.caption = { en: 'Save' };
      btnCancel.caption = { en: 'Cancel' };
      break;
    case "SelectCancel":
      btnOk.caption = { en: 'Select' };
      btnCancel.caption = { en: 'Cancel' };
      break;
    case "YesNo":
      btnOk.caption = { en: 'Yes' };
      btnCancel.caption = { en: 'No' };
      break;
  }
  //--------------------------------------------------------------------------
  if (!props.disabledButtonIds) {
    btnOk.disabled = false;
    btnCancel.disabled = false;
  }
  else {
    props.disabledButtonIds?.forEach(buttonId => {
      if (ButtonIds.isCancel(buttonId))
        btnCancel.disabled = true;
      else if (ButtonIds.isOk(buttonId))
        btnOk.disabled = true;
    });
  }
  //--------------------------------------------------------------------------
  // console.log("OkCancelToolbar.props:", props);
  // console.log("OkCancelToolbar.dialogMenuContent:", dialogMenuContent);
  // console.log("OkCancelToolbar.ui:", ui);
  //--------------------------------------------------------------------------
  return (
    <FormOptions
      ui={ui}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    />
  );
}