import { useContext } from "react";
import { AppUiContextStore } from "../context/app-ui-context/AppUiContextProvider";

export default function usePageTitle() {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  function updateTitle(header: string) {
    if (appUiContext.sysSettings.pageHeaderFormatString)
      document.title = appUiContext.sysSettings.pageHeaderFormatString.replace("{0}", header);
  }
  //---------------------------------------------------------------------------
  return updateTitle;
}