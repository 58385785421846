import { useContext, useState } from 'react';
import AdModulesList from '../../common/ad-modules/ad-modules-list/AdModulesList';
import { ClassAdModule, EAdUnitImageType, EAdUnitTextType, getImageUnitStatus, IAdModule, IAdModuleParagraph, IAdUnitImage, IAdUnitText } from '../../common/ad-modules/IAdModule';
import { IUiAdModule, tmpAdModuleMenuContent, tmpUiInputListContacts } from '../../common/ad-modules/IUiAdModule';
import ReviewResults from '../review-results/ReviewResults';
import TextEditorPopup from '../../common/text-editor/text-editor-popup/TextEditorPopup';
import { ClassAdUnitStatus } from '../../common/ad-modules/IAdUnit';
import { BusinessContext } from '../../../context/business-context/BusinessContextProvider';
import { ImageManager } from '../../common/image-editor/image-manager/ImageManager';
import { ClassImageManager } from '../../common/image-editor/image-manager/IImageManager';
import { TImageData } from '../../common/image-editor/TImageData';
import { useAbortController } from '../../../hooks/useAbortController';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../context/app-auth-context/AppAuthContext';
import WikiSpinner from '../../app-layout/spinner/wikiSpinner';
import { TImageUsage } from '../../common/image-editor/TImageUsage';
import { EOptionsAdModule, EOptionsContact, EOptionsLocation, EOptionsParagraph } from './AdModuleOptions';
import { getUpdatedAddressBlockLocation, IBusinessLocationData, IObjectLocation } from '../../business-space/business-editor/section-location/business-location-editor/IBusinessLocationData';
import { ClassCustomerBusiness } from '../../business-space/business-editor/ICustomerBusiness';
import BusinessLocationsListPopup, { tmpUiBusinessLocationsListPopup } from '../../business-space/business-editor/section-location/business-location-editor/business-locations-list-popup/BusinessLocationsListPopup';
import ContactsListPopup, { tmpUiContactsListPopup } from '../../business-space/business-editor/section-contacts/contacts-editor/contacts-list-popup/ContactsListPopup';
import { Contact, IContact } from '../../business-space/business-editor/section-contacts/contact/Contact';
import styles from './AdModulesEditor.module.css';
import PopupParagraphLayout from '../../common/content/content-paragraph/popup-layout/PopupParagraphLayout';
import { v4 as uuidv4 } from "uuid";
import { AdLanguageSet } from '../IAdLanguageSet';
import AdDetailsEditor from '../ad-details-editor/AdDetailsEditor';
import { tmpUiAdDetails } from '../ad-details-editor/IUiAdDetails';

interface IEditTextState {
  adModule: ClassAdModule;
  sourceAdModuleId?: string;
  paragraphId?: string;
  textType?: EAdUnitTextType;
  text?: string;
}

interface IEditImageState {
  adModule: ClassAdModule;
  paragraph?: IAdModuleParagraph;
  sourceAdModuleId?: string;
  imageType?: EAdUnitImageType;
}

interface ISelectLocationState {
  adModule: ClassAdModule;
  sourceAdModuleId?: string;
}

interface ISelectContactState {
  adModule: ClassAdModule;
  sourceAdModuleId?: string;
}

interface ISelectParagraphLayoutState {
  adModule: ClassAdModule;
  paragraph: IAdModuleParagraph;
}

interface IProps {
  ui: IUiAdModule;
  locale?: string;
  adModules?: ClassAdModule[];
  isReadonly: boolean;
  useExternalLayout: boolean;
  //onUpdate: (adModule: ClassAdModule, adModuleIdToReplace?: string) => void;
}

export default function AdModulesEditor(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const [imageManager, setImageManager] = useState<ClassImageManager>();
  const [selectedItemPath, setSelectedItemPath] = useState<string[]>();
  const [isLoading, setIsLoading] = useState(false);
  const abortController = useAbortController("AdModulesEditor");
  const isSystem = businessContext.businessState?.business.businessType !== "Business";
  const adLanguageSet = businessContext.adLanguageSetState?.adLanguageSet;
  if (!adLanguageSet) return null;
  //---------------------------------------------------------------------------
  const [adDetailsPopupOpen, setAdDetailsPopupOpen] = useState(false);
  const [textEditState, setTextEditState] = useState<IEditTextState>();
  const [imageEditState, setImageEditState] = useState<IEditImageState>();
  const [locationSelectState, setLocationSelectState] = useState<ISelectLocationState>();
  const [contactSelectState, setContactSelectState] = useState<ISelectContactState>();
  const [paragraphLayoutState, setParagraphLayoutState] = useState<ISelectParagraphLayoutState>();
  //--------------------------------------------------------------------------- Visualisation
  const onImageManagerCreate = (manager: ClassImageManager) => {
    if (!businessContext.businessState?.business) return;
    manager.businessId = businessContext.businessState?.business?.id;
    manager.adLanguageSetId = businessContext.adLanguageSetState?.adLanguageSet.id;
    setImageManager(manager);
  };
  //--------------------------------------------------------------------------- 
  const updateSelectedItemPath = (path?: string[]) => {
    setSelectedItemPath(path);
  };
  //--------------------------------------------------------------------------- Ad Module
  const updateAdModule = (updatedAdModule: ClassAdModule, sourceAdModuleId?: string) => {
    if (!adLanguageSet.adModules) return;
    let updatedAdModules: ClassAdModule[];
    if (sourceAdModuleId) {
      // If source module id is provided, we need to replace source with updated module
      updatedAdModules = adLanguageSet.adModules.map(adModule => adModule.id == sourceAdModuleId ? updatedAdModule : adModule);
      //console.log(updatedAdModules)
    } else {
      // Just update the module
      updatedAdModules = adLanguageSet.adModules.map(adModule => adModule.id === updatedAdModule.id ? updatedAdModule : adModule);
    };
    //-------------------------------------------------------------------------
    const updatedAdLanguageSet = new AdLanguageSet({
      ...adLanguageSet,
      adModules: updatedAdModules
    });
    businessContextDispatch({
      type: 'UpdateAdLanguageSet',
      adLanguageSet: updatedAdLanguageSet
    });
  };
  //--------------------------------------------------------------------------- Ad Module Options
  const onAdModuleOptionSelect = (optionId: EOptionsAdModule, adModuleId: string) => {
    console.log(optionId, adModuleId)
    switch (optionId) {
      case "ModuleHeaderEdit":
        onAdModuleHeaderEdit(adModuleId);
        break;
      case "ModuleDescriptionEdit":
        onAdModuleDescriptionEdit(adModuleId);
        break;
      case "PhotoblockTextEdit":
        setAdDetailsPopupOpen(true);
        break;
      case "ModuleBackgroundImageEdit":
        onAdModuleBackgroundImageEdit(adModuleId);
        break;
      case "ModuleBackgroundImageSelect":
        onAdModuleBackgroundImageSelect(adModuleId);
        break;
      case "ModuleSelectTemplate":
        break;
      case "ModuleParagraphAdd":
        paragraphAdd(adModuleId);
        break;
      case "ModuleDelete":
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onAdModuleHeaderEdit = (adModuleId: string) => {
    if (!adLanguageSet) return;
    const adModule = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!adModule) return;
    setTextEditState({
      adModule: adModule,
      sourceAdModuleId: adModule.id == adModuleId ? undefined : adModuleId,
      textType: "Header",
      text: adModule.header?.text
    });
  };
  //---------------------------------------------------------------------------
  const onAdModuleDescriptionEdit = (adModuleId: string) => {
    const adModule = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!adModule) return;
    // Open text editor
    setTextEditState({
      adModule: adModule,
      sourceAdModuleId: adModule.id == adModuleId ? undefined : adModuleId,
      textType: "Description",
      text: adModule.description?.text
    });
  };
  //---------------------------------------------------------------------------
  const onAdModuleBackgroundImageEdit = (adModuleId: string) => {
    const adModule = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!imageManager
      || !imageManager.businessId
      || !adModule
      || !adModule.image?.imageData)
      return;
    //-------------------------------------------------------------------------
    setImageEditState({
      adModule: adModule,
      sourceAdModuleId: adModule.id == adModuleId ? undefined : adModuleId,
      imageType: "Background"
    });
    //-------------------------------------------------------------------------
    imageManager.adModuleId = adModuleId;
    setIsLoading(true);
    let controller = abortController.newController("editImage");
    imageManager.editImage(
      adModule.image.imageData,
      TImageUsage.newBackground(imageManager.businessId, adModuleId, isSystem),
      controller.signal)
      .catch(error => {
        console.error(`SectionBusinessPageV2.editImage[${imageManager.imageToEdit?.id}]:`, error);
      })
      .finally(() => {
        !controller.aborted && setIsLoading(false);
        controller.remove();
      });
  };
  //---------------------------------------------------------------------------
  const onAdModuleBackgroundImageSelect = (adModuleId: string) => {
    const adModule = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!imageManager
      || !imageManager.businessId
      || !adModule)
      return;
    //-------------------------------------------------------------------------
    setImageEditState({
      adModule: adModule,
      sourceAdModuleId: adModule.id == adModuleId ? undefined : adModuleId,
      imageType: "Background"
    });
    //-------------------------------------------------------------------------
    imageManager.adModuleId = adModuleId;
    setIsLoading(true);
    let controller = abortController.newController("loadUserImages");
    imageManager.selectImage(
      instance,
      appAuthContext,
      adModule.image?.imageData,
      controller.signal)
      .catch(error => {
        console.error("imageManager?.selectImage.catch:", error);
      })
      .finally(() => {
        !controller.aborted && setIsLoading(false);
        controller.remove();
      });
  };
  //--------------------------------------------------------------------------- Location Options
  const onLocationOptionSelect = (optionId: EOptionsLocation, adModuleId: string, locationId?: string) => {
    switch (optionId) {
      case "LocationSelect":
        onLocationSelect(adModuleId);
        break;
      case "LocationExclude":
        if (!locationId) return;
        locationExclude(adModuleId, locationId);
        break;
      case "LocationRefresh":
        if (!locationId) return;
        locationRefresh(adModuleId, locationId);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onLocationSelect = (adModuleId: string) => {
    if (props.isReadonly) return;
    const adModuleToUpdate = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!adModuleToUpdate?.businessLocationData) return;
    //-------------------------------------------------------------------------
    setLocationSelectState({
      adModule: adModuleToUpdate,
      sourceAdModuleId: adModuleToUpdate.id == adModuleId ? undefined : adModuleId
    });
  };
  //--------------------------------------------------------------------------- Contact Options
  const onContactOptionSelect = (optionId: EOptionsContact, adModuleId: string, contactId?: string) => {
    console.log(optionId, adModuleId, contactId)
    switch (optionId) {
      case "ContactRefresh":
        if (!contactId) return;
        contactRefresh(adModuleId, contactId);
        break;
      case "ContactSelect":
        onContactSelect(adModuleId);
        break;
      case "ContactExclude":
        if (!contactId) return;
        contactExclude(adModuleId, contactId);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onContactSelect = (adModuleId: string) => {
    if (props.isReadonly) return;
    const adModuleToUpdate = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!adModuleToUpdate?.businessLocationData) return;
    //-------------------------------------------------------------------------
    setContactSelectState({
      adModule: adModuleToUpdate,
      sourceAdModuleId: adModuleToUpdate.id == adModuleId ? undefined : adModuleId
    });
  };
  //--------------------------------------------------------------------------- Cancellations
  const onTextEditorClose = () => {
    setTextEditState(undefined);
  };
  //---------------------------------------------------------------------------
  const locationsSelectCancel = () => {
    setLocationSelectState(undefined);
  };
  //---------------------------------------------------------------------------
  const contactsSelectCancel = () => {
    setContactSelectState(undefined);
  };
  //--------------------------------------------------------------------------- Update text
  const onTextUpdate = (updatedText: string) => {
    if (!textEditState) return;
    if (!props.adModules) return;
    //-------------------------------------------------------------------------
    if (textEditState?.paragraphId) {
      // Update paragraph text
      const updatedParagraph = textEditState.adModule.paragraphs?.find(item => item.id === textEditState.paragraphId);
      if (!updatedParagraph) return;
      updatedParagraph.text = {
        ...updatedParagraph.text as IAdUnitText,
        text: updatedText
      };
    } else if (textEditState?.textType === "Header") {
      //-----------------------------------------------------------------------
      // Update ad module header
      textEditState.adModule.header = {
        ...textEditState.adModule.header as IAdUnitText,
        text: updatedText,
        status: new ClassAdUnitStatus("Draft")
      };
    } else if (textEditState.textType === "Description") {
      //-----------------------------------------------------------------------
      // Update ad module description
      textEditState.adModule.description = {
        ...textEditState.adModule.description as IAdUnitText,
        text: updatedText,
        status: new ClassAdUnitStatus("Draft"),
        type: 'Description'
      };
    };
    //-------------------------------------------------------------------------
    updateAdModule(textEditState.adModule, textEditState.sourceAdModuleId);
    setTextEditState(undefined);
  };
  //--------------------------------------------------------------------------- Update image
  const onImageUpdate = (imageData?: TImageData) => {
    if (!imageEditState) return;
    if (!imageData) return;
    if (!imageManager?.adModuleId) return;
    if (!props.adModules) return;
    if (!imageData.imageStatus) return;
    //-------------------------------------------------------------------------
    if (imageManager.paragraphId) {
      // Paragarph image
      if (!imageEditState.paragraph) {
        console.error("onImageUpdate: imageEditState.paragraph is not set");
        return;
      };
      imageEditState.paragraph.image = {
        ...imageEditState.paragraph.image as IAdUnitImage,
        imageData: imageData,
        status: getImageUnitStatus(imageData.imageStatus)
      };
    } else {
      // Ad module background image
      imageEditState.adModule.image = {
        ...imageEditState.adModule.image as IAdUnitImage,
        imageData: imageData,
        status: getImageUnitStatus(imageData.imageStatus)
      };
    };
    //-----------------------------------------------------------------------
    updateAdModule(imageEditState.adModule, imageEditState.sourceAdModuleId);
  };
  //--------------------------------------------------------------------------- Update location
  const locationRefresh = (adModuleId: string, locationId: string) => {
    if (props.isReadonly) return;
    const updatedAdModule = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!updatedAdModule?.businessLocationData) return;
    //-------------------------------------------------------------------------
    const locationToUpdate = updatedAdModule.businessLocationData.locations.find(item => item.id === locationId);
    if (!locationToUpdate) return;
    //-------------------------------------------------------------------------
    // Re-read location data from the business
    if (!businessContext.businessState?.business) return;
    const business = businessContext.businessState?.business as ClassCustomerBusiness;
    const businessLocation = business.locationData?.locations.find((location: IObjectLocation<string>) => location.id === locationId);
    if (!businessLocation) return;
    //-------------------------------------------------------------------------
    const updatedLocation: IObjectLocation<IAdUnitText> = {
      ...locationToUpdate,
      name: {
        type: "Name",
        text: businessLocation.name,
        status: new ClassAdUnitStatus("Draft")
      },
      point: businessLocation.point,
      addressText: {
        type: "Address",
        text: businessLocation.addressText,
        status: new ClassAdUnitStatus("Draft")
      }
    };
    //-------------------------------------------------------------------------
    const updatedLocations = updatedAdModule.businessLocationData.locations.map(item => item.id === locationId ? updatedLocation : item);
    const updatedLocationData = {
      ...updatedAdModule.businessLocationData as IBusinessLocationData<IAdUnitText>,
      locations: updatedLocations
    };
    updatedAdModule.businessLocationData = updatedLocationData;
    updateAdModule(
      updatedAdModule,
      updatedAdModule.id === adModuleId ? undefined : adModuleId
    );
  };
  //---------------------------------------------------------------------------
  const locationExclude = (adModuleId: string, locationId: string) => {
    if (props.isReadonly) return;
    const adModuleToUpdate = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!adModuleToUpdate?.businessLocationData) return;
    //-------------------------------------------------------------------------
    console.log(adModuleToUpdate, adModuleId)
    const updatedLocations = adModuleToUpdate.businessLocationData.locations.filter(item => item.id !== locationId);
    const updatedLocationData = {
      ...adModuleToUpdate.businessLocationData as IBusinessLocationData<IAdUnitText>,
      locations: updatedLocations
    };
    adModuleToUpdate.businessLocationData = updatedLocationData;
    updateAdModule(
      adModuleToUpdate,
      adModuleToUpdate.id === adModuleId ? undefined : adModuleId
    );
  };
  //---------------------------------------------------------------------------
  const locationsUpdate = (selectedLocations: IObjectLocation<string>[]) => {
    if (!locationSelectState) return;
    if (!locationSelectState.adModule.businessLocationData) return;
    //-------------------------------------------------------------------------
    // User selected locations from the list: run through the selected locations and map them to the existing locations
    const updatedLocations = getUpdatedLocations(
      locationSelectState.adModule.businessLocationData.locations,
      selectedLocations);
    const updatedLocationData: IBusinessLocationData<IAdUnitText> = {
      ...locationSelectState.adModule.businessLocationData,
      locations: updatedLocations
    };
    locationSelectState.adModule.businessLocationData = updatedLocationData;
    updateAdModule(locationSelectState.adModule, locationSelectState.sourceAdModuleId);
    setLocationSelectState(undefined);
  };
  //---------------------------------------------------------------------------
  const locationDataUpdate = (adModuleId: string, locationData: IBusinessLocationData<string>) => {
    if (props.isReadonly) return;
    const adModuleToUpdate = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!adModuleToUpdate?.businessLocationData) return;
    //-------------------------------------------------------------------------
    // Convert location data strings (name, addressText) to IAdUnitText
    const updatedLocations: IObjectLocation<IAdUnitText>[] = getUpdatedLocations(
      adModuleToUpdate.businessLocationData.locations,
      locationData.locations);
    //-------------------------------------------------------------------------
    const updatedLocationData: IBusinessLocationData<IAdUnitText> = {
      ...adModuleToUpdate.businessLocationData,
      mapSettings: locationData.mapSettings,
      locations: updatedLocations
    };
    adModuleToUpdate.businessLocationData = updatedLocationData;
    updateAdModule(
      adModuleToUpdate,
      adModuleToUpdate.id === adModuleId ? undefined : adModuleId
    );
  };
  //---------------------------------------------------------------------------
  const getUpdatedLocations = (
    selectedLocations: IObjectLocation<IAdUnitText>[],
    updatedLocations: IObjectLocation<string>[]
  ): IObjectLocation<IAdUnitText>[] => {
    const result: IObjectLocation<IAdUnitText>[] = [];
    updatedLocations.forEach(location => {
      const existingLocation = selectedLocations.find(item => item.id === location.id);
      if (existingLocation) {
        //---------------------------------------------------------------------
        // Existing location updated
        const updatedLocation: IObjectLocation<IAdUnitText> = getUpdatedAddressBlockLocation(existingLocation, location);
        result.push(updatedLocation);
      } else {
        //---------------------------------------------------------------------
        // New location added
        const locationToAdd: IObjectLocation<IAdUnitText> = {
          id: location.id,
          name: {
            type: "Name",
            text: location.name,
            status: new ClassAdUnitStatus("Draft")
          },
          point: location.point,
          addressText: {
            type: "Address",
            text: location.addressText,
            status: new ClassAdUnitStatus("Draft")
          },
          status: new ClassAdUnitStatus("Draft")
        };
        result.push(locationToAdd);
      };
    });
    return result;
  };
  //--------------------------------------------------------------------------- Update contact
  const contactRefresh = (adModuleId: string, contactId: string) => {
    if (props.isReadonly) return;
    const adModuleToUpdate = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!adModuleToUpdate) return;
    if (!adModuleToUpdate.contacts) return;
    //-------------------------------------------------------------------------
    const contactToUpdate = adModuleToUpdate.contacts.find(item => item.id === contactId);
    if (!contactToUpdate) return;
    //-------------------------------------------------------------------------
    // Re-read contact data from the business
    if (!businessContext.businessState?.business) return;
    const business = businessContext.businessState?.business as ClassCustomerBusiness;
    const businessContact = business.contacts.find(contact => contact.id === contactId);
    if (!businessContact) return;
    //-------------------------------------------------------------------------
    const updatedContact = Contact.getUpdatedContact(contactToUpdate, businessContact);
    const updatedContacts = adModuleToUpdate.contacts.map(item => item.id === contactId ? updatedContact : item);
    adModuleToUpdate.contacts = updatedContacts;
    updateAdModule(
      adModuleToUpdate,
      adModuleToUpdate.id === adModuleId ? undefined : adModuleId
    );
  };
  //---------------------------------------------------------------------------
  const contactExclude = (adModuleId: string, contactId: string) => {
    if (props.isReadonly) return;
    const adModuleToUpdate = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!adModuleToUpdate?.businessLocationData) return;
    //-------------------------------------------------------------------------
    const updatedContacts = adModuleToUpdate.contacts?.filter(item => item.id !== contactId);
    adModuleToUpdate.contacts = updatedContacts;
    updateAdModule(
      adModuleToUpdate,
      adModuleToUpdate.id === adModuleId ? undefined : adModuleId
    );
  };
  //---------------------------------------------------------------------------
  const contactUpdate = (adModuleId: string, updatedContact: Contact<string>) => {
    if (props.isReadonly) return;
    const adModuleToUpdate = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!adModuleToUpdate?.contacts) return;
    //-------------------------------------------------------------------------
    const contactToUpdate = adModuleToUpdate.contacts?.find(item => item.id === updatedContact.id);
    if (!contactToUpdate) return;
    //-------------------------------------------------------------------------
    const updatedContacts = adModuleToUpdate.contacts?.map(item => item.id === updatedContact.id ?
      Contact.getUpdatedContact(contactToUpdate, updatedContact) :
      item);
    adModuleToUpdate.contacts = updatedContacts;
    updateAdModule(
      adModuleToUpdate,
      adModuleToUpdate.id === adModuleId ? undefined : adModuleId
    );
  };
  //---------------------------------------------------------------------------
  const contactsUpdate = (selectedContacts: Contact<string>[]) => {
    // User selected contact(s) from the list
    if (!contactSelectState) return;
    const existingContacts = contactSelectState.adModule.contacts ? contactSelectState.adModule.contacts : [];
    contactSelectState.adModule.contacts = getUpdatedContacts(existingContacts, selectedContacts);
    updateAdModule(contactSelectState.adModule, contactSelectState.sourceAdModuleId);
    setContactSelectState(undefined);
  };
  //---------------------------------------------------------------------------
  const getUpdatedContacts = (
    selectedContacts: IContact<IAdUnitText>[],
    updatedContacts: IContact<string>[]
  ): IContact<IAdUnitText>[] => {
    // Run through newly selected contacts and map them to the existing contacts
    const result: IContact<IAdUnitText>[] = [];
    updatedContacts.forEach(contact => {
      const existingContact = selectedContacts.find(item => item.id === contact.id);
      if (existingContact) {
        //---------------------------------------------------------------------
        // Existing contact updated
        const updatedContact: IContact<IAdUnitText> = Contact.getUpdatedContact(existingContact, contact);
        result.push(updatedContact);
      } else {
        //---------------------------------------------------------------------
        // New contact added
        const contactToAdd: IContact<IAdUnitText> = {
          id: contact.id,
          contactType: contact.contactType,
          value: {
            type: "Contact",
            text: contact.value,
            status: new ClassAdUnitStatus("Draft")
          },
          description: contact.description ? {
            type: "Description",
            text: contact.description,
            status: new ClassAdUnitStatus("Draft")
          } : undefined,
          status: new ClassAdUnitStatus("Draft")
        };
        result.push(contactToAdd);
      };
    });
    return result;
  };
  //--------------------------------------------------------------------------- Paragraph
  const onParagraphOptionSelect = (optionId: EOptionsParagraph, adModuleId: string, paragraphId: string) => {
    const adModule = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!adModule) return;
    const paragraph = adModule.paragraphs?.find(item => item.id === paragraphId);
    if (!paragraph) return;
    switch (optionId) {
      case "TextEdit":
        setTextEditState({
          adModule: adModule,
          sourceAdModuleId: adModule.id == adModuleId ? undefined : adModuleId,
          paragraphId: paragraphId,
          textType: "Text",
          text: paragraph.text?.text
        });
        break;
      case "ImageEdit":
        onParagraphImageEdit(adModuleId, paragraphId);
        break;
      case "ImageSelect":
        onParagraphImageSelect(adModuleId, paragraphId);
        break;
      case "SelectTemplate":
        setParagraphLayoutState({
          adModule: adModule,
          paragraph: paragraph
        });
        break;
      case "Delete":
        paragraphDelete(adModuleId, paragraphId);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const paragraphLayoutUpdate = (updatedParagraph: IAdModuleParagraph) => {
    if (!paragraphLayoutState) return;
    if (!props.adModules) return;
    //-------------------------------------------------------------------------
    paragraphLayoutState.adModule.paragraphs = paragraphLayoutState.adModule.paragraphs?.map(item =>
      item.id === updatedParagraph.id ? updatedParagraph : item
    );
    //-------------------------------------------------------------------------
    updateAdModule(paragraphLayoutState.adModule);
    setParagraphLayoutState(undefined);
  };
  //---------------------------------------------------------------------------
  const onParagraphImageSelect = (adModuleId: string, paragraphId: string) => {
    if (!imageManager || !imageManager.businessId) return;
    const adModule = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!adModule) return;
    const paragraph = adModule.paragraphs?.find(item => item.id === paragraphId);
    if (!paragraph || !paragraph.image) return;
    //-------------------------------------------------------------------------
    setImageEditState({
      adModule: adModule,
      paragraph: paragraph,
      imageType: "Image"
    });
    //-------------------------------------------------------------------------
    imageManager.adModuleId = adModuleId;
    imageManager.paragraphId = paragraphId;
    setIsLoading(true);
    let controller = abortController.newController("loadUserImages");
    imageManager.selectImage(
      instance,
      appAuthContext,
      paragraph.image?.imageData,
      controller.signal)
      .catch(error => {
        console.error("imageManager?.selectImage.catch:", error);
      })
      .finally(() => {
        !controller.aborted && setIsLoading(false);
        controller.remove();
      });

  };
  //---------------------------------------------------------------------------
  const onParagraphImageEdit = (adModuleId: string, paragraphId: string) => {
    if (!imageManager || !imageManager.businessId) return;
    const adModule = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!adModule) return;
    const paragraph = adModule.paragraphs?.find(item => item.id === paragraphId);
    if (!paragraph || !paragraph.image?.imageData) return;
    //-------------------------------------------------------------------------
    setImageEditState({
      adModule: adModule,
      paragraph: paragraph,
      imageType: "Image"
    });
    //-------------------------------------------------------------------------
    imageManager.adModuleId = adModuleId;
    imageManager.paragraphId = paragraphId;
    setIsLoading(true);
    let controller = abortController.newController("editImage");
    imageManager.editImage(
      paragraph.image.imageData,
      TImageUsage.newEmbedded(imageManager.businessId, adModuleId, paragraphId, true),
      controller.signal)
      .catch(error => {
        console.error(`SectionBusinessPageV2.editImage[${imageManager.imageToEdit?.id}]:`, error);
      })
      .finally(() => {
        !controller.aborted && setIsLoading(false);
        controller.remove();
      });
  };
  //---------------------------------------------------------------------------
  const paragraphAdd = (adModuleId: string) => {
    if (props.isReadonly) return;
    const adModuleToUpdate = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!adModuleToUpdate) return;
    //-------------------------------------------------------------------------
    const newParagraph: IAdModuleParagraph = {
      id: uuidv4(),
      image: {
        type: "Image",
        positionV: "Top",
        positionH: "Left",
        status: new ClassAdUnitStatus("Draft")
      },
      text: {
        type: "Text",
        status: new ClassAdUnitStatus("Draft")
      },
      status: new ClassAdUnitStatus("Draft")
    };
    adModuleToUpdate.paragraphs = adModuleToUpdate.paragraphs ? adModuleToUpdate.paragraphs.concat(newParagraph) : [newParagraph];
    updateAdModule(
      adModuleToUpdate,
      adModuleToUpdate.id === adModuleId ? undefined : adModuleId
    );
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedAdModule: IAdModule) => {
    if (props.isReadonly) return;
    updateAdModule(new ClassAdModule(updatedAdModule));
  };
  //---------------------------------------------------------------------------
  const paragraphDelete = (adModuleId: string, paragraphId: string) => {
    if (props.isReadonly) return;
    const adModuleToUpdate = adLanguageSet.getAdModuleToUpdate(adModuleId);
    if (!adModuleToUpdate) return;
    //-------------------------------------------------------------------------
    const updatedParagraphs = adModuleToUpdate.paragraphs?.filter(item => item.id !== paragraphId);
    adModuleToUpdate.paragraphs = updatedParagraphs;
    updateAdModule(
      adModuleToUpdate,
      adModuleToUpdate.id === adModuleId ? undefined : adModuleId
    );
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <WikiSpinner show={isLoading} />

      <ReviewResults
        // NOTE: Pass filtered modules?
        data={businessContext.adLanguageSetState?.adLanguageSet}
        onIssueSelect={updateSelectedItemPath}
      />

      <AdModulesList
        ui={{
          ...props.ui,
          menuContent: tmpAdModuleMenuContent,
          inputListContacts: tmpUiInputListContacts
        }}
        data={props.adModules}
        adLanguageSet={adLanguageSet}
        isReadonly={props.isReadonly}
        useExternalLayout={props.useExternalLayout}
        selectedItemPath={selectedItemPath}
        onModuleOptionSelect={onAdModuleOptionSelect}
        onLocationOptionSelect={onLocationOptionSelect}
        onLocationDataUpdate={locationDataUpdate}
        onContactOptionSelect={onContactOptionSelect}
        onContactUpdate={contactUpdate}
        onParagraphOptionSelect={onParagraphOptionSelect}
        onUpdate={onUpdate}
      />

      {adDetailsPopupOpen &&
        <AdDetailsEditor
          ui={tmpUiAdDetails}
          onClose={() => setAdDetailsPopupOpen(false)}
        />}

      {textEditState &&
        <TextEditorPopup
          data={textEditState.text}
          onUpdate={onTextUpdate}
          onClose={onTextEditorClose}
        />}

      {locationSelectState &&
        <BusinessLocationsListPopup
          ui={tmpUiBusinessLocationsListPopup}
          data={locationSelectState.adModule?.businessLocationData?.locations}
          onUpdate={locationsUpdate}
          onClose={locationsSelectCancel}
        />}

      {contactSelectState &&
        <ContactsListPopup
          ui={tmpUiContactsListPopup}
          data={Contact.createContacts(contactSelectState.adModule.contacts)}
          onUpdate={contactsUpdate}
          onClose={contactsSelectCancel}
        />}

      {paragraphLayoutState &&
        <PopupParagraphLayout
          data={paragraphLayoutState.paragraph}
          onSelect={paragraphLayoutUpdate}
          onClose={() => setParagraphLayoutState(undefined)}
        />}

      <ImageManager
        inLibrary={false}
        onManagerCreate={onImageManagerCreate}
        onImageUpdate={onImageUpdate}
      />

    </div>
  );
}