import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";
import { IAuthConfig } from "../context/app-auth-context/AppAuthContext";
import { EHttpStatusCode } from "./HttpStatusCodes";
import { IApiLogItem, LogInterceptor } from "./logging";

export interface IApiResponse {
  status: number;
  content: any;
}

interface IApiRequestProps {
  method: string;
  token?: string;//AuthenticationResult;
  contentType?: string;
  payload?: any;
  signal?: AbortSignal;
}
//---------------------------------------------------------------------------------------------
export function checkResponse(
  response: void | IApiResponse,
  funcName: string,
  aborted?: boolean,
  additionalStatuses?: EHttpStatusCode[]) {
  if (aborted === true)
    throw `API [${funcName}] was aborted`;
  if (!response)
    throw `API [${funcName}] did not respond`;
  if (response.status != EHttpStatusCode.OK && response.status != EHttpStatusCode.NoContent) {
    if (!additionalStatuses || additionalStatuses.findIndex(item => item == response.status) < 0) {
      let errorString = `API [${funcName}] response status does not indicate success: [${response.status}].`;
      if (response.content?.value)
        errorString += ` ${response.content.value}`;
      throw errorString;
    }
  }
  console.log(`checkResponse: everything is fine`);
}
//---------------------------------------------------------------------------------------------
export function checkResponse2(
  response: void | IApiResponse,
  funcName: string, { aborted, additionalStatuses }: {
    aborted?: boolean,
    additionalStatuses?: EHttpStatusCode[]
  } = {}) {
  if (aborted === true)
    throw `${funcName} API was aborted`;
  if (!response)
    throw `${funcName} API did not respond`;
  if (response.status != EHttpStatusCode.OK) {
    if (!additionalStatuses || additionalStatuses.findIndex(item => item == response.status) < 0)
      throw `${funcName} API response status does not indicate success: [${response.status}]`;
  }
}
//---------------------------------------------------------------------------------------------
export function getRequestOptions(props: IApiRequestProps): RequestInit {
  var headers = new Headers();
  //---------------------------------------------------------------------------
  let contentType: string | undefined = undefined;
  if (props.method !== "HEAD") {
    contentType = props.contentType ? props.contentType : "application/json";
    //console.log(contentType)
    if (contentType !== "image")
      headers.append("content-Type", contentType);
  }
  //---------------------------------------------------------------------------
  //if (props.token) headers.append("Authorization", `Bearer ${props.token.accessToken}`);
  if (props.token) headers.append("Authorization", `Bearer ${props.token}`);
  //---------------------------------------------------------------------------
  var options: RequestInit = {
    method: props.method,
    headers: headers,
  };
  options.mode = "cors";
  options.cache = "no-cache";
  options.redirect = "follow";
  options.referrerPolicy = "no-referrer";
  if (props.method === "POST" && props.payload) {
    if (contentType === "application/json") {
      options.body = JSON.stringify(props.payload)
    } else {
      options.body = props.payload;
    };
  };
  //---------------------------------------------------------------------------
  options.signal = props.signal ? props.signal : undefined;
  //---------------------------------------------------------------------------
  return options;
}

export function getHeadOptions(token?: AuthenticationResult): RequestInit {
  //---------------------------------------------------------------------------
  let options: RequestInit =
  {
    method: "HEAD",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer"
  };
  //---------------------------------------------------------------------------
  if (token) {
    options.headers = new Headers();
    options.headers.append("Authorization", `Bearer ${token.accessToken}`);
  }
  return options;
}
//---------------------------------------------------------------
let apiLogInterceptor: LogInterceptor | undefined;
export function SetApiLogInterceptor(value?: LogInterceptor) {
  apiLogInterceptor = value;
}
//---------------------------------------------------------------
export async function apiFetch(
  url: string,
  options: RequestInit
): Promise<IApiResponse> {
  let responseStatus: number = -1;
  let responseContent: any = undefined;
  try {
    const response = await fetch(url, options);
    responseStatus = response.status;
    if (response.status == EHttpStatusCode.NoContent)
      responseContent = undefined;
    else {
      let contentType = response.headers.get("content-type");
      if (!contentType?.startsWith("application/json")) {
        //console.log("apiFetch.response.contentType:", contentType);
        throw Error(`apiFetch.response.contentType: [${contentType}]. Must be [application/json]`);
      }
      try {
        responseContent = await response.json();
      }
      catch (error) {
        console.error("apiFetch.response.json() error:", error);
      }
    }

    return { status: responseStatus, content: responseContent };

    //return { status: response.status, content: undefined };

    //const responseContent = response.json();
    //const responseContent = await response.json();

    // let responseContent: Promise<any> | undefined;
    // try {
    //   responseContent = response.json();
    // }
    // catch {
    //   responseContent = response.text();
    // }
    // response.headers.forEach((value, key) => {
    //   console.log("Headers:", `${key}=${value}`);
    //   if (key == "content-type") {
    //     switch (value) {
    //       case "text/plain":
    //         responseContent = response.text();
    //         break;
    //     }
    //   }
    // });
    //const responseContent = response.json();
    return Promise.all([responseStatus, responseContent]).then((result) => {
      return { status: result[0], content: result[1] };
    });
  }
  catch (error) {
    console.error(`apiFetch[${url}].catch:`, error);
    throw error;
  }
  finally {
    if (apiLogInterceptor) {
      apiLogInterceptor.api(url, options, responseStatus, responseContent);
    }
  }
}

//-----------------------------------------------------------------------------
export async function apiFetchPrivate(
  instance: IPublicClientApplication,
  config: IAuthConfig | undefined | null,
  url: string,
  method: string,
  contentType?: string,
  payload?: any,
  signal?: AbortSignal
) {
  if (!config)
    throw `Auth config not provided: [${url}]`;
  const userAccount = instance.getActiveAccount();
  if (!userAccount) {
    throw `instance.getActiveAccount() returned null`;
  }
  if (userAccount && config) {
    const accessTokenRequest = {
      scopes: config.apiScopes,
      account: userAccount,
      forceRefresh: true,
      redirectUri: "/" //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#block_iframe_reload
    };
    return instance
      .acquireTokenSilent(accessTokenRequest as SilentRequest)
      .then((accessToken) => {
        //return apiFetch(url, getRequestOptions({ method: method, token: accessToken, contentType: contentType, payload: payload, signal: signal }));
        return apiFetch(url, getRequestOptions({ method: method, token: accessToken.accessToken, contentType: contentType, payload: payload, signal: signal }));
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('----abort-----')
          throw "apiFetchPrivate aborted";
        };
        //console.log(`Failed to acquire access token silently:`);
        //console.log(error);
        if (error instanceof InteractionRequiredAuthError) {
          console.log("InteractionRequiredAuthError");
          instance.acquireTokenRedirect(accessTokenRequest as RedirectRequest);
        };
      });
  }
}

//-----------------------------------------------------------------------------
export async function apiGet(url: string, signal?: AbortSignal) {
  return apiFetch(url, getRequestOptions({
    method: "GET",
    signal: signal
  }));
}
//-----------------------------------------------------------------------------
export async function apiHead(url: string) {
  let options: RequestInit = getHeadOptions();

  const response = await fetch(url, options);
  const responseStatus = response.status;
  const responseHeaders = response.headers;
  return Promise.all([responseStatus, responseHeaders]).then((result) => {
    return { status: result[0], headers: result[1] };
  });

  return apiFetch(url, getRequestOptions({ method: "HEAD" }));
}

//-----------------------------------------------------------------------------
export async function apiPost(url: string, data?: any, contentType?: string) {
  return apiFetch(url, getRequestOptions({
    method: "POST",
    payload: data,
    contentType: contentType
  }));
}

//-----------------------------------------------------------------------------
export async function apiGetPrivate(
  instance: IPublicClientApplication,
  config: IAuthConfig | undefined | null,
  url: string,
  signal?: AbortSignal
) {
  return apiFetchPrivate(instance, config, url, "GET", undefined, undefined, signal);
}

//-----------------------------------------------------------------------------
export async function apiPostPrivate(
  instance: IPublicClientApplication,
  config: IAuthConfig | undefined | null,
  url: string,
  data?: any,
  contentType?: string,
  signal?: AbortSignal
) {
  //console.log(data)
  return apiFetchPrivate(instance, config, url, "POST", contentType, data, signal);
}

//-----------------------------------------------------------------------------
export async function apiDeletePrivate(
  instance: IPublicClientApplication,
  config: IAuthConfig | undefined | null,
  url: string,
  data?: any,
  contentType?: string
) {
  return apiFetchPrivate(instance, config, url, "DELETE", contentType, data);
}

//-----------------------------------------------------------------------------
export async function apiPatchPrivate(
  instance: IPublicClientApplication,
  config: IAuthConfig | undefined | null,
  url: string,
  data?: any,
  contentType?: string
) {
  return apiFetchPrivate(instance, config, url, "PATCH", contentType, data);
}