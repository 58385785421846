import { useMsal } from '@azure/msal-react';
import { useContext, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import { ScreenType, useAppScreenContext } from '../../../../../context/app-screen-context/AppScreenProvider';
import useNavigateWithContext from '../../../../../hooks/useNavigateWithContext';
import { apiPostPrivate } from '../../../../../utils/api';
import { apiBasePath, getStaffUserByEmail } from '../../../../../utils/apiPathConstant';
import CombineStyles from '../../../../../utils/combineStyles';
import { stringFormatter } from '../../../../../utils/common';
import WikiSpinner from '../../../../app-layout/spinner/wikiSpinner';
import DialogOption from '../../../../common/dialog/dialog-option/DialogOption';
import DialogOptions from '../../../../common/dialog/dialog-options/DialogOptions';
import InputText from '../../../../common/input/input-text/InputText';
import { TUiInputText } from '../../../../common/input/input-text/TUiInputText';
import PopupEditor from '../../../../common/popup-editor/PopupEditor';
import { pathAdminConsole, pathAdministratorConsole } from '../../../AdminConsole';
import { pathUserList } from '../../AdministratorConsole';
import { TStaffUser } from '../../user-edit-form/TStaffUser';
import styles from './NewUserPopup.module.css';
import PopupDialog from '../../../../common/popup-v2/popup-dialog/PopupDialog';

const emailInput: TUiInputText = {
  id: "NewUserEmailInput",
  directoryId: "",
  elementType: "",
  disabled: false,
  visible: true,
  caption: { en: "User Email" },
  hint: undefined,
  placeholder: { en: "Type or paste email here..." },
  defaultValue: undefined,
  validation: undefined
};
const cancelOption = {
  action: null,
  canHideCaption: false,
  caption: { en: "Cancel" },
  directoryId: "dirUiCommon",
  disabled: false,
  elementType: "Button",
  hint: { en: "Close and cancel changes" },
  iconFile: "close",
  id: "CloseButton",
  index: 1,
  isDefault: false,
  priorityLevel: 0,
  visible: true,
};
const okOption = {
  action: null,
  canHideCaption: false,
  caption: { en: "Ok" },
  directoryId: "dirUiCommon",
  disabled: false,
  elementType: "Button",
  hint: { en: "Ok" },
  iconFile: "check",
  id: "AssignRole",
  index: 1,
  isDefault: false,
  priorityLevel: 0,
  visible: true,
};

interface IProps {
  onClose: () => void;
}

export default function NewUserPopup(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const screenType = useAppScreenContext();
  const navigate = useNavigateWithContext();
  const [email, setEmail] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  //---------------------------------------------------------------------------
  const getUser = () => {
    if (appAuthContext.config && appAuthContext.user && email) {
      setIsLoading(true);
      apiPostPrivate(
        instance,
        appAuthContext.config,
        `${apiBasePath}${stringFormatter(getStaffUserByEmail, [email])}`
      ).then(response => {
        if (response && response.status === 200) {
          //-------------------------------------------------------------------
          // User with specified Id is found
          const newStaffUser = response.content as TStaffUser;
          navigate(
            generatePath(`/${pathAdminConsole}/${pathAdministratorConsole}/${pathUserList}/:requestedUserId`, {
              requestedUserId: newStaffUser.userIdentity.id,
            })
          );
          props.onClose();
        } else if (response && response.status == 404) {
          //-------------------------------------------------------------------
          // User with specified Id not found
          alert(`User with email [${email}] is not found in AAD B2C, please check if the user has completed registration process`);
        } else {
          throw response ? response.status : "No adequate response from getStaffUserByEmail API";
        }
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        setIsLoading(false);
      });
    } else {
      console.error("Cannot add new user because auth context is not set")
    };
  };
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id='NewUserPopupEditor'
      header={"Administrator: New User"}
      isDraggable={false}
      isModal={true}
      onClose={props.onClose}
    >
      <WikiSpinner show={isLoading} />
      <div className={CombineStyles([styles.container, screenType == ScreenType.Mobile ? styles.mobile : ""])}>
        <InputText
          ui={emailInput}
          onUpdate={(updatedValue: string) => setEmail(updatedValue)}
        />
        <DialogOptions>
          <DialogOption
            ui={cancelOption}
            clickHandler={props.onClose}
          />
          <DialogOption
            ui={{
              ...okOption,
              disabled: !email,
              isDefault: true
            }}
            clickHandler={getUser}
          />
        </DialogOptions>
      </div>
    </PopupDialog>
  );
}