import { useContext, useEffect, useState } from "react";
import { ClassImageEditorContext, ImageEditorContext, TImageEditorDispatch } from "../image-editor-context/ImageEditorContextProvider";
import { CreateSessionMethod, ImageEditorSession } from "../image-editor-context/ImageEditorSession";
import { TImageData } from "../TImageData";
import { TImageUsage } from "../TImageUsage";
import { EImageEditorContextUpdate } from "../image-editor-context/ImageEditorContextReducer";
import { IPublicClientApplication } from "@azure/msal-browser/dist/app/IPublicClientApplication";
import { IAppAuthContext } from "../../../../context/app-auth-context/AppAuthContext";
import { TImageLibraryItem } from "../../../user-console/user-images/image-library/TImageLibrary";
//----------------------------------------------------------------------
type ETargetImageOperation = 'SelectLocalImage' | 'SelectImage' | 'EditImage';
//----------------------------------------------------------------------
export interface IImageManager {
  editorOpen: boolean;
  libraryOpen: boolean;
  localSelectorOpen: boolean;
  targetOperation?: ETargetImageOperation;
  businessId?: string;
  adModuleId?: string;
  paragraphId?: string;
  oldImage?: TImageData;
  imageToEdit?: TImageData;
  session?: ImageEditorSession;
  imageUsage?: TImageUsage;
}
//----------------------------------------------------------------------
interface IFilePack {
  instance?: IPublicClientApplication;
  appAuthContext?: IAppAuthContext;
  oldImage?: TImageData;
  abortSignal?: AbortSignal;
}
//----------------------------------------------------------------------
export class ClassImageManager implements IImageManager {
  editorOpen: boolean = false;
  libraryOpen: boolean = false;
  localSelectorOpen: boolean = false;
  targetOperation?: ETargetImageOperation;
  businessId?: string;
  adLanguageSetId?: string;
  adModuleId?: string;
  paragraphId?: string;
  oldImage?: TImageData;
  imageToEdit?: TImageData;
  removedImage?: TImageData;
  session?: ImageEditorSession;
  input?: HTMLInputElement;
  inLibrary: boolean = false;
  filePack?: IFilePack;
  createSessionMethod?: CreateSessionMethod;
  static context?: ClassImageEditorContext;
  static dispatch?: TImageEditorDispatch;
  //-------------------------------------------------------------------------------
  constructor(source?: IImageManager) {
    source && Object.assign(this, source);
  }
  //-------------------------------------------------------------------------------
  static getDefault(): IImageManagerWrapper {
    return { value: new ClassImageManager() };
  }
  //-------------------------------------------------------------------------------
  private closeAllDialogs() {
    this.editorOpen = false;
    this.libraryOpen = false;
    this.localSelectorOpen = false;
  }
  //-------------------------------------------------------------------------------
  async editImage(source: TImageData, usage: TImageUsage, abortSignal?: AbortSignal) {
    this.targetOperation = 'EditImage';
    this.closeAllDialogs();
    this.setImageToEdit(source, usage);
    return this.getEditSessionAsync(abortSignal);
  }
  //-------------------------------------------------------------------------------
  async selectImage(
    instance: IPublicClientApplication,
    appAuthContext: IAppAuthContext,
    oldImage?: TImageData,
    abortSignal?: AbortSignal) {
    return new Promise<TImageLibraryItem[]>((resolve, reject) => {
      ClassImageManager.context?.loadUserImages(instance, appAuthContext.config, abortSignal)
        .then(images => {
          let noImages = !images || images.length == 0;
          if (noImages || (images.length == 1 && oldImage?.id == images[0].id)) {
            this.targetOperation = 'SelectLocalImage';
            if (this.input) {
              this.filePack = {
                instance: instance,
                appAuthContext: appAuthContext,
                oldImage: oldImage,
                abortSignal: abortSignal
              }
              this.input.value = '';
              this.input.click();
              return resolve(images);
            }
          }
          else
            this.targetOperation = 'SelectImage';
          this.closeAllDialogs();
          this.oldImage = oldImage;
          this.imageToEdit = undefined;
          //-------------------------------------------------------------------------
          // this will trigger effect[imageEditorContext]
          ClassImageManager.dispatch?.({ type: "UserImagesLoaded", images: images });
          resolve(images);
        })
        .catch(error => {
          reject(error);
        })
    });
  }
  //-------------------------------------------------------------------------------
  async addNewImage(file: File) {
    //this.targetOperation = 'EditImage';
    this.closeAllDialogs();
    this.setImageToEdit(TImageData.fromFile(file));
    return this.getEditSessionAsync(this.filePack?.abortSignal);
  }
  //-------------------------------------------------------------------------------
  async uploadNewImage(image: TImageData) {
    return ClassImageManager.context?.uploadAllImagesForAsync(
      this.objectIds[0], 
      this.filePack?.appAuthContext, 
      this.filePack?.instance, false);
  }
  //-------------------------------------------------------------------------------
  private get objectIds(): string[] {
    let objectIds: string[] = [];
    if (this.businessId)
      objectIds.push(this.businessId);
    if (this.adLanguageSetId)
      objectIds.push(this.adLanguageSetId);
    if (this.adModuleId)
      objectIds.push(this.adModuleId);
    if (this.paragraphId)
      objectIds.push(this.paragraphId);
    return objectIds;
  }
  //-------------------------------------------------------------------------------
  setImageToEdit(source: TImageData, usage?: TImageUsage) {
    this.oldImage = source;
    if (source.file) {
      this.imageToEdit = source;
      this.createSessionMethod = ImageEditorSession.createForLocalImage;
    }
    else if (source.isUsedElsewhere(usage) || source.imageStatus != "Draft") {
      this.imageToEdit = new TImageData(source);
      this.createSessionMethod = ImageEditorSession.createForImageCopy;
    }
    else {
      this.imageToEdit = source;
      this.createSessionMethod = ImageEditorSession.createForImageEdit;
    }
  }
  //-------------------------------------------------------------------------------
  getEditSession(imageEditorContext: ClassImageEditorContext) {
    let session = imageEditorContext.getSessionByImageId(this.imageToEdit?.id);
    if (session) {
      session.objectIds = this.objectIds;
      session.canLoadNewImage = false;
      this.session = session;
      this.imageToEdit = session.imageData;
    }
    return session;
  }
  //-------------------------------------------------------------------------------
  async createEditSession(inLibrary: boolean, abortSignal?: AbortSignal): Promise<ImageEditorSession> {
    return new Promise<ImageEditorSession>((resolve, reject) => {
      if (!this.createSessionMethod)
        return reject(new Error("CreateSessionMethod is undefined"));
      if (!this.imageToEdit)
        return reject(new Error("ImageToEdit is undefined"));
      if (!this.oldImage)
        return reject(new Error("OldImage is undefined"));
      this.createSessionMethod(this.imageToEdit, inLibrary, this.oldImage, abortSignal)
        .then(session => {
          session.objectIds = this.objectIds;
          session.canLoadNewImage = false;
          this.session = session;
          this.imageToEdit = session.imageData;
          resolve(session);
        })
        .catch(error =>
          reject(error)
        );
    })
  }
  //-------------------------------------------------------------------------------
  private setSessionParams(session: ImageEditorSession) {
    session.objectIds = this.objectIds;
    session.canLoadNewImage = false;
    this.session = session;
    this.imageToEdit = session.imageData;
    return session;
  }
  //-------------------------------------------------------------------------------
  private async getEditSessionAsync(abortSignal?: AbortSignal): Promise<ImageEditorSession> {
    let session = ClassImageManager.context?.getSessionByImageId(this.imageToEdit?.id);
    if (session) {
      this.setSessionParams(session);
      ClassImageManager.dispatch?.({ type: "ActivateSession", session: session });
      return session;
    }
    return new Promise<ImageEditorSession>((resolve, reject) => {
      if (!this.createSessionMethod)
        return reject(new Error("CreateSessionMethod is undefined"));
      if (!this.imageToEdit)
        return reject(new Error("ImageToEdit is undefined"));
      if (!this.oldImage)
        return reject(new Error("OldImage is undefined"));
      this.createSessionMethod(this.imageToEdit, this.inLibrary, this.oldImage, abortSignal)
        .then(session => {
          this.setSessionParams(session);
          session.addToContext(ClassImageManager.dispatch);
          resolve(session);
        })
        .catch(error =>
          reject(error)
        );
    })
  }
  //----------------------------------------------
  tryOpenEditorOrLibrary(imageEditorContext: ClassImageEditorContext): boolean {
    switch (this.targetOperation) {
      //------------------------------------------------
      case 'SelectImage': {
        if (imageEditorContext.lastAction && imageEditorContext.lastAction.type == "UserImagesLoaded") {
          this.libraryOpen = true;
          return true;
        }
      } break;
      //------------------------------------------------
      case 'EditImage': 
      case 'SelectLocalImage': {
        //--respond only on our session--------------------------------------------
        if (this.imageToEdit && imageEditorContext.lastChangedSession && imageEditorContext.lastChangedSession.imageId == this.imageToEdit.id)
          //--respond only on session added or activated-----------------------------
          switch (imageEditorContext.lastChangedSessionUpdate) {
            case EImageEditorContextUpdate.SessionAdded:
            case EImageEditorContextUpdate.SessionActivated:
              this.editorOpen = true;
              return true;
          }
      } break;
    }
    return false;
  }
}
//----------------------------------------------------------------------
export interface IImageManagerWrapper {
  value: ClassImageManager;
}
//----------------------------------------------------------------------
export function useImageEditState(inLibrary: boolean): [ClassImageManager, () => void, () => void] {
  //------------------------------------------------------------------------------
  const { imageEditorContext, imageEditorDispatch } = useContext(ImageEditorContext);
  const [stateWrapper, setStateWrapper] = useState(ClassImageManager.getDefault);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (stateWrapper.value) {
      stateWrapper.value.inLibrary = inLibrary;
    }
  }, [stateWrapper])
  //---------------------------------------------------------------------------
  useEffect(() => {
    ClassImageManager.context = imageEditorContext;
    ClassImageManager.dispatch = imageEditorDispatch;
    if (stateWrapper.value.tryOpenEditorOrLibrary(imageEditorContext))
      setState();
  }, [imageEditorContext]);
  //------------------------------------------------------------------------------
  function setState() {
    setStateWrapper({ ...stateWrapper });
  }
  //------------------------------------------------------------------------------
  function resetState() {
    setStateWrapper(ClassImageManager.getDefault());
  }
  //------------------------------------------------------------------------------
  return [stateWrapper.value, setState, resetState];
}
