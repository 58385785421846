import { Icon } from "@iconify/react";
import styles from './ListIcon.module.css';
import CombineStyles from "../../../../utils/combineStyles";

interface IProps {
  disabled?: boolean;
  onSelect: () => void;
}

export default function OptionMoveDown(props: IProps) {
  const disabled = props.disabled == undefined ? false : props.disabled;
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (!disabled) {
      props.onSelect();
    };
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        disabled ? styles.disabled : ""
      ])}
      onClick={onSelect}
    >
      <Icon
        icon="fa:arrow-down"
        height="1.2em"
      />
    </div>
  );
}