import { TImageData } from "../../../../common/image-editor/TImageData";

export interface IBusinessListItem {
  id: string;
  logo?: any;
  name?: string;
  userRoles: string[];
}

export class BusinessListItem implements IBusinessListItem {
  id: string = "";
  name: string = "";
  userRoles: string[] = [];
  activeAdCampaignName: string = "";
  adCampaignsCount: number = 0;
  //---------------------------------------------------------------------------------------------
  private _logo?: TImageData;
  get logo(): TImageData | undefined {
    return this._logo;
  }
  set logo(value: any) {
    if (value) {
      this._logo = new TImageData(value);
    }
    else {
      this._logo = undefined;
    }
  }
  //---------------------------------------------------------------------------------------------
  constructor(source?: any, spread?: any) {
    if (source || spread) {
      Object.assign(this, source, spread);
    }
  }
  /*
  static fromSource(source: any): TBusinessListCard {
    let result = new TBusinessListCard();
    Object.assign(result, source);
    if (source.logo)
      result.logo = new TImageData(source.logo); //TImageData.fromInterface(result.logo);
    return result;
  }
  */
  //---------------------------------------------------------------------------------------------
  static fromArray(source: any): BusinessListItem[] {
    let result: BusinessListItem[] = [];
    source?.forEach((item: IBusinessListItem) => result.push(new BusinessListItem(item)));
    return result;
  }
};