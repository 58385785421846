import { useContext, useEffect, useState } from "react";
import FormOptions from "../../../common/form-options-bar/FormOptions";
import InputDropdown from "../../../common/input-v2/input-dropdown/InputDropdown";
import { getTUiMenuContent } from "../../../common/menu/menu-content/TUiMenuContent";
import { IUiOption } from "../../../common/options/IUiOption";
import { IUiBusinessWizard } from "../IUiBusinessWizardV2";
import { WizardState } from "../IWizardState";
import WizardStepWrapper from "../step-wrapper/WizardStepWrapper";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import GetDescription from "../../../common/functions/GetDescription";

interface IProps {
  state: WizardState;
  ui: IUiBusinessWizard;
  onUpdate: (state: WizardState) => void;
  onExit: () => void;
}

export default function StepAdRank(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [adRank, setAdRank] = useState<string>();
  const stepUi = props.ui.stepsDictionary.find(item => item.id == props.state.selectedStep);
  const stepData = props.state.steps.find(item => item.stepId == props.state.selectedStep);
  const prompt = GetDescription(appUiContext, props.state.selectedStep, stepUi?.description);
  const isUpdated = adRank !== props.state.data.adRankId;
  const stepIsCompleted = stepData?.isCompleted ? true : false;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!props.state.data.adRankId) {
      if (!appUiContext.dictionaries?.adRanks) {
        throw new Error("StepAdRank: adRanks dictionary is not provided");
      };
      const defaultAdRank = appUiContext.dictionaries?.adRanks[0].id;
      setAdRank(defaultAdRank);
    } else {
      setAdRank(props.state.data.adRankId);
    };
  }, [props.state.data.adRankId]);
  //---------------------------------------------------------------------------
  const onUpdate = (optionId?: string) => {
    if (!optionId) return;
    setAdRank(optionId);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    if (!adRank) return;
    let updatedState: WizardState;
    switch (optionId) {
      case "OptionBack":
        updatedState = isUpdated ?
          props.state.updateAdRank(adRank, "Back") :
          props.state.moveToPreviousStep();
        break;
      case "OptionNext":
        updatedState = isUpdated ?
          props.state.updateAdRank(adRank, "Next") :
          props.state.moveToNextStep(!stepIsCompleted);
        break;
      default:
        throw new Error(`StepAdRank: Unknown optionId ${optionId}`);
    };
    props.onUpdate(updatedState);
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionBack":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowLeft",
          isDisabled: false
        });
        break;
      case "OptionNext":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowRight",
          isDisabled: !adRank,
          isDefault: !!adRank
        });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      alignLeft={true}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <WizardStepWrapper
      ui={props.ui}
      promptString={prompt}
      optionsElement={formOptions}
      onExit={props.onExit}
    >
      <InputDropdown
        ui={{ ...props.ui.inputAdRank, hideCaption: true }}
        data={adRank}
        options={appUiContext.dictionaries?.adRanks}
        onUpdate={onUpdate}
      />
    </WizardStepWrapper>
  );
}