import InputsGroupWrapper from '../../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import InputText, { IUiInputText } from '../../../../common/input-v2/input-text/InputText';
import { TUiInputDropdown } from '../../../../common/input/input-dropdown/TUiInputDropdown';
import DictionaryNodeEditFormWrapper from '../../../common/dictionary-node-edit-form-wrapper/DictionaryNodeEditFormWrapper';
import TranslationEditor from '../../dictionaries/translation-editor/TranslationEditor';
import { ITranslation, Translation } from '../../dictionaries/DictionaryTranslationDraftItem';
import { useEffect, useState } from 'react';
import { DraftItemStateEnum } from '../../../common/dictionary-draft-models/DraftItemStateEnum';
import { IValidationTranslation, IWidgetTranslation } from '../widget-directory-translator/IWidgetTranslation';
import { EditFormState } from '../../../../common/edit-form/IEditFormState';
import { IWidgetTranslationDraft, IWidgetTranslationDraftSpread } from '../widget-directory-translator/DirectoryTranslatorState';

export interface IUiDirectoryTranslationDraftNodeEditForm {
  inputId: IUiInputText;
  inputType: IUiInputText;
  inputLocale: TUiInputDropdown;
}
const tmpUi: IUiDirectoryTranslationDraftNodeEditForm = {
  inputId: {
    id: "DirectoryNodeIdInput",
    directoryId: '',
    elementType: "",
    disabled: true,
    visible: true,
    caption: { en: "Id" },
    placeholder: null,
    defaultValue: undefined,
    hint: { en: "System Id visible to administrators and system translators" },
    validation: undefined
  },
  inputType: {
    id: "",
    elementType: "",
    directoryId: "",
    caption: { en: "Type" },
    hint: undefined,
    defaultValue: "",
    placeholder: "",
    validation: undefined
  },
  inputLocale: {
    id: "",
    directoryId: "",
    caption: { en: "Source Locale" },
    hint: undefined,
    availableValues: [{
      id: "i18n",
      name: { en: "System Id" },
      selected: false
    }, {
      id: "en",
      name: { en: "English" },
      selected: false
    }, {
      id: "uk",
      name: { en: "Ukrainian" },
      selected: false
    }, {
      id: "ru",
      name: { en: "Russian" },
      selected: false
    }],
    defaultValue: "",
    placeholder: "",
    multiSelect: false,
    validation: { required: { value: 1, errorMessageId: "", errorMessage: "" } }
  }
}

export class WidgetTranslationDraft implements IWidgetTranslationDraft {
  id!: string;
  elementType!: string;
  draftState!: DraftItemStateEnum;
  publishedVersion!: IWidgetTranslation;
  //-----------------------------------
  caption?: ITranslation;
  hint?: ITranslation;
  placeholder?: ITranslation;
  validation?: IValidationTranslation;
  //---------------------------------------------------------------------------
  constructor(source?: IWidgetTranslationDraft, spread?: IWidgetTranslationDraftSpread) {
    Object.assign(this, source, spread);
    this.setDraftState();
  };
  //---------------------------------------------------------------------------
  setDraftState() {
    let noTranslation = false;
    let noChanges = false;
    let fallbackIsAccepted = false;
    //-------------------------------------------------------------------------
    if (this.caption) {
      if (Translation.NoTranslation(this.caption))
        noTranslation = true;
      if (Translation.TranslationsAreEqual(this.caption, this.publishedVersion?.caption))
        noChanges = true;
      if (this.caption.fallbackAccepted)
        fallbackIsAccepted = true;
    };
    //-------------------------------------------------------------------------
    if (this.hint) {
      if (Translation.NoTranslation(this.hint))
        noTranslation = true;
      if (Translation.TranslationsAreEqual(this.hint, this.publishedVersion?.hint))
        noChanges = true;
      if (this.hint.fallbackAccepted)
        fallbackIsAccepted = true;
    };
    //-------------------------------------------------------------------------
    if (this.placeholder) {
      if (Translation.NoTranslation(this.placeholder))
        noTranslation = true;
      if (Translation.TranslationsAreEqual(this.placeholder, this.publishedVersion?.placeholder))
        noChanges = true;
      if (this.placeholder.fallbackAccepted)
        fallbackIsAccepted = true;
    };
    //-------------------------------------------------------------------------
    if (this.validation) {

    };
    //-------------------------------------------------------------------------
    if (noTranslation)
      this.draftState |= DraftItemStateEnum.IsNoTranslation;
    else
      this.draftState &= ~DraftItemStateEnum.IsNoTranslation;
    if (!noChanges)
      this.draftState |= DraftItemStateEnum.IsTranslationEdited;
    else
      this.draftState &= ~DraftItemStateEnum.IsTranslationEdited;
    if (fallbackIsAccepted)
      this.draftState |= DraftItemStateEnum.IsFallbackAccepted;
    else
      this.draftState &= ~DraftItemStateEnum.IsFallbackAccepted;
  };
}

export interface IWidgetEditFormState extends EditFormState<WidgetTranslationDraft> {
}

export class WidgetEditFormState implements IWidgetEditFormState {
  object?: WidgetTranslationDraft;
  initialState?: WidgetTranslationDraft;
  isNew!: boolean; // We don't use it in this case, because no one can add new items to UI directory
  isUpdated!: boolean;
  isValid!: boolean;
  //---------------------------------------------------------------------------
  constructor(
    source: IWidgetEditFormState,
    updatedObject?: IWidgetTranslationDraft
  ) {
    //-------------------------------------------------------------------------
    // Set up data
    if (updatedObject) {
      Object.assign(this, source, { object: updatedObject });
    } else {
      // Data initialization
      Object.assign(this, source);
      this.initialState = source.object;
    };
    //-------------------------------------------------------------------------
    updatedObject && this.checkChanges();
    this.validate();
  };
  //---------------------------------------------------------------------------
  checkChanges() {
    console.log("checking changes", this.object, this.initialState, JSON.stringify(this.object) !== JSON.stringify(this.initialState))
    // Check if data is updated in session (compare with initial state)
    this.isUpdated = JSON.stringify(this.object) !== JSON.stringify(this.initialState);
  };
  //---------------------------------------------------------------------------
  validate() {
    this.isValid = true;
  };
}

interface IProps {
  draftLocale: string;
  sourceLocale?: string;
  data: IWidgetTranslationDraft;
  onUpdate: (updatedWidget: IWidgetTranslationDraft) => void;
  onClose: () => void;
}

export default function WidgetEditForm(props: IProps) {
  const [state, setState] = useState<WidgetEditFormState>();
  //---------------------------------------------------------------------------
  useEffect(() => {
    const newState = new WidgetEditFormState({
      object: new WidgetTranslationDraft(props.data),
      isNew: false,
      isUpdated: false,
      isValid: false
    });
    setState(newState);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const getSourceValue = (id: string, textProperty: any) => {
    let result = undefined;
    if (props.sourceLocale) {
      if (props.sourceLocale == "i18n") {
        result = id;
      } else if (textProperty) {
        result = textProperty[props.sourceLocale];
      }
    };
    return result;
  };
  //---------------------------------------------------------------------------
  const onCaptionUpdate = (updatedValue?: ITranslation) => {
    if (state) {
      const updatedState = new WidgetEditFormState(
        state,
        new WidgetTranslationDraft(
          state.object,
          { caption: updatedValue }));
      setState(updatedState);
    } else console.error("Widget caption is updated, but edit form state is not set");
  };
  //---------------------------------------------------------------------------
  const onHintUpdate = (updatedValue?: ITranslation) => {
    if (state) {
      const updatedState = new WidgetEditFormState(
        state,
        new WidgetTranslationDraft(
          state.object,
          { hint: updatedValue }));
      setState(updatedState);
    } else console.error("Widget hint is updated, but edit form state is not set");
  };
  //---------------------------------------------------------------------------
  const onPlaceholderUpdate = (updatedValue: ITranslation) => {
    if (state) {
      const updatedState = new WidgetEditFormState(
        state,
        new WidgetTranslationDraft(
          state.object,
          { placeholder: updatedValue }));
      setState(updatedState);
    } else console.error("Widget placeholder is updated, but edit form state is not set");
  };
  //---------------------------------------------------------------------------
  const onValidationRequiredMessageUpdate = (updatedValue: ITranslation) => {
    if (state) {
      const updatedValidation: IValidationTranslation = {
        ...state.object?.validation,
        required: {
          message: updatedValue
        }
      }
      const updatedState = new WidgetEditFormState(
        state,
        new WidgetTranslationDraft(
          state.object,
          { validation: updatedValidation }));
      setState(updatedState);
    } else console.error("Widget validation/required/message is updated, but edit form state is not set");
  };
  //---------------------------------------------------------------------------
  const onValidationRegexMatchMessageUpdate = (updatedValue: ITranslation) => {
    if (state) {
      const updatedValidation: IValidationTranslation = {
        ...state.object?.validation,
        regexMatch: {
          message: updatedValue
        }
      }
      const updatedState = new WidgetEditFormState(
        state,
        new WidgetTranslationDraft(
          state.object,
          { validation: updatedValidation }));
      setState(updatedState);
    } else console.error("Widget validation/regexMatch/message is updated, but edit form state is not set");
  };
  //---------------------------------------------------------------------------
  const onValidationUniqueMessageUpdate = (updatedValue: ITranslation) => {
    if (state) {
      const updatedValidation: IValidationTranslation = {
        ...state.object?.validation,
        unique: {
          message: updatedValue
        }
      }
      const updatedState = new WidgetEditFormState(
        state,
        new WidgetTranslationDraft(
          state.object,
          { validation: updatedValidation }));
      setState(updatedState);
    } else console.error("Widget validation/unique/message is updated, but edit form state is not set");
  };
  //---------------------------------------------------------------------------
  const onConfirmChanges = () => {
    if (state?.object) {
      props.onUpdate(state?.object);
    } else console.error("Widget translation changes are confirmed but edit form state is not set");
  };
  //---------------------------------------------------------------------------
  return (
    <DictionaryNodeEditFormWrapper
      isUpdated={!!state?.isUpdated}
      isValid={!!state?.isValid}
      onConfirm={onConfirmChanges}
      onClose={props.onClose}
    >
      <InputsGroupWrapper>

        <InputText
          ui={{ ...tmpUi.inputType, readonly: true, disabled: true, hideHint: true }}
          data={props.data?.elementType ? props.data.elementType : ""}
        />
        <InputText
          ui={{ ...tmpUi.inputId, readonly: true, disabled: true, hideHint: true }}
          data={props.data?.id ? props.data.id : ""}
        />

        {props.data.caption &&
          <TranslationEditor
            ui={{
              id: "InputCaption",
              caption: { en: "Caption" },
              inputLocale: tmpUi.inputLocale
            }}
            data={{
              elementId: props.data.id,
              elementPart: "Caption",
              draftLocale: props.draftLocale,
              draftValue: state?.object?.caption?.value, //props.data?.caption?.value,
              draftFallbackAccepted: !!state?.object?.caption?.fallbackAccepted,
              sourceLocale: props.sourceLocale,
              sourceValue: getSourceValue(props.data.id, props.data.publishedVersion?.caption),
              publishedTranslations: props.data.publishedVersion?.caption
            }}
            onUpdate={onCaptionUpdate}
          />}

        {props.data.hint &&
          <TranslationEditor
            ui={{
              id: "InputHint",
              caption: { en: "Hint" },
              inputLocale: tmpUi.inputLocale
            }}
            data={{
              elementId: props.data.id,
              elementPart: "Hint",
              draftLocale: props.draftLocale,
              draftValue: state?.object?.hint?.value,
              draftFallbackAccepted: !!state?.object?.hint?.fallbackAccepted,
              sourceLocale: props.sourceLocale,
              sourceValue: getSourceValue(props.data.id, props.data.publishedVersion?.hint),
              publishedTranslations: props.data.publishedVersion?.hint
            }}
            onUpdate={onHintUpdate}
          />}

        {props.data.placeholder &&
          <TranslationEditor
            ui={{
              id: "InputPlaceholder",
              caption: { en: "Placeholder" },
              inputLocale: tmpUi.inputLocale
            }}
            data={{
              elementId: props.data.id,
              elementPart: "Placeholder",
              draftLocale: props.draftLocale,
              draftValue: state?.object?.placeholder?.value,
              draftFallbackAccepted: !!state?.object?.placeholder?.fallbackAccepted,
              sourceLocale: props.sourceLocale,
              sourceValue: getSourceValue(props.data.id, props.data.publishedVersion?.placeholder),
              publishedTranslations: props.data.publishedVersion?.placeholder
            }}
            onUpdate={onPlaceholderUpdate}
          />}

        {props.data.validation?.required &&
          <TranslationEditor
            ui={{
              id: "InputValidationMessageRequired",
              caption: { en: "Validation.Required.Message" },
              inputLocale: tmpUi.inputLocale
            }}
            data={{
              elementId: props.data.id,
              elementPart: "Validation.Required.Message",
              draftLocale: props.draftLocale,
              draftValue: state?.object?.validation?.required?.message.value,
              draftFallbackAccepted: !!state?.object?.validation?.required?.message.fallbackAccepted,
              sourceLocale: props.sourceLocale,
              sourceValue: getSourceValue(props.data.id, props.data.publishedVersion?.validation?.required?.message),
              publishedTranslations: props.data.publishedVersion?.validation?.required?.message
            }}
            onUpdate={onValidationRequiredMessageUpdate}
          />}

        {props.data.validation?.regexMatch &&
          <TranslationEditor
            ui={{
              id: "InputValidationMessageRegexMatch",
              caption: { en: "Validation.RegexMatch.Message" },
              inputLocale: tmpUi.inputLocale
            }}
            data={{
              elementId: props.data.id,
              elementPart: "Validation.RegexMatch.Message",
              draftLocale: props.draftLocale,
              draftValue: state?.object?.validation?.regexMatch?.message.value,
              draftFallbackAccepted: !!state?.object?.validation?.regexMatch?.message.fallbackAccepted,
              sourceLocale: props.sourceLocale,
              sourceValue: getSourceValue(props.data.id, props.data.publishedVersion?.validation?.regexMatch?.message),
              publishedTranslations: props.data.publishedVersion?.validation?.regexMatch?.message
            }}
            onUpdate={onValidationRegexMatchMessageUpdate}
          />}

        {props.data.validation?.unique &&
          <TranslationEditor
            ui={{
              id: "InputValidationMessageUnique",
              caption: { en: "Validation.Unique.Message" },
              inputLocale: tmpUi.inputLocale
            }}
            data={{
              elementId: props.data.id,
              elementPart: "Validation.Unique.Message",
              draftLocale: props.draftLocale,
              draftValue: state?.object?.validation?.unique?.message.value,
              draftFallbackAccepted: !!state?.object?.validation?.unique?.message.fallbackAccepted,
              sourceLocale: props.sourceLocale,
              sourceValue: getSourceValue(props.data.id, props.data.publishedVersion?.validation?.unique?.message),
              publishedTranslations: props.data.publishedVersion?.validation?.unique?.message
            }}
            onUpdate={onValidationUniqueMessageUpdate}
          />}

      </InputsGroupWrapper>
    </DictionaryNodeEditFormWrapper>
  );
}