import { useContext, useEffect, useState } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { WizardState } from '../IWizardState';
import { IUiBusinessWizard } from '../IUiBusinessWizardV2';
import GetDescription from '../../../common/functions/GetDescription';
import WizardStepWrapper from '../step-wrapper/WizardStepWrapper';
import { IUiOption } from '../../../common/options/IUiOption';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import { getNewBusinessWithAdAsync } from '../functions/getNewBusinessWithAdAsync';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import { useMsal } from '@azure/msal-react';
import { useAbortController } from '../../../../hooks/useAbortController';
import { AdLanguageSet } from '../../../ad-content-editor/IAdLanguageSet';
import { ClassAdModulePublished } from '../../../homepage/ClassAdLanguageSetPublished';
import AdModule from '../../../common/ad-modules/AdModule';
import styles from './StepPreview.module.css';

interface IProps {
  state: WizardState;
  ui: IUiBusinessWizard;
  onUpdate: (state: WizardState) => void;
  onExit: () => void;
}

export default function StepPreviewSearch(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const [isLoading, setIsLoading] = useState(true);
  const abortController = useAbortController("BusinessEditor");
  const [adLanguageSet, setAdLanguageSet] = useState<AdLanguageSet>();
  const stepUi = props.ui.stepsDictionary.find(item => item.id == props.state.selectedStep);
  const stepData = props.state.steps.find(item => item.stepId == props.state.selectedStep);
  const prompt = GetDescription(appUiContext, props.state.selectedStep, stepUi?.description);
  const isCompleted = stepData?.isCompleted ? true : false;
  //--------------------------------------------------------------------------- New business data
  useEffect(() => {
    if (props.state.adLanguageSet) {
      setAdLanguageSet(props.state.adLanguageSet);
      setIsLoading(false);
    } else {
      // Make request and get new business data based on provided wizard state data
      let controller = abortController.newController("getNewBusinessFromWizard");
      getNewBusinessWithAdAsync({
        msalInstance: instance,
        authConfig: appAuthContext.config,
        data: props.state.data,
        abortSignal: controller.signal
      }).then(response => {
        //set images from props.state, because images may be new or redacted
        //and not saved to image library
        response.updatedBusiness.logo = props.state.data.logo;
        let backgroundImageId = props.state.data.backgroundImage?.id;
        let logoImageId = props.state.data.logo?.id;
        response.updatedAdLanguageSet.adModules?.forEach(adModule => {
          if (!adModule.image || !adModule.image.imageData?.id) 
            return;
          if (backgroundImageId == adModule.image.imageData.id)
            adModule.image.imageData = props.state.data.backgroundImage;
          else if (logoImageId == adModule.image.imageData.id)
            adModule.image.imageData = props.state.data.logo;
        });
        setAdLanguageSet(response.updatedAdLanguageSet);
        const updatedState = props.state.setNewBusinessData(response.updatedBusiness, response.updatedAdLanguageSet);
        props.onUpdate(updatedState);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    };
    //-------------------------------------------------------------------------
    return () => {
      abortController.abort("getNewBusinessFromWizard");
    };
  }, []);
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    let updatedState: WizardState;
    switch (optionId) {
      case "OptionBack":
        updatedState = props.state.moveToPreviousStep();
        props.onUpdate(updatedState);
        break;
      case "OptionNext":
        updatedState = props.state.moveToNextStep(!isCompleted);
        props.onUpdate(updatedState);
        break;
      default:
        throw new Error(`StepPreviewSearch: Unknown optionId ${optionId}`);
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionBack":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowLeft",
          isDisabled: false
        });
        break;
      case "OptionNext":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowRight",
          isDisabled: !adLanguageSet,
          isDefault: true
        });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      alignLeft={true}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  const adModules = adLanguageSet?.adModules?.filter(x => x.adModuleType == "SearchResultsModule").map(adModule => {
    const adModulePublished = new ClassAdModulePublished(adModule);
    return (
      <AdModule
        key={adModulePublished.id}
        adModule={adModulePublished}
        isReadonly={true}
        isClickable={false}
        useExternalLayout={false}
        minHeightEm={20}
      />
    )
  });
  //---------------------------------------------------------------------------
  if (isLoading) return (
    <WikiSpinner show={isLoading} />
  ); else return (
    <WizardStepWrapper
      ui={props.ui}
      promptString={prompt}
      optionsElement={formOptions}
      onExit={props.onExit}
    >
      <div className={styles.modules}>
        {adModules}
      </div>
    </WizardStepWrapper>
  );
}