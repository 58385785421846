import { IAppUiContext } from "../../../context/app-ui-context/AppUiContextProvider";
import GetTranslation from "./GetTranslation";

interface Props {
  appUiContext: IAppUiContext, 
  elementId: string; // UI element Id
  elementPart: string; // UI element part (caption, hint etc)
  text: object | undefined; // Text object to be displayed
  forceLocale?: string;
}

export default function GetText(props: Props): string {
  //---------------------------------------------------------------------------
  return GetTranslation(props.appUiContext, props.text, props.elementId, props.elementPart, props.forceLocale);
}
