import { useContext, useEffect } from 'react';
import AdModule from '../AdModule';
import { IAdModule } from '../IAdModule';
import styles from './AdModulesList.module.css';
import { ListManagerContext } from '../../../../context/list-manager-context/ListManagerContextProvider';
import { EManagerMode, IFilterDynamicOptions } from '../../../../context/list-manager-context/IListManagerContextData';
import { adModulesListListColumns } from './ListSettings';
import ListColumnsManager from '../../lists/list-columns-manager/ListColumnsManager';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../functions/GetName';
import { IUiAdModule } from '../IUiAdModule';
import { BusinessContext } from '../../../../context/business-context/BusinessContextProvider';
import { EOptionsAdModule, EOptionsContact, EOptionsLocation, EOptionsParagraph } from '../../../ad-content-editor/ad-modules-editor/AdModuleOptions';
import { IBusinessLocationData } from '../../../business-space/business-editor/section-location/business-location-editor/IBusinessLocationData';
import { Contact } from '../../../business-space/business-editor/section-contacts/contact/Contact';
import { IUiOption } from '../../options/IUiOption';
import { IUiMenuContent } from '../../options/menus/IUiMenuContent';
import { AdLanguageSet } from '../../../ad-content-editor/IAdLanguageSet';

interface IProps {
  ui?: IUiAdModule;
  data?: IAdModule[];
  adLanguageSet?: AdLanguageSet;
  isReadonly: boolean; // Meaning not editable, but context menu still can be available
  useExternalLayout: boolean;
  selectedItemPath?: string[];
  onModuleOptionSelect?: (optionId: EOptionsAdModule, adModuleId: string) => void;
  onLocationOptionSelect?: (optionId: EOptionsLocation, adModuleId: string, locationId?: string) => void;
  onLocationDataUpdate?: (adModuleId: string, updatedLocationData: IBusinessLocationData<string>) => void;
  onContactOptionSelect?: (optionId: EOptionsContact, adModuleId: string, contactId?: string) => void;
  onContactUpdate?: (adModuleId: string, updatedContact: Contact<string>) => void;
  onParagraphOptionSelect?: (optionId: EOptionsParagraph, adModuleId: string, paragraphId: string) => void;
  onUpdate?: (updatedAdModule: IAdModule) => void;
}

export default function AdModulesList(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { listManagerContext, listManagerContextDispatch } = useContext(ListManagerContext);
  const { businessContext } = useContext(BusinessContext);
  //--------------------------------------------------------------------------- List Manager
  useEffect(() => {
    if (!businessContext.businessState?.business) return;
    listManagerContextDispatch({
      type: "Initialize",
      configuration: {
        listId: `AdModulesList_${businessContext.businessState.business.id}`,
        managerMode: EManagerMode.Advanced,
        listColumns: adModulesListListColumns
      }
    });
  }, [businessContext.businessState]);
  //--------------------------------------------------------------------------- Dynamic Filter Options
  useEffect(() => {
    if (!props.data) return;
    const locales: string[] = [];
    const adModuleTypes: string[] = [];
    const statuses: string[] = [];
    props.data.forEach(adModule => {
      // Update locales list
      if (!locales.includes(adModule.localeId)) {
        locales.push(adModule.localeId);
      };
      if (!adModuleTypes.includes(adModule.adModuleType)) {
        adModuleTypes.push(adModule.adModuleType);
      };
      if (adModule.status) {
        if (!statuses.includes(adModule.status.statusId)) {
          statuses.push(adModule.status.statusId);
        };
      } else {
        if (!statuses.includes("Empty")) {
          statuses.push("Empty");
        };
      };
    });
    //-------------------------------------------------------------------------
    const dynamicOptions: IFilterDynamicOptions[] = [{
      columnId: "localeId",
      options: locales.map(localeId => {
        const dictionaryItem = appUiContext.dictionaries?.supportedLocales?.find(sl => sl.id == localeId);
        return ({
          id: localeId,
          name: GetName(appUiContext, localeId, dictionaryItem?.name),
          isDynamic: true
        });
      })
    }, {
      columnId: "adModuleType",
      options: adModuleTypes.map(adModuleType => ({
        id: adModuleType,
        name: adModuleType, // until we have a dictionary
        isDynamic: true
      }))
    }, {
      columnId: "status",
      options: statuses.map(statusId => ({
        id: statusId,
        name: statusId, // until we have a dictionary
        isDynamic: true
      }))
    }];
    listManagerContextDispatch({ type: "AddDynamicFilterOptions", dynamicOptions: dynamicOptions });
  }, [props.data]);
  //---------------------------------------------------------------------------
  let filteredListItems = props.data ? props.data : [];
  //---------------------------------------------------------------------------
  listManagerContext.filterableColumns.forEach(column => {
    const selectedOptions = column.filter?.options?.filter(fi => fi.isSelected);
    if (selectedOptions) {
      switch (column.id) {
        //---------------------------------------------------------------------
        case "adModuleType":
          const types = selectedOptions.map(o => o.id);
          if (types.length > 0)
            filteredListItems = filteredListItems.filter(item => types.includes(item.adModuleType));
          break;
        //---------------------------------------------------------------------
        case "localeId":
          const locales = selectedOptions.map(o => o.id);
          if (locales.length > 0)
            filteredListItems = filteredListItems.filter(item => locales.includes(item.localeId));
          break;
        //---------------------------------------------------------------------
        case "status":
          const statuses = selectedOptions.map(o => o.id);
          if (statuses.length > 0) {
            filteredListItems = filteredListItems.filter(item => statuses.includes(item.status?.statusId ?? "Empty"));
          };
          break;
        //---------------------------------------------------------------------
        case "links":
          selectedOptions.forEach(option => {
            switch (option.id) {
              case "Orphaned":
                filteredListItems = filteredListItems.filter(item => {
                  return item.isOrphan;
                });
                break;
              case "Has more than one link":
                filteredListItems = filteredListItems.filter(item => {
                  const ads = businessContext.ads?.filter(ad => ad.adModules.includes(item.id));
                  return (ads && ads.length > 1);
                });
                break;
            };
          });
          break;
      };
    };
  });
  //---------------------------------------------------------------------------
  const adModules = filteredListItems.map(adModule => {
    const isDeletable = businessContext.ads ?
      businessContext.ads.findIndex(ad => ad.adModules.includes(adModule.id)) === -1 :
      true;
    const availableOptions: IUiOption[] = [];
    props.ui?.menuContent.options.forEach(option => {
      switch (option.id) {
        case "AdModule.MenuContent.OptionDelete":
          availableOptions.push({
            ...option,
            isDisabled: !isDeletable
          });
          break;
        default:
          availableOptions.push(option);
      };
    });
    return (
      <AdModule
        key={adModule.id}
        ui={{
          ...props.ui, menuContent: {
            ...props.ui?.menuContent as IUiMenuContent,
            options: availableOptions
          }
        }}
        adModule={adModule}
        adLanguageSet={props.adLanguageSet}
        isReadonly={props.isReadonly}
        useExternalLayout={props.useExternalLayout}
        isClickable={false}
        highlightedPath={props.selectedItemPath}
        onModuleOptionSelect={props.onModuleOptionSelect}
        onLocationOptionSelect={props.onLocationOptionSelect}
        onLocationDataUpdate={props.onLocationDataUpdate}
        onContactOptionSelect={props.onContactOptionSelect}
        onContactUpdate={props.onContactUpdate}
        onParagrapOptionSelect={props.onParagraphOptionSelect}
        onUpdate={props.onUpdate}
      />)
  });
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <div>
        <ListColumnsManager
          numberTotal={props.data?.length}
          numberFiltered={filteredListItems.length}
        />
      </div>

      {adModules}

    </div>
  );
}