import { useState } from "react";
import { setToLS } from "../../utils/storage";
import { TImageData } from "../common/image-editor/TImageData";
import styles from "./ServiceWorker.module.css"
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { IServiceWorkerMessage } from "../../context/service-worker-context/ServiceWorkerContext";

export class TBusinessFinalization {
  business: string = "business";
  sendForApproval: boolean = false;
  activate: boolean = false;
  doTheJob() {
    console.log("I am doing the job!", this.business);
  }
}

export default function ServWorkerTest() {
  const thumbnail = `${TImageData.storageEndpoint}public/b125d116-ad1e-4185-a3b1-536b3aa74b9f.Thumbnail.png`;
  const large = `${TImageData.storageEndpoint}public/b125d116-ad1e-4185-a3b1-536b3aa74b9f.Large.png`;
  const [uri, setUri] = useState<string>(thumbnail);
  //---------------------------------------------------------------------
  const onClick = () => {
    console.log("navigator.serviceWorker.controller:", navigator.serviceWorker.controller);
    let action: IServiceWorkerMessage = {
      type: "tokenRequest",
      data: undefined
    };
    navigator.serviceWorker.controller?.postMessage(action);
  }
  //---------------------------------------------------------------------
  const loadLarge = () => {
    let img = new Image();
    img.crossOrigin = "anonymous"; // Handle CORS issues
    //-------------------------------------------------------------------------
    img.onload = () => {
      setUri(large);
      img.src = "";
    }
    img.src = large;
  }
  //---------------------------------------------------------------------
  const changeImage = () => {
    if (uri === thumbnail) {
      loadLarge();
    }
    else {
      setUri(thumbnail);
    }
  }
  //---------------------------------------------------------------------
  console.log("TestBackImage.uri:", uri);
  //---------------------------------------------------------------------
  return (
    <div className={styles.container}>
      {/* <button onClick={changeImage}>
        Post message
      </button> */}
      <img
        src={large}
        onLoad={() => { setUri(large) }}
        style={{ display: 'none' }}
        alt=""
      />
      <div className={styles.imageContainer}>
        <div
          className={styles.backImage}
          style={{ backgroundImage: `url(${uri})` }}
        />
      </div>
    </div>
  );
}