import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathWidgetDirectoryDraftUpdate } from "../../../../../utils/apiPathConstant";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { IDirectoryTranslationDraft } from "./DirectoryTranslatorState";

export default function saveWidgetDirectoryDraft(
  msalInstanse: IPublicClientApplication,
  draft: IDirectoryTranslationDraft,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<IDirectoryTranslationDraft> {
  return new Promise((resolve, reject) => {
    const uri = `${apiBasePath}${pathWidgetDirectoryDraftUpdate}`;
    let draftToPass = { ...draft, locker: undefined };
    //-------------------------------------------------------------------------
    apiPostPrivate(
      msalInstanse,
      authConfig,
      uri,
      draftToPass,
      undefined,
      abortSignal
    ).then(response => {
      checkResponse(response, "saveWidgetDirectoryDraft");
      resolve(response?.content as IDirectoryTranslationDraft);
    }).catch(error =>
      reject(error)
    );
  });
}