import React from 'react';
import styles from './AdStats.module.css';
import { IAdStats } from './IAdStats';

interface IProps {
  data: IAdStats;
}

export default function AdStats(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>

      <div className={styles.statItem}>
        <span
          title='Drafts'
          className={styles.value}
          style={{
            color: props.data.draft > 0 ?
              'inherit' :
              'var(--clr_font_system)'
          }}>
          <b>{props.data.draft}</b>
        </span>
        <span className={styles.caption}>
          draft(s),
        </span>
      </div>

      <div className={styles.statItem}>
        <span
          title='Pending approval'
          className={styles.value}
          style={{
            color: props.data.pendingApproval > 0 ?
              'var(--clr_accent_attention)' :
              'var(--clr_font_system)'
          }}>
          <b>{props.data.pendingApproval}</b>
        </span>
        <span className={styles.caption}>
          pending approval,
        </span>
      </div>

      <div className={styles.statItem}>
        <span
          className={styles.value}
          style={{
            color: props.data.published > 0 ?
              'var(--clr_accent_main)' :
              'var(--clr_font_system)'
          }}>
          <b>{props.data.published}</b>
        </span>
        <span className={styles.caption}>
          published
        </span>
      </div>

    </React.Fragment>
  );
}