import Icon from '../../../icon/Icon';
import styles from './ImagePlaceholder.module.css';

interface IProps {
}

export default function ImagePlaceholder(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <Icon imageName="image2" />
      </div>
    </div>
  );
}