import { IPublicClientApplication } from "@azure/msal-browser";
import { apiGetPrivate, apiPostPrivate, checkResponse } from "../../utils/api";
import { apiBasePath, getUserApi, updateUser } from "../../utils/apiPathConstant";
import { getFromLS, lsBusinessList, setToLS } from "../../utils/storage";
import { IAuthConfig } from "./AppAuthContext";
import { TUser } from "./TUser";

//-----------------------------------------------------------------------------
export function setUserLastSignIn(userId: string) {
  const key = `usr.${userId}.lastSignedIn`;
  if (!localStorage.getItem(key))
    localStorage.setItem(key, new Date().toISOString());
}

export function getUserLastSignIn(userId: string | undefined) {
  const key = `usr.${userId}.lastSignedIn`;
  if (userId) return localStorage.getItem(key);
}

export function removeUserLastSignIn(userId: string | undefined) {
  if (userId) {
    const key = `usr.${userId}.lastSignedIn`;
    if (localStorage.getItem(key)) localStorage.removeItem(key);
  }
}

//-----------------------------------------------------------------------------
export function getUserLocally(userId: string) {
  const key = `usr.${userId}`;
  const user = getFromLS(key);
  if (user) return user;
  else return null;
}

export function setUserLocally(userId: string, user: TUser) {
  const key = `usr.${userId}`;
  setToLS(key, user);
}

export function removeUserLocally(userId: string | undefined) {
  const key = `usr.${userId}`;
  if (userId) {
    localStorage.removeItem(key);
    localStorage.removeItem(lsBusinessList);
  }
}

//-----------------------------------------------------------------------------
export async function getUserRemotelyOld(
  msalInstance: IPublicClientApplication,
  config: IAuthConfig,
  signal?: AbortSignal
) {
  //console.log("getUserRemotely.msalInstance:", msalInstance);
  //console.log("getUserRemotely.config:", config);
  const response = await apiGetPrivate(
    msalInstance,
    config,
    `${apiBasePath}${getUserApi}`,
    signal
  );
  //console.log("getUserRemotely.response:", response);
  if (response && response.status === 200) {
    return response.content;
  } else return null;
}
//-----------------------------------------------------------------------------
export async function getUserRemotely(
  msalInstance: IPublicClientApplication,
  config: IAuthConfig,
  signal?: AbortSignal
): Promise<TUser> {
  return new Promise((resolve, reject) => {
    //console.log("getUserRemotely.msalInstance:", msalInstance);
    //console.log("getUserRemotely.config:", config);
    apiGetPrivate(
      msalInstance,
      config,
      `${apiBasePath}${getUserApi}`,
      signal)
      .then(response => {
        checkResponse(response, "getUserRemotely", signal?.aborted);
        resolve(response?.content as TUser);
      })
      .catch(error => {
        reject(error);
      })
  });
}
//-----------------------------------------------------------------------------
export function updateUserPromise(
  msalInstanse: IPublicClientApplication,
  user: TUser,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const url = `${apiBasePath}${updateUser}`;
    apiPostPrivate(msalInstanse, authConfig,
      url, user,
      undefined, abortSignal)
      .then((response) => {
        checkResponse(response, "updateUserPromise", abortSignal?.aborted);
        let result = response?.content["isUpdated"] as boolean;
        resolve(result);
      })
      .catch((error) => {
        reject(error)
      });
  });
};
