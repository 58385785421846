import { ReactNode, useEffect, useState } from 'react';
import styles from './InlineFormWrapper.module.css';
import { IUiMenuContent } from '../../options/menus/IUiMenuContent';
import FormOptions from '../../form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../menu/menu-content/TUiMenuContent';
import InputsGroupWrapper from '../../input-v2/inputs-group-wrapper/InputsGroupWrapper';
import useClickOutsideDiv from '../../../../hooks/useClickOutsideDiv';

interface IProps {
  ui: IUiMenuContent;
  children: ReactNode;
  isUpdated: boolean;
  onSelect: (optionId: string) => void;
  onClose: () => void;
}

export default function InlineFormWrapper(props: IProps) {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const editFormDivRef = useClickOutsideDiv({
    handler: () => onCloseWithUnsavedChanges(),
    unsavedChanges: props.isUpdated
  });
  //--------------------------------------------------------------------------- Scroll into view
  useEffect(() => {
    if (editFormDivRef.current) {
      editFormDivRef.current.scrollIntoView({ behavior: 'smooth' });
    };
  }, [editFormDivRef.current]);
  //---------------------------------------------------------------------------
  const onCloseWithUnsavedChanges = (unsavedChanges?: boolean) => {
    if (unsavedChanges)
      setConfirmationDialogOpen(true);
    else
      props.onClose();
  };
  //---------------------------------------------------------------------------
  return (
    <div ref={editFormDivRef} className={styles.wrapper}>
      <div className={styles.container}>

        <div className={styles.inputs}>
          <InputsGroupWrapper>
            {props.children}
          </InputsGroupWrapper>
        </div>

        <FormOptions
          ui={getTUiMenuContent(props.ui)}
          onSelect={props.onSelect}
        />

      </div>
    </div>
  );
}