import CombineStyles from '../../../../../utils/combineStyles';
import Icon from '../../../icon/Icon';
import styles from './MultiselectOption.module.css';

interface IProps {
  data: string;
  onRemove?: (optionId: string) => void;
}

export default function MultiselectOption(props: IProps) {
  //---------------------------------------------------------------------------
  const onRemove = () => {
    props.onRemove && props.onRemove(props.data);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        props.onRemove ? '' : styles.readonly
      ])}>

      <div className={styles.caption}>
        {props.data}
      </div>

      {props.onRemove &&
        <div
          className={styles.option}
          onClick={onRemove}
        >
          <Icon imageName="close" />
        </div>}

    </div>
  );
}