//import styles from './CheckListItemComplexIssue.module.css';
import { useContext } from "react";
import { ClassAdUnitStatus, IAdUnit } from "../../../../common/ad-modules/IAdUnit";
import { EContentUnitType } from "../../../administrator-console/dictionaries/rejection-reasons/DictionaryDraftItemRejectionReasons";
import ChecklistItemWrapper from "../checklist-item-wrapper/ChecklistItemWrapper";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";
import GetName from "../../../../common/functions/GetName";

interface IProps {
  unitType: EContentUnitType;
  data: IAdUnit;
  level?: number;
  isReadonly: boolean;
  onHover?: (isHovered: boolean) => void;
  onReview?: (updatedUnit: IAdUnit) => void;
}

export default function CheckListItemComplexIssue(props: IProps) {
  if (!props.data.status) return null;
  if (!props.data.status.rejectionReason) {
    console.error("CheckListItemComplexIssue: rejectionReason Id is not provided");
    return null;
  };
  //---------------------------------------------------------------------------
  const { appUiContext } = useContext(AppUiContextStore);
  const rejectionReasonId = props.data.status.rejectionReason?.reasonId;
  const rejectionReasonName = appUiContext.dictionaries?.requestRejectionReasons?.find(r => r.id == rejectionReasonId)?.name;
  const issueCaption = `Check for [${GetName(appUiContext, rejectionReasonId, rejectionReasonName)}]`;
  //---------------------------------------------------------------------------
  const onHover = (isHovered: boolean) => {
    props.onHover && props.onHover(isHovered);
  };
  //---------------------------------------------------------------------------
  const onReview = (updatedStatus: ClassAdUnitStatus) => {
    if (props.isReadonly) return;
    const updatedUnit: IAdUnit = {
      ...props.data,
      status: updatedStatus
    };
    props.onReview?.(updatedUnit);
  };
  //---------------------------------------------------------------------------
  return (
    <ChecklistItemWrapper
      unitType={props.unitType}
      caption={issueCaption}
      status={props.data.status}
      isReadonly={props.isReadonly}
      onHover={onHover}
      level={props.level}
      onReview={onReview}
    />
  );
}