import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../app-auth-context/AppAuthContext";
import { AdLanguageSet, IAdLanguageSet } from "../../../components/ad-content-editor/IAdLanguageSet";
import { apiPostPrivate, checkResponse } from "../../../utils/api";
import { apiBasePath, updateAdDesignTemplate } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  businessId?: string;
  templateId: string;
  adLanguageSet?: AdLanguageSet;
  abortSignal?: AbortSignal;
}

export async function getAdWithNewTemplateAsync(props: IProps): Promise<AdLanguageSet> {
  if (!props.authConfig) throw new Error("getAdWithNewTemplateAsync: auth config is not provided");
  if (!props.businessId) throw new Error("getAdWithNewTemplateAsync: Business is not provided");
  if (!props.adLanguageSet) throw new Error("getAdWithNewTemplateAsync: Ad language set is not provided");
  //---------------------------------------------------------------------------
  const response = await apiPostPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${stringFormatter(updateAdDesignTemplate, [props.businessId, props.templateId])}`,
    props.adLanguageSet,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getAdWithNewTemplateAsync", props.abortSignal?.aborted);
  const adLanguageSet = new AdLanguageSet(response?.content as IAdLanguageSet);
  return adLanguageSet;
}