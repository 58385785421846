import { TImageData } from "../TImageData";
import { Rect } from "../image-editor-canvas/Crop";

export enum EImageRotation {
  deg0 = 0,
  deg90 = 90,
  deg180 = 180,
  deg270 = 270,
}

export enum EImageFlip {
  None = 0,
  Horizontal = 1,
  Vertical = 2,
  Both = 3,
}

export type TCanvasState = {
  width: number;
  height: number;
  matrix: DOMMatrix;
}

export enum ETransformType {
  None = 0,
  ImageOpen = 1,
  ImageFromLibraryAsCopy = 2,
  ImageFromLibraryAsLink = 3,
  Rotation = 4,
  Flip = 5,
  Crop = 6,
  ResetToDefaultImage = 7,
}

export class ImageTransformation {
  bitmap: HTMLImageElement;
  displayName: string;
  rotation: EImageRotation;
  flip: EImageFlip;
  cropRect: Rect;
  transformType: ETransformType;
  imageData: TImageData; 

  constructor(
    bitmap: HTMLImageElement,
    displayName: string,
    rotation: EImageRotation,
    flip: EImageFlip,
    cropRect: Rect,
    transformType: ETransformType,
    imageData: TImageData
  ) {
    this.bitmap = bitmap;
    this.rotation = rotation;
    this.flip = flip;
    this.displayName = displayName;
    this.cropRect = cropRect;
    this.transformType = transformType;
    this.imageData = imageData;
  }

  static getInitialTransformation(
    img: HTMLImageElement,
    imageData: TImageData,
    libraryAsLink?: boolean
  ): ImageTransformation {
    let maxSize: number = 4096;
    let w: number = img.width;
    let h: number = img.height;

    // temporary solution
    if (w > maxSize)
      maxSize = w;
    else if (h > maxSize)
      maxSize = h;

    if (w == h) {
      if (w > maxSize) {
        w = maxSize;
        h = maxSize;
      }
    }
    else if (h < w) {
      if (w > maxSize) {
        let k = w / h;
        w = maxSize;
        h = w / k;
      }
    }
    else {
      if (h > maxSize) {
        let k = h / w;
        h = maxSize;
        w = h / k;
      }
    }
    let transformType: ETransformType;
    switch (libraryAsLink) {
      case false:
        transformType = ETransformType.ImageFromLibraryAsCopy;
        break;
      case true:
        transformType = ETransformType.ImageFromLibraryAsLink;
        break;
      default:
        transformType = ETransformType.ImageOpen
        break;
    }
    return new ImageTransformation(
      img,
      "New image",
      EImageRotation.deg0,
      EImageFlip.None,
      new Rect(0, 0, w, h),
      transformType,
      imageData
    );
  }


  newRotation(value: EImageRotation): ImageTransformation {
    return new ImageTransformation(
      this.bitmap,
      "Rotate",
      value,
      this.flip,
      this.cropRect,
      ETransformType.Rotation,
      this.imageData
    );
  }

  newFlip(value: EImageFlip): ImageTransformation {
    return new ImageTransformation(
      this.bitmap,
      "Flip",
      this.rotation,
      value,
      this.cropRect,
      ETransformType.Flip,
      this.imageData
    );
  }

  newCrop(value: Rect): ImageTransformation {
    return new ImageTransformation(
      this.bitmap,
      "Crop",
      this.rotation,
      this.flip,
      value,
      ETransformType.Crop,
      this.imageData
    );
  }

  getWidthRotated(): number {
    switch (this.rotation) {
      case EImageRotation.deg90:
      case EImageRotation.deg270:
        return this.cropRect.height;
      default:
        return this.cropRect.width;
    };
  }

  getHeightRotated(): number {
    switch (this.rotation) {
      case EImageRotation.deg90:
      case EImageRotation.deg270:
        return this.cropRect.width;
      default:
        return this.cropRect.height;
    };
  }

  getCanvasState(cvs: HTMLCanvasElement, ctx?: CanvasRenderingContext2D): TCanvasState {
    let w = this.cropRect.width;
    let h = this.cropRect.height;
    switch (this.rotation) {
      case EImageRotation.deg90:
      case EImageRotation.deg270:
        w = this.cropRect.height;
        h = this.cropRect.width;
        break;
    };
    //cvs.width = w;
    //cvs.height = h;
    if (!ctx) {
      ctx = cvs.getContext('2d') as CanvasRenderingContext2D;
    }
    ctx.save();
    try {
      ctx.save();
      ctx.resetTransform();

      ctx.translate(w / 2, h / 2);
      ctx.rotate(this.rotation * Math.PI / 180);

      let dm = ctx.getTransform();

      switch (this.flip) {
        case EImageFlip.Horizontal:
          dm = dm.flipX();
          break;
        case EImageFlip.Vertical:
          dm = dm.flipY();
          break;
        case EImageFlip.Both:
          dm = dm.flipX();
          dm = dm.flipY();
          break;
      }
      return {
        width: w,
        height: h,
        matrix: dm
      };
    }
    finally {
      ctx.restore();
    }
  }

  drawImage(cvs: HTMLCanvasElement, muted: boolean) {
    let ctx: CanvasRenderingContext2D = cvs.getContext('2d') as CanvasRenderingContext2D;
    let w = this.cropRect.width;
    let h = this.cropRect.height;
    switch (this.rotation) {
      case EImageRotation.deg90:
      case EImageRotation.deg270:
        w = this.cropRect.height;
        h = this.cropRect.width;
        break;
    };
    cvs.width = w;
    cvs.height = h;
    ctx.save();
    try {
      //ctx.save();
      ctx.resetTransform();

      ctx.clearRect(0, 0, w, h);

      ctx.translate(w / 2, h / 2);
      ctx.rotate(this.rotation * Math.PI / 180);

      let mx = ctx.getTransform();

      switch (this.flip) {
        case EImageFlip.Horizontal:
          mx = mx.flipX();
          break;
        case EImageFlip.Vertical:
          mx = mx.flipY();
          break;
        case EImageFlip.Both:
          mx = mx.flipX();
          mx = mx.flipY();
          break;
      }

      let imgRect = this.cropRect;
      let cvsRect = imgRect.imageToCanvas(mx, 1);
      ctx.setTransform(mx);

      ctx.globalAlpha = muted ? 0.3 : 1;

      // console.log("drawImage.w.h:", cvs.width, cvs.height);
      // console.log("drawImage.imgRect:", imgRect);
      // console.log("drawImage.cvsRect:", cvsRect);
      // console.log("drawImage.bitmap:", this.bitmap);
      ctx.drawImage(
        this.bitmap,
        imgRect.left, imgRect.top, imgRect.width, imgRect.height,
        cvsRect.left, cvsRect.top, cvsRect.width, cvsRect.height,
      );
    }
    finally {
      ctx.restore();
    }
  }
}