import { ReactNode, useContext, useState } from 'react';
import styles from './CampaignWrapper.module.css';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import Toggler from '../../toggler/Toggler';
import Icon from '../../icon/Icon';
import PopupMenu from '../../popup-v2/popup-menu/PopupMenu';
import { getTUiMenuContent } from '../../menu/menu-content/TUiMenuContent';
import { IUiMenuContent } from '../../options/menus/IUiMenuContent';
import CombineStyles from '../../../../utils/combineStyles';
import AdStats from '../ad-stats/AdStats';
import { BusinessContext } from '../../../../context/business-context/BusinessContextProvider';

interface IProps {
  id: string;
  ui: IUiMenuContent;
  children: ReactNode; // Ad Campaign header
  adPages: ReactNode; // Ad Pages list
  isCollapsed: boolean;
  isLoading: boolean;
  onExpand?: () => void;
  onOptionSelect?: (selectedOptionId: string) => void; // Optional. If is not provided, campaign is in read-only mode
}

export default function CampaignWrapper(props: IProps) {
  const { businessContext } = useContext(BusinessContext);
  const [stateCollapsed, setStateCollapsed] = useState(props.isCollapsed);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const contextMenuOptionId = "CampaignListItemContextMenuOption";
  const readonly = props.onOptionSelect ? false : true;
  const campaignItemId = `${contextMenuOptionId}.${props.id}`;
  //---------------------------------------------------------------------------
  const toggleAdPagesList = () => {
    const isCollapsed = !stateCollapsed;
    setStateCollapsed(isCollapsed);
    if (!isCollapsed) {
      props.onExpand && props.onExpand();
    };
  };
  //---------------------------------------------------------------------------
  const onContextMenuOpen = () => {
    setContextMenuOpen(true);
  };
  //---------------------------------------------------------------------------
  const onContextMenuOptionSelect = (selectedOptionId: string) => {
    setContextMenuOpen(false);
    props.onOptionSelect && props.onOptionSelect(selectedOptionId);
  };
  //---------------------------------------------------------------------------
  let adsPublished = 0;
  let adsDrafts = 0;
  let adsPendingApproval = 0;
  businessContext.ads?.forEach(ad => {
    if (ad.adCampaignId == props.id) {
      if (ad.status.isPublished) adsPublished++;
      if (ad.status.statusId == "NA_DRFT_NEW") adsDrafts++;
      if (ad.status.statusId == "NA_PNDG_APPROVAL") adsPendingApproval++;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <WikiSpinner show={props.isLoading} />
      <div className={styles.header}>

        <div
          id={campaignItemId}
          className={CombineStyles([
            styles.optionContextMenu,
            readonly ? styles.disabled : ""
          ])}
          onClick={readonly ? undefined : onContextMenuOpen}
        >
          <Icon
            imageName="kebabButton"
          />
        </div>

        <div className={styles.details}>
          <div className={styles.headerContent}>
            {props.children}
          </div>

          <div className={styles.stats}>
            <AdStats data={{
              draft: adsDrafts,
              pendingApproval: adsPendingApproval,
              published: adsPublished
            }}/>
          </div>
        </div>

        <div className={styles.toggler}>
          <Toggler
            collapsed={stateCollapsed}
            onCollapse={toggleAdPagesList}
            onExpand={toggleAdPagesList}
          />
        </div>

      </div>

      {!stateCollapsed && props.adPages}

      {contextMenuOpen &&
        <PopupMenu
          ui={getTUiMenuContent(props.ui)}
          anchorId={campaignItemId}
          displayIcon={true}
          onClose={() => setContextMenuOpen(false)}
          onSelect={onContextMenuOptionSelect}
        />}

    </div>
  );
}