import { IUiMenuContent } from '../../../../../common/options/menus/IUiMenuContent';
import OptionLink from '../../../../../common/options/option-link/OptionLink';
import { IMapSettings } from '../IBusinessLocationData';
import styles from './OptionDefaultBounds.module.css';

interface IProps {
  ui: IUiMenuContent;
  data?: IMapSettings;
  onSelect: (optionId: string) => void;
}

export default function OptionDefaultBounds(props: IProps) {
  const boundsSaveOption = props.ui.options.find(option => option.id == "OptionMapBoundsSave");
  const boundsAutoOption = props.ui.options.find(option => option.id == "OptionMapBoundsAuto");
  if (!boundsSaveOption || !boundsAutoOption) return null;
  //---------------------------------------------------------------------------
  if (props.data?.defaultBounds)
  return (
    <div className={styles.container}>
      <OptionLink
        ui={boundsAutoOption}
        onSelect={() => props.onSelect(boundsAutoOption.id)}
      />
    </div>
  ); else return (
    <div className={styles.container}>
      <OptionLink
        ui={boundsSaveOption}
        onSelect={() => props.onSelect(boundsSaveOption.id)}
      />
    </div>
  );
}