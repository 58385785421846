import styles from "./UserMenuButton.module.css";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import UserMenuButtonCaption from "./user-menu-button-caption/UserMenuButtonCaption";
import CombineStyles from "../../../../../utils/combineStyles";
import { InteractionStatus } from "@azure/msal-browser";

interface IProps {
  //ui?: TUiButton | undefined;
  onClick: () => void;
}

export default function UserMenuButton(props: IProps) {
  const isAuthenticated = useIsAuthenticated();
  const instance = useMsal();
  const account = instance.accounts[0]; // using instance because context is updated quite slowly, and user data is not ready for a while 
  const userInitial = account?.name?.substring(0, 1).toLocaleUpperCase();
  const inProgress = instance.inProgress != InteractionStatus.None;
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      onClick={props.onClick}
    >
      <div
        id="UserMenuButton"
        className={CombineStyles([
          styles.roundedButton,
          isAuthenticated || inProgress ? styles.authenticated : ""
        ])}
      >
        <UserMenuButtonCaption
          isAuthenticated={isAuthenticated}
          letter={userInitial}
          inProgress={inProgress}
        />
      </div>
    </div>
  );
}

{/* <div
        className={styles.roundedButton}
        style={{ backgroundColor: isAuthenticated ? 'var(--clr_accent_main)' : 'var(--clr_main_base)' }}
      >
        <UserInitial username={appAuthContext.user?.userIdentity.displayName}/>

        <div id='1'
          className={styles.roundedButtonCaption}
          style={{ display: !isAuthenticated ? 'flex' : 'none' }}
        >
          <Icon
            imageName="kebabButton"
          />
        </div>

        <div id='2'
          className={styles.roundedButtonCaption}
          style={{ display: (isAuthenticated && !userIntitial) ? 'flex' : 'none' }}
        >
          <Icon
            imageName="user"
          />
        </div>

        <div id='3'
          className={styles.roundedButtonCaption}
          style={{ display: (isAuthenticated && userIntitial) ? 'flex' : 'none', top: '-2px' }}
        >
          {userIntitial}
        </div>

      </div> */}