import { useContext } from 'react';
import CombineStyles from '../../../../../../../utils/combineStyles';
import GetName from '../../../../../../common/functions/GetName';
import Icon from '../../../../../../common/icon/Icon';
import { IDirectoryTranslationsListItem } from '../../DirectoryList';
import styles from './DirectoryTranslationsListItem.module.css';
import { AppUiContextStore } from '../../../../../../../context/app-ui-context/AppUiContextProvider';

export interface IUiDirectoryTranslationsListItem {
  //inputLocale: IUiInputDropdown;
}

interface IProps {
  //ui: IUiDirectoryTranslationsListItem;
  data: IDirectoryTranslationsListItem;
  onSelect: (locale: string) => void;
}

export default function DirectoryTranslationsListItem(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    props.onSelect(props.data.locale);
  };
  //---------------------------------------------------------------------------
  const localesDictionaryItem = appUiContext.dictionaries?.supportedLocales?.find(item => item.id == props.data.locale);
  const valueToDisplay = localesDictionaryItem ? GetName(appUiContext, localesDictionaryItem.id, localesDictionaryItem.name) : props.data.locale;
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      onClick={onSelect}
    >
      <div
        className={CombineStyles([
          styles.caption,
          props.data.translationExists ? "" : styles.disabled
        ])}
      >
        {valueToDisplay}
      </div>

      <div className={CombineStyles([
        styles.icon,
        props.data.translationDraftExists ? "" : styles.disabled
      ])}>
        <Icon imageName="pencil" />
      </div>

    </div>
  );
}