import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { ReactNode, useContext, useEffect } from "react";
import { AppAuthContextStore } from "./AppAuthContext";
//-------------------------------------------------------------------------
interface IProps {
  componentName?: string;
  children?: ReactNode | undefined
}
//-------------------------------------------------------------------------
export function MsalTemplateWrapper(props: IProps) {
  const { appAuthContext } = useContext(AppAuthContextStore);
  //-------------------------------------------------------------------------
  useEffect(() => {
    //console.warn(`MsalTemplateWrapper[${props.componentName}]: setting user type to Authenticated`);
    //TUserType.newUserType(EUserType.Authenticated);
    //TUserType.toLS(location.pathname, EUserType.Authenticated, `MsalTemplateWrapper[${props.componentName}]`);
  }, []);
  //-------------------------------------------------------------------------
  useEffect(() => {
    //props.componentName && console.warn(`MsalTemplateWrapper[${props.componentName}].user:`, appAuthContext?.user);
  }, [appAuthContext?.user])
  //-------------------------------------------------------------------------
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      {appAuthContext.user && appAuthContext.config && props.children}
    </MsalAuthenticationTemplate>
  );
  //---------------------------------------------------------------------------
  function ErrorComponent() {
    return <p>An Error Occurred or you don't have permissions to this page</p>;
  }
  //---------------------------------------------------------------------------
  function LoadingComponent() {
    return <p>Authentication in progress...</p>;
  }

}