import { CSSProperties } from 'react';
import CombineStyles from '../../../../../utils/combineStyles';
import { IItemStateGroup } from '../../../administrator-console/dictionaries/business-tags-editor/BusinessTagsEditor';
import styles from './BusinessTagHeader.module.css';
import TagBadge from './tag-badge/TagBadge';
import { IBusinessTagsDictionaryDraftItem } from '../../../administrator-console/dictionaries/business-tags-editor/IBusinessTagsDictionary';
import { DraftItemStateEnum } from '../../dictionary-draft-models/DraftItemStateEnum';

interface IProps {
  draftStatesUi: IItemStateGroup[];
  data: IBusinessTagsDictionaryDraftItem;
  displayId: boolean;
  index?: number;
}

export default function BusinessTagHeader(props: IProps) {
  const hasNoTranslation = props.data.name?.en ? false : true;
  const ignoreOrder = ((props.data.draftState & DraftItemStateEnum.IsNew) == DraftItemStateEnum.IsNew)
    || ((props.data.draftState & DraftItemStateEnum.IsRepositionedNew) == DraftItemStateEnum.IsRepositionedNew)
    || ((props.data.draftState & DraftItemStateEnum.IsRepositionedOld) == DraftItemStateEnum.IsRepositionedOld);
  //---------------------------------------------------------------------------
  const caption = (props.displayId || hasNoTranslation) ? `{{${props.data.id}}}` : props.data?.name?.en;
  //---------------------------------------------------------------------------
  // Apply styles and provide badges (icons) based on current item draft state
  const calcStyles: CSSProperties = {
    color: 'inherit'
  };
  const badges: JSX.Element[] = [];
  props.draftStatesUi.forEach(group => {
    let conditionIsMet = false;
    //-------------------------------------------------------------------------
    group.states?.forEach(state => {
      // Check if the state is applied to the current node 
      //console.log(state.draftState, props.data.draftState)
      if (state.draftState === (props.data.draftState & state.draftState)) {
        conditionIsMet = true;
        //console.log(props.data.id, group.name, state.draftState, props.data.draftState)
        calcStyles.color = state.fontColor ? state.fontColor : group.color;
        calcStyles.fontWeight = state.fontWeight;
        calcStyles.textDecoration = state.textDecoration;
        //---------------------------------------------------------------------
        // Provide an icon if it's a rule for the state
        if (!group.iconId && state.iconId) {
          //console.log(state.iconId)
          badges.push(
            <TagBadge
              key={group.id + state.draftState}
              iconId={state.iconId}
              color={state.iconColor ? state.iconColor : group.color}
            />
          );
        }
      };
    });
    //-------------------------------------------------------------------------
    // If group icon is provided we display only one badge for a group
    if (group.iconId && conditionIsMet) {
      badges.push(
        <TagBadge
          key={group.id}
          iconId={group.iconId}
          color={group.color}
        />
      );
    };
  });
  //let captionColor = (props.data.draftState & DraftItemStateEnum.IsNothing) ? 'gray' : 'var(--clr_font_base)';
  //---------------------------------------------------------------------------
  //let groupIndicator = props.data.isGroup ? '\u{24E4}' : '\u{25AA}';
  //let selectable = '\u{25AA}';
  let unselectable = '\u{24E4}';
  let customOrder = props.data.itemsManualOrder ? '\u{21F5}' : '';
  //---------------------------------------------------------------------------
  return (
    <div className={CombineStyles([
      styles.container
    ])}>

      <div
        className={styles.caption}
        style={calcStyles}
      >

        {(props.index !== undefined) &&
          <div className={styles.index}>
            {props.index}
          </div>}


        <div
          className={styles.systemId}
        //style={{ color: captionColor }}
        >
          {props.data.isGroup &&
            <div className={styles.indicator}>
              {unselectable}
            </div>}

          <div className={styles.indicator}>
            {customOrder}
          </div>
          <span>
            {props.data.id}
          </span>
          {props.index && !ignoreOrder && (props.data.index !== props.index) &&
            <span>
              ({props.data.index})
            </span>}
        </div>

      </div>

      <div className={styles.badges}>
        {badges}
      </div>

      <div
        className={styles.caption}
        style={{ ...calcStyles, marginLeft: 'auto', color: 'var(--clr_font_base)' }}
      >
        {props.data?.name?.en}
      </div>

    </div>
  );
}