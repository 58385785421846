import { useContext } from 'react';
import { IAdStatus } from '../IAdLanguageSet';
import styles from './AdStatus.module.css';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';
import AdStatusIcon from './AdStatusIcon';
import GetName from '../../common/functions/GetName';

interface IProps {
  data?: IAdStatus;
}

export default function AdStatus(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const dictionaryItem = appUiContext.dictionaries?.adPageStatuses?.find(item => item.id === props.data?.statusId);
  const statusName = props.data ? GetName(appUiContext, props.data.statusId, dictionaryItem?.name) : undefined;
  //---------------------------------------------------------------------------
  var bkgrColour = props.data?.statusId == "NA_PNDG_APPROVAL" ? "var(--clr_accent_attention)" : "var(--clr_accent_neutral)";
  if (props.data?.isInTrouble)
    bkgrColour = "var(--clr_accent_wrong)";
  else if (props.data?.isPublished)
    bkgrColour = "var(--clr_accent_main)";
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      style={{ backgroundColor: bkgrColour }}
    >
      <AdStatusIcon data={props.data} />
      <span>
        {statusName}
      </span>
    </div>
  );
}