import { useEffect, useState } from 'react';
import DictionaryNodeEditForm from '../../common/dictionary-node-edit-form/DictionaryNodeEditForm';
import { DictionaryEditorDraftItem } from '../../common/models/DictionaryEditorDraftItem';
import { LocalesDictionaryDraftItem } from '../locales-editor-node/LocalesEditorNode';
import { EditFormState } from '../../../../../common/edit-form/IEditFormState';

interface IProps {
  data?: LocalesDictionaryDraftItem;
  onUpdate: (updatedItem: LocalesDictionaryDraftItem) => void;
  onClose: () => void;
}

export default function LocaleEditForm(props: IProps) {
  const [state, setState] = useState<EditFormState<LocalesDictionaryDraftItem>>();
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (props.data) {
      const state = new EditFormState<LocalesDictionaryDraftItem>({
        object: props.data,
        isNew: true,
        isValid: false
      });
      setState(state);
    };
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onUpdate = (updatedData: DictionaryEditorDraftItem, isValid: boolean) => {
    if (state) {
      const updatedState = new EditFormState<LocalesDictionaryDraftItem>(state, {
        object: updatedData as LocalesDictionaryDraftItem,
        isUpdated: true,
        isValid: isValid
      });
      setState(updatedState);
    } else {
      console.error("Dictionary draft is being updated but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    if (state?.object) {
      props.onUpdate(state.object)
    } else {
      console.error("Dictionary draft is being updated but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  return (
    <DictionaryNodeEditForm
      data={state}
      onUpdate={onUpdate}
      onConfirm={onConfirm}
      onClose={props.onClose}
    />
  );
}