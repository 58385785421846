import React from "react";
import { Icon } from "@iconify/react";
import styles from "../common/icon/Icon.module.css";

export const iconsSrc: any = {
  home: "ic:round-home",
  arrowMoveUp: "fa:arrow-up",
  arrowMoveDown: "fa:arrow-down",
  spinner: "fluent:spinner-ios-16-filled",
  signIn: "fa-solid:sign-in-alt",
  signOut: "fa-solid:sign-out-alt",
  changeColourTheme: "fa-solid:palette",
  star: "fa-solid:star",
  changeLanguage: "fa-solid:globe-americas",
  hamburgerButton: "fa-solid:bars",
  kebabButton: "fa-solid:ellipsis-v",
  meatballsButton: "fa-solid:ellipsis-h",
  accommodation: "fa-solid:bed",
  adminConsole: "fa-solid:tools",
  adminConsoleExit: "fa-solid:sign-out-alt",
  administrator: "fa-solid:drafting-compass",
  businessEditor: "fa-solid:briefcase",
  businessEditorExit: "fa-solid:sign-out-alt",
  entertainment: "fa-solid:binoculars",
  extra: "fa-solid:cubes",
  food: "fa-solid:wine-glass",
  job: "fa-solid:suitcase",
  moderator: "fa-solid:clipboard-check",
  transport: "fa-solid:car",
  backArrow: "fa-solid:angle-left",
  rightArrow: "fa-solid:angle-right",
  leftArrow: "fa-solid:angle-left",
  rightArrow2: "icon-park-outline:right-c",
  leftArrow2: "icon-park-outline:left-c",
  doubleArrowRight: "fa-solid:angle-double-right",
  doubleArrowLeft: "fa-solid:angle-double-left",
  doubleArrowDown: "fa-solid:angle-double-down",
  doubleArrowUp: "fa-solid:angle-double-up",
  downArrow: "fa-solid:angle-down",
  upArrow: "fa-solid:angle-up",
  downArrowSolid: "ic:baseline-arrow-drop-down",
  lock: "teenyicons:lock-circle-solid",
  close: "fa-solid:times",
  closefilled: "fa-solid:times",
  exit1: "streamline:emergency-exit-solid",
  exit: "ion:exit-outline",
  removeThin: "maki:cross",
  //info: "bi:info-circle",
  info: "emojione-monotone:information",
  businessOwner: "fluent:key-multiple-20-filled",
  businessesList: "fa-solid:list-ul",
  messages: "fa-solid:comment-alt",
  stats: "ic:round-query-stats",
  translator: "fa-solid:language",
  wallet: "fa-solid:wallet",
  caretDown: "fa-solid:caret-down",
  caretUp: "fa-solid:caret-up",
  exclamation: "fa-solid:exclamation",
  question: "fa-solid:question",
  ban: "fa-solid:ban",
  check: "fa-solid:check",
  userProfile: "fa-solid:house-user",
  userPrivacyPolicy: "fa-solid:file",
  userPersonalInfo: "fa-solid:user-circle",
  userPersonalInfoEdit: "fa-solid:user-edit",
  userPreferences: "fa-solid:user-cog",
  userImagesLibrary: "fa-solid:images",
  userAccount: "fa-solid:user-shield",
  notifications: "fa-solid:bell",
  image: "fa-solid:image",
  image2: "fa:image",
  images: "fa-solid:images",
  imageAdd: "mdi:image-plus-outline",
  imageEdit: "mdi:image-edit",
  imageSelect: "mdi:image-multiple",
  textEdit: "fluent:code-text-edit-20-filled",
  text: "uil:text",
  //rotateLeft: "akar-icons:arrow-back-thick-fill",
  //rotateRight: "akar-icons:arrow-forward-thick-fill",
  pencil: "clarity:pencil-solid",
  pencilOff: "material-symbols:edit-off-rounded",
  pencilRuler: "fa-solid:pencil-ruler",
  eye: "fa-solid:eye",
  eyeOpened: "el:eye-open",
  eyeClosed: "el:eye-close",
  delete: "mdi:delete",
  undelete: "mdi:delete-off",
  regMark: "fa-solid:registered",
  location: "fluent:location-12-filled",
  phone: "fa-solid:phone",
  tags: "fa-solid:tags",
  tagRequests: "clarity:tags-solid-alerted",
  bullhorn: "fa-solid:bullhorn",
  copy: "fa-solid:copy",
  refresh: "el:refresh",
  adPageDetails: "fa-solid:th-list",
  businessCard: "bx:bxs-credit-card-front",
  businessPage: "ic:round-home",
  banner: "ph:flag-banner-fill",
  cards: "ph:cards-bold",
  modules: "mdi:newspaper-variant-multiple",
  mail: "fluent:mail-24-filled",
  envelope: "f7:envelope-fill",
  history: "fa-solid:history",
  plus: "fa-solid:plus",
  send: "fluent:send-16-filled",
  checkbox: "ci:checkbox",
  checkboxChecked: "ci:checkbox-checked",
  checkboxGray: "ci:checkbox-square",
  users: "fa-solid:user-friends",
  user: "fa-solid:user",
  addUser: "fa6-solid:user-plus",
  search: "fa:search",
  systemContent: "fa-solid:file-image",
  mapLocation: "fa6-solid:map-location-dot",
  open: "fa:folder-open",
  publish: "fluent:book-globe-20-filled",
  checkeredFlag: "fa6-solid:flag-checkered",
  magicWand: "fa6-solid:wand-magic-sparkles",
  books: "wpf:books",
  dollar: "ri:money-dollar-box-fill",
  changeLink: "fluent:link-edit-16-filled",
  linkAdd: "fa-solid:link", //"fa6-solid:link", // "fa-solid:link"
  attention: "fa-solid:exclamation-triangle",
  addElement: "eva:plus-square-fill",
  removeElement: "eva:minus-square-fill",
  square: "material-symbols:square-rounded",
  circle: "material-symbols:circle",
  circleEmpty: "material-symbols:lens-outline",
  settingsSuggest: "ic:round-settings-suggest",
  keys: "mdi:key-chain-variant",
  //mail: "uiw:mail",
  uielement: "emojione-monotone:ok-button",
  rikiwikitmp: "bi:r-circle-fill",
  cases: "ic:round-cases",
  businessProgress: "streamline:business-progress-bar-2-solid",
  filter: "fluent:filter-12-filled",
  adModule: "icon-park-solid:id-card",

  //------------------------------------- ImageEditor
  rotateLeft: "fa-solid:undo-alt",
  rotateRight: "fa-solid:redo-alt",
  flipHorz: "uis:flip-v-alt",
  flipVert: "uis:flip-h-alt",
  crop: "fa-solid:crop",
  upload: "fa:cloud-upload",
  imageDescription: "ph:chat-text-bold",
  separator: "fluent-mdl2:separator",
  fullScreenEnter: "fa-solid:expand-arrows-alt", //"gridicons:fullscreen", //"mingcute:fullscreen-2-fill", //"bx:fullscreen", //"mingcute:fullscreen-fill", //,//"material-symbols:fullscreen",
  fullScreenExit: "fa-solid:compress-arrows-alt", //"gridicons:fullscreen-exit", //"mingcute:fullscreen-exit-2-fill", //"bx:exit-fullscreen", //"mingcute:fullscreen-exit-fill", //, //"material-symbols:fullscreen-exit",
  removeImage: "mdi:file-image-remove", //mdi:file-image-remove-outline, //mdi:image-remove, //mdi:image-remove-outline, //gridicons:image-remove
  brush: "lucide:paintbrush",
  badge: "fa6-solid:id-badge",
  newLabel: "clarity:new-solid",
  new: "foundation:burst-new",
  zero: "mdi:number-zero-circle",
  undo: "fa:undo",
  //------------------------------------- contact types
  web: "mdi:web",
  email: "ic:baseline-alternate-email",
  fax: "fa-solid:fax",
  skype: "fa6-brands:skype",
  whatsapp: "mingcute:whatsapp-fill",
  facebook: "fa-brands:facebook",
  linkedin: "fa6-brands:linkedin",
  mobile: "bi:phone-vibrate-fill",
  telegram: "fa6-brands:telegram",
  twitter: "eva:twitter-fill",
  //-----------------------------------------Search
  sortBasic: "mi:sort",
  modeBasic: "oui:app-advanced-settings",
  modeAdvanced: "oui:app-advanced-settings",
  //-----------------------------------------
  //template: "tabler:template",
  //template: "icon-park-outline:page-template",
  template: "icon-park-solid:page-template",

  recordStart: "fluent:record-32-regular",
  recordStop: "fluent:record-stop-32-regular",
};
//------------------------------------------------------------------------
export class IconSortFilter {
  static sortBasic = "sortBasic";
  static modeBasic = "modeBasic";
  static modeAdvanced = "modeAdvanced";
}
//------------------------------------------------------------------------
export default (props: any) => {
  var style;
  const spin = (props.spin === undefined) ? false : props.spin;
  if (spin) style = `style ${styles.spin}`;
  if (typeof iconsSrc[props.iconFile] !== "undefined") {
    return (
      <Icon
        icon={iconsSrc[props.iconFile]}
        height="1.3em"
        width="2em"
        className={`elementIcon mr-2 ${props.className} ${style}`}
      // alt={props.alt}
      />
    );
  }
  return null;
};
