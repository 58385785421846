import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import GetPlaceholder from '../../functions/GetPlaceholder';
import { IUiInputText } from '../../input-v2/input-text/InputText';
import styles from './InputLocationSearch.module.css';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import Icon from '../../icon/Icon';

interface IProps {
  elementRef: React.RefObject<HTMLInputElement>;
  ui: IUiInputText;
  visible: boolean;
}

export default function InputLocationSearch(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [clearOption, setClearOption] = useState<boolean>(!!props.elementRef.current && props.elementRef.current?.value.length > 0);
  //---------------------------------------------------------------------------
  const onUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0)
      setClearOption(true);
  };
  //---------------------------------------------------------------------------
  const onClear = () => {
    if (props.elementRef.current) {
      props.elementRef.current.value = '';
      setClearOption(false);
    };
  };
  //---------------------------------------------------------------------------
  // Clear input if it's invisible, so next time it would be set visible, input is empty
  if (props.elementRef.current && !props.visible)
    props.elementRef.current.value = '';
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container} style={{ display: props.visible ? 'flex' : 'none' }}>
      <input
        ref={props.elementRef}
        type="text"
        inputMode="text"
        enterKeyHint="search"
        className={styles.input}
        placeholder={GetPlaceholder(appUiContext, props.ui.id, props.ui.placeholder)}
        title='Type the place name you are looking for'
        onChange={onUpdate}
      />
      {clearOption &&
        <div
          className={styles.clearButton}
          onClick={onClear}
        >
          <Icon imageName="close" />
        </div>}
    </div>
  );
}