import { useContext, useState } from 'react';
import InputWrapper from '../../../../../common/input-v2/input-wrapper/InputWrapper';
import styles from './InputCoordinates.module.css';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import GetCaption from '../../../../../common/functions/GetCaption';
import { IUiInputText } from '../../../../../common/input-v2/input-text/InputText';
import GetHint from '../../../../../common/functions/GetHint';
import { IValidationState } from '../../../../../common/input-v2/input-validation/IUiValidation';
import RawInputText from '../../../../../common/input-v2/raw-inputs/RawInputText';
import { EInputValueType } from '../../../../../common/input-v2/IUiInput';
import useClickOutsideDiv from '../../../../../../hooks/useClickOutsideDiv';
const divider = ", ";

interface IProps {
  ui: IUiInputText;
  data?: number[];
  isFocused?: boolean;
  onUpdate: (updatedValue: number[]) => void;
}

export default function InputCoordinates(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [validationState, setValidationState] = useState<IValidationState>();
  //---------------------------------------------------------------------------
  const enableEditMode = () => {
    setEditMode(true);
  };
  //---------------------------------------------------------------------------
  const disableEditMode = () => {
    setEditMode(false);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedValue: string) => {
    if (!updatedValue) return;
    const coordinates = updatedValue.split(divider).map(c => parseFloat(c));
    props.onUpdate(coordinates);
  };
  //---------------------------------------------------------------------------
  const inputRef = useClickOutsideDiv({ handler: disableEditMode });
  const dataString = props.data?.join(divider);
  //---------------------------------------------------------------------------
  return (
    <InputWrapper
      caption={!props.ui.hideCaption ? GetCaption(appUiContext, props.ui.id, props.ui.caption) : undefined}
      hint={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      hideCaption={props.ui.hideCaption}
      hideHint={props.ui.hideHint}
      readonly={props.ui.readonly}
      disabled={props.ui.disabled}
      validation={validationState}
    >
      {editMode ?
        <RawInputText
          ui={props.ui}
          data={dataString}
          valueType={EInputValueType.Text}
          isFocused={props.isFocused}
          isValid={validationState?.isValid}
          onUpdate={onUpdate}
          wrapperRef={inputRef}
        />
        :
        <div
          className={styles.data}
          onClick={enableEditMode}
        >
          {props.data ? dataString : "Click to edit..."}
        </div>}
    </InputWrapper>
  );
}