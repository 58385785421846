import { useContext, useState } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { WizardState } from '../IWizardState';
import { IUiBusinessWizard } from '../IUiBusinessWizardV2';
import GetDescription from '../../../common/functions/GetDescription';
import WizardStepWrapper from '../step-wrapper/WizardStepWrapper';
import { IUiOption } from '../../../common/options/IUiOption';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import { TImageData } from '../../../common/image-editor/TImageData';
import InputImage from '../../../common/input-v2/input-image/InputImage';

interface IProps {
  state: WizardState;
  ui: IUiBusinessWizard;
  onUpdate: (state: WizardState) => void;
  onExit: () => void;
}

export default function StepBackgroundImage(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [imageData, setImageData] = useState<TImageData | undefined>(props.state.data.backgroundImage);
  const stepUi = props.ui.stepsDictionary.find(item => item.id == props.state.selectedStep);
  const stepData = props.state.steps.find(item => item.stepId == props.state.selectedStep);
  const prompt = GetDescription(appUiContext, props.state.selectedStep, stepUi?.description);
  const isUpdated = imageData !== props.state.data.backgroundImage;
  const stepIsCompleted = stepData?.isCompleted ? true : false;
  const isValid = imageData ? true : false;
  //---------------------------------------------------------------------------
  const onUpdate = (imageData?: TImageData) => {
    setImageData(imageData);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    let updatedState: WizardState;
    switch (optionId) {
      case "OptionBack":
        updatedState = isUpdated && imageData ?
          props.state.updateBackgroundImage(imageData, "Back") :
          updatedState = props.state.moveToPreviousStep();
        break;
      case "OptionNext":
        updatedState = isUpdated && imageData ?
          props.state.updateBackgroundImage(imageData, "Next") :
          props.state.moveToNextStep(!stepIsCompleted);
        break;
      default:
        throw new Error(`StepBackgroundImage: Unknown optionId ${optionId}`);
    };
    props.onUpdate(updatedState);
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionBack":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowLeft",
          isDisabled: false
        });
        break;
      case "OptionNext":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowRight",
          isDisabled: !isValid,
          isDefault: isValid
        });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      alignLeft={true}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <WizardStepWrapper
      ui={props.ui}
      promptString={prompt}
      optionsElement={formOptions}
      onExit={props.onExit}
    >
      <InputImage
        ui={props.ui.inputLogo}
        data={imageData}
        objectId={props.state.data.adLanguageSetId}
        dataIsSaved={false}
        useExternalLayout={true}
        hideCaption={true}
        hideHint={true}
        stretch={true}
        onImageUpdate={onUpdate}
      />
    </WizardStepWrapper>
  );
}