import React, { useContext, useEffect, useState } from 'react';
import CombineStyles from '../../../../../utils/combineStyles';
import Checkbox from '../../../../common/checkbox/Checkbox';
import DialogOption from '../../../../common/dialog/dialog-option/DialogOption';
import DialogOptions from '../../../../common/dialog/dialog-options/DialogOptions';
import GetName from '../../../../common/functions/GetName';
import { TUiMenuOption } from '../../../../common/menu/menu-content/menu-option/TUiMenuOption';
import { TUiStaffRole } from '../../TUiAdministratorConsole';
import { IStaffRole } from '../user-roles/user-role/UserRole';
//import RegionsEditor from './regions-editor/RegionsEditor';
import styles from './UserRoleEditorPopup.module.css'
import RegionSelector from '../region-selector/RegionSelector';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import PopupDialog from '../../../../common/popup-v2/popup-dialog/PopupDialog';
import FormOptionV2 from '../../../../common/form-options-bar/form-option/FormOptionV2';
import FormOptions from '../../../../common/form-options-bar/FormOptions';

const okButton: TUiMenuOption = {
  id: "OptionOk",
  directoryId: "",
  elementType: "",
  index: 0,
  priorityLevel: 0,
  iconFile: "check",
  caption: { en: "Ok" },
  hint: undefined,
  isDefault: true,
  canHideCaption: false,
  visible: true,
  disabled: false,
  action: null
}

interface IProps {
  staffRole: TUiStaffRole;
  data: IStaffRole;
  onClose: () => void;
  onConfirm: (updatedData: IStaffRole) => void;
}

export default function UserRoleEditorPopup(props: IProps) {
  // We can edit only already assigned role here
  const { appUiContext } = useContext(AppUiContextStore);
  const [staffRoleData, setStaffRoleData] = useState<IStaffRole>({ locales: undefined, regions: undefined });
  const [staffRoleDataInitial, setStaffRoleDataInitial] = useState<IStaffRole>({ locales: undefined, regions: undefined });
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isChanged, setIsChanged] = useState<boolean>();
  //--------------------------------------------------------------------------- Set initial data
  useEffect(() => {
    //console.log(props.data)
    setStaffRoleData(props.data);
    setStaffRoleDataInitial(props.data);
  }, [props.data]);
  //--------------------------------------------------------------------------- Check changes and validity
  useEffect(() => {
    if (staffRoleData.locales) {
      if (staffRoleData.locales.length == 0) {
        setIsValid(false);
      } else {
        setIsValid(true);
      };
    };
    //------------------------------------------------------------------------
    //console.log("change detected")
    //console.log(staffRoleData)
    //console.log(staffRoleDataInitial)
    if (JSON.stringify(staffRoleData) == JSON.stringify(staffRoleDataInitial)) {
      //console.log("actual change is not detected")
      setIsChanged(false);
    } else {
      //console.log("actual change is detected")
      setIsChanged(true);
    };
  }, [staffRoleData]);
  //--------------------------------------------------------------------------- 
  const onSelectLocale = (languageId: string, checked: boolean) => {
    if (staffRoleData.locales) {
      if (checked) {
        const updatedLocales = staffRoleData.locales.concat(languageId);
        setStaffRoleData({
          ...staffRoleData,
          locales: updatedLocales
        });
      } else {
        const updatedLocales = staffRoleData.locales.filter(locale => locale != languageId);
        setStaffRoleData({
          ...staffRoleData,
          locales: updatedLocales
        });
      };
    };
  };
  //---------------------------------------------------------------------------
  const onRegionsUpdate = (updatedRegions: string[]) => {
    //console.log(updatedRegions)
    if (staffRoleData.regions) {
      setStaffRoleData({
        ...staffRoleData,
        regions: updatedRegions
      });
    };
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    //console.log(staffRoleData)
    props.onConfirm(staffRoleData);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OptionOk":
        onConfirm();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const languageElements = appUiContext.dictionaries?.supportedLocales?.map(language => {
    const selected = staffRoleData.locales ? staffRoleData.locales.includes(language.id) : false;
    return (
      <div
        key={language.id}
        className={CombineStyles([styles.listItem, selected ? styles.selected : ""])}
        onClick={() => onSelectLocale(language.id, !selected)}
      >
        <Checkbox
          checked={selected}
          onClick={() => onSelectLocale(language.id, !selected)}
        />
        {GetName(appUiContext, language.id, language.name)}
      </div>
    );
  });
  //---------------------------------------------------------------------------
  const updatedOptions: TUiMenuOption[] = [{
    ...okButton,
    disabled: !isValid || !isChanged
  }];
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id='StaffRolesEditor'
      header={props.staffRole.name}
      isDraggable={false}
      isModal={false}
      onClose={props.onClose}
    >
      <div className={styles.container}>

        {staffRoleData.locales &&
          <React.Fragment>
            <div className={styles.section}>
              {languageElements}
            </div>
            {staffRoleData.locales.length == 0 &&
              <div className={styles.message}>
                At least one locale should be selected!
              </div>}
          </React.Fragment>}

        {staffRoleData.regions && appUiContext.dictionaries?.supportedRegions &&
          <RegionSelector
            ui={appUiContext.dictionaries.supportedRegions}
            data={staffRoleData.regions}
            onUpdate={onRegionsUpdate}
          />}

      </div>

      <FormOptions
        ui={{
          id: "",
          directoryId: "",
          elementType: "",
          visible: true,
          disabled: false,
          options: updatedOptions,
          optionGroups: []
        }}
        onSelect={onOptionSelect}
      />
      
    </PopupDialog>
  );
}