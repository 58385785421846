interface IProps {
  //expanded: boolean;
}

export default function IconReset(props: IProps) {
  //---------------------------------------------------------------------------
  //const transform = props.expanded ? "scale(1,-1) translate(0,-24)" : undefined;
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="1.2em" height="1.2em"
      viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M13 3a9 9 0 0 0-9 9H1l3.89 3.89l.07.14L9 12H6a7 7 0 0 1 7-7a7 7 0 0 1 7 7a7 7 0 0 1-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.9 8.9 0 0 0 13 21a9 9 0 0 0 9-9a9 9 0 0 0-9-9" />
    </svg>
  );
}