import { IServiceWorkerContext, IServiceWorkerMessage, ServiceWorkerManager } from "./ServiceWorkerContext";

export function ServiceWorkerContextReducer(state: IServiceWorkerContext, message: IServiceWorkerMessage) {
  try {
    switch (message.type) {
      case "updateBusiness":
      case "sendForApproval":
        state.manager?.processBusiness(message.data);
        return state;
      case 'updateBusinessResponse':
        return {...state};
      //-------------------------------------------------------------------------
      default:
        console.error("ServiceWorkerContextReducer: unexpected message type:", message.type);
        return state;
    }
  }
  finally {

  }
};
