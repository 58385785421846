import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { IUiInteractiveForm } from '../../../forms/IUiInteractiveForm';
import GetCaption from '../../../functions/GetCaption';
import PopupDialog from '../../../popup-v2/popup-dialog/PopupDialog';
import styles from './PopupParagraphLayout.module.css';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { IUiOption } from '../../../options/IUiOption';
import FormOptions from '../../../form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../menu/menu-content/TUiMenuContent';
import { LayoutIcon } from './LayoutIcon';
import { EPositionHorizontal, EPositionVertical } from '../../../design-templates/IDesignTemplateImage';
import { IAdModuleParagraph, IAdUnitImage, IAdUnitText } from '../../../ad-modules/IAdModule';
import CombineStyles from '../../../../../utils/combineStyles';
import GetHint from '../../../functions/GetHint';
import { ClassAdUnitStatus } from '../../../ad-modules/IAdUnit';

//export type EImageLayout = "NoImage" | "ImageOnly" | "Top" | "Bottom" | "Left" | "Right" | "TopLeft" | "TopRight" | "BottomLeft" | "BottomRight";

export enum EImageLayout {
  TopLeft = "TopLeft",
  TopRight = "TopRight",
  BottomLeft = "BottomLeft",
  BottomRight = "BottomRight",
  Top = "Top",
  Bottom = "Bottom",
  Left = "Left",
  Right = "Right",
  NoImage = "NoImage",
  ImageOnly = "ImageOnly"
}

export interface IImagePosition {
  positionH?: EPositionHorizontal;
  positionV?: EPositionVertical;
}

interface IProps {
  data: IAdModuleParagraph;
  onSelect: (updatedParagraph: IAdModuleParagraph) => void;
  onClose: () => void;
}

export default function PopupParagraphLayout(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [state, setState] = useState<EImageLayout>();
  const layoutIdInitial = useRef<EImageLayout>();
  //--------------------------------------------------------------------------- Layout Id
  useEffect(() => {
    let layoutId = EImageLayout.NoImage;
    if (props.data.image) {
      if (!props.data.text) {
        layoutId = EImageLayout.ImageOnly;
      } else if (!props.data.image.positionH && !props.data.image.positionV) {
        // There is no actual image yet, so use default position
        layoutId = EImageLayout.TopLeft; // NOTE: maybe get it from the design template
      } else {
        layoutId = `${props.data.image.positionV ?? ""}${props.data.image.positionH ?? ""}` as EImageLayout;
      };
    } else {
      layoutId = EImageLayout.NoImage;
    };
    setState(layoutId);
    layoutIdInitial.current = layoutId;
  }, [props.data]);
  //---------------------------------------------------------------------------
  const getUpdatedParagraph = (layoutId: EImageLayout): IAdModuleParagraph => {
    let updatedImage: IAdUnitImage | undefined = props.data.image ?? {
      type: "Image",
      status: new ClassAdUnitStatus("Draft")
    };
    let updatedText: IAdUnitText | undefined = props.data.text ?? {
      type: "Text",
      status: new ClassAdUnitStatus("Draft")
    };
    //-------------------------------------------------------------------------
    if (layoutId === EImageLayout.NoImage) {
      // Remove image unit, it's going to be only text
      updatedImage = undefined;
    } else {
      switch (layoutId) {
        case 'TopLeft':
          updatedImage.positionV = 'Top';
          updatedImage.positionH = 'Left';
          break;
        case 'TopRight':
          updatedImage.positionV = 'Top';
          updatedImage.positionH = 'Right';
          break;
        case 'BottomLeft':
          updatedImage.positionV = 'Bottom';
          updatedImage.positionH = 'Left';
          break;
        case 'BottomRight':
          updatedImage.positionV = 'Bottom';
          updatedImage.positionH = 'Right';
          break;
        case 'Top':
          updatedImage.positionV = 'Top';
          updatedImage.positionH = undefined;
          break;
        case 'Bottom':
          updatedImage.positionV = 'Bottom';
          updatedImage.positionH = undefined;
          break;
        case 'Left':
          updatedImage.positionV = undefined;
          updatedImage.positionH = 'Left';
          break;
        case 'Right':
          updatedImage.positionV = undefined;
          updatedImage.positionH = 'Right';
          break;
      };
    };
    //-------------------------------------------------------------------------
    if (layoutId === EImageLayout.ImageOnly) {
      updatedText = undefined;
    };
    //-------------------------------------------------------------------------
    const updatedParagraph = {
      ...props.data,
      image: updatedImage,
      text: updatedText
    };
    return updatedParagraph;
  };
  //---------------------------------------------------------------------------
  const onLayoutSelect = (layout: EImageLayout) => {
    setState(layout);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    if (!state) return;
    switch (optionId) {
      case "OptionOk":
        props.onSelect(getUpdatedParagraph(state));
        break;
      case "OptionCancel":
        props.onClose();
        break;
    };
  };
  //--------------------------------------------------------------------------- Render layout options
  const layoutOptions: ReactNode[] = [];
  let i = 1;
  for (let layoutString in EImageLayout) {
    const layoutId = layoutString as EImageLayout;
    layoutOptions.push(
      <div
        key={layoutString}
        className={CombineStyles([
          styles.layoutOption,
          i == 1 ? styles.firstColumn : "",
          state === layoutId ? styles.selected : ""
        ])}
        onClick={() => onLayoutSelect(layoutId)}>
        <LayoutIcon layout={layoutId} />
      </div>
    );
    i = (i == 1) ? i + 1 : 1;
  };
  //--------------------------------------------------------------------------- Render form options
  const updatedOptions: IUiOption[] = [];
  tmpUiPopupParagraphLayout.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionOk":
        updatedOptions.push({
           ...option,
            iconId: "check",
            isDefault: state !== layoutIdInitial.current,
            isDisabled: state === layoutIdInitial.current
           });
        break;
      case "OptionCancel":
        updatedOptions.push({ ...option, iconId: "close" });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...tmpUiPopupParagraphLayout.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id={tmpUiPopupParagraphLayout.id}
      header={GetCaption(appUiContext, tmpUiPopupParagraphLayout.id, tmpUiPopupParagraphLayout.caption)}
      toolbarContent={formOptions}
      isModal={true}
      isDraggable={false}
      onClose={props.onClose}
    >
      <div className={styles.description}>
        {GetHint(appUiContext, tmpUiPopupParagraphLayout.id, tmpUiPopupParagraphLayout.hint)}
      </div>
      <div className={styles.container}>
        {layoutOptions}
      </div>
    </PopupDialog>
  );
}

const tmpUiPopupParagraphLayout: IUiInteractiveForm = {
  id: "TmpUiPopupParagraphLayout",
  directoryId: "",
  elementType: "",
  caption: undefined,
  hint: undefined,
  menuContent: {
    id: "TmpUiPopupParagraphLayout.MenuContent",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionOk",
      directoryId: "",
      elementType: "",
      caption: { en: "Ok" },
      hint: undefined
    }, {
      id: "OptionCancel",
      directoryId: "",
      elementType: "",
      caption: { en: "Cancel" },
      hint: undefined
    }]
  }
};