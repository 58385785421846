import styles from './NotImplemented.module.css';

interface IProps {
}

export default function NotImplemented(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      Sorry, but this functionality is not implemented yet
    </div>
  );
}