export const defaultPagePath = "default"; 

export function getPathToDisplay(locationPathname: string, targetPath?: string): string | undefined {
  const regionalHomepageUrl = locationPathname.substring(1);
  let pathToDisplay: string | undefined = undefined;
  if (targetPath){
    if (targetPath.toLowerCase() != defaultPagePath) 
      pathToDisplay = locationPathname.replace(regionalHomepageUrl, targetPath);
    else if (regionalHomepageUrl.toLowerCase() == defaultPagePath)
      pathToDisplay = location.pathname.replace(regionalHomepageUrl, "");
  }
  return pathToDisplay;
}