import React from "react";
import styles from "./PageStatusIcon.module.css";
import { IAdStatus } from "../../../../ad-content-editor/IAdLanguageSet";

interface Props {
  status: IAdStatus;
}

export function PageStatusIcon(props: Props) {
  const iconSize = 30;
  //---------------------------------------------------------------------------
  const fontColour = "var(--clr_font_base)";
  var bkgrColour = props.status.statusId == "NA_PNDG_APPROVAL" ? "var(--clr_accent_attention)" : "var(--clr_accent_neutral)";
  if (props.status.isInTrouble) bkgrColour = "var(--clr_accent_wrong)";
  else if (props.status.isPublished) bkgrColour = "var(--clr_accent_main)";
  //---------------------------------------------------------------------------
  if (props.status.isReadonly)
    return (
      // lock image
      <svg
        className={styles.statusicon}
        width={`${iconSize}`}
        height={`${iconSize}`}
        version="1.1"
        viewBox="0 0 66.146 66.146"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(0 -230.85)">
          <circle
            cx="33.073"
            cy="263.93"
            r="33.073"
            fill={bkgrColour}
            opacity=".8"
          />
          <path
            d="m46.916 260.35h-1.8877v-5.6631c0-6.5912-5.3642-11.955-11.955-11.955-6.5912 0-11.955 5.3642-11.955 11.955v5.6631h-1.8877c-2.0843 0-3.7754 1.691-3.7754 3.7754v15.102c0 2.0843 1.6911 3.7754 3.7754 3.7754h27.686c2.0843 0 3.7754-1.6911 3.7754-3.7754v-15.102c0-2.0843-1.6911-3.7754-3.7754-3.7754zm-8.18 0h-11.326v-5.6631c0-3.1226 2.5405-5.6631 5.6631-5.6631s5.6631 2.5405 5.6631 5.6631z"
            fill={fontColour} //"currentColor"
          />
        </g>
      </svg>
    );
  else
    return (
      // pencil image
      <svg
        className={styles.statusicon}
        width={`${iconSize}`}
        height={`${iconSize}`}
        version="1.1"
        viewBox="0 0 66.146 66.146"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(0 -230.85)" fill={bkgrColour}>
          <circle cx="33.073" cy="263.93" r="33.073" opacity=".8" />
          <g
            transform="matrix(1.289 0 0 1.289 10.475 239.84)"
            fill={fontColour} //"currentColor"
            opacity="1"
          >
            <path d="m4.22 23.2-1.9 8.2a2.06 2.06 0 0 0 2 2.5 2.14 2.14 0 0 0 0.43 0l8.25-1.9 15.84-15.78-8.84-8.82z" />
            <path d="m33.82 8.32-5.9-5.9a2.07 2.07 0 0 0-2.92 0l-3.28 3.28 8.83 8.83 3.28-3.28a2.07 2.07 0 0 0-0.01-2.93z" />
          </g>
        </g>
      </svg>
    );
}
