import { iconsSrc } from '../../../uielements/icons';
import styles from './OptionInline.module.css';

interface IProps {
  iconId: string;
}

export default function OptionInline(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <span className={styles.inlineIcon}>
      <span className="iconify" data-icon={iconsSrc[props.iconId]} />
    </span>
  );
}