import React from "react";
import CombineStyles from "../../../utils/combineStyles";
import { iconsSrc } from "../../uielements/icons";
import styles from "./Icon.module.css";

export enum IconSize {
  xsmall, // !!!Remove (it's used in WikiInputURL)
  Small,
  Large,
}

interface IProps {
  id?: string;
  imageName: string | null;
  sizeEm?: number;
  size?: IconSize;
  reserveSpace?: boolean;
  visible?: boolean;
  spin?: boolean;
  onClick?: () => void;
}

export default function Icon(props: IProps) {
  const visible = props.visible == undefined ? true : props.visible;
  const spin = (props.spin === undefined) ? false : props.spin;
  //---------------------------------------------------------------------------
  const icon = props.imageName ?
    <span className="iconify" data-icon={iconsSrc[props.imageName]} />
    :
    <span />;
  //---------------------------------------------------------------------------
  // const containerStyles = spin ?
  //   `style ${styles.spin}`
  //   :
  //   CombineStyles([
  //     props.size === IconSize.Large ? styles.largeContainer : styles.smallContainer,
  //     props.reserveSpace ? styles.reserveSpace : "" // Provide empty box if icon is not provided but space should be reserved
  //   ]);
  const containerStyles = props.size ?
    CombineStyles([
      props.size === IconSize.Large ? styles.largeContainer : styles.smallContainer,
      props.reserveSpace ? styles.reserveSpace : "", // Provide empty box if icon is not provided but space should be reserved
      spin ? styles.spin : ""
    ]) :
    CombineStyles([
      styles.default,
      spin ? styles.spin : ""
    ])
  //---------------------------------------------------------------------------
  if (visible)
    return (
      <div
        id={props.id}
        className={containerStyles}
        onClick={props.onClick}
      >
        {icon}
      </div>
    );
  else return null;
}
