import { EDraftItemState } from "../EDraftItemState";
import { IDirectoryDraftItemStateGroup } from "./IDirectoryDraftItemStateGroup";

export const dictonaryDraftStatesFullDetails: IDirectoryDraftItemStateGroup[] = [{
  id: 'nothing',
  name: "No changes/errors",
  states: [{
    draftState: EDraftItemState.IsNothing,
    iconId: 'circle'
  }, {
    draftState: EDraftItemState.HasNothing,
    iconId: 'attention'
  }]
}, {
  id: 'reordered',
  name: "Re-ordered",
  color: 'yellowgreen',
  states: [{
    draftState: EDraftItemState.IsReordered,
    iconId: 'circle'
  }, {
    draftState: EDraftItemState.HasReordered,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}, {
  id: 'editedt',
  name: "Edited Translation",
  color: 'mediumspringgreen',
  states: [{
    draftState: EDraftItemState.IsTranslationEdited,
    iconId: 'circle'
  }, {
    draftState: EDraftItemState.HasTranslationEdited,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}, {
  id: 'edited',
  name: "Edited",
  color: 'blue',
  states: [{
    draftState: EDraftItemState.IsEdited,
    iconId: 'circle'
  }, {
    draftState: EDraftItemState.HasEdited,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}, {
  id: 'repositioned',
  name: "Repositioned",
  color: 'magenta',
  states: [{
    draftState: EDraftItemState.IsRepositionedNew,
    iconId: 'circle'
  }, {
    draftState: EDraftItemState.HasRepositionedNew,
    iconId: 'newLabel',
    fontColor: 'inherit'
  }, {
    draftState: EDraftItemState.IsRepositionedOld,
    iconId: 'circle',
    textDecoration: 'line-through'
  }, {
    draftState: EDraftItemState.HasRepositionedOld,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}, {
  id: 'new',
  name: "New",
  color: 'mediumpurple',
  states: [{
    draftState: EDraftItemState.IsNew,
    iconId: 'circle'
  }, {
    draftState: EDraftItemState.HasNew,
    iconId: 'newLabel',
    fontColor: 'inherit'
  }]
}, {
  id: 'notranslation',
  name: "No Translation",
  color: 'orange',
  states: [{
    draftState: EDraftItemState.HasNoTranslation,
    iconId: 'attention',
    fontColor: 'undefined'
  }, {
    draftState: EDraftItemState.IsNoTranslation,
    iconId: 'circle'
  }]
}, {
  id: 'incorrectChildren',
  name: "Incorrect Children",
  color: 'cyan',
  states: [{
    draftState: EDraftItemState.HasOnlyChild,
    iconId: 'attention'
  }, {
    draftState: EDraftItemState.HasNoChildren,
    iconId: 'zero'
  }]
}, {
  id: 'deleted',
  name: "Deactivated",
  color: 'tomato',
  states: [{
    draftState: EDraftItemState.IsDeleted,
    iconId: 'circle',
    textDecoration: 'line-through'
  }, {
    draftState: EDraftItemState.HasDeleted,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}]