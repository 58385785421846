import { useContext } from "react";
import { IUiInteractiveForm } from "../../forms/IUiInteractiveForm";
import PopupMessage from "../../popup-v2/popup-message/PopupMessage";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import GetCaption from "../../functions/GetCaption";
import GetHint from "../../functions/GetHint";
import { TUiMenuContent } from "../../menu/menu-content/TUiMenuContent";
import { TUiMenuOption } from "../../menu/menu-content/menu-option/TUiMenuOption";

interface IProps {
  ui: IUiInteractiveForm;
  show: boolean;
  onSave: () => void;
  onDiscard: () => void;
  onClose: () => void;
}

export default function UnsavedChangesDialog(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OptionDiscard":
        props.onDiscard();
        break;
      case "OptionSave":
        props.onSave();
        break;
    };
  }
  //---------------------------------------------------------------------------
  const mc = props.ui.menuContent;
  const menuContent: TUiMenuContent = {
    id: mc.id,
    directoryId: mc.directoryId,
    elementType: mc.elementType,
    hint: mc.hint,
    options: mc.options.map(o => ({
      id: o.id,
      directoryId: o.directoryId,
      elementType: o.elementType,
      caption: o.caption,
      hint: o.hint,
      iconFile: o.iconId,
      isDefault: o.isDefault,
      disabled: o.isDisabled,
      index: 0,
      priorityLevel: 0,
      canHideCaption: false,
      visible: true,
      action: undefined
    } as TUiMenuOption)),
    optionGroups: [],
    visible: true,
    disabled: false
  };
  //---------------------------------------------------------------------------
  return (
    <PopupMessage
      type='Confirmation'
      id={props.ui.id}
      header={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      message={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      optionsMenuContent={menuContent}
      show={props.show}
      onOptionSelect={onOptionSelect}
      onCancel={() => props.onClose()}
    />
  );
}