import { useMsal } from "@azure/msal-react";
import React, { useRef } from "react";
import { useContext, useEffect, useState } from "react";
import { AppAuthContextStore } from "../../../../context/app-auth-context/AppAuthContext";
import { apiPostPrivate, checkResponse } from "../../../../utils/api";
import { apiBasePath, getStaffUsersByRole } from "../../../../utils/apiPathConstant";
import WikiSpinner from "../../../app-layout/spinner/wikiSpinner";
import { TStaffUserListItem } from "./user-list-card/TStaffUserListItem";
import { AdminConsoleContext } from "../../admin-console-context/AdminConsoleContextProvider";
import UserListCard from "./user-list-card/UserListCard";
import { stringFormatter } from "../../../../utils/common";
import styles from "./UserList.module.css";
import NewUserPopup from "./new-user-popup/NewUserPopup";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import { pathAdminConsole, pathAdministratorConsole } from "../../AdminConsole";
import { optionIdUsersList, pathUserList } from "../AdministratorConsole";
import FormOptions from "../../../common/form-options-bar/FormOptions";
import { useAbortController } from "../../../../hooks/useAbortController";
import { IUiOption } from "../../../common/options/IUiOption";
import { TUiBreadcrumb } from "../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { hardcodedUiUserList } from "./IUiUserList";
import { getTUiMenuContent } from "../../../common/menu/menu-content/TUiMenuContent";
import GetHint from "../../../common/functions/GetHint";
import { IUiStaffRole } from "../IUiAdministratorConsole";
import ConsoleContent from "../../../common/console-layout/console-content/ConsoleContent";

interface IProps {
  staffRoles: IUiStaffRole[];
  optionsForBreadcrumb?: IUiOption[];
}

export default function UserList(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const { adminConsoleContext, adminConsoleContextDispatch } = useContext(AdminConsoleContext);
  const { appUiContextDispatch } = useContext(AppUiContextStore);
  const [isLoading, setIsLoading] = useState(true);
  const [currentUserList, setCurrentUserList] = useState<TStaffUserListItem[]>();
  const [popupOpen, setPopupOpen] = useState(false);
  const selectRef = useRef({} as HTMLSelectElement);
  const abortController = useAbortController("UserList");
  //--------------------------------------------------------------------------- Filter, Abortcontroller
  useEffect(() => {
    if (!adminConsoleContext.userListFilterRole)
      adminConsoleContextDispatch({ type: "SetUserListFilterRole", value: props.staffRoles[0] });
    //-------------------------------------------------------------------------
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdUsersList);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        caption: option.caption,
        path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathUserList}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
  }, [props.optionsForBreadcrumb]);
  //--------------------------------------------------------------------------- Get users list
  useEffect(() => {
    if (!adminConsoleContext.userListFilterRole)
      return;
    if (!adminConsoleContext.needToRefreshRequestList) {
      setIsLoading(false);
      return;
    }
    //-------------------------------------------------------------------------
    // Retrieve users from API
    setIsLoading(true);
    let controller = abortController.newController("getStaffUsersByRole");
    apiPostPrivate(
      instance,
      appAuthContext.config,
      `${apiBasePath}${stringFormatter(getStaffUsersByRole, [adminConsoleContext.userListFilterRole.groupName])}`,
      controller.signal
    )
      .then(response => {
        checkResponse(response, "getStaffUsersByRole", controller.aborted);
        setCurrentUserList(response?.content);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        !controller.aborted && setIsLoading(false);
      });
  }, [adminConsoleContext.userListFilterRole]);
  //---------------------------------------------------------------------------
  const onSelectChange = () => {
    let selectedRole: string = selectRef.current.value;
    console.log(selectedRole)
    //setFilterRole(props.staffRoles.find(item => item.groupName == selectedRole));
    const selectedValue = props.staffRoles.find(item => item.groupName == selectedRole);
    if (selectedValue)
      adminConsoleContextDispatch({ type: "SetUserListFilterRole", value: selectedValue });
  };
  //---------------------------------------------------------------------------
  const onAddNewUser = () => {
    setPopupOpen(true);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "StaffUsersList.MenuContent.AddUser":
        onAddNewUser();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const filterOptions = props.staffRoles.map(staffRole => (
    <option
      key={staffRole.groupName}
      value={staffRole.groupName}
    >
      {staffRole.name}
    </option>
  ));
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  hardcodedUiUserList.menuContent.options.forEach(option => {
    switch (option.id) {
      case "StaffUsersList.MenuContent.AddUser":
        updatedOptions.push({
          ...option,
          iconId: "addUser",
          isDefault: true
        })
        break;
    };
  });
  const updatedMenuContent = getTUiMenuContent({ ...hardcodedUiUserList.menuContent, options: updatedOptions });
  const listOptions = updatedMenuContent ?
    <FormOptions
      ui={updatedMenuContent}
      optionIdVisibleOnMobile={"StaffUsersList.MenuContent.AddUser"}
      onSelect={onOptionSelect}
    />
    : undefined;
  //---------------------------------------------------------------------------
  // Compose user list
  const items = currentUserList?.map((item: TStaffUserListItem) => (
    <UserListCard user={item} key={item.id} />
  ));
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={GetHint(appUiContext, hardcodedUiUserList.id, hardcodedUiUserList.hint)}
      formOptions={listOptions}
      containerWidthPercent={70}
    >
      <WikiSpinner show={isLoading} />
      <div className={styles.headerContainer}>
        <label>Select a role to filter user list</label>
        <select
          title="SelectRole"
          name="Select role"
          ref={selectRef}
          style={{ outline: 'none' }}
          value={adminConsoleContext.userListFilterRole?.groupName}
          onChange={onSelectChange}
        >
          {filterOptions}
        </select>
      </div>

      <div className={styles.list}>
      {items}
      </div>

      {popupOpen &&
        <NewUserPopup
          onClose={() => setPopupOpen(false)}
        />}

    </ConsoleContent>
  );
}
