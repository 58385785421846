import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
  ScreenType,
  useAppScreenContext,
} from "../../../../context/app-screen-context/AppScreenProvider";
import useNavigateWithContext from "../../../../hooks/useNavigateWithContext";
import { TUiMenuOption } from "../../menu/menu-content/menu-option/TUiMenuOption";
import { TUiMenuContent } from "../../menu/menu-content/TUiMenuContent";
import ConsoleMenuOption from "./console-menu-option/ConsoleMenuOption";
import ConsoleMenuTogglerIcon from "./console-menu-toggler/ConsoleMenuTogglerIcon";
import styles from "./ConsoleMenu.module.css";

function GetSelectedOption(options: TUiMenuOption[], location: string) {
  //console.log(options)
  const selectedItem = options.find((option) => {
    //console.log(option.action);
    //console.log(location);
    if ((option.action === location) ||(location.includes(option.action))) return true;
    //else return option.isDefault === true;
  });
  //console.log(selectedItem)
  if (selectedItem) return selectedItem;
  else return options[0];
}

interface IProps {
  ui: TUiMenuContent;
  defaultPath?: string | null;
  hash?: boolean;
  blinkingOptionPath?: string;
}

export default function ConsoleMenu(props: IProps) {
  //---------------------------------------------------------------------------
  const location = useLocation();
  const screenType = useAppScreenContext();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedOption, setSelectedOption] = useState<TUiMenuOption>();
  const navigate = useNavigateWithContext();
  //---------------------------------------------------------------------------
  const handleOptionSelection = (option: TUiMenuOption) => {
    setSelectedOption(option);
    //-------------------------------------------------------------------------
    // Redirect to the specified route
    //console.log(option)
    navigate(
      option.action
        ? option.action
        : props.defaultPath
          ? props.defaultPath
          : "/"
    );
  };
  //---------------------------------------------------------------------------
  const toggleLayout = () => {
    setCollapsed(!collapsed);
  };
  //---------------------------------------------------------------------------
  useEffect(() => {
    let l = location.pathname;
    if (props.hash == true) 
      l += location.hash;
    setSelectedOption(GetSelectedOption(props.ui.options, l));
  }, [props.ui.options, location]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    setCollapsed(screenType === ScreenType.Mobile ? true : collapsed);
  }, [screenType]);
  //---------------------------------------------------------------------------
  // Menu options
  const menuItems = props.ui.options.map((option) => (
    <ConsoleMenuOption
      key={option.id}
      ui={option}
      isSelected={option.id === selectedOption?.id}
      isCollapsed={collapsed}
      isBlinking={props.blinkingOptionPath ? option.action.endsWith(props.blinkingOptionPath) : false}
      onSelect={handleOptionSelection}
    />
  ));
  //---------------------------------------------------------------------------
  const containerStyles = collapsed ? styles.containerCollapsed : styles.container;
  //---------------------------------------------------------------------------
  const contentStyles = screenType === ScreenType.Desktop
    ? styles.contentDesktop
    : styles.contentMobile;
  //---------------------------------------------------------------------------
  return (
    <div className={containerStyles}>
      {/* Adjust menu layout to the screen type */}
      <div className={contentStyles}>
        {menuItems}
      </div>
      {/* Display collapse panel only in case of desktop screen */}
      {screenType === ScreenType.Desktop && (
        <div className={styles.bottom} onClick={toggleLayout}>
          <ConsoleMenuTogglerIcon isCollapsed={collapsed} />
        </div>
      )}
    </div>
  );
}
