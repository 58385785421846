import React, { useContext, useEffect } from "react";
import { useRef, useState } from "react";
import { ScreenType, useAppScreenContext } from "../../../../../context/app-screen-context/AppScreenProvider";
import useRefDimensions from "../../../../../hooks/useRefDimensions";
import GetName from "../../../functions/GetName";
import PopupHeader from "../../../popup-v2/popup-header/PopupHeader";
import Popup from "../../../popup-v2/popup/Popup";
import InputText from "../../input-text/InputText";
import InputDropdownItem from "../input-dropdown-item/InputDropdownItem";
import { IUiListItem } from "../InputDropdown";
import { TUiInputDropdown } from "../TUiInputDropdown";
import styles from "./InputDropdownPopup.module.css";
import GetPlaceholder from "../../../functions/GetPlaceholder";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";
import PopupTest from "../../../popup-v2/popup/PopupTest";

interface IProps {
  ui: TUiInputDropdown;
  id: string;
  anchorId: string;
  selectedOptionId?: string;
  isAutoComplete: boolean;
  sortSuggestions?: boolean;
  hideDropdownDescriptions?: boolean;
  onSelect: (selectedOption: IUiListItem) => void;
  onEmptyValueSelect?: () => void;
  onClose: () => void;
  onBack?: () => void;
}

export default function InputDropdownPopup(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const [availableValues, setAvailableValues] = useState<IUiListItem[]>([]);
  const [filterValue, setFilterValue] = useState("");
  const [lastValidFilterValue, setLastValidFilterValue] = useState("");
  const [counter, setCounter] = useState(0);
  const dictionaryValues = useRef<IUiListItem[]>([]);
  const anchorRef = useRef(props.anchorId ? document.getElementById(props.anchorId) as HTMLDivElement : null);
  const anchorDimensions = useRefDimensions(anchorRef);
  //---------------------------------------------------------------------------
  const valueIsRequired = props.ui.validation?.required == undefined ? false : props.ui.validation.required;
  const sortSuggestions = props.sortSuggestions == undefined ? false : props.sortSuggestions;
  const hideDropdownDescriptions = props.hideDropdownDescriptions == undefined ? false : props.hideDropdownDescriptions;
  const noValueOption: IUiListItem = {
    id: "NoValue",
    name: props.ui.placeholder,
    selected: false
  }
  //--------------------------------------------------------------------------- Prepare available values: add search string
  useEffect(() => {
    // Search string makes search agnostic to the current locale (#4612)
    if (props.ui.availableValues && props.ui.availableValues.length > 0 && props.isAutoComplete) {
      const availableValues: IUiListItem[] = [];
      props.ui.availableValues.forEach((item: IUiListItem) => {
        let searchString = '';
        if (item.name) {
          Object.entries(item.name).forEach(([key, value]) => {
            searchString = searchString + value + ',';
          });
        } else {
          searchString = item.id;
        };
        availableValues.push({
          ...item,
          searchString: searchString.toLocaleLowerCase()
        });
      });
      dictionaryValues.current = availableValues;
      setAvailableValues(availableValues);
    } else {
      setAvailableValues(props.ui.availableValues);
    };
  }, [props.ui.availableValues]);
  //--------------------------------------------------------------------------- If user types something
  const onInputChange = (updatedValue: string) => {
    // Check if input is valid, i.e. there are matching suggestions   
    // const filteredSuggestions = props.ui.availableValues.filter(item =>
    //   (item.name[appUiContext.locale.localeId] as string).toLowerCase().includes(updatedValue.toLowerCase()));
    const filteredSuggestions = dictionaryValues.current.filter(item => (
      item.searchString.includes(updatedValue.toLowerCase())
    ));
    //-------------------------------------------------------------------------
    if (filteredSuggestions.length > 0) {
      setAvailableValues(filteredSuggestions);
      setFilterValue(updatedValue);
      setLastValidFilterValue(updatedValue);
    } else {
      // We need this to make input display last valid search string
      // This way we every time pass a new value, which makes input to rerender
      // in order to suppress invalid characters and display the same unchanged search string
      const newCounterValue = counter + 1;
      setCounter(newCounterValue);
      setFilterValue(`${lastValidFilterValue}${newCounterValue}`)
    };
  };
  //---------------------------------------------------------------------------
  // If user selects an item from the list of suggestions
  const onOptionSelect = (option: IUiListItem) => {
    //console.log(option)
    props.onSelect(option);
  };
  //---------------------------------------------------------------------------
  const onNoValueSelect = () => {
    props.onEmptyValueSelect && props.onEmptyValueSelect();
  };
  //---------------------------------------------------------------------------
  const options = availableValues
    .sort((a, b) =>
      sortSuggestions ? GetName(appUiContext, a.id, a.name).localeCompare(GetName(appUiContext, b.id, b.name)) : 0)
    .map((item: IUiListItem) => (
      <InputDropdownItem
        key={item.id}
        ui={{
          ...item,
          selected: item.id == props.selectedOptionId
        }}
        isMultiSelect={false}
        hideDescription={hideDropdownDescriptions}
        onClick={onOptionSelect}
      />
    ));
  //---------------------------------------------------------------------------
  return (
    <PopupTest
      id={props.id}
      anchorId={props.anchorId}
      isModal={false}
      //isDraggable={false}
      closeOnClickOutside={true}
      fixHeight={props.isAutoComplete} // Set fixed height, so window would not shrink while user types search string
      onClose={props.onClose}
    >
      {screenType == ScreenType.Mobile &&
        <PopupHeader
          header={props.ui.id}
          onClose={props.onClose}
          onBack={props.onBack}
        />}
      <div
        className={styles.container}
        style={{ minWidth: `${anchorDimensions?.width}px` }}
      >
        {props.isAutoComplete && props.ui.searchInput &&
          <div className={styles.input}>
            {/* {GetHint(props.uiSearchInput.id, props.uiSearchInput.hint)} */}
            <InputText
              ui={props.ui.searchInput}
              data={filterValue}
              counter={counter}
              hideCaption={true}
              hideHint={true}
              onUpdate={onInputChange}
            />
          </div>}
        {!valueIsRequired &&
          <div
            className={styles.emptyValueOption}
            onClick={onNoValueSelect}
          >
            {GetPlaceholder(appUiContext, props.ui.id, props.ui.placeholder)}
            {/* <InputDropdownItem
              ui={noValueOption}
              isMultiSelect={false}
              hideDescription={true}
              onClick={onNoValueSelect}
            /> */}
          </div>}
        <div className={styles.list}>
          {options}
        </div>
      </div>
    </PopupTest>
  );
}