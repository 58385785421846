import { IAdUnitText } from "../../../../common/ad-modules/IAdModule";
import { ClassAdUnitStatus, IAdUnitStatus } from "../../../../common/ad-modules/IAdUnit";
import { TGeoPoint } from "../../../../common/map/geo-json/GeoJson";
import { IMapViewport } from "./BusinessLocationEditor";
//import { v4 as uuidv4 } from "uuid";

export const newId = "new";

export interface IBusinessLocationData<T> {
  locations: IObjectLocation<T>[];
  mapSettings?: IMapSettings;
}

export class ClassBusinessLocationData implements IBusinessLocationData<string> {
  locations!: IObjectLocation<string>[];
  mapSettings?: IMapSettings;
  //---------------------------------------------------------------------------
  constructor(data?: IBusinessLocationData<string | IAdUnitText>, addressBlockMode?: boolean) {
    // AddressblockMode means object locations contains IAdUnitText instead of string
    if (!data) {
      this.locations = [];
      this.mapSettings = { defaultZoom: 0 };
    } else if (addressBlockMode) {
      this.mapSettings = data.mapSettings;
      this.locations = data.locations.map(location => {
        const loc = location as IObjectLocation<IAdUnitText>;
        return {
          id: loc.id,
          point: loc.point,
          name: loc.name?.text,
          addressText: loc.addressText?.text,
          status: loc.status
        };
      });
    } else {
      Object.assign(this, data);
    };
  };
  //---------------------------------------------------------------------------
  getMapViewPort(userLocation?: GeolocationPosition): IMapViewport {
    let mapViewport: IMapViewport;
    if (this.mapSettings?.defaultBounds) {
      // If default bounds are provided, those bounds are primary for the map viewport
      mapViewport = {
        bounds: this.mapSettings.defaultBounds
      };
    } else if (this.locations && this.locations.length > 0) {
      // If there are business locations, the map viewport is defined by those locations
      mapViewport = {
        fitBusinessMarkers: true
      };
    } else if (userLocation) {
      // There are no custom bounds, no business locations, but we have user location
      mapViewport = {
        center: { lng: userLocation.coords.longitude, lat: userLocation.coords.latitude },
        zoom: 7
      };
    } else {
      // No custom bounds, no business locations, no user location, so the map is rendered with default settings
      mapViewport = {
        center: { lng: 0, lat: 0 },
        zoom: 1
      };
    };
    return mapViewport;

    /* // Default map center if there are no locations
    const defaultCenter = userLocation ? [userLocation.coords.longitude, userLocation.coords.latitude] : [0, 0];
    var center: google.maps.LatLngLiteral | undefined = { lng: defaultCenter[0], lat: defaultCenter[1] };
    // Number of locations
    const locationsCount = this.locations ? this.locations.length : 0;
    // New location
    const newLocation = this.locations?.find(location => location.id == newId);
    // Default zoom
    const defaultZoom = this.mapSettings?.defaultZoom ? this.mapSettings.defaultZoom : undefined;
    let zoom: number | undefined = defaultZoom;
    // Default autobounds
    let autoBounds = false;
    // Two locations with the same coords
    let twoIdenticalLocations = false;
    //-------------------------------------------------------------------------
    if (locationsCount == 2) {
      // Check if new locatoins are the same
      if (JSON.stringify(this.locations[0].point.coordinates) == JSON.stringify(this.locations[1].point.coordinates)) {
        twoIdenticalLocations = true;
      };
    };
    //-------------------------------------------------------------------------
    // Autobounds: if there are no default zoom + there are more than 1 location with different coordinates
    if (locationsCount > 1 && !twoIdenticalLocations) {
      autoBounds = true;
    };
    //-------------------------------------------------------------------------
    // Center: if there are no autobounds
    if (autoBounds) {
      center = undefined;
    } else if (locationsCount == 1 || twoIdenticalLocations) {
      // It's one location or two identical locations
      const location = this.locations[0];
      center = { lng: location.point.coordinates[0], lat: location.point.coordinates[1] };
    };
    //-------------------------------------------------------------------------
    //console.log(zoom)
    if (autoBounds) {
      //zoom = undefined;
    } else if (!zoom) {
      zoom = 7;
    };
    //-------------------------------------------------------------------------
    //console.log(locationsCount)
    mapViewport = {
      center: center,
      zoom: zoom,
      //autoBounds: autoBounds
    };
    return mapViewport; */
  };
  //---------------------------------------------------------------------------
  addNewLocation(name?: string, geoPosition?: GeolocationPosition): ClassBusinessLocationData {    
    let point: TGeoPoint;
    if (geoPosition) {
      point = new TGeoPoint({
        coordinates: [
          geoPosition.coords.longitude,
          geoPosition.coords.latitude
        ]
      })
    } else {
      point = new TGeoPoint({ coordinates: [0, 0] });
    };
    const newLocation: IObjectLocation<string> = {
      id: newId,
      name: name ? name : "New location",
      point: point
    };
    const updatedLocations = this.locations.concat(newLocation);
    return new ClassBusinessLocationData({
      ...this,
      locations: updatedLocations
    });
  };
  //---------------------------------------------------------------------------
  addNewLocationAt(atLocationId: string): ClassBusinessLocationData {
    // new location should be added next to the location with atLocationId
    const sourceLocationIndex = this.locations.findIndex(location => location.id == atLocationId);
    const sourceLocation = this.locations[sourceLocationIndex];
    const newLocation: IObjectLocation<string> = {
      id: newId,
      name: "New location",
      point: sourceLocation.point
    };
    const updatedLocations = this.locations.map(location => location);
    updatedLocations.splice(sourceLocationIndex + 1, 0, newLocation);
    return new ClassBusinessLocationData({
      ...this,
      locations: updatedLocations
    });
  };
}

// T: string or IAdUnitText
export interface IObjectLocation<T> {
  id: string;
  name?: T;
  //description?: string;
  point: TGeoPoint;
  addressText?: T;
  status?: IAdUnitStatus;
}

export interface IMapSettings {
  defaultBounds?: google.maps.LatLngBoundsLiteral;
  defaultZoom?: number; // to remove? because we use bounds
}

export const getUpdatedAddressBlockLocation = (
  selectedLocation: IObjectLocation<IAdUnitText>,
  updatedLocation: IObjectLocation<string>
): IObjectLocation<IAdUnitText> => {
  const pointIsUpdated: boolean = JSON.stringify(selectedLocation.point) !== JSON.stringify(updatedLocation.point);
  const nameIsUpdated: boolean = selectedLocation.name?.text !== updatedLocation.name;
  const addressIsUpdated: boolean = selectedLocation.addressText?.text !== updatedLocation.addressText;
  const result: IObjectLocation<IAdUnitText> = {
    id: selectedLocation.id,
    point: selectedLocation.point, // do not change coordinates, because it's an address block
    name: {
      type: "Name",
      text: updatedLocation.name,
      status: nameIsUpdated ? new ClassAdUnitStatus("Draft") : selectedLocation.status
    },
    addressText: {
      type: "Address",
      text: updatedLocation.addressText,
      status: addressIsUpdated ? new ClassAdUnitStatus("Draft") : selectedLocation.status
    },
    status: pointIsUpdated || nameIsUpdated || addressIsUpdated ?
      new ClassAdUnitStatus("Draft") :
      selectedLocation.status
  };
  return result;
};