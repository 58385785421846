import { DraftItemStateEnum } from "../../../../common/dictionary-draft-models/DraftItemStateEnum";
import { IDictionaryDraftItemStateGroup } from "./IDictionaryDraftItemStateGroup";

export const dictonaryDraftStatesFullDetails: IDictionaryDraftItemStateGroup[] = [{
  id: 'nothing',
  name: "No changes/errors",
  states: [{
    draftState: DraftItemStateEnum.IsNothing,
    iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasNothing,
    iconId: 'attention'
  }]
}, {
  id: 'reordered',
  name: "Re-ordered",
  color: 'yellowgreen',
  states: [{
    draftState: DraftItemStateEnum.IsReordered,
    iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasReordered,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}, {
  id: 'editedt',
  name: "Edited Translation",
  color: 'mediumspringgreen',
  states: [{
    draftState: DraftItemStateEnum.IsTranslationEdited,
    iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasTranslationEdited,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}, {
  id: 'edited',
  name: "Edited",
  color: 'blue',
  states: [{
    draftState: DraftItemStateEnum.IsEdited,
    iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasEdited,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}, {
  id: 'repositioned',
  name: "Repositioned",
  color: 'magenta',
  states: [{
    draftState: DraftItemStateEnum.IsRepositionedNew,
    iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasRepositionedNew,
    iconId: 'newLabel',
    fontColor: 'inherit'
  }, {
    draftState: DraftItemStateEnum.IsRepositionedOld,
    iconId: 'circle',
    textDecoration: 'line-through'
  }, {
    draftState: DraftItemStateEnum.HasRepositionedOld,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}, {
  id: 'new',
  name: "New",
  color: 'mediumpurple',
  states: [{
    draftState: DraftItemStateEnum.IsNew,
    iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasNew,
    iconId: 'newLabel',
    fontColor: 'inherit'
  }]
}, {
  id: 'notranslation',
  name: "No Translation",
  color: 'orange',
  states: [{
    draftState: DraftItemStateEnum.HasNoTranslation,
    iconId: 'attention',
    fontColor: 'undefined'
  }, {
    draftState: DraftItemStateEnum.IsNoTranslation,
    iconId: 'circle'
  }]
}, {
  id: 'incorrectChildren',
  name: "Incorrect Children",
  color: 'cyan',
  states: [{
    draftState: DraftItemStateEnum.HasOnlyChild,
    iconId: 'attention'
  }, {
    draftState: DraftItemStateEnum.HasNoChildren,
    iconId: 'zero'
  }]
}, {
  id: 'deleted',
  name: "Deactivated",
  color: 'tomato',
  states: [{
    draftState: DraftItemStateEnum.IsDeleted,
    iconId: 'circle',
    textDecoration: 'line-through'
  }, {
    draftState: DraftItemStateEnum.HasDeleted,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}]