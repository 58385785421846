import { ReactNode } from 'react';
import styles from './ColumnOptionWrapper.module.css';
import Icon from '../../icon/Icon';
import CombineStyles from '../../../../utils/combineStyles';
import useClickOutsideDiv from '../../../../hooks/useClickOutsideDiv';
import IconSwitch from '../icons/IconSwitch';

interface IProps {
  caption: string;
  children: ReactNode;
  readonly: boolean;
  hideApplyOption?: boolean;
  isEnabled?: boolean;
  onApply?: () => void;
  onClear?: () => void;
  onRestore?: () => void;
  onFocusLost: () => void;
}

export default function ColumnOptionWrapper(props: IProps) {
  const containerRef = useClickOutsideDiv({ handler: props.onFocusLost });
  const hideApplyOption = props.hideApplyOption == undefined ? false : props.hideApplyOption;
  //---------------------------------------------------------------------------
  return (
    <div ref={containerRef} className={styles.container}>

      <div className={styles.caption}>
        {props.caption}
      </div>

      <div className={styles.content}>
        {props.children}
      </div>

      <div className={styles.options}>

        {!hideApplyOption &&
          <div
            className={CombineStyles([
              styles.option,
              props.readonly || !props.onApply ? styles.readonly : ""])}
            onClick={props.readonly ? undefined : props.onApply}
          >
            <Icon imageName="check" />
          </div>}

        <div
          className={CombineStyles([
            styles.option,
            props.readonly && !props.onClear ? styles.readonly : "",
            !props.isEnabled && !props.onRestore ? styles.readonly : "",
            props.onRestore ? styles.enabled : ""
          ])}
          style={{ fontSize: "1.8em" }}
          onClick={props.isEnabled ? props.onClear : props.onRestore}
        >
          <IconSwitch key={props.isEnabled ? 1 : 0} isOn={!!props.isEnabled} />
        </div>

        {/* <div
          className={CombineStyles([
            styles.option,
            props.readonly && !props.onClear ? styles.readonly : ""])}
          onClick={props.onClear}
        >
          <Icon imageName="close" />
        </div> */}

      </div>

    </div>
  );
}