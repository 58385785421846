import styles from './RequestStatus.module.css';
import IconComponent from "../../../uielements/icons";
import { EApprovalRequestStatus } from '../approval-request-form/TApprovalRequest';

interface IStatusIcon {
  icon: string;
  style: string;
}

interface IProps {
  data: EApprovalRequestStatus;
}

export default function RequestStatus(props: IProps) {
  const statusesNotSaved = ["ApprovedNotSaved", "RejectedNotSaved"];
  //---------------------------------------------------------------------------
  const getIconByStatus = (status: string): IStatusIcon => {
    //if (IsRequestInProgress()) return InProgressIcon;
    switch (status) {
      case "New":
        return { icon: "exclamation", style: styles.statusNew };
      case "Assigned":
        return { icon: "question", style: styles.statusAssigned };
      case "Approved":
        return { icon: "check", style: styles.statusApproved };
      case "Rejected":
        return { icon: "ban", style: styles.statusRejected };
      case "ApprovedNotSaved":
        return { icon: "check", style: styles.statusProcessing };
      case "RejectedNotSaved":
        return { icon: "ban", style: styles.statusProcessing };
      default:
        return { icon: "ban", style: styles.statusRejected };
    }
  };
  const status = getIconByStatus(props.data);
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <IconComponent
        iconFile={status.icon}
        className={`${styles.statusIcon} ${status.style}`}
        spin={statusesNotSaved.includes(props.data)}
      />
    </div>
  );
}