import { useEffect } from "react";
import useNavigateWithContext from "../../../hooks/useNavigateWithContext";

interface IProps {
  to: string;
}

export default function NavigateWithContext(props: IProps) {
    const navigateWithContext = useNavigateWithContext();
    //-------------------------------------------------------------------------
    useEffect(() => {
      navigateWithContext(props.to);
    },[]);
    //-------------------------------------------------------------------------
    return null;
}