import { DictionaryDraftItem, IDictionaryDraftItem } from "../../../../common/dictionary-draft-models/DictionaryDraftItem";
import { DraftItemStateEnum } from "../../../../common/dictionary-draft-models/DraftItemStateEnum";

export interface IDictionaryEditorDraftItem extends IDictionaryDraftItem {
  index: number; // Current position inside the group
  //---------------------------------------------
  deleted: boolean; // Should be set to true for isDeleted and isRepositionedOld draftstate
  //---------------------------------------------
  pathOld?: string; // Should be set for isRepositionedNew draftstate
  pathNew?: string; // Should be set for isRepositionedOld draftstate
  //items: IDictionaryEditorDraftItem[];
  //getPathForChilds(): string;
}

const propertiesToSkip = ['deleted', 'pathOld', 'draftState', 'publishedVersion', 'updatedProperties', 'isCollapsed', 'items', 'searchId', 'searchName'];
const translationProperties = ['name', 'description'];
type ObjectKey = keyof DictionaryEditorDraftItem;

export class DictionaryEditorDraftItem extends DictionaryDraftItem implements IDictionaryEditorDraftItem {
  deleted: boolean = false;
  index!: number;
  pathOld?: string;
  pathNew?: string;
  updatedProperties: string[] = [];
  isCollapsed!: boolean; // Only for hierarchical dictionaries, used only for displaying
  //--------------------------------------------------------------------------- Constructor
  constructor(source: IDictionaryDraftItem, spread?: any) {
    super(source);
    Object.assign(this, source, spread);
  }
  //----------------------------------------------------------------------------
  refresh(locale: string) {
    //console.log(this)
    this.draftState = DraftItemStateEnum.IsNothing;
    //-------------------------------------------------------------------------- Override all other states
    // isDeleted, isRepositionedOld
    if (this.deleted) {
      if (this.pathNew) {
        this.draftState = DraftItemStateEnum.IsRepositionedOld;
      } else {
        this.draftState = DraftItemStateEnum.IsDeleted;
      };
      return;
    };
    //------------------------------------------------------------------------- Check changes
    // isNew, isEdited, isTranslationEdited
    if (this.publishedVersion) {
      const updatedProperties: string[] = [];
      let isTranslationEdited = false;
      let isEdited = false;
      //-----------------------------------------------------------------------
      const publishedItem = new DictionaryEditorDraftItem(this.publishedVersion as IDictionaryEditorDraftItem);
      Object.entries(this).forEach(([key, value]) => {
        if (propertiesToSkip.includes(key))
          return;
        //---------------------------------------------------------------------
        const publishedValue = publishedItem[key as ObjectKey];
        //---------------------------------------------------------------------
        if (!ValuesAreEqual(value, publishedValue)) {
          //console.log(value, publishedValue)
          if (translationProperties.includes(key)) {
            let valueForLocale: string | undefined = undefined;
            try {
              valueForLocale = publishedValue[locale];
            } catch { };
            if (!ValuesAreEqual(value, valueForLocale))
              isTranslationEdited = true;
          } else {
            switch (key) {
              case 'index':
                this.isReordered = true;
                break;
              case 'path':
                this.isRepositionedNew = true;
                break;
              default:
                isEdited = true;
                break;
            }
          };
          updatedProperties.push(key);
        };
      });
      this.isEdited = isEdited;
      this.isTranslationEdited = isTranslationEdited;
      this.updatedProperties = updatedProperties;
    } else {
      this.isNew = true;
    };
    //------------------------------------------------------------------------- Check validity
    // isNoTranslation
    if (!this.name) {
      this.isNoTranslation = true;
    } else if (!this.name) {
      this.isNoTranslation = true;
    };
    if (!this.description) {
      this.isNoTranslation = true;
    } else if (!this.description) {
      this.isNoTranslation = true;
    };
    //-------------------------------------------------------------------------
    if (this.draftState !== DraftItemStateEnum.IsNothing) {
      this.isNothing = false;
    }
  }
  //---------------------------------------------------------------------------
  isValid(): boolean {
    this.refresh("en");
    return !this.isNoTranslation;
  }
  //--------------------------------------------- Properties based on draftStatus
  //--------------------------------------------------------------- NoTranslation
  get isNoTranslation(): boolean {
    if (this.draftState & DraftItemStateEnum.IsNoTranslation)
      return true;
    else
      return false;
  }
  set isNoTranslation(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.IsNoTranslation;
    else
      this.draftState &= ~DraftItemStateEnum.IsNoTranslation;
  }
  //---------------------------------------------------------------------------
  get hasNoTranslation(): boolean {
    if (this.draftState & DraftItemStateEnum.HasNoTranslation)
      return true;
    else
      return false;
  }
  set hasNoTranslation(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasNoTranslation;
    else
      this.draftState &= ~DraftItemStateEnum.HasNoTranslation;
  }
  //--------------------------------------------------------------------------- TranslationEdited
  get isTranslationEdited(): boolean {
    if (this.draftState & DraftItemStateEnum.IsTranslationEdited)
      return true;
    else
      return false;
  }
  set isTranslationEdited(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.IsTranslationEdited;
    else
      this.draftState &= ~DraftItemStateEnum.IsTranslationEdited;
  }
  //---------------------------------------------------------------------------
  get hasTranslationEdited(): boolean {
    if (this.draftState & DraftItemStateEnum.HasTranslationEdited)
      return true;
    else
      return false;
  }
  set hasTranslationEdited(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasTranslationEdited;
    else
      this.draftState &= ~DraftItemStateEnum.HasTranslationEdited;
  }
  //--------------------------------------------------------------------------- Edited
  get isEdited(): boolean {
    if (this.draftState & DraftItemStateEnum.IsEdited)
      return true;
    else
      return false;
  }
  set isEdited(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.IsEdited;
    else
      this.draftState &= ~DraftItemStateEnum.IsEdited;
  }
  //---------------------------------------------------------------------------
  get hasEdited(): boolean {
    if (this.draftState & DraftItemStateEnum.HasEdited)
      return true;
    else
      return false;
  }
  set hasEdited(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasEdited;
    else
      this.draftState &= ~DraftItemStateEnum.HasEdited;
  }
  //--------------------------------------------------------------------------- RepositionedOld
  get isRepositionedOld(): boolean {
    if (this.draftState & DraftItemStateEnum.IsRepositionedOld)
      return true;
    else
      return false;
  }
  set isRepositionedOld(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.IsRepositionedOld;
    else
      this.draftState &= ~DraftItemStateEnum.IsRepositionedOld;
  }
  //---------------------------------------------------------------------------
  get hasRepositionedOld(): boolean {
    if (this.draftState & DraftItemStateEnum.HasRepositionedOld)
      return true;
    else
      return false;
  }
  set hasRepositionedOld(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasRepositionedOld;
    else
      this.draftState &= ~DraftItemStateEnum.HasRepositionedOld;
  }
  //--------------------------------------------------------------------------- RepositionedNew
  get isRepositionedNew(): boolean {
    if (this.draftState & DraftItemStateEnum.IsRepositionedNew)
      return true;
    else
      return false;
  }
  set isRepositionedNew(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.IsRepositionedNew;
    else
      this.draftState &= ~DraftItemStateEnum.IsRepositionedNew;
  }
  //---------------------------------------------------------------------------
  get hasRepositionedNew(): boolean {
    if (this.draftState & DraftItemStateEnum.HasRepositionedNew)
      return true;
    else
      return false;
  }
  set hasRepositionedNew(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasRepositionedNew;
    else
      this.draftState &= ~DraftItemStateEnum.HasRepositionedNew;
  }
  //--------------------------------------------------------------------------- Deleted
  get isDeleted(): boolean {
    if (this.draftState & DraftItemStateEnum.IsDeleted)
      return true;
    else
      return false;
  }
  set isDeleted(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.IsDeleted;
    else
      this.draftState &= ~DraftItemStateEnum.IsDeleted;
  }
  //---------------------------------------------------------------------------
  get hasDeleted(): boolean {
    if (this.draftState & DraftItemStateEnum.HasDeleted)
      return true;
    else
      return false;
  }
  set hasDeleted(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasDeleted;
    else
      this.draftState &= ~DraftItemStateEnum.HasDeleted;
  }
  //--------------------------------------------------------------------------- New
  get isNew(): boolean {
    if (this.draftState & DraftItemStateEnum.IsNew)
      return true;
    else
      return false;
  }
  set isNew(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.IsNew;
    else
      this.draftState &= ~DraftItemStateEnum.IsNew;
  }
  //---------------------------------------------------------------------------
  get hasNew(): boolean {
    if (this.draftState & DraftItemStateEnum.HasNew)
      return true;
    else
      return false;
  }
  set hasNew(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasNew;
    else
      this.draftState &= ~DraftItemStateEnum.HasNew;
  }
  //--------------------------------------------------------------------------- Nothing
  get isNothing(): boolean {
    if (this.draftState & DraftItemStateEnum.IsNothing)
      return true;
    else
      return false;
  }
  set isNothing(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.IsNothing;
    else
      this.draftState &= ~DraftItemStateEnum.IsNothing;
  }
  //---------------------------------------------------------------------------
  get hasNothing(): boolean {
    if (this.draftState & DraftItemStateEnum.HasNothing)
      return true;
    else
      return false;
  }
  set hasNothing(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasNothing;
    else
      this.draftState &= ~DraftItemStateEnum.HasNothing;
  }
  //--------------------------------------------------------------------------- HasOnlyChild
  get hasOnlyChild(): boolean {
    if (this.draftState & DraftItemStateEnum.HasOnlyChild)
      return true;
    else
      return false;
  }
  set hasOnlyChild(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasOnlyChild;
    else
      this.draftState &= ~DraftItemStateEnum.HasOnlyChild;
  }
  //--------------------------------------------------------------------------- HasNoChildren
  get hasNoChildren(): boolean {
    if (this.draftState & DraftItemStateEnum.HasNoChildren)
      return true;
    else
      return false;
  }
  set hasNoChildren(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasNoChildren;
    else
      this.draftState &= ~DraftItemStateEnum.HasNoChildren;
  }
  //--------------------------------------------------------------------------- IsReordered
  get isReordered(): boolean {
    if (this.draftState & DraftItemStateEnum.IsReordered)
      return true;
    else
      return false;
  }
  set isReordered(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.IsReordered;
    else
      this.draftState &= ~DraftItemStateEnum.IsReordered;
  }
  //--------------------------------------------------------------------------- 
  get hasReordered(): boolean {
    if (this.draftState & DraftItemStateEnum.HasReordered)
      return true;
    else
      return false;
  }
  set hasReordered(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasReordered;
    else
      this.draftState &= ~DraftItemStateEnum.HasReordered;
  }
}

function ValuesAreEqual(value1: any, value2: any): boolean {
  if ((value1 == null && value1 == value2) || (value1 == undefined && value1 == value2)) {
    return true;
  } else if (value1 == null || value2 == null || value1 == undefined || value2 == undefined) {
    return false;
  } else {
    return (JSON.stringify(value1) == JSON.stringify(value2));
  };
}