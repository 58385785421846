import { useMsal } from "@azure/msal-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router";
import { AppAuthContextStore } from "../../context/app-auth-context/AppAuthContext";
import WikiSpinner from "../app-layout/spinner/wikiSpinner";
import NavigateWithContext from "../common/navigate-with-context/NavigateWithContext";
import { AppUiContextStore } from "../../context/app-ui-context/AppUiContextProvider";
import { getUserRemotely } from "../../context/app-auth-context/user";
import { TUiNames } from "../../context/app-ui-context/AppUiCache";
import { IUiApplication_UserConsole, IUiUserConsole } from "./IUiUserConsole";
import { TUiMenuContent } from "../common/menu/menu-content/TUiMenuContent";
import UserProfile from "./user-profile/UserProfile";
import { TUiBreadcrumb } from "../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { IUiUserProfile } from "./user-profile/IUiUserProfile";
import { UserImages } from "./user-images/UserImages";
import { IUiUserImages } from "./user-images/IUiUserImages";
import { MsalTemplateWrapper } from "../../context/app-auth-context/MsalTemplateWrapper";
import { IUiMenuContent } from "../common/options/menus/IUiMenuContent";
import { IUiOption } from "../common/options/IUiOption";
import MainMenu from "../common/options/menus/main-menu/MainMenu";
import useNavigateWithContext from "../../hooks/useNavigateWithContext";

const uiName: TUiNames = "userConsoleNew";
export const pathUserConsole = "user";
export const pathUserPolicy = "policy";
export const pathUserImages = "images";
export const pathUserProfile = "profile";
export const optionIdProfile = "UserConsole.MenuContent.UserProfile";
export const optionIdImages = "UserConsole.MenuContent.UserImages";

export default function UserConsole() {
  const { instance } = useMsal();
  const { appAuthContext, appAuthContextDispatch } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const navigate = useNavigateWithContext();
  const [isLoading, setIsLoading] = useState(false);
  const [uiIsLoading, setUiIsLoading] = useState(false);
  const [ui, setUi] = useState<IUiUserConsole>();
  const needToRefreshUserData = useRef(false);
  const abortController = useRef<AbortController>();
  const pathProfile = `/${pathUserConsole}/${pathUserProfile}`;
  const pathImages = `/${pathUserConsole}/${pathUserImages}`;
  //---------------------------------------------------------------------------
  const setUpUi = (appUi: IUiApplication_UserConsole) => {
    // Set dictionaries
    if (appUi?.dictionaries) {
      appUiContextDispatch({ type: "UpdateDictionaries", value: appUi.dictionaries });
    };
    //-------------------------------------------------------------------------
    let breadcrumbUserProfile: TUiBreadcrumb | undefined = undefined;
    let breadcrumbUserImages: TUiBreadcrumb | undefined = undefined;
    appUi.userConsole.menuContent.options.forEach(option => {
      switch (option.id) {
        case "UserConsole.MenuContent.UserProfile":
          breadcrumbUserProfile = {
            id: option.id,
            caption: option.caption,
            path: `/${pathUserConsole}/${pathUserProfile}`
          };
          break;
        case "UserConsole.MenuContent.UserImages":
          breadcrumbUserImages = {
            id: option.id,
            caption: option.caption,
            path: `/${pathUserConsole}/${pathUserImages}`
          };
          break;
      };
    });
    //-------------------------------------------------------------------------
    // Set console UI
    setUi({
      ...appUi.userConsole,
      breadcrumbUserProfile: breadcrumbUserProfile,
      breadcrumbUserImages: breadcrumbUserImages
    });
  };
  //--------------------------------------------------------------------------- Set needToRefreshUserData, abortController
  useEffect(() => {
    // at first render we check if user is already authenticated
    // if so we need to retrieve user from backend to refresh its data (see next useEffect)
    // if user was not authenticated, it will be retrieved during the authentication process
    needToRefreshUserData.current = appAuthContext.user ? true : false;
    //-------------------------------------------------------------------------
    abortController.current = new AbortController();
    return (() => {
      abortController.current?.abort("UserConsole was unmounted");
    });
  }, []);
  //--------------------------------------------------------------------------- Get user
  useEffect(() => {
    if (!appAuthContext.user || !appAuthContext.config) {
      return;
    };
    //-----------------------------------------------------------------------
    if (!ui && appUiContext.uiCache?.checkAuthorization(uiName)) {
      setUiIsLoading(true);
      appUiContext.uiCache.getUiCallback<IUiApplication_UserConsole>(uiName, setUpUi, () => setUiIsLoading(false));
    };
    //-----------------------------------------------------------------------
    // Refresh user data if retrieveUserFlag is set 
    if (needToRefreshUserData.current) {
      console.log("UserConsole.useEffect[]: call to getUserRemotely");
      setIsLoading(true);
      getUserRemotely(
        instance,
        appAuthContext.config,
        abortController.current?.signal
      ).then(user => {
        console.log("User:", user);
        if (!user)
          throw `getUserRemotely responded with undefined user`;
        //---------------------------------------------------------------------
        needToRefreshUserData.current = false;
        //console.warn("UserConsole.useEffect[] setting userRef.current = true");
        //console.warn("UserConsole.useEffect[] appAuthContextDispatch(user)");
        appAuthContextDispatch({ type: "SetUser", value: user });
      }).catch((error) => {
        if (abortController.current?.signal.aborted)
          console.error("Retrieve user request aborted");
        else
          console.error("Cannot retrieve user profile remotely. Error: " + error);
      }).finally(() => {
        !abortController.current?.signal.aborted && setIsLoading(false);
      });
    };
  }, [appAuthContext]);
  //---------------------------------------------------------------------------
  const onMainMenuOptionSelect = (optionId: string) => {
    switch (optionId) {
      case optionIdProfile:
        navigate(pathProfile);
        break;
      case optionIdImages:
        navigate(pathImages);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  let selectedOptionId = "undefined";
  ui?.menuContent.options.forEach(option => {
    switch (option.id) {
      case optionIdProfile:
        selectedOptionId = location.pathname.startsWith(pathProfile) ? option.id : selectedOptionId;
        updatedOptions.push({
          ...option,
          iconId: "userProfile",
          action: pathProfile
        });
        break;
      case optionIdImages:
        selectedOptionId = location.pathname.startsWith(pathImages) ? option.id : selectedOptionId;
        updatedOptions.push({
          ...option,
          iconId: "image",
          action: pathImages
        });
        break;
    };
  });
  const updatedMenuContent: IUiMenuContent = { ...ui?.menuContent as IUiMenuContent, options: updatedOptions };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <WikiSpinner show={isLoading || uiIsLoading} />
      <MsalTemplateWrapper>

        <MainMenu
          ui={updatedMenuContent}
          selectedOptionId={selectedOptionId}
          onSelect={onMainMenuOptionSelect}
        />

        {ui &&
          <Routes>

            <Route
              index
              element={<NavigateWithContext to={`/${pathUserConsole}/${pathUserProfile}`} />}
            />

            <Route
              path={`${pathUserProfile}/*`}
              element={
                <UserProfile
                  ui={{
                    ...ui?.userProfile as IUiUserProfile,
                    breadcrumb: ui?.breadcrumbUserProfile
                  }}
                />}
            />

            <Route
              path={`${pathUserImages}/*`}
              element={
                <UserImages
                  ui={{
                    ...ui?.userImages as IUiUserImages,
                    breadcrumb: ui?.breadcrumbUserImages
                  }}
                />
              }
            />

          </Routes>}
      </MsalTemplateWrapper>
    </React.Fragment>
  );
}
