import { useContext, useEffect, useRef, useState } from 'react';
import { MsalTemplateWrapper } from '../../../../context/app-auth-context/MsalTemplateWrapper';
import { IUiRegionalHomepageEditor } from './IUiRegionalHomepageEditor';
import ConsoleLayout from '../../../common/console-layout/ConsoleLayout';
import { IUiMenuContent } from '../../../common/options/menus/IUiMenuContent';
import { getTUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import { pathAdminConsole, pathRegionalHomepages } from '../../AdminConsole';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { useAbortController } from '../../../../hooks/useAbortController';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import getRegionalHomepageBusiness from './functions/getRegionalHomepageBusiness';
import usePageTitle from '../../../../hooks/usePageTitle';
import { TUiBreadcrumb } from '../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import useNavigateWithContext from '../../../../hooks/useNavigateWithContext';
import GetName from '../../../common/functions/GetName';
import SectionDetails from './section-details/SectionDetails';
import SectionAdCampaigns from './section-adcampaigns/SectionAdCampaigns';
import createRegionalHomepageBusinessAsync from '../regional-homepages-list/createRegionalHomepageBusinessAsync';
import { ImageEditorContext } from '../../../common/image-editor/image-editor-context/ImageEditorContextProvider';
import AdCampaignEditForm from '../../../business-space/business-editor/ad-campaign/AdCampaignEditForm';
import createSystemBusinessAdCampaign from './functions/createSystemBusinessAdCampaignAsync';
import SectionAdModules from './section-admodules/SectionAdModules';
import ConsoleTabLayout, { TScrollDirection, TScrollType } from '../../../common/console-layout/console-tab-layout/ConsoleTabLayout';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import NavigateWithContext from '../../../common/navigate-with-context/NavigateWithContext';
import { BusinessContext } from '../../../../context/business-context/BusinessContextProvider';
import { ClassSystemBusinessRegional } from './ISystemBusinessRegional';
import saveRegionalHomepageBusinessAsync from './functions/saveRegionalHomepageBusinessAsync';
import { EUserRole } from '../../../../context/business-context/IBusinessContext';
import { IAdCampaign } from '../../../../context/business-context/IAdCampaign';
import { tmpAdModuleMenuContent } from '../../../common/ad-modules/IUiAdModule';

export const pathDetails = "details";
export const pathAdCampaigns = "adcampaigns";
export const pathAdModules = "admodules";
export const keywordNewRegionalHomepage = "new_";
const sections = [pathDetails, pathAdCampaigns, pathAdModules];

interface IProps {
  ui: IUiRegionalHomepageEditor;
}

export default function RegionalHomepageEditor(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { imageEditorContext } = useContext(ImageEditorContext);
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const [isLoading, setIsLoading] = useState(true);
  const [adCampaignEditorOpen, setAdCampaignEditorOpen] = useState(false);
  const updateTitle = usePageTitle();
  const navigate = useNavigateWithContext();
  const abortController = useAbortController("RegionalHomepageEditor");
  //---------------------------------------------------------------------------
  const location = useLocation();
  const { role, systemBusinessId } = useParams();
  const basePath = useRef<string>(`${pathAdminConsole}/${role}/${pathRegionalHomepages}/${systemBusinessId}`);
  //const currentSection = GetIdFromLocationHash(location)?.toLowerCase();
  const currentSection = location.pathname.split("/").pop()?.toLowerCase();
  const [sectionPathToSwitch, setSectionPathToSwitch] = useState<string>();
  //---------------------------------------------------------------------------
  const newRegionIsCreated = useRef(false);
  const systemBusiness = businessContext.businessState?.business as ClassSystemBusinessRegional; //commonBusinessContext.businessContext?.business as SystemBusiness;
  const systemBusinessIsEditable = systemBusiness && !!systemBusiness.status && systemBusiness.status.isAvailableToStaffUsers;
  const systemBusinessIsSaveable = businessContext.businessState ?
    (businessContext.businessState.isChanged || businessContext.businessState.isNew) && !isLoading
    : true;
  //--------------------------------------------------------------------------- Data, Business context, Abortcontroller
  useEffect(() => {
    const editorBasePath = `${pathAdminConsole}/${role}/${pathRegionalHomepages}`;
    const userRole = role as EUserRole;
    if (systemBusinessId?.startsWith(keywordNewRegionalHomepage)) {
      // In this case systemBusinessId is a string telling us we need to create new business for the specified region
      // Check if new business is not already created (or rather request is already made)
      if (newRegionIsCreated.current)
        return;
      //-----------------------------------------------------------------------
      // It's a first render and we need create new system business
      newRegionIsCreated.current = true;
      const pathToReplace = systemBusinessId;
      const regionId = systemBusinessId.split(keywordNewRegionalHomepage)[1];
      createRegionalHomepageBusinessAsync({
        msalInstanse: instance,
        authConfig: appAuthContext.config,
        regionId: regionId,
      }).then(business => {
        businessContextDispatch({
          type: "SetBusinessState",
          basePath: editorBasePath,
          userRole: userRole,
          business: business
        });
        //---------------------------------------------------------------------
        // Refresh URL
        const updatedLocationPath = location.pathname.replace(pathToReplace, business.id);
        basePath.current = `${editorBasePath}/${business.id}`;
        window.history.replaceState(null, '', updatedLocationPath);
      }).catch(error => {
        console.error(error);
        //navigate(`${pathBusinessConsole}`);
      }).finally(() => {
        setIsLoading(false);
      });
    } else {
      //-----------------------------------------------------------------------
      // Retrieve regional homepage system business from backend
      let controller = abortController.newController("getRegionalHomepage");
      getRegionalHomepageBusiness(
        instance,
        systemBusinessId,
        appAuthContext.config,
        controller.signal
      ).then(response => {
        businessContextDispatch({
          type: "SetBusinessState",
          userRole: userRole,
          basePath: editorBasePath,
          business: response.business,
          ads: response.ads
        });
      }).catch(error => {
        console.error(error);
        //navigate(`${pathBusinessConsole}`);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    };
    //-------------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //--------------------------------------------------------------------------- Breadcrumb, title
  useEffect(() => {
    if (systemBusiness && systemBusiness.regionId) {
      const region = appUiContext.dictionaries?.supportedRegions?.find(r => r.id == systemBusiness.regionId);
      const regionName = GetName(appUiContext, systemBusiness.regionId, region?.name);
      //-----------------------------------------------------------------------
      const businessBreadcrumb: TUiBreadcrumb = {
        id: systemBusiness.id,
        caption: { en: regionName },
        path: `/${pathAdminConsole}/${role}/${pathRegionalHomepages}/${systemBusiness.id}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: businessBreadcrumb });
      //-----------------------------------------------------------------------
      updateTitle(regionName);
    };
  }, [systemBusiness]);
  //---------------------------------------------------------------------------
  const onSave = () => {
    if (!appAuthContext.config
      || !businessContext.businessState
      || !businessContext.businessState.business) {
      console.error("RegionalHomepageEditor: business context is not set");
      return;
    };
    //-------------------------------------------------------------------------
    setIsLoading(true);
    let controller = abortController.newController("saveRegionalHomepageBusiness");
    const businessToSave = businessContext.businessState.business as ClassSystemBusinessRegional;
    saveRegionalHomepageBusinessAsync({
      msalInstance: instance,
      authContext: appAuthContext,
      imageEditorContext: imageEditorContext,
      business: businessToSave,
      abortSignal: controller.signal
    }).then(business => {
      businessContextDispatch({ type: "SetBusiness", business: business });
    }).catch(error => {
      console.error(`RegionalHomepageEditor: can't save business. `, error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onAdCampaignAdd = () => {
    setAdCampaignEditorOpen(true);
  };
  //--------------------------------------------------------------------------- Function: on create new Campaign confirm
  const createAdCampaign = (adCampaign: IAdCampaign) => {
    setAdCampaignEditorOpen(false);
    //-------------------------------------------------------------------------
    if (!appAuthContext.config
      || !businessContext.businessState
      || !businessContext.businessState.business) {
      console.error("RegionalHomepageEditor: can't create ad campaign - business context is not set");
      return;
    };
    setIsLoading(true);
    let controller = abortController.newController("createSystemBusinessAdCampaign");
    //-------------------------------------------------------------------------
    // Call API to create a new ad campaign and respond with updated business object
    const systemBusiness = businessContext.businessState?.business as ClassSystemBusinessRegional;
    createSystemBusinessAdCampaign({
      msalInstanse: instance,
      authConfig: appAuthContext.config,
      businessId: systemBusiness.id,
      adCampaignName: adCampaign.name,
      business: businessContext.businessState.isChanged ? systemBusiness : undefined,
      abortSignal: controller.signal
    }).then(systemBusiness => {
      businessContextDispatch({ type: "SetBusiness", business: systemBusiness });
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const getSectionPath = (sectionId: string) => {
    //return `/${basePath.current}#${sectionId}`;
    return `/${basePath.current}/${sectionId}`;
  };
  //---------------------------------------------------------------------------
  const switchSection = (
    currentPath: string,
    direction: TScrollDirection,
    type?: TScrollType,
  ) => {
    if (!currentPath) return;
    //-------------------------------------------------------------------------
    // Determine currect section
    let currentSection = currentPath.split(basePath.current).pop()?.toLowerCase().split("/").pop();
    currentSection = currentSection == "" ? pathDetails : currentSection;
    if (!currentSection) return;
    const currentSectionIndex = sections.indexOf(currentSection);
    //-------------------------------------------------------------------------
    // Determine what section user is going to switch to
    let newSectionIndex = currentSectionIndex;
    switch (direction) {
      case "Up":
        newSectionIndex = currentSectionIndex - 1;
        if (newSectionIndex < 0)
          return;
        break;
      case "Down":
        newSectionIndex = currentSectionIndex + 1;
        if (newSectionIndex >= sections.length)
          return;
        break;
      default:
        setSectionPathToSwitch(undefined);
        return;
    };
    const newSectionPath = sections[newSectionIndex];
    console.log(direction, type, currentSection, newSectionPath);
    //-------------------------------------------------------------------------
    // Determine action to perform
    switch (type) {
      case "Warn":
        setSectionPathToSwitch(newSectionPath);
        break;
      case "Switch":
        setSectionPathToSwitch(undefined);
        navigate(`/${basePath.current}/${newSectionPath}`);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      /* case "BusinessEditor.MenuContent.OptionUp":
        navigate(getSectionPath(sections[currentSectionIndex - 1]));
        break;
      case "BusinessEditor.MenuContent.OptionDown":
        navigate(getSectionPath(sections[currentSectionIndex + 1]));
        break; */
      case "RegionalHomepageEditor.MenuContent.OptionCreateAdCampaign":
        onAdCampaignAdd();
        break;
      case "OptionSave":
        onSave();
        break;
      case "OptionClose":
        businessContextDispatch({ type: "ClearBusinessContext" });
        navigate(`${pathAdminConsole}/${role}/${pathRegionalHomepages}`);
        break;
    };
  };
  //--------------------------------------------------------------------------- Render options
  const menuContentSections: IUiMenuContent = {
    ...props.ui.menuContent,
    id: `${props.ui.menuContent.id}_Sections`,
    options: []
  };
  const menuContentOptions: IUiMenuContent = {
    ...props.ui.menuContent,
    id: `${props.ui.menuContent.id}_Options`,
    options: []
  };
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "RegionalHomepageEditor.MenuContent.OptionDetails":
        menuContentSections.options.push({
          ...option,
          iconId: "adPageDetails",
          action: getSectionPath(pathDetails)
        });
        break;
      case "RegionalHomepageEditor.MenuContent.OptionAdCampaigns":
        menuContentSections.options.push({
          ...option,
          iconId: "bullhorn",
          action: getSectionPath(pathAdCampaigns)
        });
        break;
      case "RegionalHomepageEditor.MenuContent.OptionAdModules":
        menuContentSections.options.push({
          ...option,
          iconId: "modules",
          action: getSectionPath(pathAdModules)
        });
        break;
      //-----------------------------------------------------------------------
      /* case "RegionalHomepageEditor.MenuContent.OptionUp":
        if (currentSectionIndex > 0) {
          menuContentOptions.options.push({
            ...option,
            iconId: "doubleArrowUp"
          });
        };
        break;
      case "RegionalHomepageEditor.MenuContent.OptionDown":
        if (currentSectionIndex < sections.length - 1) {
          menuContentOptions.options.push({
            ...option,
            iconId: "doubleArrowDown"
          });
        };
        break; */
      case "RegionalHomepageEditor.MenuContent.OptionCreateAdCampaign":
        if (currentSection == pathAdCampaigns && role != "administrator" && systemBusinessIsEditable) {
          menuContentOptions.options.push({
            ...option,
            iconId: "plus"
          });
        };
        break;
      case "OptionClose":
        menuContentOptions.options.push({
          ...option,
          iconId: "close"
        });
        break;
      case "OptionSave":
        /* if (systemBusinessIsEditable) {
          // If sb is available for staff users
          menuContentOptions.options.push({
            ...option,
            iconId: "check",
            isDisabled: !systemBusinessIsSavable,
            isDefault: systemBusinessIsSavable
          });
        }; */
        break;
    };
  });
  const editFormOptions = props.ui.menuContent ?
    <FormOptions
      ui={getTUiMenuContent(menuContentOptions)}
      //optionIdVisibleOnMobile={"CloseButton"}
      onSelect={onOptionSelect}
    />
    : undefined;
  //---------------------------------------------------------------------------
  return (
    <MsalTemplateWrapper>
      <ConsoleLayout
        ui={{
          header: "",
          menuContent: getTUiMenuContent(menuContentSections)
        }}
        path={location.pathname}
        blinkingOptionPath={sectionPathToSwitch}
      >
        <WikiSpinner show={isLoading} />

        <ConsoleTabLayout
          toolbar={editFormOptions}
          onScroll={switchSection}
        >
          <Routes>
            <Route index element={
              <NavigateWithContext to={`/${basePath.current}/${pathDetails}`} />} />

            <Route path={pathDetails} element={
              <SectionDetails
                id={pathDetails}
                ui={props.ui.sectionDetails}
                isReadonly={role == "contentmanager" || !systemBusinessIsEditable}
              />} />
            <Route path={pathAdCampaigns} element={
              <SectionAdCampaigns
                id={pathAdCampaigns}
                ui={props.ui.sectionAdCampaigns}
                isReadonly={role == "administrator" || !systemBusinessIsEditable}
              />} />
            <Route path={pathAdModules} element={
              <SectionAdModules
                id={pathAdModules}
                ui={{
                  ...props.ui.sectionAdModules,
                  adModule: {
                    menuContent: tmpAdModuleMenuContent
                  }
                }}
                isReadonly={role == "administrator" || !systemBusinessIsEditable}
              />} />
          </Routes>
        </ConsoleTabLayout>

        {/* {systemBusiness &&
          <ConsoleTabLayoutScrollable
            ui={getTUiMenuContent(menuContentOptions)}
            onOptionSelect={onOptionSelect}
          >
            <SectionDetails
              id={pathDetails}
              ui={props.ui.sectionDetails}
              isReadonly={isReadOnly}
            />
            <SectionAdCampaigns
              id={pathAdCampaigns}
              ui={props.ui.sectionAdCampaigns}
              isReadonly={isReadOnly}
            />
            <SectionAdModules
              id={pathAdModules}
              ui={props.ui.sectionAdModules}
              isReadonly={isReadOnly}
            />
          </ConsoleTabLayoutScrollable>} */}

        {adCampaignEditorOpen &&
          <AdCampaignEditForm
            ui={props.ui.editFormAdCampaign}
            isSystem={true}
            onConfirm={createAdCampaign}
            onCancel={() => setAdCampaignEditorOpen(false)}
          />}

      </ConsoleLayout>
    </MsalTemplateWrapper>
  );
}