import { IUiValidation } from "./input-validation/IUiValidation";

export interface IUiInput {
  id: string;
  directoryId: string;
  elementType: string;
  caption?: any;
  hint?: any;
  placeholder?: any;
  disabled?: boolean;
  readonly?: boolean; // It's for places where value can't be edited at all
  visible?: boolean;
  hideCaption?: boolean;
  hideHint?: boolean;
  validation?: IUiValidation;
}

export enum EInputValueType {
  Text,
  Phone,
  Email,
  Date,
  DateTime
};

export type TInputMode = "none" | "email" | "search" | "tel" | "text" | "url" | "numeric" | "decimal" | undefined;

export type TEnterKeyHint = "search" | "enter" | "done" | "go" | "next" | "previous" | "send" | undefined;

export type THtmlInputTypeAttribute =
| 'button'
| 'checkbox'
| 'color'
| 'date'
| 'datetime-local'
| 'email'
| 'file'
| 'hidden'
| 'image'
| 'month'
| 'number'
| 'password'
| 'radio'
| 'range'
| 'reset'
| 'search'
| 'submit'
| 'tel'
| 'text'
| 'time'
| 'url'
| 'week'
| (string & {});