import { IAppUiLocaleContext } from "./IAppUiLocaleContext";

const queryStringParamLocale = "lang";
const queryStringParamFallbackLevel = "fallbacklevel";

export function GetLocaleContextFromQueryString(queryString: string) {
  //var params = new URLSearchParams(queryString.toLocaleLowerCase());
  //console.log("GetLocaleContextFromQueryString.queryString:", queryString);
  var params = new URLSearchParams(queryString);
  //-------------------------------------------------------------------------
  var localeProvidedInUrl = params.get(queryStringParamLocale);
  var fallbackLevelProvidedByUrl = params.get(queryStringParamFallbackLevel);
  const queryStringToStore = `?${queryStringParamLocale}=${localeProvidedInUrl}`
    + (fallbackLevelProvidedByUrl ? `&${queryStringParamFallbackLevel}=${fallbackLevelProvidedByUrl}` : '');
  //-------------------------------------------------------------------------
  if (localeProvidedInUrl) {
    var fallback = (fallbackLevelProvidedByUrl ? fallbackLevelProvidedByUrl : 0);
    var uiLocaleContext: IAppUiLocaleContext = {
      localeId: String(localeProvidedInUrl),
      fallbackLevel: Number(fallback),
      queryString: queryStringToStore,
      isImportant: true,
    };
    return uiLocaleContext;
  }
  else {
    return undefined;
  }
}

export function RemoveLocaleContextFromQueryString(queryString: string | undefined) {
  if (!queryString) return "";
  //-------------------------------------------------------------------------
  //var params = new URLSearchParams(queryString.toLocaleLowerCase());
  var params = new URLSearchParams(queryString);
  params.delete(queryStringParamLocale);
  params.delete(queryStringParamFallbackLevel);
  //-------------------------------------------------------------------------
  var newQueryString = "?";
  params.forEach((value, key) => {
    newQueryString += `${key}=${value}&`
  });
  //-------------------------------------------------------------------------
  return newQueryString.slice(0, -1);
};

// export function ExtractLocaleContextFromQueryString(queryString: string | undefined) {
//   if (!queryString) return "";
//   //-------------------------------------------------------------------------
//   var params = new URLSearchParams(queryString.toLocaleLowerCase());
// }