import { EditFormState } from "../../../../common/edit-form/IEditFormState";
import { ContentItemTranslationDraft, IContentItemTranslationDraft } from "../content-directory-translator/IContentItemTranslationDraft";

export interface IContentItemEditFormState extends EditFormState<ContentItemTranslationDraft> {

}

export class ContentItemEditFormState implements IContentItemEditFormState {
  object?: ContentItemTranslationDraft;
  initialState?: ContentItemTranslationDraft;
  isNew!: boolean;
  isUpdated!: boolean;
  isValid!: boolean;
  //---------------------------------------------------------------------------
  constructor(
    source: IContentItemEditFormState,
    updatedObject?: IContentItemTranslationDraft
  ) {
    //-------------------------------------------------------------------------
    // Set up data
    if (updatedObject) {
      Object.assign(this, source, { object: updatedObject });

    } else {
      // Data initialization
      Object.assign(this, source);
      this.initialState = source.object;
    };
    //-------------------------------------------------------------------------
    updatedObject && this.checkChanges();
    this.validate();
  };
  //---------------------------------------------------------------------------
  checkChanges() {
    if (!this.object) return;
    // Check if data is updated in session (compare with initial state)
    //this.isUpdated = this.object.getIsUpdatedInSession(this.initialState);
    this.isUpdated = !this.object.isTheSameAs(this.initialState);
  };
  //---------------------------------------------------------------------------
  validate() {
    this.isValid = true;
  };
}