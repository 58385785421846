import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './ReviewResults.module.css';
import { AdLanguageSet, IAdLanguageSetReviewIssue } from '../IAdLanguageSet';
import ReviewResultsHeader from './review-results-header/ReviewResultsHeader';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';
import { useFreezeOnScroll } from '../../../hooks/useFreezeOnScroll';
import { createRoot, Root } from 'react-dom/client';

interface ICurrentIssue {
  issue: IAdLanguageSetReviewIssue;
  reasonString?: string;
}

interface IProps {
  data?: AdLanguageSet;
  onIssueSelect: (unitPath?: string[]) => void;
}

export default function ReviewResults(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [issues, setIssues] = useState<IAdLanguageSetReviewIssue[]>();
  const [currentIssue, setCurrentIssue] = useState<IAdLanguageSetReviewIssue>();
  const defaultDivRef = useRef<HTMLDivElement>(null);
  const [defaultDiv, setDefaultDiv] = useState<HTMLDivElement>();
  const [frozenDiv, setFrozenDiv] = useState<HTMLDivElement>();
  const frozenRoot = useRef<Root>();
  //--------------------------------------------------------------------------- Freeze review result to the top on scroll
  useFreezeOnScroll({
    defaultDiv: defaultDiv,
    callback: setDiv
  });
  function setDiv(div?: HTMLDivElement) {
    setFrozenDiv(div);
  };
  //--------------------------------------------------------------------------- Clean up temporary root
  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);
  //--------------------------------------------------------------------------- Set up issues
  useEffect(() => {
    if (!props.data) return;
    const issues = props.data.getReviewIssues(appUiContext);
    setIssues(issues);
    if (issues && issues.length > 0) {
      const currentIssue = issues[0];
      setCurrentIssue(currentIssue);
    } else {
      cleanUp();
      props.onIssueSelect(undefined);
    };
  }, [props.data]);
  //--------------------------------------------------------------------------- Current issue
  useEffect(() => {
    if (!currentIssue) return;
    // Construct path to the unit corresponding with the current issue
    const path = currentIssue.adModuleId ? [currentIssue.adModuleId] : [];
    if (currentIssue.adModuleParagraphId)
      path.push(currentIssue.adModuleParagraphId);
    path.push(currentIssue.adUnitType);
    // Pass it to parent component to highlight the unit
    props.onIssueSelect(path);
  }, [currentIssue]);
  //--------------------------------------------------------------------------- Default div
  useEffect(() => {
    if (defaultDivRef.current) {
      // Update defaultDiv state once it's ready: because we need to pass it to useFreezeOnScroll
      setDefaultDiv(defaultDivRef.current);
    };
  }, [defaultDivRef.current]);
  //--------------------------------------------------------------------------- Render temporary root
  useEffect(() => {
    if (frozenDiv && issues && currentIssue) {
      // We need to render review results in temporary position: 
      // in designated frozen div provided by console tab layout
      if (!frozenRoot.current) {
        frozenRoot.current = createRoot(frozenDiv);
      };
      //-----------------------------------------------------------------------
      // Render review result in temporary root
      frozenRoot.current.render(
        <div className={styles.container}>
          <ReviewResultsHeader
            currentIndex={currentIssue.index}
            total={issues.length}
            onMove={onMove}
          />
          <div className={styles.reviewResult}>
            {currentIssue.reasonString}
          </div>
        </div>
      );
    } else if (frozenRoot.current && !frozenDiv) {
      // Clear temporary root because component is in its default position
      frozenRoot.current.render(null);
    };
  }, [frozenDiv, issues, currentIssue]);
  //---------------------------------------------------------------------------
  const cleanUp = () => {
    if (frozenRoot.current) {
      frozenRoot.current.unmount();
      frozenRoot.current = undefined;
    };
  };
  //---------------------------------------------------------------------------
  const onMove = (step: number) => {
    if (!issues || !currentIssue) return;
    const newIndex = currentIssue.index + step;
    setCurrentIssue(issues[newIndex]);
  };
  //---------------------------------------------------------------------------
  if (issues && currentIssue) return (
    <div
      ref={defaultDivRef}
      className={styles.container}
    >
      <ReviewResultsHeader
        currentIndex={currentIssue.index}
        total={issues.length}
        onMove={onMove}
      />
      <div className={styles.reviewResult}>
        {currentIssue.reasonString}
      </div>
    </div>
  ); else return null;
}