import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../context/app-auth-context/AppAuthContext";
import { BusinessListItem } from "../business-console/business-list/business-list-card/IBusinessListItem";
import { apiBasePath, pathGetUserBusinessList } from "../../../utils/apiPathConstant";
import { apiGetPrivate, checkResponse } from "../../../utils/api";

export default function getBusinessList(
  msalInstanse: IPublicClientApplication,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<BusinessListItem[]> {
  return new Promise((resolve, reject) => {
    const uri = `${apiBasePath}${pathGetUserBusinessList}`;
    //-------------------------------------------------------------------------
    apiGetPrivate(
      msalInstanse,
      authConfig,
      uri,
      abortSignal
    ).then(response => {
      checkResponse(response, "getBusinessList", abortSignal?.aborted);
      resolve(BusinessListItem.fromArray(response?.content))
    }).catch(error =>
      reject(error)
    );
  });
}