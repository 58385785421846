import { SetApiLogInterceptor } from "./api";

//----------------------------------------------------------------------------
export enum ELogLevel {
  None = 0,
  Error = 1,
  Warning = 2,
  Information = 3,
  Verbose = 4,
}
//----------------------------------------------------------------------------
const globalLogLevel: ELogLevel = ELogLevel.Verbose;
//----------------------------------------------------------------------------
export function LogInformation(currentLevel: ELogLevel, message?: any, ...optionalParams: any[]) {
  if (currentLevel <= globalLogLevel && currentLevel >= ELogLevel.Information)
    console.log(message, optionalParams);
}
//----------------------------------------------------------------------------
export function LogWarning(currentLevel: ELogLevel, message?: any, ...optionalParams: any[]) {
  if (currentLevel <= globalLogLevel && currentLevel >= ELogLevel.Warning)
    console.warn(message, optionalParams);
}
//----------------------------------------------------------------------------
export function LogError(currentLevel: ELogLevel, message?: any, ...optionalParams: any[]) {
  if (currentLevel <= globalLogLevel && currentLevel >= ELogLevel.Error)
    console.error(message, optionalParams);
}

class LineInfo {
  filepath?: string;
  line?: string;
  column?: string;
  //------------------------------------------------------------
  constructor(e: Error) {
    if (!e.stack)
      return;
    const regex = /\((.*):(\d+):(\d+)\)$/
    const match = regex.exec(e.stack.split("\n")[2]);
    if (!match)
      return;
    if (match.length > 1)
      this.filepath = match[1];
    if (match.length > 2)
      this.line = match[2];
    if (match.length > 3)
      this.column = match[2];
  }
  //------------------------------------------------------------
  toString() {
    if (!this.filepath && !this.column && !this.line)
      return "";
    return `${this.filepath}:${this.line}:${this.column}`;
  }
}
//--------------------------------------------------------------
export interface IApiLogItem {
  url: string;
  options?: RequestInit;
  responseStatus?: number;
  responseContent?: any;
}
//--------------------------------------------------------------
export class LogInterceptor {
  oldLog!: (...items: any[]) => void;
  oldWarn!: (...items: any[]) => void;
  oldError!: (...items: any[]) => void;
  oldDebug!: (...items: any[]) => void;
  consoleData!: HTMLPreElement;
  apiData!: HTMLPreElement;
  doc!: Document;
  active: boolean = false;
  //------------------------------------------------------------------------
  constructor() {
    //console.log("LogInterceptor.constructor");
  }
  //------------------------------------------------------------------------
  static create() {
    return new LogInterceptor();
  }
  //------------------------------------------------------------------------
  start() {
    console.log("LogInterceptor.start");
    this.oldLog = console.log;
    console.log = this.log.bind(this);

    this.oldWarn = console.warn;
    console.warn = this.warn.bind(this);

    this.oldError = console.error;
    console.error = this.error.bind(this);

    this.oldDebug = console.debug;
    console.debug = this.debug.bind(this);

    //this.consoleData = document.createElement('pre');
    //this.apiData = document.createElement('pre');
    this.doc = document.implementation.createHTMLDocument("New Document");
    this.consoleData = this.doc.createElement('pre');
    this.consoleData.id = "console";
    this.consoleData.innerHTML += "<p><b>Console output:</b></p>";//'<br />';
    this.apiData = this.doc.createElement('pre');
    this.apiData.id = "api";
    this.apiData.innerHTML += "<p><b>API log:</b></p>";//'<br />';
    this.doc.body.append(this.consoleData, this.apiData);
    this.active = true;
    SetApiLogInterceptor(this);
  }
  //------------------------------------------------------------------------
  stop() {
    console.log = this.oldLog;
    console.warn = this.oldWarn;
    console.error = this.oldError;
    console.log("LogInterceptor.stop:", this.consoleData);
    console.log("LogInterceptor.stop:", this.apiData);
    console.log("LogInterceptor.stop:", this.doc);
    //console.log("LogInterceptor.stop.textContent:", this.data.textContent);
    this.active = false;
    SetApiLogInterceptor(undefined);
    // Copy the text inside the text field
    //this.data.textContent && navigator.clipboard.writeText(this.data.textContent);
    //navigator.clipboard.write
    //this.data.outerHTML

    const clipboardItem = new ClipboardItem({
      'text/html': new Blob([this.doc.body.outerHTML], { type: 'text/html' }),
      'text/plain': new Blob([this.doc.body.outerHTML], { type: 'text/plain' })
    });

    // const clipboardItem = new ClipboardItem({
    //   'text/html': new Blob([this.consoleData.outerHTML], { type: 'text/html' }),
    //   'text/plain': new Blob([this.consoleData.outerHTML], { type: 'text/plain' })
    // });

    navigator.clipboard.write([clipboardItem]).then(
      _ => console.log("clipboard.write() Ok"),
      error => alert(error)
    );
  }
  toggle() {
    if (this.active)
      this.stop();
    else
      this.start();
  }
  //------------------------------------------------------------------------
  private replaceErrors(key: string, value: any) {
    if (value instanceof Error) {
      var error = {};
      Object.getOwnPropertyNames(value).forEach(propName => {
        //@ts-ignore
        error[propName] = value[propName];
      });
      return error;
    }
    return value;
  }  
  //------------------------------------------------------------------------
  api(url: string, options?: RequestInit, responseStatus?: number, responseContent?: any) {
    // Use JSON to transform objects, all others display normally
    let item: IApiLogItem = {
      url: url,
      options: options,
      responseStatus: responseStatus,
      responseContent: responseContent ? responseContent : "undefined"
    }
    this.apiData.innerHTML += "<p>api: " + JSON.stringify(item, this.replaceErrors, 4) + "</p>";//'<br />';
    
  }
  //------------------------------------------------------------------------
  private pushtItems(prefix: string, items: any[]) {
    // Use JSON to transform objects, all others display normally
    items.forEach((item, i) => {
      items[i] = (typeof item === 'object' ? JSON.stringify(item, this.replaceErrors, 4) : item);
      if (items[i] == undefined)
        items[i] = "undefined";
    });
    this.consoleData.innerHTML += `<p>${prefix}: ` + items.join(' ') + "</p>";//'<br />';
  }
  //------------------------------------------------------------------------
  log(...items: any[]) {
    // Call native method first
    //this.oldLog("LogInterceptor:", items);
    this.oldLog.apply(this, items);

    this.pushtItems("info", items);
    // Use JSON to transform objects, all others display normally
    // items.forEach((item, i) => {
    //   items[i] = (typeof item === 'object' ? JSON.stringify(item, null, 4) : item);
    //   if (items[i] == undefined)
    //     items[i] = "undefined";
    // });
    // this.consoleData.innerHTML += "<p>info: " + items.join(' ') + "</p>";//'<br />';

    //this.data.innerHTML += "info: " + items.join(' ') + "\r\n";//'<br />';
  }
  //------------------------------------------------------------------------
  warn(...items: any[]) {
    // Call native method first
    //this.oldLog("LogInterceptor:", items);
    this.oldWarn.apply(this, items);
    this.pushtItems("warn", items);

    // Use JSON to transform objects, all others display normally
    // items.forEach((item, i) => {
    //   items[i] = (typeof item === 'object' ? JSON.stringify(item, null, 4) : item);
    // });
    // this.consoleData.innerHTML += "warn: " + items.join(' ') + '\r\n';//'<br />';
  }
  //------------------------------------------------------------------------
  error(...items: any[]) {
    // Call native method first
    //this.oldLog("LogInterceptor:", items);
    this.oldError.apply(this, items);
    this.pushtItems("error", items);

    // Use JSON to transform objects, all others display normally
    // items.forEach((item, i) => {
    //   items[i] = (typeof item === 'object' ? JSON.stringify(item, this.replaceErrors, 4) : item);
    // });
    // this.consoleData.innerHTML += "<p>error: " + items.join(' ') + "</p>";//'\r\n';//'<br />';
  }
  //------------------------------------------------------------------------
  debug(...items: any[]) {
    // Call native method first
    //this.oldLog("LogInterceptor:", items);
    this.oldError.apply(this, items);
    this.pushtItems("", items);

    // Use JSON to transform objects, all others display normally
    // items.forEach((item, i) => {
    //   items[i] = (typeof item === 'object' ? JSON.stringify(item, this.replaceErrors, 4) : item);
    // });
    // this.consoleData.innerHTML += "<p>" + items.join(' ') + "</p>";//'\r\n';//'<br />';
  }
  //------------------------------------------------------------------------
  thisLine() {
    const e = new Error();
    return (new LineInfo(e)).toString() + "\r\n";
  }
}
