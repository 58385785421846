import { useContext } from 'react';
import GetCaption from '../../../../common/functions/GetCaption';
import InputWrapper from '../../../../common/input-v2/input-wrapper/InputWrapper';
import { TUiInputDropdown } from '../../../../common/input/input-dropdown/TUiInputDropdown';
import TranslationEditorInput from './translation-editor-input/TranslationEditorInput';
import styles from './TranslationEditor.module.css';
import GetTranslationWithLocale from '../../../../common/functions/GetTranslationWithLocale';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { ITranslation } from '../DictionaryTranslationDraftItem';

export interface IUiTranslationEditor {
  id: string;
  caption: any;
  inputLocale: TUiInputDropdown;
}

export interface ITranslationEditorData {
  elementId: string;
  elementPart: string;
  draftLocale: string;
  draftValue?: string;
  draftFallbackAccepted: boolean;
  sourceLocale?: string;
  sourceValue?: string;
  publishedTranslations?: any; //UiTextProperty
}

interface IProps {
  ui: IUiTranslationEditor;
  data?: ITranslationEditorData;
  onUpdate: (updatedData: ITranslation) => void;
}

export default function TranslationEditor(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const onSwitch = (fallbackAccepted: boolean) => {
    props.onUpdate({
      value: props.data?.draftValue,
      fallbackAccepted: fallbackAccepted
    });
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedValue?: string) => {
    props.onUpdate({
      value: updatedValue,
      fallbackAccepted: !!props.data?.draftFallbackAccepted
    });
  };
  //---------------------------------------------------------------------------
  const onSourceValueCopy = () => {
    if (props.data?.sourceValue && !props.data?.draftValue) {
      props.onUpdate({
        value: props.data.sourceValue,
        fallbackAccepted: !!props.data?.draftFallbackAccepted
      });
    };
  };
  //---------------------------------------------------------------------------
  // Get fallback value and locale
  let fallbackValue = undefined;
  console.log(props.data)
  if (props.data) {
    const updatedTranslations = props.data.publishedTranslations ? JSON.parse(JSON.stringify(props.data.publishedTranslations)) : {};
    console.log(updatedTranslations)
    if (updatedTranslations)
      delete updatedTranslations[props.data.draftLocale];
    console.log(updatedTranslations)
    fallbackValue = GetTranslationWithLocale(
      appUiContext.sysSettings,
      props.data.elementId,
      updatedTranslations,
      props.data.elementPart,
      props.data.draftLocale
    );
    console.log(fallbackValue)
  };
  //---------------------------------------------------------------------------
  //console.log(value, fallbackValue)
  return (
    <InputWrapper caption={GetCaption(appUiContext, props.ui.id, props.ui.caption)}>
      <div className={styles.container}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr style={{ borderBottom: '5px solid transparent' }}>
              <td colSpan={2} style={{ color: 'var(--clr_font_system)' }}>
                Source
              </td>
              <td
                className={props.data?.sourceValue && !props.data.draftValue ? styles.link : ""}
                style={{ fontSize: '1.2em', borderLeft: '.5em solid transparent', color: 'var(--clr_accent_main)' }}
                onClick={onSourceValueCopy}
              >
                {props.data?.sourceValue ? props.data.sourceValue : "--"}
              </td>
            </tr>
            <TranslationEditorInput
              ui={{
                groupId: props.ui.id,
                id: "ValueInCurrentLocale",
                caption: `Text in  [${props.data?.draftLocale}]`,
                placeholder: { en: "Type name here..." }
              }}
              data={props.data?.draftValue}
              isSelected={!props.data?.draftFallbackAccepted}
              isEditable={true}
              onSelect={() => onSwitch(false)}
              onUpdate={onUpdate}
            />
            <TranslationEditorInput
              ui={{
                groupId: props.ui.id,
                id: "FallbackValue",
                caption: `Fallback to [${fallbackValue?.locale}]`,
                placeholder: { en: "Here should be a value" }
              }}
              data={fallbackValue?.value}
              isSelected={props.data?.draftFallbackAccepted == undefined ? false : props.data.draftFallbackAccepted}
              isEditable={false}
              onSelect={() => onSwitch(true)}
            />
          </tbody>
        </table>
      </div>
    </InputWrapper>
  );
}