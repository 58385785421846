import { EDraftItemState } from "../EDraftItemState";

export interface IDirectoryDraftItemStateGroup {
  id: string;
  name: string;
  iconId?: string; // if this is provided, only one icon for the group will be shown
  color?: string;
  states?: IStateGroupFormat[];
}

export class ClassDirectoryItemStateGroup implements IDirectoryDraftItemStateGroup {
  id!: string;
  name!: string;
  iconId?: string; // if this is provided, only one icon for the group will be shown
  color?: string;
  states?: IStateGroupFormat[];
  enabled: boolean = true;
  draftState!: EDraftItemState;
  //---------------------------------------------------------------------------
  constructor(source: IDirectoryDraftItemStateGroup) {
    Object.assign(this, source);
    this.setDraftState();
  };
  //---------------------------------------------------------------------------
  private setDraftState() {
    let groupDraftState: EDraftItemState = EDraftItemState.None;
    this.states?.forEach(state => {
      groupDraftState |= state.draftState;
    });
    this.draftState = groupDraftState;
  };
  //---------------------------------------------------------------------------
  toggle(): ClassDirectoryItemStateGroup {
    return new ClassDirectoryItemStateGroup({
      ...this,
      enabled: !this.enabled
    });
  };
}

export interface IStateGroupFormat {
  draftState: EDraftItemState;
  iconId?: string;
  iconColor?: string;
  fontColor?: string; // this color if specified would override color from IItemChangeType
  fontWeight?: string;
  fontStyle?: string;
  textDecoration?: string;
}