import { IDictionaryEditorState } from "../../../common/dictionary-editor-models/IDictionaryEditorState";
import { TUiBreadcrumb } from "../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { IDictionaryDraftFilter } from "../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter";
import { IDictionaryDraft } from "../../../common/dictionary-draft-models/DictionaryDraft";
import { CheckItems } from "../common/functions/getItemsAreChanged";
import { IRegion } from "../locales-editor/getCultures";
import { IRegionsDictionaryDraftItem, RegionsDictionaryDraft, RegionsDictionaryDraftItem } from "./regions-editor-node/RegionsEditorNode";

export function GetIds(items: IRegionsDictionaryDraftItem[], ids: string[] = []) {
  items.forEach(item => {
    if (!ids.includes(item.id)) {
      ids.push(item.id);
    };
    if (item.items) {
      GetIds(item.items, ids);
    };
  });
}

function CheckValidityOld(item: IRegionsDictionaryDraftItem): boolean {
  const region = new RegionsDictionaryDraftItem(item);
  if (region.hasNoTranslation)
    return false;
  else
    return true;
}

function CheckValidity(item: IRegionsDictionaryDraftItem): boolean {
  return !(item as RegionsDictionaryDraftItem).hasNoTranslation;
}

export interface ISearchStrings {
  searchId: string[];
  searchName: string[];
}

export function UpdateSearchStrings(items: IRegionsDictionaryDraftItem[], searchStrings?: ISearchStrings) {
  const parentSearchStrings = searchStrings ? searchStrings : {
    searchId: [],
    searchName: []
  };
  //---------------------------------------------------------------------------
  items.forEach(item => {
    parentSearchStrings?.searchId.push(item.id);
    //---------------------------------
    if (item.name)
      parentSearchStrings?.searchName.push(item.name);
    //---------------------------------
    item.searchId = [];
    item.searchName = [];
    //---------------------------------
    if (item.items && item.items.length > 0) {
      const subSearchStrings = {
        searchId: [],
        searchName: []
      };
      UpdateSearchStrings(item.items, subSearchStrings);
      item.searchId = subSearchStrings.searchId;
      item.searchName = subSearchStrings.searchName;
      parentSearchStrings.searchId = parentSearchStrings.searchId.concat(subSearchStrings.searchId);
      parentSearchStrings.searchName = parentSearchStrings.searchName.concat(subSearchStrings.searchName);
    };
  });
  searchStrings = parentSearchStrings;
};

export interface INewRegionData {
  path?: string; // Undefined path means root element
  region?: IRegion;
}

export interface IRegionsDictionaryDraft extends IDictionaryDraft<IRegionsDictionaryDraftItem> {
}
export interface IRegionsEditorState extends IDictionaryEditorState<IRegionsDictionaryDraft> {
  regionIds?: string[];
}
export interface IRegionsEditorStateSpread {
  basePath?: string;
  numberOfApprovals?: number;
  filter?: IDictionaryDraftFilter;
  editModeIsEnabled?: boolean;
  sourceLocale?: string | undefined;
  breadcrumb?: TUiBreadcrumb | undefined;
  draft?: IRegionsDictionaryDraft;
  draftSessionInitial?: IRegionsDictionaryDraft;
  isUpdatedInSession?: boolean;
  isUpdated?: boolean;
  isValid?: boolean;
  isReadyToPublish?: boolean;
}
export class RegionsEditorState implements IRegionsEditorState {
  basePath!: string;
  numberOfApprovals!: number;
  filter!: IDictionaryDraftFilter;
  editModeIsEnabled!: boolean;
  sourceLocale?: string | undefined;
  breadcrumb?: TUiBreadcrumb | undefined;
  draft!: RegionsDictionaryDraft;
  draftSessionInitial!: RegionsDictionaryDraft;
  isUpdatedInSession!: boolean;
  isUpdated!: boolean;
  isValid!: boolean;
  isReadyToPublish!: boolean;
  regionIds?: string[] = [];
  //---------------------------------------------------------------------------
  constructor(
    state: IRegionsEditorState,
    spread?: IRegionsEditorStateSpread
  ) {
    Object.assign(this, state, spread);
    //this.draft.items = this.draft.items.map(item => new RegionsDictionaryDraftItem(item));
    UpdateSearchStrings(this.draft.items);
    if (this.draft && this.draftSessionInitial) {
      this.checkChangesAndValidate();
    };
    GetIds(this.draft.items, this.regionIds);
  };
  //---------------------------------------------------------------------------
  checkChangesAndValidate() {
    // 1. Check if draft is updated
    const checkResults = CheckItems<IRegionsDictionaryDraftItem>({
      propertiesToCheck: ['deleted', 'description', 'id', 'index', 'isActive', 'name', 'path', 'pathNew', 'pathOld', 'status'],
      items: this.draft.items,
      itemsInitial: this.draftSessionInitial.items,
      itemsPublished: (this.draft.publishedVersion as IRegionsDictionaryDraft).items,
      validator: CheckValidity
    });
    this.isUpdated = checkResults.isUpdatedInSession;
    this.isValid = checkResults.isValid;
    this.isReadyToPublish = checkResults.isValid && checkResults.isUpdated;
    //this.isUpdated = getItemsAreChanged<IRegionsDictionaryDraftItem>(itemPropertiesToCheck, this.draft.items, this.draftSessionInitial.items);
  };
}

