import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathGetDictionariesWithTranslations, pathGetDictionaryTranslationDrafts } from "../../../../utils/apiPathConstant";
import { apiGetPrivate, checkResponse } from "../../../../utils/api";
import { IDictionaryListItem } from "./DictionaryList";

export default function getDictionaryTranslationDrafts(
  msalInstanse: IPublicClientApplication,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<IDictionaryListItem[]> {
  return new Promise((resolve, reject) => {
    const uri = `${apiBasePath}${pathGetDictionariesWithTranslations}`;
    //-------------------------------------------------------------------------
    apiGetPrivate(
      msalInstanse,
      authConfig,
      uri,
      abortSignal
    ).then(response => {
      checkResponse(response, "getDictionaryTranslationDrafts");
      resolve(response?.content as IDictionaryListItem[]);
    }).catch(error =>
      reject(error)
    );
  });
}
