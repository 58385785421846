import { BusinessSearchListItem } from "./BusinessSearchListItem";
import styles from "./PercentDonut.module.css";

const radius = 15.91549430918954;
const c = 21;
const wh = c * 2;
const viewBox = `0 0 ${wh} ${wh}`;
const strokeWidth = 5;

interface IProps {
  data: BusinessSearchListItem;
  percents: boolean;
  onToggle: () => void;
}

export function PercentDonut(props: IProps) {
  //console.log(props.data);
  const p1 = props.data.tagRatePercent;
  const p2 = 100 - p1;
  const dashArray = `${p1} ${p2}`;
  return (
    <div className={styles.container}>
      <svg
        width="100%"
        height="100%"
        viewBox={viewBox}
        /*class="donut"*/
        onClick={props.onToggle}
        cursor="pointer"
      >
        {/*<circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>*/}
        {/*<circle class="donut-ring" cx={c} cy={c} r={radius} fill="transparent" stroke="#d2d3d4" stroke-width="5"></circle>*/}
        <circle
          //class="donut-ring"
          className={styles.circleBack}
          cx={c} cy={c}
          r={radius}
        //fill="transparent"
        //stroke="var(--clr_bkgr_base)"
        //stroke-width={strokeWidth}
        />

        <circle
          //class="donut-segment"
          className={styles.circleFront}
          cx={c} cy={c}
          r={radius}
          //stroke-dasharray={dashArray}
          strokeDasharray={dashArray}
          //stroke-dashoffset="25"
          strokeDashoffset="25"
        //fill="transparent"
        //stroke="var(--clr_accent_main)"
        //stroke-width={strokeWidth}
        >

        </circle>
      </svg>
      <label
        className={styles.text}
        onClick={props.onToggle}
      //x="50%"
      //y="50%"
      //dominant-baseline="middle"
      //text-anchor="middle"
      //fill="var(--clr_font_base)"
      //fontSize="1em"
      >
        {/*`${p1}`*/}
        {props.percents ? `${p1}` : props.data.tagRate}
      </label>
    </div>
  );
}