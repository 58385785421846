import React, { useContext } from 'react';
import styles from './AdCampaignHeader.module.css';
import { IUiListItemAdCampaign } from '../IUiListItemAdCampaign';
import GetCaption from '../../../../../common/functions/GetCaption';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../../../common/functions/GetName';
import { IAdCampaign } from '../../../../../../context/business-context/IAdCampaign';

interface IProps {
  ui: IUiListItemAdCampaign;
  data: IAdCampaign;
}

export default function AdCampaignHeader(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const adRank = appUiContext.dictionaries?.adRanks?.find(item => item.id === props.data.adRank?.id);
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <div className={styles.brand}>
        {props.data.name}
      </div>
      <div className={styles.rank}>
        <span className={styles.caption}>
          {GetCaption(appUiContext, props.ui.inputAdCampaignRank.id, props.ui.inputAdCampaignRank.caption)}:
        </span>
        <span className={styles.value}>
          {adRank ? GetName(appUiContext, adRank.id, adRank.name) : "Unknown"}
        </span>
      </div>
    </React.Fragment>
  );
}