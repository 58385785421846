import React, { useRef } from "react";
import { CSSProperties, useEffect, useState } from "react";
import { EImageSize, TImageData } from "../../../../common/image-editor/TImageData";
import styles from "./ImageThumbnail.module.css";
import { useAbortController } from "../../../../../hooks/useAbortController";

interface IProps {
  data: TImageData | undefined;
  width?: string;
  height?: string;
}

export default function ImageThumbnail(props: IProps) {
  const [size, setSize] = useState<EImageSize>('Thumbnail');
  const [imageUri, setImageUri] = useState<string>();
  const imgRef = useRef<HTMLImageElement>(null);
  const imgRef2 = useRef<HTMLImageElement | null>(null);
  const abortController = useAbortController("ImageThumbnail");
  //--------------------------------------------------------------------------- []
  useEffect(() => {
    return (() => {
      //console.log("ImageThumbnail.useEffect[].return. imgRef2.current:", imgRef2.current);
      if (imgRef2.current) {
        imgRef2.current.src = '';
      }
      abortController.abortOnUnmount();
    });
  }, []);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (imgRef.current) {
      imgRef2.current = imgRef.current;
    }
  }, [imgRef.current]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    //console.log("ImageThumbnail.useEffect[props.data]", props.data?.id);
    //getImage("Thumbnail");
    //getImage(size);
    getImage2();
  }, [props.data, size]);
  //---------------------------------------------------------------------------
  const setUri = (uri?: string) => {
    //props.data?.id == "cf1ded7e-cfcb-4f54-9bf0-9f0eae7af627" && console.log("ImageThumbnail.setUri:", uri);
    //console.log("ImageThumbnail.setUri", uri);
    setImageUri(uri);
  }
  //---------------------------------------------------------------------------
  const getImage = (size: EImageSize) => {
    console.log("ImageThumbnail.getImage", props.data?.id, size);
    if (props.data) {
      let controller = abortController.newController(`getUrlCallback[${size}]`);
      if (props.data?.id == "cf1ded7e-cfcb-4f54-9bf0-9f0eae7af627") {
        props.data.getUrlCallback(size, setUri, controller.signal);
      }
      else {
        props.data.getUrlCallback(size, setUri, controller.signal);
      }
    }
  }
  //---------------------------------------------------------------------------
  const getImage2 = () => {
    //console.log("ImageThumbnail.getImage", props.data?.id, size);
    if (props.data) {
      let controller = abortController.newController(`getUrlCallback[${size}]`);
      if (props.data?.id == "cf1ded7e-cfcb-4f54-9bf0-9f0eae7af627") {
        props.data.getUrlCallback(size, setUri, controller.signal);
      }
      else {
        props.data.getUrlCallback(size, setUri, controller.signal);
      }
    }
  }
  //---------------------------------------------------------------------------
  const style: CSSProperties = {
    width: props.width ? props.width : undefined,
    height: props.height ? props.height : props.width,
    border: '1px solid var(--clr_card_inactive)',
  };
  //---------------------------------------------------------------------------
  if (!imageUri)
    return null;
  return (
    <div style={style} >
      <img
        ref={imgRef}
        className={styles.img}
        src={imageUri}
        alt={props.data?.description}
        loading="lazy"
        onError={(error) => {
          console.log("ImageThumbnail.onError.currentSize:", size);
          switch (size) {
            case 'Thumbnail':
              setSize('Medium');
              break;
            case 'Medium':
              setSize('Large');
              break;
          }
          //getImage("Medium")
          //getImage2();
        }}
      />
    </div>
  );
}
