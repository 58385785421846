import { useContext, useEffect, useState } from 'react';
import styles from './DictionaryList.module.css';
import DictionaryListItem from './dictionary-list-item/DictionaryListItem';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import getDictionaryTranslationDrafts from './getDictionaryTranslationDrafts';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import { ELogLevel, LogInformation } from '../../../../utils/logging';
import { useAbortController } from '../../../../hooks/useAbortController';
import { EDictionaryType } from '../dictionary-draft-models/EDictionaryType';
import ConsoleContent from '../../../common/console-layout/console-content/ConsoleContent';
import GetHint from '../../../common/functions/GetHint';

let logLevel: ELogLevel = ELogLevel.None;

export interface IDictionaryTranslationDraftListItem {
  dictionaryId: string;
  locales: string[];
}

export interface IDictionaryListItem {
  dictionaryId: EDictionaryType;
  name: string;
  description: string;
  translations: IDicionaryTranslationListItem[];
}

export interface IDicionaryTranslationListItem {
  locale: string;
  translationExists: boolean;
  translationDraftExists: boolean;
}

interface IProps {
  onSelect: (dictionaryId: EDictionaryType, locale?: string) => void;
}

export default function DictionaryList(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IDictionaryListItem[]>();
  const abortController = useAbortController("DictionaryList");
  //--------------------------------------------------------------------------- Data, breadcrumbs
  useEffect(() => {
    // Get data: i.e. list of existing drafts
    let controller = abortController.newController("getDictionaryTranslationDrafts");
    getDictionaryTranslationDrafts(
      instance, appAuthContext.config,
      controller.signal
    ).then(response => {
      LogInformation(logLevel, "Dictionaries:", response);
      !controller.aborted && setData(response);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //---------------------------------------------------------------------------
  const listItems = data?.map(dictionaryItem => (
    <DictionaryListItem
      key={dictionaryItem.dictionaryId}
      mode='Translator'
      data={dictionaryItem}
      onSelect={(locale?: string) => props.onSelect(dictionaryItem.dictionaryId, locale)}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={GetHint(appUiContext, "dictionariesPrompt", { en: "Translate Riki.Wiki dictionaries content" })}
      containerWidthPercent={70}
    >
      <WikiSpinner show={isLoading} />
      <div className={styles.container}>
        {listItems}
      </div>
    </ConsoleContent>
  );
}