import styles from "./Toolbar.module.css";

interface IProps {
  children: React.ReactNode;
}

export default function Toolbar(props: IProps) {
  return (
    <div className={styles.container}>
      {props.children}
    </div>
  );
}