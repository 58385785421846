import { useContext, useEffect, useState } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import useNavigateWithContext from '../../../../hooks/useNavigateWithContext';
import { pathAdminConsole, pathAdministratorConsole } from '../../AdminConsole';
import { optionIdDictionaries, pathAdRanksEditor, pathBusinessTagsEditor, pathDictionaries, pathDictionaryRejectionReasons, pathLocalesEditor, pathRegionsEditor } from '../AdministratorConsole';
import DictionaryCard from './dictionary-card/DictionaryCard';
import { TDictionaryListItem } from './dictionary-card/TDictionaryListItem';
import styles from './DictionaryList.module.css';
import { TUiDictionaryList } from './TUiDictionaryList';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import getDictionaries from './getDictionaries';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import { useAbortController } from '../../../../hooks/useAbortController';
import { EDictionaryType, getIcon } from '../../common/dictionary-draft-models/EDictionaryType';
import GetHint from '../../../common/functions/GetHint';
import ConsoleContent from '../../../common/console-layout/console-content/ConsoleContent';
import { IUiOption } from '../../../common/options/IUiOption';
import { TUiBreadcrumb } from '../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';

interface IProps {
  ui: TUiDictionaryList;
  optionsForBreadcrumb?: IUiOption[];
}

export default function DictionaryList(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const navigate = useNavigateWithContext();
  const [dictionaries, setDictionaries] = useState<TDictionaryListItem[]>();
  const [isLoading, setIsLoading] = useState(true);
  const abortController = useAbortController("DictionaryList");
  //--------------------------------------------------------------------------- AbortController
  useEffect(() => {
    return () => {
      abortController.abortOnUnmount();
    };
  }, []);
  //--------------------------------------------------------------------------- Breadcrumb, data
  useEffect(() => {
    /* if (props.ui.breadcrumb) {
      appUiContextDispatch({
        type: "UpdateBreadcrumbsTrail", value: {
          ...props.ui.breadcrumb,
          path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}`
        }
      });
    }; */
    //------------------------------------------------------------------------- Get data
    setIsLoading(true);
    let controller = abortController.newController("getDictionaries");
    getDictionaries(
      instance, appAuthContext.config, controller.signal
    ).then(dictionaries => {
      !controller.aborted && setDictionaries(dictionaries);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  }, [props.ui.breadcrumb]);
  //---------------------------------------------------------------------------
  const onSelect = (dictionaryId: EDictionaryType) => {
    switch (dictionaryId) {
      case "BusinessTags":
        var path = `${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}/${pathBusinessTagsEditor}`;
        navigate(path);
        break;
      //-----------------------------------------------------------------------
      case "AdRanks":
        var path = `${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}/${pathAdRanksEditor}`;
        navigate(path);
        break;
      //-----------------------------------------------------------------------
      case "SupportedLocales":
        var path = `${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}/${pathLocalesEditor}`;
        navigate(path);
        break;
      //-----------------------------------------------------------------------
      case "SupportedRegions":
        var path = `${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}/${pathRegionsEditor}`;
        navigate(path);
        break;
      //-----------------------------------------------------------------------
      case "RequestRejectionReasons":
        var path = `${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}/${pathDictionaryRejectionReasons}`;
        console.log(path)
        navigate(path);
        break;
      //-----------------------------------------------------------------------
      default:
        alert("Sorry, not implemented yet");
        break;
    }
  };
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdDictionaries);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        caption: option.caption,
        path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
  }, [props.optionsForBreadcrumb]);
  //---------------------------------------------------------------------------
  const listElements = dictionaries?.map(item => (
    <DictionaryCard
      key={item.dictionaryId}
      data={{ ...item, icon: getIcon(item.dictionaryId) }}
      onSelect={onSelect}
    />
  ));
  //---------------------------------------------------------------------------
  return (    
    <ConsoleContent
      prompt={GetHint(appUiContext, props.ui.prompt.id, props.ui.prompt.text)}
      containerWidthPercent={70}
    >
      <WikiSpinner show={isLoading} />
      <div className={styles.container}>
        {listElements}
      </div>
    </ConsoleContent>
  );
}