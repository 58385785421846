import { createContext, Dispatch, ReactNode, useContext, useReducer } from "react";
import { errorUi, INotificationContextData } from "./INotificationContextData";
import { Actions, NotificationContextReducer } from "./NotificationContextReducer";
import PopupMessage from "../../components/common/popup-v2/popup-message/PopupMessage";
import { AppUiContextStore } from "../app-ui-context/AppUiContextProvider";
import GetCaption from "../../components/common/functions/GetCaption";
import GetHint from "../../components/common/functions/GetHint";
import { getTUiMenuContent } from "../../components/common/menu/menu-content/TUiMenuContent";
import useNavigateWithContext from "../../hooks/useNavigateWithContext";
import PopupError from "../../components/common/popup-v2/popup-error/PopupError";

// NOTE: Update this context for keeping messages history?

interface INotificationContext {
  notificationContext: INotificationContextData;
  notificationContextDispatch: Dispatch<Actions>;
}

export const NotificationContext = createContext<INotificationContext>({} as INotificationContext);

interface IProps {
  children: ReactNode;
}

export default function NotificationContextProvider(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const navigate = useNavigateWithContext();
  const [notificationContext, notificationContextDispatch] = useReducer(
    NotificationContextReducer,
    {} as INotificationContextData);
  //---------------------------------------------------------------------------
  const onUnsavedChangesOptionSelect = (selectedOptionId: string) => {
    if (!notificationContext.unsavedChanges) return;
    if (selectedOptionId === notificationContext.unsavedChanges.confirmOptionId) {
      notificationContext.unsavedChanges.pathDestination && navigate(notificationContext.unsavedChanges.pathDestination);
    } else {
      if (notificationContext.unsavedChanges.callback)
        notificationContext.unsavedChanges.callback(selectedOptionId, notificationContext);
      notificationContextDispatch({ type: "UnsavedChangesReset" });
    };
  };
  //---------------------------------------------------------------------------
  const onErrorNotificationClose = () => {
    notificationContextDispatch({ type: "CleanupError" });
  };
  //---------------------------------------------------------------------------
  return (
    <NotificationContext.Provider
      value={{ notificationContext, notificationContextDispatch }}>
      {props.children}

      {notificationContext.unsavedChanges &&
        notificationContext.unsavedChanges.ui &&
        notificationContext.unsavedChanges.showNotification &&
        notificationContext.unsavedChanges.notificationType &&
        <PopupMessage
          id={notificationContext.unsavedChanges.ui.id}
          type={notificationContext.unsavedChanges.notificationType}
          header={GetCaption(appUiContext, notificationContext.unsavedChanges.ui.id, notificationContext.unsavedChanges.ui.caption)}
          message={GetHint(appUiContext, notificationContext.unsavedChanges.ui.id, notificationContext.unsavedChanges.ui.hint)}
          optionsMenuContent={getTUiMenuContent(notificationContext.unsavedChanges.ui.menuContent)}
          show={notificationContext.unsavedChanges.showNotification}
          onOptionSelect={onUnsavedChangesOptionSelect}
          onCancel={() => onUnsavedChangesOptionSelect("optionCancel")}
        />}

      {notificationContext.error &&
        <PopupError
          ui={{
            ...errorUi,
            caption: { en: notificationContext.error.header },
            hint: { en: notificationContext.error.message }
          }}
          show={true}
          onClose={onErrorNotificationClose}
        />}

    </NotificationContext.Provider>
  );
}