import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathCreateAdCampaignSystemBusinessRegional } from "../../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../../utils/common";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { ClassSystemBusinessRegional, ISystemBusinessRegional } from "../ISystemBusinessRegional";

interface IProps {
  msalInstanse: IPublicClientApplication,
  authConfig: IAuthConfig,
  businessId: string,
  business?: ISystemBusinessRegional,
  adCampaignName: string,
  abortSignal?: AbortSignal
}

export default async function createSystemBusinessAdCampaignAsync(props: IProps): Promise<ClassSystemBusinessRegional> {
  if (!props.authConfig) throw new Error("createSystemBusinessAdCampaignAsync: auth config is not set");
  if (!props.businessId) throw new Error("createSystemBusinessAdCampaignAsync: business id is not provided");
  if (!props.adCampaignName) throw new Error("createSystemBusinessAdCampaignAsync: ad campaign name is not provided");
  //---------------------------------------------------------------------------
  const uri = `${apiBasePath}${stringFormatter(pathCreateAdCampaignSystemBusinessRegional, [props.businessId, props.adCampaignName])}`;
  const response = await apiPostPrivate(
    props.msalInstanse,
    props.authConfig,
    uri,
    props.business
  );
  checkResponse(response, "createSystemBusinessAdCampaignAsync", props.abortSignal?.aborted);
  const business = new ClassSystemBusinessRegional(response?.content);
  return business;
};