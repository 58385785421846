import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { ApiKey } from "./apikey-card/ApiKeyCard";
import { apiGetPrivate, checkResponse } from "../../../../utils/api";
import { apiBasePath, pathGetApiKey } from "../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../utils/common";

export default async function getApiKey(
  msalInstance: IPublicClientApplication,
  apiKeyName?: string,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal,
): Promise<ApiKey> {
  return new Promise((resolve, reject) => {
    if (!apiKeyName)
      return reject("Cannot get API key: API Key name is not provided");
    const uri = `${apiBasePath}${stringFormatter(pathGetApiKey, [apiKeyName])}`;
    apiGetPrivate(
      msalInstance,
      authConfig,
      uri,
      abortSignal
    ).then(response => {
      checkResponse(response, "getApiKey");
      resolve(new ApiKey(response?.content));
    }).catch(error =>
      reject(error)
    );
  });
}