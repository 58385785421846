export interface ITranslation {
  value?: string;
  // Should be set to true if name/description is not provided, otherwise node is considered invalid
  fallbackAccepted: boolean;
}

export class Translation implements ITranslation {
  value?: string;
  fallbackAccepted!: boolean;
  //---------------------------------------------------------------------------
  constructor(source: ITranslation) {
    Object.assign(this, source);
  };
  //---------------------------------------------------------------------------
  noTranslation(): boolean {
    if (!this.value && !this.fallbackAccepted) return true;
    return false;
  };
  //---------------------------------------------------------------------------
  isTheSameAs(t?: ITranslation): boolean {
    if (!t) return false;
    if (t.fallbackAccepted !== this.fallbackAccepted) return false;
    if (t.value !== this.value) return false;
    return true;
  };
}