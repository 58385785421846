import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../../context/app-auth-context/AppAuthContext";
import { apiPostPrivate, checkResponse } from "../../../../../../utils/api";
import { apiBasePath, pathUpdateDictionaryDraft } from "../../../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../../../utils/common";
import { ILockable, TLocker } from "../../../../../common/locks/TLocker";

export default function updateDictionaryDraft(
  msalInstance: IPublicClientApplication,
  dictionary: any,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<any> {
  return new Promise((resolve, reject) => {
    if (!dictionary)
      throw "Cannot update dictionary draft: dictionary data is not provided";
    let lockerErrorPrefix = (dictionary as ILockable).locker?.errorPrefix;
    let dictionaryToPass = { ...dictionary, locker: undefined };
    //console.log(dictionary, dictionary.id, `${apiBasePath}${stringFormatter(pathUpdateDictionaryDraft, [dictionary.id])}`)
    let url = `${apiBasePath}${stringFormatter(pathUpdateDictionaryDraft, [dictionary.dictionaryId])}`;
    //---------------------------------------------------------------------
    apiPostPrivate(
      msalInstance,
      authConfig,
      url,
      dictionaryToPass,
      undefined,
      abortSignal
    ).then(response => {
      checkResponse(response, "updateDictionaryDraft", abortSignal?.aborted);
      let locker = (response?.content as ILockable).locker;
      if (locker) {
        locker.errorPrefix = lockerErrorPrefix;
      }
      resolve(response?.content);
    }).catch(error =>
      reject(error));
  });
}