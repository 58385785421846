import { ImageEditorSession } from "./image-editor-context/ImageEditorSession";
import PopupMessage from "../popup-v2/popup-message/PopupMessage";
import { TUiMenuContent } from "../menu/menu-content/TUiMenuContent";
import { TImageData } from "./TImageData";

export enum EEnterImageEditMode {
  Cancel = 0,
  EditThisImage = 1,
  CreateCopy = 2,
}

const dialogOptions = {
  edit: 'Edit',
  copy: 'Copy',
  cancel: 'Cancel'
}

const editOption = {
  id: dialogOptions.edit,
  directoryId: "",
  elementType: "",
  index: 1,
  priorityLevel: 1,
  caption: { en: dialogOptions.edit },
  hint: null,
  iconFile: "",
  isDefault: false,
  disabled: false,
  visible: true,
  canHideCaption: false,
  action: null
}

const cancelOption = {
  id: dialogOptions.cancel,
  directoryId: "",
  elementType: "",
  index: 1,
  priorityLevel: 1,
  caption: { en: dialogOptions.cancel },
  hint: null,
  iconFile: "",
  isDefault: false,
  disabled: false,
  visible: true,
  canHideCaption: false,
  action: null
}

const copyOption = {
  id: dialogOptions.copy,
  directoryId: "",
  elementType: "",
  index: 1,
  priorityLevel: 1,
  caption: { en: dialogOptions.copy },
  hint: null,
  iconFile: "",
  isDefault: false,
  disabled: false,
  visible: true,
  canHideCaption: false,
  action: null
}

const tmpOptionsMenuContent: TUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  visible: true,
  disabled: false,
  options: [],
  optionGroups: []
}

interface IProps {
  show: boolean;
  imageData?: TImageData;
  onResponse: (editMode: EEnterImageEditMode) => void;
}

export function ImageEditorUsagePopup(props: IProps) {
  if (!props.show || !props.imageData)
    return null;
  //---------------------------------------------------------------------------
  // we are here only if image is published or used elsewhere
  let header = "";
  let message = "";
  let content = tmpOptionsMenuContent;
  if (props.imageData.isPublic) {
    header = "Published image";
    message = "This image is published. You can not edit it. Create a copy?";
    content.options = [copyOption, cancelOption];
  } else {
    header = "Image is used elsewhere"
    message = "This image usage:";
    props.imageData.imageUsage?.forEach(usage => {
      message += `\n${usage.displayFull}`;
    });
    content.options = [copyOption, editOption, cancelOption];
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (id: string) => {
    switch (id) {
      case dialogOptions.edit:
        props.onResponse(EEnterImageEditMode.EditThisImage);
        break;
      case dialogOptions.copy:
        props.onResponse(EEnterImageEditMode.CreateCopy);
        break;
      default:
        props.onResponse(EEnterImageEditMode.Cancel);
        break;
    }
  }
  //---------------------------------------------------------------------------
  return (
    <PopupMessage
      id='ImageEditorUsagePopup'
      type='Confirmation'
      header={header}
      message={message}
      optionsMenuContent={content}
      show={props.show}
      onOptionSelect={onOptionSelect}
      onCancel={() => props.onResponse(EEnterImageEditMode.Cancel)}
    />
  );
}