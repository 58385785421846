import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathGetDictionaryTranslationDraft } from "../../../../../utils/apiPathConstant";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";
import { stringFormatter } from "../../../../../utils/common";
import { IDictionaryTranslationDraft } from "./DictionaryTranslatorV1";
import { ILockable } from "../../../../common/locks/TLocker";
//import { IDictionaryTranslationDraftResponse } from "./DictionaryTranslator";
let test = true;
export default function getDictionaryTranslationDraft(
  msalInstanse: IPublicClientApplication,
  dictionaryId: string,
  localeId: string,
  lockIsNeeded: boolean,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<IDictionaryTranslationDraft> {
  return new Promise((resolve, reject) => {
    //-------------------------------------------------------------------------
    let uri = `${apiBasePath}${stringFormatter(pathGetDictionaryTranslationDraft, [dictionaryId, localeId])}`;
    if (lockIsNeeded)
      uri += '?lockIsNeeded=true'
    apiGetPrivate(
      msalInstanse,
      authConfig,
      uri,
      abortSignal
    ).then(response => {
      checkResponse(response, "getDictionaryTranslationDraft", abortSignal?.aborted);
      let draft = response?.content as IDictionaryTranslationDraft;
      if (draft.locker) {
        draft.locker.errorPrefix = `${draft.dictionaryId} dictionary`;
      }
      resolve(draft);
    }).catch(error => {
      reject(error);
    });
  });
}