import { IListColumn } from "../../../../common/lists/IListColumn";
import { validatorModerator } from "../../functions/validatorModerator";
import { validatorStatus } from "../../functions/validatorStatus";

export const approvalRequestsListColumns: IListColumn[] = [{
  id: "moderator",
  name: "Moderator",
  filter: {
    type: "Options",
    options: [{
      id: "Unassigned"
    }, {
      id: "Assigned"
    }],
    validator: validatorModerator
  }
}, {
  id: "status",
  name: "Status",
  filter: {
    type: "Options",
    options: [{
      id: "New"
    }, {
      id: "Uncompleted",
      isSelected: true
    }, {
      id: "Completed"
    }, {
      id: "Approved"
    }, {
      id: "Rejected"
    }],
    validator: validatorStatus
  }
}, {
  id: "locale",
  name: "Locale",
  filter: {
    type: "Options",
    options: []
  }
}, /* {
  id: "Rank",
  filter: {
    type: "Options",
    options: [{ id: "Free" }, { id: "Paid" }]
  }
}, */ {
  id: "businessName",
  name: "Ad Page Name",
  filter: {
    type: "Keyword",
    keyword: "",
    placeholder: "Type a part of ad page name..."
  }
}, {
  id: "businessId",
  name: "Business Id",
  filter: {
    type: "Keyword",
    keyword: "",
    placeholder: "Paste business Id..."
  }
}, {
  id: "adLanguageSetId",
  name: "Ad Id",
  filter: {
    type: "Keyword",
    keyword: "",
    placeholder: "Paste ad language Id..."
  }
}, {
  id: "userId",
  name: "User Id",
  filter: {
    type: "Keyword",
    keyword: "",
    placeholder: "Paste user Id..."
  }
}, {
  id: "dateTimeCreated",
  name: "Created",
  filter: {
    type: "DatetimeRange"
  },
  sorter: {
    order: 1,
    sort: "Asc"
  }
}, {
  id: "dateTimeResolved",
  name: "Resolved",
  filter: {
    type: "DatetimeRange"
  },
  sorter: {
    order: 2
  }
}];