import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { IRegionalHomepageRequest } from "../request-form/IRegionalHomepageRequest";
import { apiBasePath, pathCompleteRequestRegional } from "../../../../../utils/apiPathConstant";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { stringFormatter } from "../../../../../utils/common";
import { AdLanguageSet, IAdLanguageSet } from "../../../../ad-content-editor/IAdLanguageSet";
import { IUiDictionaryItemRejectionReason } from "../../../../common/dictionaries/IUiDictionaryItem";
import { EHttpStatusCode } from "../../../../../utils/HttpStatusCodes";

interface IResponse {
  request: IRegionalHomepageRequest;
  dictionary?: IUiDictionaryItemRejectionReason[];
  error?: string;
}

interface IProps {
  msalInstanse: IPublicClientApplication;
  authConfig?: IAuthConfig | null;
  abortSignal?: AbortSignal;
  requestId?: string;
  localeId?: string;
  isApproved: boolean;
  adLanguageSet?: IAdLanguageSet;
}

export async function completeRegionalHomepageRequestAsync(props: IProps): Promise<IResponse> {
  //---------------------------------------------------------------------------
  if (!props.authConfig) throw new Error("completeRegionalHomepageRequest: auth config is not provided");
  if (!props.requestId) throw new Error("completeRegionalHomepageRequest: request Id is not provided");
  if (!props.localeId) throw new Error("completeRegionalHomepageRequest: locale Id is not provided");
  if (!props.adLanguageSet) throw new Error("completeRegionalHomepageRequest: ad language set is not provided");
  //---------------------------------------------------------------------------
  const uri = `${apiBasePath}${stringFormatter(
    pathCompleteRequestRegional,
    [props.requestId, props.localeId, props.isApproved.toString()]
  )}`;
  const response = await apiPostPrivate(
    props.msalInstanse,
    props.authConfig,
    uri,
    props.adLanguageSet,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(
    response,
    "completeRegionalHomepageRequest",
    props.abortSignal?.aborted,
    [EHttpStatusCode.Conflict]
  );
  const content: IResponse = response?.content;
  const request: IRegionalHomepageRequest = content.request;
  const adLanguageSet = new AdLanguageSet(content.request.adLanguageSet);
  request.adLanguageSet = adLanguageSet;
  return {
    request: request,
    dictionary: content.dictionary,
    error: content.error
  };
}