import React from 'react';
import CombineStyles from '../../../../utils/combineStyles';
import { IListColumn } from '../IListColumn';
import ColumnOptionWrapper from '../column-option-wrapper/ColumnOptionWrapper';
import IconMove from '../icons/IconMove';
import IconSort from '../icons/IconSort';
import { ISorterItem } from './ISorterItem';
import styles from './SorterEditor.module.css';

export type TMove = "Up" | "Down";

interface IProps {
  column: IListColumn;
  total: number;
  //onUpdate: (columnId: string, updatedSorter?: ISorterItem, move?: TMove) => void;
  onSortingOrderUpdate: (updatedColumn: IListColumn) => void;
  onSortingIndexUpdate: (columnId: string, moved: TMove) => void;
}

export default function SorterEditor(props: IProps) {
  const columnSortingIsRequired = !!props.column.sorter?.isRequired;
  const columnSortingIsDisabled = !props.column.sorter || !!props.column.sorter.isDisabled;
  const isFirstItem = props.column.sorter?.order == 1;
  const isLastItem = props.column.sorter?.order == props.total;
  const isEnabled = !columnSortingIsDisabled && !columnSortingIsRequired;
  //---------------------------------------------------------------------------
  const onMoveUp = () => {
    if (!props.column.sorter || isFirstItem) return;
    props.onSortingIndexUpdate(props.column.id, "Up");
  };
  //---------------------------------------------------------------------------
  const onMoveDown = () => {
    if (!props.column.sorter || isLastItem) return;
    props.onSortingIndexUpdate(props.column.id, "Down");
  };
  //---------------------------------------------------------------------------
  const onAsc = () => {
    props.onSortingOrderUpdate({
      ...props.column,
      sorter: {
        ...props.column.sorter as ISorterItem,
        sort: "Asc",
        isDisabled: false
      }
    });
  };
  //---------------------------------------------------------------------------
  const onDesc = () => {
    props.onSortingOrderUpdate({
      ...props.column,
      sorter: {
        ...props.column.sorter as ISorterItem,
        sort: "Desc",
        isDisabled: false
      }
    });
  };
  //---------------------------------------------------------------------------
  const onClear = () => {
    props.onSortingOrderUpdate({
      ...props.column,
      sorter: {
        ...props.column.sorter as ISorterItem,
        sort: undefined,
        isDisabled: true
      }
    });
  };
  //---------------------------------------------------------------------------
  return (
    <ColumnOptionWrapper
      caption={props.column.name}
      readonly={true}
      isEnabled={isEnabled}
      hideApplyOption={true}
      onClear={columnSortingIsRequired || columnSortingIsDisabled ? undefined : onClear}
      onFocusLost={() => {}}
    >
      <div className={styles.container}>

        {/* <div className={styles.option}>
          {props.column.sorter?.order}
        </div> */}

        {props.total > 1 &&
          <React.Fragment>
            <div
              className={CombineStyles([
                styles.option,
                styles.icon,
                columnSortingIsDisabled || isFirstItem ? styles.disabled : ""
              ])}
              onClick={onMoveUp}
            >
              <IconMove move='Up' />
            </div>

            <div
              className={CombineStyles([
                styles.option,
                styles.icon,
                columnSortingIsDisabled || isLastItem ? styles.disabled : ""
              ])}
              onClick={onMoveDown}
            >
              <IconMove move='Down' />
            </div>
          </React.Fragment>}

        <div
          className={CombineStyles([
            styles.option,
            styles.icon,
            !props.column.sorter?.sort || props.column.sorter.sort != "Asc" ? styles.disabled : ""
          ])}
          onClick={onAsc}
        >
          <IconSort expanded={true} />
        </div>

        <div
          className={CombineStyles([
            styles.option,
            styles.icon,
            !props.column.sorter?.sort || props.column.sorter.sort != "Desc" ? styles.disabled : ""
          ])}
          onClick={onDesc}
        >
          <IconSort expanded={false} />
        </div>

      </div>
    </ColumnOptionWrapper>
  );
}