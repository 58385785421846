import { useEffect, useRef, useState } from "react";
import { EImageSize, TImageData } from "./TImageData";
import { AbortControllerManager, useAbortController } from "../../../hooks/useAbortController";
//--------------------------------------------------------------------------
export class TSmoothImageLoader {
  private _imageData: TImageData | undefined;
  private _startSize: EImageSize;
  private _targetSize: EImageSize;
  private _bitmap: HTMLImageElement;   // Create new img element
  private _callback: (url: string) => void;
  //-------------------------------------------------------------------------
  constructor(
    imageData: TImageData | undefined, 
    startSize: EImageSize, targetSize: EImageSize,
    callback: (url: string) => void) {
    this._imageData = imageData;
    this._startSize = startSize;
    this._targetSize = targetSize;
    this._bitmap = new Image();
    this._bitmap.crossOrigin = "anonymous"; // Handle CORS issues
    this._bitmap.onload = this.onLoad.bind(this);
    this._bitmap.onerror = () => {
      console.log("bitmap.onerror", imageData?.id);
    };
    this._callback = callback;
    //console.log(`TSmoothImageLoader: [${this._imageData?.id}], ${startSize} -> ${targetSize}`);
  }
  //-------------------------------------------------------------------------
  getUrls(abortController: AbortControllerManager) {
      if (!this._imageData) {
        return;
      }
    if (this._imageData.editedImageUrl) {
      return this._callback(this._imageData.editedImageUrl);
    }
    let startController = abortController.newController(this._startSize);
    this._imageData.getUrlPromise(this._startSize, startController.signal)
      .then(url => {
        //console.log(`${this._imageData?.id}: ${this._startSize}`);
        this._callback(url);
      })
      .catch(error => console.error(error));
    let targetController = abortController.newController(this._targetSize);
    this._imageData.getUrlPromise(this._targetSize, targetController.signal)
      .then(url => { this._bitmap.src = url })
      .catch(error => console.error(error));
  }
  //-------------------------------------------------------------------------
  private onLoad() {
    //console.log("ImageEditorSession.loadImageFromUri.onImageLoaded");
    /*this._targetSize == 'Large' && */console.log(`onLoad: ${this._imageData?.id}: ${this._targetSize}`);
    this._callback(this._bitmap.src);
  }
};
//--------------------------------------------------------------------------
export function useSmoothImageLoader(
  imageData: TImageData | undefined,
  startSize: EImageSize, targetSize: EImageSize,
  callback: (url: string) => void) {
  //--------------------------------------------------------------------------
  //console.log(`useSmoothImageLoader[${imageData?.id}]`);
  const ref = useRef(new TSmoothImageLoader(imageData, startSize, targetSize, callback));
  return ref.current;
}
//--------------------------------------------------------------------------
function ImageIsTheSame(imageInProps: TImageData, local?: TImageData) {
  //console.log("ImageIsTheSame.local:", local);
  if (!local) return false;
  if (local.id != imageInProps.id) return false;
  if (local.userId != imageInProps.userId) return false;
  if (local.imageStatus != imageInProps.imageStatus) return false;
  if (local.editedImageUrl != imageInProps.editedImageUrl) return false;
  return true;
}
//--------------------------------------------------------------------------
interface IProps {
  imageData?: TImageData;
  sourceSize: EImageSize;
  targetSize: EImageSize;
  onLoad: (url: string | undefined) => void;
}
//--------------------------------------------------------------------------
const testGuid = "";//"0bf80229-46ce-40f0-b210-4c88ed32a5fe";//"f7add114-d03c-476e-8a05-31655968f6c0";
//--------------------------------------------------------------------------
export function SmoothImageLoader(props: IProps) {
  const [url, setUrl] = useState<string>();
  const isTargetLoaded = useRef(false);
  const abortController = useAbortController("SmoothImageLoader");
  const imageData = useRef<TImageData>();
  //--------------------------------------------------------------------------- []
  useEffect(() => {
    return (() => {
      abortController.abortOnUnmount();
      imageData.current = undefined;
    });
  }, []);
  //-----------------------------------------------------------------------
  useEffect(() => {
    if (!props.imageData)
      return props.onLoad(undefined);
    if (ImageIsTheSame(props.imageData, imageData.current))
      return;
    //console.log(`SmoothImageLoader.useEffect[props.imageData]:`, props.imageData);
    imageData.current = props.imageData;
    if (props.imageData.editedImageUrl) {
      console.log(`SmoothImageLoader[${props.imageData.id}]: using editedImageUrl`);
      props.onLoad(props.imageData.editedImageUrl);
      return;
    }
    isTargetLoaded.current = false;
    let sourceController = abortController.newController("getUrlCallback_source");
    props.imageData.getUrlCallback(props.sourceSize, onLoadSource, sourceController.signal);
    let targetController = abortController.newController("getUrlCallback_target");
    props.imageData.getUrlCallback(props.targetSize, setUrl, targetController.signal);
  }, [props.imageData]);
  //-----------------------------------------------------------------------
  const onLoadSource = (url?: string) => {
    if (isTargetLoaded.current) {
      return;
    }
    props.onLoad(url);
    if (props.imageData?.id === testGuid) {
      console.log("SmoothImageLoader.onLoadSource.url:", url);
    }
  }
  //-----------------------------------------------------------------------
  const onLoadTarget = () => {
    isTargetLoaded.current = true;
    if (props.imageData?.id === testGuid) {
      console.log("SmoothImageLoader.onLoadTarget.url:", url);
    }
    props.onLoad(url);
  }
  //-----------------------------------------------------------------------
  //console.log("SmoothImageLoader.render:", props.imageData?.internalId);
  //-----------------------------------------------------------------------
  return (
    <img
      src={url}
      onLoad={onLoadTarget}
      style={{ display: 'none' }}
      alt=""
    />
  );
}