import { IPublicClientApplication } from "@azure/msal-browser";
import { IAppAuthContext } from "../../app-auth-context/AppAuthContext";
import { IApiResponse, apiPostPrivate, checkResponse } from "../../../utils/api";
import { apiBasePath, pathAdLanguageSetSendForApproval, pathSendAdLanguageSetSystemForApproval } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
import { AdLanguageSet, IAdLanguageSet } from "../../../components/ad-content-editor/IAdLanguageSet";
import { ClassImageEditorContext } from "../../../components/common/image-editor/image-editor-context/ImageEditorContextProvider";
import submitNewCategoryRequestsAsync from "./submitNewCategoryRequestsAsync";
import { INewBusinessTagRequest } from "../../../components/common/common-business-context/INewBusinessTagRequest";
import { IBusinessContext } from "../IBusinessContext";
import { EHttpStatusCode } from "../../../utils/HttpStatusCodes";

interface ISendAdLanguageSetForApprovalResponse {
  adLanguageSet: AdLanguageSet;
  newCategoryRequests?: INewBusinessTagRequest[];
  error?: boolean;
}

interface IProps {
  msalInstance: IPublicClientApplication;
  authContext?: IAppAuthContext;
  imageEditorContext?: ClassImageEditorContext;
  businessContext?: IBusinessContext;
  adCampaignId?: string;
  adLanguageSetId?: string;
  needToPublish?: boolean;
  adLanguageSet?: IAdLanguageSet;
  abortSignal?: AbortSignal;
}

export async function sendAdLanguageSetForApprovalAsync(props: IProps): Promise<ISendAdLanguageSetForApprovalResponse> {
  //---------------------------------------------------------------------------
  if (!props.msalInstance) throw new Error("sendAdLanguageSetForApprovalAsync: MSAL instance is not provided");
  if (!props.authContext) throw new Error("sendAdLanguageSetForApprovalAsync: auth context is not provided");
  if (!props.authContext.config) throw new Error("sendAdLanguageSetForApprovalAsync: auth config is not set");
  if (!props.imageEditorContext) throw new Error("sendAdLanguageSetForApprovalAsync: image editor context is not provided");
  if (!props.businessContext) throw new Error("sendAdLanguageSetForApprovalAsync: business context is not provided");
  if (!props.businessContext.businessState) throw new Error("sendAdLanguageSetForApprovalAsync: business state is not set");
  if (!props.adLanguageSetId) throw new Error("sendAdLanguageSetForApprovalAsync: Ad Language Set Id is not provided");
  if (!props.adCampaignId) throw new Error("sendAdLanguageSetForApprovalAsync: Ad campaign Id is not provided");
  //---------------------------------------------------------------------------
  const businessType = props.businessContext.businessState.business.businessType;
  const businessId = props.businessContext.businessState.business.id;
  //---------------------------------------------------------------------------
  // Upload images
  await props.imageEditorContext.uploadAllImagesForAsync(
    businessId,
    props.authContext,
    props.msalInstance,
    true
  );
  //---------------------------------------------------------------------------
  // Send ad language set for approval
  const isSystem = businessType !== "Business";
  let response: IApiResponse | void = undefined;
  const apiUri = isSystem ? pathSendAdLanguageSetSystemForApproval : pathAdLanguageSetSendForApproval;
  const apiQuery = `?publish=${props.needToPublish}&data=${props.adLanguageSet ? "true" : "false"}`;
  response = await apiPostPrivate(
    props.msalInstance,
    props.authContext.config,
    `${apiBasePath}${stringFormatter(apiUri + apiQuery, [businessId, props.adCampaignId, props.adLanguageSetId])}`,
    props.adLanguageSet,
    undefined,
    props.abortSignal
  );
  //console.log("sendAdLanguageSetForApprovalAsync: before checkResponse", response);
  checkResponse(response, "sendAdLanguageSetForApprovalAsync", props.abortSignal?.aborted, [EHttpStatusCode.UnprocessableContent]);
  //console.log("sendAdLanguageSetForApprovalAsync: response", response);
  //---------------------------------------------------------------------------
  // Submit new category requests if it's a user business
  // let updatedRequests: INewBusinessTagRequest[] | undefined = undefined;
  // if (!isSystem) {
  //   updatedRequests = await submitNewCategoryRequestsAsync(
  //     props.msalInstance,
  //     props.authContext.config,
  //     props.businessContext
  //   );
  // };
  //---------------------------------------------------------------------------
  // Return response
  const updatedAdLanguageSet = new AdLanguageSet(response?.content as IAdLanguageSet);
  //console.log("sendAdLanguageSetForApprovalAsync: response", updatedAdLanguageSet);
  return ({
    adLanguageSet: updatedAdLanguageSet,
    //newCategoryRequests: updatedRequests,
    error: response?.status === EHttpStatusCode.UnprocessableContent
  });
}