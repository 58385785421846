import { IUiMenuContent } from "../../../../common/options/menus/IUiMenuContent";
import { IUiEditFormLocation, tmpUiEditFormLocation } from "./business-location-editform/IUiEditFormLocation";

export interface IUiBusinessLocationEditor {
  //inputMapSearch: IUiInputText;
  locationEditForm: IUiEditFormLocation;
  menuContent: IUiMenuContent; // for locations list options: add, edit, delete
}

export const tmpUiBusinessLocationEditor: IUiBusinessLocationEditor = {
  locationEditForm: tmpUiEditFormLocation,
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: "",
    options: [{
      id: "OptionAdd",
      directoryId: "",
      elementType: "",
      caption: { en: "Add location" },
      hint: { en: "Add new business location" },
    }, {
      id: "OptionSelect",
      directoryId: "",
      elementType: "",
      caption: { en: "Select location..." },
      hint: { en: "Select business locations" },
    }, {
      id: "OptionRefresh",
      directoryId: "",
      elementType: "",
      caption: undefined,
      hint: { en: "Get location data from business" },
    }, {
      id: "OptionEdit",
      directoryId: "",
      elementType: "",
      caption: undefined,
      hint: { en: "Open in edit form" },
    }, {
      id: "OptionDelete",
      directoryId: "",
      elementType: "",
      caption: undefined,
      hint: { en: "Delete business location" },
    }, {
      id: "OptionMapBoundsSave",
      directoryId: "",
      elementType: "",
      caption: { en: "Set current map bounds as default" },
      hint: { en: "Save current map bounds to use it as default in business ads" },
    }, {
      id: "OptionMapBoundsAuto",
      directoryId: "",
      elementType: "",
      caption: { en: "Set auto bounds" },
      hint: { en: "Let bounds to be adjusted automatically based on existing locations" },
    }, {
      id: "OptionMapZoomSave",
      directoryId: "",
      elementType: "",
      caption: { en: "Remember current map zoom" },
      hint: { en: "Save current map zoom to use it as default zoom in ads" },
    }, {
      id: "OptionMapZoomAuto",
      directoryId: "",
      elementType: "",
      caption: { en: "Set auto zoom" },
      hint: { en: "Let zoom to be adjusted automatically based on existing locations" },
    }]
  }
}