import { DraftItemStateEnum } from "../../common/dictionary-draft-models/DraftItemStateEnum";
import { IDictionaryDraftItemStateGroup } from "../../administrator-console/dictionaries/common/settings/IDictionaryDraftItemStateGroup";

export const dictonaryTranslationDraftStates: IDictionaryDraftItemStateGroup[] = [{
  id: 'nothing',
  name: "No changes/errors",
  states: [{
    draftState: DraftItemStateEnum.IsNothing,
    iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasNothing,
    iconId: 'circleEmpty'
  }]
}, {
  id: 'editedt',
  name: "Edited Translation",
  color: 'mediumspringgreen',
  states: [{
    draftState: DraftItemStateEnum.IsTranslationEdited,
    iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasTranslationEdited,
    iconId: 'circleEmpty',
    fontColor: 'inherit'
  }]
}, {
  id: 'fallbackAccepted',
  name: "Fallback accepted",
  color: 'slateblue',
  states: [{
    draftState: DraftItemStateEnum.IsFallbackAccepted,
    iconId: 'circle',
    fontColor: 'undefined'
  }, {
    draftState: DraftItemStateEnum.HasFallbackAccepted,
    iconId: 'circleEmpty',
  }]
}, {
  id: 'notranslation',
  name: "No Translation",
  color: 'orange',
  states: [{
    draftState: DraftItemStateEnum.IsNoTranslation,
    iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasNoTranslation,
    iconId: 'circleEmpty',
    fontColor: 'undefined'
  }]
}]