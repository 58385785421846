import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { IDictionaryTranslationDraft } from "./DictionaryTranslatorV1";
import { apiBasePath, pathPublishDictionaryTranslationDraft } from "../../../../../utils/apiPathConstant";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { stringFormatter } from "../../../../../utils/common";

export default function publishDictionaryTranslationDraft(
  msalInstanse: IPublicClientApplication,
  dictionaryId: string,
  localeId: string,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<IDictionaryTranslationDraft> {
  return new Promise((resolve, reject) => {
    const uri = `${apiBasePath}${stringFormatter(pathPublishDictionaryTranslationDraft, [dictionaryId, localeId])}`;
    //-------------------------------------------------------------------------
    apiPostPrivate(
      msalInstanse,
      authConfig,
      uri,
      undefined,
      undefined,
      abortSignal
    ).then(response => {
      checkResponse(response, "publishDictionaryTranslationDraft");
      resolve(response?.content as IDictionaryTranslationDraft);
    }).catch(error => {
      reject(error);
    });
  });
}