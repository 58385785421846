import { IPublicClientApplication } from "@azure/msal-browser";
import { getFromLS, setToLS } from "../../utils/storage";
import { IAuthConfig } from "../app-auth-context/AppAuthContext";
import { TUser } from "../app-auth-context/TUser";
import { apiBasePath, getAdministratorUi, getApplicationLayout, getModeratorUi, getUiPublic, getUiUserConsole, getBusinessSearchUi, getUiBusinessWizard, pathGetUiBusinessConsole, pathGetUiAdminConsole, pathGetUiAdministratorConsole, pathGetUiContentManagerConsole, pathGetUiModeratorConsole, pathGetUiSystemTranslatorConsole } from "../../utils/apiPathConstant";
import { IApiResponse, apiGet, apiGetPrivate, checkResponse } from "../../utils/api";

export type TUiNames =
  "public" |
  "appLayout" |
  "adminConsole" |
  "administratorConsole_old" | "administratorConsole" |
  "moderatorConsole_old" | "moderatorConsole" |
  "contentManagerConsole" |
  "systemTranslatorConsole" |
  "userConsoleNew" |
  "businessWizard" |
  "businessConsole" |
  "searchConsole";

class UiDescriptor {
  uiName!: TUiNames;
  apiUrl!: string;
  isPrivate: boolean = true;
  get storageKey(): string { return `ui.${this.uiName}` }
  //-------------------------------------------------------------
  constructor(uiName: TUiNames) {
    this.uiName = uiName;
    switch (uiName) {
      case "public":
        this.apiUrl = getUiPublic;
        this.isPrivate = false;
        break;
      case "appLayout":
        this.apiUrl = getApplicationLayout;
        this.isPrivate = false;
        break;
      case "adminConsole":
        this.apiUrl = pathGetUiAdminConsole;
        break;
      case "administratorConsole_old":
        this.apiUrl = getAdministratorUi;
        break;
      case "administratorConsole":
        this.apiUrl = pathGetUiAdministratorConsole;
        break;
      case "moderatorConsole_old":
        this.apiUrl = getModeratorUi;
        break;
      case "moderatorConsole":
        this.apiUrl = pathGetUiModeratorConsole;
        break;
      case "contentManagerConsole":
        this.apiUrl = pathGetUiContentManagerConsole;
        break;
      case "systemTranslatorConsole":
        this.apiUrl = pathGetUiSystemTranslatorConsole;
        break;
      case "userConsoleNew":
        this.apiUrl = getUiUserConsole;
        break;
      case "businessWizard":
        this.apiUrl = getUiBusinessWizard;
        break;
      case "businessConsole":
        this.apiUrl = pathGetUiBusinessConsole;
        break;
      case "searchConsole":
        this.apiUrl = getBusinessSearchUi;
        this.isPrivate = false;
        break;
    }
  }
}
//-------------------------------------------------------------
export class AppUiCache {
  instance?: IPublicClientApplication;
  config?: IAuthConfig | null;
  user?: TUser | null;
  uiDescriptors: UiDescriptor[] = [
    new UiDescriptor("public"),
    new UiDescriptor("appLayout"), // remove, use public
    new UiDescriptor("adminConsole"),
    new UiDescriptor("administratorConsole_old"), // remove, use administratorConsole
    new UiDescriptor("administratorConsole"),
    new UiDescriptor("moderatorConsole_old"),
    new UiDescriptor("moderatorConsole"),
    new UiDescriptor("contentManagerConsole"),
    new UiDescriptor("systemTranslatorConsole"),
    new UiDescriptor("userConsoleNew"),
    new UiDescriptor("businessWizard"),
    new UiDescriptor("businessConsole"),
    new UiDescriptor("searchConsole"),
  ];
  //------------------------------------------------------------------------------
  toJSON() {
    let result = {
      ...this,
      instance: null,
      user: null,
      config: null,
    };
    delete result["instance"];
    delete result["user"];
    delete result["config"];
    return result;
  }
  //------------------------------------------------------------------------------
  checkAuthorization(uiName: TUiNames): boolean {
    //console.warn("AppUiCache.checkAuthorization", uiName);
    let descriptor = this.getUiDescriptor(uiName);
    if (!descriptor) {
      console.error(`${uiName}: UI descriptor not found`);
      return false;
    }
    if (!descriptor.isPrivate) {
      return true;
    }
    if (!this.user) {
      console.error(`${uiName}.Can't get UI: appAuthContext.user is undefined`);
      return false;
    }
    if (!this.config) {
      console.error(`${uiName}.Can't get UI: appAuthContext.config is undefined`);
      return false;
    }
    if (!this.instance) {
      console.error(`${uiName}.Can't get UI: appAuthContext.msalInstance is undefined`);
      return false;
    }
    return true;
  }
  //------------------------------------------------------------------------------
  private async getUiFromBackend(descriptor: UiDescriptor, abortSignal?: AbortSignal): Promise<any> {
    //console.log("AppUiCache try to get ui from backend:", descriptor.apiUrl);
    let response: void | IApiResponse;
    if (!this.instance) {
      throw "AppUiCache.instanse is undefined";
    };
    if (!this.config) {
      throw "AppUiCache.config is undefined";
    };
    if (descriptor.isPrivate) {
      response = await apiGetPrivate(
        this.instance,
        this.config,
        `${apiBasePath}${descriptor.apiUrl}`,
        abortSignal
      )
    } else {
      response = await apiGet(`${apiBasePath}${descriptor.apiUrl}`, abortSignal);
    };
    if (abortSignal?.aborted) {
      throw `API ${descriptor.apiUrl} was aborted `;
    }
    checkResponse(response, descriptor.apiUrl);
    if (!response?.content) {
      throw `Response content of [${descriptor.apiUrl}] API is empty`;
    };
    return response.content;
  }
  //------------------------------------------------------------------------------
  private getUiDescriptor(name: TUiNames): UiDescriptor | undefined {
    return this.uiDescriptors.find(item => item.uiName == name);
  }
  //------------------------------------------------------------------------------
  async getUiCallback<T>(uiName: TUiNames, setUi: (ui: T) => void,
    finalization?: () => void, abortSignal?: AbortSignal) {
    //console.log(`AppUiCache.getUi[${uiName}] started.`);
    try {
      let descriptor = this.getUiDescriptor(uiName);
      if (!descriptor) {
        throw `UI Descriptor for [${uiName}] is not found`;
      }
      if (descriptor.isPrivate && !this.checkAuthorization(uiName)) {
        throw "AppUiCache unauthorised";
      }

      // trying to get ui from LS
      let ui = getFromLS(descriptor.storageKey);
      if (ui !== false) {
        //console.log(`AppUiCache.getUi[${uiName}] from local storage:`, ui);
        setUi(ui as T);
        return;
      }
      //console.log(`AppUiCache.getUi[${uiName}] trying to get ui from backend...`);
      ui = await this.getUiFromBackend(descriptor, abortSignal);
      //console.log(`AppUiCache.getUi[${uiName}] from backend:`, ui);
      setToLS(descriptor.storageKey, ui);
      if (!abortSignal || !abortSignal.aborted) {
        setUi(ui as T);
      }
      return;
    }
    catch (error) {
      console.error(`AppUiCache.getUi[${uiName}] fail(try/catch):`, error);
      return undefined;
    }
    finally {
      finalization && finalization();
    }
  }
}