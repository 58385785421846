import { useContext, useEffect } from 'react';
import { EditFormSection } from '../../../common/console-layout/edit-form-sections/EditFormSection';
import GetHint from '../../../common/functions/GetHint';
import { IUiSectionLocation } from './IUiSectionLocation';
import styles from './SectionLocation.module.css';
import React from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import BusinessLocationEditor from './business-location-editor/BusinessLocationEditor';
import { BusinessContext } from '../../../../context/business-context/BusinessContextProvider';
import { ClassCustomerBusiness, ICustomerBusiness } from '../ICustomerBusiness';
import { tmpUiBusinessLocationEditor } from './business-location-editor/IUiBusinessLocationEditor';
import { ClassBusinessLocationData, IBusinessLocationData } from './business-location-editor/IBusinessLocationData';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import getUserGeopositionAsync from '../../../../context/app-auth-context/getUserGeoPositionAsync';
import { useAbortController } from '../../../../hooks/useAbortController';

interface IProps {
  id: string;
  ui: IUiSectionLocation;
}

export default function SectionLocation(props: IProps) {
  const { appAuthContextDispatch } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const business = businessContext.businessState?.business as ClassCustomerBusiness;
  const abortController = useAbortController("SectionLocation");
  //---------------------------------------------------------------------------
  useEffect(() => {
    let controller = abortController.newController("getCurrentGeoLocation");
    getUserGeopositionAsync({abortController: controller})
    .then(position => {
      appAuthContextDispatch({ type: "SetUserGeoposition", position: position });
    })
    .catch(error => {
      console.error("SectionLocation.getCurrentGeoLocation.catch:", error);
    });
    //-----------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
    });

    // getUserGeopositionAsync().then(position => 
    //   appAuthContextDispatch({ type: "SetUserGeoposition", position: position }));

    //appAuthContextDispatch({ type: "RefreshUserGeoposition" });
  }, []);
  //---------------------------------------------------------------------------
  const onLocationDataUpdate = (updatedBusinessLocation?: IBusinessLocationData<string>) => {
    if (!business) return;
    const updatedBusiness = new ClassCustomerBusiness({
      ...business as ICustomerBusiness,
      locationData: updatedBusinessLocation
    });
    businessContextDispatch({ type: "UpdateBusiness", business: updatedBusiness });
  };
  //---------------------------------------------------------------------------
  return (
    <EditFormSection
      id={props.id}
      caption={GetHint(appUiContext, props.ui.id, props.ui.hint)}
    >
      <div className={styles.container}>

        {business &&
          <BusinessLocationEditor
            ui={tmpUiBusinessLocationEditor}
            businessId={business.id}
            data={new ClassBusinessLocationData(business.locationData)}
            onUpdate={onLocationDataUpdate}
          />}

      </div>
    </EditFormSection>
  );
}