import { DictionaryDraftItem, IDictionaryDraftItem } from "../../common/dictionary-draft-models/DictionaryDraftItem";
import { DraftItemStateEnum } from "../../common/dictionary-draft-models/DraftItemStateEnum";
import { IDictionaryDraftFilter } from "../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter";

export interface ITranslation {
  value?: string;
  // Should be set to true if name/description is not provided, otherwise node is considered invalid
  fallbackAccepted: boolean;
}

export class Translation {
  public static TranslationsAreEqual(t1?: ITranslation, t2?: ITranslation): boolean {
    if (t1 == undefined && t2 == undefined || t1 == null && t2 == null)
      return true;
    else if (t1 == undefined || t1 == null || t2 == undefined || t2 == null)
      return false;
    return (t1.value === t2.value) && (t1.fallbackAccepted === t2.fallbackAccepted);
  }
  public static NoTranslation(t: ITranslation): boolean {
    if ((!t.value || t.value == "") && !t.fallbackAccepted)
      return true;
    else return false;
  }
}

export interface IFilterStrings {
  ids: string[];
  names: string[];
  descriptions: string[];
}

export interface IDictionaryTranslationDraftItem extends IDictionaryDraftItem {
  name: ITranslation;
  description: ITranslation;
}

export interface IDictionaryTranslationDraftItemSpread {
  name?: ITranslation;
  description?: ITranslation;
  items?: IDictionaryTranslationDraftItem[];
}

export class DictionaryTranslationDraftItem extends DictionaryDraftItem implements IDictionaryTranslationDraftItem {
  name!: ITranslation;
  description!: ITranslation;
  items?: DictionaryTranslationDraftItem[]; // For Business Tags
  filteredItems?: DictionaryTranslationDraftItem[]; // To display filtered sub-items
  //--------------------------------------------------------------------------- Constructor
  constructor(source?: IDictionaryTranslationDraftItem, spread?: any) {
    super(source);
    Object.assign(this, source, spread);
    this.createItems();
    this.filteredItems = this.items;
  };
  //---------------------------------------------------------------------------
  private createItems() {
    if (this.items && this.items.length > 0) {
      const updatedSubItems: DictionaryTranslationDraftItem[] = [];
      this.items.forEach(subItem => {
        const updatedSubItem = new DictionaryTranslationDraftItem(subItem);
        updatedSubItem.publishedVersion = new DictionaryTranslationDraftItem(subItem.publishedVersion);
        updatedSubItems.push(updatedSubItem);
      });
      this.items = updatedSubItems;
    };
  };
  //---------------------------------------------------------------------------
  isValid(): boolean {
    let result = true;
    if (this.isNoTranslation || this.hasNoTranslation) {
      result = false;
    };
    return result;
  };
  //---------------------------------------------------------------------------
  checkFilter(filter?: IDictionaryDraftFilter): boolean {
    if (!filter)
      return true;
    let resultText = true;
    let resultState = true;
    //-----------------------------------------------------------------------
    // Filter by text
    if (filter.text) {
      const searchString = filter.text.toLocaleLowerCase();
      const name = this.name.value ? this.name.value.toLocaleLowerCase() : "";
      const description = this.description.value ? this.description.value.toLocaleLowerCase() : "";
      resultText = this.id.toLocaleLowerCase().includes(searchString) || (name.includes(searchString)) || (description.includes(searchString));
    };
    //-----------------------------------------------------------------------
    // Filter by state
    if (filter.draftState !== DraftItemStateEnum.All) {
      resultState = (this.draftState & filter.draftState) != DraftItemStateEnum.None;
    };
    //-----------------------------------------------------------------------
    return (resultText && resultState);
  };
  //---------------------------------------------------------------------------
  filter(filter?: IDictionaryDraftFilter): boolean {
    const selfResult = this.checkFilter(filter);
    let resultChildren = false;
    const filteredItems: DictionaryTranslationDraftItem[] = [];
    this.items?.forEach(subItem => {
      if (subItem.filter(filter)) {
        filteredItems.push(subItem);
        resultChildren = true;
      };
    });
    //this.items = filteredItems;
    this.filteredItems = filteredItems;
    return selfResult || resultChildren;
  };
  //--------------------------------------------------------------------------- Properties based on draftStatus
  // Nothing
  // No translation
  // Translation edited
  // Fallback accepted
  //--------------------------------------------------------------------------- NoTranslation
  get isNoTranslation(): boolean {
    if (this.draftState & DraftItemStateEnum.IsNoTranslation)
      return true;
    else
      return false;
  }
  set isNoTranslation(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.IsNoTranslation;
    else
      this.draftState &= ~DraftItemStateEnum.IsNoTranslation;
  }
  //---------------------------------------------------------------------------
  get hasNoTranslation(): boolean {
    if (this.draftState & DraftItemStateEnum.HasNoTranslation)
      return true;
    else
      return false;
  }
  set hasNoTranslation(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasNoTranslation;
    else
      this.draftState &= ~DraftItemStateEnum.HasNoTranslation;
  }
  //--------------------------------------------------------------------------- TranslationEdited
  get isTranslationEdited(): boolean {
    if (this.draftState & DraftItemStateEnum.IsTranslationEdited)
      return true;
    else
      return false;
  }
  set isTranslationEdited(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.IsTranslationEdited;
    else
      this.draftState &= ~DraftItemStateEnum.IsTranslationEdited;
  }
  //---------------------------------------------------------------------------
  get hasTranslationEdited(): boolean {
    if (this.draftState & DraftItemStateEnum.HasTranslationEdited)
      return true;
    else
      return false;
  }
  set hasTranslationEdited(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasTranslationEdited;
    else
      this.draftState &= ~DraftItemStateEnum.HasTranslationEdited;
  }
  //--------------------------------------------------------------------------- FallbackAccepted
  get isFallbackAccepted(): boolean {
    if (this.draftState & DraftItemStateEnum.IsFallbackAccepted)
      return true;
    else
      return false;
  }
  set isFallbackAccepted(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.IsFallbackAccepted;
    else
      this.draftState &= ~DraftItemStateEnum.IsFallbackAccepted;
  }
  //---------------------------------------------------------------------------
  get hasFallbackAccepted(): boolean {
    if (this.draftState & DraftItemStateEnum.HasFallbackAccepted)
      return true;
    else
      return false;
  }
  set hasFallbackAccepted(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasFallbackAccepted;
    else
      this.draftState &= ~DraftItemStateEnum.HasFallbackAccepted;
  }
  //--------------------------------------------------------------------------- IsNothing
  get isNothing(): boolean {
    if (this.draftState & DraftItemStateEnum.IsNothing)
      return true;
    else
      return false;
  }
  set isNothing(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.IsNothing;
    else
      this.draftState &= ~DraftItemStateEnum.IsNothing;
  }
  //---------------------------------------------------------------------------
  get hasNothing(): boolean {
    if (this.draftState & DraftItemStateEnum.HasNothing)
      return true;
    else
      return false;
  }
  set hasNothing(value: boolean) {
    if (value)
      this.draftState |= DraftItemStateEnum.HasNothing;
    else
      this.draftState &= ~DraftItemStateEnum.HasNothing;
  }
}