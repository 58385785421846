import { IUiMenuContent } from "../../options/menus/IUiMenuContent";
import { GetTUiMenuOption, TUiMenuOption } from "./menu-option/TUiMenuOption"

export type TUiMenuContent = {
  id: string;
  directoryId: string;
  elementType: string;
  visible: boolean;
  disabled: boolean;
  // Options could be just a list of options or it could be a list of submenus
  // in this case they are displayed as option groups
  options: TUiMenuOption[];
  optionGroups: TUiMenuContent[];
  hint?: any;
}

export function getTUiMenuContent(mc: IUiMenuContent): TUiMenuContent {
  return {
    id: mc.id,
    directoryId: mc.directoryId,
    elementType: mc.elementType,
    hint: mc.hint,
    options: mc.options.map(o => GetTUiMenuOption(o)),
    optionGroups: [],
    visible: true,
    disabled: false
  };
}

export const defaultUiMenuContent: TUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  visible: false,
  disabled: false,
  // Options could be just a list of options or it could be a list of submenus
  // in this case they are displayed as option groups
  options: [],
  optionGroups: []
}