import { useContext } from "react";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import useNavigateWithContext from "../../../../hooks/useNavigateWithContext";
import CombineStyles from "../../../../utils/combineStyles";
import GetCaption from "../../functions/GetCaption";
import Icon from "../../icon/Icon";
import styles from "./Breadcrumb.module.css";
import { TUiBreadcrumb } from "./TUiBreadcrumb";

interface IProps {
  item: TUiBreadcrumb;
  isLast: boolean;
}

export default function Breadcrumb(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const navigateWithContext = useNavigateWithContext();
  //---------------------------------------------------------------------------
  const onClick = () => {
    if (props.item.path && !props.isLast)
      navigateWithContext(props.item.path);
  };
  //---------------------------------------------------------------------------
  const breadcrumbStyles = CombineStyles([
    styles.breadcrumb,
    !props.item.path ? styles.disabled : "",
    props.isLast ? styles.last : ""
  ])
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={breadcrumbStyles} onClick={onClick}>
        {GetCaption(appUiContext, props.item.id, props.item.caption)}
      </div>
      {!props.isLast &&
        <div className={styles.divider}>
          <Icon imageName="rightArrow" />
        </div>
      }
    </div>
  );
}