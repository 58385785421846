import { useEffect } from "react";

export default function useBeforeUnload(
  unsavedChanges?: boolean
) {
  // Handle user leaving page: check if there are unsaved changes
  // This will display standard browswer dialog to prompt user
  // and this dialog can't be customized in any way
  // Moreover - user can block this sort of messages
  useEffect(() => {
    if (unsavedChanges) {
      const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
        // to show the confirmation dialog
        e.preventDefault();
        // older browsers may not support this method
        // and a legacy method is used in which the event handler must return a string.
        // This returned string can be empty.
        e.returnValue = "";
      };
      //-------------------------------------------------------------------------
      // Listen the event to prevent window / tab from closing if there are unsaved changes
      window.addEventListener("beforeunload", beforeUnloadHandler);
      //-------------------------------------------------------------------------
      // Unsubsribe from event on unmount
      return () => window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [unsavedChanges]);
}