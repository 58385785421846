import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../../context/app-auth-context/AppAuthContext";
import { apiPostPrivate, checkResponse } from "../../../../../../utils/api";
import { apiBasePath, pathArchiveDictionaryDraft } from "../../../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../../../utils/common";
import { EDictionaryType } from "../../../../common/dictionary-draft-models/EDictionaryType";

export default function deleteDictionaryDraft(
  msalInstance: IPublicClientApplication,
  dictionaryId: EDictionaryType,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<any> {
  return new Promise((resolve, reject) => {
    //-------------------------------------------------------------------------
    apiPostPrivate(
      msalInstance,
      authConfig,
      `${apiBasePath}${stringFormatter(pathArchiveDictionaryDraft, [dictionaryId])}`,
      abortSignal
    ).then(response => {
      checkResponse(response, "deleteDictionaryDraft", abortSignal?.aborted);
      resolve(response?.content);
    }).catch(error =>
      reject(error)
    );
  });
}