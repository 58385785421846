import React, { useContext } from 'react';
import styles from './RootTagHeader.module.css';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { IUiListItem } from '../../../../common/input/input-dropdown/InputDropdown';
import Checkbox from '../../../../common/checkbox/Checkbox';
import GetText from '../../../../common/functions/GetText';
import CombineStyles from '../../../../../utils/combineStyles';

interface IProps {
  ui: IUiListItem;
  isSelected: boolean;
  onSelect: () => void;
}

export default function RootTagHeader(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>

      <div
        className={CombineStyles([
          styles.header,
          props.isSelected ? styles.selected : ''
        ])}
        onClick={props.onSelect}
      >
        <div className={styles.checkbox}>
          <Checkbox checked={props.isSelected} />
        </div>
        <div className={CombineStyles([
          styles.caption,
          props.isSelected ? styles.selected : ''
        ])}>
          {GetText({
            appUiContext: appUiContext,
            elementId: props.ui.id,
            elementPart: "Name",
            text: props.ui.name
          })}
        </div>
      </div>

      <div className={styles.description}>
        {GetText({
          appUiContext: appUiContext,
          elementId: props.ui.id,
          elementPart: "Description",
          text: props.ui.description
        })}
      </div>

    </React.Fragment>
  );
}