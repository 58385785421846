import { IUiSectionDetails } from "../../business-space/adpage-editor/section-details/IUiSectionDetails";
import { InputValidator } from "../../common/input-v2/input-validation/IInputValidator";
import { IValidationState } from "../../common/input-v2/input-validation/IUiValidation";
import { IAdLanguageSet } from "../IAdLanguageSet";
import { IAdDetails } from "./AdDetailsEditor";
import { IUiAdDetails } from "./IUiAdDetails";

export interface IAdDetailsValidationState {
  title: IValidationState;
  description: IValidationState;
  isValid: boolean;
}

export class AdDetailsValidator {
  title!: InputValidator;
  description!: InputValidator;
  //---------------------------------------------------------------------------
  constructor(ui: IUiAdDetails) {
    this.title = new InputValidator({
      elementId: ui.inputTitle.id,
      inputType: "InputText",
      validation: ui.inputTitle.validation
    });
    this.description = new InputValidator({
      elementId: ui.inputDescription.id,
      inputType: "InputText",
      validation: ui.inputDescription.validation
    });
  };
  //---------------------------------------------------------------------------
  initialize(data?: IAdDetails): IAdDetailsValidationState {
    const validationStateName: IValidationState = this.title.validate(data?.title, true);
    const validationStateDescription: IValidationState = this.description.validate(data?.description, true);
    //-------------------------------------------------------------------------
    return this.getValidationState(validationStateName, validationStateDescription);
  };
  //---------------------------------------------------------------------------
  getValidationState(name: IValidationState, description: IValidationState): IAdDetailsValidationState {
    return {
      title: name,
      description: description,
      isValid: name.isValid && description.isValid
    };
  };
  //---------------------------------------------------------------------------
  validateName(value: string, validationState: IAdDetailsValidationState): IAdDetailsValidationState {
    const validationStateName: IValidationState = this.title.validate(value, true);
    return this.getValidationState(validationStateName, validationState.description);
  };
  //---------------------------------------------------------------------------
  validateDescription(value: string, validationState: IAdDetailsValidationState): IAdDetailsValidationState {
    const validationStateDescription: IValidationState = this.description.validate(value, true);
    return this.getValidationState(validationState.title, validationStateDescription);
  };
  //---------------------------------------------------------------------------
  getInitialValidationState(): IAdDetailsValidationState {
    return {
      title: {
        isValid: true,
        validationMessages: []
      },
      description: {
        isValid: true,
        validationMessages: []
      },
      isValid: true
    };
  };
}