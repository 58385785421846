import { useState } from "react";
import { CosmosDatabaseHealthStatus } from "../CosmosDBHealthStatus";
import { HealthGauge } from "../HealthGauge";
import { StatusToggler } from "../StatusToggler";
import styles from "./DatabaseStatus.module.css";

interface IProps {
  status?: CosmosDatabaseHealthStatus
  spinner: boolean;
}

export function DatabaseStatus(props: IProps) {
  const [collapsed, setCollapsed] = useState(true);
  //---------------------------------------------------------------------
  const onExpand = () => {
    setCollapsed(false);
  }
  //---------------------------------------------------------------------
  const onCollapse = () => {
    setCollapsed(true);
  }
  //---------------------------------------------------------------------------
  let regions: JSX.Element[] | undefined = undefined;
  let containers: JSX.Element[] | undefined = undefined;
  if (props.status) {
    regions = [];
    containers = [];
    props.status.contactedRegions.forEach((region, i) => {
      regions?.push(
        <div key={`region_${i}`} className={styles.region}>
          {/*{!props.spinner ? `Region: ${region.name}` : `Region: `}*/}
          {!props.spinner ? `Region: ${region}` : `Region: `}
        </div>
      );
    });
    props.status.containers.forEach((container, i) => {
      containers?.push(
        <div key={`${container.name}_${i}`}>
          {!props.spinner ? `${container.name} (documents: ${container.documentCount})` : ``}
        </div>
      );
    });
  }
  //---------------------------------------------------------------------------
  let key = props.status?.serviceName;
  //---------------------------------------------------------------------------
  return (
    <div key={key} className={styles.container}>
      <div className={styles.header}>
        {props.status ? props.status.serviceName : "Unknown Service"}
      </div>
      {!props.status &&
        <div className={styles.statusContainer}>
          Unknown Service status is undefined
        </div>
      }
      {props.status &&
        <div className={styles.statusContainer}>
          <HealthGauge status={props.status} spinner={props.spinner} />
          {!props.spinner && regions && regions}
          <StatusToggler
            caption="Containers"
            count={props.status.containers.length}
            collapsed={collapsed}
            //children={containers}
            onCollapse={onCollapse}
            onExpand={onExpand}
          />
          {!collapsed && !props.spinner &&
            <div style={{ marginLeft: "1.2em" }}>
              {containers && containers}
            </div>
          }
        </div>
      }
    </div>
  );
}