import styles from './SectionDetails.module.css';
import { EditFormSection } from "../../../../common/console-layout/edit-form-sections/EditFormSection";
import GetHint from "../../../../common/functions/GetHint";
import { useContext } from 'react';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { IUiRegionalHomepageDetails } from './IUiRegionalHomepageDetails';
import InputsGroupWrapper from '../../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import InputText from '../../../../common/input-v2/input-text/InputText';
import InputDropdown from '../../../../common/input-v2/input-dropdown/InputDropdown';
import InputListBusinessPath from '../../../../business-space/business-editor/section-branding/business-path/InputListBusinessPath';
import DictionaryItemStatus from '../../../administrator-console/dictionaries/common/dictionary-item-status/DictionaryItemStatus';
import { BusinessContext } from '../../../../../context/business-context/BusinessContextProvider';
import { ClassSystemBusinessRegional, ISystemBusinessRegional } from '../ISystemBusinessRegional';
import { IBusinessPaths } from '../../../../../context/business-context/IBusiness';

interface IProps {
  id: string;
  ui: IUiRegionalHomepageDetails;
  isReadonly: boolean;
}

export default function SectionDetails(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const {businessContext, businessContextDispatch} = useContext(BusinessContext);
  let systemBusiness = businessContext.businessState?.business as ClassSystemBusinessRegional;
  //---------------------------------------------------------------------------
  const onPathListUpdate = (updatedPaths: IBusinessPaths) => {
    if (systemBusiness) {
      const updatedBusiness = new ClassSystemBusinessRegional({
        ...systemBusiness as ISystemBusinessRegional,
        paths: updatedPaths
      });
      // We set business as saved object, because business is already updated
      businessContextDispatch({ type: "SetBusiness", business: updatedBusiness});
      systemBusiness = updatedBusiness;
    };
  };
  //---------------------------------------------------------------------------
  if (!systemBusiness) return null;
  return (
    <EditFormSection
      id={props.id}
      caption={GetHint(appUiContext, props.ui.id, props.ui.hint)}
    >
      <div className={styles.container}>
        <InputsGroupWrapper>

          <InputText
            ui={{ ...props.ui.inputRegionalHomepageId, disabled: true }}
            data={systemBusiness.id}
          />

          <InputDropdown
            ui={{ ...props.ui.inputRegion, disabled: true }}
            data={systemBusiness.regionId}
            options={appUiContext.dictionaries?.supportedRegions}
            onUpdate={() => { }}
          />

          {props.ui.inputListBusinessPath &&
            <InputListBusinessPath
              ui={props.ui.inputListBusinessPath}
              business={systemBusiness}
              isReadonly={props.isReadonly}
              onUpdate={onPathListUpdate}
            />}

            <DictionaryItemStatus
              data={systemBusiness.status}
              isReadOnly={true}
            />

        </InputsGroupWrapper>

      </div>
    </EditFormSection>
  );
}