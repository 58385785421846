import CombineStyles from '../../../../utils/combineStyles';
import Icon from '../../../common/icon/Icon';
import styles from './DirectoryNodeToggler.module.css';

interface IProps {
  isExpanded?: boolean;
  onToggle?: () => void;
}

export default function DirectoryNodeToggler(props: IProps) {
  const isExpanded = props.isExpanded ?? false;
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        props.onToggle ? '' : styles.disabled
      ])}
      onClick={props.onToggle}
    >
      <Icon imageName="rightArrow" visible={isExpanded} />
      <Icon imageName="downArrow" visible={!isExpanded} />
    </div>
  );
}