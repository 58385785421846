import React from 'react';
import { TGeoPoint } from '../../map/geo-json/GeoJson';
import BusinessMarkerAdvanced from './business-marker-advanced/BusinessMarkerAdvanced';

export interface IBusinessMarker {
  businessId: string;
  locationId: string;
  name: string;
  geolocation?: TGeoPoint;
  isEditable?: boolean;
  isHighlighted?: boolean;
}

export interface IBusinessLocationUpdate {
  businessId: string;
  locationId: string;
  location: TGeoPoint;
}

interface IProps {
  map: google.maps.Map;
  businessMarkers: IBusinessMarker[];
  showIndex?: boolean;
  onLocationUpdate?: (businessLocationUpdate: IBusinessLocationUpdate) => void;
  onClick?: (businessId: string, locationId: string) => void;
}

export default function BusinessMarkers(props: IProps) {
  const showIndex = (props.showIndex == undefined) || (props.businessMarkers.length < 2) ? false : props.showIndex;
  //---------------------------------------------------------------------------
  const businessMarkers = props.businessMarkers.map((businessMarker, index) => {
    if (businessMarker.geolocation)
      return (
        <BusinessMarkerAdvanced
          key={index}
          map={props.map}
          businessMarker={businessMarker}
          index={showIndex ? (index + 1) : undefined}
          isEditable={businessMarker.isEditable}
          isHighlighted={businessMarker.isHighlighted}
          onLocationUpdate={props.onLocationUpdate}
          onClick={props.onClick}
        />
      );
  });
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      {businessMarkers}
    </React.Fragment>
  );
}