import React, { useEffect } from "react";
import { ScreenType, useAppScreenContext } from "../../context/app-screen-context/AppScreenProvider";
import styles from "./BusinessPage.module.css";
import { ClassAdLanguageSetPublished } from "../homepage/ClassAdLanguageSetPublished";
import AdModule from "../common/ad-modules/AdModule";
import { useTracker } from "../../utils/tracker";
//----------------------------------------------------------------------------
interface IProps {
  data: ClassAdLanguageSetPublished;
}
//----------------------------------------------------------------------------
export default function BusinessPage(props: IProps) {
  const screenType = useAppScreenContext();
  const tracker = useTracker();
  //---------------------------------------------------------------------------
  useEffect(() => {
    tracker?.track("BusinessPage", props.data);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const content = props.data.adModules?.map(adModule => (
    <AdModule
      key={adModule.id}
      adModule={adModule}
      isReadonly={true}
      isClickable={false}
      useExternalLayout={false}
      minHeightEm={20}
    />
  ));
  //---------------------------------------------------------------------------
  //console.log("BusinessPage.render:", props.data);
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <div className={styles.content}>
        {content}
      </div>

      {screenType == ScreenType.Desktop &&
        <div className={styles.sidebarright}>
          <div className={styles.bannersContainer}>

          </div>
        </div>}
    </div>
  );
}