import { IUiInput } from "../IUiInput";
import styles from '../Input.module.css';
import InputWrapper from "../input-wrapper/InputWrapper";
import GetCaption from "../../functions/GetCaption";
import GetHint from "../../functions/GetHint";
import { IUiInputText } from "../input-text/InputText";
import { IUiDictionaryItem } from "../../dictionaries/IUiDictionaryItem";
import CombineStyles from "../../../../utils/combineStyles";
import Icon from "../../icon/Icon";
import GetName from "../../functions/GetName";
import GetPlaceholder from "../../functions/GetPlaceholder";
import { useContext, useEffect, useState } from "react";
import InputDropdownPopup from "./input-dropdown-popup/InputDropdownPopup";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import { IValidationState } from "../input-validation/IUiValidation";
import { ValidateDropdownInput } from "../input-validation/ValidateInput";

export interface IUiInputDropdown extends IUiInput {
  caption: any;
  hint: any;
  dictionaryId: string;
  options?: IUiDictionaryItem[];
  defaultValue?: string; // IUiListItem id
  placeholder: any;
  inputSearch?: IUiInputText; // input to enter search value - is used only if input has autoComplete = true
}

interface IProps {
  ui: IUiInputDropdown;
  data?: string; // Dictionary Item id
  options?: IUiDictionaryItem[];
  hideOptionDescription?: boolean;
  isAutoComplete?: boolean;
  sortOptions?: boolean;
  onUpdate: (selectedOptionId?: string) => void;
}

export default function InputDropdown(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [popupOpen, setPopupOpen] = useState(false);
  const [validationState, setValidationState] = useState<IValidationState>({ isValid: true, validationMessages: [] });
  const selectedOption = props.options?.find(o => o.id == props.data);
  const hideOptionDescription = props.hideOptionDescription == undefined ? false : props.hideOptionDescription;
  const sortOptions = props.sortOptions == undefined ? false : props.sortOptions;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (props.ui.validation) {
      const validationState = ValidateDropdownInput({
        elementId: props.ui.id,
        validation: props.ui.validation,
        data: props.data
      });
      setValidationState(validationState);
    };
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onShowOptions = () => {
    setPopupOpen(true);
  };
  //---------------------------------------------------------------------------
  const onHideOptions = () => {
    setPopupOpen(false);
  };
  //---------------------------------------------------------------------------
  const onSelect = (selectedOption?: IUiDictionaryItem) => {
    setPopupOpen(false);
    let validationState: IValidationState = { isValid: true, validationMessages: [] };
    if (props.ui.validation) {
      validationState = ValidateDropdownInput({
        elementId: props.ui.id,
        validation: props.ui.validation,
        data: selectedOption
      });
    };
    setValidationState(validationState);
    props.onUpdate(selectedOption?.id);
  };
  //---------------------------------------------------------------------------
  let checkOptionStatus: boolean = false;
  switch (props.ui.dictionaryId) {
    case "SupportedRegions":
    case "RequestRejectionReasons":
      checkOptionStatus = true;
  }
  //---------------------------------------------------------------------------
  const filteredOptions = props.options?.filter(option => {
    // Filter out options unavailable for end users:
    // users can see them if they are already appliced, but can't select them
    if (checkOptionStatus && option.status) {
      return option.status.isAvailableToEndUsers;
    } else {
      return true;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <InputWrapper
      caption={!props.ui.hideCaption ? GetCaption(appUiContext, props.ui.id, props.ui.caption) : undefined}
      hint={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      hideCaption={props.ui.hideCaption}
      hideHint={props.ui.hideHint}
      validation={validationState}
    >
      <div
        id={props.ui.id}
        className={CombineStyles([
          styles.input,
          styles.dropdown,
          props.ui.disabled ? styles.disabled : ""
        ])}
        onClick={onShowOptions}
      >
        {selectedOption ?
          GetName(appUiContext, selectedOption.id, selectedOption.name)
          :
          props.data ?
            props.data
            :
            <span className={styles.placeholder}>
              {GetPlaceholder(appUiContext, props.ui.id, props.ui.placeholder)}
            </span>}

        {!props.ui.disabled &&
          <div className={styles.dropdownButton}>
            <Icon imageName="downArrowSolid" />
          </div>}
      </div>

      {!props.ui.disabled && popupOpen && props.options &&
        <InputDropdownPopup
          ui={props.ui}
          options={filteredOptions ? filteredOptions : []}
          sortOptions={sortOptions}
          hideDescription={hideOptionDescription}
          isAutoComplete={props.isAutoComplete}
          onClose={onHideOptions}
          onSelect={onSelect}
        />}

    </InputWrapper>
  );
}