import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAbortController } from '../../../../../hooks/useAbortController';
import { TUiBreadcrumb } from '../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { IUiOption } from '../../../../common/options/IUiOption';
import { pathAdminConsole, pathModeratorConsole } from '../../../AdminConsole';
import { optionIdRegionalHomepageRequests, pathRegionalHomepageRequests } from '../../ModeratorConsole';
import WikiSpinner from '../../../../app-layout/spinner/wikiSpinner';
import { IUiRegionalHomepageRequestsList } from './IUiRegionalHomepageRequestsList';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import { IRegionalHomepageRequestsListItem } from './requests-list-item/IRegionalHomepageRequestsListItem';
import RegionalHomepageRequestsListItem from './requests-list-item/RegionalHomepageRequestsListItem';
import useNavigateWithContext from '../../../../../hooks/useNavigateWithContext';
import { generatePath } from 'react-router';
import GetHint from '../../../../common/functions/GetHint';
import ConsoleContent from '../../../../common/console-layout/console-content/ConsoleContent';
import styles from './RegionalHomepageRequestsList.module.css';
import { approvalRequestsListColumns } from './listSettings';
import ListColumnsManager from '../../../../common/lists/list-columns-manager/ListColumnsManager';
import { ListManagerContext } from '../../../../../context/list-manager-context/ListManagerContextProvider';
import GetName from '../../../../common/functions/GetName';
import { EManagerMode, IFilterDynamicOptions } from '../../../../../context/list-manager-context/IListManagerContextData';
import { TRegionalHomepageRequestKey, compareRegionalHomepageRequests } from '../../functions/compareRequests';
import useFaviconAlert from '../../../../../hooks/useFaviconAlert';
import { getListRegionalHomepageRequest } from '../functions/getListRegionalHomepageRequest';

interface IProps {
  ui: IUiRegionalHomepageRequestsList;
  optionsForBreadcrumb?: IUiOption[];
}

export default function RegionalHomepageRequestsList(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const { listManagerContext, listManagerContextDispatch } = useContext(ListManagerContext);
  const navigate = useNavigateWithContext();
  const abortController = useAbortController("RegionalHomepagesList");
  const [isLoading, setIsLoading] = useState(false);
  const [listItems, setListItems] = useState<IRegionalHomepageRequestsListItem[]>();
  const totalCount = useRef<number>(0);
  const setAlert = useFaviconAlert();
  //--------------------------------------------------------------------------- Data, ListManagerContext, AbortController
  useEffect(() => {
    if (listItems) return;
    setIsLoading(true);
    //-------------------------------------------------------------------------
    // Initialize list manager context
    listManagerContextDispatch({
      type: "Initialize",
      configuration: {
        listId: "RegionalHomepageRequests",
        managerMode: EManagerMode.Advanced,
        listColumns: approvalRequestsListColumns
      }
    });
    //-------------------------------------------------------------------------
    // Get requests list data
    let controller = abortController.newController("getListRegionalHomepageRequest");
    getListRegionalHomepageRequest(
      instance,
      appAuthContext.config,
      controller.signal
    ).then(items => {
      setListItems(items);
      totalCount.current = items.length;
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    //-------------------------------------------------------------------------
    // Clean up
    return () => {
      abortController.abortOnUnmount();
    };
  }, []);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdRegionalHomepageRequests);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        caption: option.caption,
        path: `/${pathAdminConsole}/${pathModeratorConsole}/${pathRegionalHomepageRequests}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
  }, [props.optionsForBreadcrumb]);
  //--------------------------------------------------------------------------- Filter dynamic options, alert
  useEffect(() => {
    if (!listItems || listItems.length == 0) return;
    //-------------------------------------------------------------------------
    // Set alert if there are uncompleted requests
    const uncompletedRequests = listItems.filter(r => !r.dateTimeResolved);
    setAlert(uncompletedRequests.length > 0);
    //-------------------------------------------------------------------------
    const moderators: string[] = [];
    const locales: string[] = [];
    const regions: string[] = [];
    listItems.forEach(request => {
      // Update moderators list
      const username = request.actualLogRecord.assignedTo?.displayName;
      if (username && !moderators.includes(username)) {
        moderators.push(username);
      };
      // Update locales list
      if (!locales.includes(request.localeId)) {
        locales.push(request.localeId);
      };
      // Update regions list
      if (!regions.includes(request.regionId)) {
        regions.push(request.regionId);
      };
    });
    //-------------------------------------------------------------------------
    const dynamicOptions: IFilterDynamicOptions[] = [{
      columnId: "moderator",
      options: moderators.map(m => ({ id: m, isDynamic: true }))
    }, {
      columnId: "locale",
      options: locales.map(localeId => {
        const dictionaryItem = appUiContext.dictionaries?.supportedLocales?.find(sl => sl.id == localeId);
        return ({
          id: localeId,
          name: GetName(appUiContext, localeId, dictionaryItem?.name),
          isDynamic: true
        });
      })
    }, {
      columnId: "regionId",
      options: regions.map(regionId => {
        const dictionaryItem = appUiContext.dictionaries?.supportedRegions?.find(r => r.id == regionId);
        return ({
          id: regionId,
          name: GetName(appUiContext, regionId, dictionaryItem?.name),
          isDynamic: true
        });
      })
    }];
    listManagerContextDispatch({ type: "AddDynamicFilterOptions", dynamicOptions: dynamicOptions });
  }, [listItems]);
  //---------------------------------------------------------------------------
  const onSelect = (selectedRequestId: string, locale: string) => {
    const pagePath = generatePath(
      `/${pathAdminConsole}/${pathModeratorConsole}/${pathRegionalHomepageRequests}/:requestId/:localeId`,
      { requestId: selectedRequestId, localeId: locale });
    navigate(pagePath);
  };
  //--------------------------------------------------------------------------- Filter
  let filteredListItems = listItems ? listItems : [];
  listManagerContext.filterableColumns.forEach(listColumn => {
    const selectedOptions = listColumn.filter?.options?.filter(fi => fi.isSelected);
    if (selectedOptions) {
      switch (listColumn.id) {
        //---------------------------------------------------------------------
        case "moderator":
          // If there are selected options in the group, run through them
          const dynamicOptions: string[] = [];
          selectedOptions.forEach(option => {
            switch (option.id) {
              case "Assigned":
                filteredListItems = filteredListItems.filter(r => r.actualLogRecord.assignedTo != undefined && !r.dateTimeResolved);
                break;
              case "Unassigned":
                filteredListItems = filteredListItems.filter(r => r.actualLogRecord.assignedTo == undefined);
                break;
              default:
                dynamicOptions.push(option.id);
                break;
            };
          });
          //-------------------------------------------------------------------
          // Apply dynamic filters
          if (dynamicOptions.length > 0) {
            filteredListItems = filteredListItems.filter(r => dynamicOptions.includes(r.actualLogRecord.assignedTo?.displayName));
          };
          break;
        //---------------------------------------------------------------------
        case "status":
          selectedOptions.forEach(option => {
            switch (option.id) {
              case "New":
                filteredListItems = filteredListItems.filter(r => r.status == "New");
                break;
              case "Uncompleted":
                filteredListItems = filteredListItems.filter(r => r.status !== "Approved" && r.status !== "Rejected");
                //filteredListItems = filteredListItems.filter(r => r.dateTimeResolved === undefined || r.dateTimeResolved == "0001-01-01T00:00:00");
                break;
              case "Completed":
                filteredListItems = filteredListItems.filter(r => r.status == "Approved" || r.status == "Rejected");
                //filteredListItems = filteredListItems.filter(r => r.dateTimeResolved !== undefined && r.dateTimeResolved !== "0001-01-01T00:00:00");
                break;
              case "Approved":
                filteredListItems = filteredListItems.filter(r => r.status == "Approved");
                break;
              case "Rejected":
                filteredListItems = filteredListItems.filter(r => r.status == "Rejected");
                break;
            };
          });
          break;
        //---------------------------------------------------------------------
        case "regionId":
          const regions = selectedOptions.map(o => o.id);
          if (regions.length > 0)
            filteredListItems = filteredListItems.filter(r => regions.includes(r.regionId));
          break;
        //---------------------------------------------------------------------
        case "locale":
          const locales = selectedOptions.map(o => o.id);
          if (locales.length > 0)
            filteredListItems = filteredListItems.filter(r => locales.includes(r.localeId));
          break;
      };
    };
    //-------------------------------------------------------------------------
    const keyword = listColumn.filter?.keyword?.toLocaleLowerCase();
    if (keyword) {
      switch (listColumn.id) {
        //---------------------------------------------------------------------
        case "userId":
          filteredListItems = filteredListItems.filter(request =>
            keyword ?
              request.createdBy.userId.toString().toLocaleLowerCase().includes(keyword) :
              true);
          break;
        //---------------------------------------------------------------------
        default:
          filteredListItems = filteredListItems.filter(request =>
            keyword ?
              request[listColumn.id as TRegionalHomepageRequestKey]?.toString().toLocaleLowerCase().includes(keyword) :
              true);
          break;
      };
    };
    //-------------------------------------------------------------------------
    if (listColumn.filter?.type == "DatetimeRange") {
      filteredListItems = filteredListItems.filter(request => {
        const datetimeStart = listColumn.filter?.dateTimeStart;
        const datetimeEnd = listColumn.filter?.dateTimeEnd;
        const datetime = request[listColumn.id as TRegionalHomepageRequestKey]?.toString();
        if (datetimeStart && datetimeEnd) {
          if (!datetime) return false;
          if (datetime >= datetimeStart && datetime <= datetimeEnd) {
            return true;
          } else
            return false;
        } else return true;
      });
    };
  });
  //--------------------------------------------------------------------------- Sort
  listManagerContext.sortableColumns.forEach(column => {
    filteredListItems.sort((a, b) => column.sorter?.sort ?
      compareRegionalHomepageRequests(a, b, column.id, column.sorter.sort) :
      0
    );
  });
  //---------------------------------------------------------------------------
  const listItemElements = filteredListItems.map(listItem =>
    <RegionalHomepageRequestsListItem
      key={listItem.id}
      data={listItem}
      onSelect={onSelect}
    />);
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      containerWidthPercent={70}
    >
      <WikiSpinner show={isLoading} />

      <div className={styles.filter}>
        <ListColumnsManager
          numberTotal={listItems?.length}
          numberFiltered={filteredListItems.length}
          showBasicModeSwitch={true}
        />
      </div>

      <div className={styles.container}>
        {listItemElements}
      </div>

    </ConsoleContent>
  );
}