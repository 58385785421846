import React, { useContext, useEffect, useState } from "react";
import { ScreenType, useAppScreenContext } from "../../../../context/app-screen-context/AppScreenProvider";
import CombineStyles from "../../../../utils/combineStyles";
import PopupDialog from "../../popup-v2/popup-dialog/PopupDialog";
import { ImageEditorContext } from "../image-editor-context/ImageEditorContextProvider";
import { ImageEditorSession } from "../image-editor-context/ImageEditorSession";
import  { EImageEditorCloseCommand } from "../ImageEditor";
import { TImageData } from "../TImageData";
import { TUiImageEditor } from "../TUiImageEditor";
import styles from "./ImageEditorPopup.module.css";
import ImageEditorV2 from "../ImageEditorV2";
import { ButtonIds, EOkCancelDialogButtons, OkCancelToolbar } from "../../popup-v2/ok-cancel-toolbar/OkCancelToolbar";
//---------------------------------------------------------------------------------
interface IProps {
  ui?: TUiImageEditor;
  imageId: string;
  //command from parent component to close popup with save/discard/dialog options  
  closeCommand?: EImageEditorCloseCommand;
  dialogButtons: EOkCancelDialogButtons;
  onSessionOpened?: (session: ImageEditorSession) => void;
  onUrlAndBlobReady?: (imageData?: TImageData) => void;
  onUnsavedChangesDiscard?: (imageDataToRestore?: TImageData) => void;
  onClose?: () => void;
}

export default function ImageEditorPopupV2(props: IProps) {
  const { imageEditorContext } = useContext(ImageEditorContext);
  const screenType = useAppScreenContext();
  const [animationEnd, setAnimationEnd] = useState(false);
  const [imageEditorSession, setImageEditorSession] = useState<ImageEditorSession>();
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [imageEditorCloseCommand, setImageEditorCloseCommand] = useState<EImageEditorCloseCommand>(EImageEditorCloseCommand.None);
  const [fullWindow, setFullWindow] = useState(false);
  //-[imageEditorContext]--------------------------------------------------------
  useEffect(() => {
    if (imageEditorSession && imageEditorSession == imageEditorContext.lastChangedSession)
      setSaveEnabled(imageEditorSession.saveNeeded);
    else
      setSaveEnabled(false);
  }, [imageEditorContext, imageEditorSession]);
  //-[imageEditorCloseCommand]-------------------------------------------------
  useEffect(() => {
    setImageEditorCloseCommand(EImageEditorCloseCommand.None);
  }, [imageEditorCloseCommand]);
  //-[animationEnd]------------------------------------------------------------
  useEffect(() => {
    if (animationEnd)
      setAnimationEnd(false);
  }, [animationEnd]);
  //---------------------------------------------------------------------------
  const onImageEditorSessionOpened = (session: ImageEditorSession) => {
    //console.log("IEPopup.onImageEditorSessionOpened.session:", session);
    setImageEditorSession(session);
    props.onSessionOpened && props.onSessionOpened(session);
  }
  //---------------------------------------------------------------------------
  const onUrlAndBlobReady = (imageData?: TImageData) => {
    console.log("IEPopup.onUrlAndBlobReady");
    props.onUrlAndBlobReady && props.onUrlAndBlobReady(imageData);
  }
  //---------------------------------------------------------------------------
  const onPopupConfirm = () => {
    //console.log("IEPopup.onPopupConfirm");
    if (imageEditorSession && imageEditorSession.saveNeeded) {
      console.log("IEPopup.onPopupConfirm.setImageEditorCloseCommand(EImageEditorCloseCommand.Save)");
      setImageEditorCloseCommand(EImageEditorCloseCommand.Save);
    }
    else {
      //console.log("IEPopup.onPopupConfirm.props.onClose()");
      props.onClose && props.onClose();
    }
  }
  //---------------------------------------------------------------------------
  const onPopupCancel = () => {
    if (imageEditorSession) {
      //console.log("onPopupCancel: setImageEditorCloseCommand(Discard)")
      setImageEditorCloseCommand(EImageEditorCloseCommand.Discard);
    }
    else {
      //console.log("onPopupCancel: props.onClose && props.onClose()")
      props.onClose && props.onClose();
    }

  }
  //---------------------------------------------------------------------------
  const onPopupClose = () => {
    if (imageEditorSession && imageEditorSession.saveNeeded) {
      setImageEditorCloseCommand(EImageEditorCloseCommand.Dialog);
    }
    else
      props.onClose && props.onClose();
  }
  //---------------------------------------------------------------------------
  const onToggleFullWindow = () => {
    setFullWindow(!fullWindow);
  }
  //---------------------------------------------------------------------------
  const onAnimationEnd = () => {
    setAnimationEnd(true);
  }
  //---------------------------------------------------------------------------
  const containterStyles = CombineStyles([
    fullWindow ? styles.fullWindow : styles.container,
    screenType == ScreenType.Mobile ? styles.mobile : ""
  ]);
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id='ImageEditor'
      header={"Image Editor"}
      //toolbarContent={formOptions}
      toolbarContent={
        <OkCancelToolbar
          dialogType="Confirmation"
          dialogButtons={props.dialogButtons}
          disabledButtonIds={ !saveEnabled ? [ButtonIds.ok] : undefined }
          onOk={onPopupConfirm}
          onCancel={onPopupCancel}
        />
      }
      isDraggable={false}
      isModal={true}
      fullWindow={fullWindow}
      onClose={onPopupClose}
      onAnimationEnd={onAnimationEnd}
    >
      <div className={containterStyles}>
        <ImageEditorV2
          ui={props.ui}
          imageId={props.imageId}
          closeCommand={imageEditorCloseCommand}
          fullWindow={fullWindow}
          animationEnd={animationEnd}
          onSessionOpened={onImageEditorSessionOpened}
          onUrlAndBlobReady={onUrlAndBlobReady}
          onUnsavedChangesDiscard={props.onUnsavedChangesDiscard}
          onToggleFullWindow={onToggleFullWindow}
        />
      </div>
    </PopupDialog>
  );
}