import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../app-auth-context/AppAuthContext";
import { ReactNode, useEffect, useReducer, useState } from "react";
import { TUser } from "../app-auth-context/TUser";
import { IServiceWorkerContext, ServiceWorkerContextStore, ServiceWorkerManager } from "./ServiceWorkerContext";
import React from "react";
import { ServiceWorkerContextReducer } from "./ServiceWorkerContextReducer";
//-----------------------------------------------------------------------------
interface IProps {
  user: TUser | null;
  config?: IAuthConfig;
  instance: IPublicClientApplication;
  children?: ReactNode;
}
//-----------------------------------------------------------------------------
export function ServiceWorkerContextProvider(props: IProps) {
  const [context] = useState<IServiceWorkerContext>({ manager: undefined });
  const [serviceWorkerContext, serviceWorkerDispatch] = useReducer(
    ServiceWorkerContextReducer,
    context
  );
  //-----------------------------------------------------------------------------
  useEffect(() => {
    //console.log("AppAuthContextProvider.useEffect[instance, config, user]:",
    //instance, appAuthContext.config, appAuthContext.user);
    if (!props.instance || !props.config) {
      return;
    }
    //console.log("ServiceWorkerContextProvider.useEffect[instance, config, user]");
    //https://felixgerschau.com/how-to-communicate-with-service-workers/
    context.manager = new ServiceWorkerManager(props.config, props.instance, serviceWorkerDispatch);
    return (() => {
      //console.log("ServiceWorkerContextProvider.useEffect[instance, config, user].return");
      //navigator.serviceWorker.controller?.postMessage({ messageType: 'messageChannelClose' });
      //tokenChannel.current?.port1.close();
    });
  }, [props.instance, props.config]);
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <ServiceWorkerContextStore.Provider
        value={{ serviceWorkerContext, serviceWorkerDispatch }}
      >
        {props.children}
      </ServiceWorkerContextStore.Provider>
    </React.Fragment>
  );
}