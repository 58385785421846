import { ReactNode, useEffect, useState } from 'react';
import styles from './DictionaryNodeEditFormWrapper.module.css';
import React from 'react';
import useClickOutsideDiv from '../../../../hooks/useClickOutsideDiv';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { TUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import { TUiMenuOption } from '../../../common/menu/menu-content/menu-option/TUiMenuOption';
import PopupMessage from '../../../common/popup-v2/popup-message/PopupMessage';

export interface IUiDictionaryNodeEditForm {
  formOptions: TUiMenuContent;
}
const tmpUi: IUiDictionaryNodeEditForm = {
  formOptions: {
    id: '',
    directoryId: '',
    elementType: '',
    visible: true,
    disabled: false,
    options: [{
      id: 'optionCancel',
      directoryId: '',
      elementType: '',
      visible: true,
      disabled: false,
      canHideCaption: false,
      index: 0,
      priorityLevel: 0,
      caption: { en: 'Cancel' },
      iconFile: "close",
      hint: undefined,
      isDefault: false,
      action: undefined
    }, {
      id: 'optionOk',
      directoryId: '',
      elementType: '',
      visible: true,
      disabled: false,
      canHideCaption: false,
      index: 0,
      priorityLevel: 0,
      caption: { en: 'Ok' },
      iconFile: "check",
      hint: undefined,
      isDefault: true,
      action: undefined
    }],
    optionGroups: []
  }
}
const discardChangesConfirmationDialogMenu: TUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  visible: true,
  disabled: false,
  options: [{
    id: "optionDiscard",
    directoryId: "",
    elementType: "",
    index: 1,
    priorityLevel: 1,
    caption: { en: "Discard" },
    hint: null,
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    canHideCaption: false,
    action: null
  }, {
    id: "optionCancel",
    directoryId: "",
    elementType: "",
    index: 1,
    priorityLevel: 1,
    caption: { en: "Cancel" },
    hint: null,
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    canHideCaption: false,
    action: null
  }],
  optionGroups: []
}

interface IProps {
  children: ReactNode;
  isUpdated: boolean;
  isValid: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export default function DictionaryNodeEditFormWrapper(props: IProps) {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const editFormDivRef = useClickOutsideDiv({
    handler: onCloseWithUnsavedChanges,
    unsavedChanges: props.isUpdated
  });
  //--------------------------------------------------------------------------- Scroll the form to the edit form div
  useEffect(() => {
    if (editFormDivRef.current) {
      editFormDivRef.current.scrollIntoView({ behavior: 'smooth' });
    };
  }, [editFormDivRef.current]);
  //---------------------------------------------------------------------------
  function onCloseWithUnsavedChanges(unsavedChanges?: boolean) {
    if (unsavedChanges)
      setConfirmationDialogOpen(true);
    else
      props.onClose();
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "optionOk":
        props.onConfirm();
        break;
      case "optionCancel":
        onCloseWithUnsavedChanges(props.isUpdated);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onConfirmationDialogOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "optionDiscard":
        props.onClose();
        break;
      case "optionCancel":
        setConfirmationDialogOpen(false);
        break;
    };
  };
  //---------------------------------------------------------------------------
  var updatedOptions: TUiMenuOption[] = [];
  tmpUi.formOptions.options.forEach(option => {
    switch (option.id) {
      case "optionOk":
        const updatedOkOption: TUiMenuOption = {
          ...option,
          disabled: !(props.isUpdated && props.isValid)
        };
        updatedOptions.push(updatedOkOption);
        break;
      //-----------------------------------------------------------------------
      case "optionCancel":
        updatedOptions.push(option);
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <tr>
        <td colSpan={100}>
          <div
            ref={editFormDivRef}
            className={styles.container}
          >
            {props.children}
            <FormOptions
              ui={{ ...tmpUi.formOptions, options: updatedOptions }}
              applyMobileStyle={false}
              onSelect={onOptionSelect}
            />
          </div>
        </td>
      </tr>

      <PopupMessage
        id='DictionaryNodeDiscardChangesConfirmationMessage'
        type='Confirmation'
        header={`System Translator Console. Widget Editor`}
        message='Please confirm you are going to discard unsaved changes'
        optionsMenuContent={discardChangesConfirmationDialogMenu}
        show={confirmationDialogOpen}
        onOptionSelect={onConfirmationDialogOptionSelect}
        onCancel={() => onConfirmationDialogOptionSelect("optionCancel")}
      />

    </React.Fragment>
  );
}