import { useContext, useEffect } from "react";
import { IUiOption } from "../../../common/options/IUiOption";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import useNavigateWithContext from "../../../../hooks/useNavigateWithContext";
import { optionIdContent, pathContent } from "../SystemTranslatorConsole";
import { TUiBreadcrumb } from "../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { pathAdminConsole, pathSystemTranslator } from "../../AdminConsole";
import { EDirectoryId } from "../widgets/Widgets";
import { Route, Routes } from "react-router";
import DirectoryList from "../widgets/directory-list/DirectoryList";
import ContentDirectoryTranslator from "./content-directory-translator/ContentDirectoryTranslator";

interface IProps {
  optionsForBreadcrumb?: IUiOption[];
}

export default function ContentDirectories(props: IProps) {
  const { appUiContextDispatch } = useContext(AppUiContextStore);
  const navigate = useNavigateWithContext();
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdContent);
    if (!option) return;
    const updatedBreadcrumb: TUiBreadcrumb = {
      id: option.id,
      caption: option.caption,
      path: `/${pathAdminConsole}/${pathSystemTranslator}/${pathContent}`
    };
    appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: updatedBreadcrumb });
  }, []);
  //---------------------------------------------------------------------------
  const onSelectDirectory = (directoryId: EDirectoryId, locale?: string) => {
    navigate(`/${pathAdminConsole}/${pathSystemTranslator}/${pathContent}/${directoryId}/${locale}`);
  };
  //---------------------------------------------------------------------------
  return (
    <Routes>

      {/* List of directories */}
      <Route
        index
        element={
          <DirectoryList
            directoryType="Defaults"
            onSelect={onSelectDirectory}
          />}
      />

      {/* Default Content Directory Translation Editor */}
      <Route
        path={`:directoryId/:localeId`}
        element={
          <ContentDirectoryTranslator />}
      />

    </Routes>
  );
}