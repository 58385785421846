import { TUiBreadcrumb } from "../../components/common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { IUiDictionaries } from "../../components/common/dictionaries/IUiDictionaries";
import { TImageData } from "../../components/common/image-editor/TImageData";
import { BusinessSearchListItem } from "../../components/search-console/business-search-list/business-search-card/BusinessSearchListItem";
import { ELogLevel, LogInformation } from "../../utils/logging";
import { IAppUiContext, IRgbColors, } from "./AppUiContextProvider";
import { IAppUiLocaleContext } from "./IAppUiLocaleContext";
import { TSysSettings } from "./ISysSettings";

const logLevel: ELogLevel = ELogLevel.None;

export interface ISetLocale {
  type: "SetLocale";
  localeId: string;
  fallbackLevel?: number;
  queryString?: string;
  isImportant?: boolean;
  preferredLanguages?: string[];
}
interface ISetTheme {
  type: "SetTheme";
  themeId: string;
}
interface ISetSysSettings {
  type: "SetSysSettings";
  sysSettings: TSysSettings;
}
interface ISetIsLoading {
  type: "SetIsLoading";
  value: boolean;
}
interface IUpdateBreadcrumbsTrail {
  type: "UpdateBreadcrumbsTrail",
  value: TUiBreadcrumb
}
interface IUpdateDictionaries {
  type: "UpdateDictionaries",
  value: IUiDictionaries;
}
interface IUpdateZetIndex {
  type: "UpdateZetIndex";
  value: number;
}
interface ISetRgbaColors {
  type: "SetRgbaColors";
  value: IRgbColors;
}
interface ISetCurrentPagePath {
  type: "SetCurrentPagePath";
  value: string;
}
interface ISetPathToRemember {
  type: "SetPathToRemember";
  value?: string;
}

export type Actions = ISetLocale | ISetTheme | ISetSysSettings | ISetIsLoading |
  IUpdateBreadcrumbsTrail | IUpdateDictionaries | IUpdateZetIndex | ISetRgbaColors | ISetCurrentPagePath | ISetPathToRemember;

export const AppUiContextReducer = (state: IAppUiContext, action: Actions) => {
  let updatedState: IAppUiContext;
  switch (action.type) {
    //-------------------------------------------------------------------
    case "SetLocale": {
      if (state.locale.localeId === action.localeId &&
        state.locale.fallbackLevel === action.fallbackLevel &&
        state.locale.queryString === action.queryString &&
        state.preferredLanguages === action.preferredLanguages
      ) {
        //LogInformation(logLevel, "AppUiContextReducer.SetLocale: nothing is changed");
        console.log("AppUiContextReducer.SetLocale: nothing is changed");
        //return { ...state };
        updatedState = state;
      }
      else {
        //-----------------------------------------------------------------
        LogInformation(logLevel,
          "Passed locale: " + action.localeId +
          ", fallbackLevel: " + action.fallbackLevel +
          ", querystring: " + action.queryString
        );
        // Update context
        const appUiLocaleContext: IAppUiLocaleContext = {
          localeId: action.localeId,
          // Set fallback level value to default 0 if not provided
          fallbackLevel: action.fallbackLevel ? action.fallbackLevel : 0,
          // Clear URL locale settings in case if there are any and update location
          queryString: action.queryString,
          isImportant: action.isImportant == undefined ? state.locale.isImportant : action.isImportant,
        };
        // Store manually set locale to LS - no, it should be stored in LS only in case if user selected locale from the menu
        // So it can be used as default locale at the start (AppWrapper)
        //setToLS(lsPreferredAppLocale, action.localeId);
        //-----------------------------------------------------------------
        updatedState = {
          ...state,
          locale: appUiLocaleContext,
          preferredLanguages: action.preferredLanguages ? action.preferredLanguages : state.preferredLanguages
        };
      }
      //console.log("SetLocale.updatedState:", updatedState);
    } break;
    //-------------------------------------------------------------------
    case "SetTheme": {
      if (state.theme.themeId === action.themeId) {
        updatedState = { ...state };
      }
      else {
        //-----------------------------------------------------------------
        LogInformation(logLevel, "Passed theme id: " + action.themeId);
        updatedState = {
          ...state as IAppUiContext,
          theme: {
            themeId: action.themeId
          }
        };
      }
    } break;
    //-------------------------------------------------------------------
    case "SetSysSettings": {
      const newSysSettings: TSysSettings = action.sysSettings;
      TImageData.storageEndpoint = action.sysSettings.storageEndpoint;
      TImageData.defaultBusinessLogo = action.sysSettings.defaultBusinessLogo;
      //LogInformation(logLevel, "SetSysSettings.TImageData.defaultBusinessLogo:", TImageData.defaultBusinessLogo);
      newSysSettings.sysLocalesList = [
        newSysSettings.sysLocales.i18nLocale,
        newSysSettings.sysLocales.nativeLocale,
        newSysSettings.sysLocales.pseudoLocale,
        newSysSettings.sysLocales.pseudoRtlLocale,
      ];
      //LogInformation(logLevel, newSysSettings)
      updatedState = { ...state, sysSettings: newSysSettings };
    } break;
    //-------------------------------------------------------------------
    case "SetIsLoading": {
      updatedState = { ...state };//{ ...state, isLoading: action.value };
    } break;
    //-------------------------------------------------------------------
    case "UpdateBreadcrumbsTrail": {
      //LogInformation(logLevel, "UpdateBreadcrumbsTrail.action.value:", action.value);  
      var updatedBreadcrumbs = state.breadcrumbs.filter(item => item.path != action.value.path);
      //LogInformation(logLevel, "UpdateBreadcrumbsTrail.updatedBreadcrumbs:", updatedBreadcrumbs)
      updatedBreadcrumbs = updatedBreadcrumbs.concat(action.value);
      updatedState = {
        ...state,
        breadcrumbs: updatedBreadcrumbs
      };
    } break;
    //-------------------------------------------------------------------
    case "UpdateDictionaries": {
      //console.log("AppUiContextReducer.UpdateDictionaries:", action.value);
      let updatedDictionaries: IUiDictionaries = {
        adPageStatuses: action.value.adPageStatuses ? action.value.adPageStatuses : state.dictionaries?.adPageStatuses,
        adRanks: action.value.adRanks ? action.value.adRanks : state.dictionaries?.adRanks,
        colourThemes: action.value.colourThemes ? action.value.colourThemes : state.dictionaries?.colourThemes,
        contactTypes: action.value.contactTypes ? action.value.contactTypes : state.dictionaries?.contactTypes,
        locales: action.value.locales ? action.value.locales : state.dictionaries?.locales,
        supportedLocales: action.value.supportedLocales ? action.value.supportedLocales : state.dictionaries?.supportedLocales,
        supportedLocalesNative: action.value.supportedLocalesNative ? action.value.supportedLocalesNative : state.dictionaries?.supportedLocalesNative,
        supportedRegions: action.value.supportedRegions ? action.value.supportedRegions : state.dictionaries?.supportedRegions,
        businessTags: action.value.businessTags ? action.value.businessTags : state.dictionaries?.businessTags,
        countries: action.value.countries ? action.value.countries : state.dictionaries?.countries,
        requestRejectionReasons: action.value.requestRejectionReasons ? action.value.requestRejectionReasons : state.dictionaries?.requestRejectionReasons,
        adDesignTemplates: action.value.adDesignTemplates ? action.value.adDesignTemplates : state.dictionaries?.adDesignTemplates
      };
      //console.log("Reduser.locales:", updatedDictionaries.locales);
      updatedState = {
        ...state,
        dictionaries: updatedDictionaries
      };
    } break;
    //-------------------------------------------------------------------
    case "UpdateZetIndex": {
      updatedState = {
        ...state,
        zetIndex: action.value
      };
    } break;
    //-------------------------------------------------------------------
    case "SetRgbaColors": {
      updatedState = {
        ...state,
        rgbColors: action.value
      }
    } break;
    //-------------------------------------------------------------------
    case "SetCurrentPagePath": {
      updatedState = {
        ...state,
        currentPagePath: action.value
      };
    } break;
    //-------------------------------------------------------------------
    case "SetPathToRemember": {
      updatedState = {
        ...state,
        pathToRemember: action.value
      };
    } break;
    //-------------------------------------------------------------------
    default: {
      updatedState = { ...state };
    } break;
  }
  BusinessSearchListItem.appUiContext = updatedState;
  return updatedState;
};
