import { IPresets } from "../../../../../context/list-manager-context/IListManagerContextData";
import { IListColumn } from "../../../../common/lists/IListColumn";
import { validatorModerator } from "../../functions/validatorModerator";
import { validatorStatus } from "../../functions/validatorStatus";

export const approvalRequestsListColumns: IListColumn[] = [{
  id: "moderator",
  name: "Moderator",
  filter: {
    type: "Options",
    options: [{
      id: "Unassigned"
    }, {
      id: "Assigned"
    }],
    validator: validatorModerator
  }
}, {
  id: "status",
  name: "Status",
  filter: {
    type: "Options",
    options: [{
      id: "New"
    }, {
      id: "Uncompleted",
      isSelected: true
    }, {
      id: "Completed"
    }, {
      id: "Approved"
    }, {
      id: "Rejected"
    }],
    validator: validatorStatus
  }
}, {
  id: "regionId",
  name: "Region",
  filter: {
    type: "Options"
  }
}, {
  id: "locale",
  name: "Locale",
  filter: {
    type: "Options",
    options: []
  }
}, {
  id: "businessId",
  name: "Business Id",
  filter: {
    type: "Keyword",
    keyword: "",
    placeholder: "Paste business Id..."
  }
}, {
  id: "adLanguageSetId",
  name: "Ad Id",
  filter: {
    type: "Keyword",
    keyword: "",
    placeholder: "Paste ad language set Id..."
  }
}, {
  id: "userId",
  name: "User Id",
  filter: {
    type: "Keyword",
    keyword: "",
    placeholder: "Paste user Id..."
  }
}, {
  id: "dateTimeCreated",
  name: "Created",
  filter: {
    type: "DatetimeRange"
  },
  sorter: {
    order: 1,
    sort: "Asc",
    isRequired: true
  }
}, {
  id: "dateTimeResolved",
  name: "Resolved",
  filter: {
    type: "DatetimeRange"
  },
  sorter: {
    order: 2,
    isDisabled: true
  }
}];

export const approvalRequestsListSortPresets: IPresets = {
  options: [{
    id: "OldestFirst",
    name: "Oldest First",
  },{
    id: "NewestFirst",
    name: "Newest First",
  }],
  currentOptionId: "OldestFirst"
};

export const approvalRequestsListFilterPresets: IPresets = {
  options: [{
    id: "Uncompleted",
    name: "Uncompleted",
  },{
    id: "Rejected",
    name: "Rejected",
  }],
  currentOptionId: "Uncompleted"
};