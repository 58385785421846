import React, { useContext, useEffect, useState } from 'react';
import WikiSpinner from '../../app-layout/spinner/wikiSpinner';
import usePageTitle from '../../../hooks/usePageTitle';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../context/app-auth-context/AppAuthContext';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';

import ConsoleLayout from '../../common/console-layout/ConsoleLayout';
import { optionIdSystemTranslatorConsole, pathAdminConsole, pathSystemTranslator } from '../AdminConsole';
import { Route, Routes } from 'react-router';
import NavigateWithContext from '../../common/navigate-with-context/NavigateWithContext';
import Dictionaries from './dictionaries/Dictionaries';
import Widgets from './widgets/Widgets';
import { AbortControllerWrapper, useAbortController } from '../../../hooks/useAbortController';
import { IUiApplication_SystemTranslatorConsole, IUiSystemTranslatorConsole } from './IUiSystemTranslatorConsole';
import { IUiOption } from '../../common/options/IUiOption';
import { TUiNames } from '../../../context/app-ui-context/AppUiCache';
import { TUiBreadcrumb } from '../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { getTUiMenuContent } from '../../common/menu/menu-content/TUiMenuContent';
import Defaults from './content/ContentDirectories';

const uiName: TUiNames = "systemTranslatorConsole";
export const pathNotImplemented = "notimplemented";
export const pathDictionaries = "catalogues";
export const optionIdDictionaries = "SystemTranslatorConsole.MenuContent.OptionDictionaries";
export const pathWidgets = "ui";
export const optionIdWidgets = "SystemTranslatorConsole.MenuContent.OptionWidgetDirectories";
export const pathContent = "content";
export const optionIdContent = "SystemTranslatorConsole.MenuContent.OptionContentDirectories";

interface IProps {
  optionsForBreadcrumb?: IUiOption[];
}

export default function SystemTranslatorConsole(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const [ui, setUi] = useState<IUiSystemTranslatorConsole>();
  const [isLoading, setIsLoading] = useState(false);
  const updateTitle = usePageTitle();
  const abortController = useAbortController("SystemTranslatorConsole");
  //--------------------------------------------------------------------------- Title, abortController
  useEffect(() => {
    updateTitle("System Translator");
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //--------------------------------------------------------------------------- UI
  useEffect(() => {
    if (ui || !appUiContext.uiCache?.checkAuthorization(uiName)) {
      setIsLoading(false);
      return;
    };
    setIsLoading(true);
    let controller = abortController.newController("GetUi");
    appUiContext.uiCache.getUiCallback<IUiApplication_SystemTranslatorConsole>(
      uiName,
      (ui) => !controller.aborted && setConsoleUi(ui, controller),
      () => !controller.aborted && setIsLoading(false)
    );
  }, [appAuthContext]);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdSystemTranslatorConsole);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        caption: option.caption,
        path: `/${pathAdminConsole}/${pathSystemTranslator}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
  }, [props.optionsForBreadcrumb]);
  //---------------------------------------------------------------------------
  function setConsoleUi(consoleUi: IUiApplication_SystemTranslatorConsole, controller: AbortControllerWrapper) {
    if (!controller.aborted) {
      // Set dictionaries
      if (consoleUi?.dictionaries) {
        appUiContextDispatch({ type: "UpdateDictionaries", value: consoleUi.dictionaries });
      };
      //-------------------------------------------------------------------------
      setUi(consoleUi.systemTranslatorConsole);
    };
  };
  //--------------------------------------------------------------------------- Menu options
  const updatedMenuContent = ui ? getTUiMenuContent(ui.menuContent) : undefined;
  updatedMenuContent?.options.forEach(option => {
    switch (option.id) {
      case optionIdDictionaries:
        option.iconFile = "books";
        option.action = `/${pathAdminConsole}/${pathSystemTranslator}/${pathDictionaries}`;
        break;
      case optionIdWidgets:
        option.iconFile = "uielement";
        option.action = `/${pathAdminConsole}/${pathSystemTranslator}/${pathWidgets}`;
        break;
      case optionIdContent:
        option.iconFile = "text";
        option.action = `/${pathAdminConsole}/${pathSystemTranslator}/${pathContent}`;
        break;
    };
  });
  //---------------------------------------------------------------------------
  if (!ui || !updatedMenuContent) return null;
  else return (
    <ConsoleLayout
      ui={{ header: "", menuContent: updatedMenuContent }}
      path={pathSystemTranslator}
      hideSidebar>
      <WikiSpinner show={isLoading} />
      <Routes>
        <Route
          index
          element={<NavigateWithContext to={`/${pathAdminConsole}/${pathSystemTranslator}/${pathDictionaries}`} />}
        />
        <Route
          path={`${pathDictionaries}/*`}
          element={
            <Dictionaries
              optionsForBreadcrumb={ui.menuContent.options}
            />}
        />
        <Route
          path={`${pathWidgets}/*`}
          element={
            <Widgets
              optionsForBreadcrumb={ui.menuContent.options}
            />}
        />
        <Route
          path={`${pathContent}/*`}
          element={
            <Defaults
              optionsForBreadcrumb={ui.menuContent.options}
            />}
        />
      </Routes>
    </ConsoleLayout>
  );
}