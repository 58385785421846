import { useContext, useEffect, useRef, useState } from "react";
import { ScreenType, useAppScreenContext } from "../../../../context/app-screen-context/AppScreenProvider";
import CombineStyles from "../../../../utils/combineStyles";
import GetCaption from "../../../common/functions/GetCaption";
import styles from "./SearchDetails.module.css";
import { TLocationData, TPolygonArea } from "../../../common/map/geo-json/GeoJson";
//import { ELocationByAddressQuery } from "../../../common/map/locationSearch/LocationSearchV2";
import { IUiSearchPanel } from "../IUiSearchPanel";
import { ELogLevel, LogInformation } from "../../../../utils/logging";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import InputKeyword from "./inputs/InputKeyword";
import OptionSearch from "./option-search/OptionSearch";
import GeolocationSearcher from "./geolocation-searcher/GeolocationSearcher";
import MainMenuOptionDropdown from "../../../common/options/menus/main-menu/main-menu-option/MainMenuOptionDropdown";
import { IUiOption } from "../../../common/options/IUiOption";

const logLevel: ELogLevel = ELogLevel.None;

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

interface IProps {
  ui: IUiSearchPanel;
  onSearch: (searchByLocation: string, area: google.maps.LatLngBoundsLiteral | TPolygonArea | undefined) => void;
  onSearchV2: (locationData?: TLocationData) => void;
  selectedMainMenuOption?: IUiOption;
  onMainMenuOptionSelect?: () => void;
}

export default function SearchDetails(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const [locationData, setLocationData] = useState<TLocationData>(new TLocationData());
  //---------------------------------------------------------------------------
  const onLocationChange = (locationData: TLocationData) => {
    LogInformation(logLevel, "SearchDetails.onLocationChange.locationData:", locationData);
    setLocationData(locationData);
  };
  //---------------------------------------------------------------------------
  const onEdit = () => {

  };
  //---------------------------------------------------------------------------
  // Navigate to the Search Page
  const onSearch = () => {
    //setSearchClicked(true);
    LogInformation(logLevel, "SearchDetails.onSearch.locationData:", locationData);
    if (!locationData) {
      LogInformation(logLevel, "SearchDetails.onSearch.locationData is undefined: starting search without location");
      props.onSearchV2(locationData);
      return;
    }
    if (locationData.isAddressOnly) {
      LogInformation(logLevel, "SearchDetails.onSearch.locationData: address only - starting location query...");
      //setLocationByAddressQuery(ELocationByAddressQuery.Start);
    }
    else {
      LogInformation(logLevel, "SearchDetails.onSearch.locationData is filled up: starting search with location");
      props.onSearchV2(locationData);
    }
    // if (searchLocation === "" || (searchLocation.length > 0 && locationCoordinate)) {
    //   props.onSearch(searchLocation, locationCoordinate);
    // }
  };
  //---------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    screenType == ScreenType.Mobile ? styles.mobile : ""
  ]);
  //---------------------------------------------------------------------------
  const searchItemStyles = CombineStyles([
    styles.searchItem,
    screenType == ScreenType.Mobile ? styles.mobile : ""
  ]);
  //---------------------------------------------------------------------------
  return (
    <div className={containerStyles}>

      {screenType === ScreenType.Mobile && props.selectedMainMenuOption &&
        <div className={searchItemStyles}>
          <MainMenuOptionDropdown
            key={props.selectedMainMenuOption.iconId}
            ui={props.selectedMainMenuOption}
            onSelect={props.onMainMenuOptionSelect}
          />
        </div>}

      <div className={searchItemStyles}>
        <div className={styles.caption}>
          {GetCaption(appUiContext, props.ui.textSearchInput.id, props.ui.textSearchInput.caption)}
        </div>
        <div className={styles.input}>
          <InputKeyword ui={props.ui.textSearchInput} />
        </div>
      </div>

      <div className={searchItemStyles}>
        <div className={styles.caption}>
          {GetCaption(appUiContext, props.ui.locationSearchInput.id, props.ui.locationSearchInput.caption)}
        </div>
        <div className={styles.input}>
          <GeolocationSearcher
            ui={{
              id: props.ui.locationSearchInput.id,
              directoryId: props.ui.locationSearchInput.directoryId,
              elementType: props.ui.locationSearchInput.elementType,
              placeholder: props.ui.locationSearchInput.placeholder,
              hint: props.ui.locationSearchInput.hint
            }} />
        </div>
      </div>

      <div className={searchItemStyles}>
        <OptionSearch
          ui={props.ui.searchButton}
          onSelect={onSearch} />
      </div>

    </div>
  );
}