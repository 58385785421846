import { useContext, useEffect, useState } from "react";
import InputWrapper from "../input-wrapper/InputWrapper";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import { IUiInputDropdown } from "./InputDropdown";
import GetCaption from "../../functions/GetCaption";
import GetHint from "../../functions/GetHint";
import { IUiValidation, IValidationState } from "../input-validation/IUiValidation";
import styles from './InputDropdownMultiselect.module.css';
import GetPlaceholder from "../../functions/GetPlaceholder";
import { IUiDictionaryItem } from "../../dictionaries/IUiDictionaryItem";
import MultiselectOption from "./multiselect-option/MultiselectOption";
import InputDropdownPopup from "./input-dropdown-popup/InputDropdownPopup";

interface IProps {
  ui: IUiInputDropdown;
  data?: string[];
  options?: IUiDictionaryItem[];
  sortOptions: boolean;
  hideDescription: boolean;
  isAutoComplete?: boolean;
  onInitialValidate?: (isValid: boolean) => void;
  onUpdate?: (selectedOptions?: string[], isValid?: boolean) => void;
}

export default function InputDropdownMultiselect(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [popupOpen, setPopupOpen] = useState(false);
  const [validationState, setValidationState] = useState<IValidationState>({ isValid: true, validationMessages: [] });
  const isReadonly = props.ui.disabled;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (props.ui.validation?.required) {
      const _validationState = validate(props.data, props.ui.validation);
      props.onInitialValidate && props.onInitialValidate(_validationState.isValid);
    } else {
      setValidationState({ isValid: true, validationMessages: [] });
    };
  }, [props.ui.validation, props.data]);
  //---------------------------------------------------------------------------
  const onOptionRemove = (optionId: string) => {
    const updatedOptions = props.data?.filter(id => id != optionId);
    const _validationState = validate(updatedOptions, props.ui.validation);
    props.onUpdate && props.onUpdate(updatedOptions, _validationState.isValid);
  };
  //---------------------------------------------------------------------------
  const validate = (data?: string[], validation?: IUiValidation): IValidationState => {
    let validationState: IValidationState = { isValid: true, validationMessages: [] };
    if (validation && validation.required) {
      const isValid = !!data && data.length >= validation.required.value;
      validationState = {
        isValid: isValid,
        validationMessages: isValid ? [] : [{
          id: `${props.ui.id}.Validation.Required.Message`,
          message: validation.required.message
        }]
      };
    };
    setValidationState(validationState);
    return validationState;
  };
  //---------------------------------------------------------------------------
  const showOptions = () => {
    setPopupOpen(true);
  };
  //---------------------------------------------------------------------------
  const onSelect = (selectedOption?: IUiDictionaryItem) => {
    if (!selectedOption) return;
    let isValid = true;
    const updatedOptions = props.data ? [...props.data] : [];
    if (updatedOptions.includes(selectedOption.id)) {
      updatedOptions.splice(updatedOptions.indexOf(selectedOption.id), 1);
    } else {
      updatedOptions.push(selectedOption.id);
    };
    //-------------------------------------------------------------------------
    if (props.ui.validation?.required) {
      isValid = updatedOptions.length >= props.ui.validation.required.value;
      let validationState: IValidationState = {
        isValid: isValid,
        validationMessages: isValid ? [] : [props.ui.validation.required.message]
      };
      setValidationState(validationState);
    };
    //-------------------------------------------------------------------------
    props.onUpdate && props.onUpdate(updatedOptions, isValid);
  };
  //---------------------------------------------------------------------------
  const selectedOptions = props.data?.map(optionId =>
    <MultiselectOption
      key={optionId}
      data={optionId}
      onRemove={isReadonly ? undefined : onOptionRemove}
    />
  );
  let placeholder = isReadonly ?
    <div>
      --
    </div> :
    <div
      className={styles.placeholder}
      onClick={showOptions}
    >
      {GetPlaceholder(appUiContext, props.ui.id, props.ui.placeholder)}
    </div>;
  //---------------------------------------------------------------------------
  return (
    <InputWrapper
      caption={!props.ui.hideCaption ? GetCaption(appUiContext, props.ui.id, props.ui.caption) : undefined}
      hint={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      hideCaption={props.ui.hideCaption}
      hideHint={props.ui.hideHint}
      validation={validationState}
    >
      {props.data ?
        <div
          id={props.ui.id}
          className={styles.selectedOptions}>

          {selectedOptions}

          {!props.ui.disabled &&
            <div
              className={styles.option}
              onClick={showOptions}
            >
              Edit...
            </div>}

        </div>
        :
        placeholder}


      {!props.ui.disabled && popupOpen && props.options &&
        <InputDropdownPopup
          anchorId={props.ui.id}
          ui={props.ui}
          options={props.options}
          selectedOptions={props.data}
          sortOptions={props.sortOptions}
          hideDescription={props.hideDescription}
          isAutoComplete={props.isAutoComplete}
          isMultiselect={true}
          onClose={() => setPopupOpen(false)}
          onSelect={onSelect}
        />}

    </InputWrapper>
  );
}