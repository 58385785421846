import { IUiInteractiveForm } from "../../../common/forms/IUiInteractiveForm";
import { IUiInputText } from "../../../common/input-v2/input-text/InputText";

export interface IUiEditFormStaffUser extends IUiInteractiveForm {
  inputUserId: IUiInputText;
  inputPrincipalName: IUiInputText;
  inputDisplayName: IUiInputText;
}

export const hardcodedUiEditFormStaffUser: IUiEditFormStaffUser = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: { en: "Staff User Edit form" },
  hint: { en: "Staff User details" },
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: { en: "" },
    options: [{
      id: "OptionClose",
      directoryId: "",
      elementType: "",
      caption: { en: "Close" },
      hint: { en: "Close" }
    }]
  },
  inputUserId: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: {en: "User Id"},
    hint: undefined,
    disabled: true,
    hideHint: true
  },
  inputPrincipalName: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: {en: "Principal Name"},
    hint: undefined,
    disabled: true,
    hideHint: true
  },
  inputDisplayName: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: {en: "Display Name"},
    hint: undefined,
    disabled: true,
    hideHint: true
  }
}