import { IUiOption } from "../options/IUiOption";

export type TUiButton = {
  id: string,
  iconFile?: string | null,
  caption: any,
  hint: any,
  disabled: boolean,
  visible: boolean,
  action?: any,
  canHideCaption: boolean
};

export function getTUiButton(uiOption?: IUiOption): TUiButton | undefined {
  if (uiOption)
    return {
      id: uiOption.id,
      iconFile: uiOption.iconId,
      caption: uiOption.caption,
      hint: uiOption.hint,
      disabled: !!uiOption.isDisabled,
      visible: true,
      canHideCaption: false,
      action: uiOption.action
    };
  else return undefined;
}

export function GetTUiButton(option: IUiOption): TUiButton {
  return ({
    id: option.id,
    caption: option.caption,
    hint: option.hint,
    disabled: !!option.isDisabled,
    visible: true,
    canHideCaption: false
  });
}