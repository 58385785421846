import { PageButton } from "./PageButton";
import styles from './BusinessSearchPager.module.css';
//------------------------------------------------------------------------------------
interface IProps {
  pageCount: number;
  maxPageCountToDisplay: number;
  selectedIndex: number;
  onPageSelect: (index: number) => void;
}
//------------------------------------------------------------------------------------
export function BusinessSearchPager(props: IProps) {
  //----------------------------------------------------------------------------------
  if (props.pageCount == 0)
    return null;
  //----------------------------------------------------------------------------------
  let buttons: JSX.Element[] = [];
  for (let i = 0; i < props.pageCount; i++) {
    buttons.push(
      <PageButton
        key={`pb_${i}`}
        index={i}
        selected={i == props.selectedIndex}
        onSelect={props.onPageSelect}
      />);
  }
  //----------------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      {buttons}
    </div>
  );
}