import { useContext, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import { AdRanksDictionaryDraftItem, IAdRanksDictionaryDraft, IAdRanksDictionaryDraftItem } from './AdRanksDictionaryDraft';
import React from 'react';
import { IDictionaryDraftFilter } from '../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter';
import getDictionaryDraft from '../common/functions/getDictionaryDraft';
import updateDictionaryDraft from '../common/functions/updateDictionaryDraft';
import deleteDictionaryDraft from '../common/functions/deleteDictionaryDraft';
import publishDictionaryDraft from '../common/functions/publishDictionaryDraft';
import { DraftItemStateEnum } from '../../../common/dictionary-draft-models/DraftItemStateEnum';
import AdRanksEditorNode from './ad-ranks-editor-node/AdRanksEditorNode';
import AdRankEditForm from './ad-rank-edit-form/AdRankEditForm';
import useBeforeUnload from '../../../../../hooks/useBeforeUnload';
import DictionaryEditorWrapper from '../../../common/dictionary-editor-wrapper/DictionaryEditorWrapper';
import { IPublishedDictionary } from '../../../common/dictionary-draft-models/IPublishedDictionary';
import { useAbortController } from '../../../../../hooks/useAbortController';
import { EHttpStatusCode } from '../../../../../utils/HttpStatusCodes';
import { AdRanksEditorState, IAdRanksDictionaryItem, IAdRanksEditorState } from './IAdRanksEditorState';



export default function AdRanksEditor() {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const [state, setState] = useState<AdRanksEditorState>();
  useBeforeUnload(state?.isUpdatedInSession);
  const [newItem, setNewItem] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const abortController = useAbortController("AdRanksEditor");
  //--------------------------------------------------------------------------- Get data
  useEffect(() => {
    getDraft(false);
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //---------------------------------------------------------------------------
  const getDraft = (lockIsNeeded: boolean) => {
    console.log("AdRanksEditor: getting draft...")
    setIsLoading(true);
    let controller = abortController.newController("getDictionaryDraft");
    getDictionaryDraft(
      instance, "AdRanks", lockIsNeeded, appAuthContext.config, controller.signal
    ).then(data => {
      const dictionaryDraft = data as IAdRanksDictionaryDraft;
      if (dictionaryDraft.locker)
        dictionaryDraft.locker.errorPrefix = `AdRanks dictionary`;
      const editorState = new AdRanksEditorState({
        dictionaryDraft: dictionaryDraft,
        editMode: !!(dictionaryDraft.locker && dictionaryDraft.locker.statusCode == EHttpStatusCode.OK)
      });
      !controller.aborted && setState(editorState);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onFilterUpdate = (updatedFilter: IDictionaryDraftFilter) => {
    setState(new AdRanksEditorState(undefined, {
      state: state as IAdRanksEditorState,
      spread: { filter: updatedFilter }
    }));
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedDraftItem: AdRanksDictionaryDraftItem) => {
    if (state?.draft.items) {
      const updatedItem = JSON.parse(JSON.stringify(updatedDraftItem as IAdRanksDictionaryDraftItem));
      let updatedItems: IAdRanksDictionaryDraftItem[] = state?.draft.items;
      updatedItems = updatedItems.map(item => item.id == updatedDraftItem.id ? updatedItem : item);
      setState(new AdRanksEditorState(undefined, {
        state: state as IAdRanksEditorState,
        spread: {
          draft: {
            ...state?.draft,
            items: updatedItems
          }
        }
      }));
    } else console.error("Items are being updated, but state contains empty list of items");
  };
  //---------------------------------------------------------------------------
  const onSaveDraft = () => {
    if (!state?.draft) {
      console.error("Cannot save dictonary draft: state is not set");
      return;
    }
    setIsLoading(true);
    let controller = abortController.newController("updateDictionaryDraft");
    updateDictionaryDraft(
      instance,
      state.draft,
      appAuthContext.config,
      controller.signal
    ).then(dictionaryDraft => {
      !controller.aborted && setState(new AdRanksEditorState({
        dictionaryDraft: dictionaryDraft,
        editMode: true
      }));
    }).catch(error => console.error(error)
    ).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onDeleteDraft = () => {
    setIsLoading(true);
    let controller = abortController.newController("deleteDictionaryDraft");
    deleteDictionaryDraft(
      instance, "AdRanks", appAuthContext.config, controller.signal
    ).then(dictionaryDraft => {
      !controller.aborted && setState(new AdRanksEditorState({
        dictionaryDraft: dictionaryDraft,
        editMode: false
      }));
    })
      .catch(error => console.error(error))
      .finally(() => {
        !controller.aborted && setIsLoading(false);
      });
  };
  //---------------------------------------------------------------------------
  const onPublishDraft = () => {
    setIsLoading(true);
    let controller = abortController.newController("deleteDictionaryDraft");
    publishDictionaryDraft(
      instance, "AdRanks", appAuthContext.config, controller.signal
    ).then(dictionaryDraft => {
      !controller.aborted && setState(new AdRanksEditorState({
        dictionaryDraft: dictionaryDraft,
        editMode: false
      }));
    })
      .catch(error => console.error(error))
      .finally(() => {
        !controller.aborted && setIsLoading(false);
      });
  };
  //---------------------------------------------------------------------------
  const onAddNewItem = (newDraftItem: AdRanksDictionaryDraftItem) => {
    setNewItem(false);
    const updatedItems = (state?.draft.items ? state.draft.items : []).concat(newDraftItem);
    console.log(updatedItems)
    setState(new AdRanksEditorState(undefined, {
      state: state as IAdRanksEditorState,
      spread: {
        draft: {
          ...state?.draft as IAdRanksDictionaryDraft,
          items: updatedItems
        }
      }
    }));
  };
  //--------------------------------------------------------------------------- Delete new item
  const onHardDeleteItem = (deletedItemId: string) => {
    if (state?.draft.items) {
      const updatedItems = state?.draft.items?.filter(item => item.id !== deletedItemId);
      setState(new AdRanksEditorState(undefined, {
        state: state as IAdRanksEditorState,
        spread: {
          draft: {
            ...state?.draft as IAdRanksDictionaryDraft,
            items: updatedItems
          }
        }
      }));
    } else console.error("Items are being updated, but state contains empty list of items");
  };
  //---------------------------------------------------------------------------
  const onEnableEditMode = () => {
    getDraft(true);
  };
  //---------------------------------------------------------------------------
  const onDisableEditMode = () => {
    setState(new AdRanksEditorState(undefined, {
      state: state as IAdRanksEditorState,
      spread: {
        draft: state?.draftSessionInitial,
        editModeIsEnabled: false
      }
    }));
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "optionDisableEditMode":
        onDisableEditMode();
        break;
      case "optionSave":
        onSaveDraft();
        break;
      case "optionDraftDelete":
        onDeleteDraft();
        break;
      case "optionPublish":
        onPublishDraft();
        break;
      case "optionAdd":
        setNewItem(true);
        break;
      default:
        break;
    };
  };
  //---------------------------------------------------------------------------
  //console.log(state?.draft.items)
  const filteredDictionaryItems = state?.draft.items?.filter(item => {
    let resultText = true;
    let resultState = true;
    //-----------------------------------------------------------------------
    // Filter by text
    if (state?.filter.text) {
      const searchString = state.filter.text.toLocaleLowerCase();
      const name = item.name ? item.name : "";
      resultText = item.id.toLocaleLowerCase().includes(searchString) || (name.toLocaleLowerCase().includes(searchString));
    };
    //-----------------------------------------------------------------------
    // Filter by state
    if (state && state.filter.draftState !== DraftItemStateEnum.All) {
      resultState = (item.draftState & state.filter.draftState) != DraftItemStateEnum.None;
      //console.log(item.id, state.filter.draftState, item.draftState)
    };
    //-----------------------------------------------------------------------
    return resultText && resultState;
  });
  //console.log(filteredDictionaryItems)
  const dictionaryElements = filteredDictionaryItems?.map(item => (
    <AdRanksEditorNode
      key={item.id}
      stateGroups={state?.filter.stateGroups}
      data={{
        ...item,
        publishedVersion:
          (state?.draft.publishedVersion as IPublishedDictionary<IAdRanksDictionaryItem>)?.items.find(di => di.id == item.id)
      }}
      editModeIsEnabled={state?.editModeIsEnabled}
      onUpdate={onUpdate}
      onDelete={() => onHardDeleteItem(item.id)}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <DictionaryEditorWrapper
      setup={{
        mode: "Editor",
        role: 'Administrator',
        entityType: "Dictionary"
      }}
      state={state}
      isLoading={isLoading}
      onFilterUpdate={onFilterUpdate}
      onEnterEditMode={onEnableEditMode}
      onOptionSelect={onOptionSelect}
    >
      
      {dictionaryElements}

      {newItem &&
        <AdRankEditForm
          onUpdate={onAddNewItem}
          onClose={() => setNewItem(false)}
        />}

    </DictionaryEditorWrapper>
  );
}