import { IUiDirectoryEditorNode } from "../../../../common/directory-node-wrapper/IUiDirectoryTranslatorNode";
import { IUiEditformDictionaryItem, tmpUiEditformDictionaryItem } from "../common-dictionary-item-editform/IUiEditformDictionaryItem";

export interface IUiDictionaryEditorNode extends IUiDirectoryEditorNode {
  editForm: IUiEditformDictionaryItem;
}

export const tmpUiCommonDictionaryEditorNode: IUiDictionaryEditorNode = {
  editForm: tmpUiEditformDictionaryItem,
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionEdit",
      directoryId: "",
      elementType: "",
      caption: { en: "Edit" },
      hint: undefined
    }, {
      id: "OptionDelete",
      directoryId: "",
      elementType: "",
      caption: { en: "Delete" },
      hint: undefined
    }, {
      id: "OptionAdd",
      directoryId: "",
      elementType: "",
      caption: { en: "Add sub-item" },
      hint: undefined
    }, {
      id: "MoveUp",
      directoryId: "",
      elementType: "",
      caption: { en: "Move Up" },
      hint: undefined
    }, {
      id: "MoveDown",
      directoryId: "",
      elementType: "",
      caption: { en: "Move Down" },
      hint: undefined
    }]
  }
}