import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { apiBasePath, pathSaveSubDictionaryDraft } from "../../../../../utils/apiPathConstant";
import { CustomDictionaryDraft } from "../../dictionaries/common-dictionary/IDictionaryDraft";
import { stringFormatter } from "../../../../../utils/common";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  draft?: CustomDictionaryDraft;
  isNew: boolean;
}

export async function saveCustomDictionaryDraftAsync(props: IProps): Promise<CustomDictionaryDraft> {
  if (!props.authConfig) throw new Error("saveDictionaryDraftAsync: auth config is not provided");
  if (!props.draft) throw new Error("saveDictionaryDraftAsync: draft is not provided");
  //---------------------------------------------------------------------------
  let apiUri = stringFormatter(pathSaveSubDictionaryDraft, [props.draft.id]);
  if (props.isNew) {
    apiUri = `${apiUri}?isNew=true`;
  };
  //---------------------------------------------------------------------------
  let draftToSave = props.draft;
  if (props.draft.locker) {
    draftToSave = props.draft.getUpdated({ locker: undefined });
  };
  //---------------------------------------------------------------------------
  const response = await apiPostPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${apiUri}`,
    draftToSave,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "saveDictionaryDraftAsync", props.abortSignal?.aborted);
  const draft = new CustomDictionaryDraft().initialize(response?.content);
  return draft;
}