import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathWidgetDirectoryDraftGet } from "../../../../../utils/apiPathConstant";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";
import { stringFormatter } from "../../../../../utils/common";
import { IDirectoryTranslationDraft } from "./DirectoryTranslatorState";

interface IProps {
  msalInstanse: IPublicClientApplication;
  authConfig?: IAuthConfig;
  directoryId: string;
  localeId: string;
  lockIsNeeded: boolean;
  abortSignal?: AbortSignal;
}

export async function getWidgetDirectoryDraft(props: IProps): Promise<IDirectoryTranslationDraft> {
  if (!props.authConfig) throw new Error("getWidgetDirectoryDraft: auth context is not provided");
  //---------------------------------------------------------------------------
  let uri = `${apiBasePath}${stringFormatter(pathWidgetDirectoryDraftGet, [props.directoryId, props.localeId])}`;
  if (props.lockIsNeeded) uri += "?lockIsNeeded=true";
  const response = await apiGetPrivate(
    props.msalInstanse,
    props.authConfig,
    uri,
    props.abortSignal
  );
  checkResponse(response, "getWidgetDirectoryDraft");
  return response?.content as IDirectoryTranslationDraft;
}