import { useContext, useEffect, useRef, useState } from 'react';
import InputLocationSearch from '../input-location-search/InputLocationSearch';
import styles from './MapItself.module.css';
import BusinessMarkers, { IBusinessLocationUpdate, IBusinessMarker } from '../business-markers/BusinessMarkers';
import GetCaption from '../../functions/GetCaption';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import CurrentLocationMarkerAdvanced from '../current-location-marker/CurrentLocationMarkerAdvanced';
import { tmpUiGoogleMap } from '../google-map/IUiGoogleMap';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';

// How to detect if map is in full-screen mode: https://stackoverflow.com/questions/39620850/google-maps-api-v3-how-to-detect-when-map-changes-to-full-screen-mode

export interface IMapArea {
  bounds?: google.maps.LatLngBoundsLiteral;
  //-----------------------------
  fitBusinessMarkers?: boolean;
  //-----------------------------
  center?: google.maps.LatLngLiteral;
  zoom?: number;
  //-----------------------------
  name?: string;
  //-----------------------------
  address?: string;
}

export interface IExternalAutocomplete {
  inputRef: React.RefObject<HTMLInputElement>;
  options: google.maps.places.AutocompleteOptions;
  onMapAreaSelectCallback: (response: IMapArea) => void;
}

interface IProps {
  businesses?: IBusinessMarker[];
  currentLocation?: google.maps.LatLngLiteral | true;
  isSearchEnabled?: boolean;
  defaultMapArea?: IMapArea; // !!!! if default place is provided, listen to zoom and center changes and pass updates to the provided callback onDefaultPlaceChange
  externalAutocomplete?: IExternalAutocomplete;
  onMapClick?: (position: google.maps.LatLngLiteral) => void;
  onZoomChange?: (updatedZoom: number) => void;
  onMapAreaChange?: (updatedMapArea: IMapArea) => void;
  onBusinessLocationUpdate?: (businessLocationUpdate: IBusinessLocationUpdate) => void;
  onBusinessMarkerClick?: (businessId: string, locationId: string) => void;
}

export default function MapItself(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { appAuthContext, appAuthContextDispatch } = useContext(AppAuthContextStore);
  const [googleMap, setGoogleMap] = useState<google.maps.Map>();
  const mapDivRef = useRef<HTMLDivElement>(null);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();
  const [autocompleteExt, setAutocompleteExt] = useState<google.maps.places.Autocomplete>();
  const clickListener = useRef<google.maps.MapsEventListener>();
  const autocompleteExtIsReady = useRef(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const defaultCenter: google.maps.LatLngLiteral = { lng: 0, lat: 0 };
  const defaultZoom: number = 1;
  const defaultMapArea = useRef<IMapArea>();
  const boundsRectangle = useRef<google.maps.Rectangle>();
  const userIsActive = useRef(false);
  //--------------------------------------------------------------------------- Consts
  // const options = {
  //   fields: ["formatted_address", "geometry", "name", "place_id", "types"],
  //   strictBounds: false,
  //   types: ["establishment"],
  //   componentRestrictions: { country: [""] },
  // };
  //--------------------------------------------------------------------------- Create map
  useEffect(() => {
    const googleMapOptions: google.maps.MapOptions = {
      mapId: "DEMO_MAP_ID",
      //center: props.center ? props.center : defaultCenter,
      //zoom: props.zoom ? props.zoom : defaultZoom,
      minZoom: defaultZoom,
      disableDefaultUI: false,
      gestureHandling: 'cooperative' // Requires ctrl + zoom the map
      // restriction: {
      //   latLngBounds: { north: 85, south: -85, west: -179, east: 180 },
      //   strictBounds: true
      // }
    };
    const map = new window.google.maps.Map(mapDivRef.current as HTMLElement, googleMapOptions);
    setGoogleMap(map);
    //------------------------------------------------------------------------- on zoom changed
    map.addListener("zoom_changed", () => {
      const currentZoom = map.getZoom();
      //console.log("Zoom :" + currentZoom);
      props.onZoomChange && props.onZoomChange(currentZoom ? currentZoom : 1);
    });
    //------------------------------------------------------------------------- on idle
    map.addListener("idle", onIdleMain);
    //------------------------------------------------------------------------- on bounds changed
    map.addListener("bounds_changed", () => {
      //console.log("bounds_changed:", map.getBounds()?.toJSON())
      props.onMapAreaChange && props.onMapAreaChange({
        ...props.defaultMapArea,
        center: undefined,
        zoom: undefined,
        fitBusinessMarkers: false,
        bounds: map.getBounds()?.toJSON()
      });
    });
    //-------------------------------------------------------------------------
    // map.addListener('mousedown', () => {
    //   console.log("mousedown");
    // });
    // //-------------------------------------------------------------------------
    // map.addListener('mouseup', () => {
    //   console.log("mouseup");
    // });
    //-------------------------------------------------------------------------
    map.addListener('drag', () => {
      //console.log("drag");
    });
    //-------------------------------------------------------------------------
    map.addListener('dragend', () => {
      //console.log("dragend");
    });
    //-------------------------------------------------------------------------
    map.addListener('dragstart', () => {
      userIsActive.current = true;
      //console.log("dragstart");
    });
    // map.addListener('DOMMouseScroll', () => {
    //   console.log("DOMMouseScroll");
    // });
    //-------------------------------------------------------------------------
    mapDivRef.current?.addEventListener('pointermove', () => {
      //console.log("pointermove");
    }, true);
    //-------------------------------------------------------------------------
    mapDivRef.current?.addEventListener('pointerup', () => {
      //console.log("pointerup");
    }, true);
    //-------------------------------------------------------------------------
    mapDivRef.current?.addEventListener('mousewheel', () => {
      userIsActive.current = true;
      //console.log("mousewheel");
    }, true);
    //------------------------------------------------------------------------- clean up
    return (() => {
      if (googleMap)
        google.maps.event.clearInstanceListeners(googleMap);
    })
  }, []);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!googleMap) return;
    if (clickListener.current) clickListener.current.remove();
    clickListener.current = googleMap.addListener("click", (e: google.maps.MapMouseEvent) => {
      e.domEvent.preventDefault();
      e.domEvent.stopPropagation();
      if (!e.latLng) return;
      let zoom = googleMap.getZoom();
      console.log("map.onClick:", e.latLng.lat(), e.latLng.lng(), zoom);
      props.onMapClick && props.onMapClick({
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      });
    });
  }, [googleMap, props.onMapClick]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!googleMap) return;
    google.maps.event.clearListeners(googleMap, "idle");
    googleMap.addListener("idle", onIdleMain);
    if (props.onMapAreaChange) {
      googleMap.addListener("idle", onIdleUser);
    }
  }, [props.onMapAreaChange]);
  //--------------------------------------------------------------------------- Check map capabilities
  useEffect(() => {
    if (googleMap) {
      googleMap.addListener('mapcapabilities_changed', () => {
        const mapCapabilities = googleMap.getMapCapabilities();
        if (!mapCapabilities.isAdvancedMarkersAvailable) {
          // Advanced markers are *not* available, add a fallback.
          //console.log("Advanced markers are *not* available")
        };
      });
    };
  }, [googleMap]);
  //--------------------------------------------------------------------------- Check default map area prop
  // useEffect(() => {
  //   if (!googleMap) return;
  //   if (props.defaultMapArea) {
  //     //-----------------------------------------------------------------------
  //     if (!defaultMapArea.current) {
  //       // It's a first render
  //       console.log('map: first render', props.defaultMapArea)
  //       // We need to control changes to the default area, so save a copy
  //       defaultMapArea.current = JSON.parse(JSON.stringify(props.defaultMapArea));
  //     } else {
  //       // It's not a first render, but default area is changed
  //       console.log('map: not a first render')

  //     };
  //     /* //-----------------------------------------------------------------------
  //     // If default area is provided
  //     //console.log('new default area:', props.defaultMapArea);
  //     if (props.defaultMapArea.center) {
  //       //console.log('Map.NewDefaultArea: set center and zoom');
  //       googleMap.setCenter(props.defaultMapArea.center);
  //       props.defaultMapArea.zoom && googleMap.setZoom(props.defaultMapArea.zoom);
  //     } else if (props.defaultMapArea.bounds) {
  //       //console.log('Map.NewDefaultArea: fitBounds');
  //       googleMap.fitBounds(props.defaultMapArea.bounds, 0);
  //     } else {
  //       //console.log('Map.NewDefaultArea: do nothing');
  //     }; */
  //   };
  // }, [googleMap, props.defaultMapArea]);
  //--------------------------------------------------------------------------- Check if click needs to be handled

  //--------------------------------------------------------------------------- Set up internal autocomplete
  useEffect(() => {
    //console.log('setting up 1...', autocomplete)
    if (!autocomplete) {
      //console.log('setting up 2...', searchInputRef.current)
      if (searchInputRef.current) {
        //console.log('setting up IAC...')
        const ac = new window.google.maps.places.Autocomplete(searchInputRef.current, {
          //types: ["establishment"],
          fields: ["geometry", "name", "place_id", "formatted_address"]
        });
        //console.log(ac)
        setAutocomplete(ac);
      };
    };
  }, [autocomplete, searchInputRef.current]);
  //--------------------------------------------------------------------------- Handle internal autocomplete events
  useEffect(() => {
    //console.log('setting up autocomplete events...', googleMap, autocomplete)
    if (googleMap && autocomplete) {
      autocomplete.bindTo('bounds', googleMap);
      autocomplete.addListener('place_changed', () => {
        const selectedPlace = autocomplete.getPlace();
        //---------------------------------------------------------------------
        if (!selectedPlace.geometry || !selectedPlace.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          alert("this location not available");
          return;
        };
        //---------------------------------------------------------------------
        // If the place has a geometry, then present it on a map.
        if (selectedPlace.geometry.viewport) {
          console.log("Map.Fitting bounds by place");
          googleMap.fitBounds(selectedPlace.geometry.viewport);
        }
        else {
          console.log("Map.Setting center and zoom by place");
          googleMap.setCenter(selectedPlace.geometry.location);
          googleMap.setZoom(17);
        };
        //const latitude = selectedPlace.geometry.location.lat();
        //const longitude = selectedPlace.geometry.location.lng();
        //---------------------------------------------------------------------
        //console.log(props.onPlaceSelected)
        const selectedArea = getMapArea(selectedPlace);
        props.onMapAreaChange && props.onMapAreaChange(selectedArea);
      });
    };
  }, [googleMap, autocomplete]);
  //--------------------------------------------------------------------------- Set up external autocomplete
  useEffect(() => {
    //console.log('setting up 1...', autocompleteExt)
    if (!autocompleteExt) {
      //console.log('setting up 2...', props.externalAutocomplete?.inputRef?.current)
      if (props.externalAutocomplete?.inputRef?.current && props.externalAutocomplete.options) {
        //console.log('setting up EAC...')
        const ac = new window.google.maps.places.Autocomplete(
          props.externalAutocomplete?.inputRef?.current,
          props.externalAutocomplete.options
        );
        //console.log(ac)
        setAutocompleteExt(ac);
      };
    };
  }, [autocompleteExt, props.externalAutocomplete?.inputRef, props.externalAutocomplete?.options]);
  //--------------------------------------------------------------------------- Handle external autocomplete events
  useEffect(() => {
    if (googleMap && autocompleteExt && !autocompleteExtIsReady.current) {
      autocompleteExt.bindTo('bounds', googleMap);
      autocompleteExt.addListener('place_changed', () => {
        const selectedPlace = autocompleteExt.getPlace();
        const selectedArea = getMapArea(selectedPlace);
        //---------------------------------------------------------------------
        if (props.externalAutocomplete?.onMapAreaSelectCallback) {
          props.externalAutocomplete.onMapAreaSelectCallback(selectedArea);
        };
        //---------------------------------------------------------------------
        autocompleteExtIsReady.current = true;
      });
    };
  }, [googleMap, autocompleteExt, autocompleteExtIsReady.current]);
  //---------------------------------------------------------------------------
  const onIdleMain = () => {
    // Check changes made to default map area
    //console.log('Map event: idle(main) defaultMapArea:', defaultMapArea.current);
    if (!googleMap || !defaultMapArea.current) {
      return;
    }
    const currentCenter = googleMap?.getCenter()?.toJSON();
    const currentZoom = googleMap?.getZoom();
    //console.log('idle.zoom:', currentZoom);
    if (!currentCenter || !currentZoom)
      return;
    //---------------------------------------------------------------------
    // Check if it's default values and do nothing because it's a first map render
    if (currentCenter.lat === defaultCenter.lat && currentZoom == defaultZoom)
      return;
    //---------------------------------------------------------------------
    // Check if it's a first change to the default area
    if (defaultMapArea.current.center == undefined) {
      // It's a first map are render, so just update center and zoom
      defaultMapArea.current.center = currentCenter;
      defaultMapArea.current.zoom = currentZoom;
    }
    else if (!locationsAreEqual(defaultMapArea.current.center, currentCenter) || defaultMapArea.current.zoom !== currentZoom) {
      // It's not a first change, meaning user made these changes
      console.log('user changed smth', currentZoom)
      //console.log('map area: ', defaultMapArea.current.center, defaultMapArea.current.zoom)
      //console.log('current: ', currentCenter, currentZoom)
      let isUpdated = false;
      //---------------------------------------------------------------------
      if (defaultMapArea.current.center !== currentCenter) {
        defaultMapArea.current.center = currentCenter;
        isUpdated = true;
      };
      //---------------------------------------------------------------------
      if (defaultMapArea.current.zoom !== currentZoom) {
        defaultMapArea.current.zoom = currentZoom;
        isUpdated = true;
      };
      //---------------------------------------------------------------------
      if (isUpdated) {
        const mapAreaName = GetCaption(appUiContext, tmpUiGoogleMap.captionMapArea.id, tmpUiGoogleMap.captionMapArea.caption);
        defaultMapArea.current.name = mapAreaName;
        defaultMapArea.current.address = mapAreaName;
        //console.log(props.defaultMapArea, defaultMapArea.current)
        //props.onMapAreaChange && props.onMapAreaChange(defaultMapArea.current);
      };
    };
  }
  //---------------------------------------------------------------------------
  const onIdleUser = () => {
    //console.log("Map.idle(user)", props.onMapAreaChange, userIsActive.current);
    if (!googleMap || !props.onMapAreaChange || !userIsActive.current) {
      return;
    }
    userIsActive.current = false;
    let area: IMapArea = {
      zoom: googleMap.getZoom(),
      center: googleMap.getCenter()?.toJSON(),
      bounds: googleMap.getBounds()?.toJSON(),
    }
    //console.log("Map.idle(user).onMapAreaChange:", area);
    props.onMapAreaChange(area);
  };
  //---------------------------------------------------------------------------
  const setupMapViewport = (map?: google.maps.Map, config?: IMapArea) => {
    if (!map || !config) return;
    boundsRectangle.current?.setMap(null);
    if (config.bounds) {
      if (props.onBusinessLocationUpdate) {
        // If it's a editable mode, show bounds rectangle
        boundsRectangle.current = new google.maps.Rectangle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.2,
          map: googleMap,
          bounds: config.bounds
        });
      };
      boundsRectangle.current?.setBounds(config.bounds);
      //-----------------------------------------------------------------------
      // Custom bounds are provided. Set padding to 0 because we don't need extra padding here
      map.fitBounds(config.bounds, 0);
    } else if (config.fitBusinessMarkers && props.businesses) {
      if (props.businesses.length == 1) {
        // If there is only one business, center the map on it
        // (because fitBounds will zoom in too much)
        const mapCenter = props.businesses[0].geolocation?.latLngLiteral;
        mapCenter && map.setCenter(mapCenter);
        map.setZoom(7); // Question: how to determine proper default zoom based on the location?
      } else if (props.businesses.length > 1) {
        // config.fitBusinessMarkers = true and business markers are provided
        const bounds = new google.maps.LatLngBounds();
        props.businesses.forEach(business => {
          if (business.geolocation) {
            bounds.extend(business.geolocation.latLngLiteral);
          };
        });
        map.fitBounds(bounds);
      };
    } else if (config.center) {
      //-----------------------------------------------------------------------
      // Map center is provided
      map.setCenter(config.center);
      config.zoom && map.setZoom(config.zoom);
    };
  };
  //---------------------------------------------------------------------------
  const locationsAreEqual = (loc1: google.maps.LatLngLiteral, loc2: google.maps.LatLngLiteral) => {
    return (loc1.lat == loc2.lat && loc1.lng == loc2.lng);
  };
  //---------------------------------------------------------------------------
  const getMapArea = (placeResult: google.maps.places.PlaceResult): IMapArea => {
    //const location = placeResult.geometry?.location;
    //const viewport = placeResult.geometry?.viewport;
    //console.log(location, viewport, center)
    //-------------------------------------------------------------------------
    const updatedArea: IMapArea = {
      bounds: placeResult.geometry?.viewport?.toJSON(),
      name: placeResult.name,
      address: placeResult.formatted_address
    }
    return updatedArea;
  };
  //---------------------------------------------------------------------------
  setupMapViewport(googleMap, props.defaultMapArea);
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      {/* Search input */}
      <InputLocationSearch
        elementRef={searchInputRef}
        ui={tmpUiGoogleMap.inputSearch}
        visible={!!props.isSearchEnabled}
      />

      {/* Map itself */}
      <div
        id="map"
        ref={mapDivRef}
        className={styles.map}>
      </div>

      {/* <button onClick={() => {console.log(googleMap?.getBounds()?.toJSON())}}>Click</button> */}

      {/* Current location marker */}
      {googleMap && props.currentLocation &&
        <CurrentLocationMarkerAdvanced
          map={googleMap}
          position={props.currentLocation}
        />}

      {/* Business markers */}
      {googleMap && props.businesses &&
        <BusinessMarkers
          map={googleMap}
          businessMarkers={props.businesses}
          showIndex={true}
          onLocationUpdate={props.onBusinessLocationUpdate}
          onClick={props.onBusinessMarkerClick}
        />}

    </div>
  );
}