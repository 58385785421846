import { useContext, useEffect, useState } from 'react';
import styles from './Account.module.css';
import { IUiEditFormAccount } from './IUiEditFormAccount';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import React from 'react';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import ConsoleTabLayout from '../../../common/console-layout/console-tab-layout/ConsoleTabLayout';
import ConsoleTabContent from '../../../common/console-layout/console-tab-layout/console-tab-content/ConsoleTabContent';
import { TUiCaption } from '../../../common/captions/TUiCaption';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import ButtonLink from '../../../common/button-link/ButtonLink';
import { useMsal } from '@azure/msal-react';
import { apiBasePath, deleteAccount } from '../../../../utils/apiPathConstant';
import { apiGetPrivate } from '../../../../utils/api';
import { removeUserLastSignIn, removeUserLocally } from '../../../../context/app-auth-context/user';

interface IProps {
  ui?: IUiEditFormAccount;
}

export default function Account(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContextDispatch } = useContext(AppUiContextStore);
  const [isLoading, setIsLoading] = useState(false);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    if (props.ui?.breadcrumb) {
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: props.ui.breadcrumb });
    };
  }, []);
  //---------------------------------------------------------------------------
  const onResetPassword = () => {
    if (appAuthContext.config?.authorities.forgotPassword) {
      instance.loginRedirect({
        scopes: appAuthContext.config.loginScopes,
        authority: appAuthContext.config?.authorities.forgotPassword,
      });
    } else console.error("Forgot password flow is not configured");
  };
  //---------------------------------------------------------------------------
  const onDeleteAccount = () => {
    setIsLoading(true);
    if (appAuthContext.config) {
      const currentUserId = appAuthContext.user?.userIdentity.id;
      if (confirm("Are you sure?") == true) {
        const url = `${apiBasePath}${deleteAccount}`;
        apiGetPrivate(
          instance,
          appAuthContext.config,
          url)
          .then((response) => {
            if (response && response.status === 200) {
              console.log("Sign out option is selected")
              // Clear up user data in local storage
              removeUserLocally(appAuthContext.user?.userIdentity.id);
              removeUserLastSignIn(appAuthContext.user?.userIdentity.id);
              //-------------------------------------------------------------------------
              // Log out
              instance.logoutRedirect({ postLogoutRedirectUri: "/" });
            } else {
              alert("Delete Account failed.");
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => setIsLoading(false));
      } else {
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
      console.error("Could not delete account because auth context is not set");
    };
  };
  //---------------------------------------------------------------------------
  let formCaption: TUiCaption | undefined = undefined;
  if (props.ui) {
    formCaption = {
      id: props.ui.id,
      directoryId: props.ui.directoryId,
      elementType: props.ui.elementType,
      text: props.ui.hint
    };
  };
  const optionChangePassword = props.ui?.menuContent.options.find(option => option.id == "UserAccount.MenuContent.OptionChangePassword");
  const optionDeleteAccount = props.ui?.menuContent.options.find(option => option.id == "UserAccount.MenuContent.OptionDeleteAccount");
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <WikiSpinner show={isLoading} />
      <ConsoleTabLayout
        allowScroll={true}
      >
        <ConsoleTabContent prompt={formCaption}>
          {props.ui &&
            <div className={styles.container}>

              {optionChangePassword && appAuthContext.user?.userIdentity.signInType === 'emailAddress' &&
                <ButtonLink
                  ui={{
                    id: optionChangePassword.id,
                    caption: optionChangePassword.caption,
                    hint: optionChangePassword.hint,
                    iconFile: null,
                    disabled: false,
                    visible: true,
                    canHideCaption: false
                  }}
                  onClick={onResetPassword}
                />}

                {optionDeleteAccount &&
                  <ButtonLink
                    ui={{
                      id: optionDeleteAccount.id,
                      caption: optionDeleteAccount.caption,
                      hint: optionDeleteAccount.hint,
                      iconFile: null,
                      disabled: false,
                      visible: true,
                      canHideCaption: false
                    }}
                    onClick={onDeleteAccount}
                  />}

            </div>}
        </ConsoleTabContent>
      </ConsoleTabLayout>
    </React.Fragment>
  );
}