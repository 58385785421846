import { IDictionaryItemStatus } from "../../../../common/dictionaries/IUiDictionaryItem";
import { IDictionaryDraftItem } from "../../../common/dictionary-draft-models/DictionaryDraftItem";
import { DictionaryEditorDraftItem } from "../common/models/DictionaryEditorDraftItem";

export type EContentUnitType =
  "AdLanguageSet" |
  "AdModule" |
  "ContentParagraph" |
  "ContentText" |
  "ContentImage" |
  "Location" |
  "Contact";

export interface IRejectionReason {
  objectTypes: EContentUnitType[];
  adRanks?: string[]; // applicable to: ad rank IDs
  containsImage?: boolean; // applicable to complex issues only
  containsText?: boolean; // applicable to complex issues only
  //businessTypes: EBusinessType[]; // applicable to: business types
  //adModuleTypes: string[]; // applicable to: ad module types
}

export interface IDictionaryDraftItemRejectionReasons extends IDictionaryDraftItem, IRejectionReason {
  status?: IDictionaryItemStatus;
}

interface IDictionaryDraftItemRejectionReasonsUpdate {
  id?: string;
  name?: string;
  description?: string;
  objectTypes?: EContentUnitType[];
  adRanks?: string[];
  containsImage?: boolean;
  containsText?: boolean;
  status?: IDictionaryItemStatus;
  deleted?: boolean;
}

export class DictionaryDraftItemRejectionReasons extends DictionaryEditorDraftItem implements IDictionaryDraftItemRejectionReasons {
  objectTypes!: EContentUnitType[];
  adRanks?: string[];
  containsImage?: boolean;
  containsText?: boolean;
  status?: IDictionaryItemStatus;
  //---------------------------------------------------------------------------
  constructor(
    source: IDictionaryDraftItemRejectionReasons,
    update?: IDictionaryDraftItemRejectionReasonsUpdate
  ) {
    //const sourceCopy = JSON.parse(JSON.stringify(source));
    //super(sourceCopy);
    super(source);
    Object.assign(this, source, update);
    this.verify();
    this.isValid();
  }
  //---------------------------------------------------------------------------
  verify() {
    // Ad ranks applicable to AdLanguageSet only, so in any other combination adRanks should be cleared
    if (!this.objectTypes.includes("AdLanguageSet")) {
      this.adRanks = undefined;
    } else if (this.objectTypes.length > 1) {
      this.adRanks = undefined;
    };
    // Image and text flags are applicable to complex issues only
    if (!this.objectTypes.includes("AdLanguageSet") && !this.objectTypes.includes("AdModule")) {
      this.containsImage = undefined;
      this.containsText = undefined;
    };
  };
  //---------------------------------------------------------------------------
  isValid(): boolean {
    this.refresh("en");
    //console.log(this.draftState, this.isNoTranslation)
    return !this.isNoTranslation;
  };
}