import { useContext, useEffect, useRef, useState } from 'react';
import PopupDialog from '../../common/popup-v2/popup-dialog/PopupDialog';
import styles from './AdDetailsEditor.module.css';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';
import GetCaption from '../../common/functions/GetCaption';
import { IUiAdDetails } from './IUiAdDetails';
import { IUiOption } from '../../common/options/IUiOption';
import FormOptions from '../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../common/menu/menu-content/TUiMenuContent';
import { EditFormState } from '../../common/edit-form/IEditFormState';
import { AdDetailsValidator, IAdDetailsValidationState } from './AdDetailsValidator';
import { BusinessContext } from '../../../context/business-context/BusinessContextProvider';
import InputText from '../../common/input-v2/input-text/InputText';
import InputsGroupWrapper from '../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import { ScreenType, useAppScreenContext } from '../../../context/app-screen-context/AppScreenProvider';
import CombineStyles from '../../../utils/combineStyles';
import GetHint from '../../common/functions/GetHint';
import { IAdLanguageSet } from '../IAdLanguageSet';

export interface IAdDetails {
  title?: string;
  description?: string;
}

interface IProps {
  ui: IUiAdDetails;
  onClose: () => void;
}

export default function AdDetailsEditor(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const [state, setState] = useState<EditFormState<IAdDetails>>();
  const [validationState, setValidationState] = useState<IAdDetailsValidationState>();
  const formValidator = useRef<AdDetailsValidator>();
  const adLanguageSet = businessContext.adLanguageSetState?.adLanguageSet;
  if (!adLanguageSet) return null;
  //---------------------------------------------------------------------------
  useEffect(() => {
    const state = new EditFormState<IAdDetails>({
      object: {
        title: adLanguageSet.name,
        description: adLanguageSet.description
      },
      isNew: false,
      isValid: false
    });
    setState(state);
    //-------------------------------------------------------------------------
    if (formValidator.current) return;
    formValidator.current = new AdDetailsValidator(props.ui);
    const validationState = formValidator.current.initialize(state.object);
    setValidationState(validationState);
  }, []);
  //---------------------------------------------------------------------------
  const onTitleUpdate = (updatedValue: string) => {
    if (!state?.object) return;
    if (!validationState) return;
    const updatedData: IAdDetails = {
      ...state.object,
      title: updatedValue
    };
    const updatedState = new EditFormState<IAdDetails>(state, {
      object: updatedData,
      isUpdated: true,
      isValid: validationState.isValid
    });
    setState(updatedState);
    setValidationState(formValidator.current?.validateName(updatedValue, validationState));
  };
  //---------------------------------------------------------------------------
  const onDescriptionUpdate = (updatedValue: string) => {
    if (!state?.object) return;
    if (!validationState) return;
    const updatedData: IAdDetails = {
      ...state.object,
      description: updatedValue
    };
    const updatedState = new EditFormState<IAdDetails>(state, {
      object: updatedData,
      isUpdated: true,
      isValid: validationState.isValid
    });
    setState(updatedState);
    setValidationState(formValidator.current?.validateDescription(updatedValue, validationState));
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    if (!state?.object) return;
    if (!validationState?.isValid) return;
    let updatedAdModules = adLanguageSet.adModules;
    if (!updatedAdModules) return;
    //-------------------------------------------------------------------------
    // Update: adLanguageSet, search module, homepage photoblock
    const titleUnit = adLanguageSet.getTitleUnit();
    titleUnit.text = state.object.title;
    const descriptionUnit = adLanguageSet.getDescriptionUnit();
    descriptionUnit.text = state.object.description;
    //-------------------------------------------------------------------------
    // Search results module
    const searchModuleToEditData = adLanguageSet.getSearchResultsModuleToEdit();
    if (searchModuleToEditData) {
      const searchModuleToEdit = searchModuleToEditData.adModule;
      const id = searchModuleToEditData?.sourceAdModuleId ? searchModuleToEditData.sourceAdModuleId : searchModuleToEdit.id;
      searchModuleToEdit.header = titleUnit;
      searchModuleToEdit.description = descriptionUnit;
      updatedAdModules = updatedAdModules.map(adModule => adModule.id == id ? searchModuleToEdit : adModule);
    };
    //-------------------------------------------------------------------------
    // Homepage banner module
    const homepageModuleToEditData = adLanguageSet.getHomepageBannerToEdit();
    if (homepageModuleToEditData) {
      const homepageModuleToEdit = homepageModuleToEditData.adModule;
      const id = homepageModuleToEditData?.sourceAdModuleId ? homepageModuleToEditData.sourceAdModuleId : homepageModuleToEdit.id;
      homepageModuleToEdit.header = titleUnit;
      homepageModuleToEdit.description = descriptionUnit;
      updatedAdModules = updatedAdModules.map(adModule => adModule.id == id ? homepageModuleToEdit : adModule);
    };
    //-------------------------------------------------------------------------
    const updatedAdLanguageSet: IAdLanguageSet = {
      ...adLanguageSet,
      name: titleUnit.text,
      description: descriptionUnit.text,
      adModules: updatedAdModules
    };
    businessContextDispatch({
      type: "UpdateAdLanguageSet",
      adLanguageSet: updatedAdLanguageSet,
      isValid: validationState?.isValid
    });
    props.onClose();
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionOk":
        onConfirm();
        break;
      case "OptionCancel":
        props.onClose();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionOk":
        const isDisabled = !validationState?.isValid || !state?.isUpdated;
        updatedOptions.push({
          ...option,
          iconId: "check",
          isDisabled: isDisabled,
          isDefault: !isDisabled
        });
        break;
      case "OptionCancel":
        updatedOptions.push({
          ...option,
          iconId: "close"
        });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id={"AdDetailsEditor"}
      header={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      isModal={true}
      isDraggable={false}
      toolbarContent={formOptions}
      onClose={props.onClose}
    >
      <div
        className={CombineStyles([
          styles.container,
          screenType === ScreenType.Desktop ? styles.desktop : ''
        ])}>

        <div className={styles.prompt}>
          {GetHint(appUiContext, props.ui.id, props.ui.hint)}
        </div>

        <InputsGroupWrapper>

          {/* Ad Title */}
          <InputText
            ui={{ ...props.ui.inputTitle, disabled: adLanguageSet.status.isReadonly }}
            data={state?.object?.title}
            validationState={validationState?.title}
            onUpdate={onTitleUpdate}
          />

          {/* Business short description */}
          <InputText
            ui={{ ...props.ui.inputDescription, disabled: adLanguageSet.status.isReadonly }}
            data={state?.object?.description}
            validationState={validationState?.description}
            onUpdate={onDescriptionUpdate}
          />

        </InputsGroupWrapper>
      </div>
    </PopupDialog>
  );
}