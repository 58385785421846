import CombineStyles from '../../../../../utils/combineStyles';
import { IWizardStep } from '../../IWizardStep';
import styles from './WizardProgressItem.module.css';
import { ScreenType, useAppScreenContext } from '../../../../../context/app-screen-context/AppScreenProvider';
import Icon from '../../../../common/icon/Icon';
import { IUiWizardStepsDictionaryItem } from '../../IUiBusinessWizardV2';
import { useContext } from 'react';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../../common/functions/GetName';
import GetDescription from '../../../../common/functions/GetDescription';
import { EWizardStep, WizardState } from '../../IWizardState';

interface IProps {
  ui: IUiWizardStepsDictionaryItem;
  wizardState: WizardState;
  step: IWizardStep;
  isSelectable: boolean;
  isSelected: boolean;
  selectedDivRef?: React.RefObject<HTMLDivElement>;
  mode: ScreenType;
  onSelect: (stepId: EWizardStep) => void;
}

export default function WizardProgressItem(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const isMobile = props.mode == ScreenType.Mobile;
  let title = GetDescription(appUiContext, props.ui.id, props.ui.description);
  let titleAddition;
  //---------------------------------------------------------------------------
  const getCompletedCaption = () => {
    switch (props.step.stepId) {
      case "StepLocale":
        const localeItem = appUiContext.dictionaries?.supportedLocales?.find(item => item.id == props.wizardState.data.localeId);
        return GetName(appUiContext, props.wizardState.data.localeId, localeItem?.name);
      case "StepAdRank":
        const adRankItem = appUiContext.dictionaries?.adRanks?.find(item => item.id == props.wizardState.data.adRankId);
        return GetName(appUiContext, props.wizardState.data.localeId, adRankItem?.name);
      case "StepName":
        return props.wizardState.data.name;
      case "StepDescription":
        return props.wizardState.data.description;
      case "StepLogo":
        return props.wizardState.data.logo ? "Image" : "Default";
      case "StepTags":
        return props.wizardState.data.tags.length;
      case "StepLocation":
        return props.wizardState.data.locationData?.locations.length;
      case "StepContacts":
        return props.wizardState.data.contacts.length;
      case "StepBackgroundImage":
        return props.wizardState.data.backgroundImage ? "Image" : "No Image";
      default:
        //var unicodeCharacter = "&#" + unicodeNumber + ";"; 0252 - checkmark
        return "Ok";
    };
  };
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (isMobile) return;
    if (!props.isSelectable) return;
    props.onSelect(props.step.stepId);
  };
  //---------------------------------------------------------------------------
  if (props.step.isCompleted) {
    titleAddition = getCompletedCaption();
    title = titleAddition ? titleAddition.toString() : title;
  };
  //---------------------------------------------------------------------------
  return (
    <div
      ref={props.selectedDivRef}
      title={title}
      className={CombineStyles([
        styles.container,
        isMobile ? styles.mobile : '',
        props.isSelected ? styles.selected : '',
        props.isSelectable ? styles.selectable : '',
        (props.step.isCompleted || props.isSelected) ? '' : styles.disabled
      ])}
      onClick={onSelect}
    >

      {/* Connecting line */}
      {!props.step.isLastDisplayed &&
        <div className={CombineStyles([
          styles.line,
          isMobile ? styles.mobile : styles.desktop
        ])} />}

      {/* Box with step number inside */}
      <div className={CombineStyles([
        styles.number,
        isMobile ? styles.mobile : '',
        props.isSelected ? styles.selected : '',
      ])}>
        {(props.step.isLastDisplayed && !props.step.isLast) || (props.step.isFirstDisplayed && !props.step.isFirst) ?
          <Icon imageName="meatballsButton" />
          :
          <span className={styles.index}>
            {props.step.index + 1}
          </span>}
      </div>

      {/* Step caption displayed only on desktop */}
      {!isMobile &&
        <div className={styles.content}>
          <div className={CombineStyles([
            styles.title,
            props.isSelected ? styles.selected : ''
          ])}>

            <span>
              {GetName(appUiContext, props.step.stepId, props.ui.name)}
            </span>

            {props.step.isCompleted &&
              <span>
                {`: `}
                <span className={CombineStyles([
                  styles.value,
                  props.isSelected ? styles.selected : ''
                ])}>
                  {getCompletedCaption()}
                </span>
              </span>}

          </div>
        </div>}

    </div>
  );
}