import { TUiBreadcrumb } from "../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { IDictionaryDraft } from "../../../common/dictionary-draft-models/DictionaryDraft";
import { IPublishedDictionary } from "../../../common/dictionary-draft-models/IPublishedDictionary";
import { IDictionaryEditorState } from "../../../common/dictionary-editor-models/IDictionaryEditorState";
import { IDictionaryDraftFilter } from "../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter";
import { DictionaryDraftItemRejectionReasons, IDictionaryDraftItemRejectionReasons } from "./DictionaryDraftItemRejectionReasons";

export interface IDictionaryDraftRejectionReasons extends IDictionaryDraft<DictionaryDraftItemRejectionReasons> {
}

export interface IDictionaryEditorStateRejectionReasons extends IDictionaryEditorState<IDictionaryDraftRejectionReasons> {
}

interface IDictionaryEditorStateRejectionReasonsUpdate {
  draft?: IDictionaryDraftRejectionReasons;
  editModeIsEnabled?: boolean;
  filter?: IDictionaryDraftFilter;
}

export class DictionaryEditorStateRejectionReasons implements IDictionaryEditorStateRejectionReasons {
  basePath!: string;
  numberOfApprovals!: number;
  filter!: IDictionaryDraftFilter;
  editModeIsEnabled!: boolean;
  sourceLocale?: string | undefined;
  breadcrumb?: TUiBreadcrumb | undefined;
  //-----------------------------------
  draft!: IDictionaryDraftRejectionReasons;
  draftSessionInitial!: IDictionaryDraftRejectionReasons;
  //-----------------------------------
  isUpdatedInSession!: boolean;
  isUpdated!: boolean;
  isValid!: boolean;
  isReadyToPublish!: boolean;
  //---------------------------------------------------------------------------
  constructor(
    state: IDictionaryEditorStateRejectionReasons,
    spread?: IDictionaryEditorStateRejectionReasonsUpdate
  ) {
    Object.assign(this, state, spread);
    if (!spread) {
      //-------------------------------------------------------------------------
      // Process draft items
      const draftItems: DictionaryDraftItemRejectionReasons[] = [];
      this.draft.items.forEach(item => {
        let publishedVersion = (this.draft.publishedVersion as IPublishedDictionary<IDictionaryDraftItemRejectionReasons>)?.items.find(di => di.id == item.id);
        item.publishedVersion = publishedVersion;
        draftItems.push(new DictionaryDraftItemRejectionReasons(item));
      });
      this.draft.items = draftItems;
      //-------------------------------------------------------------------------
      this.draftSessionInitial = JSON.parse(JSON.stringify(this.draft));
      const draftItemsInitial: DictionaryDraftItemRejectionReasons[] = [];
      this.draftSessionInitial.items.forEach(item => {
        draftItemsInitial.push(new DictionaryDraftItemRejectionReasons(item));
      });
      this.draftSessionInitial.items = draftItemsInitial;
    };
    this.checkChangesAndValidate();
  };
  //---------------------------------------------------------------------------
  checkChangesAndValidate() {
    this.isUpdated = false;
    console.log("checkChangesAndValidate")
    // 1. Check if draft is updated
    if (this.draft.items.length != this.draftSessionInitial.items.length) {
      this.isUpdated = true;
    } else {
      // Check items one by one
      const itemPropertiesToCheck = ['id', 'name', 'description', 'status', 'objectTypes', 'adRanks', 'containsImage', 'deleted', 'index', 'isActive', 'path', 'pathNew', 'pathOld'];
      type DraftItemObjectKey = keyof DictionaryDraftItemRejectionReasons;
      for (let j = 0; j < this.draft.items.length; j++) {
        for (let i = 0; i < itemPropertiesToCheck.length; i++) {
          const key = itemPropertiesToCheck[i] as DraftItemObjectKey;
          if (JSON.stringify(this.draft.items[j][key]) !== JSON.stringify(this.draftSessionInitial.items[j][key])) {
            this.isUpdated = true;
          };
        };
      };
    };
    //-------------------------------------------------------------------------
    // 2. Check if draft is valid and ready to publish
    this.isReadyToPublish = false;
    //  - a. Current user can publish: this check is done by DictionaryEditorWrapper
    //  - b. There are changes compared to published version
    //  - c. No error draft states
    const dictionaryPublished = this.draft.publishedVersion as IDictionaryDraftRejectionReasons;
    let isChanged = false;
    let isValid = true;
    if (this.draft.items.length != dictionaryPublished.items.length) {
      isChanged = true;
    };
    // Check validity and (optionally) whether items are changed
    for (let j = 0; j < this.draft.items.length; j++) {
      const item = new DictionaryDraftItemRejectionReasons(this.draft.items[j]);
      // Check if it's valid first
      if (!item.isValid()) {
        isValid = false;
      } else {
        // Check if it's changed (only if the draft is not already considered as changed)
        if (!isChanged) {
          const publishedItem = dictionaryPublished.items.find(x => x.id == item.id);
          if (publishedItem) {
            isChanged =
              item.name !== publishedItem?.name ||
              item.description !== publishedItem?.description ||
              item.deleted !== publishedItem.deleted ||
              JSON.stringify(item.status) !== JSON.stringify(publishedItem.status) ||
              JSON.stringify(item.objectTypes) !== JSON.stringify(publishedItem.objectTypes) ||
              JSON.stringify(item.adRanks) !== JSON.stringify(publishedItem.adRanks) ||
              item.containsImage?.valueOf() != publishedItem.containsImage?.valueOf();
          } else {
            isChanged = true;
          };
          if (isChanged)
            break;
        };
      };
    };
    this.isValid = isValid;
    this.isReadyToPublish = isValid && isChanged;
  };
}