import { useNavigate } from "react-router";
import { TUiMenuContent } from "../menu/menu-content/TUiMenuContent";
import PopupMessage from "../popup-v2/popup-message/PopupMessage";
import { ErrorInfo, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";

const optionOk = {
  id: "OK",
  directoryId: "",
  elementType: "",
  index: 1,
  priorityLevel: 1,
  caption: { en: "OK" },
  hint: null,
  iconFile: "",
  isDefault: false,
  disabled: false,
  visible: true,
  canHideCaption: false,
  action: null
}

const tmpOptionsMenuContent: TUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  visible: true,
  disabled: false,
  options: [optionOk],
  optionGroups: []
}

interface IProps {
  error: Error
}

export function LogError(error: Error, info: ErrorInfo) {
  console.debug(error, info);
};

export function ErrorBoundaryDialog(props: IProps) {
  const navigate = useNavigate();
  const { resetBoundary } = useErrorBoundary();
  const [show, setShow] = useState(true);
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    setShow(false);
    resetBoundary();
    navigate("/");
  }
  //---------------------------------------------------------------------------
  let message = props.error.message + "\r\n\r\n" + "You will be redirected to home page."
  return (
    <PopupMessage
      id='ErrorBoundaryDialog'
      type='Error'
      header='Something went wrong'
      message={message}
      optionsMenuContent={tmpOptionsMenuContent}
      show={show}
      onOptionSelect={onOptionSelect}
      onCancel={() => onOptionSelect("")}
    />
  );

}