import { useContext } from 'react';
import styles from './CustomerAdRequestsListItem.module.css';
import { ICustomerAdRequestsListItem } from './ICustomerAdRequestsListItem';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../../../common/functions/GetName';
import ApprovalRequestDetails from '../../../approval-request-details/ApprovalRequestDetails';
import RequestStatus from '../../../approval-request-status/RequestStatus';

interface IProps {
  data: ICustomerAdRequestsListItem;
  onSelect: (selectedRequestId: string, locale: string) => void;
}

export default function CustomerAdRequestsListItem(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const localeName = appUiContext.dictionaries?.supportedLocales?.find(l => l.id == props.data.localeId);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    props.onSelect(props.data.id, props.data.localeId);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      onClick={onSelect}
    >

      <div className={styles.requestCaption}>
        <span className={styles.title}>
          {props.data.businessName ? props.data.businessName : "No title"}
        </span>
        <div className={styles.locale}>
          <span className={styles.localeName}>
            {GetName(appUiContext, props.data.localeId, localeName?.name)}
          </span>
        </div>
      </div>

      <div className={styles.details}>
        <ApprovalRequestDetails
          data={props.data}
          assignedTo={props.data.actualLogRecord.assignedTo}
        />
      </div>

      <RequestStatus data={props.data.status} />

    </div>
  );
}