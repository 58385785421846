import CombineStyles from '../../../../utils/combineStyles';
import { IFilterItemOption } from '../filter-editor/IFilterItem';
import styles from './FilterOption.module.css';

interface IProps {
  option: IFilterItemOption;
  onSelect: (filterOption: IFilterItemOption) => void; 
}

export default function FilterOption(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div
      key={props.option.id}
      className={CombineStyles([
        styles.filterOption,
        props.option.isDisabled ? styles.disabled : ""])}
      onClick={() => props.onSelect(props.option)}
    >
      <input
        type="checkbox"
        className={styles.inputCheckbox}
        readOnly
        disabled={props.option.isDisabled}
        checked={!!props.option.isSelected}
      />
      <label>
        {props.option.name ? props.option.name : props.option.id}
      </label>
    </div>
  );
}