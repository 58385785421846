import { IAdLanguageSetListItem } from "../../components/ad-content-editor/IAdLanguageSet";
import { ClassAdModule } from "../../components/common/ad-modules/IAdModule";
import { IUiListItem } from "../../components/common/input/input-dropdown/InputDropdown";
import { Actions } from "./BusinessContextReducer";
import { ClassAdLanguageSetState } from "./IAdLanguageSetState";
import { ClassBusinessState } from "./IBusinessState";

export type EUserRole = "owner" | "administrator" | "contentmanager";

export interface IBusinessContext {
  userRole?: EUserRole;
  basePath?: string; // ends with business id "/<pathBusinessConsole>/<role>/<pathBusinesses>/<businessId>", e.g. "/business/owner/businesses/<businessId>"
  businessState?: ClassBusinessState;
  adLanguageSetState?: ClassAdLanguageSetState;
  ads?: IAdLanguageSetListItem[];
  adModules?: ClassAdModule[];
  businessTags?: IUiListItem[]; // Business Tags dictionary with requested tags
  businessTagRequests?: IBusinessTagRequest[]; // Current user's business tag requests
  lastAction?: Actions;
}

export const businessContextDefaultValue: IBusinessContext = {
  
};

export interface IObjectState<T> {
  object: T;
  initialState: T;
  updatedProperties: string[];
  isNew: boolean;
  isChanged: boolean;
  isValid: boolean;
}

export interface IBusinessTagRequest {
  id: string;
  locale: string;
  path: string;
  name?: string;
  description?: string;
  businessIds: string[];
  status?: string;
}