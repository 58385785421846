import { useEffect, useState } from 'react';
import CombineStyles from '../../../../../../utils/combineStyles';
import Toggler from '../../../../../common/toggler/Toggler';
import styles from './BusinessTagNode.module.css';
import { IBusinessTagsDictionaryDraftItem } from '../../../../administrator-console/dictionaries/business-tags-editor/IBusinessTagsDictionary';

interface IProps {
  data: IBusinessTagsDictionaryDraftItem;
  selectedItemPath?: string;
  currentItemPath?: string; // current node what we selecting parent for (it should be unavailable to select and expand)
  // ...we should not be able to expand because we can't make a node a child for itself
  collapsed: boolean;
  onSelect: (selectedNodePath: string) => void;
}

export default function BusinessTagNode(props: IProps) {
  const [collapsed, setCollapsed] = useState(true);
  const [selected, setSelected] = useState(false);
  const [unavailable, setunavailable] = useState(false); // Node is unavailable if it's the node we are editing
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (props.selectedItemPath) {
      // Check if the node itself is selected
      if (props.selectedItemPath == props.data.path) {
        setSelected(true);
      } else {
        setSelected(false);
        // Expand node if it's present in selected node path!
        if (props.selectedItemPath.startsWith(props.data.path)) {
          setCollapsed(false);
        };
      };
    };
  }, [props.selectedItemPath]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if ((props.currentItemPath && props.currentItemPath === props.data.path) || props.data.isDeleted || props.data.isRepositionedOld) {
      setunavailable(true);
    } else {
      setunavailable(false);
    };
  }, [props.currentItemPath]);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (!unavailable)
      props.onSelect(props.data.path);
  };
  //---------------------------------------------------------------------------
  const onSelectChild = (updatedSelectedNodePath: string) => {
    props.onSelect(updatedSelectedNodePath);
  };
  //---------------------------------------------------------------------------
  const onExpand = () => {
    if (hasChildren && !unavailable) {
      setCollapsed(false);
    };
  };
  //---------------------------------------------------------------------------
  const onCollapse = () => {
    if (!collapsed) {
      setCollapsed(true);
    };
  };
  //---------------------------------------------------------------------------
  const hasChildren = props.data.items && props.data.items.length > 0;
  const systemId = `{{${props.data.id}}}`;
  const caption = props.data.name && props.data.name.en ? props.data.name.en : systemId;
  //---------------------------------------------------------------------------
  // Do not display in the list removed items and current node
  const childrenElements = props.data.items?.filter(item =>
    !item.isDeleted &&
    !item.isRepositionedOld &&
    (item.path != props.currentItemPath)
  ).map(item => (
    <BusinessTagNode
      key={item.id}
      data={item}
      currentItemPath={props.currentItemPath}
      selectedItemPath={selected ? undefined : props.selectedItemPath}
      collapsed={true}
      onSelect={onSelectChild}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={CombineStyles([
        styles.header,
        selected ? styles.selected : "",
        unavailable ? styles.unavailable : ""
      ])}>
        <div className={CombineStyles([
          styles.toggler,
          (hasChildren) ? "" : styles.disabled,
          (!unavailable) ? "" : styles.disabled
        ])}>
          <Toggler
            collapsed={collapsed}
            onExpand={onExpand}
            onCollapse={onCollapse}
          />
        </div>
        <div
          className={styles.caption}
          onClick={onSelect}
        >
          <div className={CombineStyles([styles.name])}>
            {caption}
          </div>
          <div className={styles.systemId}>
            {systemId}
          </div>
        </div>
      </div>
      {!collapsed &&
        <div className={styles.children}>
          {childrenElements}
        </div>}
    </div>
  );
}