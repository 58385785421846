import { EDraftItemState } from "../../../../../context/directory-editor-context/EDraftItemState";
import { DirectoryDraftItem } from "../../../../../context/directory-editor-context/IDirectoryDraftItem";
import { IDirectoryItemTranslationDraft } from "../../../common/directory-translator/translation-editor/IDirectoryItemTranslationDraft";
import { Translation } from "../../../common/directory-translator/translation-editor/ITranslation";
import { ITranslation } from "../../dictionaries/DictionaryTranslationDraftItem";

export interface IDraftItemChanges {
  isUpdatedInSession: boolean; // Compared to state before edit form was opened (use it to decide whether Ok option should be enabled)
  isUpdated: boolean;          // Updated compared to original from which draft was created
}

export interface IContentItemTranslationDraft extends IDirectoryItemTranslationDraft {
  text: ITranslation;
  itemTranslation?: IContentItemTranslation; // Item translation from translation doc in Edit database
  itemPublished?: IContentItemPublished;     // Item translations published to CACTUS
}

interface IContentItemTranslationDraftSpread {
  text?: ITranslation;
}

export class ContentItemTranslationDraft extends DirectoryDraftItem implements IContentItemTranslationDraft {
  id!: string;
  draftState!: EDraftItemState;
  text!: Translation;
  itemTranslation?: IContentItemTranslation;
  itemPublished?: IContentItemPublished;
  //---------------------------------------------------------------------------
  constructor(source: IContentItemTranslationDraft, spread?: IContentItemTranslationDraftSpread) {
    super(source);
    Object.assign(this, source, spread);
    this.text = new Translation(this.text);
    if (spread) {
      this.setDraftState();
    };
  };
  //---------------------------------------------------------------------------
  setDraftState() {
    if (this.text.isTheSameAs(this.itemTranslation?.text))
      this.draftState = EDraftItemState.IsNothing;
    // let noTranslation = false;
    // let noChanges = false;
    // let fallbackIsAccepted = false;
    // if (this.text.noTranslation())
    //   noTranslation = true;
    // if (this.text.fallbackAccepted)
    //   fallbackIsAccepted = true;
    // //-------------------------------------------------------------------------
    // if (noTranslation)
    //   this.draftState |= EDraftItemState.IsNoTranslation;
    // else
    //   this.draftState &= ~EDraftItemState.IsNoTranslation;
    // if (!noChanges)
    //   this.draftState |= EDraftItemState.IsTranslationEdited;
    // else
    //   this.draftState &= ~EDraftItemState.IsTranslationEdited;
    // if (fallbackIsAccepted)
    //   this.draftState |= EDraftItemState.IsFallbackAccepted;
    // else
    //   this.draftState &= ~EDraftItemState.IsFallbackAccepted;
  };
  //---------------------------------------------------------------------------
  getIsUpdatedInSession(initialValue?: IContentItemTranslationDraft): boolean {
    let isUpdatedInSession = false;
    //-------------------------------------------------------------------------
    // Check if data is updated compared to initial state
    if (!this.text.isTheSameAs(initialValue?.text)) {
      isUpdatedInSession = true;
    };
    //-------------------------------------------------------------------------
    // Return result
    return isUpdatedInSession;
  };
  //---------------------------------------------------------------------------
  isTheSameAs(item?: IDirectoryItemTranslationDraft): boolean {
    //console.log(this, item)
    if (!item) return false;
    if (item.id !== this.id) return false;
    const contentItem = item as IContentItemTranslationDraft;
    if (!this.text.isTheSameAs(contentItem.text)) return false;
    return true;
  };
}

export interface IContentItemTranslation {
  id: string;
  text: ITranslation;
}

export interface IContentItemPublished {
  id: string;
  text: any; // UiTextProperty
}