import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../app-auth-context/AppAuthContext";
import { AdLanguageSet, IAdLanguageSet } from "../../../components/ad-content-editor/IAdLanguageSet";
import { apiBasePath, pathAdLanguageSetGet, pathGetAdLanguageSetSystem } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
import { apiGetPrivate, checkResponse } from "../../../utils/api";
import { IBusiness } from "../IBusiness";
import { ClassSystemBusinessRegional } from "../../../components/admin-console/regional-homepages/regional-homepage-editor/ISystemBusinessRegional";
import { ClassCustomerBusiness } from "../../../components/business-space/business-editor/ICustomerBusiness";

interface IResponse {
  ad: AdLanguageSet;
  business?: IBusiness;
}

interface IProps {
  msalInstance: IPublicClientApplication,
  authConfig?: IAuthConfig,
  isSystem: boolean,
  businessId?: string,
  adLanguageSetId?: string,
  getBusinessData: boolean,
  abortSignal?: AbortSignal
}

export default async function getAdLanguageSetAsync(props: IProps): Promise<IResponse> {
  if (!props.authConfig) throw new Error("getAdLanguageSetAsync: auth config is not provided");
  if (!props.businessId) throw new Error("getAdLanguageSetAsync: Business is not provided");
  if (!props.adLanguageSetId) throw new Error("getAdLanguageSetAsync: Ad language set Id is not provided");
  //---------------------------------------------------------------------------
  const uriTemplate = props.isSystem ? pathGetAdLanguageSetSystem : pathAdLanguageSetGet;
  const uri = `${apiBasePath}${stringFormatter(uriTemplate, [props.businessId, props.adLanguageSetId, props.getBusinessData.toString()])}`;
  const response = await apiGetPrivate(
    props.msalInstance,
    props.authConfig,
    uri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getAdLanguageSetAsync", props.abortSignal?.aborted);
  const adLanguageSet = new AdLanguageSet(response?.content.ad as IAdLanguageSet);
  let business: IBusiness | undefined = undefined;
  if (props.getBusinessData) {
    business = props.isSystem ?
    new ClassSystemBusinessRegional(response?.content.business) :
    new ClassCustomerBusiness(response?.content.business);
  };
  const responseData: IResponse = {
    ad: adLanguageSet,
    business: business
  };
  //console.log("getAdLanguageSetAsync", responseData);
  return (responseData);
}