import { useContext, useEffect, useRef, useState } from 'react';
import FormOptions from '../../../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../../../common/menu/menu-content/TUiMenuContent';
import PopupDialog from '../../../../../common/popup-v2/popup-dialog/PopupDialog';
import styles from './BusinessPathEditForm.module.css';
import { IUiEditFormBusinessPath } from './IUiEditFormBusinessPath';
import GetCaption from '../../../../../common/functions/GetCaption';
import GetHint from '../../../../../common/functions/GetHint';
import { IUiValidation, IValidationState } from '../../../../../common/input-v2/input-validation/IUiValidation';
import { ValidateInput } from '../../../../../common/input-v2/input-validation/ValidateInput';
import RawInputText from '../../../../../common/input-v2/raw-inputs/RawInputText';
import ValueWrapper from '../../../../../common/input-v2/value-wrapper/ValueWrapper';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import { useAbortController } from '../../../../../../hooks/useAbortController';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../../context/app-auth-context/AppAuthContext';
import WikiSpinner from '../../../../../app-layout/spinner/wikiSpinner';
import CombineStyles from '../../../../../../utils/combineStyles';
import { ScreenType, useAppScreenContext } from '../../../../../../context/app-screen-context/AppScreenProvider';
import { EBusinessType } from '../../../../../../context/business-context/IBusiness';
import { BusinessContext } from '../../../../../../context/business-context/BusinessContextProvider';
import createBusinessPathAsync from '../functions/createBusinessPathAsync';
import { EHttpStatusCode } from '../../../../../../utils/HttpStatusCodes';

export interface IBusinessPath {
  value: string;
  isMaster: boolean;
}

interface IProps {
  ui: IUiEditFormBusinessPath;
  businessId: string;
  businessType: EBusinessType;
  data: IBusinessPath;
  existingPaths?: string[];
  editMasterFlag?: boolean;
  onUpdate: (updatedPath: IBusinessPath) => void;
  onCancel: () => void;
}

export default function BusinessPathEditForm(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const { businessContext } = useContext(BusinessContext);
  const screenType = useAppScreenContext();
  const [path, setPath] = useState<IBusinessPath>(props.data);
  const initialValue = useRef<IBusinessPath>(JSON.parse(JSON.stringify(props.data)));
  const [validationState, setValidationState] = useState<IValidationState>({ isValid: true, validationMessages: [] });
  const [isLoading, setIsLoading] = useState(false);
  const abortController = useAbortController("BusinessPathEditForm");
  const validation = props.ui.inputValue.validation;
  const isSystem = props.businessType !== "Business";
  //---------------------------------------------------------------------------
  if (isSystem && validation?.regexMatch) {
    // Temporary solution: Update business path mask, if it's a system business
    const updatedRegex = validation.regexMatch.regexPattern.replace('{10,}', '{2,}');
    validation.regexMatch.regexPattern = updatedRegex;
  };
  //--------------------------------------------------------------------------- Abortcontroller
  useEffect(() => {
    return () => {
      abortController.abortOnUnmount();
    };
  }, []);
  //--------------------------------------------------------------------------- Validate
  useEffect(() => {
    if (props.ui.inputValue.validation) {
      // Check existing path (only in case if it's a new path is edited)
      const existingPath = props.data.value == "" ? props.existingPaths?.find(p => p == path.value) : undefined;
      const validationState = ValidateInput({
        elementId: props.ui.inputValue.id,
        validation: validation as IUiValidation,
        data: path.value,
        isUnique: existingPath ? false : true
      });
      setValidationState(validationState);
    };
  }, [path]);
  //---------------------------------------------------------------------------
  const onValueUpdate = (updatedValue: string) => {
    let newValue = updatedValue.toLocaleLowerCase();
    const existingPath = props.data.value == "" ? props.existingPaths?.find(p => p == path.value) : undefined;
    const validationState = ValidateInput({
      elementId: props.ui.inputValue.id,
      validation: validation as IUiValidation,
      data: newValue,
      isUnique: existingPath ? false : true
    });
    setValidationState(validationState);
    setPath({
      ...path,
      value: newValue
    });
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    if (businessContext.businessState?.isNew) {
      // If current business is new, we don't need to call API, just update business in context
      // URL will be created when business is saved
      props.onUpdate(path);
    } else {
      // If it's an existing business, call API to try to create the path
      setIsLoading(true);
      let controller = abortController.newController("createBusinessPath");
      const oldPathToReplace = initialValue.current.value;
      createBusinessPathAsync({
        msalIntance: instance,
        authConfig: appAuthContext.config,
        businessId: props.businessId,
        path: path.value,
        oldPathToReplace: oldPathToReplace
      }).then(response => {
        switch (response?.status) {
          //-------------------------------------------------------------------
          case EHttpStatusCode.OK:
          case EHttpStatusCode.Created:
            console.log(`URL [${path.value}] created for the business`);
            props.onUpdate(path);
            break;
          //-------------------------------------------------------------------
          case EHttpStatusCode.Conflict:
            console.error(`URL [${path.value}] is used by another business`);
            const updatedValidationState: IValidationState = {
              isValid: false,
              validationMessages: [{
                id: `${props.ui.id}.Validation.Unique.Message`,
                message: props.ui.inputValue.validation?.unique?.message
              }]
            };
            setValidationState(updatedValidationState);
            break;
        };
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    };
  };
  //---------------------------------------------------------------------------
  const onIsMasterUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPath({
      ...path,
      isMaster: event.target.checked
    });
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OptionOk":
        onConfirm();
        break;
      case "OptionCancel":
        props.onCancel();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const mc = getTUiMenuContent(props.ui.menuContent);
  mc.options.forEach(option => {
    switch (option.id) {
      case "OptionOk":
        option.disabled = !validationState.isValid || (path.value == initialValue.current.value && path.isMaster == initialValue.current.isMaster);
        option.isDefault = validationState.isValid;
        break;
    };
  });
  const menuElement = (mc.options && mc.options.length > 0) ?
    <FormOptions
      ui={mc}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    /> : undefined;
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id={props.ui.id}
      header={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      isModal={true}
      isDraggable={false}
      toolbarContent={menuElement}
      onClose={props.onCancel}
    >
      <WikiSpinner show={isLoading} />
      <div className={CombineStyles([
        styles.form,
        screenType == ScreenType.Desktop ? styles.desktop : ""
      ])}>

        {GetHint(appUiContext, props.ui.id, props.ui.hint)}

        <div className={styles.container}>
          <div className={styles.displayUrl}>
            {window.location.origin}/{path.value}
          </div>
          <ValueWrapper
            disabled={false}
            hint={GetHint(appUiContext, props.ui.id, props.ui.hint)}
            hideHint={false}
            validation={validationState}
          >
            <RawInputText
              ui={props.ui.inputValue}
              data={path.value}
              isFocused={true}
              isValid={validationState?.isValid}
              onUpdate={onValueUpdate}
            />
          </ValueWrapper>
        </div>

        {props.ui.inputIsMaster &&
          <div className={styles.isMaster}>
            <div>
              {GetCaption(appUiContext, props.ui.inputIsMaster.id, props.ui.inputIsMaster.caption)}
            </div>
            <div>
              <input
                type="checkbox"
                checked={path.isMaster}
                onChange={onIsMasterUpdate}
              />
            </div>
          </div>}

      </div>
    </PopupDialog>
  );
}