import { useContext } from 'react';
import { IUiOption } from '../../../../common/options/IUiOption';
import styles from './OptionSearch.module.css';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import GetCaption from '../../../../common/functions/GetCaption';
import GetHint from '../../../../common/functions/GetHint';
import { ScreenType, useAppScreenContext } from '../../../../../context/app-screen-context/AppScreenProvider';
import CombineStyles from '../../../../../utils/combineStyles';

interface IProps {
  ui: IUiOption;
  onSelect: () => void;
}

export default function OptionSearch(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  //---------------------------------------------------------------------------
  return (
    <button
      className={CombineStyles([
        styles.container,
        screenType == ScreenType.Mobile ? styles.mobile : ''
      ])}
      title={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      onClick={props.onSelect}
    >
      <div className={styles.text}>
        {GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      </div>
    </button>
  );
}