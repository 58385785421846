import { useContext, useEffect, useState } from 'react';
import { IObjectLocation, newId } from '../IBusinessLocationData';
import styles from './EditFormBusinessLocation.module.css';
import { EditFormState } from '../../../../../common/edit-form/IEditFormState';
import { IUiEditFormLocation } from './IUiEditFormLocation';
import { IUiOption } from '../../../../../common/options/IUiOption';
import FormOptions from '../../../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../../../common/menu/menu-content/TUiMenuContent';
import InputText from '../../../../../common/input-v2/input-text/InputText';
import InputsGroupWrapper from '../../../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import InputCoordinates from '../input-coordinates/InputCoordinates';
import { TGeoPoint } from '../../../../../common/map/geo-json/GeoJson';
import CombineStyles from '../../../../../../utils/combineStyles';
import { AppAuthContextStore } from '../../../../../../context/app-auth-context/AppAuthContext';
import OptionLink from '../../../../../common/options/option-link/OptionLink';

interface IProps {
  ui: IUiEditFormLocation;
  data: IObjectLocation<string>;
  addressBlockMode?: boolean;
  locationIsRequired?: boolean; // For business wizard
  onUpdate: (updatedLocation: IObjectLocation<string>) => void;
  onConfirm: () => void;
  onClose: () => void;
}

export default function EditFormBusinessLocation(props: IProps) {
  const { appAuthContext } = useContext(AppAuthContextStore);
  // Maintain state to check changes and validate data
  const [state, setState] = useState<EditFormState<IObjectLocation<string>>>();
  //--------------------------------------------------------------------------- Data
  useEffect(() => {
    if (state) {
      // If it's not a first render, update the state
      const updatedState = new EditFormState<IObjectLocation<string>>(state, {
        object: props.data,
        isValid: true
      });
      setState(updatedState);
    } else {
      // If it's a first render, set the state
      const newState = new EditFormState<IObjectLocation<string>>({
        object: props.data,
        isNew: false,
        isValid: true
      });
      setState(newState);
    };
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onNameUpdate = (updatedValue: string, isValid: boolean) => {
    const updatedLocation: IObjectLocation<string> = {
      ...props.data,
      name: updatedValue
    };
    props.onUpdate(updatedLocation);
  };
  //---------------------------------------------------------------------------
  const onAddressTextUpdate = (updatedValue: string, isValid: boolean) => {
    const updatedLocation: IObjectLocation<string> = {
      ...props.data,
      addressText: updatedValue
    };
    props.onUpdate(updatedLocation);
  };
  //---------------------------------------------------------------------------
  const onUpdateCoordinates = (updatedValue: number[]) => {
    const updatedLocation: IObjectLocation<string> = {
      ...props.data,
      point: new TGeoPoint({ coordinates: updatedValue })
    };
    props.onUpdate(updatedLocation);
  };
  //---------------------------------------------------------------------------
  const restoreDefaultPosition = () => {
    if (!state?.object) return;
    if (!appAuthContext.userLocation) return;
    const updatedLocation: IObjectLocation<string> = {
      ...state.object,
      point: new TGeoPoint({
        coordinates: [
          appAuthContext.userLocation.position.coords.longitude,
          appAuthContext.userLocation.position.coords.latitude
        ]
      })
    };
    props.onUpdate(updatedLocation);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionOk":
        props.onConfirm();
        break;
      case "OptionCancel":
        props.onClose();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionOk":
        const isDisabled = !state?.isUpdated || !state.isValid;
        updatedOptions.push({
          ...option,
          iconId: "check",
          isDisabled: isDisabled,
          isDefault: !isDisabled
        });
        break;
      case "OptionCancel":
        if (!props.locationIsRequired) {
          updatedOptions.push({ ...option, iconId: "close" });
        };
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container} onResize={() => { console.log('resize') }}>

      <div className={styles.inputs}>
        <InputsGroupWrapper>
          <InputText
            ui={props.ui.inputName}
            data={props.data?.name}
            isFocused={true}
            onUpdate={onNameUpdate}
          />
          <InputText
            ui={props.ui.inputAddressText}
            data={props.data?.addressText}
            onUpdate={onAddressTextUpdate}
          />
          <InputCoordinates
            ui={{ ...props.ui.inputCoordinates, disabled: props.addressBlockMode ? true : false }}
            data={props.data?.point?.coordinates}
            onUpdate={onUpdateCoordinates}
          />
          {!props.addressBlockMode &&
            <div className={styles.option}>
              <OptionLink
                ui={{
                  id: "OptionSetDefaultPosition",
                  directoryId: "",
                  elementType: "",
                  caption: { en: "Set to default position" },
                  hint: undefined,
                  isDisabled: !appAuthContext.userLocation
                }}
                onSelect={restoreDefaultPosition}
              />
            </div>}
        </InputsGroupWrapper>

      </div>

      <FormOptions
        ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
        applyMobileStyle={false}
        onSelect={onOptionSelect}
      />
    </div>
  );
}