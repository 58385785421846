import { AdLanguageSet } from "../../../../ad-content-editor/IAdLanguageSet";

export interface IAdLanguageSetReviewState {
  adLanguageSet: AdLanguageSet;
  isReadonly: boolean;
  isModified: boolean;
  isCompleted: boolean;
  isApproved: boolean;
}

interface IStateUpdate {
  state: IAdLanguageSetReviewState;
  updatedData: AdLanguageSet;
}

interface IConstructorProps {
  source?: AdLanguageSet;
  update?: IStateUpdate;
}

export class AdLanguageSetReviewState implements IAdLanguageSetReviewState {
  adLanguageSet!: AdLanguageSet;
  isReadonly!: boolean;
  isModified!: boolean;
  isCompleted!: boolean;
  isApproved!: boolean;
  private adLanguageSetInitial!: AdLanguageSet;
  //---------------------------------------------------------------------------
  constructor(props: IConstructorProps) {
    if (props.source) {
      this.initialize(props.source);
    } else if (props.update && !props.update.state.isReadonly) {
      Object.assign(this, props.update.state, { adLanguageSet: props.update.updatedData });
      this.refreshStatus();
      this.updateAdModulesEditorStatus();
    };
  };
  //---------------------------------------------------------------------------
  initialize(adLanguageSet: AdLanguageSet) {
    this.adLanguageSet = adLanguageSet;
    this.isReadonly = adLanguageSet.status.statusId != "NA_PNDG_APPROVAL";
    this.isModified = false;
    this.isCompleted = false;
    this.isApproved = adLanguageSet.status.isApproved;
    this.adLanguageSetInitial = new AdLanguageSet(JSON.parse(JSON.stringify(adLanguageSet)));
  };
  //---------------------------------------------------------------------------
  refreshStatus() {
    this.isCompleted = false;
    this.isApproved = false;
    //-------------------------------------------------------------------------
    if (!this.adLanguageSet.adModules) return;
    //-------------------------------------------------------------------------
    // Check if there are changes
    this.isModified = JSON.stringify(this.adLanguageSet) !== JSON.stringify(this.adLanguageSetInitial);
    if (!this.isModified) return;
    //-------------------------------------------------------------------------
    // Check if there are uncompleted ad modules
    const unprocessedModule = this.adLanguageSet.adModules?.find(x => x.status?.statusId == "PendingApproval");
    if (unprocessedModule) return;
    //-------------------------------------------------------------------------
    // Ad Modules are completed. Check complex issue items
    const unprocessedIssueItem = this.adLanguageSet.issues?.find(x => x.status?.statusId == "Draft");
    if (this.adLanguageSet.issues && this.adLanguageSet.issues.length > 0 && unprocessedIssueItem) return;
    //-------------------------------------------------------------------------
    // Both ad modules and complex issue items are completed
    const approvedAdModules = this.adLanguageSet.adModules.filter(x => x.status?.statusId == "Approved");
    let adModulesAreApproved = approvedAdModules.length == this.adLanguageSet.adModules.length;
    //-------------------------------------------------------------------------
    let issueItemsAreApproved = true;
    if (this.adLanguageSet.issues) {
      const approvedIssueItems = this.adLanguageSet.issues.filter(x => x.status?.statusId == "Approved");
      issueItemsAreApproved = approvedIssueItems.length == this.adLanguageSet.issues.length;
    };
    this.isCompleted = true;
    this.isApproved = adModulesAreApproved && issueItemsAreApproved;
  };
  //---------------------------------------------------------------------------
  updateAdModulesEditorStatus() {
    // Run through ad modules and check whether ad module status is changed
    this.adLanguageSet.adModules?.forEach(adModule => {
      const initialModule = this.adLanguageSetInitial.adModules?.find(item => item.id === adModule.id);
      if (initialModule) {
        if (adModule.status?.statusId !== initialModule.status?.statusId) {
          adModule.editorStatus = {
            isNew: false,
            isModified: true
          };
        };
      } else {
        console.error("It's moderator, where the new ad modules come from?!");
      };
    });
  };
  //---------------------------------------------------------------------------
  getInitialState(): AdLanguageSetReviewState {
    return new AdLanguageSetReviewState({ source: this.adLanguageSetInitial });
  };
}