import { useEffect, useRef, useState } from 'react';
import { ScreenType, useAppScreenContext } from '../../../../../context/app-screen-context/AppScreenProvider';
import CombineStyles from '../../../../../utils/combineStyles';
import InputCheckbox from '../../../../common/input/input-checkbox/InputCheckbox';
import { TUiInputCheckbox } from '../../../../common/input/input-checkbox/TUiInputCheckbox';
import InputText from '../../../../common/input/input-text/InputText';
import { TUiInputText } from '../../../../common/input/input-text/TUiInputText';
import { TUiMenuOption } from '../../../../common/menu/menu-content/menu-option/TUiMenuOption';
import { TUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import ValidationWrapper from '../../../../common/validation-wrapper/ValidationWrapper';
import BusinessTagParentEditor, { IBusinessTagParentInput } from '../business-tag-parent-editor/BusinessTagParentEditor';
import styles from './BusinessTagEditForm.module.css';
import { IBusinessTagsDictionaryDraftItem } from '../../../administrator-console/dictionaries/business-tags-editor/IBusinessTagsDictionary';
import { DraftItemStateEnum } from '../../dictionary-draft-models/DraftItemStateEnum';
import { IUiValidationMessage } from '../../../../common/input-v2/input-validation/IUiValidation';
import FormOptions from '../../../../common/form-options-bar/FormOptions';

const tmpInputId: TUiInputText = {
  id: "BusinessTagIdInput",
  directoryId: '',
  elementType: "",
  disabled: true,
  visible: true,
  caption: { en: "Id" },
  placeholder: { en: "Type Id here..." },
  defaultValue: undefined,
  hint: undefined,
  validation: undefined
}
const tmpInputIsGroup: TUiInputCheckbox = {
  id: '',
  caption: { en: "Group" },
  hint: undefined,
  disabled: false
}
const tmpInputItemsManualOrder: TUiInputCheckbox = {
  id: '',
  caption: { en: "Manual Order" },
  hint: undefined,
  disabled: false
}
const tmpInputName: TUiInputText = {
  id: "",
  directoryId: '',
  elementType: "",
  disabled: false,
  visible: true,
  caption: { en: "Name" },
  placeholder: { en: "Type Name here..." },
  defaultValue: undefined,
  hint: undefined,
  validation: undefined
}
const tmpBusinessTagParentInput: IBusinessTagParentInput = {
  id: "",
  caption: { en: "Parent" },
  hint: undefined
}
const tmpOptionOk: TUiMenuOption = {
  id: 'optionOk',
  directoryId: '',
  elementType: '',
  visible: true,
  disabled: false,
  canHideCaption: false,
  index: 0,
  priorityLevel: 0,
  caption: { en: 'Ok' },
  iconFile: "check",
  hint: undefined,
  isDefault: true,
  action: undefined
}
const tmpOptionCancel: TUiMenuOption = {
  id: 'optionCancel',
  directoryId: '',
  elementType: '',
  visible: true,
  disabled: false,
  canHideCaption: false,
  index: 0,
  priorityLevel: 0,
  caption: { en: 'Cancel' },
  iconFile: "close",
  hint: undefined,
  isDefault: false,
  action: undefined
}
const tmpOptionsMenu: TUiMenuContent = {
  id: '',
  directoryId: '',
  elementType: '',
  visible: true,
  disabled: false,
  options: [tmpOptionCancel, tmpOptionOk],
  optionGroups: []
}
const tmpValidationMessages: IUiValidationMessage[] = [{
  id: "ValidationMessage_TagIdEmpty",
  message: { en: "Tag Id cannot be empty" }
}, {
  id: "ValidationMessage_TagIdAlreadyExists",
  message: { en: "Tag with this Id already exists" }
}, {
  id: "ValidationMessage_TagNameEmpty",
  message: { en: "Tag name is required" }
}, {
  id: "ValidationMessage_TagNameAlreadyExists",
  message: { en: "Tag with this name already exists" }
}]

interface IProps {
  data?: IBusinessTagsDictionaryDraftItem;
  //initialSessionData?: IBusinessTagsDictionaryDraftItem; // It have to be provided if tag is not new
  initialData?: IBusinessTagsDictionaryDraftItem; // It have to be provided if tag is not new
  parent?: IBusinessTagsDictionaryDraftItem;
  rootNodes: IBusinessTagsDictionaryDraftItem[];
  onUpdate: (updatedTag: IBusinessTagsDictionaryDraftItem, isBrandNew: boolean, updatedParentTag?: IBusinessTagsDictionaryDraftItem) => void;
  onCancel: () => void;
}

export default function BusinessTagEditForm(props: IProps) {
  const screenType = useAppScreenContext();
  const [isNew, setIsNew] = useState(false);
  const [tag, setTag] = useState<IBusinessTagsDictionaryDraftItem>();
  const [parentTag, setParentTag] = useState<IBusinessTagsDictionaryDraftItem>();
  const initialSessionTag = useRef<IBusinessTagsDictionaryDraftItem>();
  const initialParentPath = useRef<string>();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [idIsValid, setIdIsValid] = useState(true);
  const [nameIsValid, setNameIsValid] = useState(true);
  const [tagIdValidationMessages, setTagIdValidationMessages] = useState<IUiValidationMessage[]>([]);
  const [tagNameValidationMessages, setTagNameValidationMessages] = useState<IUiValidationMessage[]>([]);
  const stringifiedTags = useRef<string>();
  //--------------------------------------------------------------------------- Set up data
  useEffect(() => {
    //console.log(props.data)
    if (props.data) {
      setIsNew(false);
      setTag(props.data);
      initialSessionTag.current = props.data;
      setIsValid(true);
      if (!(props.data.name && props.data.name["en"])) {
        setNameIsValid(false);
        setIsValid(false);
        setTagNameValidationMessages(tmpValidationMessages.filter(vm => vm.id == "ValidationMessage_TagNameEmpty"));
      };
    } else {
      setIsNew(true);
      initialSessionTag.current = {} as IBusinessTagsDictionaryDraftItem;
      setTag({} as IBusinessTagsDictionaryDraftItem);
      setIdIsValid(false);
      setTagIdValidationMessages(tmpValidationMessages.filter(vm => vm.id == "ValidationMessage_TagIdEmpty"));
    };
    //-------------------------------------------------------------------------
    // Prepare data to check new Id uniqueness
    stringifiedTags.current = JSON.stringify(props.rootNodes);
  }, [props.data]);
  //--------------------------------------------------------------------------- Set up initial parent
  useEffect(() => {
    setParentTag(props.parent);
    // We need this to check whether parent was changed
    initialParentPath.current = props.parent?.path;
  }, [props.parent]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (idIsValid && nameIsValid) {
      setIsValid(true);
    } else {
      setIsValid(false);
    };
  }, [idIsValid, nameIsValid]);
  //---------------------------------------------------------------------------
  const onUpdateId = (updatedId: string) => {
    updatedId = updatedId ? updatedId.trim() : updatedId;
    // This is possible only in case of a new tag 
    if (isNew && parentTag && stringifiedTags.current) {
      // Check if new id is unique
      if (stringifiedTags.current.includes(`"id":"${updatedId}"`) || updatedId == "") {
        setIdIsValid(false);
        if (updatedId == "") {
          setTagIdValidationMessages(tmpValidationMessages.filter(m => m.id == "ValidationMessage_TagIdEmpty"));
        } else {
          setTagIdValidationMessages(tmpValidationMessages.filter(m => m.id == "ValidationMessage_TagIdAlreadyExists"));
        };
      } else {
        setIdIsValid(true);
        setTagIdValidationMessages([]);
      };
      //----------------------------------------------------------------------- ATTENTION: provide index value if parent has itemsManualOrder=true!
      const newTag: IBusinessTagsDictionaryDraftItem = {
        id: updatedId,
        path: `${parentTag.path}.${updatedId}`,
        isGroup: false,
        itemsManualOrder: false,
        draftState: DraftItemStateEnum.IsNew,
        index: 0,
        isNew: true,
        isEdited: false,
        translationIsEdited: false,
        isDeleted: false,
        isRepositionedOld: false,
        isRepositionedNew: false,
        isReordered: false,
        hasWarning: false,
        hasEdited: false,
        hasTranslationEdited: false,
        hasNew: false,
        hasDeleted: false,
        hasRepositionedOld: false,
        hasRepositionedNew: false,
        hasReordered: false,
        searchString: {},
        searchStringId: updatedId,
        isCollapsed: true
      };
      setTag(newTag);
      setIsUpdated(true);
    } else console.error("Can't verify new Id: insufficient data");
  };
  //--------------------------------------------------------------------------- isRepositioned...
  const onUpdateParent = (updatedParent: IBusinessTagsDictionaryDraftItem) => {
    // Determine whether 'Ok' option should be available
    setParentTag(updatedParent);
    if (updatedParent.path != initialParentPath.current) {
      setIsUpdated(true);
    } else {
      setIsUpdated(false);
    };
    //-------------------------------------------------------------------------
    // Check actual changes

  };
  //--------------------------------------------------------------------------- isEdited
  const onUpdateGroup = (updatedIsGroup: boolean) => {
    if (tag) {
      // Determine whether 'Ok' option should be available
      if (updatedIsGroup != initialSessionTag.current?.isGroup) {
        setIsUpdated(true);
      } else {
        setIsUpdated(false);
      };
      //-----------------------------------------------------------------------
      // Check if tag is ACTUALLY updated, meaning comparing it with original state
      const isActuallyUpdated = tag?.isNew ? false : updatedIsGroup != props.initialData?.isGroup;
      console.log(updatedIsGroup)
      console.log(props.initialData?.isGroup)
      //---------------------------------------------------------------------
      // Figure out draft state
      let draftState: DraftItemStateEnum = tag.draftState;
      if (isActuallyUpdated) {
        draftState |= DraftItemStateEnum.IsEdited;
      } else {
        draftState &= ~DraftItemStateEnum.IsEdited;
      };
      //---------------------------------------------------------------------
      const updatedTag: IBusinessTagsDictionaryDraftItem = {
        ...tag as IBusinessTagsDictionaryDraftItem,
        isGroup: updatedIsGroup,
        draftState: draftState,
        isEdited: isActuallyUpdated
      };
      setTag(updatedTag);
    };
  };
  //--------------------------------------------------------------------------- NOTE! forbid changing the value if children are reordered!!!
  const onUpdateManualOrder = (updatedManualOrder: boolean) => {
    if (tag) {
      // Determine whether 'Ok' option should be available
      if (updatedManualOrder != initialSessionTag.current?.itemsManualOrder) {
        setIsUpdated(true);
      } else {
        setIsUpdated(false);
      };
      //-----------------------------------------------------------------------
      // Check if tag is ACTUALLY updated, meaning comparing it with original state
      const isActuallyUpdated = tag?.isNew ? false : updatedManualOrder != props.initialData?.itemsManualOrder;
      console.log(updatedManualOrder)
      console.log(props.initialData?.itemsManualOrder)
      //---------------------------------------------------------------------
      // Figure out draft state
      let draftState: DraftItemStateEnum = tag.draftState;
      if (isActuallyUpdated) {
        draftState |= DraftItemStateEnum.IsEdited;
      } else {
        draftState &= ~DraftItemStateEnum.IsEdited;
      };
      //---------------------------------------------------------------------
      const updatedTag: IBusinessTagsDictionaryDraftItem = {
        ...tag as IBusinessTagsDictionaryDraftItem,
        itemsManualOrder: updatedManualOrder,
        draftState: draftState,
        isEdited: isActuallyUpdated
      };
      setTag(updatedTag);
    };
  };
  //--------------------------------------------------------------------------- translationIsUpdated
  const onUpdateName = (updatedName: string) => {
    if (tag) {
      if (updatedName == "") {
        setNameIsValid(false);
        setTagNameValidationMessages(tmpValidationMessages.filter(m => m.id == "ValidationMessage_TagNameEmpty"));
      } else if (stringifiedTags.current) {
        // Check if new name is unique
        // if (stringifiedTags.current.includes(`"en":"${updatedName}"`)) {
        //   console.log(stringifiedTags.current)
        //   setNameIsValid(false);
        //   setTagNameValidationMessages(tmpValidationMessages.filter(m => m.id == "ValidationMessage_TagNameAlreadyExists"));
        // } else {
        //   setNameIsValid(true);
        //   setTagNameValidationMessages([]);
        // };      
        updatedName = updatedName ? updatedName.trim() : updatedName;
        setNameIsValid(true);
        setTagNameValidationMessages([]);
        //---------------------------------------------------------------------
        // Determine whether 'Ok' option should be available
        const newName = updatedName == "" ? null : { en: updatedName };
        if (JSON.stringify(newName) == JSON.stringify(initialSessionTag.current?.name)) {
          setIsUpdated(false);
        } else {
          setIsUpdated(true);
        };
        //---------------------------------------------------------------------
        // Check if tag is ACTUALLY updated, meaning comparing it with original state
        const isActuallyUpdated = ((tag.draftState & DraftItemStateEnum.IsNew) == DraftItemStateEnum.IsNew)
          ? false : JSON.stringify(newName) != JSON.stringify(props.initialData?.name);
        //---------------------------------------------------------------------
        // Figure out draft state
        let draftState: DraftItemStateEnum = tag.draftState;
        if (isActuallyUpdated) {
          draftState |= DraftItemStateEnum.IsTranslationEdited;
        } else {
          draftState &= ~DraftItemStateEnum.IsTranslationEdited;
        };
        //---------------------------------------------------------------------
        if (updatedName.length > 0)
          draftState &= ~DraftItemStateEnum.IsNoTranslation;
        //---------------------------------------------------------------------
        const updatedTag: IBusinessTagsDictionaryDraftItem = {
          ...tag as IBusinessTagsDictionaryDraftItem,
          name: newName,
          draftState: draftState,
          translationIsEdited: isActuallyUpdated
        };
        setTag(updatedTag);
      };
    };
  };
  //--------------------------------------------------------------------------- Apply changes
  const onConfirm = () => {
    if (tag) {
      // If parent was not changed, do not pass it
      let parentToPass = (parentTag?.path == initialParentPath.current) ? undefined : parentTag;
      parentToPass = isNew ? parentTag : parentToPass;
      props.onUpdate(tag, isNew, parentToPass);
    } else {
      console.error("Something is wrong: cannot pass changes because edited tag is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "optionOk":
        onConfirm();
        break;
      //-----------------------------------------------------------------------
      case "optionCancel":
        props.onCancel();
        break;
    };
  };
  //---------------------------------------------------------------------------
  var updatedOptions: TUiMenuOption[] = [];
  tmpOptionsMenu.options.forEach(option => {
    switch (option.id) {
      case "optionOk":
        const updatedOkOption: TUiMenuOption = {
          ...option,
          disabled: !isUpdated || !isValid
        };
        updatedOptions.push(updatedOkOption);
        break;
      //-----------------------------------------------------------------------
      case "optionCancel":
        updatedOptions.push(option);
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        screenType == ScreenType.Mobile ? styles.mobile : ""
      ])}
    >
      <div className={styles.form}>
        <ValidationWrapper
          validationState={{ isValid: true, validationMessages: [] }}
        >
          <BusinessTagParentEditor
            ui={tmpBusinessTagParentInput}
            data={props.parent}
            rootNodes={props.rootNodes}
            currentItemPath={tag?.path}
            onConfirm={isNew ? undefined : onUpdateParent}
          />
        </ValidationWrapper>
        <ValidationWrapper
          validationState={{ isValid: idIsValid, validationMessages: tagIdValidationMessages }}
        >
          <InputText
            ui={tmpInputId}
            data={tag?.id}
            disabled={!isNew}
            hideHint={true}
            onUpdate={onUpdateId}
          />
        </ValidationWrapper>
        <ValidationWrapper
          validationState={{ isValid: true, validationMessages: [] }}
        >
          <InputCheckbox
            ui={tmpInputIsGroup}
            data={!!tag?.isGroup}
            hideHint={true}
            onChange={onUpdateGroup}
          />
        </ValidationWrapper>
        <ValidationWrapper
          validationState={{ isValid: true, validationMessages: [] }}
        >
          <InputCheckbox
            ui={tmpInputItemsManualOrder}
            data={!!tag?.itemsManualOrder}
            hideHint={true}
            onChange={onUpdateManualOrder}
          />
        </ValidationWrapper>
        <ValidationWrapper
          validationState={{ isValid: nameIsValid, validationMessages: tagNameValidationMessages }}
        >
          <InputText
            ui={tmpInputName}
            data={tag?.name?.en}
            hideHint={true}
            disabled={!tag?.id}
            onUpdate={onUpdateName}
          />
        </ValidationWrapper>
      </div>

      <div className={styles.options}>
        <FormOptions
          ui={{ ...tmpOptionsMenu, options: updatedOptions }}
          applyMobileStyle={false}
          optionIdVisibleOnMobile='optionOk'
          onSelect={onOptionSelect}
        />
      </div>

    </div>
  );
}