import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../context/app-auth-context/AppAuthContext";
import { EHttpStatusCode } from "../../../utils/HttpStatusCodes";
import { TUserInfo } from "../../user-console/user-profile/UserInfo";
import { apiGetPrivate, apiPostPrivate, checkResponse, checkResponse2 } from "../../../utils/api";
import { apiBasePath, createLock, releaseLock } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
//----------------------------------------------------------------------------------
export type ELockType = 'Administrator' | 'Moderator' | 'SystemTranslator';
//----------------------------------------------------------------------------------
export interface ILockable {
  locker?: ILocker;
}
//----------------------------------------------------------------------------------
export interface ILocker {
  id: string;
  statusCode: EHttpStatusCode;
  owner: TUserInfo;
  lockType: ELockType;
  errorPrefix?: string;
  lockedAt: Date
  expiresAt: Date;
}
//----------------------------------------------------------------------------------
export class TLocker {
  id!: string;
  statusCode!: EHttpStatusCode;//string;
  owner!: TUserInfo;
  lockType!: ELockType;
  errorPrefix?: string;
  //---------------------------------------------------------------------------------
  private _lockedAt!: Date;
  get lockedAt(): Date {
    return this._lockedAt;
  }
  set lockedAt(value: any) {
    if (value)
      this._lockedAt = new Date(value);
  }
  //---------------------------------------------------------------------------------
  private _expiresAt!: Date;
  get expiresAt(): Date {
    return this._expiresAt;
  }
  set expiresAt(value: any) {
    if (value)
      this._expiresAt = new Date(value);
  }
  //---------------------------------------------------------------------------------
  constructor(source: any) {
    if (source) {
      Object.assign(this, source);
    }
  }
  //---------------------------------------------------------------------------------
  toJSON() {
    let result = {
      ...this,
      _lockedAt: null,
      _expiresAt: null,
      LockedAt: this._lockedAt,
      ExpiresAt: this._expiresAt,
    };
    delete result["_lockedAt"];
    delete result["_expiresAt"];
    return result;
  }
  //---------------------------------------------------------------------------------
  get isValid(): boolean {
    return this.statusCode == EHttpStatusCode.OK;
  }
  //---------------------------------------------------------------------------------
  get elementId(): string {
    return `locker_${this.id}`;
  }
  //---------------------------------------------------------------------------------
  getErrorPhrase(): string | undefined {
    if (this.statusCode == EHttpStatusCode.Conflict)//"Conflict")
      return `${this.errorPrefix ? this.errorPrefix : 'Document'} is locked by user ${this.owner.userPrincipalName} till 
        ${this.expiresAt.toLocaleString()}`;
    return undefined;
  }
  //---------------------------------------------------------------------------------
  static async createLock(
    id: string,
    timeSpanMinutes: number,
    lockType: ELockType,
    config?: IAuthConfig,
    instance?: IPublicClientApplication): Promise<TLocker | undefined> {
    //---------------------------------------------------------------------------------
    return new Promise((resolve, reject) => {
      if (!config || !instance) {
        return reject('Config or instanse is not set');
      }
      apiGetPrivate(
        instance,
        config,
        `${apiBasePath}${stringFormatter(createLock, [id, `${timeSpanMinutes}`, lockType])}`
      )
        .then((response) => {
          //checkResponse2(response, "createLock", { additionalStatuses: [EHttpStatusCode.Conflict] });
          checkResponse(response, "createLock", undefined, [EHttpStatusCode.Conflict]);
          resolve(new TLocker(response?.content));
        })
        .catch(error =>
          reject(error)
        );
    });
  }
  //---------------------------------------------------------------------------------
  releaseLock(config?: IAuthConfig, instance?: IPublicClientApplication) {
    if (!config || !instance)
      return;
    apiPostPrivate(
      instance,
      config,
      `${apiBasePath}${stringFormatter(releaseLock, [this.id])}`
    )
      .then(response => {
        checkResponse(response, `releaseLock [${this.id}]`);
        console.log(`Lock [${this.id}] was released successfully.`);
      })
      .catch((error) => {
        console.error(error);
      })
  }
}
