import styles from "./ConsoleMenuOption.module.css";
import { TUiMenuOption } from "../../../menu/menu-content/menu-option/TUiMenuOption";
import Icon, { IconSize } from "../../../icon/Icon";
import GetText from "../../../functions/GetText";
import {
  ScreenType,
  useAppScreenContext,
} from "../../../../../context/app-screen-context/AppScreenProvider";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";
import { useContext } from "react";
import CombineStyles from "../../../../../utils/combineStyles";
import GetHint from "../../../functions/GetHint";

interface IProps {
  ui: TUiMenuOption;
  isSelected: boolean;
  isCollapsed: boolean;
  isBlinking?: boolean;
  onSelect: (optionId: TUiMenuOption) => void;
}

export default function SidebarMenuOption(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  //---------------------------------------------------------------------------
  const computedStyle =
    (screenType === ScreenType.Mobile
      ? styles.containerMobile
      : styles.containerDesktop) +
    (props.isSelected ? " " + styles.selected : "");
  //---------------------------------------------------------------------------
  const toggleSelection = () => {
    if (!props.isSelected) props.onSelect(props.ui);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      title={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      className={CombineStyles([
        screenType === ScreenType.Mobile ? styles.containerMobile : styles.containerDesktop,
        props.isSelected ? " " + styles.selected : "",
        props.isBlinking ? styles.blink : ''
      ])}
      onClick={toggleSelection}>

      {props.ui.iconFile &&
        <Icon
          imageName={props.ui.iconFile}
          size={IconSize.Large}
          reserveSpace={true}
        />}

      {!props.isCollapsed &&
        <div className={styles.caption}>
          {GetText({
            appUiContext: appUiContext,
            elementId: props.ui.id,
            elementPart: "Caption",
            text: props.ui.caption,
          })}
        </div>}

    </div>
  );
}
