import { useContext } from 'react';
import { EditFormSection } from '../../../common/console-layout/edit-form-sections/EditFormSection';
import GetHint from '../../../common/functions/GetHint';
import { IUiSectionContacts } from './IUiSectionContacts';
import styles from './SectionContacts.module.css';
import ContactsEditor from './contacts-editor/ContactsEditor';
import { Contact } from './contact/Contact';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { BusinessContext } from '../../../../context/business-context/BusinessContextProvider';
import { ClassCustomerBusiness, ICustomerBusiness } from '../ICustomerBusiness';

interface IProps {
  id: string;
  ui: IUiSectionContacts;
}

export default function SectionContacts(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const business = businessContext.businessState?.business as ClassCustomerBusiness;
  //---------------------------------------------------------------------------
  const onUpdateContactsList = (updatedContactsList: Contact<string>[]) => {
    const updatedBusiness = new ClassCustomerBusiness({
      ...business as ICustomerBusiness,
      contacts: updatedContactsList
    });
    businessContextDispatch({ type: "UpdateBusiness", business: updatedBusiness });
  };
  //---------------------------------------------------------------------------
  return (
    <EditFormSection
      id={props.id}
      caption={GetHint(appUiContext, props.ui.id, props.ui.hint)}
    >
      <div className={styles.container}>
        <ContactsEditor
          ui={props.ui.inputListContacts}
          data={business?.contacts}
          onUpdate={onUpdateContactsList}
        />
      </div>
    </EditFormSection >
  );
}