import { ReactNode } from 'react';
import styles from './DialogOptions.module.css'

interface IProps {
  children: ReactNode;
}

export default function DialogOptions(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      {props.children}
    </div>
  );
}