import CombineStyles from '../../../../utils/combineStyles';
import IconAngle from '../../../common/icons/IconAngle';
import styles from './ReviewResultsHeader.module.css';

interface IProps {
  currentIndex: number;
  total: number;
  onMove: (step: number) => void;
}

export default function ReviewResultsHeader(props: IProps) {
  const nextIsDisabled = props.currentIndex == props.total - 1;
  const backIsDisabled = props.currentIndex == 0;
  //---------------------------------------------------------------------------
  const onMoveNext = () => {
    !nextIsDisabled && props.onMove(1);
  };
  //---------------------------------------------------------------------------
  const onMoveBack = () => {
    !backIsDisabled && props.onMove(-1);
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.caption}>
        Issue {props.currentIndex + 1} of {props.total}
      </div>
      <div className={styles.options}>
        <div
          className={CombineStyles([
            styles.option,
            backIsDisabled ? styles.disabled : ''
          ])}
          onClick={onMoveBack}
        >
          <IconAngle pointTo='Left' />
        </div>
        <div
          className={CombineStyles([
            styles.option,
            nextIsDisabled ? styles.disabled : ''
          ])}
          onClick={onMoveNext}
        >
          <IconAngle pointTo='Right' />
        </div>
      </div>
    </div>
  );
}