import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import styles from './BusinessLocationEditor.module.css';
import GoogleMap from '../../../../common/google-maps/google-map/GoogleMap';
import { IBusinessLocationUpdate, IBusinessMarker } from '../../../../common/google-maps/business-markers/BusinessMarkers';
import { TGeoPoint } from '../../../../common/map/geo-json/GeoJson';
import { ClassBusinessLocationData, IBusinessLocationData, IObjectLocation, newId } from './IBusinessLocationData';
import { EditFormState } from '../../../../common/edit-form/IEditFormState';
import { tmpUiEditFormLocation } from './business-location-editform/IUiEditFormLocation';
import EditFormBusinessLocation from './business-location-editform/EditFormBusinessLocation';
import ListItemBusinessLocation, { IHighlightedItem } from './business-location-listItem/ListItemBusinessLocation';
import { v4 as uuidv4 } from "uuid";
import { IUiBusinessLocationEditor } from './IUiBusinessLocationEditor';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import CombineStyles from '../../../../../utils/combineStyles';
import OptionLink from '../../../../common/options/option-link/OptionLink';
import { EOptionsLocation } from '../../../../ad-content-editor/ad-modules-editor/AdModuleOptions';
import { ScreenType, useAppScreenContext } from '../../../../../context/app-screen-context/AppScreenProvider';
import OptionDefaultBounds from './option-default-bounds/OptionDefaultBounds';
import { IMapArea } from '../../../../common/google-maps/map-itself/MapItself';

export interface IMapViewport {
  // center/zoom or bounds can be set, not both at the same time
  center?: google.maps.LatLngLiteral; // [lng, lat]
  zoom?: number;
  bounds?: google.maps.LatLngBoundsLiteral;
  fitBusinessMarkers?: boolean;
}

interface IProps {
  ui: IUiBusinessLocationEditor;
  businessId: string;
  data?: ClassBusinessLocationData;
  addressBlockMode?: boolean;
  isReadonly?: boolean;
  highlightedItem?: IHighlightedItem;
  hideMapBoundsOption?: boolean;
  locationIsRequired?: boolean; // For business wizard
  onLocationOptionSelect?: (optionId: EOptionsLocation, locationId?: string) => void;
  onUpdate?: (updatedLocationData: IBusinessLocationData<string>) => void;
}

export default function BusinessLocationEditor(props: IProps) {
  const { appAuthContext } = useContext(AppAuthContextStore);
  const screenType = useAppScreenContext();
  const [state, setState] = useState<EditFormState<ClassBusinessLocationData>>();
  const [mapViewport, setMapViewport] = useState<IMapViewport>();
  const [locationToEdit, setLocationToEdit] = useState<IObjectLocation<string>>();
  const [highlightedLocation, setHighlightedLocation] = useState<string>();
  const userLocation = appAuthContext.userLocation?.position;
  const currentZoom = useRef<number>();
  const currentBounds = useRef<google.maps.LatLngBoundsLiteral>();
  const addressBlockMode = props.addressBlockMode == undefined ? false : props.addressBlockMode;
  const isReadonly = props.isReadonly == undefined ? false : props.isReadonly;
  const optionLocationSelect = props.ui.menuContent.options.find(option => option.id == "OptionSelect");
  //--------------------------------------------------------------------------- Initialise state, new location
  useEffect(() => {
    if (state) {
      // If state is already set (something is changed from outside, e.g. new location is added)
      setState(new EditFormState<ClassBusinessLocationData>(state, {
        object: props.data,
        isValid: true
      }));
    } else {
      // If it's a first render (state is not set)
      const newState = new EditFormState<ClassBusinessLocationData>({
        object: new ClassBusinessLocationData(props.data as IBusinessLocationData<string>),
        initialState: JSON.parse(JSON.stringify(props.data)),
        isValid: true,
        isUpdated: false,
        isNew: false
      });
      setState(newState);
    };
    //-------------------------------------------------------------------------
    // Check if there is a new location to edit
    const newLocation = props.data?.locations.find(location => location.id == newId);
    if (newLocation) {
      setLocationToEdit(newLocation);
    };
    //-------------------------------------------------------------------------
    if (screenType == ScreenType.Mobile) {
      // Make one location highlighted by default on mobile
      setHighlightedLocation(props.data?.locations[0]?.id);
    };
  }, [props.data]);
  //--------------------------------------------------------------------------- Map viewport
  useEffect(() => {
    if (!state?.object) return;
    const locationData = new ClassBusinessLocationData(state?.object as IBusinessLocationData<string>);
    // Do not include user location in the viewport if there are business locations (for now)
    const mapViewport: IMapViewport = locationData.getMapViewPort(userLocation);
    setMapViewport(mapViewport);
  }, [state?.object, userLocation]);
  //---------------------------------------------------------------------------
  const onBusinessMarkerClick = (businessId: string, locationId: string) => {
    setHighlightedLocation(locationId);
  };
  //---------------------------------------------------------------------------
  const updateBusinessLocations = () => {
    if (!state?.object) return;
    if (!locationToEdit) return;
    if (locationToEdit.id == newId) {
      // Add new location to the list
      const newLocation = {
        ...locationToEdit,
        id: uuidv4()
      };
      const updatedLocations = state.object.locations.map(location => location.id == newId ? newLocation : location);
      //const updatedLocations = [...state.object.locations];
      const updatedLocationData = new ClassBusinessLocationData({
        ...state.object,
        locations: updatedLocations,
        mapSettings: {
          defaultZoom: undefined
        }
      });
      setState(new EditFormState<ClassBusinessLocationData>(state, {
        object: updatedLocationData,
        isValid: true
      }));
      passUpdates(updatedLocationData);
    } else {
      //-----------------------------------------------------------------------
      // Existing location is edited
      const updatedLocations = state.object.locations.map(location => location.id == locationToEdit.id ? locationToEdit : location);
      const updatedLocationData = new ClassBusinessLocationData({
        ...state.object,
        locations: updatedLocations
      });
      setState(new EditFormState<ClassBusinessLocationData>(state, {
        object: updatedLocationData,
        isValid: true
      }));
      passUpdates(updatedLocationData);
    };
  };
  //---------------------------------------------------------------------------
  const passUpdates = (updatedLocationData: ClassBusinessLocationData) => {
    setLocationToEdit(undefined);
    props.onUpdate && props.onUpdate(updatedLocationData);
  };
  //---------------------------------------------------------------------------
  const onMapOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionMapBoundsSave":
        setDefaultBounds(false);
        break;
      //-----------------------------------------------------------------------
      case "OptionMapBoundsAuto":
        setDefaultBounds(true);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const setDefaultBounds = (auto: boolean) => {
    if (!state) return;
    if (!currentBounds.current) return;
    const updatedLocationData = new ClassBusinessLocationData({
      ...state?.object as IBusinessLocationData<string>,
      mapSettings: {
        defaultBounds: auto ? undefined : currentBounds.current,
      }
    });
    setState(new EditFormState<ClassBusinessLocationData>(state, {
      object: updatedLocationData,
      isValid: true
    }));
    passUpdates(updatedLocationData);
  };
  //---------------------------------------------------------------------------
  const onMapAreChange = (updatedMapArea: IMapArea) => {
    currentBounds.current = updatedMapArea.bounds;
  };
  //--------------------------------------------------------------------------- Zoom
  const onMapZoomUpdate = (updatedZoom: number) => {
    // Keep it in ref for the moment user decided to save it
    currentZoom.current = updatedZoom;
  };
  //---------------------------------------------------------------------------
  const updateZoom = () => {
    if (!state) return;
    if (!currentZoom.current) return;
    console.log(currentZoom.current);
    const updatedLocationData: IBusinessLocationData<string> = {
      ...state?.object as IBusinessLocationData<string>,
      mapSettings: {
        defaultZoom: currentZoom.current
      }
    };
    /* setState(new EditFormState<IBusinessLocationData<string>>(state, {
      object: updatedLocationData,
      isValid: true
    }));
    passUpdates(updatedLocationData); */
  };
  //---------------------------------------------------------------------------
  const setZoomAuto = () => {
    if (!state) return;
    const updatedLocationData: IBusinessLocationData<string> = {
      ...state?.object as IBusinessLocationData<string>,
      mapSettings: {
        defaultZoom: undefined
      }
    };
    /* setState(new EditFormState<IBusinessLocationData<string>>(state, {
      object: updatedLocationData,
      isValid: true
    }));
    passUpdates(updatedLocationData); */
  };
  //--------------------------------------------------------------------------- Location Point
  const onLocationPointUpdate = (businessLocationUpdate: IBusinessLocationUpdate) => {
    if (!locationToEdit) return;
    const updatedLocation: IObjectLocation<string> = {
      ...locationToEdit,
      point: new TGeoPoint({
        coordinates: businessLocationUpdate.location.coordinates
      })
    };
    setLocationToEdit(updatedLocation);
  };
  //---------------------------------------------------------------------------
  const onLocationPointSet = (position: google.maps.LatLngLiteral) => {
    if (!locationToEdit) return;
    const updatedLocation: IObjectLocation<string> = {
      ...locationToEdit,
      point: new TGeoPoint({ coordinates: [position.lng, position.lat] })
    };
    setLocationToEdit(updatedLocation);
  };
  //--------------------------------------------------------------------------- Business Location
  const onLocationEdit = (locationId: string) => {
    if (!state?.object) return;
    const selectedLocation = state.object.locations.find(location => location.id == locationId);
    setLocationToEdit(selectedLocation);
  };
  //---------------------------------------------------------------------------
  const onLocationEditCancel = () => {
    console.log(locationToEdit)
    setLocationToEdit(undefined);
    if (!state) return;
    // Remove new location from the list if there is one
    const locations = state?.object?.locations;
    const updatedLocations = locations?.filter(location => location.id != newId);
    if (locations && updatedLocations && updatedLocations?.length !== locations?.length) {
      const updatedObject = new ClassBusinessLocationData({
        ...state.object as IBusinessLocationData<string>,
        locations: updatedLocations
      });
      const newState = new EditFormState<ClassBusinessLocationData>(state, {
        ...state,
        object: updatedObject
      });
      setState(newState);
      passUpdates(updatedObject);
    };
  };
  //---------------------------------------------------------------------------
  const onLocationDelete = (locationId: string) => {
    if (!state?.object) return;
    const updatedLocations = state.object.locations.filter(location => location.id != locationId);
    const updatedLocation = new ClassBusinessLocationData({
      ...state.object,
      locations: updatedLocations
    });
    setState(new EditFormState<ClassBusinessLocationData>(state, {
      object: updatedLocation,
      isValid: true
    }));
    passUpdates(updatedLocation);
  };
  //---------------------------------------------------------------------------
  const onLocationUpdate = (updatedLocation: IObjectLocation<string>) => {
    setLocationToEdit(updatedLocation);
  };
  //---------------------------------------------------------------------------
  const onHighlightLocationUpdate = (locationId: string, isHovered: boolean) => {
    if (isHovered) {
      setHighlightedLocation(locationId);
    } else {
      setHighlightedLocation(undefined);
    };
  };
  //---------------------------------------------------------------------------
  const addNewLocation = (locationId: string) => {
    if (!state?.object) return;
    // locationId here - is a location new location should be placed after
    console.log(locationId);
    const updatedLocationData = state?.object?.addNewLocationAt(locationId);
    setState(new EditFormState<ClassBusinessLocationData>(state, {
      object: updatedLocationData,
      isValid: true
    }));
    passUpdates(updatedLocationData);
  };
  //---------------------------------------------------------------------------
  const onLocationOptionSelect = (optionId: string, locationId: string) => {
    switch (optionId) {
      case "OptionEdit":
        onLocationEdit(locationId);
        break;
      //-----------------------------------------------------------------------
      case "OptionAdd":
        if (!addressBlockMode) {
          addNewLocation(locationId);
        };
        break;
      //-----------------------------------------------------------------------
      case "OptionRefresh":
        if (addressBlockMode) {
          props.onLocationOptionSelect && props.onLocationOptionSelect("LocationRefresh", locationId);
        };
        break;
      //-----------------------------------------------------------------------
      case "OptionDelete":
        if (addressBlockMode) {
          props.onLocationOptionSelect && props.onLocationOptionSelect("LocationExclude", locationId);
        } else {
          onLocationDelete(locationId);
        };
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onLocationSelect = () => {
    if (!addressBlockMode) return;
    props.onLocationOptionSelect && props.onLocationOptionSelect("LocationSelect");
  };
  //---------------------------------------------------------------------------
  const businessMarkers: IBusinessMarker[] = [];
  state?.object?.locations.filter(location =>
    location.point
  ).map(location =>
    location.id == locationToEdit?.id ? locationToEdit : location
  ).forEach(location => {
    const businessMarker: IBusinessMarker = {
      businessId: props.businessId,
      locationId: location.id,
      name: location.name ?? location.id,
      geolocation: new TGeoPoint(location.point),
      isEditable: location.id == locationToEdit?.id,
      isHighlighted: location.id == highlightedLocation
    };
    businessMarkers.push(businessMarker);
  });
  //---------------------------------------------------------------------------
  const locationsListElements = state?.object?.locations.map(location => (
    <ListItemBusinessLocation
      key={location.id}
      ui={props.ui.menuContent}
      data={location}
      addressBlockMode={addressBlockMode}
      isReadonly={isReadonly}
      isSelected={location.id == highlightedLocation}
      highlightedItem={props.highlightedItem}
      onOptionSelect={onLocationOptionSelect}
      onHover={onHighlightLocationUpdate}
    />
  ));
  //---------------------------------------------------------------------------
  let mapBoundsOption: ReactNode = null;
  if (!props.hideMapBoundsOption) {
    mapBoundsOption =
      <OptionDefaultBounds
        ui={props.ui.menuContent}
        data={state?.object?.mapSettings}
        onSelect={onMapOptionSelect}
      />;
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <div className={styles.map}>
        <GoogleMap
          businesses={businessMarkers}
          currentLocation={userLocation ?
            { lat: userLocation.coords.latitude, lng: userLocation.coords.longitude } :
            undefined}
          isSearchEnabled={locationToEdit ? true : false}
          defaultMapArea={{
            bounds: mapViewport?.bounds,
            fitBusinessMarkers: mapViewport?.fitBusinessMarkers,
            center: mapViewport?.center,
            zoom: mapViewport?.zoom
          }}
          onBusinessLocationUpdate={addressBlockMode ? undefined : onLocationPointUpdate}
          onBusinessMarkerClick={onBusinessMarkerClick}
          onMapClick={locationToEdit ? onLocationPointSet : undefined}
          onZoomChange={onMapZoomUpdate}
          onMapAreaChange={onMapAreChange}
        />
      </div>

      {locationToEdit ?
        <div className={styles.editFormWrapper}>
          <EditFormBusinessLocation
            ui={tmpUiEditFormLocation}
            data={locationToEdit}
            addressBlockMode={addressBlockMode}
            locationIsRequired={props.locationIsRequired}
            onUpdate={onLocationUpdate}
            onConfirm={updateBusinessLocations}
            onClose={onLocationEditCancel}
          />
        </div>
        :
        !props.isReadonly && mapBoundsOption}

      {!locationToEdit &&
        <div className={CombineStyles([
          styles.list,
          addressBlockMode ? styles.addressBlock : ''
        ])}>

          {locationsListElements}

        </div>}

      {!isReadonly && props.addressBlockMode && !locationToEdit && optionLocationSelect &&
        <div className={styles.option}>
          <OptionLink
            ui={optionLocationSelect}
            onSelect={onLocationSelect}
          />
        </div>}

    </div>
  );
}