import { IDictionaryDraftAbstractUpdate, IDraftStateResult, IDirectorySummary } from "../../../../../context/directory-editor-context/IDirectoryDraftAbstract";
import { TLocker } from "../../../../common/locks/TLocker";
import { IWorkflowLog } from "../../../common/workflows/IWorkflowLog";
import { IDictionaryDraft } from "../../dictionaries/common-dictionary/IDictionaryDraft";
import { BusinessTagsDictionaryDraftItem } from "./IBusinessTagsDictionaryDraftItem";

export interface IBusinessTagsDictionaryDraft extends IDictionaryDraft<BusinessTagsDictionaryDraftItem> {
}

export class BusinessTagsDictionaryDraft implements IBusinessTagsDictionaryDraft {
  id!: string;
  dictionaryId!: string;
  locale!: string;
  name?: string;
  description?: string;
  isHierarchical!: boolean;
  isSortedManually!: boolean;
  rootIsLocked!: boolean;
  items!: BusinessTagsDictionaryDraftItem[];
  datetimeSaved?: string;
  log?: IWorkflowLog;
  locker?: TLocker;
  summary!: IDirectorySummary;
  original!: IBusinessTagsDictionaryDraft;
  //---------------------------------------------------------------------------
  initialize(source: IBusinessTagsDictionaryDraftInitial): BusinessTagsDictionaryDraft {
    const draft = new BusinessTagsDictionaryDraft();
    Object.assign(draft, source);
    draft.id = source.dictionaryId;
    //-------------------------------------------------------------------------
    draft.items = source.items.map(item => new BusinessTagsDictionaryDraftItem(item));
    //-------------------------------------------------------------------------
    return draft;
  };
  //---------------------------------------------------------------------------
  getUpdated(update: IBusinessTagsDictionaryDraftUpdate): BusinessTagsDictionaryDraft {
    //console.log(update);
    const updatedDraft = new BusinessTagsDictionaryDraft();
    Object.assign(updatedDraft, this, update);
    //console.log(updatedDraft);
    if (update.id) updatedDraft.dictionaryId = update.id;
    return updatedDraft;
  };
  //---------------------------------------------------------------------------
  checkChangesAndValidate(initial: IBusinessTagsDictionaryDraft): IDraftStateResult {
    let isUpdatedInSession = false;
    let isUpdated = false;
    let isValid = true;
    //-------------------------------------------------------------------------
    if (this.name !== initial.name || this.description !== initial.description) {
      isUpdatedInSession = true;
    };
    //-------------------------------------------------------------------------
    if (this.items.length !== initial.items.length) {
      isUpdatedInSession = true;
    };
    //-------------------------------------------------------------------------
    if (this.name !== this.original.name || this.description !== this.original.description) {
      //console.log('1');
      isUpdated = true;
    };
    //-------------------------------------------------------------------------
    const itemsCount = this.items.filter(item => !item.subDictionaryId).length;
    if (itemsCount !== this.original.items.length) {
      //console.log('2');
      isUpdated = true;
    };
    //-------------------------------------------------------------------------
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      const initialItem = initial.items.find(initialItem => initialItem.fullPath === item.fullPath);
      const draftState: IDraftStateResult = item.checkChangesAndValidate(initialItem);
      isUpdatedInSession = isUpdatedInSession || draftState.isUpdatedInSession;
      isUpdated = isUpdated || draftState.isUpdated;
      isValid = isValid && draftState.isValid;
    };
    //-------------------------------------------------------------------------
    return {
      isUpdatedInSession,
      isUpdated,
      isValid
    };
  };
  //---------------------------------------------------------------------------
  pathIsUnique(path: string): boolean {
    return this.items.findIndex(item => item.fullPath === path) === -1;
  };
  //---------------------------------------------------------------------------
  clone(): BusinessTagsDictionaryDraft {
    throw new Error("Method not implemented.");
  };
}

export interface IBusinessTagsDictionaryDraftInitial {
  dictionaryId: string;
  locale: string;
  name?: string;
  description?: string;
  isHierarchical: boolean;
  isSortedManually: boolean;
  rootIsLocked: boolean;
  items: BusinessTagsDictionaryDraftItem[];
};

export interface IBusinessTagsDictionaryDraftUpdate extends IDictionaryDraftAbstractUpdate {
  dictionaryId?: string;
  name?: string;
  description?: string;
  isHierarchical?: boolean;
  isSortedManually?: boolean;
  rootIsLocked?: boolean;
  items?: BusinessTagsDictionaryDraftItem[];
  locker?: undefined;
}