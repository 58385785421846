import { useContext, useEffect, useState } from "react";
import { Flags } from "../../common/icon/FlagIcon";
import styles from "./LanguageBarText.module.css";
import { SearchLanguageGroupId } from "./SearchLanguageGroupId";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";
import { TSearchLanguageGroups } from "./TSearchLanguage";
import { SearchLanguageGroupName } from "./SearchLanguageGroupName";
import { AppAuthContextStore } from "../../../context/app-auth-context/AppAuthContext";

interface IProps {
  parentKey: string;
  locales: string[];
  selectedLocale: string;
  onSelectLocale?: (locale: string) => void;
}
//------------------------------------------------------------------------------------
export function LanguageBarText(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [highlightedLocale, setHighlightedLocale] = useState("");
  const [languageGroups, setLanguageGroups] = useState<TSearchLanguageGroups>();
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!props.locales || !props.selectedLocale || !appUiContext.dictionaries?.locales) {
      return;
    }
    let flags = new Flags(props.locales);
    let languageGroups = new TSearchLanguageGroups(props.locales, appUiContext, flags);
    //languageGroups.moveToTop(props.selectedLocale);
    languageGroups.sortByContext();
    setLanguageGroups(languageGroups)
  }, [props.locales, props.selectedLocale, appUiContext.dictionaries?.locales]);
  //---------------------------------------------------------------------------
  const onMouseEnter = (locale: string) => {
    setHighlightedLocale(locale);
  };
  //---------------------------------------------------------------------------
  const onMouseLeave = () => {
    setHighlightedLocale("");
  };
  //---------------------------------------------------------------------------
  const onClick = (locale: string) => {
    props.onSelectLocale && props.onSelectLocale(locale);
    setHighlightedLocale("");
  }
  //---------------------------------------------------------------------------
  let groups = languageGroups?.items.map(group =>
    //<SearchLanguageGroupId
    <SearchLanguageGroupName
      key={`LG_${group.id}`}
      parentKey={props.parentKey}
      languageGroup={group}
      selectedLocale={props.selectedLocale}
      highlightedLocale={highlightedLocale}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    />);
  //---------------------------------------------------------------------------
  return (
    <div className={styles.columnContainer} >
      <div className={styles.rowContainer} onMouseLeave={onMouseLeave} >
        {groups}
      </div>
    </div>
  )
}