import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { apiBasePath, pathPublishBusinessTagsDictionaryDraft } from "../../../../../utils/apiPathConstant";
import { BusinessTagsDictionaryDraft } from "../business-tags-editor/IBusinessTagsDictionaryDraft";
import { IDictionary } from "../../../../../context/directory-editor-context/IDirectoryEditorContextData";
import { IBusinessTagsDictionaryResponseContent } from "./IBusinessTagsDictionaryResponseContent";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
}

export async function publishBusinessTagsDictionaryDraftAsync(props: IProps): Promise<IBusinessTagsDictionaryResponseContent> {
  if (!props.authConfig) throw new Error("publishBusinessTagsDictionaryDraftAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const response = await apiPostPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${pathPublishBusinessTagsDictionaryDraft}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "publishBusinessTagsDictionaryDraftAsync", props.abortSignal?.aborted);
  const draft = new BusinessTagsDictionaryDraft().initialize(response?.content.draft);  
  return {
    draft: draft,
    subDictionariesList: response?.content.subDictionariesList,
    subDictionaryOriginals: response?.content.subDictionaryOriginals as IDictionary[]
  };
}