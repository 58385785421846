import { TUiBreadcrumb } from "../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { IUiListItem } from "../../../../common/input/input-dropdown/InputDropdown";
import { pathAdminConsole, pathAdministratorConsole } from "../../../AdminConsole";
import { DraftItemStateEnum } from "../../../common/dictionary-draft-models/DraftItemStateEnum";
import { IDictionaryEditorState } from "../../../common/dictionary-editor-models/IDictionaryEditorState";
import { IDictionaryDraftFilter } from "../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter";
import { pathDictionaries } from "../../AdministratorConsole";
import { dictonaryDraftStatesFullDetails } from "../common/settings/dictonaryDraftStatesFullDetails";
import { AdRanksDictionaryDraftItem, IAdRanksDictionaryDraft, IAdRanksDictionaryDraftItem } from "./AdRanksDictionaryDraft";

export interface IDictionaryEditorStateInitial {
  dictionaryDraft: IAdRanksDictionaryDraft;
  editMode: boolean;
}

export interface IDictionaryEditorStateUpdate {
  state: IAdRanksEditorState;
  spread: IAdRanksEditorStateSpread;
}

export interface IAdRanksDictionaryItem extends IUiListItem {
}

export interface IAdRanksEditorState extends IDictionaryEditorState<IAdRanksDictionaryDraft> {
}

export interface IAdRanksEditorStateSpread {
  filter?: IDictionaryDraftFilter;
  editModeIsEnabled?: boolean;
  draft?: IAdRanksDictionaryDraft;
  isUpdatedInSession?: boolean; // To know if we can save draft
  isUpdated?: boolean;  // As compared to published version
  isValid?: boolean;  // To know if we can save
  isReadyToPublish?: boolean;
}

export class AdRanksEditorState implements IAdRanksEditorState {
  basePath!: string;
  numberOfApprovals!: number;
  filter!: IDictionaryDraftFilter;
  editModeIsEnabled!: boolean;
  breadcrumb?: TUiBreadcrumb;
  //-----------------------------------
  draft!: IAdRanksDictionaryDraft;
  draftSessionInitial!: IAdRanksDictionaryDraft;
  //-----------------------------------
  isUpdatedInSession!: boolean; // To know if we can save draft
  isUpdated!: boolean;  // As compared to published version
  isValid!: boolean;  // To know if we can save
  isReadyToPublish!: boolean;
  //---------------------------------------------------------------------------
  constructor(
    initialData?: IDictionaryEditorStateInitial,
    update?: IDictionaryEditorStateUpdate
  ) {
    if (initialData) {
      // State is being initialized
      //console.log("first")
      Object.assign(this, {
        basePath: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}`,
        numberOfApprovals: 2,
        filter: {
          draftState: DraftItemStateEnum.All,
          stateGroups: dictonaryDraftStatesFullDetails,
          selectedGroups: dictonaryDraftStatesFullDetails.map(g => g.id), // switch on all the options (groups)
          searchById: false
        },
        editModeIsEnabled: initialData.editMode,
        //-------------------------------
        breadcrumb: {
          id: initialData.dictionaryDraft.dictionaryId,
          caption: { en: `${initialData.dictionaryDraft.dictionaryId}` },
          path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}/${initialData.dictionaryDraft.dictionaryId}`
        },
        //-------------------------------
        draft: initialData.dictionaryDraft,
        draftSessionInitial: JSON.parse(JSON.stringify(initialData.dictionaryDraft)),
        //-------------------------------
        isUpdatedInSession: false,
        isUpdated: false,
        isValid: true // ATTENTION: Temporary
      });
      this.setIsReadyToPublish();
    } else if (update) {
      //-----------------------------------------------------------------------
      // State is being updated
      //console.log("not first")
      Object.assign(this, update.state, update.spread);
      //console.log(this)
      //---------------------------------
      // Check if there are changes compared to initial state
      this.isUpdated = this.getIsUpdatedInSession();
      //---------------------------------
      // Check actual changes compared to published version
      // Option Publish should be enabled only if there are changes compared to published version
      //this.isUpdated = false;
      this.setIsReadyToPublish();
    } else console.error("Invalid constructor AdRanksEditorState call");
  }
  //---------------------------------------------------------------------------
  getIsUpdatedInSession(): boolean {
    let result = false;
    const propertiesToCheck = ['name', 'description'];
    type DraftObjectKey = keyof IAdRanksDictionaryDraft;
    for (let i = 0; i < propertiesToCheck.length; i++) {
      const key = propertiesToCheck[i] as DraftObjectKey;
      const value1 = this.draft[key];
      const value2 = this.draftSessionInitial[key];
      if (value1 !== value2) {
        return true;
      };
    };
    //---------------------------------
    if (this.draft.items.length !== this.draftSessionInitial.items.length) {
      return true;
    } else {
      // Compare items
      const itemPropertiesToCheck = ['deleted', 'description', 'id', 'index', 'isActive', 'name', 'path', 'pathNew', 'pathOld', 'pointsPerEngagement', 'pointsPerView'];
      type DraftItemObjectKey = keyof IAdRanksDictionaryDraftItem;
      for (let j = 0; j < this.draft.items.length; j++) {
        for (let i = 0; i < itemPropertiesToCheck.length; i++) {
          const key = itemPropertiesToCheck[i] as DraftItemObjectKey;
          if (this.draft.items[j][key] !== this.draftSessionInitial.items[j][key]) {
            return true;
          };
        };
      };
    };
    //---------------------------------
    return result;
  };
  //---------------------------------------------------------------------------
  setIsReadyToPublish() {
    if (!this.draft.publishedVersion) {
      console.error("setIsReadyToPublish: Cannot update state, published data is not provided!");
    }
    // 0. Current user can publish: this check is done by DictionaryEditorWrapper
    // 1. There are changes compared to published version
    // 2. No error draft states
    this.isReadyToPublish = false;
    let isChanged = false;
    let isValid = false;
    const dictionaryPublished = this.draft.publishedVersion as IAdRanksDictionaryDraft;
    //---------------------------------
    // Check root properties: whether they are changed and valid
    // if (this.dictionaryDraft.name !== dictionaryPublished.name || this.dictionaryDraft.description !== dictionaryPublished.description) {
    //   isChanged = true;
    // };
    isValid = true; // For now consider all root properties valid
    if (!isChanged && isValid) {
      // Check items collection length
      if (this.draft.items.length !== dictionaryPublished.items.length) {
        isChanged = true;
      };
      const publishedITemsLength = dictionaryPublished.items.length;
      // Run through items to check validity (and changes)
      for (let i = 0; i < this.draft.items.length; i++) {
        const item = new AdRanksDictionaryDraftItem(this.draft.items[i]);
        if (!item.isValid()) {
          isValid = false;
          break;
        } else if (!isChanged) {
          // Do it only if it's not already determined as changed
          const publishedItem = (i < publishedITemsLength) ? dictionaryPublished.items[i] : undefined;
          if (publishedItem) {
            // It shouldn't be the case here, just for compilator
            isChanged =
              item.name !== publishedItem?.name ||
              item.description !== publishedItem?.description ||
              item.pointsPerView !== publishedItem?.pointsPerView ||
              item.pointsPerEngagement !== publishedItem.pointsPerEngagement ||
              item.deleted !== publishedItem.deleted;
            if (isChanged) break;
          };
        };
      };
    };
    //this.isUpdated = isChanged;
    this.isReadyToPublish = isChanged && isValid;
  };
}