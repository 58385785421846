import CombineStyles from "../../../utils/combineStyles";
import FlagIcon from "../../common/icon/FlagIcon";
import styles from "./SearchLanguage.module.css";
import { TSearchLanguage } from "./TSearchLanguage";

interface IProps {
  language: TSearchLanguage;
  selectedLocale: string;
  highlightedLocale: string;
  onClick: (locale: string) => void;
  onMouseEnter?: (locale: string) => void;
}
export function SearchLanguage(props: IProps) {
  //---------------------------------------------------------------------------------
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    props.onClick(props.language.id);
  }
  //---------------------------------------------------------------------------------
  const onMouseEnter = () => {
    props.onMouseEnter && props.onMouseEnter(props.language.id);
  }
  //---------------------------------------------------------------------------------
  let text = props.language.dictionaryItem?.name.native;
  if (!text) {
    text = props.language.id;
  }
  //---------------------------------------------------------------------------------
  const selected = props.language.id == props.selectedLocale;
  const highlighted = props.language.id == props.highlightedLocale;
  const containerStyles = CombineStyles([
    styles.container,
    highlighted ? styles.highlighted : selected ? styles.selected : ""
  ]);
  //---------------------------------------------------------------------------------
  return (
    <div
      className={containerStyles}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {props.language.flag &&
        <FlagIcon
          flag={props.language.flag}
          selected={selected || highlighted}
          //onClick={props.onClick}
          //onMouseEnter={props.onMouseEnter}
        />}
      {text}
    </div>
  );
}