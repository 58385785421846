import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiDeletePrivate, checkResponse } from "../../../../../utils/api";
import { apiBasePath, pathDeleteSubDictionaryDraft } from "../../../../../utils/apiPathConstant";
import { CustomDictionaryDraft } from "../../dictionaries/common-dictionary/IDictionaryDraft";
import { stringFormatter } from "../../../../../utils/common";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  dictionaryId: string;
}

export async function deleteCustomDictionaryDraftAsync(props: IProps): Promise<CustomDictionaryDraft | undefined> {
  if (!props.authConfig) throw new Error("deleteDictionaryDraftAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const response = await apiDeletePrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${stringFormatter(pathDeleteSubDictionaryDraft, [props.dictionaryId])}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "deleteDictionaryDraftAsync", props.abortSignal?.aborted);
  let draft = undefined;
  if (response?.content) {
    draft = new CustomDictionaryDraft().initialize(response?.content);
  };
  return draft;
}