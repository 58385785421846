import { IListColumn } from "../../lists/IListColumn";

export const adModulesListListColumns: IListColumn[] = [{
  id: "adModuleType",
  name: "Module Type",
  filter: {
    type: "Options",
    options: []
  }
}, {
  id: "localeId",
  name: "Locale",
  filter: {
    type: "Options",
    options: []
  }
}, {
  id: "status",
  name: "Status",
  filter: {
    type: "Options",
    options: []
  }
}, {
  id: "links",
  name: "Links",
  filter: {
    type: "Options",
    options: [{
      id: "Orphaned"
    }, {
      id: "Has more than one link"
    }],
  }
}]