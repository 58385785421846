import { apiGet, checkResponse } from "../../../utils/api";
import { apiBasePath, pathGetBusinessPage } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
import { IAdLanguageSet } from "../../ad-content-editor/IAdLanguageSet";
import { ClassAdLanguageSetPublished } from "../ClassAdLanguageSetPublished";

interface IGetBusinessPageResponse {
  regionalHomepage?: ClassAdLanguageSetPublished;
  userBuisnessPage?: ClassAdLanguageSetPublished;
}

interface IProps {
  businessPath?: string;
  localeId?: string;
  abortSignal?: AbortSignal;
}

export async function getBusinessPageAsync(props: IProps): Promise<IGetBusinessPageResponse> {
  //---------------------------------------------------------------------------
  if (!props.businessPath) throw new Error("getBusinessPageAsync: Ad Language Set Id is not provided");
  if (!props.localeId) throw new Error("getBusinessPageAsync: Ad campaign Id is not provided");
  //---------------------------------------------------------------------------
  const response = await apiGet(
    `${apiBasePath}${stringFormatter(pathGetBusinessPage, [props.businessPath, props.localeId])}`,
    props.abortSignal
  );
  checkResponse(response, "getBusinessPageAsync", props.abortSignal?.aborted);
  //---------------------------------------------------------------------------
  // Return response
  const adLanguageSet = new ClassAdLanguageSetPublished(response?.content.ad as IAdLanguageSet);
  if (response.content.businessType == "SystemBusinessRegional") {
    return {
      regionalHomepage: adLanguageSet,
      userBuisnessPage: undefined
    };
  } else {
    return {
      regionalHomepage: undefined,
      userBuisnessPage: adLanguageSet
    };
  };
}