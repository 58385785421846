import { IAppUiContext } from "../../../context/app-ui-context/AppUiContextProvider";

//-----------------------------------------------------------------------------
// Fallback logic example:
// 2: de-ch --> id
// 1: de-ch --> de --> id
// 0: de-ch --> de --> en --> id
const defaultLocale = "en";

export function GetParentLocale(locale: string) {
  // This function should return the highest parent locale in hierarchy
  var parentLocale = locale;
  while (parentLocale.includes("-")) {
    parentLocale = parentLocale.split("-")[0];
  }
  if (parentLocale === locale) return defaultLocale;
  else return parentLocale;

  // NOTE: For proper locale determination es2020 is needed:
  // let locale = new Intl.Locale("en-Latn-US");
  // console.log(locale.language); // Prints "en"
}

export default function GetTranslation(
  appUiContext: IAppUiContext,
  textProperty: any,
  elementId: string,
  elementPart?: string,
  forceLocale?: string
) {
  //---------------------------------------------------------------------------
  // Get locale settings from context
  //const { appUiContext } = useContext(AppUiContextStore);
  const locale = appUiContext.locale?.localeId;
  //---------------------------------------------------------------------------
  // Set fallback level to max value in case if it's system locale (i.e. no need to fallback from system locale)
  const fallbackLevel = appUiContext.sysSettings.sysLocalesList.includes(locale)
    ? 2
    : appUiContext.locale.fallbackLevel;
  //---------------------------------------------------------------------------
  const valueIsEmpty =
    textProperty === null ||
    textProperty === undefined ||
    Object.keys(textProperty).length === 0;
  //---------------------------------------------------------------------------
  const SystemId = () => {
    if (elementPart)
      return `${appUiContext.sysSettings.sysLanguagePrefix}${elementId}.${elementPart}${appUiContext.sysSettings.sysLanguageSuffix}`;
    else
      return `${appUiContext.sysSettings.sysLanguagePrefix}${elementId}${appUiContext.sysSettings.sysLanguageSuffix}`;
  };
  //---------------------------------------------------------------------------
  if (
    locale === appUiContext.sysSettings.sysLocales.i18nLocale ||
    valueIsEmpty
  ) {
    return SystemId();
  } else {
    if (forceLocale) {
      // Use specified locale if it's provided in params
      // and return system id if there are no value for the locale
      if (textProperty[forceLocale])
        return textProperty[forceLocale];
      else
        return SystemId();
      // } else if (textProperty[appUiContext.sysSettings.sysLocales.nativeLocale]) {
      //   // Use native locale if it's present in translations
      //   return textProperty[appUiContext.sysSettings.sysLocales.nativeLocale];
    } else {
      // Apply fallback logic
      var fallbackLocale = locale;
      for (let i = 2; i >= 0; i--) {
        //console.log(`i=${i}, fallbacklocale=${fallbackLocale}`);
        if (textProperty[fallbackLocale]) return textProperty[fallbackLocale];
        if (i === fallbackLevel) return SystemId();
        else {
          fallbackLocale = GetParentLocale(fallbackLocale);
        }
      }
    }
  }
}
//-----------------------------------------------------------------------------------
export function GetTranslationLocale(
  appUiContext: IAppUiContext,
  textProperty: any,
  elementId: string,
  elementPart?: string,
  locale?: string): string {
  //---------------------------------------------------------------------------
  // Get locale settings from context
  //const { appUiContext } = useContext(AppUiContextStore);
  if (!locale)
    locale = appUiContext.locale?.localeId;
  //---------------------------------------------------------------------------
  // Set fallback level to max value in case if it's system locale (i.e. no need to fallback from system locale)
  const fallbackLevel = appUiContext.sysSettings.sysLocalesList.includes(locale)
    ? 2
    : appUiContext.locale.fallbackLevel;
  //---------------------------------------------------------------------------
  const valueIsEmpty =
    textProperty === null ||
    textProperty === undefined ||
    Object.keys(textProperty).length === 0;
  //---------------------------------------------------------------------------
  const SystemId = () => {
    if (elementPart)
      return `${appUiContext.sysSettings.sysLanguagePrefix}${elementId}.${elementPart}${appUiContext.sysSettings.sysLanguageSuffix}`;
    else
      return `${appUiContext.sysSettings.sysLanguagePrefix}${elementId}${appUiContext.sysSettings.sysLanguageSuffix}`;
  };
  //---------------------------------------------------------------------------
  let returnSystemId = 
    locale === appUiContext.sysSettings.sysLocales.i18nLocale || valueIsEmpty;
  //---------------------------------------------------------------------------
  if (!returnSystemId) {
    // Apply fallback logic
    let fallbackLocale = locale;
    for (let i = 2; i >= 0; i--) {
      //console.log(`i=${i}, fallbacklocale=${fallbackLocale}`);
      if (textProperty[fallbackLocale]) {
        return textProperty[fallbackLocale] as string;
      }
      if (i === fallbackLevel) {
        return SystemId();
      }
      fallbackLocale = GetParentLocale(fallbackLocale);
    }
  }
  return SystemId();
}
//-----------------------------------------------------------------------------------
export function GetTranslationForSearch(
  textProperty: any,
  selectedLocale: string,
  appUiContext: IAppUiContext,
  ): string {
  //---------------------------------------------------------------------------
  if (textProperty === null || textProperty === undefined || Object.keys(textProperty).length === 0) {
    return "No content";
  }
  let availableLocales = Object.keys(textProperty);
  //---------------------------------------------------------------------------
  // if selectedLocale in textProperty - return selected 
  if (availableLocales.indexOf(selectedLocale) >= 0) {
    return textProperty[selectedLocale];
  }
  //---------------------------------------------------------------------------
  // selectedLocale is not present in textProperty - try preferredLanguages
  for (let locale of appUiContext.preferredLanguages) {
    if (availableLocales.indexOf(locale) >= 0)
      return textProperty[locale];
  }
  //---------------------------------------------------------------------------
  // nothing preferred is present - return first language from textProperty
  return textProperty[availableLocales[0]];
}


