import styles from './SectionAdCampaigns.module.css';
import { useContext } from "react";
import { EditFormSection } from "../../../../common/console-layout/edit-form-sections/EditFormSection";
import { IUiRegionalHomepageSectionAdCampaigns } from "./IUiRegionalHomepageSectionAdCampaigns";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";
import GetHint from "../../../../common/functions/GetHint";
import { CommonBusinessContext } from '../../../../common/common-business-context/CommonBusinessContextProvider';
import { SystemBusiness } from '../../../content-manager-console/system-businesses/TSystemBusiness';
import { AdCampaign } from '../../../../business-space/business-editor/ad-campaign/AdCampaign';
import AdCampaignsListItem from './adcampaigns-list-item/AdCampaignsListItem';
import { BusinessContext } from '../../../../../context/business-context/BusinessContextProvider';
import { ClassSystemBusinessRegional } from '../ISystemBusinessRegional';
import { IAdCampaign } from '../../../../../context/business-context/IAdCampaign';

interface IProps {
  id: string;
  ui: IUiRegionalHomepageSectionAdCampaigns;
  isReadonly: boolean;
  blink?: boolean;
}

export default function SectionAdCampaigns(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //const { commonBusinessContext } = useContext(CommonBusinessContext);
  const {businessContext} = useContext(BusinessContext);
  const systemBusiness = businessContext.businessState?.business as ClassSystemBusinessRegional;
  //---------------------------------------------------------------------------
  const adCampaigns = systemBusiness?.adCampaigns?.map((adCampaign: IAdCampaign) => {
    return (
      <AdCampaignsListItem
        key={adCampaign.id}
        ui={props.ui.listItemAdCampaign}
        data={adCampaign}
        isCollapsed={systemBusiness.adCampaigns.length > 1}
        isReadonly={props.isReadonly}
      />
    );
  });
  //---------------------------------------------------------------------------
  if (!systemBusiness) return null;
  return (
    <EditFormSection
      id={props.id}
      caption={GetHint(appUiContext, props.ui.id, props.ui.hint)}
    >
      <div className={styles.container}>
        {adCampaigns}
      </div>
    </EditFormSection>
  );
}