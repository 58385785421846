import CombineStyles from "../../../../utils/combineStyles";
import styles from "./TagChainElement.module.css";

interface IProps {
  name: string;
  found?: boolean;
  comma: boolean;
}
export function TagChainElement(props: IProps) {
  let text = props.comma ? `${props.name}, ` : props.name;
  const containerStyles = CombineStyles([
    styles.container,
    props.found == true
      ? styles.found
      : props.found == false
        ? styles.notFound
        : styles.other
  ]);
  return (
    <div className={containerStyles}>
      {text}
    </div>
  )
}