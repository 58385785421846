import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../app-auth-context/AppAuthContext";
import { IApiResponse, apiPostPrivate, checkResponse } from "../../../utils/api";
import { apiBasePath, pathAdLanguageSetCreate, pathCreateAdLanguageSetSystem } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
import { AdLanguageSet, IAdLanguageSet } from "../../../components/ad-content-editor/IAdLanguageSet";
import { EBusinessType } from "../IBusiness";

interface IProps {
  msalInstance: IPublicClientApplication,
  authConfig?: IAuthConfig | null,
  businessType: EBusinessType,
  localeId: string,
  businessId?: string,
  adCampaignId?: string,
  originalAdLanguageSetId?: string,
  abortSignal?: AbortSignal
}

export async function createAdLanguageSetAsync(props: IProps): Promise<AdLanguageSet> {
  //---------------------------------------------------------------------------
  if (!props.authConfig) throw new Error("createAdLanguageSetAsync: auth config is not provided");
  if (!props.businessId) throw new Error("createAdLanguageSetAsync: Business Id is not provided");
  if (!props.adCampaignId) throw new Error("createAdLanguageSetAsync: Ad campaign Id is not provided");
  const isSystem = props.businessType !== "Business";
  //---------------------------------------------------------------------------
  let response: IApiResponse | void = undefined;
  const apiUri = isSystem ? pathCreateAdLanguageSetSystem : pathAdLanguageSetCreate;
  const apiQuery = props.originalAdLanguageSetId ? `?sourceId=${props.originalAdLanguageSetId}` : "";
  response = await apiPostPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${stringFormatter(apiUri + apiQuery, [props.businessId, props.adCampaignId, props.localeId])}`,
    undefined,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "createAdLanguageSetAsync", props.abortSignal?.aborted);
  const newAdLanguageSet = new AdLanguageSet(response?.content as IAdLanguageSet);
  return newAdLanguageSet;
}