import { MutableRefObject } from "react";
import { IPopupStates } from "./GetPopupStates_v3";

export default function applyAnimation(
  popupRef?: React.RefObject<HTMLDivElement>,
  popupStatesRef?: MutableRefObject<IPopupStates | undefined>
) {
  const popup = popupRef?.current;
  const ps = popupStatesRef?.current;
  if (popup && ps) {
    //-----------------------------------------------------------------------
    // Apply initial state to popup div
    ps.initial.top && (popup.style.top = ps.initial.top.asString);
    ps.initial.bottom && (popup.style.bottom = ps.initial.bottom.asString);
    ps.initial.left && (popup.style.left = ps.initial.left.asString);
    ps.initial.right && (popup.style.right = ps.initial.right.asString);
    ps.initial.width && (popup.style.width = ps.initial.width.asString);
    ps.initial.height && (popup.style.height = ps.initial.height.asString);
    ps.initial.maxWidth && (popup.style.maxWidth = ps.initial.maxWidth.asString);
    ps.initial.maxHeight && (popup.style.maxHeight = ps.initial.maxHeight.asString);
    ps.initial.transform && (popup.style.transform = ps.initial.transform);
    const initialRect = popup.getBoundingClientRect();
    //console.log(initialRect.top, initialRect.left, initialRect.width, initialRect.height, ps.initial.transform)
    //-----------------------------------------------------------------------
    // Apply final styles
    ps.final.top && (popup.style.top = ps.final.top.asString);
    ps.final.bottom && (popup.style.bottom = ps.final.bottom.asString);
    ps.final.left && (popup.style.left = ps.final.left.asString);
    ps.final.right && (popup.style.right = ps.final.right.asString);
    ps.final.width && (popup.style.width = ps.final.width.asString);
    ps.final.height && (popup.style.height = ps.final.height.asString);
    ps.final.maxWidth && (popup.style.maxWidth = ps.final.maxWidth.asString);
    ps.final.maxHeight && (popup.style.maxHeight = ps.final.maxHeight.asString);
    popup.style.transform = ps.final.transform ? ps.final.transform : '';
    const finalRect = popup.getBoundingClientRect();
    //console.log(finalRect.top, finalRect.left, finalRect.width, finalRect.height, ps.final.transform)
    //-----------------------------------------------------------------------
    // Calc the delta
    var deltaTop = initialRect.top - finalRect.top;
    var deltaLeft = initialRect.left - finalRect.left;
    var deltaWidth = initialRect.width / finalRect.width;
    var deltaHeight = initialRect.height / finalRect.height;
    //---------------------------------------------------------------------
    // Invert
    popup.style.transition = '';
    popup.style.transform = `${popup.style.transform} translateX(${deltaLeft}px) translateY(${deltaTop}px) scaleX(${deltaWidth}) scaleY(${deltaHeight})`;
    popup.style.opacity = '0';
    //---------------------------------------------------------------------
    // Play
    const tt = '1000ms ease-out';
    requestAnimationFrame(() => {
      popup.style.transition = `transform ${tt}, opacity ${tt}`;
      popup.style.transform = ps.final.transform ? ps.final.transform : '';
      popup.style.opacity = '1';
    });
  };
}