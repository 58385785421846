import { createContext, Dispatch, ReactNode, useReducer } from "react";
import { ClassDirectoryEditorContext, IDirectoryEditorContextData } from "./IDirectoryEditorContextData";
import { Actions, DirectoryEditorContextReducer } from "./DirectoryEditorContextReducer";

interface IDirectoryEditorContext {
  //directoryEditorContext: IDirectoryEditorContextData;
  directoryEditorContext: ClassDirectoryEditorContext;
  directoryEditorContextDispatch: Dispatch<Actions>;
}

export const DirectoryEditorContext = createContext<IDirectoryEditorContext>({} as IDirectoryEditorContext);
// How to make context nullable: https://kentcdodds.com/blog/how-to-use-react-context-effectively

interface IProps {
  children: ReactNode;
}

export default function DirectoryEditorContextProvider(props: IProps) {
  const [directoryEditorContext, directoryEditorContextDispatch] = useReducer(
    DirectoryEditorContextReducer,
    {} as ClassDirectoryEditorContext);
  //---------------------------------------------------------------------------
  return (
    <DirectoryEditorContext.Provider
      value={{ directoryEditorContext, directoryEditorContextDispatch }}>
      {props.children}
    </DirectoryEditorContext.Provider>
  );
}