import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AppAuthContextStore } from "../../../context/app-auth-context/AppAuthContext";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";
import usePageTitle from "../../../hooks/usePageTitle";
import WikiSpinner from "../../app-layout/spinner/wikiSpinner";
import { TUiBreadcrumb } from "../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import ConsoleLayout from "../../common/console-layout/ConsoleLayout";
import { TUiMenuOption } from "../../common/menu/menu-content/menu-option/TUiMenuOption";
import NavigateWithContext from "../../common/navigate-with-context/NavigateWithContext";
import { optionIdAdministratorConsole, optionIdRegionalHomepages, pathAdminConsole, pathAdministratorConsole, pathAdministratorConsoleFull, pathBusinessTagsConsole, pathFullRegionalHomepages, pathRegionalHomepages } from "../AdminConsole";
import BusinessTagsEditor from "./dictionaries/business-tags-editor/BusinessTagsEditor";
import DictionaryList from "./dictionaries/DictionaryList";
import { TUiAdministratorConsole } from "./TUiAdministratorConsole";
import UserEditForm from "./user-edit-form/UserEditForm";
import UserList from "./user-list/UserList";
import CategoryRequests from "./category-requests/CategoryRequests";
import ApiKeys from "./apiKeys/ApiKeys";
import AdRanksEditor from "./dictionaries/ad-ranks-editor/AdRanksEditor";
import LocalesEditor from "./dictionaries/locales-editor/LocalesEditor";
import { TUiNames } from "../../../context/app-ui-context/AppUiCache";
import RegionsEditorV2 from "./dictionaries/regions-editor/RegionsEditorV2";
import { AbortControllerWrapper, useAbortController } from "../../../hooks/useAbortController";
import { IUiAdministratorConsole, IUiApplication_AdministratorConsole } from "./IUiAdministratorConsole";
import { IUiOption } from "../../common/options/IUiOption";
import { getTUiMenuContent } from "../../common/menu/menu-content/TUiMenuContent";
import RegionalHomepagesList from "../regional-homepages/regional-homepages-list/RegionalHomepagesList";
import CommonDictionaryEditor from "./dictionaries/common-dictionary/CommonDictionaryEditor";
import DictionaryEditorRejectionReasons from "./dictionaries/rejection-reasons/DictionaryEditorRejectionReasons";

export const pathUserList = "users";
export const optionIdUsersList = "AdministratorConsole.MenuContent.OptionUsers";
export const pathDictionaries = "catalogues";
export const optionIdDictionaries = "AdministratorConsole.MenuContent.OptionDictionaries";
export const pathBusinessTagsEditor = "businesstags";
export const optionIdBusinessTagsConsole = "AdministratorConsole.MenuContent.OptionBusinessTags";
export const pathAdRanksEditor = "adranks";
export const pathLocalesEditor = "supportedlocales";
export const pathRegionsEditor = "supportedregions";
export const pathDictionaryRejectionReasons = "rejectionreasons";
export const pathNewCategoryRequests = "categoryrequests";
export const optionIdNewCategoryRequests = "AdministratorConsole.MenuContent.OptionBusinessTagRequests";
export const pathApiKey = "apikeys";

const uiNameOld: TUiNames = "administratorConsole_old";
const uiName: TUiNames = "administratorConsole";

interface IProps {
  optionsForBreadcrumb?: IUiOption[];
}

export default function AdministratorConsole(props: IProps) {
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const updateTitle = usePageTitle();
  const [uiOld, setUiOld] = useState<TUiAdministratorConsole>(); // To remove
  const [ui, setUi] = useState<IUiAdministratorConsole>();
  const [isLoadingOld, setIsLoadingOld] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const abortController = useAbortController("AdministratorConsole");
  //--------------------------------------------------------------------------- Title, AbortController
  useEffect(() => {
    updateTitle("Administrator");
    return () => {
      abortController.abortOnUnmount();
    };
  }, []);
  //--------------------------------------------------------------------------- Get UI from backend
  useEffect(() => {
    if (uiOld || !appUiContext.uiCache?.checkAuthorization(uiNameOld)) {
      return;
    }
    setIsLoadingOld(true);
    setIsLoading(true);
    //-------------------------------------------------------------------------
    // Old UI version
    let controllerOld = abortController.newController("GetUIOld");
    appUiContext.uiCache.getUiCallback<TUiAdministratorConsole>(
      uiNameOld,
      (ui) => !controllerOld.aborted && setUiOptions(ui, controllerOld),
      () => !controllerOld.aborted && setIsLoadingOld(false)
    );
    //-------------------------------------------------------------------------
    // New UI version
    let controller = abortController.newController("GetUi");
    appUiContext.uiCache.getUiCallback<IUiApplication_AdministratorConsole>(
      uiName,
      (ui) => !controller.aborted && setConsoleUi(ui, controller),
      () => !controller.aborted && setIsLoading(false)
    );
  }, [appAuthContext.config, appAuthContext.user]);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdAdministratorConsole);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        caption: option.caption,
        path: `/${pathAdministratorConsoleFull}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
  }, [props.optionsForBreadcrumb]);
  //---------------------------------------------------------------------------
  function setConsoleUi(consoleUi: IUiApplication_AdministratorConsole, controller: AbortControllerWrapper) {
    if (!controller.aborted) {
      // Set dictionaries
      if (consoleUi?.dictionaries) {
        appUiContextDispatch({ type: "UpdateDictionaries", value: consoleUi.dictionaries });
      };
      //-------------------------------------------------------------------------
      setUi(consoleUi.administratorConsole);
      //-------------------------------------------------------------------------
      // Breadcrumb for dictionaries
      const option = consoleUi.administratorConsole.menuContent.options?.find(o => o.id == optionIdDictionaries);
      if (option) {
        const breadcrumb: TUiBreadcrumb = {
          id: option.id,
          caption: option.caption,
          path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}`
        };
        appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
      };
    };
  };
  //--------------------------------------------------------------------------- Take care of UI
  function setUiOptions(consoleUi: TUiAdministratorConsole, controller: AbortControllerWrapper) {
    const menuContent = consoleUi.administratorMenu;
    //----------------------------------------------------------------- Administrator Menu
    // Run through menu options and update 'action' with corresponding relative route
    var updatedOptions: TUiMenuOption[] = [];
    menuContent.options.forEach((option: TUiMenuOption) => {
      switch (option.id) {
        case "AdministratorMenu_Users":
          updatedOptions.push({
            ...option,
            action: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathUserList}`,
          });
          break;
        case "AdministratorMenu_Dictionaries":
          updatedOptions.push({
            ...option,
            action: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}`,
          });
          break;
        case "AdministratorMenu_NewCategoryRequests":
          updatedOptions.push({
            ...option,
            action: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathNewCategoryRequests}`,
          });
          break;
        case "AdministratorMenu_ApiKeys":
          updatedOptions.push({
            ...option,
            action: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathApiKey}`,
          });
          break;
      }
    });
    //-----------------------------------------------------------------
    // const updatedBreadcrumb: TUiBreadcrumb | undefined = consoleUi.breadcrumb ? {
    //   id: consoleUi.breadcrumb.id,
    //   caption: consoleUi.breadcrumb.caption,
    //   path: `/${pathAdminConsole}/${pathAdministratorConsole}`
    // } : undefined;
    //-----------------------------------------------------------------
    const updatedUi: TUiAdministratorConsole = {
      ...consoleUi,
      //breadcrumb: updatedBreadcrumb ? updatedBreadcrumb : consoleUi.breadcrumb,
      administratorMenu: {
        ...consoleUi.administratorMenu,
        options: updatedOptions
      }
    };
    //-----------------------------------------------------------------
    !controller.aborted && setUiOld(updatedUi);
  };
  //--------------------------------------------------------------------------- Menu options
  const updatedOptions: IUiOption[] = [];
  ui?.menuContent?.options.forEach(option => {
    switch (option.id) {
      case "AdministratorConsole.MenuContent.OptionUsers":
        updatedOptions.push({
          ...option,
          iconId: "users",
          action: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathUserList}`
        });
        break;
      case "AdministratorConsole.MenuContent.OptionDictionaries":
        updatedOptions.push({
          ...option,
          iconId: "books",
          action: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}`
        });
        break;
      case optionIdRegionalHomepages:
        updatedOptions.push({
          ...option,
          iconId: "mapLocation",
          action: pathFullRegionalHomepages
        });
        break;
      case "AdministratorConsole.MenuContent.OptionApiKeys":
        updatedOptions.push({
          ...option,
          iconId: "keys",
          action: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathApiKey}`
        });
        break;
      case optionIdBusinessTagsConsole:
        updatedOptions.push({
          ...option,
          iconId: "tags",
          action: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathBusinessTagsConsole}`
        });
        break;
    };
  });
  const updatedMenuContent = ui ? getTUiMenuContent({...ui.menuContent, options: updatedOptions}) : undefined;
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      {/* Check user roles here to render routes conditionally (the same should be done in components in case if user uses URL) */}
      {uiOld && updatedMenuContent &&
        <ConsoleLayout
          ui={{ header: "", menuContent: updatedMenuContent }}
          path={pathAdministratorConsoleFull}
          hideSidebar={true}
        >
          <WikiSpinner key={"administratorConsole"} show={isLoading || isLoadingOld} />
          <Routes>
            <Route index element={<NavigateWithContext to={`/${pathAdminConsole}/${pathAdministratorConsole}/${pathUserList}`} />} />

            {ui?.staffRoles &&
              <Route path={`${pathUserList}`} element={
                <UserList
                  optionsForBreadcrumb={ui?.menuContent.options}
                  //ui={uiOld.userList}
                  staffRoles={ui.staffRoles}
                />} />}

            {ui?.staffRoles &&
              <Route path={`${pathUserList}/:requestedUserId`} element={
                <UserEditForm
                  staffRoles={ui?.staffRoles}
                />} />}

            <Route path={pathDictionaries} element={
              <DictionaryList
                ui={uiOld.dictionaryListUi}
                optionsForBreadcrumb={ui?.menuContent.options}
              />} />

            <Route path={`${pathDictionaries}/${pathBusinessTagsEditor}`} element={
              <BusinessTagsEditor />} />

            <Route path={`${pathDictionaries}/${pathAdRanksEditor}`} element={
              <AdRanksEditor />} />

            <Route path={`${pathDictionaries}/${pathLocalesEditor}`} element={
              <LocalesEditor />} />

            <Route path={`${pathDictionaries}/${pathRegionsEditor}`} element={
              //<RegionsEditor />
              <RegionsEditorV2 />} />

            <Route path={`${pathDictionaries}/${pathDictionaryRejectionReasons}`} element={
              <DictionaryEditorRejectionReasons />} />

            <Route path={`${pathDictionaries}/:dictionaryId`} element={
              <CommonDictionaryEditor />} />

            <Route path={pathRegionalHomepages} element={
              <RegionalHomepagesList
                role={"Administrator"}
                ui={ui?.regionalHomepagesList}
                optionsForBreadcrumb={ui?.menuContent.options}
              />} />

            {/* <Route path={pathNewCategoryRequests} element={
              <CategoryRequests
                ui={uiOld.newCategoryRequestsUi}
                optionsForBreadcrumb={ui?.menuContent.options}
              />} /> */}

            <Route path={pathApiKey} element={
              <ApiKeys ui={uiOld.apiKeysUi} />} />

          </Routes>
        </ConsoleLayout>}
    </React.Fragment>
  );
}