import React from 'react';
import styles from './TagCheckbox.module.css';

export type EBusinessTagExtendedState = "Unchecked" | "Gray" | "Checked" | "DoubleChecked";

interface IProps {
  state: EBusinessTagExtendedState;
}

export default function TagCheckbox(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <svg width="100%" height="100%" version="1.1" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
        <g stroke="currentColor" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round">
          <rect
            x="2" y="2" width="26" height="26" fill="none"
          />

          <g className={props.state == "Gray" ? "" : styles.invisible}>
            <rect x="10" y="10" width="10" height="10" fill="currentColor" />
          </g>

          <g
            transform="translate(6, 6)"
            className={props.state == "DoubleChecked" ? "" : styles.invisible}
          >
            <path d="m1.9844 12.248 5.9203 4.5622 8.6281-10.14" fill="none" />
            <path d="m3.0244 5.6102 3.6139 2.6106 5.4345-6.2364" fill="none" />
          </g>

          <g
            transform="translate(6, 3)"
            className={props.state == "Checked" ? "" : styles.invisible}
          >
            <path d="m1.9844 12.248 5.9203 4.5622 8.6281-10.14" fill="none" />
          </g>
        </g>
      </svg>
    </div>
  );
}