import { ReactNode } from 'react';
import PopupHeader from '../popup-header/PopupHeader';
//import Popup from '../popup/Popup';
import { TAnimationType } from '../popup/GetPopupStates';
import PopupTest from '../popup/PopupTest';

interface IProps {
  id: string;
  header: string;
  children: ReactNode;
  toolbarContent?: ReactNode;
  isModal: boolean;
  isDraggable: boolean;
  animation?: TAnimationType;
  zindex?: number; // delete
  fullWindow?: boolean;
  fullWindowContent?: boolean;
  fixHeight?: boolean; // Prevent resizing based on popup content
  allowPropagation?: boolean;
  shadowColor?: string;
  onClose: () => void;
  onBack?: () => void;
  onEnter?: () => void;
  onAnimationEnd?: () => void;
}

export default function PopupDialog(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <PopupTest
      id={props.id}
      isModal={props.isModal}
      //isDraggable={props.isDraggable}
      closeOnClickOutside={false}
      animation={props.animation}

      //headerContent={headerContent}
      //zindex={props.zindex}
      //fullWindowContent={props.fullWindow}
      fullWindow={props.fullWindow}
      fixHeight={props.fixHeight}
      allowPropagation={props.allowPropagation}
      shadowColor={props.shadowColor}
      onClose={props.onClose}
      //onEnter={props.onEnter}
      onAnimationEnd={props.onAnimationEnd}>

      {!props.fullWindowContent &&
        <PopupHeader
          header={props.header}
          onBack={props.onBack}
          onClose={props.onClose}
        />}

      {props.children}

      {props.toolbarContent}

    </PopupTest>
  );
}