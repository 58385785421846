import { CSSProperties, ReactNode, useContext } from 'react';
import styles from './DirectoryNodeAttribute.module.css';
import CombineStyles from '../../../../utils/combineStyles';
import { DirectoryEditorContext } from '../../../../context/directory-editor-context/DirectoryEditorContextProvider';
import DirectoryNodeToggler from '../directory-node-toggler/DirectoryNodeToggler';
import { v4 as uuidv4 } from "uuid";

interface IProps {
  children?: ReactNode;
  isFirstColumn?: boolean;
  isExpandable?: boolean;
  isExpanded?: boolean;
  level?: number;
  icon?: boolean;
  customStyles?: CSSProperties;
  onClick?: () => void;
  onToggle?: () => void;
}

export default function DirectoryNodeAttribute(props: IProps) {
  const { directoryEditorContext } = useContext(DirectoryEditorContext);
  const showToggler = directoryEditorContext.editorState.draft.isHierarchical && props.isFirstColumn;
  //---------------------------------------------------------------------------
  // Indentation for hierarchical dictionaries
  const indent = props.level ?
    // repeat indent div accordingly to the current level
    [...Array(props.level)].map(() => {
      return <div key={uuidv4()} className={styles.indent} />
    }) :
    <></>;
  //---------------------------------------------------------------------------
  // If it's the first column and it's a hierarchical dictionary, add the toggler
  const caption = showToggler ?
    <div
      className={styles.caption}
    >

      {indent}

      <DirectoryNodeToggler
        isExpanded={props.isExpandable && props.isExpanded}
        onToggle={props.isExpandable ? props.onToggle : undefined}
      />

      <div onClick={props.onClick}>
        {props.children}
      </div>

    </div> :
    props.children;
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        showToggler ? styles.noPadding : '',
        props.icon ? styles.icon : '',
        props.onClick ? styles.clickable : ''
      ])}
      style={props.customStyles}
      onClick={showToggler ? undefined : props.onClick}
    >
      {caption}
    </div>
  );
}