import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AppAuthContextStore } from "../../../context/app-auth-context/AppAuthContext";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";
import usePageTitle from "../../../hooks/usePageTitle";
import WikiSpinner from "../../app-layout/spinner/wikiSpinner";
import { TUiBreadcrumb } from "../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import ConsoleLayout from "../../common/console-layout/ConsoleLayout";
import NavigateWithContext from "../../common/navigate-with-context/NavigateWithContext";
import { optionIdContentManagerConsole, pathAdminConsole, pathContentManagerConsole, pathContentManagerConsoleFull, pathContentManagerConsolePathFull, pathRegionalHomepages } from "../AdminConsole";
import { TUiNames } from "../../../context/app-ui-context/AppUiCache";
import { IUiApplication_ContentManagerConsole, IUiContentManagerConsole } from "./IUiContentManagerConsole";
import { AbortControllerWrapper, useAbortController } from "../../../hooks/useAbortController";
import { IUiOption } from "../../common/options/IUiOption";
import { getTUiMenuContent } from "../../common/menu/menu-content/TUiMenuContent";
import RegionalHomepagesList from "../regional-homepages/regional-homepages-list/RegionalHomepagesList";

const uiName: TUiNames = "contentManagerConsole";
export const optionIdCMRegionalHomepages = "ContentManagerConsole.MenuContent.OptionRegionalHomepages";

interface IProps {
  optionsForBreadcrumb?: IUiOption[];
}

export default function ContentManagerConsole(props: IProps) {
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const updateTitle = usePageTitle();
  const [isLoading, setIsLoading] = useState(true);
  const [ui, setUi] = useState<IUiContentManagerConsole>();
  const abortController = useAbortController("ContentManagerConsole");
  //--------------------------------------------------------------------------- Title, abortController
  useEffect(() => {
    updateTitle("Content Manager");
    return () => {
      abortController.abortOnUnmount();
    };
  }, []);
  //--------------------------------------------------------------------------- Get UI from backend
  useEffect(() => {
    console.log(ui)
    if (ui || !appUiContext.uiCache?.checkAuthorization(uiName)) {
      setIsLoading(false);
      return;
    };
    setIsLoading(true);
    let controller = abortController.newController("GetUi");
    appUiContext.uiCache.getUiCallback<IUiApplication_ContentManagerConsole>(
      uiName,
      (ui) => !controller.aborted && setConsoleUi(ui, controller),
      () => !controller.aborted && setIsLoading(false)
    );
  }, [appAuthContext]);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdContentManagerConsole);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        caption: option.caption,
        path: `/${pathContentManagerConsoleFull}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
  }, [props.optionsForBreadcrumb]);
  //---------------------------------------------------------------------------
  function setConsoleUi(consoleUi: IUiApplication_ContentManagerConsole, controller: AbortControllerWrapper) {
    if (!controller.aborted) {
      console.log(consoleUi)
      // Set dictionaries
      if (consoleUi?.dictionaries) {
        appUiContextDispatch({ type: "UpdateDictionaries", value: consoleUi.dictionaries });
      };
      //-------------------------------------------------------------------------
      setUi(consoleUi.contentManagerConsole);
    };
  };
  //--------------------------------------------------------------------------- Menu options
  const updatedMenuContent = ui ? getTUiMenuContent(ui.menuContent) : undefined;
  updatedMenuContent?.options.forEach(option => {
    switch (option.id) {
      case optionIdCMRegionalHomepages:
        option.iconFile = "mapLocation";
        option.action = `/${pathAdminConsole}/${pathContentManagerConsole}/${pathRegionalHomepages}`;
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      {updatedMenuContent &&
        <ConsoleLayout
          ui={{ header: "", menuContent: updatedMenuContent }}
          path={pathContentManagerConsolePathFull}
          hideSidebar={true}>
          <WikiSpinner show={isLoading} />
          <Routes>

            <Route
              index
              element={<NavigateWithContext to={`/${pathAdminConsole}/${pathContentManagerConsole}/${pathRegionalHomepages}`} />}
            />

            <Route
              path={pathRegionalHomepages}
              element={
                <RegionalHomepagesList
                  role={"ContentManager"}
                  ui={ui?.regionalHomepagesList}
                  optionsForBreadcrumb={ui?.menuContent.options}
                />}
            />

          </Routes>
        </ConsoleLayout>}
    </React.Fragment >
  );
}