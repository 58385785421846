import styles from './BusinessListCard.module.css';
import { BusinessListItem } from './IBusinessListItem';
import BusinessLogo from '../../../../business-console/business-list/business-logo/BusinessLogo';

interface IProps {
  data: BusinessListItem;
  onSelect: (businessId: string) => void;
}

export default function BusinessListCard(props: IProps) {
  //---------------------------------------------------------------------------
  const openBusiness = () => {
    props.onSelect(props.data.id);
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container} onClick={openBusiness}>
      <div className={styles.logoContainer}>
        <BusinessLogo
          data={props.data.logo}
          useDefaultLogo={false}
        />
      </div>
      <div className={styles.name}>
        <div className={styles.value}>
          {props.data.name}
        </div>
        <div className={styles.system}>
          {props.data.userRoles}
        </div>
        <div className={styles.system}>
          {props.data.id}
        </div>
      </div>
    </div>
  );
}