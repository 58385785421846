import styles from './CategoryRequests.module.css';
import ConsoleTabLayout from "../../../common/console-layout/console-tab-layout/ConsoleTabLayout";
import ConsoleTabContent from "../../../common/console-layout/console-tab-layout/console-tab-content/ConsoleTabContent";
import { IUiNewCategoryRequests } from './IUiNewCategoryRequests';
import { useContext, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import { apiGetPrivate, checkResponse } from '../../../../utils/api';
import { apiBasePath, getNewCategoryRequests } from '../../../../utils/apiPathConstant';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { pathAdminConsole, pathAdministratorConsole } from '../../AdminConsole';
import { optionIdNewCategoryRequests, pathNewCategoryRequests } from '../AdministratorConsole';
import CategoryRequestCard from './category-request-card/CategoryRequestCard';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import { useAbortController } from '../../../../hooks/useAbortController';
import { IUiOption } from '../../../common/options/IUiOption';
import { TUiBreadcrumb } from '../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { INewBusinessTagRequest } from '../../../common/common-business-context/INewBusinessTagRequest';
import ConsoleContent from '../../../common/console-layout/console-content/ConsoleContent';

interface IProps {
  //ui: IUiNewCategoryRequests;
  optionsForBreadcrumb?: IUiOption[];
}

export default function CategoryRequests(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const [requests, setRequests] = useState<INewBusinessTagRequest[]>();
  const [isLoading, setIsLoading] = useState(false);
  const abortController = useAbortController("CategoryRequests");
  //--------------------------------------------------------------------------- Get data
  useEffect(() => {
    if (!appAuthContext.user || !appAuthContext.config) {
      console.error("Cannot retrieve data because auth context is not set");
      return;
    }
    setIsLoading(true);
    let controller = abortController.newController("getNewCategoryRequests");
    apiGetPrivate(
      instance,
      appAuthContext.config,
      `${apiBasePath}${getNewCategoryRequests}`,
      controller.signal
    ).then(response => {
      checkResponse(response, "getNewCategoryRequests", controller.aborted)
      setRequests(response?.content);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdNewCategoryRequests);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        caption: option.caption,
        path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathNewCategoryRequests}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
  }, [props.optionsForBreadcrumb]);
  //---------------------------------------------------------------------------
  const listElements = requests?.map(request => (
    <CategoryRequestCard
      key={request.id}
      data={request}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={"Business Tag Requests"}
      containerWidthPercent={70}
    >
      <WikiSpinner show={isLoading} />
      <div className={styles.container}>
        {listElements}
      </div>
    </ConsoleContent>
  );
}