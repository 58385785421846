import { useEffect, useRef, useState } from 'react';
import styles from './DictionaryTranslatorNodeEditForm.module.css';
import FormOptions from '../../../../common/form-options-bar/FormOptions';
import InputText, { IUiInputText } from '../../../../common/input-v2/input-text/InputText';
import { TUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import { IUiInput } from '../../../../common/input-v2/IUiInput';
import { TUiMenuOption } from '../../../../common/menu/menu-content/menu-option/TUiMenuOption';
import { DictionaryTranslationDraftItem, IDictionaryTranslationDraftItem, ITranslation, Translation } from '../DictionaryTranslationDraftItem';
import React from 'react';
import PopupMessage from '../../../../common/popup-v2/popup-message/PopupMessage';
import useClickOutsideDiv from '../../../../../hooks/useClickOutsideDiv';
import InputsGroupWrapper from '../../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import TranslationEditor from '../translation-editor/TranslationEditor';
import { TUiInputDropdown } from '../../../../common/input/input-dropdown/TUiInputDropdown';
import { IUiListItem } from '../../../../common/input/input-dropdown/InputDropdown';
import { IDictionaryDraftItem } from '../../../common/dictionary-draft-models/DictionaryDraftItem';
import { EditFormState, IEditFormState } from '../../../../common/edit-form/IEditFormState';

export interface IUiDictionaryTranslationDraftNodeEditForm {
  inputId: IUiInputText;
  inputName: IUiInputText;
  inputDescription: IUiInputText;
  inputLocale: TUiInputDropdown;
  inputAcceptFallback: IUiInput;
  formOptions: TUiMenuContent;
}
const tmpUi: IUiDictionaryTranslationDraftNodeEditForm = {
  inputId: {
    id: "DictionaryNodeIdInput",
    directoryId: '',
    elementType: "",
    disabled: true,
    visible: true,
    caption: { en: "Id" },
    placeholder: { en: "Type Id here..." },
    defaultValue: undefined,
    hint: { en: "System Id visible to administrators and system translators" },
    validation: {
      required: {
        value: 1,
        message: {
          id: "DictionaryNodeEditForm_Id_ValidationMessage_Required",
          hint: "Id cannot be empty"
        }
      },
      unique: {
        message: {
          id: "DictionaryNodeEditForm_Id_ValidationMessage_Unique",
          hint: "Entry with this Id already exists"
        }
      }
    }
  },
  inputName: {
    id: "DictionaryNodeNameInput",
    directoryId: '',
    elementType: "",
    disabled: false,
    visible: true,
    caption: { en: "Name" },
    placeholder: { en: "Type Name here..." },
    defaultValue: undefined,
    hint: { en: "Name in current draft locale visible to end users" },
    validation: undefined
  },
  inputDescription: {
    id: "DictionaryNodeDescriptionInput",
    directoryId: '',
    elementType: "",
    disabled: false,
    visible: true,
    caption: { en: "Description" },
    placeholder: { en: "Type Description here..." },
    defaultValue: undefined,
    hint: { en: "Detailed description in current draft locale visible to end users" },
    validation: undefined
  },
  inputLocale: {
    id: "",
    directoryId: "",
    caption: { en: "Source Locale" },
    hint: undefined,
    availableValues: [{
      id: "i18n",
      name: { en: "System Id" },
      selected: false
    }, {
      id: "en",
      name: { en: "English" },
      selected: false
    }, {
      id: "uk",
      name: { en: "Ukrainian" },
      selected: false
    }, {
      id: "ru",
      name: { en: "Russian" },
      selected: false
    }],
    defaultValue: "",
    placeholder: "",
    multiSelect: false,
    validation: { required: { value: 1, errorMessageId: "", errorMessage: "" } }
  },
  inputAcceptFallback: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Accept fallback value" }
  },
  formOptions: {
    id: '',
    directoryId: '',
    elementType: '',
    visible: true,
    disabled: false,
    options: [{
      id: 'optionCancel',
      directoryId: '',
      elementType: '',
      visible: true,
      disabled: false,
      canHideCaption: false,
      index: 0,
      priorityLevel: 0,
      caption: { en: 'Cancel' },
      iconFile: "close",
      hint: undefined,
      isDefault: false,
      action: undefined
    }, {
      id: 'optionOk',
      directoryId: '',
      elementType: '',
      visible: true,
      disabled: false,
      canHideCaption: false,
      index: 0,
      priorityLevel: 0,
      caption: { en: 'Ok' },
      iconFile: "check",
      hint: undefined,
      isDefault: true,
      action: undefined
    }],
    optionGroups: []
  }
}
const discardChangesConfirmationDialogMenu: TUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  visible: true,
  disabled: false,
  options: [{
    id: "optionDiscard",
    directoryId: "",
    elementType: "",
    index: 1,
    priorityLevel: 1,
    caption: { en: "Discard" },
    hint: null,
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    canHideCaption: false,
    action: null
  }, {
    id: "optionCancel",
    directoryId: "",
    elementType: "",
    index: 1,
    priorityLevel: 1,
    caption: { en: "Cancel" },
    hint: null,
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    canHideCaption: false,
    action: null
  }],
  optionGroups: []
}

function TranslationsAreEqual(t1?: ITranslation, t2?: ITranslation): boolean {
  if (!t1 && !t2) {
    // If both values are empty (undefined or null)
    return true;
  } else if (!t1 || !t2) {
    // If one of values is empty
    return false;
  } else {
    if (t1.fallbackAccepted == t2.fallbackAccepted) {
      // If fallback accepted value is the same
      if (!t1.fallbackAccepted) {
        // Check values only if fallback is not accepted
        if (!t1.value && !t2.value) {
          // If values both empty
          return true;
        } else if (!t1.value || !t2.value) {
          // If one of them is empty
          return false;
        } else {
          // String values decide the result
          return t1.value == t2.value;
        };
      } else
        return true;
    } else {
      return false;
    };
  };
}

class DictionaryTranslatorNodeEditFormState extends EditFormState<DictionaryTranslationDraftItem> {
  constructor(source: IEditFormState<DictionaryTranslationDraftItem>, spread?: any) {
    super(source, spread);
    //-------------------------------------------------------------------------
    // Check differences between initial and current state
    this.isUpdated =
      !TranslationsAreEqual(this.object?.name, this.initialState?.name) ||
      !TranslationsAreEqual(this.object?.description, this.initialState?.description);
    //-------------------------------------------------------------------------
    // Validate data
    this.isValid = true;
    if (!this.object?.name.fallbackAccepted && !this.object?.name.value) {
      this.isValid = false;
    };
    if (!this.object?.description.fallbackAccepted && !this.object?.description.value) {
      this.isValid = false;
    };
  }
}

interface IProps {
  draftLocale: string;
  sourceLocale?: string;
  data: IDictionaryTranslationDraftItem;
  onUpdate: (updatedItem: DictionaryTranslationDraftItem) => void;
  onClose: () => void;
}

export default function DictionaryTranslatorNodeEditForm(props: IProps) {
  const [state, setState] = useState<DictionaryTranslatorNodeEditFormState>();
  const [discardChangesDialogOpen, setDiscardChangesDialogOpen] = useState(false);
  const editFormDivRef = useClickOutsideDiv({
    handler: onCloseWithUnsavedChanges,
    unsavedChanges: state?.isUpdated
  });
  //--------------------------------------------------------------------------- Scroll the form to the edit form div
  useEffect(() => {
    if (editFormDivRef.current) {
      editFormDivRef.current.scrollIntoView({ behavior: 'smooth' });
    };
  }, [editFormDivRef.current]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    const state = new DictionaryTranslatorNodeEditFormState({
      object: new DictionaryTranslationDraftItem(props.data),
      isNew: false,
      isUpdated: false,
      isValid: true,
      //publishedState: props.data.dictionaryDraftItemPublished ? new DictionaryTranslationDraftItem(props.data.dictionaryDraftItemPublished) : undefined,
      //isActuallyUpdated: false
    });
    setState(state);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onNameUpdate = (updatedValue?: ITranslation) => {
    const updatedTranslationItem = new DictionaryTranslationDraftItem(
      state?.object, {
      name: {
        value: updatedValue?.value,
        fallbackAccepted: updatedValue?.fallbackAccepted
      }
    });
    const updatedState = new DictionaryTranslatorNodeEditFormState(
      state as DictionaryTranslatorNodeEditFormState,
      { object: updatedTranslationItem }
    );
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onDescriptionUpdate = (updatedValue?: ITranslation) => {
    //console.log(updatedValue)
    const updatedTranslationItem = new DictionaryTranslationDraftItem(
      state?.object, {
      description: {
        value: updatedValue?.value,
        fallbackAccepted: updatedValue?.fallbackAccepted
      }
    });
    const updatedState = new DictionaryTranslatorNodeEditFormState(
      state as DictionaryTranslatorNodeEditFormState,
      { object: updatedTranslationItem }
    );
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    if (state?.object && state.object.name && state.object.description && state.isUpdated) {
      // Update draftState
      const updatedItem = new DictionaryTranslationDraftItem({
        ...state.object,
        name: {
          ...state.object.name,
          value: state.object.name.fallbackAccepted ? undefined : state.object.name.value
        },
        description: {
          ...state.object.description,
          value: state.object.description.fallbackAccepted ? undefined : state.object.description.value
        }
      });
      //-----------------------------------------------------------------------
      // Check if data is changed (name, description, fallbackAccepted values are changed)
      let nameIsChanged = !Translation.TranslationsAreEqual(state.object.name, state.object.publishedVersion?.name);
      let descriptionIsChanged = !Translation.TranslationsAreEqual(state.object.description, state.object.publishedVersion?.description);
      updatedItem.isTranslationEdited = nameIsChanged || descriptionIsChanged;
      //-----------------------------------------------------------------------
      updatedItem.isFallbackAccepted = updatedItem.name?.fallbackAccepted || updatedItem.description?.fallbackAccepted;
      //-----------------------------------------------------------------------
      updatedItem.isNoTranslation = Translation.NoTranslation(updatedItem.name) || Translation.NoTranslation(updatedItem.description);
      //-----------------------------------------------------------------------
      updatedItem.isNothing = !updatedItem.isTranslationEdited && !updatedItem.isFallbackAccepted && !updatedItem.isNoTranslation;
      //-----------------------------------------------------------------------
      props.onUpdate(updatedItem);
    } else console.error("Cannot update translation item: state is not set");
  };
  //---------------------------------------------------------------------------
  function onCloseWithUnsavedChanges(unsavedChanges?: boolean) {
    if (unsavedChanges)
      setDiscardChangesDialogOpen(true);
    else
      props.onClose();
  };
  //---------------------------------------------------------------------------
  const onDiscardChangesDialogOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "optionDiscard":
        props.onClose();
        break;
      case "optionCancel":
        setDiscardChangesDialogOpen(false);
        break;
    }
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "optionOk":
        onConfirm();
        break;
      case "optionCancel":
        onCloseWithUnsavedChanges(state?.isUpdated);
        break;
    }
  };
  //---------------------------------------------------------------------------
  const getSourceValue = (textProperty: IUiListItem) => {
    //console.log(textProperty)
    //console.log(props.sourceLocale)
    let result = undefined;
    if (props.sourceLocale) {
      if (props.sourceLocale == "i18n") {
        result = textProperty.id;
      } else if (textProperty.name) {
        result = textProperty.name[props.sourceLocale];
      }
    }
    return result;
  };
  //---------------------------------------------------------------------------
  var updatedOptions: TUiMenuOption[] = [];
  tmpUi.formOptions.options.forEach(option => {
    switch (option.id) {
      case "optionOk":
        const updatedOkOption: TUiMenuOption = {
          ...option,
          disabled: !(state?.isUpdated && state?.isValid)
        };
        updatedOptions.push(updatedOkOption);
        break;
      //-----------------------------------------------------------------------
      case "optionCancel":
        updatedOptions.push(option);
        break;
    };
  });
  //---------------------------------------------------------------------------
  if (state?.object)
    return (
      <React.Fragment>
        <div ref={editFormDivRef} className={styles.container}>
          <div className={styles.inputsContainer}>
            <InputsGroupWrapper>
              <InputText
                ui={{ ...tmpUi.inputId, disabled: true, hideHint: true }}
                data={state?.object?.id ? state.object.id : ""}
              />
              <TranslationEditor
                ui={{
                  id: "NameInput",
                  caption: { en: "Name" },
                  inputLocale: tmpUi.inputLocale
                }}
                data={{
                  elementId: props.data.id,
                  elementPart: "Name",
                  draftLocale: props.draftLocale,
                  draftValue: state?.object?.name?.value,
                  draftFallbackAccepted: state?.object ? state.object.name?.fallbackAccepted : false,
                  sourceLocale: props.sourceLocale,
                  sourceValue: getSourceValue(props.data.publishedVersion),
                  publishedTranslations: (props.data.publishedVersion as IDictionaryDraftItem).name
                }}
                onUpdate={onNameUpdate}
              />
              <TranslationEditor
                ui={{
                  id: "DescriptionInput",
                  caption: { en: "Description" },
                  inputLocale: tmpUi.inputLocale
                }}
                data={{
                  elementId: props.data.id,
                  elementPart: "Description",
                  draftLocale: props.draftLocale,
                  draftValue: state?.object?.description?.value,
                  draftFallbackAccepted: state?.object ? state.object.description?.fallbackAccepted : false,
                  sourceLocale: props.sourceLocale,
                  sourceValue: getSourceValue(props.data.publishedVersion),
                  publishedTranslations: (props.data.publishedVersion as IDictionaryDraftItem).description
                }}
                onUpdate={onDescriptionUpdate}
              />
            </InputsGroupWrapper>
          </div>
          <FormOptions
            ui={{ ...tmpUi.formOptions, options: updatedOptions }}
            applyMobileStyle={false}
            onSelect={onOptionSelect}
          />
        </div>

        <PopupMessage
          id='DictionaryNodeDiscardChangesConfirmationMessage'
          type='Confirmation'
          header={`System Translator Console. Dictionary Editor`}
          message='Please confirm you are going to discard unsaved changes'
          optionsMenuContent={discardChangesConfirmationDialogMenu}
          show={discardChangesDialogOpen}
          onOptionSelect={onDiscardChangesDialogOptionSelect}
          onCancel={() => onDiscardChangesDialogOptionSelect("optionCancel")}
        />

      </React.Fragment>
    );
  else return null;
}