import { IDictionaryItemStatus } from "../../../../../common/dictionaries/IUiDictionaryItem";
import { DictionaryDraft, IDictionaryDraft } from "../../../../common/dictionary-draft-models/DictionaryDraft";
import { DraftItemStateEnum } from "../../../../common/dictionary-draft-models/DraftItemStateEnum";
import { IDictionaryDraftFilter } from "../../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter";
import { DictionaryEditorDraftItem, IDictionaryEditorDraftItem } from "../../common/models/DictionaryEditorDraftItem";

export interface IRegionsDictionaryDraftItem extends IDictionaryEditorDraftItem {
  status?: IDictionaryItemStatus;
  items?: RegionsDictionaryDraftItem[];
  searchId: string[];
  searchName: string[];
}
interface IRegionsDictionaryDraftItemSpread {
  name?: any;
  description?: any;
  items?: RegionsDictionaryDraftItem[];
  status?: IDictionaryItemStatus;
  deleted?: boolean;
  path?: string | undefined;
  pathOld?: string | undefined;
  pathNew?: string | undefined;
}
export class RegionsDictionaryDraftItem extends DictionaryEditorDraftItem implements IRegionsDictionaryDraftItem {
  index!: number;
  deleted!: boolean;
  items?: RegionsDictionaryDraftItem[];
  searchId!: string[]; // List of all children Ids used for search
  searchName!: string[];
  status?: IDictionaryItemStatus;
  //---------------------------------------------------------------------------
  constructor(
    source: IRegionsDictionaryDraftItem,
    //source: any,
    spread?: IRegionsDictionaryDraftItemSpread
  ) {
    const sourceCopy = JSON.parse(JSON.stringify(source));
    super(sourceCopy);
    Object.assign(this, sourceCopy, spread);
    //this.updateItems();
    this.refresh("en");
  }
  //---------------------------------------------------------------------------
  updateItems() {
    this.searchId = [];
    this.searchName = [];
    // Run through items and create SearchId
    if (this.items && this.items.length > 0) {
      this.items = this.items.map(subItem => {
        const updatedSubItem = new RegionsDictionaryDraftItem(subItem);
        this.searchId = this.searchId.concat(updatedSubItem.id, updatedSubItem.searchId);
        if (updatedSubItem.name)
          this.searchName = this.searchName.concat(updatedSubItem.name, updatedSubItem.searchName);
        return (
          updatedSubItem
        );
      });
    };
  };
  //---------------------------------------------------------------------------
  isValid(): boolean {
    this.refresh("en");
    return !this.isNoTranslation;
  }
  //---------------------------------------------------------------------------
  deactivate() {
    this.deleted = true;
    this.status = undefined;
    this.refresh('en');
  };
  //---------------------------------------------------------------------------
  activate() {
    this.deleted = false;
    // Try to get status from published version to restore it
    const publishedStatus = this.publishedVersion ? (this.publishedVersion as IRegionsDictionaryDraftItem).status : undefined;
    if (publishedStatus) {
      this.status = publishedStatus;
    } else {
      // Set default status
      this.status = {
        isAvailableToStaffUsers: true,
        isAvailableToEndUsers: false
      };
    };
    this.refresh('en');
  };
  //---------------------------------------------------------------------------
  setStatusForStaffUsers(isAvailableForStaff: boolean) {
    const isActive = isAvailableForStaff || this.status?.isAvailableToEndUsers;
    this.deleted = !isActive;
    if (isActive) {
      this.status = {
        isAvailableToStaffUsers: isAvailableForStaff,
        isAvailableToEndUsers: !!this.status?.isAvailableToEndUsers
      };
    } else
      this.status = undefined;
    this.refresh('en');
  };
  //---------------------------------------------------------------------------
  setStatusForEndUsers(isAvailableForEndUsers: boolean) {
    const isActive = isAvailableForEndUsers || this.status?.isAvailableToStaffUsers;
    this.deleted = !isActive;
    if (isActive) {
      this.status = {
        isAvailableToStaffUsers: !!this.status?.isAvailableToStaffUsers,
        isAvailableToEndUsers: isAvailableForEndUsers
      };
    } else
      this.status = undefined;
    this.refresh('en');
  };
  //---------------------------------------------------------------------------
  // filterAllNodesUnderThis(items?: RegionsDictionaryDraftItem[]): RegionsDictionaryDraftItem[] | undefined {
  //   let path = this.getPathForChilds();
  //   return items?.filter(item => item.path?.startsWith(path));
  // }
  //---------------------------------------------------------------------------
  // filterDirectSiblings(items?: RegionsDictionaryDraftItem[]): RegionsDictionaryDraftItem[] | undefined {
  //   let path = this.getPathForChilds();
  //   return items?.filter(item => item.path === path);
  // }
  //---------------------------------------------------------------------------
  // static getSiblings(
  //   source: RegionsDictionaryDraftItem[],
  //   directSiblings: RegionsDictionaryDraftItem[],
  //   otherSiblings: RegionsDictionaryDraftItem[],
  //   path?: string) {
  //   //---------------------------------------------------------------------------
  //   source.forEach(item => {
  //     if (item.path === path)
  //       directSiblings.push(item);
  //     else if (!path || item.path?.startsWith(path))
  //       otherSiblings.push(item);
  //   });
  // }
  //---------------------------------------------------------------------------
  checkFilter(filter?: IDictionaryDraftFilter): boolean {
    if (!filter) {
      return true;
    }
    let resultText = true;
    let resultState = true;
    //-------------------------------------------------------------------------
    // Filter by text
    if (filter.text) {
      const searchString: string = filter.text.toLocaleLowerCase();
      const name = this.name ? this.name : "";
      resultText = this.id.toLocaleLowerCase().includes(searchString)
        || (name.toLocaleLowerCase().includes(searchString))
        || this.searchId.findIndex(x => x.toLocaleLowerCase().includes(searchString)) > -1
        || this.searchName.findIndex(x => x.toLocaleLowerCase().includes(searchString)) > -1;
    };
    //-------------------------------------------------------------------------
    // Filter by state
    if (filter.draftState !== DraftItemStateEnum.All) {
      resultState = (this.draftState & filter.draftState) != DraftItemStateEnum.None;
      //console.log(item.id, state.filter.draftState, item.draftState)
    };
    //-------------------------------------------------------------------------
    return resultText && resultState;
  };
}



export class RegionsDictionaryDraft extends DictionaryDraft<IRegionsDictionaryDraftItem> {
  items!: RegionsDictionaryDraftItem[];
  //---------------------------------------------------------------------------
  constructor(source: IDictionaryDraft<IRegionsDictionaryDraftItem>) {
    super(source);
    this.createItems(source.items);
    if (source.publishedVersion) {
      this.publishedVersion = new RegionsDictionaryDraft(source.publishedVersion);
    }
    //if (this.isHierarchical) {
    //this.items = [DictionaryDraft.buildHierarchy(this.items)];
    //}
  }
  //---------------------------------------------------------------------------
  private createItems(source: IRegionsDictionaryDraftItem[]) {
    this.items = [];
    for (let i = 0; i < source.length; i++) {
      this.items.push(new RegionsDictionaryDraftItem(source[i]));
    }
  }
}
