import Icon from "../../../icon/Icon";
import styles from "./ExpandButton.module.css";

interface IProps {
  expanded: boolean;
}

export default function ExpandButton(props: IProps) {
  //---------------------------------------------------------------------------rightArrow
  return (
    <div
      className={styles.container}
      //style={{ width: props.widthEm ? `${props.widthEm}em` : undefined }}
    >
      <div
        className={styles.icon}
        style={{ display: props.expanded ? "flex" : "none" }}
      >
        <Icon imageName="downArrow" />
      </div>
      <div
        className={styles.icon}
        style={{ display: !props.expanded ? "flex" : "none" }}
      >
        <Icon imageName="rightArrow" />
      </div>
    </div>
  );
}