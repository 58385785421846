import styles from "./MainMenuPopupOption.module.css";
import { ReactNode, useState } from "react";
import { IUiOption } from "../../../../../common/options/IUiOption";
import ButtonCaption from "../../../../../common/button-caption/ButtonCaption";
import { ButtonType } from "../../../../../common/button/ButtonType";
import { GetTUiMenuOption } from "../../../../../common/menu/menu-content/menu-option/TUiMenuOption";
import CombineStyles from "../../../../../../utils/combineStyles";
import { IUiMenuContent } from "../../../../../common/options/menus/IUiMenuContent";
import { ScreenType, useAppScreenContext } from "../../../../../../context/app-screen-context/AppScreenProvider";
import PopupMenu from "../../../../../common/popup-v2/popup-menu/PopupMenu";
import { getTUiMenuContent } from "../../../../../common/menu/menu-content/TUiMenuContent";

interface IProps {
  ui: IUiOption;
  selectedOptionPath?: string;
  onSelect: (optionId: string) => void;
  onClose: () => void;
}

export default function MainMenuPopupOption(props: IProps) {
  const screenType = useAppScreenContext();
  const [open, setOpen] = useState(false);
  const submenuContent = props.ui.action as IUiMenuContent;
  //---------------------------------------------------------------------------
  const currentOptionId = props.ui.id.split(".").pop();
  const isSelected = currentOptionId ? props.selectedOptionPath?.startsWith(currentOptionId) : false;
  let subPath: string | undefined = undefined;
  if (isSelected) {
    subPath = currentOptionId ? props.selectedOptionPath?.substring(currentOptionId.length + 1) : undefined;
  };
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (props.ui.action) {
      // If there are sub-options, show them
      setOpen(!open);
    } else {
      // Leaf option, so use callback
      props.onSelect(props.ui.id);
    };
  };
  //---------------------------------------------------------------------------
  const onSuboptionSelect = (suboptionId: string) => {
    props.onSelect(`${currentOptionId}.${suboptionId}`);
  };
  //---------------------------------------------------------------------------
  let subMenu: ReactNode = undefined;
  if (submenuContent) {
    if (screenType == ScreenType.Mobile) {
      // Mobile: Render usual popup menu
      subMenu =
        <PopupMenu
          ui={getTUiMenuContent(submenuContent)}
          displayIcon={true}
          onBack={() => setOpen(false)}
          onClose={props.onClose}
          onSelect={onSuboptionSelect}
        />;
    } else {
      // Desktop: Render suboptions
      const subOptions = submenuContent.options.map(option =>
        <MainMenuPopupOption
          key={option.id}
          ui={option}
          selectedOptionPath={subPath}
          onSelect={onSuboptionSelect}
          onClose={props.onClose}
        />
      );
      subMenu =
        <div
          id={submenuContent.id}
          className={styles.suboptions}>
          {subOptions}
        </div>;
    };
  };
  //---------------------------------------------------------------------------
  return (
    <div
      id={props.ui.id}
      className={styles.wrapper}
    >
      <div
        className={CombineStyles([
          styles.container,
          isSelected ? styles.selected : ""
        ])}
        onClick={onSelect}
      >
        <ButtonCaption
          type={ButtonType.Popup}
          ui={GetTUiMenuOption(props.ui)}
          customCaption={props.ui.customCaption}
          displayIcon={true}
          isDropdown={props.ui.isDropdown}
        />
      </div>

      {open && subMenu}

    </div>
  )
}