import { IAdUnitImage } from '../../../../common/ad-modules/IAdModule';
import { IAdUnitStatus } from '../../../../common/ad-modules/IAdUnit';
import ImageThumbnail from '../../../../user-console/user-images/image-library/image-thumbnail/ImageThumbnail';
import ChecklistItemWrapper from '../checklist-item-wrapper/ChecklistItemWrapper';

interface IProps {
  data: IAdUnitImage;
  level?: number; // Instead of indent
  isReadonly: boolean;
  onHover?: (isHovered: boolean) => void;
  onReview?: (updatedImageUnit: IAdUnitImage) => void;
}

export default function ChecklistItemImage(props: IProps) {
  if (!props.data.status) return null;
  const imageIsReadonly = props.data.imageData?.imageStatus !== "Draft" && props.data.imageData?.imageStatus !== "ApprovePending";
  //---------------------------------------------------------------------------
  const onHover = (isHovered: boolean) => {
    props.onHover && props.onHover(isHovered);
  };
  //---------------------------------------------------------------------------
  const onReview = (updatedStatus: IAdUnitStatus) => {
    console.log("ChecklistItemImage.onReview");
    if (props.isReadonly) return;
    const updatedUnit: IAdUnitImage = {
      ...props.data,
      status: updatedStatus
    };
    props.onReview?.(updatedUnit);
  };
  //---------------------------------------------------------------------------
  return (
    <ChecklistItemWrapper
      unitType='ContentImage'
      status={props.data.status}
      isReadonly={props.isReadonly || imageIsReadonly }
      onHover={onHover}
      caption={props.data.type}
      level={props.level}
      onReview={onReview}
    >
      <ImageThumbnail
        data={props.data.imageData}
        height='6em'
      />
    </ChecklistItemWrapper>
  );
}