import { AdLanguageSet, IAdLanguageSet } from "../../components/ad-content-editor/IAdLanguageSet";
import getUpdatedPropertiesList, { IComparisonResult } from "./functions/getUpdatedPropertiesList";
import { IObjectState } from "./IBusinessContext";

export interface IAdLanguageSetState extends IObjectState<IAdLanguageSet> {
}

export class ClassAdLanguageSetState implements IAdLanguageSetState {
  object!: AdLanguageSet;
  initialState!: AdLanguageSet;
  updatedProperties!: string[];
  isNew!: boolean;
  isChanged!: boolean;
  isValid!: boolean;
  get adLanguageSet(): AdLanguageSet {
    return this.object;
  };
  set adLanguageSet(value: AdLanguageSet) {
    this.object = value;
  };
  get isReadyToPublish(): boolean {
    return !this.isChanged && this.isValid;
  }
  //---------------------------------------------------------------------------
  constructor(data: IAdLanguageSetState) {
    Object.assign(this, data);
  };
  //---------------------------------------------------------------------------
  static initializeContext(adLanguageSetData: IAdLanguageSet, isNew?: boolean): IAdLanguageSetState {
    const adLanguageSetObject = new AdLanguageSet(adLanguageSetData);
    const adLanguageSetObjectInitial = new AdLanguageSet(JSON.parse(JSON.stringify(adLanguageSetData)));
    const context: IAdLanguageSetState = {
      object: adLanguageSetData,
      initialState: adLanguageSetObjectInitial,
      updatedProperties: [],
      isNew: isNew == true ? true : false,
      isChanged: isNew == true ? true : false,
      isValid: adLanguageSetObject.validate()
    };
    //console.log("ClassAdLanguageSetState.initializeContext", context);
    return context;
  };
  //---------------------------------------------------------------------------
  getUpdatedContext(updatedAdLanguageSet: IAdLanguageSet, isValid?: boolean): IAdLanguageSetState {
    const updatedObject = new AdLanguageSet(updatedAdLanguageSet);
    const comparisonResult: IComparisonResult = getUpdatedPropertiesList(updatedObject, this.initialState);
    //const isValidBool = isValid == undefined ? true : isValid;
    //-------------------------------------------------------------------------
    if (comparisonResult.updatedProperties?.includes("adModules")) {
      // If modules are updated, update modules editor status, comparing modules to initial state
      updatedObject.adModules?.forEach(adModule => {
        const initialModule = this.initialState.adModules?.find(item => item.id === adModule.id);
        if (initialModule) {
          // Check if module is modified
          const comparisonResult: IComparisonResult = getUpdatedPropertiesList(adModule, initialModule);
          console.log(adModule.adModuleType, comparisonResult)
          if (comparisonResult.isChanged) {
            adModule.editorStatus = {
              isNew: adModule.editorStatus.isNew,
              isModified: adModule.editorStatus.isNew ? false : true
            };
          };
        } else {
          // Module is new
          adModule.editorStatus = {
            isNew: true,
            isModified: false
          };
        };
      });
    };
    //-------------------------------------------------------------------------
    const context: IAdLanguageSetState = {
      ...this,
      adLanguageSet: updatedObject,
      updatedProperties: comparisonResult.updatedProperties,
      isChanged: comparisonResult.isChanged,
      isValid: updatedObject.validate() //&& isValidBool
    };
    //console.log("ClassAdLanguageSetState.getUpdatedContext", context);
    return context;
  };
}