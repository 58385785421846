import { useContext, useEffect, useState } from 'react';
import styles from './BusinessTagsPopup.module.css';
import { IUiBusinessTagsPopup } from './IUiBusinessTagsPopup';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import PopupDialog from '../../popup-v2/popup-dialog/PopupDialog';
import GetCaption from '../../functions/GetCaption';
import { IUiOption } from '../../options/IUiOption';
import FormOptions from '../../form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../menu/menu-content/TUiMenuContent';
import { EditFormState } from '../../edit-form/IEditFormState';
import InputText from '../../input-v2/input-text/InputText';
import BusinessTagsCollection from './business-tags-collection/BusinessTagsCollection';
import { IUiBusinessTag, UiBusinessTag } from './business-tag-node/IUiBusinessTag';
import { ScreenType, useAppScreenContext } from '../../../../context/app-screen-context/AppScreenProvider';
import CombineStyles from '../../../../utils/combineStyles';

const optionIdOk = "OptionOk";
const optionIdCancel = "OptionCancel";
const optionIdRequestNewTag = "OptionRequest";

interface IProps {
  ui: IUiBusinessTagsPopup;
  rootPath?: string;
  selectedPaths: string[];
  allowRequestTags?: boolean;
  onUpdate: (selectedPaths?: string[]) => void;
  onClose: () => void;
}

export default function BusinessTagsPopup(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const [filterString, setFilterString] = useState<string>();
  const [state, setState] = useState<EditFormState<string[]>>();
  const optionRequestNewTag = props.ui.menuContent.options?.find(option => option.id === optionIdRequestNewTag);
  const businessTags = (appUiContext.dictionaries?.businessTags?.filter(item =>
    item.path?.startsWith(props.rootPath ?? '')) as IUiBusinessTag[]).map(tag => new UiBusinessTag(tag)).filter(tag => tag.status && tag.status.viewers);
  //---------------------------------------------------------------------------
  useEffect(() => {
    const state = new EditFormState<string[]>({
      object: props.selectedPaths ? props.selectedPaths : [],
      isValid: true
    });
    setState(state);
  }, [props.selectedPaths]);
  //--------------------------------------------------------------------------- 
  const onSearchInputUpdate = (value: string) => {
    if (value != undefined && value !== "")
      setFilterString(value.toLocaleLowerCase());
    else
      setFilterString(undefined);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (selectedPaths?: string[]) => {
    if (!state) return;
    const updatedState = new EditFormState<string[]>(state, {
      object: selectedPaths,
      isValid: true
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case optionIdOk:
        if (!state) return;
        props.onUpdate && props.onUpdate(state.object);
        break;
      case optionIdCancel:
        props.onClose();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui?.menuContent.options?.forEach(option => {
    switch (option.id) {
      case optionIdOk:
        updatedOptions.push({
          ...option,
          isDisabled: !state?.isUpdated,
          isDefault: true
        });
        break;
      case optionIdCancel:
        updatedOptions.push(option);
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui?.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id=""
      header={GetCaption(appUiContext, props.ui?.id, props.ui?.caption)}
      toolbarContent={formOptions}
      isModal={true}
      isDraggable={false}
      onClose={props.onClose}
    >
      <div className={CombineStyles([
        styles.container,
        screenType === ScreenType.Desktop ? styles.desktop : ""
      ])}>

        <InputText
          ui={{ ...props.ui.inputSearch, hideCaption: true, hideHint: true }}
          data={filterString}
          onUpdate={onSearchInputUpdate}
        />

        {state?.object &&
          <div className={styles.list}>
            <BusinessTagsCollection
              ui={{
                optionRequestNewTag: optionRequestNewTag
              }}
              tags={businessTags}
              rootPath={props.rootPath}
              selectedPaths={state.object}
              filterString={filterString}
              onUpdate={onUpdate}
            />
          </div>}

      </div>
    </PopupDialog>
  );
}

interface IComponentProps {
  text?: string;
}
function Component(props: IComponentProps) {
  console.log("Component", props.text);
  if (props.text) {
    return <div>{props.text}</div>;
  } else return null;
}