//-----------------------------------------------------------------------------

import { IAuthConfig } from "../AppAuthContext";

// Interfaces for the object that comes from API
interface IPolicies {
  signUpSignInPolicyId: string; //"B2C_1_SignUpSignIn"
  changePasswordPolicyId: string; //"B2C_1A_PASSWORDRESET"
}

export interface IIdentityServiceData {
  isHealthy: boolean,
  config: IAuthConfig
}

//-----------------------------------------------------------------------------
// Function returns instance of IAuthConfig
// MSAL config reference
// FAQ: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/FAQ.md (includes compatibility with browsers)
// Config options: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md#using-the-config-object

export interface IProps {
  isHealthy: boolean;
  tenantDomain: string; //e.g. "rikiwikib2cdev4.onmicrosoft.com"
  authorityDomain: string; //e.g. "rikiwikib2cdev4.b2clogin.com"
  homePageUri: string; //URL, e.g. "https://dev2.riki.wiki";
  applicationId: string; // GUID, e.g. "21275653-8eb7-4277-a023-309e91eac0ae";
  policies: IPolicies;
}

export function GetIdentityServiceData(
  identityServiceConfig: IProps
): IIdentityServiceData {
  const signUpSignInPolicyAuthority = `https://${identityServiceConfig.authorityDomain}/${identityServiceConfig.tenantDomain}/${identityServiceConfig.policies.signUpSignInPolicyId}`;
  const forgotPasswordPolicyAuthority = identityServiceConfig.policies
    .changePasswordPolicyId
    ? `https://${identityServiceConfig.authorityDomain}/${identityServiceConfig.tenantDomain}/${identityServiceConfig.policies.changePasswordPolicyId}`
    : undefined;
  const serviceIsHealthy: boolean =
    identityServiceConfig.isHealthy &&
    Boolean(identityServiceConfig.applicationId) &&
    Boolean(identityServiceConfig.authorityDomain) &&
    Boolean(identityServiceConfig.tenantDomain) &&
    Boolean(signUpSignInPolicyAuthority);
  //---------------------------------------------------------------------------
  return {
    isHealthy: serviceIsHealthy,
    config: {
      clientId: identityServiceConfig.applicationId,
      msalConfig: {
        auth: {
          clientId: identityServiceConfig.applicationId,
          authority: signUpSignInPolicyAuthority,
          knownAuthorities: [identityServiceConfig.authorityDomain],
          redirectUri: "/redirect"
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: true,
        },
      },
      loginScopes: ["openid", "offline_access"],
      apiScopes: [identityServiceConfig.applicationId],
      authorities: {
        signUpSignIn: signUpSignInPolicyAuthority,
        forgotPassword: forgotPasswordPolicyAuthority,
      },
      extraQueryParameters: {
        ui_locales: "uk",
        application_name: "rikiwiki",
        theme: "MenuColourThemes_dark"
      }
    },
  };
}

/*
login request additional parameters:
extraQueryParameters: {
      ui_locales: getFromLS(langLS),
      application_name: "rikiwiki",
      theme: getFromLS("theme").id,
    }
*/