import React from "react";
import styles from "./TogglerWithCaption.module.css";
import Toggler from "./Toggler";
//--------------------------------------------------------------------------------
export type ETogglerPosition = 'Left' | 'Right';
//--------------------------------------------------------------------------------
interface IProps {
  caption: string;
  showToggler: boolean;
  togglerPosition: ETogglerPosition;
  collapsed: boolean;
  onClick: () => void;
}
//--------------------------------------------------------------------------------
export function TogglerWithCaption(props: IProps) {

  let captionStyle = props.showToggler ? styles.captionWithToggler : styles.caption;
  switch (props.togglerPosition) {
    case 'Left':
      return (
        <div className={captionStyle} onClick={props.onClick}>
          {props.showToggler &&
            <div className={styles.toggler}>
              <Toggler collapsed={props.collapsed} />
            </div>
          }
          <span>{props.caption}</span>
        </div >
      );
    case 'Right':
      return (
        <div className={captionStyle} onClick={props.onClick}>
          <span>{props.caption}</span>
          {props.showToggler &&
            <div className={styles.toggler}>
              <Toggler collapsed={props.collapsed} />
            </div>
          }
        </div >
      );
  }
}