import { ChangeEvent } from 'react';
import InputContainer from '../input-container/InputContainer';
import { TUiValidation } from '../input-validation/TUiValidation';
import styles from './InputDatetime.module.css';
import CombineStyles from '../../../../utils/combineStyles';

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local

function formatIntTwoDigits(n: number): string {
  return n < 10 ? `0${n}` : `${n}`;
}

//-----"yyyy-MM-ddThh:mm:ss"
export function FormatDateForInput(d?: Date): string | undefined {
  if (!d)
    return undefined;
  let n = d.getMonth() + 1;
  let month = formatIntTwoDigits(n);
  n = d.getDate();
  let date = formatIntTwoDigits(n);
  n = d.getHours();
  let hours = formatIntTwoDigits(n);
  n = d.getMinutes();
  let minutes = formatIntTwoDigits(n);
  n = d.getSeconds();
  let seconds = formatIntTwoDigits(n);
  return `${d.getFullYear()}-${month}-${date}T${hours}:${minutes}:${seconds}`;
}

export interface IUiInputDatetime {
  id: string;
  caption: any;
  hint: any;
  disabled: boolean;
  validation: TUiValidation | undefined;
}

interface IProps {
  ui: IUiInputDatetime;
  data?: Date;
  isWarning?: boolean;
  isInvalid?: boolean;
  onUpdate?: (updatedValue: Date, isValid: boolean) => void;
}

export default function InputDatetime(props: IProps) {
  const isWarning = props.isWarning == undefined ? false : props.isWarning;
  const isInvalid = props.isInvalid == undefined ? false : props.isInvalid;
  //---------------------------------------------------------------------------
  const onUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    var value = e.target.value;
    var d: Date = new Date(value);
    console.log("InputDatetime.onUpdate date:", d);
    // var datetimeUtc = Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes());
    // var datetimeUtcString = d.toUTCString();
    // console.log(datetimeUtcString)
    // var datetime = new Date(datetimeUtcString);
    // console.log(datetime)
    // NOTE: Did not find a way to get UTC date here in javascript, so the backend should handle conversion
    props.onUpdate && props.onUpdate(d, true);
  };
  //---------------------------------------------------------------------------
  let dateString = FormatDateForInput(props.data);
  let disabledValue = dateString ? dateString : "--";
  let value = dateString;
  let minValue = FormatDateForInput(new Date()) as string;
  if (minValue.indexOf('.') >= 0)
    minValue = minValue.split('.')[0];
  //---------------------------------------------------------------------------
  return (
    <InputContainer
      ui={props.ui}
      hideCaption={false}
      hideHint={true}
      isValid={true}
    >
      {props.ui.disabled ?
        <div className={styles.readonly}>
          {disabledValue}
        </div>
        :
        <input
          type='datetime-local'
          className={CombineStyles([styles.input, isWarning ? styles.warning : "", isInvalid ? styles.invalid : ""])}
          defaultValue={value}
          value={value}
          min={minValue}
          onChange={onUpdate}
        />
      }
    </InputContainer>
  );
}