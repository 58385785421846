import { CSSProperties, ReactNode } from 'react';
import CombineStyles from '../../../../utils/combineStyles';
import styles from './DirectoryNodeWrapper.module.css';
import { IUiDirectoryEditorNode } from './IUiDirectoryTranslatorNode';
import DirectoryNodeBadges from './directory-node-badges/DirectoryNodeBadges';
import { IDirectoryDraftItem } from '../../../../context/directory-editor-context/IDirectoryDraftItem';
import DirectoryNodeMenu from './directory-node-menu/DirectoryNodeMenu';

interface IProps {
  ui?: IUiDirectoryEditorNode;
  data: IDirectoryDraftItem;
  children?: ReactNode; // Must contain one or more DirectoryNodeAttribute components
  editModeIsEnabled?: boolean;
  customStyles?: CSSProperties;
  onOptionSelect?: (selectedOptionId: string) => void;
}

export default function DirectoryNodeWrapper(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.row,
        props.editModeIsEnabled ? styles.editable : ''
      ])}
      style={props.customStyles}>

      {props.children}

      <DirectoryNodeBadges
        draftState={props.data.draftState}
      />

      <DirectoryNodeMenu
        ui={props.ui?.menuContent}
        data={props.data}
        onOptionSelect={props.onOptionSelect}
      />

    </div>
  );
}