import React, { Dispatch, ReactNode, useEffect, useReducer } from "react";
import { Actions, ListManagerContextReducer } from "./ListManagerContextReducer";
import { IListManagerContextData, ListManagerContextData } from "./IListManagerContextData";

interface IListManagerContext {
  listManagerContext: IListManagerContextData;
  listManagerContextDispatch: Dispatch<Actions>;
}

export const ListManagerContext = React.createContext({} as IListManagerContext);

interface IProps {
  children: ReactNode;
}

export default function ListManagerContextProvider(props: IProps) {
  const [listManagerContext, listManagerContextDispatch] = useReducer(
    ListManagerContextReducer,
    new ListManagerContextData());
  //--------------------------------------------------------------------------- Session data
  useEffect(() => {
    return () => {
      //listManagerContext.
    };
  }, []);
  //---------------------------------------------------------------------------
  return (
    <ListManagerContext.Provider value={{ listManagerContext, listManagerContextDispatch }}>
      {props.children}
    </ListManagerContext.Provider>
  );
}