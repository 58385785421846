import { IUiInputText } from "../../input-v2/input-text/InputText";
import { TUiValidation, getTUiValidation } from "../input-validation/TUiValidation"

export type TUiInputText = {
  id: string,
  directoryId: string,
  elementType: string,
  disabled: boolean,
  visible: boolean,
  caption: any,
  hint: any,
  placeholder: any,
  defaultValue: any,
  validation: TUiValidation | undefined;
}

export function getTUiInputText(it: IUiInputText): TUiInputText {
  return {
    id: it.id,
    directoryId: it.directoryId,
    elementType: it.elementType,
    caption: it.caption,
    hint: it.hint,
    placeholder: it.placeholder,
    defaultValue: it.defaultValue,
    disabled: !!it.disabled,
    visible: true,
    validation: it.validation ? getTUiValidation(it.validation) : undefined
  };
}