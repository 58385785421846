import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { apiGetPrivate, checkResponse } from "../../../../utils/api";
import { apiBasePath, getUserImages } from "../../../../utils/apiPathConstant";
import { IImageData, TImageData } from "../../../common/image-editor/TImageData";
//-------------------------------------------------------------------------
export interface IImageLibraryItem extends IImageData {
  name: string;
  uploadedAt?: Date;
}
//-------------------------------------------------------------------------
export class TImageLibraryItem extends TImageData {
  name: string = "";
  uploadedAt?: Date;

  constructor(source: any, spread?: any) {
    super(source, spread);
    if (source.name)
      this.name = source.name;
    if (source.uploadedAt)
      this.uploadedAt = new Date(source.uploadedAt);
  }

  static fromArray(source: any): TImageLibraryItem[] {
    let result: TImageLibraryItem[] = [];
    source?.forEach((item: any) => result.push(new TImageLibraryItem(item)));
    return result;
  }

  compareDate(other: TImageLibraryItem): number {
    // if (this.uploadedAt && other.uploadedAt)
    //   return other.uploadedAt.getTime() - this.uploadedAt.getTime();
    // if (other.uploadedAt)
    //   return -1;
    // else if (this.uploadedAt)
    //   return 1;
    // return 0;
    if (other.lastModified && this.lastModified)
      return Math.sign(other.lastModified.getTime() - this.lastModified.getTime());
    if (this.lastModified)
      return -1;
    else if (other.lastModified)
      return 1;
    return 0;
  }

  compareDescription(other: TImageLibraryItem, filter: string): number {
    if (other.description && this.description)
      return this.description.toLowerCase().indexOf(filter) - other.description.toLowerCase().indexOf(filter);
    if (this.description)
      return -1;
    else if (other.description)
      return 1;
    return this.compareDate(other);
  }
};
//-------------------------------------------------------------------------
export type TImageLibraryItems = TImageLibraryItem[];
//-------------------------------------------------------------------------
export class ClassImageList {
  items: TImageLibraryItem[] = [];
  //-----------------------------------------------------------------------
  constructor(source: IImageLibraryItem[]) {
    this.items = TImageLibraryItem.fromArray(source);
  }
  //-----------------------------------------------------------------------
  static async request(
    config: IAuthConfig | null,
    instance: IPublicClientApplication,
    abortSignal?: AbortSignal
  ): Promise<ClassImageList> {
    return new Promise((resolve, reject) => {
      //-------------------------------------------------------------------------
      // Retrieve Image list from API otherwise
      //console.log("Retrieve Image list from API");
      apiGetPrivate(
        instance,
        config,
        `${apiBasePath}${getUserImages}`,
        abortSignal
      )
        .then((response) => {
          checkResponse(response, "requestUserImageLibrary", abortSignal?.aborted);
          let images = TImageLibraryItem.fromArray(response?.content);
          images.sort((i1, i2) => i1.compareDate(i2));
          resolve(new ClassImageList(images));
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}
//-------------------------------------------------------------------------
export function requestUserImageLibrary(
  onImages: (images: TImageLibraryItems | undefined) => void,
  onFinally: () => void,
  config?: IAuthConfig,
  instance?: IPublicClientApplication,
  abortSignal?: AbortSignal
) {
  //-------------------------------------------------------------------------
  if (!config || !instance) {
    onFinally();
    return;
  }
  // Retrieve Image list from API otherwise
  //console.log("Retrieve Image list from API");
  apiGetPrivate(
    instance,
    config,
    `${apiBasePath}${getUserImages}`,
    abortSignal
  )
    .then((response) => {
      checkResponse(response, "requestUserImageLibrary");
      let images = TImageLibraryItem.fromArray(response?.content);
      images.sort((i1, i2) => i1.compareDate(i2));

      // const images: TImageLibraryItems = response.content.sort(
      //   (a: TImageLibraryItem, b: TImageLibraryItem) => new Date(b.uploadedAt).getTime() - new Date(a.uploadedAt).getTime()
      // );
      onImages(images);
    })
    .catch((error) => {
      console.error(error);
      onImages(undefined);
    })
    .finally(() => { onFinally() });
}
//-------------------------------------------------------------------------
export function requestUserImageLibrary2(
  config: IAuthConfig | null,
  instance: IPublicClientApplication,
  abortSignal?: AbortSignal
): Promise<TImageLibraryItems> {
  return new Promise((resolve, reject) => {
    //-------------------------------------------------------------------------
    // Retrieve Image list from API otherwise
    //console.log("Retrieve Image list from API");
    apiGetPrivate(
      instance,
      config,
      `${apiBasePath}${getUserImages}`,
      abortSignal
    )
      .then((response) => {
        checkResponse(response, "requestUserImageLibrary", abortSignal?.aborted);
        let images = TImageLibraryItem.fromArray(response?.content);
        images.sort((i1, i2) => i1.compareDate(i2));
        resolve(images);
      })
      .catch((error) => {
        reject(error);
      })
  });
}

