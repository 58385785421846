import React from 'react';
import { IFilter } from '../../../administrator-console/dictionaries/business-tags-editor/BusinessTagsEditor';
import BusinessTagsDictionaryNodeEditor from '../BusinessTagsDictionaryNodeEditor';
import DropTarget from '../drop-target/DropTarget';
import styles from './BusinessTagChildrenList.module.css';
import { IBusinessTagsDictionaryDraftItem } from '../../../administrator-console/dictionaries/business-tags-editor/IBusinessTagsDictionary';
import { DraftItemStateEnum } from '../../dictionary-draft-models/DraftItemStateEnum';

interface IProps {
  data: IBusinessTagsDictionaryDraftItem;
  initialData?: IBusinessTagsDictionaryDraftItem;
  rootNodes: IBusinessTagsDictionaryDraftItem[];
  displayId: boolean;
  filter: IFilter;
  isReadonly: boolean;
  onUpdate: (updatedTag: IBusinessTagsDictionaryDraftItem, updatedParentTag?: IBusinessTagsDictionaryDraftItem) => void;
  onDelete: (tagPath: string) => void;
}

export default function BusinessTagChildrenList(props: IProps) {
  const displayDropTargets = props.data?.itemsManualOrder;
  //---------------------------------------------------------------------------
  const onReorder = (reorderedTagId: string, newPosition: number) => {
    console.log(props.data.items, reorderedTagId, newPosition)
    if (props.data.items) {
      const itemToReorder = props.data.items.find(item => item.id == reorderedTagId);
      if (itemToReorder) {
        const initialPosition = itemToReorder.index;
        // Filter reordered item out
        let updatedItems: IBusinessTagsDictionaryDraftItem[] = props.data.items.map(item => item).filter(item => item.id != reorderedTagId);
        // And place it in the new position
        updatedItems.splice(newPosition, 0, itemToReorder);
        console.log(updatedItems)
        //---------------------------------------------------------------------
        // Get new position again, because it could different from passed value because of the shuffles
        newPosition = updatedItems.findIndex(item => item.id == reorderedTagId) + 1;
        console.log(newPosition)
        if (((itemToReorder.draftState & DraftItemStateEnum.IsNew) == DraftItemStateEnum.IsNew)
          || ((itemToReorder.draftState & DraftItemStateEnum.IsRepositionedNew) == DraftItemStateEnum.IsRepositionedNew)
          || ((itemToReorder.draftState & DraftItemStateEnum.IsRepositionedOld) == DraftItemStateEnum.IsRepositionedOld)
        ) {
          // Do nothing, because it's new and don't have previous position
          // so it could not be considered as reordered
        } else {
          // Check if the new position is actually the initial position
          if (newPosition == initialPosition) {
            itemToReorder.draftState &= ~DraftItemStateEnum.IsReordered;
          } else {
            itemToReorder.draftState |= DraftItemStateEnum.IsReordered;
          };
        };
        //---------------------------------------------------------------------
        const updatedTag: IBusinessTagsDictionaryDraftItem = {
          ...props.data,
          items: updatedItems
        };
        props.onUpdate(updatedTag);
        //---------------------------------------------------------------------
        // Run through reordered items in the list and update their state if necessary
        // In current version we display as relocated only those dragged manually
        // I.e. we don't mark items that was moved because other tags were moved
        updatedItems.forEach((item, index) => {
          const currentStateIsReordered = (item.draftState & DraftItemStateEnum.IsReordered) == DraftItemStateEnum.IsReordered;
          if (item.index != (index + 1)) {
            if (((item.draftState & DraftItemStateEnum.IsNew) == DraftItemStateEnum.IsNew)
              || ((item.draftState & DraftItemStateEnum.IsRepositionedNew) == DraftItemStateEnum.IsRepositionedNew)
              || ((item.draftState & DraftItemStateEnum.IsRepositionedOld) == DraftItemStateEnum.IsRepositionedOld)
            ) {
              // Do nothing, because it's new and don't have previous position
              // so it could not be considered as reordered
            } else {
              // Update draftstate here if there is a need to display all relocated items,
              // including those relocated just because other were relocated
              item.draftState |= DraftItemStateEnum.IsReordered;
            };
          } else {
            // Element eventually is in the initial position after reorderings
            if (currentStateIsReordered) {
              item.draftState &= ~DraftItemStateEnum.IsReordered;
            };
          };
          //---------------------------------------------------------------
          const updatedTag: IBusinessTagsDictionaryDraftItem = {
            ...props.data,
            items: updatedItems
          };
          props.onUpdate(updatedTag);
        });
      } else console.error(`Reordering is failed: tag with Id=[${reorderedTagId}] is not found`);
    } else console.error('No correct data to process reordering');
  };
  //---------------------------------------------------------------------------
  /* const onReorder_keepingOldPosition = (reorderedTagId: string, oldPosition: number, newPosition: number) => {
    console.log(props.data.items, reorderedTagId, oldPosition, newPosition)
    if (props.data.items && props.initialData?.items) {
      // Get initial tag position
      const initialTagPosition = props.initialData?.items.findIndex(item => item.id == reorderedTagId);
      //console.log(initialTagPosition)
      //-----------------------------------------------------------------------
      // Check if it's an actual (not secondary or reverted) change
      const tagCopies = props.data.items.filter(item => item.id == reorderedTagId);
      //console.log(tagCopies)
      if (tagCopies && (initialTagPosition >= 0)) {
        let updatedItems: IBusinessTagsDictionaryDraftItem[] = props.data.items.map(item => item);
        if (tagCopies.length == 1) {
          // It's a first position change for the tag
          const itemInOldPosition = tagCopies[0];
          // Create a copy for the new position
          const updatedItemCopy: IBusinessTagsDictionaryDraftItem = JSON.parse(JSON.stringify(itemInOldPosition));
          itemInOldPosition.draftState |= DraftItemStateEnum.IsRepositionedOld;
          updatedItemCopy.draftState |= DraftItemStateEnum.IsRepositionedNew;
          // Get updated items list
          updatedItems.splice(newPosition, 0, updatedItemCopy);
        } else {
          // There could be two copies meaning 
          // it's not a first re-position for the tag, so
          // need to find last position and copy it to the new position + remove all previous old positions
          const newVersionToCopy = tagCopies.find(item => item.draftState & DraftItemStateEnum.IsRepositionedNew);
          const updatedNewVersionCopy: IBusinessTagsDictionaryDraftItem = JSON.parse(JSON.stringify(newVersionToCopy));
          updatedNewVersionCopy.draftState |= DraftItemStateEnum.IsRepositionedNew;
          updatedItems.splice(newPosition, 0, updatedNewVersionCopy);
          //console.log(updatedItems)
          //-------------------------------------------------------------------
          // Now remove obsolete tag copies
          updatedItems = updatedItems?.filter(item => !tagCopies.includes(item));
          //console.log(updatedItems)
          //-------------------------------------------------------------------
          // Check if new position is the same as initial one
          const currentPosition = updatedItems.findIndex(item => item.id == reorderedTagId);
          if (currentPosition == initialTagPosition) {
            updatedNewVersionCopy.draftState &= ~DraftItemStateEnum.IsRepositionedNew;
          } else {
            //-----------------------------------------------------------------
            const oldVersionToCopy = props.initialData.items.find(item => item.id == reorderedTagId);
            const updatedOldVersionCopy = JSON.parse(JSON.stringify(oldVersionToCopy));
            updatedOldVersionCopy.draftState |= DraftItemStateEnum.IsRepositionedOld;
            updatedItems?.splice(initialTagPosition, 0, updatedOldVersionCopy);
          };
        };
        //console.log(updatedItems)
        //---------------------------------------------------------------------
        const updatedTag: IBusinessTagsDictionaryDraftItem = {
          ...props.data,
          items: updatedItems,
          //collapsed: false
        };
        props.onUpdate(updatedTag);
      } else console.error(`Could not find tag(s) with id=[${reorderedTagId}]`);
    } else console.error('No correct data to process');
  }; */
  //---------------------------------------------------------------------------
  const children = props.data?.items?.filter(item => {
    // By default include all entries
    let resultText = true;
    let resultState = true;
    //-------------------------------------------------------------------------
    // Filter by text
    if (props.filter.text) {
      resultText = props.filter.searchById ? item.searchStringId.includes(props.filter.text) : item.searchString["en"].includes(props.filter.text);
    };
    //-------------------------------------------------------------------------
    // Filter by state
    if (props.filter.draftState !== DraftItemStateEnum.All) {
      resultState = (item.draftState & props.filter.draftState) != DraftItemStateEnum.None;
      console.log(item.id, props.filter.draftState, item.draftState)
    };
    //-------------------------------------------------------------------------
    return resultText && resultState;
  });
  //---------------------------------------------------------------------------
  const childNodeElements = children?.map((item, index) => {
    const initialData = props.initialData?.items?.find(initItem => initItem.id == item.id);
    return (
      <React.Fragment key={item.id + item.draftState}>
        <BusinessTagsDictionaryNodeEditor
          key={item.id + item.draftState}
          currentPositionIndex={props.data?.itemsManualOrder ? index : undefined}
          data={item}
          index={props.data.itemsManualOrder ? (index + 1) : undefined}
          initialData={initialData}
          parent={props.data}
          rootNodes={props.rootNodes}
          displayId={props.displayId}
          readonly={props.isReadonly}
          manualOrder={!!props.data?.itemsManualOrder}
          filter={props.filter}
          onUpdate={props.onUpdate}
          onDelete={props.onDelete}
          onReorder={onReorder}
        />
        {displayDropTargets && // drop target for re-ordering (for placing a tag after another one)
          <DropTarget
            key={item.id + item.draftState + '_' + index}
          />}
      </React.Fragment>
    );
  });
  //---------------------------------------------------------------------------
  if (props.data.isCollapsed)
    return null;
  else return (
    <div className={styles.container}>

      {displayDropTargets && // drop target for placing a tag to first position in the list
        <DropTarget
          key={'0'}
        />}

      {childNodeElements}

    </div>
  );
}