import { useMsal } from "@azure/msal-react";
import React, { useContext, useEffect, useState } from "react";
import { AppAuthContextStore } from "../../../../../context/app-auth-context/AppAuthContext";
import { TStaffRoles } from "../../../../../context/app-auth-context/TUser";
import { ScreenType, useAppScreenContext } from "../../../../../context/app-screen-context/AppScreenProvider";
import { apiPostPrivate } from "../../../../../utils/api";
import { apiBasePath, assignUserRole, revokeUserRole } from "../../../../../utils/apiPathConstant";
import CombineStyles from "../../../../../utils/combineStyles";
import { stringFormatter } from "../../../../../utils/common";
import WikiSpinner from "../../../../app-layout/spinner/wikiSpinner";
import { TStaffUser } from "../TStaffUser";
import { IStaffRole } from "../user-roles/user-role/UserRole";
import UserRoleEditor from "./user-role-editor/UserRoleEditor";
import styles from "./UserRolesEditor.module.css";
import PopupDialog from "../../../../common/popup-v2/popup-dialog/PopupDialog";
import { IUiStaffRole } from "../../IUiAdministratorConsole";

const cancelOption = {
  action: null,
  canHideCaption: false,
  caption: { en: "Cancel" },
  directoryId: "dirUiCommon",
  disabled: false,
  elementType: "Button",
  hint: { en: "Close and cancel changes" },
  iconFile: "close",
  id: "CloseButton",
  index: 1,
  isDefault: false,
  priorityLevel: 0,
  visible: true,
};
const applyOption = {
  action: null,
  canHideCaption: false,
  caption: { en: "Apply" },
  directoryId: "dirUiCommon",
  disabled: false,
  elementType: "Button",
  hint: { en: "Assign a role" },
  iconFile: "check",
  id: "AssignRole",
  index: 1,
  isDefault: false,
  priorityLevel: 0,
  visible: true,
};

export function GetValidPropertyName(propertyName: string): string {
  return propertyName.substring(0, 1).toLowerCase() + propertyName.substring(1);
}

interface IUserRoleAction {
  staffRoles: IUiStaffRole[];
  data?: TStaffUser;
  onUpdate: (updatedStaffUser: TStaffUser) => void;
  onClose: () => void;
}

export default function UserRolesEditor(props: IUserRoleAction) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const screenType = useAppScreenContext();
  const [data, setData] = useState<TStaffRoles>();
  const [dataInitialState, setDataInitialState] = useState<TStaffRoles>();
  const [stateIsChanged, setStateIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  type ObjectKey = keyof TStaffRoles;
  //---------------------------------------------------------------------------
  useEffect(() => {
    const dataToSet = props.data?.staffRoles ? props.data?.staffRoles : undefined;
    setData(dataToSet);
    setDataInitialState(dataToSet);
  }, [props.data?.staffRoles]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    //console.log(JSON.stringify(data))
    //console.log(JSON.stringify(dataInitialState))
    // Compare current state with initial one and set flag
    if (JSON.stringify(data) == JSON.stringify(dataInitialState)) {
      //console.log("not changed")
      setStateIsChanged(false);
    } else {
      //console.log("changed")
      setStateIsChanged(true);
    };
  }, [data]);
  //--------------------------------------------------------------------------- Assign/revoke a role 
  const onAssignRevoke = (staffRole: IUiStaffRole, isAssigned: boolean) => {
    if (appAuthContext.config && appAuthContext.user && props.data) {
      setIsLoading(true);
      const apiUri = isAssigned ? assignUserRole : revokeUserRole;
      apiPostPrivate(
        instance,
        appAuthContext.config,
        `${apiBasePath}${stringFormatter(apiUri, [props.data.userIdentity.id, staffRole.propertyName])}`
        ).then(response => {
          if (response && response.status === 200) {
            const updatedStaffUser = response.content.updatedStaffUser as TStaffUser;
            setData(updatedStaffUser.staffRoles);
            props.onUpdate(updatedStaffUser);
          } else {
            throw response ? response.status : `No response from ${apiUri} API`;
          };
        }).catch(error => {
          console.error(error);
        }).finally(() => {
          setIsLoading(false);
        });
    } else {
      console.error("Cannot process the request because auth context is not set or user data is not provided");
    };
  };
  //---------------------------------------------------------------------------
  const staffRoleElements = props.staffRoles.map(staffRole => {
    const propertyName = GetValidPropertyName(staffRole.propertyName);
    const existingStaffRole = data ? data[propertyName as ObjectKey] : undefined;
    return (
      <UserRoleEditor
        key={staffRole.groupName}
        staffRole={staffRole}
        data={existingStaffRole as IStaffRole}
        onUpdate={(isAssigned: boolean) => onAssignRevoke(staffRole, isAssigned)}
      />);
  });
  //---------------------------------------------------------------------------
  const containterStyles = CombineStyles([
    styles.container,
    screenType == ScreenType.Mobile ? styles.mobile : ""
  ]);
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id='StaffRolesEditor'
      header={"User staff roles"}
      isDraggable={false}
      isModal={false}
      onClose={props.onClose}
    >
      <WikiSpinner show={isLoading} />
      <div className={containterStyles}>
        {staffRoleElements}
      </div>
    </PopupDialog>
  );
}
