import { CSSProperties, useContext } from "react";
import CombineStyles from "../../../../../utils/combineStyles";
import ButtonCaption from "../../../button-caption/ButtonCaption";
import { ButtonType } from "../../../button/ButtonType";
import { TUiMenuOption } from "../../../menu/menu-content/menu-option/TUiMenuOption";
import styles from "./FormOptionToolbarDesktop.module.css";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";
import GetHint from "../../../functions/GetHint";

interface IProps {
  ui: TUiMenuOption;
  isDropdown: boolean;
  onSelect: (optionId: string) => void;
}

export default function FormOptionToolbarDesktop(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const customCaption = undefined;
  const spin = false;
  //---------------------------------------------------------------------------
  const calculatedStyle: CSSProperties = {
    backgroundColor: props.ui.customColor,
    display: props.ui.visible ? "flex" : "none"
  };
  //---------------------------------------------------------------------------
  const containerStyle = CombineStyles([
    styles.container,
    props.isDropdown ? styles.dropdown : "",
    props.ui.isDefault ? styles.default : "",
    props.ui.disabled ? styles.disabled : ""
  ]);
  //---------------------------------------------------------------------------
  return (
    <div
      id={props.ui.id}
      title={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      className={containerStyle}
      style={calculatedStyle}
      onClick={() => props.onSelect(props.ui.id)}
    >
      <ButtonCaption
        type={ButtonType.Toolbar}
        ui={props.ui}
        customCaption={customCaption}
        displayIcon={true}
        isDropdown={props.isDropdown}
        spin={spin}
      />
    </div>
  );
}