import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../app-auth-context/AppAuthContext";
import { apiBasePath, getNewCategoryRequestsByAuthor } from "../../../utils/apiPathConstant";
import { apiGetPrivate, checkResponse } from "../../../utils/api";
import { IBusinessTagRequest } from "../IBusinessContext";
import { EHttpStatusCode } from "../../../utils/HttpStatusCodes";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
}

export default async function getBusinessTagRequestsAsync(props: IProps): Promise<IBusinessTagRequest[]> {
  if (!props.authConfig) throw new Error("getBusinessTagRequestsAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  console.log("getBusinessTagRequestsAsync call");
  const response = await apiGetPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${getNewCategoryRequestsByAuthor}`
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getBusinessTagRequestsAsync", props.abortSignal?.aborted, [EHttpStatusCode.NotFound]);
  if (response?.status == EHttpStatusCode.NotFound)
    return [];
  const requests = response?.content ? response.content as IBusinessTagRequest[] : [];
  return requests;
}