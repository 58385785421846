
export function saveToSessionStorage(key: string, value: any): any {
  //console.log("saveToSessionStorage", key, value);
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function getFromSessionStorage(key: string): any {
  const data = sessionStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  } else {
    return undefined;
  }
}

export function clearFromSessionStorage(key: string) {
  sessionStorage.removeItem(key);
}