import CombineStyles from "../../../../utils/combineStyles";
import ButtonCaption from "../../button-caption/ButtonCaption";
import { ButtonType } from "../../button/ButtonType";
import { TUiMenuOption } from "../../menu/menu-content/menu-option/TUiMenuOption";
import styles from "./DialogOption.module.css";

interface IProps {
  ui: TUiMenuOption;
  //disabled?: boolean;
  clickHandler: () => void;
}

// Difference from EditFormOptionButton - it does not change appearance on mobile
export default function DialogOption(props: IProps) {
  const disabled = (props.ui.disabled == undefined) ? false : props.ui.disabled;
  //---------------------------------------------------------------------------
  const clickHandler = () => {
    if (!disabled)
      props.clickHandler();
  }
  //-----------------------------------------------------------------------
  const containerStyle = CombineStyles([
    styles.container,
    props.ui.isDefault ? (" " + styles.default) : "",
    disabled ? (" " + styles.disabled) : ""
  ]);
  //-----------------------------------------------------------------------
  return (
    <div
      id={props.ui.id}
      className={containerStyle}
      onClick={clickHandler}
    >
      <ButtonCaption
        type={ButtonType.Toolbar}
        ui={props.ui}
        displayIcon={true}
        isDropdown={false}
      />
    </div>
  );
}