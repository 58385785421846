import { useContext } from 'react';
import { IUiInteractiveForm } from '../../forms/IUiInteractiveForm';
import PopupTest from '../popup/PopupTest';
import styles from './PopupError.module.css';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import GetCaption from '../../functions/GetCaption';
import GetHint from '../../functions/GetHint';
import PopupErrorHeader from './popup-error-header/PopupErrorHeader';

export interface IErrorMessage {
  ui: IUiInteractiveForm;
  onClose: () => void;
}

interface IProps {
  ui: IUiInteractiveForm;
  show: boolean;
  onOptionSelect?: (optionId: string) => void;
  onClose: () => void;
}

export default function PopupError(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionCopy":
        navigator.clipboard.writeText(props.ui.hint?.en || '');
        break;
      case "OptionClose":
        props.onClose();
        break;
      default:
        props.onOptionSelect && props.onOptionSelect(optionId);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const optionElements = props.ui.menuContent.options.map(option => (
    <div
      key={option.id}
      className={styles.option}
      onClick={() => onOptionSelect(option.id)}
    >
      {GetCaption(appUiContext, option.id, option.caption)}
    </div>
  ));
  //---------------------------------------------------------------------------
  if (props.show) return (
    <PopupTest
      id={props.ui.id}
      isModal={true}
      closeOnClickOutside={false}
      animation="SlideFromRight"
      //shadowColor={messageColor}
      ignoreFullWindowOnMobile={true}
      fullWindow={false}
      onClose={props.onClose}
    //onEnter={props.onEnter}
    //onAnimationEnd={props.onAnimationEnd}
    >
      <div className={styles.container}>

        <PopupErrorHeader
          header={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
          onClose={props.onClose}
        />

        <div className={styles.message}>
          {GetHint(appUiContext, props.ui.id, props.ui.hint)}
        </div>

        <div className={styles.options}>
          {optionElements}
        </div>

      </div>
    </PopupTest>
  ); else return null;
}

export const tmpUiPopupNotification: IUiInteractiveForm = {
  id: "PopupNotification",
  directoryId: '',
  elementType: '',
  caption: undefined,
  hint: undefined,
  menuContent: {
    id: "PopupNotification.MenuContent",
    directoryId: '',
    elementType: '',
    hint: undefined,
    options: [{
      id: "OptionCopy",
      directoryId: '',
      elementType: '',
      caption: { en: "Copy" },
      hint: undefined
    }, {
      id: "OptionClose",
      directoryId: '',
      elementType: '',
      caption: { en: "Close" },
      hint: undefined
    }]
  }
};