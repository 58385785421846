import { TUiBreadcrumb } from "../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { TLocker } from "../../../common/locks/TLocker";
import { EDraftItemState } from "../../../../context/directory-editor-context/EDraftItemState";
import { IDirectoryDraftFilter } from "../../../../context/directory-editor-context/IDirectoryEditorContextData";
import { DirectoryDraftItem } from "../../../../context/directory-editor-context/IDirectoryDraftItem";
import { IWorkflowLog } from "../workflows/IWorkflowLog";
import { DirectoryTranslationDraft, IDirectoryTranslationDraft } from "./IDirectoryTranslationDraft";
import { directoryTranslationDraftStates } from "../../../../context/directory-editor-context/directory-editor-settings/directoryTranslationDraftStates";
import { ClassDirectoryItemStateGroup } from "../../../../context/directory-editor-context/directory-editor-settings/IDirectoryDraftItemStateGroup";

export interface IDirectoryTranslatorState<TItem>  {
  draft: IDirectoryTranslationDraft<TItem>;
  draftInitial?: IDirectoryTranslationDraft<TItem>;
  filter: IDirectoryDraftFilter;
}

interface IDirectoryTranslatorStateInitial<TItem> {
  directoryId: string;
  basePath: string;
  locale: string;
  localeName: string;
  sourceLocale?: string;
  editMode: boolean;
  log?: IWorkflowLog;
  locker?: TLocker;
  datetimeSaved?: string;
  draft: DirectoryTranslationDraft<TItem>;
}

interface IDirectoryTranslatorStateUpdate<TItem> {
  editMode?: boolean;
  sourceLocale?: string;
  draft?: IDirectoryTranslationDraft<TItem>;
}

export class DirectoryTranslatorState<TItem> implements IDirectoryTranslatorState<TItem> {
  directoryType!: "Directory";
  basePath!: string;
  breadcrumb!: TUiBreadcrumb;
  directoryId!: string;
  locale!: string;
  sourceLocale?: string;
  //name?: string;
  //description?: string;
  editMode!: boolean;
  log?: IWorkflowLog;
  datetimeSaved?: string;
  rootIsLocked!: boolean;
  //---------------------------------------------------------------------------
  draft!: DirectoryTranslationDraft<TItem>;
  draftInitial!: DirectoryTranslationDraft<TItem>;
  //---------------------------------------------------------------------------
  filter!: IDirectoryDraftFilter;
  //---------------------------------------------------------------------------
  isNew!: boolean;
  isUpdatedInSession!: boolean; // To know if we can save draft
  isUpdated!: boolean;  // As compared to existing translation
  //isValid!: boolean; // We don't check validity for translation
  isReadyToPublish!: boolean;
  //---------------------------------------------------------------------------
  constructor(source: IDirectoryTranslatorState<TItem>) {
    Object.assign(this, source);
  };
  //---------------------------------------------------------------------------
  checkChanges() {
    // Check name and description here once it is implemented
    let i = 0;
    let isUpdatedInSession = false;
    let isUpdated = false;
    this.draft.items.forEach(draftItem => {
      i++;
      const item = draftItem as DirectoryDraftItem;
      if ((item.draftState & EDraftItemState.IsTranslationEdited) != EDraftItemState.None) {
        isUpdated = true;
      };
      const initialItem = this.draftInitial.items[i];
      
    });
  };
  //---------------------------------------------------------------------------
  static getInitialState<TItem>(initialData: IDirectoryTranslatorStateInitial<TItem>): DirectoryTranslatorState<TItem> {
    const breadcrumb: TUiBreadcrumb = {
      id: initialData.directoryId,
      caption: { en: `${initialData.directoryId} (${initialData.localeName})` },
      path: `${initialData.basePath}/${initialData.directoryId}`
    };
    const initialState = new DirectoryTranslatorState<TItem>({
      //directoryType: "Directory",
      //directoryId: initialData.directoryId,
      //basePath: initialData.basePath,
      //breadcrumb,
      //editMode: initialData.editMode,
      //locale: initialData.locale,
      //sourceLocale: initialData.sourceLocale,
      //log: initialData.log,
      //locker: initialData.locker,
      //rootIsLocked: true,
      //datetimeSaved: initialData.datetimeSaved,
      draft: initialData.draft,
      //draftInitial: JSON.stringify(initialData.draft)
      filter: {
        draftState: EDraftItemState.All,
        stateGroups: directoryTranslationDraftStates.map(group => new ClassDirectoryItemStateGroup(group))
      },
      //isNew: false,
      //isUpdatedInSession: false,
      //isUpdated: false, // ATTENTION: update this! compare draft with published version
    });
    return initialState;
  };
  //---------------------------------------------------------------------------
  getUpdatedState(updates: IDirectoryTranslatorStateUpdate<TItem>): DirectoryTranslatorState<TItem> {
    const updatedState = new DirectoryTranslatorState<TItem>(this);
    Object.assign(updatedState, updates);
    //this.checkChanges();
    return updatedState;
  };
}