import { useContext, useEffect, useState } from "react";
import { AppAuthContextStore } from "../../../../context/app-auth-context/AppAuthContext";
import { useMsal } from "@azure/msal-react";
import { apiGetPrivate, checkResponse, IApiResponse } from "../../../../utils/api";
import { apiBasePath, pathGetUserBusinessesStats } from "../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../utils/common";
import { useAbortController } from "../../../../hooks/useAbortController";
import { PageViewProperties } from "../../../../utils/tracker";

interface IProps {

}
//-----------------------------------------------------------------------------
export function BusinessStats(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const [startDate, setStartDate] = useState<Date>(new Date(2024,8,24));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const abortController = useAbortController("BusinessStats");
  //---------------------------------------------------------------------------
  useEffect(() => {
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //---------------------------------------------------------------------------
  const getStats = () => {
    let controller = abortController.newController("getStats");
    setIsLoading(true);
    let uri = `${apiBasePath}${stringFormatter(pathGetUserBusinessesStats, [startDate.toISOString(), endDate.toISOString()])}`;
    console.log(uri);
    apiGetPrivate(
      instance, appAuthContext.config,
      uri,
      controller.signal
    )
      .then(response => {
        //console.log("BusinessStats.getStats:", response);
        checkResponse(response, "getStats", controller.signal?.aborted);
        let pageViews = PageViewProperties.fromArray(response?.content);
        console.log("BusinessStats.getStats:", pageViews);
      })
      .catch(error => {
        console.error("BusinessStats.getStats.catch:", error);
      })
      .finally(() => {
        !controller.aborted && setIsLoading(false);
      });
  }
  //---------------------------------------------------------------------------
  return (
    <div>
      <button type="button" onClick={getStats}>
        Get stats for last month
      </button>
    </div>
  );
}