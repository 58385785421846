import { INotificationContextData, IUnsavedChangesContextInitial } from "./INotificationContextData";

interface IUnsavedChangesInitialize {
  type: "UnsavedChangesInitialize";
  data: IUnsavedChangesContextInitial;
}
interface IUnsavedChangesShowNotification {
  type: "UnsavedChangesShowNotification";
  pathDestination?: string;
}
interface IUnsavedChangesReset {
  type: "UnsavedChangesReset";
}
interface ISetError {
  type: "SetError";
  header: string;
  message: string;
}
interface ICleanupError {
  type: "CleanupError";
}
interface ICleanup {
  type: "Cleanup";
}

export type Actions =
  IUnsavedChangesInitialize | IUnsavedChangesShowNotification | IUnsavedChangesReset |
  ISetError | ICleanupError |
  ICleanup;

export const NotificationContextReducer = (state: INotificationContextData, action: Actions) => {
  var updatedState: INotificationContextData = state;
  switch (action.type) {
    //-------------------------------------------------------------------------
    case "UnsavedChangesInitialize":
      updatedState = {
        ...state,
        unsavedChanges: {
          ...action.data,
          stateInitial: action.data
        }
      };
      break;
    //-------------------------------------------------------------------------
    case "UnsavedChangesShowNotification":
      if (state.unsavedChanges) {
        updatedState = {
          ...state,
          unsavedChanges: {
            ...state.unsavedChanges,
            showNotification: true,
            pathDestination: action.pathDestination,
            pathUnsavedChanges: undefined
          }
        };
      } else console.error("UnsavedChangesShowNotification: unsaved changes context is not initialized");
      break;
    //-------------------------------------------------------------------------
    case "UnsavedChangesReset":
      if (state.unsavedChanges) {
        updatedState = {
          ...state,
          unsavedChanges: {
            ...state.unsavedChanges.stateInitial,
            stateInitial: state.unsavedChanges.stateInitial,
            // If callback is provided, do not clear pathDestination just in case its needed for callback
            pathDestination: state.unsavedChanges.callback ? state.unsavedChanges.pathDestination : undefined
          }
        };
      } else console.error("UnsavedChangesReset: unsaved changes context is not initialized");
      break;
    //-------------------------------------------------------------------------
    case "SetError":
      updatedState = {
        ...state,
        error: {
          header: action.header,
          message: action.message
        }
      };
      //console.error(action.message);
      break;
    //-------------------------------------------------------------------------
    case "CleanupError":
      updatedState = {
        ...state,
        error: undefined
      };
      break;
    //-------------------------------------------------------------------------
    case "Cleanup":
      updatedState = {} as INotificationContextData;
      break;
  };
  return updatedState;
}