import { useContext } from 'react';
import { IDictionaryDraftCommon } from '../../dictionary-draft-models/DictionaryDraft';
import styles from './DictionaryEditorHeader.module.css';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { TUiInputText } from '../../../../common/input/input-text/TUiInputText';
import { TUiInputDropdown } from '../../../../common/input/input-dropdown/TUiInputDropdown';
import InputDropdown, { IUiListItem, getIUiListItem } from '../../../../common/input/input-dropdown/InputDropdown';
import { IDictionaryEditorWrapperSetup } from '../DictionaryEditorWrapper';
import GetName from '../../../../common/functions/GetName';

export interface IUiDictionaryEditorHeader {
  inputDictionaryId: TUiInputText;
  inputDraftLocale: TUiInputDropdown;
  //inputDictionaryName: IUiInputText;
  //inputDictionaryDescription: IUiInputText;
  inputSourceLocale: TUiInputDropdown;
}

interface IProps {
  setup: IDictionaryEditorWrapperSetup;
  ui: IUiDictionaryEditorHeader;
  data?: IDictionaryDraftCommon;
  sourceLocale?: string;
  onSourceLocaleUpdate?: (locale: string) => void;
}

export default function DictionaryEditorHeader(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const updatedAvailableValues = appUiContext.dictionaries?.supportedLocales?.filter(x => x.id != props.data?.locale);
  const draftLocaleName = appUiContext.dictionaries?.supportedLocales?.find(x => x.id === props.data?.locale)?.name;
  //---------------------------------------------------------------------------
  let dictionaryName = props.data?.dictionaryId;
  if (props.data?.name?.value) {
    dictionaryName = props.data?.name?.value;
  } else if (typeof props.data?.name == "string") {
    dictionaryName = props.data?.name;
  } else {
    dictionaryName = props.data?.dictionaryId;
  };
  //---------------------------------------------------------------------------
  let availableValues: IUiListItem[] = [{
    id: "i18n",
    name: { en: "System Id" },
    selected: false
  }];
  if (updatedAvailableValues) {
    availableValues = availableValues.concat(updatedAvailableValues.map(x => getIUiListItem(x)))
  };
  //---------------------------------------------------------------------------
  return (
    <header className={styles.header}>
      <table className={styles.table}>
        <tbody>

          <tr>
            <td className={styles.caption}>
              {props.setup.entityType}
            </td>
            <td className={styles.value}>
              {dictionaryName}
            </td>
          </tr>

          {props.setup.mode == "Translator" && props.data?.locale &&
            <tr>
              <td className={styles.caption}>
                Draft Locale
              </td>
              <td className={styles.value}>
                {GetName(appUiContext, props.data.locale, draftLocaleName)}
              </td>
            </tr>}

          {props.setup.mode == "Translator" && props.sourceLocale &&
            <tr>
              <td className={styles.caption}>
                Source Locale
              </td>
              <td>
                <InputDropdown
                  ui={{ ...props.ui.inputSourceLocale, availableValues: availableValues }}
                  data={props.sourceLocale}
                  disabled={false}
                  hideCaption={true}
                  hideHint={true}
                  hideDropdownDescriptions={true}
                  isAutoComplete={false}
                  onUpdate={props.onSourceLocaleUpdate}
                />
              </td>
            </tr>}

        </tbody>
      </table>
    </header>
  );
}