import { IUiValidation } from "../../input-v2/input-validation/IUiValidation";

export type TUiRequired = {
  value: number;
  errorMessageId: string;
  errorMessage: any; // text
}

export type TUiRegex = {
  regexPattern: string;
  errorMessageId: string;
  errorMessage: any;
}

export type TUiApiCheck = {
  apiUri: string;
  errorMessageId: string;
  errorMessage: any;
}

export type TUiUnique = {
  errorMessageId: string;
  errorMessage: any;
}

export type TUiValidation = {
  required?: TUiRequired;
  regexMatch?: TUiRegex;
  apiCheck?: TUiApiCheck;
  unique?: TUiUnique;
}

export interface IValidationMessage { // Remove
  id: string;
  value: any;
}

export function getTUiValidation(v: IUiValidation): TUiValidation {
  console.log(v)
  return ({
    required: v.required ? {
      value: v.required.value,
      errorMessageId: v.required.message.id,
      errorMessage: v.required.message.hint
    } : undefined,
    regexMatch: v.regexMatch ? {
      regexPattern: v.regexMatch.regexPattern,
      errorMessageId: v.regexMatch.message.id,
      errorMessage: v.regexMatch.message.hint
    } : undefined,
    unique: v.unique ? {
      errorMessageId: v.unique.message.id,
      errorMessage: v.unique.message.hint
    } : undefined
  })
}