import React, { useState } from "react";
import DictionaryEditorNodeWrapper from "../../common/dictionary-node-wrapper/DictionaryEditorNodeWrapper";
import { IDictionaryDraftItemStateGroup } from "../../common/settings/IDictionaryDraftItemStateGroup";
import LocaleEditForm from "../locale-edit-form/LocaleEditForm";
import { DictionaryEditorDraftItem, IDictionaryEditorDraftItem } from "../../common/models/DictionaryEditorDraftItem";
import { DraftItemStateEnum } from "../../../../common/dictionary-draft-models/DraftItemStateEnum";

export interface ILocalesDictionaryDraftItem extends IDictionaryEditorDraftItem {
}
export interface ILocaleDictionaryDraftItemSpread {
  index?: number;
  deleted?: boolean;
  path?: string | undefined;
  pathOld?: string | undefined;
  pathNew?: string | undefined;
  id?: string;
  name?: any;
  description?: any;
  draftState?: DraftItemStateEnum;
  publishedVersion: any;
}
export class LocalesDictionaryDraftItem extends DictionaryEditorDraftItem implements ILocalesDictionaryDraftItem {
  index!: number;
  deleted!: boolean;
  path?: string | undefined;
  pathOld?: string | undefined;
  pathNew?: string | undefined;
  id!: string;
  name?: any;
  description?: any;
  draftState!: DraftItemStateEnum;
  publishedVersion: any;
  //---------------------------------------------------------------------------
  constructor(
    //source: ILocalesDictionaryDraftItem,
    source: any,
    spread?: ILocaleDictionaryDraftItemSpread
  ) {
    const sourceCopy = JSON.parse(JSON.stringify(source));
    super(sourceCopy);
    Object.assign(this, sourceCopy, spread);
  }
  //---------------------------------------------------------------------------
  isValid(): boolean {
    this.refresh("en");
    return !this.isNoTranslation;
  }
}

interface IProps {
  stateGroups?: IDictionaryDraftItemStateGroup[];
  data: LocalesDictionaryDraftItem;
  editModeIsEnabled?: boolean;
  onUpdate: (updatedDraftItem: LocalesDictionaryDraftItem) => void;
  onDelete: () => void;
}

export default function (props: IProps) {
  const [editMode, setEditMode] = useState(false);
  const editModeIsEnabled = props.editModeIsEnabled == undefined ? false : props.editModeIsEnabled;
  //---------------------------------------------------------------------------
  const onClose = () => {
    setEditMode(false);
  };
  //---------------------------------------------------------------------------
  const onDelete = () => {
    if (!props.data.deleted) {
      if (props.data.isNew) {
        // If it's a new item, make a simple deletion
        props.onDelete();
      } else {
        // If it's a existing item, mark it as deleted
        const updatedItem = new LocalesDictionaryDraftItem(props.data);
        updatedItem.deleted = true;
        updatedItem.refresh("en");
        props.onUpdate(updatedItem);
      };
    } else console.error("Cannot delete item: it's already deleted");
  };
  //---------------------------------------------------------------------------
  const onActivate = () => {
    if (props.data.deleted) {
      const updatedItem = new LocalesDictionaryDraftItem(props.data);
      updatedItem.deleted = false;
      updatedItem.refresh("en");
      props.onUpdate(updatedItem);
    } else console.error("Cannot restore item: it's not deleted");
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedDraftItem: LocalesDictionaryDraftItem) => {
    setEditMode(false);
    props.onUpdate(updatedDraftItem);
  };
  //---------------------------------------------------------------------------
  if (props.data)
    return (
      <React.Fragment>
        {!editMode ?
          <DictionaryEditorNodeWrapper
            data={props.data}
            stateGroups={props.stateGroups}
            editModeIsEnabled={editModeIsEnabled}
            isOrderedManually={false}
            onEdit={() => setEditMode(true)}
            onDelete={onDelete}
            onDeactivate={onDelete}
            onActivate={onActivate}
          >
          </DictionaryEditorNodeWrapper>
          :
          <LocaleEditForm
            data={props.data}
            onUpdate={onUpdate}
            onClose={onClose}
          />}
      </React.Fragment>
    );
  else return null;
}