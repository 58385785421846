import { useEffect, useRef, useState } from 'react';
import FormOptions from '../../../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../../../common/menu/menu-content/TUiMenuContent';
import { IUiOption } from '../../../../../common/options/IUiOption';
import { Contact } from '../../contact/Contact';
import styles from './ContactEditForm.module.css';
import { IUiEditFormContact } from './IUiEditFormContact';
import { v4 as uuidv4 } from "uuid";
import { IUiDictionaryItem } from '../../../../../common/dictionaries/IUiDictionaryItem';
import InputText from '../../../../../common/input-v2/input-text/InputText';
import { IValidationState } from '../../../../../common/input-v2/input-validation/IUiValidation';
import InputsGroupWrapper from '../../../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import { ValidateInput } from '../../../../../common/input-v2/input-validation/ValidateInput';

interface IContactEditFormState {
  contact: Contact<string>;
  contactInitialState: Contact<string>;
  contactsList: Contact<string>[];
  ui: IUiEditFormContact;
  isNew: boolean;
  isUpdated: boolean;
  isValid: boolean;
}
interface IContactEditFormStateSpread {
  contact: Contact<string>;
}

class ContactEditFormState implements IContactEditFormState {
  contact!: Contact<string>;
  contactInitialState!: Contact<string>;
  contactsList!: Contact<string>[];
  ui!: IUiEditFormContact;
  isNew!: boolean;
  isUpdated!: boolean;
  isValid!: boolean;
  validationStateValue!: IValidationState;
  validationStateDescription!: IValidationState;
  //---------------------------------------------------------------------------
  constructor(state: IContactEditFormState, spread?: IContactEditFormStateSpread) {
    Object.assign(this, state, spread);
    // Exclude existing contact from contacts list so we wouldn't check contact against itself
    this.contactsList = this.isNew ? this.contactsList : this.contactsList.filter(c => c.id !== this.contact.id);
    //--------------------------------- Check changes
    this.isUpdated = JSON.stringify(this.contact) !== JSON.stringify(this.contactInitialState);
    //--------------------------------- Validate
    this.validationStateValue = this.validateValue();
    this.validationStateDescription = this.validateDescription();
    this.isValid = this.validationStateValue.isValid && this.validationStateDescription.isValid;
  };
  //---------------------------------------------------------------------------
  validateValue(): IValidationState {
    if (this.ui.inputContactData.validation) {
      const existingContact_Value = this.contactsList.find(c => (c.value === this.contact.value));
      const validationState = ValidateInput({
        elementId: this.ui.inputContactData.id,
        validation: this.ui.inputContactData.validation,
        data: this.contact.value,
        isUnique: (existingContact_Value == undefined)
      });
      return validationState;
    } else {
      return ({
        isValid: true,
        validationMessages: []
      });
    }
  };
  //---------------------------------------------------------------------------
  validateDescription(): IValidationState {
    if (this.ui.inputContactDescription.validation) {
      //console.log(this.contact.description)
      const existingContact_Description = this.contactsList.find(c => this.valuesAreEqual(c.description, this.contact.description));
      const validationState = ValidateInput({
        elementId: this.ui.inputContactDescription.id,
        validation: this.ui.inputContactDescription.validation,
        data: this.contact.description,
        isUnique: (existingContact_Description == undefined)
      });
      return validationState;
    } else {
      return ({
        isValid: true,
        validationMessages: []
      });
    };
  };
  //---------------------------------------------------------------------------
  valuesAreEqual(
    value1: string | undefined | null,
    value2: string | undefined | null
  ): boolean {
    return (this.getEvaluated(value1) === this.getEvaluated(value2));
  };
  //---------------------------------------------------------------------------
  getEvaluated(value: any) {
    if (value == "" || value == undefined || value == null)
      return 1;
    else
      return value;
  };
}

interface IProps {
  ui: IUiEditFormContact;
  contactType: IUiDictionaryItem;
  contacts: Contact<string>[];
  data?: Contact<string>;
  addressBlockMode?: boolean;
  onClose: () => void;
  onUpdate: (updatedContact: Contact<string>) => void;
}

export default function ContactEditForm(props: IProps) {
  const [state, setState] = useState<ContactEditFormState>();
  const editFormDivRef = useRef<HTMLDivElement>(null);
  const addressBlockMode = props.addressBlockMode == undefined ? false : props.addressBlockMode;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (editFormDivRef.current) {
      editFormDivRef.current.scrollIntoView({ behavior: 'smooth' });
    };
  }, [editFormDivRef.current]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    const contactData = props.data ? props.data : new Contact<string>({
      id: uuidv4(),
      contactType: props.contactType.id
    });
    const newState = new ContactEditFormState({
      contact: contactData,
      contactInitialState: JSON.parse(JSON.stringify(contactData)),
      contactsList: props.contacts,
      isNew: !props.data,
      isUpdated: false,
      isValid: false,
      ui: props.ui
    });
    setState(newState);
  }, []);
  //---------------------------------------------------------------------------
  const onDataUpdate = (updatedValue: string) => {
    if (addressBlockMode) return;
    if (!state) return;
    const newState = new ContactEditFormState(state, {
      contact: {
        ...state?.contact,
        value: updatedValue
      }
    });
    setState(newState);
  };
  //---------------------------------------------------------------------------
  const onDescriptionUpdate = (updatedValue: string) => {
    if (!state) return;
    const newState = new ContactEditFormState(state, {
      contact: {
        ...state?.contact,
        description: updatedValue
      }
    });
    setState(newState);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OptionCancel":
        props.onClose();
        break;
      case "OptionOk":
        if (state?.isValid)
          props.onUpdate(state.contact);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionCancel":
        updatedOptions.push({
          ...option,
          iconId: "close"
        })
        break;
      case "OptionOk":
        updatedOptions.push({
          ...option,
          iconId: "check",
          isDisabled: !(state?.isValid && state.isUpdated),
          isDefault: state?.isValid && state.isUpdated
        })
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({
        ...props.ui.menuContent,
        options: updatedOptions
      })}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <div ref={editFormDivRef} className={styles.container}>

      <div className={styles.form}>
        <InputsGroupWrapper>
          <InputText
            ui={{ ...props.ui.inputContactData, disabled: addressBlockMode }}
            data={state?.contact ? state.contact.value : ""}
            isFocused={true}
            validationState={state?.validationStateValue}
            onUpdate={onDataUpdate}
          />
          <InputText
            ui={props.ui.inputContactDescription}
            data={state?.contact ? state.contact.description : ""}
            validationState={state?.validationStateDescription}
            onUpdate={onDescriptionUpdate}
          />
        </InputsGroupWrapper>
      </div>

      {formOptions}

    </div>
  );
}