import React, { ReactNode } from "react";
import Toggler from "../common/toggler/Toggler";
import styles from "./StatusToggler.module.css";

interface IProps {
  caption: string;
  count?: number;
  collapsed: boolean;
  children?: ReactNode;
  onExpand?: () => void;
  onCollapse?: () => void;
}

export function StatusToggler(props: IProps) {
  //---------------------------------------------------------------------------
  let caption = props.count == undefined ? props.caption :
    `${props.caption} (${props.count})`;
  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.toggler}>
          <Toggler collapsed={props.collapsed} onCollapse={props.onCollapse} onExpand={props.onExpand} />
        </div>
        {caption}
      </div>
      {!props.collapsed && props.children &&
        <div className={styles.childrenContainer}>
          {props.children}
        </div>
      }
    </React.Fragment>
  );
}