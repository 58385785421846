import React, { useContext } from "react";
import { TUiCaption } from "./TUiCaption";
import GetText from "../functions/GetText";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";

interface IProps {
  ui: TUiCaption;
}

export default function Caption(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  return (
    <React.Fragment>
      {GetText({
        appUiContext: appUiContext, 
        elementId: props.ui.id,
        elementPart: "Caption",
        text: props.ui.text,
      })}
    </React.Fragment>
  );
}