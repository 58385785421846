import { INewBusinessTagRequest } from '../../../../common/common-business-context/INewBusinessTagRequest';
import styles from './CategoryRequestCard.module.css';

interface IProps {
  data: INewBusinessTagRequest;
}

export default function CategoryRequestCard(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span>
          {`${props.data.path.replace('.', " / ")} / `}
        </span>
        <span className={styles.tagName}>
          {props.data.name}
        </span>
      </div>
    </div>
  );
}