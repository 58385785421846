export default function IconDoubleCheck() {
  //---------------------------------------------------------------------------

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="1em" height="1em"
      viewBox="0 0 50 50">
      <g
        stroke="currentColor"
        strokeWidth={8}
        strokeLinejoin="round"
        strokeLinecap="round"
        fill="none"
      >
        <polyline points="10,13 22,25 35,10" />
        <polyline points="5,28 23,45 45,20" />
      </g>
    </svg>
  );
}