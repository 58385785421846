import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../context/app-auth-context/AppAuthContext";
import { apiPostPrivate, checkResponse } from "../../../utils/api";
import { apiBasePath, pathCreateAdCampaign } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
import { IAdCampaign } from "../../../context/business-context/IAdCampaign";
import { ClassCustomerBusiness, ICustomerBusiness } from "../business-editor/ICustomerBusiness";

interface IProps {
  msalInstance: IPublicClientApplication,
  authConfig?: IAuthConfig | null,
  businessId?: string,
  adCampaign?: IAdCampaign,
  abortSignal?: AbortSignal
}

export async function createAdCampaignAsync(props: IProps): Promise<ClassCustomerBusiness> {
  //---------------------------------------------------------------------------
  if (!props.authConfig) throw new Error("createAdCampaignAsync: auth context is not provided");
  if (!props.businessId) throw new Error("createAdCampaignAsync: Business Id is not provided");
  if (!props.adCampaign) throw new Error("createAdCampaignAsync: Ad campaign data is not provided");
  if (!props.adCampaign.name) throw new Error("createAdCampaignAsync: Ad campaign name is not provided");
  if (!props.adCampaign.adRank) throw new Error("createAdCampaignAsync: Ad campaign ad rank is not provided");
  //---------------------------------------------------------------------------
  const uri = stringFormatter(pathCreateAdCampaign, [props.businessId, props.adCampaign.name]);
  const response = await apiPostPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${uri}`,
    props.adCampaign.adRank,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "createAdCampaignAsync", props.abortSignal?.aborted);
  return new ClassCustomerBusiness(response?.content as ICustomerBusiness);
}
