import { EDraftItemState } from "./EDraftItemState";

export interface IDirectoryDraftItem {
  id: string; // User-defined id
  path?: string;
  fullPath?: string;
  draftState: EDraftItemState;
  /* isNoTranslation: boolean;
  hasNoTranslation: boolean;
  isTranslationEdited: boolean;
  hasTranslationEdited: boolean;
  isEdited: boolean;
  hasEdited: boolean;
  isRepositionedOld: boolean;
  hasRepositionedOld: boolean;
  isRepositionedNew: boolean;
  hasRepositionedNew: boolean;
  isDeleted: boolean;
  hasDeleted: boolean;
  isNew: boolean;
  hasNew: boolean;
  isNothing: boolean;
  hasNothing: boolean;
  hasOnlyChild: boolean;
  hasNoChildren: boolean;
  isReordered: boolean;
  hasReordered: boolean;   */
}

export class DirectoryDraftItem implements IDirectoryDraftItem {
  id!: string;
  path?: string;
  draftState!: EDraftItemState;
  get fullPath(): string {
    return this.path ? `${this.path}.${this.id}` : this.id;
  }
  //---------------------------------------------------------------------------
  constructor(source: IDirectoryDraftItem) {
    Object.assign(this, source);
  };
  //---------------------------------------------------------------------------
  getIsNothing(): boolean {
    let isNothing = true;
    if (this.isNew || this.hasNew) isNothing = false;
    if (this.isNoTranslation || this.hasNoTranslation) isNothing = false;
    if (this.isTranslationEdited || this.hasTranslationEdited) isNothing = false;
    if (this.isEdited || this.hasEdited) isNothing = false;
    if (this.isRepositionedOld || this.hasRepositionedOld) isNothing = false;
    if (this.isRepositionedNew || this.hasRepositionedNew) isNothing = false;
    if (this.isReordered || this.hasReordered) isNothing = false;
    if (this.isDeleted || this.hasDeleted) isNothing = false;
    if (this.isFallbackAccepted || this.hasFallbackAccepted) isNothing = false;
    if (this.hasOnlyChild) isNothing = false;
    if (this.hasNoChildren) isNothing = false;
    return isNothing;
  };
  //---------------------------------------------------------------------------
  keywordFilterConditionIsMet(keyword: string): boolean {
    let result = this.id.toLowerCase().includes(keyword);
    return result;
  };
  //--------------------------------------------- Properties based on draftStatus
  //--------------------------------------------------------------------------- Nothing
  get isNothing(): boolean {
    if (this.draftState & EDraftItemState.IsNothing)
      return true;
    else
      return false;
  }
  set isNothing(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.IsNothing;
    else
      this.draftState &= ~EDraftItemState.IsNothing;
  }
  //---------------------------------------------------------------------------
  get hasNothing(): boolean {
    if (this.draftState & EDraftItemState.HasNothing)
      return true;
    else
      return false;
  }
  set hasNothing(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.HasNothing;
    else
      this.draftState &= ~EDraftItemState.HasNothing;
  }
  //--------------------------------------------------------------------------- New
  get isNew(): boolean {
    if (this.draftState & EDraftItemState.IsNew)
      return true;
    else
      return false;
  }
  set isNew(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.IsNew;
    else
      this.draftState &= ~EDraftItemState.IsNew;
  }
  //---------------------------------------------------------------------------
  get hasNew(): boolean {
    if (this.draftState & EDraftItemState.HasNew)
      return true;
    else
      return false;
  }
  set hasNew(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.HasNew;
    else
      this.draftState &= ~EDraftItemState.HasNew;
  }
  //--------------------------------------------------------------- NoTranslation
  get isNoTranslation(): boolean {
    if (this.draftState & EDraftItemState.IsNoTranslation)
      return true;
    else
      return false;
  }
  set isNoTranslation(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.IsNoTranslation;
    else
      this.draftState &= ~EDraftItemState.IsNoTranslation;
  }
  //---------------------------------------------------------------------------
  get hasNoTranslation(): boolean {
    if (this.draftState & EDraftItemState.HasNoTranslation)
      return true;
    else
      return false;
  }
  set hasNoTranslation(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.HasNoTranslation;
    else
      this.draftState &= ~EDraftItemState.HasNoTranslation;
  }
  //--------------------------------------------------------------------------- TranslationEdited
  get isTranslationEdited(): boolean {
    if (this.draftState & EDraftItemState.IsTranslationEdited)
      return true;
    else
      return false;
  }
  set isTranslationEdited(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.IsTranslationEdited;
    else
      this.draftState &= ~EDraftItemState.IsTranslationEdited;
  }
  //---------------------------------------------------------------------------
  get hasTranslationEdited(): boolean {
    if (this.draftState & EDraftItemState.HasTranslationEdited)
      return true;
    else
      return false;
  }
  set hasTranslationEdited(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.HasTranslationEdited;
    else
      this.draftState &= ~EDraftItemState.HasTranslationEdited;
  }
  //--------------------------------------------------------------------------- Edited
  get isEdited(): boolean {
    if (this.draftState & EDraftItemState.IsEdited)
      return true;
    else
      return false;
  }
  set isEdited(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.IsEdited;
    else
      this.draftState &= ~EDraftItemState.IsEdited;
  }
  //---------------------------------------------------------------------------
  get hasEdited(): boolean {
    if (this.draftState & EDraftItemState.HasEdited)
      return true;
    else
      return false;
  }
  set hasEdited(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.HasEdited;
    else
      this.draftState &= ~EDraftItemState.HasEdited;
  }
  //--------------------------------------------------------------------------- RepositionedOld
  get isRepositionedOld(): boolean {
    if (this.draftState & EDraftItemState.IsRepositionedOld)
      return true;
    else
      return false;
  }
  set isRepositionedOld(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.IsRepositionedOld;
    else
      this.draftState &= ~EDraftItemState.IsRepositionedOld;
  }
  //---------------------------------------------------------------------------
  get hasRepositionedOld(): boolean {
    if (this.draftState & EDraftItemState.HasRepositionedOld)
      return true;
    else
      return false;
  }
  set hasRepositionedOld(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.HasRepositionedOld;
    else
      this.draftState &= ~EDraftItemState.HasRepositionedOld;
  }
  //--------------------------------------------------------------------------- RepositionedNew
  get isRepositionedNew(): boolean {
    if (this.draftState & EDraftItemState.IsRepositionedNew)
      return true;
    else
      return false;
  }
  set isRepositionedNew(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.IsRepositionedNew;
    else
      this.draftState &= ~EDraftItemState.IsRepositionedNew;
  }
  //---------------------------------------------------------------------------
  get hasRepositionedNew(): boolean {
    if (this.draftState & EDraftItemState.HasRepositionedNew)
      return true;
    else
      return false;
  }
  set hasRepositionedNew(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.HasRepositionedNew;
    else
      this.draftState &= ~EDraftItemState.HasRepositionedNew;
  }
  //--------------------------------------------------------------------------- IsReordered
  get isReordered(): boolean {
    if (this.draftState & EDraftItemState.IsReordered)
      return true;
    else
      return false;
  }
  set isReordered(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.IsReordered;
    else
      this.draftState &= ~EDraftItemState.IsReordered;
  }
  //--------------------------------------------------------------------------- 
  get hasReordered(): boolean {
    if (this.draftState & EDraftItemState.HasReordered)
      return true;
    else
      return false;
  }
  set hasReordered(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.HasReordered;
    else
      this.draftState &= ~EDraftItemState.HasReordered;
  }
  //--------------------------------------------------------------------------- Deleted
  get isDeleted(): boolean {
    if (this.draftState & EDraftItemState.IsDeleted)
      return true;
    else
      return false;
  }
  set isDeleted(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.IsDeleted;
    else
      this.draftState &= ~EDraftItemState.IsDeleted;
  }
  //---------------------------------------------------------------------------
  get hasDeleted(): boolean {
    if (this.draftState & EDraftItemState.HasDeleted)
      return true;
    else
      return false;
  }
  set hasDeleted(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.HasDeleted;
    else
      this.draftState &= ~EDraftItemState.HasDeleted;
  }
  //--------------------------------------------------------------------------- FallbackAccepted
  get isFallbackAccepted(): boolean {
    if (this.draftState & EDraftItemState.IsFallbackAccepted)
      return true;
    else
      return false;
  }
  set isFallbackAccepted(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.IsFallbackAccepted;
    else
      this.draftState &= ~EDraftItemState.IsFallbackAccepted;
  }
  //---------------------------------------------------------------------------
  get hasFallbackAccepted(): boolean {
    if (this.draftState & EDraftItemState.HasFallbackAccepted)
      return true;
    else
      return false;
  }
  set hasFallbackAccepted(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.HasFallbackAccepted;
    else
      this.draftState &= ~EDraftItemState.HasFallbackAccepted;
  }
  //--------------------------------------------------------------------------- HasOnlyChild
  get hasOnlyChild(): boolean {
    if (this.draftState & EDraftItemState.HasOnlyChild)
      return true;
    else
      return false;
  }
  set hasOnlyChild(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.HasOnlyChild;
    else
      this.draftState &= ~EDraftItemState.HasOnlyChild;
  }
  //--------------------------------------------------------------------------- HasNoChildren
  get hasNoChildren(): boolean {
    if (this.draftState & EDraftItemState.HasNoChildren)
      return true;
    else
      return false;
  }
  set hasNoChildren(value: boolean) {
    if (value)
      this.draftState |= EDraftItemState.HasNoChildren;
    else
      this.draftState &= ~EDraftItemState.HasNoChildren;
  }
}