import React, { ChangeEvent, CSSProperties, HTMLInputTypeAttribute, useEffect, useRef, useState } from "react";
import CombineStyles from "../../../../utils/combineStyles";
import Icon from "../../icon/Icon";
import styles from "./InputBox.module.css";

export enum EInputValueType {
  Text,
  Phone,
  Email,
  Date,
  DateTime
};
// type HTMLInputTypeAttribute = | 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image'
//   | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit'
//   | 'tel' | 'text' | 'time' | 'url' | 'week' | (string & {});
type TInputMode = "none" | "email" | "search" | "tel" | "text" | "url" | "numeric" | "decimal" | undefined;
export type TEnterKeyHint = "search" | "enter" | "done" | "go" | "next" | "previous" | "send" | undefined;


interface IProps {
  id: string;
  valueType?: EInputValueType;
  enterKeyHint?: TEnterKeyHint;
  placeholder?: any;
  regexPattern?: string;
  value: string;
  dropdownButtonEm: number;
  allowInput: boolean;
  inputIsForbidden?: boolean;
  focused?: boolean;
  showAlert?: boolean;
  showInvalid?: boolean;
  autoCompleteOff?: boolean;
  onFocus?: () => void;
  onLeave?: () => void;
  onChange?: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onInputFinished?: (event?: React.FocusEvent<HTMLInputElement>) => void;
}
//---------------------------------------------------------------------------
export default function InputBox(props: IProps) {
  const [inputValue, setInputValue] = useState<string>();
  const inputRef = useRef({} as HTMLInputElement);
  const focusIsSet = useRef<boolean>(false);
  //---------------------------------------------------------------------------
  const valueType = props.valueType == undefined ? EInputValueType.Text : props.valueType;
  const enterKeyHint = props.enterKeyHint == undefined ? "next" : props.enterKeyHint;
  const focused = (props.focused === undefined) ? false : props.focused;
  const showAlert = (props.showAlert === undefined) ? false : props.showAlert;
  const showInvalid = (props.showInvalid === undefined) ? false : props.showInvalid;
  const autoCompleteOff = (props.autoCompleteOff == undefined) ? false : props.autoCompleteOff;
  //---------------------------------------------------------------------------
  // Remove character number condition from regex
  // Example: from this regex "[a-zA-Z0-9-._~:\\@\\!\\$\\(\\)\\*\\+=]{10,}" we need to remove this part at the end "{10,}"
  const re = new RegExp("\{.*\}$", 'g');
  const regexPart = props.regexPattern?.split(re)[0];
  const regexPatternToCheckInput = regexPart ? new RegExp(regexPart, 'g') : undefined;
  //---------------------------------------------------------------------------
  // Set cursor to the end of the string when input is just rendered
  // (we don't need this if user is editing the value)
  // UPDATE: made it fire on every props change because for some reason input loses focus after re-render if parent component made some changes
  // like enabled/disabled an option on the form 
  // 2023.05.29 UPDATE: No need to run it on every props change because the issue seems to solve itself)))
  useEffect(() => {
    //console.log('AAAAAAA', props.id, focused, inputRef.current, focusIsSet.current)
    if (!props.inputIsForbidden && focused && inputRef.current && !focusIsSet.current && inputRef.current.inputMode !== 'email') {
      const endOfTheString = inputRef.current.value ? inputRef.current.value.length : 0;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(endOfTheString, endOfTheString);
      focusIsSet.current = true;
    };
  }, [inputRef.current, props.focused]);
  //---------------------------------------------------------------------------
  // Update input value once user selected value from the list (or it's provided by currently edited object)
  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);
  //---------------------------------------------------------------------------
  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onFocus && props.onFocus();
  }
  //---------------------------------------------------------------------------
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.allowInput) {
      var valueToUse = event.target.value;
      if (regexPatternToCheckInput) {
        const allowedString = valueToUse.match(regexPatternToCheckInput)?.join('');
        valueToUse = allowedString ? allowedString : '';
      };
      setInputValue(valueToUse);
      if (props.onChange)
        props.onChange(valueToUse);
    };
  };
  //---------------------------------------------------------------------------
  // Signal to the parent element that user finished their input
  // It's important in case of dropdown input
  const onLeave = (e: React.FocusEvent<HTMLInputElement>) => {
    //console.log(e)
    if (props.onInputFinished) {
      props.onInputFinished(e);
    }
    else if (props.onLeave) {
      props.onLeave();
    }

  };
  //---------------------------------------------------------------------------
  const clearInput = () => {
    if (inputValue) {
      setInputValue("");
      if (props.onChange)
        props.onChange("");
    };
    inputRef.current.focus();
  };
  //---------------------------------------------------------------------------
  const inputStyles = CombineStyles([
    styles.input,
    props.allowInput ? "" : styles.noInput
  ]);
  //---------------------------------------------------------------------------
  var paddingRightEm: number | undefined = props.dropdownButtonEm ? props.dropdownButtonEm : undefined;
  const clearButtonSpaceWidth = 2; //em
  if (props.allowInput) {
    // We need extra space to display 'clear' button
    paddingRightEm = paddingRightEm ? paddingRightEm + clearButtonSpaceWidth : clearButtonSpaceWidth;
  };
  //---------------------------------------------------------------------------
  var calculatedStyle: CSSProperties | undefined = undefined;
  if (showAlert) {
    calculatedStyle = { border: '3px solid var(--clr_accent_attention)' };
  };
  if (showInvalid == true) {
    calculatedStyle = { border: '3px solid var(--clr_accent_wrong)' };
  };
  //--------------------------------------------------------------------------- Handle input value type
  var inputType: HTMLInputTypeAttribute = 'text';
  var inputMode: TInputMode = "text";
  switch (valueType) {
    case EInputValueType.Email:
      inputType = 'email';
      inputMode = 'email';
      break;
    case EInputValueType.Date:
      inputType = 'date'
      break;
    case EInputValueType.DateTime:
      inputType = 'datetime-local'
      break;
  }
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container} style={calculatedStyle} >
      <input
        ref={inputRef}
        id={props.id}
        type={inputType}
        inputMode={inputMode}
        enterKeyHint={enterKeyHint}
        autoFocus={focused}
        className={inputStyles}
        style={{ height: '2em', paddingRight: `${paddingRightEm}em`, width: 'inherit' }}
        autoComplete={autoCompleteOff ? "off" : undefined}
        placeholder={props.placeholder}
        value={inputValue ? inputValue : ""}
        onChange={onChange}
        onKeyDown={props.onKeyDown}
        onBlur={onLeave}
        onFocus={onFocus}
      />
      {props.allowInput && inputValue !== "" &&
        <div
          className={styles.clearButton}
          style={{ width: `${clearButtonSpaceWidth}em`, right: `${props.dropdownButtonEm}em` }}
          onClick={clearInput}
        >
          <Icon imageName="close" />
        </div>
      }
    </div>
  );
}