import { IUiInteractiveForm } from "../forms/IUiInteractiveForm";
import { TUiMenuOption } from "../menu/menu-content/menu-option/TUiMenuOption";

export type TUiImageEditor = {
  id: string;
  directoryId: string;
  elementType: string;
  //caption: any; // move to wrapper component i.e. BusinessLogoEditor
  //displayImage: TUiDisplayImage,
  //imagePrompt: TUiCaption,
  //buttonEdit: TUiButtonOption,
  //buttonDelete: TUiButtonOption,
  //imageEditor: TUiImageEditor
  //fileTypes: string;
  options: TUiMenuOption[];
  //validation: null;
  //confirmationDialog: TUiConfirmationDialog;
  unsavedChangesDialog: IUiInteractiveForm;
}

export const imageEditorUi: TUiImageEditor = {
  id: "ImageEditor",
  directoryId: "",
  elementType: "",
  options: [{
    id: "ImagePicker_Options_SelectImage",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_SelectImageFromGallery",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_RemoveImage",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_Undo",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_Redo",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_FlipHorz",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_FlipVert",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_RotateLeft",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_RotateRight",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_Crop",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_CropConfirm",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_CropCancel",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_Save",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_FullScreenEnter",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_FullScreenExit",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_Save",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }, {
    id: "ImagePicker_Options_EditImage",
    directoryId: "",
    elementType: "",
    caption: {
      en: ""
    },
    hint: {
      en: ""
    },
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    index: 0,
    priorityLevel: 0,
    canHideCaption: false,
    action: undefined
  }],
  unsavedChangesDialog: {
    id: "ImageEditor.UnsavedChangesDialog",
    directoryId: "",
    elementType: "",
    caption: { en: "Image Editor" },
    hint: { en: "Unsaved changes dialog description" },
    menuContent: {
      id: "",
      directoryId: "",
      elementType: "",
      hint: undefined,
      options: [{
        id: "OptionDiscard",
        directoryId: "",
        elementType: "",
        caption: {
          en: "Discard"
        },
        hint: {
          en: "Discard unsaved changes"
        }
      }, {
        id: "OptionSave",
        directoryId: "",
        elementType: "",
        caption: {
          en: "Save"
        },
        hint: {
          en: "Save changes"
        }
      }]
    }
  }
}