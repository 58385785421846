import { IMapArea } from "../../google-maps/map-itself/MapItself";

//--------------------------------------------------------------------------------------------
export interface ILocation {
  type: string;
  coordinates: any; // [longitude, latitude]
}
//--------------------------------------------------------------------------------------------
export class TLocation implements ILocation {
  id?: string;
  type!: string;
  coordinates!: any;//number[]; // [longitude, latitude]
  //--------------------------------------------------------------------------------------------
  static create(source: ILocation): TLocation {
    if (!source) {
      // default location
      return TGeoPoint.fromLatLng(0, 0);
    }
    switch (source.type) {
      case "Point":
      case "point":
        return new TGeoPoint(source);
      case "Polygon":
      case "polygon":
        return new TPolygonArea(source);
      default:
        throw `Unsupported location type: [${source.type}]`;
    }
  }
  //--------------------------------------------------------------------------------------------
  static pointFromLatLng(lat: number, lng: number): TGeoPoint {
    return new TGeoPoint({ coordinates: [lng, lat] });
  }
  //--------------------------------------------------------------------------------------------
  getPointPosition(): google.maps.LatLngLiteral {
    return {
      lng: this.coordinates[0],
      lat: this.coordinates[1]
    };
  }
}
//--------------------------------------------------------------------------------------------
export class TGeoPoint extends TLocation {
  type: string = "Point";
  coordinates: number[] = [0, 0];
  distance?: number; // for example: distance from search base point or user location
  //--------------------------------------------------------------------------------------------
  constructor(source: any) {
    super();
    if (source != undefined) {
      Object.assign(this, source);
    }
  }
  //--------------------------------------------------------------------------------------------
  static fromLatLng(lat: number, lng: number): TGeoPoint {
    return new TGeoPoint({ coordinates: [lng, lat] });
  }
  //--------------------------------------------------------------------------------------------
  toJSON() {
    let result = {
      ...this,
      lng: null,
      lat: null
    };
    delete result["lng"];
    delete result["lat"];
    return result;
  }
  //--------------------------------------------------------------------------------------------
  get lng(): number {
    return this.coordinates[0];
  }
  set lng(value: number) {
    this.coordinates[0] = value;
  }
  //--------------------------------------------------------------------------------------------
  get lat(): number {
    return this.coordinates[1];
  }
  set lat(value: number) {
    this.coordinates[1] = value;
  }
  get googlePoint(): TGooglePoint {
    return new google.maps.LatLng(this.lat, this.lng);
  }
  //--------------------------------------------------------------------------------------------
  get latLngLiteral(): google.maps.LatLngLiteral {
    return { lat: this.lat, lng: this.lng }
  }
  //--------------------------------------------------------------------------------------------
  static equals(p1?: TGooglePoint | null, p2?: TGooglePoint | null): boolean {
    if (!p1 || !p2) {
      return false;
    }
    let ll1 = new google.maps.LatLng(p1);
    let ll2 = new google.maps.LatLng(p2);
    return ll1.equals(ll2);
  }
  //--------------------------------------------------------------------------------------------
  static distance(p1: TGeoPoint, p2: TGeoPoint): number | undefined {
    //return google.maps.geometry.spherical?.computeDistanceBetween(p1, p2);
    const toRad = (grad: number) => grad * Math.PI / 180;
    var R = 6371000; // km
    var dLat = toRad(p2.lat - p1.lat);
    var dLng = toRad(p2.lng - p1.lng);
    var lat1 = toRad(p1.lat);
    var lat2 = toRad(p2.lat);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }
  //----------------------------------------------------------------------------------------------------
  static getBounds(markers: TGeoPoint[]): google.maps.LatLngBounds {
    let bounds = new google.maps.LatLngBounds();
    markers.forEach(marker => bounds.extend(marker.googlePoint));
    return bounds;
  }
  //----------------------------------------------------------------------------------------------------
  static compareDistance(d1?: number, d2?: number): number {
    if (d1 == undefined && d2 == undefined)
      return 0;
    if (d1 != undefined && d2 != undefined)
      return d1 - d2;
    if (d1 != undefined)
      return -1;
    return 1;
  }
  //----------------------------------------------------------------------------------------------------
  compareDistance(other: TGeoPoint): number {
    return TGeoPoint.compareDistance(this.distance, other.distance);
  }
  //--------------------------------------------------------------------------------------------
  get pointPosition(): google.maps.LatLngLiteral {
    return { lng: this.lng, lat: this.lat };
  }
  //--------------------------------------------------------------------------------------------
  getId() {
    if (this.id)
      return this.id;
    return `${this.coordinates[0]}${this.coordinates[1]}`.replace(/\D/g, '');
  }
  //--------------------------------------------------------------------------------------------
  get isZero() {
    return this.coordinates[0] == 0 && this.coordinates[1] == 0;
  }
}
//--------------------------------------------------------------------------------------------
export class TPolygonArea {
  type: string = "Polygon";
  coordinates!: (number | undefined)[][][];
  //--------------------------------------------------------------------------------------------
  constructor(source: any) {
    if (source != undefined) {
      Object.assign(this, source);
    }
  }
  //--------------------------------------------------------------------------------------------
  toJSON() {
    let result = {
      ...this,
      center: null,
      frameWest: null,
      frameEast: null,
      frameNorth: null,
      frameSouth: null,
    };
    delete result["center"];
    delete result["frameWest"];
    delete result["frameEast"];
    delete result["frameNorth"];
    delete result["frameSouth"];
    return result;
  }
  //--------------------------------------------------------------------------------------------
  static createFrame(ne: TGeoPoint, sw: TGeoPoint) {
    let result = new TPolygonArea({
      coordinates: [
        [
          [ne.lng, sw.lat],
          [ne.lng, ne.lat],
          [sw.lng, ne.lat],
          [sw.lng, sw.lat],
          [ne.lng, sw.lat],
        ],
      ]
    });
    return result;
  }
  //--------------------------------------------------------------------------------------------
  static createGoogleFrameLatLng(ne: google.maps.LatLng, sw: google.maps.LatLng) {
    let result = new TPolygonArea({
      coordinates: [
        [
          [ne.lng(), sw.lat()],
          [ne.lng(), ne.lat()],
          [sw.lng(), ne.lat()],
          [sw.lng(), sw.lat()],
          [ne.lng(), sw.lat()],
        ],
      ]
    });
    return result;
  }
  //--------------------------------------------------------------------------------------------
  static createGoogleLatLngBounds(bounds: google.maps.LatLngBounds) {
    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();
    return TPolygonArea.createGoogleFrameLatLng(ne, sw);
  }
  //--------------------------------------------------------------------------------------------
  static createGoogleFrameLiteral(bounds: google.maps.LatLngBoundsLiteral) {
    let result = new TPolygonArea({
      coordinates: [
        [
          [bounds.east, bounds.south],
          [bounds.east, bounds.north],
          [bounds.west, bounds.north],
          [bounds.west, bounds.south],
          [bounds.east, bounds.south],
        ],
      ]
    });
    return result;
  }
  //--------------------------------------------------------------------------------------------
  get frameWest(): number | undefined {
    let result: number | undefined = undefined;
    this.coordinates.forEach(ring => {
      ring.forEach(point => {
        if (point[0]) {
          if (!result || point[0] < result) {
            result = point[0];
          }
        }
      });
    });
    return result;
  }
  //--------------------------------------------------------------------------------------------
  get frameEast(): number | undefined {
    let result: number | undefined = undefined;
    this.coordinates.forEach(ring => {
      ring.forEach(point => {
        if (point[0]) {
          if (!result || point[0] > result) {
            result = point[0];
          }
        }
      });
    });
    return result;
  }
  //--------------------------------------------------------------------------------------------
  get frameNorth(): number | undefined {
    let result: number | undefined = undefined;
    this.coordinates.forEach(ring => {
      ring.forEach(point => {
        if (point[1]) {
          if (!result || point[1] > result) {
            result = point[1];
          }
        }
      });
    });
    return result;
  }
  //--------------------------------------------------------------------------------------------
  get frameSouth(): number | undefined {
    let result: number | undefined = undefined;
    this.coordinates.forEach(ring => {
      ring.forEach(point => {
        if (point[1]) {
          if (!result || point[1] < result) {
            result = point[1];
          }
        }
      });
    });
    return result;
  }
  //--------------------------------------------------------------------------------------------
  get center(): TGeoPoint | undefined {
    let north = this.frameNorth;
    let south = this.frameSouth;
    let west = this.frameWest;
    let east = this.frameEast;
    if (north && south && west && east) {
      let result = new TGeoPoint(undefined);
      result.lng = (west + east) / 2;
      result.lat = (south + north) / 2;
      return result;
    }
    return undefined;
  }
  //--------------------------------------------------------------------------------------------
  get frame(): google.maps.LatLngBoundsLiteral | undefined {
    let east = this.frameEast;
    let west = this.frameWest;
    let north = this.frameNorth;
    let south = this.frameSouth;
    if (east && west && north && south)
      return { east, north, south, west };
    return undefined;
  }
  //--------------------------------------------------------------------------------------------
  getPointPosition(): google.maps.LatLngLiteral {
    let result = this.center?.getPointPosition();
    if (result) {
      return result;
    }
    return { lng: 0, lat: 0 };
  }
}
//--------------------------------------------------------------------------------------------
export type TGooglePoint = google.maps.LatLng | google.maps.LatLngLiteral;
//--------------------------------------------------------------------------------------------
export const zeroGooglePoint = { lat: 0, lng: 0 };
//--------------------------------------------------------------------------------------------
export enum LocationType {
  Point,
  Polygon,
  Bounds
}
//--------------------------------------------------------------------------------------------
export class TLocationData {
  place?: google.maps.places.PlaceResult;
  get placeId(): string | undefined {
    return this.place?.place_id;
  }
  get placeTypes(): string[] | undefined {
    return this.place?.types;
  }
  address: string = "";
  mapPoint?: TGooglePoint;
  //mapArea?: TPolygonArea;
  mapBoundsLiteral?: google.maps.LatLngBoundsLiteral;
  zoom?: number;
  //--------------------------------------------------------------------------------------------
  isSetFromMap: boolean = false;
  //--------------------------------------------------------------------------------------------
  constructor(source?: any, spread?: any) {
    if (source || spread) {
      Object.assign(this, source, spread);
    }
  }
  //--------------------------------------------------------------------------------------------
  get isEmpty(): boolean {
    return !this.mapPoint && !this.address && !this.mapBoundsLiteral && !this.place;
  }
  //--------------------------------------------------------------------------------------------
  get isZeroPoint(): boolean {
    if (this.mapPoint && !this.address && !this.mapBoundsLiteral && !this.place) {
      return TGeoPoint.equals(this.mapPoint, zeroGooglePoint);
    }
    return false;
  }
  //--------------------------------------------------------------------------------------------
  get isAddressOnly(): boolean {
    if (this.address && !this.mapPoint && !this.mapBoundsLiteral && !this.place) {
      return true;
    }
    return false;
  }
  //--------------------------------------------------------------------------------------------
  static fromMapArea(area: IMapArea) {
    return new TLocationData({
      mapBoundsLiteral: area.bounds,
      zoom: area.zoom,
    });
  }
  //--------------------------------------------------------------------------------------------
  static fromGooglePoint(point: TGooglePoint): TLocationData {
    return new TLocationData({ mapPoint: point });
  }
  //--------------------------------------------------------------------------------------------
  static fromPointLocation(point?: TLocation): TLocationData | undefined {
    if (!point)
      return undefined;
    let geoPoint = new TGeoPoint(point);
    let result = new TLocationData();
    result.mapPoint = { lat: geoPoint.lat, lng: geoPoint.lng };
    return result;
  }
  //--------------------------------------------------------------------------------------------
  static fromAddress(address?: string): TLocationData {
    return new TLocationData({ address: address });
  }
  //--------------------------------------------------------------------------------------------
  static fromAddressAndBounds(address?: string, bounds?: google.maps.LatLngBoundsLiteral): TLocationData {
    return new TLocationData({
      address: address,
      mapBoundsLiteral: bounds,
    });
  }
  //--------------------------------------------------------------------------------------------
  static fromParams(address?: string, point?: TGooglePoint, bounds?: google.maps.LatLngBoundsLiteral): TLocationData {
    return new TLocationData({
      address: address,
      mapPoint: point,
      mapBoundsLiteral: bounds,
    });
  }
  //--------------------------------------------------------------------------------------------
  static fromPlace(place: google.maps.places.PlaceResult): TLocationData {
    let location = new TLocationData();
    location.fillFromPlaceResult(place);
    return location;
  }
  //--------------------------------------------------------------------------------------------
  fillFromPlaceResult(place: google.maps.places.PlaceResult) {
    this.place = place;
    this.address = place.name ? place.name : "";
    this.mapPoint = place.geometry?.location;
    if (place.geometry?.viewport) {
      this.mapBoundsLiteral = place.geometry.viewport.toJSON();
      //this.mapArea = TPolygonArea.createGoogleLatLngBounds(place.geometry.viewport);
    }
    this.isSetFromMap = true;
  }
  //--------------------------------------------------------------------------------------------
  getPointCoordinates(): number[] {
    if (!this.mapPoint) {
      return [0, 0];
    }
    if (typeof this.mapPoint.lat === "function" && typeof this.mapPoint.lng === "function") {
      return [this.mapPoint.lng(), this.mapPoint.lat()];
    }
    else if (typeof this.mapPoint.lat === "number" && typeof this.mapPoint.lng === "number") {
      return [this.mapPoint.lng, this.mapPoint.lat];
    }
    return [0, 0];
  }
  //--------------------------------------------------------------------------------------------
  getPointLocation(): TLocation {
    let location = new TLocation();
    location.type = "Point";
    location.coordinates = this.getPointCoordinates();
    return location;
    //return { type: "Point", coordinates: this.getPointCoordinates() };
  }
}
