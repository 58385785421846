import styles from './GoogleMapPopup.module.css';
import { ReactNode, useContext } from "react";
import PopupDialog from "../../../../../common/popup-v2/popup-dialog/PopupDialog";
import { AppUiContextStore } from "../../../../../../context/app-ui-context/AppUiContextProvider";
import GetCaption from "../../../../../common/functions/GetCaption";
import { IUiMenuContent } from "../../../../../common/options/menus/IUiMenuContent";
import GetHint from "../../../../../common/functions/GetHint";
import FormOptions from '../../../../../common/form-options-bar/FormOptions';
import { TUiMenuContent, getTUiMenuContent } from '../../../../../common/menu/menu-content/TUiMenuContent';
import { GetTUiMenuOption, TUiMenuOption } from '../../../../../common/menu/menu-content/menu-option/TUiMenuOption';
import { ScreenType, useAppScreenContext } from '../../../../../../context/app-screen-context/AppScreenProvider';
import CombineStyles from '../../../../../../utils/combineStyles';

export interface IUiGoogleMapPopup {
  id: string;
  caption: any;
  hint: any;
  menuContent: IUiMenuContent;
}

const tmpUi: IUiGoogleMapPopup = {
  id: "GoogleMapPopup",
  caption: { en: "Select a place on the map" },
  hint: { en: "Use search or just navigate to the place or area using the map" },
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionOk",
      directoryId: "",
      elementType: "",
      caption: { en: "Ok" },
      hint: { en: "Ok" }
    }, {
      id: "OptionCancel",
      directoryId: "",
      elementType: "",
      caption: { en: "Cancel" },
      hint: { en: "Cancel" }
    }]
  }
}

interface IProps {
  children: ReactNode;
  isConfirmationEnabled: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function GoogleMapPopup(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const header = GetCaption(appUiContext, tmpUi.id, tmpUi.caption);
  const isMobile = screenType == ScreenType.Mobile;
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OptionOk":
        props.onConfirm();
        break;
      case "OptionCancel":
        props.onClose();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: TUiMenuOption[] = [];
  tmpUi.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionOk":
        const isConfirmationEnabled: boolean = props.isConfirmationEnabled;
        updatedOptions.push(GetTUiMenuOption({ ...option, iconId: "check", isDisabled: !isConfirmationEnabled, isDefault: isConfirmationEnabled }));
        break;
      case "OptionCancel":
        updatedOptions.push(GetTUiMenuOption({ ...option, iconId: "close" }));
        break;
    };
  });
  const menuContent: TUiMenuContent = getTUiMenuContent(tmpUi.menuContent);
  const toolbar =
    <FormOptions
      ui={{ ...menuContent, options: updatedOptions }}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id={tmpUi.id}
      header={header}
      toolbarContent={toolbar}
      isModal={true}
      isDraggable={false}
      onClose={props.onClose}
    >
      <div className={CombineStyles([
        styles.container,
        isMobile ? styles.mobile : ''
      ])}>

        <div className={styles.prompt}>
          {GetHint(appUiContext, tmpUi.id, tmpUi.hint)}
        </div>

        <div className={styles.map}>
          {props.children}
        </div>
      </div>
    </PopupDialog>
  );
}