interface IProps {
  checked: boolean;
}

export default function IconCheckBox(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="1em" height="1em"
      viewBox="0 0 52 52">
      <g
        stroke="currentColor"
        strokeWidth={4}
        strokeLinejoin="round"
        strokeLinecap="round"
        fill="none"
      >
        <rect
          rx={4}
          width={46}
          height={46}
          x="2"
          y="2"
        />
        {props.checked &&
          <polyline points="10,23 23,35 40,15" />}
      </g>
    </svg>
  );
}