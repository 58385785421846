import { IPublicClientApplication } from "@azure/msal-browser";
import { IDictionariesListItem } from "../../../common/dictionary-draft-models/IDictionariesListItem";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";
import { apiBasePath, pathGetSubDictionariesList } from "../../../../../utils/apiPathConstant";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
}

export async function getCustomDictionariesListAsync(props: IProps): Promise<IDictionariesListItem[]> {
  if (!props.authConfig) throw new Error("getCustomDictionariesListAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const response = await apiGetPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${pathGetSubDictionariesList}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getCustomDictionariesListAsync", props.abortSignal?.aborted);
  return response?.content as IDictionariesListItem[];
}