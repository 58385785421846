import { EditFormSection } from '../../../common/console-layout/edit-form-sections/EditFormSection';
import styles from './SectionBranding.module.css';
import { useContext, useEffect, useState } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { ScreenType, useAppScreenContext } from '../../../../context/app-screen-context/AppScreenProvider';
import { IUiSectionBranding } from './IUiSectionBranding';
import GetHint from '../../../common/functions/GetHint';
//import InputListBusinessPath from './business-path/InputListBusinessPath';
import InputText from '../../../common/input-v2/input-text/InputText';
import InputsGroupWrapper from '../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
//import PopupDialog from '../../../common/popup-v2/popup-dialog/PopupDialog';
import { BusinessContext } from '../../../../context/business-context/BusinessContextProvider';
import { ClassCustomerBusiness, ICustomerBusiness } from '../ICustomerBusiness';
import { IBusinessPaths } from '../../../../context/business-context/IBusiness';
import { BusinessLogoEditor } from './business-logo-editor/BusinessLogoEditor';
import { useAbortController } from '../../../../hooks/useAbortController';
import { deleteBusinessAsync } from '../../functions/deleteBusinessAsync';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import { useMsal } from '@azure/msal-react';
import useNavigateWithContext from '../../../../hooks/useNavigateWithContext';
import { pathBusinessConsole, pathBusinesses } from '../../business-console/BusinessConsole';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import { TImageData } from '../../../common/image-editor/TImageData';

interface IProps {
  id: string;
  ui: IUiSectionBranding;
}

export default function SectionBranding(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext, appAuthContextDispatch } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const [isLoading, setIsLoading] = useState(false);
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const [logoEditorOpen, setLogoEditorOpen] = useState(false);
  const abortController = useAbortController("BusinessEditor");
  const navigate = useNavigateWithContext();
  const [constextIsSaved, setContextIsSaved] = useState(false);
  //---------------------------------------------------------------------------
  let business = businessContext.businessState?.business as ClassCustomerBusiness;
  let isDeletable = false;
  const readOnlyAds = businessContext.ads?.find(ad => ad.status.isReadonly);
  if (!readOnlyAds) isDeletable = true;
  //--------------------------------------------------------------------------- Popup test
  // const [tmpPopupOpen, setTmpPopupOpen] = useState(false);
  // const [tmpPopupFullWindow, setTmpPopupFullWindow] = useState(false);
  // const [tmpPopupFullWindowContent, setTmpPopupFullWindowContent] = useState(false);
  //---------------------------------------------------------------------------
  useEffect(() => {
    // remove deleted image when business context is saved, so undo option is not available
    if (businessContext.lastAction?.type == 'SetBusinessState') {
      setContextIsSaved(true);
    };
  }, [businessContext.lastAction]);
  //---------------------------------------------------------------------------
  const onUpdateName = (updatedBusinessName: string, isValid: boolean) => {
    if (!businessContext.businessState) return;
    const updatedBusiness: ICustomerBusiness = {
      ...businessContext.businessState.business as ICustomerBusiness,
      name: updatedBusinessName
    };
    //const updatedBusinessState: IBusinessState = businessContext.businessState.getUpdatedContext(updatedBusiness);
    businessContextDispatch({
      type: "UpdateBusiness",
      business: updatedBusiness,
      isValid: isValid
    });
    // NOTE: for now we do not update business name if it's not valid (instead of showing validation messages)
    //const businessName = business.name;
    // if (updatedBusinessName != businessName) {
    //   const updatedBusiness: Business = new Business(commonBusinessContext.businessContext?.business, { name: updatedBusinessName });
    //   commonBusinessContextDispatch({ type: "UpdateBusiness", value: updatedBusiness });
    // };
  };
  //---------------------------------------------------------------------------
  const onLogoUpdate = (imageData?: TImageData) => {
    if (!businessContext.businessState)
      return;
    //console.log("SectionBranding.onLogoUpdate:", imageData);
    if (!imageData) {
      //setLogoUri(undefined);
    } 
    else if (imageData.imageManager?.targetOperation == "SelectLocalImage") {
      // setIsLoading(true);
      // imageData.imageManager.uploadNewImage(imageData)
      //   .catch(error => {
      //     console.error("BusinessLogoEditor.onImageUpdate.uploadNewImage.catch:", error);
      //   })
      //   .finally(() => {
      //     setIsLoading(false);
      //     if (imageData.imageManager) {
      //       imageData.imageManager.filePack = undefined;
      //       imageData.imageManager = undefined;
      //     }
      //   });
    }
    let updatedBusiness = new ClassCustomerBusiness(businessContext.businessState.business as ICustomerBusiness);
    updatedBusiness.logo = imageData;
    businessContextDispatch({ type: "UpdateBusiness", business: updatedBusiness });
  };
  //---------------------------------------------------------------------------
  const onUpdateBusinessPath = (updatedValue: IBusinessPaths) => {
    if (!businessContext.businessState) return;
    console.log(updatedValue)
    const masterPath = updatedValue.pathList && updatedValue.pathList.length > 0 ? updatedValue.pathList[0] : "";
    const updatedPaths: IBusinessPaths = {
      masterPath: masterPath,
      pathList: [masterPath]
    };
    const updatedBusiness = new ClassCustomerBusiness({
      ...business as ICustomerBusiness,
      paths: updatedPaths
    });
    if (businessContext.businessState.isNew) {
      businessContextDispatch({ type: "UpdateBusiness", business: updatedBusiness });
    } else {
      // We set business as saved object, because business is already updated
      businessContextDispatch({ type: "SetBusiness", business: updatedBusiness });
    };
  };
  //---------------------------------------------------------------------------
  const onBusinessLogoEditorToggle = (open: boolean) => {
    setLogoEditorOpen(open);
  };
  //---------------------------------------------------------------------------
  const deleteBusiness = () => {
    if (!businessContext.businessState) return;
    setIsLoading(true);
    let controller = abortController.newController("saveBusiness");
    const businessToSave = businessContext.businessState.business as ClassCustomerBusiness;
    deleteBusinessAsync({
      msalInstanse: instance,
      authContext: appAuthContext,
      businessId: business.id,
      abortSignal: controller.signal
    }).then(response => {
      // Clear business context
      businessContextDispatch({ type: "ClearBusinessContext" });
      //-----------------------------------------------------------------------
      // Navigate to business list
      navigate(`${pathBusinessConsole}/${businessContext.userRole}/${pathBusinesses}`);
    }).catch(error => {
      console.error(`saveBusiness:`, error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  return (
    <EditFormSection
      id={props.id}
      caption={GetHint(appUiContext, props.ui.id, props.ui.hint)}
    >
      <div className={styles.container}>
        <WikiSpinner show={isLoading} />

        {business &&
          <InputsGroupWrapper>

            <InputText
              ui={{ ...props.ui.inputBusinessId, disabled: true }}
              data={business.id}
            />

            <InputText
              ui={props.ui.inputBusinessName}
              data={business.name}
              isFocused={!logoEditorOpen && screenType != ScreenType.Mobile}
              onUpdate={onUpdateName}
            />

            {/* {props.ui.inputListBusinessPath && business &&
              <InputListBusinessPath
                ui={props.ui.inputListBusinessPath}
                business={business}
                businessIsNew={businessContext.businessState?.isNew}
                onUpdate={onUpdateBusinessPath}
              />} */}

            {business &&
              <BusinessLogoEditor
                ui={props.ui.inputBusinessLogo}
                data={business.logo}
                objectId={business.id}
                dataIsSaved={constextIsSaved}
                useExternalLayout={true}
                onImageUpdate={onLogoUpdate}
                onPopupOpenClose={onBusinessLogoEditorToggle}
              />}

          </InputsGroupWrapper>}

        {isDeletable &&
          <button onClick={deleteBusiness}>
            Delete
          </button>}

        {/* <button onClick={() => setTmpPopupOpen(true)}>Open Popup</button>
        {tmpPopupOpen &&
          <PopupDialog
            id="tmpPopup"
            header="Tmp Dialog"
            isModal={true}
            fullWindow={tmpPopupFullWindow}
            fullWindowContent={tmpPopupFullWindowContent}
            isDraggable={false}
            animation="SlideFromRight"
            onClose={() => setTmpPopupOpen(false)}
          >
            <div style={{ padding: '1em', display: 'flex', flexFlow: 'column', gap: '1em' }}>
              <button onClick={() => setTmpPopupFullWindow(true)}>Set Full Window</button>
              <button onClick={() => setTmpPopupFullWindow(false)}>Exit Full Window</button>
              <br></br>
              <button onClick={() => setTmpPopupFullWindowContent(true)}>Set Full Window Content</button>
              <button onClick={() => setTmpPopupFullWindowContent(false)}>Exit Full Window Content</button>
            </div>
          </PopupDialog>} */}

      </div>

    </EditFormSection>
  );
}