import { useContext, useEffect } from 'react';
import { TUiCaption } from '../../../common/captions/TUiCaption';
import ConsoleTabLayout from '../../../common/console-layout/console-tab-layout/ConsoleTabLayout';
import ConsoleTabContent from '../../../common/console-layout/console-tab-layout/console-tab-content/ConsoleTabContent';
import { IUiEditFormStaff } from './IUiEditFormStaff';
import styles from './Staff.module.css';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import UserRolesList from '../../../admin-console/administrator-console/user-edit-form/user-roles/UserRolesList';
//import InputText from '../../../common/input/input-text/InputText';
import { getTUiInputText } from '../../../common/input/input-text/TUiInputText';
import InputText from '../../../common/input-v2/input-text/InputText';
import InputsGroupWrapper from '../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';

interface IProps {
  ui?: IUiEditFormStaff;
}

export default function Staff(props: IProps) {
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContextDispatch } = useContext(AppUiContextStore);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    if (props.ui?.breadcrumb) {
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: props.ui.breadcrumb });
    };
  }, []);
  //---------------------------------------------------------------------------
  let formCaption: TUiCaption | undefined = undefined;
  if (props.ui) {
    formCaption = {
      id: props.ui.id,
      directoryId: props.ui.directoryId,
      elementType: props.ui.elementType,
      text: props.ui.hint
    };
  };
  const inputUserId = props.ui?.inputUserId;
  //---------------------------------------------------------------------------
  return (
    <ConsoleTabLayout allowScroll={true}>
      <ConsoleTabContent prompt={formCaption}>
        {props.ui && appAuthContext.user?.userIdentity &&
          <div className={styles.container}>

            <InputsGroupWrapper>
              {inputUserId &&
                <InputText
                  ui={{ ...inputUserId, disabled: true }}
                  data={appAuthContext.user?.userIdentity.id}
                />}
              {appAuthContext.user && appAuthContext.user.staffRoles &&
                <UserRolesList
                  staffRoles={props.ui.staffRoles}
                  data={{
                    userIdentity: appAuthContext.user.userIdentity,
                    staffRoles: appAuthContext.user.staffRoles
                  }}
                />}
            </InputsGroupWrapper>
          </div>}
      </ConsoleTabContent>
    </ConsoleTabLayout>
  );
}