import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { AdLanguageSet, IAdLanguageSet } from "../../../ad-content-editor/IAdLanguageSet";
import { apiBasePath, pathCompleteCustomerAdRequest } from "../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../utils/common";
import { apiPostPrivate, checkResponse } from "../../../../utils/api";
import { ICustomerAdRequest } from "../customer-ads/request-form/ICustomerAdRequest";
import { EHttpStatusCode } from "../../../../utils/HttpStatusCodes";
import { IUiDictionaryItemRejectionReason } from "../../../common/dictionaries/IUiDictionaryItem";

interface IResponse {
  request: ICustomerAdRequest;
  dictionary?: IUiDictionaryItemRejectionReason[];
  error?: string;
}

interface IProps {
  msalInstanse: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  requestId: string;
  localeId: string;
  adLanguageSet?: IAdLanguageSet;
  isApproved: boolean;
}

export async function completeCustomerAdRequestAsync(props: IProps): Promise<IResponse> {
  if (!props.authConfig) throw new Error("completeCustomerAdRequestAsync: auth config is not provided");
  if (!props.requestId) throw new Error("completeCustomerAdRequestAsync: request id is not provided");
  if (!props.localeId) throw new Error("completeCustomerAdRequestAsync: locale id is not provided");
  if (!props.adLanguageSet) throw new Error("completeCustomerAdRequestAsync: ad language set data is not provided");
  //---------------------------------------------------------------------------
  const uri = stringFormatter(pathCompleteCustomerAdRequest, [props.requestId, props.localeId, props.isApproved.toString()]);
  const response = await apiPostPrivate(
    props.msalInstanse,
    props.authConfig,
    `${apiBasePath}${uri}`,
    props.adLanguageSet,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(
    response, 
    "completeCustomerAdRequestAsync", 
    props.abortSignal?.aborted,
    [EHttpStatusCode.Conflict]
  );
  const content: IResponse = response?.content;
  // If statusCode == Conflict: update cached dictionary 
  const request: ICustomerAdRequest = content.request;
  const adLanguageSet = new AdLanguageSet(request.adLanguageSet);
  request.adLanguageSet = adLanguageSet;
  return {
    request: request,
    dictionary: content.dictionary,
    error: content.error
  };
}