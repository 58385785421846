import ExpandButton from '../../../../../../business-console/business-tags-selector-popup/tag-node/tag-node-header/expand-button/ExpandButton';
import styles from '../../../locales-editor/cultures-list/culture-list-item/CultureListItem.module.css';
import CombineStyles from '../../../../../../../utils/combineStyles';
import { IRegion } from '../../../locales-editor/getCultures';

interface IProps {
  data: IRegion;
  unavailableRegions?: string[];
  selectedRegion?: IRegion;
  onSelect: (selectedRegion: IRegion, confirmed: boolean) => void; 
}

export default function RegionsListItem(props: IProps) {
  const isUnavailable = props.unavailableRegions?.includes(props.data.id);
  //const isSelected = props.selectedRegions?.findIndex(x => x.id == props.data.id) > -1;
  const isSelected = props.selectedRegion?.id == props.data.id;
  const isAvailable = !isUnavailable && !isSelected;
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (isAvailable)
      props.onSelect(props.data, false);
  };
  //---------------------------------------------------------------------------
  const onSelectConfirmed = () => {
    if (isAvailable)
      props.onSelect(props.data, true);
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <div
        className={CombineStyles([
          styles.header,
          isAvailable ? styles.available : "",
          isUnavailable ? styles.used : "",
          isSelected ? styles.selected : ""
        ])}
        onDoubleClick={onSelectConfirmed}
      >

        <div
          className={styles.caption}
          //onClick={onToggle}
        >
          {props.data.name}
          {/* {(props.data.subCultures.length > 0) &&
            <ExpandButton widthEm={1} expanded={expanded} />} */}
        </div>

        <div
          className={styles.option}
          onClick={onSelect}
        >
          Select
        </div>

      </div>

      {/* {expanded &&
        <div className={styles.items}>
          {subCultures}
        </div>} */}

    </div>
  );
}