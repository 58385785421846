import { DraftItemStateEnum } from "./DraftItemStateEnum";

export interface IDictionaryDraftItem {
  id: string;
  path?: string; // Not null for subitems in dictionaries with IsHierarchical=true
  name?: any; // string for editor, ITranslation for translator
  description?: any;
  //---------------------------------------------
  draftState: DraftItemStateEnum;
  //---------------------------------------------
  publishedVersion?: any;
}

export class DictionaryDraftItem implements IDictionaryDraftItem {
  id!: string;
  path?: string; // Not null for subitems in dictionaries with IsHierarchical=true
  name?: any; // string for editor, ITranslation for translator
  description?: any;
  //---------------------------------------------
  draftState!: DraftItemStateEnum;
  //---------------------------------------------
  publishedVersion?: any;
  //----------------------------------------------------------------------------
  constructor(source?: IDictionaryDraftItem) {
    if (source) {
      Object.assign(this, source);
    }
  }
  //----------------------------------------------------------------------------
  getPathForChilds(): string {
    return this.path ? `${this.path}.${this.id}` : this.id;
  }
  //---------------------------------------------------------------------------
  filterDirectSiblings<T>(items?: T[]): T[] | undefined {
    if (items) {
      let path = this.getPathForChilds();
      return items.filter(item => ((item as unknown) as IDictionaryDraftItem).path === path);
    }
  }
  //---------------------------------------------------------------------------
  filterAllNodesUnderThis<T>(items?: T[]): T[] | undefined {
    if (items) {
      let path = this.getPathForChilds();
      return items.filter(item => ((item as unknown) as IDictionaryDraftItem).path?.startsWith(path));
    }
  }

}