import { pathAdminConsole, pathSystemTranslator } from '../../../AdminConsole';
import { pathDictionaries } from '../../SystemTranslatorConsole';
import { DraftItemStateEnum } from '../../../common/dictionary-draft-models/DraftItemStateEnum';
import { dictonaryTranslationDraftStates } from '../dictionaryTranslationDraftStates';
import { IDictionaryEditorState } from '../../../common/dictionary-editor-models/IDictionaryEditorState';
import { IDictionaryDraftFilter } from '../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter';
import { DictionaryTranslationDraftItem, IDictionaryTranslationDraftItem } from '../DictionaryTranslationDraftItem';
import { DictionaryDraft, IDictionaryDraft } from '../../../common/dictionary-draft-models/DictionaryDraft';
import { CheckItems } from '../../../administrator-console/dictionaries/common/functions/getItemsAreChanged';

interface IDictionaryTranslatorStateInitial {
  dictionaryDraft: IDictionaryTranslationDraft;
  editMode: boolean;
}
interface IDictionaryTranslatorStateUpdate {
  state: IDictionaryTranslatorState;
  spread: IDictionaryTranslatorStateSpread;
}
interface IDictionaryTranslatorStateSpread {
  filter?: IDictionaryDraftFilter;
  editModeIsEnabled?: boolean;
  sourceLocale?: string;
  draft?: DictionaryTranslationDraft;
  isUpdatedInSession?: boolean; // To know if we can save draft
  isUpdated?: boolean;  // To know if we can publish
  isValid?: boolean;  // To know if we can publish
  isReadyToPublish?: boolean;
}
export interface IDictionaryTranslationDraft extends IDictionaryDraft<IDictionaryTranslationDraftItem> {
}
export class DictionaryTranslationDraft extends DictionaryDraft<IDictionaryTranslationDraftItem> {
  items!: DictionaryTranslationDraftItem[];
  //------------------------------------------------------------------------------------
  constructor(source: IDictionaryDraft<IDictionaryTranslationDraftItem>) {
    super(source);
    this.createItems(source.items);
    if (source.publishedVersion) {
      this.publishedVersion = new DictionaryTranslationDraft(source.publishedVersion);
    }
  }
  //------------------------------------------------------------------------------------dictionaryDraftItemPublished
  private createItems(sourceItems: IDictionaryTranslationDraftItem[]) {
    this.items = [];
    for (let i = 0; i < sourceItems.length; i++) {
      const updatedItem = new DictionaryTranslationDraftItem(sourceItems[i]);
      updatedItem.publishedVersion = new DictionaryTranslationDraftItem(sourceItems[i].publishedVersion);
      this.items.push(updatedItem);
    }
  }
}

export interface IDictionaryTranslatorState extends IDictionaryEditorState<DictionaryTranslationDraft> {
}

export class DictionaryTranslatorState implements IDictionaryTranslatorState {
  basePath!: string;
  numberOfApprovals!: number;
  filter!: IDictionaryDraftFilter;
  editModeIsEnabled!: boolean;
  sourceLocale!: string;
  //-----------------------------------
  draft!: DictionaryTranslationDraft;
  draftSessionInitial!: DictionaryTranslationDraft;
  //-----------------------------------
  isUpdatedInSession!: boolean; // To know if we can save draft
  isUpdated!: boolean;  // To know if we can publish
  isValid!: boolean;  // To know if we can publish
  isReadyToPublish!: boolean;
  //---------------------------------------------------------------------------
  constructor(initialData?: IDictionaryTranslatorStateInitial, update?: IDictionaryTranslatorStateUpdate) {
    if (initialData) {
      // State is being initialized
      //console.log("first")
      Object.assign(this, {
        basePath: `/${pathAdminConsole}/${pathSystemTranslator}/${pathDictionaries}`,
        numberOfApprovals: 1,
        filter: {
          draftState: DraftItemStateEnum.All,
          stateGroups: dictonaryTranslationDraftStates,
          selectedGroups: dictonaryTranslationDraftStates.map(g => g.id), // switch on all the options (groups)
          searchById: false
        },
        editModeIsEnabled: initialData.editMode,
        sourceLocale: "i18n",
        //-------------------------------
        breadcrumb: {
          id: initialData.dictionaryDraft.dictionaryId,
          caption: { en: `${initialData.dictionaryDraft.dictionaryId} (${initialData.dictionaryDraft.locale})` },
          path: `/${pathAdminConsole}/${pathSystemTranslator}/${pathDictionaries}/${initialData.dictionaryDraft.dictionaryId}`
        },
        //-------------------------------
        draft: new DictionaryTranslationDraft(initialData.dictionaryDraft),
        //draftSessionInitial: JSON.parse(JSON.stringify(initialData.dictionaryDraft)),
        draftSessionInitial: new DictionaryTranslationDraft(initialData.dictionaryDraft),        
        //-------------------------------
        isUpdatedInSession: false,
        isUpdated: false,
        isValid: true
      });
      this.checkChangesAndValidate();
    } else if (update) {
      //-----------------------------------------------------------------------
      // State is beign updated
      //console.log("not first")
      Object.assign(this, update.state, update.spread);
      this.checkChangesAndValidate();
    } else console.error("Invalid constructor DictionaryTranslatorState call");
  };
  //---------------------------------------------------------------------------
  checkChangesAndValidate() {
    // 1. Check if draft is updated (for we compare only items)
    let results = CheckItems<IDictionaryTranslationDraftItem>({
      propertiesToCheck: ['name', 'description', 'items'],
      items: this.draft.items,
      itemsInitial: this.draftSessionInitial.items,
      itemsPublished: (this.draft.publishedVersion as IDictionaryTranslationDraft).items,
      validator: GetValidity
    });
    //console.log(results)
    this.isUpdated = results.isUpdatedInSession;
    this.isValid = results.isValid;
    this.isReadyToPublish = results.isUpdated && results.isValid;
  };
}

function GetValidity(item: IDictionaryTranslationDraftItem): boolean {
  // if (!item.name.fallbackAccepted && !item.name.value)
  //   return false;
  // if (!item.description.fallbackAccepted && !item.description.value)
  //   return false;
  return true;
}
