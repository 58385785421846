import { useContext } from 'react';
import styles from './MainMenuOptionDropdown.module.css';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import GetCaption from '../../../../functions/GetCaption';
import { IUiOption } from '../../../IUiOption';
import Icon from '../../../../icon/Icon';

interface IProps {
  ui: IUiOption;
  onSelect?: (optionId: string) => void;
}

export default function MainMenuOptionDropdown(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (!props.onSelect) return;
    props.onSelect(props.ui.id);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      onClick={onSelect}
    >

      <div className={styles.option}>

        {props.ui.iconId &&
          <div className={styles.icon}>
            <Icon imageName={props.ui.iconId} />
          </div>}

        {GetCaption(appUiContext, props.ui.id, props.ui.caption)}

        <div className={styles.iconDropdown}>
          <Icon imageName="downArrowSolid" />
        </div>

      </div>

    </div>
  );
}