import { useContext, useState } from "react";
import CombineStyles from "../../../../../utils/combineStyles";
import GetName from "../../../functions/GetName";
import GetText from "../../../functions/GetText";
import Icon from "../../../icon/Icon";
import { IUiListItem } from "../InputDropdown";
import styles from "./InputDropdownItem.module.css";
import msStyles from "./InputDropDownItemMultiselect.module.css";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";

interface IProps {
  ui: IUiListItem;
  isMultiSelect: boolean;
  hideDescription: boolean;
  disabled?: boolean;
  //selected: boolean; // ??? this is for non-multiselect case, otherwise we show only unselected items
  //onClick: (optionId: string, isSelected: boolean) => void;
  parentItems?: IUiListItem[];
  onClick: (updatedItem: IUiListItem, chain?: IUiListItem[]) => void;
}

export default function InputDropdownItem(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [selected, setSelected] = useState(props.ui.selected);
  //---------------------------------------------------------------------------
  const disabled = props.disabled == undefined ? false : props.disabled;
  const parentItems: IUiListItem[] = props.parentItems ? props.parentItems : [];
  //console.log(props.ui.id)
  //console.log(parentItems)
  //--------------------------------------------------------------------------- Main Item selection handler
  const onSelect = () => {
    // console.log("item")
    // console.log(props.ui)
    // console.log(parentItems)
    setSelected(!selected);
    if (parentItems && parentItems.length > 0) {
      props.onClick(props.ui, parentItems);
    } else {
      props.onClick(props.ui);
    };
  }
  //---------------------------------------------------------------------------
  const onSelectSubItem = (updatedItem: IUiListItem, chain?: IUiListItem[]) => {
    // console.log("Subitem")
    // console.log(updatedItem)
    // console.log(chain)
    props.onClick(updatedItem, chain);
  }
  //---------------------------------------------------------------------------
  const containerStyles = props.isMultiSelect ?
    CombineStyles([
      msStyles.container,
      selected ? msStyles.selected : ""
    ])
    :
    CombineStyles([
      styles.container,
      disabled ? styles.disabled : "",
      selected ? styles.selected : ""
    ]);
  //---------------------------------------------------------------------------
  //.filter(item => GetName(item.id, item.name).toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()))
  const subOptions = props.ui.items?.sort((a, b) => GetName(appUiContext, a.id, a.name).localeCompare(GetName(appUiContext, b.id, b.name)))
    .map((item: IUiListItem) => (
      <InputDropdownItem
        key={item.id}
        ui={item}
        isMultiSelect={false}
        hideDescription={props.hideDescription}
        //selected={(props.isMultiSelect && (selectedItems.findIndex(si => si.id === item.id) > -1)) ? true : false}
        parentItems={parentItems.concat(props.ui)}
        onClick={onSelectSubItem}
      />
    ));
  //---------------------------------------------------------------------------
  return (
    <div className={containerStyles}>
      <div className={styles.itemContainer} onClick={onSelect}>
        <div className={styles.captionContainer}>
          <div className={styles.name}>
            {GetText({
              appUiContext: appUiContext, 
              elementId: props.ui.id,
              elementPart: "Name",
              text: props.ui.name
            })}
          </div>
          <div className={styles.prompt}>
            {!props.hideDescription &&
              GetText({
                appUiContext: appUiContext, 
                elementId: props.ui.id,
                elementPart: "Description",
                text: props.ui.description
              })}
          </div>
        </div>
        {props.ui.items &&
          <div>
            <Icon imageName="rightArrow"/>
          </div>}
      </div>
      {props.ui.items &&
        <div className={styles.subItems}>
          {subOptions}
        </div>}
    </div>
  );
}