export interface IComparisonResult {
  isChanged: boolean;
  updatedProperties: string[];
}

export default function getUpdatedPropertiesList(
  currentState: object,
  initialState: object
): IComparisonResult {
  //---------------------------------------------------------------------------
  var result: IComparisonResult = {
    isChanged: false,
    updatedProperties: []
  };
  //---------------------------------------------------------------------------
  // Compare changed state with initial and set flag to true only in case if there are actual changes
  //console.log("Initial:", initialState);
  //console.log("Current:", currentState);
  if (JSON.stringify(currentState) !== JSON.stringify(initialState)) {
    //console.log("initialState/currentState", initialState, currentState);
    // Identify properties where changes are made
    var updatedPropertiesList: string[] = [];
    for (const propertyName in currentState) {
      //console.log(propertyName)
      const currentValue = (currentState as any)[propertyName];
      const initialValue = (initialState as any)[propertyName];
      //console.log(currentValue, initialValue)
      if (JSON.stringify(currentValue) !== JSON.stringify(initialValue)) {
        updatedPropertiesList.push(propertyName);
        //console.log("propertyName/initialValue/currentValue", propertyName, initialValue, currentValue);
      };
    };
    //-------------------------------------------------------------------------
    if (updatedPropertiesList.length > 0) {
      result = {
        isChanged: true,
        updatedProperties: updatedPropertiesList
      };
      //console.warn(`GetUpdatedPropertiesList.updatedPropertiesList:`, updatedPropertiesList);
    }
    else {
      console.warn(`That's strange: object is changed, but can't determine changed properties list...`)
    };
  };
  //---------------------------------------------------------------------------
  return result;
}