import CombineStyles from '../../../../../../utils/combineStyles';
import InputWrapper from '../../../../../common/input-v2/input-wrapper/InputWrapper';
import styles from './SourceValue.module.css';

interface IProps {
  caption: string;
  data?: string;
  disabled: boolean;
  onClick?: () => void;
}

export default function SourceValue(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <InputWrapper caption={props.caption}>
      <div
        className={CombineStyles([
          styles.data,
          props.data ? '' : styles.noData,
          props.disabled ? styles.disabled : ''])}
        onClick={props.onClick}
      >
        {props.data ? props.data : 'No source value'}
      </div>
    </InputWrapper>
  );
}