import React, { useContext, useEffect, useRef, useState } from "react";
import { apiPost, checkResponse } from "../../utils/api";
import { apiBasePath, searchBusinessPreliminary } from "../../utils/apiPathConstant";
import WikiSpinner from "../app-layout/spinner/wikiSpinner";
import { IListItem } from "../business-space/business-editor/Business";
import styles from "./SearchConsole.module.css";
import BusinessSearchList from "./business-search-list/BusinessSearchList";
import { AppUiContextStore } from "../../context/app-ui-context/AppUiContextProvider";
import { BusinessSearchQuery, BusinessSearchResult } from "./business-search-list/BusinessSearchResult";
import BusinessSearchEditor from "./business-search-editor/BusinessSearchEditor";
import { AppScreenContext, ScreenType } from "../../context/app-screen-context/AppScreenProvider";
import CombineStyles from "../../utils/combineStyles";
import { TUiNames } from "../../context/app-ui-context/AppUiCache";
import { ELogLevel, LogInformation } from "../../utils/logging";
import { BusinessSearchParams } from "./business-search-editor/IBusinessSearchParams";
import { IUiSearchConsole } from "./IUiSearchConsole";
import { UiTagTreeNode } from "./TagTree";
import { IUiListItem } from "../common/input/input-dropdown/InputDropdown";
import { TGeoPoint, TPolygonArea } from "../common/map/geo-json/GeoJson";
import { AppAuthContextStore } from "../../context/app-auth-context/AppAuthContext";

//https://caglayanyanikoglu.medium.com/use-infinitive-scroll-like-a-pro-e78d7d5a93a8


const logLevel: ELogLevel = ELogLevel.None;

export type TSearchState = {
  selectedTagTree: IListItem | undefined;
}

const uiName: TUiNames = "searchConsole";

interface IProps {
  ui?: IUiSearchConsole;
}

export default function SearchConsole(props: IProps) {
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useContext(AppScreenContext);
  //---------------------------------------------------------------------------
  const [businessSearchResult, setBusinessSearchResult] = useState<BusinessSearchResult>();
  const [isLoading, setIsLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [mainMenuClicked, setMainMenuClicked] = useState<number>(0);
  const [hoverBusiness, setHoverBusiness] = useState<string>();
  const divList = useRef<HTMLDivElement>(null);
  const tagTree = useRef<UiTagTreeNode>();

  let userPosition = appAuthContext.userLocation?.position
    ? TGeoPoint.fromLatLng(appAuthContext.userLocation?.position.coords.latitude, appAuthContext.userLocation?.position.coords.longitude)
    : TGeoPoint.fromLatLng(0, 0);

  //---------------------------------------------------------------------------
  useEffect(() => {
    if (props.ui) {
      let root: IUiListItem = {
        id: 'rootTag',
        selected: false,
        group: true,
        items: [],
      }
      props.ui.businessTags?.forEach(item => root.items?.push(item));
      tagTree.current = UiTagTreeNode.fromUiListItem(root);
    }
  }, [props.ui]);
  //-[mainMenuClicked]------------------------------- Handle sidebar visibility
  useEffect(() => {
    if (mainMenuClicked == 0) {
      //-----------------------------------------------------------------------
      // Initial render
      setSidebarOpen(true);
    } else {
      //-----------------------------------------------------------------------
      // Toggle search sidebar state if user click main menu button
      if (sidebarOpen) {
        LogInformation(logLevel, "sidebar is opened, so closing it...")
        setSidebarOpen(false);
      } else {
        LogInformation(logLevel, "sidebar is closed, so opening it...")
        setSidebarOpen(true);
      };
    };
  }, [mainMenuClicked]);
  //---------------------------------------------------------------------------
  const onMainMenuButtonClick = () => {
    LogInformation(logLevel, "Main menu button clicked - 1!")
    setMainMenuClicked(Date.now);
  };
  //---------------------------------------------------------------------------
  const getBusinesses = (updatedBusinessSearchParams?: BusinessSearchParams) => {
    if (screenType == ScreenType.Mobile) {
      setSidebarOpen(false);
    };
    //-------------------------------------------------------------------------
    let query = new BusinessSearchQuery(
      updatedBusinessSearchParams,
      appUiContext.locale.localeId);
    query.pointForDistance = userPosition.isZero ? undefined : userPosition;
    // else
    //   query.pointForDistance = TGeoPoint.fromLatLng(37.923896797904895, 28.567429823805575);
    query.area = new TPolygonArea({
      type: "Polygon",
      coordinates: [[[77.29889679790489, -5.7287382311026365], [77.29889679790489, 62.86359787871379], [-1.4511032020951031, 62.86359787871379], [-1.4511032020951031, -5.7287382311026365], [77.29889679790489, -5.7287382311026365]]]
    });
    //-----------------------------------------------------------------------
    setIsLoading(true);
    apiPost(`${apiBasePath}${searchBusinessPreliminary}`, query)
      .then((response) => {
        checkResponse(response, "searchBusiness");
        const result = new BusinessSearchResult(response.content, query, tagTree.current);
        console.log("getBusinesses.result:", result);
        //if (!query.pointForDistance) 
        {
          result.mergeLocations();
          console.log("getBusinesses.result.merged:", result);
        }
        setBusinessSearchResult(result);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        //refreshUrl(updatedBusinessSearchParams);
        setIsLoading(false);
      });
  };
  //---------------------------------------------------------------------------
  const onScrollToTop = () => {
    if (divList.current) {
      divList.current.scrollTop = 0;
    }
  };
  //---------------------------------------------------------------------------
  const sidebarStyles = CombineStyles([
    styles.sidebar,
    screenType == ScreenType.Mobile ? styles.mobile : ""
  ]);
  const listStyles = CombineStyles([
    styles.list,
    screenType == ScreenType.Mobile ? styles.mobile : ""
  ]);
  //---------------------------------------------------------------------------style={{ borderTop: screenType == ScreenType.Mobile ? 'none' : undefined }}
  //LogInformation(logLevel, "SearchConsole.ui.defaultBusinessLogo:", ui?.defaultBusinessLogo);
  //---------------------------------------------------------------------------style={{ borderTop: screenType == ScreenType.Mobile ? 'none' : undefined }}
  if (businessSearchResult) {
    businessSearchResult.showMarkersForPageOnly = false;//showMarkersForPageOnly;
  }
  //---------------------------------------------------------------------------
  let businessMarkers = businessSearchResult?.getBusinessMarkers(appUiContext);
  //console.log(props.ui);
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <div className={styles.container} >
        {props.ui &&
          <div
            className={sidebarStyles}
            style={{
              width: !sidebarOpen ? 0 : undefined,
              flex: !sidebarOpen ? 'none' : undefined,
              overflowX: !sidebarOpen ? 'hidden' : undefined
            }}
          >
            <BusinessSearchEditor
              ui={props.ui}
              businessSearchResult={businessSearchResult}
              businessMarkers={businessMarkers}
              userPosition={userPosition}
              onSearchStart={getBusinesses}
              highlightedBusinessId={hoverBusiness}
            />
          </div>}

        <div ref={divList} className={listStyles}>
          <WikiSpinner show={isLoading} />
          <BusinessSearchList
            businessSearchResult={businessSearchResult}
            onScrollToTop={onScrollToTop}
            onBusinessHover={setHoverBusiness}
          />
        </div>

      </div>
    </React.Fragment>
  );
}