import { IPresets } from "../../../context/list-manager-context/IListManagerContextData";
import { IListColumn } from "../../common/lists/IListColumn";


export const testListColumns: IListColumn[] = [/* {
  id: "column1",
  name: "Column 1",
  filter: {
    type: "Options",
    options: [{
      id: "column1Option1"
    }, {
      id: "column1Option2"
    }, {
      id: "column1Option3"
    }],
  }
}, {
  id: "column2",
  name: "Column 2",
  filter: {
    type: "Options",
    options: [{
      id: "column2Option1"
    }, {
      id: "column2Option2",
      isSelected: true
    }, {
      id: "column2Option3"
    }],
  }
}, */ {
  id: "id",
  name: "Item Id",
  filter: {
    type: "Keyword",
    keyword: "",
    placeholder: "Input index Id..."
  },
  sorter: {
    order: 1,
    sort: "Asc",
    isRequired: true,
    isDefault: true
  }
}, /* {
  id: "dateTimeCreated",
  name: "Created",
  filter: {
    type: "DatetimeRange"
  },
  sorter: {
    order: 2,
    sort: "Asc",
    isRequired: false
  }
} */];

export const testListSortPresets: IPresets = {
  options: [{
    id: "SmallestFirst",
    name: "From Smallest to Largest",
  },{
    id: "LargestFirst",
    name: "From Largest to Smallest",
  }],
  currentOptionId: "SmallestFirst"
};

export const testListFilterPresets: IPresets = {
  options: [{
    id: "Uncompleted",
    name: "Uncompleted",
  },{
    id: "Rejected",
    name: "Rejected",
  }],
  currentOptionId: "Uncompleted"
};