import { ReactNode, useEffect, useState } from "react";
import BusinessSearchSectionHeader from "./business-search-section-header/BusinessSearchSectionHeader";
import styles from "./BusinessSearchSection.module.css";
import { TUiBusinessSearchSection } from "./TUiBusinessSearchSection";

interface IProps {
  ui: TUiBusinessSearchSection;
  switchedOn: boolean;
  editDisabled: boolean;
  children?: ReactNode;
  onStateUpdate: (switchedOn: boolean) => void;
  onEdit?: () => void;
}

export default function BusinessSearchSection(props: IProps) {
  const [collapsed, setCollapsed] = useState(true);
  //console.log(props.ui.header.id)
  //console.log(collapsed)
  //---------------------------------------------------------------------------
  useEffect(() => {
    //console.log(props.children)
    if (!props.children)
      setCollapsed(true);
    else
      setCollapsed(false);
  }, [props.children]);
  //---------------------------------------------------------------------------
  const onExpand = () => {
    setCollapsed(false);
  }
  //---------------------------------------------------------------------------
  const onCollapse = () => {
    setCollapsed(true);
  }
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <BusinessSearchSectionHeader
        ui={props.ui.header}
        switchedOn={props.switchedOn}
        editDisabled={props.editDisabled}
        collapsed={collapsed}
        onStateUpdate={props.onStateUpdate}
        onExpand={onExpand}
        onCollapse={onCollapse}
        onEdit={props.onEdit}
      />
      {!collapsed && props.children &&
        <div className={styles.content}>
          {props.children}
        </div>}
    </div>
  );
}