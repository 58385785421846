import CombineStyles from '../../../../utils/combineStyles';
import DictionaryNodeToggler from '../../administrator-console/dictionaries/common/dictionary-node-wrapper/dictionary-node-toggler/DictionaryNodeToggler';
import styles from './DictionaryNodeSystemId.module.css';

interface IProps {
  data: string;
  isHierarchical?: boolean;
  isExpandable: boolean;
  isCollapsed: boolean;
  editModeIsEnabled?: boolean;
  indent?: number;
  onToggle: () => void;
  onEdit: () => void;
}

export default function DictionaryNodeSystemId(props: IProps) {
  const isHierarchical = props.isHierarchical == undefined ? false : props.isHierarchical;
  const editModeIsEnabled = props.editModeIsEnabled == undefined ? false : props.editModeIsEnabled;
  const indent = props.indent ? props.indent : 0;
  //---------------------------------------------------------------------------
  return (
    <td
      className={CombineStyles([
        styles.text,
        styles.narrow,
        styles.system,
        //editModeIsEnabled ? styles.editable : ""
      ])}
      onDoubleClick={props.onEdit}
    >
      <div
        className={styles.container}
        style={{ paddingLeft: `${indent * 1.2}em` }}
      >

        {/* Toggler for hierarchical dictionaries */}
        {isHierarchical &&
          <DictionaryNodeToggler
            isCollapsed={props.isCollapsed}
            onToggle={props.isExpandable ? props.onToggle : undefined}
          />}

        <span
          className={editModeIsEnabled ? styles.clickableName : ""}
          onClick={props.onEdit}
        >
          {props.data}
        </span>

      </div>
    </td>
  );
}