import { IPopupState } from "./GetPopupStates_v3";

export default function restore(
  popupRef?: React.RefObject<HTMLDivElement>,
  stateToRestore?: IPopupState
) {
  const popup = popupRef?.current;
  if (popup && stateToRestore) {
    //-----------------------------------------------------------------------
    // Initial styles are already applied
    const initialRect = popup.getBoundingClientRect();
    console.log(initialRect)
    //-----------------------------------------------------------------------
    // Apply final styles
    console.log('state before', stateToRestore)
    popup.style.width = '';
    popup.style.height = '';
    stateToRestore.maxWidth && (popup.style.maxWidth = stateToRestore.maxWidth.asString);
    stateToRestore.maxHeight && (popup.style.maxHeight = stateToRestore.maxHeight.asString);
    const finalRect = popup.getBoundingClientRect();
    console.log(finalRect)
    //-----------------------------------------------------------------------
    // Calc the delta
    var invertWidth = initialRect.width / finalRect.width;
    var inverHeight = initialRect.height / finalRect.height;
    //---------------------------------------------------------------------
    // Invert
    popup.style.transition = '';
    const popupTransform = popup.style.transform;
    popup.style.transform = `${popupTransform} scaleX(${invertWidth}) scaleY(${inverHeight})`;
    //---------------------------------------------------------------------
    // Play
    const tt = '1000ms ease-out';
    requestAnimationFrame(() => {
      popup.style.transition = `transform ${tt}, opacity ${tt}`;
      popup.style.transform = popupTransform ? popupTransform : '';
      popup.style.opacity = '1';
    });
  };
}