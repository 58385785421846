import React, { useRef, useState } from 'react';
import styles from './SectionMainCategory.module.css';
import BusinessSearchSection from '../business-search-section/BusinessSearchSection'
import { TUiBusinessSearchSection } from '../business-search-section/TUiBusinessSearchSection';
import { IUiListItem } from '../../../common/input/input-dropdown/InputDropdown';
import { GetTUiMenuOption } from '../../../common/menu/menu-content/menu-option/TUiMenuOption';
import { IUiSelectorBusinessTags } from '../../../business-console/business-tags-selector/IUiSelectorBusinessTags';
import SelectedSubTags from '../../../business-console/business-tags-selector/selected-subtags/SelectedSubTags';
import BusinessTagsPopup from '../../../common/business/business-tags-popup/BusinessTagsPopup';
import { tmpUiBusinessTagsPopup } from '../../../common/business/business-tags-popup/IUiBusinessTagsPopup';
import BusinessTagsSelected from '../../../common/business/business-tags-selected/BusinessTagsSelected';

interface IProps {
  ui: IUiSelectorBusinessTags;
  rootTag: IUiListItem;
  selectedTags: string[];
  onUpdate: (selectedTags?: string[]) => void;
}

export default function SectionMainCategory(props: IProps) {
  const [popupOpen, setPopupOpen] = useState(false);
  const pocket = useRef<string[]>();
  const isSelected = props.selectedTags ? props.selectedTags.findIndex(x => x.startsWith(props.rootTag.id)) > -1 : false;
  const hasSubTags = props.selectedTags ? props.selectedTags.findIndex(x => x.startsWith(props.rootTag.id) && x != props.rootTag.id) > -1 : false;
  //---------------------------------------------------------------------------
  const onEditTags = () => {
    setPopupOpen(true);
  };
  //---------------------------------------------------------------------------
  const onTagsUpdate = (selectedTags?: string[]) => {
    console.log(selectedTags);
    props.onUpdate(selectedTags);
    setPopupOpen(false);
  };
  //---------------------------------------------------------------------------
  const onTagsCancel = () => {
    setPopupOpen(false);
  };
  //---------------------------------------------------------------------------
  const onToggle = () => {
    let updatedTags = props.selectedTags ? props.selectedTags : [];
    if (isSelected) {
      // Unselect root tag and all subtags
      pocket.current = props.selectedTags.filter(x => x.startsWith(props.rootTag.id));
      updatedTags = updatedTags.filter(x => !x.startsWith(props.rootTag.id));
      props.onUpdate(updatedTags);
    } else {
      //-----------------------------------------------------------------------
      // Select root tag
      if (pocket.current) {
        updatedTags = updatedTags.concat(pocket.current);
      } else {
        updatedTags.push(props.rootTag.id);
      };
      props.onUpdate(updatedTags);
    };
  };
  //---------------------------------------------------------------------------
  const sectionUi: TUiBusinessSearchSection = {
    header: GetTUiMenuOption({
      id: props.rootTag.id,
      directoryId: "",
      elementType: "",
      caption: props.rootTag.name,
      hint: undefined
    })
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <BusinessSearchSection
        ui={sectionUi}
        switchedOn={isSelected}
        editDisabled={false}
        onStateUpdate={onToggle}
        onEdit={onEditTags}
      >
        {hasSubTags &&
          <div className={styles.container}>
            <SelectedSubTags
              ui={props.rootTag}
              selectedTags={props.selectedTags}
              path={props.rootTag.id}
              onUpdate={onTagsUpdate}
            />
            <BusinessTagsSelected
              selectedTags={props.selectedTags}
              rootPath={props.rootTag.id}
              onUpdate={onTagsUpdate}
            />
          </div>}
      </BusinessSearchSection>

      {popupOpen &&
        <BusinessTagsPopup
          ui={tmpUiBusinessTagsPopup}
          selectedPaths={props.selectedTags}
          rootPath={props.rootTag.id}
          onUpdate={onTagsUpdate}
          onClose={onTagsCancel}
        />}
        
    </React.Fragment>
  );
}