import { Buffer } from 'buffer';
import { TPolygonArea } from '../../common/map/geo-json/GeoJson';

export const paramSearchCategories = "cat";
export const paramSearchLocationAddress = "location";
export const paramSearchLocationBounds = "bounds";
export const paramSearchUseArea = "useArea";
export const paramSearchMode = "mode";

export interface IBusinessSearchParam {
  enabled: boolean;
}

export interface IBusinessSearchParams extends IBusinessSearchParam {
  tags: string[];
  locationArea?: TPolygonArea;
  useLocationArea?: boolean;
  locationBoundsToDisplay?: google.maps.LatLngBoundsLiteral;
  locationName?: string;
}

export class BusinessSearchParams implements IBusinessSearchParams {
  enabled: boolean = true;
  tags: string[] = [];
  locationArea?: TPolygonArea;
  useLocationArea?: boolean;
  locationName?: string;
  searchModeAuto?: boolean;
  //--------------------------------------------------------------------------------------------------
  get locationBoundsToDisplay(): google.maps.LatLngBoundsLiteral | undefined {
    return this.locationArea?.frame;
  }
  set locationBoundsToDisplay(value: google.maps.LatLngBoundsLiteral | undefined) {
    this.locationArea = value
      ? TPolygonArea.createGoogleFrameLiteral(value)
      : undefined;
  }
  //---------------------------------------------------------------------------
  constructor(source?: any, spread?: any) {
    if (source || spread) {
      Object.assign(this, source, spread);
    }
  }
  //---------------------------------------------------------------------------
  static getSearchParamsFromUrl(searchParams?: URLSearchParams): BusinessSearchParams {
    if (!searchParams) {
      return new BusinessSearchParams(defaultSearchParams);
    };
    //-------------------------------------------------------------------------
    const tagsEncoded = searchParams.get(paramSearchCategories);
    const tagsDecoded = tagsEncoded ? Buffer.from(tagsEncoded, 'base64').toString('utf8') : undefined;
    const boundsQuery = searchParams.get(paramSearchLocationBounds);
    const latLngBoundsLiteral = (boundsQuery && boundsQuery != "") ? JSON.parse(boundsQuery) : undefined;
    const useLocationAreaString = searchParams.get(paramSearchUseArea);
    const searchMode = searchParams.get(paramSearchMode);
    let result = new BusinessSearchParams(undefined);
    result.tags = tagsDecoded ? JSON.parse(tagsDecoded) : [];
    result.locationName = searchParams.get(paramSearchLocationAddress) ?? undefined;
    result.locationBoundsToDisplay = latLngBoundsLiteral;
    result.useLocationArea = useLocationAreaString ? useLocationAreaString == "true" : undefined;
    result.searchModeAuto = searchMode == 'auto' ? true : false;
    return result;
  }
  //---------------------------------------------------------------------------
  static getCleanUrl(href: string): URL {
    let result = new URL(href);
    result.searchParams.delete(paramSearchCategories);
    result.searchParams.delete(paramSearchLocationAddress);
    result.searchParams.delete(paramSearchLocationBounds);
    result.searchParams.delete(paramSearchMode);
    result.searchParams.delete(paramSearchUseArea);
    return result;
  }
  //---------------------------------------------------------------------------
  getUrl(href: string): URL {
    let result = BusinessSearchParams.getCleanUrl(href);
    //------------------------------------------------------------------------- Tags  
    if (this.tags && this.tags.length > 0) {
      const tagsTreeString = JSON.stringify(this.tags);
      const tagsTreeEncoded = Buffer.from(tagsTreeString, 'utf8').toString('base64');
      result.searchParams.append(paramSearchCategories, tagsTreeEncoded);
    };
    // if (this.tagTree?.items && this.tagTree.items.length > 0) {
    //   const tagsTreeString = JSON.stringify(this.tagTree);
    //   const tagsTreeEncoded = Buffer.from(tagsTreeString, 'utf8').toString('base64');
    //   result.searchParams.append(paramSearchCategories, tagsTreeEncoded);
    // };
    //-------------------------------------------------------------------- Location name - Rename to Area!!!
    if (this.locationName) {
      result.searchParams.append(paramSearchLocationAddress, this.locationName);
    };
    //------------------------------------------------------------------------- Location bounds
    if (this.locationBoundsToDisplay) {
      result.searchParams.append(paramSearchLocationBounds, JSON.stringify(this.locationBoundsToDisplay));
    };
    //------------------------------------------------------------------------- Location bounds
    if (this.useLocationArea !== undefined) {
      result.searchParams.append(paramSearchUseArea, `${this.useLocationArea}`);
    }
    return result;
  }
}

export const defaultSearchParams: IBusinessSearchParams = {
  tags: [],
  useLocationArea: false,
  locationArea: undefined,
  enabled: false,
  locationName: undefined
}