import { useState } from 'react';
import Icon from '../../../../common/icon/Icon';
import { IDictionaryListItem } from '../DictionaryList';
import styles from './DictionaryListItem.module.css';
import Toggler from '../../../../common/toggler/Toggler';
import DirectoryTranslationsListItem from '../../../system-translator-console/widgets/directory-list/directory-list-item/directory-translations-list-item/DirectoryTranslationsListItem';
import CombineStyles from '../../../../../utils/combineStyles';
import { getIcon } from '../../dictionary-draft-models/EDictionaryType';

export interface IUiDictionaryListItem {
  //inputLocale: IUiInputDropdown;
}

interface IProps {
  //ui: IUiDictionaryListItem;
  mode: "Editor" | "Translator";
  data?: IDictionaryListItem;
  onSelect: (locale?: string) => void;
}

export default function DictionaryListItem(props: IProps) {
  const [collapsed, setCollapsed] = useState(true);
  //---------------------------------------------------------------------------
  const onToggle = () => {
    if (props.mode == "Translator") {
      setCollapsed(!collapsed);
    };
  };
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (props.mode == "Editor") {
      props.onSelect();
    };
  };
  //---------------------------------------------------------------------------
  const onSelectLocale = (locale: string) => {
    //if (props.data?.dictionaryId != "BusinessTags") {
      if (props.mode == "Translator") {
        props.onSelect(locale);
      };
    //};
  };
  //---------------------------------------------------------------------------
  const translations = props.data?.translations.map(t => (
    <DirectoryTranslationsListItem
      key={t.locale}
      data={t}
      onSelect={() => onSelectLocale(t.locale)}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <div
        className={CombineStyles([
          styles.header,
          //props.data?.dictionaryId == "BusinessTags" ? styles.disabled : ""
        ])}
        onClick={props.mode == "Translator" ? onToggle : onSelect}
      >
        <div className={styles.icon}>
          <Icon imageName={getIcon(props.data?.dictionaryId)} />
        </div>
        <div className={styles.caption}>
          <div className={styles.name}>
            {props.data?.name}
          </div>
          <div className={CombineStyles([
            styles.description,
            //props.data?.dictionaryId == "BusinessTags" ? styles.disabled : ""
          ])}>
            {props.data?.description}
          </div>
        </div>
        <div className={styles.toggler}>
          <Toggler collapsed={collapsed} />
        </div>
      </div>

      {!collapsed &&
        <div className={styles.items}>
          {translations}
        </div>}

    </div>
  );
}