import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { apiBasePath, pathPublishSubDictionaryDraft } from "../../../../../utils/apiPathConstant";
import { CustomDictionaryDraft } from "../../dictionaries/common-dictionary/IDictionaryDraft";
import { stringFormatter } from "../../../../../utils/common";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  dictionaryId: string;
  isNew?: boolean;
}

export async function publishCustomDictionaryDraftAsync(props: IProps): Promise<CustomDictionaryDraft> {
  if (!props.authConfig) throw new Error("publishCustomDictionaryDraftAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  let apiUri = `${apiBasePath}${stringFormatter(pathPublishSubDictionaryDraft, [props.dictionaryId])}`;
  if (props.isNew) apiUri += "?isNew=true";
  const response = await apiPostPrivate(
    props.msalInstance,
    props.authConfig,
    apiUri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "publishCustomDictionaryDraftAsync", props.abortSignal?.aborted);
  const draft = new CustomDictionaryDraft().initialize(response?.content);
  return draft;
}