import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { IUiInput } from '../../../../common/input-v2/IUiInput';
import { TImageData } from '../../../../common/image-editor/TImageData';
import styles from './BusinessLogoEditor.module.css';
import { SmoothImageLoader } from '../../../../common/image-editor/SmoothImageLoader';
import { ImageEditorPlaceholder } from '../../../../common/image-editor/image-editor-placeholder/ImageEditorPlaceholder';
import CombineStyles from '../../../../../utils/combineStyles';
import InputWrapper from '../../../../common/input-v2/input-wrapper/InputWrapper';
import GetCaption from '../../../../common/functions/GetCaption';
import GetHint from '../../../../common/functions/GetHint';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { TImageUsage } from '../../../../common/image-editor/TImageUsage';
import { BusinessContext } from '../../../../../context/business-context/BusinessContextProvider';
import { ClassCustomerBusiness, ICustomerBusiness } from '../../ICustomerBusiness';
import { useAbortController } from '../../../../../hooks/useAbortController';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import WikiSpinner from '../../../../app-layout/spinner/wikiSpinner';
import { ClassImageManager } from '../../../../common/image-editor/image-manager/IImageManager';
import { ImageManager } from '../../../../common/image-editor/image-manager/ImageManager';

export const pathWizardBusinessLogo = "2";
const placeholderHint = "Business logo";

interface IProps {
  ui: IUiInput;
  data?: TImageData;
  objectId: string;
  dataIsSaved?: boolean;
  useExternalLayout: boolean;
  hideCaption?: boolean;
  hideHint?: boolean;
  onPopupOpenClose?: (open: boolean) => void;
  onImageUpdate: (imageData?: TImageData) => void;
}

export function BusinessLogoEditor(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  //const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const [logoUri, setLogoUri] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [imageManager, setImageManager] = useState<ClassImageManager>();
  const abortController = useAbortController("BusinessLogoEditor");
  //const business = businessContext.businessState?.business as ClassCustomerBusiness;
  //if (!business) return null;
  //---------------------------------------------------------------------------
  // useEffect(() => {
  //   if (!business.logo) setLogoUri(undefined);
  // }, [businessContext.businessState?.business]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!props.data) setLogoUri(undefined);
  }, [props.data]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    // remove deleted image when business context is saved, so undo option is not available
    if (imageManager && props.dataIsSaved) {
      imageManager.removedImage = undefined;
    };
  }, [props.dataIsSaved]);
  //---------------------------------------------------------------------- ImageEditorOpen
  useEffect(() => {
    imageManager && props.onPopupOpenClose && props.onPopupOpenClose(imageManager?.editorOpen);
  }, [imageManager?.editorOpen]);
  //---------------------------------------------------------------------- ImageEditorOpen
  useEffect(() => {
    imageManager && props.onPopupOpenClose && props.onPopupOpenClose(imageManager?.libraryOpen);
  }, [imageManager?.libraryOpen]);
  //---------------------------------------------------------------------------
  const onImageUpdate = (imageData?: TImageData) => {
    imageData?.clearSas();
    props.onImageUpdate(imageData);
  };
  //---------------------------------------------------------------------------
  const onImageManagerCreate = (manager: ClassImageManager) => {
    manager.businessId = props.objectId;
    setImageManager(manager);
  };
  //---------------------------------------------------------------------------
  const onImageEdit = () => {
    //-------------------------------------------------------------------------
    if (!props.data || !imageManager)
      return;
    //-------------------------------------------------------------------------
    setIsLoading(true);
    let controller = abortController.newController("editImage");
    imageManager?.editImage(
      props.data,
      TImageUsage.newLogo(props.objectId, false),
      controller.signal)
      .catch(error => {
        console.error(`BusinessLogoEditor.editImage[${imageManager?.imageToEdit?.id}]:`, error);
      })
      .finally(() => {
        !controller.aborted && setIsLoading(false);
        controller.remove();
      });
  };
  //---------------------------------------------------------------------------
  const onImageSelect = () => {
    if (!props.objectId)
      return;
    //-------------------------------------------------------------------------
    setIsLoading(true);
    let controller = abortController.newController("loadUserImages");
    imageManager?.selectImage(
      instance,
      appAuthContext,
      props.data,
      controller.signal)
      .catch(error => {
        console.error("imageEditorContext.loadUserImages.catch:", error);
      })
      .finally(() => {
        console.error("imageEditorContext.loadUserImages.finally");
        !controller.aborted && setIsLoading(false);
        controller.remove();
      });
  };
  //---------------------------------------------------------------------------
  const imageContainerStyles: CSSProperties = {
    //backgroundImage: `linear-gradient(transparent, var(--clr_main_base)), url(${logoUri})`,
    backgroundImage: `url(${logoUri})`,
    //height: props.useExternalLayout ? '100%' : undefined,
    //aspectRatio: props.useExternalLayout ? undefined : '2/1'
    //flex: props.adBlock.widthPercent ? `1 1 ${props.adBlock.widthPercent - 5}%` : `1 1 30%`
    flex: `1 1 30%`
  };
  //---------------------------------------------------------------------------
  return (
    <InputWrapper
      caption={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      hideCaption={props.hideCaption}
      hint={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      hideHint={props.hideHint}
      readonly={false}
      disabled={false}
      validation={{ isValid: true, validationMessages: [] }}
    >
      <WikiSpinner show={isLoading} />

      {props.data &&
        <SmoothImageLoader
          onLoad={setLogoUri}
          sourceSize='Thumbnail'
          targetSize='Medium'
          imageData={props.data}
        />}

      <div className={styles.imageEditor}>
        <div
          className={CombineStyles([styles.image, props.data ? '' : styles.placeholder])}
          style={imageContainerStyles}
          onClick={props.data ? onImageEdit : undefined}
        >
          {!props.data &&
            <div className={styles.imagePlaceholder}>
              <ImageEditorPlaceholder onImageSelect={onImageSelect} />
            </div>}
        </div>
        <div className={styles.imageManager}>
          <ImageManager
            image={props.data}
            inLibrary={false}
            onManagerCreate={onImageManagerCreate}
            onImageEdit={onImageEdit}
            onImageSelect={onImageSelect}
            onImageUpdate={onImageUpdate}
          />
        </div>
      </div>
    </InputWrapper>
  );
}