import CombineStyles from "../../../../utils/combineStyles";
import { TUiMenuOption } from "../../menu/menu-content/menu-option/TUiMenuOption";
import { UndoIcon } from "./image-editor-icons/ImageEdtorIcons";
import styles from "./image-editor-icons/CustomIcon.module.css";

interface IProps {
  ui: TUiMenuOption;
  onSelect: () => void;
}

export default function UndoOption(props: IProps) {
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (!props.ui.disabled) {
      props.onSelect();
    };
  };
  //---------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    props.ui.disabled ? styles.disabled : ""
  ]);
  //--------------------------------------------------------------------------- 
  return (
    <div
      className={containerStyles}
      onClick={onSelect}
    >
      <UndoIcon />
    </div>
  );
}