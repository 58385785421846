import React from 'react';
import styles from './ApprovalRequestDetails.module.css';
import { IApprovalRequestBase } from '../approval-request-form/TApprovalRequest';
import CombineStyles from '../../../../utils/combineStyles';
import { IUserReference } from '../../common/workflows/IUserReference';

interface IProps {
  data: IApprovalRequestBase;
  assignedTo?: IUserReference;
}

export default function ApprovalRequestDetails(props: IProps) {
  //---------------------------------------------------------------------------
  const getFormattedDatetime = (datetime: string) => {
    return (new Date(datetime).toLocaleString());
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <div className={styles.detailsItem}>
        <span className={styles.caption}>
          Created at:
        </span>
        <span className={styles.data}>
          {getFormattedDatetime(props.data.dateTimeCreated)}
        </span>
      </div>

      {props.data.dateTimeResolved &&
        <div className={styles.detailsItem}>
          <span className={styles.caption}>
            {props.data.dateTimeResolved ?
              "Resolved at" :
              "Created at"}
            :
          </span>
          <span className={styles.data}>
            {props.data.dateTimeResolved ?
              getFormattedDatetime(props.data.dateTimeResolved) :
              getFormattedDatetime(props.data.dateTimeCreated)}
          </span>
        </div>}

      <div className={styles.detailsItem}>
        <span className={styles.caption}>
          {props.data.dateTimeResolved ?
            "Resolved by" :
            "Assigned to"}
          :&nbsp;
        </span>
        {props.assignedTo ?
          <span className={styles.data}>
            {props.assignedTo.displayName}
          </span>
          :
          <span className={CombineStyles([styles.data, styles.attention])}>
            Not assigned
          </span>}
      </div>

    </React.Fragment>
  );
}