import { IPublicClientApplication } from "@azure/msal-browser";
import { IAppAuthContext, IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { EImageLoadingState, TImageData } from "../TImageData";
import { StateTest, ClassImageEditorContext } from "./ImageEditorContextProvider";
import { ImageEditorSession } from "./ImageEditorSession";
import { TImageLibraryItem } from "../../../user-console/user-images/image-library/TImageLibrary";

export enum EImageEditorContextUpdate {
  None = 0,
  SessionAdded = 1,
  ImageEditorOpened = 2,
  LoadingStateChanged = 3,
  ImageTransformed = 4,
  DescriptionChanged = 5,
  UrlAndBlobReady = 6,
  ImageUploadEnd = 7,
  ImageRemoved = 8,
  SessionActivated = 9,
};
//--------------------------------------------------------------------------------
interface INewImageEditorSession {
  type: "NewSession";
  session: ImageEditorSession;
}
//--------------------------------------------------------------------------------
interface IImageEditorOpened {
  type: "ImageEditorOpened";
  session: ImageEditorSession;
}
//--------------------------------------------------------------------------------
interface IImageEditorSessionUpdate {
  type: "UpdateSession";
  update: EImageEditorContextUpdate;
  session: ImageEditorSession;
}
//--------------------------------------------------------------------------------
interface IImageEditorSessionActivate {
  type: "ActivateSession";
  session: ImageEditorSession;
}
//--------------------------------------------------------------------------------
interface IUploadImagesForObjectId {
  type: "UploadImagesForObjectId";
  objectId: string;
  appAuthContext: IAppAuthContext,
  instance: IPublicClientApplication,
  removeSessions: boolean;
}
//--------------------------------------------------------------------------------
interface IImageEditorSessionRemove {
  type: "RemoveSession";
  session: ImageEditorSession;
}
//--------------------------------------------------------------------------------
interface IImageEditorRemoveSessions {
  type: "RemoveSessions";
  objectId: string;
}
//--------------------------------------------------------------------------------
interface IImageEditorStateTest {
  type: "StateTest";
  value: number;
}
//--------------------------------------------------------------------------------
interface IImageEditorImageUploadEnd {
  type: "ImageUploadEnd";
  session: ImageEditorSession;
}
//--------------------------------------------------------------------------------
interface IClearSessions {
  type: "ClearSessions";
}
//--------------------------------------------------------------------------------
interface IUserImagesLoaded {
  type: "UserImagesLoaded";
  images: TImageLibraryItem[];
}
//--------------------------------------------------------------------------------

export type Actions =
  INewImageEditorSession |
  IImageEditorOpened |
  IImageEditorSessionUpdate |
  IImageEditorSessionActivate |
  IUploadImagesForObjectId |
  IImageEditorSessionRemove |
  IImageEditorRemoveSessions |
  IImageEditorStateTest |
  IImageEditorImageUploadEnd |
  IClearSessions |
  IUserImagesLoaded;

export function ImageEditorContextReducer(state: ClassImageEditorContext, action: Actions) {

  let nextState: ClassImageEditorContext = state.clone();
  nextState.lastAction = action;
  switch (action.type) {
    //------------------------------------------------------------------------
    case "NewSession": {
      nextState.addSession(action.session);
      nextState.lastChangedSessionUpdate = EImageEditorContextUpdate.SessionAdded;
      nextState.lastChangedSession = action.session;
    } break;
    //------------------------------------------------------------------------
    case "ImageEditorOpened": {
      nextState.lastChangedSessionUpdate = EImageEditorContextUpdate.ImageEditorOpened;
      nextState.lastChangedSession = action.session;
    } break;
    //------------------------------------------------------------------------
    case "UpdateSession": {
      nextState.lastChangedSessionUpdate = action.update;
      nextState.lastChangedSession = action.session;
      switch (action.update) {
        case EImageEditorContextUpdate.LoadingStateChanged:
          break;
      }
    } break;
    //------------------------------------------------------------------------
    case "ActivateSession": {
      nextState.lastChangedSessionUpdate = EImageEditorContextUpdate.SessionActivated;
      nextState.lastChangedSession = action.session;
    } break;
    //------------------------------------------------------------------------
    case "RemoveSession": {
      let index = nextState.sessions.indexOf(action.session);
      if (index >= 0 && index < nextState.sessions.length) {
        nextState.sessions.splice(index, 1);
        if (nextState.lastChangedSession == action.session)
          nextState.lastChangedSession = undefined;
        nextState.lastChangedSessionUpdate = EImageEditorContextUpdate.None;
      }
    } break;
    //------------------------------------------------------------------------
    case "RemoveSessions": {
      nextState.removeAllSessionsFor(action.objectId);
    } break;
    //------------------------------------------------------------------------
    case "StateTest": {
      let n = nextState.stateTest.prop;
      nextState.stateTest = new StateTest();
      nextState.stateTest.prop = n;
    } break;
    //------------------------------------------------------------------------
    case "ImageUploadEnd": {
      nextState.lastChangedSession = action.session;
      nextState.lastChangedSessionUpdate = EImageEditorContextUpdate.ImageUploadEnd;
    } break;
    //------------------------------------------------------------------------
    //ImageEditorContextClear
    case "ClearSessions": {
      nextState.sessions = [];
    } break;
    //------------------------------------------------------------------------
    case "UserImagesLoaded": {
      nextState.userImages = action.images;
    } break;
  }
  //--------------------------------------------------------------------------
  return nextState;
}
