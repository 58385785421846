import React, { CSSProperties, ReactNode, useState } from 'react';
import CombineStyles from '../../../../../../utils/combineStyles';
import Icon from '../../../../../common/icon/Icon';
import Toggler from '../../../../../common/toggler/Toggler';
import { DictionaryEditorDraftItem } from '../models/DictionaryEditorDraftItem';
import styles from './DictionaryNodeWrapper.module.css';
import { IDictionaryItemStatus } from '../../../../../common/dictionaries/IUiDictionaryItem';
import DictionaryNodeStatus from './dictionary-node-status/DictionaryNodeStatus';
import DictionaryNodeBadges from './dictionary-node-badges/DictionaryNodeBadges';
import { IDictionaryDraftItemStateGroup } from '../settings/IDictionaryDraftItemStateGroup';
import { IUiDictionaryEditorNode } from './IUiDictionaryEditorNode';
import PopupMenu from '../../../../../common/popup-v2/popup-menu/PopupMenu';
import { getTUiMenuContent } from '../../../../../common/menu/menu-content/TUiMenuContent';

interface IProps {
  ui: IUiDictionaryEditorNode;
  stateGroups?: IDictionaryDraftItemStateGroup[];
  data: DictionaryEditorDraftItem;
  status?: IDictionaryItemStatus; // Move to DictionaryEditorDraftItem
  children?: ReactNode;
  editModeIsEnabled?: boolean;
  isHierarchical?: boolean;
  isCollapsed?: boolean;
  showStatus?: boolean; // Temporary solution to show status in the editor node until it's applied to all dictionaries
  onToggle?: () => void;
  onToggleMode: () => void;
  onOptionSelect?: (selectedOptionId: string) => void;
}

export default function DictionaryNodeWrapper(props: IProps) {
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const contextMenuDivId = `DictionaryNodeOptions_${props.data.id}`;
  const isHierarchical = props.isHierarchical == undefined ? false : props.isHierarchical;
  //---------------------------------------------------------------------------
  const onToggle = () => {
    props.onToggle && props.onToggle();
  };
  //---------------------------------------------------------------------------
  const onContextMenuClose = () => {
    setContextMenuOpen(false);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    setContextMenuOpen(false);
    props.onOptionSelect && props.onOptionSelect(selectedOptionId);
  };
  //---------------------------------------------------------------------------
  const contextMenuContent = getTUiMenuContent(props.ui.menuContent);
  //--------------------------------------------------------------------------- Determine styles
  const calculatedStyles: CSSProperties = { color: 'inherit' };
  props.stateGroups?.forEach(stateGroup => {
    stateGroup.states?.forEach(state => {
      if (state.draftState === (props.data.draftState & state.draftState)) {
        calculatedStyles.color = state.fontColor ? state.fontColor : stateGroup.color;
        calculatedStyles.fontWeight = state.fontWeight;
        calculatedStyles.textDecoration = state.textDecoration;
      };
    });
  });
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.row,
        props.editModeIsEnabled ? styles.editable : ''
      ])}>

      {/* 1) Id, toggler and tabulations for sub-items */}
      <div className={CombineStyles([styles.cell, styles.firstColumn])}>
        {isHierarchical &&
          <div
            className={styles.toggler}
            onClick={onToggle}
          >
            <Toggler collapsed={!!props.isCollapsed} />
          </div>}
        <div
          className={styles.caption}
          style={calculatedStyles}
          onClick={props.onToggleMode}
        >
          {props.data.id}
        </div>
      </div>

      {/* 2) Name */}
      <div
        className={CombineStyles([styles.cell, styles.caption])}
        style={calculatedStyles}
        onClick={props.onToggleMode}>
        {props.data.name}
      </div>

      {/* 3) Additional attributes */}
      {props.children &&
        <div className={CombineStyles([
          styles.cell,
          styles.additionalAttributes
        ])}
          onClick={props.onToggleMode}>
          {props.children}
        </div>}

      {/* 4) Dictionary Item Status */}
      {props.showStatus &&
        <div
          className={CombineStyles([styles.cell, styles.caption])}
          onClick={props.onToggleMode}>
          <DictionaryNodeStatus
            data={props.status}
            newUi={true}
          />
        </div>}

      {/* 5) Editor Status badges (indicators) */}
      <div
        className={CombineStyles([styles.cell, styles.badges])}
        onClick={props.onToggleMode}>
        <DictionaryNodeBadges
          stateGroups={props.stateGroups}
          draftState={props.data.draftState}
          newUi={true}
        />
      </div>

      {/* 6) Context Menu */}
      {props.editModeIsEnabled &&
        <div
          id={contextMenuDivId}
          className={CombineStyles([
            styles.cell,
            styles.contextMenu
          ])}
          onClick={() => setContextMenuOpen(true)}
        >
          <Icon imageName="kebabButton" />
        </div>}

      {contextMenuOpen &&
        <PopupMenu
          anchorId={contextMenuDivId}
          ui={contextMenuContent}
          displayIcon={true}
          onSelect={onOptionSelect}
          onClose={onContextMenuClose}
        />}

    </div>
  );
}