import Icon from '../../../../../common/icon/Icon';
import styles from './DirectoryNodeBadge.module.css';

interface IProps {
  iconId: string;
  color?: string;
  title?: string;
}

export default function DirectoryNodeBadge(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div
      title={props.title}
      className={styles.container}
      style={{ color: props.color }}
    >
      <Icon imageName={props.iconId} />
    </div>
  );
}