interface IProps {
  collapsed: boolean;
  horizontal?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
}

export default function Toggler(props: IProps) {
  const transformVertical = props.collapsed ? "scale(1,-1) translate(0,-512)" : undefined;
  //---------------------------------------------------------------------------
  if (props.horizontal) return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="0.63em" height="1em"
      viewBox="0 0 320 512">
      <path
        fill="currentColor"
        d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256L73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
      />
    </svg>
  ); else return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="0.88em" height="1em"
      onClick={props.collapsed ? props.onExpand : props.onCollapse}
      viewBox="0 0 448 512">
      <path
        fill="currentColor"
        d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3L86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"
        transform={transformVertical}
      />
    </svg>
  );
}