import { ReactNode, useContext } from 'react';
import { IUiListColumnsManager } from '../../../../../context/list-manager-context/IUiListColumnsManager';
import { IListTotals } from '../ListColumnsManager';
import styles from './ListColumnsManagerHeader.module.css';
import CombineStyles from '../../../../../utils/combineStyles';
import IconKebab from '../../../icons/IconKebab';
import IconSort from '../../icons/IconSort';
import IconFilter from '../../icons/IconFilter';
import IconList from '../../icons/IconList';
import IconGrid from '../../icons/IconGrid';
import IconReset from '../../icons/IconReset';
import { ListManagerContext } from '../../../../../context/list-manager-context/ListManagerContextProvider';
import { EListMode, EManagerMode } from '../../../../../context/list-manager-context/IListManagerContextData';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import GetCaption from '../../../functions/GetCaption';
import IconSortSimplified from '../../icons/IconSortSimplified';

interface IProps {
  totals: IListTotals;
  expandedOptionId?: string;
  showBasicModeSwitch?: boolean;
  onOptionSelect: (optionId: string) => void;
}

export default function ListColumnsManagerHeader(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { listManagerContext } = useContext(ListManagerContext);
  //---------------------------------------------------------------------------
  // Create list of header items
  let itemContextMenu: ReactNode = undefined;
  let itemSort: ReactNode = undefined;
  let itemFilter: ReactNode = undefined;
  let itemReset: ReactNode = undefined;
  let itemListModeOptions: ReactNode = undefined;
  let listModeOptions: ReactNode[] = [];
  let itemSimplifiedMode: ReactNode = undefined;
  let isSelected = false;
  listManagerContext.availableOptions.forEach(option => {
    switch (option.id) {
      case "OptionContextMenu":
        itemContextMenu =
          <div
            key={option.id}
            className={styles.headerItem}>
            <div
              title={GetCaption(appUiContext, option.id, option.caption)}
              className={styles.headerOption}
              onClick={() => props.onOptionSelect(option.id)}
            >
              <IconKebab />
            </div>
          </div>;
        break;
      case "OptionSort":
        itemSort =
          <div
            key={option.id}
            className={styles.headerItem}
          >
            <div
              title={GetCaption(appUiContext, option.id, option.caption)}
              className={styles.headerOption}
              onClick={() => props.onOptionSelect(option.id)}
            >
              <IconSort expanded={false} />
            </div>
          </div>;
        break;
      case "OptionSortPresets":
        itemSort =
          <div
            key={option.id}
            className={styles.headerItem}
          >
            <div
              title={GetCaption(appUiContext, option.id, option.caption)}
              className={CombineStyles([
                styles.headerOption,
                styles.link
              ])}
              onClick={() => props.onOptionSelect(option.id)}
            >
              <IconSortSimplified />
              {listManagerContext.sortPresets?.options.find(o => o.id == listManagerContext.sortPresets?.currentOptionId)?.name}
            </div>
          </div>;
        break;
      case "OptionFilter":
        itemFilter =
          <div
            key={option.id}
            className={CombineStyles([
              styles.headerItem,
              props.expandedOptionId === option.id ? styles.expanded : ""
            ])}
          >
            <div
              title={GetCaption(appUiContext, option.id, option.caption)}
              className={styles.headerOption}
              onClick={() => props.onOptionSelect(option.id)}
            >
              <IconFilter expanded={false} />
            </div>
          </div>;
        break;
      case "OptionReset":
        itemReset =
          <div
            key={option.id}
            className={styles.headerItem}>
            <div
              title={GetCaption(appUiContext, option.id, option.caption)}
              className={styles.headerOption}
              onClick={() => props.onOptionSelect(option.id)}
            >
              <IconReset />
            </div>
          </div>;
        break;
      case "OptionListModeList":
        isSelected = listManagerContext.currentListMode === option.id as EListMode;
        listModeOptions.push(
          <div
            key={option.id}
            title={GetCaption(appUiContext, option.id, option.caption)}
            className={CombineStyles([
              styles.headerOption,
              isSelected ? styles.selected : ""
            ])}
            // Handle select only if it's not currently selected
            onClick={isSelected ? undefined : () => props.onOptionSelect(option.id)}
          >
            <IconList />
          </div>
        );
        break;
      case "OptionListModeGrid":
        isSelected = listManagerContext.currentListMode === option.id as EListMode;
        listModeOptions.push(
          <div
            key={option.id}
            title={GetCaption(appUiContext, option.id, option.caption)}
            className={CombineStyles([
              styles.headerOption,
              isSelected ? styles.selected : ""
            ])}
            // Handle select only if it's not currently selected
            onClick={isSelected ? undefined : () => props.onOptionSelect(option.id)}
          >
            <IconGrid />
          </div>
        );
        break;
      case "OptionSetSimplifiedMode":
        /* if (props.showBasicModeSwitch) {
          itemSimplifiedMode =
            <div
              key={option.id}
              className={styles.headerItem}>
              <div
                //title={GetCaption(appUiContext, option.id, option.caption)}
                className={styles.headerOption}
                style={{
                  width: "max-content",
                  color: "var(--clr_accent_main)",
                  textDecoration: "underline"
                }}
                onClick={() => props.onOptionSelect(option.id)}
              >
                {GetCaption(appUiContext, option.id, option.caption)}
              </div>
            </div>;
        }; */
        break;
    };
  });
  if (listModeOptions.length > 0) {
    itemListModeOptions =
      <div
        key="listModeOptions"
        className={styles.headerItem}>
        {listModeOptions}
      </div>;
  };
  //---------------------------------------------------------------------------
  // Totals
  const itemTotals: ReactNode =
    <div
      key="totals"
      className={styles.headerItem}>
      <div className={styles.totals}>
        <span className={styles.system}>
          Total:&nbsp;
        </span>
        <span className={props.totals.total != props.totals.filtered ? styles.highlight : ""}>
          {props.totals.filtered ? props.totals.filtered : 0}/{props.totals.total ? props.totals.total : 0}
        </span>
      </div>
    </div>;
  //---------------------------------------------------------------------------
  // Arrange header items depending on expanded option
  const headerItems: ReactNode[] = [];
  const headerItemsLeft: ReactNode[] = [];
  let headerItemExpanded: ReactNode = undefined;
  const headerItemsRight: ReactNode[] = [];
  switch (props.expandedOptionId) {
    case "OptionContextMenu":
      headerItemExpanded = itemContextMenu;
      headerItemsRight.push(itemSort);
      headerItemsRight.push(itemFilter);
      headerItemsRight.push(itemListModeOptions);
      headerItemsRight.push(itemReset);
      headerItemsRight.push(itemSimplifiedMode);
      headerItemsRight.push(itemTotals);
      break;
    //-------------------------------------------------------------------------
    case "OptionSort":
    case "OptionSortPresets":
      headerItemsLeft.push(itemContextMenu);
      headerItemExpanded = itemSort;
      headerItemsRight.push(itemFilter);
      headerItemsRight.push(itemListModeOptions);
      headerItemsRight.push(itemReset);
      headerItemsRight.push(itemSimplifiedMode);
      headerItemsRight.push(itemTotals);
      break;
    //-------------------------------------------------------------------------
    case "OptionFilter":
      headerItemsLeft.push(itemContextMenu);
      headerItemsLeft.push(itemSort);
      headerItemExpanded = itemFilter;
      headerItemsRight.push(itemListModeOptions);
      headerItemsRight.push(itemReset);
      headerItemsRight.push(itemSimplifiedMode);
      headerItemsRight.push(itemTotals);
      break;
    //-------------------------------------------------------------------------
    default:
      // No expanded options, so each option is displayed as a separate item
      headerItems.push(itemContextMenu);
      headerItems.push(itemSort);
      headerItems.push(itemFilter);
      headerItems.push(itemListModeOptions);
      headerItems.push(itemReset);
      headerItems.push(itemSimplifiedMode);
      headerItems.push(itemTotals);
      break;
  };
  //---------------------------------------------------------------------------
  if (props.expandedOptionId) return (
    <div className={CombineStyles([
      styles.container,
      styles.expanded
    ])}>
      {headerItemsLeft.length > 0 &&
        <div className={CombineStyles([styles.group, styles.left])}>
          {headerItemsLeft}
        </div>}
      <div className={styles.itemExpanded}>
        {headerItemExpanded}
      </div>
      <div className={CombineStyles([styles.group, styles.right])}>
        {headerItemsRight}
      </div>
    </div>
  ); else return (
    <div className={styles.container}>
      {headerItems}
    </div>
  );
}