import { apiPost, checkResponse } from "../../../utils/api";
import { apiBasePath, getAdForHome, getAdForHomeWithCategory } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
import { IAdModule } from "../../common/ad-modules/IAdModule";
import { ClassAdModulePublished } from "../ClassAdLanguageSetPublished";

interface IResponse {
  ads: ClassAdModulePublished[];
  continuationToken?: string;
}

interface IProps {
  localeId: string;
  category?: string;
  numberOfAds: number;
  continuationToken?: string;
}

export async function getAdsAsync(props: IProps): Promise<IResponse> {
  const apiUri = props.category ?
    stringFormatter(getAdForHomeWithCategory, [props.localeId, props.category, props.numberOfAds.toString()])
    :
    stringFormatter(getAdForHome, [props.localeId, props.numberOfAds.toString()]);
  //---------------------------------------------------------------------------
  const response = await apiPost(
    `${apiBasePath}${apiUri}`,
    props.continuationToken,
    undefined);
  //---------------------------------------------------------------------------, props.abortSignal?.aborted
  checkResponse(response, "getAdLanguageSetAsync");
  const ads = response.content.ads as IAdModule[];
  const result = ads?.map(ad => new ClassAdModulePublished(ad));
  return {
    ads: result,
    continuationToken: response.content.continuationToken
  };
}