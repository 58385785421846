import { IPublicClientApplication } from "@azure/msal-browser";
import { IAppAuthContext } from "../../app-auth-context/AppAuthContext";
import { IApiResponse, apiPostPrivate, checkResponse } from "../../../utils/api";
import { apiBasePath, pathDeactivateAdLanguageSetSystem, pathAdLanguageSetDeactivate } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
import { AdLanguageSet, IAdLanguageSet, IAdLanguageSetListItem } from "../../../components/ad-content-editor/IAdLanguageSet";
import { IBusiness } from "../IBusiness";

interface IProps {
  msalInstance: IPublicClientApplication;
  authContext?: IAppAuthContext;
  business?: IBusiness;  
  adCampaignId?: string;
  adLanguageSetId?: string;
  abortSignal?: AbortSignal;
  respondWith: 'object' | 'list';
}

export async function deactivateAdLanguageSetAsync(props: IProps): Promise<AdLanguageSet | IAdLanguageSetListItem[]> {
  //---------------------------------------------------------------------------
  if (!props.msalInstance) throw new Error("deactivateAdLanguageSetAsync: MSAL instance is not provided");
  if (!props.authContext) throw new Error("deactivateAdLanguageSetAsync: auth context is not provided");
  if (!props.business) throw new Error("deactivateAdLanguageSetAsync: business is not provided");
  if (!props.adLanguageSetId) throw new Error("deactivateAdLanguageSetAsync: Ad Language Set Id is not provided");
  if (!props.adCampaignId) throw new Error("deactivateAdLanguageSetAsync: Ad campaign Id is not provided");
  //---------------------------------------------------------------------------
  const authConfig = props.authContext.config;
  if (!authConfig) throw new Error("deactivateAdLanguageSetAsync: Auth context is not set");
  const businessType = props.business.businessType;
  const businessId = props.business.id;
  if (!businessId) throw new Error("deactivateAdLanguageSetAsync: Business context is not set");
  //---------------------------------------------------------------------------
  // Send ad language set for approval
  const isSystem = businessType !== "Business";
  let response: IApiResponse | void = undefined;
  const apiUri = isSystem ? pathDeactivateAdLanguageSetSystem : pathAdLanguageSetDeactivate;
  response = await apiPostPrivate(
    props.msalInstance,
    props.authContext.config,
    `${apiBasePath}${stringFormatter(apiUri, [businessId, props.adCampaignId, props.adLanguageSetId, props.respondWith])}`,
    undefined,
    undefined,
    props.abortSignal
  );
  checkResponse(response, "deactivateAdLanguageSetAsync", props.abortSignal?.aborted);
  //---------------------------------------------------------------------------
  // Return response
  if (props.respondWith == 'object') {
    const updatedAdLanguageSet = new AdLanguageSet(response?.content as IAdLanguageSet);
    return (updatedAdLanguageSet);
  } else {
    const updatedAdLanguageSetList = response?.content as IAdLanguageSetListItem[];
    return (updatedAdLanguageSetList);
  };
}