// https://svgomg.net/

//import React from "react";

interface IProps {
}

export default function NameAndSlogan(props: IProps) {
  var accentMainColour = "var(--clr_accent_main)";
  //---------------------------------------------------------------------------
  return (
    <svg
      height="100%"
      viewBox="0 0 47.159 12.363"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fontWeight="400"
        fontFamily="sans-serif"
        letterSpacing="0"
        wordSpacing="0"
        //stroke="red" stroke-width="0"
        fill={accentMainColour}>

        <text
          transform="matrix(.05761 0 0 .05547 -6.933 -51.29)"
          xmlSpace="preserve"
          x="104.303" y="1081.373"
          fontSize="30.68">
          <tspan
            x="104.303" y="1081.373"
            fontWeight="700"
            fontSize="218.948"
            fontFamily="Arial">
            RikiWiki
          </tspan>
        </text>

        <text
          xmlSpace="preserve"
          x="117.488" y="1126.97"
          fontSize="12"
          transform="matrix(.05712 0 0 .05595 -6.933 -51.29)">
          <tspan
            x="117.488" y="1126.97"
            fontWeight="700"
            fontSize="50.8"
            fontFamily="Arial">
            Life Is a Journey Not a Destination
          </tspan>
        </text>

      </g>
    </svg>
  );
}