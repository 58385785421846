import React, { ReactNode, useEffect, useState } from 'react';
import styles from './ChecklistItemWrapper.module.css';
import { ClassAdUnitStatus, IAdUnitStatus } from '../../../../common/ad-modules/IAdUnit';
import ChecklistItemReject from '../checklist-item-reject/ChecklistItemReject';
import { IRequestRejectionReason } from '../../approval-request-checklist/IApprovalRequestUnit';
import CombineStyles from '../../../../../utils/combineStyles';
import Toggler from '../../../../common/toggler/Toggler';
import IconCheck from '../../../../common/icons/IconCheck';
import IconBan from '../../../../common/icons/IconBan';
import { EContentUnitType } from '../../../administrator-console/dictionaries/rejection-reasons/DictionaryDraftItemRejectionReasons';

const getTabulationElements = (level?: number) => {
  const tabCount = level ?? 0;
  return [...Array(tabCount)].map((_, index) => <div key={index}></div>)
};

interface IProps {
  unitType: EContentUnitType;
  status: IAdUnitStatus;
  level?: number; // Instead of indent
  caption?: string;
  children?: ReactNode; // NOTE: must consist of one or more <td> elements
  isGroup?: boolean;
  isCollapsed?: boolean;
  isReadonly: boolean;
  onToggle?: () => void;
  onHover: (isHovered: boolean) => void;
  onReview?: (updatedStatus: IAdUnitStatus) => void;
}

export default function ChecklistItemWrapper(props: IProps) {
  const [rejected, setRejected] = useState<boolean>();
  const level = props.level ?? 0;
  //--------------------------------------------------------------------------- State
  useEffect(() => {
    if (props.isGroup) return; // this for not showing rejection form for group items
    if (props.status?.statusId == 'Rejected')
      setRejected(true);
    else if (props.status?.statusId == 'Approved')
      setRejected(false);
  }, [props.status]);
  //---------------------------------------------------------------------------
  const onMouseEnter = () => {
    props.onHover(true);
  };
  //---------------------------------------------------------------------------
  const onMouseLeave = () => {
    props.onHover(false);
  };
  //---------------------------------------------------------------------------
  const onStatusChange = (reject: boolean) => {
    if (props.isReadonly) return;
    // if the same button clicked again - do nothing
    if (rejected != undefined && rejected == reject) return;
    if (!reject) {
      // Pass updates
      setRejected(false);
      const updatedStatus: IAdUnitStatus = {
        ...props.status,
        statusId: 'Approved',
        isApproved: true,
        isInTrouble: false,
        //rejectionReason: undefined
      };
      props.onReview?.(updatedStatus);
    } else {
      // Open rejection reason edit form
      setRejected(true);
      //-------------------------------------------------------------------------
      // If reason Id is already provided (meaning it's a complex issue with pre-defined reason)
      if (props.status.rejectionReason?.reasonId && props.status.statusId !== "Rejected") {
        // Run onReject callback
        const updatedStatus: IAdUnitStatus = {
          ...props.status,
          statusId: 'Rejected',
          isApproved: false,
          isInTrouble: true
        };
        props.onReview?.(updatedStatus);
      }
    };
  };
  //---------------------------------------------------------------------------
  const onRejectCancel = () => {
    if (!props.status.rejectionReason) {
      // If user closes rejection form without entering reason
      // Mark item as draft
      setRejected(false);
      const updatedStatus: IAdUnitStatus = {
        ...props.status,
        statusId: 'Draft',
        isApproved: false,
        isInTrouble: false,
        rejectionReason: undefined
      };
      props.onReview?.(updatedStatus);
    };
  };
  //---------------------------------------------------------------------------
  const onReject = (reason: IRequestRejectionReason) => {
    setRejected(false);
    const updatedStatus = new ClassAdUnitStatus('Rejected', reason);
    props.onReview?.(updatedStatus);
  };
  //---------------------------------------------------------------------------
  if (props.isGroup) return (
    <div
      className={CombineStyles([styles.container, styles.group])}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={props.onToggle}>

      {/* Toggler */}
      <div
        className={CombineStyles([
          styles.gridCell,
          styles.icon
        ])}
        style={{
          gridColumn: `${level + 1} / ${level + 2}`
        }}
      >
        <Toggler collapsed={!!props.isCollapsed} />
      </div>

      {/* Group caption */}
      <div
        className={CombineStyles([
          styles.gridCell,
          styles.text
        ])}
        style={{
          gridColumn: `${level + 2} / 5`
        }}>
        {props.caption}
      </div>

      {/* Approve icon readonly */}
      <div
        className={CombineStyles([
          styles.gridCell,
          styles.icon,
          props.status.isApproved ? styles.approved : styles.disabled
        ])}>
        <IconCheck />
      </div>

      {/* Reject icon readonly */}
      <div
        className={CombineStyles([
          styles.gridCell,
          styles.icon,
          props.status.isInTrouble ? styles.troubled : styles.disabled
        ])}>
        <IconBan />
      </div>

    </div>
  ); else return (
    <div
      className={styles.container}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>

      <div className={CombineStyles([
        styles.wrapper,
        rejected == true ? styles.rejected : ''
      ])}>

        {/* Item caption */}
        {props.caption &&
          <div
            className={CombineStyles([
              styles.gridCell,
              styles.text,
              styles.itemCaption
            ])}
            style={{
              gridColumn: props.children ? `${level + 1} / 4` : `${level + 1} / 5`, // if there is no children, span the cell from {level} up to options div 
              color: props.children ? '' : 'inherit' // If it's a complex issue caption, make it usual color
            }}>
            {props.caption}
          </div>}

        {/* Item content */}
        {props.children &&
          <div
            className={CombineStyles([
              styles.gridCell,
              styles.itemContent
            ])}
            style={{
              gridColumn: props.caption ? undefined : `${level + 1} / 5`
            }}>
            {props.children}
          </div>}

        {/* Approve icon */}
        <div
          className={CombineStyles([
            styles.gridCell,
            styles.icon,
            props.isReadonly ? styles.readonly : '',
            props.status.isApproved ? styles.approved :
              props.isReadonly ? styles.disabled : ''
          ])}
          onClick={() => onStatusChange(false)}>
          <IconCheck />
        </div>

        {/* Reject icon */}
        <div
          className={CombineStyles([
            styles.gridCell,
            styles.icon,
            props.isReadonly ? styles.readonly : '',
            rejected == true ? styles.troubled :
              props.isReadonly ? styles.disabled : ''
          ])}
          onClick={() => onStatusChange(true)}>
          <IconBan />
        </div>

      </div>

      {/* Rejection reason details */}
      {rejected == true &&
        <React.Fragment>
          <div
            className={styles.rejectWrapper}
            style={{
              gridColumn: `${level + 1} / 7`
            }}>
            <ChecklistItemReject
              unitType={props.unitType}
              data={props.status.rejectionReason}
              isReadonly={props.isReadonly}
              onReject={onReject}
              onCancel={onRejectCancel}
            />
          </div>
        </React.Fragment>}

    </div>
  );
}