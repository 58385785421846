import styles from './RegionalHomepagesList.module.css';
import { useContext, useEffect, useState } from 'react';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import { useAbortController } from '../../../../hooks/useAbortController';
import { IUiRegionalHomepagesList } from './IUiRegionalHomepagesList';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import { IUiOption } from '../../../common/options/IUiOption';
import { TUiBreadcrumb } from '../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { optionIdRegionalHomepages, pathAdminConsole, pathAdministratorConsole, pathRegionalHomepages } from '../../AdminConsole';
import { getRegionalHomepageBusinesses } from './getRegionalHomepageBusinesses';
import { IRegionalHomepagesListItem } from './regional-homepages-list-item/IRegionalHomepagesListItem';
import RegionalHomepagesListItem from './regional-homepages-list-item/RegionalHomepagesListItem';
import useNavigateWithContext from '../../../../hooks/useNavigateWithContext';
import { generatePath, useLocation } from 'react-router-dom';
import { keywordNewRegionalHomepage } from '../regional-homepage-editor/RegionalHomepageEditor';
import { optionIdCMRegionalHomepages } from '../../content-manager-console/ContentManagerConsole';
import ConsoleContent from '../../../common/console-layout/console-content/ConsoleContent';
import GetHint from '../../../common/functions/GetHint';

interface IProps {
  role: "Administrator" | "ContentManager";
  ui?: IUiRegionalHomepagesList;
  optionsForBreadcrumb?: IUiOption[];
}

export default function RegionalHomepagesList(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const navigate = useNavigateWithContext();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [listItems, setListItems] = useState<IRegionalHomepagesListItem[]>();
  const abortController = useAbortController("RegionalHomepagesList");
  const role = location.pathname.replace(pathAdminConsole, "").replace(pathRegionalHomepages, "").replace(/\//g, "");
  const optionId = role == pathAdministratorConsole ? optionIdRegionalHomepages : optionIdCMRegionalHomepages;
  const userCanCreateBusiness = role == pathAdministratorConsole;
  //--------------------------------------------------------------------------- Get regions/regional homepages list
  useEffect(() => {
    setIsLoading(true);
    let controller = abortController.newController("getRegionalHomepageBusinesses");
    getRegionalHomepageBusinesses(
      instance,
      appAuthContext.config,
      controller.signal
    ).then(items => {
      setListItems(items);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    //-------------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionId);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        caption: option.caption,
        path: `/${pathAdminConsole}/${role}/${pathRegionalHomepages}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
  }, [props.optionsForBreadcrumb]);
  //---------------------------------------------------------------------------
  const onCreate = (regionId: string) => {
    if (!userCanCreateBusiness) return;
    onBusinessCreate(regionId);
  };
  //---------------------------------------------------------------------------
  const onBusinessSelect = (selectedBusinessId: string) => {
    // Navigate to regional homepage editor
    const path = generatePath(`${location.pathname}/:systemBusinessId`, { systemBusinessId: selectedBusinessId });
    navigate(path);
  };
  //---------------------------------------------------------------------------
  const onBusinessCreate = (regionId: string) => {
    const path = generatePath(`${pathAdminConsole}/${pathAdministratorConsole}/${pathRegionalHomepages}/:systemBusinessId`,
      { systemBusinessId: `${keywordNewRegionalHomepage}${regionId}` });
    navigate(path);
  };
  //---------------------------------------------------------------------------
  const listItemElements = listItems?.map(listItem => (
    <RegionalHomepagesListItem
      key={listItem.regionId}
      data={listItem}
      onSelect={onBusinessSelect}
      onCreate={userCanCreateBusiness ? onCreate : undefined}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={props.ui ? GetHint(appUiContext, props.ui.id, props.ui.hint) : undefined}
      containerWidthPercent={70}
    >
      <WikiSpinner show={isLoading} />
      <div className={styles.list}>
        {listItemElements}
      </div>
    </ConsoleContent>
  );
}