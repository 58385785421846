import { useContext, useEffect, useState } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { WizardState } from '../IWizardState';
import { IUiBusinessWizard } from '../IUiBusinessWizardV2';
import GetDescription from '../../../common/functions/GetDescription';
import WizardStepWrapper from '../step-wrapper/WizardStepWrapper';
import { IUiOption } from '../../../common/options/IUiOption';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import BusinessLocationEditor from '../../business-editor/section-location/business-location-editor/BusinessLocationEditor';
import { ClassBusinessLocationData, IBusinessLocationData, newId } from '../../business-editor/section-location/business-location-editor/IBusinessLocationData';
import styles from './StepLocation.module.css';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';

interface IProps {
  state: WizardState;
  ui: IUiBusinessWizard;
  onUpdate: (state: WizardState) => void;
  onExit: () => void;
}

export default function StepLocation(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { appAuthContext } = useContext(AppAuthContextStore);
  const [locationData, setLocationData] = useState<ClassBusinessLocationData>();
  const stepUi = props.ui.stepsDictionary.find(item => item.id == props.state.selectedStep);
  const stepData = props.state.steps.find(item => item.stepId == props.state.selectedStep);
  const prompt = GetDescription(appUiContext, props.state.selectedStep, stepUi?.description);
  const isUpdated = locationData !== props.state.data.locationData;
  const stepIsCompleted = stepData?.isCompleted ? true : false;
  const uncompletedLocations = locationData?.locations.find(item => item.id === newId);
  const isValid = locationData && locationData.locations.length > 0 && !uncompletedLocations;
  //---------------------------------------------------------------------------
  // Check if there is only one location and it's new
  const onlyLocationIsNew = uncompletedLocations && locationData?.locations.length == 1;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (props.state.data.locationData)
      setLocationData(props.state.data.locationData);
    else {
      // Add new empty location automatically
      const locationData = new ClassBusinessLocationData().addNewLocation(
        props.state.data.name,
        appAuthContext.userLocation?.position);
      setLocationData(locationData);
    };
  }, [props.state.data.locationData]);
  //---------------------------------------------------------------------------
  const onLocationDataUpdate = (updatedData: IBusinessLocationData<string>) => {
    if (!locationData) return;
    if (updatedData.locations.length == 0) {
      const updatedLocationData = new ClassBusinessLocationData(updatedData).addNewLocation(
        props.state.data.name,
        appAuthContext.userLocation?.position);
      setLocationData(updatedLocationData);
    } else
      setLocationData(new ClassBusinessLocationData(updatedData));
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    let updatedState: WizardState;
    switch (optionId) {
      case "OptionBack":
        updatedState = isUpdated && isValid ?
          props.state.updateLocationData(locationData, "Back") :
          updatedState = props.state.moveToPreviousStep();
        break;
      case "OptionNext":
        if (!isValid) return;
        updatedState = isUpdated ?
          props.state.updateLocationData(locationData, "Next") :
          props.state.moveToNextStep(!stepIsCompleted);
        break;
      default:
        throw new Error(`StepLocationData: Unknown optionId ${optionId}`);
    };
    props.onUpdate(updatedState);
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionBack":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowLeft",
          isDisabled: false
        });
        break;
      case "OptionNext":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowRight",
          isDisabled: !isValid,
          isDefault: isValid
        });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      alignLeft={true}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <WizardStepWrapper
      ui={props.ui}
      promptString={prompt}
      optionsElement={formOptions}
      onExit={props.onExit}
    >
      <div className={styles.container}>
        {locationData &&
          <BusinessLocationEditor
            ui={props.ui.inputLocationData}
            businessId={props.state.data.businessId}
            data={locationData}
            hideMapBoundsOption={true}
            locationIsRequired={onlyLocationIsNew}
            onUpdate={onLocationDataUpdate}
          />}
      </div>
    </WizardStepWrapper>
  );
}