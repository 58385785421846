import { useContext, useEffect, useState } from 'react';
import DictionaryEditorWrapper from '../../../common/dictionary-editor-wrapper/DictionaryEditorWrapper';
import { DictionaryEditorStateRejectionReasons, IDictionaryDraftRejectionReasons, IDictionaryEditorStateRejectionReasons } from './DictionaryEditorStateRejectionReasons';
import { DraftItemStateEnum } from '../../../common/dictionary-draft-models/DraftItemStateEnum';
import { useAbortController } from '../../../../../hooks/useAbortController';
import getDictionaryDraft from '../common/functions/getDictionaryDraft';
import { useMsal } from '@azure/msal-react';
import { EHttpStatusCode } from '../../../../../utils/HttpStatusCodes';
import { ILockable } from '../../../../common/locks/TLocker';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import { pathAdminConsole, pathAdministratorConsole } from '../../../AdminConsole';
import { pathDictionaries } from '../../AdministratorConsole';
import { dictonaryDraftStatesFullDetails } from '../common/settings/dictonaryDraftStatesFullDetails';
import { DictionaryDraftItemRejectionReasons } from './DictionaryDraftItemRejectionReasons';
import DictionaryNodeRejectionReasons from './dictionary-node/DictionaryNodeRejectionReasons';
import EditFormRejectionReason from './edit-form/EditFormRejectionReason';
import { IDictionaryDraftFilter } from '../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter';
import updateDictionaryDraft from '../common/functions/updateDictionaryDraft';
import deleteDictionaryDraft from '../common/functions/deleteDictionaryDraft';
import { EDictionaryType } from '../../../common/dictionary-draft-models/EDictionaryType';
import publishDictionaryDraft from '../common/functions/publishDictionaryDraft';

interface IProps {
}

export default function DictionaryEditorRejectionReasons(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const [state, setState] = useState<DictionaryEditorStateRejectionReasons>();
  const [isLoading, setIsLoading] = useState(true);
  const [newItem, setNewItem] = useState(false);
  const abortController = useAbortController("DictionaryEditorRejectionReasons");
  //--------------------------------------------------------------------------- Data, abortcontroller
  useEffect(() => {
    getDraft(false);
    //-------------------------------------------------------------------------
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //---------------------------------------------------------------------------
  const getDraft = (lockIsNeeded: boolean) => {
    //console.log("RejectionReasons dictionary editor: getting draft...")
    setIsLoading(true);
    let controller = abortController.newController("getDictionaryDraft");
    getDictionaryDraft(
      instance, "RequestRejectionReasons", lockIsNeeded, appAuthContext.config, controller.signal
    ).then(data => {
      let locker = (data as ILockable).locker;
      if (locker)
        locker.errorPrefix = `Rejection Reasons dictionary`;
      const dictionaryDraft = data as IDictionaryDraftRejectionReasons;
      const editorState = newDraftState(dictionaryDraft, !!(locker && locker.statusCode == EHttpStatusCode.OK));
      !controller.aborted && setState(editorState);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const saveDraft = () => {
    if (!state?.draft) {
      console.error("Cannot save dictonary draft: state is not set");
      return;
    };
    setIsLoading(true);
    let controller = abortController.newController("updateDictionaryDraftRejectionReasons");
    updateDictionaryDraft(
      instance,
      state.draft,
      appAuthContext.config
    ).then(dictionaryDraft => {
      !controller.aborted && setState(newDraftState(dictionaryDraft, true));
    }).catch(error =>
      console.error(error)
    ).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const deleteDraft = () => {
    if (!state?.draft) {
      console.error("Cannot delete dictonary draft: state is not set");
      return;
    };
    setIsLoading(true);
    let controller = abortController.newController("deleteDictionaryDraft");
    deleteDictionaryDraft(
      instance,
      state.draft.dictionaryId as EDictionaryType,
      appAuthContext.config
    ).then(dictionaryDraft => {
      !controller.aborted && setState(newDraftState(dictionaryDraft, false));
    }).catch(error => console.error(error)
    ).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const publishDraft = () => {
    if (!state?.draft) {
      console.error("Cannot publish dictonary draft: state is not set");
      return;
    };
    setIsLoading(true);
    let controller = abortController.newController("publishDictionaryDraft");
    publishDictionaryDraft(
      instance,
      state.draft.dictionaryId as EDictionaryType,
      appAuthContext.config
    ).then(dictionaryDraft => {
      !controller.aborted && setState(newDraftState(dictionaryDraft, false));
    }).catch(error => console.error(error)
    ).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const newDraftState = (dictionaryDraft: IDictionaryDraftRejectionReasons, editModeIsEnabled: boolean) => {
    let state = new DictionaryEditorStateRejectionReasons({
      basePath: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}`, // remove???
      numberOfApprovals: 2,
      filter: {
        draftState: DraftItemStateEnum.All,
        stateGroups: dictonaryDraftStatesFullDetails,
        selectedGroups: dictonaryDraftStatesFullDetails.map(g => g.id), // switch on all the options (groups)
        searchById: false
      },
      editModeIsEnabled: editModeIsEnabled,
      breadcrumb: {
        id: dictionaryDraft.dictionaryId,
        caption: { en: `${dictionaryDraft.name}` },
        path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}/${dictionaryDraft.dictionaryId}`
      },
      draft: dictionaryDraft,
      isUpdated: false,
      isUpdatedInSession: false,
      isValid: false,
      isReadyToPublish: false
    });
    return state;
  };
  //---------------------------------------------------------------------------
  const enableEditMode = () => {
    // Get draft this time with lock
    getDraft(true);
  };
  //---------------------------------------------------------------------------
  const disableEditMode = () => {
    setState(new DictionaryEditorStateRejectionReasons(
      state as IDictionaryEditorStateRejectionReasons, {
      draft: state?.draftSessionInitial,
      editModeIsEnabled: false
    }));
  };
  //---------------------------------------------------------------------------
  const onFilterUpdate = (updatedFilter: IDictionaryDraftFilter) => {
    setState(new DictionaryEditorStateRejectionReasons(
      state as IDictionaryEditorStateRejectionReasons,
      { filter: updatedFilter }
    ));
  };
  //---------------------------------------------------------------------------
  const onAddNewItem = () => {
    setNewItem(true);
  };
  //---------------------------------------------------------------------------
  const addNewItem = (newItem: DictionaryDraftItemRejectionReasons) => {
    if (state?.draft.items) {
      // Add new item to the list
      const updatedItems = state.draft.items.concat(newItem);
      const updatedState = new DictionaryEditorStateRejectionReasons(state, {
        draft: {
          ...state.draft,
          items: updatedItems
        }
      });
      setState(updatedState);
      setNewItem(false);
    } else {
      console.error("New element is being added, but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedDraftItem: DictionaryDraftItemRejectionReasons, oldId?: string) => {
    if (state?.draft.items) {
      // If oldId is provided, user updated newly added item id, so we need to find it by old id
      const idToSearch = oldId ? oldId : updatedDraftItem.id;
      const updatedItems = state.draft.items.map(item => item.id == idToSearch ? updatedDraftItem : item);
      const updatedState = new DictionaryEditorStateRejectionReasons(state, {
        draft: {
          ...state.draft,
          items: updatedItems
        }
      });
      setState(updatedState);
    } else {
      console.error("New element is being updated, but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onHardDeleteItem = (deletedItemId: string) => {
    if (state) {
      const updatedItems = state.draft.items?.filter(item => item.id !== deletedItemId);
      const updatedState = new DictionaryEditorStateRejectionReasons(state, {
        draft: {
          ...state.draft,
          items: updatedItems
        }
      });
      setState(updatedState);
    } else {
      console.error("New element is being deleted, but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "optionDisableEditMode":
        disableEditMode();
        break;
      case "optionSave":
        saveDraft();
        break;
      case "optionDraftDelete":
        deleteDraft();
        break;
      case "optionPublish":
        publishDraft();
        break;
      case "optionAdd":
        onAddNewItem();
        break;
      default:
        break;
    };
  };
  //---------------------------------------------------------------------------
  const filteredDictionaryItems = state?.draft.items?.filter(item => {
    let resultText = true;
    let resultState = true;
    //-----------------------------------------------------------------------
    // Filter by text
    if (state?.filter.text) {
      const searchString = state.filter.text.toLocaleLowerCase();
      const name = item.name ? item.name : "";
      resultText = item.id.toLocaleLowerCase().includes(searchString) || (name.toLocaleLowerCase().includes(searchString));
    };
    //-----------------------------------------------------------------------
    // Filter by state
    if (state && state.filter.draftState !== DraftItemStateEnum.All) {
      resultState = (item.draftState & state.filter.draftState) != DraftItemStateEnum.None;
      //console.log(item.id, state.filter.draftState, item.draftState)
    };
    //-----------------------------------------------------------------------
    return resultText && resultState;
  });
  const dictionaryItemsToDisplay = filteredDictionaryItems?.map(item => {
    return (
      <DictionaryNodeRejectionReasons
        key={item.id}
        stateGroups={state?.filter.stateGroups}
        data={item}
        editModeIsEnabled={state?.editModeIsEnabled}
        onUpdate={onUpdate}
        onDelete={onHardDeleteItem}
      />
    );
  });
  //---------------------------------------------------------------------------
  return (
    <DictionaryEditorWrapper
      setup={{
        mode: "Editor",
        role: 'Administrator',
        entityType: "Dictionary"
      }}
      state={state}
      isLoading={isLoading}
      showItemStatus={true} // We need to display item status (accessibility)
      onFilterUpdate={onFilterUpdate}
      onEnterEditMode={enableEditMode}
      onOptionSelect={onOptionSelect}
      newUi={true}
    >
      {dictionaryItemsToDisplay}

      {newItem &&
        <EditFormRejectionReason
          editModeIsEnabled={true}
          onUpdate={addNewItem}
          onClose={() => setNewItem(false)}
        />}

    </DictionaryEditorWrapper>
  );
}