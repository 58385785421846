import { useContext, useState } from "react";
import { IUiInputListBusinessPath } from "../IUiInputListBusinessPath";
import { getTUiIconButton } from "../../../../../common/icon-button/TUiIconButton";
import styles from './BusinessPathlistItem.module.css';
import IconButton from "../../../../../common/icon-button/IconButton";
import PopupInformer from "../../../../../common/popup-v2/popup-informer/PopupInformer";
import GetCaption from "../../../../../common/functions/GetCaption";
import GetPlaceholder from "../../../../../common/functions/GetPlaceholder";
import CombineStyles from "../../../../../../utils/combineStyles";
import { IUiOption } from "../../../../../common/options/IUiOption";
import { AppUiContextStore } from "../../../../../../context/app-ui-context/AppUiContextProvider";
//import Icon from "../../../../../common/icon/Icon";

interface IProps {
  ui: IUiInputListBusinessPath;
  data?: string;
  isMaster?: boolean;
  isReadonly: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  onRefresh?: () => void;
}

export default function BusinessPathlistItem(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [popupOpen, setPopupOpen] = useState(false);
  //const isMaster = props.isMaster == undefined ? false : props.isMaster;
  //---------------------------------------------------------------------------
  const copyToClipboard = () => {
    if (props.data) {
      //console.log("copy") 
      navigator.clipboard.writeText(`${window.location.origin}/${props.data}`);
      setPopupOpen(true);
    };
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "InputListBusinessPath.MenuContent.OptionEdit":
        props.onEdit && props.onEdit();
        break;
      case "InputListBusinessPath.MenuContent.OptionCopy":
        copyToClipboard();
        break;
      case "InputListBusinessPath.MenuContent.OptionCreate":
        props.onRefresh && props.onRefresh();
        break;
      case "InputListBusinessPath.MenuContent.OptionDelete":
        props.onDelete && props.onDelete();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "InputListBusinessPath.MenuContent.OptionEdit":
        updatedOptions.push({
          ...option,
          iconId: "pencil",
          isDisabled: props.isReadonly
        });
        break;
      case "InputListBusinessPath.MenuContent.OptionCopy":
        updatedOptions.push({
          ...option,
          iconId: "copy",
          isDisabled: !props.data
        });
        break;
      case "InputListBusinessPath.MenuContent.OptionCreate":
        if (props.onRefresh) {
          updatedOptions.push({
            ...option,
            iconId: "refresh",
            isDisabled: props.isReadonly
          });
        };
        break;
      case "InputListBusinessPath.MenuContent.OptionDelete":
        if (props.onDelete) {
          updatedOptions.push({
            ...option,
            iconId: "delete",
            isDisabled: props.isReadonly || !props.data || props.data === "default"
          });
        };
        break;
      // case "InputListBusinessPath.MenuContent.OptionSetAsMaster":
      //   if (isMaster) {
      //     updatedOptions.push({
      //       ...option,
      //       iconId: "star",
      //       isDisabled: true
      //     });
      //   };
      //   break;
    };
  });
  const menuOptionElements = updatedOptions.map(option => (
    <IconButton
      key={option.id}
      ui={getTUiIconButton(option)}
      inline={false}
      clickHandler={() => onOptionSelect(option.id)}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={CombineStyles([styles.url, props.data ? "" : styles.placeholder])}>
        {props.data ? `${window.location.origin}/${props.data}` : GetPlaceholder(appUiContext, props.ui.id, props.ui.placeholder)}
      </div>
      {/* {isMaster &&
        <Icon imageName="star" />} */}
      <div id={props.ui.menuContent.id} className={styles.options}>
        {menuOptionElements}
      </div>

      <PopupInformer
        show={popupOpen}
        anchorId={"InputListBusinessPath.MenuContent.OptionCopy"}
        onClose={() => setPopupOpen(false)}
      >
        {GetCaption(appUiContext, props.ui.notificationCopied.id, props.ui.notificationCopied.caption)}
      </PopupInformer>

    </div>
  );
}