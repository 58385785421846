import React from 'react';
import { useState } from 'react';
import { IUiListItem } from '../../../common/input/input-dropdown/InputDropdown';
//import SelectedSubTags from '../selected-subtags/SelectedSubTags';
import styles from './RootTag.module.css';
import RootTagHeader from './root-tag-header/RootTagHeader';
import { IUiSelectorBusinessTags } from '../IUiSelectorBusinessTags';
import BusinessTagsPopup from '../../../common/business/business-tags-popup/BusinessTagsPopup';
import BusinessTagsSelected from '../../../common/business/business-tags-selected/BusinessTagsSelected';

export function updateSelectedTags(tagPath: string, onUpdate: (selectedTags?: string[]) => void, selectedTags?: string[]) {
  if (!selectedTags || selectedTags.length === 0) {
    // All tags are unselected, just pass the update
    onUpdate(selectedTags);
    return;
  };
  //-------------------------------------------------------------------------
  // Add root tag to the selected tags, if there are subtags selected 
  // and if it's not there yet
  // Or remove root tag if there are no subtags selected
  const tag = selectedTags?.find(tag => tag == tagPath);
  const subTags = selectedTags?.find(tag => tag !== tagPath && tag.startsWith(tagPath));
  if (subTags && !tag) {
    // Add root tag
    onUpdate([...selectedTags, tagPath]);
  } else if (!subTags && tag) {
    // Remove root tag
    onUpdate(selectedTags.filter(tag => tag !== tagPath));
  } else {
    // Just pass the update
    onUpdate(selectedTags);
  };
}

interface IProps {
  ui: IUiSelectorBusinessTags;
  rootTag: IUiListItem;
  selectedTags?: string[];
  onUpdate: (selectedTags?: string[]) => void;
}

export default function RootTag(props: IProps) {
  const [popupOpen, setPopupOpen] = useState(false);
  const tagPath = props.rootTag.id;
  //---------------------------------------------------------------------------
  const isSelected = !!props.selectedTags?.find(x => x.startsWith(tagPath));
  //---------------------------------------------------------------------------
  const openTagsSelector = () => {
    console.log("onSelect")
    setPopupOpen(true);
  };
  //---------------------------------------------------------------------------
  const closePopup = () => {
    setPopupOpen(false);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (selectedTags?: string[]) => {
    closePopup();
    updateSelectedTags(tagPath, props.onUpdate, selectedTags);
    /*
    if (!selectedTags || selectedTags.length === 0) {
      // All tags are unselected, just pass the update
      props.onUpdate(selectedTags);
      return;
    };
    //-------------------------------------------------------------------------
    // Add root tag to the selected tags, if there are subtags selected and if it's not there yet
    // Or remove root tag if there are no subtags selected
    const tag = selectedTags?.find(tag => tag == tagPath);
    const subTags = selectedTags?.find(tag => tag !== tagPath && tag.startsWith(tagPath));
    if (subTags && !tag) {
      // Add root tag
      props.onUpdate([...selectedTags, tagPath]);
    } else if (!subTags && tag) {
      // Remove root tag
      props.onUpdate(selectedTags.filter(tag => tag !== tagPath));
    } else {
      // Just pass the update
      props.onUpdate(selectedTags);
    };
    */
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <RootTagHeader
        ui={props.rootTag}
        isSelected={isSelected}
        onSelect={openTagsSelector}
      />

      <div className={styles.details}>

        {isSelected &&
          <div className={styles.subTags}>
            <BusinessTagsSelected
              selectedTags={props.selectedTags}
              rootPath={props.rootTag.id}
              onUpdate={onUpdate}
            />
            <div
              className={styles.option}
              onClick={openTagsSelector}
            >
              Edit...
            </div>
          </div>}

      </div>

      {popupOpen &&
        <BusinessTagsPopup
          ui={props.ui}
          rootPath={props.rootTag.id}
          selectedPaths={props.selectedTags ? props.selectedTags : []}
          onUpdate={onUpdate}
          onClose={closePopup}
        />}

    </div>
  );
}