import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { ApiKey } from "./apikey-card/ApiKeyCard";
import { apiGetPrivate, checkResponse } from "../../../../utils/api";
import { apiBasePath, pathGetApiKeys } from "../../../../utils/apiPathConstant";

export default async function getApiKeys(
  msalInstance: IPublicClientApplication,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal,
): Promise<ApiKey[]> {
  return new Promise((resolve, reject) => {
    const uri = `${apiBasePath}${pathGetApiKeys}`;
    apiGetPrivate(
      msalInstance,
      authConfig,
      uri,
      abortSignal
    ).then(response => {
      checkResponse(response, "getApiKeys");
      resolve(ApiKey.fromArray(response?.content));
    }).catch((error) => {
      reject(error);
    });
  });
}