import React from 'react';
import LocalesListItem from '../locales-list-item/LocalesListItem';
import { IUiDictionaryItem } from '../../../../../../common/dictionaries/IUiDictionaryItem';
//import styles from './LocalesList.module.css';

interface IUiDictionaryItemGroup {
  id: string;
  items: IUiDictionaryItem[];
}

interface IProps {
  selectedLocales?: string[]; // selected in current editor session
  usedLocales?: string[]; // selected before current editor session
  showNativeNames?: boolean;
  options?: IUiDictionaryItem[];
  onSelect: (selectedCulture: IUiDictionaryItem, confirmed: boolean) => void;
}

export default function LocalesList(props: IProps) {
  //---------------------------------------------------------------------------
  const groups: IUiDictionaryItemGroup[] = [];
  let group: IUiDictionaryItemGroup = { id: "", items: [] };
  let currentItemPath = "";
  let previousItemPath: string | undefined = undefined;
  props.options?.forEach(item => {
    currentItemPath = item.path ? `${item.path}.${item.id}` : item.id;
    if (previousItemPath && currentItemPath.startsWith(`${previousItemPath}.`)) {
      group.items.push(item);
    } else {
      if (group.items.length > 0) {
        groups.push(group);
      };
      previousItemPath = currentItemPath;
      group = { id: currentItemPath, items: [item] };
    };
  });
  groups.push(group);
  //---------------------------------------------------------------------------
  const listItems = groups.map(group => (
    <LocalesListItem
      key={group.id}
      selectedLocales={props.selectedLocales}
      usedLocales={props.usedLocales}
      options={group.items}
      showNativeName={props.showNativeNames}
      onSelect={props.onSelect}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      {listItems}
      <div style={{ height: '1px' }}></div>
    </React.Fragment>
  );
}