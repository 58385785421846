import { IUiInteractiveForm } from "../../../../../common/forms/IUiInteractiveForm";
import { IUiInputCheckbox } from "../../../../../common/input-v2/input-checkbox/InputCheckBox";
import { IUiInputText } from "../../../../../common/input-v2/input-text/InputText";

export interface IUiEditformDictionaryDetails extends IUiInteractiveForm {
  inputId: IUiInputText;
  inputName: IUiInputText;
  inputDescription: IUiInputText;
  inputIsHierarchical: IUiInputCheckbox;
  inputIsSortedManually: IUiInputCheckbox;
}

export const tmpUiEditformDictionaryDetails: IUiEditformDictionaryDetails = {
  id: 'tmpUiEditformDictionaryDetails',
  directoryId: '',
  elementType: '',
  caption: undefined,
  hint: undefined,
  menuContent: {
    id: '',
    directoryId: '',
    elementType: '',
    hint: undefined,
    options: [{
      id: 'OptionClose',
      directoryId: '',
      elementType: '',
      caption: { en: 'Close' },
      hint: undefined,
    }, {
      id: 'OptionCancel',
      directoryId: '',
      elementType: '',
      caption: { en: 'Cancel' },
      hint: undefined,
    },{
      id: 'OptionOk',
      directoryId: '',
      elementType: '',
      caption: { en: 'Ok' },
      hint: undefined,
    }],
  },
  inputId: {
    id: '',
    directoryId: '',
    elementType: '',
    caption: { en: 'Dictionary Id' },
    hint: { en: 'Dictionary Identifier' },
  },
  inputName: {
    id: '',
    directoryId: '',
    elementType: '',
    caption: { en: 'Name' },
    hint: { en: 'Dictionary name' },
  },
  inputDescription: {
    id: '',
    directoryId: '',
    elementType: '',
    caption: { en: 'Description' },
    hint: { en: 'Dictionary description: purpose, requirements etc' },
  },
  inputIsHierarchical: {
    id: '',
    directoryId: '',
    elementType: '',
    caption: { en: 'Hierarchical' },
    hint: { en: 'Indicates that dictionary has hierarchical structure: dictionary items can have sub-items' },
  },
  inputIsSortedManually: {
    id: '',
    directoryId: '',
    elementType: '',
    caption: { en: 'Sorted Manually' },
    hint: { en: 'Dictionary items are not sorted in UI, original order is preserved' },
  }
};