import CombineStyles from "../../../../utils/combineStyles";
import Icon from "../../icon/Icon";
import { TUiMenuOption } from "../../menu/menu-content/menu-option/TUiMenuOption";
import styles from "./ImageEditorOption.module.css";

interface IProps {
  ui: TUiMenuOption;
  onSelect: () => void;
}

export default function ConfirmOption(props: IProps) {
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (!props.ui.disabled) {
      props.onSelect();
    };
  };
  //---------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    props.ui.disabled ? styles.disabled : "",
    props.ui.visible ? "" : styles.hidden
  ]);
  //---------------------------------------------------------------------------
  return (
    <div
      className={containerStyles}
      style={{
        //fontSize: "3.2em",
        color: "var(--clr_accent_main)"
      }}
      onClick={onSelect}
      >
      <Icon imageName="check" />
    </div>
  );
}