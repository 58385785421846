import { useContext } from "react";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import GetText from "../../../common/functions/GetText";
import Icon from "../../../common/icon/Icon";
import styles from "./AttentionNotification.module.css";

interface IProps {
}
//---------------------------------------------------------------------------
const message = {
  en: "(Hardcoded) There are unsaved changes on this form. You may want to save them before you leave user profile page."
};

export default function AttentionNotification(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <Icon imageName="info" />
      </div>
      {GetText({
        appUiContext: appUiContext,
        elementId: "Tmp",
        elementPart: "Tmp",
        text: message,
      })}
    </div>
  );
}