import { useEffect, useState } from 'react';
import styles from './RegionSelector.module.css';
import RegionNode from './region-node/RegionNode';
import { IUiDictionaryItemMlRegion } from '../../../../common/dictionaries/IUiDictionaryItem';
import { EditFormState } from '../../../../common/edit-form/IEditFormState';

interface IRegionSelectorState extends EditFormState<string[]> {
}
interface IRegionSelectorStateSpread {
  object?: string[];
  isNew?: boolean;
  isUpdated?: boolean;
  isValid?: boolean;
}

class RegionsSelectorState implements IRegionSelectorState {
  object?: string[];
  initialState?: string[];
  isNew!: boolean;
  isUpdated!: boolean;
  isValid!: boolean;
  //---------------------------------------------------------------------------
  constructor(state: IRegionSelectorState, spread: IRegionSelectorStateSpread) {
    Object.assign(this, state, spread);    
  };
}

interface IProps {
  ui: IUiDictionaryItemMlRegion[];
  data: string[];
  onUpdate: (updatedList: string[]) => void;
}

export default function RegionSelector(props: IProps) {
  const [state, setState] = useState<RegionsSelectorState>();
  //---------------------------------------------------------------------------
  useEffect(() => {
    const dataSorted = props.data.sort();
    const newState: IRegionSelectorState = {
      object: dataSorted,
      initialState: JSON.parse(JSON.stringify(dataSorted)),
      isNew: false,
      isUpdated: false,
      isValid: false
    };
    setState(newState);
  }, []);
  //---------------------------------------------------------------------------
  const regionNodes = props.ui.map(region => (
    <RegionNode
      key={region.id}
      ui={region}
      data={props.data}
      onUpdate={props.onUpdate}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      {regionNodes}
    </div>
  );
}