import { Icon } from "@iconify/react";
import CombineStyles from "../../../../utils/combineStyles";
import styles from './ListIcon.module.css';

interface IProps {
  disabled?: boolean;
  onSelect: () => void;
}

export default function OptionDelete(props: IProps) {
  const disabled = props.disabled == undefined ? false : props.disabled;
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (!disabled) {
      props.onSelect();
    };
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        disabled ? styles.disabled : ""
      ])}
      style={{
        padding: '0 2px'
      }}
      onClick={onSelect}
    >
      <Icon
        icon="mdi:delete"
        height="1.5em"
      />
    </div>
  );
}