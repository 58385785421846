import { IUiInteractiveForm } from "../../../../../common/forms/IUiInteractiveForm";
import { IUiInputText } from "../../../../../common/input-v2/input-text/InputText";

export interface IUiEditFormLocation extends IUiInteractiveForm {
  inputName: IUiInputText;
  inputAddressText: IUiInputText;
  inputCoordinates: IUiInputText;
}

export const tmpUiEditFormLocation: IUiEditFormLocation = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: undefined,
  hint: undefined,
  inputName: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Name" },
    placeholder: { en: "Type location name..." },
    hint: undefined
  },
  inputAddressText: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Address" },
    placeholder: { en: "Type location address..." },
    hint: undefined
  },
  inputCoordinates: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Coordinates" },
    placeholder: { en: "Paste location coordinates here..." },
    hint: { en: "Click to paste coordinates or use map to specify location" }
  },
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: "",
    options: [{
      id: "OptionOk",
      directoryId: "",
      elementType: "",
      caption: { en: "Ok" },
      hint: undefined,
    }, {
      id: "OptionCancel",
      directoryId: "",
      elementType: "",
      caption: { en: "Cancel" },
      hint: undefined,
    }]
  }
};