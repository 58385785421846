import { RefObject } from 'react';
import styles from './EditFormPrompt.module.css';

interface IProps {
  divRef?: RefObject<HTMLDivElement>;
  prompt: string;
}

export default function EditFormPrompt(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div ref={props.divRef} className={styles.container}>
      {props.prompt}
    </div>
  );
}