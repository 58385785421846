import styles from './ContactTypeCard.module.css';
import { IUiDictionaryItem } from '../../../../../common/dictionaries/IUiDictionaryItem';
import GetName from '../../../../../common/functions/GetName';
import Icon from '../../../../../common/icon/Icon';
import { Contact } from '../../contact/Contact';
import { useContext, useEffect, useRef, useState } from 'react';
import ContactEditForm from '../contact-edit-form/ContactEditForm';
import CombineStyles from '../../../../../../utils/combineStyles';
import ContactsListItem from './contacts-list-item/ContactsListItem';
import { IUiInputListContacts } from '../IUiInputListContacts';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';

interface IProps {
  ui?: IUiInputListContacts;
  contactType: IUiDictionaryItem;
  data?: Contact<string>[];
  editOnly?: boolean;
  scrollIntoView: boolean;
  onUpdate: (updatedContact: Contact<string>) => void;
  onDelete: (deletedContactId: string) => void;
}

export default function ContactTypeCard(props: IProps) {
  if (!props.ui) return null;
  const { appUiContext } = useContext(AppUiContextStore);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const noData = !props.data || props.data.length == 0;
  const editOnly = props.editOnly == undefined ? false : props.editOnly;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [props.scrollIntoView]);
  //---------------------------------------------------------------------------
  const onAddContactToggle = () => {
    setEditFormOpen(!editFormOpen);
  };
  //---------------------------------------------------------------------------
  const onEditFormClose = () => {
    setEditFormOpen(false);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedContact: Contact<string>) => {
    setEditFormOpen(false);
    props.onUpdate(new Contact<string>(updatedContact));
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string, contactId: string) => {
    switch (selectedOptionId) {
      case "InputListContacts.MenuContent.OptionDelete":
        props.onDelete && props.onDelete(contactId);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const contacts = props.data?.map(contact => (
    <ContactsListItem
      key={contact.id}
      ui={props.ui}
      contactType={props.contactType}
      contacts={props.data}
      data={contact}
      onUpdate={props.onUpdate}
      onDelete={props.onDelete}
      onOptionSelect={onOptionSelect}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <div ref={containerRef} className={styles.container}>

      <div className={styles.header}>

        <div
          className={CombineStyles([
            styles.brand,
            noData ? styles.disabled : ""
          ])}>
          <div className={styles.logo}>
            <Icon imageName={GetIcon(props.contactType.id)} />
          </div>
          <div className={styles.name}>
            {GetName(appUiContext, props.contactType.id, props.contactType.name)}
          </div>
        </div>

        {!editOnly &&
          <div
            className={styles.options}
            onClick={onAddContactToggle}>
            <Icon imageName="plus" />
          </div>}

      </div>

      {props.data && props.data.length > 0 && contacts}

      {editFormOpen &&
        <ContactEditForm
          ui={props.ui.editForm}
          contactType={props.contactType}
          contacts={props.data ? props.data : []}
          onClose={onEditFormClose}
          onUpdate={onUpdate}
        />}

    </div>
  );
}

export function GetIcon(contactType?: string) {
  switch (contactType) {
    case "Email":
      return "email";
    case "Website":
      return "web";
    case "Mobilephone":
      return "mobile";
    case "Landline":
      return "phone";
    case "Fax":
      return "fax";
    case "Whatsapp":
      return "whatsapp";
    case "Telegram":
      return "telegram";
    case "Twitter":
      return "twitter";
    case "Skype":
      return "skype";
    case "Facebook":
      return "facebook";
    case "Linkedin":
      return "linkedin";
    default:
      return "location";
  };
}