import styles from "./EditFormOptionButton.module.css";
import ButtonCaption from "../../button-caption/ButtonCaption";
import { TUiMenuOption } from "../../menu/menu-content/menu-option/TUiMenuOption";
import { TUiMenuContent } from "../../menu/menu-content/TUiMenuContent";
import { ButtonType } from "../../button/ButtonType";
import { ScreenType, useAppScreenContext } from "../../../../context/app-screen-context/AppScreenProvider";
import Icon, { IconSize } from "../../icon/Icon";
import { CSSProperties } from "react";

interface IProps {
  type: ButtonType;
  ui: TUiMenuOption;
  visible: boolean;
  disabled?: boolean;
  selected?: boolean;
  options?: TUiMenuContent;
  customCaption?: any; // This could be passed in case if caption should be dynamic (only in case of dropdown button with options provided)
  customColor?: string;
  spin?: boolean;
  clickHandler: (optionId: string) => void;
}

export default function EditFormOptionButton(props: IProps) {
  const screenType = useAppScreenContext();
  const disabled = props.disabled === undefined ? false : props.disabled;
  const spin = (props.spin === undefined) ? false : props.spin;
  //---------------------------------------------------------------------------
  const clickHandler = () => {
    if (!disabled)
      props.clickHandler(props.ui.id);
  }
  //---------------------------------------------------------------------------
  const calculatedStyle: CSSProperties = {
    backgroundColor: props.customColor ? props.customColor : undefined,
    display: props.visible ? "flex" : "none"
  };
  //---------------------------------------------------------------------------
  if (props.type === ButtonType.Popup) {
    // Popup (mobile only)
    const containerStyles =
      styles.container + " " + styles.popup
      + (props.options ? (" " + styles.dropdown) : "")
      + ((props.ui.isDefault && (screenType === ScreenType.Desktop)) ? (" " + styles.default) : "")
      + (disabled ? (" " + styles.disabled) : "");
    //-------------------------------------------------------------------------
    return (
      <div
        id={props.ui.id}
        className={containerStyles}
        style={calculatedStyle}
        onClick={clickHandler}
      >
        <ButtonCaption
          type={props.type}
          ui={props.ui}
          customCaption={props.customCaption}
          displayIcon={true}
          isDropdown={props.options ? true : false}
        />
      </div>
    );
  } else {
    // Toolbar (mobile or desktop)
    if (screenType === ScreenType.Mobile) {
      //-----------------------------------------------------------------------
      // Toolbar Mobile
      const containerStyle =
        styles.container + " " + styles.mobile
        + (props.options ? (" " + styles.dropdown) : "")
        + (disabled ? (" " + styles.disabled) : "");
      //-----------------------------------------------------------------------
      return (
        <div
          id={props.ui.id}
          className={containerStyle}
          style={calculatedStyle}
          onClick={clickHandler}
        >
          {props.ui.iconFile &&
            <Icon
              imageName={props.ui.iconFile}
              size={IconSize.Small}
              reserveSpace={false}
              spin={spin}
            />}
        </div>
      );
    } else {
      //-----------------------------------------------------------------------
      // Toolbar Desktop
      const containerStyle =
        styles.container
        + (props.options ? (" " + styles.dropdown) : "")
        + (props.ui.isDefault ? (" " + styles.default) : "")
        + (disabled ? (" " + styles.disabled) : "");
      //-----------------------------------------------------------------------
      return (
        <div
          id={props.ui.id}
          className={containerStyle}
          style={calculatedStyle}
          onClick={clickHandler}
        >
          <ButtonCaption
            type={props.type}
            ui={props.ui}
            customCaption={props.customCaption}
            displayIcon={true}
            isDropdown={props.options ? true : false}
            spin={spin}
          />
        </div>
      );
    }
  }
}