import React, { useEffect, useRef, useState } from "react";
import CombineStyles from "../../../../utils/combineStyles";
import Icon from "../../icon/Icon";
import { TUiMenuOption } from "../../menu/menu-content/menu-option/TUiMenuOption";
import styles from "./ImageEditorOption.module.css";

interface IProps {
  ui: TUiMenuOption;
  onImageSelect: () => void;
}

export default function SelectImageOption(props: IProps) {
  //---------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    props.ui.disabled ? styles.disabled : ""
  ]);
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <div
        className={containerStyles}
        onClick={props.onImageSelect}
      >
        <Icon imageName="image" />
      </div>
    </React.Fragment>
  );
}