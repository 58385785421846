import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { apiPostPrivate, checkResponse } from "../../../../utils/api";
import { apiBasePath, pathGetNewBusinessWithAd } from "../../../../utils/apiPathConstant";
import { ClassCustomerBusiness } from "../../business-editor/ICustomerBusiness";
import { IBusinessWizardData } from "../IWizardState";
import { AdLanguageSet } from "../../../ad-content-editor/IAdLanguageSet";
import { IBusinessWizardResponse } from "./IBusinessWizardResponse";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  data: IBusinessWizardData;
}

export async function getNewBusinessWithAdAsync(props: IProps): Promise<IBusinessWizardResponse> {
  if (!props.authConfig) throw new Error("getNewBusinessAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const response = await apiPostPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${pathGetNewBusinessWithAd}`,
    props.data,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getNewBusinessAsync", props.abortSignal?.aborted);
  const business = new ClassCustomerBusiness(response?.content.business);
  const adLanguageSet = new AdLanguageSet(response?.content.adLanguageSet); 
  return {
    updatedBusiness: business,
    updatedAdLanguageSet: adLanguageSet,
  };
}