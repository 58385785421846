import { useContext } from 'react';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import CombineStyles from '../../../../../utils/combineStyles';
import GetText from '../../../../common/functions/GetText';
import Icon from '../../../../common/icon/Icon';
import { IUiListItem } from '../../../../common/input/input-dropdown/InputDropdown';
import styles from './SelectedSubTagCard.module.css';
import IconDoubleCheck from '../../../../common/icons/IconDoubleCheck';

interface IProps {
  tag: IUiListItem;
  isDoubleChecked: boolean;
  onDelete?: (tagPath: string) => void;
}

export default function SelectedSubTagCard(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  let title: string | undefined = undefined;
  if (props.tag.isRequested) {
    title = "Requested tag";
  } else if (props.isDoubleChecked) {
    title = "Includes all sub-tags";
  }
  //---------------------------------------------------------------------------
  const onDelete = () => {
    props.onDelete && props.tag.path && props.onDelete(props.tag.path);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      title={title}
      className={CombineStyles([
        styles.container,
        props.isDoubleChecked ? styles.doubleChecked : '',
        props.tag.isRequested ? styles.requested : ''
      ])}>
      {props.isDoubleChecked &&
        <div className={styles.doubleCheckIcon}>
          <IconDoubleCheck />
        </div>}
      <div className={styles.caption}>
        {GetText({
          appUiContext: appUiContext,
          elementId: props.tag.id,
          elementPart: "Name",
          text: props.tag.name
        })}
      </div>
      {props.onDelete &&
        <div
          className={styles.deleteOption}
          onClick={onDelete}
        >
          <Icon imageName="close" />
        </div>}
    </div>
  );
}