import { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer, useRef, useState } from "react";
import { businessContextDefaultValue, IBusinessContext } from "./IBusinessContext";
import { Actions, BusinessContextReducer } from "./BusinessContextReducer";
import { useMsal } from "@azure/msal-react";
import { AppAuthContextStore } from "../app-auth-context/AppAuthContext";
import { useAbortController } from "../../hooks/useAbortController";
import WikiSpinner from "../../components/app-layout/spinner/wikiSpinner";
import getBusinessTagRequestsAsync from "./functions/getBusinessTagRequestsAsync";
import { AppUiContextStore } from "../app-ui-context/AppUiContextProvider";
import { GetUpdatedBusinessTagsDictionary } from "./functions/UpdateBusinessTags";

type TBusinessContext = {
  businessContext: IBusinessContext;
  businessContextDispatch: Dispatch<Actions>;
}

export const BusinessContext = createContext<TBusinessContext>({} as TBusinessContext);

interface IProps {
  children: ReactNode;
}

export default function BusinessContextProvider(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const [businessContext, businessContextDispatch] = useReducer(BusinessContextReducer, businessContextDefaultValue);
  const abortController = useAbortController("BusinessContextProvider");
  const [isLoading, setIsLoading] = useState(false);
  const newTagsRequestIsSent = useRef(false);
  //--------------------------------------------------------------------------- Business Tags requests, abort controller
  useEffect(() => {
    if (newTagsRequestIsSent.current) return;
    let controller = abortController.newController("getRegionalHomepage");
    setIsLoading(true);
    getBusinessTagRequestsAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal
    }).then(requests => {
      // Put user's new business tag requests to the business context
      businessContextDispatch({
        type: "SetBusinessTagRequests",
        requests: requests
      });
    }).catch(error => {
      console.error("BusinessContextProvider.getBusinessTagRequestsAsync.catch:", error);
    }).finally(() => {
      //!controller.aborted && setIsLoading(false);
    });
    newTagsRequestIsSent.current = true;
    //-------------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //--------------------------------------------------------------------------- Business Tags
  useEffect(() => {
    if (!appUiContext.dictionaries?.businessTags) return;
    const updatedDictionary = JSON.parse(JSON.stringify(appUiContext.dictionaries.businessTags));
    if (businessContext.businessTagRequests) {
      console.log("Updating business tags dictionary with requested tags");
      businessContext.businessTagRequests.forEach(tagRequest => {
        GetUpdatedBusinessTagsDictionary(updatedDictionary, tagRequest);
      });
    };
    businessContextDispatch({
      type: "SetBusinessTagsDictionary",
      dictionary: updatedDictionary
    });
    setIsLoading(false);
  }, [appUiContext.dictionaries, businessContext.businessTagRequests]);
  //---------------------------------------------------------------------------
  return (
    <BusinessContext.Provider value={{ businessContext, businessContextDispatch }}>
      <WikiSpinner show={isLoading} />
      {!isLoading && props.children}
    </BusinessContext.Provider>
  );
}