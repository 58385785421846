import styles from "./RikiWikiLogo.module.css";
import { useLocation } from "react-router";
import Logo from "./logo/Logo";
import NameAndSlogan from "./name-and-slogan/NameAndSlogan";

interface IProps {
  onClick: () => void;
}

export default function RikiWikiLogo(props: IProps) {
  const currentLocation = useLocation();
  //---------------------------------------------------------------------------
  const onClick = () => {
    if (currentLocation.pathname == "/") {
      location.reload();
    } else {
      props.onClick();
    }
  };
  //---------------------------------------------------------------------------
  return (
    <div
      id="RikiWikiLogo"
      className={styles.container}
      onClick={props.onClick}
      onDoubleClick={onClick}
    >
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.name}>
        <NameAndSlogan />
      </div>
    </div>
  )
}