import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { IDictionaryTranslationDraft } from "./DictionaryTranslatorV1";
import { apiBasePath, pathSaveDictionaryTranslationDraft } from "../../../../../utils/apiPathConstant";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { ILockable } from "../../../../common/locks/TLocker";
import { Locker } from "../../../../common/locks/Locker";

export default function saveDictionaryTranslationDraft(
  msalInstanse: IPublicClientApplication,
  translationDraft: IDictionaryTranslationDraft,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<IDictionaryTranslationDraft> {
  return new Promise((resolve, reject) => {
    //-------------------------------------------------------------------------
    let uri = `${apiBasePath}${pathSaveDictionaryTranslationDraft}`;
    let draft = {...translationDraft, locker: undefined };
    //-------------------------------------------------------------------------
    apiPostPrivate(
      msalInstanse,
      authConfig,
      uri,
      draft,
      undefined,
      abortSignal
    ).then(response => {
      checkResponse(response, "saveDictionaryTranslationDraft", abortSignal?.aborted);
      let draft = response?.content as IDictionaryTranslationDraft;
      if (draft.locker) {
        draft.locker.errorPrefix = `${draft.dictionaryId} dictionary`;
      }
      resolve(draft);
    }).catch(error =>
      reject(error)
    );
  });
}