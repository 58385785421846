import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ScreenType,
  useAppScreenContext,
} from "../../../context/app-screen-context/AppScreenProvider";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";
import Breadcrumb from "./breadcrumb/Breadcrumb";
import { TUiBreadcrumb } from "./breadcrumb/TUiBreadcrumb";
import styles from "./BreadcrumbsTrail.module.css";
import { GetIdFromLocationHash } from "../../../utils/locationUtils";

interface IProps {
  items?: TUiBreadcrumb[];
}

export default function BreadcrumbsTrail(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const reactLocation = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<TUiBreadcrumb[]>([]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    // Assuming we have all possible breadcrumbs in appUiContext
    const fullPath: string = location.pathname;
    const pathItems = fullPath.substring(1).split("/");
    if (location.hash) {
      let id = GetIdFromLocationHash(reactLocation);
      id && pathItems.push(id);
    }
    var curPath: string = "";
    var updatedBreadcrumbs: TUiBreadcrumb[] = [];
    pathItems.forEach(item => {
      //console.log(item)
      curPath = `${curPath}/${item}`;
      //console.log(curPath)
      const breadcrumb = appUiContext.breadcrumbs?.find(item => item.path?.toLocaleLowerCase() === curPath.toLocaleLowerCase());
      //console.log(breadcrumb)
      if (breadcrumb) {
        updatedBreadcrumbs.push(breadcrumb);
      };
    });
    //---------------------------------------------------------------------------
    const breadcrumbsToRender =
      (screenType === ScreenType.Mobile && updatedBreadcrumbs.length > 2)
        ? updatedBreadcrumbs.slice(Math.max(updatedBreadcrumbs.length - 2, 1))
        : updatedBreadcrumbs;
    //console.log(breadcrumbsToRender)
    //---------------------------------------------------------------------------
    setBreadcrumbs(breadcrumbsToRender);
  }, [appUiContext.breadcrumbs, location, reactLocation, screenType]);
  //---------------------------------------------------------------------------
  //console.log("breadcrumbs:", breadcrumbs);
  const items = breadcrumbs.map((breadcrumbsItem, index) => (
    <Breadcrumb
      key={breadcrumbsItem.path}
      item={breadcrumbsItem}
      isLast={index === breadcrumbs.length - 1}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      {breadcrumbs ? items : "Breadcrumbs trail is not provided"}
    </div>
  );
}
