import { useState } from "react";
import { TImageUsage } from "../../../../common/image-editor/TImageUsage";
import PopupTest from "../../../../common/popup-v2/popup/PopupTest";
import styles from "./ImageUsage.module.css";
import PopupMenu from "../../../../common/popup-v2/popup-menu/PopupMenu";
import { TUiMenuOption } from "../../../../common/menu/menu-content/menu-option/TUiMenuOption";
import { TUiMenuContent } from "../../../../common/menu/menu-content/TUiMenuContent";
//----------------------------------------------------------------------------
const previewButton: TUiMenuOption = {
  id: "preview",
  directoryId: "",
  elementType: "",
  caption: { en: "Preview" },
  hint: { en: "Preview" },
  iconFile: "eyeOpened",
  isDefault: true,
  disabled: false,
  visible: true,
  action: undefined,
  index: 0,
  priorityLevel: 0,
  canHideCaption: false
}
//----------------------------------------------------------------------------
const editButton: TUiMenuOption = {
  id: "edit",
  directoryId: "",
  elementType: "",
  caption: { en: "Edit" },
  hint: { en: "Edit" },
  iconFile: "pencil",
  isDefault: false,
  disabled: false,
  visible: true,
  action: undefined,
  index: 0,
  priorityLevel: 0,
  canHideCaption: false
}
//----------------------------------------------------------------------------
const popupMenuContent: TUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  options: [previewButton, editButton],
  optionGroups: [],
  visible: true,
  disabled: false
}
//---------------------------------------------------------------------------
interface IProps {
  usage: TImageUsage;
}
//---------------------------------------------------------------------------
export function ImageUsage(props: IProps) {
  const [popupOpen, setPopupOpen] = useState(false);
  //-------------------------------------------------------------------------
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setPopupOpen(!popupOpen);
  }
  //-------------------------------------------------------------------------
  const onPopupClose = () => {
    setPopupOpen(false);
  }
  //-------------------------------------------------------------------------
  const onPopupOptionSelect = (optionId: string) => {
    setPopupOpen(false);
    switch (optionId) {
      case "preview":
        return alert(props.usage.displayPreview);
      case "edit":
        return alert(props.usage.displayEdit);
    }
  }
  //-------------------------------------------------------------------------
  previewButton.caption = { en: props.usage.displayPreview }
  editButton.caption = { en: props.usage.displayEdit }
  //-------------------------------------------------------------------------
  let divId = props.usage.divId;
  //-------------------------------------------------------------------------
  return (
    <div
      id={divId}
      className={styles.container}
      onClick={onClick}
    >
      {props.usage.displayName}
      {popupOpen &&
        <PopupMenu
          ui={popupMenuContent}
          anchorId={divId}
          displayIcon={true}
          onSelect={onPopupOptionSelect}
          onClose={onPopupClose}
        />
      }
    </div>
  );
}