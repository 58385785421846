import { useMsal } from "@azure/msal-react";
import ConsoleLayout from "../../common/console-layout/ConsoleLayout";
import { useContext, useEffect, useState } from "react";
import { AppAuthContextStore } from "../../../context/app-auth-context/AppAuthContext";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import WikiSpinner from "../../app-layout/spinner/wikiSpinner";
import { IUiBusinessEditor } from "./IUiBusinessEditor";
import { getTUiMenuContent } from "../../common/menu/menu-content/TUiMenuContent";
import { pathBusinessConsole, pathBusinesses, pathBusinessNew } from "../business-console/BusinessConsole";
import { TUiBreadcrumb } from "../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import getBusinessAsync from "../functions/getBusinessAsync";
import usePageTitle from "../../../hooks/usePageTitle";
import useNavigateWithContext from "../../../hooks/useNavigateWithContext";
import SectionBranding from "./section-branding/SectionBranding";
import SectionLocation from "./section-location/SectionLocation";
import SectionContacts from "./section-contacts/SectionContacts";
import SectionTags from "./section-tags/SectionTags";
import SectionAdCampaigns from "./section-adcampaigns/SectionAdCampaigns";
import { ImageEditorContext } from "../../common/image-editor/image-editor-context/ImageEditorContextProvider";
import AdCampaignEditForm from "./ad-campaign/AdCampaignEditForm";
import { createAdCampaignAsync } from "../functions/createAdCampaign";
import { IUiMenuContent } from "../../common/options/menus/IUiMenuContent";
import { useAbortController } from "../../../hooks/useAbortController";
import { BusinessContext } from "../../../context/business-context/BusinessContextProvider";
import { EUserRole } from "../../../context/business-context/IBusinessContext";
import { ClassCustomerBusiness, ICustomerBusiness } from "./ICustomerBusiness";
import { getNewBusinessAsync } from "../functions/getNewBusinessAsync";
import { IAdCampaign } from "../../../context/business-context/IAdCampaign";
import saveBusinessAsync from "../../../context/business-context/functions/saveBusinessAsync";
import ConsoleTabLayout, { TScrollDirection, TScrollType } from "../../common/console-layout/console-tab-layout/ConsoleTabLayout";
import FormOptions from "../../common/form-options-bar/FormOptions";
import NavigateWithContext from "../../common/navigate-with-context/NavigateWithContext";
import { IBusinessLocationData, IObjectLocation, newId } from "./section-location/business-location-editor/IBusinessLocationData";
import { TGeoPoint } from "../../common/map/geo-json/GeoJson";
import SectionAdModules from "./section-ad-modules/SectionAdModules";
import { tmpAdModuleMenuContent } from "../../common/ad-modules/IUiAdModule";

export const pathBranding = "branding";
export const pathLocation = "location";
export const pathContacts = "contacts";
export const pathTags = "tags";
export const pathAdCampaigns = "adcampaigns";
export const pathAdModules = "modules";
const sections = [pathBranding, pathLocation, pathContacts, pathTags, pathAdCampaigns, pathAdModules];

interface IProps {
  ui: IUiBusinessEditor;
}

export default function BusinessEditor(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext, appAuthContextDispatch } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const { imageEditorContext } = useContext(ImageEditorContext);
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const { appUiContextDispatch } = useContext(AppUiContextStore);
  const [isLoading, setIsLoading] = useState(false);
  const [newAdCampaign, setNewAdCampaign] = useState(false);
  const updateTitle = usePageTitle();
  const navigate = useNavigateWithContext();
  const abortController = useAbortController("BusinessEditor");
  //---------------------------------------------------------------------------
  const location = useLocation();
  const { role, businessId } = useParams();
  const basePath = `${pathBusinessConsole}/${role}/${pathBusinesses}`;
  //const currentSection = GetIdFromLocationHash(location)?.toLowerCase();
  const currentSection = location.pathname.split("/").pop()?.toLowerCase();
  const currentSectionIndex = sections.findIndex(item => item == currentSection);
  const [sectionPathToSwitch, setSectionPathToSwitch] = useState<string>();
  //---------------------------------------------------------------------------
  const userRole = role as EUserRole;
  const business = businessContext.businessState?.business as ClassCustomerBusiness;
  const businessIsSaveable = businessContext.businessState ?
    (businessContext.businessState.isChanged || businessContext.businessState.isNew) && businessContext.businessState.isValid && !isLoading
    : false;
  let newLocation = business?.locationData?.locations.find((location: IObjectLocation<string>) => location.id == newId);
  //--------------------------------------------------------------------------- Get data, set business context
  useEffect(() => {
    if (businessId) {
      // Check if business is new
      if (businessId == pathBusinessNew) {
        //---------------------------------------------------------------------
        // New business is being created
        console.log("New business is being created");
        setIsLoading(true);
        let controller = abortController.newController("getNewBusiness");
        getNewBusinessAsync({
          msalInstance: instance,
          authConfig: appAuthContext.config,
          abortSignal: controller.signal,
          localeId: appUiContext.locale.localeId
        }).then(businessData => {
          //-----------------------------------------------------------------------
          // Set Business Context
          businessContextDispatch({
            type: "SetBusinessState",
            userRole: userRole,
            basePath: `${pathBusinessConsole}/${userRole}/${pathBusinesses}/${pathBusinessNew}`,
            business: businessData,
            isNew: true
          });
        }).catch(error =>
          console.error(error)
        ).finally(() => {
          !controller.aborted && setIsLoading(false);
        });
      } else {
        //---------------------------------------------------------------------
        // Get existing business
        setIsLoading(true);
        let controller = abortController.newController("getBusiness");
        getBusinessAsync({
          msalInstance: instance,
          authConfig: appAuthContext.config,
          businessId: businessId,
          abortSignal: controller.signal
        }).then(response => {
          businessContextDispatch({
            type: "SetBusinessState",
            userRole: userRole,
            basePath: `${basePath}/${businessId}`,
            business: response.business,
            ads: response.ads
          });
        }).catch(error => {
          console.error(error);
          //navigate(`${pathBusinessConsole}`);
        }).finally(() => {
          !controller.aborted && setIsLoading(false);
        });
      };
    };
    //---------------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
      businessId && imageEditorContext.removeAllSessionsFor(businessId);
    });
  }, []);
  //--------------------------------------------------------------------------- Breadcrumb, title
  useEffect(() => {
    if (!businessContext.businessState?.business) return;
    const business = businessContext.businessState.business as ClassCustomerBusiness;
    let businessName = business.name;
    let businessPath = business.id;
    if (businessContext.businessState.isNew) {
      businessName = "New";
      businessPath = pathBusinessNew;
    };
    const businessBreadcrumb: TUiBreadcrumb = {
      id: business.id,
      caption: { en: businessName },
      path: `/${basePath}/${businessPath}`
    };
    appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: businessBreadcrumb });
    //-----------------------------------------------------------------------
    updateTitle(businessName);
  }, [businessContext.businessState]);
  //---------------------------------------------------------------------------
  const save = () => {
    if (!businessContext.businessState) return;
    setIsLoading(true);
    let controller = abortController.newController("saveBusiness");
    const businessToSave = businessContext.businessState.business as ClassCustomerBusiness;
    saveBusinessAsync({
      msalInstance: instance,
      authContext: appAuthContext,
      imageEditorContext: imageEditorContext,
      business: businessToSave,
      abortSignal: controller.signal
    }).then(response => {
      // Update business context
      businessContextDispatch({ type: "SetBusiness", business: response.updatedBusiness });
      if (location.pathname.includes(pathBusinessNew)) {
        // If business was new, update business id in the URL
        const newPath = location.pathname.replace(pathBusinessNew, response.updatedBusiness.id);
        window.history.replaceState(null, "", newPath);
      };
      //-----------------------------------------------------------------------
      // Update user profile
      if (response.updatedUserRoles)
        appAuthContextDispatch({ type: "UpdateUserRoles", value: response.updatedUserRoles });
      //-----------------------------------------------------------------------
      // Update business tag requests, if there are any
      if (response.updatedBusinessTagRequests) {
        businessContextDispatch({ type: "SetBusinessTagRequests", requests: response.updatedBusinessTagRequests });
      };
    }).catch(error => {
      console.error(`saveBusiness:`, error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onAddNewLocation = () => {
    if (newLocation) return;
    //-------------------------------------------------------------------------
    // Create new location and add it to the business
    console.log("onAddNewLocation.currentUserPosition:", appAuthContext.userLocation?.position);
    let point: TGeoPoint;
    if (appAuthContext.userLocation?.position) {
      point = new TGeoPoint({
        coordinates: [
          appAuthContext.userLocation.position.coords.longitude,
          appAuthContext.userLocation.position.coords.latitude
        ]
      })
    } else {
      point = new TGeoPoint({ coordinates: [0, 0] });
    };
    newLocation = {
      id: newId,
      name: business ? business.name : "New location",
      point: point
    };
    const updatedBusiness = new ClassCustomerBusiness({
      ...business as ICustomerBusiness,
      locationData: {
        ...business.locationData as IBusinessLocationData<string>,
        locations: business.locationData?.locations ?
          [...business.locationData?.locations, newLocation] :
          [newLocation]
      }
    });
    businessContextDispatch({ type: "UpdateBusiness", business: updatedBusiness });
  };
  //---------------------------------------------------------------------------
  const onAddAdCampaign = () => {
    setNewAdCampaign(true);
  };
  //---------------------------------------------------------------------------
  const onAddAdCampaignCancel = () => {
    setNewAdCampaign(false);
  };
  //---------------------------------------------------------------------------
  const createAdCampaign = (newCampaign: IAdCampaign) => {
    if (!businessContext.businessState) return;
    setNewAdCampaign(false);
    if (businessContext.businessState?.isNew) {
      //-----------------------------------------------------------------------
      // If business is new, just update context
      const updatedBusiness = new ClassCustomerBusiness({
        ...businessContext.businessState.business as ICustomerBusiness,
        adCampaigns: [...businessContext.businessState.business.adCampaigns, newCampaign]
      });
      console.log([...businessContext.businessState.business.adCampaigns, newCampaign]);
      console.log(updatedBusiness)

      businessContextDispatch({ type: "UpdateBusiness", business: updatedBusiness });
    } else {
      //-----------------------------------------------------------------------
      // If business is not new, update business using API
      setIsLoading(true);
      let controller = abortController.newController("createAdCampaignAsync");
      createAdCampaignAsync({
        msalInstance: instance,
        authConfig: appAuthContext.config,
        businessId: businessContext.businessState?.business.id,
        adCampaign: newCampaign,
        abortSignal: controller.signal
      }).then(updatedBusiness => {
        // Update business context
        console.log(updatedBusiness)
        businessContextDispatch({ type: "SetBusiness", business: updatedBusiness });
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    };
  };
  //---------------------------------------------------------------------------
  const getSectionPath = (sectionId: string) => {
    return `/${basePath}/${business?.id}/${sectionId}`;
  };
  //---------------------------------------------------------------------------
  const switchSection = (
    currentPath: string,
    direction: TScrollDirection,
    type?: TScrollType,
  ) => {
    if (!currentPath) return;
    //-------------------------------------------------------------------------
    // Determine currect section
    let currentSection = currentPath.split(basePath).pop()?.toLowerCase().split("/").pop();
    currentSection = currentSection == "" ? pathBranding : currentSection;
    if (!currentSection) return;
    const currentSectionIndex = sections.indexOf(currentSection);
    //-------------------------------------------------------------------------
    // Determine what section user is going to switch to
    let newSectionIndex = currentSectionIndex;
    switch (direction) {
      case "Up":
        newSectionIndex = currentSectionIndex - 1;
        if (newSectionIndex < 0)
          return;
        break;
      case "Down":
        newSectionIndex = currentSectionIndex + 1;
        if (newSectionIndex >= sections.length)
          return;
        break;
      default:
        setSectionPathToSwitch(undefined);
        return;
    };
    const newSectionPath = sections[newSectionIndex];
    console.log(direction, type, currentSection, newSectionPath);
    //-------------------------------------------------------------------------
    // Determine action to perform
    switch (type) {
      case "Warn":
        setSectionPathToSwitch(newSectionPath);
        break;
      case "Switch":
        setSectionPathToSwitch(undefined);
        navigate(`/${basePath}/${business.id}/${newSectionPath}`);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "BusinessEditor.MenuContent.OptionUp":
        navigate(getSectionPath(sections[currentSectionIndex - 1]));
        break;
      case "BusinessEditor.MenuContent.OptionDown":
        navigate(getSectionPath(sections[currentSectionIndex + 1]));
        break;
      case "BusinessEditor.MenuContent.OptionCreateBusinessLocation":
        onAddNewLocation();
        break;
      case "BusinessEditor.MenuContent.OptionCreateAdCampaign":
        onAddAdCampaign();
        break;
      case "OptionClose":
        navigate(`${pathBusinessConsole}`);
        break;
      case "OptionSave":
        save();
        break;
    };
  };
  //--------------------------------------------------------------------------- Render options
  const menuContentSections: IUiMenuContent = {
    ...props.ui.menuContent,
    id: `${props.ui.menuContent.id}_Sections`,
    options: []
  };
  const menuContentOptions: IUiMenuContent = {
    ...props.ui.menuContent,
    id: `${props.ui.menuContent.id}_Options`,
    options: []
  };
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "BusinessEditor.MenuContent.OptionBranding":
        menuContentSections.options.push({
          ...option,
          iconId: "regMark",
          action: getSectionPath(pathBranding)
        });
        break;
      case "BusinessEditor.MenuContent.OptionLocation":
        menuContentSections.options.push({
          ...option,
          iconId: "location",
          action: getSectionPath(pathLocation)
        });
        break;
      case "BusinessEditor.MenuContent.OptionContacts":
        menuContentSections.options.push({
          ...option,
          iconId: "phone",
          action: getSectionPath(pathContacts)
        });
        break;
      case "BusinessEditor.MenuContent.OptionTags":
        menuContentSections.options.push({
          ...option,
          iconId: "tags",
          action: getSectionPath(pathTags)
        });
        break;
      case "BusinessEditor.MenuContent.OptionAdCampaigns":
        menuContentSections.options.push({
          ...option,
          iconId: "bullhorn",
          action: getSectionPath(pathAdCampaigns)
        });
        break;
      case "BusinessEditor.MenuContent.OptionAdModules":
        menuContentSections.options.push({
          ...option,
          iconId: "modules",
          action: getSectionPath(pathAdModules)
        });
        break;
      //-----------------------------------------------------------------------
      case "BusinessEditor.MenuContent.OptionUp":
        if (currentSectionIndex > 0) {
          menuContentOptions.options.push({
            ...option,
            iconId: "doubleArrowUp"
          });
        };
        break;
      case "BusinessEditor.MenuContent.OptionDown":
        if (currentSectionIndex < sections.length - 1) {
          menuContentOptions.options.push({
            ...option,
            iconId: "doubleArrowDown"
          });
        };
        break;
      case "BusinessEditor.MenuContent.OptionCreateBusinessLocation":
        if (currentSection == pathLocation) {
          menuContentOptions.options.push({
            ...option,
            iconId: "plus",
            isDisabled: newLocation ? true : false
          });
        };
        break;
      case "BusinessEditor.MenuContent.OptionCreateAdCampaign":
        if (currentSection == pathAdCampaigns) {
          menuContentOptions.options.push({
            ...option,
            iconId: "plus"
          });
        };
        break;
      case "OptionClose":
        menuContentOptions.options.push({
          ...option,
          iconId: "close"
        });
        break;
      case "OptionSave":
        menuContentOptions.options.push({
          ...option,
          iconId: "check",
          isDisabled: !businessIsSaveable || !!newLocation,
          isDefault: businessIsSaveable
        });
        break;
    };
  });
  const editFormOptions = props.ui.menuContent ?
    <FormOptions
      ui={getTUiMenuContent(menuContentOptions)}
      onSelect={onOptionSelect}
    />
    : undefined;
  //---------------------------------------------------------------------------
  return (
    <ConsoleLayout
      ui={{
        header: business ? business.name : "No Business Name",
        menuContent: getTUiMenuContent(menuContentSections)
      }}
      path={location.pathname}
      blinkingOptionPath={sectionPathToSwitch}
    >
      <ConsoleTabLayout
        toolbar={editFormOptions}
        onScroll={switchSection}
      >
        <WikiSpinner show={isLoading} />
        <Routes>
          <Route index element={
            <NavigateWithContext to={`/${basePath}/${businessId}/${pathBranding}`} />} />
          <Route path={pathBranding} element={
            <SectionBranding
              id={pathBranding}
              ui={props.ui.sectionBranding}
            />} />
          <Route path={pathLocation} element={
            <SectionLocation
              id={pathLocation}
              ui={props.ui.sectionLocation} />} />
          <Route path={pathContacts} element={
            <SectionContacts
              id={pathContacts}
              ui={props.ui.sectionContacts} />} />
          <Route path={pathTags} element={
            <SectionTags
              id={pathTags}
              ui={props.ui.sectionTags} />} />
          <Route path={pathAdCampaigns} element={
            <SectionAdCampaigns
              id={pathAdCampaigns}
              ui={props.ui.sectionAdCampaigns} />} />
          <Route path={pathAdModules} element={
            <SectionAdModules
              id={pathAdModules}
              ui={{
                ...props.ui.sectionAdModules,
                adModule: {
                  menuContent: tmpAdModuleMenuContent
                }
              }} />} />
        </Routes>
      </ConsoleTabLayout>

      {/* <ConsoleTabLayoutScrollable
        ui={getTUiMenuContent(menuContentOptions)}
        onOptionSelect={onOptionSelect}
      >
        <SectionBranding id={pathBranding} ui={props.ui.sectionBranding} />
        <SectionLocation id={pathLocation} ui={props.ui.sectionLocation} />
        <SectionContacts id={pathContacts} ui={props.ui.sectionContacts} />
        <SectionTags id={pathTags} ui={props.ui.sectionTags} />
        <SectionAdCampaigns id={pathAdCampaigns} ui={props.ui.sectionAdCampaigns} />
      </ConsoleTabLayoutScrollable> */}

      {newAdCampaign &&
        <AdCampaignEditForm
          ui={props.ui.editFormAdCampaign}
          isSystem={false}
          onConfirm={createAdCampaign}
          onCancel={onAddAdCampaignCancel}
        />}

    </ConsoleLayout>
  );
}