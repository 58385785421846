import { IPublicClientApplication } from "@azure/msal-browser";
import { apiGetPrivate, checkResponse } from "../../../../../../utils/api";
import { IAuthConfig } from "../../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathGetDictionaryDraft } from "../../../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../../../utils/common";
import { EDictionaryType } from "../../../../common/dictionary-draft-models/EDictionaryType";
import { ILockable, TLocker } from "../../../../../common/locks/TLocker";

export default function getDictionaryDraft(
  msalInstance: IPublicClientApplication,
  dictionaryId: EDictionaryType,
  lockIsNeeded: boolean,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<object> {
  return new Promise((resolve, reject) => {
    //-------------------------------------------------------------------------
    let url = `${apiBasePath}${stringFormatter(pathGetDictionaryDraft, [dictionaryId])}`;
    if (lockIsNeeded)
      url += "?lockIsNeeded=true";
    console.log("getDictionaryDraft.url:", url);
    apiGetPrivate(
      msalInstance,
      authConfig,
      url,
      abortSignal
    ).then(response => {
      checkResponse(response, "getDictionaryDraft", abortSignal?.aborted);
      let locker = (response?.content as ILockable).locker;
      if (locker) {
        locker.errorPrefix = `${dictionaryId} dictionary`;
      }
      resolve(response?.content);
    }).catch(error =>
      reject(error)
    );
  });
}