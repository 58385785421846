import { IPublicClientApplication } from "@azure/msal-browser";
import { IAppAuthContext } from "../../../context/app-auth-context/AppAuthContext";
import { apiDeletePrivate, checkResponse } from "../../../utils/api";
import { apiBasePath, deleteBusiness } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
import { EHttpStatusCode } from "../../../utils/HttpStatusCodes";


interface IProps {
  msalInstanse: IPublicClientApplication;
  authContext?: IAppAuthContext;
  businessId: string;
  abortSignal?: AbortSignal;
}

export async function deleteBusinessAsync(props: IProps): Promise<void> {
  if (!props.authContext) throw new Error("deleteBusinessAsync: auth context is not provided");
  //---------------------------------------------------------------------------
  // Delete business
  const response = await apiDeletePrivate(
    props.msalInstanse,
    props.authContext.config,
    `${apiBasePath}${stringFormatter(deleteBusiness, [props.businessId])}`,
    props.businessId
  );
  console.log(response);
  checkResponse(response, "deleteBusinessAsync", props.abortSignal?.aborted, [EHttpStatusCode.NoContent]);
}