//import styles from './IconBan.module.css';

interface IProps {
}

export default function IconBan(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="1em" height="1em"
      viewBox="0 0 525 512">
      <path
        fill="currentColor"
        d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248s248-111.034 248-248S392.967 8 256 8m130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676M125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676"
        transform="scale(1,-1) translate(0,-512)"
      />
    </svg>
  );
}