import React, { useContext } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { IUiBusinessTag, UiBusinessTag } from '../business-tags-popup/business-tag-node/IUiBusinessTag';
import BusinessTag from './business-tag/BusinessTag';

interface IProps {
  selectedTags?: string[];
  rootPath?: string;
  onUpdate?: (selectedTags?: string[]) => void;
}

export default function BusinessTagsSelected(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const businessTags = (appUiContext.dictionaries?.businessTags?.filter(item =>
    item.path?.startsWith(props.rootPath ?? '')) as IUiBusinessTag[]).map(tag => new UiBusinessTag(tag));
  //---------------------------------------------------------------------------
  const onDelete = (tagPath: string) => {
    if (!tagPath) return;
    // Delete selected tag and all its children if it's a selectable group
    const updatedSelectedTags = props.selectedTags?.filter(x => !x.startsWith(tagPath));
    props.onUpdate && props.onUpdate(updatedSelectedTags);
  };
  //---------------------------------------------------------------------------
  const tagElements = businessTags.filter(tag =>
    props.selectedTags?.includes(tag.fullPath)).map(tag => (
      <BusinessTag
        key={tag.fullPath}
        tag={tag}
        onDelete={onDelete}
      />
    ));
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      {tagElements}
    </React.Fragment>
  );
}