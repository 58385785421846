import { useContext, useEffect, useState } from "react";
import CombineStyles from "../../../../../utils/combineStyles";
import Checkbox from "../../../../common/checkbox/Checkbox";
import GetCaption from "../../../../common/functions/GetCaption";
import IconButton from "../../../../common/icon-button/IconButton";
import { TUiIconButton } from "../../../../common/icon-button/TUiIconButton";
import Icon, { IconSize } from "../../../../common/icon/Icon";
import { TUiMenuOption } from "../../../../common/menu/menu-content/menu-option/TUiMenuOption";
import Toggler from "../../../../common/toggler/Toggler";
import styles from "./BusinessSearchSectionHeader.module.css";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";

const optionEdit: TUiIconButton = {
  id: "EditTags",
  directoryId: "",
  elementType: "",
  iconFile: "pencil",
  hint: undefined,
  visible: true,
  disabled: false,
  inline: false,
  action: undefined
}

interface IProps {
  ui: TUiMenuOption;
  switchedOn: boolean;
  collapsed: boolean;
  editDisabled: boolean;
  onStateUpdate: (switchedOn: boolean) => void;
  onExpand: () => void;
  onCollapse: () => void;
  onEdit?: () => void;
}

export default function BusinessSearchSectionHeader(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //const [switchedOn, setSwitchedOn] = useState(false);
  //console.log(props.collapsed)
  //---------------------------------------------------------------------------
  useEffect(() => {
    //setSwitchedOn(props.switchedOn)
  }, [props.switchedOn]);
  //---------------------------------------------------------------------------
  const switchState = () => {
    if (props.switchedOn) {
      //console.log("switched on")
      //setSwitchedOn(false);
      if (!props.collapsed)
        props.onCollapse();
    } else {
      //console.log("switched off")
      //setSwitchedOn(true);
      if (props.collapsed)
        props.onExpand();
    };
    props.onStateUpdate(!props.switchedOn);
  };
  //---------------------------------------------------------------------------
  const onEdit = () => {
    if (props.onEdit)
      props.onEdit();
  }
  //---------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    !props.switchedOn ? styles.switchedOff : ""
  ]);
  //---------------------------------------------------------------------------
  return (
    <div
      className={containerStyles}
      style={{
        //borderBottom: props.collapsed ? '1px solid var(--clr_main_base)' : undefined,
        //borderRadius: props.collapsed ? '5px' : '5px 5px 0 0' 
      }}
    >
      <div className={styles.enabler}>
        <Checkbox
          collapseRightMargin={true}
          checked={props.switchedOn}
          onClick={switchState}
        />
      </div>
      <div className={styles.caption}>
        {props.ui.iconFile &&
          <Icon
            imageName={props.ui.iconFile}
            size={IconSize.Small}
            reserveSpace={true}
          />}
        <div className={styles.textWrapper}>
          {GetCaption(appUiContext, props.ui.id, props.ui.caption)}
        </div>
      </div>
      <div className={styles.options}>
        <div className={styles.option} onClick={onEdit}>
          <IconButton
            ui={{ ...optionEdit, disabled: props.editDisabled }}
            inline={false}
          />
        </div>
        <div className={styles.option}>
          <Toggler
            collapsed={props.collapsed}
            onExpand={props.switchedOn ? props.onExpand : undefined}
            onCollapse={props.switchedOn ? props.onCollapse : undefined}
          />
        </div>
      </div>
    </div>
  );
}