import { useContext } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { AdCampaign } from '../../../business-space/business-editor/ad-campaign/AdCampaign';
import GetCaption from '../../functions/GetCaption';
import styles from './AdPagesList.module.css';
import { IUiListItemAdPage } from './IUiListItemAdPage';
import AdPageListItem from './adpages-list-item/AdPageListItem';
import { IAdLanguageSetListItem } from '../../../ad-content-editor/IAdLanguageSet';

interface IProps {
  ui: IUiListItemAdPage;
  //data: AdCampaign;
  data?: IAdLanguageSetListItem[];
  onAdPageOpen: (adPageId: string) => void;
  onAdPageCreate?: () => void; // These funcs can be not provided if it's readonly mode
  onAdPageOptionSelect?: (adPageId: string, selectedOptionId: string) => void;
}

export default function AdPagesList(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const optionIdNewAdPage = "AdPageEditor.MenuContent.OptionCreate";
  const addFirstAdPageOption = props.ui.menuContent.options.find(option => option.id == optionIdNewAdPage);
  //---------------------------------------------------------------------------
  const onAdPageCreate = () => {
    props.onAdPageCreate && props.onAdPageCreate();
  }
  //---------------------------------------------------------------------------
  const listItems = props.data?.map(adPage => {
    let optionSelectHandler = undefined;
    if (props.onAdPageOptionSelect) {
      // Provide handler only if it's provided by parent component
      // otherwise we are in read-only mode
      optionSelectHandler = function(selectedOptionId: string) {
        props.onAdPageOptionSelect && props.onAdPageOptionSelect(adPage.id, selectedOptionId);
      };
    };
    return (
      <AdPageListItem
        key={adPage.id}
        ui={props.ui}
        data={adPage}
        onAdPageOpen={() => props.onAdPageOpen(adPage.id)}
        onAdPageOptionSelect={optionSelectHandler}
      />)
  });
  //---------------------------------------------------------------------------
  if (props.onAdPageCreate) return (
    <div className={styles.container}>
      {listItems && listItems.length > 0 ?
        listItems
        :
        <div
          className={styles.addPageOption}
          onClick={onAdPageCreate}
        >
          {addFirstAdPageOption && GetCaption(appUiContext, addFirstAdPageOption.id, addFirstAdPageOption.caption)}
        </div>}
    </div>
  ); else return (
    <div className={styles.container}>
      {listItems}
    </div>
  );
}