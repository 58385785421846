import { apiGet, checkResponse } from "../../utils/api";
import { apiBasePath, getApplicationLocale } from "../../utils/apiPathConstant";
import { stringFormatter } from "../../utils/common";

export default function getAppLocale(
  localeId?: string,
  abortSignal?: AbortSignal
): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!localeId)
      return reject("Cannot get app locale: locale id is not provided");
    apiGet(
      `${apiBasePath}${stringFormatter(getApplicationLocale, [localeId])}`,
      abortSignal
    ).then(response => {
      checkResponse(response, "getAppLocale");
      const appLocale = response.content.applicationLocale;
      resolve(appLocale);
    }).catch(error =>
      reject(error)
    );
  });
}