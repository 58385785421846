import CombineStyles from '../../../../../utils/combineStyles';
import { IRegionalHomepagesListItem } from './IRegionalHomepagesListItem';
import styles from './RegionalHomepagesListItem.module.css';
import { useContext } from 'react';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../../common/functions/GetName';
import React from 'react';
import Icon from '../../../../common/icon/Icon';
import AdStats from '../../../../common/business/ad-stats/AdStats';

interface IProps {
  data: IRegionalHomepagesListItem;
  onSelect: (businessId: string) => void;
  onCreate?: (regionId: string) => void;
}

export default function RegionalHomepagesListItem(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  let statusString = undefined;
  if (!props.data.status) {
    statusString = "Unavailable for all users";
  } else if (!props.data.status.isAvailableToStaffUsers) {
    statusString = "Unavailable for staff users";
  };
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (props.data.businessId) {
      props.onSelect(props.data.businessId);
    };
  };
  //---------------------------------------------------------------------------
  const onCreate = () => {
    props.onCreate && props.onCreate(props.data.regionId);
  };
  //---------------------------------------------------------------------------
  const regionName = appUiContext.dictionaries?.supportedRegions?.find(r => r.id == props.data.regionId)?.name;
  const urls = props.data.paths?.join(', ');
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        !props.data.status?.isAvailableToStaffUsers ? styles.disabled : ""
      ])}
      onClick={onSelect}>

      <div className={styles.details}>

        <div
          className={CombineStyles([
            styles.name,
            !props.data.status?.isAvailableToStaffUsers ? styles.nameDisabled : ""
          ])}
        >
          {GetName(appUiContext, props.data.regionId, regionName)}
          <span style={{ color: 'var(--clr_font_system)', fontWeight: "normal" }}>
            &nbsp;({props.data.regionId})
          </span>
        </div>

        {props.data.businessId ?
          <div>
            <span className={styles.caption}>URL(s): </span>
            <span>{urls}</span>
          </div>
          :
          props.onCreate && props.data.status?.isAvailableToStaffUsers == true &&
          <div
            className={styles.link}
            onClick={onCreate}
          >
            Create Regional Homepage
          </div>}

      </div>

      <div
        title='Ads'
        className={styles.stats}>
        <AdStats data={props.data.adStats} />
      </div>

      <div
        title={statusString}
        className={CombineStyles([
          styles.status,
          !props.data.status ? styles.unavailable : "",
          (props.data.status && !props.data.status.isAvailableToStaffUsers) ? styles.unavailableForStaff : ""
        ])}>
        <Icon imageName="lock" />
      </div>

    </div>
  );
}