import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import ConsoleTabLayout from '../../../../common/console-layout/console-tab-layout/ConsoleTabLayout';
import styles from './DirectoryEditorWrapper.module.css';
import { IUiDirectoryEditor } from '../IUiDirectoryEditor';
import { IUiOption } from '../../../../common/options/IUiOption';
import FormOptions from '../../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import DirectoryEditorHeader from './directory-editor-header/DirectoryEditorHeader';
import { Locker } from '../../../../common/locks/Locker';
import PopupMessage from '../../../../common/popup-v2/popup-message/PopupMessage';
import useNavigateWithContext from '../../../../../hooks/useNavigateWithContext';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { DirectoryEditorContext } from '../../../../../context/directory-editor-context/DirectoryEditorContextProvider';
import { TUiBreadcrumb } from '../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import useBeforeUnload from '../../../../../hooks/useBeforeUnload';
import { IUiInteractiveForm } from '../../../../common/forms/IUiInteractiveForm';
import GetCaption from '../../../../common/functions/GetCaption';
import GetHint from '../../../../common/functions/GetHint';
import DirectoryEditorFilter from '../directory-editor-filter/DirectoryEditorFilter';
import { ClassDirectoryEditorContext } from '../../../../../context/directory-editor-context/IDirectoryEditorContextData';

interface IConfirmationDialog {
  ui: IUiInteractiveForm;
  optionId?: "OptionEditModeDisable" | "OptionClose";
  message: string;
}

interface IProps {
  ui: IUiDirectoryEditor;
  gridTemplateColumns: string; // Depends on the number of columns in the grid
  isLoading: boolean;
  children: ReactNode;
  onSelectSourceLocale?: (selectedLocale?: string) => void;
  onDetailsEdit?: () => void;
  onOptionSelect: (optionId: string) => void;
}

export default function DirectoryEditorWrapper(props: IProps) {
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const { directoryEditorContext, directoryEditorContextDispatch } = useContext(DirectoryEditorContext);
  const navigate = useNavigateWithContext();
  useBeforeUnload(directoryEditorContext.editorState?.isUpdatedInSession); // Standard browser dialog when user tries to close the tab with unsaved changes
  const [breadcrumb, setBreadcrumb] = useState<TUiBreadcrumb>();
  const [confirmationDialog, setConfirmationDialog] = useState<IConfirmationDialog>();
  const wrapperDivRef = useRef<HTMLDivElement>(null);
  //---------------------------------------------------------------------------
  // Define grid columns template based on props
  //let gridTemplateColumns = 'minmax(2em, 30%) minmax(2em, 100%)'; // Id, Name
  //if (props.showItemAdditionalAttributes) gridTemplateColumns = gridTemplateColumns + ' minmax(2em, 100%)'; // Additional attributes
  //if (props.showItemStatus) gridTemplateColumns = gridTemplateColumns + ' min-content'; // Status
  //gridTemplateColumns = gridTemplateColumns + ' min-content'; // Badges
  //if (props.state?.editMode) gridTemplateColumns = gridTemplateColumns + ' 2em'; // Content menu
  //--------------------------------------------------------------------------- Breadcrumb state
  useEffect(() => {
    if (!directoryEditorContext.id) return;
    if (!directoryEditorContext.basePath) return;
    setBreadcrumb({
      id: directoryEditorContext.id,
      path: directoryEditorContext.basePath,
      caption: { en: directoryEditorContext.name }
    });
    if (location.pathname !== directoryEditorContext.basePath) {
      console.log(location.pathname, directoryEditorContext.basePath)
      window.history.replaceState(null, '', directoryEditorContext.basePath);
    };
  }, [directoryEditorContext.basePath, directoryEditorContext.name]);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    if (!breadcrumb) return;
    appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
  }, [breadcrumb]);
  //---------------------------------------------------------------------------
  const closeEditor = () => {
    // remove last item from the path!
    const pathItems: string[] = directoryEditorContext.basePath.split("/");
    pathItems.pop();
    const newPath = pathItems.join("/");
    navigate(newPath);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionEditModeDisable":
        if (directoryEditorContext.editorState?.isUpdatedInSession) {
          setConfirmationDialog({
            ui: props.ui.unsavedChangesDialog,
            optionId: optionId,
            message: 'Please confirm you are going to discard unsaved changes'
          });
        } else {
          directoryEditorContextDispatch({ type: "DisableEditMode" });
        };
        break;
      case "OptionClose":
        if (directoryEditorContext.editorState?.isUpdatedInSession) {
          setConfirmationDialog({
            ui: props.ui.unsavedChangesDialog,
            optionId: optionId,
            message: 'Please confirm you are going to discard unsaved changes'
          });
        } else {
          closeEditor();
        };
        break;
      default:
        props.onOptionSelect(optionId);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onConfirmationDialogOptionSelect = (optionId: string) => {
    setConfirmationDialog(undefined);
    switch (optionId) {
      case "optionOk":
        switch (confirmationDialog?.optionId) {
          case "OptionEditModeDisable":
            directoryEditorContextDispatch({ type: "DisableEditMode" });
            break;
          case "OptionClose":
            closeEditor();
            break;
        };
        break;
      case "optionCancel":
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onFilterUpdate = (updatedFilter: any) => {
    //directoryEditorContextDispatch({ type: "UpdateFilter", data: updatedFilter });
  };
  //--------------------------------------------------------------------------- Options
  var updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      //-----------------------------------------------------------------------
      case "OptionClose":
        updatedOptions.push({ ...option, iconId: "close" });
        break;
      //-----------------------------------------------------------------------
      case "OptionEditModeEnable":
        if (!directoryEditorContext?.editMode && !props.isLoading) {
          updatedOptions.push({ ...option, iconId: "pencil" });
        };
        break;
      //-----------------------------------------------------------------------
      case "OptionEditModeDisable":
        if (directoryEditorContext?.editMode && !props.isLoading) {
          updatedOptions.push({ ...option, iconId: "pencilOff", isDisabled: directoryEditorContext.editorState.isNew });
        };
        break;
      //-----------------------------------------------------------------------
      case "OptionAdd":
        if (directoryEditorContext?.editMode && !props.isLoading && !directoryEditorContext.editorState.draft.rootIsLocked) {
          updatedOptions.push({ ...option, iconId: "plus" });
        };
        break;
      //-----------------------------------------------------------------------
      case "OptionSave":
        if (directoryEditorContext?.editMode && !props.isLoading) {
          updatedOptions.push({
            ...option,
            isDisabled: !directoryEditorContext?.editorState.isUpdatedInSession,
            isDefault: directoryEditorContext.editorState.isUpdatedInSession
          });
        };
        break;
      //-----------------------------------------------------------------------
      case "OptionDelete":
        if (directoryEditorContext?.editMode && !props.isLoading && !!directoryEditorContext.editorState.draft.summary.savedAt) {
          updatedOptions.push({ ...option, iconId: "delete" });
        };
        break;
      //-----------------------------------------------------------------------
      case "OptionPublish":
        if (directoryEditorContext.editMode && !props.isLoading) {
          updatedOptions.push({
            ...option,
            iconId: 'publish',
            isDisabled: !directoryEditorContext.editorState.isUpdated || !directoryEditorContext.editorState.isValid || directoryEditorContext.editorState.isUpdatedInSession
          });
        };
        break;
    }
  });
  const editorOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      optionIdVisibleOnMobile="optionClose"
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <ConsoleTabLayout
      toolbar={editorOptions}
      allowScroll={true}
      isLoading={props.isLoading}
    >
      {directoryEditorContext.id &&
        <div ref={wrapperDivRef} className={styles.container}>

          <div className={styles.header}>
            <DirectoryEditorHeader
              onSelectSourceLocale={props.onSelectSourceLocale}
              onDetailsEdit={props.onDetailsEdit}
            />
            <Locker
              locker={directoryEditorContext.editorState.draft?.locker}
              inSession={directoryEditorContext.editMode}
            />
            <DirectoryEditorFilter
              //editorContext={directoryEditorContext}
              //onUpdate={onFilterUpdate}
            />

            {/* <DictionaryEditorFilter
            ui={{searchInput: {
              id: '',
              directoryId: '',
              elementType: '',
              disabled: false,
              visible: true,
              caption: undefined,
              hint: undefined,
              placeholder: 'Type search keyword here...',
              defaultValue: '',
              validation: undefined,
            }}}
            data={{draftState: DraftItemStateEnum.All, stateGroups: props.state?.filter?.stateGroups}}
            showGroupingsSelector={props.setup.mode == "Editor"}
            onUpdate={props.onFilterUpdate}
          /> */}
          </div>

          <div
            className={styles.list}
            style={{ gridTemplateColumns: props.gridTemplateColumns }}
          >
            {props.children}
          </div>

          {confirmationDialog &&
            <PopupMessage
              id={confirmationDialog.ui.id}
              type='Confirmation'
              header={`${directoryEditorContext.id}: ${GetCaption(appUiContext, confirmationDialog.ui.id, confirmationDialog.ui.caption)}`}
              message={GetHint(appUiContext, confirmationDialog.ui.id, confirmationDialog.ui.hint)}
              optionsMenuContent={getTUiMenuContent(confirmationDialog.ui.menuContent)}
              show={!!confirmationDialog}
              onOptionSelect={onConfirmationDialogOptionSelect}
              onCancel={() => onConfirmationDialogOptionSelect("optionCancel")}
            />}

        </div>}
    </ConsoleTabLayout>
  );
}