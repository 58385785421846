import styles from "./ApplicationHeader.module.css";
import useNavigateWithContext from "../../../hooks/useNavigateWithContext";
import MainMenuButton from "./main-menu/main-menu-button/MainMenuButton";
import RikiWikiLogo from "./rikiwiki-logo/RikiWikiLogo";
import UserMenu from "./user-menu/UserMenu";
import { ScreenType, useAppScreenContext } from "../../../context/app-screen-context/AppScreenProvider";
import CombineStyles from "../../../utils/combineStyles";
import { IUiApplicationHeader } from "./IUiApplicationHeader";

interface IProps {
  ui?: IUiApplicationHeader;
}

export default function ApplicationHeader(props: IProps) {
  const screenType = useAppScreenContext();
  const navigate = useNavigateWithContext();
  //---------------------------------------------------------------------------
  const onLogoSelect = () => {
    navigate("/");
  };
  //---------------------------------------------------------------------------
  if (props.ui)
    return (
      <header className={CombineStyles([
        styles.container,
        screenType == ScreenType.Mobile ? styles.mobile : ""
      ])}>

        <div className={styles.topLine}>
          <MainMenuButton
          //selectedOptionId={selectedOptionId}
          //onOptionSelect={onOptionSelect}
          />
          <RikiWikiLogo onClick={onLogoSelect} />
          <UserMenu ui={props.ui.userMenuContent} />
        </div>

      </header>
    );
  else return null;
}
