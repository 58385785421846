import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathDeleteAdCampaignSystemBusinessRegional } from "../../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../../utils/common";
import { apiDeletePrivate, checkResponse } from "../../../../../utils/api";
import { ClassSystemBusinessRegional } from "../ISystemBusinessRegional";
import { EHttpStatusCode } from "../../../../../utils/HttpStatusCodes";

interface IProps {
  msalInstanse: IPublicClientApplication,
  authConfig?: IAuthConfig,
  businessId: string,
  adCampaignId: string,
  abortSignal?: AbortSignal
}

export default async function deleteSystemBusinessAdCampaignAsync(props: IProps): Promise<ClassSystemBusinessRegional> {
  if (!props.authConfig) throw new Error("deleteSystemBusinessAdCampaignAsync: auth config is not provided");
  if (!props.businessId) throw new Error("deleteSystemBusinessAdCampaignAsync: business Id is not provided");
  //---------------------------------------------------------------------------
  const uri = `${apiBasePath}${stringFormatter(pathDeleteAdCampaignSystemBusinessRegional, [props.businessId, props.adCampaignId])}`;
  const response = await apiDeletePrivate(
    props.msalInstanse,
    props.authConfig,
    uri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "deleteSystemBusinessAdCampaign", props.abortSignal?.aborted, [EHttpStatusCode.NotFound]);
  const business = new ClassSystemBusinessRegional(response?.content);
  return business;
};