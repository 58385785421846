import { iconsSrc } from "../../../uielements/icons";

interface IProps {
  imageName: string;
}

export default function IconInline(props: IProps) {
  return (
    <span
      style={{
        padding: '0 3px 0 3px',
        verticalAlign: 'middle'
      }}>
      <span
        className="iconify"
        data-icon={iconsSrc[props.imageName]}>
      </span>
    </span>
  );
}