interface IProps<T> {
  propertiesToCheck: string[];
  items: T[];
  itemsInitial: T[];
  itemsPublished: T[];
  validator: (item: T) => boolean;
}
interface IResult {
  isUpdatedInSession: boolean;
  isUpdated: boolean;
  isValid: boolean;
}

export function CheckItems<T>(props: IProps<T>): IResult {
  let isUpdatedInSession = false;
  let isUpdated = false;
  let isValid = true;
  //---------------------------------------------------------------------------
  if (props.items?.length != props.itemsInitial?.length) {
    isUpdatedInSession = true;
  };
  //---------------------------------------------------------------------------
  if (props.items?.length != props.itemsPublished?.length) {
    isUpdated = true;
  };
  //---------------------------------------------------------------------------
  if (props.items) {
    type ObjectKey = keyof T;
    // Loop through items
    for (let i = 0; i < props.items.length; i++) {
      //-----------------------------------------------------------------------
      // Check validity
      if (isValid) {
        const itemIsValid = props.validator(props.items[i]);
        if (!itemIsValid)
          isValid = false;
      };
      //-----------------------------------------------------------------------
      // If it's not already considered as changed
      if (!isUpdatedInSession || !isUpdated) {
        // Loop through properties we need to check
        for (let j = 0; j < props.propertiesToCheck.length; j++) {
          const key = props.propertiesToCheck[j] as ObjectKey;
          const item = props.items[i][key];  
          const itemInitial = (props.itemsInitial.length > i) ? props.itemsInitial[i][key] : undefined;
          // itemsPublished would be null if the item is new
          const itemsPublished = (props.itemsPublished?.length > i) ? props.itemsPublished[i][key] : undefined;
          if (props.propertiesToCheck[j] == 'items') {
            // If the items have hierarchical structure
            const subItems = (item as unknown) as [];
            const subItemsInitial = (itemInitial as unknown) as [];
            const subItemsPublished = (itemsPublished as unknown) as [];
            const subItemsResult = CheckItems({
              propertiesToCheck: props.propertiesToCheck,
              items: subItems,
              itemsInitial: subItemsInitial,
              itemsPublished: subItemsPublished,
              validator: props.validator
            });
            if (subItemsResult.isUpdatedInSession) isUpdatedInSession = true;
            if (subItemsResult.isUpdated) isUpdated = true;
            if (!subItemsResult.isValid) isValid = false;
          } else {
            //-----------------------------------------------------------------
            // If it's not sub-items, just compare the values
            if (JSON.stringify(item) != JSON.stringify(itemInitial)) {
              isUpdatedInSession = true;
            };
            if (JSON.stringify(item) != JSON.stringify(itemsPublished)) {
              isUpdated = true;
            };
          };
        };
      };
    };
  };
  //---------------------------------------------------------------------------
  return {
    isUpdatedInSession: isUpdatedInSession,
    isUpdated: isUpdated,
    isValid: isValid
  };
}

export default function getItemsAreChanged<T>(
  propertiesToCheck: string[],
  items?: T[],
  itemsInitial?: T[]
): boolean {
  let result = false;
  if (items?.length != itemsInitial?.length) {
    result = true;
  } else if (items && itemsInitial) {
    //-------------------------------------------------------------------------
    type ObjectKey = keyof T;
    // Loop through items
    for (let i = 0; i < items.length; i++) {
      // Loop through properties we need to check
      for (let j = 0; j < propertiesToCheck.length; j++) {
        const key = propertiesToCheck[j] as ObjectKey;
        const item = items[i][key];
        const itemInitial = itemsInitial[i][key];
        //---------------------------------------------------------------------
        if (propertiesToCheck[j] == 'items') {
          // If items have hierarchical structure
          const itemsArray = (item as unknown) as [];
          const itemsInitialArray = (itemInitial as unknown) as [];
          const itemsLength = itemsArray?.length;
          const itemsInitialLength = itemsInitialArray?.length;
          if (itemsLength !== itemsInitialLength) {
            result = true;
            break;
          } else if (itemsLength > 0) {
            // Compare items one by one
            result = getItemsAreChanged<T>(propertiesToCheck, itemsArray, itemsInitialArray);
          };
        } else {
          // If it's not items, just compare the values
          if (JSON.stringify(item) != JSON.stringify(itemInitial)) {
            result = true;
            break;
          };
        };
      };
      // If we already now there are changes, just stop
      if (result)
        break;
    };
  };
  return result;
}