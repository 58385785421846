import { ReactNode, useRef } from 'react';
import styles from './ValueWrapper.module.css';
import ValidationWrapper from '../../validation-wrapper/ValidationWrapper';
import { IValidationState } from '../input-validation/IUiValidation';

export interface IValidationMessage {
  id: string;
  text: any; // Text
}

interface IProps {
  children: ReactNode;
  disabled?: boolean;
  hint?: string;
  hideHint?: boolean;
  validation?: IValidationState;
}

export default function ValueWrapper(props: IProps) {
  // ValueWrapper displays hint and validation messages
  //const isValid = !props.validation || (props.validation && props.validation.isValid) || props.disabled;
  //const disabled = !!props.disabled;
  const hintDivRef = useRef<HTMLDivElement>(null);
  const hint = props.hideHint ? undefined : props.hint;
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <ValidationWrapper validationState={props.validation}>
        {props.children}
      </ValidationWrapper>

      {hint &&
        <div
          ref={hintDivRef}
          className={styles.hint}
        >
          {hint}
        </div>}

    </div>
  );
}