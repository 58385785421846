import { IUiValidation, IValidationState } from "./IUiValidation";
import { ValidateDropdownInput, ValidateInput } from "./ValidateInput";

export type EInputType = "InputText" | "InputDropdown";

export interface IInputValidator {
  elementId: string;
  inputType: EInputType;
  validation?: IUiValidation;
}

export class InputValidator implements IInputValidator {
  elementId!: string;
  inputType!: EInputType;
  validation?: IUiValidation;
  //---------------------------------------------------------------------------
  constructor(source: IInputValidator) {
    Object.assign(this, source);
  };
  //---------------------------------------------------------------------------
  validate(data?: string | number, isUnique?: boolean): IValidationState {
    let validationState: IValidationState = {
      isValid: true,
      validationMessages: []
    };
    //-------------------------------------------------------------------------
    switch (this.inputType) {
      case "InputText":
        validationState = ValidateInput({
          elementId: this.elementId,
          validation: this.validation,
          data: data,
          isUnique: isUnique
        });
        break;
      case "InputDropdown":
        validationState = ValidateDropdownInput({
          elementId: this.elementId,
          validation: this.validation,
          data: data,
          isUnique: isUnique
        });
        break;
    };
    //-------------------------------------------------------------------------
    return validationState;
  };
}