import { IListColumn } from "../../components/common/lists/IListColumn";
import { IFilterItem, IFilterItemOption } from "../../components/common/lists/filter-editor/IFilterItem";
import { ISortStep, ISorterItem } from "../../components/common/lists/sorter-editor/ISorterItem";
import { IUiOption } from "../../components/common/options/IUiOption";
import { clearFromSessionStorage, getFromSessionStorage, saveToSessionStorage } from "../../utils/sessionStorage";
import { IUiListColumnsManager, uiListColumnsManager } from "./IUiListColumnsManager";
import { Actions } from "./ListManagerContextReducer";
//---------------------------------------------------------------------------------
export enum EManagerMode {
  Default = 0,
  Basic = 1,
  Advanced = 2,
}
export type EListMode = "OptionListModeList" | "OptionListModeGrid";
//----------------------------------------------------------------------------
const logging = false;

export interface IFilterDynamicOptions {
  columnId: string;
  options: IFilterItemOption[];
}

export interface IPresets {
  options: IPreset[];
  currentOptionId: string;
}
export interface IPreset {
  id: string;
  name: string; // Here should be translations
}

// For saving in session storage
export interface IListManagerContextStore {
  listColumns: IListColumn[];
  managerMode: EManagerMode;
  listModes?: EListMode[]; // If not provided these options won't appear in manager menu. It has to be at least two options
  currentListMode?: EListMode; // Should be provided if listModes are provided, otherwise it would be just first mode in the list
  sortPresets?: IPresets;
  filterPresets?: IPresets;
}

// Base interface for the context data
export interface IListManagerContextConfiguration extends IListManagerContextStore {
  listId: string;
}

export interface IListManagerContextData extends IListManagerContextConfiguration {
  ui: IUiListColumnsManager;
  filterableColumns: IListColumn[];
  sortableColumns: IListColumn[];
  sortableColumnsForSorting: IListColumn[];
  sortSteps: ISortStep[];
  leadingSortColumn?: IListColumn;
  selectedSortColumn?: IListColumn;
  defaultSortColumn?: IListColumn;
  lastAction?: Actions;
  availableOptions: IUiOption[];
  clearedFilters: ISavedFilter[];
  //---------------------------------------------------------------------------
  getColumn(columnId: string, columns: IListColumn[]): IListColumn | undefined;
  restoreDefaults(): IListManagerContextData;
  addFilterDynamicOptions(dynamicOptions: IFilterDynamicOptions[]): IListManagerContextData;
  updateFilterOption(columnId: string, filterItemOptionId: string): IListManagerContextData;
  updateFilterKeyword(columnId: string, keyword: string): IListManagerContextData;
  updateFilterDatetimeStart(columnId: string, datetime: string): IListManagerContextData;
  updateFilterDatetimeEnd(columnId: string, datetime: string): IListManagerContextData;
  clearFilter(columnId: string): IListManagerContextData;
  restoreFilter(columnId: string): IListManagerContextData;
  updateSorter(updatedColumn: IListColumn): IListManagerContextData;
  updateTwoColumns(firstColumn: IListColumn, secondColumn: IListColumn): IListManagerContextData;
  saveToStorage(data: IListManagerContextData): void;
  setManagerMode(value: EManagerMode): void;
}

export class ListManagerContextData implements IListManagerContextData {
  listId!: string;
  ui!: IUiListColumnsManager;
  managerMode: EManagerMode = EManagerMode.Basic;
  listColumns!: IListColumn[];
  sortPresets?: IPresets;
  filterPresets?: IPresets;
  lastAction?: Actions;
  //--------------------------------------------------------------------------- Properties
  get filterableColumns(): IListColumn[] {
    if (!this.listColumns || this.listColumns.length == 0) return [];
    return this.listColumns.filter(column => column.filter);
  };
  //---------------------------------------------------------------------------
  get sortableColumns(): IListColumn[] {
    if (!this.listColumns || this.listColumns.length == 0) return [];
    const sortableColumns = this.listColumns
      .filter(column => column.sorter?.order !== undefined)
      .sort((a, b) => {
        return (a.sorter?.order !== undefined && b.sorter?.order !== undefined) ?
          a.sorter.order - b.sorter.order :
          0
      });
    return (sortableColumns);
  };
  //---------------------------------------------------------------------------
  get sortableColumnsForSorting(): IListColumn[] {
    // Reverse because last we need sort by the first column
    const sortableColumns = this.sortableColumns.reverse();
    return (sortableColumns);
  };
  //---------------------------------------------------------------------------
  get sortSteps(): ISortStep[] {
    let columns = this.sortableColumnsForSorting;
    let result: ISortStep[] = [];
    for (let column of columns) {
      if (column.sorter?.sort) {
        result.push({
          propertyName: column.id,
          sortType: column.sorter.sort
        });
      }
    }
    return result;
  };
  //---------------------------------------------------------------------------
  get leadingSortColumn(): IListColumn | undefined {
    const sortableColumns = this.sortableColumns;
    if (sortableColumns && sortableColumns.length > 0) {
      return sortableColumns[sortableColumns.length - 1];
    }
    return undefined;
  };
  //---------------------------------------------------------------------------
  selectedSortColumn?: IListColumn;
  //---------------------------------------------------------------------------
  get defaultSortColumn(): IListColumn | undefined {
    return this.listColumns?.find(column => column.sorter?.isDefault);
  };
  //---------------------------------------------------------------------------
  listModes?: EListMode[];
  currentListMode?: EListMode;
  //---------------------------------------------------------------------------
  availableOptions: IUiOption[] = [];
  clearedFilters: ISavedFilter[] = [];
  //--------------------------------------------------------------------------- Private
  private listColumnsInitial!: IListColumn[]; // without dynamic filter options
  private dynamicFilterOptions!: IFilterDynamicOptions[];
  private sessionStorageKey!: string;
  //--------------------------------------------------------------------------- Constructor
  constructor(data?: IListManagerContextData) {
    if (!data) return;
    if (!data.listColumns) return;
    //-----------------------------------------------------------------------
    Object.assign(this, data);
    this.ui = uiListColumnsManager; // temporary static data, it have to be requested from backend in provider useEffect
    this.listColumns = ListManagerContextData.getCopy(data.listColumns);
    this.sessionStorageKey = `clmngr.${this.listId}`;
    //-----------------------------------------------------------------------
    // Try to get session data
    this.tryToRestoreFromStorage();
    //-----------------------------------------------------------------------
    if (!this.listColumnsInitial) {
      //logging && console.log("setting initial data...", data.listColumns)
      // Reset initial data
      this.listColumnsInitial = ListManagerContextData.getCopy(data.listColumns);
    };
    if (!this.selectedSortColumn)
      this.selectedSortColumn = this.defaultSortColumn;
    //-----------------------------------------------------------------------
    // If default list mode is not provided, just set the first option as current value
    if (this.listModes && this.listModes.length > 0 && !this.currentListMode)
      this.currentListMode = this.listModes[0];
    //-----------------------------------------------------------------------
    // Set available options
    this.setAvailableOptions();
  };
  //--------------------------------------------------------------------------- Static Methods
  static getCopy(listColumns: IListColumn[]): IListColumn[] {
    const listCopy: IListColumn[] = [];
    listColumns.forEach(column => {
      const filterOptionsCopy: IFilterItemOption[] = [];
      column.filter?.options?.forEach(fo => {
        const optionCopy: IFilterItemOption = { ...fo };
        filterOptionsCopy.push(optionCopy);
      });
      //-----------------------------------------------------------------------
      const columnCopy: IListColumn = {
        ...column,
        filter: column.filter ? {
          ...column.filter as IFilterItem,
          options: filterOptionsCopy
        } : undefined,
        sorter: column.sorter ? { ...column.sorter as ISorterItem } : undefined
      };
      listCopy.push(columnCopy);
    });
    return listCopy;
  };
  //--------------------------------------------------------------------------- Methods
  restoreDefaults(): IListManagerContextData {
    //const updatedColumns: IListColumn[] = [];
    this.clearStorage();
    this.listColumns = ListManagerContextData.getCopy(this.listColumnsInitial);
    if (this.dynamicFilterOptions) {
      const dynamicOptionsCopy = JSON.parse(JSON.stringify(this.dynamicFilterOptions));
      return this.addFilterDynamicOptions(dynamicOptionsCopy);
    };
    return { ...this };
  };
  //---------------------------------------------------------------------------
  getColumn(columnId: string, columns: IListColumn[]): IListColumn | undefined {
    return columns.find(lc => lc.id == columnId);
  };
  //---------------------------------------------------------------------------
  addFilterDynamicOptions(dynamicOptions: IFilterDynamicOptions[]): IListManagerContextData {
    this.dynamicFilterOptions = dynamicOptions;
    //-------------------------------------------------------------------------
    // Update columns with dynamic options
    let updatedColumns = this.listColumns.map(c => c);
    dynamicOptions.forEach(dynamicOptions => {
      const columnToUpdate = this.getColumn(dynamicOptions.columnId, this.listColumns);
      const columnInitial = this.getColumn(dynamicOptions.columnId, this.listColumnsInitial);
      if (columnToUpdate?.filter && columnInitial?.filter) {
        //logging && console.log(columnInitial?.filter)
        // Get initial options
        const initialOptions = columnInitial.filter.options ? columnInitial.filter.options : [];
        // Update with dynamic options
        const updatedOptions = initialOptions.concat(dynamicOptions.options);
        //---------------------------------------------------------------------
        const updatedFilter: IFilterItem = {
          ...columnToUpdate.filter as IFilterItem,
          options: updatedOptions
        };
        //---------------------------------------------------------------------
        const updatedColumn: IListColumn = {
          ...columnToUpdate,
          filter: updatedFilter
        };
        updatedColumns = updatedColumns.map(c => c.id == dynamicOptions.columnId ? updatedColumn : c);
      } else {
        console.error(`TListColumnsManager: There is no column with id=[${dynamicOptions.columnId}] or it has no filter`);
      };
    });
    //-------------------------------------------------------------------------
    const updatedData: IListManagerContextData = {
      ...this,
      listColumns: updatedColumns
    };
    return updatedData;
  };
  //---------------------------------------------------------------------------
  updateFilterOption(columnId: string, filterItemOptionId: string): IListManagerContextData {
    const columnToUpdate = this.getColumn(columnId, this.listColumns);
    if (!columnToUpdate || !columnToUpdate.filter || !columnToUpdate.filter.options) return this;
    const optionToUpdate = columnToUpdate.filter?.options?.find(o => o.id == filterItemOptionId);
    if (!optionToUpdate) return this;
    //-------------------------------------------------------------------------
    const newValue = !optionToUpdate.isSelected;
    const updatedOption: IFilterItemOption = {
      ...optionToUpdate,
      isSelected: newValue
    };
    let updatedOptions = columnToUpdate.filter.options.map(o => o.id == filterItemOptionId ? updatedOption : o);
    if (columnToUpdate.filter.validator) {
      // If filter item validator is provided, use it apply filtering rules
      updatedOptions = columnToUpdate.filter.validator(updatedOption.id, newValue, updatedOptions);
    };
    logging && console.log(updatedOptions)
    //-------------------------------------------------------------------------
    const updatedFilter: IFilterItem = {
      ...columnToUpdate.filter,
      options: updatedOptions
    };
    //-------------------------------------------------------------------------
    const updatedColumn: IListColumn = {
      ...columnToUpdate,
      filter: updatedFilter
    };
    const updatedColumns = this.listColumns.map(c => c.id == columnId ? updatedColumn : c);
    //-------------------------------------------------------------------------
    const updatedData: IListManagerContextData = {
      ...this,
      listColumns: updatedColumns
    };
    this.saveToStorage(updatedData);
    return updatedData;
  };
  //---------------------------------------------------------------------------
  updateFilterKeyword(columnId: string, keyword: string): IListManagerContextData {
    const columnToUpdate = this.getColumn(columnId, this.listColumns);
    if (!columnToUpdate || !columnToUpdate.filter) return this;
    //-------------------------------------------------------------------------
    const updatedFilter: IFilterItem = {
      ...columnToUpdate.filter,
      keyword: keyword
    };
    //-------------------------------------------------------------------------
    const updatedColumn: IListColumn = {
      ...columnToUpdate,
      filter: updatedFilter
    };
    const updatedColumns = this.listColumns.map(c => c.id == columnId ? updatedColumn : c);
    //-------------------------------------------------------------------------
    const updatedData: IListManagerContextData = {
      ...this,
      listColumns: updatedColumns,
    };
    this.saveToStorage(updatedData);
    return updatedData;
  };
  //---------------------------------------------------------------------------
  updateFilterDatetimeStart(columnId: string, datetime: string): IListManagerContextData {
    const columnToUpdate = this.getColumn(columnId, this.listColumns);
    if (!columnToUpdate || !columnToUpdate.filter) return this;
    //-------------------------------------------------------------------------
    const updatedFilter: IFilterItem = {
      ...columnToUpdate.filter,
      dateTimeStart: datetime
    };
    //-------------------------------------------------------------------------
    const updatedColumn: IListColumn = {
      ...columnToUpdate,
      filter: updatedFilter
    };
    const updatedColumns = this.listColumns.map(c => c.id == columnId ? updatedColumn : c);
    //-------------------------------------------------------------------------
    const updatedData: IListManagerContextData = {
      ...this,
      listColumns: updatedColumns,
    };
    this.saveToStorage(updatedData);
    return updatedData;
  };
  //---------------------------------------------------------------------------
  updateFilterDatetimeEnd(columnId: string, datetime: string): IListManagerContextData {
    const columnToUpdate = this.getColumn(columnId, this.listColumns);
    if (!columnToUpdate || !columnToUpdate.filter) return this;
    //-------------------------------------------------------------------------
    const updatedFilter: IFilterItem = {
      ...columnToUpdate.filter,
      dateTimeEnd: datetime
    };
    //-------------------------------------------------------------------------
    const updatedColumn: IListColumn = {
      ...columnToUpdate,
      filter: updatedFilter
    };
    const updatedColumns = this.listColumns.map(c => c.id == columnId ? updatedColumn : c);
    //-------------------------------------------------------------------------
    const updatedData: IListManagerContextData = {
      ...this,
      listColumns: updatedColumns
    };
    this.saveToStorage(updatedData);
    return updatedData;
  };
  //---------------------------------------------------------------------------
  clearFilter(columnId: string): IListManagerContextData {
    const columnToUpdate = this.getColumn(columnId, this.listColumns);
    if (!columnToUpdate || !columnToUpdate.filter) return this;
    //-------------------------------------------------------------------------
    // Save filter state to restore it later
    this.clearedFilters.push({ columnId: columnId, filter: columnToUpdate.filter });
    //-------------------------------------------------------------------------
    let updatedFilter: IFilterItem | undefined = undefined;
    switch (columnToUpdate.filter.type) {
      case "Options":
        const updatedOptions = columnToUpdate.filter.options?.map(option => ({ ...option, isSelected: false }));
        updatedFilter = {
          ...columnToUpdate.filter as IFilterItem,
          options: updatedOptions
        };
        break;
      //-----------------------------------------------------------------------
      case "Keyword":
        updatedFilter = {
          ...columnToUpdate.filter as IFilterItem,
          keyword: ""
        };
        logging && console.log(updatedFilter)
        break;
      //-----------------------------------------------------------------------
      case "DatetimeRange":
        updatedFilter = {
          ...columnToUpdate.filter as IFilterItem,
          dateTimeStart: undefined,
          dateTimeEnd: undefined
        };
        break;
    };
    //-------------------------------------------------------------------------
    const updatedColumn: IListColumn = {
      ...columnToUpdate,
      filter: updatedFilter
    };
    const updatedColumns = this.listColumns.map(c => c.id == columnId ? updatedColumn : c);
    //-------------------------------------------------------------------------
    const updatedData: IListManagerContextData = {
      ...this,
      listColumns: updatedColumns
    };
    this.saveToStorage(updatedData);
    return updatedData;
  };
  //---------------------------------------------------------------------------
  restoreFilter(columnId: string): IListManagerContextData {
    const columnToUpdate = this.getColumn(columnId, this.listColumns);
    if (!columnToUpdate || !columnToUpdate.filter) return this;
    //-------------------------------------------------------------------------
    // Try to get storev filter
    const filterToRestore = this.clearedFilters.find(f => f.columnId == columnId);
    if (!filterToRestore) return this;
    //-------------------------------------------------------------------------
    //-------------------------------------------------------------------------
    const updatedColumn: IListColumn = {
      ...columnToUpdate,
      filter: filterToRestore.filter
    };
    const updatedColumns = this.listColumns.map(c => c.id == columnId ? updatedColumn : c);
    //-------------------------------------------------------------------------
    const updatedData: IListManagerContextData = {
      ...this,
      listColumns: updatedColumns
    };
    updatedData.clearedFilters = this.clearedFilters.filter(f => f.columnId != columnId);
    this.saveToStorage(updatedData);
    return updatedData;
  };
  //---------------------------------------------------------------------------
  updateSorter(updatedColumn: IListColumn): IListManagerContextData {
    const columnToUpdate = this.getColumn(updatedColumn.id, this.listColumns);
    if (!columnToUpdate || !columnToUpdate.sorter) return this;
    //-------------------------------------------------------------------------
    const updatedColumns = this.listColumns.map(c => c.id == updatedColumn.id ? updatedColumn : c);
    //-------------------------------------------------------------------------
    const updatedData: IListManagerContextData = {
      ...this,
      listColumns: updatedColumns,
      selectedSortColumn: updatedColumn,
    };
    this.saveToStorage(updatedData);
    return updatedData;
  };
  //---------------------------------------------------------------------------  
  updateTwoColumns(firstColumn: IListColumn, secondColumn: IListColumn): IListManagerContextData {
    const updatedColumns = this.listColumns.map(column => {
      if (column.id == firstColumn.id)
        return firstColumn;
      else if (column.id == secondColumn.id)
        return secondColumn;
      else
        return column;
    });
    //-------------------------------------------------------------------------
    const updatedData: IListManagerContextData = {
      ...this,
      listColumns: updatedColumns
    };
    this.saveToStorage(updatedData);
    return updatedData;
  };
  //---------------------------------------------------------------------------
  saveToStorage(data: IListManagerContextData) {
    const storageData: IListManagerContextStore = {
      managerMode: data.managerMode,
      listColumns: data.listColumns,
    }
    saveToSessionStorage(this.sessionStorageKey, storageData);
  };
  //---------------------------------------------------------------------------
  tryToRestoreFromStorage() {
    const storageData = getFromSessionStorage(this.sessionStorageKey) as IListManagerContextStore;
    if (!storageData)
      return;
    this.managerMode = storageData.managerMode;
    for (let column of this.listColumns) {
      const storedColumn = storageData.listColumns.find(sc => sc.id == column.id);
      if (!storedColumn)
        continue;
      if (column.filter) {
        column.filter.keyword = storedColumn.filter?.keyword;
        column.filter.dateTimeStart = storedColumn.filter?.dateTimeStart;
        column.filter.dateTimeEnd = storedColumn.filter?.dateTimeEnd;
        column.filter.options?.forEach(o => {
          const storedOption = storedColumn.filter?.options?.find(so => so.id == o.id);
          if (storedOption) {
            o.isSelected = storedOption.isSelected;
          };
        });
      };
      if (column.sorter) {
        column.sorter = storedColumn.sorter;
      };
    }
  };
  //---------------------------------------------------------------------------
  clearStorage() {
    clearFromSessionStorage(this.sessionStorageKey);
  };
  //---------------------------------------------------------------------------
  setAvailableOptions() {
    let availableOptions: IUiOption[] = this.ui.menuContent.options;
    //-------------------------------------------------------------------------
    // Remove options based on current manager mode
    switch (this.managerMode) {
      case EManagerMode.Default:
      case EManagerMode.Basic:
        // Filter out advanced sorting and filtering options
        availableOptions = availableOptions.filter(o => o.id != "OptionSort" && o.id != "OptionFilter");
        break;
      case EManagerMode.Advanced:
        // Filter out simplified sorting and filtering options
        availableOptions = availableOptions.filter(o => o.id != "OptionSortPresets" && o.id != "OptionFilterPresets");
        break;
    };
    //-------------------------------------------------------------------------
    if (!this.sortPresets && !this.filterPresets) {
      // Filter out switch manager mode options, if the simplified mode options are not provided
      availableOptions = availableOptions.filter(o => o.id != "OptionSetSimplifiedMode" && o.id != "OptionSetAndvancedMode");
    };
    //-------------------------------------------------------------------------
    if (this.listModes) {
      // Remove options not listed in available list modes
      availableOptions = availableOptions.filter(o => {
        if (o.id.startsWith("OptionListMode")) {
          return this.listModes?.includes(o.id as EListMode);
        } else
          return true;
      });
    } else {
      // If there are no list modes declared, remove the list mode options
      availableOptions = availableOptions.filter(o => !o.id.startsWith("OptionListMode"));
    };
    //-------------------------------------------------------------------------
    this.availableOptions = availableOptions;
  };
  //---------------------------------------------------------------------------
  setManagerMode(value: EManagerMode): IListManagerContextData {
    this.managerMode = value;
    this.saveToStorage(this);
    return this;
  }
}

interface ISavedFilter {
  columnId: string;
  filter: IFilterItem;
}