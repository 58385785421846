import { useState } from 'react';
import Icon from '../../../../common/icon/Icon';
import styles from './DirectoryNodeMenu.module.css';
import { IDirectoryDraftItem } from '../../../../../context/directory-editor-context/IDirectoryDraftItem';
import { IUiMenuContent } from '../../../../common/options/menus/IUiMenuContent';
import PopupMenu from '../../../../common/popup-v2/popup-menu/PopupMenu';
import { getTUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import CombineStyles from '../../../../../utils/combineStyles';

interface IProps {
  ui?: IUiMenuContent;
  data: IDirectoryDraftItem;
  onOptionSelect?: (optionId: string) => void;
}

export default function DirectoryNodeMenu(props: IProps) {
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const contextMenuDivId = `DictionaryNodeOptions_${props.data.id}`;
  const noMenu = !props.ui || props.ui.options.length === 0 || !props.onOptionSelect;
  //---------------------------------------------------------------------------
  const onMenuOpen = () => {
    if (noMenu) return;
    setContextMenuOpen(true);
  };
  //---------------------------------------------------------------------------
  const onSelect = (optionId: string) => {
    setContextMenuOpen(false);
    props.onOptionSelect && props.onOptionSelect(optionId);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      id={contextMenuDivId}
      className={CombineStyles([
        styles.container,
        noMenu ? styles.disabled : ''
      ])}
      onClick={onMenuOpen}>
      <Icon imageName="kebabButton" />

      {contextMenuOpen && props.ui &&
        <PopupMenu
          anchorId={contextMenuDivId}
          ui={getTUiMenuContent(props.ui)}
          displayIcon={true}
          onSelect={onSelect}
          onClose={() => setContextMenuOpen(false)}
        />}

    </div>
  );
}