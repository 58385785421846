import { pathAdminConsole, pathSystemTranslator } from "../../../AdminConsole";
import { IDictionaryDraft } from "../../../common/dictionary-draft-models/DictionaryDraft";
import { DraftItemStateEnum } from "../../../common/dictionary-draft-models/DraftItemStateEnum";
import { IDictionaryEditorState } from "../../../common/dictionary-editor-models/IDictionaryEditorState";
import { IDictionaryDraftFilter } from "../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter";
import { ITranslation } from "../../dictionaries/DictionaryTranslationDraftItem";
import { dictonaryTranslationDraftStates } from "../../dictionaries/dictionaryTranslationDraftStates";
import { pathWidgets } from "../../SystemTranslatorConsole";
import { IValidationTranslation, IWidgetTranslation } from "./IWidgetTranslation";

export interface IWidgetTranslationDraft extends IWidgetTranslation {
  draftState: DraftItemStateEnum;
  publishedVersion: IWidgetTranslation;
}
export interface IWidgetTranslationDraftSpread {
  caption?: ITranslation;
  hint?: ITranslation;
  placeholder?: ITranslation;
  validation?: IValidationTranslation;
  //-----------------------------------
  draftState?: DraftItemStateEnum;
  publishedVersion?: IWidgetTranslation;
}

export interface IDirectoryTranslationDraft extends IDictionaryDraft<IWidgetTranslationDraft> {
  directoryId: string;
}

export interface IDirectoryTranslatorState extends IDictionaryEditorState<IDirectoryTranslationDraft> {
}

interface IDirectoryTranslatorStateInitial {
  draft?: IDirectoryTranslationDraft;
  editMode: boolean;
}

interface IDirectoryTranslatorStateUpdate {
  state: IDirectoryTranslatorState;
  spread: any;
}

export class DirectoryTranslatorState implements IDirectoryTranslatorState {
  basePath!: string;
  numberOfApprovals!: number;
  filter!: IDictionaryDraftFilter;
  editModeIsEnabled!: boolean;
  sourceLocale!: string;
  //-----------------------------------
  draft!: IDirectoryTranslationDraft;
  draftSessionInitial!: IDirectoryTranslationDraft;
  //-----------------------------------
  isUpdatedInSession!: boolean; // To know if we can save draft
  isUpdated!: boolean;  // To know if we can publish
  isValid!: boolean;  // To know if we can publish
  isReadyToPublish!: boolean;
  //---------------------------------------------------------------------------
  constructor(
    initialData?: IDirectoryTranslatorStateInitial,
    update?: IDirectoryTranslatorStateUpdate
  ) {
    if (initialData) {
      // State is being initialized
      console.log("DirectoryTranslatorState constructor: first")
      Object.assign(this, {
        basePath: `/${pathAdminConsole}/${pathSystemTranslator}/${pathWidgets}`,
        numberOfApprovals: 1,
        filter: {
          draftState: DraftItemStateEnum.All,
          stateGroups: dictonaryTranslationDraftStates,
          selectedGroups: dictonaryTranslationDraftStates.map(g => g.id), // switch on all the options (groups)
          searchById: false
        },
        editModeIsEnabled: initialData.editMode,
        sourceLocale: "i18n",
        //-------------------------------
        breadcrumb: initialData.draft ? {
          id: initialData.draft.directoryId,
          caption: { en: `${initialData.draft.directoryId} (${initialData.draft.locale})` },
          path: `/${pathAdminConsole}/${pathSystemTranslator}/${pathWidgets}/${initialData.draft.directoryId}`
        } : undefined,
        //-------------------------------
        draft: {
          ...initialData.draft,
          dictionaryId: initialData.draft?.directoryId
        },
        draftSessionInitial: initialData.draft ? JSON.parse(JSON.stringify(initialData.draft)) : undefined,
        //-------------------------------
        isUpdatedInSession: false,
        isUpdated: false,
        isValid: true
      });
    } else if (update) {
      // State is being updated
      console.log("DirectoryTranslatorState constructor: update")
      Object.assign(this, update.state, update.spread);
    } else console.error("Invalid DirectoryTranslatorState constructor call");
    this.checkChangesAndValidate();
  };
  //---------------------------------------------------------------------------
  checkChangesAndValidate() {
    this.isUpdated = JSON.stringify(this.draft.items) !== JSON.stringify(this.draftSessionInitial.items);
    let isValid = true;
    let isChanged = false;
    if (this.draft.publishedVersion) {
      isChanged = JSON.stringify(this.draft.items) !== JSON.stringify(this.draft.publishedVersion.items);
      // Run through items and decide whether it's valid
      // for (let i = 0; i < this.draft.items.length; i++) {
      //   const item = this.draft.items[i];
      //   //-----------------------------
      //   if (item.caption != undefined && Translation.NoTranslation(item.caption))
      //     isValid = false;
      //   if (item.hint != undefined && Translation.NoTranslation(item.hint))
      //     isValid = false;
      //   if (item.placeholder != undefined && Translation.NoTranslation(item.placeholder))
      //     isValid = false;
      // };
    } else {
      // If there are no published version, it's a first translation, so it's changed by default
      isChanged = true;
    };
    this.isReadyToPublish = isChanged && isValid;
  };
  noTranslation(translation: ITranslation) {
    if (!translation.fallbackAccepted && !translation.value)
      return true;
    else
      return false;
  }
}