import React, { CSSProperties } from 'react';
import { DraftItemStateEnum } from '../../../../../common/dictionary-draft-models/DraftItemStateEnum';
import { IDictionaryDraftItemStateGroup } from '../../settings/IDictionaryDraftItemStateGroup';
import styles from './DictionaryNodeBadges.module.css';
import DictionaryNodeBadge from './dictionary-node-badge/DictionaryNodeBadge';

interface IProps {
  stateGroups?: IDictionaryDraftItemStateGroup[];
  draftState: DraftItemStateEnum;
  newUi?: boolean;
}

export default function DictionaryNodeBadges(props: IProps) {
  //console.log(props.stateGroups, props.draftState)
  //---------------------------------------------------------------------------
  // Apply styles and provide badges (icons) based on current item draft state
  const calcStyles: CSSProperties = {
    color: 'inherit'
  };
  const badges: JSX.Element[] = [];
  props.stateGroups?.forEach(stateGroup => {
    let conditionIsMet = false;
    //-------------------------------------------------------------------------
    stateGroup.states?.forEach(state => {
      // Check if the state is applied to the current node 
      //console.log(state.draftState, props.data.draftState)
      if (state.draftState === (props.draftState & state.draftState)) {
        conditionIsMet = true;
        //console.log(props.data.id, group.name, state.draftState, props.data.draftState)
        calcStyles.color = state.fontColor ? state.fontColor : stateGroup.color;
        calcStyles.fontWeight = state.fontWeight;
        calcStyles.textDecoration = state.textDecoration;
        //---------------------------------------------------------------------
        // Provide an icon if it's a rule for the state
        if (!stateGroup.iconId && state.iconId) {
          badges.push(
            <DictionaryNodeBadge
              key={stateGroup.id + state.draftState}
              iconId={state.iconId}
              color={state.iconColor ? state.iconColor : stateGroup.color}
            />
          );
        }
      };
    });
    //-------------------------------------------------------------------------
    // If group icon is provided we display only one badge for a group
    if (stateGroup.iconId && conditionIsMet) {
      badges.push(
        <DictionaryNodeBadge
          key={stateGroup.id}
          iconId={stateGroup.iconId}
          color={stateGroup.color}
        />
      );
    };
  });
  //---------------------------------------------------------------------------
  if (props.newUi) return (
    <React.Fragment>
      {badges}
    </React.Fragment>
  ); else
    return (
      <td className={styles.cell}>
        <div className={styles.container}>
          {badges}
        </div>
      </td>
    );
}