import { ReactNode } from 'react';
import styles from './WizardStepWrapper.module.css';
import { IUiBusinessWizard } from '../IUiBusinessWizardV2';
import FormOptionV2 from '../../../common/form-options-bar/form-option/FormOptionV2';
import { GetTUiMenuOption } from '../../../common/menu/menu-content/menu-option/TUiMenuOption';
import { ButtonType } from '../../../common/button/ButtonType';

interface IProps {
  ui: IUiBusinessWizard;
  promptString: string;
  children: ReactNode;
  optionsElement: ReactNode;
  onExit: () => void;
}

export default function WizardStepWrapper(props: IProps) {
  //---------------------------------------------------------------------------
  const optionExit = props.ui.menuContent.options.find(option => option.id === "OptionExit");
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <div className={styles.prompt}>
        {props.promptString}
      </div>

      <div className={styles.content}>
        {props.children}
      </div>

      <div className={styles.options}>
        <div className={styles.optionCancel}>
          {optionExit &&
            <FormOptionV2
              ui={GetTUiMenuOption({ ...optionExit, iconId: "exit" })}
              type={ButtonType.Toolbar}
              applyMobileStyle={false}
              onSelect={props.onExit}
            />}
        </div>
        {props.optionsElement}
      </div>

    </div>
  );
}