interface IProps {
}

export default function IconSortSimplified(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="1em" height="1em"
      viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="M10.73 13.79c.29.28.75.28 1.04 0l2.75-2.65a.75.75 0 1 0-1.04-1.08L12 11.486V2.75a.75.75 0 0 0-1.5 0v8.736L9.02 10.06a.75.75 0 1 0-1.04 1.08zM5.28 2.22a.75.75 0 0 0-1.06 0L1.47 4.97a.75.75 0 0 0 1.06 1.06L4 4.56v8.69a.75.75 0 0 0 1.5 0V4.56l1.47 1.47a.75.75 0 0 0 1.06-1.06z" />
    </svg>
  );
}