import { ReactNode } from 'react';
import styles from './DictionaryNodeWrapper.module.css';
import CombineStyles from '../../../../utils/combineStyles';

interface IProps {
  editModeIsEnabled: boolean;
  children: ReactNode;
  onSelect?: () => void;
}

export default function DictionaryNodeWrapperV1(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <tr
      className={CombineStyles([
        styles.container,
        props.editModeIsEnabled ? styles.editable : ""
      ])}
      onDoubleClick={props.editModeIsEnabled ? props.onSelect : undefined}
    >
      {props.children}
    </tr>
  );
}