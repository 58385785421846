import { TImageLibraryItem } from "../TImageLibrary";
import styles from "./ImageListCardOptions.module.css";
import Icon from "../../../../common/icon/Icon";
//---------------------------------------------------------------------------
interface IProps {
  show: boolean;
  imageLibraryItem: TImageLibraryItem;
  onCopy: () => void;
  onEdit: () => void;
  onDelete: () => void;
}
//---------------------------------------------------------------------------
export function ImageListCardOptions(props: IProps) {
  if (!props.show) {
    return null;
  }
  //-------------------------------------------------------------------------
  const onDelete = () => {
    !isLocked && props.onDelete();
  }
  //-------------------------------------------------------------------------
  let imageNameEdit = "pencil"; // "lock"
  let isLocked = props.imageLibraryItem.imageStatus != "Draft";
  let onEditClick = isLocked ? undefined : props.onEdit;
  let onDeleteClick = isLocked ? undefined : onDelete;
  let editStyle = isLocked ? styles.optionDisabled : styles.option;
  //-------------------------------------------------------------------------
  return (
    <div className={styles.optionContainer}>
      <div className={editStyle} >
        <Icon
          imageName={imageNameEdit}
          onClick={onEditClick}
        //size={IconSize.Small}
        />
      </div>
      <div className={styles.option} >
          <Icon
            imageName={"copy"}
            onClick={props.onCopy}
          //size={IconSize.Small}
          />
        </div>
        <div className={editStyle} >
          <Icon
            imageName={"delete"}
            onClick={onDeleteClick}
          //size={IconSize.Small}
          />
        </div>
    </div>
  );
}