import { ReactNode, useRef, useState } from 'react';
import { ScreenType, useAppScreenContext } from '../../../../context/app-screen-context/AppScreenProvider';
import styles from './BusinessWizardWrapper.module.css';
import CombineStyles from '../../../../utils/combineStyles';
import WizardProgress from '../wizard-progress/WizardProgress';
import { WizardState } from '../IWizardState';
import { IUiBusinessWizard } from '../IUiBusinessWizardV2';
import PopupTest from '../../../common/popup-v2/popup/PopupTest';

interface IProps {
  ui: IUiBusinessWizard;
  wizardState: WizardState;
  children?: ReactNode;
  onUpdate: (state: WizardState) => void;
}

export default function BusinessWizardWrapper(props: IProps) {
  const screenType = useAppScreenContext();
  const [splitterState, setSplitterState] = useState<number>(40);
  const [moving, setMoving] = useState(false);
  const [progressPopupOpen, setProgressPopupOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerRect = useRef<DOMRect>();
  const isMobile = screenType == ScreenType.Mobile;
  //---------------------------------------------------------------------------
  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    containerRect.current = containerRef.current?.getBoundingClientRect();
    setMoving(true);
  };
  //---------------------------------------------------------------------------
  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!moving) return;
    if (!containerRect.current) return;
    e.stopPropagation();
    e.preventDefault();
    const percent = (e.clientX - containerRect.current.left) / containerRect.current.width * 100;
    setSplitterState(percent);
  };
  //---------------------------------------------------------------------------
  const onMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    containerRect.current = undefined;
    setMoving(false);
  };
  //---------------------------------------------------------------------------
  const openAsPopup = () => {
    setProgressPopupOpen(true);
  };
  //---------------------------------------------------------------------------
  const onStateUpdate = (state?: WizardState) => {
    state && props.onUpdate(state);
  };
  //---------------------------------------------------------------------------
  const onPopupStepSelect = (state?: WizardState) => {
    setProgressPopupOpen(false);
    state && props.onUpdate(state);
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.wrapper}>

      {/* Wizard container */}
      <div
        ref={containerRef}
        className={CombineStyles([
          styles.container,
          isMobile ? styles.mobile : ''])}
        style={{ cursor: moving ? 'col-resize' : 'default' }}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >

        {/* Progress-bar container */}
        <div
          className={CombineStyles([
            styles.progress,
            isMobile ? styles.mobile : ''
          ])}
          style={{ flex: isMobile ? undefined : `1 1 ${splitterState}%` }}>
          <WizardProgress
            ui={props.ui.stepsDictionary}
            wizardState={props.wizardState}
            mode={screenType}
            onUpdate={onStateUpdate}
            onOpenAsPopup={openAsPopup}
          />
        </div>

        {/* Splitter */}
        <div
          className={styles.splitter}
          onMouseDown={onMouseDown}
        />

        {/* Wizard step content */}
        <div
          className={CombineStyles([
            styles.content,
            isMobile ? styles.mobile : ''])}
          style={{ flex: isMobile ? undefined : `1 1 ${100 - splitterState}%` }}>
          {props.children}
        </div>

      </div>

      {/* Sidebar */}
      {!isMobile &&
        <div className={styles.sidebar}>
        </div>}

      {/* Progress popup */}
      {isMobile && progressPopupOpen &&
        <PopupTest
          id="progressPopup"
          onClose={() => setProgressPopupOpen(false)}
        >
          <WizardProgress
            ui={props.ui.stepsDictionary}
            wizardState={props.wizardState}
            mode={ScreenType.Desktop}
            onUpdate={onPopupStepSelect}
          />
        </PopupTest>}

    </div>
  );
}