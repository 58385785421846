import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathGetListCustomerAdRequest } from "../../../../utils/apiPathConstant";
import { apiGetPrivate, checkResponse } from "../../../../utils/api";
import { ICustomerAdRequestsListItem } from "../customer-ads/requests-list/requests-list-item/ICustomerAdRequestsListItem";

interface IProps {
  msalInstanse: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
}

export async function getListCustomerAdRequestAsync(props: IProps): Promise<ICustomerAdRequestsListItem[]> {
  if (!props.authConfig) throw new Error("getListCustomerAdRequestAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const response = await apiGetPrivate(
    props.msalInstanse,
    props.authConfig,
    `${apiBasePath}${pathGetListCustomerAdRequest}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getListCustomerAdRequestAsync", props.abortSignal?.aborted);
  const requests = response?.content as ICustomerAdRequestsListItem[];
  return requests;
} 