import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathContentDirectoryDraftGet } from "../../../../utils/apiPathConstant";
import { apiGetPrivate, checkResponse } from "../../../../utils/api";
import { stringFormatter } from "../../../../utils/common";
import { DirectoryTranslationDraft, IDirectoryTranslationDraft } from "../../common/directory-translator/IDirectoryTranslationDraft";
import { ContentItemTranslationDraft, IContentItemTranslationDraft } from "./content-directory-translator/IContentItemTranslationDraft";

interface IProps {
  msalInstanse: IPublicClientApplication;
  authConfig?: IAuthConfig;
  directoryId: string;
  localeId: string;
  lockIsNeeded: boolean;
  abortSignal?: AbortSignal;
}

export async function getContentDirectoryDraft(props: IProps): Promise<DirectoryTranslationDraft<ContentItemTranslationDraft>> {
  if (!props.authConfig) throw new Error("getContentDirectoryDraft: auth context is not provided");
  //---------------------------------------------------------------------------
  let uri = `${apiBasePath}${stringFormatter(pathContentDirectoryDraftGet, [props.directoryId, props.localeId])}`;
  if (props.lockIsNeeded) uri += "?lockIsNeeded=true";
  const response = await apiGetPrivate(
    props.msalInstanse,
    props.authConfig,
    uri,
    props.abortSignal
  );
  checkResponse(response, "getContentDirectoryDraft");
  //---------------------------------------------------------------------------
  const translationDraft = response?.content as IDirectoryTranslationDraft<IContentItemTranslationDraft>;
  const items = translationDraft.items?.map(item => new ContentItemTranslationDraft(item));
  return new DirectoryTranslationDraft<ContentItemTranslationDraft>({
    ...translationDraft,
    items: items
  });
}