import { IDictionaryItemContent } from "../../../../admin-console/administrator-console/business-tags-console/business-tags-editor/IBusinessTagsDictionaryDraftItem";
import { IDictionaryItem, IDictionaryItemStatus } from "../../../../admin-console/administrator-console/dictionaries/common-dictionary/IDictionaryDraftItem";
//import { IUiDictionaryItem } from "../../../dictionaries/IUiDictionaryItem";

export interface IUiBusinessTag extends IDictionaryItem<any> {
  content?: IDictionaryItemContent;
}

export class UiBusinessTag implements IUiBusinessTag {
  id!: string;
  path?: string;
  name?: any;
  description?: any;
  content?: IDictionaryItemContent;
  status?: IDictionaryItemStatus;
  searchString!: string;
  //---------------------------------------------------------------------------
  get level(): number {
    return this.fullPath ? (this.fullPath.match(/\./g) || []).length : 0;
  };
  //---------------------------------------------------------------------------
  get fullPath(): string {
    const pathItems = this.path ? [this.path] : [];
    pathItems.push(this.id);
    return pathItems.join('.');
  };
  //---------------------------------------------------------------------------
  get isSelectable(): boolean {
    return !this.content?.dictionaryId;
  };
  //---------------------------------------------------------------------------
  constructor(source: IUiBusinessTag) {
    Object.assign(this, source);
    this.createSearchString();
  };
  //---------------------------------------------------------------------------
  createSearchString() {
    const searchItems = [this.id];
    if (this.name) {
      Object.keys(this.name).forEach(key => {
        searchItems.push(this.name[key]);
      });
    };
    if (this.description) {
      Object.keys(this.description).forEach(key => {
        searchItems.push(this.description[key]);
      });
    };
    this.searchString = searchItems.join(',').toLocaleLowerCase();
  };
  //---------------------------------------------------------------------------
  isSelected(selectedPaths: string[]): boolean {
    if (this.isSelectable) {
      return selectedPaths.find(path => path === this.fullPath) ? true : false;
    } else {
      return selectedPaths.find(path => path.startsWith(this.fullPath)) ? true : false;
    };
  };
  //---------------------------------------------------------------------------
  isFitForFilter(filterString?: string): boolean {
    if (!filterString) return true;
    return this.searchString.includes(filterString);
  };
}