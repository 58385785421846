import { ReactNode, useRef, WheelEvent, TouchEvent } from 'react';
import styles from './ScrollableContainer.module.css';
import CombineStyles from '../../../../utils/combineStyles';

export class TouchState {
  start?: number;
  current?: number;
  get delta(): number {
    if (this.start && this.current)
      return this.current - this.start;
    return 0;
  }
}

interface IProps {
  elementRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  children: ReactNode;
  onScrollUp?: () => void;
  onScrolledToTop?: () => void;
  onScrollDown?: () => void;
  onScrolledToBottom?: () => void;
}

export default function (props: IProps) {
  const touchState = useRef<TouchState>(new TouchState());
  const div: HTMLDivElement | null | undefined = props.elementRef?.current;
  //--------------------------------------------------------------------------- Handle mouse events
  const onWheel = (e: WheelEvent<HTMLDivElement>) => {
    if (!div) return;
    //-------------------------------------------------------------------------
    if (e.deltaY < 0) {
      // User scrolls div up (to the div top)
      //console.log('scroll up')
      props.onScrollUp && props.onScrollUp();
      //--------------------------------------------------------------------- Scrolled to the top
      if (div && div.scrollTop == 0) {
        // User scrolled the div up to the top
        //console.log('top')
        props.onScrolledToTop && props.onScrolledToTop();
      };
    } else {
      // User scrolls div down (to the div bottom)
      console.log('scroll down')
      props.onScrollDown && props.onScrollDown();
      //--------------------------------------------------------------------- Scrolled to the bottom
      if (div && div.scrollTop === (div.scrollHeight - div.offsetHeight)) {
        // User scrolled the div up to the top
        //console.log('bottom')
        props.onScrolledToBottom && props.onScrolledToBottom();
      };
    };
  };
  //--------------------------------------------------------------------------- Handle gestures
  const onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    // Fix position on touch start
    //console.log('touch start')
    if (e.touches.length > 0) {
      touchState.current.start = e.touches[0].clientY;
      touchState.current.current = e.touches[0].clientY;
    };
  };
  //---------------------------------------------------------------------------
  const onTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    // Update position on touch move
    //console.log('touch move')
    if (e.touches.length > 0) {
      touchState.current.current = e.touches[0].clientY;
      //----------------------------------------------------------------------- Scroll up
      //console.log(touchState.current.delta)
      if (touchState.current.delta > 0) {
        // User scrolls the div up
        //console.log('scroll up')
        props.onScrollUp && props.onScrollUp();
        //--------------------------------------------------------------------- Scrolled to the top
        if (div && div.scrollTop == 0) {
          // User scrolled the div up to the top
          //console.log('top')
          props.onScrolledToTop && props.onScrolledToTop();
        };
      //----------------------------------------------------------------------- Scroll down
      } else {
        //console.log('scroll down', div?.scrollTop, div?.clientHeight)
        props.onScrollDown && props.onScrollDown();
        //--------------------------------------------------------------------- Scrolled to the bottom
        if (div && div.scrollTop === (div.scrollHeight - div.offsetHeight)) {
          // User scrolled the div up to the top
          //console.log('bottom')
          props.onScrolledToBottom && props.onScrolledToBottom();
        };
      };
    };
  };
  //---------------------------------------------------------------------------
  const onTouchEnd = (e: TouchEvent<HTMLDivElement>) => {
    //console.log('touch end')
    //-------------------------------------------------------------------------
    if (touchState.current.delta > 0) {
      touchState.current.start = undefined;
      touchState.current.current = undefined;
    };
  }
  //---------------------------------------------------------------------------
  return (
    <div
      ref={props.elementRef}
      className={CombineStyles([
        styles.container,
        props.className ? props.className : ''
      ])}
      // Mouse events
      onWheel={onWheel}
      // Mobile gestures
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {props.children}
    </div>
  );
}