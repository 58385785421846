import { useContext } from "react";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";
import { TUiButton } from "../button/TUiButton";
import GetCaption from "../functions/GetCaption";
import GetHint from "../functions/GetHint";
import styles from "./ButtonLink.module.css";

interface IProps {
  ui: TUiButton;
  disabled?: boolean;
  hideHint?: boolean;
  onClick: () => void;
}

export default function ButtonLink(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const hideHint = props.hideHint == undefined ? false : props.hideHint;
  const disabled = props.disabled == undefined ? false : props.disabled;
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div
        id={props.ui.id}
        className={disabled ? styles.linkDisabled : styles.link}
        onClick={props.onClick}>
        {GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      </div>
      {!hideHint &&
        <div className={styles.hint}>
          {GetHint(appUiContext, props.ui.id, props.ui.hint)}
        </div>}
    </div>
  );
}