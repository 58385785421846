import styles from './SystemAdCampaignHeader.module.css';
import { IUiListItemAdCampaign } from '../../../../../../business-space/business-editor/section-adcampaigns/adcampaign-list-item/IUiListItemAdCampaign';
import { IAdCampaign } from '../../../../../../../context/business-context/IAdCampaign';

interface IProps {
  ui: IUiListItemAdCampaign;
  data: IAdCampaign;
}

export default function SystemAdCampaignHeader(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div className={styles.brand}>
      {props.data.name}
    </div>
  );
}