import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";
import { apiBasePath, pathGetNewSubDictionaryDraft } from "../../../../../utils/apiPathConstant";
import { CustomDictionaryDraft } from "../../dictionaries/common-dictionary/IDictionaryDraft";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
}

export async function getNewCustomDictionaryDraftAsync(props: IProps): Promise<CustomDictionaryDraft> {
  if (!props.authConfig) throw new Error("getNewDictionaryDraftAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const response = await apiGetPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${pathGetNewSubDictionaryDraft}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getNewDictionaryDraftAsync", props.abortSignal?.aborted);
  const draft = new CustomDictionaryDraft().initialize(response?.content);
  return draft;
}