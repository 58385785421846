import React from "react";
import styles from "./IconButton.module.css";
import { TUiIconButton } from "./TUiIconButton";
import CombineStyles from "../../../utils/combineStyles";
import IconInline from "../icon/icon-inline/IconInline";
import { iconsSrc } from "../../uielements/icons";

interface Props {
  ui: TUiIconButton;
  inline: boolean;
  flexible?: boolean;
  customDisabledColor?: string;
  clickHandler?: (event: React.MouseEvent) => void;
}

export default function IconButton(props: Props) {
  const flexible = props.flexible == undefined ? false : props.flexible;
  //-------------------------------------------------------------------------
  // This click handler only purpose - to suppress parent object onClick event
  // so parent onClick would not be triggered when user clicks on disabled button
  const clickHandler = (event: React.MouseEvent) => {
    if (props.ui.disabled) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      props.clickHandler && props.clickHandler(event);
    };
  };
  //-------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    props.ui.disabled ? styles.disabled : "",
    props.ui.inline ? styles.inline : ""
  ]);
  //-------------------------------------------------------------------------
  if (props.ui.visible)
    if (props.inline)
      return (
        <span
          id={props.ui.id}
          className={containerStyles}
          onClick={clickHandler}
        >
          <IconInline imageName={props.ui.iconFile} />
        </span>
      );
    else
      return (
        <div
          id={props.ui.id}
          style={{ 
            color: props.ui.disabled && props.customDisabledColor ? props.customDisabledColor : undefined,
            width: flexible ? '100%' : undefined,
            height: flexible ? '100%' : undefined
          }}
          className={containerStyles}
          onClick={clickHandler}
        >
          <span className="iconify" data-icon={iconsSrc[props.ui.iconFile]} />
        </div>
      );
  else return <React.Fragment></React.Fragment>;
}
