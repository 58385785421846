import { AzureB2cIdentityServiceHealthStatus } from "./RikiWikiHealthStatus";
import styles from "./HealthStatus.module.css";
import WikiSpinner from "../app-layout/spinner/wikiSpinner";
import { HealthGauge } from "./HealthGauge";

interface IProps {
  status?: AzureB2cIdentityServiceHealthStatus
  spinner: boolean;
}

export function HealthStatusB2c(props: IProps) {

  //console.log("props.spinner:", props.spinner);

  return (

    <div className={styles.container}>

      <WikiSpinner show={props.spinner} />

      <div className={styles.header}>
        {props.status ? props.status.serviceName : "Unknown Service"}
      </div>

      {!props.status &&
        <div className={styles.statusContainer}>
          Unknown Service status is undefined
        </div>
      }

      {props.status &&
        <div className={styles.statusContainer}>
          <HealthGauge status={props.status} spinner={props.spinner} />
          <div>
            {!props.spinner
              ? `User count: ${props.status.userCount}`
              : `User count: `}
          </div>
        </div>
      }
    </div>

  );
}