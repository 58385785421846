import { ClassSystemBusinessRegional, ISystemBusinessRegional } from "../../components/admin-console/regional-homepages/regional-homepage-editor/ISystemBusinessRegional";
import { ClassCustomerBusiness, ICustomerBusiness } from "../../components/business-space/business-editor/ICustomerBusiness";
import getUpdatedPropertiesList, { IComparisonResult } from "./functions/getUpdatedPropertiesList";
import { IBusiness } from "./IBusiness";
import { IObjectState } from "./IBusinessContext";

export interface IBusinessState extends IObjectState<IBusiness> {
}

export class ClassBusinessState implements IBusinessState {
  object!: IBusiness;
  initialState!: IBusiness;
  updatedProperties!: string[];
  isNew!: boolean;
  isChanged!: boolean;
  isValid!: boolean;
  get business(): IBusiness {
    return this.object;
  };
  set business(value: IBusiness) {
    this.object = value;
  };
  //---------------------------------------------------------------------------
  constructor(data: IBusinessState) {
    Object.assign(this, data);
  };
  //---------------------------------------------------------------------------
  static initializeContext(businessData: IBusiness, isNew?: boolean): IBusinessState {
    let businessObject: IBusiness;
    let businessObjectInitial: IBusiness;
    switch (businessData.businessType) {
      case "Business":
        // NOTE! This is a placeholder for the actual business object class
        businessObject = new ClassCustomerBusiness(businessData as ICustomerBusiness);
        businessObjectInitial = new ClassCustomerBusiness(businessData as ICustomerBusiness);
        break;
      case "SystemBusinessRegional":
        businessObject = new ClassSystemBusinessRegional(businessData as ISystemBusinessRegional);
        businessObjectInitial = new ClassSystemBusinessRegional(businessData as ISystemBusinessRegional);
        break;
    };    
    const context: IBusinessState = {
      object: businessObject,
      initialState: businessObjectInitial,
      updatedProperties: [],
      isNew: !!isNew,
      isChanged: false,
      isValid: businessObject.validate()
    };
    return context;
  };
  //---------------------------------------------------------------------------
  getUpdatedContext(updatedBusiness: IBusiness, isValid?: boolean): IBusinessState {
    const businessIsValid: boolean = isValid === undefined ? true : isValid;
    let updatedBusinessObject: IBusiness;
    switch (this.business.businessType) {
      case "Business":
        updatedBusinessObject = new ClassCustomerBusiness(updatedBusiness as ICustomerBusiness);
        break;
      case "SystemBusinessRegional":
        updatedBusinessObject = new ClassSystemBusinessRegional(updatedBusiness as ISystemBusinessRegional);
        break;
    };
    const comparisonResult: IComparisonResult = getUpdatedPropertiesList(updatedBusiness, this.initialState);
    const context: IBusinessState = {
      ...this,
      business: updatedBusinessObject,
      updatedProperties: comparisonResult.updatedProperties,
      isChanged: comparisonResult.isChanged,
      isValid: updatedBusinessObject.validate() && businessIsValid
    };
    return context;
  };
}