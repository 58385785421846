import { useEffect, useState } from "react";

interface IState {
  element: HTMLElement;
  scrollableParent: HTMLElement;
  frozenContainer: HTMLElement;
}

interface IProps {
  defaultDiv?: HTMLDivElement;
  callback: (renderDiv?: HTMLDivElement) => void;
}

export function useFreezeOnScroll(props: IProps) {
  const [state, setState] = useState<IState>();
  const [targetDiv, setTargetDiv] = useState<HTMLDivElement>();
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (props.defaultDiv) {
      const element: HTMLDivElement = props.defaultDiv;
      //-----------------------------------------------------------------------
      const scrollableParent = getScrollableParent(props.defaultDiv);
      if (scrollableParent) {
        const container = scrollableParent.parentElement;
        if (container) {
          const frozenDiv = container.querySelector('.frozen') as HTMLElement;
          if (frozenDiv) {
            setState({
              element: element,
              scrollableParent: scrollableParent,
              frozenContainer: frozenDiv
            });
          };
        };
      };
    };
  }, [props.defaultDiv]);
  //---------------------------------------------------------------------------
  useEffect(() => {
      props.callback(targetDiv);
  }, [targetDiv]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    const handler = () => {
      if (!state) return;
      // Check if user scrolls the content, check the element position
      const elementTop = state.element.getBoundingClientRect().top;
      const scrollableParentTop = state.scrollableParent.getBoundingClientRect().top;   
      if (elementTop <= scrollableParentTop) {
        // If user scrolled out the element, update targetDiv, i.e. provide reference to the frozen div
        setTargetDiv(state.frozenContainer as HTMLDivElement);
      } else {
        // If user scrolls back to the element, clear targetDiv, because we dont need it anymore
        // because element is rendered in its default position
        setTargetDiv(undefined);
      };
    };
    //-------------------------------------------------------------------------
    if (state) {
      console.log('everything is in place, add listener');
      state.scrollableParent.addEventListener('scroll', handler);
    };
    //-------------------------------------------------------------------------
    return () => {
      if (state) {
        console.log('cleanin up', state.scrollableParent);
        state.scrollableParent.removeEventListener('scroll', handler);
      };
    };
  }, [state]);
}

export const getScrollableParent = (element?: HTMLElement | null): HTMLElement | null => {
  if (!element) return null;
  const computedStyle = window.getComputedStyle(element);
  if (computedStyle.overflowY == 'auto') {
    return element;
  } else return getScrollableParent(element.parentElement);
};