import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { pathNotFound } from "../../App";
import useNavigateWithContext from "../../hooks/useNavigateWithContext";
import styles from "./NotFoundPage.module.css";

interface IProps {

}

export default function NotFoundPage(props: IProps) {
  const location = useLocation();
  const navigate = useNavigateWithContext();
  const [pagePath, setPagePath] = useState<string>();
  //---------------------------------------------------------------------------
  useEffect(() => {
    const pagePath = location.pathname.split(`${pathNotFound}/`)[1];
    console.log(pagePath)
    setPagePath(pagePath);
  }, [location]);
  //---------------------------------------------------------------------------
  const goToHomePage = () => {
    navigate("/");
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        We are terribly sorry,
        <br></br> 
        but the page you are looking for (/{pagePath}) does not exist :(
      </div>
      <div className={styles.gohomelink} onClick={goToHomePage}>
        Click here to go to the home page
      </div>
    </div>
  );
}