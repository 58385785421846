import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useContext, useEffect, useState } from "react";
import { AppAuthContextStore } from "../../../../context/app-auth-context/AppAuthContext";
import { ScreenType, useAppScreenContext } from "../../../../context/app-screen-context/AppScreenProvider";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import useNavigateWithContext from "../../../../hooks/useNavigateWithContext";
import { pathAdminConsole } from "../../../admin-console/AdminConsole";
import { TUiMenuOption } from "../../../common/menu/menu-content/menu-option/TUiMenuOption";
import { TUiMenuContent } from "../../../common/menu/menu-content/TUiMenuContent";
import PopupMenu from "../../../common/popup-v2/popup-menu/PopupMenu";
import { pathUserConsole } from "../../../user-console/UserConsole";
import UserMenuButton from "./user-menu-button/UserMenuButton";
import UserMenuOptionButton from "./user-menu-option-button/UserMenuOptionButton";
import styles from "./UserMenu.module.css";
import { IUiMenuContent } from "../../../common/options/menus/IUiMenuContent";
import GetName from "../../../common/functions/GetName";
import { getFromSessionStorage, saveToSessionStorage } from "../../../../utils/sessionStorage";
import { userSessionPreferencesKey } from "../../../../context/app-ui-context/IUserSessionPreferences";
import { pathSignInOut } from "../../../../App";
import { authActionParam, flagSignOut } from "../../../page-signin-signout/PageSignInOut";
import InputDropdownPopup from "../../../common/input-v2/input-dropdown/input-dropdown-popup/InputDropdownPopup";
import { IUiInputDropdown } from "../../../common/input-v2/input-dropdown/InputDropdown";
import { IUiDictionaryItem } from "../../../common/dictionaries/IUiDictionaryItem";
import { pathBusinessConsole, pathBusinessWizard } from "../../../business-space/business-console/BusinessConsole";
import { InteractionStatus } from "@azure/msal-browser";

interface IProps {
  ui: IUiMenuContent;
}

export default function UserMenu(props: IProps) {
  const { instance } = useMsal();
  const ins = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { appAuthContext, appAuthContextDispatch } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const navigate = useNavigateWithContext();
  const [authInProgress, setAuthInProcess] = useState(false);
  const [userMenuPopupOpen, setUserMenuPopupOpen] = useState(false);
  const [languagesPopupOpen, setLanguagesPopupOpen] = useState(false);
  const [themesPopupOpen, setThemesPopupOpen] = useState(false);
  //--------------------------------------------------------------------------- Auth in progress
  useEffect(() => {
    if (appAuthContext.user || isAuthenticated) {
      console.log('user is here')
      setAuthInProcess(false);
    } else if (ins.inProgress !== InteractionStatus.None) {
      setAuthInProcess(true);
    };
  }, [isAuthenticated, appAuthContext.user]);
  //--------------------------------------------------------------------------- User menu button click
  const onUserMenuButtonSelect = () => {
    setUserMenuPopupOpen(!userMenuPopupOpen);
  };
  //---------------------------------------------------------------------------
  const signIn = () => {
    setAuthInProcess(true);
    setUserMenuPopupOpen(false);
    console.log("Trying to handle sign in...");
    appAuthContextDispatch({ type: "SetInProcess", value: true });
    //console.log("appAuthContext.config:", appAuthContext.config);
    //console.log("appUiContext.locale.localeId:", appUiContext.locale.localeId)
    //console.log("appUiContext.locale.localeId:", appUiContext.locale.localeId)
    if (appAuthContext.config?.loginScopes) {
      const loginRedirectRequest = {
        scopes: appAuthContext.config.loginScopes,
        extraQueryParameters: {
          ui_locales: appUiContext.locale.localeId,
          application_name: "rikiwiki",
          theme: "MenuColourThemes_" + appUiContext.theme.themeId.split("-").slice(-1)[0]
        }
      };
      //console.log("loginRedirectRequest:", loginRedirectRequest);
      // Redirect user to B2C authentication form
      instance.loginRedirect(loginRedirectRequest);
    } else {
      console.log(appAuthContext.config);
      console.error("Can't handle sign in because appAuthContext.config.loginScopes value is not provided");
    };
  };
  //--------------------------------------------------------------------------- Option selection handler
  const onOptionSelect = (selectedOptionId: string) => {
    console.log(selectedOptionId);
    switch (selectedOptionId) {
      //----------------------------------------------------------------------- Sign in
      case "UserMenuContent.SignIn":
        //TUserType.toLS(location.pathname, EUserType.Authenticated);
        signIn();
        break;
      //----------------------------------------------------------------------- Open profile
      case "UserMenuContent.OpenUserProfile":
        setUserMenuPopupOpen(false);
        navigate(pathUserConsole);
        break;
      //----------------------------------------------------------------------- Sign out
      case "UserMenuContent.SignOut":
        setUserMenuPopupOpen(false);
        console.log("Sign out option is selected");
        //TUserType.toLS(location.pathname, EUserType.Anonymous);
        //navigate(`${pathSignInOut}/${flagSignOut}`);
        navigate(`${pathSignInOut}?${authActionParam}=${flagSignOut}`);
        /*
        // Clear up user data in local storage
        removeUserLocally(appAuthContext.user?.userIdentity.id);
        removeUserLastSignIn(appAuthContext.user?.userIdentity.id);
        // Log out
        instance.logoutRedirect({ postLogoutRedirectUri: "/" });
        */
        break;
      //----------------------------------------------------------------------- Change language
      case "UserMenuContent.ChangeLanguage":
        // Open popup with languages list and handle selection
        setLanguagesPopupOpen(true);
        break;
      //----------------------------------------------------------------------- Change theme
      case "UserMenuContent.ChangeColorTheme":
        // Open popup with themes list and handle selection
        setThemesPopupOpen(true);
        break;
      //----------------------------------------------------------------------- Place an Ad (start business wizard)
      case "UserMenuContent.StartBusinessWizard":
        setUserMenuPopupOpen(false);
        appUiContextDispatch({ type: "SetPathToRemember", value: location.pathname })
        //navigate(`${pathBusinessConsole}/${pathBusinessWizard}`);
        navigate(`${pathBusinessConsole}/owner/${pathBusinessWizard}`);
        break;
      //----------------------------------------------------------------------- Business Editor
      case "UserMenuContent.OpenBusinessConsole":
        setUserMenuPopupOpen(false);
        navigate(pathBusinessConsole);
        break;
      //----------------------------------------------------------------------- Admin Console
      case "UserMenuContent.OpenAdminConsole":
        setUserMenuPopupOpen(false);
        navigate(pathAdminConsole);
        break;
    };
  };
  //--------------------------------------------------------------------------- Handle language selection
  const onLanguageChange = (selectedOption?: IUiDictionaryItem) => {
    console.log("change language:", selectedOption);
    setLanguagesPopupOpen(false);
    setUserMenuPopupOpen(false);
    if (selectedOption) {
      const localeId = selectedOption.id;
      console.log(selectedOption);
      //---------------------------------------------------------------------
      // Update context
      if (appAuthContext.user) {
        // This means user set locale based on profile but they want to override it temporarily
        appUiContextDispatch({ type: "SetLocale", localeId: localeId, isImportant: true });
      } else {
        appUiContextDispatch({ type: "SetLocale", localeId: localeId });
      };
      //---------------------------------------------------------------------
      // Store selected locale to LS to persist user's choice
      // setToLS(lsPreferredAppLocale, localeId);
      //---------------------------------------------------------------------
      // Store selected locale to session storage to persist user's choice for the session
      // If user wants the change to persist between session, they should update preferences in user profile
      const sessionStorageKey = appAuthContext.user ?
        `${userSessionPreferencesKey}.${appAuthContext.user.userIdentity.id}` :
        userSessionPreferencesKey;
      const currentPreferences = getFromSessionStorage(sessionStorageKey);
      saveToSessionStorage(sessionStorageKey, {
        ...currentPreferences,
        locale: localeId
      });
    }
  };
  //--------------------------------------------------------------------------- Handle theme selection
  const onThemeChange = (selectedOption?: IUiDictionaryItem) => {
    setThemesPopupOpen(false);
    setUserMenuPopupOpen(false);
    if (selectedOption) {
      // Update context
      appUiContextDispatch({ type: "SetTheme", themeId: selectedOption.id });
      //---------------------------------------------------------------------
      // Store selected theme to session storage to persist user's choice for the session
      // If user wants the change to persist between session, they should update preferences in user profile
      const sessionStorageKey = appAuthContext.user ?
        `${userSessionPreferencesKey}.${appAuthContext.user.userIdentity.id}` :
        userSessionPreferencesKey;
      const currentPreferences = getFromSessionStorage(sessionStorageKey);
      saveToSessionStorage(sessionStorageKey, {
        ...currentPreferences,
        theme: selectedOption.id
      });
    }
  };
  //--------------------------------------------------------------------------- Handle language/theme popup closing
  const onOptionsPopupClose = () => {
    setLanguagesPopupOpen(false);
    setThemesPopupOpen(false);
    setUserMenuPopupOpen(false);
  };
  //--------------------------------------------------------------------------- Sort out the options
  let headerOptions: TUiMenuOption[] = []; // Visible only on desktop
  let popupAuthOptions: TUiMenuOption[] = [];
  let popupSettingOptions: TUiMenuOption[] = [];
  let popupNavigationOptions: TUiMenuOption[] = [];
  let updatedOption: TUiMenuOption | undefined = undefined;
  props.ui.options.forEach(option => {
    switch (option.id) {
      case "UserMenuContent.OpenUserProfile":
        if (appAuthContext.user) {
          updatedOption = { ...option, iconFile: "userProfile", isDefault: false, index: 0, priorityLevel: 0, disabled: false, visible: true, canHideCaption: false, action: null };
          popupAuthOptions.push(updatedOption);
        };
        break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.SignIn":
        //if (!appAuthContext.user && !isAuthenticated && !authInProgress) {
        if (!isAuthenticated && !authInProgress) {
          updatedOption = { ...option, iconFile: "signIn", isDefault: true, index: 0, priorityLevel: 0, disabled: false, visible: true, canHideCaption: false, action: null };
          if (screenType === ScreenType.Desktop)
            headerOptions.push(updatedOption);
          else
            popupAuthOptions.push(updatedOption);
        };
        break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.SignOut": {
        //if (appAuthContext.user) {
        if (isAuthenticated && !authInProgress) {
          updatedOption = { ...option, iconFile: "signOut", isDefault: false, index: 0, priorityLevel: 0, disabled: false, visible: true, canHideCaption: false, action: null };
          popupAuthOptions.push(updatedOption);
        };
      } break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.ChangeLanguage": {
        const localeItem = appUiContext.dictionaries?.supportedLocalesNative?.find(item => item.id == appUiContext.locale.localeId);
        const localeSystemId = appUiContext.locale.localeId == "i18n" ? GetName(appUiContext, "i18n", null) : undefined;
        updatedOption = {
          ...option,
          iconFile: "changeLanguage",
          showDropdown: true,
          canHideCaption: false,
          customCaption: localeSystemId ?
            localeSystemId :
            GetName(appUiContext, appUiContext.locale.localeId, localeItem?.name, 'native'),
          isDefault: false, index: 0, priorityLevel: 0, disabled: false, visible: true, action: null
        };
        if (screenType === ScreenType.Desktop)
          headerOptions.push(updatedOption);
        else
          popupSettingOptions.push(updatedOption);
      } break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.ChangeColorTheme":
        updatedOption = {
          ...option,
          iconFile: "changeColourTheme",
          showDropdown: true,
          canHideCaption: true,
          isDefault: false, index: 0, priorityLevel: 0, disabled: false, visible: true, action: null
        };
        if (screenType === ScreenType.Desktop)
          headerOptions.push(updatedOption);
        else
          popupSettingOptions.push(updatedOption);
        break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.StartBusinessWizard":
        updatedOption = { ...option, iconFile: "magicWand", isDefault: false, index: 0, priorityLevel: 0, disabled: false, visible: true, canHideCaption: false, action: null };
        popupNavigationOptions.push(updatedOption);
        break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.OpenBusinessConsole":
        if (appAuthContext.user?.businessRoles) {
          updatedOption = { ...option, iconFile: "businessEditor", isDefault: false, index: 0, priorityLevel: 0, disabled: false, visible: true, canHideCaption: false, action: null };
          popupNavigationOptions.push(updatedOption);
        };
        break;
      //-----------------------------------------------------------------------
      case "UserMenuContent.OpenAdminConsole":
        if (appAuthContext.user?.staffRoles) {
          updatedOption = { ...option, iconFile: "adminConsole", isDefault: false, index: 0, priorityLevel: 0, disabled: false, visible: true, canHideCaption: false, action: null };
          popupNavigationOptions.push(updatedOption);
        };
        break;
    };
  });
  const popupMenuContent: TUiMenuContent = {
    ...props.ui,
    visible: true,
    disabled: false,
    options: [],
    optionGroups: [],
    hint: props.ui.hint
  };
  if (popupAuthOptions.length > 0)
    popupMenuContent.optionGroups.push({
      id: `${props.ui.id}_auth`,
      directoryId: props.ui.directoryId,
      elementType: props.ui.elementType,
      visible: true,
      disabled: false,
      options: popupAuthOptions,
      optionGroups: []
    });
  if (popupSettingOptions.length > 0)
    popupMenuContent.optionGroups.push({
      id: `${props.ui.id}_setting`,
      directoryId: props.ui.directoryId,
      elementType: props.ui.elementType,
      visible: true,
      disabled: false,
      options: popupSettingOptions,
      optionGroups: []
    });
  if (popupNavigationOptions.length > 0)
    popupMenuContent.optionGroups.push({
      id: `${props.ui.id}_navigate`,
      directoryId: props.ui.directoryId,
      elementType: props.ui.elementType,
      visible: true,
      disabled: false,
      options: popupNavigationOptions,
      optionGroups: []
    });
  //--------------------------------------------------------------------------- Render header options
  const headerOptionElements = headerOptions.map(option => (
    <UserMenuOptionButton
      key={option.id}
      ui={option}
      clickHandler={onOptionSelect}
    />
  ));
  //--------------------------------------------------------------------------- Select language menu
  const preferredLanguageInput: IUiInputDropdown = {
    id: "UserMenuContent.ChangeLanguage",
    directoryId: "",
    elementType: "",
    dictionaryId: "",
    caption: undefined,
    hint: undefined,
    defaultValue: undefined,
    placeholder: undefined,
    validation: {
      required: {
        value: 1,
        message: "",
      }
    }
  };
  //--------------------------------------------------------------------------- Select theme menu
  const preferredThemesInput: IUiInputDropdown = {
    id: "UserMenuContent.ChangeColorTheme",
    directoryId: "",
    elementType: "",
    dictionaryId: "",
    caption: undefined,
    hint: undefined,
    defaultValue: appUiContext.theme.themeId,
    placeholder: undefined,
    validation: {
      required: {
        value: 1,
        message: ""
      }
    }
  };
  //---------------------------------------------------------------------------
  if (props.ui)
    return (
      <div id="UserMenu" className={styles.container}>
        {headerOptionElements}
        <UserMenuButton onClick={onUserMenuButtonSelect} />

        {userMenuPopupOpen && popupMenuContent.optionGroups.length > 0 &&
          <PopupMenu
            ui={popupMenuContent}
            anchorId="UserMenuButton"
            displayIcon={true}
            onClose={() => setUserMenuPopupOpen(false)}
            onSelect={onOptionSelect}
          />}

        {languagesPopupOpen && appUiContext.dictionaries?.supportedLocalesNative &&
          <InputDropdownPopup
            ui={preferredLanguageInput}
            options={appUiContext.dictionaries.supportedLocalesNative}
            selectedOptionId={appUiContext.locale.localeId}
            sortOptions={true}
            hideDescription={false}
            locale="native"
            onClose={onOptionsPopupClose}
            onSelect={onLanguageChange}
          />}

        {themesPopupOpen && appUiContext.dictionaries?.colourThemes &&
          <InputDropdownPopup
            ui={preferredThemesInput}
            options={appUiContext.dictionaries.colourThemes}
            selectedOptionId={appUiContext.theme.themeId}
            sortOptions={true}
            hideDescription={false}
            onClose={onOptionsPopupClose}
            onSelect={onThemeChange} // Close just languages popup but leave user menu popup open
          />}

      </div>
    );
  else return null;
}