import { useContext, useEffect, useState } from 'react';
import { EditFormSection } from '../../../../common/console-layout/edit-form-sections/EditFormSection';
import GetHint from '../../../../common/functions/GetHint';
import { IUiRegionalHomepageSectionAdModules } from './IUiRegionalHomepageSectionAdModules';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { useAbortController } from '../../../../../hooks/useAbortController';
import WikiSpinner from '../../../../app-layout/spinner/wikiSpinner';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import { useMsal } from '@azure/msal-react';
import getListAdModulesAsync from '../functions/getListAdModulesAsync';
import AdModulesList from '../../../../common/ad-modules/ad-modules-list/AdModulesList';
import deleteAdModuleAsync from '../functions/deleteSystemAdModuleAsync';
import { BusinessContext } from '../../../../../context/business-context/BusinessContextProvider';
import { ClassSystemBusinessRegional } from '../ISystemBusinessRegional';
import { EOptionsAdModule } from '../../../../ad-content-editor/ad-modules-editor/AdModuleOptions';
import { IUiOption } from '../../../../common/options/IUiOption';

interface IProps {
  id: string;
  ui: IUiRegionalHomepageSectionAdModules;
  isReadonly: boolean;
}

export default function SectionAdModules(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const [isLoading, setIsLoading] = useState(false);
  const abortController = useAbortController("SectionAdModules");
  const systemBusiness = businessContext.businessState?.business as ClassSystemBusinessRegional;
  //--------------------------------------------------------------------------- Data, AbortController
  useEffect(() => {
    if (!businessContext.businessState?.business) return;
    if (!businessContext.adModules) {
      setIsLoading(true);
      let controller = abortController.newController("getListAdModulesAsync");
      getListAdModulesAsync({
        msalInstanse: instance,
        businessId: systemBusiness.id,
        authConfig: appAuthContext.config,
        abortSignal: controller.signal
      }).then((adModules) => {
        businessContextDispatch({ type: "SetAdModules", adModules: adModules });
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    };
    //-------------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
    });
  }, [businessContext]);
  //---------------------------------------------------------------------------
  const onDelete = (adModuleId: string) => {
    if (props.isReadonly) return;
    if (!businessContext.businessState?.business) return;
    setIsLoading(true);
    let controller = abortController.newController("deleteAdModuleAsync");
    deleteAdModuleAsync({
      msalInstanse: instance,
      businessId: systemBusiness.id,
      adModuleId: adModuleId,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal
    }).then((adModules) => {
      businessContextDispatch({ type: "SetAdModules", adModules: adModules });
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: EOptionsAdModule, adModuleId: string) => {
    switch (optionId) {
      case "ModuleDelete":
        onDelete(adModuleId);
        break;
      default:
        break;
    };
  };
  //---------------------------------------------------------------------------
  // Filter ad module options: here we only can allow to delete ad module
  // (availability of the option for a particular ad module is defined in the ad module component)
  const adModuleOptions: IUiOption[] = [];
  props.ui.adModule.menuContent.options.forEach(option => {
    switch (option.id) {
      case "AdModule.MenuContent.OptionDelete":
        adModuleOptions.push(option);
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <EditFormSection
      id={props.id}
      caption={GetHint(appUiContext, props.ui.id, props.ui.hint)}
    >
      <WikiSpinner show={isLoading} />
      <AdModulesList
        ui={props.isReadonly ?
          undefined : {
            ...props.ui.adModule,
            menuContent: {
              ...props.ui.adModule.menuContent,
              options: adModuleOptions
            }
          }}
        data={businessContext.adModules}
        isReadonly={true}
        useExternalLayout={false}
        onModuleOptionSelect={onOptionSelect}
      />
    </EditFormSection>
  );
}