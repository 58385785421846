import { useContext, useEffect, useState } from 'react';
import { EInputValueType } from '../../../../../common/input/input-box/InputBox';
import PopupDialog from '../../../../../common/popup-v2/popup-dialog/PopupDialog';
import styles from './EmailEditPopup.module.css';
import { TUiInputText } from '../../../../../common/input/input-text/TUiInputText';
import { TUiMenuOption } from '../../../../../common/menu/menu-content/menu-option/TUiMenuOption';
import { IUiEditFormUserEmail } from '../IUiInputListUserEmails';
import GetCaption from '../../../../../common/functions/GetCaption';
import { getTUiMenuContent } from '../../../../../common/menu/menu-content/TUiMenuContent';
import FormOptions from '../../../../../common/form-options-bar/FormOptions';
import InputText from '../../../../../common/input-v2/input-text/InputText';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';

export interface IUiEmailEditorPopup {
  id: string;
  header: any;
  inputEmail: TUiInputText;
  options: TUiMenuOption[];
}

interface IProps {
  ui: IUiEditFormUserEmail;
  data?: string;
  onClose?: () => void;
  onUpdate: (updatedEmail: string) => void;
}

export default function EmailEditPopup(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [data, setData] = useState<string>();
  const [isValid, setIsValid] = useState(true);
  //---------------------------------------------------------------------------
  useEffect(() => {
    setData(props.data);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onUpdate = (updatedValue: string, isValid: boolean) => {
    console.log(updatedValue)
    setData(updatedValue);
    setIsValid(isValid);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case 'OptionCancel':
        props.onClose && props.onClose();
        break;
      case 'OptionOk':
        if (data && isValid) {
          props.onUpdate(data);
        };
        break;
    };
  };
  //---------------------------------------------------------------------------
  const mc = getTUiMenuContent(props.ui.menuContent);
  mc.options.forEach(option => {
    switch (option.id) {
      case "OptionOk":
        option.iconFile = "check";
        option.disabled = !isValid;
        option.isDefault = isValid;
        break;
      case 'OptionCancel':
        option.iconFile = "close";
        break;
    };
  });
  const menuElement = (mc.options && mc.options.length > 0) ?
    <FormOptions
      ui={mc}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    /> : undefined;
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id={props.ui.id}
      header={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      toolbarContent={menuElement}
      isDraggable={false}
      isModal={true}
      onClose={props.onClose ? props.onClose : () => { }}
    >
      <div className={styles.container}>
        <InputText
          ui={{...props.ui.inputUserEmail, hideCaption: true}}
          valueType={EInputValueType.Email}
          enterKeyHint='enter'
          data={data}
          isFocused={true}
          onUpdate={onUpdate}
        />
      </div>
    </PopupDialog>
  );
}