import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { BusinessTagsDictionaryDraft } from "../business-tags-editor/IBusinessTagsDictionaryDraft";
import { apiPostPrivate, checkResponse } from "../../../../../utils/api";
import { apiBasePath, pathSaveBusinessTagsDictionaryDraft } from "../../../../../utils/apiPathConstant";
import { IBusinessTagsDictionaryResponseContent } from "./IBusinessTagsDictionaryResponseContent";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  draft?: BusinessTagsDictionaryDraft;
}

export async function saveBusinessTagsDictionaryDraftAsync(props: IProps): Promise<IBusinessTagsDictionaryResponseContent> {
  if (!props.authConfig) throw new Error("saveBusinessTagsDictionaryDraftAsync: auth config is not provided");
  if (!props.draft) throw new Error("saveBusinessTagsDictionaryDraftAsync: dictionary draft is not provided");
  //---------------------------------------------------------------------------
  let draftToSave = props.draft;
  if (props.draft.locker) {
    draftToSave = props.draft.getUpdated({ locker: undefined });
  };
  //---------------------------------------------------------------------------
  const response = await apiPostPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${pathSaveBusinessTagsDictionaryDraft}`,
    draftToSave,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "saveBusinessTagsDictionaryDraftAsync", props.abortSignal?.aborted);
  const draft = new BusinessTagsDictionaryDraft().initialize(response?.content.draft);  
  return {
    draft: draft,
    subDictionariesList: response?.content.subDictionaries,
    subDictionaryOriginals: response?.content.subDictionaryOriginals
  };
}