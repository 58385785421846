import { Route, Routes } from 'react-router';
import DirectoryList from '../widgets/directory-list/DirectoryList';
import WidgetDirectoryTranslator from './widget-directory-translator/WidgetDirectoryTranslator';
import useNavigateWithContext from '../../../../hooks/useNavigateWithContext';
import { pathAdminConsole, pathSystemTranslator } from '../../AdminConsole';
import { optionIdWidgets, pathWidgets } from '../SystemTranslatorConsole';
import { IUiOption } from '../../../common/options/IUiOption';
import { useContext, useEffect } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { TUiBreadcrumb } from '../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';

export type EDirectoryId = "UiCommon" | "UiUserConsole" | "UiBusinessConsole" | "UiAdminConsole";

interface IProps {
  optionsForBreadcrumb?: IUiOption[];
}

export default function Widgets(props: IProps) {
  const { appUiContextDispatch } = useContext(AppUiContextStore);
  const navigate = useNavigateWithContext();
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdWidgets);
    if (!option) return;
    const updatedBreadcrumb: TUiBreadcrumb = {
      id: option.id,
      caption: option.caption,
      path: `/${pathAdminConsole}/${pathSystemTranslator}/${pathWidgets}`
    };
    appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: updatedBreadcrumb });
  }, []);
  //---------------------------------------------------------------------------
  const onSelectDirectory = (dictionaryId: EDirectoryId, locale?: string) => {
    navigate(`/${pathAdminConsole}/${pathSystemTranslator}/${pathWidgets}/${dictionaryId}/${locale}`);
  };
  //---------------------------------------------------------------------------
  return (
    <Routes>

      {/* List of directories */}
      <Route
        index
        element={
          <DirectoryList
            directoryType='Widgets'
            onSelect={onSelectDirectory}
          />}
      />

      {/* Widgets Directory Translation Editor */}
      <Route
        path={`:directoryId/:localeId`}
        element={
          <WidgetDirectoryTranslator />}
      />

    </Routes>
  );
}