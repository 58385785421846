import { TSortType } from "../../../common/lists/sorter-editor/ISorterItem";
import { IApprovalRequest } from "../approval-request-form/TApprovalRequest";
import { ICustomerAdRequestsListItem } from "../customer-ads/requests-list/requests-list-item/ICustomerAdRequestsListItem";
import { IRegionalHomepageRequestsListItem } from "../regional-homepages/requests-list/requests-list-item/IRegionalHomepageRequestsListItem";

export type TApprovalRequestKey = keyof IApprovalRequest;
export type TRegionalHomepageRequestKey = keyof IRegionalHomepageRequestsListItem;
export type TCustomerAdRequestsListItemKey = keyof ICustomerAdRequestsListItem;

export const compareRequests = (
  a: IApprovalRequest,
  b: IApprovalRequest,
  propertyName: string,
  sort: TSortType
) => {
  const aValue = a[propertyName as TApprovalRequestKey] ?? "";
  const bValue = b[propertyName as TApprovalRequestKey] ?? "";
  //console.log(propertyName)
  //console.log(a[propertyName as TApprovalRequestKey], b[propertyName as TApprovalRequestKey])
  if (aValue == bValue) {
    return 0;
  } else if (aValue < bValue) {
    return sort == "Asc" ? -1 : 1;
  } else {
    return sort == "Asc" ? 1 : -1;
  };
};

export const compareRegionalHomepageRequests = (
  a: IRegionalHomepageRequestsListItem,
  b: IRegionalHomepageRequestsListItem,
  propertyName: string,
  sort: TSortType
) => {
  const aValue = a[propertyName as TRegionalHomepageRequestKey] ?? "";
  const bValue = b[propertyName as TRegionalHomepageRequestKey] ?? "";
  //console.log(propertyName)
  //console.log(a[propertyName as TApprovalRequestKey], b[propertyName as TApprovalRequestKey])
  if (aValue == bValue) {
    return 0;
  } else if (aValue < bValue) {
    return sort == "Asc" ? -1 : 1;
  } else {
    return sort == "Asc" ? 1 : -1;
  };
};

export const compareCustomerAdRequests = (
  a: ICustomerAdRequestsListItem,
  b: ICustomerAdRequestsListItem,
  propertyName: string,
  sort: TSortType
) => {
  const aValue = a[propertyName as TCustomerAdRequestsListItemKey] ?? "";
  const bValue = b[propertyName as TCustomerAdRequestsListItemKey] ?? "";
  //console.log(propertyName)
  //console.log(a[propertyName as TApprovalRequestKey], b[propertyName as TApprovalRequestKey])
  if (aValue == bValue) {
    return 0;
  } else if (aValue < bValue) {
    return sort == "Asc" ? -1 : 1;
  } else {
    return sort == "Asc" ? 1 : -1;
  };
};