import CombineStyles from '../../../../../utils/combineStyles';
import { TUiMenuOption } from '../../../menu/menu-content/menu-option/TUiMenuOption';
import { TUiMenuContent } from '../../../menu/menu-content/TUiMenuContent';
import PopupMenuItem from '../popup-menu-item/PopupMenuItem';
import styles from './PopupMenuItemGroup.module.css';

interface IProps {
  ui: TUiMenuContent;
  displayIcon: boolean;
  isFirst: boolean;
  isLast: boolean;
  onSelect?: (selectedOptionId: string) => void;
  onClose: () => void;
}

export default function PopupMenuItemGroup(props: IProps) {
  //console.log(props.isLast)
  //---------------------------------------------------------------------------
  const optionElements = props.ui.options.map(option => {
    return (
      <PopupMenuItem
        key={option.id}
        ui={option}
        displayIcon={true}
        onSelect={props.onSelect}
        onClose={props.onClose}
      />
    );
  });
  //---------------------------------------------------------------------------
  return (
    <div className={CombineStyles([
      styles.container,
      props.isFirst ? styles.isFirst : "",
      props.isLast ? styles.isLast : ""
    ])}>
      {optionElements}
    </div>
  );
}