import styles from './MapError.module.css';

interface IProps {
}

export default function MapError(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        We are sorry, <br/>but there is a problem with Google Map at the moment
      </div>
    </div>
  );
}