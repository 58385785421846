import { useState } from "react";
import { TImageData } from "../../../../common/image-editor/TImageData";
import Toggler from "../../../../common/toggler/Toggler";
import styles from "./ImageUsageList.module.css";
import { ImageUsage } from "./ImageUsage";
import { TImageUsage } from "../../../../common/image-editor/TImageUsage";
import { ImageUsageBusiness } from "./ImageUsageBusiness";
import { TogglerWithCaption } from "../../../../common/toggler/TogglerWithCaption";
//--------------------------------------------------------------------------

class TImageUsages {
  constructor(source: TImageData) {

  }
}
//--------------------------------------------------------------------------
interface IProps {
  imageData: TImageData
}
//--------------------------------------------------------------------------
export function ImageUsageList(props: IProps) {
  const [collapsed, setCollapsed] = useState(true);
  //------------------------------------------------------------------------
  const onClick = () => {
    setCollapsed(!collapsed);
  }
  //------------------------------------------------------------------------
  let items = props.imageData.getUsageBusinesses()?.map(usage =>
    <ImageUsageBusiness key={usage.businessId} usageBusiness={usage} />
  );
  //------------------------------------------------------------------------
  //let count = items?.length ?? 0;
  let count = props.imageData.imageUsage?.length ?? 0;
  //------------------------------------------------------------------------
  return (
    <div>
      <TogglerWithCaption
        caption={`References (${count})`}
        showToggler={count > 0}
        collapsed={collapsed}
        onClick={onClick}
        togglerPosition={"Right"}
      />
      <div className={styles.childrenContainer}>
        {!collapsed && items}
      </div>
    </div>
  );
}