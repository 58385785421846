import { IUiInteractiveForm } from "../../../common/forms/IUiInteractiveForm";
import { IUiDirectoryEditorHeader } from "./directory-editor-wrapper/directory-editor-header/IUiDirectoryEditorHeader";

export interface IUiDirectoryEditor extends IUiInteractiveForm {
  directoryEditorHeader: IUiDirectoryEditorHeader;
  unsavedChangesDialog: IUiInteractiveForm;
}

export const tmpUiDirectoryEditor: IUiDirectoryEditor = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: undefined,
  hint: undefined,
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionClose",
      directoryId: "",
      elementType: "",
      caption: { en: "Close" },
      hint: undefined
    },{
      id: "OptionDelete",
      directoryId: "",
      elementType: "",
      caption: { en: "Delete Draft" },
      hint: undefined
    },{
      id: "OptionEditModeEnable",
      directoryId: "",
      elementType: "",
      caption: { en: "Edit Mode" },
      hint: undefined
    },{
      id: "OptionEditModeDisable",
      directoryId: "",
      elementType: "",
      caption: { en: "Exit Edit Mode" },
      hint: undefined
    },{
      id: "OptionAdd",
      directoryId: "",
      elementType: "",
      caption: { en: "Add Root Item" },
      hint: undefined
    },{
      id: "OptionSave",
      directoryId: "",
      elementType: "",
      caption: { en: "Save" },
      hint: undefined
    },{
      id: "OptionPublish",
      directoryId: "",
      elementType: "",
      caption: { en: "Publish" },
      hint: undefined
    }]
  },
  directoryEditorHeader: {
    inputDirectoryId: {
      id: "",
      directoryId: "",
      elementType: "",
      caption: { en: "Directory ID" },
      hint: undefined
    }
  },
  unsavedChangesDialog: {
    id: "DirectoryEditorWrapper.UnsavedChangesDialog",
    directoryId: "",
    elementType: "",
    caption: {en: "Unsaved Changes"},
    hint: {en: "The draft has unsaved changes. You are about to discard them"},
    menuContent: {
      id: "",
      directoryId: "",
      elementType: "",
      hint: undefined,
      options: [{
        id: "optionOk",
        directoryId: "",
        elementType: "",
        caption: { en: "Ok" },
        hint: null
      }, {
        id: "optionCancel",
        directoryId: "",
        elementType: "",
        caption: { en: "Cancel" },
        hint: null
      }]
    }
  }
};