import { ReactNode, useContext, useLayoutEffect, useRef } from 'react';
import CombineStyles from '../../../utils/combineStyles';
import styles from './ValidationWrapper.module.css';
import { IValidationState } from '../input-v2/input-validation/IUiValidation';
import GetValidationMessage from '../functions/GetValidationMessage';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';

interface IProps {
  id?: string;
  validationState?: IValidationState;
  children: ReactNode;
}

export default function ValidationWrapper(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const wrappderDivRef = useRef<HTMLDivElement>(null);
  const validationDivRef = useRef<HTMLDivElement>(null);
  const cachedValidationDivRect = useRef<DOMRect>();
  const isValid = props.validationState?.isValid == undefined ? true : props.validationState.isValid;
  //--------------------------------------------------------------------------- Animation: apply FLIP
  useLayoutEffect(() => {
    if (wrappderDivRef.current && validationDivRef.current && cachedValidationDivRect.current) {
      const wrappderDiv = wrappderDivRef.current;
      const animatedDiv = validationDivRef.current;
      // Set desired styles for the divs: wrapper
      const newPadding = isValid ? '0' : '0em';
      const previousPadding = isValid ? '0em' : '0';
      wrappderDiv.style.paddingTop = newPadding;
      wrappderDiv.style.paddingBottom = newPadding;
      // Set desired styles for the divs: validaton
      animatedDiv.style.height = !isValid ? 'auto' : '1px';
      const newOpacity = !isValid ? '1' : '0';
      const previousOpacity = !isValid ? '1' : '0';
      animatedDiv.style.opacity = newOpacity;
      // FLIP: Last
      const animatedDivRect = animatedDiv.getBoundingClientRect();
      const animatedDivScaleX = cachedValidationDivRect.current.width / animatedDivRect.width;
      const animatedDivScaleY = cachedValidationDivRect.current.height / animatedDivRect.height;
      //console.log(`scale(${animatedDivScaleX}, ${animatedDivScaleY})`)
      // Update cached data with current value
      cachedValidationDivRect.current = animatedDivRect;
      // Handle animation
      requestAnimationFrame(() => {
        // FLIP: Invert
        //console.log('invert')
        // wrapper div
        wrappderDiv.style.paddingTop = previousPadding;
        wrappderDiv.style.paddingBottom = previousPadding;
        wrappderDiv.style.transition = 'none';
        // validation div
        animatedDiv.style.transition = 'none';
        animatedDiv.style.transform = `scale(${animatedDivScaleX}, ${animatedDivScaleY})`;
        animatedDiv.style.transformOrigin = `top left`;
        animatedDiv.style.opacity = previousOpacity;
        requestAnimationFrame(() => {
          // FLIP: Play 
          //console.log('play')
          wrappderDiv.style.transition = 'padding-top 200ms ease-out, padding-bottom 200ms ease-out';
          wrappderDiv.style.paddingTop = newPadding;
          wrappderDiv.style.paddingBottom = newPadding;
          animatedDiv.style.transition = `transform 200ms ease-out, opacity 200ms ease-out`;
          animatedDiv.style.transform = 'none';
          animatedDiv.style.opacity = newOpacity;
        });
      });
    };
  }, [props.validationState?.isValid]);
  //---------------------------------------------------------------------------
  //console.log(props.validationMessages)
  //props.validationMessages && console.log(props.validationMessages[0].text)
  const validationMessageElements = props.validationState?.validationMessages?.map(item => (
    <div
      key={item.id}>
      {GetValidationMessage(appUiContext, item.id, item.message)}
    </div>
  ));
  //--------------------------------------------------------------------------- Animation: Cache initial measurements (FLIP:First)
  if (validationDivRef.current && cachedValidationDivRect.current == undefined) {
    cachedValidationDivRect.current = validationDivRef.current.getBoundingClientRect();
  };
  //---------------------------------------------------------------------------
  return (
    <div
      ref={wrappderDivRef}
      //id={props.id}
      className={styles.container}
    >

      <div className={CombineStyles([
        styles.data,
        !isValid ? styles.invalid : ""
      ])}
      >
        {props.children}
      </div>

      <div
        ref={validationDivRef}
        className={styles.validation}
      >
        {validationMessageElements}
      </div>

    </div>
  );
}