import { useContext, useEffect, useState } from "react";
import GetCaption from "../../functions/GetCaption";
import GetHint from "../../functions/GetHint";
import InputWrapper from "../input-wrapper/InputWrapper";
import { EInputValueType, IUiInput, TEnterKeyHint } from '../IUiInput';
import RawInputText from '../raw-inputs/RawInputText';
import { ValidateInput } from "../input-validation/ValidateInput";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import { IValidationState } from "../input-validation/IUiValidation";

export interface IUiInputText extends IUiInput {
  defaultValue?: string;
}

interface IProps {
  ui: IUiInputText;
  data?: string;
  isUnique?: boolean; // Provide only if unique validation check is provided (because input can't check it by itself)
  valueType?: EInputValueType; // Ignored in case if multiline=true
  enterKeyHint?: TEnterKeyHint;
  linesCount?: number;
  validationState?: IValidationState;
  isFocused?: boolean;
  onInitialValidate?: (isValid: boolean) => void; // Can be provided only if validationState is not provided, otherwise it doesn't make sense
  onUpdate?: (updatedValue: string, isValid: boolean) => void;
}

export default function InputText(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [validationState, setValidationState] = useState<IValidationState>();
  const linesCount = props.linesCount == undefined ? 0 : props.linesCount;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (props.validationState) {
      // If external validation state is provided
      setValidationState(props.validationState);
    } else {
      // Otherwise validate using UI validation
      if (props.ui.validation) {
        const validationData = ValidateInput({
          elementId: props.ui.id,
          validation: props.ui.validation,
          data: props.data,
          isUnique: props.isUnique
        });
        setValidationState(validationData);
        props.onInitialValidate && props.onInitialValidate(validationData.isValid);
      } else {
        setValidationState({
          isValid: true,
          validationMessages: []
        });
        props.onInitialValidate && props.onInitialValidate(true);
      };
    };
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onUpdate = (updatedValue: string) => {
    if (props.ui.validation) {
      const validationData: IValidationState = ValidateInput({
          elementId: props.ui.id,
          validation: props.ui.validation,
          data: updatedValue,
          isUnique: props.isUnique
        });
      setValidationState(validationData);
      props.onUpdate && props.onUpdate(updatedValue, validationData.isValid);
    } else {
      props.onUpdate && props.onUpdate(updatedValue, true);
    };
  };
  //---------------------------------------------------------------------------
  return (
    <InputWrapper
      caption={!props.ui.hideCaption ? GetCaption(appUiContext, props.ui.id, props.ui.caption) : undefined}
      hint={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      hideCaption={props.ui.hideCaption}
      hideHint={props.ui.hideHint}
      readonly={props.ui.readonly}
      disabled={props.ui.disabled}
      validation={validationState}
    >
      <RawInputText
        ui={props.ui}
        data={props.data}
        valueType={props.valueType}
        enterKeyHint={props.enterKeyHint}
        linesCount={linesCount}
        isFocused={props.isFocused}
        isValid={validationState?.isValid}
        onUpdate={onUpdate}
      />
    </InputWrapper>
  );
}