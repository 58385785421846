import { useContext, useEffect, useState } from "react";
import { apiPostPrivate } from "../../utils/api";
import { useMsal } from "@azure/msal-react";
import { AppAuthContextStore } from "../../context/app-auth-context/AppAuthContext";
import { AppUiContextStore } from "../../context/app-ui-context/AppUiContextProvider";
import { getRequestOptions } from "../../utils/api";
import styles from "./TestUtils.module.css";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import WikiSpinner from "../app-layout/spinner/wikiSpinner";
import {
  apiBasePath,
  sendMailUtil
} from "../../utils/apiPathConstant";
import { setToLS, lsUtilLinkUi, getFromLS, clearFromLS } from "../../utils/storage";
import { MsalTemplateWrapper } from "../../context/app-auth-context/MsalTemplateWrapper";

//const permittedFileExtensions = ["html", "htm"];
//const utils002 = "C:\\Miramark\\repos\\RikiWikiV3\\RikiWikiWeb\\ClientApp\\src\\components\\test-utils\\Utils_002.htm";
//const utils002 = "Utils_002.htm";
//const utils002Dev3 = "https://rikiwikiusrdev3.blob.core.windows.net/utils/Utils_002.htm";
const permittedFileNames = [
  "index.html", "index.htm", "default.html", "default.htm"
];
//const storageUri = "https://simplestaticwebsite.blob.core.windows.net/$web/";

export interface IBlobFolder {
  folderName: string;
  itemToDisplay: string | undefined;
}

interface IBlobRoot {
  subFolders: IBlobFolder[];
  subFolderNames: string[];
}

interface IErrorStatus {
  isError: boolean;
  message: string;
}

export default function TestUtils() {
  const { appUiContext } = useContext(AppUiContextStore);
  const { instance } = useMsal();
  const { appAuthContext, appAuthContextDispatch } = useContext(AppAuthContextStore);
  const [isLoading, setIsLoading] = useState(true);
  const [itemsTree, setItemsTree] = useState<IBlobRoot>();
  const [viewerOpen, setViewerOpen] = useState(false);
  const [authenticateUser, setAuthenticateUser] = useState(false);
  const [isPopupOpen, setisPopupOpen] = useState(false);
  const [currentHtml, setCurrentHtml] = useState<string>();
  const [searchString, setSearchString] = useState("");
  const [iframeContextHtml, setiframeContextHtml] = useState("");
  const [emailRecipient, setemailRecipient] = useState("");
  const [emailSender, setemailSender] = useState("");
  const [errorStatus, setErrorStatus] = useState<IErrorStatus>({ isError: false, message: "" });
  //---------------------------------------------------------------------------
  const storageUri = appUiContext.sysSettings.testUtilsUrl;
  if (!storageUri) {
    const message = "Error: test utilities URL is not provided";
    setErrorStatus({ isError: true, message: message });
    console.error(message);
    setIsLoading(false);
  }
  //---------------------------------------------------------------------------
  useEffect(() => {
    const uri = storageUri + "?restype=container&comp=list";
    console.log("trying to get blobs from storage...", uri);
    fetch(uri, getRequestOptions({ method: "GET" }))
      .then(response => response.text())
      .then(text =>
        parseContainerContent(text)
      )
      .catch((error) => {
        setErrorStatus({ isError: true, message: error });
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  //-----------------------------------------------------------------------------
  const parseContainerContent = (text: string) => {
    //console.log("response.text:", text);
    //---------------------------------------------------------------------
    // Parse XML response
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(text, "text/xml");
    //---------------------------------------------------------------------
    // Loop through blobs
    var items: string[] = [];
    xmlDoc.getElementsByTagName("Blobs")[0].childNodes.forEach((blob) => {
      const blobFullName = blob.childNodes[0].textContent;
      if (blobFullName) {
        items.push(blobFullName);
      };
    });
    //---------------------------------------------------------------------
    items.sort();
    //items.splice(1, 0, "002. Browser Info for RikiWiki/file:///C:/Miramark/repos/RikiWikiV3/RikiWikiWeb/ClientApp/src/components/test-utils/Utils_002.htm")
    //items.splice(1, 0, `002. Browser Info for RikiWiki/${utils002}`);
    console.log("items:", items);
    var tree: IBlobRoot = {
      subFolders: [],
      subFolderNames: []
    };
    var currentNode: IBlobFolder;
    items.forEach(blobFullName => {
      // Skip files besides permitted ones
      const fileName = blobFullName.toLocaleLowerCase().split("/").slice(-1)[0];
      //console.log("fileName:", fileName);
      if (!permittedFileNames.includes(fileName)) {
        return;
      }
      //console.log("-----------------------------")
      //console.log(blobFullName);
      var parts = blobFullName?.split("/");
      //-------------------------------------------------------------------
      for (let i = 0; i < parts.length; i++) {
        // Skip root files and deeper elements (take only root folders and files inside them)
        if (i == 0 && (i == parts.length - 1)) continue;
        if (i > 1) continue;
        //console.log(i)
        //-----------------------------------------------------------------
        //console.log(currentNode)
        if (i == parts.length - 1) {
          // Leaf
          //console.log(parts[i] + ": leaf");
          if (currentNode) {
            currentNode.itemToDisplay = blobFullName;
          }
        }
        else {
          // Folder
          if (i == 1)
            continue; // skip subfolders
          //---------------------------------------------------------------
          //console.log(parts[i] + ": folder");
          if (tree.subFolderNames.includes(parts[i])) {
            continue;
          }
          else {
            var newFolder: IBlobFolder = {
              folderName: parts[i],
              itemToDisplay: undefined
            };
            //console.log(newNode);
            tree.subFolderNames.push(newFolder.folderName);
            tree.subFolders.push(newFolder);
            //-------------------------------------------------------------
            currentNode = newFolder;
          }
        }
      };
    });
    console.log("tree:", tree);
    setItemsTree(tree);
    const cachedUi = getFromLS(lsUtilLinkUi);
    console.log("cachedUi:", cachedUi);
    if (cachedUi) {
      setCurrentHtml(cachedUi);
      setViewerOpen(true);
      setisPopupOpen(true);
    }
  }
  //-----------------------------------------------------------------------------
  const handleClick = (itemUri: string | undefined) => {
    if (itemUri) {
      console.log("itemUri:", itemUri)
      setCurrentHtml(storageUri + itemUri);
      setViewerOpen(true);
    }
  }
  const sendMailWithBase64Content = () => {
    setAuthenticateUser(false);
    setIsLoading(true);
    const input = document.getElementById('iframeContent') as HTMLIFrameElement;
    input.scrollTop = 0;
    html2canvas(input, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: input.scrollHeight + input.clientHeight,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'px', [Number(input.clientWidth), Number(input.scrollHeight)], true);
      pdf.addImage(imgData, 'JPEG', 20, 20, Number(input.clientWidth), Number(input.scrollHeight), '', 'FAST');
      //pdf.save(`downloadFileName.pdf`);
      let base64 = pdf.output('datauristring').replace("data:application/pdf;filename=generated.pdf;base64,", "");
      const bodyJson = {
        "from": emailSender,
        "to": [emailRecipient],
        "cc": [],
        "subject": "[TEST] Testing through EMU solution new version0",
        "body": "Testing through EMU solution renamed",
        "timestamp": String(Date.now()),
        "status": "new",
        "Type": "Regular",
        "application": "RikiWiki",
        "attachment": base64,
      };
      const sendMailUrl = `${apiBasePath}${sendMailUtil}`;
      if (appAuthContext.config) {
        apiPostPrivate(instance,
          appAuthContext.config,
          sendMailUrl,
          bodyJson)
          .then((response) => {
            if (response) {
              setIsLoading(false);
              if (response.status == 200) {
                setisPopupOpen(false);
                alert('Mail sent successfully.');
              } else {
                alert('Error while sending a mail.');
                setIsLoading(false);
              }
            } else {
              alert('Error while sending a mail.');
              setIsLoading(false);
            }
          }).catch((error) => {
            console.error(error);
            setIsLoading(false);
          });
      }
    });
  }

  //-----------------------------------------------------------------------------
  const getIframeContent = () => {
    const currentUserId = instance.getActiveAccount()?.localAccountId;
    if (appAuthContext.config && currentUserId) {
      //open popup to get the email addresses of recipient 
      setisPopupOpen(!isPopupOpen);
    } else {
      setToLS(lsUtilLinkUi, currentHtml);
      setAuthenticateUser(true);
    }
  }
  //-----------------------------------------------------------------------------
  var items;
  if (isLoading) {
    items = <>Please, wait a second: data is prepared...</>;
  } else if (itemsTree && itemsTree.subFolders.length > 0) {
    items = itemsTree.subFolders
      .filter(f =>
        f.folderName.toLowerCase().includes(searchString.toLowerCase()) || searchString == '')
      .map((item) => {
        //console.log("item:", item)
        return (
          <div
            key={item.folderName}
            className={styles.item}
            onClick={() => handleClick(item.itemToDisplay)}
          >
            {item.folderName}
          </div>
        )
      })
  } else if (errorStatus) {
    items = <>{errorStatus.message}</>;
  };
  window.addEventListener('message', event => {
    // IMPORTANT: check the origin of the data! 
    const org1 = 'https://simplestaticwebsite.blob.core.windows.net';
    const org2 = 'https://rikiwikiusrdev3.blob.core.windows.net';
    let b1 = event.origin.startsWith(org1);
    let b2 = event.origin.startsWith(org2);
    if (b1 || b2) {
      //setiframeContextHtml(event.data);
      //console.log("setiframeContextHtml:", event.data);
    } else {
      return;
    }
  });
  //-----------------------------------------------------------------------------
  const onCancelWizard = () => {
    //navigate("/");
    setisPopupOpen(false);
  };
  //---------------------------------------------------------------------------
  const navigateToNextStep = () => {
    clearFromLS(lsUtilLinkUi);
    if (emailRecipient && emailSender && validateEmail(emailRecipient) && validateEmail(emailSender)) {
      sendMailWithBase64Content();
    } else {
      alert("Please provide both sender and recipient email addressess.");
    }
  };
  const validateEmail = (val: string) => {
    if (val) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(val)) {
        return true;
      }
      alert("You have entered an invalid email address!")
      return false;
    }
  };
  return (
    <>
      <WikiSpinner show={isLoading}></WikiSpinner>

      <div className={styles.container}>
        {!viewerOpen &&
          <div className={styles.items}>
            <div className={styles.inputContainer}>
              <input
                type="text"
                placeholder="Input text to search..."
                className={styles.input}
                value={searchString}
                autoFocus
                onInput={(event) => setSearchString(event.currentTarget.value)}
              />
              <button
                className={styles.clearButton}
                onClick={() => setSearchString('')}
              >
                Clear
              </button>
            </div>
            {items}
          </div>
        }
        {viewerOpen && currentHtml &&
          <div className={styles.viewer}>
            <div className={styles.options}>
              <button onClick={() => setViewerOpen(false)}>Close</button>
              <button onClick={() => getIframeContent()}>Send</button>
            </div>

            <div className={styles.info}>
              {currentHtml}
            </div>
            {/* <div
              id="iframeContent"
              style={{ overflow: "auto", padding: "20px", color: "rgb(20 20 30)" }}
              className={styles.iframe}
            >
              {parse(iframeContextHtml)}
            </div> */}
            <iframe
              id="iframe"
              title="storageStaticHtml"
              src={currentHtml}
              className={styles.iframe}
              allow="geolocation"
            //style={{ display: "none" }}
            >
            </iframe>
          </div>
        }
        {authenticateUser &&
          <MsalTemplateWrapper>
          </MsalTemplateWrapper>
        }
        {/* {isPopupOpen && (
          <WikiModal
            id="getEmailIdsPopup"
            open={true}
            onBack
            backdrop="static"
            className={styles.container}
            centered
            header="Sender and Recipient Details"
            size="xl"
            onCloseAction={onCancelWizard}
            isHeaderBack={false}
            footer={
              <Action
                onCancel={onCancelWizard}
                onsubmit={navigateToNextStep}
                disabledSubmitFlag={emailRecipient === "" || emailSender === ""}

              />
            }
          >
            {
              <div className={styles.inputContainer}>
                <input
                  type="text"
                  placeholder="Enter Recipient email address"
                  className={styles.input}
                  value={emailRecipient}
                  onBlur={(event) =>
                    validateEmail(event.currentTarget.value)
                  }
                  onInput={(event) => setemailRecipient(event.currentTarget.value)}
                />
                <input
                  type="text"
                  placeholder="Enter Sender email address"
                  className={styles.input}
                  value={emailSender}
                  onBlur={(event) =>
                    validateEmail(event.currentTarget.value)
                  }
                  onInput={(event) => setemailSender(event.currentTarget.value)}
                />
              </div>
            }
          </WikiModal>
        )} */}
      </div>
    </>
  )
}