import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useContext } from "react";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";
import GoogleMapError from "./map-error/MapError";

interface IProps {
  callback?: (status: Status) => void;
  children: React.JSX.Element;
}

export default function GoogleMapsWrapper(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  if (appUiContext.sysSettings.mapSettings.key)
    return (
      <Wrapper
        apiKey={appUiContext.sysSettings.mapSettings.key}
        //libraries={appUiContext.sysSettings.mapSettings.libraries}
        libraries={["places", "marker"]} // "marker" allows using AdvancedMarker (in case if mapId is provided in MapItself component)
        version={"beta"}
        //render={render}
        callback={status => {
          props.callback && props.callback(status);
        }}>
        {props.children}
      </Wrapper>
    );
  else return (
    <GoogleMapError />
  );
}