import CombineStyles from "../../../../utils/combineStyles";
import Icon from "../../icon/Icon";
import { TUiMenuOption } from "../../menu/menu-content/menu-option/TUiMenuOption";
import styles from "./ImageEditorOption.module.css";
import { v4 as newGuid } from "uuid";

interface IProps {
  ui: TUiMenuOption;
}

export default function SeparatorOption(props: IProps) {
  //---------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container, styles.disabled
    //props.ui.disabled ? styles.disabled : ""
  ]);
  //---------------------------------------------------------------------------
  //console.log("SeparatorOption.props.ui:", props.ui);
  //---------------------------------------------------------------------------
  return (
    <div key={newGuid()} className={containerStyles}>
      <Icon imageName="separator" />
    </div>
  );
}