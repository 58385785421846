export type EDictionaryType =
  'AdPageStatuses' |
  'AdRanks' |
  'BusinessTags' |
  'ContactTypes' |
  'ColourThemes' |
  'Countries' |
  'SupportedLocales' |
  'SupportedLocalesNative' |
  'RequestRejectionReasons' |
  'RequestStatuses' |
  'SupportedRegions' |
  'SystemSettings' |
  'Locales';

//------------------------------------------------------------------------------------
export function getIcon(dictionaryId: EDictionaryType | undefined) {
  //console.log(dictionaryId)
  switch (dictionaryId) {
    case "AdPageStatuses":
      return "businessProgress";
    case "AdRanks":
      return "dollar";
    case "BusinessTags":
      return "tags";
    case "ContactTypes":
      return "phone";
    case "ColourThemes":
      return "changeColourTheme";
    case "SupportedLocales":
      return "translator";
    case "SupportedRegions":
      return "changeLanguage";
    case "Locales":
      return "translator";
    case "RequestRejectionReasons":
      return "ban";
    default:
      return "";
  }
}
