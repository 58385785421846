import { useContext } from 'react';
import { IUiListItem } from '../../common/input/input-dropdown/InputDropdown';
import styles from './BusinessTagsSelector.module.css';
import RootTag from './root-tag/RootTag';
import { BusinessContext } from '../../../context/business-context/BusinessContextProvider';
import { IUiSelectorBusinessTags } from './IUiSelectorBusinessTags';

interface IProps {
  ui: IUiSelectorBusinessTags;
  selectedtags?: string[];
  onUpdate: (updatedTags?: string[]) => void;
}

export default function BusinessTagsSelector(props: IProps) {
  // We'll use business tags dictionary from context, not static version provided by UI
  // This is because we need to display newly requested tags in the tree
  const { businessContext } = useContext(BusinessContext);
  //---------------------------------------------------------------------------
  const onUpdate = (selectedTags?: string[]) => {
    props.onUpdate(selectedTags?.sort());
  };
  //---------------------------------------------------------------------------
  // Get business tags dictionary from business context because we need to display newly requested tags, if there are any
  const rootTagElements = businessContext.businessTags?.filter(tag => !tag.path).map((dictionaryItem: IUiListItem) => {
    return (
      <RootTag
        key={dictionaryItem.id}
        ui={props.ui}
        rootTag={dictionaryItem}
        selectedTags={props.selectedtags}
        onUpdate={onUpdate}
      />
    )
  });
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      {rootTagElements}
    </div>
  );
}