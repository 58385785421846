import { IUiCaption } from "../../captions/IUiCaption";
import { IUiInputText } from "../../input-v2/input-text/InputText";


export interface IUiGoogleMap {
  inputSearch: IUiInputText;
  captionMapArea: IUiCaption;
}

export const tmpUiGoogleMap: IUiGoogleMap = {
  inputSearch: {
    id: "",
    elementType: "",
    directoryId: "",
    caption: undefined,
    placeholder: { en: "Type to search..." },
    hint: { en: "Search a place or area by name" },
  },
  captionMapArea: {
    id: "",
    elementType: "",
    directoryId: "",
    caption: { en: "[Map Area]" }
  }
}