import { IPublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { IAuthConfig } from "../app-auth-context/AppAuthContext";
import { Dispatch } from "react";
import React from "react";
import { TBusinessUpdater } from "../../components/common/business-updater/BusinessUpdater";
//-----------------------------------------------------------------------------
export type EServiceWorkerMessageType =
  'none' |
  //-----------------------------------------------------------------------------
  'messageChannelOpen' |
  'messageChannelClose' |
  //-----------------------------------------------------------------------------
  'tokenRequest' |
  //-----------------------------------------------------------------------------
  'updateBusiness' |
  'sendForApproval' |
  'sendForApprovalAndPublish' |
  'uploadImages' |
  'updateBusinessResponse' |
  //-----------------------------------------------------------------------------
  'errorNoToken'
  ;
//-----------------------------------------------------------------------------
export interface IServiceWorkerMessage {
  type: EServiceWorkerMessageType;
  data?: any;
}
//-----------------------------------------------------------------------------
// Context
//-----------------------------------------------------------------------------
export interface IServiceWorkerContext {
  manager?: ServiceWorkerManager;
}
//-----------------------------------------------------------------------------
interface IServiceWorkerContextStore {
  serviceWorkerContext: IServiceWorkerContext;
  serviceWorkerDispatch: Dispatch<IServiceWorkerMessage>;
}
export const ServiceWorkerContextStore = React.createContext<IServiceWorkerContextStore>({} as IServiceWorkerContextStore);
//-----------------------------------------------------------------------------

export class ServiceWorkerManager {
  channel: MessageChannel;
  port: MessagePort;
  config: IAuthConfig;
  instance: IPublicClientApplication;
  dispatch: Dispatch<IServiceWorkerMessage>;
  //---service worker responses----------------------------------------------------------------
  updateBusinessResponse?: IServiceWorkerMessage;
  sendForApprovalResponse?: IServiceWorkerMessage;
  //-------------------------------------------------------------------------------------------
  constructor(
    config: IAuthConfig,
    instance: IPublicClientApplication,
    dispatch: Dispatch<IServiceWorkerMessage>) {
    //-------------------------------------------------------------------------------------------
    //console.log("ServiceWorkerManager: constructor");
    this.instance = instance;
    this.config = config;
    this.dispatch = dispatch;
    this.channel = new MessageChannel();
    this.port = this.channel.port1;
    this.port.onmessage = this.onmessage.bind(this);
    navigator.serviceWorker.controller?.postMessage({
      type: 'messageChannelOpen',
    }, [this.channel.port2]);
  }
  //-------------------------------------------------------------------------------------------
  onmessage(event: MessageEvent<any>) {
    // Print the result
    //console.log("TServiceWorkerManager.onMessage. Message from SW. Event:", event);
    //console.log("TServiceWorkerManager.onMessage. this:", this);
    let message = event.data as IServiceWorkerMessage;
    switch (message.type) {
      case 'tokenRequest':
        this.onTokenRequest();
        break;
      case 'updateBusinessResponse':
        this.updateBusinessResponse = message;
        this.dispatch(message);
        break;
    }
  }
  //-------------------------------------------------------------------------------------------
  onTokenRequest() {
    const userAccount = this.instance?.getActiveAccount();
    if (userAccount && this.config) {
      console.log("TServiceWorkerManager.onTokenRequest. Requesting msal token...");
      const accessTokenRequest = {
        scopes: this.config.apiScopes,
        account: userAccount,
        forceRefresh: true,
        redirectUri: "/" //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#block_iframe_reload
      };
      this.instance?.acquireTokenSilent(accessTokenRequest as SilentRequest)
        .then((accessToken) => {
          console.log("TServiceWorkerManager.onTokenRequest. Token acquired. Sending to SW...", accessToken.accessToken);
          this.port.postMessage(accessToken.accessToken);
        })
    }
    else {
      console.error("ServiceWorkerManager: userAccount or appAuthContext.config is undefined");
    }
  }
  //-methods----------------------------------------------------------------
  processBusiness(publisher: TBusinessUpdater) {
    publisher.pushToServiceWorker();
  }
}