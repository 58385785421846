import React, { useContext, useEffect } from 'react';
import { IUiUserProfile } from './IUiUserProfile';
import ConsoleLayout from '../../common/console-layout/ConsoleLayout';
import { pathUserConsole, pathUserProfile } from '../UserConsole';
import { TUiMenuContent } from '../../common/menu/menu-content/TUiMenuContent';
import { TUiMenuOption } from '../../common/menu/menu-content/menu-option/TUiMenuOption';
import { PersonalInfo } from './personal-Info/PersonalInfo';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';
import { Route, Routes } from 'react-router';
import { TUiBreadcrumb } from '../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { IUiEditFormPersonalInfo } from './personal-Info/IUiEditFormPersonalInfo';
import NavigateWithContext from '../../common/navigate-with-context/NavigateWithContext';
import Preferences from './preferences/Preferences';
import { IUiEditFormPreferences } from './preferences/IUiEditFormPreferences';
import Account from './account/Account';
import { IUiEditFormAccount } from './account/IUiEditFormAccount';
import Staff from './staff/Staff';
import { IUiEditFormStaff } from './staff/IUiEditFormStaff';
import { AppAuthContextStore } from '../../../context/app-auth-context/AppAuthContext';

export const pathPersonalInfo = "personal";
export const pathPreferences = "preferences";
export const pathAccount = "account";
export const pathStaffUser = "rikiwiki";

interface IProps {
  ui?: IUiUserProfile;
}

export default function UserProfile(props: IProps) {
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContextDispatch } = useContext(AppUiContextStore);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    if (props.ui?.breadcrumb) {
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: props.ui.breadcrumb });
    };
  }, []);
  //--------------------------------------------------------------------------- Breadcrumbs for child routes
  let breadcrumbPersonalInfo: TUiBreadcrumb = {
    id: "UserProfile.MenuContent.PersonalInfo",
    caption: undefined,
    path: `/${pathUserConsole}/${pathUserProfile}/${pathPersonalInfo}`
  };
  let breadcrumbPreferences: TUiBreadcrumb = {
    id: "UserProfile.MenuContent.Preferences",
    caption: undefined,
    path: `/${pathUserConsole}/${pathUserProfile}/${pathPreferences}`
  };
  let breadcrumbAccount: TUiBreadcrumb = {
    id: "UserProfile.MenuContent.Account",
    caption: undefined,
    path: `/${pathUserConsole}/${pathUserProfile}/${pathAccount}`
  };
  let breadcrumbStaff: TUiBreadcrumb = {
    id: "UserProfile.MenuContent.StaffUser",
    caption: undefined,
    path: `/${pathUserConsole}/${pathUserProfile}/${pathStaffUser}`
  };
  //--------------------------------------------------------------------------- Update menu content
  let updatedMenuContent: TUiMenuContent | undefined = undefined;
  if (props.ui?.menuContent) {
    const mc = props.ui?.menuContent;
    const updatedOptions: TUiMenuOption[] = [];
    mc.options.forEach(option => {
      switch (option.id) {
        case "UserProfile.MenuContent.PersonalInfo":
          breadcrumbPersonalInfo.caption = option.caption;
          updatedOptions.push({
            id: option.id,
            directoryId: option.directoryId,
            elementType: option.elementType,
            caption: option.caption,
            hint: option.hint,
            iconFile: "userPersonalInfoEdit",
            isDefault: true,
            action: `/${pathUserConsole}/${pathUserProfile}/${pathPersonalInfo}`,
            visible: true,
            disabled: false,
            index: 0,
            priorityLevel: 0,
            canHideCaption: false
          });
          break;
        case "UserProfile.MenuContent.Preferences":
          breadcrumbPreferences.caption = option.caption;
          updatedOptions.push({
            id: option.id,
            directoryId: option.directoryId,
            elementType: option.elementType,
            caption: option.caption,
            hint: option.hint,
            iconFile: "userPreferences",
            isDefault: true,
            action: `/${pathUserConsole}/${pathUserProfile}/${pathPreferences}`,
            visible: true,
            disabled: false,
            index: 0,
            priorityLevel: 0,
            canHideCaption: false
          });
          break;
        case "UserProfile.MenuContent.Account":
          breadcrumbAccount.caption = option.caption;
          updatedOptions.push({
            id: option.id,
            directoryId: option.directoryId,
            elementType: option.elementType,
            caption: option.caption,
            hint: option.hint,
            iconFile: "userAccount",
            isDefault: true,
            action: `/${pathUserConsole}/${pathUserProfile}/${pathAccount}`,
            visible: true,
            disabled: false,
            index: 0,
            priorityLevel: 0,
            canHideCaption: false
          });
          break;
        case "UserProfile.MenuContent.StaffUser":
          if (appAuthContext.user?.staffRoles) {
            breadcrumbStaff.caption = option.caption;
            updatedOptions.push({
              id: option.id,
              directoryId: option.directoryId,
              elementType: option.elementType,
              caption: option.caption,
              hint: option.hint,
              iconFile: "rikiwikitmp",
              isDefault: true,
              action: `/${pathUserConsole}/${pathUserProfile}/${pathStaffUser}`,
              visible: true,
              disabled: false,
              index: 0,
              priorityLevel: 0,
              canHideCaption: false
            });
          };
          break;
      };
    });
    //-------------------------------------------------------------------------
    updatedMenuContent = {
      id: mc.id,
      directoryId: mc.directoryId,
      elementType: mc.elementType,
      options: updatedOptions,
      visible: true,
      disabled: false,
      optionGroups: []
    };
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      {updatedMenuContent &&
        <ConsoleLayout ui={{ header: "", menuContent: updatedMenuContent }} path={pathUserProfile}>
          <Routes>
            <Route
              index
              element={<NavigateWithContext to={`/${pathUserConsole}/${pathUserProfile}/${pathPersonalInfo}`} />}
            />
            <Route path={pathPersonalInfo} element={
              <PersonalInfo
                ui={{
                  ...props.ui?.editFormPersonalInfo as IUiEditFormPersonalInfo,
                  breadcrumb: breadcrumbPersonalInfo
                }}
              />}
            />
            <Route path={pathPreferences} element={
              <Preferences
                ui={{
                  ...props.ui?.editFormPreferences as IUiEditFormPreferences,
                  breadcrumb: breadcrumbPreferences
                }}
              />}
            />
            <Route path={pathAccount} element={
              <Account
                ui={{
                  ...props.ui?.editFormAccount as IUiEditFormAccount,
                  breadcrumb: breadcrumbAccount
                }}
              />}
            />
            <Route path={pathStaffUser} element={
              <Staff
                ui={{
                  ...props.ui?.editFormStaffRoles as IUiEditFormStaff,
                  breadcrumb: breadcrumbStaff
                }}
              />}
            />
          </Routes>
        </ConsoleLayout>}
    </React.Fragment>
  );
}