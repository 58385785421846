import { IUiInteractiveForm } from "../../../../../common/forms/IUiInteractiveForm";
import { IUiInputDropdown } from "../../../../../common/input-v2/input-dropdown/InputDropdown";
import { IUiInputText } from "../../../../../common/input-v2/input-text/InputText";

export interface IUiEditformDictionaryItem extends IUiInteractiveForm {
  inputId: IUiInputText;
  inputName: IUiInputText;
  inputDescription: IUiInputText;
  inputParent: IUiInputDropdown;
}

export const tmpUiEditformDictionaryItem: IUiEditformDictionaryItem = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: undefined,
  hint: undefined,
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionClose",
      directoryId: "",
      elementType: "",
      caption: { en: "Close" },
      hint: undefined
    }, {
      id: "OptionCancel",
      directoryId: "",
      elementType: "",
      caption: { en: "Cancel" },
      hint: undefined
    }, {
      id: "OptionOk",
      directoryId: "",
      elementType: "",
      caption: { en: "Ok" },
      hint: undefined
    }]
  },
  inputParent: {
    id: "DictionaryNodeIdInput",
    directoryId: '',
    elementType: "",
    caption: { en: "Path" },
    hint: { en: "Node path in the hierarchy" },
    placeholder: { en: "No path (Root item)" },
    dictionaryId: "",
    inputSearch: {
      id: '',
      directoryId: "",
      elementType: "",
      caption: { en: "Search" },
    }
  },
  inputId: {
    id: "DictionaryNodeIdInput",
    directoryId: '',
    elementType: "",
    disabled: true,
    visible: true,
    caption: { en: "Id" },
    placeholder: { en: "Type Id here..." },
    defaultValue: undefined,
    hint: { en: "System Id visible to administrators and system translators" },
    validation: {
      required: {
        value: 3,
        message: { en: "Provide an Identifier as long as 3 characters at least" }
      },
      /* regexMatch: {
        regexPattern: "^[a-zA-Z0-9-_]*$", // letters in both cases, digits, dash, underscore
        message: { en: "Identifier must not iclude spaces, dots, commas, slashes" }
      }, */
      unique: {
        message: { en: "Entry with this Id already exists" }
      }
    }
  },
  inputName: {
    id: "DictionaryItemNameInput",
    directoryId: '',
    elementType: "",
    disabled: false,
    visible: true,
    caption: { en: "Name" },
    placeholder: { en: "Type Name here..." },
    defaultValue: undefined,
    hint: { en: "Name in English visible to end users" },
    validation: {
      required: {
        value: 3,
        message: { en: "Provide a name as long as 3 characters at least" }
      },
      unique: {
        message: { en: "Entry with this Name already exists" }
      }
    }
  },
  inputDescription: {
    id: "DictionaryNodeDescriptionInput",
    directoryId: '',
    elementType: "",
    disabled: false,
    visible: true,
    caption: { en: "Description" },
    placeholder: { en: "Type Description here..." },
    defaultValue: undefined,
    hint: { en: "Detailed description in English visible to end users" },
    // validation: {
    //   required: {
    //     value: 1,
    //     message: {
    //       id: "DictionaryNodeEditForm_Description_ValidationMessage_Required",
    //       hint: "Provide a description in English"
    //     }
    //   }
    // }
  },
}