import { useContext, useEffect, useState } from 'react';
import styles from './ContactsEditor.module.css';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import ContactTypeCard from './contact-type-card/ContactTypeCard';
import { Contact } from '../contact/Contact';
import { IUiInputListContacts } from './IUiInputListContacts';

interface IProps {
  ui?: IUiInputListContacts;
  data?: Contact<string>[];
  contactType?: string;
  editOnly?: boolean;
  onUpdate: (updatedContactsList: Contact<string>[]) => void;
}

export default function ContactsEditor(props: IProps) {
  if (!props.ui) return null;
  const { appUiContext } = useContext(AppUiContextStore);
  const [data, setData] = useState<Contact<string>[]>();
  const [lastTouched, setLastTouched] = useState<string>(); // to make it visible even if there is scrolling
  const editOnly = props.editOnly == undefined ? false : props.editOnly;
  //---------------------------------------------------------------------------
  useEffect(() => {
    setData(props.data ? props.data : []);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onUpdateContact = (updatedContact: Contact<string>) => {
    const existingContact = props.data?.find(c => c.id == updatedContact.id);
    let updatedContactsList: Contact<string>[] = props.data ? props.data.map(c => c) : [];
    if (props.data && existingContact) {
      updatedContactsList = props.data?.map(c => c.id == updatedContact.id ? updatedContact : c);
    } else {
      updatedContactsList.push(updatedContact);
    };
    console.log(updatedContactsList);
    props.onUpdate(updatedContactsList);
    setLastTouched(updatedContact.contactType);
  };
  //---------------------------------------------------------------------------
  const onDeleteContact = (deletedContactId: string) => {
    if (props.data) {
      const updatedContactsList = props.data?.filter(contact => contact.id !== deletedContactId);
      props.onUpdate(updatedContactsList);
    };
  };
  //---------------------------------------------------------------------------
  const contactGroups = appUiContext.dictionaries?.contactTypes?.filter(c =>
    props.contactType ? c.id == props.contactType : true
  ).map(contactType => {
    if (contactType.id != "None") {
      const contacts = data?.filter(contact => contact.contactType == contactType.id);
      return (
        <ContactTypeCard
          key={contactType.id}
          ui={props.ui}
          contactType={contactType}
          data={contacts}
          editOnly={editOnly}
          scrollIntoView={lastTouched == contactType.id}
          onUpdate={onUpdateContact}
          onDelete={onDeleteContact}
        />
      );
    }
  });
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      {contactGroups}
    </div>
  );
}