import CombineStyles from '../../../../../../utils/combineStyles';
import Icon from '../../../../icon/Icon';
import { IUiOption } from '../../../../options/IUiOption';
import styles from './AdPagesListItemOption.module.css';

interface IProps {
  ui: IUiOption;
  onSelect: () => void;
}

export default function AdPagesListItemOption(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        props.ui.isDisabled ? styles.disabled : ""
      ])}
      onClick={props.onSelect}
    >
      <Icon
        imageName={props.ui.iconId ? props.ui.iconId : ""}
      />
    </div>
  );
}