import { useContext } from 'react';
import Checkbox from '../../../common/checkbox/Checkbox';
import { IUiDictionaryItem } from '../../../common/dictionaries/IUiDictionaryItem';
import styles from './TemplatesListItem.module.css';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../common/functions/GetName';
import CombineStyles from '../../../../utils/combineStyles';

interface IProps {
  data: IUiDictionaryItem;
  isSelected: boolean;
  onSelect: (selectedItem: IUiDictionaryItem) => void;
}

export default function TemplatesListItem(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    props.onSelect(props.data);
  };
  //---------------------------------------------------------------------------
  return (
    <div className={CombineStyles([
      styles.container,
      props.isSelected ? styles.selected : ''
    ])}
      onClick={onSelect}
    >
      <div className={styles.checkbox}>
        <Checkbox checked={props.isSelected} />
      </div>
      <div className={styles.caption}>
        {GetName(appUiContext, props.data.id, props.data.name)}
      </div>
    </div>
  );
}