import { ReactNode } from 'react';
import styles from './InputWrapper.module.css';
import ValueWrapper from '../value-wrapper/ValueWrapper';
import { IValidationState } from '../input-validation/IUiValidation';

interface IProps {
  caption?: string;
  hint?: string;
  readonly?: boolean;
  disabled?: boolean;
  validation?: IValidationState;
  hideCaption?: boolean;
  hideHint?: boolean;
  customGapEm?: number;
  children: ReactNode;
}

export default function InputWrapper(props: IProps) {
  //---------------------------------------------------------------------------
  const readonly = props.readonly == undefined ? false : props.readonly;
  const disabled = props.disabled == undefined ? false : props.disabled;
  const hideCaption = props.hideCaption == undefined ? false : props.hideCaption;
  const hideHint = props.hideHint == undefined ? false : props.hideHint;
  //---------------------------------------------------------------------------
  if (hideCaption) return (
    <ValueWrapper
      disabled={disabled}
      hint={props.hint}
      hideHint={hideHint}
      validation={props.validation}
    >
      {props.children}
    </ValueWrapper>
  ); else return (
    <div className={styles.wrapper}>

      {!hideCaption &&
        <label className={styles.caption}>
          {props.caption}
        </label>}

      <ValueWrapper
        disabled={disabled}
        hint={props.hint}
        hideHint={hideHint}
        validation={props.validation}
      >
        {props.children}
      </ValueWrapper>

    </div>
  );
  /* else return (
    <tr
      className={CombineStyles([
        styles.container,
        screenType == ScreenType.Mobile ? styles.mobile : "",
        disabled ? styles.disabled : ""
      ])}
      style={props.customGapEm ? { gap: `${props.customGapEm}em` } : undefined}
    >
      {!hideCaption &&
        <td className={CombineStyles([
          styles.captionCell,
          screenType == ScreenType.Mobile ? styles.mobile : ""
        ])}>
          <label className={CombineStyles([
            styles.captionDiv,
            readonly ? styles.readonly : ""
          ])}>
            {props.caption}
          </label>
        </td>}
      <td>
        <ValueWrapper
          disabled={disabled}
          hint={props.hint}
          hideHint={hideHint}
          validation={props.validation}
        >
          {props.children}
        </ValueWrapper>
      </td>
    </tr>
  ); */
}