import { useContext, useEffect, useRef, useState } from 'react';
import { IUiListItemAdCampaign } from '../../../../../business-space/business-editor/section-adcampaigns/adcampaign-list-item/IUiListItemAdCampaign';
import CampaignWrapper from '../../../../../common/business/campaign-wrapper/CampaignWrapper';
import { IUiMenuContent } from '../../../../../common/options/menus/IUiMenuContent';
import SystemAdCampaignHeader from './system-adcampaign-header/SystemAdCampaignHeader';
import AdPagesList from '../../../../../common/business/adpages-list/AdPagesList';
import { generatePath } from 'react-router-dom';
import useNavigateWithContext from '../../../../../../hooks/useNavigateWithContext';
import { IUiOption } from '../../../../../common/options/IUiOption';
import { useAbortController } from '../../../../../../hooks/useAbortController';
import deleteSystemBusinessAdCampaign from '../../functions/deleteSystemBusinessAdCampaignAsync';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../../context/app-auth-context/AppAuthContext';
import PopupAdPageLanguage, { INewAdPageState } from '../../../../../business-space/adpage-editor/popup-adpage-language/PopupAdPageLanguage';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import { createAdLanguageSetAsync } from '../../../../../../context/business-context/functions/createAdLanguageSetAsync';
import { IAdLanguageSetListItem } from '../../../../../ad-content-editor/IAdLanguageSet';
import { pathAds } from '../../../../AdminConsole';
import { deleteAdLanguageSetAsync } from '../../../../../../context/business-context/functions/deleteAdLanguageSetAsync';
import { activateAdLanguageSetAsync } from '../../../../../../context/business-context/functions/activateAdLanguageSetAsync';
import { deactivateAdLanguageSetAsync } from '../../../../../../context/business-context/functions/deactivateAdLanguageSetAsync';
import { IAdCampaign } from '../../../../../../context/business-context/IAdCampaign';
import { BusinessContext } from '../../../../../../context/business-context/BusinessContextProvider';
import { NotificationContext } from '../../../../../../context/notification-context/NotificationContextProvider';

const componentName = "Ad Campaign Editor";

interface IProps {
  ui: IUiListItemAdCampaign;
  data: IAdCampaign;
  isCollapsed: boolean;
  isReadonly?: boolean;
}

export default function AdCampaignsListItem(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const { notificationContextDispatch } = useContext(NotificationContext);
  const navigate = useNavigateWithContext();
  const [isLoading, setIsLoading] = useState(false);
  const [adListItems, setAdListItems] = useState<IAdLanguageSetListItem[]>([]);
  const [newAdLanguageSetState, setNewAdLanguageSetState] = useState<INewAdPageState>();
  const abortController = useAbortController("AdCampaignsListItem");
  const adPageIdToOpen = useRef<string | undefined>(undefined);
  //---------------------------------------------------------------------------
  const adCampaign = props.data;
  const cantDelete = businessContext.ads && businessContext.ads.findIndex(ad => ad.adCampaignId == adCampaign.id && ad.status.isReadonly) > -1;
  const isReadonly = props.isReadonly == undefined ? false : props.isReadonly;
  //--------------------------------------------------------------------------- Abortcontroller
  useEffect(() => {
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //--------------------------------------------------------------------------- Ads
  useEffect(() => {
    const campaignAds = businessContext.ads ? businessContext.ads.filter(item => item.adCampaignId == adCampaign.id) : [];
    setAdListItems(campaignAds);
  }, [businessContext.ads]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (adPageIdToOpen.current && businessContext.lastAction?.type == "SetBusiness") {
      let id = adPageIdToOpen.current;
      adPageIdToOpen.current = undefined;
      onAdLanguageSetOpen(id);
    };
  }, [businessContext.businessState]);
  //---------------------------------------------------------------------------
  const navigateToContentEditor = (basePath: string, businessId: string, adLanguageSetId: string) => {
    const adContentEditorPath = `/${basePath}/:businessId/${pathAds}/:adLanguageSetId`;
    const path = generatePath(adContentEditorPath, {
      businessId: businessId,
      adLanguageSetId: adLanguageSetId,
    });
    navigate(path);
  };
  //--------------------------------------------------------------------------- Ad Campaign funcs
  const onAdCampaignOptionSelect = (selectedOptionId: string) => {
    console.log(selectedOptionId)
    switch (selectedOptionId) {
      case "ListItemAdCampaign.MenuContent.OptionEdit":
        //onAdCampaignEdit();
        break;
      case "ListItemAdCampaign.MenuContent.OptionDelete":
        onAdCampaignDelete();
        break;
      // case "ListItemAdCampaign.MenuContent.OptionNewAdPageWizard":
      //   onAdPageWizard();
      //   break;
    };
  };
  //---------------------------------------------------------------------------
  const onAdCampaignEdit = () => {

  };
  //---------------------------------------------------------------------------
  const onAdCampaignDelete = () => {
    if (!businessContext.businessState) {
      console.error("Ad campaign is being deleted, but business context is not set");
      return;
    };
    setIsLoading(true);
    let controller = abortController.newController("deleteAdCampaign");
    deleteSystemBusinessAdCampaign({
      msalInstanse: instance,
      authConfig: appAuthContext.config,
      businessId: businessContext.businessState.business.id,
      adCampaignId: props.data.id,
      abortSignal: controller.signal
    }).then(updatedBusiness => {
      businessContextDispatch({ type: "SetBusiness", business: updatedBusiness });
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //--------------------------------------------------------------------------- Ad Page funcs
  const onAdLanguageSetOpen = (adLanguageSetId: string) => {
    if (!businessContext.basePath) return;
    if (!businessContext.businessState?.business) return;
    navigateToContentEditor(
      businessContext.basePath,
      businessContext.businessState.business.id,
      adLanguageSetId);
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetCreate = (originalAdLanguageSetId?: string) => {
    // Ask user to select ad page locale
    // Determine available languages list:
    // language is unavailable if there is a draft page for the language
    // published pages are ignored because we can create copies for published pages 
    const supportedLocales = appUiContext.dictionaries?.supportedLocales;
    const unavailableLocales = businessContext.ads?.filter(ad => ad.adCampaignId == props.data.id && !ad.status.isReadonly).map(ad => ad.localeId);
    const availableLocales = supportedLocales?.filter(item => !unavailableLocales?.includes(item.id));
    setNewAdLanguageSetState({
      popupIsOpen: true,
      availableLocales: availableLocales ? availableLocales : [],
      originalAdPageId: originalAdLanguageSetId
    });
  };
  //---------------------------------------------------------------------------
  const createNewAdLanguageSet = (selectedLocale: string) => {
    if (!businessContext.businessState) return;
    if (!businessContext.basePath) return;
    // Close select language dialog
    setNewAdLanguageSetState({ ...newAdLanguageSetState as INewAdPageState, popupIsOpen: false });
    //-------------------------------------------------------------------------
    if (!adCampaign) return;
    const business = businessContext.businessState.business;
    if (!business) return;
    //-------------------------------------------------------------------------
    // Call API
    setIsLoading(true);
    let controller = abortController.newController("createAdLanguageSet");
    createAdLanguageSetAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      businessType: business.businessType,
      businessId: business.id,
      adCampaignId: adCampaign.id,
      localeId: selectedLocale,
      originalAdLanguageSetId: newAdLanguageSetState?.originalAdPageId,
      abortSignal: controller.signal
    }).then(adLanguageSet => {
      businessContextDispatch({ type: "SetAdLanguageSet", adLanguageSet: adLanguageSet });
      if (!businessContext.basePath) return;
      navigateToContentEditor(
        businessContext.basePath,
        business.id,
        adLanguageSet.id);
    }).catch(error => {
      console.error(error);
    }).finally(() =>
      !controller.aborted && setIsLoading(false)
    );
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetEdit = (adLanguageSetId: string) => {
    if (!businessContext.basePath) return;
    if (!businessContext.businessState) return;
    if (!businessContext.ads) return;
    //-------------------------------------------------------------------
    // Find the ad language set
    const selectedAdLanguageSet = businessContext.ads.find(adp => adp.id == adLanguageSetId);
    if (!selectedAdLanguageSet)
      return console.error(`AdCampaignCard: Ad language set Id=[${adLanguageSetId}] not found`);
    //-------------------------------------------------------------------
    // Check if ad page is not read-only (otherwise we need to create a copy to edit)
    if (!selectedAdLanguageSet.status.isReadonly) {
      return onAdLanguageSetOpen(adLanguageSetId);
    };
    //-------------------------------------------------------------------
    // Check if there is a draft already for the language
    const draftpage = businessContext.ads.find(adp => (adp.localeId == selectedAdLanguageSet.localeId) && !adp.status.isReadonly);
    if (draftpage) {
      // Open the draft
      return onAdLanguageSetOpen(draftpage.id);
    };
    //-------------------------------------------------------------------
    // Create a draft copy of the selected page 
    const business = businessContext.businessState?.business;
    if (!business) return;
    //-------------------------------------------------------------------  
    setIsLoading(true);
    let controller = abortController.newController("createAdLanguageSet");
    createAdLanguageSetAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      businessType: business.businessType,
      businessId: business.id,
      adCampaignId: selectedAdLanguageSet.adCampaignId,
      localeId: selectedAdLanguageSet.localeId,
      originalAdLanguageSetId: selectedAdLanguageSet.id,
      abortSignal: controller.signal
    }).then(adLanguageSet => {
      businessContextDispatch({ type: "SetAdLanguageSet", adLanguageSet: adLanguageSet });
      //-----------------------------------------------------------------------
      // Navigate to ad content editor
      if (!businessContext.basePath) return;
      navigateToContentEditor(
        businessContext.basePath,
        business.id,
        adLanguageSet.id);
    }).catch(error => {
      console.error(error);
    }).finally(() =>
      !controller.aborted && setIsLoading(false)
    );
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetTranslate = (adLanguageSetId: string) => {
    const adLanguageSet = businessContext.ads?.find(item => item.id == adLanguageSetId);
    if (!adLanguageSet) return;
    //---------------------------------------------------------------------------
    // Determine available languages list:
    // language is unavailable if there is a draft page for the language
    // published pages are ignored because we can create copies for published pages 
    const supportedLocales = appUiContext.dictionaries?.supportedLocales;
    let unavailableLocales = businessContext.ads?.filter(ad => ad.adCampaignId == props.data.id && !ad.status.isReadonly).map(ad => ad.localeId);
    // Add current ad set locale to the list of unavailable locales
    if (unavailableLocales)
      unavailableLocales.push(adLanguageSet.localeId);
    else
      unavailableLocales = [adLanguageSet.localeId];
    // Get available locales
    const availableLocales = supportedLocales?.filter(item => !unavailableLocales?.includes(item.id));
    //---------------------------------------------------------------------------
    // Ask user to select ad page locale
    setNewAdLanguageSetState({
      popupIsOpen: true,
      availableLocales: availableLocales ? availableLocales : [],
      originalAdPageId: adLanguageSetId
    });
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetActivate = (adLanguageSetId: string) => {
    if (!businessContext.businessState) return;
    if (!adCampaign) return;
    setIsLoading(true);
    let controller = abortController.newController("onAdLanguageSetActivate");
    activateAdLanguageSetAsync({
      msalInstance: instance,
      authContext: appAuthContext,
      business: businessContext.businessState.business,
      adCampaignId: adCampaign.id,
      adLanguageSetId: adLanguageSetId,
      abortSignal: controller.signal,
      respondWith: 'list'
    }).then(response => {
      businessContextDispatch({ type: "SetAds", ads: response as IAdLanguageSetListItem[] });
    }).catch(error => {
      //console.error(error);
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetDeactivate = (adLanguageSetId: string) => {
    if (!businessContext.businessState) return;
    if (!adCampaign) return;
    setIsLoading(true);
    let controller = abortController.newController("onAdLanguageSetDeactivate");
    deactivateAdLanguageSetAsync({
      msalInstance: instance,
      authContext: appAuthContext,
      business: businessContext.businessState.business,
      adCampaignId: adCampaign.id,
      adLanguageSetId: adLanguageSetId,
      abortSignal: controller.signal,
      respondWith: 'list'
    }).then(response => {
      businessContextDispatch({ type: "SetAds", ads: response as IAdLanguageSetListItem[] });
    }).catch(error => {
      //console.error(error);
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetDelete = (adLanguageSetId: string) => {
    if (!businessContext.businessState) return;
    if (!adCampaign) return;
    setIsLoading(true);
    let controller = abortController.newController("onAdLanguageSetDelete");
    deleteAdLanguageSetAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      businessType: businessContext.businessState.business.businessType,
      businessId: businessContext.businessState?.business.id,
      adCampaignId: adCampaign.id,
      adLanguageSetId: adLanguageSetId,
      abortSignal: controller.signal
    }).then(response => {
      businessContextDispatch({ type: "SetAds", ads: response as IAdLanguageSetListItem[] });
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onAdPageOptionSelect = (adLanguageSetId: string, selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "AdPageEditor.MenuContent.OptionCreate":
        onAdLanguageSetCreate();
        break;
      case "AdPageEditor.MenuContent.OptionEdit":
        onAdLanguageSetEdit(adLanguageSetId);
        break;
      case "AdPageEditor.MenuContent.OptionTranslate":
        onAdLanguageSetTranslate(adLanguageSetId);
        break;
      case "AdPageEditor.MenuContent.OptionActivate":
        onAdLanguageSetActivate(adLanguageSetId);
        break;
      case "AdPageEditor.MenuContent.OptionDeactivate":
        onAdLanguageSetDeactivate(adLanguageSetId);
        break;
      case "AdPageEditor.MenuContent.OptionDelete":
        onAdLanguageSetDelete(adLanguageSetId);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedMenuContent: IUiMenuContent = { ...props.ui.menuContent, options: [] };
  if (!isReadonly) {
    const updatedOptions: IUiOption[] = [];
    props.ui.menuContent.options.forEach(option => {
      switch (option.id) {
        // case "ListItemAdCampaign.MenuContent.OptionEdit":
        //   updatedOptions.push(option);
        //   break;
        case "ListItemAdCampaign.MenuContent.OptionDelete":
          updatedOptions.push({ ...option, isDisabled: cantDelete });
          break;
        // case "ListItemAdCampaign.MenuContent.OptionNewAdPageWizard":
        //   break;
      };
    });
    updatedMenuContent.options = updatedOptions;
  };
  //---------------------------------------------------------------------------
  const adsList =
    <AdPagesList
      ui={props.ui.listItemAdPage}
      data={adListItems}
      onAdPageOpen={onAdLanguageSetOpen}
      onAdPageCreate={isReadonly ? undefined : onAdLanguageSetCreate}
      onAdPageOptionSelect={isReadonly ? undefined : onAdPageOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <CampaignWrapper
      id={adCampaign.id}
      ui={updatedMenuContent}
      adPages={adsList}
      isCollapsed={props.isCollapsed}
      isLoading={isLoading}
      onOptionSelect={isReadonly ? undefined : onAdCampaignOptionSelect}>

      <SystemAdCampaignHeader
        ui={props.ui}
        data={props.data}
      />

      {newAdLanguageSetState && newAdLanguageSetState.popupIsOpen &&
        <PopupAdPageLanguage
          ui={props.ui.inputAdPageLanguage}
          data={newAdLanguageSetState.availableLocales}
          onSelect={createNewAdLanguageSet}
          onClose={() => setNewAdLanguageSetState(undefined)}
        />}

    </CampaignWrapper>
  );
}