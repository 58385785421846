import { MutableRefObject, useEffect, useState } from "react";

export interface IDivDimensions {
  top: number,
  bottom: number,
  left: number,
  right: number,
  width: number,
  height: number
}

export default function useRefDimensions(ref: MutableRefObject<HTMLDivElement | null>) {
  const [dimensions, setDimensions] = useState<IDivDimensions>();
  useEffect(() => {
    if (ref.current) {
      const boundingRect = ref.current.getBoundingClientRect();
      setDimensions({
        top: boundingRect.top,
        bottom: boundingRect.bottom,
        left: boundingRect.left,
        right: boundingRect.right,
        width: boundingRect.width,
        height: boundingRect.height
      });
    };
  }, [ref]);
  return dimensions;
}