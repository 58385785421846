import React, { ReactNode, useContext, useEffect, useRef } from "react";
import useNavigateWithContext from "../../hooks/useNavigateWithContext";

export enum ScreenType {
  Mobile = 0,
  Desktop = 1,
}
const maxWidthMobile = 620;


//-----------------------------------------------------------------------------
// Hook
export const useAppScreenContext = () => {
  const screenType = useContext(AppScreenContext);
  return screenType;
};

//-----------------------------------------------------------------------------
// Context
export const AppScreenContext = React.createContext(
  (window.innerWidth <= maxWidthMobile || window.innerHeight <= maxWidthMobile) ?
    ScreenType.Mobile
    :
    ScreenType.Desktop
);


//-----------------------------------------------------------------------------
// Context Provider
interface Props {
  children: ReactNode;
}

export default function AppScreenContextProvider(props: Props) {
  const [screenType, setScreenType] = React.useState(
    window.innerWidth <= maxWidthMobile || window.innerHeight <= maxWidthMobile ?
      ScreenType.Mobile
      :
      ScreenType.Desktop
  );
  const interval = useRef(0);
  const whereTo = useRef(0);
  const navigate = useNavigateWithContext();
  //---------------------------------------------------------------------------
  const randomNavigate = () => {
    switch (whereTo.current) {
      case 0: {
        navigate("/search?cat=WyJBY2NvbW1vZGF0aW9uIiwiQWNjb21tb2RhdGlvbi5UeXBlLkFwYXJ0bWVudHMiLCJBY2NvbW1vZGF0aW9uLlR5cGUuQmFja3BhY2tlcnMiLCJBY2NvbW1vZGF0aW9uLlR5cGUuQmVkQW5kQnJlYWtmYXN0IiwiQWNjb21tb2RhdGlvbi5UeXBlLkNhbXBpbmciLCJBY2NvbW1vZGF0aW9uLkFjY29tbW9kYXRpb24gVHlwZS5BcGFydG1lbnQiLCJBY2NvbW1vZGF0aW9uLkFjY29tbW9kYXRpb24gVHlwZS5Ib3RlbCIsIkFjY29tbW9kYXRpb24uQWNjb21tb2RhdGlvbiBUeXBlLkhvc3RlbHMiXQ%3D%3D&useArea=false");
      } break;
      case 1: {
        navigate("/944f450a-d9f5-46ec-a3e2-52eb7bcd5187/en");
      } break;
      case 2: {
        navigate("/2e60d7bf-ae00-41eb-b589-48658679dddc/en");
      } break;
      case 3: {
        navigate("/944f450a-d9f5-46ec-a3e2-52eb7bcd5187/ru");
      } break;
      case 4: {
        navigate("/2e60d7bf-ae00-41eb-b589-48658679dddc/ru");
      } break;
      case 5: {
        navigate("/");
      } break;
    }
    whereTo.current++;
    if (whereTo.current >= 5)
      whereTo.current = 0;
  }
  //---------------------------------------------------------------------------
  useEffect(() => {
    //console.warn("SetInterval");
    //interval.current = window.setInterval(randomNavigate, 30000)
    //-----------------------------------------------------------------------
    return () => {
      if (interval.current) {
        console.warn("ClearInterval");
        window.clearInterval(interval.current);
      }
    };
  }, []);
  //---------------------------------------------------------------------------
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [screenType]);
  //---------------------------------------------------------------------------
  const handleWindowResize = () => {
    if (window.innerWidth <= maxWidthMobile || window.innerHeight <= maxWidthMobile)
      setScreenType(ScreenType.Mobile);
    else
      setScreenType(ScreenType.Desktop);
    // Update to use window.matchMedia() ???
  };
  //---------------------------------------------------------------------------
  return (
    <AppScreenContext.Provider value={screenType}>
      {props.children}
    </AppScreenContext.Provider>
  );
}
