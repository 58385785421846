import { clearFromSessionStorage } from "./sessionStorage";

//----------------------------------------------------------------------------- Constants: system
export const lsIdentityServiceData = "sys.identityService";
export const lsSystemSettings = "sys.Settings";
export const lsSystemVersion = "sys.Version";
//----------------------------------------------------------------------------- Constants: user
export const lsPreferredAppLocale = "usr.preferredAppLocale";
export const lsPreferredAppTheme = "usr.preferredAppTheme";
export const lsBusinessList = "usr.businesses"; // !!! need to include user id in this key
export const lsBusinessApprovalRequests = "usr.bussinessApprovalRequests";
export const lsImageList = "usr.images"; 
//----------------------------------------------------------------------------- Constants: UI
export const lsAppLayoutUi = "ui.appLayout";
export const lsSearchConsoleUi = "ui.searchConsole";
//----------------------------------------------------------------------------- Business
export const lsBusinessConsoleUi = "ui.businessConsole";
export const lsBusinessOwnerConsoleUi = "ui.businessOwnerConsole";
export const lsBusinessEditorUi = "ui.businessEditor";
export const lsBusinessWizardUi = "ui.businessWizardUi";
export const lsAdPageEditorUi = "ui.adpageEditor";
//---------------- ------------------------------------------------------------ Admin
export const lsAdminConsoleUi = "ui.adminConsole";
export const lsModeratorUi = "ui.ModeratorConsole";
export const lsAdminstratorUi = "ui.AdministratorConsole";
export const lsContentManagerUi = "ui.ContentManagerConsole";
export const lsRegionalHomepageEditorUi = "ui.RegionalHomepageEditor";
//----------------------------------------------------------------------------- User
export const lsUserConsoleUi = "ui.userConsole";
export const lsUserProfileUi = "ui.userProfile";
export const lsUserProfilePersonalUi = "ui.userProfilePersonal";
//----------------------------------------------------------------------------- Utils
export const lsUtilLinkUi = "ui.UtilLinkUi";
//-----------------------------------------------------------------------------
// All data stored in LS in following format
// {
//   content: any,
//   timestamp: string
// }
//-----------------------------------------------------------------------------
// ATTENTION: this command should not be used at all, only
//  - specific keys
//  - keys with certain prefix
//  - keys that meet condition for timestamp
// can be cleared
export const clearLS = () => {
  console.log("LocalStorage cleared");
  window.localStorage.clear();
};
//-----------------------------------------------------------------------------

export const RemoveFromLS = (appVersion: string) => {
  console.log("clear LocalStorage item with older app version");
  var arrayOfKeys = Object.keys(localStorage);
  var arrayOfValues = Object.values(localStorage);
  var arrayOfValues = [];
  for (let i in arrayOfKeys) {
    const value = localStorage.getItem(arrayOfKeys[i]);
    if (value) {
      let valueObject;
      try {
        valueObject = JSON.parse(value);
      }
      catch (err) {
        continue;
      }
      //-----------------------------------------------------------------------
      if (valueObject.version !== appVersion) {
        arrayOfValues.push(arrayOfKeys[i]);
      }
    }
  }
  //---------------------------------------------------------------------------
  for (let j = 0; j < arrayOfValues.length; j++) {
    localStorage.removeItem(arrayOfValues[j]);
  }
};

export const RemoveUIRelatedDataFromLS = () => {
    console.log("clear LocalStorage item with ui. in the key");
    let arrayOfKeys = Object.keys(localStorage);
    for (let i in arrayOfKeys) {
        const value = arrayOfKeys[i];
        if (value.indexOf('ui.') >= 0) {
            localStorage.removeItem(value);
        }
    }
    clearFromSessionStorage(lsIdentityServiceData);
    location.reload();
};
//-----------------------------------------------------------------------------
export const clearFromLS = (key: string) => {
  console.log(`LocalStorage: ${key} removed`);
  window.localStorage.removeItem(key);
};
//-----------------------------------------------------------------------------
export const setToLS = (key: string, value: any) => {
  const currentVersion = getFromLS(lsSystemVersion);
  const objectToStore = { content: value, timestamp: new Date().getTime(), version: currentVersion ? currentVersion : "unknown" };
  localStorage.setItem(key, JSON.stringify(objectToStore));
};
//-----------------------------------------------------------------------------
export const getFromLS = (key: string) => {
  const value = localStorage.getItem(key);
  if (value) {
    const valueObject = JSON.parse(value);
    return valueObject.content;
  } else {
    return false;
  }
};
