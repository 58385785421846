import { IUiMenuContent } from "../../../../../common/options/menus/IUiMenuContent";

export interface IUiDictionaryEditorNode {
  menuContent: IUiMenuContent;
}
export const tmpUiDictionaryEditorNode: IUiDictionaryEditorNode = {
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionEdit",
      directoryId: "",
      elementType: "",
      iconId: "pencil",
      caption: { en: "Edit" },
      hint: null,
      isDefault: false,
      isDisabled: false
    }, {
      id: "OptionAdd",
      directoryId: "",
      elementType: "",
      iconId: "plus",
      caption: { en: "Add Child Item" },
      hint: null,
      isDefault: false,
      isDisabled: false
    }, {
      id: "OptionDelete",
      directoryId: "",
      elementType: "",
      iconId: "delete",
      caption: { en: "Delete" },
      hint: null,
      isDefault: false,
      isDisabled: false
    }]
  }
}