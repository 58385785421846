import { IBusinessTagsDictionaryDraft, IBusinessTagsDictionaryDraftItem } from "./IBusinessTagsDictionary";

export default function DraftsAreEqual(
  draft1?: IBusinessTagsDictionaryDraft,
  draft2?: IBusinessTagsDictionaryDraft
): boolean {
  if ((draft1 == null && draft1 == draft2) || (draft1 == undefined && draft1 == draft2)) {
    return true;
  } else if (!draft1 || !draft2) {
    // If one of them is null/undefined
    return false;
  } else {
    let result: boolean = true;
    let key: keyof IBusinessTagsDictionaryDraft;
    for (key in draft1) {
      switch (key) {
        //----------------------------------------------
        case "initialStateStringified":
          // Just skip these
          break;
        //----------------------------------------------
        case "items":
          if (!DraftItemCollectionsAreEqual(draft1.items, draft2.items)) {
            result = false;
          };
          break;
        //----------------------------------------------
        default:
          if (JSON.stringify(draft1[key]) !== JSON.stringify(draft2[key])) {
            result = false;
          };
          break;
      };
    };
    return result;
  }
}

function DraftItemCollectionsAreEqual(
  draftItems1?: IBusinessTagsDictionaryDraftItem[] | null,
  draftItems2?: IBusinessTagsDictionaryDraftItem[] | null
): boolean {
  //--------------------------------------------------
  if ((draftItems1 == null && draftItems1 == draftItems2) || (draftItems1 == undefined && draftItems1 == draftItems2)) {
    return true;
  } else if (!draftItems1 || !draftItems2) {
    // If one of them is null/undefined
    return false;
  } else if ((draftItems1 ? draftItems1.length : 0) != (draftItems2 ? draftItems2.length : 0)) {
    // If number of items are different
    return false;
  } else {
    //--------------------------------------------------
    let result: boolean = true;
    for (let i = 0; i < (draftItems1 ? draftItems1.length : 0); i++) {
      if (!DraftItemsAreEqual(draftItems1[i], draftItems2[i])) {
        result = false;
        break;
      }
    };

    //--------------------------------------------------
    return result;
  };
};

function DraftItemsAreEqual(
  draftItem1?: IBusinessTagsDictionaryDraftItem | null,
  draftItem2?: IBusinessTagsDictionaryDraftItem | null
): boolean {
  // If botho of them are null or both of them are undefined, they are equal
  if ((draftItem1 == null && draftItem1 == draftItem2) || (draftItem1 == undefined && draftItem1 == draftItem2)) {
    return true;
  } else if (!draftItem1 || !draftItem2) {
    // If one of them is null/undefined
    return false;
  } else {
    //--------------------------------------------------
    let result: boolean = true;
    let key: keyof IBusinessTagsDictionaryDraftItem;
    for (key in draftItem1) {
      switch (key) {
        //----------------------------------------------
        case "isCollapsed":
        case "searchString":
        case "searchStringId":
        case "isEdited":
        case "translationIsEdited":
        case "isNew":
        case "isDeleted":
        case "isRepositionedOld":
        case "isRepositionedNew":
        case "isReordered":
        case "hasWarning":
        case "hasEdited":
        case "hasTranslationEdited":
        case "hasNew":
        case "hasDeleted":
        case "hasRepositionedOld":
        case "hasRepositionedNew":
        case "hasReordered":
        case "isCollapsed":
          // Just skip these
          break;
        //----------------------------------------------
        case "items":
          if (!DraftItemCollectionsAreEqual(draftItem1.items, draftItem2.items)) {
            result = false;
          };
          break;
        //----------------------------------------------
        default:
          if (JSON.stringify(draftItem1[key]) !== JSON.stringify(draftItem2[key])) {
            result = false;
          };
          break;
      };
    };
    return result;
  };
}

function isObject(o: object) {
  return o && typeof o === 'object';
}