import React from 'react';
import CombineStyles from '../../../../../../../utils/combineStyles';
import styles from './DictionaryNodeStatus.module.css';
import { IDictionaryItemStatus } from '../../../../../../common/dictionaries/IUiDictionaryItem';
import DictionaryItemStatus from '../../dictionary-item-status/DictionaryItemStatus';

interface IProps {
  data?: IDictionaryItemStatus;
  newUi?: boolean;
}

export default function DictionaryNodeStatus(props: IProps) {
  let statusName = "Inactive";
  if (props.data) {
    if (props.data.isAvailableToEndUsers && props.data.isAvailableToStaffUsers) {
      statusName = "Active";
    } else if (props.data.isAvailableToEndUsers) {
      statusName = "Active (users)";
    } else if (props.data.isAvailableToStaffUsers) {
      statusName = "Active (staff)";
    };
  }
  //---------------------------------------------------------------------------
  if (props.newUi) return (
    <DictionaryItemStatus
      data={props.data}
      oneLine={true}
    />
  ); else return (
    <td>
      <div
        className={CombineStyles([
          styles.container,
          props.data ? styles.active : ''
        ])}>
        {statusName}
      </div>
    </td>
  );
}