import React, { useContext, useEffect, useState } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { IUiEditFormPersonalInfo } from './IUiEditFormPersonalInfo';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import { useMsal } from '@azure/msal-react';
import { TUserIdentity } from '../../../../context/app-auth-context/TUser';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import ConsoleTabLayout from '../../../common/console-layout/console-tab-layout/ConsoleTabLayout';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { TUiMenuOption } from '../../../common/menu/menu-content/menu-option/TUiMenuOption';
import { TUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import ConsoleTabContent from '../../../common/console-layout/console-tab-layout/console-tab-content/ConsoleTabContent';
import styles from "./PersonalInfo.module.css";
import { TUiCaption } from '../../../common/captions/TUiCaption';
import { ScreenType, useAppScreenContext } from '../../../../context/app-screen-context/AppScreenProvider';
import AttentionNotification from '../attention-notification/AttentionNotification';
import { apiPostPrivate } from '../../../../utils/api';
import { apiBasePath, updateUserPersonalInfo } from '../../../../utils/apiPathConstant';
import EmailList from './email-list/EmailList';
import InputsGroupWrapper from '../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import InputText from '../../../common/input-v2/input-text/InputText';
import InputDropdown from '../../../common/input-v2/input-dropdown/InputDropdown';
import { IEditFormState } from '../../../common/edit-form/IEditFormState';

export const pathPersonalInfo = "personal"; //remove

interface IUserPersonalInfoAboutMeFormState extends IEditFormState<TUserIdentity> {
  updatedProperties: string[];
}

interface IUserPersonalInfoAboutMeFormStateSpread {
  object?: TUserIdentity;
  initialState?: TUserIdentity;
  isNew?: boolean;
  isUpdated?: boolean;
  isValid?: boolean;
}

class UserPersonalInfoAboutMeFormState implements IUserPersonalInfoAboutMeFormState {
  object?: TUserIdentity;
  initialState?: TUserIdentity;
  updatedProperties!: string[];
  isNew?: boolean;
  isUpdated?: boolean;
  isValid!: boolean;
  //---------------------------------------------------------------------------
  constructor(
    state: IUserPersonalInfoAboutMeFormState,
    spread?: IUserPersonalInfoAboutMeFormStateSpread
  ) {
    Object.assign(this, state, spread);
    this.checkChanges();
  }
  //---------------------------------------------------------------------------
  checkChanges() {
    const updatedProperties: string[] = [];
    const isUpdated = JSON.stringify(this.object) != JSON.stringify(this.initialState);
    if (isUpdated) {
      // Check what properties are changed
      for (const propertyName in this.object) {
        //console.log(propertyName)
        const a = (this.object as any)[propertyName];
        const b = (this.initialState as any)[propertyName];
        //console.log("a, b", a, b);
        if (JSON.stringify(a) !== JSON.stringify(b)) {
          updatedProperties.push(propertyName);
        };
      };
      console.log('updatedPropertiesList:', updatedProperties);
    };
    //---------------------------------
    this.isUpdated = isUpdated;
    this.updatedProperties = updatedProperties;
  };
}

interface IProps {
  ui?: IUiEditFormPersonalInfo;
}

export function PersonalInfo(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext, appAuthContextDispatch } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const [state, setState] = useState<UserPersonalInfoAboutMeFormState>();
  const [isLoading, setIsLoading] = useState(false);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    if (props.ui?.breadcrumb) {
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: props.ui.breadcrumb });
    };
  }, []);
  //--------------------------------------------------------------------------- Set state based on auth context
  useEffect(() => {
    if (appAuthContext.user?.userIdentity) {
      const newState = new UserPersonalInfoAboutMeFormState({
        object: appAuthContext.user.userIdentity,
        initialState: JSON.parse(JSON.stringify(appAuthContext.user.userIdentity)),
        updatedProperties: [],
        isNew: false,
        isUpdated: false,
        isValid: true
      });
      setState(newState);
    };
  }, [appAuthContext.user]);
  //---------------------------------------------------------------------------
  const onUpdateDisplayName = (value: string, isValid: boolean) => {
    if (state) {
      const updatedIdentity: TUserIdentity = {
        ...state.object as TUserIdentity,
        displayName: value
      };
      //-----------------------------------------------------------------------
      const updatedState = new UserPersonalInfoAboutMeFormState(state, {
        object: updatedIdentity,
        isValid: isValid
      });
      setState(updatedState);
    } else console.error("User data is being updated, but state is not set");
  };
  //---------------------------------------------------------------------------
  const onUpdateFirstName = (value: string, isValid: boolean) => {
    if (state) {
      const updatedIdentity: TUserIdentity = {
        ...state.object as TUserIdentity,
        firstName: value
      };
      //-----------------------------------------------------------------------
      const updatedState = new UserPersonalInfoAboutMeFormState(state, {
        object: updatedIdentity
      });
      setState(updatedState);
    } else console.error("User data is being updated, but state is not set");
  };
  //---------------------------------------------------------------------------
  const onUpdateLastName = (value: string, isValid: boolean) => {
    if (state) {
      const updatedIdentity: TUserIdentity = {
        ...state.object as TUserIdentity,
        lastName: value
      };
      //-----------------------------------------------------------------------
      const updatedState = new UserPersonalInfoAboutMeFormState(state, {
        object: updatedIdentity
      });
      setState(updatedState);
    } else console.error("User data is being updated, but state is not set");
  };
  //---------------------------------------------------------------------------
  const onUpdateCountry = (selectedOptionId?: string) => {
    if (state) {
      const updatedIdentity: TUserIdentity = {
        ...state.object as TUserIdentity,
        country: selectedOptionId
      };
      //-----------------------------------------------------------------------
      const updatedState = new UserPersonalInfoAboutMeFormState(state, {
        object: updatedIdentity
      });
      setState(updatedState);
    } else console.error("User data is being updated, but state is not set");
  };
  //---------------------------------------------------------------------------
  const onUpdateEmailsList = (emailsList: string[]) => {
    if (state) {
      const updatedIdentity: TUserIdentity = {
        ...state.object as TUserIdentity,
        otherMails: emailsList
      };
      //-----------------------------------------------------------------------
      const updatedState = new UserPersonalInfoAboutMeFormState(state, {
        object: updatedIdentity
      });
      setState(updatedState);
    } else console.error("User data is being updated, but state is not set");
  };
  //---------------------------------------------------------------------------
  const onSave = () => {
    setIsLoading(true);
    //-------------------------------------------------------------------------
    if (appAuthContext.config) {
      apiPostPrivate(
        instance,
        appAuthContext.config,
        `${apiBasePath}${updateUserPersonalInfo}`,
        state?.object)
        .then((response) => {
          if (response && response.status === 200) {
            const updatedUser = response.content;
            //---------------------------------------------------------------
            // Update global auth context
            appAuthContextDispatch({ type: "SetUser", value: updatedUser });
            alert("User Details Saved Successfully.");
          } else {
            alert("User Details Save failed.");
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setIsLoading(false));
    } else {
      console.error("Could not update user identity data because auth context is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OptionSave":
        onSave();
        break;
    };
  };
  //--------------------------------------------------------------------------- Edit form options
  let updatedMenuContent: TUiMenuContent | undefined = undefined;
  let editFormOptions = undefined;
  let formCaption: TUiCaption | undefined = undefined;
  if (props.ui) {
    formCaption = {
      id: props.ui.id,
      directoryId: props.ui.directoryId,
      elementType: props.ui.elementType,
      text: props.ui.hint
    };
    if (props.ui?.menuContent) {
      const mc = props.ui.menuContent;
      const updatedOptions: TUiMenuOption[] = [];
      const saveDisabled = !state?.isUpdated || !state?.isValid;
      mc.options.forEach(option => {
        switch (option.id) {
          case "OptionSave":
            updatedOptions.push({
              id: option.id,
              directoryId: option.directoryId,
              elementType: option.elementType,
              caption: option.caption,
              hint: option.hint,
              iconFile: "check",
              isDefault: !saveDisabled,
              disabled: saveDisabled,
              visible: true,
              index: 0,
              priorityLevel: 0,
              canHideCaption: false,
              action: undefined
            });
            break;
        };
      });
      updatedMenuContent = {
        id: mc.id,
        directoryId: mc.directoryId,
        elementType: mc.elementType,
        options: updatedOptions,
        visible: true,
        disabled: false,
        optionGroups: []
      };
      editFormOptions =
        <FormOptions
          ui={updatedMenuContent}
          optionIdVisibleOnMobile={"OptionSave"}
          onSelect={onOptionSelect}
        />;
    };
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <WikiSpinner show={isLoading} />
      <ConsoleTabLayout
        toolbar={editFormOptions}
        allowScroll={true}
      >
        <ConsoleTabContent prompt={formCaption}>
          {props.ui &&
            <div className={styles.container}>
              <InputsGroupWrapper>
                <InputText
                  ui={props.ui.inputDisplayName}
                  data={state?.object?.displayName ? state?.object?.displayName : ''}
                  //showAlert={state?.updatedProperties.includes("displayName")}
                  onUpdate={onUpdateDisplayName}
                />
                <InputText
                  ui={props.ui.inputFirstName}
                  data={state?.object?.firstName ? state?.object?.firstName : ''}
                  //showAlert={state?.updatedProperties.includes("firstName")}
                  onUpdate={onUpdateFirstName}
                />
                <InputText
                  ui={props.ui.inputLastName}
                  data={state?.object?.lastName ? state?.object?.lastName : ''}
                  //showAlert={state?.updatedProperties.includes("lastName")}
                  onUpdate={onUpdateLastName}
                />

                <InputDropdown
                  ui={props.ui.inputCountry}
                  data={state?.object?.country}
                  options={appUiContext.dictionaries?.countries}
                  hideOptionDescription={true}
                  isAutoComplete={true}
                  sortOptions={true}
                  onUpdate={onUpdateCountry}
                />

                <EmailList
                  ui={props.ui.inputEmailsList}
                  showAlert={state?.updatedProperties.includes("otherMails")}
                  data={state?.object?.otherMails ? state?.object?.otherMails : []}
                  onUpdate={onUpdateEmailsList}
                />
              </InputsGroupWrapper>

              {state?.isUpdated && state.isValid &&
                <AttentionNotification />}

              {screenType == ScreenType.Mobile &&
                <div className={styles.bottomBox}>
                </div>}

            </div>}
        </ConsoleTabContent>
      </ConsoleTabLayout>
    </React.Fragment>
  );
}