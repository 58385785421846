import { useState } from 'react';
import DictionaryItemCell from '../../../common/dictionary-node-cell/DictionaryNodeCell';
import DictionaryNodeBadges from '../../../administrator-console/dictionaries/common/dictionary-node-wrapper/dictionary-node-badges/DictionaryNodeBadges';
import { IDictionaryDraftItemStateGroup } from '../../../administrator-console/dictionaries/common/settings/IDictionaryDraftItemStateGroup';
import DictionaryNodeWrapperV1 from '../../../common/dictionary-node-wrapper/DictionaryNodeWrapperV1';
import { IValue } from '../../dictionaries/dictionary-translator-node/DictionaryTranslatorNodeV1';
import WidgetEditForm from '../widget-edit-form/WidgetEditForm';
import { DraftItemStateEnum } from '../../../common/dictionary-draft-models/DraftItemStateEnum';
import { IWidgetTranslation } from '../widget-directory-translator/IWidgetTranslation';
import { IWidgetTranslationDraft } from '../widget-directory-translator/DirectoryTranslatorState';

export interface IWidgetTranslatorState {
  id: string;
  elementType: string;
  caption?: IValue;
  hint?: IValue;
  placeholder?: IValue;
  draftState: DraftItemStateEnum;
  publishedVersion: IWidgetTranslation;
}

interface IProps {
  draftLocale?: string;
  sourceLocale?: string;
  data: IWidgetTranslationDraft;
  stateGroups?: IDictionaryDraftItemStateGroup[];
  editModeIsEnabled?: boolean;
  onUpdate: (updatedWidget: IWidgetTranslationDraft) => void;
}

export default function WidgetDirectoryNode(props: IProps) {
  const [editMode, setEditMode] = useState(false);
  const editModeIsEnabled = props.editModeIsEnabled == undefined ? false : props.editModeIsEnabled;
  //---------------------------------------------------------------------------
  const onEdit = () => {
    setEditMode(true);
  };
  //---------------------------------------------------------------------------
  const onClose = () => {
    setEditMode(false);
  };
  //---------------------------------------------------------------------------
  const onWidgetUpdate = (updatedWidget: IWidgetTranslationDraft) => {
    setEditMode(false);
    props.onUpdate(updatedWidget);
  };
  //---------------------------------------------------------------------------
  if (editMode && props.draftLocale) return (
    <WidgetEditForm
      draftLocale={props.draftLocale}
      sourceLocale={props.sourceLocale}
      data={props.data}
      onUpdate={onWidgetUpdate}
      onClose={onClose}
    />
  ); else return (
    <DictionaryNodeWrapperV1
      editModeIsEnabled={editModeIsEnabled}
      onSelect={onEdit}
    >

      <DictionaryItemCell
        editModeIsEnabled={editModeIsEnabled}
        onSelect={onEdit}
      >
        {props.data.elementType}
      </DictionaryItemCell>

      <DictionaryItemCell
        editModeIsEnabled={editModeIsEnabled}
        onSelect={onEdit}
      >
        {props.data.id}
      </DictionaryItemCell>

      <DictionaryNodeBadges
        stateGroups={props.stateGroups}
        draftState={props.data.draftState}
      />

    </DictionaryNodeWrapperV1>
  );
}