export type HttpStringCodes = 
  'OK' | 'Created' | 'Accepted' | 'NoContent' | 'PartialContent' | 'MultiStatus' | 
  'BadRequest' | 'Unauthorized' | 'Forbidden' | 'NotFound' | 'MethodNotAllowed' | 'NotAcceptable' | 
  'RequestTimeout' | 'Conflict' | 'PreconditionFailed' | 'TooManyRequests' |
  'InternalServerError' | 'NotImplemented' | 'BadGateway' | 'ServiceUnavailable' | 'GatewayTimeout';

  export enum EHttpStatusCode {
    OK = 200,
    Created = 201,
    Accepted = 202,
    NoContent = 204,
    PartialContent = 206,
    MultiStatus = 207,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    NotAcceptable= 406,
    RequestTimeout = 408,
    Conflict = 409,
    PreconditionFailed = 412,
    UnprocessableContent = 422,
    TooManyRequests = 429,
    InternalServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504
  }

