import { useContext } from "react";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import GetCaption from "../../functions/GetCaption";
import GetHint from "../../functions/GetHint";
import { IUiInput } from "../IUiInput";
import InputWrapper from "../input-wrapper/InputWrapper";
import IconCheckBox from "../../icons/IconCheckBox";
import styles from './InputCheckBox.module.css';
import CombineStyles from "../../../../utils/combineStyles";

export interface IUiInputCheckbox extends IUiInput {
  threeState?: boolean;
}

interface IProps {
  ui: IUiInputCheckbox;
  data?: boolean;
  onChange?: (newValue: boolean) => void;
}

export default function InputCheckBox(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const threeState = props.ui.threeState == undefined ? false : props.ui.threeState;
  //---------------------------------------------------------------------------
  const onClick = () => {
    if (props.ui.disabled) return;
    if (!props.onChange) return;
    props.onChange(!props.data);
  };
  //---------------------------------------------------------------------------
  return (
    <InputWrapper
      caption={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      hint={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      hideCaption={props.ui.hideCaption}
      hideHint={props.ui.hideHint}
      disabled={props.ui.disabled}
    >
      <div
        className={CombineStyles([
          styles.container,
          props.ui.disabled ? styles.disabled : ''
        ])}
        onClick={onClick}
      >
        <IconCheckBox checked={props.data ? props.data : false} />
      </div>
      {/* <Checkbox
        checked={props.data}
        threeState={threeState}
        disabled={props.ui.disabled}
        onClick={() => props.onChange && props.onChange(!props.data)}
      /> */}
    </InputWrapper>
  );
}