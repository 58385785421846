import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";
import { apiBasePath, pathGetRegions } from "../../../../../utils/apiPathConstant";

export interface ICulture {
  id: string;
  name: string;
  nativeName: string;
  isNeutral: boolean;
  regions: IRegion[];
  subCultures: ICulture[];
}
export interface IRegion {
  id: string;
  name: string;
  nativeName: string;
}

export default function getRegions(
  msalInstance: IPublicClientApplication,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<IRegion[]> {
  return new Promise((resolve, reject) => {
    //-------------------------------------------------------------------------
    apiGetPrivate(
      msalInstance,
      authConfig,
      `${apiBasePath}${pathGetRegions}`,
      abortSignal
    ).then(response => {
      checkResponse(response, "getRegions");
      resolve(response?.content as IRegion[]);
    }).catch(error =>
      reject(error)
    );
  });
}