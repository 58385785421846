import { IDirectoryDraftItemStateGroup } from '../../../../../../context/directory-editor-context/directory-editor-settings/IDirectoryDraftItemStateGroup';
import Icon from '../../../../../common/icon/Icon';
import styles from './DraftStateLegendItem.module.css';

interface IProps {
  stateGroup: IDirectoryDraftItemStateGroup;
  enabled: boolean;
  onToggle: (groupId: string) => void;
}

export default function DraftStateLegendItem(props: IProps) {
  //---------------------------------------------------------------------------
  const onClick = () => {
    props.onToggle(props.stateGroup.id);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      style={{
        color: `${props.stateGroup.color ? props.stateGroup.color : 'inherit'}`,
        opacity: props.enabled ? 1 : 0.3
      }}
      onClick={onClick}
    >

      <div className={styles.icon}>
        <Icon imageName={props.stateGroup.iconId ? props.stateGroup.iconId : 'circle'} />
      </div>

      {props.stateGroup.name}

    </div>
  );
}