import { ReactNode, RefObject, useCallback, useEffect, useRef, useState } from "react";

export function useVisible(ref: RefObject<HTMLDivElement>) {
  //const observer = useRef<IntersectionObserver>();
  const [isIntersecting, setIntersecting] = useState(false);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    observer.observe(ref.current);
  }, [ref]);
  //---------------------------------------------------------------------------
  return isIntersecting;
}