import React, { useState } from "react";
import styles from "./HealthGauge.module.css";
import { ServiceHealthStatus } from "./RikiWikiHealthStatus";
import { EHttpStatusCode } from "../../utils/HttpStatusCodes";
import { StatusToggler } from "./StatusToggler";
import { TogglerWithCaption } from "../common/toggler/TogglerWithCaption";

interface IProps {
  status: ServiceHealthStatus;
  spinner: boolean;
}

export function HealthGauge(props: IProps) {
  const [collapsed, setCollapsed] = useState(true);
  //------------------------------------------------------------------------
  const onClick = () => {
    setCollapsed(!collapsed);
  }
  //---------------------------------------------------------------------
  const onExpand = () => {
    setCollapsed(false);
  }
  //---------------------------------------------------------------------
  const onCollapse = () => {
    setCollapsed(true);
  }
  //---------------------------------------------------------------------------
  let color = '';
  if (!props.spinner) {
    if (props.status.healthScore > 80)
      color = "green"
    else if (props.status.healthScore > 40)
      color = "darkgoldenrod";
    else
      color = "red";
  }
  //---------------------------------------------------------------------------
  let errors = props.status.statusCode == "OK" ? undefined :
    <StatusToggler
      caption="Error"
      collapsed={collapsed}
      //count={0}
      onCollapse={onCollapse}
      onExpand={onExpand}
      children={
        <React.Fragment>
          <div key="error">
            {props.spinner ? "Error: " : props.status.errorPhrase}
          </div>
          <div key="codeLine">
            {props.spinner ? `Code line: ` : props.status.errorCodeLine}
          </div>
        </React.Fragment>
      }
    />
  //---------------------------------------------------------------------------
  const getResponseStatus = () => {
    let caption = "Response status code: ";
    if (!props.spinner)
      caption += `${props.status.statusCode}`;
    if (props.status.statusCode == "OK")
      return (<div> {caption} </div>);
    let error = props.spinner ? "Error: " : props.status.errorPhrase;
    let codeLineAt: string | undefined = undefined;
    let codeLineIn: string | undefined = undefined;
    let codeLines = props.status.errorCodeLine.split(" in ");
    if (codeLines.length > 0)
      codeLineAt = codeLines[0];
    if (codeLines.length > 1)
      codeLineIn = `in ${codeLines[1]}`;
    return (
      <div>
        <TogglerWithCaption
          //caption={`Error(s)`}
          caption={caption}
          showToggler={true}
          collapsed={collapsed}
          onClick={onClick}
          togglerPosition={"Right"}
        />
        {!collapsed &&
          <React.Fragment>
            <div className={styles.errorDiv}>
              {props.spinner ? "Error: " : props.status.errorPhrase}
            </div>
            {codeLineAt &&
              <div className={styles.errorDiv}>
                {props.spinner ? `Code line: ` : codeLineAt}
              </div>}
              {codeLineIn && !props.spinner &&
              <div className={styles.errorDiv}>
                {codeLineIn}
              </div>}
            {/* <div className={styles.errorDiv}>
              {props.spinner ? `Code line: ` : props.status.errorCodeLine}
            </div> */}
          </React.Fragment>
        }
      </div>
    );
  }
  //---------------------------------------------------------------------------
  let responseStatus = getResponseStatus();
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <div className={styles.healthScoreContainer}>
        Health:
        <div className={styles.healthScoreValue} style={{ backgroundColor: color }}>
          {!props.spinner ? `${props.status.healthScore} %` : ''}
        </div>
      </div>
      {responseStatus}
      {/* <div>
        {!props.spinner
          ? `Response status code: ${props.status.statusCode}`
          : `Response status code: `}
      </div>
      {errors != undefined &&
        errors
      } */}
      <div>
        {!props.spinner
          ? `Response time: ${props.status.responseTimeMilliseconds} ms`
          : `Response time: `}
      </div>
    </React.Fragment>
  );
}