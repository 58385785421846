import React, { useState } from "react";
import CombineStyles from "../../../utils/combineStyles";
import styles from "./SearchLanguageGroupName.module.css";
import PopupTest from "../../common/popup-v2/popup/PopupTest";
import { SearchLanguage } from "./SearchLanguage";
import { TSearchLanguageGroup } from "./TSearchLanguage";

//--------------------------------------------------------------------------
interface IProps {
  parentKey: string;
  languageGroup: TSearchLanguageGroup;
  selectedLocale: string;
  highlightedLocale: string;
  onClick: (locale: string) => void;
  onMouseEnter?: (locale: string) => void;
}
//--------------------------------------------------------------------------
export function SearchLanguageGroupName(props: IProps) {
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  //---------------------------------------------------------------------------
  const onMouseEnter = (locale: string) => {
    props.onMouseEnter && props.onMouseEnter(locale);
  }
  //---------------------------------------------------------------------------
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (singleLanguage) 
      props.onClick(singleLanguage.id);
    else if (props.languageGroup.items.length > 1) 
      setPopupOpen(true);
  }
  //---------------------------------------------------------------------------
  const selectPopupLocale = (locale: string) => {
    setPopupOpen(false);
    props.onClick(locale);
  }
  //---------------------------------------------------------------------------
  const singleLanguage = props.languageGroup.singleLanguage();
  //---------------------------------------------------------------------------
  let popupItems: React.JSX.Element[] | undefined;
  if (singleLanguage)
    popupItems = undefined;
  else 
    popupItems = props.languageGroup.items.map(language =>
      <SearchLanguage
        key={language.id}
        language={language}
        selectedLocale={props.selectedLocale}
        highlightedLocale={props.highlightedLocale}
        onClick={selectPopupLocale}
        onMouseEnter={onMouseEnter}
      />
    );
  //---------------------------------------------------------------------------
  //props.flag.id == "flagpack:ch" && console.log("render", popupOpen, props.flag.id);
  //---------------------------------------------------------------------------
  let divId = `${props.parentKey}_LG_${props.languageGroup.id}`;
  //---------------------------------------------------------------------------
  const selected = props.languageGroup.contains(props.selectedLocale);
  const highlighted = props.languageGroup.contains(props.highlightedLocale);
  const containerStyles = CombineStyles([
    styles.container,
    highlighted ? styles.highlighted : selected ? styles.selected : ""
  ]);
  //---------------------------------------------------------------------------
  return (
    <div
      id={divId}
      className={containerStyles}
      onClick={onClick}
      onMouseEnter={props.onMouseEnter ? () => onMouseEnter(props.languageGroup.id) : undefined}
    >
      {props.languageGroup.dictionaryItem?.name.native}
      {popupOpen && popupItems &&
        <PopupTest
          id={props.languageGroup.id}
          anchorId={divId}
          isModal={false}
          closeOnClickOutside={true}
          onClose={() => setPopupOpen(false)}
        >
          {popupItems}
        </PopupTest>
      }
    </div>
  );
}
