import { useContext, useRef, useState } from 'react';
import GetHint from '../../../../common/functions/GetHint';
import { IUiInputListBusinessPath } from './IUiInputListBusinessPath';
import styles from './InputListBusinessPath.module.css';
import BusinessPathlistItem from './list-item/BusinessPathListItem';
import BusinessPathEditForm, { IBusinessPath } from './edit-form/BusinessPathEditForm';
import InputWrapper from '../../../../common/input-v2/input-wrapper/InputWrapper';
import GetCaption from '../../../../common/functions/GetCaption';
import { IValidationState } from '../../../../common/input-v2/input-validation/IUiValidation';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import ButtonLink from '../../../../common/button-link/ButtonLink';
import { GetTUiButton } from '../../../../common/button/TUiButton';
import GetPlaceholder from '../../../../common/functions/GetPlaceholder';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import { useAbortController } from '../../../../../hooks/useAbortController';
import WikiSpinner from '../../../../app-layout/spinner/wikiSpinner';
import { IBusiness, IBusinessPaths } from '../../../../../context/business-context/IBusiness';
import deleteBusinessPathAsync from './functions/deleteBusinessPathAsync';

interface IProps {
  ui: IUiInputListBusinessPath;
  business: IBusiness;
  businessIsNew?: boolean;
  isReadonly?: boolean;
  onUpdate: (updatedPaths: IBusinessPaths) => void;
}

export default function InputListBusinessPath(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const [isLoading, setIsLoading] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [validationState] = useState<IValidationState>({ isValid: true, validationMessages: [] });
  const pathToEditRef = useRef<IBusinessPath>();
  const abortController = useAbortController("InputListBusinessPath");
  const businessPaths = props.business.paths;
  const isSystem = props.business.businessType !== "Business";
  const isReadonly = props.isReadonly == undefined ? false : props.isReadonly;
  const isNew = props.businessIsNew == undefined ? false : props.businessIsNew;
  //--------------------------------------------------------------------------- Only for user business
  const getPathFromBusinessName = () => {
    // NOTE: Commented the code out for now: 
    // requirements are needed for the case when business name is not meeting the regex rule
    //-------------------------------------------------------------------------
    // var result: string = "";
    // const businessName = props.businessName;
    // if (props.ui.editForm.inputValue.validation?.regexMatch?.regexPattern && businessName) {
    //   // Remove character number condition from regex
    //   // Example: from this regex "[a-zA-Z0-9-._~:\\@\\!\\$\\(\\)\\*\\+=]{10,}" we need to remove this part at the end "{10,}"
    //   const re = new RegExp("\{.*\}$", 'g');
    //   const newRegexString = props.ui.editForm.inputValue.validation.regexMatch.regexPattern.split(re)[0];
    //   //console.log(newRegexString)
    //   if (newRegexString) {
    //     // Find matches in business name using regex
    //     const newRegex = new RegExp(newRegexString, 'g');
    //     const characterArray = businessName.match(newRegex);
    //     //console.log(characterArray)
    //     //-------------------------------------------------------------------
    //     // Concatenate array into new string
    //     if (characterArray) {
    //       const businessUrlPath = characterArray.join('');
    //       //console.log(businessUrlPath)
    //       result = businessUrlPath;
    //     }
    //   };
    // };
    //props.onUpdate({ paths: [], masterPath: result });
  };
  //---------------------------------------------------------------------------
  const onEdit = (pathToEdit?: string) => {
    pathToEditRef.current = {
      value: pathToEdit ? pathToEdit : "",
      isMaster: pathToEdit === businessPaths?.masterPath
    };
    setEditFormOpen(true);
  };
  //---------------------------------------------------------------------------
  const onAdd = () => {
    // This option is used only if business is a system one
    pathToEditRef.current = {
      value: "",
      isMaster: false
    };
    setEditFormOpen(true);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedPath: IBusinessPath) => {
    setEditFormOpen(false);
    console.log(pathToEditRef)
    console.log(updatedPath)
    if (!isSystem) {
      // It's a user business we are dealing with (with only one path which is a master one by default)
      props.onUpdate({
        pathList: [updatedPath.value],
        masterPath: updatedPath.value
      });
    } else {
      //-----------------------------------------------------------------------
      // It's a system business
      if (pathToEditRef.current?.value === "") {
        // It's a new path in the list
        if (props.business.paths.pathList.length > 0) {
          // Current business already has paths
          const updatedPaths = props.business.paths.pathList.concat(updatedPath.value);
          props.onUpdate({
            pathList: updatedPaths,
            masterPath: updatedPath.isMaster ? updatedPath.value : props.business.paths.masterPath
          });
        } else {
          // It's a first path in the list, so make it master
          props.onUpdate({
            pathList: [updatedPath.value],
            masterPath: updatedPath.value
          });
        };
      } else {
        // Existing path is edited
        const updatedPaths = props.business.paths.pathList.map(p => p == pathToEditRef.current?.value ? updatedPath.value : p);
        props.onUpdate({
          pathList: updatedPaths ? updatedPaths : [],
          masterPath: updatedPath.isMaster ? updatedPath.value : props.business.paths.masterPath
        });
      };
    };
  };
  //---------------------------------------------------------------------------
  const onDelete = (deletedPath: string) => {
    if (isNew) {
      // Pass changes to business editor
      const updatedPaths = props.business.paths.pathList.filter(path => path !== deletedPath);
      props.onUpdate({
        pathList: updatedPaths,
        masterPath: props.business.paths.masterPath
      });
    } else {
      // Call API to delete path
      setIsLoading(true);
      let controller = abortController.newController("deleteBusinessPath");
      deleteBusinessPathAsync({
        msalIntance: instance,
        authConfig: appAuthContext.config,
        businessId: props.business.id,
        path: deletedPath,
      }).then(response => {
        // Pass changes to business editor
        const updatedPaths = props.business.paths.pathList.filter(path => path !== deletedPath);
        props.onUpdate({
          pathList: updatedPaths,
          masterPath: props.business.paths.masterPath
        });
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    };
  };
  //---------------------------------------------------------------------------
  const optionAdd = props.ui.menuContent.options.find(o => o.id.endsWith(".OptionAdd"));
  //---------------------------------------------------------------------------
  let listItems = businessPaths.pathList.map(path => (
    <BusinessPathlistItem
      key={path}
      ui={props.ui}
      data={path}
      isMaster={businessPaths.masterPath == path}
      isReadonly={isReadonly}
      onEdit={() => onEdit(path)}
      onDelete={() => onDelete(path)}
      onRefresh={isSystem ? undefined : getPathFromBusinessName}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <InputWrapper
      caption={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      hint={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      hideHint={isReadonly}
      readonly={false}
      disabled={false}
      validation={validationState}
    >
      <WikiSpinner show={isLoading} />

      {listItems ?
        <div className={styles.content}>
          {listItems}
        </div>
        :
        <label className={styles.placeholder}>
          {isReadonly ?
            "--"
            :
            GetPlaceholder(appUiContext, props.ui.id, props.ui.placeholder)}
        </label>}

      {!isReadonly && isSystem && optionAdd &&
        <ButtonLink
          ui={GetTUiButton(optionAdd)}
          disabled={false}
          hideHint={true}
          onClick={onAdd}
        />}

      {editFormOpen && pathToEditRef.current &&
        <BusinessPathEditForm
          ui={props.ui.editForm}
          data={pathToEditRef.current}
          businessId={props.business.id}
          businessType={props.business.businessType}
          existingPaths={props.business.paths.pathList}
          editMasterFlag={isSystem}
          onUpdate={onUpdate}
          onCancel={() => setEditFormOpen(false)}
        />}

    </InputWrapper>
  );
}