import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import styles from './DictionaryTranslatorNode.module.css';
import { IDictionaryDraftItemStateGroup } from '../../../administrator-console/dictionaries/common/settings/IDictionaryDraftItemStateGroup';
import CombineStyles from '../../../../../utils/combineStyles';
import DictionaryTranslatorValue from './dictionary-translator-value/DictionaryTranslatorValue';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import GetTranslationWithLocale from '../../../../common/functions/GetTranslationWithLocale';
import { DictionaryTranslationDraftItem, ITranslation } from '../DictionaryTranslationDraftItem';
import DictionaryNodeBadges from '../../../administrator-console/dictionaries/common/dictionary-node-wrapper/dictionary-node-badges/DictionaryNodeBadges';
import DictionaryTranslatorNodeEditForm from '../dictionary-translator-node-edit-form/DictionaryTranslatorNodeEditForm';
import { IUiDictionaryEditorNodeWrapper } from '../../../administrator-console/dictionaries/common/dictionary-node-wrapper/DictionaryEditorNodeWrapper';
import DictionaryNodeWrapperV1 from '../../../common/dictionary-node-wrapper/DictionaryNodeWrapperV1';
import DictionaryNodeWrapperV2 from '../../../common/dictionary-node-wrapper/DictionaryNodeWrapperV2';
import DictionaryNodeToggler from '../../../administrator-console/dictionaries/common/dictionary-node-wrapper/dictionary-node-toggler/DictionaryNodeToggler';

// Table columns:
// - system Id
// - Name
// - Name locale
// - Description
// - Description locale
// - Badges
// - Options(?)

export interface IValue extends ITranslation {
  locale?: string;
}

interface IProps {
  ui: IUiDictionaryEditorNodeWrapper;
  stateGroups?: IDictionaryDraftItemStateGroup[];
  draftLocale?: string;
  sourceLocale?: string;
  isHierarchical?: boolean;
  data: DictionaryTranslationDraftItem;
  items?: DictionaryTranslationDraftItem[];
  indent?: number;
  editModeIsEnabled?: boolean;
  onUpdate: (updatedItem: DictionaryTranslationDraftItem) => void;
}

export default function DictionaryTranslatorNodeV2(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [nameValue, setNameValue] = useState<IValue>();
  const [descriptionValue, setDescriptionValue] = useState<IValue>();
  const [editMode, setEditMode] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [childNodes, setChildNodes] = useState<DictionaryTranslationDraftItem[]>();
  const editModeIsEnabled = props.editModeIsEnabled == undefined ? false : props.editModeIsEnabled;
  const indent = props.indent ? props.indent : 0;
  const isHierarchical = props.isHierarchical == undefined ? false : props.isHierarchical;
  //---------------------------------------------------------------------------
  useEffect(() => {
    //-------------------------------------------------------------------------
    // Determine what value to dislay for name
    // 1. Display current draft value if it's provided
    let value: IValue = {
      value: props.data.name?.value,
      locale: props.draftLocale,
      fallbackAccepted: props.data.name?.fallbackAccepted
    };
    // 2. Pick a value from published data using fallback logic otherwise
    //console.log(props.draftLocale, props.data.name.fallbackAccepted, props.data.dictionaryDraftItemPublished);
    if (props.data.name.fallbackAccepted && props.data.publishedVersion && props.draftLocale) {
      const valueWithLocale = GetTranslationWithLocale(
        appUiContext.sysSettings,
        props.data.id,
        props.data.publishedVersion.name,
        "Name",
        props.draftLocale);
      //console.log("valueWithLocale:", valueWithLocale);
      value.value = valueWithLocale.value;
      value.locale = valueWithLocale.locale;
      value.fallbackAccepted = props.data.name?.fallbackAccepted;
    };
    setNameValue(value);
    //-------------------------------------------------------------------------
    value = {
      value: props.data.description?.value,
      locale: props.draftLocale,
      fallbackAccepted: props.data.description?.fallbackAccepted
    };
    if (props.data.description.fallbackAccepted && props.data.publishedVersion && props.draftLocale) {
      const valueWithLocale = GetTranslationWithLocale(
        appUiContext.sysSettings,
        props.data.id,
        props.data.publishedVersion.description,
        "Description",
        props.draftLocale);
      value.value = valueWithLocale.value;
      value.locale = valueWithLocale.locale;
      value.fallbackAccepted = props.data.description?.fallbackAccepted;
    };
    setDescriptionValue(value);
    //---------------------------------------------------------------------------
    let childs = props.data.filterDirectSiblings(props.items);
    if (childs && childs.length > 0) {
      setChildNodes(childs);
      setCollapsed(false);
    }
    else {
      setCollapsed(true);
    }
    //---------------------------------------------------------------------------
  }, [props.data, props.items]);
  //---------------------------------------------------------------------------
  const onEdit = () => {
    setEditMode(!!props.editModeIsEnabled);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedItem: DictionaryTranslationDraftItem) => {
    setEditMode(false);
    props.onUpdate(updatedItem);
  };
  //---------------------------------------------------------------------------
  const getValue = () => {
    if (props.sourceLocale == "i18n")
      return props.data.id;
    if (props.data.publishedVersion.name && props.sourceLocale) {
      let descriptor = Object.getOwnPropertyDescriptor(
        props.data.publishedVersion.name, 
        props.sourceLocale);
      if (descriptor && descriptor.value)
        return descriptor.value;
    }
    return "--";
  }
  //---------------------------------------------------------------------------
  const onToggle = () => {
    setCollapsed(!collapsed);
  };
  //---------------------------------------------------------------------------
  let childNodesToDisplay = childNodes?.map(item => {
    const allNodesUnderThis = item.filterAllNodesUnderThis(props.items);
    return (
      <DictionaryTranslatorNodeV2
        ui={props.ui}
        key={item.id}
        stateGroups={props.stateGroups}
        draftLocale={props.draftLocale}
        sourceLocale={props.sourceLocale}
        isHierarchical={props.isHierarchical}
        data={item}
        items={allNodesUnderThis}
        indent={indent + 1}
        editModeIsEnabled={props.editModeIsEnabled}
        onUpdate={props.onUpdate}
      />);
  });
  //--------------------------------------------------------------------------- Determine styles
  const calculatedStyles: CSSProperties = { color: 'inherit' };
  //---------------------------------------------------------------------------
  let isExpandable = childNodesToDisplay ? childNodesToDisplay.length > 0 : false;
  //---------------------------------------------------------------------------
  if (editMode && props.draftLocale) return (
    <tr>
      <td colSpan={100}>
        <DictionaryTranslatorNodeEditForm
          draftLocale={props.draftLocale}
          sourceLocale={props.sourceLocale}
          data={props.data}
          onUpdate={onUpdate}
          onClose={() => setEditMode(false)}
        />
      </td>
    </tr>
  );
  else return (
    <React.Fragment>
      <DictionaryNodeWrapperV2
        editModeIsEnabled={editModeIsEnabled}
        indent={indent}
      >
        {/* System Id */}
        <td
          className={CombineStyles([
            styles.text,
            styles.narrow,
            styles.system,
            editModeIsEnabled ? styles.editable : ""
          ])}
          onDoubleClick={onEdit}
        >
          <div
            className={styles.firstColumn}
            style={{ paddingLeft: `${indent * 1.2}em` }}
          >
            {/* Toggler for hierarchical dictionaries */}
            {isHierarchical &&
              <DictionaryNodeToggler
                isCollapsed={collapsed}
                onToggle={isExpandable ? onToggle : undefined}
              />}
            <span
              className={editModeIsEnabled ? styles.clickableName : ""}
              onClick={onEdit}
            >
              {getValue()}
            </span>
          </div>
        </td>

        {/* Name */}
        <DictionaryTranslatorValue
          draftLocale={props.draftLocale}
          data={nameValue}
          editModeIsEnabled={editModeIsEnabled}
          style={calculatedStyles}
          maxWidthEm='8'
          onDoubleClick={onEdit}
          onSelectText={onEdit}
        />

        {/* Description */}
        <DictionaryTranslatorValue
          draftLocale={props.draftLocale}
          data={descriptionValue}
          editModeIsEnabled={editModeIsEnabled}
          style={calculatedStyles}
          maxWidthEm='6'
          onDoubleClick={onEdit}
          onSelectText={onEdit}
        />

        {/* Badges to indicate node draft state */}
        <DictionaryNodeBadges
          stateGroups={props.stateGroups}
          draftState={props.data.draftState}
        />

      </DictionaryNodeWrapperV2>
      {!collapsed && childNodesToDisplay}
    </React.Fragment>
  );
}