//import styles from './ContentDirectoryTranslator.module.css';
import { useContext, useEffect, useState } from "react";
import { useAbortController } from "../../../../../hooks/useAbortController";
import { useParams } from "react-router-dom";
import { EHttpStatusCode } from "../../../../../utils/HttpStatusCodes";
import { getContentDirectoryDraft } from "../getContentDirectoryDraft";
import { useMsal } from "@azure/msal-react";
import { AppAuthContextStore } from "../../../../../context/app-auth-context/AppAuthContext";
import DirectoryEditorWrapper from "../../../common/directory-editor/directory-editor-wrapper/DirectoryEditorWrapper";
import { tmpUiDirectoryEditor } from "../../../common/directory-editor/IUiDirectoryEditor";
import { DirectoryTranslatorState } from "../../../common/directory-translator/IDirectoryTranslatorState";
import { pathAdminConsole, pathSystemTranslator } from "../../../AdminConsole";
import { pathContent } from "../../SystemTranslatorConsole";
import GetName from "../../../../common/functions/GetName";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";
import { ContentItemTranslationDraft } from "./IContentItemTranslationDraft";
import ContentDirectoryNode from "../content-directory-node/ContentDirectoryNode";

interface IProps {
}

export default function ContentDirectoryTranslator(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const [state, setState] = useState<DirectoryTranslatorState<ContentItemTranslationDraft>>();
  const [isLoading, setIsLoading] = useState(true);
  const { directoryId, localeId } = useParams();
  const abortController = useAbortController("WidgetDirectoryTranslator");
  let gridTemplateColumns = 'minmax(2em, 100%) minmax(2em, 1%)'; // Id, Name
  //--------------------------------------------------------------------------- Abort controller
  useEffect(() => {
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //--------------------------------------------------------------------------- Data
  useEffect(() => {
    if (directoryId && localeId)
      getDraft(directoryId, localeId, false);
  }, [directoryId, localeId]);
  //---------------------------------------------------------------------------
  const getDraft = (directoryId: string, localeId: string, lockIsNeeded: boolean) => {
    setIsLoading(true);
    let controller = abortController.newController("getWidgetDirectoryDraft");
    getContentDirectoryDraft({
      msalInstanse: instance,
      authConfig: appAuthContext.config,
      directoryId,
      localeId,
      lockIsNeeded,
      abortSignal: controller.signal
    }).then(directoryDraft => {
      let locker = directoryDraft.locker;
      if (locker)
        locker.errorPrefix = `${directoryId} directory`;
      //-----------------------------------------------------------------------
      let translatorState: DirectoryTranslatorState<ContentItemTranslationDraft>;
      translatorState = DirectoryTranslatorState.getInitialState<ContentItemTranslationDraft>({
        basePath: `/${pathAdminConsole}/${pathSystemTranslator}/${pathContent}`,
        directoryId: directoryDraft.directoryId,
        locale: directoryDraft.locale,
        localeName: GetName(appUiContext, directoryDraft.locale, appUiContext.dictionaries?.supportedLocales?.find(x => x.id == directoryDraft.locale)?.name),
        sourceLocale: state?.sourceLocale,
        log: directoryDraft.log,
        locker: locker,
        datetimeSaved: directoryDraft.datetimeSaved,
        editMode: !!(locker && locker.statusCode == EHttpStatusCode.OK),
        draft: directoryDraft
      });
      setState(translatorState);
    }).catch(error => {
      console.error(error);
      // if (!controller.aborted) {
      //   const translatorState = new DirectoryTranslatorState({
      //     draft: undefined,
      //     editMode: false
      //   });
      //   setState(translatorState);
      // }
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const enableEditMode = () => {
    if (directoryId && localeId) {
      getDraft(directoryId, localeId, true);
    } else console.error("Unable to switch edit mode: critical data is not provided");
  };
  //---------------------------------------------------------------------------
  const disableEditMode = () => {
    const updatedState = state?.getUpdatedState({ editMode: false });
    console.log(updatedState);
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onSelectSourceLocale = (localeId?: string) => {
    const updatedState = state?.getUpdatedState({ sourceLocale: localeId });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onItemUpdate = (updatedItem: ContentItemTranslationDraft) => {
    if (!state) return;
    const updatedItems = state.draft.items.map(x => x.id == updatedItem.id ? updatedItem : x);
    const updatedDraft = state.draft.getUpdatedState({ items: updatedItems });
    const updatedState = state.getUpdatedState({ draft: updatedDraft });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    console.log(optionId);
    switch (optionId) {
      //-----------------------------------------------------------------------
      case "OptionEditModeEnable":
        enableEditMode();
        break;
      //-----------------------------------------------------------------------
      case "OptionEditModeDisable":
        disableEditMode();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const items = state?.draft.items.map(item => (
    <ContentDirectoryNode
      key={item.id}
      //stateGroups={state.filter.stateGroups}
      draftLocale={state.locale}
      sourceLocale={state.sourceLocale}
      data={item}
      editModeIsEnabled={state.editMode}
      onUpdate={onItemUpdate}
    />
  ))
  //---------------------------------------------------------------------------
  return (
    <DirectoryEditorWrapper
      ui={tmpUiDirectoryEditor}
      //state={state}
      gridTemplateColumns={gridTemplateColumns}
      isLoading={isLoading}
      onSelectSourceLocale={onSelectSourceLocale}
      onOptionSelect={onOptionSelect}
    >
      {items}
    </DirectoryEditorWrapper>
  );
}