import { useContext, useEffect, useState } from 'react';
import GetCaption from '../../../common/functions/GetCaption';
import GetHint from '../../../common/functions/GetHint';
import PopupDialog from '../../../common/popup-v2/popup-dialog/PopupDialog';
import styles from './AdCampaignEditForm.module.css';
import { IUiEditFormAdCampaign } from './IUiEditFormAdCampaign';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import InputText from '../../../common/input-v2/input-text/InputText';
import InputDropdown from '../../../common/input-v2/input-dropdown/InputDropdown';
import InputsGroupWrapper from '../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import { IUiDictionaryItemAdRank } from '../../../common/dictionaries/IUiDictionaryItem';
import CombineStyles from '../../../../utils/combineStyles';
import { BusinessContext } from '../../../../context/business-context/BusinessContextProvider';
import { ScreenType, useAppScreenContext } from '../../../../context/app-screen-context/AppScreenProvider';
import { IAdCampaign, IAdRank } from '../../../../context/business-context/IAdCampaign';
import { v4 as uuidv4 } from "uuid";

interface IAdCampaignDetails {
  name: string;
  adRankId?: string;
}

interface IProps {
  ui: IUiEditFormAdCampaign;
  data?: IAdCampaign;
  isSystem: boolean;
  onConfirm: (updatedAdCampaign: IAdCampaign) => void;
  onCancel: () => void;
}

export default function AdCampaignEditForm(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const { businessContext } = useContext(BusinessContext);
  const [adCampaignDetails, setAdCampaignDetails] = useState<IAdCampaignDetails>();
  const [isValid, setIsValid] = useState(false);
  const [nameIsUnique, setNameIsUnique] = useState(true);
  //---------------------------------------------------------------------------
  useEffect(() => {
    const adCampaignDetailsToEdit: IAdCampaignDetails = props.data ? {
      name: props.data.name,
      adRankId: props.data.adRank?.id
    } : {
      name: "",
      adRankId: props.ui.defaultAdCampaignRankId
    };
    setAdCampaignDetails(adCampaignDetailsToEdit);
  }, []);
  //---------------------------------------------------------------------------
  const onNameUpdate = (updatedValue: string, isValid: boolean) => {
    console.log(isValid)
    setIsValid(isValid);
    //-------------------------------------------------------------------------
    // Check if ad campaign name is unique
    const existingCampaign = businessContext.businessState?.business.adCampaigns
      .find(c => c.name.toLocaleLowerCase() == updatedValue.toLocaleLowerCase());
    setNameIsUnique(existingCampaign == undefined);
    //-------------------------------------------------------------------------
    // Update state
    const updatedAdCampaignDetails: IAdCampaignDetails = {
      ...adCampaignDetails as IAdCampaignDetails,
      name: updatedValue
    };
    setAdCampaignDetails(updatedAdCampaignDetails);
  };
  //---------------------------------------------------------------------------
  const onAdRankUpdate = (optionId?: string) => {
    if (optionId) {
      const updatedAdCampaignDetails: IAdCampaignDetails = {
        ...adCampaignDetails as IAdCampaignDetails,
        adRankId: optionId
      }
      setAdCampaignDetails(updatedAdCampaignDetails);
    };
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    if (!adCampaignDetails) {
      console.error("AdCampaignEditForm: state is not set");
      return;
    };
    //-------------------------------------------------------------------------
    // Validate ad rank
    let selectedAdRank: IAdRank | undefined = undefined;
    if (!props.isSystem) {
      if (!adCampaignDetails.adRankId) {
        console.error(`AdCampaignEditForm: ad rank is not provided`);
        return;
      };
      const dictionaryItem = appUiContext.dictionaries?.adRanks?.find(di => di.id == adCampaignDetails.adRankId);
      if (dictionaryItem) {
        const adrankDictionaryItem = dictionaryItem as IUiDictionaryItemAdRank;
        selectedAdRank = {
          id: adCampaignDetails.adRankId,
          pointsPerView: adrankDictionaryItem.pointsPerView,
          pointsPerEngagement: adrankDictionaryItem.pointsPerEngagement
        };
      } else {
        console.error(`AdCampaignEditForm: cannot find ad rank id=[${adCampaignDetails.adRankId}] in Ad Ranks dictionary`);
        return;
      };
    };
    //-------------------------------------------------------------------------
    if (props.data) {
      const updatedAdCampaign: IAdCampaign = {
        ...props.data,
        name: adCampaignDetails.name
      };
      //-----------------------------
      if (!props.isSystem && adCampaignDetails.adRankId !== props.data.adRank?.id) {
        updatedAdCampaign.adRank = selectedAdRank;
      };
      props.onConfirm(updatedAdCampaign);
    } else {
      const newAdCampaign: IAdCampaign = {
        id: uuidv4(),
        dateTimeCreated: "", // Update on backend
        name: adCampaignDetails.name,
        adRank: selectedAdRank
      };
      props.onConfirm(newAdCampaign);
    };
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OptionOk":
        onConfirm();
        break;
      case "OptionCancel":
        props.onCancel();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const mc = getTUiMenuContent(props.ui.menuContent);
  mc.options.forEach(option => {
    switch (option.id) {
      case "OptionOk":
        option.disabled = !(isValid)
        option.isDefault = isValid;
        break;
    };
  });
  const menuElement = (mc.options && mc.options.length > 0) ?
    <FormOptions
      ui={mc}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    /> : undefined;
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id={props.ui.id}
      header={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      isModal={true}
      isDraggable={false}
      toolbarContent={menuElement}
      onClose={props.onCancel}
    >
      <div className={CombineStyles([
        styles.container,
        screenType == ScreenType.Mobile ? styles.mobile : ""
      ])}>

        <div className={styles.prompt}>
          {GetHint(appUiContext, props.ui.id, props.ui.hint)}
        </div>

        <InputsGroupWrapper>
          <InputText
            ui={props.ui.inputAdCampaignName}
            data={adCampaignDetails?.name}
            isUnique={nameIsUnique}
            onInitialValidate={(isValid: boolean) => setIsValid(isValid)}
            onUpdate={onNameUpdate}
          />
          {!props.isSystem &&
            <InputDropdown
              ui={props.ui.inputAdCampaignRank}
              options={appUiContext.dictionaries?.adRanks}
              data={adCampaignDetails?.adRankId}
              isAutoComplete={false}
              onUpdate={onAdRankUpdate}
            />}
        </InputsGroupWrapper>

      </div>
    </PopupDialog>
  );
}