import { useContext } from "react";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import styles from "./SysInfo.module.css";

export default function SysInfo() {
  const { appUiContext } = useContext(AppUiContextStore);
  //-------------------------------------------------------------------------
  return (
    <div id="sysInfo" className={styles.container}>
      {"v." + appUiContext.sysSettings.version}
    </div>
  )
}