import React, { useContext, useEffect, useRef, useState } from "react";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";
import useNavigateWithContext from "../../../hooks/useNavigateWithContext";
import WikiSpinner from "../../app-layout/spinner/wikiSpinner";
import styles from "./BusinessPresenter.module.css";
import { getAdsAsync } from "./getAdsAsync";
import { ClassAdModulePublished } from "../ClassAdLanguageSetPublished";
import AdModule from "../../common/ad-modules/AdModule";
import { IUiBusinessPresenter } from "./IUiBusinessPresenter";
import { AdModuleWrapper } from "./AdModuleWrapper";
import { useAppScreenContext } from "../../../context/app-screen-context/AppScreenProvider";

const adsNumber = 10;//30;
const maxAdsNumber = 30;//30;


interface IState {
  category?: string;
  ads: ClassAdModulePublished[];
  continuationToken?: string;
  firstPage: boolean;
}

interface IProps {
  ui?: IUiBusinessPresenter;
  category?: string;
  refresh: boolean;
  parent: HTMLDivElement | null;
  onFirstPage?: () => void;
}

export default function BusinessPresenter(props: IProps) {
  const navigate = useNavigateWithContext();
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const [isLoading, setIsLoading] = useState(true);
  const observerTarget = useRef(null);
  const observerTarget2 = useRef(null);
  const [aggregatedState, setState] = useState<IState>({ ads: [], firstPage: true });
  const onePageAdsNumber = useRef(10);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!props.category) return;
    let localState: IState = {
      ads: [],
      continuationToken: undefined,
      category: props.category ? props.category : "Accommodation",
      firstPage: true
    };
    calcAdsNumberForPage();
    getNextPage(localState, onePageAdsNumber.current);
  }, [props.category, screenType]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (observerTarget.current)
      observerTarget2.current = observerTarget.current;
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          getNextPage(aggregatedState, onePageAdsNumber.current);
        };
      },
      { threshold: 1 }
    );
    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    };
    if (aggregatedState.firstPage) {
      setState({ ...aggregatedState, firstPage: false });
      props.onFirstPage && props.onFirstPage();
    };
    return () => {
      //console.log("useEffect[observerTarget.current].return", observerTarget2.current);
      if (observerTarget2.current) {
        observer.unobserve(observerTarget2.current);
      };
    };
  }, [observerTarget.current, aggregatedState]);
  //---------------------------------------------------------------------------
  const getNextPage = (state: IState, adsNumber: number) => {
    getAdsAsync({
      localeId: appUiContext.locale.localeId,
      category: state.category,
      numberOfAds: adsNumber,
      continuationToken: state.continuationToken
    }).then(response => {
      //-----------------------------------------------------------------
      if (state.ads.length == 0)
        state.ads = response.ads;
      else
        state.ads = state.ads.concat(response.ads);
      //-----------------------------------------------------------------
      if (response.continuationToken && state.ads?.length < maxAdsNumber)
        state.continuationToken = response.continuationToken;
      else
        state.continuationToken = "";
      //-----------------------------------------------------------------
      setState(state);
    }).catch(error => {
      console.error(error);
      if (state.firstPage == true) {
        // Clear ads if it's a first page and it failed to retrieve ads
        // (otherwise presenter continues to display ads from the previous request)
        setState({
          ...state,
          ads: []
        });
      };
    }).finally(() => {
      setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  /* function nextPage(state: IState) {
    //-------------------------------------------------------------------------
    // Determine how to call GetAdsForHomepage API
    let sn = `${onePageAdsNumber.current}`;
    const apiUri = state.category ?
      stringFormatter(getAdForHomeWithCategory, [appUiContext.locale.localeId, state.category, sn])
      :
      stringFormatter(getAdForHome, [appUiContext.locale.localeId, sn]);
    //console.log("nextPage:", funcName, apiUri);
    //-----------------------------------------------------------------------
    // Call the API
    apiPost(`${apiBasePath}${apiUri}`, state.continuationToken, state.continuationToken ? "application/json" : undefined)
      .then((response) => {
        if (!response) {
          throw `api call [${apiUri}] response is undefined`;
        }
        let blocks: AdBlock[] = [];
        if (response.status == EHttpStatusCode.OK) {
          blocks = AdBlock.fromArray(response.content.ads);
        }
        //-----------------------------------------------------------------
        if (!state.ads)
          state.ads = blocks;
        else if (blocks.length > 0)
          state.ads = state.ads.concat(blocks);
        //-----------------------------------------------------------------
        if (response.content.continuationToken && state.ads.length < maxAdsNumber)
          state.continuationToken = response.content.continuationToken;
        else
          state.continuationToken = "";
        //-----------------------------------------------------------------
        //console.log("nextPage.setState:", state);
        setState({ ...state });
        //-----------------------------------------------------------------
        var random: number;
        const lastLayoutId = getFromLS("ui.layoutId");
        do {
          random = Math.floor(Math.random() * 4);
        } while (random === lastLayoutId);
        //setBlockStyles(blockStylesList[random]);
        setToLS("ui.layoutId", random);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  } */
  //---------------------------------------------------------------------------
  const calcAdsNumberForPage = () => {
    if (props.parent?.parentElement) {
      let empx = parseFloat(getComputedStyle(props.parent).fontSize);
      let rect = props.parent.parentElement.getBoundingClientRect();
      let h = Math.trunc(rect.width / (empx * 20));
      let v = Math.trunc(rect.height / (empx * 15));
      //console.log("empx = ", empx);
      //console.log("h, v:", h, v);
      onePageAdsNumber.current = h * v;
    }
  };
  //---------------------------------------------------------------------------
  const onModuleSelect = (businessId: string, locale: string) => {
    console.log(businessId, locale);
    const url = locale ? `${businessId}/${locale}` : `${businessId}`;
    //console.log(url)
    navigate(url);
  };
  //--------------------------------------------------------------------------- Desktop
  // var blocks: JSX.Element[] = [];
  // for (var i = 0; i < blockStyles.length; i++) {
  //   if (ads) {
  //     const ad = ads[i];
  //     const adBlock = ads[i]
  //       ?
  //       <div key={ad.id} className={blockStyles[i]}>
  //         <AdBlockCard
  //           adBlock={ad}
  //           editable={false}
  //           clickable={true}
  //           onClick={blockClickHandler}
  //           useExternalLayout={true}
  //         />
  //       </div>
  //       :
  //       <div key={i} className={blockStyles[i]}>
  //         <div className={layoutstyles.placeholder}>
  //           Here could be your ad!
  //         </div>
  //       </div>;
  //     blocks.push(adBlock);
  //   }
  // };
  //--------------------------------------------------------------------------- Mobile
  // var mobileBlocks: JSX.Element[] = [];
  // for (var i = 0; i < blockStyles.length; i++) {
  //   if (ads) {
  //     const ad = ads[i];
  //     const adBlock = ads[i]
  //       ?
  //       <div key={ad.id} style={{ width: '100%', height: '20%' }}>
  //         <AdBlockCard
  //           adBlock={ad}
  //           editable={false}
  //           clickable={true}
  //           onClick={blockClickHandler}
  //           useExternalLayout={true}
  //         />
  //       </div>
  //       :
  //       <div key={i} style={{ width: '100%', height: '20%' }}>
  //         <div className={layoutstyles.placeholder}>
  //           Here could be your ad!
  //         </div>
  //       </div>;
  //     mobileBlocks.push(adBlock);
  //   }
  // };
  //---------------------------------------------------------------------------
  let lastBlockIndex: number = -1;
  if (aggregatedState.ads && aggregatedState.ads.length > 0 && aggregatedState.continuationToken != "") {
    lastBlockIndex = aggregatedState.ads.length - 1;
  }
  //---------------------------------------------------------------------------
  var adModules = aggregatedState.ads?.map((ad, index) => (
    <AdModuleWrapper 
      key={`${ad.id}_${index}`}
      ui={props.ui?.photoblockDesign}
      adModule={ad}
      ref={index == lastBlockIndex ? observerTarget : undefined}
      onClick={onModuleSelect}
    />
    /*
    <div
      key={ad.id}
      ref={index == lastBlockIndex ? observerTarget : undefined}
      className={styles.item}>
      <AdModule
        externalDesignTemplate={props.ui?.photoblockDesign}
        adModule={ad}
        isReadonly={true}
        isClickable={true}
        useExternalLayout={true}
        onClick={onModuleSelect}
      />
    </div>
    */
  ));
  if (adModules && adModules.length < onePageAdsNumber.current) {
    var placeholders: JSX.Element[] = [];
    for (let i = 0; i < (onePageAdsNumber.current - adModules.length); i++) {
      placeholders.push(
        <div
          key={i}
          className={styles.placeholderItem}
        >
          Here could be your ad!
        </div>
      );
    };
    adModules = adModules.concat(placeholders);
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <WikiSpinner show={isLoading} />
      {adModules}
    </div>
  );
}