import React from "react";
import { EImageLayout } from "./PopupParagraphLayout";

interface IProps {
  layout: EImageLayout;
}

export function LayoutIcon(props: IProps) {
  let transform = '';
  switch (props.layout) {
    case 'TopRight':
      transform = "scale(-1, 1)";
      break;
    case 'Bottom':
    case 'BottomLeft':
      transform = "scale(1, -1)";
      break;
    case 'Right':
    case 'BottomRight':
      transform = "scale(-1, -1)";
      break;
  };
  //---------------------------------------------------------------------------
  return (
    <svg
      width="100%" height="100%"
      viewBox="0 0 150 88"
      xmlns="http://www.w3.org/2000/svg">

      <g
        fill="currentColor"
        transform-origin="center"
        transform={transform}
      >
        {Figure(props.layout)}
      </g>

    </svg>
  );
}

function Figure(layout: EImageLayout) {
  switch (layout) {
    case 'NoImage':
      return (
        <React.Fragment>
          {/* lines of text */}
          <rect x="0" y="0" width="150" height="8" rx="3" />
          <rect x="0" y="20" width="150" height="8" rx="3" />
          <rect x="0" y="40" width="150" height="8" rx="3" />
          <rect x="0" y="60" width="150" height="8" rx="3" />
          <rect x="0" y="80" width="150" height="8" rx="3" />
        </React.Fragment>
      );
    case 'ImageOnly':
      return (
        <React.Fragment>
          {/* picture */}
          <rect x="0" y="0" width="150" height="88" rx="5" />
        </React.Fragment>
      );
    case 'Top':
    case 'Bottom':
      return (
        <React.Fragment>
          {/* picture */}
          <rect x="0" y="0" width="150" height="48" rx="5" />

          {/* lines of text */}
          <rect x="0" y="60" width="150" height="8" rx="3" />
          <rect x="0" y="80" width="150" height="8" rx="3" />
        </React.Fragment>
      );
    case 'Left':
    case 'Right':
      return (
        <React.Fragment>
          {/* picture */}
          <rect x="0" y="0" width="70" height="88" rx="5" />

          {/* lines of text */}
          <rect x="80" y="0" width="70" height="8" rx="3" />
          <rect x="80" y="20" width="70" height="8" rx="3" />
          <rect x="80" y="40" width="70" height="8" rx="3" />
          <rect x="80" y="60" width="70" height="8" rx="3" />
          <rect x="80" y="80" width="70" height="8" rx="3" />
        </React.Fragment>
      );
      break;
    default:
      return (
        <React.Fragment>
          {/* picture */}
          <rect x="0" y="0" width="70" height="48" rx="5" />

          {/* lines of text */}
          <rect x="80" y="0" width="70" height="8" rx="3" />
          <rect x="80" y="20" width="70" height="8" rx="3" />
          <rect x="80" y="40" width="70" height="8" rx="3" />
          <rect x="0" y="60" width="150" height="8" rx="3" />
          <rect x="0" y="80" width="150" height="8" rx="3" />
        </React.Fragment>
      );
  };
}