import React, { useEffect, useState } from 'react';
import { IAdModuleParagraph } from '../../../../common/ad-modules/IAdModule';
import ChecklistItemWrapper from '../checklist-item-wrapper/ChecklistItemWrapper';
import ChecklistItemText from '../checklist-item-text/ChecklistItemText';
//import styles from './ChecklistItemParagraph.module.css';

interface IProps {
  data: IAdModuleParagraph;
  level?: number;
  isReadonly: boolean;
  isCollapsed: boolean;
}

export default function ChecklistItemParagraph(props: IProps) {
  console.log(props.data)
  if (!props.data.status) return null;
  const [collapsed, setCollapsed] = useState(true);
  //---------------------------------------------------------------------------
  useEffect(() => {
    setCollapsed(props.isCollapsed);
  }, [props.isCollapsed]);
  //---------------------------------------------------------------------------
  const onHover = () => {

  };
  //---------------------------------------------------------------------------
  const onTextHover = () => {

  };
  //---------------------------------------------------------------------------
  const onToggle = () => {
    setCollapsed(!collapsed);
  };
  //---------------------------------------------------------------------------
  const onTextReview = () => {

  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <ChecklistItemWrapper
        unitType="ContentParagraph"
        level={props.level}
        status={props.data.status}
        isGroup={true}
        isReadonly={true} // NOTE: for now we can't review ad module as such (only its contents)
        onHover={onHover}
        caption={"Paragraph"}
        isCollapsed={true}
        onToggle={onToggle}
      />

      {/* Ad Module Header */}
      {props.data.text && !collapsed &&
        <ChecklistItemText
          data={props.data.text}
          level={2}
          isReadonly={props.isReadonly}
          onHover={onTextHover}
          onReview={onTextReview}
        />}
    </React.Fragment>
  );
}