import { useContext } from 'react';
import { PageStatusIcon } from '../adpage-status-icon/PageStatusIcon';
import styles from './AdPageListItem.module.css';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../functions/GetName';
import { IUiListItemAdPage } from '../IUiListItemAdPage';
import { IUiOption } from '../../../options/IUiOption';
import AdPagesListItemOption from './adpages-list-item-option/AdPagesListItemOption';
import { IAdLanguageSetListItem } from '../../../../ad-content-editor/IAdLanguageSet';

interface IProps {
  ui: IUiListItemAdPage;
  data: IAdLanguageSetListItem;
  onAdPageOpen: () => void;
  onAdPageOptionSelect?: (selectedOptionId: string) => void;
}

export default function AdPageListItem(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const adPageLocale = appUiContext.dictionaries?.supportedLocales?.find(l => l.id == props.data.localeId);
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  if (props.onAdPageOptionSelect) {
    props.ui.menuContent.options.forEach(option => {
      switch (option.id) {
        case "AdPageEditor.MenuContent.OptionEdit":
          updatedOptions.push({
            ...option,
            iconId: "pencil",
            // isDisabled: (props.data.isReadonly && (props.data.newAdPageId !== null)) ||
            //   (commonBusinessContext.businessType == "System" && props.data.isReadonly)
          });
          break;
        case "AdPageEditor.MenuContent.OptionTranslate":
          updatedOptions.push({
            ...option,
            iconId: "translator"
          });
          break;
        case "AdPageEditor.MenuContent.OptionActivate":
          updatedOptions.push({
            ...option,
            iconId: "eyeOpened",
            isDisabled: !props.data.status.statusId.startsWith("NA_DEAC_")
          });
          break;
        case "AdPageEditor.MenuContent.OptionDeactivate":
          updatedOptions.push({
            ...option,
            iconId: "eyeClosed",
            isDisabled: !props.data.status.isPublished
          });
          break;
        case "AdPageEditor.MenuContent.OptionDelete":
          updatedOptions.push({
            ...option,
            iconId: "delete",
            isDisabled: props.data.status.isReadonly
          });
          break;
      };
    });
  };
  const optionElements = updatedOptions.map(option => (
    <AdPagesListItemOption
      key={option.id}
      ui={option}
      onSelect={option.isDisabled ?
        () => { }
        :
        () => props.onAdPageOptionSelect && props.onAdPageOptionSelect(option.id)}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <div
        className={styles.details}
        onClick={props.onAdPageOpen}
      >
        <PageStatusIcon status={props.data.status} />
        <div className={styles.brand}>
          {adPageLocale ? GetName(appUiContext, adPageLocale.id, adPageLocale.name) : "Unknown"}
        </div>
      </div>

      {/* Display options only if option select handler is provided */}
      {props.onAdPageOptionSelect &&
        <div className={styles.options}>
          {optionElements}
        </div>}

    </div>
  );
}