import { useState } from 'react';
import { IDirectoryListItem } from '../DirectoryList';
import styles from './DirectoryListItem.module.css';
import Toggler from '../../../../../common/toggler/Toggler';
import DirectoryTranslationsListItem from './directory-translations-list-item/DirectoryTranslationsListItem';
import { EDirectoryId } from '../../Widgets';

interface IProps {
  data: IDirectoryListItem;
  onSelect: (directoryId: EDirectoryId, locale: string) => void;
}

export default function DirectoryListItem(props: IProps) {
  const [collapsed, setCollapsed] = useState(true);
  //---------------------------------------------------------------------------
  const translations = props.data.translations.map(t => (
    <DirectoryTranslationsListItem
      key={t.locale}
      data={t}
      onSelect={(localeId: string) => props.onSelect(props.data.directoryId, localeId)}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <div
        className={styles.header}
        onClick={() => setCollapsed(!collapsed)}
      >
        <div className={styles.caption}>
          <div className={styles.name}>
            {props.data.directoryId}
          </div>
          <div className={styles.description}>
            {props.data.description}
          </div>
        </div>
        <div className={styles.toggler}>
          <Toggler collapsed={collapsed} />
        </div>
      </div>

      {!collapsed &&
        <div className={styles.items}>
          {translations}
        </div>}

    </div>
  );
}