import { useContext, useEffect, useRef, useState } from 'react';
import { EditFormState } from '../../../../../common/edit-form/IEditFormState';
import DictionaryItemEditform from './DictionaryItemEditform';
import { IUiEditformDictionaryItem } from './IUiEditformDictionaryItem';
import { DirectoryEditorContext } from '../../../../../../context/directory-editor-context/DirectoryEditorContextProvider';
import { DictionaryDraftItem, IDictionaryDraftItemUpdate, IDictionaryItemStatus } from '../IDictionaryDraftItem';
import { DictionaryItemValidator, IDictionaryItemValidationState } from './DictionaryItemValidator';
import { EDraftItemState } from '../../../../../../context/directory-editor-context/EDraftItemState';

interface IProps {
  ui: IUiEditformDictionaryItem;
  data?: DictionaryDraftItem;
  isNew?: boolean;
  isReadonly?: boolean;
  onUpdate: (updatedItem: DictionaryDraftItem, itemPathToCheck?: string) => void;
  onReposition?: (newItem: DictionaryDraftItem, oldItem: DictionaryDraftItem) => void;
  onClose: () => void;
}

export default function CommonDictionaryItemEditForm(props: IProps) {
  const { directoryEditorContext } = useContext(DirectoryEditorContext);
  const [editorState, setEditorState] = useState<EditFormState<DictionaryDraftItem>>();
  const [validationState, setValidationState] = useState<IDictionaryItemValidationState>();
  const formValidator = useRef<DictionaryItemValidator>();
  const isReadonly = props.isReadonly == undefined ? false : props.isReadonly;
  const dictionaryItem = editorState?.object;
  const dictionaryItemInitial = editorState?.initialState;
  //--------------------------------------------------------------------------- Data, Validation
  useEffect(() => {
    formValidator.current = new DictionaryItemValidator(props.ui);
    //-------------------------------------------------------------------------
    const validationState = formValidator.current.initialize(props.data);
    setValidationState(validationState);
    //-------------------------------------------------------------------------
    const state = new EditFormState<DictionaryDraftItem>({
      object: props.data,
      isNew: props.isNew ? true : false,
      isValid: false
    });
    setEditorState(state);
  }, []);
  //---------------------------------------------------------------------------
  const onIdUpdate = (updatedId: string) => {
    if (!editorState) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    // If it's a new item, automatically update name with the same value as Id
    const objectUpdate: IDictionaryDraftItemUpdate = editorState.isNew ? {
      id: updatedId,
      name: updatedId
    } : {
      id: updatedId
    };
    let updatedItem: DictionaryDraftItem = editorState.object ?
      editorState.object.getUpdated(objectUpdate) :
      DictionaryDraftItem.getNew({
        id: updatedId,
        name: updatedId
      });
    //-------------------------------------------------------------------------
    let updatedValidationState = formValidator.current.validateId(updatedItem.guid, updatedId, validationState, directoryEditorContext.editorState.draft.items);
    if (objectUpdate.name)
      updatedValidationState = formValidator.current.validateName(updatedItem.guid, objectUpdate.name, updatedValidationState, directoryEditorContext.editorState.draft.items);
    setValidationState(updatedValidationState);
    //-------------------------------------------------------------------------
    const updatedState = new EditFormState<DictionaryDraftItem>(editorState, {
      object: updatedItem as DictionaryDraftItem,
      isValid: updatedValidationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onNameUpdate = (updatedName: string) => {
    if (!editorState?.object) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    let updatedItem: DictionaryDraftItem = editorState.object.getUpdated({ name: updatedName });
    const updatedValidationState = formValidator.current.validateName(updatedItem.guid, updatedName, validationState, directoryEditorContext.editorState.draft.items);
    setValidationState(updatedValidationState);
    const updatedState = new EditFormState<DictionaryDraftItem>(editorState, {
      object: updatedItem as DictionaryDraftItem,
      isValid: updatedValidationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onDescriptionUpdate = (updatedDescription: string) => {
    if (!editorState?.object) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    let updatedItem: DictionaryDraftItem = editorState.object.getUpdated({ description: updatedDescription });
    const updatedValidationState = formValidator.current.validateDescription(updatedItem.guid, updatedDescription, validationState, directoryEditorContext.editorState.draft.items);
    setValidationState(updatedValidationState);
    const updatedState = new EditFormState<DictionaryDraftItem>(editorState, {
      object: updatedItem as DictionaryDraftItem,
      isValid: updatedValidationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onStatusUpdate = (updatedStatus: IDictionaryItemStatus) => {
    if (!editorState?.object) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    let updatedItem: DictionaryDraftItem = editorState.object.getUpdated({ status: updatedStatus });
    const updatedState = new EditFormState<DictionaryDraftItem>(editorState, {
      object: updatedItem as DictionaryDraftItem,
      isValid: validationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onParentUpdate = (parentNodeFullPath?: string) => {
    if (!editorState?.object) return;
    if (!validationState) return;
    let updatedItem: DictionaryDraftItem = editorState.object.getUpdated({ path: parentNodeFullPath });
    //-------------------------------------------------------------------------
    const updatedState = new EditFormState<DictionaryDraftItem>(editorState, {
      object: updatedItem as DictionaryDraftItem,
      isValid: validationState.isValid
    });
    setEditorState(updatedState);
  };
  //--------------------------------------------------------------------------- NOTE: Handle draftstate here!
  const onConfirm = () => {
    if (!dictionaryItem) return;
    if (!validationState?.isValid) return;
    dictionaryItem.draftState = EDraftItemState.None;
    if (props.onReposition && dictionaryItem.original && dictionaryItemInitial && dictionaryItem.path !== dictionaryItemInitial.path) {
      // We only can reposition if it's published (has original), otherwise it's just an update
      // If it's published and path is changed, handle repositioning: 
      const newItem = dictionaryItem;
      newItem.isRepositionedNew = true;
      //---------------------------------------------------------------------
      const oldItem = new DictionaryDraftItem(dictionaryItemInitial);
      oldItem.isRepositionedOld = true;
      oldItem.newPath = newItem.path;
      newItem.oldPath = oldItem.path;
      //-----------------------------------------------------------------------
      props.onReposition(newItem, oldItem);
    } else {
      // If it's a new item or path is not changed, just update current item
      if (dictionaryItem.original) {
        if (dictionaryItem.name != dictionaryItem.original?.name) {
          dictionaryItem.isTranslationEdited = true;
        };
        if (dictionaryItem.description != dictionaryItem.original?.description) {
          dictionaryItem.isTranslationEdited = true;
        };
        //---------------------------------------------------------------------
        if (JSON.stringify(dictionaryItem.status) !== JSON.stringify(dictionaryItem.original.status)) {
          dictionaryItem.isEdited = true;
        };
        if (!dictionaryItem.status || (!dictionaryItem.status.editors && !dictionaryItem.status.viewers)) {
          dictionaryItem.isDeleted = true;
          dictionaryItem.isEdited = false;
        };
      } else {
        dictionaryItem.isNew = true;
      };
      //-----------------------------------------------------------------------
      // If it's a new item but was repositioned, we need to check its parent (if it's valid after repositioning)
      let pathToCheck: string | undefined = undefined;
      if (dictionaryItemInitial && dictionaryItem.path != dictionaryItemInitial.path) {
        pathToCheck = new DictionaryDraftItem(dictionaryItemInitial).path;
      };
      console.log(dictionaryItem.path, dictionaryItemInitial?.path, pathToCheck)
      props.onUpdate(dictionaryItem, pathToCheck);
    };
  };
  //---------------------------------------------------------------------------
  return (
    <DictionaryItemEditform
      ui={props.ui}
      data={editorState}
      isReadonly={!directoryEditorContext.editMode || isReadonly}
      validationState={validationState}
      onIdUpdate={onIdUpdate}
      onParentUpdate={onParentUpdate}
      onNameUpdate={onNameUpdate}
      onDescriptionUpdate={onDescriptionUpdate}
      onStatusUpdate={onStatusUpdate}
      onConfirm={onConfirm}
      onClose={props.onClose}
    />
  );
}