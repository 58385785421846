import React, { useEffect, useRef, useState } from 'react';
import CombineStyles from '../../../../utils/combineStyles';
import Icon from '../../../common/icon/Icon';
import PopupDialog from '../../../common/popup-v2/popup-dialog/PopupDialog';
import Toggler from '../../../common/toggler/Toggler';
import BusinessTagEditForm from './business-tag-edit-form/BusinessTagEditForm';
import styles from './BusinessTagsDictionaryNodeEditor.module.css';
import { IFilter } from '../../administrator-console/dictionaries/business-tags-editor/BusinessTagsEditor';
import BusinessTagHeader from './business-tag-header/BusinessTagHeader';
import BusinessTagChildrenList from './business-tag-children-list/BusinessTagChildrenList';
import { dropTargetId } from './drop-target/DropTarget';
import { IBusinessTagsDictionaryDraftItem } from '../../administrator-console/dictionaries/business-tags-editor/IBusinessTagsDictionary';
import { DraftItemStateEnum } from '../dictionary-draft-models/DraftItemStateEnum';

interface ITagToEdit {
  tag?: IBusinessTagsDictionaryDraftItem;
  parentTag?: IBusinessTagsDictionaryDraftItem;
}

const itemIdDataName = 'itemId';
const itemIndexDataName = 'itemIndex';

interface IProps {
  currentPositionIndex?: number; // Passed if parent item has itemsManualOrder=true; we need it to determine order of elements on drag-n-drop
  data: IBusinessTagsDictionaryDraftItem;
  index?: number;
  initialData?: IBusinessTagsDictionaryDraftItem;
  parent?: IBusinessTagsDictionaryDraftItem;
  rootNodes: IBusinessTagsDictionaryDraftItem[];
  displayId: boolean;
  readonly: boolean;
  manualOrder: boolean;  // This means items in current list can be dragged to a new position 
  filter: IFilter;
  onUpdate: (updatedTag: IBusinessTagsDictionaryDraftItem, updatedParentTag?: IBusinessTagsDictionaryDraftItem) => void;
  onDelete: (tagPath: string) => void;
  onReorder?: (tagId: string, newPosition: number) => void;
}

export default function BusinessTagsDictionaryNodeEditor(props: IProps) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [tagToEdit, setTagToEdit] = useState<ITagToEdit>();
  const containerDiv = useRef<HTMLDivElement>(null);
  const headerDiv = useRef<HTMLDivElement>(null);
  const topTarget = useRef<HTMLDivElement | null>(null);
  const bottomTarget = useRef<HTMLDivElement | null>(null);
  const newPosition = useRef<number>();
  //---------------------------------------------------------------------------
  const hasChildren = props.data.items && props.data.items.length > 0;
  const cannotBeDeleted = hasChildren ||
    ((props.data.draftState & (DraftItemStateEnum.IsDeleted | DraftItemStateEnum.IsRepositionedNew | DraftItemStateEnum.IsRepositionedOld))
      != DraftItemStateEnum.None);
  const cannotBeEdited = (props.data.draftState & (DraftItemStateEnum.IsDeleted | DraftItemStateEnum.IsRepositionedOld)) != DraftItemStateEnum.None;
  const isDraggable = props.onReorder && !cannotBeEdited && !props.readonly;
  //console.log(props.data.id, props.data.isCollapsed)
  //---------------------------------------------------------------------------
  useEffect(() => {
    // If current list can be re-ordered manually, display drop targets while elements are dragged
    if ((props.currentPositionIndex != undefined) && containerDiv.current) {
      // Presume that nearest siblings (above and under) of the current container are drop targets
      const topSibling = containerDiv.current.previousSibling as HTMLDivElement;
      const bottomSibling = containerDiv.current?.nextSibling as HTMLDivElement;
      topTarget.current = (topSibling && topSibling.id === dropTargetId) ? topSibling : null;
      bottomTarget.current = (bottomSibling && bottomSibling.id === dropTargetId) ? bottomSibling : null;
    };
  }, [props.currentPositionIndex, containerDiv.current]);
  //---------------------------------------------------------------------------
  const onSetCollapsed = (updatedCollapsed: boolean) => {
    //console.log('set collapsed')
    if (hasChildren) {
      props.onUpdate({ ...props.data, isCollapsed: updatedCollapsed });
    };
  };
  //---------------------------------------------------------------------------
  const onEdit = () => {
    if (props.data && !cannotBeEdited) {
      setTagToEdit({ tag: props.data, parentTag: props.parent });
      setPopupOpen(true);
    };
  };
  //---------------------------------------------------------------------------
  const onUpdate = (
    updatedTag: IBusinessTagsDictionaryDraftItem,
    isNewChild: boolean,
    updatedParentTag?: IBusinessTagsDictionaryDraftItem
  ) => {
    // Check if it's a new item???
    // in this case it should not be marked as edited
    console.log(updatedParentTag)
    console.log(updatedTag)
    setPopupOpen(false);
    setTagToEdit(undefined);
    if (isNewChild) {
      const updatedItems = props.data?.items ? props.data.items.concat(updatedTag) : [updatedTag];
      const updatedCurrentTag: IBusinessTagsDictionaryDraftItem = {
        ...props.data as IBusinessTagsDictionaryDraftItem,
        hasNew: true,
        items: updatedItems,
        //collapsed: collapsed
      };
      props.onUpdate(updatedCurrentTag);
    } else {
      props.onUpdate(updatedTag, updatedParentTag);
    };
  };
  //---------------------------------------------------------------------------
  const onChildUpdate = (updatedChildTag: IBusinessTagsDictionaryDraftItem, updatedParentTag?: IBusinessTagsDictionaryDraftItem) => {
    props.onUpdate(updatedChildTag, updatedParentTag);
  };
  //---------------------------------------------------------------------------
  const onChildAdd = () => {
    if (!cannotBeEdited) {
      setTagToEdit({ tag: undefined, parentTag: props.data });
      setPopupOpen(true);
    };
  };
  //---------------------------------------------------------------------------
  const onDelete = () => {
    // Check if it's a new item
    // in this case it should not be marked as deleted, but just removed from the tree
    if (props.data.isNew) {
      props.onDelete(props.data.path);
    } else {
      // Otherwise it's just marked as deleted
      if (!cannotBeDeleted && props.parent) {
        const updatedCurrentTag: IBusinessTagsDictionaryDraftItem = {
          ...props.data as IBusinessTagsDictionaryDraftItem,
          draftState: props.data.draftState | DraftItemStateEnum.IsDeleted,
          isDeleted: props.data.isNew ? props.data.isDeleted : true
        };
        props.onUpdate(updatedCurrentTag);
      };
    };
  };
  //---------------------------------------------------------------------------
  const onRestore = () => {
    // Just make it appear unchanged, switching isDelete flag off
    if (props.data.isDeleted) {
      const updatedCurrentTag: IBusinessTagsDictionaryDraftItem = {
        ...props.data as IBusinessTagsDictionaryDraftItem,
        draftState: props.data.draftState & ~DraftItemStateEnum.IsDeleted,
        isDeleted: false
      };
      props.onUpdate(updatedCurrentTag);
    };
  };
  //---------------------------------------------------------------------------
  const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    if (props.onReorder && (e.target as HTMLDivElement).id == 'header') {
      //isDragged.current = true;
      //console.log("drag start", props.data.id)
      e.dataTransfer.clearData();
      e.dataTransfer.setData(itemIdDataName, props.data.id);
      if (props.currentPositionIndex)
        e.dataTransfer.setData(itemIndexDataName, props.currentPositionIndex.toString());
    };
  };
  //---------------------------------------------------------------------------
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    // Do something only if it's an re-orderable list and only if another node is being dragged
    if ((props.currentPositionIndex != undefined) && containerDiv.current && headerDiv.current) {
      e.preventDefault();
      //console.log('drag over', props.data.id, props.index, topTarget.current, bottomTarget.current)
      //-----------------------------------------------------------------------
      // If so, highlight drop target based on mouse pointer position
      const headerDivRect = headerDiv.current.getBoundingClientRect();
      //console.log('drag over', props.data.id, topTarget.current, bottomTarget.current)
      // If it's a top half of the business tag header, activate drop target placed above the current tag
      if (e.nativeEvent.offsetY < headerDivRect.height / 2) {
        if (topTarget.current)
          topTarget.current.style.opacity = '1';
        if (bottomTarget.current)
          bottomTarget.current.style.opacity = '0';
        newPosition.current = props.currentPositionIndex;
      } else {
        // If it's a bottom half of the business tag header, activate drop target placed under the current tag
        if (topTarget.current)
          topTarget.current.style.opacity = '0';
        if (bottomTarget.current)
          bottomTarget.current.style.opacity = '1';
        newPosition.current = props.currentPositionIndex + 1;
      };
    };
  };
  //---------------------------------------------------------------------------
  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    if (containerDiv.current) {
      console.log('drag leave', props.data.id)
      if (topTarget.current)
        topTarget.current.style.opacity = '0';
      if (bottomTarget.current)
        bottomTarget.current.style.opacity = '0';
    };
  };
  //---------------------------------------------------------------------------
  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    //console.log('drop', props.data.id, newPosition.current)
    if (props.onReorder && (props.currentPositionIndex != undefined)
      && (newPosition.current != undefined)
      && containerDiv.current && headerDiv.current
    ) {
      // Check if it's the same item
      const droppedItemId = e.dataTransfer.getData(itemIdDataName);
      const itemIsReturnedToInitialPosition = (droppedItemId == props.data.id)
        && ((props.data.draftState & DraftItemStateEnum.IsRepositionedOld) != DraftItemStateEnum.None);
      if (droppedItemId != props.data.id || itemIsReturnedToInitialPosition) {
        //----------------------------------------------------------------------
        // Get transferred data and respond to parent component with changes made
        // This is for case when we need to display item in its old position
        //props.onReorder(droppedItemId, Number(e.dataTransfer.getData(itemIndexDataName)), newPosition.current);
        // This is for the case when we don't need to display item in old position
        props.onReorder(droppedItemId, newPosition.current);
      };
      //----------------------------------------------------------------------
      // Make drop target elements invisible
      if (topTarget.current)
        topTarget.current.style.opacity = '0';
      if (bottomTarget.current)
        bottomTarget.current.style.opacity = '0';
    }
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <div
        ref={containerDiv}
        className={styles.container}
      >
        <div
          id='header'
          ref={headerDiv}
          className={styles.header}
          draggable={isDraggable}
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div className={CombineStyles([
            styles.toggler,
            hasChildren ? "" : styles.disabled
          ])}>
            <Toggler
              collapsed={props.data.isCollapsed}
              onExpand={() => onSetCollapsed(false)}
              onCollapse={() => onSetCollapsed(true)}
            />
          </div>
          <BusinessTagHeader
            draftStatesUi={props.filter.grouping}
            data={props.data}
            displayId={props.displayId}
            index={props.index}
          />

          {!props.readonly &&
            <div className={styles.options}>
              <div
                className={CombineStyles([
                  styles.option,
                  cannotBeEdited ? styles.disabled : ""
                ])}
                onClick={onEdit}
              >
                <Icon imageName='pencil' />
              </div>
              <div
                className={CombineStyles([
                  styles.option,
                  cannotBeEdited ? styles.disabled : ""
                ])}
                onClick={onChildAdd}
              >
                <Icon imageName='plus' />
              </div>
              {!props.data.isDeleted &&
                <div
                  className={CombineStyles([
                    styles.option,
                    cannotBeDeleted ? styles.disabled : "",
                    props.parent ? "" : styles.disabled
                  ])}
                  onClick={onDelete}
                >
                  <Icon imageName='delete' />
                </div>}
              {props.data.isDeleted &&
                <div
                  className={styles.option}
                  onClick={onRestore}
                >
                  <Icon imageName='undelete' />
                </div>}
            </div>}

        </div>

        <BusinessTagChildrenList
          key={props.data.id}
          data={props.data}
          initialData={props.initialData}
          rootNodes={props.rootNodes}
          displayId={props.displayId}
          isReadonly={props.readonly}
          filter={props.filter}
          onUpdate={onChildUpdate}
          onDelete={props.onDelete}
        />

        {popupOpen &&
          <PopupDialog
            id=''
            header={tagToEdit?.tag ? 'Edit Business Tag' : `Create a sub-tag for {{${props.data.id}}}`}
            isModal={true}
            isDraggable={false}
            onClose={() => setPopupOpen(false)}
          >
            <BusinessTagEditForm
              data={tagToEdit?.tag}
              initialData={tagToEdit?.tag ? props.initialData : undefined}
              parent={tagToEdit?.parentTag}
              rootNodes={props.rootNodes}
              onUpdate={onUpdate}
              onCancel={() => setPopupOpen(false)}
            />
          </PopupDialog>}

      </div>
    </React.Fragment>
  );
}