import { TSysSettings } from "../../../context/app-ui-context/ISysSettings";
import { GetParentLocale } from "./GetTranslation";

//-----------------------------------------------------------------------------
// Fallback logic example:
// 2: de-ch --> id
// 1: de-ch --> de --> id
// 0: de-ch --> de --> en --> id
const defaultLocale = "en";

export interface IGetTranslationResult {
  value: string;
  locale: string;
}

export default function GetTranslationWithLocale(
  systemSettings: TSysSettings,
  elementId: string,
  textProperty: any,
  elementPart: string, //"Name" | "Description" | "Caption" | "Placeholder" | "Hint",
  locale: string,
  fallbackLevel?: number
): IGetTranslationResult {
  //---------------------------------------------------------------------------
  // Set fallback level to max value in case if it's system locale (i.e. no need to fallback from system locale)
  if (!fallbackLevel) {
    fallbackLevel = systemSettings.sysLocalesList.includes(locale)
      ? 2
      : fallbackLevel;
  };
  //---------------------------------------------------------------------------
  const valueIsEmpty =
    textProperty === null ||
    textProperty === undefined ||
    Object.keys(textProperty).length === 0;
  //---------------------------------------------------------------------------
  const SystemId = () => {
    return `${systemSettings.sysLanguagePrefix}${elementId}.${elementPart}${systemSettings.sysLanguageSuffix}`;
  };
  //---------------------------------------------------------------------------
  if (
    locale === systemSettings.sysLocales.i18nLocale ||
    valueIsEmpty
  ) {
    return { value: SystemId(), locale: systemSettings.sysLocales.i18nLocale };
  } else {
    // if (textProperty[systemSettings.sysLocales.nativeLocale])
    //   return textProperty[systemSettings.sysLocales.nativeLocale];
    // else {
      // Apply fallback logic
      var fallbackLocale = locale;
      for (let i = 2; i >= 0; i--) {
        //console.log(`i=${i}, fallbacklocale=${fallbackLocale}`);
        if (textProperty[fallbackLocale])
          return {
            value: textProperty[fallbackLocale],
            locale: fallbackLocale
          };
        if (i === fallbackLevel)
          return {
            value: SystemId(),
            locale: systemSettings.sysLocales.i18nLocale
          };
        else {
          fallbackLocale = GetParentLocale(fallbackLocale);
        };
      };
    //}
  };
  return { value: SystemId(), locale: systemSettings.sysLocales.i18nLocale };
}