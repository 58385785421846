import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { ScreenType, useAppScreenContext } from "../../../context/app-screen-context/AppScreenProvider";
import { ImageEditorSession } from "./image-editor-context/ImageEditorSession";
import CancelOption from "./image-editor-option/CancelOption";
import ConfirmOption from "./image-editor-option/ConfirmOption";
import CropImageOption from "./image-editor-option/CropImageOption";
import StandardImageEditorOption from "./image-editor-option/StandardImageEditorOption";
import RedoOption from "./image-editor-option/RedoOption";
import RotateLeftOption from "./image-editor-option/RotateLeftOption";
import RotateRightOption from "./image-editor-option/RotateRightOption";
import SelectImageOption from "./image-editor-option/SelectImageOption";
import SeparatorOption from "./image-editor-option/SeparatorOption";
import UndoOption from "./image-editor-option/UndoOption";
import styles from "./ImageEditorToolbar.module.css";
import { EImageLoadingState } from "./TImageData";
import { TUiImageEditor } from "./TUiImageEditor";
import { EImageEditorAction } from "./image-editor-canvas/Crop";

export enum EImageEditorMode {
  Image = 0,
  Placeholder = 1,
  Spinner = 2,
  Crop = 3,
  Description = 4,
  ImageLibrary = 5,
}

interface IProps {
  ui: TUiImageEditor;
  session?: ImageEditorSession;
  mode: EImageEditorMode;
  fullWindow: boolean;
  removeImageDisabled: boolean;
  userImageCount: number;
  editEnabled: boolean;
  onImageEditorAction: (action: EImageEditorAction) => void;

  // image select is performed by separate callback,
  // not by EImageEditorAction.SelectImage because of <input> div complications
  onImageSelect: () => void;
}


export default function ImageEditorToolbar(props: IProps) {
  const [optionsDisabled, setOptionsDisabled] = useState(false);
  const screenType = useAppScreenContext();
  const divRef = useRef<HTMLDivElement>(null);
  const resizeObserver = useRef<ResizeObserver>();
  //---------------------------------------------------------------------------
  const resizeObserverCallback = () => {
    let br = divRef.current?.getBoundingClientRect();
    //console.log("Toolbar.observer:", br);
  }
  //---------------------------------------------------------------------------------------------
  const newObserver = () => {
    //console.log("Toolbar.newObserver");

    if (!divRef.current)
      return;
    if (resizeObserver.current) {
      resizeObserver.current.disconnect();
    }
    resizeObserver.current = new ResizeObserver(resizeObserverCallback);
    resizeObserver.current.observe(divRef.current);
  }
  //---------------------------------------------------------------------------------------------
  useEffect(() => {
    if (divRef.current) {
      newObserver();
    }
  }, [divRef.current]);
  //-[props.session.loadingState]------------------------------------------------------
  useEffect(() => {
    let loadingState = props.session?.loadingState;

    switch (loadingState) {
      case EImageLoadingState.Downloading:
      case EImageLoadingState.ImageUploading:
      case EImageLoadingState.DescriptionUploading:
        setOptionsDisabled(true);
        break;
      default:
        setOptionsDisabled(false);
    };
  }, [props.session?.loadingState]);
  //---------------------------------------------------------------------------
  const cropStartClick = () => {
    //setMode(EImageEditorMode.Crop);
    props.onImageEditorAction(EImageEditorAction.CropStart);
  }
  //---------------------------------------------------------------------------
  const confirmClick = () => {
    switch (props.mode) {
      case EImageEditorMode.Crop:
        props.onImageEditorAction(EImageEditorAction.CropConfirm);
        break;
      case EImageEditorMode.Description:
        props.onImageEditorAction(EImageEditorAction.ImageDescriptionConfirm);
        break;
    }
  }
  //---------------------------------------------------------------------------
  const cancelClick = () => {
    switch (props.mode) {
      case EImageEditorMode.Crop:
        props.onImageEditorAction(EImageEditorAction.CropDiscard);
        break;
      case EImageEditorMode.Description:
        props.onImageEditorAction(EImageEditorAction.ImageDescriptionDiscard);
        break;
      case EImageEditorMode.ImageLibrary:
        props.onImageEditorAction(EImageEditorAction.ImageFromGalleryDiscard);
        break;
    }
    //setMode(EImageEditorMode.Image);
  }
  //---------------------------------------------------------------------------
  const onFullScreenClick = () => {
    props.onImageEditorAction(EImageEditorAction.ToggleFullScreenMode);
  }
  //---------------------------------------------------------------------------
  const getFullScreenOptions = () => {
    if (!fullScreenExitOptionUi || !fullScreenEnterOptionUi || !separatorOptionUi)
      return undefined;
    //---------------------------------------------------------------------------
    let separator = <SeparatorOption
      key="separator"
      ui={{
        ...separatorOptionUi,
        disabled: commonDisabled,
        visible: true
      }} />;
    //---------------------------------------------------------------------------
    let enterFullScreen = <StandardImageEditorOption
      key="fullScreenEnter"
      ui={{
        ...fullScreenEnterOptionUi,
        disabled: commonDisabled,
        visible: !props.fullWindow
      }}
      onSelect={onFullScreenClick}
      imageName="fullScreenEnter"
    />
    //---------------------------------------------------------------------------
    let exitFullScreen = <StandardImageEditorOption
      key="fullScreenExit"
      ui={{
        ...fullScreenExitOptionUi,
        disabled: commonDisabled,
        visible: props.fullWindow
      }}
      onSelect={onFullScreenClick}
      imageName="fullScreenExit"
    />
    //---------------------------------------------------------------------------
    let items: JSX.Element[] = [separator, enterFullScreen, exitFullScreen];
    return items;
  }
  //---------------------------------------------------------------------------
  const onRemoveImage = () => {
    props.onImageEditorAction(EImageEditorAction.RemoveImage);
    //alert("Remove Image");
  }
  //---------------------------------------------------------------------------
  const selectImageOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_SelectImage");
  const selectImageFromGalleryOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_SelectImageFromGallery");
  const removeImageOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_SelectImage");
  const undoOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_Undo");
  const redoOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_Redo");
  const flipHorzOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_FlipHorz");
  const flipVertOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_FlipVert");
  const rotateLeftOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_RotateLeft");
  const rotateRightOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_RotateRight");
  const cropOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_Crop");
  const confirmOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_CropConfirm");
  const cancelOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_CropCancel");
  const fullScreenEnterOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_FullScreenEnter");
  const fullScreenExitOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_FullScreenExit");
  const separatorOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_Save");
  const editImageOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_EditImage");
  //const imageDescriptionOptionUi = props.ui.options.find(o => o.id == "ImagePicker_Options_Save");

  //---------------------------------------------------------------------------
  let containerType: string;
  switch (props.mode) {
    case EImageEditorMode.Crop:
    case EImageEditorMode.Description:
    case EImageEditorMode.ImageLibrary:
      containerType = screenType == ScreenType.Desktop ? styles.container : styles.mobileContainer;
      break;
    default:
      containerType = styles.container;
      break;
  }

  let contanerStyle = props.fullWindow ? { padding: '0.5em 0.5em 0.5em 0.5em' } : { padding: '0.5em 0 0.5em 0' };
  let loadingState = props.session?.loadingState;

  let commonDisabled: boolean = optionsDisabled;
  if (!commonDisabled && props.session?.imageExists == false)
    commonDisabled = true;
  let imageIsReadOnly = props.session?.isCurrentStateReadOnly;
  //console.log("Toolbar.imageIsReadOnly:", imageIsReadOnly);
  if (!commonDisabled && imageIsReadOnly == true)
    commonDisabled = true;

  let removeImageDisabled = commonDisabled || props.removeImageDisabled;
  //console.log("ImageEditorToolbar.removeImageDisabled:", removeImageDisabled);

  let fullScreenOptions = getFullScreenOptions();

  const ret = () => {
    //console.log("ImageEditorToolbar.ret.props.editEnabled:", props.editEnabled)
    if (!props.editEnabled && props.mode == EImageEditorMode.Image) {
      return <React.Fragment>
        {/* {editImageOptionUi &&
          <StandardImageEditorOption
            key="pencil"
            ui={{
              ...editImageOptionUi,
              disabled: false,
              visible: true
            }}
            imageName="pencil"
            onSelect={() => props.onImageEditorAction(EImageEditorAction.EnterEditMode)}
          />} */}
        {removeImageOptionUi && !props.removeImageDisabled && !props.session?.inLibrary &&
          <StandardImageEditorOption
            key="delete"
            ui={{
              ...removeImageOptionUi,
              disabled: props.removeImageDisabled,
              visible: true
            }}
            imageName="delete"//"removeImage"
            onSelect={onRemoveImage}
          />}
      </React.Fragment>
    }
    switch (props.mode) {
      case EImageEditorMode.Placeholder:
        return <React.Fragment>
          {selectImageOptionUi &&
            <SelectImageOption
              ui={{
                ...selectImageOptionUi,
                disabled: optionsDisabled,
                visible: true
              }}
              //fileTypes={props.ui.fileTypes}
              onImageSelect={props.onImageSelect}
            />}
          {selectImageFromGalleryOptionUi &&
            <StandardImageEditorOption
              key="images"
              ui={{
                ...selectImageFromGalleryOptionUi,
                disabled: optionsDisabled || props.userImageCount == 0,
                visible: true
              }}
              imageName="images"
              onSelect={() => props.onImageEditorAction(EImageEditorAction.SelectImageFromGallery)}
            />}
        </React.Fragment>

      case EImageEditorMode.Image:
      case EImageEditorMode.Spinner:
        return <React.Fragment>
          {
            selectImageOptionUi && props.session?.canLoadNewImage &&
            <SelectImageOption
              ui={{
                ...selectImageOptionUi,
                disabled: optionsDisabled,
                visible: true
              }}
              //fileTypes={props.ui.fileTypes}
              onImageSelect={props.onImageSelect}
            />
          }
          {
            // selectImageFromGalleryOptionUi && !props.session?.inLibrary &&
            // <StandardImageEditorOption
            //   key="images"
            //   ui={{
            //     ...selectImageFromGalleryOptionUi,
            //     disabled: optionsDisabled || props.userImageCount == 0,
            //     visible: true
            //   }}
            //   imageName="images"
            //   onSelect={() => props.onImageEditorAction(EImageEditorAction.SelectImageFromGallery)}
            // />
          }
          {
            // removeImageOptionUi && !props.session?.inLibrary &&
            // <StandardImageEditorOption
            //   key="delete"
            //   ui={{
            //     ...removeImageOptionUi,
            //     disabled: removeImageDisabled,
            //     visible: true
            //   }}
            //   imageName="delete"//"removeImage"
            //   onSelect={onRemoveImage}
            // />
          }
          {undoOptionUi &&
            <UndoOption
              ui={{
                ...undoOptionUi,
                disabled: optionsDisabled || !props.session?.undoEnabled,
                visible: true
              }}
              onSelect={() => props.onImageEditorAction(EImageEditorAction.Undo)}
            />}
          {redoOptionUi &&
            <RedoOption
              ui={{
                ...redoOptionUi,
                disabled: optionsDisabled || !props.session?.redoEnabled,
                visible: true
              }}
              onSelect={() => props.onImageEditorAction(EImageEditorAction.Redo)}
            />}
          {flipHorzOptionUi &&
            <StandardImageEditorOption
              key="flipHorz"
              ui={{
                ...flipHorzOptionUi,
                disabled: commonDisabled,
                visible: true
              }}
              imageName="flipHorz"
              onSelect={() => props.onImageEditorAction(EImageEditorAction.FlipHorizontal)}

            />}
          {flipVertOptionUi &&
            <StandardImageEditorOption
              key="flipVert"
              ui={{
                ...flipVertOptionUi,
                disabled: commonDisabled,
                visible: true
              }}
              imageName="flipVert"
              onSelect={() => props.onImageEditorAction(EImageEditorAction.FlipVertical)}
            />}
          {rotateLeftOptionUi &&
            <RotateLeftOption
              ui={{
                ...rotateLeftOptionUi,
                disabled: commonDisabled,
                visible: true
              }}
              onSelect={() => props.onImageEditorAction(EImageEditorAction.RotateCounterclockwise)}
            />}
          {rotateRightOptionUi &&
            <RotateRightOption
              ui={{
                ...rotateRightOptionUi,
                disabled: commonDisabled,
                visible: true
              }}
              onSelect={() => props.onImageEditorAction(EImageEditorAction.RotateClockwise)}
            />}
          {cropOptionUi &&
            <CropImageOption
              ui={{
                ...cropOptionUi,
                disabled: commonDisabled,
                visible: true
              }}
              onSelect={cropStartClick}
            />}
          {fullScreenOptions}
        </React.Fragment>

      case EImageEditorMode.Crop:
      case EImageEditorMode.Description:
        return <React.Fragment>
          {confirmOptionUi &&
            <ConfirmOption
              ui={{
                ...confirmOptionUi,
                disabled: optionsDisabled,
                visible: true
              }}
              onSelect={confirmClick}
            />}
          {cancelOptionUi &&
            <CancelOption
              ui={{
                ...cancelOptionUi,
                disabled: optionsDisabled,
                visible: true
              }}
              onSelect={cancelClick}
            />}
          {/*props.mode == EImageEditorMode.Crop && fullScreenOptions*/}
        </React.Fragment>

      case EImageEditorMode.ImageLibrary:
        return <React.Fragment>
          {cancelOptionUi &&
            <CancelOption
              ui={{
                ...cancelOptionUi,
                disabled: optionsDisabled,
                visible: true
              }}
              onSelect={cancelClick}
            />}
        </React.Fragment>

      default:
        return <React.Fragment>
          {getFullScreenOptions()}
        </React.Fragment>
    }
  }

  return (
    <div ref={divRef} className={containerType} style={contanerStyle}>
      {ret()}
    </div>
  );

}
