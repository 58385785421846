import { useMsal } from "@azure/msal-react";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { AppAuthContextStore } from "../../../../context/app-auth-context/AppAuthContext";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import useNavigateWithContext from "../../../../hooks/useNavigateWithContext";
import { apiPostPrivate } from "../../../../utils/api";
import { apiBasePath, getStaffUserById, patchStaffUser } from "../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../utils/common";
import WikiSpinner from "../../../app-layout/spinner/wikiSpinner";
import { TUiBreadcrumb } from "../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import ConsoleTabContent from "../../../common/console-layout/console-tab-layout/console-tab-content/ConsoleTabContent";
import ConsoleTabLayout from "../../../common/console-layout/console-tab-layout/ConsoleTabLayout";
import { getTUiMenuContent } from "../../../common/menu/menu-content/TUiMenuContent";
import { pathAdminConsole, pathAdministratorConsole } from "../../AdminConsole";
import { pathUserList } from "../AdministratorConsole";
import { TStaffUser } from "./TStaffUser";
import UserRolesList from "./user-roles/UserRolesList";
import InputsGroupWrapper from "../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper";
import styles from "./UserEditForm.module.css";
import InputText from "../../../common/input-v2/input-text/InputText";
import FormOptions from "../../../common/form-options-bar/FormOptions";
import { IUiStaffRole } from "../IUiAdministratorConsole";
import { IUiOption } from "../../../common/options/IUiOption";
import { hardcodedUiEditFormStaffUser } from "./IUiEditFormStaffUser";
import GetHint from "../../../common/functions/GetHint";

export const pathUsers = "users";

interface IProps {
  staffRoles: IUiStaffRole[];
}

export default function UserEditForm(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  let navigate = useNavigateWithContext();
  const { requestedUserId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<TStaffUser>();
  const ui = hardcodedUiEditFormStaffUser;
  //--------------------------------------------------------------------------- Get user data
  useEffect(() => {
    if (appAuthContext.config && appAuthContext.user && requestedUserId) {
      setIsLoading(true);
      apiPostPrivate(
        instance,
        appAuthContext.config,
        `${apiBasePath}${stringFormatter(getStaffUserById, [requestedUserId])}`
      )
        .then((response) => {
          if (response && response.status === 200) {
            setUser(response.content);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
  }, []);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    if (user) {
      const userBreadcrumb: TUiBreadcrumb = {
        id: "UserId",
        caption: { en: user.userIdentity.userPrincipalName },
        path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathUsers}/${user.userIdentity.id}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: userBreadcrumb });
    }
  }, [user]);
  //---------------------------------------------------------------------------
  const onUpdate = (updatedStaffUser: TStaffUser) => {
    console.log(updatedStaffUser)
    setUser(updatedStaffUser);
    // Call API to update user data in database
    if (appAuthContext.config && appAuthContext.user) {
      setIsLoading(true);
      //apiPatchPrivate(
      apiPostPrivate(
        instance,
        appAuthContext.config,
        `${apiBasePath}${stringFormatter(patchStaffUser, [updatedStaffUser.userIdentity.id])}`,
        updatedStaffUser.staffRoles
      )
        .then((response) => {
          if (response && response.status === 200) {
            //setUser(response.content);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
  };
  //---------------------------------------------------------------------------
  const onClose = () => {
    // Navigate to user list
    const path = `/${pathAdminConsole}/${pathAdministratorConsole}/${pathUserList}`;
    console.log(path);
    navigate(path);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OptionClose":
        onClose();
        break;
    };
  };
  //---------------------------------------------------------------------------  
  const updatedOptions: IUiOption[] = [];
  ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionClose":
        updatedOptions.push({
          ...option,
          iconId: "close",
          isDefault: true
        })
        break;
    };
  });
  const updatedMenuContent = getTUiMenuContent({ ...ui.menuContent, options: updatedOptions });
  const listOptions = updatedMenuContent ?
    <FormOptions
      ui={updatedMenuContent}
      optionIdVisibleOnMobile={"SystemBusinessList_MenuContent_OptionAdd"}
      onSelect={onOptionSelect}
    />
    : undefined;
  //---------------------------------------------------------------------------
  return (
    <ConsoleTabLayout toolbar={listOptions} allowScroll={true}>
      <ConsoleTabContent prompt={GetHint(appUiContext, ui.id, ui.hint)}>
        <WikiSpinner show={isLoading} />
        {user &&
          <div className={styles.container}>
            <InputsGroupWrapper>
              <InputText
                ui={ui.inputUserId}
                data={user.userIdentity.id}
              />
              <InputText
                ui={ui.inputPrincipalName}
                data={user.userIdentity.userPrincipalName}
              />
              <InputText
                ui={ui.inputDisplayName}
                data={user.userIdentity.displayName ? user.userIdentity.displayName : undefined}
              />
              <UserRolesList
                staffRoles={props.staffRoles}
                data={user}
                onUpdate={onUpdate}
              />
            </InputsGroupWrapper>
          </div>}
      </ConsoleTabContent>
    </ConsoleTabLayout>
  );
}
