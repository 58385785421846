import { useContext } from 'react';
import RequestStatus from '../../../approval-request-status/RequestStatus';
import { IRegionalHomepageRequestsListItem } from './IRegionalHomepageRequestsListItem';
import styles from './RegionalHomepageRequestsListItem.module.css';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../../../common/functions/GetName';
import ApprovalRequestDetails from '../../../approval-request-details/ApprovalRequestDetails';

interface IProps {
  data: IRegionalHomepageRequestsListItem;
  onSelect: (selectedRequestId: string, locale: string) => void;
}

export default function RegionalHomepageRequestsListItem(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    props.onSelect(props.data.id, props.data.localeId);
  };
  //---------------------------------------------------------------------------
  const regionName = appUiContext.dictionaries?.supportedRegions?.find(r => r.id == props.data.regionId);
  const localeName = appUiContext.dictionaries?.supportedLocales?.find(l => l.id == props.data.localeId);
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      onClick={onSelect}
    >

      <div className={styles.requestCaption}>
        <span className={styles.region}>
          {GetName(appUiContext, props.data.regionId, regionName?.name)}
        </span>
        <div className={styles.locale}>
          <span className={styles.localeName}>
            {GetName(appUiContext, props.data.localeId, localeName?.name)}
          </span>
        </div>
      </div>

      <div className={styles.details}>
        <ApprovalRequestDetails
          data={props.data}
          assignedTo={props.data.actualLogRecord.assignedTo}
        />
      </div>

      <RequestStatus data={props.data.status} />

    </div>
  );
}