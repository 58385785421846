import { useState } from 'react';
import { IUiOption } from '../../../../../../common/options/IUiOption';
import { Contact } from '../../../contact/Contact';
import styles from './ContactsListItem.module.css';
import ContactEditForm from '../../contact-edit-form/ContactEditForm';
import { IUiInputListContacts } from '../../IUiInputListContacts';
import { IUiDictionaryItem } from '../../../../../../common/dictionaries/IUiDictionaryItem';
import Icon from '../../../../../../common/icon/Icon';
import { GetIcon } from '../ContactTypeCard';
import CombineStyles from '../../../../../../../utils/combineStyles';
import { ErrorNoUi } from '../../../../../../common/errors/no-ui/ErrorNoUi';
import Checkbox from '../../../../../../common/checkbox/Checkbox';
import { EOptionsContact } from '../../../../../../ad-content-editor/ad-modules-editor/AdModuleOptions';
import { IHighlightedItem } from '../../../../section-location/business-location-editor/business-location-listItem/ListItemBusinessLocation';

interface IProps {
  ui?: IUiInputListContacts;
  contactType?: IUiDictionaryItem;
  contacts?: Contact<string>[];
  data: Contact<string>;
  addressBlockMode?: boolean;
  isSelected?: boolean;
  isReadonly?: boolean;
  highlightedItem?: IHighlightedItem;
  onUpdate?: (updatedContact: Contact<string>) => void;
  onDelete?: (contactId: string) => void;
  onOptionSelect?: (optionId: EOptionsContact, contactId: string) => void;
  onToggle?: (locationId: string) => void;
}

export default function ContactsListItem(props: IProps) {
  const [editMode, setEditMode] = useState(false);
  const addressBlockMode = props.addressBlockMode == undefined ? false : props.addressBlockMode;
  const isReadonly = props.isReadonly == undefined ? false : props.isReadonly;
  const isSelectable = props.onToggle === undefined ? false : true;
  const isHighlighted = props.highlightedItem?.path.includes(props.data.id);
  const valueIsHighlighted = isHighlighted && props.highlightedItem?.path.includes("Contact");
  const descriptionIsHighlighted = isHighlighted && props.highlightedItem?.path.includes("Description");
  //---------------------------------------------------------------------------
  const onToggle = () => {
    if (!props.onToggle) return;
    props.onToggle(props.data.id);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedContact: Contact<string>) => {
    setEditMode(false);
    if (!props.onUpdate) return;
    props.onUpdate(updatedContact);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "InputListContacts.MenuContent.OptionEdit":
        setEditMode(true);
        break;
      case "InputListContacts.MenuContent.OptionDelete":
        if (addressBlockMode) {
          props.onOptionSelect && props.onOptionSelect("ContactExclude", props.data.id);
        } else {
          props.onDelete && props.onDelete(props.data.id);
        };
        break;
      case "InputListContacts.MenuContent.OptionRefresh":
        console.log(selectedOptionId, props.data.id)
        props.onOptionSelect && props.onOptionSelect("ContactRefresh", props.data.id);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui?.menuContent.options.forEach(option => {
    switch (option.id) {
      case "InputListContacts.MenuContent.OptionEdit":
        updatedOptions.push({
          ...option,
          iconId: "pencil"
        })
        break;
      case "InputListContacts.MenuContent.OptionDelete":
        updatedOptions.push({
          ...option,
          iconId: addressBlockMode ? "close" : "delete"
        })
        break;
      case "InputListContacts.MenuContent.OptionRefresh":
        updatedOptions.push({
          ...option,
          iconId: "refresh"
        })
        break;
    };
  });
  const optionElements = props.isReadonly ?
    undefined :
    updatedOptions.map(option => (
      <div
        key={option.id}
        className={CombineStyles([
          styles.option,
          addressBlockMode ? styles.addressBlock : ""
        ])}
        onClick={() => onOptionSelect(option.id)}>
        <Icon imageName={option.iconId ? option.iconId : ''} />
      </div>
    ));
  //---------------------------------------------------------------------------
  if (editMode) return (
    <div className={styles.editFormWrapper}>
      {(props.ui && props.contactType) ?
        <ContactEditForm
          ui={props.ui.editForm}
          contactType={props.contactType}
          contacts={props.contacts ? props.contacts : []}
          data={props.data}
          addressBlockMode={addressBlockMode}
          onClose={() => setEditMode(false)}
          onUpdate={onUpdate}
        /> :
        <ErrorNoUi />}
    </div>
  ); else return (
    <div
      className={CombineStyles([
        styles.container,
        isReadonly ? styles.readonly : "",
        props.isSelected ? styles.selected : ""
      ])}
      style={{
        border: isHighlighted ? `1px solid ${props.highlightedItem?.color}` : '',
        boxShadow: isHighlighted ? `0 0 3px 1px ${props.highlightedItem?.color}` : undefined,
        zIndex: isHighlighted ? 15 : undefined
      }}
      onClick={isSelectable ? onToggle : undefined}>

      {addressBlockMode &&
        <div
          className={styles.icon}
          style={{ color: isHighlighted ? props.highlightedItem?.color : '' }}>
          <Icon imageName={props.contactType ? GetIcon(props.contactType.id) : 'question'} />
        </div>}

      {isSelectable &&
        <div className={styles.icon}>
          <Checkbox checked={props.isSelected} />
        </div>}

      <div className={CombineStyles([
        styles.title,
        addressBlockMode ? styles.addressBlock : ""
      ])}>
        <div
          className={styles.value}
          style={{ color: valueIsHighlighted ? props.highlightedItem?.color : '' }}>
          {props.data.value}
        </div>
        <div
          className={styles.description}
          style={{ color: descriptionIsHighlighted ? props.highlightedItem?.color : '' }}>
          {props.data.description}
        </div>
      </div>

      {optionElements}

    </div>
  );
}