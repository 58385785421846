import CombineStyles from "../../../../utils/combineStyles";
import Icon from "../../icon/Icon";
import { TUiMenuOption } from "../../menu/menu-content/menu-option/TUiMenuOption";
import styles from "./ImageEditorOption.module.css";

interface IProps {
  ui: TUiMenuOption;
  onSelect: () => void;
}

export default function CropImageOption(props: IProps) {
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (!props.ui.disabled) {
      props.onSelect();
    };
  };
  //---------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    props.ui.disabled ? styles.disabled : ""
  ]);
  //---------------------------------------------------------------------------
  return (
    <div
      className={containerStyles}
      //style={{ fontSize: "2.8em" }}
      onClick={onSelect}
    >
      <Icon imageName="crop" />
    </div>
  );
}