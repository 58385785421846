import { useEffect, useState } from "react";
import InlineFormWrapper from "../../../../common/forms/inline-form-wrapper/InlineFormWrapper";
import InputText from "../../../../common/input-v2/input-text/InputText";
import { ITranslation } from "../../../common/directory-translator/translation-editor/ITranslation";
import TranslationEditor from "../../../common/directory-translator/translation-editor/TranslationEditor";
import { IUiContentItemEditform } from "./IUiContentItemEditform";
import { ContentItemEditFormState } from "./IContentItemEditFormState";
import { ContentItemTranslationDraft } from "../content-directory-translator/IContentItemTranslationDraft";
import { IUiOption } from "../../../../common/options/IUiOption";

interface IProps {
  ui: IUiContentItemEditform;
  data: ContentItemTranslationDraft;
  draftLocale: string;
  sourceLocale?: string;
  editModeIsEnabled: boolean;
  onUpdate: (updatedContentItem: ContentItemTranslationDraft) => void;
  onClose: () => void;
}

export default function ContentItemEditform(props: IProps) {
  const [state, setState] = useState<ContentItemEditFormState>();
  //--------------------------------------------------------------------------- State
  useEffect(() => {
    const newState = new ContentItemEditFormState({
      object: new ContentItemTranslationDraft(props.data),
      isNew: false,
      isUpdated: false,
      isValid: false
    });
    setState(newState);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const getSourceValue = (id: string, textProperty: any) => {
    let result = undefined;
    if (props.sourceLocale && props.sourceLocale != "i18n" && !!textProperty) {
      result = textProperty[props.sourceLocale];
    } else {
      result = id;
    };
    return result;
  };
  //---------------------------------------------------------------------------
  const onTextUpdate = (updatedTranslation: ITranslation) => {
    if (!state?.object) return;
    const updatedItem = new ContentItemTranslationDraft(
      state.object,
      { text: updatedTranslation });
    const updatedState = new ContentItemEditFormState(
      state,
      updatedItem
    );
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionClose":
      case "OptionCancel":
        props.onClose();
        break;
      //-----------------------------------------------------------------------
      case "OptionOk":
        if (!state?.object) return;
        if (!state?.isValid || !state?.isUpdated) return;
        props.onUpdate(state.object);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionClose":
        if (!props.editModeIsEnabled) {
          updatedOptions.push(option);
        };
        break;
      case "OptionCancel":
        if (props.editModeIsEnabled) {
          updatedOptions.push(option);
        };
        break;
      case "OptionOk":
        if (props.editModeIsEnabled) {
          updatedOptions.push({ ...option, isDisabled: !state?.isValid || !state?.isUpdated });
        };
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <InlineFormWrapper
      ui={{ ...props.ui.menuContent, options: updatedOptions }}
      onSelect={onOptionSelect}
      isUpdated={state?.isUpdated ? true : false}
      onClose={props.onClose}
    >
      <InputText
        ui={{ ...props.ui.inputId, disabled: true, hideHint: true }}
        data={props.data?.id ? props.data.id : ""}
      />
      <TranslationEditor
        ui={props.ui.textTranslationEditor}
        data={{
          elementId: props.data.id,
          elementPart: "Text",
          draftLocale: props.draftLocale,
          draftValue: state?.object?.text?.value, //props.data?.caption?.value,
          draftFallbackAccepted: !!state?.object?.text?.fallbackAccepted,
          sourceLocale: props.sourceLocale,
          sourceValue: getSourceValue(props.data.id, props.data.itemPublished?.text),
          publishedTranslations: props.data.itemPublished?.text
        }}
        editModeIsEnabled={props.editModeIsEnabled}
        onUpdate={onTextUpdate}
      />
    </InlineFormWrapper>
  );
}