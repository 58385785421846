import { useEffect, useState } from 'react';
import BusinessTagNode from './business-tag-node/BusinessTagNode';
import styles from './BusinessTagNodeSelector.module.css';
import { IBusinessTagsDictionaryDraftItem } from '../../../administrator-console/dictionaries/business-tags-editor/IBusinessTagsDictionary';

interface IProps {
  selectedNodePath?: string;
  currentItemPath?: string; // current node what we selecting parent for (it should be unavailable to select)
  rootNodes: IBusinessTagsDictionaryDraftItem[];
  onSelect: (selectedNodePath: string) => void;
}

export default function BusinessTagNodeSelector(props: IProps) {
  //const { adminConsoleContext } = useContext(AdminConsoleContext);
  const [selectedNodePath, setSelectedNodePath] = useState<string>();
  //---------------------------------------------------------------------------
  useEffect(() => {
    setSelectedNodePath(props.selectedNodePath);
  }, [props.selectedNodePath]);
  //---------------------------------------------------------------------------
  const onSelect = (updatedSelectedNodePath: string) => {
    //console.log(updatedSelectedNodePath)
    setSelectedNodePath(updatedSelectedNodePath);
    props.onSelect(updatedSelectedNodePath);
  };
  //---------------------------------------------------------------------------
  const rootTagElements = props.rootNodes?.map(item => (
    <BusinessTagNode
      key={item.id}
      data={item}
      currentItemPath={props.currentItemPath}
      selectedItemPath={selectedNodePath}
      collapsed={true}
      onSelect={onSelect}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      {rootTagElements}
    </div>
  );
}