import { useContext, useEffect, useState } from 'react';
import { IUiInteractiveForm } from '../../../../../common/forms/IUiInteractiveForm';
import PopupDialog from '../../../../../common/popup-v2/popup-dialog/PopupDialog';
import { Contact } from '../../contact/Contact';
import styles from './ContactsListPopup.module.css';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import { BusinessContext } from '../../../../../../context/business-context/BusinessContextProvider';
import { EditFormState } from '../../../../../common/edit-form/IEditFormState';
import { ClassCustomerBusiness } from '../../../ICustomerBusiness';
import GetCaption from '../../../../../common/functions/GetCaption';
import { IUiOption } from '../../../../../common/options/IUiOption';
import FormOptions from '../../../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../../../common/menu/menu-content/TUiMenuContent';
import ContactsListItem from '../contact-type-card/contacts-list-item/ContactsListItem';
import { ClassAdUnitStatus } from '../../../../../common/ad-modules/IAdUnit';

export interface IUiContactsListPopup extends IUiInteractiveForm {
}

export const tmpUiContactsListPopup: IUiContactsListPopup = {
  id: "ContactsListPopup",
  directoryId: "",
  elementType: "",
  caption: { en: "Contacts" },
  hint: { en: "List of contacts" },
  menuContent: {
    id: "ContactsListPopup.MenuContent",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionOk",
      directoryId: "",
      elementType: "",
      caption: { en: "Ok" },
      hint: undefined
    }, {
      id: "OptionCancel",
      directoryId: "",
      elementType: "",
      caption: { en: "Cancel" },
      hint: undefined
    }]
  }
};

interface IProps {
  ui: IUiContactsListPopup;
  data?: Contact<string>[];
  onClose: () => void;
  onUpdate: (updatedContacts: Contact<string>[]) => void;
}

export default function ContactsListPopup(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { businessContext } = useContext(BusinessContext);
  const [state, setState] = useState<EditFormState<string[]>>();
  const business = businessContext.businessState?.business as ClassCustomerBusiness;
  //---------------------------------------------------------------------------
  useEffect(() => {
    const initialState = new EditFormState<string[]>({
      object: props.data ? props.data.map(contact => contact.id) : [],
      isValid: true
    });
    setState(initialState);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onToggle = (contactId: string) => {
    if (!state?.object) return;
    let updatedContacts = state.object;
    if (state.object.includes(contactId)) {
      // Exclude selected location
      updatedContacts = updatedContacts.filter(item => item != contactId);
    } else {
      //-----------------------------------------------------------------------
      // Include selected location
      const updatedList = state.object.concat(contactId);
      // Create new list to keep the order
      updatedContacts = [];
      business.contacts.forEach(contact => {
        if (updatedList.includes(contact.id)) {
          updatedContacts.push(contact.id);
        };
      });
    };
    const updatedState = new EditFormState<string[]>(state, {
      object: updatedContacts,
      isValid: true
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    const selectedContacts = business.contacts?.filter(contact => state?.object?.includes(contact.id));
    const updatedContacts: Contact<string>[] = [];
    selectedContacts?.forEach(contact => {
      updatedContacts.push(new Contact<string>({
        ...contact,
        status: new ClassAdUnitStatus("Draft")
      }));
    });
    props.onUpdate(updatedContacts ? updatedContacts : []);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionOk":
        onConfirm();
        break;
      case "OptionCancel":
        props.onClose();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionOk":
        updatedOptions.push({
          ...option,
          isDisabled: !state?.isUpdated,
          isDefault: state?.isUpdated
        });
        break;
      case "OptionCancel":
        updatedOptions.push(option);
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  const contactsListItems = business.contacts?.map(contact => {
    const contactTypeData = appUiContext.dictionaries?.contactTypes?.find(contactType => contactType.id === contact.contactType);
    const isSelected = state?.object ? state.object.includes(contact.id) : false;
    return (
      <ContactsListItem
        key={contact.id}
        contactType={contactTypeData}
        data={contact}
        isSelected={isSelected}
        isReadonly={true}
        onToggle={onToggle}
      />);
  });
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id={props.ui.id}
      header={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      toolbarContent={formOptions}
      isModal={true}
      isDraggable={false}
      onClose={props.onClose}
    >
      <div className={styles.container}>
        {contactsListItems}
      </div>
    </PopupDialog>
  );
}