import { IAdUnitText } from '../../../../common/ad-modules/IAdModule';
import { ClassAdUnitStatus } from '../../../../common/ad-modules/IAdUnit';
import ChecklistItemWrapper from '../checklist-item-wrapper/ChecklistItemWrapper';

interface IProps {
  data: IAdUnitText;
  level?: number;
  isReadonly: boolean;
  onHover?: (isHovered: boolean) => void;
  onReview?: (updatedTextUnit: IAdUnitText) => void;
}

export default function ChecklistItemText(props: IProps) {
  if (!props.data.status) return null;
  //---------------------------------------------------------------------------
  const onHover = (isHovered: boolean) => {
    props.onHover && props.onHover(isHovered);
  };
  //---------------------------------------------------------------------------
  const onReview = (updatedStatus: ClassAdUnitStatus) => {
    console.log("ChecklistItemText.onReview");
    if (props.isReadonly) return;
    const updatedUnit: IAdUnitText = {
      ...props.data,
      status: updatedStatus
    };
    props.onReview?.(updatedUnit);
  };
  //---------------------------------------------------------------------------
  return (
    <ChecklistItemWrapper
      unitType='ContentText'
      status={props.data.status}
      isReadonly={props.isReadonly}
      onHover={onHover}
      caption={props.data.type}
      level={props.level}
      onReview={onReview}
    >
      {props.data.text}
    </ChecklistItemWrapper>
  );
}