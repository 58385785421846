import { useMsal } from "@azure/msal-react";
import DictionaryEditorWrapper from "../../../common/dictionary-editor-wrapper/DictionaryEditorWrapper";
import { useContext, useEffect, useState } from "react";
import { AppAuthContextStore } from "../../../../../context/app-auth-context/AppAuthContext";
import useBeforeUnload from "../../../../../hooks/useBeforeUnload";
import { IDictionaryEditorState } from "../../../common/dictionary-editor-models/IDictionaryEditorState";
import { IDictionaryDraft } from "../../../common/dictionary-draft-models/DictionaryDraft";
import { TUiBreadcrumb } from "../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { IDictionaryDraftFilter } from "../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter";
import getDictionaryDraft from "../common/functions/getDictionaryDraft";
import { ILockable } from "../../../../common/locks/TLocker";
import { pathAdminConsole, pathAdministratorConsole } from "../../../AdminConsole";
import { pathDictionaries } from "../../AdministratorConsole";
import { DraftItemStateEnum } from "../../../common/dictionary-draft-models/DraftItemStateEnum";
import { dictonaryDraftStatesFullDetails } from "../common/settings/dictonaryDraftStatesFullDetails";
import LocalesEditorNode, { ILocalesDictionaryDraftItem, LocalesDictionaryDraftItem } from "./locales-editor-node/LocalesEditorNode";
import { IPublishedDictionary } from "../../../common/dictionary-draft-models/IPublishedDictionary";
import LocaleEditForm from "./locale-edit-form/LocaleEditForm";
import updateDictionaryDraft from "../common/functions/updateDictionaryDraft";
import deleteDictionaryDraft from "../common/functions/deleteDictionaryDraft";
import publishDictionaryDraft from "../common/functions/publishDictionaryDraft";
import { useAbortController } from "../../../../../hooks/useAbortController";
import styles from './LocalesEditor.module.css';
import LocaleSelector from "./locale-selector/LocaleSelector";
import { IUiDictionaryItem } from "../../../../common/dictionaries/IUiDictionaryItem";
import { EHttpStatusCode } from "../../../../../utils/HttpStatusCodes";

interface ILocalesDictionaryDraft extends IDictionaryDraft<ILocalesDictionaryDraftItem> {
}
interface ILocalesEditorState extends IDictionaryEditorState<ILocalesDictionaryDraft> {
}
interface IlocalesEditorStateSpread {
  basePath?: string;
  numberOfApprovals?: number;
  filter?: IDictionaryDraftFilter;
  editModeIsEnabled?: boolean;
  sourceLocale?: string | undefined;
  breadcrumb?: TUiBreadcrumb | undefined;
  //-----------------------------------
  draft?: ILocalesDictionaryDraft;
  draftSessionInitial?: ILocalesDictionaryDraft;
  //-----------------------------------
  isUpdatedInSession?: boolean;
  isUpdated?: boolean;
  isValid?: boolean;
  isReadyToPublish?: boolean;
}
class LocalesEditorState implements ILocalesEditorState {
  basePath!: string;
  numberOfApprovals!: number;
  filter!: IDictionaryDraftFilter;
  editModeIsEnabled!: boolean;
  sourceLocale?: string | undefined;
  breadcrumb?: TUiBreadcrumb | undefined;
  //-----------------------------------
  draft!: ILocalesDictionaryDraft;
  draftSessionInitial!: ILocalesDictionaryDraft;
  //-----------------------------------
  isUpdatedInSession!: boolean;
  isUpdated!: boolean;
  isValid!: boolean;
  isReadyToPublish!: boolean;
  //---------------------------------------------------------------------------
  constructor(
    state: ILocalesEditorState,
    spread?: IlocalesEditorStateSpread
  ) {
    Object.assign(this, state, spread);
    this.checkChangesAndValidate();
  };
  //---------------------------------------------------------------------------
  checkChangesAndValidate() {
    console.log("checkChangesAndValidate")
    // 1. Check if draft is updated
    if (this.draft.items.length != this.draftSessionInitial.items.length) {
      this.isUpdated = true;
    } else {
      // Check items one by one
      const itemPropertiesToCheck = ['deleted', 'description', 'id', 'index', 'isActive', 'name', 'path', 'pathNew', 'pathOld'];
      type DraftItemObjectKey = keyof ILocalesDictionaryDraftItem;
      for (let j = 0; j < this.draft.items.length; j++) {
        for (let i = 0; i < itemPropertiesToCheck.length; i++) {
          const key = itemPropertiesToCheck[i] as DraftItemObjectKey;
          if (this.draft.items[j][key] !== this.draftSessionInitial.items[j][key]) {
            this.isUpdated = true;
          };
        };
      };
    };
    //-------------------------------------------------------------------------
    // 2. Check if draft is valid and ready to publish
    this.isReadyToPublish = false;
    //  - a. Current user can publish: this check is done by DictionaryEditorWrapper
    //  - b. There are changes compared to published version
    //  - c. No error draft states
    const dictionaryPublished = this.draft.publishedVersion as ILocalesDictionaryDraft;
    let isChanged = false;
    let isValid = true;
    if (this.draft.items.length != dictionaryPublished.items.length) {
      isChanged = true;
    };
    // Check validity and (optionally) whether items are changed
    for (let j = 0; j < this.draft.items.length; j++) {
      const item = new LocalesDictionaryDraftItem(this.draft.items[j]);
      // Check if it's valid first
      if (!item.isValid()) {
        isValid = false;
      } else {
        // Check if it's changed (only if the draft is not already considered as changed)
        if (!isChanged) {
          const publishedItem = dictionaryPublished.items.find(x => x.id == item.id);
          if (publishedItem) {
            isChanged =
              item.name !== publishedItem?.name ||
              item.description !== publishedItem?.description ||
              item.deleted !== publishedItem.deleted;
          } else {
            isChanged = true;
          };
          if (isChanged)
            break;
        };
      };
    };
    this.isValid = isValid;
    this.isReadyToPublish = isValid && isChanged;
  };
}

export default function LocalesEditor() {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const [state, setState] = useState<LocalesEditorState>();
  useBeforeUnload(state?.isUpdatedInSession);
  const [newItem, setNewItem] = useState(false);
  const [newLocale, setNewLocale] = useState<LocalesDictionaryDraftItem>();
  //const [cultures, setCultures] = useState<ICulture[]>();
  const [culturesDialogOpen, setCulturesDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const abortController = useAbortController("LocalesEditor");
  //--------------------------------------------------------------------------- Data, abortcontroller
  useEffect(() => {
    getDraft(false);
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //---------------------------------------------------------------------------
  /* useEffect(() => {
    retrieveCulturesV2();
  }, [appUiContext.locale]); */
  //---------------------------------------------------------------------------
  const newDraftState = (dictionaryDraft: any, editModeIsEnabled: boolean) => {
    let state = new LocalesEditorState({
      basePath: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}`,
      numberOfApprovals: 2,
      filter: {
        draftState: DraftItemStateEnum.All,
        stateGroups: dictonaryDraftStatesFullDetails,
        selectedGroups: dictonaryDraftStatesFullDetails.map(g => g.id), // switch on all the options (groups)
        searchById: false
      },
      editModeIsEnabled: editModeIsEnabled,
      breadcrumb: {
        id: dictionaryDraft.dictionaryId,
        caption: { en: `${dictionaryDraft.dictionaryId}` },
        path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}/${dictionaryDraft.dictionaryId}`
      },
      draft: dictionaryDraft,
      draftSessionInitial: JSON.parse(JSON.stringify(dictionaryDraft)),
      isUpdated: false,
      isUpdatedInSession: false,
      isValid: false,
      isReadyToPublish: false
    });
    return state;
  };
  //---------------------------------------------------------------------------
  const getDraft = (lockIsNeeded: boolean) => {
    console.log("LocalesEditor: getting draft...")
    setIsLoading(true);
    let controller = abortController.newController("getDictionaryDraft");
    getDictionaryDraft(
      instance, "SupportedLocales", lockIsNeeded, appAuthContext.config, controller.signal
    ).then(data => {
      let locker = (data as ILockable).locker;
      const editorState = newDraftState(data, !!(locker && locker?.statusCode == EHttpStatusCode.OK));
      !controller.aborted && setState(editorState);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onFilterUpdate = (updatedFilter: IDictionaryDraftFilter) => {
    setState(new LocalesEditorState(
      state as ILocalesEditorState,
      { filter: updatedFilter }
    ));
  };
  //---------------------------------------------------------------------------
  const onEnableEditMode = () => {
    getDraft(true);
  };
  //---------------------------------------------------------------------------
  const onDisableEditMode = () => {
    setState(new LocalesEditorState(
      state as ILocalesEditorState, {
      draft: state?.draftSessionInitial,
      editModeIsEnabled: false
    }));
  };
  //---------------------------------------------------------------------------
  const onSaveDraft = () => {
    if (!state?.draft) {
      console.error("Cannot save dictonary draft: state is not set");
      return;
    }
    setIsLoading(true);
    let controller = abortController.newController("updateDictionaryDraft");
    updateDictionaryDraft(
      instance,
      state.draft,
      appAuthContext.config
    ).then(dictionaryDraft => {
      !controller.aborted && setState(newDraftState(dictionaryDraft, true));
    }).catch(error =>
      console.error(error)
    ).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onDeleteDraft = () => {
    setIsLoading(true);
    let controller = abortController.newController("deleteDictionaryDraft");
    deleteDictionaryDraft(
      instance, "SupportedLocales", appAuthContext.config
    ).then(dictionaryDraft => {
      !controller.aborted && setState(newDraftState(dictionaryDraft, false));
    }).catch(error => console.error(error)
    ).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onPublishDraft = () => {
    if (!state?.draft) {
      console.error("Cannot save dictonary draft: state is not set");
      return;
    }
    setIsLoading(true);
    let controller = abortController.newController("publishDictionaryDraft");
    publishDictionaryDraft(
      instance, "SupportedLocales", appAuthContext.config
    ).then(dictionaryDraft => {
      !controller.aborted && setState(newDraftState(dictionaryDraft, false));
    }).catch(error => console.error(error)
    ).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedDraftItem: LocalesDictionaryDraftItem) => {
    if (state?.draft.items) {
      const updatedItems = state.draft.items.map(item => item.id == updatedDraftItem.id ? updatedDraftItem : item);
      const updatedState = new LocalesEditorState(state, {
        draft: {
          ...state.draft,
          items: updatedItems
        }
      });
      setState(updatedState);
      setNewItem(false);
    } else {
      console.error("New element is being updated, but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onHardDeleteItem = (deletedItemId: string) => {
    if (state) {
      const updatedItems = state.draft.items?.filter(item => item.id !== deletedItemId);
      const updatedState = new LocalesEditorState(state, {
        draft: {
          ...state.draft,
          items: updatedItems
        }
      });
      setState(updatedState);
    } else {
      console.error("New element is being deleted, but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onAddNewItem = () => {
    /* if (!cultures) {
      retrieveCulturesV2();
    }; */
    // Ask for selecting new locale from the list
    setCulturesDialogOpen(true);
  };
  //---------------------------------------------------------------------------
  /* const retrieveCulturesV2 = () => {
    if (!appUiContext.dictionaries?.locales) {
      return retrieveCultures();
    }
    //return retrieveCultures();
    let cultures = TCulture.getCultureList(appUiContext.dictionaries?.locales, appUiContext);
    //console.log("retrieveCulturesV2:", cultures);
    setCultures(cultures);
  } */
  //---------------------------------------------------------------------------
  /* const retrieveCultures = () => {
    console.log("LocalesEditor: getting cultures...")
    setIsLoading(true);
    let controller = abortController.newController("retrieveCultures");
    getCultures(
      instance, appAuthContext.config, controller.signal
    ).then(cultures => {
      !controller.aborted && setCultures(cultures);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  }; */
  //---------------------------------------------------------------------------
  /* const onCultureSelected = (selectedCulture: ICulture) => {
    if (state?.draft.items) {
      setCulturesDialogOpen(false);
      const newLocale = new LocalesDictionaryDraftItem({
        index: state.draft.items.length,
        id: selectedCulture.id,
        name: selectedCulture.name,
        deleted: false,
        draftState: 0
      });
      setNewLocale(newLocale);
      setNewItem(true);
    } else {
      console.error("New element is being added, but state is not set");
    };
  }; */
  //---------------------------------------------------------------------------
  const onLocaleSelect = (selectedLocale: IUiDictionaryItem) => {
    console.log(selectedLocale)
    if (state?.draft.items) {
      setCulturesDialogOpen(false);
      const newLocale = new LocalesDictionaryDraftItem({
        index: state.draft.items.length,
        id: selectedLocale.id,
        name: selectedLocale.name['en'],
        description: selectedLocale.name['en'],
        deleted: false,
        draftState: 0
      });
      setNewLocale(newLocale);
      setNewItem(true);
    };
  };
  //---------------------------------------------------------------------------
  const onNewItemAdded = (newItem: LocalesDictionaryDraftItem) => {
    if (state?.draft.items) {
      // Add new item to the list
      const updatedItems = state.draft.items.concat(newItem);
      const updatedState = new LocalesEditorState(state, {
        draft: {
          ...state.draft,
          items: updatedItems
        }
      });
      setState(updatedState);
      setNewItem(false);
    } else {
      console.error("New element is being added, but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "optionDisableEditMode":
        onDisableEditMode();
        break;
      case "optionSave":
        onSaveDraft();
        break;
      case "optionDraftDelete":
        onDeleteDraft();
        break;
      case "optionPublish":
        onPublishDraft();
        break;
      case "optionAdd":
        onAddNewItem();
        break;
      default:
        break;
    };
  };
  //---------------------------------------------------------------------------
  const filteredDictionaryItems = state?.draft.items?.filter(item => {
    let resultText = true;
    let resultState = true;
    //-----------------------------------------------------------------------
    // Filter by text
    if (state?.filter.text) {
      const searchString = state.filter.text.toLocaleLowerCase();
      const name = item.name ? item.name : "";
      resultText = item.id.toLocaleLowerCase().includes(searchString) || (name.toLocaleLowerCase().includes(searchString));
    };
    //-----------------------------------------------------------------------
    // Filter by state
    if (state && state.filter.draftState !== DraftItemStateEnum.All) {
      resultState = (item.draftState & state.filter.draftState) != DraftItemStateEnum.None;
      //console.log(item.id, state.filter.draftState, item.draftState)
    };
    //-----------------------------------------------------------------------
    return resultText && resultState;
  });
  const dictionaryItemsToDisplay = filteredDictionaryItems?.map(item => {
    let publishedVersion = (state?.draft.publishedVersion as IPublishedDictionary<ILocalesDictionaryDraftItem>)?.items.find(di => di.id == item.id);
    let itemData = new LocalesDictionaryDraftItem({
      ...item,
      publishedVersion: publishedVersion
    });
    return (
      <LocalesEditorNode
        key={item.id}
        stateGroups={state?.filter.stateGroups}
        data={itemData}
        editModeIsEnabled={state?.editModeIsEnabled}
        onUpdate={onUpdate}
        onDelete={() => onHardDeleteItem(item.id)}
      />);
  });
  //---------------------------------------------------------------------------
  if (culturesDialogOpen) return (
    <div className={styles.container}>
      <LocaleSelector
        selectedLocales={state?.draft.items.map(item => item.id)}
        showNativeNames={false}
        onSelect={onLocaleSelect}
        onClose={() => setCulturesDialogOpen(false)}
      />
      {/* <CulturesList
        //data={cultures}
        selectedLocales={state?.draft.items.map(item => item.id)}
        showNativeNames={true}
        onSelect={onCultureSelected}
        onClose={() => setCulturesDialogOpen(false)}
      /> */}
    </div>
  ); else return (
    <DictionaryEditorWrapper
      setup={{
        mode: "Editor",
        role: 'Administrator',
        entityType: "Dictionary"
      }}
      state={state}
      isLoading={isLoading}
      onFilterUpdate={onFilterUpdate}
      onEnterEditMode={onEnableEditMode}
      onOptionSelect={onOptionSelect}
    >
      {dictionaryItemsToDisplay}

      {newItem &&
        <LocaleEditForm
          data={newLocale}
          onUpdate={onNewItemAdded}
          onClose={() => setNewItem(false)}
        />}

    </DictionaryEditorWrapper>
  );
}