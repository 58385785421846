import { useContext } from 'react';
import GetHint from '../../common/functions/GetHint';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';
import styles from './SectionDetails.module.css';
import InputsGroupWrapper from '../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import InputText from '../../common/input-v2/input-text/InputText';
import GetName from '../../common/functions/GetName';
import React from 'react';
import { EditFormSection } from '../../common/console-layout/edit-form-sections/EditFormSection';
import { BusinessContext } from '../../../context/business-context/BusinessContextProvider';
import AdStatus from '../ad-status/AdStatus';
import { IUiSectionDetails } from './IUiSectionDetails';

interface IProps {
  ui: IUiSectionDetails;
}

export default function SectionDetails(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const adLanguageSet = businessContext.adLanguageSetState?.adLanguageSet;
  if (!adLanguageSet) return null;
  const adpageLocale = appUiContext.dictionaries?.supportedLocales?.find(item => item.id == adLanguageSet.localeId);
  const isSystem = businessContext.businessState?.business.businessType !== "Business";
  const adCampaign = businessContext.businessState?.business.adCampaigns.find(adCampaign => adCampaign.id == adLanguageSet.adCampaignId);
  //---------------------------------------------------------------------------
  const onNameUpdate = (updatedValue: string) => {
    const updatedName = updatedValue.length > 0 ? updatedValue : undefined;

    // AdLanguageSet should provide methods for updating name/description
    // according to current state: create ad module copies etc


    /* const updatedNameUnit: IAdUnitText = adLanguageSet.name ? {
      ...adLanguageSet.name as IAdUnitText,
      text: updatedName,
      status: new ClassAdUnitStatus("Draft")
    } : {
      type: "Text",
      text: updatedName,
      status: new ClassAdUnitStatus("Draft")
    };
    let updatedAdModules = adLanguageSet.adModules;
    //-------------------------------------------------------------------------
    // Update homepage photoblock header
    const photoBlock = adLanguageSet.adModules?.find(adModule => adModule.adModuleType === "Photoblock" && adModule.usage.isHomepage);
    if (photoBlock) {
      photoBlock.header = updatedNameUnit;
      updatedAdModules = adLanguageSet.adModules?.map(adModule => adModule.id == photoBlock.id ? photoBlock : adModule);
    };
    //-------------------------------------------------------------------------
    // Update search results module
    const searchModule = adLanguageSet.adModules?.find(adModule => adModule.adModuleType === "SearchResultsModule");
    if (searchModule) {
      searchModule.header = updatedNameUnit;
      updatedAdModules = adLanguageSet.adModules?.map(adModule => adModule.id == searchModule.id ? searchModule : adModule);
    };
    //-------------------------------------------------------------------------
    const updatedAdLanguageSet: IAdLanguageSet = {
      ...adLanguageSet,
      name: updatedNameUnit,
      adModules: updatedAdModules
    };
    //const detailsAreValid = isValid && descriptionIsValid;
    businessContextDispatch({
      type: "UpdateAdLanguageSet",
      adLanguageSet: updatedAdLanguageSet,
      isValid: validationState?.isValid
    }); */
  };
  //---------------------------------------------------------------------------
  const onDescriptionUpdate = (updatedValue: string, isValid: boolean) => {
    const updatedDescription = updatedValue.length > 0 ? updatedValue : undefined;
    /* const updatedDescriptionUnit: IAdUnitText = adLanguageSet.description ? {
      ...adLanguageSet.description as IAdUnitText,
      text: updatedDescription,
      status: new ClassAdUnitStatus("Draft")
    } : {
      type: "Description",
      text: updatedDescription,
      status: new ClassAdUnitStatus("Draft")
    };
    let updatedAdModules = adLanguageSet.adModules;
    //-------------------------------------------------------------------------
    // Update photoblock paragraph
    const photoBlock = adLanguageSet.adModules?.find(adModule => adModule.adModuleType === "Photoblock" && adModule.usage.isHomepage);
    if (photoBlock) {
      photoBlock.description = updatedDescriptionUnit;
      updatedAdModules = adLanguageSet.adModules?.map(adModule => adModule.id == photoBlock.id ? photoBlock : adModule);
    };
    //-------------------------------------------------------------------------
    // Update search results module
    const searchModule = adLanguageSet.adModules?.find(adModule => adModule.adModuleType === "SearchResultsModule");
    if (searchModule) {
      searchModule.description = updatedDescriptionUnit;
      updatedAdModules = adLanguageSet.adModules?.map(adModule => adModule.id == searchModule.id ? searchModule : adModule);
    };
    //-------------------------------------------------------------------------
    const updatedAdLanguageSet: IAdLanguageSet = {
      ...adLanguageSet,
      description: updatedDescriptionUnit,
      adModules: updatedAdModules
    };
    //const detailsAreValid = isValid && descriptionIsValid;
    businessContextDispatch({
      type: "UpdateAdLanguageSet",
      adLanguageSet: updatedAdLanguageSet,
      isValid: validationState?.isValid
    }); */
  };
  //---------------------------------------------------------------------------
  return (
    <EditFormSection
      id={props.ui.id}
      caption={GetHint(appUiContext, props.ui.id, props.ui.hint)}
    >
      <div className={styles.container}>

        <AdStatus data={adLanguageSet?.status} />

        <div className={styles.form}>
          <InputsGroupWrapper>

            {/* Ad Id */}
            <InputText
              ui={{ ...props.ui.inputAdPageId, disabled: true }}
              data={adLanguageSet.id}
            />

            {/* Ad Language */}
            <InputText
              ui={{ ...props.ui.inputAdPageLanguage, disabled: true }}
              data={GetName(appUiContext, adLanguageSet.localeId, adpageLocale?.name)}
            />

            {/* Ad Campaign rank: NOTE: display rates as well! */}
            <InputText
              ui={{
                id: "AdCampaign",
                directoryId: "",
                elementType: "",
                caption: { en: "Ad Campaign" },
                hint: { en: "Ad Campaign" },
                placeholder: undefined,
                disabled: true
              }}
              data={adCampaign?.name}
            />

          </InputsGroupWrapper>

        </div>
      </div>
    </EditFormSection>
  );
}