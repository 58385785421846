import { useContext } from 'react';
import { IUiOption } from '../IUiOption';
import styles from './OptionLink.module.css';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import GetCaption from '../../functions/GetCaption';
import GetHint from '../../functions/GetHint';
import CombineStyles from '../../../../utils/combineStyles';

interface IProps {
  ui: IUiOption;
  onSelect?: (optionId: string) => void;
}

export default function OptionLink(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (props.ui.isDisabled) return;
    props.onSelect && props.onSelect(props.ui.id);
  };
  //---------------------------------------------------------------------------
  return (
    <button
      id={props.ui.id}
      title={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      className={CombineStyles([
        styles.container,
        props.ui.isDisabled ? styles.disabled : ''
      ])}
      onClick={onSelect}>
      {GetCaption(appUiContext, props.ui.id, props.ui.caption)}
    </button>
  );
}