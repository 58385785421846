import { EDraftItemState } from "../EDraftItemState";
import { IDirectoryDraftItemStateGroup } from "./IDirectoryDraftItemStateGroup";

export const directoryTranslationDraftStates: IDirectoryDraftItemStateGroup[] = [{
  id: 'nothing',
  name: "No changes/errors",
  states: [{
    draftState: EDraftItemState.IsNothing,
    iconId: 'circle'
  }, {
    draftState: EDraftItemState.HasNothing,
    iconId: 'circleEmpty'
  }]
}, {
  id: 'editedt',
  name: "Edited Translation",
  color: 'mediumspringgreen',
  states: [{
    draftState: EDraftItemState.IsTranslationEdited,
    iconId: 'circle'
  }, {
    draftState: EDraftItemState.HasTranslationEdited,
    iconId: 'circleEmpty',
    fontColor: 'inherit'
  }]
}, {
  id: 'fallbackAccepted',
  name: "Fallback accepted",
  color: 'slateblue',
  states: [{
    draftState: EDraftItemState.IsFallbackAccepted,
    iconId: 'circle',
    fontColor: 'undefined'
  }, {
    draftState: EDraftItemState.HasFallbackAccepted,
    iconId: 'circleEmpty',
  }]
}, {
  id: 'notranslation',
  name: "No Translation",
  color: 'orange',
  states: [{
    draftState: EDraftItemState.IsNoTranslation,
    iconId: 'circle'
  }, {
    draftState: EDraftItemState.HasNoTranslation,
    iconId: 'circleEmpty',
    fontColor: 'undefined'
  }]
}]