import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathCreateSystemBusinessRegionalPath } from "../../../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../../../utils/common";
import { apiPostPrivate, checkResponse, IApiResponse } from "../../../../../../utils/api";
import { EHttpStatusCode } from "../../../../../../utils/HttpStatusCodes";

interface IProps {
  msalIntance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  businessId?: string;
  path: string;
  oldPathToReplace?: string;
  abortSignal?: AbortSignal;
}

export default async function createBusinessPathAsync(props: IProps): Promise<void | IApiResponse> {
  if (!props.authConfig) throw new Error("createBusinessPathAsync: auth config is not provided");
  if (!props.businessId) throw new Error("createBusinessPathAsync: business Id is not provided");
  //---------------------------------------------------------------------------
  const baseUri = pathCreateSystemBusinessRegionalPath;
  const uriAddition = props.oldPathToReplace ? `?replace=${props.oldPathToReplace}` : "";
  const uri = `${apiBasePath}${stringFormatter(baseUri + uriAddition, [
    props.businessId,
    props.path
  ])}`;
  const response = await apiPostPrivate(
    props.msalIntance,
    props.authConfig,
    uri,
    undefined,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------  
  checkResponse(response, "getNewBusinessAsync", props.abortSignal?.aborted, [
    EHttpStatusCode.OK, // already exists and belongs to the same business
    EHttpStatusCode.Created, // created
    EHttpStatusCode.Conflict // already exists and belongs to another business
  ]);
  return response;
}