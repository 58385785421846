import { IPublicClientApplication } from "@azure/msal-browser";
import { IAppAuthContext, IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { apiPostPrivate, checkResponse } from "../../../../utils/api";
import { apiBasePath, mergeImageBlocksAsync, uploadImageSizeAsync } from "../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../utils/common";
import { ImageTransformation } from "../image-editor-history/ImageEditorHistory";
import { EImageLoadingState, EImageSize, TImageData } from "../TImageData";
import { TImageEditorDispatch } from "./ImageEditorContextProvider";
import { EImageEditorContextUpdate } from "./ImageEditorContextReducer";
import { ImageBlock } from "../ImageLoader";
import { v4 as createNewGuid } from "uuid";
import { EHttpStatusCode } from "../../../../utils/HttpStatusCodes";
import { TImageUsage } from "../TImageUsage";
//----------------------------------------------------------------------------------
export type ImageUploadEndCallback = (imageData: TImageData) => void;
//export type ImageEditorSessionCallback = (session: ImageEditorSession) => void;
//----------------------------------------------------------------------------------
export type ImageEditorSessionCallback = (imageData: TImageData) => void;
//----------------------------------------------------------------------------------
export type CreateSessionMethod = (
  source: TImageData,
  inLibrary: boolean,
  initialImageData: TImageData,
  abortSignal?: AbortSignal) => Promise<ImageEditorSession>;
//----------------------------------------------------------------------------------
export class ImageEditorSession {
  removeAfterUpload: boolean;
  objectIds: string[];
  //targetUsage?: TImageUsage;
  initialImageData?: TImageData;
  imageData: TImageData;
  changesToUpload?: TImageData; // description, if changed and blob, if saved
  imageId: string;
  idFromLibrary?: boolean;
  placeholderHint?: string;
  loadingState?: EImageLoadingState;
  loadErrorString?: string;

  transform?: ImageTransformation;
  transforms: ImageTransformation[];
  transformIndex: number;
  firstTransformIndex: number;

  inLibrary?: boolean; // some logic is different if image is opened from library

  //resetToDefaultImage: EResetToDefaultImage = EResetToDefaultImage.None;
  resetToDefaultImage: boolean = false;
  imageRemoved?: boolean;
  //--buttons------------------------------------------------------------
  canRemoveImage?: boolean;
  canLoadNewImage: boolean = true;

  private _editedImageBlob?: Blob;
  private _editedImageUrl?: string;
  get editedImageUrl(): string | undefined {
    return this._editedImageUrl;
  }
  get editedImageBlob(): Blob | undefined {
    return this._editedImageBlob;
  }
  private set editedImageBlob(value: Blob | undefined) {
    this._editedImageBlob = value;
    if (this._editedImageBlob)
      this._editedImageUrl = URL.createObjectURL(this._editedImageBlob);
    else
      this._editedImageUrl = undefined;
    this.imageData.editedImageUrl = this._editedImageUrl;
  }

  imageBlocks?: ImageBlock[];

  dispatch?: TImageEditorDispatch;
  onUploadEnd?: ImageUploadEndCallback;
  onUrlAndBlobReady?: ImageEditorSessionCallback;

  constructor(objectIds: string[], imageId: string, targetUsage?: TImageUsage, imageData?: TImageData, placeholderHint?: string) {
    this.objectIds = objectIds;
    //this.targetUsage = targetUsage;
    this.imageId = imageId;
    if (imageData) {
      this.imageData = new TImageData(imageData);
      this.initialImageData = new TImageData(imageData);
    }
    else {
      this.imageData = new TImageData({ id: imageId });
      this.initialImageData = undefined;
    }
    this.idFromLibrary = this.imageData.fromLibrary;
    //this.imageData = new TImageData(imageData);
    //this.initialImageData = new TImageData(imageData);
    this.placeholderHint = placeholderHint;
    this.transforms = [];
    this.transformIndex = -1;
    if (this.initialImageData)
      this.firstTransformIndex = 1;
    else
      this.firstTransformIndex = 0;
    // if (this.imageData?.imageUri)
    //   this.firstTransformIndex = 1;
    // else
    //   this.firstTransformIndex = 0;
    this.removeAfterUpload = false;
  }

  static NewSession(
    objectIds: string[],
    targetUsage: TImageUsage,
    imageId: string,
    newGuid: boolean,
    imageData?: TImageData) {
    console.log("NewSession.imageData:", imageData);
    let session = new ImageEditorSession(objectIds, imageId, targetUsage, imageData);
    //if (newGuid)
    //session.loadingState = EImageLoadingState.NewGuid;
    return session;
  }
  //-----------------------------------------------------------------------
  static async createForLocalImage(
    fileSource: TImageData,
    inLibrary: boolean,
    initialImageData?: TImageData,
    abortSignal?: AbortSignal) {
    try {
      if (abortSignal?.aborted)
        throw Error("Aborted");
      let file = fileSource.file;
      if (!file)
        throw Error("File is undefined");
      fileSource.file = undefined;
      let imageData = new TImageData({
        id: createNewGuid(),
        fileName: file.name,
        contentType: file.type,
        contentLength: file.size,
        lastModified: new Date(),
      });
      let session = new ImageEditorSession([imageData.id], imageData.id, undefined, imageData);
      session.firstTransformIndex = 0;
      if (initialImageData) {
        session.initialImageData = new TImageData(initialImageData);
      }
      let url = URL.createObjectURL(file);
      console.log("ImageEditorSession.createForLocalImage.url:", url);
      await session.loadBitmapAsync(url, imageData, false);
      if (abortSignal?.aborted)
        throw "Aborted";
      console.log("ImageEditorSession.loadBitmapAsync:", session);
      return session;
    }
    catch (error) {
      console.log("ImageEditorSession.createForLocalImage:", error);
      throw (error);
    }
  }
  //-----------------------------------------------------------------------
  static async createForImageCopy(
    source: TImageData,
    inLibrary: boolean,
    initialImageData?: TImageData,
    abortSignal?: AbortSignal
  ) {
    try {
      let url = await source.getUrlPromise("Large", abortSignal);
      console.log("ImageEditorSession.createForImageCopy.url:", url);
      if (abortSignal?.aborted)
        throw "Aborted";
      let imageData = new TImageData(source);
      imageData.id = createNewGuid();
      imageData.imageUri = undefined;
      imageData.fromLibrary = undefined;
      imageData.imageUsage = undefined;
      imageData.lastModified = new Date();
      imageData.imageStatus = "Draft";
      imageData.clearSas();
      if (!source.description || source.description.includes("Copy of"))
        imageData.description = `Copy of ${source.id}`;
      else
        imageData.description = `Copy of ${source.description}`;
      let session = new ImageEditorSession([imageData.id], imageData.id, undefined, imageData);
      //session.initialImageData = new TImageData(imageData);
      // if (initialImageData) {
      //   session.initialImageData = new TImageData(initialImageData);
      // }
      session.firstTransformIndex = inLibrary ? 0 : 1;
      await session.loadBitmapAsync(url, imageData, false);
      if (abortSignal?.aborted)
        throw "Aborted";
      console.log("ImageEditorSession.loadBitmapAsync:", session);
      return session;
    }
    catch (error) {
      console.log("ImageEditorSession.createForImageCopy:", error);
      throw (error);
    }
  }
  //-----------------------------------------------------------------------
  static async createForImageEdit(
    source: TImageData,
    inLibrary: boolean,
    initialImageData?: TImageData,
    abortSignal?: AbortSignal) {
    try {
      let url = await source.getUrlPromise("Large", abortSignal);
      //console.log("ImageEditorSession.createForImageEdit.url:", url);
      if (abortSignal?.aborted)
        throw "Aborted";
      let session = new ImageEditorSession([source.id], source.id, undefined, source);
      if (initialImageData) {
        session.initialImageData = new TImageData(initialImageData);
      }
      await session.loadBitmapAsync(url, session.imageData, false);
      if (abortSignal?.aborted)
        throw "Aborted";
      //console.log("ImageEditorSession.loadBitmapAsync:", session);
      return session;
    }
    catch (error) {
      console.error("ImageEditorSession.createForImageCopy:", error);
      throw (error);
    }
  }
  //-----------------------------------------------------------------------
  loadImage() {
    console.log("Session.loadImage:", this.imageData)
    this.loadOtherImage(this.imageData, true);
  }

  loadImageFromUrl(imageUrl: string, newGuid: boolean, fileName?: string, contentType?: string, description?: string) {
    if (this.loadingState == EImageLoadingState.Downloading) {
      return;
    }
    // if current imageData is readOnly - create new imageData in transform
    // if not - use current imageData
    let imageData: TImageData = this.transform?.imageData ? this.transform.imageData : this.imageData;
    //if (!this.imageData.isReadOnly) {
    if (newGuid) {
      imageData = new TImageData(imageData, {
        id: createNewGuid(),
        imageStatus: "Draft",
        usage: undefined,
      });
    }/*
    else {
      imageData = this.imageData;
    }*/
    imageData.fileName = fileName;
    imageData.contentType = contentType;
    imageData.description = description;
    this.loadBitmap(imageUrl, imageData);
  }

  loadOtherImage(imageData: TImageData, asLink: boolean) {
    if (this.loadingState == EImageLoadingState.Downloading) {
      return;
    }
    // if we need a copy - only url and description matters from source imageData
    if (!asLink) {
      imageData.getUrlCallback("Large", (url) => {
        url && this.loadImageFromUrl(url, true, imageData.fileName, imageData.contentType, imageData.description)
      });
      return;
    }
    imageData.getUrlCallback("Large", (url) => {
      if (url)
        this.loadBitmap(url, imageData, asLink);
    });
  }
  //--------------------------------------------------------------------------
  private loadBitmap(imageUri: string, imageData: TImageData, asLink?: boolean) {

    let bitmap: HTMLImageElement = new Image();   // Create new img element
    bitmap.crossOrigin = "anonymous"; // Handle CORS issues
    //-------------------------------------------------------------------------
    const onload = () => {
      //console.log("ImageEditorSession.loadImageFromUri.onImageLoaded");
      imageData.imageUri = bitmap?.src;
      imageData.widthPx = bitmap?.width;
      imageData.heightPx = bitmap?.height;
      if (asLink) {
        this.idFromLibrary = imageData.fromLibrary;
      }
      if (bitmap) {
        let transform = ImageTransformation.getInitialTransformation(bitmap, imageData, asLink);
        if (imageData.description && (asLink == true || !this.imageData.description))
          this.imageData.description = imageData.description;
        this.addNewTransform(transform);
        this.setLoadingState(EImageLoadingState.Opened);
      }
    };
    const onerror = (ev: Event | string) => {
      console.log("ImageEditorSession.loadBitmap.onError.event:", ev);
      let uri = imageUri;
      let i = uri.indexOf('?');
      if (i >= 0)
        uri = uri.substring(0, i);
      this.loadErrorString = `Image not found`;
      this.setLoadingState(EImageLoadingState.DownloadError);
    }
    bitmap.onload = onload.bind(this);
    bitmap.onerror = onerror.bind(this);
    this.loadErrorString = undefined;
    bitmap.src = imageUri;
    this.setLoadingState(EImageLoadingState.Downloading);
  }
  //--------------------------------------------------------------------------
  async loadBitmapAsync(imageUri: string, imageData: TImageData, copyDescription: boolean) {
    return new Promise<void>((resolve, reject) => {
      let bitmap: HTMLImageElement = new Image();   // Create new img element
      bitmap.crossOrigin = "anonymous"; // Handle CORS issues
      //-------------------------------------------------------------------------
      const onload = () => {
        //console.log("ImageEditorSession.loadImageFromUri.onImageLoaded");
        imageData.imageUri = bitmap?.src;
        imageData.widthPx = bitmap?.width;
        imageData.heightPx = bitmap?.height;
        if (bitmap) {
          let transform = ImageTransformation.getInitialTransformation(bitmap, imageData);
          if (imageData.description && copyDescription)
            this.imageData.description = imageData.description;
          this.addNewTransform(transform);
          this.setLoadingState(EImageLoadingState.Opened);
        }
        resolve();
      };
      const onerror = (ev: Event | string) => {
        console.log("ImageEditorSession.loadBitmap.onError.event:", ev);
        let uri = imageUri;
        let i = uri.indexOf('?');
        if (i >= 0)
          uri = uri.substring(0, i);
        this.loadErrorString = `Image not found`;
        this.setLoadingState(EImageLoadingState.DownloadError);
        reject();
      }
      bitmap.onload = onload.bind(this);
      bitmap.onerror = onerror.bind(this);
      this.loadErrorString = undefined;
      bitmap.src = imageUri;
      this.setLoadingState(EImageLoadingState.Downloading);
    });
  }

  removeImage(defaultImageData?: TImageData) {
    this.editedImageBlob = undefined;
    this.imageData = new TImageData(defaultImageData);
    if (this.imageData.id) {
      // if valid defaultImageData was provided -
      // set session id to imageData.id
      if (this.imageId != this.imageData.id)
        this.imageId = this.imageData.id;
    }
    else if (this.imageId && !this.idFromLibrary) {
      // if imadeId was created on frontend - not from user image library
      this.imageData.id = this.imageId;
    }
    else {
      // image was from library: new id
      this.imageData.id = createNewGuid();
      this.imageId = this.imageData.id;
      this.imageData.fromLibrary = false;
      this.idFromLibrary = false;
    }
    this.transforms = [];
    this.transform = undefined;
    this.firstTransformIndex = this.initialImageData ? 1 : 0;
    this.firstTransformIndex = 0;
    this.setTransformIndex(-1);
    this.loadingState = undefined;
    //this.loadImage();
    this.resetToDefaultImage = defaultImageData != undefined;
    this.imageRemoved = true;
    this.dispatch && this.dispatch({
      type: "UpdateSession",
      update: EImageEditorContextUpdate.ImageRemoved,
      session: this
    });

    //this.resetToDefaultImage = EResetToDefaultImage.Request;
  }

  get imageExists(): boolean {
    return this.transformIndex >= 0;
  }

  get imageIsChanged(): boolean {
    let logging = false;
    if (logging) {
      console.log("imageIsChanged.transformIndex:", this.transformIndex);
      console.log("imageIsChanged.firstTransformIndex:", this.firstTransformIndex);
      console.log("imageIsChanged.resetToDefaultImage:", this.resetToDefaultImage);
      console.log("imageIsChanged.imageRemoved:", this.imageRemoved);
      console.log("imageIsChanged.initialImageData:", this.initialImageData);
    }
    let result =
      this.transformIndex >= this.firstTransformIndex ||
      this.resetToDefaultImage ||
      (this.initialImageData != undefined && this.imageRemoved == true);
    logging && console.log("imageIsChanged.return:", result);
    return result;
  }

  get descriptionIsChanged(): boolean {
    //console.log("descriptionIsChanged.imageData.description:", this.imageData.description);
    //console.log("descriptionIsChanged.initialImageData.description:", this.initialImageData?.description);
    let result = this.imageData.description != this.initialImageData?.description;
    //console.log("descriptionIsChanged.return:", result);
    return result;
  }

  get saveNeeded(): boolean {
    return this.imageIsChanged || this.descriptionIsChanged;
  }

  get uploadNeeded(): boolean {
    return this.imageIsChanged || this.descriptionIsChanged || this._editedImageBlob != undefined;
  }

  get imageSaved(): boolean {
    //if (this._editedImageBlob == undefined && this._editedImageUrl == undefined)
    if (this._editedImageBlob == undefined)
      return false;
    // if (this.transform) {
    //   return (this.transform.editedImageBlob == this._editedImageBlob &&
    //     this.transform.editedImageUrl == this.editedImageUrl);
    // }
    return true;
  }
  //---------------------------------------------------------------------------
  get undoEnabled(): boolean {
    return this.transforms.length > 0 && this.transformIndex > 0
  }
  //---------------------------------------------------------------------------
  get redoEnabled(): boolean {
    return this.transforms.length > 0 && this.transformIndex < this.transforms.length - 1
  }
  //---------------------------------------------------------------------------
  get isCurrentStateReadOnly(): boolean {
    //if (this.imageData.isPublic === true)
    //return true;
    if (this.transform && this.transform.imageData)
      return this.transform.imageData.isPublic === true
        || this.transform.imageData.isSystemImage;
    return false;
  }
  //---------------------------------------------------------------------------
  setLoadingState(value: EImageLoadingState | undefined) {
    //console.log("ImageEditorSession.setLoadingState:", value);
    this.loadingState = value;
    this.dispatch && this.dispatch({
      type: "UpdateSession",
      update: EImageEditorContextUpdate.LoadingStateChanged,
      session: this
    });
  }
  //---------------------------------------------------------------------------
  setTransformIndex(value: number) {
    //console.log(`session.setTransformIndex(${value}).transforms:`, this.transforms);
    //console.log(`session.setTransformIndex(${value}).firstTransformIndex:`, this.firstTransformIndex);
    this.transformIndex = value;
    if (this.transforms.length > 0 && value >= 0 && value < this.transforms.length) {
      this.transform = this.transforms[value];
    }
    else {
      this.transform = undefined;
    }
    if (this.transforms.length > 0 && this.imageRemoved == true) {
      this.imageRemoved = false;
    }
    this.dispatch && this.dispatch({
      type: "UpdateSession",
      update: EImageEditorContextUpdate.ImageTransformed,
      session: this
    });
  }
  //---------------------------------------------------------------------------
  addNewTransform(newTransform: ImageTransformation): number {
    this.transforms.push(newTransform);
    this.setTransformIndex(this.transforms.length - 1);
    // switch (this.resetToDefaultImage) {
    //   case EResetToDefaultImage.None:
    //     if (this.imageIsChanged)
    //       this.imageData.id = this.imageId;
    //     break;
    //   case EResetToDefaultImage.Request:
    //     this.resetToDefaultImage = EResetToDefaultImage.Done;
    //     //this.imageData.id = undefined;
    //     break;
    //   case EResetToDefaultImage.Done:
    //     this.resetToDefaultImage = EResetToDefaultImage.None;
    //     //if (this.imageIsChanged)
    //       //this.imageData.id = this.imageId;
    //     break;
    // }
    return this.transformIndex;
  }
  //---------------------------------------------------------------------------
  setDescription(value: string | undefined) {
    this.imageData.description = value;
    this.dispatch && this.dispatch({
      type: "UpdateSession",
      update: EImageEditorContextUpdate.DescriptionChanged,
      session: this
    });
  }
  //---------------------------------------------------------------------------
  saveCurrentDescription() {
    //this.initialImageData.description = this.imageData.description;
    this.imageData.lastModified = new Date();
    this.callUrlAndBlobReady();
    this.dispatch && this.dispatch({
      type: "UpdateSession",
      update: EImageEditorContextUpdate.UrlAndBlobReady,
      session: this
    });
  }
  //---------------------------------------------------------------------------
  private callUrlAndBlobReady() {
    if (this.onUrlAndBlobReady) {
      //this.imageData.addUsage(this.targetUsage);
      this.onUrlAndBlobReady(this.imageData);
    }
  }
  //---------------------------------------------------------------------------
  setToImageFromLibraryAsLink() {
    if (!this.transform || !this.transform.imageData.id)
      return;
    try {
      this.imageData = this.transform.imageData;
      this.imageId = this.imageData.id as string;
      this.idFromLibrary = true;
      this.editedImageBlob = undefined;
      //this.initialImageData = new TImageData(this.imageData); //{ ...this.imageData };
      this.changesToUpload = new TImageData(this.imageData); //{ ...this.imageData };
      console.log('session.setImageReferensFromTransform.clear undo history');
      this.transforms = [this.transform];
      this.setTransformIndex(0);
      //this.firstTransformIndex = 1;
      console.log("Session.setDataUrlAndBlob.dispatch.EImageEditorContextUpdate.UrlAndBlobReady");
      this.dispatch && this.dispatch({
        type: "UpdateSession",
        update: EImageEditorContextUpdate.UrlAndBlobReady,
        session: this
      });
      console.log("Session.setDataUrlAndBlob.this.onUrlAndBlobReady");
      console.log("Session.setDataUrlAndBlob.this:", this);
      this.callUrlAndBlobReady();
    }
    finally {

    }
  }
  //---------------------------------------------------------------------------
  setBlobFromCanvas(blob: Blob, storageEndpoint: string, userId?: string) {
    console.log("Session.setBlobFromCanvas");
    if (!this.resetToDefaultImage) {
      // standard save procedure - all changes will be saved
      if (!this.transform) {
        console.error("Could not upload image because current transform is not set");
        return;
      }
      let description = this.imageData.description;
      this.imageData = this.transform.imageData;
      this.imageData.userId = userId;
      this.imageData.description = description;
      this.imageId = this.imageData.id as string;
      this.editedImageBlob = blob;
      this.imageData.dotExtension = "";//".png";
      this.imageData.contentType = blob.type;//"image/png";
      this.imageData.contentLength = blob.size;
      this.imageData.imageStatus = "Draft";
      let id = this.imageData.id as string;
      //old - https://rikiwikiusrdev3.blob.core.windows.net/img/307f/307fc7d2-f191-4aff-8f92-59b7026142d7.png"
      //let folder = id.substring(0, 4);
      let uri = `${storageEndpoint}private/${id}.Large`;//.png`;
      this.imageData.imageUri = uri;
      // set current transform to imageData
      this.imageData.widthPx = Math.round(this.transform.getWidthRotated());
      this.imageData.heightPx = Math.round(this.transform.getHeightRotated());
      this.imageData.lastModified = new Date();
      // set current image data params to initialImageData
      //this.initialImageData = new TImageData(this.imageData); //{ ...this.imageData };
      this.changesToUpload = new TImageData(this.imageData); //{ ...this.imageData };
      // leave only current transform in transforms array
      console.log('session.setBlobFromCanvas.clear undo history');
      this.transforms = [this.transform];
      this.setTransformIndex(0);
      //this.firstTransformIndex = 1;
      console.log("Session.setBlobFromCanvas.this:", this);
    }
    else // !this.resetToDefaultImage
    {
      // imageData and initialImagedata was restored to default image
      // with no id and imageUri only
      this.changesToUpload = new TImageData(undefined);//{};
    }

    console.log("Session.setDataUrlAndBlob.dispatch.EImageEditorContextUpdate.UrlAndBlobReady");
    this.dispatch && this.dispatch({
      type: "UpdateSession",
      update: EImageEditorContextUpdate.UrlAndBlobReady,
      session: this
    });

    console.log("Session.setDataUrlAndBlob.this.onUrlAndBlobReady");
    this.callUrlAndBlobReady();
  }
  //---------------------------------------------------------------------------
  discardChanges() {
    this.imageData = new TImageData(this.initialImageData); //{ ...this.initialImageData };
    this.changesToUpload = undefined;
    //this.editedImageDataUrl = undefined;
    //console.log('session.setDataUrlAndBlob.clear undo history');

    if (this.transforms.length > 0) {
      this.transforms = [this.transforms[0]];
      //this.firstTransformIndex = 1;
      this.setTransformIndex(0);
    }
    else {
      this.transforms = [];
      this.transform = undefined;
      //this.firstTransformIndex = 0;
      this.setTransformIndex(-1);
    }
  }
  //---------------------------------------------------------------------------
  onBlockUploadEnd(imageBlock: ImageBlock, session: ImageEditorSession, config: IAuthConfig, instance: IPublicClientApplication) {
    if (!session.imageBlocks) {
      console.error(`ImageSession [${session.imageId}].onBlockUploadEnd: imageBlocks is undefined`, imageBlock);
      return;
    }
    if (!session._editedImageBlob) {
      console.error(`ImageSession [${session.imageId}].onBlockUploadEnd: blob is undefined`);
      return;
    }
    let blockIds: string[] = [];
    session.imageBlocks.forEach(block => {
      if (block.blockId) {
        blockIds.push(block.blockId);
      }
    });
    // if all block ids already assigned: merge blocks to final blob
    if (blockIds.length != session.imageBlocks.length)
      return;

    let dotExtension = `.${session._editedImageBlob?.type.split("/")[1]}`;
    apiPostPrivate(
      instance, config,
      `${apiBasePath}${stringFormatter(mergeImageBlocksAsync, [session.imageId, dotExtension])}`,
      blockIds
    )
      .then((response) => {
        if (response && response.status === 200) {
          let uri = response.content.imageUri;
          console.log("ImageSession.onBlockUploadEnd.Uri:", uri);
          session.imageData.imageUri = uri;
          session.editedImageBlob = undefined;
          session.setLoadingState(EImageLoadingState.DescriptionUploaded);
          session.dispatch && session.dispatch({ type: "ImageUploadEnd", session: session });
          session.onUploadEnd && session.onUploadEnd(session.imageData);
        } else {
          console.error(response);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  };
  //---------------------------------------------------------------------------
  uploadImageFromEditedBlobByBlocks(
    removeSessionAfterUpload: boolean,
    appAuthContext?: IAppAuthContext,
    instance?: IPublicClientApplication
  ): boolean {
    if (this.imageData.isSystemImage) {
      console.log("uploadImageFromEditedBlobByBlocks: system image - no need to upload");
      return false;
    }

    let blob = this._editedImageBlob;
    if (!blob) {
      console.error(`ImageEditorSession[${this.imageId}].imageData.editedImageBlob not exists.`);
      return false;
    }
    if (!instance || !appAuthContext?.config || !appAuthContext?.user) {
      console.error("Could not upload image because auth context is not set");
      return false;
    }
    const blockSize = 524288;
    console.log("uploadImageFromEditedUrlByBlocks.imageData:", this.imageData);
    // in case of uploading from edited url no need to callback on upload
    this.removeAfterUpload = removeSessionAfterUpload ?? false;

    let imageDataToUpload = new TImageData(this.imageData)
    if (!imageDataToUpload.lastModified)
      imageDataToUpload.lastModified = new Date();
    imageDataToUpload.editedImageUrl = undefined;
    let imageSize = blob.size;
    imageDataToUpload.contentLength = imageSize;
    this.uploadImageBySize(blob, 'Thumbnail', imageDataToUpload, appAuthContext.config, instance);
    this.uploadImageBySize(blob, 'Medium', imageDataToUpload, appAuthContext.config, instance);
    this.imageBlocks = [];
    let blockCount = Math.ceil(imageSize / blockSize);
    let start = 0;
    let blockIndex = 0;
    while (start < imageSize) {
      let end = start + blockSize;
      if (end > imageSize)
        end = imageSize;
      let imageBlock: ImageBlock = new ImageBlock({
        blockCount: blockCount,
        imageSize: imageSize,
        imageId: this.imageId,
        blockIndex: blockIndex,
        blockStart: start,
        blockEnd: end,
        onUploadEnd: this.onBlockUploadEnd,
      });
      this.imageBlocks.push(imageBlock);
      //let imageData = blockIndex == 0 ? imageDataToUpload : undefined;
      imageBlock.upload(
        blob.slice(start, end, blob.type),
        this,
        appAuthContext.config, instance,
        imageDataToUpload
      );
      start = end;
      blockIndex++;
    }
    return true;
  };
  //---------------------------------------------------------------------------
  checkImageForUpload(date: Date, userId?: string): TImageData | undefined {

    if (this.imageData.isSystemImage) {
      console.log("uploadImageFromEditedBlobByBlocks: system image - no need to upload");
      return undefined;
    }

    let imageDataToUpload = new TImageData(this.imageData)
    let blob = this._editedImageBlob;
    if (!imageDataToUpload.userId)
      imageDataToUpload.userId = userId;
    imageDataToUpload.editedImageUrl = undefined;
    if (blob) {
      imageDataToUpload.lastModified = date;
      imageDataToUpload.contentLength = blob.size;
    }
    // if (!blob) {
    //   console.error(`ImageEditorSession[${this.imageId}].imageData.editedImageBlob not exists.`);
    //   return undefined;
    // }

    // let imageDataToUpload = new TImageData(this.imageData)
    // imageDataToUpload.lastModified = date;
    // imageDataToUpload.editedImageUrl = undefined;
    // imageDataToUpload.contentLength = blob.size;
    // imageDataToUpload.userId = userId;
    return imageDataToUpload;
  }
  //---------------------------------------------------------------------------
  getImageUpdates(
    libraryItemsToUpload: TImageData[],
    sessionsToUploadImage: ImageEditorSession[],
    date: Date,
    userId?: string) {

    // if current image is system image - nothing to change
    if (this.imageData.isSystemImage) {
      console.log("getImageUpdates: system image - no need to upload");
      return;
    }

    if (!this.descriptionIsChanged && this._editedImageBlob == undefined) {
      console.log("getImageUpdates: no changes is session", this);
      return;
    }
    let imageDataToUpload = new TImageData(this.imageData)
    let blob = this._editedImageBlob;
    if (!imageDataToUpload.userId)
      imageDataToUpload.userId = userId;
    imageDataToUpload.editedImageUrl = undefined;

    if (blob) {
      // in this case we upload imageData and image
      imageDataToUpload.lastModified = date;
      imageDataToUpload.contentLength = blob.size;
      libraryItemsToUpload.push(imageDataToUpload);
      sessionsToUploadImage.push(this);
    }
    else if (this.descriptionIsChanged) {
      libraryItemsToUpload.push(imageDataToUpload);
      // in this case we update usage only
      //updateUsageOnly.push(imageDataToUpload);
      //imageDataToUpload.updateUsageOnly = true;
    }
  }
  //---------------------------------------------------------------------------
  async uploadImageFromEditedBlobByBlocksAsync(
    removeSessionAfterUpload: boolean,
    appAuthContext?: IAppAuthContext,
    instance?: IPublicClientApplication
  ): Promise<boolean> {
    try {
      if (this.imageData.isSystemImage) {
        throw "uploadImageFromEditedBlobByBlocks: system image - no need to upload";
      }
      let blob = this._editedImageBlob;
      if (!blob) {
        throw `ImageEditorSession[${this.imageId}].imageData.editedImageBlob not exists.`;
      }
      if (!instance || !appAuthContext?.config || !appAuthContext?.user) {
        throw "Could not upload image because auth context is not set";
      }
      const blockSize = 524288;
      console.log("uploadImageFromEditedUrlByBlocks.imageData:", this.imageData);
      // in case of uploading from edited url no need to callback on upload
      this.removeAfterUpload = removeSessionAfterUpload ?? false;

      let imageDataToUpload = new TImageData(this.imageData)
      if (!imageDataToUpload.lastModified)
        imageDataToUpload.lastModified = new Date();
      imageDataToUpload.editedImageUrl = undefined;
      let imageSize = blob.size;
      imageDataToUpload.contentLength = imageSize;
      this.uploadImageBySize(blob, 'Thumbnail', imageDataToUpload, appAuthContext.config, instance);
      this.uploadImageBySize(blob, 'Medium', imageDataToUpload, appAuthContext.config, instance);
      let blockCount = Math.ceil(imageSize / blockSize);
      let start = 0;
      let blockIndex = 0;
      let promises: Promise<ImageBlock>[] = [];
      while (start < imageSize) {
        let end = start + blockSize;
        if (end > imageSize)
          end = imageSize;
        let imageBlock: ImageBlock = new ImageBlock({
          blob: blob.slice(start, end, blob.type),
          blockCount: blockCount,
          imageSize: imageSize,
          imageId: this.imageId,
          blockIndex: blockIndex,
          blockStart: start,
          blockEnd: end
        });
        promises.push(imageBlock.uploadAsync(
          appAuthContext.config, instance,
          imageDataToUpload,
          this
        ));
        start = end;
        blockIndex++;
      }
      console.log(`uploadImageFromEditedUrlByBlocks[${this.imageId}].start, blockCount:`, promises.length);
      this.imageBlocks = await Promise.all(promises);

      let blockIds: string[];
      let maxTries = 5;
      let i = 1;
      do {
        blockIds = [];
        promises = [];
        this.imageBlocks.forEach(block => {
          if (block.blockId) {
            blockIds.push(block.blockId);
          }
          else if (appAuthContext.config) {
            promises.push(block.uploadAgainAsync(
              imageDataToUpload,
              appAuthContext.config, instance,
              this
            ));
          }
        });
        // if all block ids already assigned: merge blocks to final blob
        if (blockIds.length == this.imageBlocks.length)
          break;
        if (promises.length > 0) {
          console.log(`Not all blocks are uploaded (${promises.length}). Next try: [${i}]`);
          await Promise.all(promises);
        }
        i++;
      } while (i <= maxTries);

      if (blockIds.length != this.imageBlocks.length)
        throw `blockIds.length[${blockIds.length}] != this.imageBlocks.length[${this.imageBlocks.length}]`;

      console.log(`uploadImageFromEditedUrlByBlocks[${this.imageId}], blocks uploaded: merge blocks`);

      //let dotExtension = `.${blob.type.split("/")[1]}`;
      imageDataToUpload.dotExtension = "";//dotExtension;
      imageDataToUpload.blockIds = blockIds;
      let response = await apiPostPrivate(
        instance, appAuthContext.config,
        //`${apiBasePath}${stringFormatter(mergeImageBlocksAsync, [this.imageId, dotExtension])}`,
        `${apiBasePath}${mergeImageBlocksAsync}`,
        imageDataToUpload//blockIds
      );
      checkResponse(response, `uploadImageFromEditedUrlByBlocks[${this.imageId}]`)
      let uri = response?.content.imageUri;
      console.log(`uploadImageFromEditedUrlByBlocks[${this.imageId}]: blocks merged`);
      this.imageData.imageUri = uri;
      this.editedImageBlob = undefined;
      this.setLoadingState(EImageLoadingState.DescriptionUploaded);
      this.dispatch && this.dispatch({ type: "ImageUploadEnd", session: this });
      return true;
    }
    catch (error) {
      console.error(`uploadImageFromEditedUrlByBlocks[${this.imageId}].catch:`, error);
    }
    return false;
  };
  //---------------------------------------------------------------------------
  async uploadImageAsIsAsync(
    blob: Blob,
    imageSize: EImageSize,
    imageData: TImageData,
    config: IAuthConfig,
    instance: IPublicClientApplication): Promise<boolean> {
    console.log("uploadImageAsIsAsync.blob.size:", blob?.size);
    try {
      let fileName = `image.${blob.type.split("/")[1]}`;
      let formData: FormData = new FormData();
      formData.append("image", blob, fileName);
      formData.append("imageData", JSON.stringify(imageData));

      let maxTries = 5;
      let i = 1;
      do {
        try {
          let response = await apiPostPrivate(
            instance, config,
            `${apiBasePath}${stringFormatter(uploadImageSizeAsync, [imageSize])}`,
            formData,
            "image"
          )
          if (response && response.status === EHttpStatusCode.OK) {
            console.log("uploadImageAsIsAsync succeeded. Result:", response.content);
            return true;
          } else {
            console.error(`uploadImageAsIsAsync [try: ${i}] failed. Response:`, response);
          }
        } catch (error) {
          console.error(`uploadImageAsIsAsync.catch [try: ${i}]`, error);
        }
        i++;
      } while (i <= maxTries);
      throw `uploadImageAsIsAsync failed [${maxTries}] times`;
    } catch (error) {
      console.error(`uploadImageAsIsAsync[${imageData.id}].catch:`, error);
      return false;
    }
  }
  //---------------------------------------------------------------------------
  uploadImageBySize(blob: Blob, imageSize: EImageSize, imageData: TImageData, config: IAuthConfig, instance: IPublicClientApplication) {
    // Maximum size of any dimension.
    let maxPixels: number | undefined = 512;
    switch (imageSize) {
      case 'Medium':
        maxPixels = 512;
        break;
      case 'Thumbnail':
        maxPixels = 100;
        break;
      default: {
        console.error("uploadImageBySize error: incorrect image size:", imageSize);
        return;
      }
    }
    console.log("uploadImageBySize.size:", imageSize);
    // Width and height.
    let originalWidth = imageData.widthPx as number;
    let originalHeight = imageData.heightPx as number;
    // Compute best factor to scale entire image based on larger dimension.
    let factor: number;
    if (originalWidth > originalHeight)
      factor = maxPixels / originalWidth;
    else
      factor = maxPixels / originalHeight;

    console.log("uploadImageBySize.resizeFactor:", factor);

    if (factor >= 1) {
      console.log(`uploadImageBySize: resize factor is ${factor} - uploading image as is (without resizing)`);
      this.uploadImageAsIsAsync(blob, imageSize, imageData, config, instance);
      return;
    }

    let targetWidth = Math.round(originalWidth * factor);
    let targetHeight = Math.round(originalHeight * factor);

    // get it back as a Blob
    let options: ImageBitmapOptions = {
      resizeWidth: targetWidth,
      resizeHeight: targetHeight,
      resizeQuality: "high"
    };
    createImageBitmap(blob, options)
      .then((bmp: ImageBitmap) => {
        let canvas = document.createElement('canvas');
        // canvas.width = originalWidth;
        // canvas.height = originalHeight;
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        let ctx = canvas.getContext('bitmaprenderer');
        ctx?.transferFromImageBitmap(bmp);
        const imageType = blob.type;//'image.png';
        canvas.toBlob((image: Blob | null) => {
          console.log(`upload[${imageSize}]Image.size:`, image?.size);
          if (!image)
            return;
          this.uploadImageAsIsAsync(image, imageSize, imageData, config, instance);
        }, imageType, 1);
      }); //.then
  }

  addToContext(dispatch?: TImageEditorDispatch) {
    this.dispatch = dispatch;
    this.dispatch?.({ type: "NewSession", session: this });
  }

  removeFromContext() {
    this.dispatch?.({ type: "RemoveSession", session: this });
  }
}