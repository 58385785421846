import styles from "./FormOptionToolbarMobile.module.css";
import { CSSProperties } from "react";
import CombineStyles from "../../../../../utils/combineStyles";
import { TUiMenuOption } from "../../../menu/menu-content/menu-option/TUiMenuOption";
import Icon, { IconSize } from "../../../icon/Icon";

interface IProps {
  ui: TUiMenuOption;
  onSelect: (optionId: string) => void;
}

export default function FormOptionToolbarMobile(props: IProps) {
  const spin = false;
  //---------------------------------------------------------------------------
  const calculatedStyle: CSSProperties = {
    backgroundColor: props.ui.customColor,
    display: props.ui.visible ? "flex" : "none"
  };
  //---------------------------------------------------------------------------
  const containerStyle = CombineStyles([
    styles.container,
    props.ui.isDefault ? styles.default : "",
    props.ui.disabled ? styles.disabled : ""
  ]);
  //---------------------------------------------------------------------------
  return (
    <div
      id={props.ui.id}
      className={containerStyle}
      style={calculatedStyle}
      onClick={() => props.onSelect(props.ui.id)}
    >
      {props.ui.iconFile &&
        <Icon
          imageName={props.ui.iconFile}
          size={IconSize.Small}
          reserveSpace={false}
          spin={spin}
        />}
    </div>
  );
}