import { ReactNode } from "react";
import styles from "./CustomIcon.module.css";

interface IWrapperProps {
  children: ReactNode;
}

export function CustomIconWrapper(props: IWrapperProps) {
  return (
    <div className={styles.container}>
      {props.children}
    </div>
  );
}

//-----------------------------------------------------------------------------
/* export function SelectImageIcon() {
  return (
    <svg
      style={{ width: "100%", height: "100%" }}
      //className={styles.statusicon}
      version="1.1"
      viewBox="0 0 1024 960" // min-x, min-y, width and height // 1024 960
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="scale(1,-1) translate(0,-925)"
        fill="currentColor"
      >
        <path d="M959.884 832c0.040-0.034 0.082-0.076 0.116-0.116v-767.77c-0.034-0.040-0.076-0.082-0.116-0.116h-895.77c-0.040 0.034-0.082 0.076-0.114 0.116v767.772c0.034 0.040 0.076 0.082 0.114 0.114h895.77zM960 896h-896c-35.2 0-64-28.8-64-64v-768c0-35.2 28.8-64 64-64h896c35.2 0 64 28.8 64 64v768c0 35.2-28.8 64-64 64v0zM832 672c0-53.020-42.98-96-96-96s-96 42.98-96 96 42.98 96 96 96 96-42.98 96-96zM896 128h-768v128l224 384 256-320h64l224 192z" />
      </g>
    </svg>
  );
} */

//-----------------------------------------------------------------------------
export function RedoIcon() {
  return (
    <svg
      style={{ width: "100%", height: "100%" }}
      //className={styles.statusicon}
      version="1.1"
      viewBox="0 0 1024 1024" // min-x, min-y, width and height // 1024 960
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="scale(1,-1) translate(0,-960)"
        fill="currentColor"
      >
        <path d="M576 711.628v248.372l384-384-384-384v253.824c-446.75 10.482-427.588-303.792-313.86-509.824-280.712 303.414-221.1 789.57 313.86 775.628z" />
      </g>
    </svg>
  );
}

//-----------------------------------------------------------------------------
export function UndoIcon() {
  return (
    <svg
      style={{ width: "100%", height: "100%" }}
      //className={styles.statusicon}
      version="1.1"
      viewBox="0 0 1024 1024" // min-x, min-y, width and height // 1024 960
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="scale(1,-1) translate(0,-960)"
        fill="currentColor"
      >
        <path d="M761.862-64c113.726 206.032 132.888 520.306-313.862 509.824v-253.824l-384 384 384 384v-248.372c534.962 13.942 594.57-472.214 313.862-775.628z" />
      </g>
    </svg>
  );
}

//-----------------------------------------------------------------------------
/* export function UndoIcon() {
  return (
    <svg
      style={{ width: "100%", height: "100%" }}
      //className={styles.statusicon}
      version="1.1"
      viewBox="0 0 1024 1024" // min-x, min-y, width and height // 1024 960
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="scale(1,-1) translate(0,-960)"
        fill="currentColor"
      >
        <path d="M512 896c-141.384 0-269.376-57.32-362.032-149.978l-149.968 149.978v-384h384l-143.532 143.522c69.496 69.492 165.492 112.478 271.532 112.478 212.068 0 384-171.924 384-384 0-114.696-50.292-217.636-130.018-288l84.666-96c106.302 93.816 173.352 231.076 173.352 384 0 282.77-229.23 512-512 512z" />
      </g>
    </svg>
  );
} */

//-----------------------------------------------------------------------------
/* export function RedoIcon() {
  return (
    <svg
      style={{ width: "100%", height: "100%" }}
      //className={styles.statusicon}
      version="1.1"
      viewBox="0 0 1024 1024" // min-x, min-y, width and height // 1024 960
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="scale(1,-1) translate(0,-960)"
        fill="currentColor"
      >
        <path d="M0 384c0-152.924 67.048-290.184 173.35-384l84.666 96c-79.726 70.364-130.016 173.304-130.016 288 0 212.076 171.93 384 384 384 106.042 0 202.038-42.986 271.53-112.478l-143.53-143.522h384v384l-149.97-149.978c-92.654 92.658-220.644 149.978-362.030 149.978-282.77 0-512-229.23-512-512z" />
      </g>
    </svg>
  );
} */

//-----------------------------------------------------------------------------
export function CropIcon() {
  return (
    <svg
      style={{ width: "100%", height: "100%" }}
      //className={styles.statusicon}
      version="1.1"
      viewBox="0 0 1024 1024" // min-x, min-y, width and height // 1024 960
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="scale(1,-1) translate(0,-960)"
        fill="currentColor"
      >
        <path d="M832 704l192 192-64 64-192-192h-448v192h-128v-192h-192v-128h192v-512h512v-192h128v192h192v128h-192v448zM320 640h320l-320-320v320zM384 256l320 320v-320h-320z" />
      </g>
    </svg>
  );
}