export interface IEditFormState<T> {
  object?: T;
  //---------------------------------------------------------------------------
  // Following properties are relative to the current edit session only,
  // not to published dictionary version
  initialState?: T;
  isNew?: boolean;
  isUpdated?: boolean;
  isValid: boolean;
  //---------------------------------------------------------------------------
  // Following properties are relative to the published dictionary version
  //publishedState?: T;
  //isActuallyUpdated: boolean;
}

interface IEditFormSpread<T> {
  object?: T;
  isUpdated?: boolean;
  isValid?: boolean;
}

export class EditFormState<T> implements IEditFormState<T> {
  object?: T;
  initialState?: T;
  isNew!: boolean;
  isUpdated!: boolean;
  isValid!: boolean;
  //---------------------------------------------------------------------------
  constructor(source: IEditFormState<T>, spread?: IEditFormSpread<T>) {
    //console.log('this', this);
    //console.log('source', source);
    //console.log('spread', spread);
    Object.assign(this, source, spread);
    //console.log(this.initialState, this.object)
    if (spread == undefined) {
      //console.log("first")
      // State is being initialized
      this.isUpdated = false;
      if (this.isNew) {
        // If it's a new object, initial state is undefined
        this.initialState = undefined;
      } else {
        // It's an existing object
        this.initialState = JSON.parse(JSON.stringify(this.object));
        this.isUpdated = false;
      };
    } else {
      //console.log("not first")
      // State is being updated
      // Compare state with initial value

      // ATTENTION: replace the procedure to check changes!!
      //console.log(JSON.stringify(this.initialState))
      //console.log(JSON.stringify(this.object))
      if (JSON.stringify(this.initialState) == JSON.stringify(this.object)) {
        // Nothing's changed
        //console.log("no changes")
        this.isUpdated = false;
      } else {
        // There are changes
        //console.log("changed")
        this.isUpdated = true;
      };
    };
  };
}