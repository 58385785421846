import React, { useEffect, useState } from 'react';
import { AdRanksDictionaryDraftItem, IAdRanksDictionaryDraftItem } from '../AdRanksDictionaryDraft';
import { IDictionaryDraftItemStateGroup } from '../../common/settings/IDictionaryDraftItemStateGroup';
import AdRankEditForm from '../ad-rank-edit-form/AdRankEditForm';
import DictionaryEditorNodeWrapper from '../../common/dictionary-node-wrapper/DictionaryEditorNodeWrapper';

interface IProps {
  stateGroups?: IDictionaryDraftItemStateGroup[];
  data: IAdRanksDictionaryDraftItem;
  editModeIsEnabled?: boolean;
  onUpdate: (updatedDraftItem: AdRanksDictionaryDraftItem) => void;
  onDelete: () => void;
}

export default function AdRanksEditorNode(props: IProps) {
  const [data, setData] = useState<AdRanksDictionaryDraftItem>();
  const [editMode, setEditMode] = useState(false);
  const localeId = "en";
  //---------------------------------------------------------------------------
  const editModeIsEnabled = props.editModeIsEnabled == undefined ? false : props.editModeIsEnabled;
  //---------------------------------------------------------------------------
  useEffect(() => {
    setData(new AdRanksDictionaryDraftItem(props.data));
  }, [props.data]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (props.editModeIsEnabled != undefined && !props.editModeIsEnabled) {
      setEditMode(false);
    };
  }, [props.editModeIsEnabled]);
  //---------------------------------------------------------------------------
  const onClose = () => {
    setEditMode(false);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedDraftItem: AdRanksDictionaryDraftItem) => {
    //console.log(updatedDraftItem)
    setEditMode(false);
    props.onUpdate(updatedDraftItem);
  };
  //---------------------------------------------------------------------------
  const onDelete = () => {
    if (data) {
      if (!data.deleted) {
        if (data.isNew) {
          // If it's a new item, make a simple deletion
          props.onDelete();
        } else {
          // If it's a existing item, mark it as deleted
          const updatedItem = new AdRanksDictionaryDraftItem(data);
          updatedItem.deleted = true;
          updatedItem.refresh(localeId);
          props.onUpdate(updatedItem);
        };
      } else console.error("Cannot delete item: it's already deleted");
    } else console.error("Cannot delete item: state is not set");
  };
  //---------------------------------------------------------------------------
  const onActivate = () => {
    if (data) {
      if (data.deleted) {
        const updatedItem = new AdRanksDictionaryDraftItem(data);
        updatedItem.deleted = false;
        updatedItem.refresh(localeId);
        props.onUpdate(updatedItem);
      } else console.error("Cannot restore item: it's not deleted");
    } else console.error("Cannot delete item: state is not set");
  };
  //---------------------------------------------------------------------------
  if (data)
    return (
      <React.Fragment>
        {!editMode ?
          <DictionaryEditorNodeWrapper
            data={data}
            stateGroups={props.stateGroups}
            editModeIsEnabled={editModeIsEnabled}
            isOrderedManually={false}
            onEdit={() => setEditMode(true)}
            onDelete={onDelete}
            onDeactivate={onDelete}
            onActivate={onActivate}
          >
            <span style={{ color: 'var(--clr_font_system)', fontSize: '.8em' }}>PPV:</span>&nbsp;
            <span>{props.data.pointsPerView}</span>&nbsp;
            <span style={{ color: 'var(--clr_font_system)', fontSize: '.8em' }}>PPE:</span>&nbsp;
            <span>{props.data.pointsPerEngagement}</span>
          </DictionaryEditorNodeWrapper>
          :
          <AdRankEditForm
            data={data}
            onUpdate={onUpdate}
            onClose={onClose}
          />}
      </React.Fragment>
    );
  else return null;
}