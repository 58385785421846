import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import styles from './Inputs.module.css';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { IUiInputText } from '../../../../common/input-v2/input-text/InputText';
import CombineStyles from '../../../../../utils/combineStyles';
import GetPlaceholder from '../../../../common/functions/GetPlaceholder';
import Icon from '../../../../common/icon/Icon';
import GetHint from '../../../../common/functions/GetHint';

interface IProps {
  elementRef?: React.RefObject<HTMLInputElement>;
  ui: IUiInputText;
  searchString?: string;
  onMapOptionSelect: () => void;
  onClear: () => void;
}

export default function InputLocation(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [value, setValue] = useState<string>('');
  const inputRef = props.elementRef ? props.elementRef : useRef<HTMLInputElement>(null);
  const inputIsEmpty = value.length === 0;
  //---------------------------------------------------------------------------
  useEffect(() => {
    //console.log(props.searchString)
    if (props.searchString && props.searchString.length > 0) {
      setValue(props.searchString)
      //setInputEmpty(false);
    }
    else {
      setValue('')
      //setInputEmpty(true);
    }
  }, [props.searchString]);
  //---------------------------------------------------------------------------
  const onUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  //---------------------------------------------------------------------------
  const onClear = () => {
    setValue('');
    inputRef.current?.focus();
    props.onClear();
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <input
        ref={inputRef}
        id={props.ui.id}
        type="text"
        inputMode="text"
        enterKeyHint="search"
        className={CombineStyles([styles.input, inputIsEmpty ? '' : styles.notEmpty])}
        placeholder={GetPlaceholder(appUiContext, props.ui.id, props.ui.placeholder)}
        title={GetHint(appUiContext, props.ui.id, props.ui.hint)}
        value={value}
        onChange={onUpdate}
      />

      {!inputIsEmpty &&
        <div
          className={styles.clearIcon}
          onClick={onClear}
        >
          <Icon imageName="close" />
        </div>}

      <div
        id={`${props.ui.id}_mapButton`}
        className={styles.locationIcon}
        onClick={props.onMapOptionSelect}
      >
        <Icon imageName="location" />
      </div>

    </div>
  );
}