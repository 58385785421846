import { useState } from 'react';
import CombineStyles from '../../../../utils/combineStyles';
import { EOptionsAdModule } from '../../../ad-content-editor/ad-modules-editor/AdModuleOptions';
import BusinessLogo from '../../../business-space/business-logo/BusinessLogo';
import { BusinessSearchListItem } from '../../../search-console/business-search-list/business-search-card/BusinessSearchListItem';
import { PercentDonut } from '../../../search-console/business-search-list/business-search-card/PercentDonut';
import Icon from '../../icon/Icon';
import OptionInline from '../../options/option-inline/OptionInline';
import { getUnitColor } from '../AdModule';
import { IAdModule } from '../IAdModule';
import styles from './AdModuleSearch.module.css';
import PopupMenu from '../../popup-v2/popup-menu/PopupMenu';
import { IUiMenuContent } from '../../options/menus/IUiMenuContent';
import { getTUiMenuContent } from '../../menu/menu-content/TUiMenuContent';

interface IProps {
  ui?: IUiMenuContent;
  adModule: IAdModule;
  isReadonly: boolean;
  highlightedPath?: string[];
  onModuleOptionSelect?: (optionId: EOptionsAdModule, adModuleId: string) => void;
}

export default function AdModuleSearch(props: IProps) {
  const [popupOpen, setPopupOpen] = useState(false);
  const menuElementId = `${props.adModule.id}_AdModuleContextMenu`;
  const menuContent = props.ui ? getTUiMenuContent(props.ui) : undefined;
  const isHighlighted = props.highlightedPath?.includes(props.adModule.id);
  const logoIsHighlighted = isHighlighted && props.highlightedPath?.includes("Logo");
  const imageColor = logoIsHighlighted ? getUnitColor(props.adModule.image?.status) : undefined;
  const headerIsHighlighted = isHighlighted && props.highlightedPath?.includes("Header");
  const headerColor = headerIsHighlighted ? getUnitColor(props.adModule.header?.status) : undefined;
  const descriptionIsHighlighted = isHighlighted && props.highlightedPath?.includes("Description");
  const descriptionColor = descriptionIsHighlighted ? getUnitColor(props.adModule.description?.status) : undefined;
  //---------------------------------------------------------------------------
  /* const onHeaderClick = () => {
    if (props.isReadonly) return;
    if (!props.onModuleOptionSelect) return;
    props.onModuleOptionSelect("ModuleHeaderEdit", props.adModule.id);
  }; */
  //---------------------------------------------------------------------------
  const onContextMenuOpen = () => {
    if (!props.ui) return;
    if (!props.onModuleOptionSelect) return;
    setPopupOpen(true);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    setPopupOpen(false);
    if (!props.onModuleOptionSelect) return;
    // Convert UI options to ad module options
    switch (selectedOptionId) {
      case "AdModule.MenuContent.OptionEditText":
        props.onModuleOptionSelect("PhotoblockTextEdit", props.adModule.id);
        break;
      case "AdModule.MenuContent.OptionDelete":
        props.onModuleOptionSelect("ModuleDelete", props.adModule.id);
        break;
    };
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <div
        className={styles.logo}
        style={{
          border: isHighlighted ? `1px solid ${imageColor}` : '',
          boxShadow: isHighlighted ? `0 0 5px 2px ${imageColor}` : undefined,
          zIndex: isHighlighted ? 15 : undefined
        }}
      >
        <BusinessLogo
          data={props.adModule.image?.imageData}
          useDefaultLogo={true} />
      </div>

      <div
        className={styles.details}
      //onClick={onHeaderClick}
      >

        <div
          className={styles.title}
          style={{
            color: headerColor
          }}
        >
          {props.adModule?.header?.text}
        </div>

        <div
          className={styles.description}
          style={{
            color: descriptionColor
          }}>
          {props.adModule.description?.text}
          {(!props.isReadonly && props.onModuleOptionSelect) &&
            <OptionInline iconId='pencil' />}
        </div>

        <div style={{ color: 'var(--clr_font_system)' }}>
          <b>English</b>
        </div>

      </div>

      <div className={styles.stuff}>
        <PercentDonut data={new BusinessSearchListItem({
          businessId: "",
          businessName: "",

        })} percents={true} onToggle={() => { }} />
      </div>

      {props.ui && props.ui.options.length > 0 &&
        <div
          id={menuElementId}
          className={CombineStyles([styles.contextMenu, styles.floating])}
          onClick={onContextMenuOpen}>
          <Icon imageName="kebabButton" />
        </div>}

      {popupOpen && menuContent &&
        <PopupMenu
          anchorId={menuElementId}
          ui={menuContent}
          displayIcon={true}
          onClose={() => setPopupOpen(false)}
          onSelect={onOptionSelect}
        />}

    </div>
  );
}