import React, { useState } from 'react';
import styles from '../DictionaryEditorNodeWrapper.module.css';
import { DictionaryEditorDraftItem } from '../../models/DictionaryEditorDraftItem';
import Icon from '../../../../../../common/icon/Icon';
import PopupMenu from '../../../../../../common/popup-v2/popup-menu/PopupMenu';
import { TUiMenuContent } from '../../../../../../common/menu/menu-content/TUiMenuContent';
import DictionaryNodeItemWrapper from '../dictionary-node-item-wrapper/DictionaryNodeItemWrapper';

interface IProps {
  ui: TUiMenuContent;
  data: DictionaryEditorDraftItem;
  onSelect: (selectedOptionId: string) => void;
}

export default function DictionaryNodeMenu(props: IProps) {
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const contextMenuDivId = `DictionaryNodeOptions_${props.data.id}`;
  //---------------------------------------------------------------------------
  const onContextMenuOpen = () => {
    setContextMenuOpen(true);
  };
  //---------------------------------------------------------------------------
  const onContextMenuClose = () => {
    setContextMenuOpen(false);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    setContextMenuOpen(false);
    props.onSelect(selectedOptionId);
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      
      <td
        id={contextMenuDivId}
        className={styles.options}
        onClick={onContextMenuOpen}
      >
        <DictionaryNodeItemWrapper>
          <Icon imageName="kebabButton" />
        </DictionaryNodeItemWrapper>
      </td>

      {contextMenuOpen &&
        <PopupMenu
          anchorId={contextMenuDivId}
          ui={props.ui}
          displayIcon={true}
          onSelect={onOptionSelect}
          onClose={onContextMenuClose}
        />}

    </React.Fragment>
  );
}