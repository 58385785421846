import Icon from '../../../icon/Icon';
import styles from './PopupErrorHeader.module.css';

interface IProps {
  header: string;
  onClose: () => void;
}

export default function PopupErrorHeader(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {props.header}
      </div>
      <div
        className={styles.option}
        onClick={props.onClose}>
        <Icon imageName="close" />
      </div>
    </div>
  );
}