import IconButton from "../../../../common/icon-button/IconButton";
import { IUiMenuContent } from "../../../../common/options/menus/IUiMenuContent";
import styles from "./EmailListItem.module.css";

interface IProps {
  ui: IUiMenuContent;
  data: string;
  onEdit: () => void;
  onDelete: () => void;
}

export default function EmailListItem(props: IProps) {
  //---------------------------------------------------------------------------
  const optionEdit = props.ui.options.find(option => option.id == "UserEmails.MenuContent.OptionEdit");
  const optionDelete = props.ui.options.find(option => option.id == "UserEmails.MenuContent.OptionDelete");
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      {props.data}
      {optionEdit &&
        <IconButton
          ui={{
            id: optionEdit.id,
            directoryId: optionEdit.directoryId,
            elementType: optionEdit.elementType,
            hint: optionEdit.hint,
            iconFile: "pencil",
            disabled: false,
            visible: true,
            inline: false,
            action: undefined
          }}
          inline={false}
          clickHandler={props.onEdit}
        />}
      {optionDelete &&
        <IconButton
          ui={{
            id: optionDelete.id,
            directoryId: optionDelete.directoryId,
            elementType: optionDelete.elementType,
            hint: optionDelete.hint,
            iconFile: "delete",
            disabled: false,
            visible: true,
            inline: false,
            action: undefined
          }}
          inline={false}
          clickHandler={props.onDelete}
        />}
    </div>
  );
}