import { useContext, useEffect, useState } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { WizardState } from '../IWizardState';
import { IUiBusinessWizard } from '../IUiBusinessWizardV2';
import GetDescription from '../../../common/functions/GetDescription';
import WizardStepWrapper from '../step-wrapper/WizardStepWrapper';
import { IUiOption } from '../../../common/options/IUiOption';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import styles from './StepLocation.module.css';
import { Contact } from '../../business-editor/section-contacts/contact/Contact';
import ContactsEditor from '../../business-editor/section-contacts/contacts-editor/ContactsEditor';

interface IProps {
  state: WizardState;
  ui: IUiBusinessWizard;
  onUpdate: (state: WizardState) => void;
  onExit: () => void;
}

export default function StepContacts(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [contacts, setContacts] = useState<Contact<string>[]>();
  const stepUi = props.ui.stepsDictionary.find(item => item.id == props.state.selectedStep);
  const stepData = props.state.steps.find(item => item.stepId == props.state.selectedStep);
  const prompt = GetDescription(appUiContext, props.state.selectedStep, stepUi?.description);
  const isUpdated = contacts !== props.state.data.contacts;
  const stepIsCompleted = stepData?.isCompleted ? true : false;
  //---------------------------------------------------------------------------
  useEffect(() => {
    setContacts(props.state.data.contacts);
  }, [props.state.data.locationData]);
  //---------------------------------------------------------------------------
  const onUpdate = (updatedData: Contact<string>[]) => {
    setContacts(updatedData);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    let updatedState: WizardState;
    switch (optionId) {
      case "OptionBack":
        updatedState = isUpdated ?
          props.state.updateContacts(contacts ? contacts : [], "Back") :
          updatedState = props.state.moveToPreviousStep();
        break;
      case "OptionNext":
        updatedState = isUpdated ?
          props.state.updateContacts(contacts ? contacts : [], "Next") :
          props.state.moveToNextStep(!stepIsCompleted);
        break;
      case "OptionSkip":
        updatedState = props.state.moveToNextStep(true);
        break;
      default:
        throw new Error(`StepContacts: Unknown optionId ${optionId}`);
    };
    props.onUpdate(updatedState);
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionBack":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowLeft",
          isDisabled: false
        });
        break;
      case "OptionSkip":
        if (!stepIsCompleted && !isUpdated) {
          updatedOptions.push({
            ...option,
            iconId: "doubleArrowRight",
            isDefault: true
          });
        };
        break;
      case "OptionNext":
        if (stepIsCompleted || isUpdated) {
          updatedOptions.push({
            ...option,
            iconId: "doubleArrowRight",
            isDisabled: false,
            isDefault: true
          });
        };
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      alignLeft={true}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <WizardStepWrapper
      ui={props.ui}
      promptString={prompt}
      optionsElement={formOptions}
      onExit={props.onExit}
    >
      <div className={styles.container}>
        <ContactsEditor
          ui={props.ui.inputListContacts}
          data={contacts}
          onUpdate={onUpdate}
        />
      </div>
    </WizardStepWrapper>
  );
}