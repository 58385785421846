//import styles from './UserMenuButtonCaption.module.css';

interface IProps {
  isAuthenticated: boolean;
  letter?: string;
  inProgress: boolean;
}

export default function (props: IProps) {
  const dotsSize = 2;
  const initialSize = 3.5;
  const letter = props.letter ? props.letter : "?";
  //---------------------------------------------------------------------------
  if (!props.isAuthenticated || props.inProgress)
    return (
      // Render vertical dots button if not authenticated and animated vertical dots if in process
      <svg xmlns="http://www.w3.org/2000/svg"
        width={`${dotsSize}em`} height={`${dotsSize}em`}
        viewBox="0 0 24 24">

        <circle cx="12" cy="4" r="3" fill="currentColor">
          {props.inProgress &&
            <animate id="svgSpinners3DotsScale0" attributeName="r" begin="0;svgSpinners3DotsScale1.end-0.25s" dur="0.75s" values="3;.2;3" />}
        </circle>

        <circle cx="12" cy="12" r="3" fill="currentColor">
          {props.inProgress &&
            <animate attributeName="r" begin="svgSpinners3DotsScale0.end-0.6s" dur="0.75s" values="3;.2;3" />}
        </circle>

        <circle cx="12" cy="20" r="3" fill="currentColor">
          {props.inProgress &&
            <animate id="svgSpinners3DotsScale1" attributeName="r" begin="svgSpinners3DotsScale0.end-0.45s" dur="0.75s" values="3;.2;3" />}
        </circle>

      </svg>
    );
  else return (
    // Render current user intitial, i.e. display name first letter, if authenticated
    <svg xmlns="http://www.w3.org/2000/svg"
      width={`${initialSize}em`} height={`${initialSize}em`}
      //style={{ backgroundColor: 'red', opacity: '.5' }}
      viewBox="0 0 24 24">
      {/* <circle cx="50%" cy="50%" transform="translate(-50%, -50%)" r="1" fill="black"></circle> */}

      <text
        x="50%" y="50%"
        textAnchor="middle"
        //alignmentBaseline="central"
        //dominantBaseline="central"
        fill="currentColor"
        style={{ transform: 'translateY(.37em)', fontWeight: 'bold' }}
        fontSize="16px"
      >
        {letter}
      </text>
      
    </svg>
  );
}