import { ReactNode, useContext } from "react";
import InlineFormWrapper from "../../../../../common/forms/inline-form-wrapper/InlineFormWrapper";
import { IUiEditformDictionaryItem } from "./IUiEditformDictionaryItem";
import { EditFormState } from "../../../../../common/edit-form/IEditFormState";
import { IUiOption } from "../../../../../common/options/IUiOption";
import InputText from "../../../../../common/input-v2/input-text/InputText";
import { DictionaryDraftItem, IDictionaryItemStatus } from "../IDictionaryDraftItem";
import { IDictionaryItemValidationState } from "./DictionaryItemValidator";
import DictionaryItemStatus from "../dictionary-item-status/DictionaryItemStatus";
import { DirectoryEditorContext } from "../../../../../../context/directory-editor-context/DirectoryEditorContextProvider";
import InputDropdown from "../../../../../common/input-v2/input-dropdown/InputDropdown";
import { IUiDictionaryItem } from "../../../../../common/dictionaries/IUiDictionaryItem";

interface IProps {
  ui: IUiEditformDictionaryItem;
  data?: EditFormState<DictionaryDraftItem>;
  validationState?: IDictionaryItemValidationState;
  children?: ReactNode; // Children must be one or more Input components
  isReadonly: boolean;
  onParentUpdate?: (parentNodeFullPath?: string) => void;
  onIdUpdate: (value: string) => void;
  onNameUpdate: (value: string) => void;
  onDescriptionUpdate: (value: string) => void;
  onStatusUpdate: (value: IDictionaryItemStatus) => void;
  onConfirm: () => void;
  onClose: () => void;
}

export default function DictionaryItemEditform(props: IProps) {
  const { directoryEditorContext } = useContext(DirectoryEditorContext);
  const dictionaryIsHierarchical = directoryEditorContext.editorState.draft.isHierarchical;
  const idIsReadonly = !!props.data?.object && !props.data?.object.isNew;
  //---------------------------------------------------------------------------
  const parentItemOptions: IUiDictionaryItem[] = [];
  if (dictionaryIsHierarchical) {
    directoryEditorContext.editorState.draft.items.forEach(item => {
        const dictionaryItem = item as DictionaryDraftItem;
        if (dictionaryItem.fullPath === props.data?.object?.fullPath) return;
        if (dictionaryItem.isRepositionedOld) return;
        parentItemOptions.push({
          id: dictionaryItem.fullPath,
          index: 0,
          name: { en: dictionaryItem.fullPath },
          description: { en: dictionaryItem.description }
        });
      });
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionClose":
      case "OptionCancel":
        props.onClose();
        break;
      case "OptionOk":
        props.onConfirm();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionClose":
        if (props.isReadonly) {
          updatedOptions.push({ ...option, iconId: "close" });
        };
        break;
      case "OptionCancel":
        if (!props.isReadonly) {
          updatedOptions.push({ ...option, iconId: "close" });
        };
        break;
      case "OptionOk":
        if (!props.isReadonly) {
          const isDisabled = !(props.data?.isUpdated && props.validationState?.isValid);
          updatedOptions.push({
            ...option,
            iconId: "check",
            isDisabled: isDisabled,
            isDefault: !isDisabled
          });
        };
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <InlineFormWrapper
      ui={{ ...props.ui.menuContent, options: updatedOptions }}
      isUpdated={false}
      onSelect={onOptionSelect}
      onClose={props.onClose}
    >
      <InputText
        key={props.ui.inputId.id}
        ui={{ ...props.ui.inputId, disabled: props.isReadonly || idIsReadonly }}
        data={props.data?.object?.id}
        validationState={props.validationState?.id}
        isFocused={true}
        onUpdate={props.onIdUpdate}
      />
      <InputText
        ui={{ ...props.ui.inputName, disabled: props.isReadonly || !props.data?.object?.id }}
        data={props.data?.object?.name ? props.data.object.name : ""}
        validationState={props.validationState?.name}
        onUpdate={props.onNameUpdate}
      />
      <InputText
        ui={{ ...props.ui.inputDescription, disabled: props.isReadonly || !props.data?.object?.id }}
        data={props.data?.object?.description ? props.data.object.description : ""}
        validationState={props.validationState?.description}
        onUpdate={props.onDescriptionUpdate}
      />

      {dictionaryIsHierarchical && props.onParentUpdate &&
        <InputDropdown
          ui={{ ...props.ui.inputParent, disabled: props.isReadonly }}
          data={props.data?.object?.path}
          isAutoComplete={true}
          hideOptionDescription={true}
          options={parentItemOptions}
          sortOptions={true}
          onUpdate={props.onParentUpdate}
        />}

      {props.children}

      <DictionaryItemStatus
        data={props.data?.object?.status}
        isReadOnly={props.isReadonly}
        onUpdate={props.onStatusUpdate}
      />

    </InlineFormWrapper>
  );
}