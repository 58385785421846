import { ReactNode } from 'react';
import styles from './PopupInformer.module.css';
import PopupTest from '../popup/PopupTest';

// Main feature of informer popup - disappear automatically on timeout

interface IProps {
  //id: string;
  anchorId: string;
  show: boolean;
  children: ReactNode;
  onClose: () => void;
}

export default function PopupInformer(props: IProps) {
  const fadeAwayTimeoutMs = 2000;
  //---------------------------------------------------------------------------
  const onClose = () => {
    console.log("closing...")
    props.onClose();
  }
  //---------------------------------------------------------------------------
  if (props.show)
    return (
      <PopupTest
        id=""
        anchorId={props.anchorId}
        fadeAwayTimeoutMs={fadeAwayTimeoutMs}
        ignoreFullWindowOnMobile={true}
        closeOnClickOutside={true}
        onClose={onClose}
      >
        <div className={styles.container}>
          {props.children}
        </div>
      </PopupTest>
    );
  else return null;
}