import { ChangeEvent, useContext, useRef, useState } from 'react';
import { TUiInputText } from '../../../../common/input/input-text/TUiInputText';
import styles from './Inputs.module.css';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { IUiInputText } from '../../../../common/input-v2/input-text/InputText';
import CombineStyles from '../../../../../utils/combineStyles';
import GetPlaceholder from '../../../../common/functions/GetPlaceholder';
import Icon from '../../../../common/icon/Icon';
import GetHint from '../../../../common/functions/GetHint';

interface IProps {
  ui: TUiInputText;
  searchString?: string;
}

export default function InputKeyword(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [inputEmpty, setInputEmpty] = useState<boolean>(!props.searchString || props.searchString?.length == 0);
  const inputRef = useRef<HTMLInputElement>(null);
  //---------------------------------------------------------------------------
  const onUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0)
      setInputEmpty(false);
    else
      setInputEmpty(true);
  };
  //---------------------------------------------------------------------------
  const onClear = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setInputEmpty(true);
    }
  };
  //---------------------------------------------------------------------------
  const ui: IUiInputText = {
    id: props.ui.id,
    directoryId: props.ui.directoryId,
    elementType: props.ui.elementType,
    caption: props.ui.caption,
    hint: props.ui.hint,
    placeholder: props.ui.placeholder
  }
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <input
        ref={inputRef}
        id={props.ui.id}
        type="text"
        inputMode="text"
        enterKeyHint="search"
        className={CombineStyles([styles.input, inputEmpty ? '' : styles.notEmpty])}
        placeholder={GetPlaceholder(appUiContext, props.ui.id, props.ui.placeholder)}
        title={GetHint(appUiContext, props.ui.id, props.ui.hint)}
        defaultValue={props.searchString ? props.searchString : ""}
        onChange={onUpdate}
      />
      {!inputEmpty &&
        <div
          className={styles.locationIcon}
          onClick={onClear}
        >
          <Icon imageName="close" />
        </div>}
    </div>
  );
}