import { IUiOption } from "../options/IUiOption"

export type TUiIconButton = {
    id: string,
    directoryId: string,
    elementType: string,
    hint: any,
    disabled: boolean,
    visible: boolean,
    iconFile: string,
    inline: boolean,
    action: any
}

export function getTUiIconButton(uiOption: IUiOption): TUiIconButton {
    return {
        id: uiOption.id,
        directoryId: uiOption.directoryId,
        elementType: uiOption.elementType,
        hint: uiOption.hint,
        disabled: !!uiOption.isDisabled,
        visible: true,
        iconFile: uiOption.iconId ? uiOption.iconId : "",
        inline: true,
        action: uiOption.action
    };
}