import { InputValidator } from "../../../../../common/input-v2/input-validation/IInputValidator";
import { IValidationState } from "../../../../../common/input-v2/input-validation/IUiValidation";
import { ValidateUnique } from "../../../../../common/input-v2/input-validation/ValidateInput";
import { DictionaryItemValidator, IDictionaryItemValidationState } from "../../../dictionaries/common-dictionary/common-dictionary-item-editform/DictionaryItemValidator";
import { BusinessTagsDictionaryDraftItem, IBusinessTagsDictionaryDraftItem } from "../IBusinessTagsDictionaryDraftItem";
import { IUiEditformBusinessTagsDictionaryItem } from "./IUiEditformBusinessTagsDictionaryItem";

export interface IBusinessTagsDictionaryItemValidationState extends IDictionaryItemValidationState {
  //subDictionaryId: IValidationState;
}

export class BusinessTagsDictionaryItemValidator extends DictionaryItemValidator {
  subDictionaryId!: InputValidator;
  //---------------------------------------------------------------------------
  constructor(ui: IUiEditformBusinessTagsDictionaryItem) {
    super(ui);
    this.subDictionaryId = new InputValidator({
      elementId: ui.inputDictionary.id,
      inputType: "InputText",
      validation: ui.inputDictionary.validation
    });
  };
  //---------------------------------------------------------------------------
  initialize(data?: BusinessTagsDictionaryDraftItem): IBusinessTagsDictionaryItemValidationState {
    // We pass isUnique = true so it would validate data first and then we can check for uniqueness
    const validationStateId: IValidationState = this.id.validate(data?.id, true);
    const validationStateName: IValidationState = this.name.validate(data?.name, true);
    const validationStateDescription: IValidationState = this.description.validate(data?.description, true);
    //const validationStateSubDictionaryId: IValidationState = this.subDictionaryId.validate(data?.subDictionaryId, true);
    //-------------------------------------------------------------------------
    return this.getBusinessTagsDictionaryItemValidationState(validationStateId, validationStateName, validationStateDescription);
  };
  //---------------------------------------------------------------------------
  getBusinessTagsDictionaryItemValidationState(
    id: IValidationState,
    name: IValidationState,
    description: IValidationState,
    //subDictionaryId: IValidationState
  ): IBusinessTagsDictionaryItemValidationState {
    return {
      id: id,
      name: name,
      description: description,
      //subDictionaryId: subDictionaryId,
      isValid: id.isValid && name.isValid && description.isValid
    };
  };
  //---------------------------------------------------------------------------
  validateId(
    guid: string,
    value: string,
    validationState: IBusinessTagsDictionaryItemValidationState,
    dictionaryItems: IBusinessTagsDictionaryDraftItem[]
  ): IBusinessTagsDictionaryItemValidationState {
    let idIsUnique = true;
    // Validate the value itself first
    const validationStateId: IValidationState = this.id.validate(value, idIsUnique);
    // Validate uniqueness if the value is valid
    if (validationStateId.isValid && this.id.validation?.unique && dictionaryItems) {
      const duplicate = dictionaryItems.find(item => item.id.toLowerCase() === value.toLowerCase() && item.guid != guid);
      ValidateUnique({
        elementId: this.id.elementId,
        validation: this.id.validation,
        data: value,
        isUnique: !duplicate,
        validationState: validationStateId
      });
    };
    //-------------------------------------------------------------------------
    return this.getBusinessTagsDictionaryItemValidationState(
      validationStateId,
      validationState.name,
      validationState.description
    );
  };
  //---------------------------------------------------------------------------
  validateName(
    guid: string,
    value: string,
    validationState: IBusinessTagsDictionaryItemValidationState,
    dictionaryItems: IBusinessTagsDictionaryDraftItem[]
  ): IBusinessTagsDictionaryItemValidationState {
    let nameIsUnique = true;
    const validationStateName: IValidationState = this.name.validate(value, nameIsUnique);
    if (validationStateName.isValid && this.name.validation?.unique && dictionaryItems) {
      const duplicate = dictionaryItems.find(item => item.name?.toLowerCase() === value.toLowerCase() && item.guid != guid);
      ValidateUnique({
        elementId: this.name.elementId,
        validation: this.name.validation,
        data: value,
        isUnique: !duplicate,
        validationState: validationStateName
      });
    };
    //-------------------------------------------------------------------------
    return this.getBusinessTagsDictionaryItemValidationState(
      validationState.id,
      validationStateName,
      validationState.description
    );
  };
  //---------------------------------------------------------------------------
  validateDescription(
    guid: string,
    value: string,
    validationState: IBusinessTagsDictionaryItemValidationState,
    dictionaryItems: IBusinessTagsDictionaryDraftItem[]
  ): IBusinessTagsDictionaryItemValidationState {
    let isUnique = true;
    const validationStateDescription: IValidationState = this.description.validate(value, isUnique);
    if (validationStateDescription.isValid && this.description.validation?.unique && dictionaryItems) {
      const duplicate = dictionaryItems.find(item => item.name?.toLowerCase() === value.toLowerCase() && item.guid != guid);
      ValidateUnique({
        elementId: this.name.elementId,
        validation: this.name.validation,
        data: value,
        isUnique: !duplicate,
        validationState: validationStateDescription
      });
    };
    //-------------------------------------------------------------------------
    return this.getBusinessTagsDictionaryItemValidationState(
      validationState.id,
      validationState.name,
      validationStateDescription
    );
  };
}