import React, { useContext, useEffect } from "react";
import { IUiBusinessOwnerConsole } from "./IUiBusinessOwnerConsole";
import { getTUiMenuContent } from "../../../common/menu/menu-content/TUiMenuContent";
import { TUiBreadcrumb } from "../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import ConsoleLayout from "../../../common/console-layout/ConsoleLayout";
import { Route, Routes } from "react-router";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import BusinessList from "../business-list/BusinessList";
import NavigateWithContext from "../../../common/navigate-with-context/NavigateWithContext";
import { pathBusinessConsole, pathBusinessOwnerConsole, pathBusinesses, pathMessages, pathStats, pathWallet } from "../BusinessConsole";
import { BusinessStats } from "../business-stats/BusinessStats";
import { IUiOption } from "../../../common/options/IUiOption";

interface IProps {
  ui?: IUiBusinessOwnerConsole;
}

export default function BusinessOwnerConsole(props: IProps) {
  const { appUiContextDispatch } = useContext(AppUiContextStore);
  const pathRoot = `${pathBusinessConsole}/${pathBusinessOwnerConsole}`;
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    console.log(props.ui)
    if (props.ui?.breadcrumb) {
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: props.ui.breadcrumb });
    };
  }, []);
  //--------------------------------------------------------------------------- Breadcrumbs for child routes
  let breadcrumbBusinessList: TUiBreadcrumb | undefined = undefined;
  let breadcrumbMessages: TUiBreadcrumb | undefined = undefined;
  let breadcrumbWallet: TUiBreadcrumb | undefined = undefined;
  let breadcrumbStats: TUiBreadcrumb | undefined = undefined;
  //--------------------------------------------------------------------------- Update menu content
  const updatedOptions: IUiOption[] = [];
  props.ui?.menuContent?.options.forEach(option => {
    switch (option.id) {
      //-------------------------------------------------------------------
      case "BusinessOwnerConsole.MenuContent.OptionBusinesses":
        option.iconId = "cases";
        option.action = `/${pathRoot}/${pathBusinesses}`;
        breadcrumbBusinessList = {
          id: option.id,
          caption: option.caption,
          path: `/${pathRoot}/${pathBusinesses}`
        };
        updatedOptions.push(option);
        break;
      //-------------------------------------------------------------------
      case "BusinessOwnerConsole.MenuContent.OptionMessages":
        option.iconId = "messages";
        option.action = `/${pathRoot}/${pathMessages}`;
        breadcrumbMessages = {
          id: option.id,
          caption: option.caption,
          path: option.action
        };
        updatedOptions.push(option);
        break;
      //-------------------------------------------------------------------
      /* case "BusinessOwnerConsole.MenuContent.OptionWallet":
        option.iconId = "wallet";
        option.action = `/${pathRoot}/${pathWallet}`;
        breadcrumbWallet = {
          id: option.id,
          caption: option.caption,
          path: option.action
        };
        updatedOptions.push(option);
        break;
      //-------------------------------------------------------------------
      case "BusinessOwnerConsole.MenuContent.OptionStats":
        option.iconId = "stats";
        option.action = `/${pathRoot}/${pathStats}`;
        breadcrumbStats = {
          id: option.id,
          caption: option.caption,
          path: option.action
        };
        updatedOptions.push(option);
        break; */
    };
  });
  const updatedMenuContent = props.ui?.menuContent ? getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions }) : undefined;
  //---------------------------------------------------------------------------
  if (updatedMenuContent)
    return (
      <ConsoleLayout
        ui={{ header: "", menuContent: updatedMenuContent }}
        path={pathBusinessOwnerConsole}
        hideSidebar={true}
      >
        <Routes>

          <Route
            index
            element={<NavigateWithContext to={`/${pathRoot}/${pathBusinesses}`} />}
          />

          {props.ui?.businessList &&
            <Route
              path={pathBusinesses}
              element={
                <BusinessList ui={{
                  ...props.ui.businessList,
                  breadcrumb: breadcrumbBusinessList
                }} />}
            />}

          {<Route
            path={pathStats}
            element={<BusinessStats />}
          />}
        </Routes>
      </ConsoleLayout>
    );
  else return null;
}