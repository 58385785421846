import { IEditFormState } from "../../../../../common/edit-form/IEditFormState";
import { DraftItemStateEnum } from "../../../../common/dictionary-draft-models/DraftItemStateEnum";
import { AdRanksDictionaryDraftItem, IAdRanksDictionaryDraftItem } from "../AdRanksDictionaryDraft";

export interface IEditFormStateInitial {
  draftItem?: IAdRanksDictionaryDraftItem;
}

export interface IEditFormStateUpdate {
  state: AdRanksEditFormState;
  spread: any;
}

export class AdRanksEditFormState implements IEditFormState<AdRanksDictionaryDraftItem> {
  object?: AdRanksDictionaryDraftItem;
  initialState?: AdRanksDictionaryDraftItem;
  isNew?: boolean;
  isUpdated?: boolean;
  isValid!: boolean;
  //---------------------------------------------------------------------------
  constructor(
    initialData?: IEditFormStateInitial,
    update?: IEditFormStateUpdate
  ) {
    if (initialData) {
      // Initialisation
      if (initialData.draftItem) {
        //-------------------------------
        // Edit existing item
        this.object = new AdRanksDictionaryDraftItem(initialData.draftItem);
        this.isNew = false;
      } else {
        //-------------------------------
        // New item
        this.object = new AdRanksDictionaryDraftItem({
          id: "",
          index: 0,
          deleted: false,
          pointsPerView: 0,
          pointsPerEngagement: 0,
          draftState: DraftItemStateEnum.IsNew
        });
        this.isNew = true;
      };
      this.initialState = JSON.parse(JSON.stringify(this.object));
    } else if (update) {
      //-----------------------------------------------------------------------
      // Update
      Object.assign(this, update.state, update.spread);
      this.isUpdated = false;
      //-------------------------------------------------------------------------
      // Check changes
      const current = this.object as AdRanksDictionaryDraftItem;
      const initial = this.initialState as AdRanksDictionaryDraftItem;
      console.log(current.name != initial.name)
      if (current.id != initial?.id ||
        current.name != initial.name ||
        current.description != initial.description ||
        current.pointsPerView != initial.pointsPerView ||
        current.pointsPerEngagement != initial.pointsPerEngagement
      ) {
        this.isUpdated = true;
      }
    } else console.error("AdRanksEditFormState: Invalid constructor call");
    //-------------------------------------------------------------------------
    // Check validity
    this.isValid = (this.object as AdRanksDictionaryDraftItem).isValid();
  };
}