import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathDeleteAdCampaign } from "../../../utils/apiPathConstant";
import { apiDeletePrivate, checkResponse } from "../../../utils/api";
import { stringFormatter } from "../../../utils/common";
import { ClassCustomerBusiness, ICustomerBusiness } from "../business-editor/ICustomerBusiness";

interface IProps {
  msalInstanse: IPublicClientApplication,
  authConfig?: IAuthConfig,
  businessId?: string,
  adCampaignId: string,
  abortSignal?: AbortSignal
}

export default async function deleteAdCampaignAsync(props: IProps): Promise<ClassCustomerBusiness> {
  if (!props.authConfig) throw new Error("deleteAdCampaignAsync: auth config is not provided");
  if (!props.businessId) throw new Error("deleteAdCampaignAsync: business Id is not provided");
  //---------------------------------------------------------------------------
  const response = await apiDeletePrivate(
    props.msalInstanse,
    props.authConfig,
    `${apiBasePath}${stringFormatter(pathDeleteAdCampaign, [props.businessId, props.adCampaignId])}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "deleteAdCampaign", props.abortSignal?.aborted);
  const business = new ClassCustomerBusiness(response?.content as ICustomerBusiness);
  return business;
};