import { IUiInteractiveForm } from "../../components/common/forms/IUiInteractiveForm";
import { EPopupType } from "../../components/common/popup-v2/popup-message/PopupMessage";

//export type ENotificationType = "Confirmation" | "Error" | "ErrorAlert";

export interface INotificationContextData {
  unsavedChanges?: IUnsavedChangesContext;
  error?: IErrorContext;
}

export interface IUnsavedChangesContext {
  ui?: IUiInteractiveForm;
  notificationType?: EPopupType;
  selectedOpionId?: string;
  confirmOptionId?: string;
  showNotification?: boolean;
  pathUnsavedChanges?: string; // Path that has unsaved changes
  pathDestination?: string; // Path where user wants to navigate to
  stateInitial?: IUnsavedChangesContextInitial;
  callback?: (selectedOptionId: string, state: INotificationContextData) => void;
}

export interface IUnsavedChangesContextInitial {
  ui: IUiInteractiveForm;
  notificationType: EPopupType;
  pathUnsavedChanges?: string;
  confirmOptionId?: string;
  callback?: (selectedOptionId: string, state: INotificationContextData) => void;
}

export interface IErrorContext {
  header: string;
  message: string;
}

export const errorUi: IUiInteractiveForm = {
  id: "ErrorMessage",
  directoryId: "",
  elementType: "",
  caption: undefined,
  hint: undefined,
  menuContent: {
    id: "ErrorMessage.MenuContent",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionCopy",
      directoryId: "",
      elementType: "",
      caption: { en: "Copy" },
      hint: undefined
    }, {
      id: "OptionClose",
      directoryId: "",
      elementType: "",
      caption: { en: "Close" },
      hint: undefined
    }]
  }
}