import { AzureStorageServiceHealthStatus } from "./RikiWikiHealthStatus";
import WikiSpinner from "../app-layout/spinner/wikiSpinner";
import { HealthGauge } from "./HealthGauge";
import styles from "./HealthStatus.module.css";

interface IProps {
  status?: AzureStorageServiceHealthStatus
  spinner: boolean;
}

export function HealthStatusStorage(props: IProps) {

  //console.log("props.spinner:", props.spinner);

  return (
    <div className={styles.container}>
      <WikiSpinner show={props.spinner} />
      <div className={styles.header}>
        {props.status ? props.status.serviceName : "Unknown Service"}
      </div>
      {!props.status &&
        <div className={styles.statusContainer}>
          Unknown Service status is undefined
        </div>
      }

      {props.status &&
        <div className={styles.statusContainer}>
          <HealthGauge status={props.status} spinner={props.spinner} />
          <div>
            {!props.spinner
              ? `Containers: ${props.status.containers}`
              : `Containers:`}
          </div>
        </div>
      }
    </div>

  );
}