import React, { useContext } from 'react';
import { EditFormSection } from '../../../common/console-layout/edit-form-sections/EditFormSection';
import GetHint from '../../../common/functions/GetHint';
import { IUiSectionTags } from './IUiSectionTags';
import styles from './SectionTags.module.css';
import BusinessTagsSelector from '../../../business-console/business-tags-selector/BusinessTagsSelector';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { BusinessContext } from '../../../../context/business-context/BusinessContextProvider';
import { ClassCustomerBusiness } from '../ICustomerBusiness';

interface IProps {
  id: string;
  ui: IUiSectionTags;
}

export default function SectionTags(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const business = businessContext.businessState?.business as ClassCustomerBusiness;
  //---------------------------------------------------------------------------
  const onUpdate = (updatedTags?: string[]) => {
    console.log(updatedTags)
    const business = businessContext.businessState?.business as ClassCustomerBusiness;
    if (business) {
      const updatedBusiness = new ClassCustomerBusiness(business);
      updatedBusiness.tags = updatedTags;
      //console.log(updatedBusiness)
      //isValid = BusinessTagsTreeIsValid(updatedBusiness.tags);
      businessContextDispatch({ type: "UpdateBusiness", business: updatedBusiness });
    };
  };
  //---------------------------------------------------------------------------
  return (
    <EditFormSection
      id={props.id}
      caption={GetHint(appUiContext, props.ui.id, props.ui.hint)}
    >
      {props.ui.selectorBusinessTags && business &&
        <BusinessTagsSelector
          ui={props.ui.selectorBusinessTags}
          selectedtags={business.tags}
          onUpdate={onUpdate}
        />}
    </EditFormSection>
  );
}