import React, { useEffect, useState } from 'react';
import { IDictionaryDraftItemStateGroup } from '../../common/settings/IDictionaryDraftItemStateGroup';
import DictionaryEditorNodeWrapper from '../../common/dictionary-node-wrapper/DictionaryEditorNodeWrapper';
import RegionEditForm from '../region-edit-form/RegionEditForm';
import { INewRegionData } from '../RegionsEditorV1';
import { IDictionaryDraftFilter } from '../../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter';
import { RegionsDictionaryDraftItem } from './RegionsEditorNode';
import DictionaryNodeStatus from '../../common/dictionary-node-wrapper/dictionary-node-status/DictionaryNodeStatus';

interface IProps {
  stateGroups?: IDictionaryDraftItemStateGroup[];
  data: RegionsDictionaryDraftItem;
  items?: RegionsDictionaryDraftItem[];
  indent?: number;
  editModeIsEnabled?: boolean;
  rootIsLocked?: boolean;
  newSubRegion?: INewRegionData;
  filter?: IDictionaryDraftFilter;
  onUpdate: (updatedDraftItem: RegionsDictionaryDraftItem) => void;
  onDelete: (id: string) => void;
  onSubRegionAdd?: (parentPath?: string) => void;
  onSubRegionAdded: (subRegion: RegionsDictionaryDraftItem) => void;
}

export function RegionsEditorNodeV2(props: IProps) {
  const [collapsed, setCollapsed] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newSubRegion, setNewSubRegion] = useState(false);
  const editModeIsEnabled = !!props.editModeIsEnabled;
  const rootIsLocked = !!props.rootIsLocked;
  const isExpandable = props.items && (props.items.length > 0);
  const indent = props.indent ? props.indent : 0;
  //---------------------------------------------------------------------------
  useEffect(() => {
    let path = props.data.getPathForChilds();
    if (props.newSubRegion && props.newSubRegion.path == path) {
      setNewSubRegion(true);
    };
  }, [props.newSubRegion]);
  //---------------------------------------------------------------------------
  const onDelete = () => {
    props.onDelete(props.data.id);
  };
  //---------------------------------------------------------------------------
  const onDeactivate = () => {
    // If it's a existing item, mark it as deleted
    const updatedItem = new RegionsDictionaryDraftItem(props.data);
    updatedItem.deactivate();
    props.onUpdate(updatedItem);
  };
  //---------------------------------------------------------------------------
  const onActivate = () => {
    const updatedItem = new RegionsDictionaryDraftItem(props.data);
    updatedItem.activate();
    props.onUpdate(updatedItem);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedItem: RegionsDictionaryDraftItem) => {
    setEditMode(false);
    updatedItem.refresh("en");
    props.onUpdate(updatedItem);
  };
  //---------------------------------------------------------------------------
  const onClose = () => {
    setEditMode(false);
  };
  //------------------------------------------------ Start adding new sub-region
  const onSubRegionAdd = () => {
    props.onSubRegionAdd && props.onSubRegionAdd(props.data.getPathForChilds());
  };
  //------------------------------------------------------- New sub-region added
  const onSubRegionAddCompleted = (newSubRegion: RegionsDictionaryDraftItem) => {
    setNewSubRegion(false);
    props.onSubRegionAdded(newSubRegion);
  };
  //---------------------------------------------------------------------------
  const onSubRegionAddCancelled = () => {
    setNewSubRegion(false);
  };
  //---------------------------------------------------------------------------
  const onToggle = () => {
    setCollapsed(!collapsed);
  };
  //---------------------------------------------------------------------------
  const childNodes = props.data.filterDirectSiblings(props.items);
  //---------------------------------------------------------------------------
  let childNodesToDisplay = childNodes?.map(subRegion => {
    //subRegion = new RegionsDictionaryDraftItem(subRegion);
    const allNodesUnderThis = subRegion.filterAllNodesUnderThis(props.items);
    return (
      <RegionsEditorNodeV2
        key={subRegion.id}
        stateGroups={props.stateGroups}
        data={subRegion}
        items={allNodesUnderThis}
        editModeIsEnabled={props.editModeIsEnabled}
        indent={indent + 1}
        newSubRegion={props.newSubRegion}
        onUpdate={props.onUpdate}
        onDelete={props.onDelete}
        onSubRegionAdd={props.onSubRegionAdd}
        onSubRegionAdded={props.onSubRegionAdded}
      />);
  });
  //---------------------------------------------------------------------------
  if (newSubRegion) {
    if (!childNodesToDisplay)
      childNodesToDisplay = [];
    childNodesToDisplay.push(
      <RegionEditForm
        key="NewSubRegion"
        newRegionData={props.newSubRegion}
        onUpdate={onSubRegionAddCompleted}
        onClose={onSubRegionAddCancelled}
      />
    );
  };
  //---------------------------------------------------------------------------
  if (props.data) return (
    <React.Fragment>
      {!editMode ?
        <React.Fragment>
          <DictionaryEditorNodeWrapper
            data={props.data}
            status={props.data.status}
            showStatus={true}
            stateGroups={props.stateGroups}
            isHierarchical={true}
            isCollapsed={collapsed}
            isOrderedManually={false}
            editModeIsEnabled={editModeIsEnabled}
            indent={indent}
            onToggle={isExpandable ? onToggle : undefined}
            onEdit={() => setEditMode(true)}
            onDelete={onDelete}
            onDeactivate={rootIsLocked ? undefined : onDeactivate}
            onActivate={onActivate}
            onAddChild={onSubRegionAdd}
          />
          {!collapsed &&
            childNodesToDisplay}
        </React.Fragment>
        :
        <RegionEditForm
          data={new RegionsDictionaryDraftItem(props.data)}
          rootIsLocked={rootIsLocked}
          onUpdate={onUpdate}
          onClose={onClose}
        />}
    </React.Fragment>
  );
  else return null;
}