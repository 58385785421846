import { TUiStaffRole } from "../administrator-console/TUiAdministratorConsole";
import { ApprovalRequest, IApprovalRequest } from "../moderator-console/approval-request-form/TApprovalRequest";
import { TDirectoryItem } from "../TUiAdminConsole";
import { TAdminConsoleContext } from "./AdminConsoleContextProvider";

interface ISetApprovalRequestStatusDictionary {
  type: "SetApprovalRequestStatusDictionary",
  value: TDirectoryItem[]
}
interface ISetRequestRejectionReasonDictionary {
  type: "SetRequestRejectionReasonDictionary",
  value: TDirectoryItem[]
}
//--------------------------------------------
interface ISetApprovalRequestList {
  type: "SetApprovalRequestList",
  value: IApprovalRequest[]
}
//--------------------------------------------
interface IUpdateApprovalRequestList {
  type: "UpdateApprovalRequestList",
  value: IApprovalRequest
}
//--------------------------------------------
interface ISetRefreshRequestList {
  type: "SetRefreshRequestList",
  value: boolean;
}
//--------------------------------------------
interface IReplaceRequest {
  type: "ReplaceRequest",
  oldRequestId: string;
  newRequest: ApprovalRequest;
}
//--------------------------------------------
interface ISetUserListFilterRole {
  type: "SetUserListFilterRole",
  value: TUiStaffRole;
}
//-------------------------------------------- not used for now
// interface ISetBusinessTags {
//   type: "SetBusinessTags",
//   value: IUiDictionaryEditorItem[];
// }

export type Actions = ISetApprovalRequestStatusDictionary | ISetRequestRejectionReasonDictionary |
  ISetApprovalRequestList | IUpdateApprovalRequestList |
  ISetRefreshRequestList | ISetUserListFilterRole | IReplaceRequest;

export const AdminConsoleContextReducer = (state: TAdminConsoleContext, action: Actions) => {
  var updatedState: TAdminConsoleContext;
  switch (action.type) {
    //-------------------------------------------------------------------------
    case "SetApprovalRequestStatusDictionary":
      console.log("Setting ApprovalRequestStatusDictionary");
      return {
        ...state,
        approvalRequestStatusDictionary: action.value
      };
    //-------------------------------------------------------------------------
    case "SetRequestRejectionReasonDictionary":
      console.log("Setting RequestRejectionReasonDictionary");
      return {
        ...state,
        approvalRequestRejectionReasonDictionary: action.value
      };
    //-------------------------------------------------------------------------
    case "SetApprovalRequestList":
      console.log("Setting 'ApprovalRequestList' ");
      return {
        ...state,
        approvalRequestList: action.value
      };
    //-------------------------------------------------------------------------
    case "UpdateApprovalRequestList": {
      console.warn("Updating Approval Request list...", action.value.status);
      const updatedList = state.approvalRequestList.map((request) =>
        (request.adPageId === action.value.adPageId ? action.value : request));
        //(request.adPageId === state.approvalRequest?.adPageId ? state.approvalRequest : request));
      //-----------------------------------------------------------------------
      return {
        ...state,
        approvalRequestList: updatedList
      };
    }
    //-------------------------------------------------------------------------
    case "SetRefreshRequestList":
      console.log("Setting 'NeedToRefreshRequestList' to ");
      return {
        ...state,
        needToRefreshRequestList: action.value
      };
    //-------------------------------------------------------------------------
    case "SetUserListFilterRole":
      updatedState = {
        ...state,
        userListFilterRole: action.value
      };
      return updatedState;
    //-------------------------------------------------------------------------
    case "ReplaceRequest":
      updatedState = { ...state };
      let index = updatedState.approvalRequestList.findIndex(item => item.adPageId == action.oldRequestId);
      if (index >= 0)
        updatedState.approvalRequestList[index] = action.newRequest;
      return updatedState;
    //-------------------------------------------------------------------------
    // case "SetBusinessTags":
    //   updatedState = {
    //     ...state,
    //     businessTags: action.value
    //   };
    //   return updatedState;
  }
};