import { useContext, useEffect, useRef, useState } from 'react';
import CampaignWrapper from '../../../../common/business/campaign-wrapper/CampaignWrapper';
import { IUiListItemAdCampaign } from './IUiListItemAdCampaign';
import { IUiMenuContent } from '../../../../common/options/menus/IUiMenuContent';
import AdCampaignHeader from './adcampaign-header/AdCampaignHeader';
import AdPagesList from '../../../../common/business/adpages-list/AdPagesList';
import deleteAdCampaignAsync from '../../../functions/deleteAdCampaignAsync';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import useNavigateWithContext from '../../../../../hooks/useNavigateWithContext';
import { pathAdpageWizard, pathAds, pathBusinessConsole, pathBusinessOwnerConsole, pathBusinesses } from '../../../business-console/BusinessConsole';
import PopupAdPageLanguage, { INewAdPageState } from '../../../adpage-editor/popup-adpage-language/PopupAdPageLanguage';
import { generatePath } from 'react-router-dom';
import { useAbortController } from '../../../../../hooks/useAbortController';
import { IAdLanguageSetListItem } from '../../../../ad-content-editor/IAdLanguageSet';
import { BusinessContext } from '../../../../../context/business-context/BusinessContextProvider';
import { IAdCampaign } from '../../../../../context/business-context/IAdCampaign';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { activateAdLanguageSetAsync } from '../../../../../context/business-context/functions/activateAdLanguageSetAsync';
import { deleteAdLanguageSetAsync } from '../../../../../context/business-context/functions/deleteAdLanguageSetAsync';
import { createAdLanguageSetAsync } from '../../../../../context/business-context/functions/createAdLanguageSetAsync';
import { deactivateAdLanguageSetAsync } from '../../../../../context/business-context/functions/deactivateAdLanguageSetAsync';
import PopupMessage from '../../../../common/popup-v2/popup-message/PopupMessage';
import GetCaption from '../../../../common/functions/GetCaption';
import GetHint from '../../../../common/functions/GetHint';
import { IUiInteractiveForm } from '../../../../common/forms/IUiInteractiveForm';
import { getTUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import saveBusinessAsync from '../../../../../context/business-context/functions/saveBusinessAsync';
import { ImageEditorContext } from '../../../../common/image-editor/image-editor-context/ImageEditorContextProvider';
import { ClassCustomerBusiness } from '../../ICustomerBusiness';
import { pathBranding, pathContacts, pathLocation, pathTags } from '../../BusinessEditor';
import { NotificationContext } from '../../../../../context/notification-context/NotificationContextProvider';

const componentName = "Ad Campaign Editor";

interface IBusinessInvalid {
  popupOpen: boolean;
  path?: string;
}

interface IProps {
  ui: IUiListItemAdCampaign;
  data: IAdCampaign;
  isCollapsed: boolean;
}

export default function AdCampaignListItem(props: IProps) {
  const { instance } = useMsal();
  const { appUiContext } = useContext(AppUiContextStore);
  const { appAuthContext, appAuthContextDispatch } = useContext(AppAuthContextStore);
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const { imageEditorContext } = useContext(ImageEditorContext);
  const { notificationContextDispatch } = useContext(NotificationContext);
  const [adListItems, setAdListItems] = useState<IAdLanguageSetListItem[]>([]);
  const [newAdPageState, setNewAdPageState] = useState<INewAdPageState>();
  const [businessInvalid, setBusinessInvalid] = useState<IBusinessInvalid>();
  const [businessSaveConfirmationOpen, setBusinessSaveConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigateWithContext();
  const abortController = useAbortController("AdCampaignListItem");
  const adPageIdToOpen = useRef<string | undefined>(undefined);
  const cantDelete = businessContext.ads && businessContext.ads.findIndex(ad => ad.adCampaignId == props.data.id && ad.status.isReadonly) > -1;
  //--------------------------------------------------------------------------- AbortController
  useEffect(() => {
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //--------------------------------------------------------------------------- Ads
  useEffect(() => {
    const campaignAds = businessContext.ads ? businessContext.ads.filter(item => item.adCampaignId == props.data.id) : [];
    setAdListItems(campaignAds);
  }, [businessContext.ads]);
  //--------------------------------------------------------------------------- Navigate to ad content editor
  useEffect(() => {
    if (!businessContext.lastAction) return;
    if (businessContext.lastAction.type != "SetAdLanguageSet") return;
    if (businessContext.lastAction.adLanguageSet.id == adPageIdToOpen.current) {
      console.log("AdCampaignListItem.useEffect[lastAction]:", adPageIdToOpen.current);
      adPageIdToOpen.current = undefined;
      onAdLanguageSetOpen(businessContext.lastAction.adLanguageSet.id);
    };
  }, [businessContext.lastAction]);
  //---------------------------------------------------------------------------
  const saveBusiness = () => {
    if (!businessContext.businessState) return;
    setIsLoading(true);
    let controller = abortController.newController("saveBusiness");
    const businessToSave = businessContext.businessState.business as ClassCustomerBusiness;
    saveBusinessAsync({
      msalInstance: instance,
      authContext: appAuthContext,
      imageEditorContext: imageEditorContext,
      business: businessToSave,
      abortSignal: controller.signal
    }).then(response => {
      // Update business context
      businessContextDispatch({ type: "SetBusiness", business: response.updatedBusiness });
      //-----------------------------------------------------------------------
      // Update user profile
      if (response.updatedUserRoles)
        appAuthContextDispatch({ type: "UpdateUserRoles", value: response.updatedUserRoles });
      //-----------------------------------------------------------------------
      // Update business tag requests, if there are any
      if (response.updatedBusinessTagRequests) {
        businessContextDispatch({ type: "SetBusinessTagRequests", requests: response.updatedBusinessTagRequests });
      };
      //-----------------------------------------------------------------------
      // Update business editor UI
      navigate(`${pathBusinessConsole}/${businessContext.userRole}/${pathBusinesses}/${businessToSave.id}`);
    }).catch(error => {
      //console.error(`saveBusiness:`, error);
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName + " (Save Business)", message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //--------------------------------------------------------------------------- Ad Campaign
  const onAdCampaignDelete = () => {
    setIsLoading(true);
    let controller = abortController.newController("deleteAdCampaign");
    deleteAdCampaignAsync({
      msalInstanse: instance,
      authConfig: appAuthContext.config,
      businessId: businessContext.businessState?.business.id,
      adCampaignId: props.data.id,
      abortSignal: controller.signal
    }).then(updatedBusiness => {
      businessContextDispatch({ type: "SetBusiness", business: updatedBusiness });
    }).catch(error => {
      //console.error(error);
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //--------------------------------------------------------------------------- Ad Page
  const onAdPageWizard = () => {
    //console.log(props.data)
    // Update context
    //commonBusinessContextDispatch({ type: "SetAdCampaign", value: props.data });
    // Navigate to ad page wizard
    //navigate(`/${pathBusinessConsole}/${pathAdpageWizard}`);
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetOpen = (adLanguageSetId: string) => {
    if (!businessContext.basePath) return;
    if (!businessContext.businessState?.business) return;
    navigateToContentEditor(
      businessContext.basePath,
      businessContext.businessState.business.id,
      adLanguageSetId);
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetEdit = (adLanguageSetId: string) => {
    if (!businessContext.basePath) return;
    if (!businessContext.businessState) return;
    if (!businessContext.ads) return;
    //-------------------------------------------------------------------
    // Find the ad language set
    const selectedAdLanguageSet = businessContext.ads.find(adp => adp.id == adLanguageSetId);
    if (!selectedAdLanguageSet)
      return console.error(`AdCampaignCard: Ad language set Id=[${adLanguageSetId}] not found`);
    //-------------------------------------------------------------------
    // Check if ad page is not read-only (otherwise we need to create a copy to edit)
    if (!selectedAdLanguageSet.status.isReadonly) {
      return onAdLanguageSetOpen(adLanguageSetId);
    };
    //-------------------------------------------------------------------
    // Check if there is a draft already for the language
    const draftpage = businessContext.ads.find(adp => (adp.localeId == selectedAdLanguageSet.localeId) && !adp.status.isReadonly);
    if (draftpage) {
      // Open the draft
      return onAdLanguageSetOpen(draftpage.id);
    };
    //-------------------------------------------------------------------
    // Create a draft copy of the selected page
    setIsLoading(true);
    let controller = abortController.newController("createAdPageAsync");
    createAdLanguageSetAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      businessType: businessContext.businessState.business.businessType,
      businessId: businessContext.businessState.business.id,
      adCampaignId: props.data.id,
      localeId: selectedAdLanguageSet.localeId,
      originalAdLanguageSetId: selectedAdLanguageSet.id,
      abortSignal: controller.signal
    }).then(adLanguageSet => {
      businessContextDispatch({ type: "SetAdLanguageSet", adLanguageSet: adLanguageSet });
      //-----------------------------------------------------------------------
      // Navigate to ad content editor
      if (!businessContext.basePath) return;
      if (!businessContext.businessState?.business) return;
      navigateToContentEditor(
        businessContext.basePath,
        businessContext.businessState.business.id,
        adLanguageSet.id);
    }).catch(error => {
      console.error(error);
    }).finally(() =>
      !controller.aborted && setIsLoading(false)
    );
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetCreate = (originalAdLanguageSetId?: string) => {
    if (businessContext.businessState?.isNew) {
      // If business is new, check if it's valid: name, locations, contacts
      const business = businessContext.businessState.business as ClassCustomerBusiness;
      if (!business.name) {
        setBusinessInvalid({
          popupOpen: true,
          path: pathBranding
        });
      } else if (business.locationData?.locations.length == 0) {
        setBusinessInvalid({
          popupOpen: true,
          path: pathLocation
        });
      } else if (business.contacts.length == 0) {
        setBusinessInvalid({
          popupOpen: true,
          path: pathContacts
        });
      } else if (business.tags?.length == 0) {
        setBusinessInvalid({
          popupOpen: true,
          path: pathTags
        });
      } else {
        // Ask user if they want to save the business first
        setBusinessSaveConfirmationOpen(true);
      };
      return;
    };
    //-------------------------------------------------------------------------
    // Ask user to select ad page locale
    // Determine available languages list:
    // language is unavailable if there is a draft page for the language
    // published pages are ignored because we can create copies for published pages 
    // NOTE: this code assumes that you can create a copy here only if it's a translation to another language
    // otherwise it would be edit without selecting language
    // or it's a first ADL in campaign and so there are no unavailable locales, because there are no other ADLs
    const supportedLocales = appUiContext.dictionaries?.supportedLocales;
    const unavailableLocales = adListItems
      .filter(adsListItem => !adsListItem.status.isReadonly || adsListItem.id == originalAdLanguageSetId)
      .map(adsListItem => adsListItem.localeId);
    const availableLocales = supportedLocales?.filter(item => !unavailableLocales?.includes(item.id));
    setNewAdPageState({
      popupIsOpen: true,
      availableLocales: availableLocales ? availableLocales : [],
      originalAdPageId: originalAdLanguageSetId
    });
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetCreateCancel = () => {
    setNewAdPageState(undefined);
  };
  //---------------------------------------------------------------------------
  const onBusinessInvalidOptionSelect = (optionId: string) => {
    const path = businessInvalid?.path;
    setBusinessInvalid(undefined);
    switch (optionId) {
      case "OptionOk":
        navigate(`${pathBusinessConsole}/${pathBusinessOwnerConsole}/${pathBusinesses}/${businessContext.businessState?.business.id}/${path}`);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onBusinessSaveConfirmationOptionSelect = (optionId: string) => {
    setBusinessSaveConfirmationOpen(false);
    switch (optionId) {
      case "OptionSave":
        saveBusiness();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const createNewAdLanguageSet = (selectedLocale: string) => {
    if (!businessContext.businessState?.business) return;
    // Close select language dialog
    setNewAdPageState({ ...newAdPageState as INewAdPageState, popupIsOpen: false });
    //-------------------------------------------------------------------------
    // Call API
    setIsLoading(true);
    let controller = abortController.newController("createAdPageAsync");
    createAdLanguageSetAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      businessType: businessContext.businessState.business.businessType,
      businessId: businessContext.businessState.business.id,
      adCampaignId: props.data.id,
      localeId: selectedLocale,
      originalAdLanguageSetId: newAdPageState?.originalAdPageId,
      abortSignal: controller.signal
    }).then(adLanguageSet => {
      adPageIdToOpen.current = adLanguageSet.id;
      businessContextDispatch({ type: "SetAdLanguageSet", adLanguageSet: adLanguageSet });
      console.log("AdCampaignListItem.businessContextDispatch[SetAdLanguageSet]:", adPageIdToOpen.current);
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() =>
      !controller.aborted && setIsLoading(false)
    );
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetDelete = (adLanguageSetId: string) => {
    if (!businessContext.businessState?.business) return;
    setIsLoading(true);
    let controller = abortController.newController("deleteAdLanguageSet");
    deleteAdLanguageSetAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      businessType: businessContext.businessState.business.businessType,
      businessId: businessContext.businessState.business.id,
      adCampaignId: props.data.id,
      adLanguageSetId: adLanguageSetId,
      abortSignal: controller.signal
    }).then(response => {
      businessContextDispatch({ type: "SetAds", ads: response as IAdLanguageSetListItem[] });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetActivate = (adLanguageSetId: string) => {
    setIsLoading(true);
    let controller = abortController.newController("activateAdLanguageSet");
    activateAdLanguageSetAsync({
      msalInstance: instance,
      authContext: appAuthContext,
      business: businessContext.businessState?.business,
      adCampaignId: props.data.id,
      adLanguageSetId: adLanguageSetId,
      respondWith: 'list',
      abortSignal: controller.signal
    }).then(response => {
      businessContextDispatch({ type: "SetAds", ads: response as IAdLanguageSetListItem[] });
    }).catch(error => {
      //console.error(error);
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetDeactivate = (adLanguageSetId: string) => {
    if (!businessContext.businessState) return;
    setIsLoading(true);
    let controller = abortController.newController("onAdLanguageSetDeactivate");
    deactivateAdLanguageSetAsync({
      msalInstance: instance,
      authContext: appAuthContext,
      business: businessContext.businessState.business,
      adCampaignId: props.data.id,
      adLanguageSetId: adLanguageSetId,
      abortSignal: controller.signal,
      respondWith: 'list'
    }).then(response => {
      !controller.aborted && businessContextDispatch({ type: "SetAds", ads: response as IAdLanguageSetListItem[] });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const navigateToContentEditor = (basePath: string, businessId: string, adLanguageSetId: string) => {
    console.log(basePath, businessId, adLanguageSetId)
    const adContentEditorPath = `/${basePath}/${pathAds}/:adLanguageSetId`;
    const path = generatePath(adContentEditorPath, {
      adLanguageSetId: adLanguageSetId,
    });
    navigate(path);
  };
  //---------------------------------------------------------------------------
  const onAdCampaignOptionSelect = (selectedOptionId: string) => {
    console.log(selectedOptionId)
    switch (selectedOptionId) {
      case "ListItemAdCampaign.MenuContent.OptionDelete":
        onAdCampaignDelete();
        break;
      case "ListItemAdCampaign.MenuContent.OptionNewAdPageWizard":
        onAdPageWizard();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onAdLanguageSetOptionSelect = (adLanguageSetId: string, selectedOptionId: string) => {
    //console.log("onAdPageOptionSelect:", adPageId, selectedOptionId);
    switch (selectedOptionId) {
      case "AdPageEditor.MenuContent.OptionCreate":
        onAdLanguageSetCreate();
        break;
      case "AdPageEditor.MenuContent.OptionEdit":
        onAdLanguageSetEdit(adLanguageSetId);
        break;
      case "AdPageEditor.MenuContent.OptionTranslate":
        onAdLanguageSetCreate(adLanguageSetId);
        break;
      case "AdPageEditor.MenuContent.OptionActivate":
        onAdLanguageSetActivate(adLanguageSetId);
        break;
      case "AdPageEditor.MenuContent.OptionDeactivate":
        onAdLanguageSetDeactivate(adLanguageSetId);
        break;
      case "AdPageEditor.MenuContent.OptionDelete":
        onAdLanguageSetDelete(adLanguageSetId);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedMenuContent: IUiMenuContent = { ...props.ui.menuContent, options: [] };
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "ListItemAdCampaign.MenuContent.OptionDelete":
        updatedMenuContent.options.push({
          ...option,
          iconId: "delete",
          isDisabled: cantDelete
        });
        break;
      case "ListItemAdCampaign.MenuContent.OptionNewAdPageWizard":
        updatedMenuContent.options.push({
          ...option,
          iconId: "magicWand"
        });
        break;
    };
  });
  //---------------------------------------------------------------------------
  const businessId = businessContext.businessState?.business.id;
  const adPages = adListItems?.map(adPage => {
    const adPagesListItem: IAdLanguageSetListItem = {
      id: adPage.id,
      adCampaignId: props.data.id,
      businessId: businessId ? businessId : "",
      localeId: adPage.localeId,
      status: {
        statusId: adPage.status.statusId,
        isAutoActivated: false,
        isReadonly: adPage.status.isReadonly,
        isApproved: adPage.status.isApproved,
        isInTrouble: adPage.status.isInTrouble,
        isPublished: adPage.status.isPublished
      },
      adModules: []
    };
    return adPagesListItem;
  });
  const adPagesList =
    <AdPagesList
      ui={props.ui.listItemAdPage}
      data={adPages}
      onAdPageOpen={onAdLanguageSetOpen}
      onAdPageCreate={onAdLanguageSetCreate}
      onAdPageOptionSelect={onAdLanguageSetOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <CampaignWrapper
      id={props.data.id}
      ui={updatedMenuContent}
      adPages={adPagesList}
      isCollapsed={props.isCollapsed}
      isLoading={isLoading}
      onOptionSelect={onAdCampaignOptionSelect}
    >

      <AdCampaignHeader
        ui={props.ui}
        data={props.data}
      />

      {newAdPageState && newAdPageState.popupIsOpen &&
        <PopupAdPageLanguage
          ui={props.ui.inputAdPageLanguage}
          data={newAdPageState.availableLocales}
          onSelect={createNewAdLanguageSet}
          onClose={onAdLanguageSetCreateCancel}
        />}

      <PopupMessage
        type='Error'
        id={tmpUiBusinessInvalid.id}
        header={GetCaption(appUiContext, tmpUiBusinessInvalid.id, tmpUiBusinessInvalid.caption)}
        message={GetHint(appUiContext, tmpUiBusinessInvalid.id, tmpUiBusinessInvalid.hint)}
        optionsMenuContent={getTUiMenuContent(tmpUiBusinessInvalid.menuContent)}
        show={businessInvalid ? businessInvalid.popupOpen : false}
        onOptionSelect={onBusinessInvalidOptionSelect}
        onCancel={() => setBusinessInvalid({ ...businessInvalid, popupOpen: false })}
      />

      <PopupMessage
        type='Confirmation'
        id={tmpUiBusinessSaveConfirmation.id}
        header={GetCaption(appUiContext, tmpUiBusinessSaveConfirmation.id, tmpUiBusinessSaveConfirmation.caption)}
        message={GetHint(appUiContext, tmpUiBusinessSaveConfirmation.id, tmpUiBusinessSaveConfirmation.hint)}
        optionsMenuContent={getTUiMenuContent(tmpUiBusinessSaveConfirmation.menuContent)}
        show={businessSaveConfirmationOpen}
        onOptionSelect={onBusinessSaveConfirmationOptionSelect}
        onCancel={() => setBusinessSaveConfirmationOpen(false)}
      />

    </CampaignWrapper>
  );
}

const tmpUiBusinessInvalid: IUiInteractiveForm = {
  id: "tmpUiBusinessInvalid",
  directoryId: "",
  elementType: "",
  caption: { en: "Create new ad" },
  hint: { en: "Please fill up business data before you can continue with business ads: business name, location(s), contact(s), business tags" },
  menuContent: {
    id: "tmpUiBusinessInvalidMenuContent",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionOk",
      directoryId: "",
      elementType: "",
      caption: { en: "Ok" },
      hint: undefined
    }]
  }
}

const tmpUiBusinessSaveConfirmation: IUiInteractiveForm = {
  id: "tmpUiBusinessSaveConfirmation",
  directoryId: "",
  elementType: "",
  caption: { en: "Create new ad" },
  hint: { en: "Please save business data before you can continue with business ads" },
  menuContent: {
    id: "tmpUiBusinessSaveConfirmationMenuContent",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionSave",
      directoryId: "",
      elementType: "",
      caption: { en: "Save" },
      hint: undefined
    }, {
      id: "OptionCancel",
      directoryId: "",
      elementType: "",
      caption: { en: "Cancel" },
      hint: undefined
    }]
  }
}