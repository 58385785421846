import styles from './DirectoryEditorFilter.module.css';
import DraftStateLegendItem from './draft-state-legend-item/DraftStateLegendItem';
import { useContext } from 'react';
import { DirectoryEditorContext } from '../../../../../context/directory-editor-context/DirectoryEditorContextProvider';

interface IProps {
}

export default function DirectoryEditorFilter(props: IProps) {
  const { directoryEditorContext, directoryEditorContextDispatch } = useContext(DirectoryEditorContext);
  //---------------------------------------------------------------------------
  const onLegendItemToggle = (id: string) => {
    const updatedStateGroups = directoryEditorContext.filter.stateGroups.map(group =>
      group.id === id ? group.toggle() : group);
    directoryEditorContextDispatch({ type: "UpdateFilter", updatedStateGroups });
  };
  //---------------------------------------------------------------------------
  const onKeywordUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    directoryEditorContextDispatch({ type: "UpdateFilter", keyword: e.target.value });
  };
  //---------------------------------------------------------------------------
  const legendItems = directoryEditorContext.filter.stateGroups.map(group =>
    <DraftStateLegendItem
      key={group.id}
      stateGroup={group}
      enabled={group.enabled}
      onToggle={onLegendItemToggle}
    />
  );
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.legend}>
        {legendItems}
        <input
          className={styles.searchInput}
          placeholder='Type search keyword here...'
          onChange={onKeywordUpdate}
        />
      </div>
    </div>
  );
}