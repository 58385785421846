import React, { useState } from 'react';
import { IUiTranslationEditorInput } from './IUiTranslationEditorInput';
import styles from './TranslationEditorInput.module.css';
import RawInputText from '../../../../../common/input-v2/raw-inputs/RawInputText';
import { IValidationState } from '../../../../../common/input-v2/input-validation/IUiValidation';

interface IProps {
  ui: IUiTranslationEditorInput;
  data?: string;
  isEditable: boolean;
  isSelected: boolean;
  onSelect: () => void;
  onUpdate?: (updatedTranslation?: string) => void;
}

export default function TranslationEditorInput(props: IProps) {
  const [validationState, setValidationState] = useState<IValidationState>();
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (!props.isSelected) {
      props.onSelect();
    };
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedValue: string) => {
    if (props.isEditable) {
      validateValue(updatedValue);
      if (updatedValue == "") {
        props.onUpdate && props.onUpdate(undefined);
      } else {
        props.onUpdate && props.onUpdate(updatedValue);
      };
    };
  };
  //---------------------------------------------------------------------------
  const validateValue = (value?: string) => {
    if (value && value != "") {
      setValidationState({ isValid: true, validationMessages: [] });
    } else {
      setValidationState({ isValid: false, validationMessages: [{ id: "EmptyValue", message: { en: "Value cannot be empty" } }] });
    };
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>

      <div className={styles.caption} onClick={onSelect}>
        <input
          type="radio"
          id={props.ui.id}
          className={styles.radio}
          name={props.ui.groupId}
          checked={props.isSelected}
          readOnly
        />
        <label className={styles.label}>
          {props.ui.caption}
        </label>
      </div>

      <div
        className={styles.input}
        onClick={onSelect}
      >
        {props.isSelected &&
          <RawInputText
            ui={{
              id: "", directoryId: "", elementType: "",
              placeholder: props.ui.placeholder,
              hideCaption: true,
              hideHint: true,
              disabled: !props.isEditable || !props.isSelected
            }}
            data={props.data}
            isFocused={props.isSelected}
            isValid={validationState?.isValid}
            onUpdate={props.isEditable ? onUpdate : undefined}
          />}
      </div>

    </React.Fragment>
  );
}