import { IUiMenuContent } from '../IUiMenuContent';
import MainMenuOption from './main-menu-option/MainMenuOption';
import styles from './MainMenu.module.css';
import { ScreenType, useAppScreenContext } from '../../../../../context/app-screen-context/AppScreenProvider';
import { ReactNode } from 'react';
import MainMenuOptionDropdown from './main-menu-option/MainMenuOptionDropdown';

interface IProps {
  ui?: IUiMenuContent;
  selectedOptionId: string;
  collapseToDropdown?: boolean;
  onSelect: (optionId: string) => void;
}

export default function MainMenu(props: IProps) {
  const screenType = useAppScreenContext();
  //if (screenType === ScreenType.Mobile) return null;
  let optionElements = null;
  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------
  if (screenType === ScreenType.Desktop) {
    optionElements = props.ui?.options.map(option => {
      return (
        <MainMenuOption
          key={option.id}
          ui={option}
          isSelected={option.id === props.selectedOptionId}
          onSelect={props.onSelect}
        />
      );
    });
  } else if (props.collapseToDropdown) {
    const selectedOption = props.ui?.options.find(o => o.id === props.selectedOptionId);
    console.log(selectedOption);
    if (selectedOption) {
      optionElements =
        <MainMenuOptionDropdown
          ui={selectedOption}
          onSelect={props.onSelect}
        />;
    };
  };
  //---------------------------------------------------------------------------
  return (
    <div id="mm" className={styles.container}>
      {optionElements}
    </div>
  );
}