import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { TDictionaryListItem } from "./dictionary-card/TDictionaryListItem";
import { apiGetPrivate, checkResponse } from "../../../../utils/api";
import { apiBasePath, pathGetDictionaryDrafts } from "../../../../utils/apiPathConstant";

export default function getDictionaries(
  msalInstance: IPublicClientApplication,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<TDictionaryListItem[]> {
  return new Promise((resolve, reject) => {
    //-------------------------------------------------------------------------
    apiGetPrivate(
      msalInstance,
      authConfig,
      `${apiBasePath}${pathGetDictionaryDrafts}`,
      abortSignal
    ).then(response => {
      checkResponse(response, "getDictionaries");
      resolve(response?.content as TDictionaryListItem[]);
    }).catch(error =>
      reject(error)
    );
  });
}