import React, { useEffect, useState } from 'react';
import DictionaryNodeEditForm from '../../common/dictionary-node-edit-form/DictionaryNodeEditForm';
import { RegionsDictionaryDraftItem } from '../regions-editor-node/RegionsEditorNode';
import { DictionaryEditorDraftItem } from '../../common/models/DictionaryEditorDraftItem';
import { IUiInputCheckbox } from '../../../../../common/input-v2/input-checkbox/InputCheckBox';
import InputCheckbox from '../../../../../common/input-v2/input-checkbox/InputCheckBox';
import { DraftItemStateEnum } from '../../../../common/dictionary-draft-models/DraftItemStateEnum';
import { INewRegionData } from '../RegionsEditorV1';
import { EditFormState } from '../../../../../common/edit-form/IEditFormState';

export interface IUiRegionsDictionaryDraftItemEditForm {
  inputIsAvailable: IUiInputCheckbox;
  inputIsAvailableToStaffUsers: IUiInputCheckbox;
  inputIsAvailableToEndUsers: IUiInputCheckbox;
}

export const tmpUiRegionStatus: IUiRegionsDictionaryDraftItemEditForm = {
  inputIsAvailable: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Active" },
    hint: { en: "Available to users" },
    hideHint: true,
    disabled: false
  },
  inputIsAvailableToStaffUsers: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Staff users" },
    hint: { en: "Available to Staff users" },
    hideHint: true,
    disabled: false
  },
  inputIsAvailableToEndUsers: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "End users" },
    hint: { en: "Available to End users" },
    hideHint: true,
    disabled: false
  }
}

interface IProps {
  data?: RegionsDictionaryDraftItem;
  newRegionData?: INewRegionData;
  rootIsLocked?: boolean;
  onUpdate: (updatedItem: RegionsDictionaryDraftItem) => void;
  onClose: () => void;
}

export default function RegionEditForm(props: IProps) {
  const [state, setState] = useState<EditFormState<RegionsDictionaryDraftItem>>();
  //---------------------------------------------------------------------------
  useEffect(() => {
    let state: EditFormState<RegionsDictionaryDraftItem> | undefined = undefined;
    if (props.data) {
      state = new EditFormState<RegionsDictionaryDraftItem>({
        object: props.data,
        isNew: false,
        isValid: props.data.isValid()
      });
    } else {
      const newObject = new RegionsDictionaryDraftItem({
        id: props.newRegionData?.region ? props.newRegionData.region.id : "",
        name: props.newRegionData?.region ? props.newRegionData.region.name : "",
        items: undefined,
        index: 0,
        deleted: false,
        status: { isAvailableToStaffUsers: true, isAvailableToEndUsers: false },
        draftState: DraftItemStateEnum.IsNew,
        searchId: [],
        searchName: [],
        //getPathForChilds: () => {return ""}
      });
      state = new EditFormState<RegionsDictionaryDraftItem>({
        object: newObject,
        isNew: true,
        isValid: newObject.isValid()
      });
    };
    setState(state);
  }, [props.data, props.newRegionData]);
  //---------------------------------------------------------------------------
  const onBaseDataUpdate = (updatedData: DictionaryEditorDraftItem, isValid: boolean) => {
    if (!state) {
      console.error("Dictionary draft is being updated but state is not set");
      return;
    }
    if (props.newRegionData) {
      //updatedData.path = `${props.newRegionData?.path}.${updatedData.id}`;
      updatedData.path = props.newRegionData.path;
    };
    const updatedState = new EditFormState<RegionsDictionaryDraftItem>(state, {
      object: updatedData as RegionsDictionaryDraftItem,
      isUpdated: true,
      isValid: isValid
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    if (state?.object) {
      props.onUpdate(new RegionsDictionaryDraftItem(state.object));
    } else {
      console.error("Dictionary draft is being updated but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onStatusSwitch = (isActive: boolean) => {
    if (state && state.object) {
      const updatedRegion = new RegionsDictionaryDraftItem(state.object);
      if (isActive) {
        updatedRegion.activate();
      } else {
        updatedRegion.deactivate();
      }
      const updatedState = new EditFormState<RegionsDictionaryDraftItem>(state, {
        object: updatedRegion,
        isUpdated: true,
        isValid: state.isValid
      });
      setState(updatedState);
    } else {
      console.error("Dictionary draft is being updated but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onStaffUsersSwitch = (updatedStaffUsersValue: boolean) => {
    if (state && state.object) {
      const updatedRegion = new RegionsDictionaryDraftItem(state.object);
      updatedRegion.setStatusForStaffUsers(updatedStaffUsersValue);
      const updatedState = new EditFormState<RegionsDictionaryDraftItem>(state, {
        object: updatedRegion,
        isUpdated: true,
        isValid: state.isValid
      });
      setState(updatedState);
    } else {
      console.error("Dictionary draft is being updated but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onEndUsersSwitch = (updatedEndUsersValue: boolean) => {
    if (state && state.object) {
      const updatedRegion = new RegionsDictionaryDraftItem(state.object);
      updatedRegion.setStatusForEndUsers(updatedEndUsersValue);
      const updatedState = new EditFormState<RegionsDictionaryDraftItem>(state, {
        object: updatedRegion,
        isUpdated: true,
        isValid: state.isValid
      });
      setState(updatedState);
    } else {
      console.error("Dictionary draft is being updated but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  return (
    <DictionaryNodeEditForm
      data={state}
      onUpdate={onBaseDataUpdate}
      onConfirm={onConfirm}
      onClose={props.onClose}
    >
      {!props.rootIsLocked &&
        <React.Fragment>
          <InputCheckbox
            ui={tmpUiRegionStatus.inputIsAvailable}
            data={!!state?.object?.status}
            onChange={onStatusSwitch}
          />
          <InputCheckbox
            ui={{ ...tmpUiRegionStatus.inputIsAvailableToStaffUsers, disabled: !state?.object?.status }}
            data={state?.object?.status?.isAvailableToStaffUsers}
            onChange={onStaffUsersSwitch}
          />
          <InputCheckbox
            ui={{ ...tmpUiRegionStatus.inputIsAvailableToEndUsers, disabled: !state?.object?.status }}
            data={state?.object?.status?.isAvailableToEndUsers}
            onChange={onEndUsersSwitch}
          />
        </React.Fragment>}
    </DictionaryNodeEditForm>
  );
}