import { IPublicClientApplication } from "@azure/msal-browser/dist/app/IPublicClientApplication";
import { IAuthConfig } from "../../../context/app-auth-context/AppAuthContext";
import { apiGetPrivate, apiPostPrivate, checkResponse } from "../../../utils/api";
import { addUserImageLibraryItemsAsync, apiBasePath, getPrivateImageUri, getUserImageCount, updateImageLibraryItemsUsage } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
import { EHttpStatusCode } from "../../../utils/HttpStatusCodes";
import { ImageEditorSession } from "./image-editor-context/ImageEditorSession";
import { EAdUnitImageType } from "../ad-modules/IAdModule";
import { IImageUsage, TImageUsage, UsageBusiness } from "./TImageUsage";
import { ClassImageManager } from "./image-manager/IImageManager";
import { v4 as createNewGuid } from "uuid";

/*
export interface TImageData {
  id?: string;
  imageUri?: string;
  description?: string;
  widthPx?: number;
  heightPx?: number;
  lastModified?: Date;
  editedImageUrl?: string;
}
*/
//-------------------------------------------------------------------------
export type EImageSize = "Large" | "Medium" | "Thumbnail";
//-------------------------------------------------------------------------
export type EImageStatus = "Draft" | "ApprovePending" | "Approved" | "Published";
//-------------------------------------------------------------------------
export interface IImageData {
  id: string;
  userId?: string;
  imageUri?: string;
  description?: string;
  widthPx?: number;
  heightPx?: number;
  contentType?: string;
  contentLength?: number;
  editedImageUrl?: string;
  imageStatus?: EImageStatus;
  dotExtension?: string;
  fromLibrary?: boolean;
  blockIds?: string[];
  fileName?: string;
  lastModified?: Date;
  imageUsage?: IImageUsage[];
}
//-------------------------------------------------------------------------
export class TImageData implements IImageData {
  id!: string;
  userId?: string;
  internalId!: string;
  imageUri?: string;
  description?: string;
  widthPx?: number;
  heightPx?: number;
  contentType?: string;
  contentLength?: number;
  editedImageUrl?: string;
  imageStatus?: EImageStatus;
  dotExtension?: string;
  blockIds?: string[];
  fromLibrary?: boolean;

  file?: File; // locally opened file
  imageManager?: ClassImageManager;

  static config?: IAuthConfig | null;
  static instance?: IPublicClientApplication | null;
  static storageEndpoint?: string;
  static defaultBusinessLogo?: TImageData;

  constructor(source: any, spread?: any) {
    if (source !== undefined || spread !== undefined) {
      Object.assign(this, source, spread);
    }
    this.internalId = createNewGuid();
  }
  //-------------------------------------------------------------------------------------
  toJSON() {
    let result = {
      ...this,
      _lastModified: null,
      lastModified: this._lastModified,
      _imageUsage: null,
      imageUsage: this._imageUsage,
      _sas: null,
      _sasExpiry: null,
      imageExists: null,
      _fileName: null,
      fileName: null,
      extension: null,
      fromLibrary: null,
      file: null,
      imageManager: null,
      internalId: null,
    };
    delete result["_lastModified"];
    delete result["_imageUsage"];
    delete result["_sas"];
    delete result["_sasExpiry"];
    delete result["imageExists"];
    delete result["fileName"];
    delete result["_fileName"];
    delete result["extension"];
    delete result["fromLibrary"];
    delete result["file"];
    delete result["imageManager"];
    delete result["internalId"];
    return result;
  }
  //-------------------------------------------------------------------------------------
  static fromFile(file: File) {
    return new TImageData({ id: "dummy", file: file });
  }
  //-------------------------------------------------------------------------------------
  private getSasIndex(imageSize: EImageSize): number {
    switch (imageSize) {
      case "Large":
        return 0;
      case "Medium":
        return 1;
      case "Thumbnail":
        return 2;
    }
  }
  //-------------------------------------------------------------------------------------
  private _sas: (string | undefined)[] = [undefined, undefined, undefined];
  private _sasExpiry: (Date | undefined)[] = [undefined, undefined, undefined];
  private static sasExpirationFrameMilliseconds = 60000;
  //-------------------------------------------------------------------------------------
  private setSas(uri: string, imageSize: EImageSize) {
    let sizeIndex = this.getSasIndex(imageSize);
    this._sas[sizeIndex] = undefined;
    this._sasExpiry[sizeIndex] = undefined;
    let qIndex = uri.indexOf('?');
    if (qIndex <= 0)
      return;
    this._sas[sizeIndex] = uri.substring(qIndex + 1);
    let searchParams: URLSearchParams = new URLSearchParams(this._sas[sizeIndex]);
    if (!searchParams.has("se"))
      return;
    let s: string | null = searchParams.get("se");
    if (s) {
      this._sasExpiry[sizeIndex] = new Date(s);
    }
  }
  //-------------------------------------------------------------------------------------
  private getSas(imageSize: EImageSize): string | undefined {
    let sizeIndex = this.getSasIndex(imageSize);
    let sas = this._sas[sizeIndex];
    let sasExpiry = this._sasExpiry[sizeIndex];
    if (!sas || !sasExpiry)
      return undefined;
    let now = new Date();
    if (sasExpiry.getTime() - now.getTime() > TImageData.sasExpirationFrameMilliseconds) {
      return sas;
    }
  }
  //-------------------------------------------------------------------------------------
  clearSas() {
    this._sas = [undefined, undefined, undefined];
    this._sasExpiry = [undefined, undefined, undefined];
  }
  //-------------------------------------------------------------------------------------
  private get extension(): string {
    return this.dotExtension != undefined ? this.dotExtension : "";
  }
  //-------------------------------------------------------------------------------------
  private _fileName?: string;
  get fileName(): string | undefined {
    return this._fileName;
  }
  set fileName(value: string | undefined) {
    if (value) {
      try {
        this.dotExtension = '.' + value.split('.')[1];
        this._fileName = `image${this.dotExtension}`;
      }
      catch (error) {
        console.error("TImageData.setFileName.extension error", value);
      }
    }
    else {
      this._fileName = undefined;
      this.dotExtension = "";
    }
  }
  //-------------------------------------------------------------------------------------
  private _lastModified?: Date;
  get lastModified(): Date | undefined {
    return this._lastModified;
  }
  set lastModified(value: any) {
    if (value !== undefined)
      this._lastModified = new Date(value);
    else
      this._lastModified = undefined;
  }
  //-------------------------------------------------------------------------------------
  get isPublic(): boolean | undefined {
    if (this.id == "17dc718a-a6dd-497c-9711-84fad971ddd2") {
      //console.warn("ImageStatus:", this.imageStatus);
    }
    if (this.isSystemImage)
      return true;
    switch (this.imageStatus) {
      case "Draft":
      case "ApprovePending":
      case "Approved":
        return false;
      case "Published":
        return true;
      default:
        return false;
    }
  }
  set isPublic(value: boolean | undefined) {

  }
  //-------------------------------------------------------------------------------------
  get isReadOnly(): boolean {
    switch (this.imageStatus) {
      //case "None":
      case "Draft":
        return this.isSystemImage;
    }
    return true;
  }
  get isValidId(): boolean {
    return this.id != undefined && this.userId != undefined;
  }
  //-------------------------------------------------------------------------------------
  private _imageUsage?: TImageUsage[];
  get imageUsage(): TImageUsage[] | undefined {
    return this._imageUsage;
  }
  set imageUsage(value: IImageUsage[] | undefined) {
    if (value) {
      this._imageUsage = TImageUsage.fromArray(value);
    }
    else {
      this._imageUsage = undefined;
    }
  }
  //-------------------------------------------------------------------------------------
  isUsedElsewhere(exceptUsage?: IImageUsage): boolean {
    if (this.imageUsage == undefined || this.imageUsage.length == 0)
      return false;
    if (exceptUsage && this.imageUsage.length == 1 && this.imageUsage.findIndex(u => u.equals(exceptUsage)) == 0)
      return false;
    return true;
  }
  //-------------------------------------------------------------------------------------
  addUsage(usage: TImageUsage): boolean {
    if (!this.usageExists(usage)) {
      if (!this._imageUsage)
        this._imageUsage = [usage];
      else
        this._imageUsage.push(usage);
      return true;
    }
    return false;
  }
  //-------------------------------------------------------------------------------------
  removeUsage(usage: IImageUsage): boolean {
    if (!this._imageUsage)
      return false;
    let i = this._imageUsage.findIndex(u => u.equals(usage));
    if (i < 0)
      return false;
    if (this._imageUsage.length == 1)
      this.imageUsage = undefined;
    else
      this._imageUsage.splice(i, 1);
    return true;
  }
  //-------------------------------------------------------------------------------------
  usageExists(usage?: IImageUsage) {
    if (!this._imageUsage || !usage)
      return false;
    return this._imageUsage.findIndex(u => u.equals(usage)) >= 0;
  }
  //-------------------------------------------------------------------------------------
  getUsageBusinesses() {
    if (!this._imageUsage || this._imageUsage.length == 0)
      return undefined;
    let businessIds: string[] = [];
    this._imageUsage.forEach(usage => {
      if (!businessIds.includes(usage.businessId))
        businessIds.push(usage.businessId);
    });
    return businessIds.map(id => new UsageBusiness(this, id));
  }
  //-------------------------------------------------------------------------------------
  static async getUserImageCount(userId: string, abortSignal?: AbortSignal): Promise<number> {
    if (TImageData.config && TImageData.instance)
      try {
        let response = await apiGetPrivate(
          TImageData.instance, TImageData.config,
          `${apiBasePath}${getUserImageCount}`,
          abortSignal
        );
        checkResponse(response, "getUserImageCount", abortSignal?.aborted);
        let count: number = response?.content.value;
        return count;
      } catch (error) {
        console.log("TImageData.getUserImageCount fail(try/catch):", error);
      }
    return 0;
  }
  //-------------------------------------------------------------------------------------
  static async uploadImageLibraryItems(items: TImageData[]): Promise<boolean> {
    try {
      if (items.length > 0 && TImageData.config && TImageData.instance) {
        console.log("uploadImageLibraryItems.items:", items);
        let response = await apiPostPrivate(
          TImageData.instance,
          TImageData.config,
          `${apiBasePath}${addUserImageLibraryItemsAsync}`,
          items
        );
        if (response && response.status == EHttpStatusCode.OK)
          return true;
        else {
          console.error("uploadImageLibraryItems.error, response:", response);
        }
      }
    } catch (error) {
      console.error("uploadImageLibraryItems.error:", error);
    }
    return false;
  }
  //-------------------------------------------------------------------------------------
  static async updateImageLibraryItemsUsage(items: TImageData[]): Promise<boolean> {
    try {
      if (items.length > 0 && TImageData.config && TImageData.instance) {
        console.log("updateImageLibraryItemsUsage.items:", items);
        let response = await apiPostPrivate(
          TImageData.instance,
          TImageData.config,
          `${apiBasePath}${updateImageLibraryItemsUsage}`,
          items
        );
        if (response && response.status == EHttpStatusCode.OK)
          return true;
        else {
          console.error("updateImageLibraryItemsUsage.error, response:", response);
        }
      }
    } catch (error) {
      console.error("updateImageLibraryItemsUsage.error:", error);
    }
    return false;
  }
  //-------------------------------------------------------------------------------------
  get isSystemImage(): boolean {
    let index = this.id?.indexOf("sys/");
    return index === 0;
  }
  //-------------------------------------------------------------------------------------
  get containerName(): string {
    switch (this.isPublic) {
      case true:
        return "public";
      case false:
        return "private";
      default:
        return "img";
    }
  }
  //-------------------------------------------------------------------------------------
  get imageIsUsedSomewhere(): boolean {
    // not yet implemented 
    return false;
  }
  //-------------------------------------------------------------------------------------
  getPublicUri(size: EImageSize): string | undefined {
    switch (this.isPublic) {
      case true:
        let uri = `${TImageData.storageEndpoint}public/${this.id}.${size}${this.extension}`;
        if (this.lastModified)
          uri = `${uri}?${this.lastModified.toISOString()}`;
        return uri;
      case false:
        return undefined;
      case undefined:
        return this.urlFallbackOld;
    }
  }
  //-------------------------------------------------------------------------------------
  test() {
    return this.id == "f471bf17-7a56-4f3b-a85c-7436dddfe175";
  }
  //-------------------------------------------------------------------------------------
  getUrlCallback(
    size: EImageSize,
    callback: (url: string | undefined) => void,
    abortSignal?: AbortSignal) {
    if (this.editedImageUrl && !abortSignal?.aborted) {
      return callback(this.editedImageUrl);
    }
    let publicUri = this.getPublicUri(size);
    if (publicUri && !abortSignal?.aborted) {
      return callback(publicUri);
    }
    if (!this.imageStatus) {
      return callback(undefined);
    }
    this.getUrlAsync(size, abortSignal)
      .then((uri) => {
        !abortSignal?.aborted && callback(uri);
      })
      .catch(error => {
        console.error(`TImageData[id:${this.id}].getUriCallback[${size}].catch:`, error);
        !abortSignal?.aborted && callback(undefined);
      });
  }
  //-------------------------------------------------------------------------------------
  async getUrlPromise(
    size: EImageSize,
    abortSignal?: AbortSignal): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.editedImageUrl) {
        return resolve(this.editedImageUrl);
      }

      let publicUri = this.getPublicUri(size);
      if (publicUri) {
        return resolve(publicUri);
      }
      if (!this.imageStatus) {
        return reject(`Image[${this.id}]: ImageStatus undefined`);
      }
      if (abortSignal?.aborted)
        return reject(`Aborted`);

      this.getUrlAsync(size, abortSignal)
        .then(uri => {
          if (abortSignal?.aborted) {
            throw `Aborted`;
          }
          resolve(uri);
        })
        .catch(error => {
          //console.error(`TImageData[id:${this.id}].getUriPromise.catch: ${error}`);
          reject(`TImageData[id:${this.id}].getUriPromise.catch: ${error}`);
        });
    });
  }
  //-------------------------------------------------------------------------------------
  async getUrlAsync(size: EImageSize, abortSignal?: AbortSignal): Promise<string> {

    let uri = `${TImageData.storageEndpoint}${this.containerName}/${this.id}.${size}${this.extension}`;

    if (this.isPublic) {
      if (this.lastModified)
        return `${uri}?${this.lastModified.toISOString()}`;
      return uri;
    }
    // private image - check if sas already exists and not expired
    let sas = this.getSas(size);
    if (sas) {
      return `${uri}?${sas}`;
    }
    // sas not exists or expired - get new sas
    return new Promise((resolve, reject) => {
      if (!TImageData.instance)
        return reject(`TImageData.instance is undefined`);
      if (!TImageData.config)
        return reject(`TImageData.config is undefined`);
      if (!this.id)
        return reject(`ImageData.id is undefined`);
      let sasIndex = this.getSasIndex(size);
      this._sas[sasIndex] = undefined;
      this._sasExpiry[sasIndex] = undefined;

      if (abortSignal?.aborted)
        return reject(`getUrlAsync.${this.id}.${size}: aborted`);

      apiPostPrivate(
        TImageData.instance, TImageData.config,
        `${apiBasePath}${stringFormatter(getPrivateImageUri, [size])}`,
        this,
        undefined,
        abortSignal
      )
        .then(response => {
          checkResponse(response, "getPrivateImageUri", abortSignal?.aborted);
          let uri: string = response?.content.uri;
          this.setSas(uri, size);
          resolve(uri);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  //-------------------------------------------------------------------------------------
  get urlFallbackOld(): string | undefined {
    if (this.editedImageUrl)
      return this.editedImageUrl;

    if (!this.imageUri)
      return undefined;

    if (this.lastModified)
      return `${this.imageUri}?${this.lastModified.toISOString()}`;
    return this.imageUri;
  }
}

//--------------------------------------------------------------------------------
// classes for image updates from UI to backend
//--------------------------------------------------------------------------------
export type TUsageUpdate = {
  imageId: string;
  userId: string;
  usages?: TImageUsage[];
}

export class ImageInLocalStorage {
  uploaded: boolean = false;
  objectIds: string[] = [];
  loadingState?: EImageLoadingState;
  blob?: Blob;


  constructor(source: any) {
    if (source !== undefined)
      Object.assign(this, source);
  }

  toJSON() {
    let result = {
      ...this,
      _imageData: null,
      imageData: this._imageData,
    };
    delete result["_imageData"];
    return result;
  }

  private _imageData?: TImageData = undefined;
  get imageData(): TImageData | undefined {
    return this._imageData;
  }
  set imageData(value: any) {
    if (value === undefined)
      this._imageData = undefined;
    else {
      this._imageData = new TImageData(value);
    }
  }
}

export enum EImageLoadingState {
  // Undefined - display existing/uploaded image or no image at all
  Selected = 1,         // User selected image file - signal to download image
  ImageUploading = 2,        // Uploading to storage process is started
  ImageUploaded = 3,         // Uploading process is finished: we got new image permanent URI
  Downloading = 4,      // Downloading from storage process is started
  Downloaded = 5,       // Downloading process is finished, signal to draw the image and go to "Opened" state
  Opened = 6,           // image is drawn - stable state
  DescriptionUploading = 7,
  DescriptionUploaded = 8,
  ReadyForUpload = 9, // for image in local storage
  DownloadError = 10,
  NewGuid = 11, // session created with new Guid - no image yet
}