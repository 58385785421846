import { useContext } from 'react';
import GetCaption from '../../../../common/functions/GetCaption';
import GetHint from '../../../../common/functions/GetHint';
import { IUiInputList } from '../../../../common/input-v2/input-list/IUiInputList';
import InputWrapper from '../../../../common/input-v2/input-wrapper/InputWrapper';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { IUiCaption } from '../../../../common/captions/IUiCaption';
import LanguagesList from './languages-list/LanguagesList';
import styles from './InputListLanguages.module.css';

export interface IUiInputListLanguages extends IUiInputList {
  captionBrowserSettings: IUiCaption;
}

interface IProps {
  ui: IUiInputListLanguages;
  data?: string[];
  onUpdate: (updatedLocalesList?: string[]) => void;
}

export default function InputListLanguages(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const onCreateLanguagesList = () => {
    // Get locales from browser and display the list
    const browserLocales = navigator.languages.map(x => x);
    props.onUpdate(browserLocales);
  };
  //---------------------------------------------------------------------------
  const onUseBrowserSettings = () => {
    // Set locales list empty
    props.onUpdate(undefined);
  };
  //---------------------------------------------------------------------------
  return (
    <InputWrapper
      caption={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      hint={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      hideHint={false}
      readonly={false}
      disabled={false}
      validation={{ isValid: true, validationMessages: [] }}
    >
      {props.data && props.data.length > 0 ?
        <LanguagesList
          ui={props.ui}
          data={props.data}
          onUseBrowserSettings={onUseBrowserSettings}
          onUpdate={props.onUpdate}
        />
        :
        <div
          className={styles.option}
          onClick={onCreateLanguagesList}
        >
          Specify list of languages
        </div>
        /*
        <BrowserSettingsOption
          ui={props.ui.captionBrowserSettings}
          onSwitchoff={onCreateLanguagesList}
        /> */}
    </InputWrapper>
  );
}