import { useContext } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { IUiDictionaryItem } from '../../../common/dictionaries/IUiDictionaryItem';
import GetCaption from '../../../common/functions/GetCaption';
import GetHint from '../../../common/functions/GetHint';
import { IUiInputDropdown } from '../../../common/input-v2/input-dropdown/InputDropdown';
import InputDropdownItem from '../../../common/input/input-dropdown/input-dropdown-item/InputDropdownItem';
import PopupDialog from '../../../common/popup-v2/popup-dialog/PopupDialog';
import styles from './PopupAdPageLanguage.module.css';

export interface INewAdPageState {
  popupIsOpen: boolean;
  availableLocales: IUiDictionaryItem[];
  originalAdPageId?: string;
}

interface IProps {
  ui: IUiInputDropdown;
  data: IUiDictionaryItem[];
  onSelect: (selectedLocale: string) => void;
  onClose: () => void;
}

export default function PopupAdPageLanguage(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const localeOptions = props.data.map(item => (
    <InputDropdownItem
      key={item.id}
      ui={{ ...item, selected: false }}
      isMultiSelect={false}
      hideDescription={false}
      //disabled={unavailableLanguages?.includes(item.id)}
      onClick={() => props.onSelect(item.id)}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id={props.ui.id}
      header={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      isModal={true}
      isDraggable={false}
      onClose={props.onClose}
    >
      <div className={styles.container}>
        <div className={styles.prompt}>
          {GetHint(appUiContext, props.ui.id, props.ui.hint)}
        </div>
        <div className={styles.optionsContainer}>
          {localeOptions}
        </div>
      </div>
    </PopupDialog>
  );
}