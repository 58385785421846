import React, { useEffect, useState } from "react";
import { Contact, IContact } from "../../../../business-space/business-editor/section-contacts/contact/Contact";
import { getUnitStatus, IAdUnitText } from "../../../../common/ad-modules/IAdModule";
import ChecklistItemWrapper from "../checklist-item-wrapper/ChecklistItemWrapper";
import ChecklistItemText from "../checklist-item-text/ChecklistItemText";

interface IProps {
  adModuleId: string;
  data: IContact<IAdUnitText>;
  isReadonly: boolean;
  isCollapsed: boolean;
  onHover?: (path: string[]) => void;
  onReview?: (updatedContactUnit: Contact<IAdUnitText>) => void;
}

export default function ChecklistItemContact(props: IProps) {
  if (!props.data.status) return null;
  const [collapsed, setCollapsed] = useState(true);
  //--------------------------------------------------------------------------- Collapsed
  useEffect(() => {
    setCollapsed(props.isCollapsed || !!props.data.status?.isCompleted);
  }, [props.isCollapsed, props.data.status]);
  //---------------------------------------------------------------------------
  const onToggle = () => {
    setCollapsed(!collapsed);
  };
  //---------------------------------------------------------------------------
  const onHover = (isHovered: boolean) => {
    if (!props.onHover) return;
    const path: string[] = isHovered ? [props.adModuleId, props.data.id] : [];
    props.onHover(path);
  };
  //---------------------------------------------------------------------------
  const onValueHover = (isHovered: boolean) => {
    if (!props.onHover) return;
    if (!props.data.value) return;
    const path: string[] = isHovered ? [props.adModuleId, props.data.id, props.data.value.type] : [];
    props.onHover(path);
  };
  //---------------------------------------------------------------------------
  const onDescriptionHover = (isHovered: boolean) => {
    if (!props.onHover) return;
    if (!props.data.description) return;
    const path: string[] = isHovered ? [props.adModuleId, props.data.id, props.data.description.type] : [];
    props.onHover(path);
  };
  //---------------------------------------------------------------------------
  const onValueReview = (updatedTextUnit: IAdUnitText) => {
    if (props.isReadonly) return;
    const updatedLocationUnit: Contact<IAdUnitText> = {
      ...props.data,
      value: updatedTextUnit
    };
    props.onReview?.(updatedLocationUnit);
  };
  //---------------------------------------------------------------------------
  const onDescriptionReview = (updatedTextUnit: IAdUnitText) => {
    if (props.isReadonly) return;
    const updatedLocationUnit: Contact<IAdUnitText> = {
      ...props.data,
      description: updatedTextUnit
    };
    props.onReview?.(updatedLocationUnit);
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <ChecklistItemWrapper
        unitType='ContentText'
        caption={props.data.contactType}
        isGroup={true}
        level={1}
        status={props.data.status}
        isReadonly={props.isReadonly}
        isCollapsed={collapsed}
        onHover={onHover}
        onToggle={onToggle}
      />

      {/* Contact value */}
      {props.data.value && !collapsed &&
        <ChecklistItemText
          data={props.data.value}
          level={2}
          isReadonly={props.isReadonly}
          onHover={onValueHover}
          onReview={onValueReview}
        />}

      {/* Contact description */}
      {props.data.description && !collapsed &&
        <ChecklistItemText
          data={props.data.description}
          level={2}
          isReadonly={props.isReadonly}
          onHover={onDescriptionHover}
          onReview={onDescriptionReview}
        />}

    </React.Fragment>
  );
}