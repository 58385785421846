import { useContext, useState } from 'react';
import styles from './RegionNode.module.css';
import Checkbox from '../../../../../common/checkbox/Checkbox';
import ExpandButton from '../../../../../business-console/business-tags-selector-popup/tag-node/tag-node-header/expand-button/ExpandButton';
import CombineStyles from '../../../../../../utils/combineStyles';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import GetTranslationWithLocale from '../../../../../common/functions/GetTranslationWithLocale';
import { IUiDictionaryItemMlRegion } from '../../../../../common/dictionaries/IUiDictionaryItem';

interface IProps {
  ui: IUiDictionaryItemMlRegion;
  data: string[];
  onUpdate: (updatedList: string[]) => void;
}

export default function RegionNode(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [expanded, setExpanded] = useState(true);
  const isDisabled = !(props.ui.status && props.ui.status.isAvailableToStaffUsers);
  const isSelected = props.data.includes(props.ui.id);
  const regionName = GetTranslationWithLocale(appUiContext.sysSettings, props.ui.id, props.ui.name, "Name", appUiContext.locale.localeId).value;
  //---------------------------------------------------------------------------
  const onToggle = () => {
    setExpanded(!expanded);
  };
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (!isDisabled) {
      let updatedList = props.data;
      if (isSelected) {
        // Exclude the region from the list
        updatedList = props.data.filter(x => x != props.ui.id);
      } else {
        // Add the region to the list
        updatedList = props.data.concat(props.ui.id);
      };
      console.log(updatedList)
      props.onUpdate(updatedList.sort());
    };
  };
  //---------------------------------------------------------------------------
  const childNodes = props.ui.items?.map(subItem => (
    <RegionNode
      key={subItem.id}
      ui={subItem}
      data={props.data}
      onUpdate={props.onUpdate}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <div className={CombineStyles([
        styles.header,
        isDisabled ? styles.disabled : "",
        isSelected ? styles.selected : ""
      ])}>
        <Checkbox
          disabled={isDisabled}
          checked={isSelected}
          onClick={onSelect}
        />
        <div className={styles.caption} onClick={onToggle}>
          {regionName}
          {(props.ui.items && props.ui.items.length > 0) &&
            <ExpandButton widthEm={1} expanded={expanded} />}
        </div>
      </div>

      {expanded &&
        <div className={styles.subnodes}>
          {childNodes}
        </div>}

    </div>
  );
}