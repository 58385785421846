import { useMsal } from '@azure/msal-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import useNavigateWithContext from '../../../../../hooks/useNavigateWithContext';
import { apiGetPrivate, apiPostPrivate, checkResponse } from '../../../../../utils/api';
import { apiBasePath, pathArchiveDictionaryDraft, pathGetDictionaryDraft, pathPublishDictionaryDraft, pathUpdateDictionaryDraft } from '../../../../../utils/apiPathConstant';
import { stringFormatter } from '../../../../../utils/common';
import WikiSpinner from '../../../../app-layout/spinner/wikiSpinner';
import { TUiBreadcrumb } from '../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import ConsoleTabLayout from '../../../../common/console-layout/console-tab-layout/ConsoleTabLayout';
import { TUiInputText } from '../../../../common/input/input-text/TUiInputText';
import { TUiMenuOption } from '../../../../common/menu/menu-content/menu-option/TUiMenuOption';
import { TUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import { pathAdminConsole, pathAdministratorConsole } from '../../../AdminConsole';
import BusinessTagsDictionaryNodeEditor from '../../../common/dictionary-business-tags/BusinessTagsDictionaryNodeEditor';
import { pathBusinessTagsEditor, pathDictionaries } from '../../AdministratorConsole';
import styles from './BusinessTagsEditor.module.css';
import { IBusinessTagsDictionaryDraft, IBusinessTagsDictionaryDraftItem } from './IBusinessTagsDictionary';
import PopupMessage from '../../../../common/popup-v2/popup-message/PopupMessage';
import TagSearchInput from '../../../../business-console/business-tags-selector-popup/tag-search-input/TagSearchInput';
import DraftsAreEqual from './CompareDrafts';
import { DraftItemStateEnum } from '../../../common/dictionary-draft-models/DraftItemStateEnum';
import LegendItem from '../../../common/dictionary-editor-wrapper/dictionary-editor-legend-item/LegendItem';
import DraftHistory from '../common/draft-history/DraftHistory';
import { IDocumentDraftHistoryRecord } from '../../../common/dictionary-draft-models/IDocumentDraftHistoryRecord';
import { useAbortController } from '../../../../../hooks/useAbortController';
import FormOptions from '../../../../common/form-options-bar/FormOptions';
import { IUiInputText } from '../../../../common/input-v2/input-text/InputText';

interface ISearchStrings {
  searchString: string[];
  searchStringId: string[];
}

const SetCollapsed = (itemsToUpdate?: IBusinessTagsDictionaryDraftItem[]) => {
  if (itemsToUpdate) {
    itemsToUpdate.forEach(item => {
      item.isCollapsed = true;
      SetCollapsed(item.items);
    })
  };
}

const RefreshItems = (itemsToUpdate: IBusinessTagsDictionaryDraftItem[], newPath?: string): ISearchStrings => {
  //console.log(itemsToUpdate)
  let searchStringTotal: string[] = [];
  let searchStringIdTotal: string[] = [];
  //---------------------------------------------------------------------------
  itemsToUpdate.forEach(itemToUpdate => {
    let itemState: DraftItemStateEnum = itemToUpdate.draftState;
    //-------------------------------------------------------------------------
    itemState &= ~DraftItemStateEnum.HasNoTranslation;
    itemState &= ~DraftItemStateEnum.HasTranslationEdited;
    itemState &= ~DraftItemStateEnum.HasEdited;
    itemState &= ~DraftItemStateEnum.HasNew;
    itemState &= ~DraftItemStateEnum.HasRepositionedNew;
    itemState &= ~DraftItemStateEnum.HasRepositionedOld;
    itemState &= ~DraftItemStateEnum.HasReordered;
    itemState &= ~DraftItemStateEnum.HasDeleted;
    itemState &= ~DraftItemStateEnum.HasNothing;
    itemState &= ~DraftItemStateEnum.HasOnlyChild;
    if (itemToUpdate.items && itemToUpdate.items.length == 1) {
      itemState |= DraftItemStateEnum.HasOnlyChild;
    };
    itemState &= ~DraftItemStateEnum.HasNoChildren;
    if (itemToUpdate.isGroup && (!itemToUpdate.items || itemToUpdate.items.length == 0)) {
      itemState |= DraftItemStateEnum.HasNoChildren;
    }
    //-------------------------------------------------------------------------
    //console.log(itemToUpdate.id)
    var hasWarning = false;
    var hasEdited = false;
    var hasTranslationEdited = false;
    var hasNew = false;
    var hasDeleted = false;
    var hasRepositionedOld = false;
    var hasRepositionedNew = false;
    //var hasReordered = false;
    //-------------------------------------------------------------------------
    if (newPath) {
      //itemToUpdate.oldPath = itemToUpdate.path;
      itemToUpdate.path = `${newPath}.${itemToUpdate.id}`;
    }
    //-------------------------------------------------------------------------
    // Update item search string: it should contain item Id and Name in current locale
    // NOTE: search string in multi-language text as other text properties
    let searchString: string[] = [itemToUpdate.id];
    // This search string includes only Ids
    let searchStringId: string[] = [itemToUpdate.id];
    const itemName = itemToUpdate.name ? itemToUpdate.name["en"] : undefined;
    if (itemName)
      searchString.push(itemName);
    //-------------------------------------------------------------------------
    // Update search strings with data from children
    if (itemToUpdate.items && itemToUpdate.items.length > 0) {
      const searchStringsAddition: ISearchStrings = RefreshItems(itemToUpdate.items, `${itemToUpdate.path}`);
      //console.log(searchStringsAddition)
      searchString = searchString.concat(searchStringsAddition.searchString);
      searchStringId = searchStringId.concat(searchStringsAddition.searchStringId);
    };
    //-------------------------------------------------------------------------
    itemToUpdate.searchString["en"] = searchString.join(",").toLocaleLowerCase();
    itemToUpdate.searchStringId = searchStringId.join(",").toLocaleLowerCase();
    //console.log(itemToUpdate.searchString["en"])
    if (searchString)
      searchStringTotal = searchStringTotal.concat(searchString);
    if (searchStringId)
      searchStringIdTotal = searchStringIdTotal.concat(searchStringId);
    //console.log(searchStringTotal)
    //-------------------------------------------------------------------------
    // Set up flags
    itemToUpdate.items?.forEach(subItem => {
      if ((subItem.draftState & (DraftItemStateEnum.IsNoTranslation | DraftItemStateEnum.HasNoTranslation)) != DraftItemStateEnum.None) {
        itemState |= DraftItemStateEnum.HasNoTranslation;
      };
      if ((subItem.draftState & (DraftItemStateEnum.IsTranslationEdited | DraftItemStateEnum.HasTranslationEdited)) != DraftItemStateEnum.None) {
        itemState |= DraftItemStateEnum.HasTranslationEdited;
      };
      if ((subItem.draftState & (DraftItemStateEnum.IsEdited | DraftItemStateEnum.HasEdited)) != DraftItemStateEnum.None) {
        itemState |= DraftItemStateEnum.HasEdited;
      };
      if ((subItem.draftState & (DraftItemStateEnum.IsNew | DraftItemStateEnum.HasNew)) != DraftItemStateEnum.None) {
        itemState |= DraftItemStateEnum.HasNew;
      };
      if ((subItem.draftState & (DraftItemStateEnum.IsRepositionedNew | DraftItemStateEnum.HasRepositionedNew)) != DraftItemStateEnum.None) {
        itemState |= DraftItemStateEnum.HasRepositionedNew;
      };
      if ((subItem.draftState & (DraftItemStateEnum.IsRepositionedOld | DraftItemStateEnum.HasRepositionedOld)) != DraftItemStateEnum.None) {
        //console.log("aaaaa")
        itemState |= DraftItemStateEnum.HasRepositionedOld;
      };
      if ((subItem.draftState & (DraftItemStateEnum.IsReordered | DraftItemStateEnum.HasReordered)) != DraftItemStateEnum.None) {
        itemState |= DraftItemStateEnum.HasReordered;
      };
      if ((subItem.draftState & (DraftItemStateEnum.IsDeleted | DraftItemStateEnum.HasDeleted)) != DraftItemStateEnum.None) {
        itemState |= DraftItemStateEnum.HasDeleted;
      };
      if ((subItem.draftState & (DraftItemStateEnum.IsNothing | DraftItemStateEnum.HasNothing)) != DraftItemStateEnum.None) {
        itemState |= DraftItemStateEnum.HasNothing;
      };
      if ((subItem.draftState & DraftItemStateEnum.HasOnlyChild) != DraftItemStateEnum.None) {
        itemState |= DraftItemStateEnum.HasOnlyChild;
      };
      if ((subItem.draftState & DraftItemStateEnum.HasNoChildren) != DraftItemStateEnum.None) {
        itemState |= DraftItemStateEnum.HasNoChildren;
      };
      //-----------------------------------------------------------------------
      //console.log(subItem)
      if (!subItem.name?.en || subItem.hasWarning)
        hasWarning = true;
      if (subItem.isEdited || subItem.hasEdited)
        hasEdited = true;
      if (subItem.translationIsEdited || subItem.hasTranslationEdited)
        hasTranslationEdited = true;
      if (subItem.isNew || subItem.hasNew)
        hasNew = true;
      if (subItem.isDeleted || subItem.hasDeleted)
        hasDeleted = true;
      if (subItem.isRepositionedOld || subItem.hasRepositionedOld)
        hasRepositionedOld = true;
      if (subItem.isRepositionedNew || subItem.hasRepositionedNew)
        hasRepositionedNew = true;
    });
    //-------------------------------------------------------------------------
    itemToUpdate.draftState = itemState;
    //------------------------------------------------------------------------- to remove once flags are ready
    itemToUpdate.hasWarning = hasWarning;
    itemToUpdate.hasEdited = hasEdited;
    itemToUpdate.hasTranslationEdited = hasTranslationEdited;
    itemToUpdate.hasNew = hasNew;
    itemToUpdate.hasDeleted = hasDeleted;
    itemToUpdate.hasRepositionedOld = hasRepositionedOld;
    itemToUpdate.hasRepositionedNew = hasRepositionedNew;
    //itemToUpdate.hasReordered = hasReordered;
    //console.log(itemToUpdate)
  });
  //---------------------------------------------------------------------------
  return {
    searchString: searchStringTotal,
    searchStringId: searchStringIdTotal
  };
}

export interface IItemStateGroup {
  id: string;
  name: string;
  iconId?: string; // if this is provided, only one icon for the group will be shown
  color?: string;
  states?: IDraftStateFormat[];
}
export interface IDraftStateFormat {
  draftState: DraftItemStateEnum;
  iconId?: string;
  iconColor?: string;
  fontColor?: string; // this color if specified would override color from IItemChangeType
  fontWeight?: string;
  fontStyle?: string;
  textDecoration?: string;
}

const statesV1: IItemStateGroup[] = [{
  id: 'notranslation',
  name: "No Translation",
  color: 'orange',
  states: [{
    draftState: DraftItemStateEnum.HasNoTranslation,
    iconId: 'attention',
    fontColor: 'undefined'
  }, {
    draftState: DraftItemStateEnum.IsNoTranslation,
    iconId: 'circle'
  }]
}, {
  id: 'incorrectChildren',
  name: "Incorrect Children",
  color: 'cyan',
  states: [{
    draftState: DraftItemStateEnum.HasOnlyChild,
    iconId: 'attention'
  }, {
    draftState: DraftItemStateEnum.HasNoChildren,
    iconId: 'zero'
  }]
}, {
  id: 'editedt',
  name: "Edited Translation",
  color: 'mediumspringgreen',
  states: [{
    draftState: DraftItemStateEnum.IsTranslationEdited,
    iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasTranslationEdited,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}, {
  id: 'edited',
  name: "Edited",
  color: 'blue',
  states: [{
    draftState: DraftItemStateEnum.IsEdited,
    iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasEdited,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}, {
  id: 'repositioned',
  name: "Repositioned",
  color: 'magenta',
  states: [{
    draftState: DraftItemStateEnum.IsRepositionedNew,
    iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasRepositionedNew,
    iconId: 'newLabel',
    fontColor: 'inherit'
  }, {
    draftState: DraftItemStateEnum.IsRepositionedOld,
    iconId: 'circle',
    textDecoration: 'line-through'
  }, {
    draftState: DraftItemStateEnum.HasRepositionedOld,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}, {
  id: 'new',
  name: "New",
  color: 'mediumpurple',
  states: [{
    draftState: DraftItemStateEnum.IsNew,
    iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasNew,
    iconId: 'newLabel',
    fontColor: 'inherit'
  }]
}, {
  id: 'deleted',
  name: "Deleted",
  color: 'tomato',
  states: [{
    draftState: DraftItemStateEnum.IsDeleted,
    iconId: 'circle',
    textDecoration: 'line-through'
  }, {
    draftState: DraftItemStateEnum.HasDeleted,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}, {
  id: 'reordered',
  name: "Re-ordered",
  color: 'yellowgreen',
  states: [{
    draftState: DraftItemStateEnum.IsReordered,
    iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasReordered,
    iconId: 'attention',
    fontColor: 'inherit'
  }]
}, {
  id: 'nothing',
  name: "Nothing",
  color: 'gray',
  states: [{
    draftState: DraftItemStateEnum.IsNothing,
    //iconId: 'circle'
  }, {
    draftState: DraftItemStateEnum.HasNothing,
    iconId: 'attention'
  }]
}]
//-----------------------------------------------------------------------------
const statesV2: IItemStateGroup[] = [{
  id: 'incorrect',
  name: "Incorrect",
  iconId: 'attention',
  color: 'yellow',
  states: [{
    draftState: DraftItemStateEnum.IsNoTranslation,
    fontColor: 'undefined'
  }, {
    draftState: DraftItemStateEnum.HasNoTranslation,
    fontColor: 'undefined'
  }, {
    draftState: DraftItemStateEnum.HasOnlyChild,
    fontColor: 'undefined'
  }, {
    draftState: DraftItemStateEnum.HasNoChildren,
    fontColor: 'undefined'
  }]
}, {
  id: 'new',
  name: "New",
  color: 'green',
  states: [{
    draftState: DraftItemStateEnum.IsNew,
    fontColor: 'green'
  }, {
    draftState: DraftItemStateEnum.HasNew,
    fontColor: 'undefined'
  }]
}, {
  id: 'deleted',
  name: "Deleted",
  color: 'red',
  states: [{
    draftState: DraftItemStateEnum.IsDeleted,
    textDecoration: 'line-through'
  }, {
    draftState: DraftItemStateEnum.HasDeleted,
    fontColor: 'undefined'
  }]
}, {
  id: 'repositioned',
  name: "Repositioned",
  color: 'blue',
  states: [{
    draftState: DraftItemStateEnum.IsRepositionedNew
  }, {
    draftState: DraftItemStateEnum.HasRepositionedNew,
    fontColor: 'undefined'
  }, {
    draftState: DraftItemStateEnum.IsRepositionedOld,
    textDecoration: 'line-through'
  }, {
    draftState: DraftItemStateEnum.HasRepositionedOld,
    fontColor: 'undefined'
  }]
}, {
  id: 'updated',
  name: "Updated",
  color: 'yellow',
  states: [{
    draftState: DraftItemStateEnum.IsTranslationEdited
  }, {
    draftState: DraftItemStateEnum.HasTranslationEdited,
    fontColor: 'orange',
    fontWeight: 'bold'
  }, {
    draftState: DraftItemStateEnum.IsEdited
  }, {
    draftState: DraftItemStateEnum.HasEdited,
    fontColor: 'orange',
    fontWeight: 'bold'
  }]
}, {
  id: 'nothing',
  name: "Nothing",
  color: 'gray',
  states: [{
    draftState: DraftItemStateEnum.HasNothing,
    fontColor: 'undefined'
  }, {
    draftState: DraftItemStateEnum.IsNothing
  }]
}]

export interface IFilter {
  text?: string;
  draftState: DraftItemStateEnum;
  grouping: IItemStateGroup[];
  groups: string[];
  searchById: boolean;
}

const closeOption: TUiMenuOption = {
  id: "optionClose",
  directoryId: "",
  elementType: "",
  index: 1,
  priorityLevel: 1,
  caption: { en: "Close" },
  hint: null,
  iconFile: "close",
  isDefault: false,
  disabled: false,
  visible: true,
  canHideCaption: false,
  action: null
}
const enableEditModeOption: TUiMenuOption = {
  id: "optionEnableEditMode",
  directoryId: "",
  elementType: "",
  index: 1,
  priorityLevel: 1,
  caption: { en: "Enable Edit Mode" },
  hint: null,
  iconFile: "pencil",
  isDefault: false,
  disabled: false,
  visible: true,
  canHideCaption: false,
  action: null
}
const disableEditModeOption: TUiMenuOption = {
  id: "optionDisableEditMode",
  directoryId: "",
  elementType: "",
  index: 1,
  priorityLevel: 1,
  caption: { en: "Exit Edit Mode" },
  hint: null,
  iconFile: "pencil",
  isDefault: false,
  disabled: false,
  visible: true,
  canHideCaption: false,
  action: null
}
const saveOption: TUiMenuOption = {
  id: "optionSave",
  directoryId: "",
  elementType: "",
  index: 1,
  priorityLevel: 1,
  caption: { en: "Save Draft" },
  hint: null,
  iconFile: "check",
  isDefault: true,
  disabled: false,
  visible: true,
  canHideCaption: false,
  action: null
}
const publishOption: TUiMenuOption = {
  id: "optionPublish",
  directoryId: "",
  elementType: "",
  index: 1,
  priorityLevel: 1,
  caption: { en: "Publish" },
  hint: null,
  iconFile: "publish",
  isDefault: false,
  disabled: false,
  visible: true,
  canHideCaption: false,
  action: null
}
const deleteDraftOption: TUiMenuOption = {
  id: "optionDraftDelete",
  directoryId: "",
  elementType: "",
  index: 1,
  priorityLevel: 1,
  caption: { en: "Delete draft" },
  hint: null,
  iconFile: "delete",
  isDefault: false,
  disabled: false,
  visible: true,
  canHideCaption: false,
  action: null
}
const editFormOptionsMenuContent: TUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  visible: true,
  disabled: false,
  options: [closeOption, deleteDraftOption, enableEditModeOption, disableEditModeOption, publishOption, saveOption],
  optionGroups: []
};
export const tmpSearchInput: IUiInputText = {
  id: "BusinessTagsSearchInput",
  directoryId: "",
  elementType: "",
  disabled: false,
  visible: true,
  caption: {},
  hint: { en: "This input is for filtering tags tree" },
  placeholder: { en: "Type keyword to search..." },
  validation: undefined
}
const tmpConfirmationDialogOptionsMenuContent: TUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  visible: true,
  disabled: false,
  options: [{
    id: "optionDelete",
    directoryId: "",
    elementType: "",
    index: 1,
    priorityLevel: 1,
    caption: { en: "Delete" },
    hint: null,
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    canHideCaption: false,
    action: null
  }, {
    id: "optionCancel",
    directoryId: "",
    elementType: "",
    index: 1,
    priorityLevel: 1,
    caption: { en: "Cancel" },
    hint: null,
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    canHideCaption: false,
    action: null
  }],
  optionGroups: []
}

export default function BusinessTagsEditor() {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContextDispatch } = useContext(AppUiContextStore);
  const [dictionary, setDictionary] = useState<IBusinessTagsDictionaryDraft>();
  const [dictionaryStatus, setDictionaryStatus] = useState<IDocumentDraftHistoryRecord>();
  const [readonly, setReadonly] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [userCantPublish, setUserCantPublish] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<IFilter>({
    text: undefined,
    draftState: DraftItemStateEnum.All,
    grouping: statesV1,
    groups: statesV1.map(g => g.id),
    searchById: true
  });
  const [showSystemId, setShowSystemId] = useState(false);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  // Initial state of the current edit session (i.e. includes changes made to the draft)
  // We will use it to enable/disable save option
  const dictionarySessionInitialState = useRef<IBusinessTagsDictionaryDraft>();
  // True initial state, i.e. original (without changes made to the draft)
  // We will use to detect actual changes in the dictionary
  const dictionaryInitialState = useRef<IBusinessTagsDictionaryDraft>();
  const navigate = useNavigateWithContext();
  const abortController = useAbortController("BusinessTagsEditor");
  //--------------------------------------------------------------------------- Handle data 
  useEffect(() => {
    // Retrieve business tags dictionary from database
    if (!dictionary) {
      setIsLoading(true);
      let controller = abortController.newController("GetDictionaryDraft");
      apiGetPrivate(
        instance,
        appAuthContext.config,
        `${apiBasePath}${stringFormatter(pathGetDictionaryDraft, ["BusinessTags"])}`,
        controller.signal
      ).then(response => {
        checkResponse(response, "GetDictionaryDraft", controller.aborted);
        console.log(response?.content)
        const loadedDictionary = response?.content as IBusinessTagsDictionaryDraft;
        SetCollapsed(loadedDictionary.items);
        setDictionary(loadedDictionary);
        dictionarySessionInitialState.current = loadedDictionary;
        dictionaryInitialState.current = JSON.parse(loadedDictionary.initialStateStringified);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    }
    return (() => {
      abortController.abortOnUnmount();
    })
  }, []);
  //--------------------------------------------------------------------------- Check & update draft status
  useEffect(() => {
    // Update header based on the current status
    const lastHistoryRecord = dictionary?.historyRecords[dictionary?.historyRecords.length - 1];
    //console.log(lastHistoryRecord)
    setDictionaryStatus(lastHistoryRecord);
    //-------------------------------------------------------------------------
    // Decide whether user is allowed to publish
    let userIsAllowedToPublish = true;
    if (lastHistoryRecord?.step == "Draft") {
      userIsAllowedToPublish = true;
    } else {
      // Check previous steps up to last draft step
      if (dictionary?.historyRecords) {
        let i = dictionary.historyRecords.length - 1;
        while (i >= 0) {
          let historyItem = dictionary.historyRecords[i];
          console.log(i, historyItem)
          if (historyItem.staffUser && historyItem.staffUser.userId == appAuthContext.user?.userIdentity.id)
            userIsAllowedToPublish = false;
          i = (historyItem.step == "Draft") ? -1 : i - 1;
          console.log(i);
        };
      };
    };
    setUserCantPublish(!userIsAllowedToPublish);
    //-------------------------------------------------------------------------
    // Check actual changes (meaning they are not reverted changes)
    if (DraftsAreEqual(dictionary, dictionarySessionInitialState.current)) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    };
    //-------------------------------------------------------------------------
    // Check if dictionary is valid (can be published)
    // ATTENTION: Need a list of conditions !!!
    let isValid = true;
    dictionary?.items.forEach(rootTag => {
      if ((rootTag.draftState & DraftItemStateEnum.HasOnlyChild) == DraftItemStateEnum.HasOnlyChild) {
        isValid = false;
      };
      if ((rootTag.draftState & DraftItemStateEnum.HasNoChildren) == DraftItemStateEnum.HasNoChildren) {
        isValid = false;
      };
    });
    setIsValid(isValid);
  }, [dictionary, dictionarySessionInitialState.current, appAuthContext]);
  //--------------------------------------------------------------------------- Handle breadcrumb
  useEffect(() => {
    let header = "Business Tags";
    let status = "";
    switch (dictionaryStatus?.step) {
      case "Draft":
        status = "Draft";
        break;
      case "FirstApproval":
        status = "Waiting for approval: 2 of 3";
        break;
      case "FinalApproval":
        status = "Waiting for approval: 3 of 3";
        break;
      default:
        status = "Undefined";
        break;
    };
    header = `${header} (${status})`;
    const breadcrumb: TUiBreadcrumb = {
      id: "BusinessTags",
      caption: { en: header },
      path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}/${pathBusinessTagsEditor}`
    };
    appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
  }, [dictionaryStatus]);
  //---------------------------------------------------------------------------
  const setGroupingType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const grouping: IItemStateGroup[] = (event.target.value == "statesV1") ? statesV1 : statesV2;
    const updatedFilter: IFilter = {
      ...filter as IFilter,
      draftState: DraftItemStateEnum.All,
      grouping: grouping,
      groups: grouping.map(g => g.id)
    };
    setFilter(updatedFilter);
  };
  //---------------------------------------------------------------------------
  const onClose = () => {
    navigate(`${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}`);
  };
  //---------------------------------------------------------------------------
  const onEnableEditMode = () => {
    setReadonly(false);
  };
  //---------------------------------------------------------------------------
  const onDisableEditMode = () => {
    setReadonly(true);
  };
  //---------------------------------------------------------------------------
  const onSave = () => {
    if (!dictionary) {
      return;
    }
    console.log(dictionary)
    setIsLoading(true);
    let controller = abortController.newController("UpdateDictionaryDraft");
    let dictionaryToPass = { ...dictionary, locker: undefined };
    apiPostPrivate(
      instance,
      appAuthContext.config,
      `${apiBasePath}${stringFormatter(pathUpdateDictionaryDraft, ["BusinessTags"])}`,
      dictionaryToPass
    ).then(response => {
      checkResponse(response, "UpdateDictionaryDraft", controller.aborted);
      const loadedDictionary = response?.content as IBusinessTagsDictionaryDraft;
      SetCollapsed(loadedDictionary.items);
      setDictionary(loadedDictionary);
      setIsChanged(false);
      dictionarySessionInitialState.current = loadedDictionary;
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onDraftDelete = () => {
    // Ask user to confirm deletion
    setDeleteConfirmationDialogOpen(true);
  };
  //---------------------------------------------------------------------------
  const onDeleteConfirmationDialogOptionSelect = (optionId: string) => {
    setDeleteConfirmationDialogOpen(false);
    switch (optionId) {
      case "optionDelete":
        deleteDraft();
        break;
      case "optionCancel":
        onDeleteCancel();
        break;
    }
  };
  //---------------------------------------------------------------------------
  const onDeleteCancel = () => {
    setDeleteConfirmationDialogOpen(false);
  };
  //---------------------------------------------------------------------------
  const deleteDraft = () => {
    if (!dictionary) {
      return;
    }
    setIsLoading(true);
    let controller = abortController.newController("ArchiveDictionaryDraft");
    apiPostPrivate(
      instance,
      appAuthContext.config,
      `${apiBasePath}${stringFormatter(pathArchiveDictionaryDraft, ["BusinessTags"])}`,
      undefined, undefined,
      controller.signal
    ).then(response => {
      checkResponse(response, "ArchiveDictionaryDraft", controller.aborted);
      const loadedDictionary = response?.content as IBusinessTagsDictionaryDraft;
      SetCollapsed(loadedDictionary.items);
      setDictionary(loadedDictionary);
      setIsChanged(false);
      dictionarySessionInitialState.current = loadedDictionary;
      // After successful archiving set draft readonly
      setReadonly(true);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onPublish = () => {
    if (!dictionary) {
      return;
    }
    //console.log(dictionary)
    setIsLoading(true);
    let controller = abortController.newController("ArchiveDictionaryDraft");
    apiPostPrivate(
      instance,
      appAuthContext.config,
      `${apiBasePath}${stringFormatter(pathPublishDictionaryDraft, ["BusinessTags"])}`
    ).then(response => {
      checkResponse(response, "PublishDictionaryDraft", controller.aborted);
      // This API should respond with updated draft
      const loadedDictionary = response?.content as IBusinessTagsDictionaryDraft;
      SetCollapsed(loadedDictionary.items);
      setDictionary(loadedDictionary);
      setIsChanged(false);
      dictionarySessionInitialState.current = loadedDictionary;
      // After successful publishing set draft readonly (whatever the stage)
      setReadonly(true);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  }
  //---------------------------------------------------------------------------
  const onSearchInputChange = (value: string) => {
    if (value != undefined && value !== "")
      setFilter({
        ...filter as IFilter,
        text: value.toLowerCase()
      });
    else
      setFilter({
        ...filter as IFilter,
        text: undefined
      });
  };
  //--------------------------------------------------------------------------- Filter condition update
  const onLegendItemSwitch = (draftStateId: string, enabled: boolean) => {
    console.log(draftStateId)
    let draftState: DraftItemStateEnum = filter.draftState;
    //-------------------------------------------------------------------------
    // Update groups list to check if all the groups are switched on
    let updatedGroupsList: string[] = [];
    if (filter.groups.includes(draftStateId)) {
      updatedGroupsList = filter.groups.filter(ct => ct != draftStateId);
    } else {
      updatedGroupsList = filter.groups.concat(draftStateId);
    };
    //-------------------------------------------------------------------------
    if (updatedGroupsList.length == filter.grouping.length) {
      draftState = DraftItemStateEnum.All;
    } else if (updatedGroupsList.length == 0) {
      draftState = DraftItemStateEnum.None;
    } else {
      // Get selected state group data
      const stateGroup = filter.grouping.find(g => g.id == draftStateId);
      // Get selected group states
      stateGroup?.states?.forEach(state => {
        if (enabled) {
          draftState |= state.draftState;
        } else {
          draftState &= ~state.draftState;
        };
      });
    };
    //-------------------------------------------------------------------------
    setFilter({
      ...filter as IFilter,
      draftState: draftState,
      groups: updatedGroupsList
    })
  };
  //--------------------------------------------------------------------------- Handle business tags tree update
  const onRootTagUpdate = (
    updatedTag: IBusinessTagsDictionaryDraftItem,
    newParentTag?: IBusinessTagsDictionaryDraftItem
  ) => {
    console.log("onRootTagUpdate")
    console.log(updatedTag)
    console.log(newParentTag)
    if (dictionary) {
      setIsLoading(true);
      //-----------------------------------------------------------------------
      // Create a copy of business tags tree
      let rootTags: IBusinessTagsDictionaryDraftItem[] = [];
      let rootTagsToRefresh: IBusinessTagsDictionaryDraftItem[] = [];
      //----------------------------------------------------------------------- Locate tags to update
      // Locate first (and possibly last) root tag to start with
      const tagPathElements = updatedTag.path.split('.');
      const rootTagToStartWith = dictionary.items.find(tag => tag.id == tagPathElements[0]);
      if (rootTagToStartWith) {
        if (tagPathElements.length == 1) {
          //-------------------------------------------------------------------
          // We are updating root tag. In this case 'newParentTag' is irrelevant and cannot be provided
          // So we just update root tags list
          rootTags = dictionary.items.map(item => (item.id == updatedTag.id) ? updatedTag : item);
          rootTagsToRefresh = [updatedTag];
        } else {
          //-------------------------------------------------------------------
          // We are updating tag(s) in the middle of the tree
          let rootTagToRefresh = rootTagToStartWith;
          rootTagsToRefresh.push(rootTagToRefresh);
          console.log("root tag", rootTagToRefresh)
          // Now we need to determine a first parent tag
          let parentTagToUpdate: IBusinessTagsDictionaryDraftItem | undefined = undefined;
          //-------------------------------------------------------------------
          // Locate parent tag
          console.log("Locating parent tag...")
          parentTagToUpdate = rootTagToStartWith;
          for (let i = 1; i < tagPathElements.length - 1; i++) {
            console.log(i)
            parentTagToUpdate = parentTagToUpdate?.items?.find(item => item.id == tagPathElements[i]);
          };
          if (parentTagToUpdate && parentTagToUpdate.items) {
            // (First) Parent tag is located, we can continue
            console.log("parent tag", parentTagToUpdate)
            if (!newParentTag) {
              //---------------------------------------------------------------
              // No new parent so we just updating existing tag
              const updatedChildren = parentTagToUpdate.items.map(item => (item.id == updatedTag.id) ? updatedTag : item);
              parentTagToUpdate.items = updatedChildren;
              console.log("updated parent tag", parentTagToUpdate)
              rootTags = dictionary.items;
            } else {
              //--------------------------------------------------------------- REPOSITION
              // We need to reposition the tag, i.e. delete it in one place and add in another
              // 1) First locate new parent tag and update its children list - add the tag
              let newRootTagToRefresh: IBusinessTagsDictionaryDraftItem | undefined;
              const newPathElements = newParentTag.path.split('.');
              let newParentTagToUpdate: IBusinessTagsDictionaryDraftItem | undefined;
              let tagsToSearch = dictionary.items;
              for (let i = 0; i < newPathElements.length; i++) {
                newParentTagToUpdate = tagsToSearch.find(item => item.id == newPathElements[i]);
                //-------------------------------------------------------------
                // Locate new root tag to refresh
                if (i == 0 && newParentTagToUpdate) {
                  newRootTagToRefresh = newParentTagToUpdate;
                  if (newRootTagToRefresh.id != rootTagToRefresh.id)
                    rootTagsToRefresh.push(newRootTagToRefresh);
                };
                //-------------------------------------------------------------
                // Move on to search parent tag
                if (newParentTagToUpdate && newParentTagToUpdate.items) {
                  tagsToSearch = newParentTagToUpdate.items;
                };
              };
              if (newParentTagToUpdate) {
                //-------------------------------------------------------------
                // Create a copy of updated tag and add it to the children of the new parent
                const updatedTagCopy: IBusinessTagsDictionaryDraftItem = JSON.parse(JSON.stringify(updatedTag));
                updatedTagCopy.draftState |= DraftItemStateEnum.IsRepositionedNew;
                updatedTagCopy.draftState &= ~DraftItemStateEnum.IsReordered; // if it's repositioned, then it does not matter if it was reordered before
                updatedTagCopy.isRepositionedNew = true;
                updatedTagCopy.oldPath = updatedTagCopy.path;
                newParentTagToUpdate.items?.push(updatedTagCopy);
                rootTags = dictionary.items;
                //-------------------------------------------------------------
                // 2) Locate the old tag position
                const oldTagVersion = parentTagToUpdate.items.find(item => item.id == updatedTag.id);
                if (oldTagVersion) {
                  // Then check if it's not a first reposition (it's already was repositioned)
                  if ((oldTagVersion.draftState & DraftItemStateEnum.IsRepositionedNew) != DraftItemStateEnum.None) {
                    // It's not a first reposition, so
                    // We need to just remove the tag from its current position
                    parentTagToUpdate.items = parentTagToUpdate.items.filter(item => item.id != updatedTag.id);
                  } else {
                    //---------------------------------------------------------
                    // It's a first reposition, so
                    // Update parent tag to mark tag as removed to a new position
                    oldTagVersion.draftState |= DraftItemStateEnum.IsRepositionedOld;
                  };

                  /* // Then check if it's not a first reposition
                  if (oldTagVersion.isRepositionedNew) {
                    // It's not a first reposition, so
                    // We need to just remove the tag from its current position
                    parentTagToUpdate.items = parentTagToUpdate.items.filter(item => item.id != updatedTag.id);
                  } else {
                    //---------------------------------------------------------
                    // It's a first reposition, so
                    // Update parent tag to mark tag as removed to a new position
                    oldTagVersion.isRepositionedOld = true;
                  }; */
                } else console.error("Could not find tag to reposition...");
                //-------------------------------------------------------------
                // 3) Additionally, check if the new position is the same as old position (yes, that could be the case)
                // In this case destination parent tag will contain both versions of the tag: old and new
                // so we need to revert the reposition alltogether
                const copies = newParentTagToUpdate.items?.filter(item => item.id == updatedTag.id);
                if (copies && copies.length > 1) {
                  // Remove new position tag, we don't need it anymore
                  newParentTagToUpdate.items = newParentTagToUpdate.items?.filter(item => !(item.id == updatedTag.id && item.isRepositionedNew));
                  // Revert old position tag status to original, i.e. make it appear unchanged
                  const oldItemToRestore = newParentTagToUpdate.items?.find(item => item.id == updatedTag.id && item.isRepositionedOld);
                  if (oldItemToRestore) {
                    oldItemToRestore.draftState &= ~DraftItemStateEnum.IsRepositionedOld;
                    oldItemToRestore.isRepositionedOld = false;
                  };
                };
              } else console.error(`Could not locate new parent tag with path [${newParentTag.path}]`);
            };
          } else {
            console.error("Unable to locate parent tag containing updated tag");
          };
        };
        console.log("root tags to update", rootTagsToRefresh)
        //---------------------------------------------------------------------
        // Refresh root tags affected by the update
        console.log("-----------------------------------------")
        RefreshItems(rootTagsToRefresh);
        //---------------------------------------------------------------------
        // Update dictionary draft
        const updatedDictionary: IBusinessTagsDictionaryDraft = {
          ...dictionary as IBusinessTagsDictionaryDraft,
          items: rootTags
        };
        console.log(updatedDictionary)
        setDictionary(updatedDictionary);
        // Change a priori is not actual if it's a collapsed/expanded node
        // if (isActualChange) {
        //   checkChanges(updatedDictionary);
        // };
      } else console.error("Unable to locate root tag containing updated tag");
      setIsLoading(false);
    } else console.error("Cannot update tags, dictionary draft is not set");
  };
  //---------------------------------------------------------------------------
  const onTagDelete = (path: string) => {
    console.log("delete: " + path)
    // Locate parent tag to update (only parent because we can't delete root categories)
    const tagPathElements = path.split('.');
    let updatedBusinessTags: IBusinessTagsDictionaryDraftItem[] = JSON.parse(JSON.stringify(dictionary?.items));
    let parentTagToUpdate: IBusinessTagsDictionaryDraftItem | undefined = undefined;
    let tagsToUpdate = updatedBusinessTags;
    for (let i = 0; i < tagPathElements.length - 1; i++) {
      parentTagToUpdate = tagsToUpdate.find(item => item.id == tagPathElements[i]);
      if (parentTagToUpdate && parentTagToUpdate.items) {
        tagsToUpdate = parentTagToUpdate.items;
      };
    };
    console.log(parentTagToUpdate)
    //-------------------------------------------------------------------------
    const rootItemToRefresh = updatedBusinessTags.find(item => item.id == tagPathElements[0]);
    console.log(rootItemToRefresh)
    //-------------------------------------------------------------------------
    if (parentTagToUpdate && rootItemToRefresh) {
      const updatedItems = parentTagToUpdate?.items?.filter(item => item.path != path);
      parentTagToUpdate.items = updatedItems;
      RefreshItems([rootItemToRefresh]);
      const updatedDictionary: IBusinessTagsDictionaryDraft = {
        ...dictionary as IBusinessTagsDictionaryDraft,
        items: updatedBusinessTags
      };
      setDictionary(updatedDictionary);
      //checkChanges(updatedDictionary);
    };
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      //---------------------------------------------------------------------
      case "optionClose":
        onClose();
        break;
      //---------------------------------------------------------------------
      case "optionEnableEditMode":
        if (readonly) {
          onEnableEditMode();
        };
        break;
      //---------------------------------------------------------------------
      case "optionDisableEditMode":
        if (!readonly) {
          onDisableEditMode();
        };
        break;
      //---------------------------------------------------------------------
      case "optionDraftDelete":
        if (!readonly) {
          onDraftDelete();
        };
        break;
      //---------------------------------------------------------------------
      case "optionPublish":
        if (!readonly) {
          onPublish();
        };
        break;
      //---------------------------------------------------------------------
      case "optionSave":
        if (!readonly) {
          onSave();
        };
        break;
    };
  };
  //--------------------------------------------------------------------------- Legend & Filter
  const legendItems = filter.grouping.map(item => (
    <LegendItem
      key={item.id}
      ui={item}
      enabled={filter.groups.includes(item.id)}
      onSwitch={(enabled: boolean) => onLegendItemSwitch(item.id, enabled)}
    />
  ));
  //--------------------------------------------------------------------------- Options
  var updatedOptions: TUiMenuOption[] = [];
  editFormOptionsMenuContent.options.forEach(option => {
    switch (option.id) {
      //---------------------------------------------------------------------
      case "optionClose":
        updatedOptions.push({
          ...option,
          disabled: false
        });
        break;
      //---------------------------------------------------------------------
      case "optionEnableEditMode":
        if (readonly) {
          updatedOptions.push({
            ...option,
            disabled: !dictionary || userCantPublish
          });
        };
        break;
      //---------------------------------------------------------------------
      case "optionDisableEditMode":
        if (!readonly) {
          updatedOptions.push({
            ...option
          });
        };
        break;
      //---------------------------------------------------------------------
      case "optionDraftDelete":
        if (!readonly) {
          updatedOptions.push({
            ...option,
            disabled: false
          });
        };
        break;
      //---------------------------------------------------------------------
      case "optionPublish":
        if (!readonly) {
          updatedOptions.push({
            ...option,
            disabled: !dictionary?.isUpdated || !dictionary || isChanged || userCantPublish || !isValid
          });
        };
        break;
      //---------------------------------------------------------------------
      case "optionSave":
        if (!readonly) {
          updatedOptions.push({
            ...option,
            disabled: !(dictionary && isChanged)
          });
        };
        break;
    };
  });
  const editorOptions =
    <FormOptions
      ui={{
        ...editFormOptionsMenuContent,
        options: updatedOptions
      }}
      optionIdVisibleOnMobile="optionClose"
      onSelect={onOptionSelect}
    />;
  //--------------------------------------------------------------------------- Tags
  let rootTagElements: any[] = [];
  if (dictionary && dictionary.items) {
    //-------------------------------------------------------------------------
    // Filter root tags list
    const rootTags: IBusinessTagsDictionaryDraftItem[] = dictionary.items.filter(item => {
      // By default include all entries
      let resultText = true;
      let resultState = true;
      //-----------------------------------------------------------------------
      // Filter by text
      if (filter.text) {
        resultText = filter.searchById ? item.searchStringId.includes(filter.text) : item.searchString["en"].includes(filter.text);
      };
      //-----------------------------------------------------------------------
      // Filter by state
      if (filter.draftState !== DraftItemStateEnum.All) {
        resultState = (item.draftState & filter.draftState) != DraftItemStateEnum.None;
        console.log(item.id, filter.draftState, item.draftState)
      };
      //-----------------------------------------------------------------------
      // Filter by change type
      // if (filter.states.length < filter.statesCount) {
      //   resultState = false;
      //   filter.states.forEach(state => {
      //     switch (state) {
      //       case "not":
      //         resultState = resultState || item.hasWarning || !item.name['en'];
      //         break;
      //       case "edt":
      //         resultState = resultState || item.translationIsEdited || item.hasTranslationEdited
      //         break;
      //       case "edtg":
      //         resultState = resultState || item.isEdited || item.hasEdited
      //         break;
      //       case "new":
      //         resultState = resultState || item.isNew || item.hasNew
      //         break;
      //       case "rep":
      //         resultState = resultState || item.isRepositionedNew || item.isRepositionedOld || item.hasRepositionedNew || item.hasRepositionedOld
      //         break;
      //       case "del":
      //         resultState = resultState || item.isDeleted || item.hasDeleted;
      //         break;
      //     }
      //   });
      // };
      //-----------------------------------------------------------------------
      return resultText && resultState;
    });
    //-------------------------------------------------------------------------
    rootTagElements = rootTags?.map(item => {
      //const initialSessionData = dictionarySessionInitialState.current?.items.find(rootItem => rootItem.id == item.id);
      const initialData = dictionaryInitialState.current?.items?.find(rootItem => rootItem.id == item.id);
      if (initialData) {
        return (
          <BusinessTagsDictionaryNodeEditor
            key={item.id}
            data={item}
            initialData={initialData}
            rootNodes={dictionary.items}
            displayId={showSystemId}
            readonly={readonly || userCantPublish}
            manualOrder={item.itemsManualOrder}
            onUpdate={onRootTagUpdate}
            onDelete={onTagDelete}
            filter={filter}
          />)
      }
      else return undefined;
    });
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <WikiSpinner show={isLoading} />
      <ConsoleTabLayout
        toolbar={editorOptions}
        allowScroll={true}
      >
        <div className={styles.details}>

          {dictionary && dictionaryStatus &&
            <DraftHistory
              currentStatus={dictionaryStatus}
              historyItems={dictionary.historyRecords}
              numberOfApprovals={2}
            />}

          <div className={styles.settings}>
            <div className={styles.settingsLeft}>
              <div style={{ display: 'flex', gap: '.5em' }}>
                <input title='showSystemId' type='checkbox' defaultChecked={showSystemId} onChange={e => {
                  setShowSystemId(e.target.checked);
                }} />
                <label>
                  Display System Id as caption
                </label>
              </div>
              <select title='selectStateGroups' onChange={setGroupingType}>
                <option value="statesV1">Full Details</option>
                <option value="statesV2">Less Details</option>
              </select>
            </div>

            <div className={styles.legend}>
              {legendItems}
            </div>
          </div>

          <div className={styles.selectCaption}>
            <input title='searchMode' type='checkbox' defaultChecked={filter.searchById} onChange={e => {
              setFilter({ ...filter as IFilter, searchById: e.target.checked });
            }} />
            <label>
              Search by Id
            </label>
          </div>
          <div className={styles.search}>
            <TagSearchInput
              ui={tmpSearchInput}
              onChange={onSearchInputChange}
            />
          </div>
        </div>
        <div className={styles.tagsEditor}>
          {rootTagElements}
        </div>
      </ConsoleTabLayout>

      <PopupMessage
        id='DraftDeleteConfirmationMessage'
        type='Confirmation'
        header='Administrator Console. Business Tags Dictionary Editor'
        message='Please confirm you are going to delete current draft version'
        optionsMenuContent={tmpConfirmationDialogOptionsMenuContent}
        show={deleteConfirmationDialogOpen}
        onOptionSelect={onDeleteConfirmationDialogOptionSelect}
        onCancel={onDeleteCancel}
      />

    </React.Fragment>
  );
}