import styles from "./UserListCard.module.css";
import { TStaffUserListItem } from "./TStaffUserListItem";
import { pathAdminConsole, pathAdministratorConsole } from "../../../AdminConsole";
import { generatePath } from "react-router";
import { pathUserList } from "../../AdministratorConsole";
import useNavigateWithContext from "../../../../../hooks/useNavigateWithContext";

interface IProps {
  user: TStaffUserListItem;
}

export default function UserListCard(props: IProps) {
  let navigate = useNavigateWithContext();
  //--------------------------------------------------------------------------- Open User
  const openUser = () => {
    navigate(
      generatePath(`/${pathAdminConsole}/${pathAdministratorConsole}/${pathUserList}/:requestedUserId`, {
        requestedUserId: props.user.id,
      })
    );
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container} onClick={openUser} >
      <div className={styles.data}>
        <div className={styles.name}>
          <div className={styles.value}>
            <span>{props.user.displayName}</span>
          </div>
          <div className={styles.userid}>
            <span>{props.user.id}</span>
          </div>
          <span>{props.user.principalName}</span>
        </div>
      </div>
    </div>
  );
}
