import { InputValidator } from "../../../../../common/input-v2/input-validation/IInputValidator";
import { IValidationState } from "../../../../../common/input-v2/input-validation/IUiValidation";
import { ValidateUnique } from "../../../../../common/input-v2/input-validation/ValidateInput";
import { DictionaryDraftItem, IDictionaryDraftItem } from "../IDictionaryDraftItem";
import { IUiEditformDictionaryItem } from "./IUiEditformDictionaryItem";

export interface IDictionaryItemValidationState {
  id: IValidationState;
  name: IValidationState;
  description: IValidationState;
  isValid: boolean;
}

export class DictionaryItemValidator {
  id!: InputValidator;
  name!: InputValidator;
  description!: InputValidator;
  //---------------------------------------------------------------------------
  constructor(ui: IUiEditformDictionaryItem) {
    this.id = new InputValidator({
      elementId: ui.inputId.id,
      inputType: "InputText", // we could get from ui elementType
      validation: ui.inputId.validation
    });
    this.name = new InputValidator({
      elementId: ui.inputName.id,
      inputType: "InputText",
      validation: ui.inputName.validation
    });
    this.description = new InputValidator({
      elementId: ui.inputDescription.id,
      inputType: "InputText",
      validation: ui.inputDescription.validation
    });
  };
  //---------------------------------------------------------------------------
  getDictionaryItemValidationState(id: IValidationState, name: IValidationState, description: IValidationState): IDictionaryItemValidationState {
    return {
      id: id,
      name: name,
      description: description,
      isValid: id.isValid && name.isValid && description.isValid
    };
  };
  //---------------------------------------------------------------------------
  initialize(data?: DictionaryDraftItem): IDictionaryItemValidationState {
    const validationStateId: IValidationState = this.id.validate(data?.id, true);
    const validationStateName: IValidationState = this.name.validate(data?.name, true);
    const validationStateDescription: IValidationState = this.description.validate(data?.description, true);
    //-------------------------------------------------------------------------
    return this.getDictionaryItemValidationState(validationStateId, validationStateName, validationStateDescription);
  };
  //---------------------------------------------------------------------------
  validateId(guid: string, value: string, validationState: IDictionaryItemValidationState, dictionaryItems: IDictionaryDraftItem[]): IDictionaryItemValidationState {
    let idIsUnique = true;
    const validationStateId: IValidationState = this.id.validate(value, idIsUnique);
    if (validationStateId.isValid && this.id.validation?.unique && dictionaryItems) {
      const duplicate = dictionaryItems.find(item => item.id.toLowerCase() === value.toLowerCase() && item.guid != guid);
      ValidateUnique({
        elementId: this.id.elementId,
        validation: this.id.validation,
        data: value,
        isUnique: !duplicate,
        validationState: validationStateId
      });
    };
    //-------------------------------------------------------------------------
    return this.getDictionaryItemValidationState(validationStateId, validationState.name, validationState.description);
  };
  //---------------------------------------------------------------------------
  validateName(guid: string, value: string, validationState: IDictionaryItemValidationState, dictionaryItems: IDictionaryDraftItem[]): IDictionaryItemValidationState {
    let nameIsUnique = true;
    const validationStateName: IValidationState = this.id.validate(value, nameIsUnique);
    if (validationStateName.isValid && this.name.validation?.unique && dictionaryItems) {
      const duplicate = dictionaryItems.find(item => item.name?.toLowerCase() === value.toLowerCase() && item.guid != guid);
      ValidateUnique({
        elementId: this.id.elementId,
        validation: this.id.validation,
        data: value,
        isUnique: !duplicate,
        validationState: validationStateName
      });
    };
    //-------------------------------------------------------------------------
    return this.getDictionaryItemValidationState(validationState.id, validationStateName, validationState.description);
  };
  //---------------------------------------------------------------------------
  validateDescription(guid: string, value: string, validationState: IDictionaryItemValidationState, dictionaryItems: IDictionaryDraftItem[]): IDictionaryItemValidationState {
    let descriptionIsUnique = true;
    const validationStateDescription: IValidationState = this.description.validate(value, descriptionIsUnique);
    if (validationStateDescription.isValid && this.description.validation?.unique && dictionaryItems) {
      const duplicate = dictionaryItems.find(item => item.description?.toLowerCase() === value.toLowerCase() && item.guid != guid);
      ValidateUnique({
        elementId: this.id.elementId,
        validation: this.id.validation,
        data: value,
        isUnique: !duplicate,
        validationState: validationStateDescription
      });
    };
    //-------------------------------------------------------------------------
    return this.getDictionaryItemValidationState(validationState.id, validationState.name, validationStateDescription);
  };
  //---------------------------------------------------------------------------
  getInitialValidationState(): IDictionaryItemValidationState {
    return {
      id: {
        isValid: true,
        validationMessages: []
      },
      name: {
        isValid: true,
        validationMessages: []
      },
      description: {
        isValid: true,
        validationMessages: []
      },
      isValid: true
    };
  };
  //---------------------------------------------------------------------------
  validate(data: DictionaryDraftItem, dictionaryItems: IDictionaryDraftItem[], checkIdUnique: boolean): IDictionaryItemValidationState {
    // NOTE: checkIdUnique=true only in case of new item
    let idIsUnique = true;
    let nameIsUnique = true;
    let descriptionIsUnique = true;
    const checkUnique =
      this.id.validation?.unique != undefined ||
      this.name.validation?.unique != undefined ||
      this.description.validation?.unique != undefined;
    const validationStateId: IValidationState = this.id.validate(data.id, idIsUnique);
    const validationStateName: IValidationState = this.name.validate(data.name, nameIsUnique);
    const validationStateDescription: IValidationState = this.description.validate(data.description, descriptionIsUnique);
    //-------------------------------------------------------------------------
    if (validationStateId.isValid && validationStateName.isValid && validationStateDescription.isValid && checkUnique) {
      // Check uniqueness only if all the data is valid
      // Exclude current item from the list, so it would not be compared to itself
      dictionaryItems.filter(item => item.guid != data.guid).find(item => {
        if (checkIdUnique && this.id.validation?.unique && item.id.toLowerCase() === data.id.toLowerCase()) {
          idIsUnique = false;
        };
        if (this.name.validation?.unique && item.name?.toLowerCase() === data.name?.toLowerCase()) {
          nameIsUnique = false;
        };
        if (this.description.validation?.unique && item.description?.toLowerCase() === data.description?.toLowerCase()) {
          nameIsUnique = false;
        };
        return !idIsUnique || !nameIsUnique || !descriptionIsUnique;
      });
      ValidateUnique({
        elementId: this.id.elementId,
        validation: this.id.validation,
        data: data.id,
        isUnique: idIsUnique,
        validationState: validationStateId
      });
      console.log(validationStateId)
      ValidateUnique({
        elementId: this.name.elementId,
        validation: this.name.validation,
        data: data.name,
        isUnique: nameIsUnique,
        validationState: validationStateName
      });
      ValidateUnique({
        elementId: this.description.elementId,
        validation: this.description.validation,
        data: data.description,
        isUnique: descriptionIsUnique,
        validationState: validationStateDescription
      });
    };
    //-------------------------------------------------------------------------
    return {
      id: validationStateId,
      name: validationStateName,
      description: validationStateDescription,
      isValid: validationStateId.isValid && validationStateName.isValid && validationStateDescription.isValid
    };
  };
}