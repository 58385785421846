import CombineStyles from '../../../../../../../utils/combineStyles';
import Icon from '../../../../../../common/icon/Icon';
import styles from './DictionaryNodeToggler.module.css';

interface IProps {
  isCollapsed: boolean;
  onToggle?: () => void;
}

export default function DictionaryNodeToggler(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        props.onToggle ? '' : styles.disabled
      ])}
      onClick={props.onToggle}
    >
      <Icon imageName="rightArrow" visible={props.isCollapsed} />
      <Icon imageName="downArrow" visible={!props.isCollapsed} />
    </div>
  );
}