import { TUser } from "../context/app-auth-context/TUser";
import { getFromLS, setToLS } from "./storage";

export class UserSettings {
  //--------------------------------------------------------------------
  static userId: string = "anonymous";
  //--------------------------------------------------------------------
  static set user(value: TUser | null | undefined) {
    if (value)
      UserSettings.userId = value.userIdentity.id;
    else
      UserSettings.userId = "anonymous";
    //console.log("UserSettings.user.set:", UserSettings.userId);
  }
  //--------------------------------------------------------------------
  private static get keySearchParams(): string {
    return `usr.${UserSettings.userId}.searchParams`;
  }
  //--------------------------------------------------------------------
  private static get keyHomepageTab(): string {
    return `usr.${UserSettings.userId}.homepageTab`;
  }
  //--------------------------------------------------------------------
  static get searchParams(): URLSearchParams | undefined {
    let params = getFromLS(UserSettings.keySearchParams);
    if (!params)
      return undefined;
    let result = new URLSearchParams(params as string);
    return result;
  }
  //--------------------------------------------------------------------
  static getHomepageTab(): string {
    let tab = getFromLS(UserSettings.keyHomepageTab);
    if (!tab)
      return "Accommodation";
    return tab;
  }
  //--------------------------------------------------------------------
  static setHomepageTab(value: string) {
    setToLS(UserSettings.keyHomepageTab, value);
  }
}