import CombineStyles from '../../../../utils/combineStyles';
import styles from './PageButton.module.css';
//------------------------------------------------------------------------------------
interface IProps {
  index: number;
  selected: boolean;
  onSelect: (index: number) => void;
}
//------------------------------------------------------------------------------------
export function PageButton(props: IProps) {
  //----------------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    props.selected ? styles.selected : ""
  ]);
  //console.log("PageButton.render:", props.index, props.businessIds);
  return (
    <div
      className={containerStyles}
      onClick={() => props.onSelect(props.index)}
    >
      {props.index + 1}
    </div>
  );
}