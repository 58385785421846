import { useParams } from "react-router-dom";
import DictionaryEditorWrapper from "../../../common/dictionary-editor-wrapper/DictionaryEditorWrapper";
import { useContext, useEffect, useState } from "react";
import useBeforeUnload from "../../../../../hooks/useBeforeUnload";
import { IDictionaryDraftFilter } from "../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter";
import { useMsal } from "@azure/msal-react";
import { AppAuthContextStore } from "../../../../../context/app-auth-context/AppAuthContext";
import { DraftItemStateEnum } from "../../../common/dictionary-draft-models/DraftItemStateEnum";
import React from "react";
import WidgetDirectoryNode from "../widget-directory-node/WidgetDirectoryNode";
import saveWidgetDirectoryDraft from "./saveWidgetDirectoryDraft";
import deleteWidgetDirectoryDraft from "./deleteWidgetDirectoryDraft";
import publishWidgetDirectoryDraft from "./publishWidgetDirectoryDraft";
import { IWidgetTranslation } from "./IWidgetTranslation";
import { useAbortController } from "../../../../../hooks/useAbortController";
import { EHttpStatusCode } from "../../../../../utils/HttpStatusCodes";
import { DirectoryTranslatorState, IDirectoryTranslatorState, IWidgetTranslationDraft } from "./DirectoryTranslatorState";
import { getWidgetDirectoryDraft } from "./getWidgetDirectoryDraft";



export default function WidgetDirectoryTranslator() {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { directoryId, localeId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState<DirectoryTranslatorState>();
  useBeforeUnload(state?.isUpdatedInSession);
  const abortController = useAbortController("WidgetDirectoryTranslator");
  //--------------------------------------------------------------------------- Abort controller
  useEffect(() => {
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (directoryId && localeId)
      getDraft(directoryId, localeId, false);
  }, [directoryId, localeId]);
  //---------------------------------------------------------------------------
  const getDraft = (directoryId: string, localeId: string, lockIsNeeded: boolean) => {
    setIsLoading(true);
    let controller = abortController.newController("getWidgetDirectoryDraft");
    getWidgetDirectoryDraft({
      msalInstanse: instance,
      authConfig: appAuthContext.config,
      directoryId,
      localeId,
      lockIsNeeded,
      abortSignal: controller.signal
    }).then(directoryDraft => {
      let translatorState: DirectoryTranslatorState;
      let locker = directoryDraft.locker;
      if (locker)
        locker.errorPrefix = `${directoryId} directory`;

      if (state) {
        //-------------------------------------------------------------------
        // State is already set
        translatorState = new DirectoryTranslatorState(undefined, {
          state: state,
          spread: {
            //dictionaryDraft: directoryDraft,
            draft: directoryDraft,
            editModeIsEnabled: !!(locker && locker.statusCode == EHttpStatusCode.OK)
          }
        });
      } else {
        //-------------------------------------------------------------------
        // State is not set yet
        translatorState = new DirectoryTranslatorState({
          draft: directoryDraft,
          editMode: !!(locker && locker.statusCode == EHttpStatusCode.OK)
        });
      }
      !controller.aborted && setState(translatorState);
    }).catch(error => {
      console.error(error);
      if (!controller.aborted) {
        const translatorState = new DirectoryTranslatorState({
          draft: undefined,
          editMode: false
        });
        setState(translatorState);
      }
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onSaveDraft = () => {
    if (state?.draft && state.draft.locale) {
      setIsLoading(true);
      let controller = abortController.newController("saveWidgetDirectoryDraft");
      saveWidgetDirectoryDraft(
        instance,
        state?.draft,
        appAuthContext.config
      ).then(directoryDraft => {
        const translatorState = new DirectoryTranslatorState({
          draft: directoryDraft,
          editMode: true
        });
        !controller.aborted && setState(translatorState);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    } else console.error("Widget directory changes are being saved, but editor state is not set");
  };
  //---------------------------------------------------------------------------
  const onDeleteDraft = (releaseLockCallback?: () => void) => {
    if (state?.draft && state.draft.dictionaryId && state.draft.locale) {
      setIsLoading(true);
      //-----------------------------------------------------------------------
      // Delete Dictionary translation draft
      let controller = abortController.newController("deleteWidgetDirectoryDraft");
      deleteWidgetDirectoryDraft(
        instance,
        state.draft.dictionaryId,
        state.draft.locale,
        appAuthContext.config
      ).then(directoryDraft => {
        // Probably we should release lock on backend: need to update Locker component
        releaseLockCallback && releaseLockCallback();
        const translatorState = new DirectoryTranslatorState({
          draft: directoryDraft,
          editMode: false
        });
        !controller.aborted && setState(translatorState);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    } else console.error("Cannot delete directory draft, state is not set");
  };
  //---------------------------------------------------------------------------
  const onPublishDraft = () => {
    if (state?.draft && state.draft.locale) {
      setIsLoading(true);
      let controller = abortController.newController("publishWidgetDirectoryDraft");
      publishWidgetDirectoryDraft(
        instance,
        state.draft.directoryId,
        state.draft.locale,
        appAuthContext.config
      ).then(data => {
        const translatorState = new DirectoryTranslatorState({
          draft: data,
          editMode: false
        });
        !controller.aborted && setState(translatorState);
      }).catch(error =>
        console.error(error)
      ).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    } else
      console.error("Cannot publish widget directory draft, state is not set");
  };
  //---------------------------------------------------------------------------
  const onFilterUpdate = (updatedFilter: IDictionaryDraftFilter) => {
    setState(new DirectoryTranslatorState(undefined, {
      state: state as DirectoryTranslatorState,
      spread: { filter: updatedFilter }
    }));
  };
  //---------------------------------------------------------------------------
  const onEnableEditMode = () => {
    if (directoryId && localeId) {
      getDraft(directoryId, localeId, true);
    } else console.error("Unable to switch edit mode: critical data is not provided");
  };
  //---------------------------------------------------------------------------
  const onDisbleEditMode = () => {
    setState(new DirectoryTranslatorState({
      draft: state?.draftSessionInitial,
      editMode: false
    }));
  };
  //---------------------------------------------------------------------------
  const onSourceLocaleUpdate = (localeId: string) => {
    setState(new DirectoryTranslatorState(undefined, {
      state: state as IDirectoryTranslatorState,
      spread: { sourceLocale: localeId }
    }));
  };
  //---------------------------------------------------------------------------
  const onWidgetUpdate = (updatedWidget: IWidgetTranslationDraft) => {
    console.log(updatedWidget)
    const updatedItems: IWidgetTranslationDraft[] | undefined = state?.draft.items.map(x => x.id == updatedWidget.id ? updatedWidget : x);
    setState(new DirectoryTranslatorState(undefined, {
      state: state as IDirectoryTranslatorState,
      spread: {
        draft: {
          ...state?.draft,
          items: updatedItems
        }
      }
    }));
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string, releaseLockCallback?: () => void) => {
    switch (selectedOptionId) {
      case "optionEnableEditMode":
        setState(new DirectoryTranslatorState(undefined, {
          state: state as DirectoryTranslatorState,
          spread: { editModeIsEnabled: true }
        }));
        break;
      case "optionDisableEditMode":
        onDisbleEditMode();
        break;
      case "optionSave":
        onSaveDraft();
        break;
      case "optionDraftDelete":
        onDeleteDraft(releaseLockCallback);
        break;
      case "optionPublish":
        onPublishDraft();
        break;
      default:
        break;
    };
  };
  //---------------------------------------------------------------------------
  const filteredItems = state?.draft.items?.filter(item => {
    let resultText = true;
    let resultState = true;
    //-----------------------------------------------------------------------
    // Filter by text
    if (state?.filter.text) {
      const searchString = state.filter.text.toLocaleLowerCase();
      const name = item.id ? item.id : "";
      resultText = item.id.toLocaleLowerCase().includes(searchString) || (name.toLocaleLowerCase().includes(searchString));
    };
    //-----------------------------------------------------------------------
    // Filter by state
    if (state && state.filter.draftState !== DraftItemStateEnum.All) {
      resultState = (item.draftState & state.filter.draftState) != DraftItemStateEnum.None;
      //console.log(item.id, state.filter.draftState, item.draftState)
    };
    //-----------------------------------------------------------------------
    return resultText && resultState;
  });
  const directoryNodes = filteredItems?.map(item => (
    <WidgetDirectoryNode
      key={item.id}
      stateGroups={state?.filter.stateGroups}
      draftLocale={state?.draft.locale}
      sourceLocale={state?.sourceLocale}
      data={{
        ...item,
        publishedVersion: state?.draft.publishedVersion?.items.find((x: IWidgetTranslation) => x.id == item.id)
      }}
      editModeIsEnabled={state?.editModeIsEnabled}
      onUpdate={onWidgetUpdate}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <DictionaryEditorWrapper
      setup={{
        mode: 'Translator',
        role: 'SystemTranslator',
        entityType: "UI Directory",
      }}
      state={state}
      //tableHeader={tableHeader}
      isLoading={isLoading}
      onFilterUpdate={onFilterUpdate}
      onEnterEditMode={onEnableEditMode}
      onOptionSelect={onOptionSelect}
      onSourceLocaleUpdate={onSourceLocaleUpdate}
    >
      {directoryNodes}
    </DictionaryEditorWrapper>
  );
}