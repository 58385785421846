import { useContext, useEffect } from 'react';
import { LocationData } from '../../../common/map/MapWrapper';
import { TUiMenuOption } from '../../../common/menu/menu-content/menu-option/TUiMenuOption';
import BusinessSearchSection from '../business-search-section/BusinessSearchSection'
import styles from './SectionMap.module.css';
import { TGeoPoint, TLocationData } from '../../../common/map/geo-json/GeoJson';
import GoogleMap from '../../../common/google-maps/google-map/GoogleMap';
import { IBusinessMarker } from '../../../common/google-maps/business-markers/BusinessMarkers';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { BusinessSearchResult } from '../../business-search-list/BusinessSearchResult';
import { IMapArea } from '../../../common/google-maps/map-itself/MapItself';

const optionLocation: TUiMenuOption = {
  id: "SectionLocation",
  directoryId: "",
  elementType: "",
  index: 1,
  priorityLevel: 0,
  iconFile: "mapLocation",
  caption: { en: "Location" },
  hint: undefined,
  visible: true,
  disabled: false,
  canHideCaption: false,
  isDefault: true,
  action: undefined
}

interface IProps {
  locationNameToDisplay?: string;// | null;
  locationAreaToDisplay?: google.maps.LatLngBoundsLiteral;
  businessSearchResult?: BusinessSearchResult;
  businessMarkers?: IBusinessMarker[];
  highlightedBusinessId?: string;
  userPosition?: TGeoPoint;
  switchedOn: boolean;
  onStateUpdate: (switchedOn: boolean) => void;
  onLocationUpdate?: (locationData: LocationData) => void;
  onLocationUpdateV2?: (locationData: TLocationData) => void;
}

export default function SectionMap(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  useEffect(() => {
    //console.warn("SectionMap.useEffect[props]:", props);
  }, [props]);
  //---------------------------------------------------------------------------
  //let bounds = TGeoPoint.getBounds(props.businessesToDisplay.map(item => item.location));
  //---------------------------------------------------------------------------
  let locationToSearch: TLocationData = new TLocationData({
    address: props.locationNameToDisplay,
  });
  //---------------------------------------------------------------------------
  const onMapAreaChange = (updatedMapArea: IMapArea) => {
    console.log("SectionMap.onMapAreaChange:", updatedMapArea);
    if (props.onLocationUpdateV2) {
      console.log("SectionMap.onMapAreaChange: call onLocationUpdateV2");
      props.onLocationUpdateV2(TLocationData.fromMapArea(updatedMapArea));
    }
  }
  //---------------------------------------------------------------------------
  let mapAreaChange = props.switchedOn ? onMapAreaChange : undefined;
  let defaultMapArea: IMapArea | undefined = undefined;
  let zoom = props.userPosition?.isZero ? 2 : 11; 
  if (!props.switchedOn) {
    if (props.businessSearchResult?.businessesFrame) {
      defaultMapArea = { bounds: props.businessSearchResult?.businessesFrame }
    }
    else if (props.userPosition) {
      defaultMapArea = { center: props.userPosition.pointPosition, zoom: zoom };
    }
  }
  else if (props.locationAreaToDisplay) {
    defaultMapArea = { bounds: props.locationAreaToDisplay }
  }
  else if (props.userPosition) {
    defaultMapArea = { center: props.userPosition.pointPosition, zoom: zoom };
  }

  // let defaultMapArea = props.switchedOn
  //   ? props.locationAreaToDisplay ? { bounds: props.locationAreaToDisplay } : undefined
  //   : { bounds: props.businessSearchResult?.businessesFrame }
  //---------------------------------------------------------------------------
  //console.log("SectionMap.render:", defaultMapArea?.center);
  //---------------------------------------------------------------------------
  return (
    <BusinessSearchSection
      ui={{ header: optionLocation }}
      switchedOn={props.switchedOn}
      editDisabled={false}
      onStateUpdate={props.onStateUpdate}
    >
      <div className={styles.map}>
        <GoogleMap
          //businesses={markers}
          businesses={props.businessMarkers}
          currentLocation={props.userPosition?.pointPosition}
          isSearchEnabled={true}
          defaultMapArea={defaultMapArea}
          onMapAreaChange={mapAreaChange}
        //onBusinessLocationUpdate={onBusinessLocationUpdate}
        //onMapClick={onBusinessLocationSet}
        //onZoomChange={onZoomUpdate}
        />

        {/* <MapWrapper
          locationData={TLocationData.fromAddressAndBounds(props.locationNameToDisplay, props.locationAreaToDisplay)}
          showLocationSearch={true}
          markers={markers}
          locationUpdateType={ELocationUpdateType.ByLocationSearch}
          onLocationUpdateV2={props.onLocationUpdateV2}
          selectedBusinessId={props.selectedBusinessId}
          locationType={LocationType.Polygon}
        /> */}
      </div>
    </BusinessSearchSection>
  );
}