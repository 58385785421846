import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router";
import DictionaryList from "../../common/dictionary-list/DictionaryList";
import { optionIdDictionaries, pathDictionaries, pathNotImplemented } from "../SystemTranslatorConsole";
import NotImplemented from "../../../common/not-implemented/NotImplemented";
import useNavigateWithContext from "../../../../hooks/useNavigateWithContext";
//import DictionaryTranslatorV1 from "./dictionary-translator/DictionaryTranslatorV1";
import { pathAdminConsole, pathSystemTranslator } from "../../AdminConsole";
import DictionaryTranslatorV2 from "./dictionary-translator/DictionaryTranslatorV2";
import HierarchicalTranslator from "./business-tags-translator/HierarchicalTranslator";
import { EDictionaryType } from "../../common/dictionary-draft-models/EDictionaryType";
import { TUiBreadcrumb } from "../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import { IUiOption } from "../../../common/options/IUiOption";

interface IProps {
  optionsForBreadcrumb?: IUiOption[];
}

export default function Dictionaries(props: IProps) {
  const { appUiContextDispatch } = useContext(AppUiContextStore);
  const navigate = useNavigateWithContext();
  //---------------------------------------------------------------------------
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdDictionaries);
    if (!option) return;
    const updatedBreadcrumb: TUiBreadcrumb = {
      id: option.id,
      caption: option.caption,
      path: `/${pathAdminConsole}/${pathSystemTranslator}/${pathDictionaries}`
    };
    appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: updatedBreadcrumb });
  }, []);
  //---------------------------------------------------------------------------
  const onSelectDictionary = (dictionaryId: EDictionaryType, locale?: string) => {
    //console.log("onSelectDictionary:", dictionaryId, locale);
    switch (dictionaryId) {
      case "AdRanks":
      case "SupportedLocales":
      case "ColourThemes":
      case "ContactTypes":
      case "AdPageStatuses":
      case "SupportedRegions":
      case "RequestRejectionReasons":
      case "Locales":
        navigate(`/${pathAdminConsole}/${pathSystemTranslator}/${pathDictionaries}/${dictionaryId}/${locale}`);
        break;
      case "BusinessTags":
        navigate(`/${pathAdminConsole}/${pathSystemTranslator}/${pathDictionaries}/businesstags/${locale}`);
        break;
      default:
        navigate(`${pathNotImplemented}`);
        break;
    }
  };
  //---------------------------------------------------------------------------
  return (
    <Routes>

      <Route
        index
        element={
          <DictionaryList
            onSelect={onSelectDictionary}
          />}
      />

      <Route
        path={`businesstags/:localeId`}
        element={
          <HierarchicalTranslator
            dictionaryType="BusinessTags"
          />}
      />

      <Route
        path={`:dictionaryId/:localeId`}
        element={
          <DictionaryTranslatorV2 />}
      />

      <Route
        path={pathNotImplemented}
        element={
          <NotImplemented />}
      />

    </Routes>
  );
}