import { ChangeEvent, useContext } from 'react';
import GetCaption from '../../functions/GetCaption';
import GetHint from '../../functions/GetHint';
import InputWrapper from '../input-wrapper/InputWrapper';
import styles from '../Input.module.css';
import { IUiInput } from '../IUiInput';
import CombineStyles from '../../../../utils/combineStyles';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';

export interface IUiInputNumber extends IUiInput {
  defaultValue?: number;
  minValue?: number;
  maxValue?: number;
  stepValue?: number;
}

interface IProps {
  ui: IUiInputNumber;
  data?: number;
  onUpdate: (updatedValue: number, isValid: boolean) => void;
}

export default function InputNumber(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const onUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    props.onUpdate(Number(e.target.value), true);
  };
  //---------------------------------------------------------------------------
  const title = props.ui.hint 
    ? GetHint(appUiContext, props.ui.id, props.ui.hint) 
    : GetCaption(appUiContext, props.ui.id, props.ui.caption);
  //---------------------------------------------------------------------------
  return (
    <InputWrapper
      caption={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      hint={GetHint(appUiContext, props.ui.id, props.ui.hint)}
    >
      <input
        title={title}
        type='number'
        className={CombineStyles([
          styles.input,
          props.ui.disabled ? styles.disabled : "",
          props.ui.readonly ? styles.readonly : ""
        ])}
        value={props.data ? props.data : props.ui.defaultValue}
        min={props.ui.minValue}
        max={props.ui.maxValue}
        step={props.ui.stepValue}
        disabled={props.ui.disabled || props.ui.readonly}
        onChange={onUpdate}
      />
    </InputWrapper>
  );
}