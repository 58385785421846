import { useContext } from 'react';
import { EditFormSection } from '../../../common/console-layout/edit-form-sections/EditFormSection';
import GetHint from '../../../common/functions/GetHint';
import { IUiSectionAdCampaigns } from './IUiSectionAdCampaigns';
import styles from './SectionAdCampaigns.module.css';
import AdCampaignListItem from './adcampaign-list-item/AdCampaignListItem';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { BusinessContext } from '../../../../context/business-context/BusinessContextProvider';
import { ClassCustomerBusiness } from '../ICustomerBusiness';
import { IAdCampaign } from '../../../../context/business-context/IAdCampaign';

interface IProps {
  id: string;
  ui: IUiSectionAdCampaigns;
}

export default function SectionAdCampaigns(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { businessContext } = useContext(BusinessContext);
  const business = businessContext.businessState?.business as ClassCustomerBusiness;
  //---------------------------------------------------------------------------
  const adCampaigns = business?.adCampaigns?.map((adCampaign: IAdCampaign) => (
    <AdCampaignListItem
      key={adCampaign.id}
      ui={props.ui.listItemAdCampaign}
      data={adCampaign}
      isCollapsed={business.adCampaigns.length > 1}
    />
  ))
  //---------------------------------------------------------------------------
  return (
    <EditFormSection
      id={props.id}
      caption={GetHint(appUiContext, props.ui.id, props.ui.hint)}
    >
      <div className={styles.container}>
        {adCampaigns}
      </div>
    </EditFormSection>
  );
}