import { useContext, useEffect, useState } from 'react';
import { TStaffRoles } from '../../../../../context/app-auth-context/TUser';
import { TUiStaffRole } from '../../TUiAdministratorConsole';
import { TStaffUser } from '../TStaffUser';
import UserRolesEditor, { GetValidPropertyName } from '../user-roles-editor/UserRolesEditor';
import UserRole, { IStaffRole } from './user-role/UserRole';
import styles from './UserRolesList.module.css';
import InputWrapper from '../../../../common/input-v2/input-wrapper/InputWrapper';
import GetCaption from '../../../../common/functions/GetCaption';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import { IUiStaffRole } from '../../IUiAdministratorConsole';
import { hardcodedInputListUserRoles } from './IUiStaffRolesList';

interface IProps {
  staffRoles: IUiStaffRole[];
  data: TStaffUser;
  onUpdate?: (updatedStaffUser: TStaffUser) => void;
}

export default function UserRolesList(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [staffUser, setStaffUser] = useState<TStaffUser>();
  const [popupOpen, setPopupOpen] = useState(false);
  type ObjectKey = keyof TStaffRoles;
  const ui = hardcodedInputListUserRoles;
  //---------------------------------------------------------------------------
  useEffect(() => {
    setStaffUser(props.data);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onUpdate = (staffRole: TUiStaffRole, updatedData: IStaffRole) => {
    if (props.onUpdate) {
      //console.log(staffRole)
      //console.log(updatedData)
      const propertyName = GetValidPropertyName(staffRole.propertyName);
      var updatedStaffRoles: TStaffRoles = { ...props.data.staffRoles as TStaffRoles };
      //console.log(updatedStaffRoles)
      updatedStaffRoles[propertyName as ObjectKey] = updatedData;
      //console.log(updatedStaffRoles)
      //-------------------------------------------------------------------------
      const updatedStaffUser: TStaffUser = {
        ...props.data,
        staffRoles: updatedStaffRoles
      };
      setStaffUser(updatedStaffUser);
      //-------------------------------------------------------------------------
      props.onUpdate(updatedStaffUser);
    };
  };
  //---------------------------------------------------------------------------
  const userRoleElements: JSX.Element[] = [];
  const userStaffRoles: TStaffRoles | undefined = staffUser?.staffRoles;
  if (userStaffRoles && props.staffRoles) {
    props.staffRoles.forEach(staffRole => {
      var propertyName = staffRole.propertyName;
      propertyName = propertyName.substring(0, 1).toLowerCase() + propertyName.substring(1);
      const userRoleData = userStaffRoles[propertyName as ObjectKey];
      //console.log(userRoleData)
      if (userRoleData) {
        userRoleElements.push(
          <UserRole
            key={staffRole.groupName}
            staffRole={staffRole}
            data={userRoleData}
            onUpdate={props.onUpdate ? (updatedData: IStaffRole) => onUpdate(staffRole, updatedData) : undefined}
          />);
      };
    });
  };
  //---------------------------------------------------------------------------{/* <UserRoleAction roles={user.userIdentity.userGroups} availableRoles={user.staffRoles} /> */}
  return (
    <InputWrapper
      caption={GetCaption(appUiContext, ui.id, ui.caption)}
    >
      <div className={styles.data}>
        {userRoleElements}
        {props.onUpdate &&
          <div
            className={styles.editOption}
            onClick={() => setPopupOpen(true)}
          >
            Edit User Roles...
          </div>}
      </div>

      {props.onUpdate && popupOpen &&
        <UserRolesEditor
          staffRoles={props.staffRoles}
          data={staffUser}
          onUpdate={props.onUpdate}
          onClose={() => setPopupOpen(false)}
        />}

    </InputWrapper>
  );
}