import { useEffect, useState } from 'react';
import DictionaryNodeEditForm from '../../common/dictionary-node-edit-form/DictionaryNodeEditForm';
import { AdRanksDictionaryDraftItem } from '../AdRanksDictionaryDraft';
import InputNumber from '../../../../../common/input-v2/input-number/InputNumber';
import useBeforeUnload from '../../../../../../hooks/useBeforeUnload';
import { DictionaryEditorDraftItem } from '../../common/models/DictionaryEditorDraftItem';
import { AdRanksEditFormState } from './AdRanksEditFormState';
import { tmpUiEditFormAdRank } from './IUiDictionaryDraftEditFormAdRank';


interface IProps {
  //ui: IUiDictionaryDraftEditFormAdRank;
  //stateGroups?: IDictionaryDraftItemStateGroup[];
  data?: AdRanksDictionaryDraftItem;
  onUpdate: (updatedDraftItem: AdRanksDictionaryDraftItem) => void;
  onClose: () => void;
}

export default function AdRankEditForm(props: IProps) {
  const [state, setState] = useState<AdRanksEditFormState>();
  useBeforeUnload(state?.isUpdated);
  //const dataValidity = useRef<IValidityState>({ ppv: false, ppe: false });
  const localeId = "en";
  // We have two types of initial data here:
  //  - draft initial data - to determine changes made to published version of the dictionary
  //  - session initial data - to determine changes made as compared to the moment of the form opening

  // !!! Add options: delete, restore
  // !!! Add detailed draftstate

  //---------------------------------------------------------------------------
  useEffect(() => {
    setState(new AdRanksEditFormState({
      draftItem: props.data

      // Published state !!!

    }));
  }, []);
  //--------------------------------------------------------------------------- Common part of dictionary node
  const onBaseDataUpdate = (updatedData: DictionaryEditorDraftItem) => {
    //console.log(updatedData, state);
    if (state?.object) {
      const updatedObject = new AdRanksDictionaryDraftItem(
        state.object, updatedData
      );
      setState(new AdRanksEditFormState(undefined, {
        state: state,
        spread: { object: updatedObject }
      }));
    } else console.error("onBaseDataUpdate: Data is updated but state.object is not set");
  };
  //--------------------------------------------------------------------------- Specific parts of dictionary node
  const onPpvUpdate = (updatedValue: number, isValid: boolean) => {
    if (state?.object) {
      const updatedObject = new AdRanksDictionaryDraftItem(
        state.object, { pointsPerView: updatedValue }
      );
      setState(new AdRanksEditFormState(undefined, {
        state: state,
        spread: { object: updatedObject }
      }));
    } else console.error("Data is updated (ppv) but state is not set");
  };
  //---------------------------------------------------------------------------
  const onPpeUpdate = (updatedValue: number, isValid: boolean) => {
    if (state?.object) {
      const updatedObject = new AdRanksDictionaryDraftItem(
        state.object, { pointsPerEngagement: updatedValue }
      );
      setState(new AdRanksEditFormState(undefined, {
        state: state,
        spread: { object: updatedObject }
      }));
    } else console.error("Data is updated (ppe) but state is not set");
  };
  //--------------------------------------------------------------------------- Updates are confirmed
  const onConfirm = () => {
    // Take draft state, update current dictionary node and pass it to caller
    if (state?.object && state.isValid && state.isUpdated) {
      const updatedDraftItem = state.object;
      updatedDraftItem.refresh(localeId);
      //-----------------------------------------------------------------------
      props.onUpdate(updatedDraftItem);
    } else console.error("Cannot confirm changes: states are not set or there are no updates or data is not valid");
  };
  //---------------------------------------------------------------------------
  return (
    <DictionaryNodeEditForm
      data={state}
      onUpdate={onBaseDataUpdate}
      onConfirm={onConfirm}
      onClose={props.onClose}
    >
      <InputNumber
        ui={{
          ...tmpUiEditFormAdRank.inputPpv,
          disabled: props.data?.publishedVersion ? true : false
        }}
        data={state?.object?.pointsPerView}
        onUpdate={onPpvUpdate}
      />
      <InputNumber
        ui={{
          ...tmpUiEditFormAdRank.inputPpe,
          disabled: props.data?.publishedVersion ? true : false
        }}
        data={state?.object?.pointsPerEngagement}
        onUpdate={onPpeUpdate}
      />
    </DictionaryNodeEditForm>
  );
}