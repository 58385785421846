import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styles from './ConsoleContent.module.css';
import EditFormPrompt from '../../edit-form/edit-form-prompt/EditFormPrompt';
import { useVisible } from '../../../../hooks/useVisible';
import CombineStyles from '../../../../utils/combineStyles';
import Icon from '../../icon/Icon';
import { ScreenType, useAppScreenContext } from '../../../../context/app-screen-context/AppScreenProvider';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';

interface IProps {
  prompt?: any;
  children: ReactNode;
  sidebarChildren?: ReactNode;
  formOptions?: ReactNode;
  containerWidthPercent?: number;
  isLoading?: boolean;
}

export default function ConsoleContent(props: IProps) {
  const screenType = useAppScreenContext();
  const [splitterState, setSplitterState] = useState<number>(props.containerWidthPercent ? props.containerWidthPercent : 50);
  const [cursorMove, setCursorMove] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerRect = useRef<DOMRect>();
  const promptRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const promptIsVisible = useVisible(promptRef);
  //---------------------------------------------------------------------------
  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
    return (() => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    });
  }, []);
  //---------------------------------------------------------------------------
  const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    containerRect.current = containerRef.current?.getBoundingClientRect();
    setCursorMove(true);
  };
  //---------------------------------------------------------------------------
  const onMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    if (containerRect.current != undefined) {
      const percent = (event.clientX - containerRect.current.left) / containerRect.current.width * 100;
      setSplitterState(percent);
    }
  };
  //---------------------------------------------------------------------------
  const onMouseUp = (event: MouseEvent) => {
    containerRect.current = undefined;
    setCursorMove(false);
  };
  //---------------------------------------------------------------------------
  const onScrollToTop = () => {
    contentRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };
  //---------------------------------------------------------------------------
  return (
    <div
      ref={containerRef}
      className={styles.wrapper}
      style={{ cursor: cursorMove ? 'w-resize' : 'default' }}
    >

      <div
        className={styles.container}
        style={{ flex: `1 1 ${splitterState}%` }}
      >
        <WikiSpinner show={!!props.isLoading} />

        <div
          ref={contentRef}
          className={styles.content}>

          <div
            className={styles.scrollable}
          >
            {props.prompt &&
              <EditFormPrompt
                divRef={promptRef}
                prompt={props.prompt}
              />}
            {props.children}
          </div>

          <div
            className={CombineStyles([
              styles.floatingOption,
              promptIsVisible ? "" : styles.visible])}
            onClick={onScrollToTop}
          >
            <Icon imageName="doubleArrowUp" />
          </div>

        </div>

        {props.formOptions}

      </div>

      {(screenType == ScreenType.Desktop && (props.sidebarChildren || (props.containerWidthPercent && props.containerWidthPercent > 0))) &&
        <React.Fragment>
          <div
            className={styles.splitter}
            onMouseDown={onMouseDown}
          />
          <div
            className={styles.sidebar}
            style={{
              flex: `1 1 ${100 - splitterState}%`,
              backgroundColor: props.containerWidthPercent ? "var(--clr_main_base)" : ""
            }}
          >
            {props.sidebarChildren}
          </div>
        </React.Fragment>}

    </div>
  );
}