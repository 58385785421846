import React, { useContext, useEffect, useState } from 'react';
import GetName from '../../../functions/GetName';
import styles from './BusinessTagNode.module.css';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import IconCheckBox from '../../../icons/IconCheckBox';
import CombineStyles from '../../../../../utils/combineStyles';
//import { IUiBusinessTagNode } from './IUiBusinessTagNode';
import { UiBusinessTag } from './IUiBusinessTag';
import ExpandButton from '../expand-button/ExpandButton';
import BusinessTagsCollection from '../business-tags-collection/BusinessTagsCollection';
import { IUiBusinessTagsCollection } from '../business-tags-collection/IUiBusinessTagsCollection';
import TagCheckbox, { EBusinessTagExtendedState } from '../tag-checkbox/TagCheckbox';

interface IProps {
  ui: IUiBusinessTagsCollection;
  rootTag: UiBusinessTag;
  tags: UiBusinessTag[];
  selectedPaths: string[];
  filterString?: string;
  isExpanded?: boolean;
  onUpdate?: (selectedPaths?: string[]) => void;
}

export default function BusinessTagNode(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [expanded, setExpanded] = useState(false);
  const isSelectable = props.rootTag.isSelectable;
  const isSelected = props.rootTag.isSelected(props.selectedPaths);
  let isFitForFilter = true;
  let checkBoxState: EBusinessTagExtendedState = isSelected ? "Checked" : "Unchecked";
  const tags = props.tags.filter(tag => tag.path?.startsWith(props.rootTag.fullPath)); // just so child nodes would not filter the whole list
  let subTags = tags.filter(tag => tag.path === props.rootTag.fullPath);
  const selectedSubTags = props.selectedPaths.filter(path => path.startsWith(props.rootTag.fullPath) && path !== props.rootTag.fullPath);
  const allChilrenSelected = selectedSubTags.length === subTags.length;
  //---------------------------------------------------------------------------
  if (isSelectable && subTags.length > 0) {
    // Check how many subtags are selected
    if (selectedSubTags.length > 0) {
      if (allChilrenSelected) {
        checkBoxState = "DoubleChecked";
      } else if (selectedSubTags.length < subTags.length) {
        checkBoxState = "Gray";
      };
    };
  };
  //---------------------------------------------------------------------------
  if (props.filterString) {
    isFitForFilter = props.rootTag.isFitForFilter(props.filterString);
    subTags = tags.filter(tag => tag.isFitForFilter(props.filterString));
  };
  let isExpandable = subTags.length > 0;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (props.filterString)
      setExpanded(true);
    else if (props.isExpanded !== undefined)
      setExpanded(props.isExpanded);
    else setExpanded(false);
  }, [props.isExpanded, props.filterString]);
  //---------------------------------------------------------------------------
  const toggleState = () => {
    if (!props.onUpdate) return;
    let updatedPaths = props.selectedPaths ? props.selectedPaths : [];
    if (isSelected) {
      if (subTags.length > 0) {
        console.log('parent', props.rootTag.fullPath, checkBoxState)
        // Make it "double-checked", whatever that means
        if (allChilrenSelected) {
          // Unselect all the children
          updatedPaths = props.selectedPaths.filter(path => !path.startsWith(props.rootTag.fullPath));
        } else {
          // Select all the children
          updatedPaths = updatedPaths.concat(subTags.map(tag => tag.fullPath));
        };
        // if (checkBoxState === "Gray")
        // else
        //   updatedPaths = updatedPaths.filter(path => !path.startsWith(props.rootTag.fullPath));
      } else {
        console.log('kidless tag')
        // Uncheck the tag and all descendants
        updatedPaths = props.selectedPaths.filter(path => !path.startsWith(props.rootTag.fullPath));
      };
    } else {
      if (isExpandable) setExpanded(true);
      updatedPaths = props.selectedPaths.concat(props.rootTag.fullPath);
    };
    props.onUpdate(updatedPaths);
  };
  //---------------------------------------------------------------------------
  const toggleSubTags = () => {
    setExpanded(!expanded);
  };
  //---------------------------------------------------------------------------
  const onSubTagsUpdate = (selectedPaths?: string[]) => {
    if (!props.onUpdate) return;
    // If it's selectable and if at least one child is selected, select the parent
    // Otherwise, unselect the current tag
    if (isSelectable) {
      let updatedPaths = selectedPaths ? selectedPaths : [];
      //const selectedSubTags = selectedPaths?.filter(path => path.startsWith(props.rootTag.fullPath));
      if (selectedSubTags) {
        // If there are selected subtags, check if current tag is selected
        if (!isSelected) {
          console.log(1, props.rootTag.fullPath)
          updatedPaths.push(props.rootTag.fullPath);
        } else {
          console.log(2, updatedPaths)

        };
      } else {
        console.log(3)
        // If there are no selected subtags, unselect current tag
        updatedPaths = updatedPaths.filter(path => path !== props.rootTag.fullPath);
      };
      props.onUpdate(updatedPaths);
    } else {
      // If it's not selectable, just pass the updates
      props.onUpdate(selectedPaths);
    };
  };
  //---------------------------------------------------------------------------
  if (!isFitForFilter && subTags.length == 0) return null;
  return (
    <React.Fragment>
      <div className={CombineStyles([
        styles.container,
        isSelected ? styles.selected : ''
      ])}>


        {isSelectable ?
          <div
            className={styles.option}
            onClick={toggleState}>
            {/* <IconCheckBox checked={isSelected} /> */}
            <TagCheckbox state={checkBoxState} />
          </div> :
          <div className={styles.padding} />}

        <div
          className={styles.caption}
          onClick={isSelectable ? toggleState : isExpandable ? toggleSubTags : undefined}>
          {GetName(appUiContext, props.rootTag.id, props.rootTag.name)}
        </div>

        {isExpandable &&
          <div
            className={styles.option}
            onClick={toggleSubTags}>
            <ExpandButton expanded={expanded} />
          </div>}

      </div>

      {isExpandable && expanded &&
        <div className={styles.list}>
          <BusinessTagsCollection
            ui={props.ui}
            tags={tags}
            rootPath={props.rootTag.fullPath}
            selectedPaths={props.selectedPaths}
            filterString={props.filterString}
            onUpdate={onSubTagsUpdate}
          />
        </div>}
    </React.Fragment>
  );
}