import { IDictionaryDraft } from "../../../common/dictionary-draft-models/DictionaryDraft";
import { DictionaryEditorDraftItem, IDictionaryEditorDraftItem } from "../common/models/DictionaryEditorDraftItem";

export interface IAdRanksDictionaryDraft extends IDictionaryDraft<IAdRanksDictionaryDraftItem> {
  name: string; // Make it specific instead of 'any'
  description: string;
}

export interface IAdRanksDictionaryDraftItem extends IDictionaryEditorDraftItem {
  pointsPerView: number;
  pointsPerEngagement: number;
}

export class AdRanksDictionaryDraftItem extends DictionaryEditorDraftItem implements IAdRanksDictionaryDraftItem {
  pointsPerView!: number;
  pointsPerEngagement!: number;
  //---------------------------------------------------------------------------
  constructor(
    //source: IAdRanksDictionaryDraftItem, 
    source: any, 
    spread?: any) {
    const sourceCopy = JSON.parse(JSON.stringify(source));
    super(sourceCopy);
    Object.assign(this, sourceCopy, spread);
  }
  //---------------------------------------------------------------------------
  isValid(): boolean {
    this.refresh("en");
    //console.log(this.draftState, this.isNoTranslation)
    return !this.isNoTranslation;
  }
}