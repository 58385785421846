import { useEffect, useRef, useState } from 'react';
import InputLocation from '../inputs/InputLocation';
//import styles from './GeolocationSearcher.module.css';
import React from 'react';
import GoogleMap from '../../../../common/google-maps/google-map/GoogleMap';
import { IUiInputText } from '../../../../common/input-v2/input-text/InputText';
import GoogleMapPopup from './google-map-popup/GoogleMapPopup';
import { IMapArea } from '../../../../common/google-maps/map-itself/MapItself';

// User can use search and in this case if the place is found, map adjusts bounds to the place
// This place data can be passed to search console
// Another case when user do not use search, but just sets map bounds (or center/zoom)
// We can just pass those bounds to the search console
// But we have to show something in input so user can see that location for search is selected
// In this case, display a special text from component UI, like '[map area]'

interface IProps {
  ui: IUiInputText;
}

export default function GeolocationSearcher(props: IProps) {
  // Place selected using SearchPanel input (external popup for the map)
  const [selectedPlace, setSelectedPlace] = useState<IMapArea>();
  // Place selected or an updated area in popup
  const [selectedPlaceFromPopup, setSelectedPlaceFromPopup] = useState<IMapArea>();
  const [placesAreTheSame, setPlacesAreTheSame] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  //---------------------------------------------------------------------------
  useEffect(() => {
    // Once search panel input is updated, update popup state as well
    setSelectedPlaceFromPopup(selectedPlace);
  }, [selectedPlace]);
  //--------------------------------------------------------------------------- Check if places are different 
  useEffect(() => {
    let theSame = JSON.stringify(selectedPlace) === JSON.stringify(selectedPlaceFromPopup);
    //console.log('the same: ', theSame)
    setPlacesAreTheSame(theSame);
  }, [selectedPlaceFromPopup]);
  //--------------------------------------------------------------------------- Input events
  const onClear = () => {
    setSelectedPlace(undefined);
  };
  //---------------------------------------------------------------------------
  const toggleMap = () => {
    setPopupOpen(!popupOpen);
  };
  //---------------------------------------------------------------------------
  const onPlaceSelect = (newMapArea: IMapArea) => {
    //console.log('on place select')
    setPopupOpen(false);
    setSelectedPlace(newMapArea);
  };
  //--------------------------------------------------------------------------- Popup events
  const onPopupPlaceSelect = (newMapArea: IMapArea) => {
    console.log('on popup place select')
    // If user selected a place using GoogleMap's autocomplete
    setSelectedPlaceFromPopup(newMapArea);
  };
  //---------------------------------------------------------------------------
  const onMapAreaChange = (updatedMapArea: IMapArea) => {
    console.log("on map area change", selectedPlace, updatedMapArea)
    setSelectedPlaceFromPopup(updatedMapArea);
  };
  //---------------------------------------------------------------------------
  const onPopupConfirmed = () => {
    setPopupOpen(false);
    // Update main selected place state
    setSelectedPlace(selectedPlaceFromPopup);
    setPlacesAreTheSame(true);
  };
  //---------------------------------------------------------------------------
  const onPopupClose = () => {
    setPopupOpen(false);
    // Restore popup selected place setting to the main selected place
    setSelectedPlaceFromPopup(selectedPlace);
  };
  //---------------------------------------------------------------------------
  const map =
    <GoogleMap
      businesses={[]}
      currentLocation={true}
      defaultMapArea={selectedPlaceFromPopup}
      isSearchEnabled={true}
      externalAutocomplete={{
        inputRef: inputRef,
        options: {
          fields: ["geometry", "name", "place_id", "formatted_address"]
        },
        onMapAreaSelectCallback: onPlaceSelect
      }}
      onMapAreaChange={onMapAreaChange}
    />;
  //---------------------------------------------------------------------------
  //console.log(placesAreTheSame)
  return (
    <React.Fragment>

      <InputLocation
        elementRef={inputRef}
        ui={props.ui}
        searchString={selectedPlace?.address}
        onMapOptionSelect={toggleMap}
        onClear={onClear}
      />

      {popupOpen ?
        <GoogleMapPopup
          isConfirmationEnabled={!placesAreTheSame}
          onClose={onPopupClose}
          onConfirm={onPopupConfirmed}>
          {map}
        </GoogleMapPopup>
        :
        <div style={{ display: 'none' }}>
          {map}
        </div>}

    </React.Fragment>
  );
}