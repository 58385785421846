import { useEffect, useRef, useState } from "react";
import { EImageSize, TImageData } from "../../../common/image-editor/TImageData";
import styles from "./BusinessLogo.module.css";
import { ImageEditorPlaceholder } from "../../../common/image-editor/image-editor-placeholder/ImageEditorPlaceholder";
import { AbortControllerWrapper, useAbortController } from "../../../../hooks/useAbortController";

interface IProps {
  data: TImageData | undefined;
  useDefaultLogo: boolean;
}

const testId = '3b7ad95f-4339-4a33-acb1-403d0887c107';

export default function BusinessLogo(props: IProps) {
  const [imageSize, setImageSize] = useState<EImageSize>("Thumbnail");
  const [imageUri, setImageUri] = useState<string>();
  const imgRef = useRef<HTMLImageElement>(null);
  const imgRef2 = useRef<HTMLImageElement | null>(null);
  const abortController = useAbortController("BusinessLogo");
  //---------------------------------------------------------------------------
  const test = () => {
    return props.data?.id == testId;
  }
  //---------------------------------------------------------------------------
  useEffect(() => {
    return (() => {
      if (imgRef2.current) {
        imgRef2.current.src = '';
      }
      abortController.abortOnUnmount();
    });
  }, []);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (imgRef.current) {
      imgRef2.current = imgRef.current;
    }
  }, [imgRef.current]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    getImageUri(imageSize);
  }, [props.data, imageSize]);
  //---------------------------------------------------------------------------
  const getImageUri = (size: EImageSize) => {
    //test() && console.log("getImageUri:", size);
    //-------------------------------------------------------
    let controller = (props.data || props.useDefaultLogo)
      ? abortController.newController(size)
      : undefined;
    //-------------------------------------------------------
    let promise: Promise<string | undefined> | undefined = undefined;
    if (props.data) {
      promise = props.data.getUrlPromise(size, controller?.signal);
    }
    else if (props.useDefaultLogo) {
      promise = TImageData.defaultBusinessLogo?.getUrlPromise(size, controller?.signal);
    }
    //-------------------------------------------------------
    promise?.then(uri => {
      if (controller?.aborted)
        throw 'aborted';
      setImageUri(uri);
    })
      .catch(error => {
        test() && console.error(size, error);
      });

  }
  //---------------------------------------------------------------------------
  const onError = () => {
    //test() && console.log(`onError[${imageSize}]:`, abortController.state);
    //-------------------------------------------------------------------
    let controller = abortController.getController(imageSize);
    if (controller && controller.aborted) {
      //controller.remove(test());
      controller.remove();
      return;
    }
    //-------------------------------------------------------------------
    switch (imageSize) {
      case 'Thumbnail': {
        setImageSize("Medium");
      } break;
      case 'Medium': {
        setImageSize("Large");
      } break;
    }
  }
  //---------------------------------------------------------------------------
  //test() && console.log("render:", abortController.state);
  //---------------------------------------------------------------------------
  return (
    <div id="businessLogo"
      className={styles.container}
    >
      {(props.data || props.useDefaultLogo) &&
        <img
          ref={imgRef}
          src={imageUri}
          onError={onError}
          alt={props.data?.description}
          loading="lazy"
          width={"auto"}
          height={"auto"}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />}

      {!props.data && !props.useDefaultLogo &&
        <div className={styles.placeholder}>
          <ImageEditorPlaceholder />
        </div>
      }
    </div>
  );
}
