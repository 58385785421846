import { useMsal } from '@azure/msal-react';
import { TImageData } from '../../image-editor/TImageData';
import { IUiInput } from '../IUiInput';
import styles from './InputImage.module.css';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import { useAbortController } from '../../../../hooks/useAbortController';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { ClassImageManager } from '../../image-editor/image-manager/IImageManager';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import { SmoothImageLoader } from '../../image-editor/SmoothImageLoader';
import { ImageEditorPlaceholder } from '../../image-editor/image-editor-placeholder/ImageEditorPlaceholder';
import CombineStyles from '../../../../utils/combineStyles';
import { ImageManager } from '../../image-editor/image-manager/ImageManager';
import InputWrapper from '../input-wrapper/InputWrapper';
import GetCaption from '../../functions/GetCaption';
import GetHint from '../../functions/GetHint';
import { TImageUsage } from '../../image-editor/TImageUsage';

interface IProps {
  ui: IUiInput;
  data?: TImageData;
  objectId: string;
  dataIsSaved?: boolean;
  useExternalLayout: boolean;
  hideCaption?: boolean;
  hideHint?: boolean;
  stretch?: boolean;
  onImageUpdate: (imageData?: TImageData) => void;
}

export default function InputImage(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const [logoUri, setLogoUri] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [imageManager, setImageManager] = useState<ClassImageManager>();
  const abortController = useAbortController("BusinessLogoEditor");
  //---------------------------------------------------------------------------
  useEffect(() => {
    // remove deleted image when business context is saved, so undo option is not available
    if (imageManager && props.dataIsSaved) {
      imageManager.removedImage = undefined;
    };
  }, [props.dataIsSaved]);
  //---------------------------------------------------------------------- ImageEditorOpen
  // useEffect(() => {
  //   imageManager && props.onPopupOpenClose && props.onPopupOpenClose(imageManager?.editorOpen);
  // }, [imageManager?.editorOpen]);
  //---------------------------------------------------------------------- ImageEditorOpen
  // useEffect(() => {
  //   imageManager && props.onPopupOpenClose && props.onPopupOpenClose(imageManager?.libraryOpen);
  // }, [imageManager?.libraryOpen]);
  //---------------------------------------------------------------------------
  const onImageUpdate = (imageData?: TImageData) => {
    imageData?.clearSas();
    props.onImageUpdate(imageData);
  };
  //---------------------------------------------------------------------------
  const onImageManagerCreate = (manager: ClassImageManager) => {
    manager.businessId = props.objectId;
    setImageManager(manager);
  };
  //---------------------------------------------------------------------------
  const onImageEdit = () => {
    if (!props.data || !imageManager)
      return;
    //-------------------------------------------------------------------------
    setIsLoading(true);
    let controller = abortController.newController("editImage");
    imageManager?.editImage(
      props.data,
      TImageUsage.newLogo(props.objectId, false),
      controller.signal)
      .catch(error => {
        console.error(`BusinessLogoEditor.editImage[${imageManager?.imageToEdit?.id}]:`, error);
      })
      .finally(() => {
        !controller.aborted && setIsLoading(false);
        controller.remove();
      });
  };
  //---------------------------------------------------------------------------
  const onImageSelect = () => {
    if (!props.objectId)      return;
    //-------------------------------------------------------------------------
    setIsLoading(true);
    let controller = abortController.newController("loadUserImages");
    imageManager?.selectImage(
      instance,
      appAuthContext,
      props.data,
      controller.signal)
      .catch(error => {
        console.error("imageEditorContext.loadUserImages.catch:", error);
      })
      .finally(() => {
        console.error("imageEditorContext.loadUserImages.finally");
        !controller.aborted && setIsLoading(false);
        controller.remove();
      });
  };
  //---------------------------------------------------------------------------
  const imageContainerStyles: CSSProperties = {
    backgroundImage: `url(${logoUri})`,
    flex: props.stretch ? '1' : undefined,
    aspectRatio: props.stretch ? '2/1' : undefined
  };
  //---------------------------------------------------------------------------
  return (
    <InputWrapper
      caption={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      hideCaption={props.hideCaption}
      hint={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      hideHint={props.hideHint}
      readonly={false}
      disabled={false}
      validation={{ isValid: true, validationMessages: [] }}
    >
      <WikiSpinner show={isLoading} />

      {props.data &&
        <SmoothImageLoader
          onLoad={setLogoUri}
          sourceSize='Thumbnail'
          targetSize='Medium'
          imageData={props.data}
        />}

      <div className={styles.imageEditor}>
        <div
          className={CombineStyles([styles.image, props.data ? '' : styles.placeholder])}
          style={imageContainerStyles}
          onClick={props.data ? onImageEdit : undefined}
        >
          {!props.data &&
            <div className={styles.imagePlaceholder}>
              <ImageEditorPlaceholder onImageSelect={onImageSelect} />
            </div>}
        </div>
        <div className={styles.imageManager}>
          <ImageManager
            image={props.data}
            inLibrary={false}
            onManagerCreate={onImageManagerCreate}
            onImageEdit={onImageEdit}
            onImageSelect={onImageSelect}
            onImageUpdate={onImageUpdate}
          />
        </div>
      </div>
    </InputWrapper>
  );
}