import { IUiInputText } from "../../../common/input-v2/input-text/InputText";
import InputText from "../../../common/input/input-text/InputText";
import { getTUiInputText } from "../../../common/input/input-text/TUiInputText";
import styles from "./TagSearchInput.module.css";

interface IProps {
  ui: IUiInputText;
  onChange: (value: string) => void;
}

export default function TagSearchInput(props: IProps) {
  const onDragStart = (event: React.DragEvent) => {
    // We need this to prevent editor window to be draggable if mouse is inside text area
    event.preventDefault();
    event.stopPropagation();
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      draggable="true" // This is for preventDefault and stopPropagation
      onDragStart={onDragStart}
    >
      <InputText
        ui={getTUiInputText(props.ui)}
        hideCaption={true}
        hideHint={true}
        onUpdate={props.onChange}
      />
    </div>
  );
}