import { useEffect, useState } from 'react';
import CombineStyles from '../../../../../../utils/combineStyles';
import { TUiStaffRole } from '../../../TUiAdministratorConsole';
import UserRole, { IStaffRole } from '../../user-roles/user-role/UserRole';
import styles from './UserRoleEditor.module.css';

interface IProps {
  staffRole: TUiStaffRole;
  data?: IStaffRole;
  onUpdate: (isAssigned: boolean) => void;
}

export default function UserRoleEditor(props: IProps) {
  // This editor allows to assign/revoke a role but not to edit details
  const [data, setData] = useState<IStaffRole>();
  //---------------------------------------------------------------------------
  useEffect(() => {
    setData(props.data);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onAssign = () => {
    props.onUpdate(true);
  };
  //---------------------------------------------------------------------------
  const onRevoke = () => {
    props.onUpdate(false);
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={CombineStyles([styles.caption, data ? styles.assigned : ""])}>
        <UserRole
          key={props.staffRole.groupName}
          staffRole={props.staffRole}
          data={props.data}
        />
      </div>
      {data ?
        <div
          className={CombineStyles([styles.option, styles.revoke])}
          onClick={onRevoke}
        >
          Revoke
        </div>
        :
        <div
          className={CombineStyles([styles.option, styles.assign])}
          onClick={onAssign}
        >
          Assign
        </div>
      }
    </div>
  );
}