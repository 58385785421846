import { useContext, useEffect } from "react";
import ConsoleLayout from "../../../common/console-layout/ConsoleLayout";
import { getTUiMenuContent } from "../../../common/menu/menu-content/TUiMenuContent";
import { IUiOption } from "../../../common/options/IUiOption";
import { pathAdminConsole, pathAdministratorConsole, pathBusinessTagsConsole } from "../../AdminConsole";
import { IUiBusinessTagsConsole } from "./IUiBusinessTagsConsole";
import { TUiBreadcrumb } from "../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import { optionIdBusinessTagsConsole } from "../AdministratorConsole";
import { Route, Routes } from "react-router";
import SubDictionaryEditor from "./sub-dictionary-editor/SubDictionaryEditor";
import SubDictionariesList from "./sub-dictionaries-list/SubDictionariesList";
import BusinessTagsEditor from "./business-tags-editor/BusinessTagsEditor";
import NavigateWithContext from "../../../common/navigate-with-context/NavigateWithContext";
import CategoryRequests from "../category-requests/CategoryRequests";

export const pathDictionaries = "dictionaries";
export const optionIdDictionariesList = "BusinessTagsConsole.MenuContent.OptionDictionaries";
export const pathBusinessTagsEditor = "BusinessTags";
export const optionIdBusinessTagsEditor = "BusinessTagsConsole.MenuContent.OptionEditor";
export const pathBusinessTagRequests = "tagRequests";
export const optionIdBusinessTagRequests = "BusinessTagsConsole.MenuContent.OptionRequests";


interface IProps {
  ui?: IUiBusinessTagsConsole;
  optionsForBreadcrumb?: IUiOption[];
}

export default function BusinessTagsConsole(props: IProps) {
  if (!props.ui) return null;
  const { appUiContextDispatch } = useContext(AppUiContextStore);
  const rootPath = `${pathAdminConsole}/${pathAdministratorConsole}/${pathBusinessTagsConsole}`;
  //--------------------------------------------------------------------------- Breadcrumbs
  useEffect(() => {
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdBusinessTagsConsole);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        caption: option.caption,
        path: `/${pathAdminConsole}/${pathAdministratorConsole}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
    //-------------------------------------------------------------------------
    const breadcrumb: TUiBreadcrumb = {
      id: optionIdBusinessTagsConsole,
      caption: { en: "Business Tags" },
      path: `/${rootPath}`
    };
    appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
  }, []);
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui?.menuContent.options.forEach(option => {
    switch (option.id) {
      case optionIdBusinessTagsEditor:
        updatedOptions.push({
          ...option,
          iconId: "pencilRuler",
          action: `${pathAdminConsole}/${pathAdministratorConsole}/${pathBusinessTagsConsole}/${pathBusinessTagsEditor}`
        });
        break;
      case optionIdDictionariesList:
        updatedOptions.push({
          ...option,
          iconId: "books",
          action: `${pathAdminConsole}/${pathAdministratorConsole}/${pathBusinessTagsConsole}/${pathDictionaries}`
        });
        break;
      case "BusinessTagsConsole.MenuContent.OptionRequests":
        updatedOptions.push({
          ...option,
          iconId: "tagRequests",
          action: `${pathAdminConsole}/${pathAdministratorConsole}/${pathBusinessTagsConsole}/${pathBusinessTagRequests}`
        });
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <ConsoleLayout
      ui={{ header: "", menuContent: getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions }) }}
      path={rootPath}
      hideSidebar={true}
    >
      <Routes>

        <Route index element={
          <NavigateWithContext to={`/${pathAdminConsole}/${pathAdministratorConsole}/${pathBusinessTagsConsole}/${pathBusinessTagsEditor}`} />
        } />

        <Route path={pathBusinessTagsEditor} element={
          <BusinessTagsEditor />
        } />

        <Route path={pathDictionaries} element={
          <SubDictionariesList
            ui={props.ui.subDictionariesList}
            optionsForBreadcrumb={props.ui.menuContent.options}
          />
        } />

        <Route path={`${pathDictionaries}/:dictionaryId/*`} element={
          <SubDictionaryEditor />
        } />

        <Route path={pathBusinessTagRequests} element={
          <CategoryRequests
            optionsForBreadcrumb={props.ui.menuContent.options}
          />} />

      </Routes>
    </ConsoleLayout>
  );
}