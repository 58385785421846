import { IAdUnitStatus } from "../IAdUnit";

interface IProps {
  data: IAdUnitStatus;
}

export default function AdModuleStatus(props: IProps) {
  const opacity = .7;
  //---------------------------------------------------------------------------
  if (props.data.statusId == "PendingApproval") return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="1em" height="1em"
      viewBox="0 0 512 512">
      <path
        // question mark
        fill="currentColor"
        fillOpacity={opacity}
        d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8m0 448c-110.532 0-200-89.431-200-200c0-110.495 89.472-200 200-200c110.491 0 200 89.471 200 200c0 110.53-89.431 200-200 200m107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516c17.561-9.845 28.324-16.541 28.324-29.579c0-17.246-21.999-28.693-39.784-28.693c-23.189 0-33.894 10.977-48.942 29.969c-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8M298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42s18.841-42 42-42s42 18.841 42 42" />
    </svg>
  ); else if (props.data.statusId == "Approved" || props.data.statusId == "Rejected" || props.data.statusId == "Draft") return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="1em" height="1em"
      viewBox="0 0 100 100">
      <g
        stroke="currentColor"
        strokeWidth={9}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeOpacity={opacity}
        fill="none"
      >
        <circle
          cx="50"
          cy="50"
          r="43"
        />

        {props.data.isApproved ?
          <polyline
            // checkmark
            strokeWidth={11}
            points="27,50 45,67 72,40"
          /> : props.data.isInTrouble ?
            <line
              // ban line
              x1="20"
              y1="80"
              x2="80"
              y2="20"
            /> :
            <g
              // pencil
              strokeWidth={1}
              strokeLinecap="round"
              fill="currentColor"
              fillOpacity={opacity}>
              <polygon
                points="30,70 32,58  55,35 65,45  42,68"
              />
              <polygon
                points="58,32 63,27 73,37 68,42"
              />
            </g>}
      </g>
    </svg>
  ); else return null;
}