import { useContext } from 'react';
import { UiBusinessTag } from '../../business-tags-popup/business-tag-node/IUiBusinessTag';
import styles from './BusinessTag.module.css';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import GetText from '../../../functions/GetText';
import Icon from '../../../icon/Icon';
import CombineStyles from '../../../../../utils/combineStyles';
import GetDescription from '../../../functions/GetDescription';

interface IProps {
  tag: UiBusinessTag;
  onDelete?: (path: string) => void;
}

export default function BusinessTag(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const isUnavailable = !props.tag.status?.viewers;
  const title = isUnavailable ? 'This item is not available anymore' : GetDescription(appUiContext, props.tag.id, props.tag.description);
  //---------------------------------------------------------------------------
  const onDelete = () => {
    props.onDelete && props.onDelete(props.tag.fullPath);
  };
  //---------------------------------------------------------------------------
  return (
    <div 
    title={title}
    className={CombineStyles([
      styles.container,
      isUnavailable ? styles.unavailable : ''
    ])}>

      <div className={styles.caption}>
        {GetText({
          appUiContext: appUiContext,
          elementId: props.tag.id,
          elementPart: "Name",
          text: props.tag.name
        })}
      </div>

      {props.onDelete &&
        <div
          className={styles.deleteOption}
          onClick={onDelete}
        >
          <Icon imageName="close" />
        </div>}

    </div>
  );
}