import { IListColumn } from "../../components/common/lists/IListColumn";
import { EListMode, EManagerMode, IFilterDynamicOptions, IListManagerContextConfiguration, IListManagerContextData, ListManagerContextData } from "./IListManagerContextData";

interface IInitialize {
  type: "Initialize";
  configuration: IListManagerContextConfiguration;
}
interface IAddDynamicFilterOptions {
  type: "AddDynamicFilterOptions",
  dynamicOptions: IFilterDynamicOptions[];
}
interface IUpdateFilterOption {
  type: "UpdateFilterOption";
  columnId: string;
  filterItemOptionId: string;
}
interface IClearFilter {
  type: "ClearFilter";
  columnId: string;
}
interface IRestoreFilter {
  type: "RestoreFilter";
  columnId: string;
}
interface IUpdateFilterKeyword {
  type: "UpdateFilterKeyword";
  columnId: string;
  keyword: string;
}
interface IUpdateFilterDatetimeStart {
  type: "UpdateFilterDatetimeStart";
  columnId: string;
  datetime: string;
}
interface IUpdateFilterDatetimeEnd {
  type: "UpdateFilterDatetimeEnd";
  columnId: string;
  datetime: string;
}
interface IUpdateSorter {
  type: "UpdateSorter";
  updatedColumn: IListColumn;
}
interface IUpdateTwoColumns {
  type: "UpdateTwoColumns",
  firstColumn: IListColumn;
  secondColumn: IListColumn;
}
interface IRestoreDefaults {
  type: "RestoreDefaults";
}
interface ISetMode {
  type: "SetMode";
  mode: EManagerMode;
}
interface ISetListMode {
  type: "SetListMode";
  listMode: EListMode;
}
interface ISetSortPreset {
  type: "SetSortPreset";
  optionId: string;
}

export type Actions =
  IInitialize | IRestoreDefaults |
  IAddDynamicFilterOptions |
  IUpdateFilterOption |
  IUpdateFilterKeyword |
  IUpdateFilterDatetimeStart | IUpdateFilterDatetimeEnd |
  IUpdateSorter | IUpdateTwoColumns |
  IClearFilter | IRestoreFilter |
  ISetMode | ISetListMode |
  ISetSortPreset;
//-----------------------------------------------------------------------------
const logging = false;
//-----------------------------------------------------------------------------
export const ListManagerContextReducer = (state: IListManagerContextData, action: Actions) => {
  var updatedData: IListManagerContextData;
  var updatedState = state as ListManagerContextData;
  switch (action.type) {
    //-------------------------------------------------------------------------
    case "Initialize":
      logging && console.log("Initialize", state);
      updatedState = new ListManagerContextData(action.configuration as IListManagerContextData);
      //console.log(updatedState)
      break;
    //-------------------------------------------------------------------------
    case "RestoreDefaults":
      logging && console.log("RestoreDefaults")
      updatedData = state.restoreDefaults();
      updatedState = new ListManagerContextData(updatedData);
      break;
    //-------------------------------------------------------------------------
    case "AddDynamicFilterOptions":
      logging && console.log("AddDynamicFilterOptions")
      updatedData = state.addFilterDynamicOptions(action.dynamicOptions);
      updatedState = new ListManagerContextData(updatedData);
      break;
    //-------------------------------------------------------------------------
    case "UpdateFilterOption":
      updatedData = state.updateFilterOption(action.columnId, action.filterItemOptionId);
      updatedState = new ListManagerContextData(updatedData);
      logging && console.log("UpdateFilterOption", updatedState.filterableColumns);
      break;
    //-------------------------------------------------------------------------
    case "UpdateFilterKeyword":
      updatedData = state.updateFilterKeyword(action.columnId, action.keyword);
      updatedState = new ListManagerContextData(updatedData);
      logging && console.log("UpdateFilterKeyword", updatedState.filterableColumns);
      break;
    //-------------------------------------------------------------------------
    case "UpdateFilterDatetimeStart":
      updatedData = state.updateFilterDatetimeStart(action.columnId, action.datetime);
      updatedState = new ListManagerContextData(updatedData);
      logging && console.log("UpdateFilterDatetimeStart", updatedState.filterableColumns);
      break;
    //-------------------------------------------------------------------------
    case "UpdateFilterDatetimeEnd":
      updatedData = state.updateFilterDatetimeEnd(action.columnId, action.datetime);
      updatedState = new ListManagerContextData(updatedData);
      logging && console.log("UpdateFilterDatetimeEnd", updatedState.filterableColumns);
      break;
    //-------------------------------------------------------------------------
    case "ClearFilter":
      updatedData = state.clearFilter(action.columnId);
      updatedState = new ListManagerContextData(updatedData);
      logging && console.log("ClearFilterOptions", updatedState.filterableColumns);
      break;
    //-------------------------------------------------------------------------
    case "RestoreFilter":
      updatedData = state.restoreFilter(action.columnId);
      updatedState = new ListManagerContextData(updatedData);
      logging && console.log("ClearFilterOptions", updatedState.filterableColumns);
      break;
    //-------------------------------------------------------------------------
    case "UpdateSorter":
      logging && console.log("UpdateSorter")
      updatedData = state.updateSorter(action.updatedColumn);
      updatedState = new ListManagerContextData(updatedData);
      //updatedState.selectedSortColumn = action.updatedColumn;
      break;
    //-------------------------------------------------------------------------
    case "UpdateTwoColumns":
      logging && console.log("UpdateTwoColumns")
      updatedData = state.updateTwoColumns(action.firstColumn, action.secondColumn);
      updatedState = new ListManagerContextData(updatedData);
      break;
    //-------------------------------------------------------------------------
    case "SetMode":
      updatedData = updatedState.setManagerMode(action.mode);
      updatedState = new ListManagerContextData(updatedData);
      logging && console.log("SetMode", action.mode);
      break;
    //-------------------------------------------------------------------------
    case "SetListMode":
      updatedState = new ListManagerContextData(state);
      updatedState.currentListMode = action.listMode;
      break;
    //-------------------------------------------------------------------------
    case "SetSortPreset":
      if (state.sortPresets) {
        state.sortPresets.currentOptionId = action.optionId;
        updatedState = new ListManagerContextData(state);
      };
      break;
    //-------------------------------------------------------------------------
    default:
      console.error(`ListManagerContextReducer: Unrecognizable action`);
      break;
  };
  updatedState.lastAction = action;
  return updatedState;
}