import { CropEvent, CropStrip, ECropDirection, ImageCrop, OffscreenImage, 
  Point, Rect, TCropStart, redrawMidCanvasMatrix, redrawTopCanvas, 
  useCropEvents } from "./Crop";
import styles from "./CanvasOverlay.module.css";
import { useEffect, useRef, useState } from "react";
import { ImageTransformation } from "../image-editor-history/ImageEditorHistory";

const imageDefaultCursor = 'pointer';
//---------------------------------------------------------------------------------------
export enum ECropAction {
  None = 0,
  Confirm = 1,
  Discard = 2,
}
//---------------------------------------------------------------------------------------
interface IProps {
  backCanvas: HTMLCanvasElement;
  transform: ImageTransformation;
  backCanvasClientRect: DOMRect;
  cropAction: ECropAction;
  onCropAction: (crop?: ImageCrop) => void;
}
//---------------------------------------------------------------------------------------
export function CanvasOverlay(props: IProps) {
  const midCanvas = useRef<HTMLCanvasElement>(null);
  const topCanvas = useRef<HTMLCanvasElement>(null);
  const [offscreen, setOffscreen] = useState<OffscreenImage>();
  const [size, movePoints, cropEvents] = useCropEvents(props.backCanvas, props.transform);
  const [crop, setCrop] = useState<ImageCrop>();
  const [cursor, setCursor] = useState(imageDefaultCursor);
  //---------------------------------------------------------------------------
  useEffect(() => {
    //console.log("CanvasOverlay.mount");
    return () => {
      //console.log("CanvasOverlay.unmount");
    }
  }, [])
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!props.backCanvasClientRect)
      return;
    if (!crop || !crop.changedByUser) {
      let crop = ImageCrop.initCrop(size.rect, size.matrix, size.scale);
      setCrop(crop);
    }
    else {
      crop.newClientRectOnResize(size.state.matrix, size.scale);
    }
  }, [props.backCanvasClientRect]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    switch (props.cropAction) {
      case ECropAction.Confirm: {
        props.onCropAction(crop);
      } break;
      case ECropAction.Discard: {
        props.onCropAction();
      } break;
    }
  }, [props.cropAction]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    Point.setCanvasSize(topCanvas.current);
    Point.setCanvasSize(midCanvas.current);
    redrawMidCanvasMatrix(
      size.matrix,
      size.scale,
      midCanvas.current,
      crop,
      offscreen?.offscreen
    );
    redrawTopCanvas(topCanvas.current, crop);
  }/*[redraw on every render]*/);
  //---------------------------------------------------------------------------
  const newOffscreen = () => {
    //console.log("New offscreen");
    let w = Math.trunc(props.transform.cropRect.width / size.scale);
    let h = Math.trunc(props.transform.cropRect.height / size.scale);
    if (Point.offscreenWidth != w)
      Point.offscreenWidth = w;
    if (Point.offscreenHeight != h)
      Point.offscreenHeight = h;
    if (!offscreen || offscreen.changeNeeded(props.transform.bitmap, w, h)) {
      let options: ImageBitmapOptions = {
        resizeWidth: w,
        resizeHeight: h,
        resizeQuality: "high"
      };
      //console.log("create new offscreen");
      createImageBitmap(
        props.transform.bitmap,
        props.transform.cropRect.left,
        props.transform.cropRect.top,
        props.transform.cropRect.width,
        props.transform.cropRect.height,
        options
      ).then((bmp: ImageBitmap) => {
        setOffscreen(new OffscreenImage(props.transform.bitmap, bmp));
      });
    }
  }
  //---------------------------------------------------------------------------
  const pointerDown = (e: CropEvent) => {
    if (!crop)
      return;

    // check if user uses more than 2 fingers
    if (!cropEvents.addEvent(e)) {
      return;
    }

    let newCrop = crop.clone();
    if (cropEvents.count == 2) {
      let rect = size.rect;
      movePoints.setStartingPoint(
        cropEvents.startEvents[0].clientX - rect.left,
        cropEvents.startEvents[1].clientX - rect.left,
        cropEvents.startEvents[0].clientY - rect.top,
        cropEvents.startEvents[1].clientY - rect.top,
        crop.clientRect.centerX,
        crop.clientRect.centerY,
      );
      // set cropStrip to Move
      newCrop.cropStart = {
        clientX: 0, clientY: 0,
        clientRectSnap: crop.clientRect.clone()
      };
      newCrop.cropStrip = crop.strips.moveStrip;
    }
    else {
      newCrop.cropStrip = crop.strips.getStripByPoint(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
      newCrop.cropStart = {
        clientX: e.nativeEvent.offsetX,
        clientY: e.nativeEvent.offsetY,
        //cropSnap: new ImageCrop(crop.imageRect, crop.clientRect),
        clientRectSnap: crop.clientRect.clone()
      };
    }
    //newCrop.cursor = newCrop.cropStrip != undefined ? newCrop.cropStrip.cursor : '';
    let cursor = newCrop.cropStrip != undefined ? newCrop.cropStrip.cursor : '';
    setCursor(cursor);
    newCrop.moving = true;
    setCrop(newCrop);
  }
  //---------------------------------------------------------------------------
  const pointerMove = (e: React.PointerEvent<HTMLCanvasElement>) => {
    if (!crop || !topCanvas.current) {
      return;
    }
    if (cropEvents.isExtraEvent(e)) {
      return;
    }
    let i = cropEvents.startEvents.findIndex(ce => ce.nativeEvent.pointerId == e.nativeEvent.pointerId);
    if (i >= 0 && i < cropEvents.startEvents.length) {
      switch (i) {
        case 0:
          movePoints.moveX1 = e.clientX - size.rect.left;
          movePoints.moveY1 = e.clientY - size.rect.top;
          break;
        case 1:
          movePoints.moveX2 = e.clientX - size.rect.left;
          movePoints.moveY2 = e.clientY - size.rect.top;
          break;
      }
      //cropEvents.moveEvents[i] = e;
      //if (cropEvents.startEvents.length == 1)
        cropEvents.startEvents[i] = e;
      //console.log("movePoints", movePoints);
    }

    let newCrop: ImageCrop | undefined = undefined;
    if (cropEvents.count == 2) {
      newCrop = calcCurrentPinchDelta();
    }
    else {
      if (crop.cropStrip) { // cropStrip assigned in mouseDown
        if (crop.cropStart) {
          newCrop = calcCurrentCropDelta(crop.cropStart, crop, e.nativeEvent.offsetX, e.nativeEvent.offsetY);
        }
      }
      else { // user just moves the mouse without holding down a button
        let strip: CropStrip | undefined = crop.strips.getStripByPoint(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
        let cursor = strip != undefined ? strip.cursor : '';
        setCursor(cursor);
      }
    }
    //console.log("pointerMove.newCrop:", newCrop);
    if (newCrop) {
      newCrop.changedByUser = true;
      setCrop(newCrop);
    }
  }
  //---------------------------------------------------------------------------
  const pointerUp = (e: CropEvent) => {
    if (!crop || !crop.cropStrip)
      return;
    if (cropEvents.removeExtraEvent(e)) {
      return;
    }
    cropEvents.removeEvent(e);
    switch (cropEvents.count) {
      case 0: {
        if (crop.cropStrip && crop.cropStart) { // cropStrip assigned in mouseDown
          let newCrop = calcCurrentCropDelta(crop.cropStart, crop, e.nativeEvent.offsetX, e.nativeEvent.offsetY);
          if (newCrop) {
            newCrop.cropStrip = undefined;
            newCrop.moving = false;
            setCursor(imageDefaultCursor);
            setCrop(newCrop);
          }
        }
      } break;
      case 1: {
        //pointerDown(cropEvents.moveEvents[0]);
        pointerDown(cropEvents.startEvents[0]);
      } break;
    }
  }
  //---------------------------------------------------------------------------
  const calcCurrentCropDelta = (start: TCropStart, currentCrop: ImageCrop, offsetX: number, offsetY: number): ImageCrop | undefined => {
    //console.log("calcCurrentCropDelta.start.cropSnap:", start.clientRectSnap);
    if (!start || !crop || !start.clientRectSnap) {
      return;
    }

    let clientRect = start.clientRectSnap;

    let newClientTop: number | undefined = undefined;
    let newClientBottom: number | undefined = undefined;
    let newClientLeft: number | undefined = undefined;
    let newClientRight: number | undefined = undefined;

    let changed: boolean = false;

    const calcNewTop = () => {
      let top = clientRect.top + (offsetY - start.clientY);
      if (top < 0) top = 0;
      if (crop.cropStrip?.cropDirection == ECropDirection.Move || clientRect.bottom - top > 10) {
        newClientTop = top;
        changed = true;
      }
    }
    const calcNewLeft = () => {
      let left = clientRect.left + (offsetX - start.clientX);
      if (left < 0) left = 0;
      if (crop.cropStrip?.cropDirection == ECropDirection.Move || clientRect.right - left > 10) {
        newClientLeft = left;
        changed = true;
      }
    }
    const calcNewBottom = () => {
      let bottom = clientRect.bottom - (start.clientY - offsetY);
      if (bottom > Point.clientHeight) bottom = Point.clientHeight;
      if (crop.cropStrip?.cropDirection == ECropDirection.Move || bottom - clientRect.top > 10) {
        newClientBottom = bottom;
        changed = true;
      }
    }
    const calcNewRight = () => {
      let right = clientRect.right - (start.clientX - offsetX);
      if (right > Point.clientWidth) right = Point.clientWidth;
      if (crop.cropStrip?.cropDirection == ECropDirection.Move || right - clientRect.left > 10) {
        newClientRight = right;
        changed = true;
      }
    }
    switch (crop.cropStrip?.cropDirection) {
      case ECropDirection.North:
        calcNewTop();
        break;

      case ECropDirection.South:
        calcNewBottom();
        break;

      case ECropDirection.West:
        calcNewLeft();
        break;

      case ECropDirection.East:
        calcNewRight();
        break;

      case ECropDirection.NorthWest:
        calcNewTop();
        calcNewLeft();
        break;

      case ECropDirection.NorthEast:
        calcNewTop();
        calcNewRight();
        break;

      case ECropDirection.SouthEast:
        calcNewBottom();
        calcNewRight();
        break;

      case ECropDirection.SouthWest:
        calcNewLeft();
        calcNewBottom();
        break;

      case ECropDirection.Move:
        calcNewLeft();
        calcNewRight();
        calcNewTop();
        calcNewBottom();
        break;
    }
    // if something changed - return new crop
    if (changed) {

      let left = newClientLeft != undefined ? newClientLeft : clientRect.left;
      let top = newClientTop != undefined ? newClientTop : clientRect.top;
      let right = newClientRight != undefined ? newClientRight : clientRect.right;
      let bottom = newClientBottom != undefined ? newClientBottom : clientRect.bottom;

      //console.log("l, t, r, b:", left, top, right, bottom);

      let n: number = 1;
      if (crop.cropStrip?.cropDirection == ECropDirection.Move) {
        if (left <= n) {
          left = n;
          right = currentCrop.clientRect.right;
        }
        else if (right >= Point.clientWidth - n) {
          right = Point.clientWidth - n;
          left = currentCrop.clientRect.left;
        }

        if (top <= n) {
          top = n;
          bottom = currentCrop.clientRect.bottom;
        }
        else if (bottom >= Point.clientHeight - n) {
          bottom = Point.clientHeight - n;
          top = currentCrop.clientRect.top;
        }
      }
      else {
        if (left < n) left = n;
        if (top < n) top = n;
        if (right > Point.clientWidth - n) right = Point.clientWidth - n;
        if (bottom > Point.clientHeight - n) bottom = Point.clientHeight - n;
      }

      clientRect = new Rect(left, top, right, bottom);
      let cropImageRect = clientRect.clientToImage(size.matrix, size.scale);
      cropImageRect.checkBoundsExceedingR(Point.imageRect);
      //console.log("Point.imgRect:", Point.imageRect);
      //console.log("NewCrop.imgRect:", cropImageRect);
      //console.log("NewCrop.clientRect:", clientRect);
      return crop.clone(cropImageRect, clientRect);
    }
    return undefined;
  }
  //---------------------------------------------------------------------------
  const calcCurrentPinchDelta = (): ImageCrop | undefined => {
    if (!crop || !crop.cropStart?.clientRectSnap) {
      return;
    }

    let clientRect = crop.cropStart.clientRectSnap;

    let newClientTop: number | undefined = undefined;
    let newClientBottom: number | undefined = undefined;
    let newClientLeft: number | undefined = undefined;
    let newClientRight: number | undefined = undefined;

    let changed: boolean = false;

    let sizeX = clientRect.width - movePoints.resizeX;
    let sizeY = clientRect.height - movePoints.resizeY;

    //const calcNewTop = () => 
    {
      let top = movePoints.shiftY - sizeY / 2;
      if (top < 0) top = 0;
      if (crop.cropStrip?.cropDirection == ECropDirection.Move || clientRect.bottom - top > 10) {
        newClientTop = top;
        changed = true;
      }
    }
    //const calcNewLeft = () => 
    {
      let left = movePoints.shiftX - sizeX / 2;
      if (left < 0) left = 0;
      if (crop.cropStrip?.cropDirection == ECropDirection.Move || clientRect.right - left > 10) {
        newClientLeft = left;
        changed = true;
      }
    }
    //const calcNewBottom = () => 
    {
      let bottom = movePoints.shiftY + sizeY / 2;
      if (bottom > Point.clientHeight) bottom = Point.clientHeight;
      if (crop.cropStrip?.cropDirection == ECropDirection.Move || bottom - clientRect.top > 10) {
        newClientBottom = bottom;
        changed = true;
      }
    }
    //const calcNewRight = () => 
    {
      let right = movePoints.shiftX + sizeX / 2;
      if (right > Point.clientWidth) right = Point.clientWidth;
      if (crop.cropStrip?.cropDirection == ECropDirection.Move || right - clientRect.left > 10) {
        newClientRight = right;
        changed = true;
      }
    }
    //calcNewLeft();
    //calcNewRight();
    //calcNewTop();
    //calcNewBottom();

    // if something changed - return new crop
    if (changed) {

      let left = newClientLeft != undefined ? newClientLeft : clientRect.left;
      let top = newClientTop != undefined ? newClientTop : clientRect.top;
      let right = newClientRight != undefined ? newClientRight : clientRect.right;
      let bottom = newClientBottom != undefined ? newClientBottom : clientRect.bottom;

      let n: number = 1;
      if (crop.cropStrip?.cropDirection == ECropDirection.Move) {
        if (left <= n) {
          left = n;
          right = crop.clientRect.right;
        }
        else if (right >= Point.clientWidth - n) {
          right = Point.clientWidth - n;
          left = crop.clientRect.left;
        }

        if (top <= n) {
          top = n;
          bottom = crop.clientRect.bottom;
        }
        else if (bottom >= Point.clientHeight - n) {
          bottom = Point.clientHeight - n;
          top = crop.clientRect.top;
        }
      }
      else {
        if (left < n) left = n;
        if (top < n) top = n;
        if (right > Point.clientWidth - n) right = Point.clientWidth - n;
        if (bottom > Point.clientHeight - n) bottom = Point.clientHeight - n;
      }

      clientRect = new Rect(left, top, right, bottom);
      let cropImageRect = clientRect.clientToImage(size.matrix, size.scale);
      cropImageRect.checkBoundsExceedingR(Point.imageRect);
      return crop.clone(cropImageRect, clientRect);
    }
    return undefined;
  }
  //---------------------------------------------------------------------------
  let sizeChanged = size.check(
    props.backCanvas.getBoundingClientRect(),
    props.transform,
    props.transform.getCanvasState(props.backCanvas)
  );
  if (sizeChanged) {
    newOffscreen();
  }
  //----------------------------------------------------------------------------------
  //console.log("overlay.render")
  //----------------------------------------------------------------------------------
  return (
    <div className={styles.container} style={{ width: size.width, height: size.height }}>
      <canvas ref={midCanvas} className={styles.midCanvas} />
      <canvas
        ref={topCanvas}
        className={styles.topCanvas}
        onPointerDown={pointerDown}
        onPointerMove={pointerMove}
        onPointerUp={pointerUp}
        style={{ cursor: cursor }}
      />
    </div>
  )

}