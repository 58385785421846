import styles from "./InputValidationMessage.module.css";

interface IProps {
  string: string;
}

export default function InputValidationMessage(props: IProps) {
  return (
    <div className={styles.container}>
      {props.string}
    </div>
  );
}