import { IUiInteractiveForm } from "../forms/IUiInteractiveForm";
import { IUiInputText } from "../input-v2/input-text/InputText";

export interface IUiTextEditor extends IUiInteractiveForm {
  inputText: IUiInputText;
}

export const tmpTextEditorPopup: IUiTextEditor = {
  id: 'TextEditorPopup',
  directoryId: '',
  elementType: '',
  caption: { en: 'Text Editor' },
  hint: { en: 'Text Editor' },
  inputText: {
    id: 'TextEditor.InputText',
    directoryId: '',
    elementType: '',
    caption: { en: 'Text' },
    hint: { en: 'Text' }
  },
  menuContent: {
    id: 'TextEditor.MenuContent',
    directoryId: '',
    elementType: '',
    hint: { en: 'Text Editor Menu Content' },
    options: [{
      id: 'TextEditor.MenuContent.OptionOk',
      directoryId: '',
      elementType: '',
      caption: { en: 'Ok' },
      hint: { en: 'Ok' }
    }, {
      id: 'TextEditor.MenuContent.OptionCancel',
      directoryId: '',
      elementType: '',
      caption: { en: 'Cancel' },
      hint: { en: 'Cancel' }
    }]
  }
};