import { ReactNode, useRef, useState } from 'react';
import styles from './FilterEditor.module.css';
import { IFilterItemOption } from './IFilterItem';
import ColumnOptionWrapper from '../column-option-wrapper/ColumnOptionWrapper';
import FilterOption from '../filter-option/FilterOption';
import { IListColumn } from '../IListColumn';
import CombineStyles from '../../../../utils/combineStyles';

interface IProps {
  column: IListColumn;
  onOptionUpdate: (columnId: string, filterItemOptionId: string) => void;
  onKeywordUpdate: (columnId: string, keyword: string) => void;
  onDatetimeStartUpdate: (columnId: string, datetime: string) => void;
  onDatetimeEndUpdate: (columnId: string, datetime: string) => void;
  onClear: (columnId: string) => void;
  onRestore?: (columnId: string) => void;
}

export default function FilterEditor(props: IProps) {
  const [editMode, setEditMode] = useState(false);
  const [isValid, setIsValid] = useState(true);
  let isEnabled = false;
  //---------------------------------------------------------------------------
  const onClear = () => {
    setEditMode(false);
    props.onClear(props.column.id);
  };
  //---------------------------------------------------------------------------
  const onRestore = () => {
    props.onRestore && props.onRestore(props.column.id);
  };
  //---------------------------------------------------------------------------
  const enableEditMode = () => {
    setEditMode(true);
    if (props.column.filter?.type == "DatetimeRange") {
      setIsValid(!!props.column.filter.dateTimeStart && !!props.column.filter.dateTimeEnd);
    };
  };
  //---------------------------------------------------------------------------
  const onApply = () => {
    setEditMode(false);
    if (props.column.filter?.type == "Options") {
      const unSelectedOptions = props.column.filter?.options?.filter(o => !o.isSelected);
      if (unSelectedOptions && unSelectedOptions.length == 0) {
        props.onClear(props.column.id);
      };
    };
  };
  //---------------------------------------------------------------------------
  const onKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onKeywordUpdate(props.column.id, e.target.value);
  };
  //---------------------------------------------------------------------------
  const getDateString = (date: Date | null) => {
    // ISO format "2024-04-07T12:41:15.9465799Z", we need to remove time since we don't filter time so far
    return date?.toISOString().split("T")[0];
  };
  //---------------------------------------------------------------------------
  const onDatetimeStartUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const datetimeString = getDateString(e.target.valueAsDate);
    datetimeString && props.onDatetimeStartUpdate(props.column.id, datetimeString);
  };
  //---------------------------------------------------------------------------
  const onDatetimeEndUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.column.filter?.dateTimeStart) return;
    const datetimeString = getDateString(e.target.valueAsDate);
    if (!datetimeString) return;
    setIsValid(true);
    const dateIsValid = datetimeString >= props.column.filter.dateTimeStart;
    dateIsValid && props.onDatetimeEndUpdate(props.column.id, datetimeString);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOption: IFilterItemOption) => {
    if (!selectedOption.isDisabled)
      props.onOptionUpdate(props.column.id, selectedOption.id);
  };
  //---------------------------------------------------------------------------
  const getSelectedOptionsString = (selectedOptions: IFilterItemOption[]): string => {
    if (selectedOptions.length == 0) {
      return "Any";
    } else if (selectedOptions.length == 1) {
      const name = selectedOptions[0].name ? selectedOptions[0].name : selectedOptions[0].id;
      return name;
    } else {
      const name = selectedOptions[0].name ? selectedOptions[0].name : selectedOptions[0].id;
      return `${name} (+${selectedOptions.length - 1})`;
    };
  };
  //---------------------------------------------------------------------------
  const getKeywordString = (keyword?: string): string => {
    if (!keyword) {
      return "Any";
    } else {
      return `contains '${keyword}'`;
    };
  };
  //---------------------------------------------------------------------------
  const getDatetimeRangeString = (dateStart?: string, dateEnd?: string): string => {
    if (!dateStart && !dateEnd) {
      return "Anytime";
    } else {
      return `from ${dateStart} to ${dateEnd}`;
    };
  };
  //---------------------------------------------------------------------------
  const optionElements = props.column.filter?.options?.map(option => (
    <FilterOption
      key={option.id}
      option={option}
      onSelect={onOptionSelect}
    />
  ));
  //---------------------------------------------------------------------------
  let jsxContent: ReactNode = undefined;
  switch (props.column.filter?.type) {
    //-------------------------------------------------------------------------
    case "Keyword":
      if (editMode) {
        jsxContent =
          <input
            type="text"
            className={styles.input}
            placeholder={props.column.filter.placeholder}
            value={props.column.filter.keyword}
            onChange={onKeywordChange}
          />;
      } else {
        isEnabled = !!props.column.filter.keyword;
        jsxContent =
          <div
            className={CombineStyles([
              styles.datetimeRange,
              styles.clickable,
              isEnabled ? styles.highlighted : ""])}
            onClick={enableEditMode}
          >
            {getKeywordString(props.column.filter.keyword)}
            {/* <Icon imageName='pencil' /> */}
          </div>;
      };
      break;
    //-------------------------------------------------------------------------
    case "Options":
      if (editMode) {
        jsxContent =
          <div className={styles.filterOptionsEdit}>
            {optionElements}
          </div>;
      } else if (props.column.filter.options && props.column.filter.options.length > 0) {
        const selectedOptions = props.column.filter.options.filter(o => o.isSelected);
        isEnabled = selectedOptions && selectedOptions.length > 0;
        jsxContent =
          <div
            className={CombineStyles([
              styles.filterOptionsView,
              styles.clickable,
              isEnabled ? styles.highlighted : ""])}
            onClick={enableEditMode}
          >
            {getSelectedOptionsString(selectedOptions)}
          </div>;
      };
      break;
    //-------------------------------------------------------------------------
    case "DatetimeRange":
      if (editMode) {
        jsxContent =
          <div className={styles.datetimeRange}>
            <input
              type="date"
              className={styles.input}
              value={props.column.filter.dateTimeStart ? props.column.filter.dateTimeStart : ""}
              onChange={onDatetimeStartUpdate}
            />
            <span>-</span>
            <input
              type="date"
              className={styles.input}
              disabled={!props.column.filter.dateTimeStart}
              value={props.column.filter.dateTimeEnd ? props.column.filter.dateTimeEnd : ""}
              onChange={onDatetimeEndUpdate}
            />
          </div>;
      } else {
        isEnabled = !!props.column.filter.dateTimeStart;
        jsxContent =
          <div
            className={CombineStyles([
              styles.datetimeRange,
              styles.clickable,
              isEnabled ? styles.highlighted : ""])}
            onClick={enableEditMode}
          >
            {getDatetimeRangeString(props.column.filter.dateTimeStart, props.column.filter.dateTimeEnd)}
            {/* <Icon imageName='pencil' /> */}
          </div>;
      };
      break;
  };
  //---------------------------------------------------------------------------
  return (
    <ColumnOptionWrapper
      caption={props.column.name}
      readonly={!editMode}
      isEnabled={isEnabled}
      onApply={isValid ? onApply : undefined}
      onClear={onClear}
      onRestore={props.onRestore ? onRestore : undefined}
      onFocusLost={() => setEditMode(false)}
    >
      {jsxContent}
    </ColumnOptionWrapper>
  );
}