import React, { ReactNode, useEffect, useRef, useState } from "react";
import { ScreenType, useAppScreenContext } from "../../../../context/app-screen-context/AppScreenProvider";
import CombineStyles from "../../../../utils/combineStyles";
import styles from "./ConsoleTabLayout.module.css";
import WikiSpinner from "../../../app-layout/spinner/wikiSpinner";

type TScrollEventType = 'None' | 'Wheel' | 'Touch';
export type TScrollDirection = "Up" | "Down" | "None";
export type TScrollType = "Warn" | "Switch";

interface IScrollStuff {
  locationPath: string;
  eventType: TScrollEventType;
  direction: TScrollDirection;
  counter: number;
  warningIsSent: boolean;
};

const defaultScrollStuff: IScrollStuff = {
  locationPath: '',
  eventType: "None",
  direction: "None",
  counter: 0,
  warningIsSent: false
};

interface IScrollThresholds {
  resetTimeout: number;
  warn: number;
  max: number;
}

const wheelThresholds: IScrollThresholds = {
  resetTimeout: 500,
  warn: 500,
  max: 800
};

const touchThresholds: IScrollThresholds = {
  resetTimeout: 300,
  warn: 150,
  max: 300
};

interface IScrollMovement {
  direction: TScrollDirection;
  counter: number;
}

interface IProps {
  children?: ReactNode;
  toolbar?: ReactNode;
  headerChildren?: ReactNode;
  isLoading?: boolean;
  allowScroll?: boolean;
  // onScroll is called when user scrolls up or down even if it's top/bottom already (to switch to another tab)
  // type 'warn' can be used to warn user the tab is going to switch
  // type 'switch' means user is switching to another tab 
  onScroll?: (currentPath: string, direction: TScrollDirection, type?: TScrollType) => void; // Provide type only if direction is not 'None'
}

export default function ConsoleTabLayout(props: IProps) {
  const screenType = useAppScreenContext();
  const scrollable = useRef<HTMLDivElement>(null);
  const positionVertical = useRef<number>();
  const [scrollStuff, setscrollStuff] = useState<IScrollStuff>({
    ...defaultScrollStuff,
    locationPath: location.pathname
  });
  const [toolOpen, setToolOpen] = useState(false);
  const [mouseData, setMouseData] = useState<IScrollThresholds>(wheelThresholds);
  const [touchData, setTouchData] = useState<IScrollThresholds>(touchThresholds);
  const scrollTimeoutRef = useRef<number>();
  //--------------------------------------------------------------------------- Clear timeout
  useEffect(() => {
    return () => {
      scrollTimeoutRef.current && window.clearTimeout(scrollTimeoutRef.current);
    };
  }, []);
  //--------------------------------------------------------------------------- Listen to location changes
  useEffect(() => {
    setscrollStuff({
      ...defaultScrollStuff,
      locationPath: location.pathname
    });
    scrollTimeoutRef.current && window.clearTimeout(scrollTimeoutRef.current);
    scrollTimeoutRef.current = undefined;
  }, [location.pathname]);
  //--------------------------------------------------------------------------- Scroll to another tab
  useEffect(() => {
    if (!props.onScroll) return;
    //-------------------------------------------------------------------------
    const thresholds = scrollStuff.eventType == "Wheel" ? mouseData : touchData;
    if (scrollStuff.counter >= thresholds.max) {
      //-----------------------------------------------------------------------
      // Inform subscriber component that user is switching to another tab
      props.onScroll(scrollStuff.locationPath, scrollStuff.direction, "Switch");
    } else if (scrollStuff.counter >= thresholds.warn && !scrollStuff.warningIsSent) {
      //-----------------------------------------------------------------------
      // If user has scrolled enough to be informed that he's about to switch to another tab
      // 1) Clear current timeout
      scrollTimeoutRef.current && window.clearTimeout(scrollTimeoutRef.current);
      // 2) Set new timeout to reset scroll stuff if user would stop scrolling before reaching the max threshold
      scrollTimeoutRef.current = window.setTimeout(() => {
        const updatedScrollStuff: IScrollStuff = {
          ...defaultScrollStuff,
          locationPath: location.pathname
        };
        setscrollStuff(updatedScrollStuff);
        props.onScroll?.(scrollStuff.locationPath, "None");
      }, thresholds.resetTimeout);
      // 3) Inform subscriber component that user is going to switch to another tab
      props.onScroll(scrollStuff.locationPath, scrollStuff.direction, "Warn");
    } else {
      //-----------------------------------------------------------------------
      // Do nothing while user scrolls but did not reach any threshold yet, just clear current timeout
      scrollTimeoutRef.current && window.clearTimeout(scrollTimeoutRef.current);
    };
  }, [scrollStuff]);
  //---------------------------------------------------------------------------
  const onTouchStart = (e: React.TouchEvent) => {
    positionVertical.current = e.touches[0].clientY;
    //-------------------------------------------------------------------------
    // Reset scroll stuff if user just started to swipe/scroll or do something else using gestures
    scrollTimeoutRef.current && window.clearTimeout(scrollTimeoutRef.current);
    setscrollStuff({
      ...defaultScrollStuff,
      locationPath: location.pathname
    });
  };
  //---------------------------------------------------------------------------
  const onTouchMove = (e: React.TouchEvent) => {
    if (!scrollable.current) return;
    if (!positionVertical.current) return;
    //-------------------------------------------------------------------------
    // Listen to touch move
    const delta = positionVertical.current - e.changedTouches[0].clientY;
    const scrollMovement = getScrollMovement(delta, scrollable.current);
    if (!scrollMovement) return;
    //-------------------------------------------------------------------------
    // Update scroll stuff
    const updatedScrollStuff: IScrollStuff = {
      ...scrollStuff,
      eventType: "Touch",
      direction: scrollMovement.direction,
      counter: scrollMovement.counter
    };
    setscrollStuff(updatedScrollStuff);
  };
  //---------------------------------------------------------------------------
  const onWheel = (e: React.WheelEvent) => {
    if (e.ctrlKey) return; // Do not scroll if ctrl key is pressed (e.g. on google maps)
    if (!scrollable.current) return;
    const scrollMovement = getScrollMovement(e.deltaY, scrollable.current);
    if (!scrollMovement) return;
    //-------------------------------------------------------------------------
    // Check if scroll direction is the same
    const directionIsTheSame = scrollStuff.eventType == "Wheel" && scrollStuff.direction == scrollMovement.direction;
    // Update scroll stuff
    const updatedScrollStuff: IScrollStuff = {
      ...scrollStuff,
      eventType: "Wheel",
      direction: scrollMovement.direction,
      counter: directionIsTheSame ? scrollStuff.counter + scrollMovement.counter : 0
    };
    setscrollStuff(updatedScrollStuff);
  };
  //---------------------------------------------------------------------------
  const getScrollMovement = (
    deltaY: number,
    scrollableDiv: HTMLDivElement
  ): IScrollMovement | undefined => {
    const direction = deltaY > 0 ? "Down" : "Up";
    const isScrolledToTop = scrollableDiv.scrollTop == 0;
    const isScrolledToBottom = scrollableDiv.scrollHeight ?
      scrollableDiv.scrollHeight - scrollableDiv.scrollTop - scrollableDiv.clientHeight <= 0 :
      false;
    if (direction == "Down" && !isScrolledToBottom) return undefined;
    if (direction == "Up" && !isScrolledToTop) return undefined;
    //-------------------------------------------------------------------------
    const counter = deltaY < 0 ? deltaY * -1 : deltaY;
    return {
      direction: direction,
      counter: counter
    };
  };
  //---------------------------------------------------------------------------
  const onThresholdUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'wheel' | 'touch',
    valueType: 'warn' | 'max'
  ) => {
    const newValue = parseInt(e.target.value);
    let data: IScrollThresholds;
    let handler: React.Dispatch<React.SetStateAction<IScrollThresholds>>;
    switch (type) {
      case 'wheel':
        data = { ...mouseData };
        handler = setMouseData;
        break;
      case 'touch':
        data = { ...touchData };
        handler = setTouchData;
        break;
    };
    switch (valueType) {
      case 'warn':
        data.warn = newValue;
        break;
      case 'max':
        data.max = newValue;
        break;
    };
    handler(data);
  };
  //---------------------------------------------------------------------------
  let tool = undefined;
  if (props.onScroll) {
    tool = toolOpen ?
      <div style={{ padding: '1em', display: 'flex', flexFlow: 'column' }}>

        <div style={{ display: 'flex', flexFlow: 'column' }}>
          <label style={{ color: 'orange' }}>
            Mouse wheel
          </label>
          <div style={{ display: 'flex', gap: '1em' }}>
            <label>Warning</label>
            <input
              type="number"
              value={mouseData.warn}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onThresholdUpdate(e, 'wheel', 'warn')}
              style={{ width: '5em' }} />
            <label>Switch</label>
            <input
              type="number"
              value={mouseData.max}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onThresholdUpdate(e, 'wheel', 'max')}
              style={{ width: '5em' }} />
          </div>
        </div>

        <div style={{ display: 'flex', flexFlow: 'column' }}>
          <label style={{ color: 'orange' }}>
            Swipe gesture
          </label>
          <div style={{ display: 'flex', gap: '1em' }}>
            <label>Warning</label>
            <input
              type="number"
              value={touchData.warn}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onThresholdUpdate(e, 'touch', 'warn')}
              style={{ width: '5em' }} />
            <label>Switch</label>
            <input
              type="number"
              value={touchData.max}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onThresholdUpdate(e, 'touch', 'max')}
              style={{ width: '5em' }} />
          </div>
        </div>

        <button
          style={{ marginTop: '1em' }}
          onClick={() => setToolOpen(false)}
        >
          Hide
        </button>

      </div>
      :
      <button
        style={{ margin: '1em 1em 0 1em' }}
        onClick={() => setToolOpen(true)}
      >
        Show scroll thresholds
      </button>
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>

      <div
        className={styles.wrapper}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onWheelCapture={onWheel}
      >
        <WikiSpinner show={props.isLoading ? true : false} />
        <div className={CombineStyles(["frozen", styles.frozen])}>
        </div>

        <div
          ref={scrollable}
          className={styles.container}
        >
          {tool}

          {props.headerChildren &&
            <div className={CombineStyles([
              styles.header
            ])}>
              {props.headerChildren}
            </div>}

          <div
            className={CombineStyles([
              styles.content,
              props.headerChildren ? '' : styles.noHeader
            ])}>
            {props.children}
          </div>
        </div>

      </div>

      <div
        className={CombineStyles([
          styles.toolbar,
          screenType == ScreenType.Mobile ? styles.mobile : "",
          props.toolbar ? "" : styles.empty,
        ])}
      >
        {props.toolbar}
      </div>
    </React.Fragment>
  );
}



// //---------------------------------------------------------------------------
// function throttle(func: () => any, throttleMs: number) {
//   let isFirstCall = true;
//   let inThrottle = false;

//   return function () {
//     if (!inThrottle) {
//       inThrottle = true;
//       if (isFirstCall)
//         isFirstCall = false;
//       else {
//         isFirstCall = true;
//         func();
//       }
//       setTimeout(() => inThrottle = false, throttleMs);
//     };
//   };
// }
// //---------------------------------------------------------------------------
// const throttledFunc = useCallback(throttle(() => {
//   console.log("Up!")
//   props.onScrollUp && props.onScrollUp();
// }, 1500), []);