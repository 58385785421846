import { IDictionaryDraftAbstractUpdate, IDirectoryDraftAbstract, IDraftStateResult, IDirectorySummary } from "../../../../../context/directory-editor-context/IDirectoryDraftAbstract";
import { IDirectoryDraftItem } from "../../../../../context/directory-editor-context/IDirectoryDraftItem";
import { TLocker } from "../../../../common/locks/TLocker";
import { IWorkflowLog } from "../../../common/workflows/IWorkflowLog";
import { ICustomDictionaryOriginal } from "./ICustomDictionaryOriginal";
import { DictionaryDraftItem } from "./IDictionaryDraftItem";

export interface IDictionaryDraft<TItem extends IDirectoryDraftItem> extends IDirectoryDraftAbstract {
  dictionaryId: string;
  locale: string; // English in case of dictionary editor (as opposed to translator)
  name?: string;
  description?: string;
  isHierarchical: boolean;
  isSortedManually: boolean;
  rootIsLocked: boolean;
  items: TItem[];
  original?: ICustomDictionaryOriginal;
}

export interface ICustomDictionaryDraft extends IDictionaryDraft<DictionaryDraftItem> {
  documentId: string; // Temporary Id for the draft
}

export class CustomDictionaryDraft implements ICustomDictionaryDraft {
  documentId!: string;
  id!: string;
  dictionaryId!: string;
  locale!: string;
  name?: string;
  description?: string;
  isHierarchical!: boolean;
  isSortedManually!: boolean;
  rootIsLocked!: boolean;
  items!: DictionaryDraftItem[];
  //datetimeSaved?: string;
  log?: IWorkflowLog;
  locker?: TLocker;
  summary!: IDirectorySummary;
  original?: ICustomDictionaryOriginal;
  //---------------------------------------------------------------------------
  initialize(source: ICustomDictionaryDraftInitial): CustomDictionaryDraft {
    const draft = new CustomDictionaryDraft();
    Object.assign(draft, source);
    draft.id = source.dictionaryId;
    //-------------------------------------------------------------------------
    draft.items = source.items.map(item => new DictionaryDraftItem(item));
    //-------------------------------------------------------------------------
    return draft;
  };
  //---------------------------------------------------------------------------
  getUpdated(update: ICustomDictionaryDraftUpdate): CustomDictionaryDraft {
    //console.log(update);
    const updatedDraft = new CustomDictionaryDraft();
    Object.assign(updatedDraft, this, update);
    //console.log(updatedDraft);
    if (update.id) updatedDraft.dictionaryId = update.id;
    return updatedDraft;
  };
  //---------------------------------------------------------------------------
  clone(): IDirectoryDraftAbstract {
    throw new Error("Method not implemented.");
  };
  //---------------------------------------------------------------------------
  checkChangesAndValidate(initial: IDirectoryDraftAbstract): IDraftStateResult {
    //console.log('dictionary draft: checkChangesAndValidate');
    let isUpdatedInSession = false;
    let isUpdated = false;
    let isValid = true;
    //-------------------------------------------------------------------------
    if (this.id !== initial.id || this.name !== initial.name || this.description !== initial.description) {
      isUpdatedInSession = true;
    } else if (this.isHierarchical !== initial.isHierarchical || this.isSortedManually !== initial.isSortedManually) {
      isUpdatedInSession = true;
    };
    //-------------------------------------------------------------------------
    if (this.items.length !== initial.items?.length) {
      isUpdatedInSession = true;
    };
    //-------------------------------------------------------------------------
    //console.log(this, this.original);
    if (!this.original) {
      isUpdated = true;
    } else if (this.dictionaryId !== this.original.dictionaryId || this.name !== this.original.name || this.description !== this.original.description) {
      isUpdated = true;
    } else if (this.isHierarchical !== this.original.isHierarchical || this.isSortedManually !== this.original.isSortedManually || this.rootIsLocked !== this.original.rootIsLocked) {
      isUpdated = true;
    };
    //-------------------------------------------------------------------------
    if (!this.original) {
      //console.log('dictionary draft: no original');
      isUpdated = true;
    } else if (this.items.length !== this.original?.items?.length) {
      //console.log('dictionary draft: items count is different');
      isUpdated = true;
    };
    //-------------------------------------------------------------------------
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      const initialItem = initial.items.find(initialItem => initialItem.id === item.id);
      const draftStateResult: IDraftStateResult = item.checkChangesAndValidate(initialItem);
      isUpdatedInSession = isUpdatedInSession || draftStateResult.isUpdatedInSession;
      isUpdated = isUpdated || draftStateResult.isUpdated;
      isValid = isValid && draftStateResult.isValid;
    };
    //-------------------------------------------------------------------------
    if (this.items.length < 2) {
      isValid = false;
    };
    //-------------------------------------------------------------------------
    return {
      isUpdatedInSession,
      isUpdated,
      isValid
    };
  };
}

export interface ICustomDictionaryDraftInitial {
  documentId: string;
  dictionaryId: string;
  locale: string;
  name?: string;
  description?: string;
  isHierarchical: boolean;
  isSortedManually: boolean;
  rootIsLocked: boolean;
  items: DictionaryDraftItem[];
  original?: ICustomDictionaryOriginal;
};

export interface ICustomDictionaryDraftUpdate extends IDictionaryDraftAbstractUpdate {
  dictionaryId?: string;
  name?: string;
  description?: string;
  isHierarchical?: boolean;
  isSortedManually?: boolean;
  rootIsLocked?: boolean;
  items?: DictionaryDraftItem[];
  locker?: undefined;
}