import React, { useEffect, useRef, useState } from 'react';
import CombineStyles from '../../../../../utils/combineStyles';
import ButtonLink from '../../../../common/button-link/ButtonLink';
import { TUiButton } from '../../../../common/button/TUiButton';
import InputContainer, { IInput } from '../../../../common/input/input-container/InputContainer';
import { TUiMenuOption } from '../../../../common/menu/menu-content/menu-option/TUiMenuOption';
import { TUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import PopupDialog from '../../../../common/popup-v2/popup-dialog/PopupDialog';
import BusinessTagNodeSelector from '../business-tag-node-selector/BusinessTagNodeSelector';
import styles from './BusinessTagParentEditor.module.css';
import { IBusinessTagsDictionaryDraftItem } from '../../../administrator-console/dictionaries/business-tags-editor/IBusinessTagsDictionary';
import FormOptions from '../../../../common/form-options-bar/FormOptions';

const tmpButtonEditParent: TUiButton = {
  id: "",
  iconFile: null,
  caption: { en: "Change..." },
  hint: null,
  disabled: false,
  visible: true,
  canHideCaption: false
}
const tmpOptionOk: TUiMenuOption = {
  id: 'optionOk',
  directoryId: '',
  elementType: '',
  visible: true,
  disabled: false,
  canHideCaption: false,
  index: 0,
  priorityLevel: 0,
  caption: { en: 'Ok' },
  iconFile: "check",
  hint: undefined,
  isDefault: true,
  action: undefined
}
const tmpOptionCancel: TUiMenuOption = {
  id: 'optionCancel',
  directoryId: '',
  elementType: '',
  visible: true,
  disabled: false,
  canHideCaption: false,
  index: 0,
  priorityLevel: 0,
  caption: { en: 'Cancel' },
  iconFile: "close",
  hint: undefined,
  isDefault: false,
  action: undefined
}
const tmpOptionsMenu: TUiMenuContent = {
  id: '',
  directoryId: '',
  elementType: '',
  visible: true,
  disabled: false,
  options: [tmpOptionCancel, tmpOptionOk],
  optionGroups: []
}

export interface IBusinessTagParentInput extends IInput {
}

interface IProps {
  ui: IBusinessTagParentInput;
  data?: IBusinessTagsDictionaryDraftItem; // current parent node
  rootNodes: IBusinessTagsDictionaryDraftItem[];
  currentItemPath?: string; // current node what we selecting parent for (it should be unavailable to select)
  onConfirm?: (selectedNode: IBusinessTagsDictionaryDraftItem) => void;
}

export default function BusinessTagParentEditor(props: IProps) {
  //const { adminConsoleContext } = useContext(AdminConsoleContext);
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IBusinessTagsDictionaryDraftItem>();
  const initialSelectedItemPath = useRef<string>();
  //---------------------------------------------------------------------------
  useEffect(() => {
    setSelectedItem(props.data);
    initialSelectedItemPath.current = props.data?.path;
  }, [props.data?.path]);
  //---------------------------------------------------------------------------
  const onEdit = () => {
    setPopupOpen(true);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (selectedNodePath: string) => {
    // Parent node path cannot be empty - because we can't create root items
    // Find node by its path and set state
    const selectedNodeParts = selectedNodePath.split('.');
    var nodes = props.rootNodes;
    var targetNode: IBusinessTagsDictionaryDraftItem | undefined;
    selectedNodeParts.forEach(part => {
      console.log(part)
      targetNode = nodes?.find(node => node.id === part);
      if (targetNode && targetNode.items) {
        console.log(targetNode)
        nodes = targetNode.items;
      } else {
        console.error(`That's weird... no node in business tags tree with id=${part}`);
      }
    });
    console.log(targetNode)
    setSelectedItem(targetNode);
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    setPopupOpen(false);
    selectedItem && props.onConfirm && props.onConfirm(selectedItem);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "optionOk":
        onConfirm();
        break;
      //-----------------------------------------------------------------------
      case "optionCancel":
        setPopupOpen(false);
        break;
    }
  };
  //---------------------------------------------------------------------------
  var updatedOptions: TUiMenuOption[] = [];
  tmpOptionsMenu.options.forEach(option => {
    switch (option.id) {
      case "optionOk":
        updatedOptions.push({
          ...option,
          disabled: !selectedItem?.path || (selectedItem.path == initialSelectedItemPath.current)
        });
        break;
      //-----------------------------------------------------------------------
      case "optionCancel":
        updatedOptions.push(option);
        break;
    }
  });
  const formOptions =
    <FormOptions
      ui={{ ...tmpOptionsMenu, options: updatedOptions }}
      applyMobileStyle={false}
      optionIdVisibleOnMobile='optionOk'
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <InputContainer
      ui={props.ui}
      hideCaption={false}
      hideHint={true}
      isValid={true}
    >
      <div className={styles.container}>
        <div className={styles.caption}>
          {selectedItem ?
            <React.Fragment>
              <span className={styles.value}>
                {`{{${selectedItem?.id}}}`}
              </span>
              {selectedItem?.name?.en &&
                <React.Fragment>
                  <span className={styles.system}>
                    ,&nbsp;English:&nbsp;
                  </span>
                  <span className={styles.value}>
                    {selectedItem?.name?.en}
                  </span>
                </React.Fragment>}
            </React.Fragment>
            :
            <span className={styles.value}>
              Root
            </span>
          }
        </div>
        {props.onConfirm &&
          <div
            className={CombineStyles([styles.option])}
            onClick={onEdit}
          >
            <ButtonLink
              ui={tmpButtonEditParent}
              hideHint={true}
              onClick={() => { }}
            />
          </div>}
      </div>

      {popupOpen &&
        <PopupDialog
          id=''
          header={'Select parent tag'}
          toolbarContent={formOptions}
          isModal={true}
          isDraggable={false}
          onClose={() => setPopupOpen(false)}
        >
          <div className={styles.popupContainer}>
            <div className={styles.tree}>
              <BusinessTagNodeSelector
                selectedNodePath={selectedItem?.path}
                currentItemPath={props.currentItemPath}
                rootNodes={props.rootNodes}
                onSelect={onUpdate}
              />
            </div>
          </div>
        </PopupDialog>}
    </InputContainer >
  );
}