import { useContext, useRef, useState } from 'react';
import CombineStyles from '../../../../../../../utils/combineStyles';
import { IUiDictionaryItem } from '../../../../../../common/dictionaries/IUiDictionaryItem';
import { IFilterLocales } from '../LocaleSelector';
import styles from './LocalesListItem.module.css';
import { AppUiContextStore } from '../../../../../../../context/app-ui-context/AppUiContextProvider';
import GetName from '../../../../../../common/functions/GetName';
import LocalesList from '../locales-list/LocalesList';
import ExpandButton from '../../../../../../business-console/business-tags-selector-popup/tag-node/tag-node-header/expand-button/ExpandButton';

interface IProps {
  selectedLocales?: string[];
  usedLocales?: string[];
  options: IUiDictionaryItem[];
  showNativeName?: boolean;
  filter?: IFilterLocales;
  onSelect: (selectedCulture: IUiDictionaryItem, confirmed: boolean) => void;
}

export default function LocalesListItem(props: IProps) {
  const rootItem = props.options[0];
  if (!rootItem) return null;
  //---------------------------------------------------------------------------
  const { appUiContext } = useContext(AppUiContextStore);
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef({} as HTMLDivElement);
  const isSelected = props.selectedLocales?.includes(rootItem.id);
  const isUsed = props.usedLocales?.includes(rootItem.id);
  const isAvailable = !isUsed && !isSelected;
  const showNativeName = props.showNativeName == undefined ? false : props.showNativeName;
  const isExpandable = props.options.length > 1;
  const childrenLocales = props.options.slice(1); // all items besides first one
  //---------------------------------------------------------------------------
  /*  useEffect(() => {
     // it helps when the last item in the list is expanded,
     // but makes things worse if it's an item in the middle
     if (containerRef.current && expanded)
       containerRef.current.scrollIntoView({ behavior: 'smooth' });
   }, [containerRef.current, expanded]); */
  //---------------------------------------------------------------------------
  const onToggle = () => {
    if (isExpandable)
      setExpanded(!expanded);
  };
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (isAvailable)
      props.onSelect(rootItem, false);
  };
  //---------------------------------------------------------------------------
  const onSelectConfirmed = () => {
    if (isAvailable)
      props.onSelect(rootItem, true);
  };
  //---------------------------------------------------------------------------
  let isSupported = false;
  if (showNativeName) {
    // If it's a list for user's preferred languages, indicate if the locale is supported
    const dictionaryItemSupported = appUiContext.dictionaries?.supportedLocales?.find(di => di.id == rootItem.id);
    isSupported = !!dictionaryItemSupported;
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.wrapper}>

      <div
        className={CombineStyles([
          styles.container,
          isAvailable ? styles.available : "",
          isUsed ? styles.used : "",
          isSelected ? styles.selected : ""
        ])}
        onDoubleClick={onSelectConfirmed}
      >

        <div
          className={styles.caption}
          onClick={onToggle}
        >
          <span style={{
            color: isSupported ?
              isSelected ? 'var(--clr_main_base)' : 'var(--clr_accent_main)'
              : undefined
          }}>
            <span>
              {GetName(appUiContext, rootItem.id, rootItem.name)}
            </span>
            {props.showNativeName &&
              <span>
                <span> - </span>
                {GetName(appUiContext, rootItem.id, rootItem.name, 'native')}
              </span>}
          </span>
          {isExpandable &&
            <ExpandButton widthEm={1} expanded={expanded} />}
        </div>

        <div
          className={styles.option}
          onClick={onSelect}
        >
          Select
        </div>

      </div>

      {expanded &&
        <div ref={containerRef} className={styles.items}>
          <LocalesList
            selectedLocales={props.selectedLocales}
            usedLocales={props.usedLocales}
            options={childrenLocales}
            showNativeNames={props.showNativeName}
            onSelect={props.onSelect}
          />
        </div>}

    </div>
  );
}