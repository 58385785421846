import { IUiInputNumber } from "../../../../../common/input-v2/input-number/InputNumber";

export interface IUiDictionaryDraftEditFormAdRank {
  inputPpv: IUiInputNumber;
  inputPpe: IUiInputNumber;
}

export const tmpUiEditFormAdRank: IUiDictionaryDraftEditFormAdRank = {
  inputPpv: {
    id: "AdRankEditForm_InputPpv",
    directoryId: '',
    elementType: "",
    caption: { en: "Points Per View" },
    hint: { en: "Number of points to charge per view (PPV)" },
    placeholder: { en: "Type a number here..." },
    defaultValue: 0,
    minValue: 0,
    maxValue: 50,
    disabled: false,
    visible: true,
    validation: {
      required: {
        value: 1,
        message: {
          id: "DictionaryNodeEditForm_Ppv_ValidationMessage_Required",
          hint: "Value cannot be empty"
        }
      }
    }
  },
  inputPpe: {
    id: "AdRankEditForm_InputPpe",
    directoryId: '',
    elementType: "",
    caption: { en: "Points Per Engagement" },
    hint: { en: "Number of points to charge per engagement (PPE)" },
    placeholder: { en: "Type a number here..." },
    defaultValue: 0,
    minValue: 0,
    maxValue: 50,
    disabled: false,
    visible: true,
    validation: {
      required: {
        value: 1,
        message: {
          id: "DictionaryNodeEditForm_Ppe_ValidationMessage_Required",
          hint: "Value cannot be empty"
        }
      }
    }
  }
}