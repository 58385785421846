import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathDeleteDictionaryTranslationDraft, pathGetDictionaryTranslationDraft } from "../../../../../utils/apiPathConstant";
import { apiDeletePrivate, checkResponse } from "../../../../../utils/api";
import { stringFormatter } from "../../../../../utils/common";
import { IDictionaryTranslationDraft } from "./DictionaryTranslatorV1";

export default function deleteDictionaryTranslationDraft(
  msalInstanse: IPublicClientApplication,
  dictionaryId: string,
  localeId: string,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<IDictionaryTranslationDraft> {
  return new Promise((resolve, reject) => {
    const uri = `${apiBasePath}${stringFormatter(pathDeleteDictionaryTranslationDraft, [dictionaryId, localeId])}`;
    //-------------------------------------------------------------------------
    apiDeletePrivate(
      msalInstanse,
      authConfig,
      uri,
      abortSignal
    ).then(response => {
      checkResponse(response, "deleteDictionaryTranslationDraft");
      resolve(response?.content as IDictionaryTranslationDraft);
    }).catch(error => 
      reject(error)
    );
  });
}