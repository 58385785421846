import React, { ChangeEvent, useContext, useEffect, useRef } from 'react';
import CombineStyles from '../../../../utils/combineStyles';
import GetPlaceholder from '../../functions/GetPlaceholder';
import { IUiInputText } from '../input-text/InputText';
import styles from '../Input.module.css';
import Icon from '../../icon/Icon';
import { EInputValueType, TEnterKeyHint, THtmlInputTypeAttribute, TInputMode } from '../IUiInput';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';

interface IProps {
  ui: IUiInputText;
  data?: string;
  valueType?: EInputValueType; // Ignored in case if multiline=true
  enterKeyHint?: TEnterKeyHint;
  linesCount?: number;
  isValid?: boolean;
  isFocused?: boolean;
  onUpdate?: (updatedValue: string) => void;
  wrapperRef?: React.RefObject<HTMLDivElement>;
}

export default function RawInputText(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const inputRef = useRef<HTMLInputElement>(null);
  const multiLineInputRef = useRef<HTMLTextAreaElement>(null);
  const firstRender = useRef(true); // Do we need this?
  //---------------------------------------------------------------------------
  const valueType = props.valueType == undefined ? EInputValueType.Text : props.valueType;
  const enterKeyHint = props.enterKeyHint == undefined ? "next" : props.enterKeyHint;
  const value = props.data ? props.data : (props.ui.defaultValue ? props.ui.defaultValue : "");
  const isValid = props.isValid == undefined ? true : props.isValid;
  const placeholder = GetPlaceholder(appUiContext, props.ui.id, props.ui.placeholder);
  const showClearOption = !props.ui.disabled && !props.ui.readonly && !props.linesCount && value;
  const isFocused = props.isFocused == undefined ? false : props.isFocused;
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (inputRef.current && isFocused && firstRender.current && valueType != EInputValueType.Email) {
      setFocus(inputRef);
    };
  }, [inputRef.current]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (multiLineInputRef.current && isFocused && firstRender.current) {
      setFocus(multiLineInputRef);
    };
  }, [multiLineInputRef.current]);
  //---------------------------------------------------------------------------
  const setFocus = (inputRef: React.RefObject<HTMLInputElement> | React.RefObject<HTMLTextAreaElement>) => {
    if (inputRef.current) {
      const endOfTheString = inputRef.current.value ? inputRef.current.value.length : 0;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(endOfTheString, endOfTheString);
    };
    firstRender.current = false;
  };
  //---------------------------------------------------------------------------
  const onUpdate = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    props.onUpdate && props.onUpdate(e.target.value);
  };
  //---------------------------------------------------------------------------
  const onClear = () => {
    props.onUpdate && props.onUpdate("");
    setFocus(inputRef);
  };
  //--------------------------------------------------------------------------- Handle input value type
  var inputType: THtmlInputTypeAttribute = 'text';
  var inputMode: TInputMode = "text";
  switch (valueType) {
    case EInputValueType.Email:
      inputType = 'email';
      inputMode = 'email';
      break;
    case EInputValueType.Date:
      inputType = 'date'
      break;
    case EInputValueType.DateTime:
      inputType = 'datetime-local'
      break;
  }
  //---------------------------------------------------------------------------
  return (
    <div ref={props.wrapperRef} className={styles.container}>
      {props.linesCount ?
        <textarea
          ref={multiLineInputRef}
          title={props.data ? props.data : placeholder}
          className={CombineStyles([
            styles.textarea,
            styles.stretch,
            props.ui.disabled ? styles.disabled : "",
            props.ui.readonly ? styles.readonly : "",
            isValid ? "" : styles.invalid
          ])}
          inputMode='text'
          rows={props.linesCount}
          value={value}
          placeholder={placeholder}
          disabled={props.ui.disabled || props.ui.readonly}
          onChange={onUpdate}
        />
        :
        <input
          ref={inputRef}
          title={props.data ? props.data : placeholder}
          type={inputType}
          inputMode={inputMode}
          enterKeyHint={enterKeyHint}
          className={CombineStyles([
            styles.input,
            styles.stretch,
            props.ui.disabled ? styles.disabled : "",
            props.ui.readonly ? styles.readonly : "",
            isValid ? "" : styles.invalid
          ])}
          style={{ paddingRight: showClearOption ? `var(--input-paddingRight)` : undefined }}
          value={value}
          placeholder={placeholder}
          disabled={props.ui.disabled || props.ui.readonly}
          onChange={onUpdate}
        />}

      {showClearOption &&
        <div
          className={CombineStyles([
            styles.clearButton,
            isValid ? "" : styles.invalid
          ])}
          onClick={onClear}
        >
          <Icon imageName="close" />
        </div>}

    </div>
  );
}