import { useContext, useEffect, useRef, useState } from "react";
import DictionaryNodeEditForm from "../../common/dictionary-node-edit-form/DictionaryNodeEditForm";
import { DictionaryDraftItemRejectionReasons, EContentUnitType } from "../DictionaryDraftItemRejectionReasons";
import { DictionaryEditorDraftItem } from "../../common/models/DictionaryEditorDraftItem";
import { IUiInputDropdown } from "../../../../../common/input-v2/input-dropdown/InputDropdown";
import InputDropdownMultiselect from "../../../../../common/input-v2/input-dropdown/InputDropdownMultiselect";
import { IDictionaryItemStatus, IUiDictionaryItem } from "../../../../../common/dictionaries/IUiDictionaryItem";
import DictionaryItemStatus from "../../common/dictionary-item-status/DictionaryItemStatus";
import { DraftItemStateEnum } from "../../../../common/dictionary-draft-models/DraftItemStateEnum";
import { EditFormState } from "../../../../../common/edit-form/IEditFormState";
import { AppUiContextStore } from "../../../../../../context/app-ui-context/AppUiContextProvider";
import InputCheckBox, { IUiInputCheckbox } from "../../../../../common/input-v2/input-checkbox/InputCheckBox";

interface IValidityState {
  baseData?: boolean;
  objectTypes?: boolean;
}

interface IProps {
  data?: DictionaryDraftItemRejectionReasons;
  editModeIsEnabled?: boolean;
  onUpdate: (updatedDraftItem: DictionaryDraftItemRejectionReasons, oldId?: string) => void;
  onClose: () => void;
}

export default function EditFormRejectionReason(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [state, setState] = useState<EditFormState<DictionaryDraftItemRejectionReasons>>();
  const dataValidity = useRef<IValidityState>({ baseData: undefined, objectTypes: undefined }); // To control if all the inputs are valid
  const isApplicableAdLanguageSet = state?.object?.objectTypes.includes("AdLanguageSet");
  const isApplicableAdModule = state?.object?.objectTypes.includes("AdModule");
  const isApplicableParagraph = state?.object?.objectTypes.includes("ContentParagraph");
  const adRanksAvailable = isApplicableAdLanguageSet && state?.object?.objectTypes && state?.object?.objectTypes.length == 1;
  //--------------------------------------------------------------------------- State
  useEffect(() => {
    const state = new EditFormState<DictionaryDraftItemRejectionReasons>({
      object: props.data,
      isNew: props.data ? false : true,
      isValid: false
    });
    setState(state);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const updateValidity = (isValid: boolean) => {
    dataValidity.current.baseData = isValid;
  };
  //---------------------------------------------------------------------------
  const onBaseDataUpdate = (updatedData: DictionaryEditorDraftItem, isValid: boolean) => {
    if (state?.object) {
      // Existing item is being updated
      dataValidity.current.baseData = isValid;
      const updatedItem = new DictionaryDraftItemRejectionReasons(state.object, {
        id: updatedData.id,
        name: updatedData.name,
        description: updatedData.description
      });
      const updatedState = new EditFormState<DictionaryDraftItemRejectionReasons>(state, {
        object: updatedItem,
        isValid: isValid && dataValidity.current.objectTypes
      });
      setState(updatedState);
    } else {
      //-----------------------------------------------------------------------
      // We are creating a new item
      const updatedItem = new DictionaryDraftItemRejectionReasons({
        id: updatedData.id,
        name: updatedData.name,
        description: updatedData.description,
        objectTypes: [],
        draftState: DraftItemStateEnum.IsNew
      });
      const updatedState = new EditFormState<DictionaryDraftItemRejectionReasons>({
        object: updatedItem,
        isNew: true,
        isValid: isValid
      });
      setState(updatedState);
    };
  };
  //---------------------------------------------------------------------------
  const onContentUnitTypesUpdate = (options?: string[], isValid?: boolean) => {
    if (!state?.object) return console.error("State is not initialized");
    dataValidity.current.objectTypes = isValid;
    const updatedItem = new DictionaryDraftItemRejectionReasons(state.object, {
      objectTypes: options?.map(option => option as EContentUnitType) ?? []
    });
    const updatedState = new EditFormState<DictionaryDraftItemRejectionReasons>(state, {
      object: updatedItem,
      isValid: isValid && dataValidity.current.baseData
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onAdRanksUpdate = (options?: string[], isValid?: boolean) => {
    if (!state?.object) return console.error("State is not initialized");
    const updatedItem = new DictionaryDraftItemRejectionReasons(state.object, {
      adRanks: options
    });
    const updatedState = new EditFormState<DictionaryDraftItemRejectionReasons>(state, {
      object: updatedItem,
      isValid: isValid && dataValidity.current.baseData
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onContainsImageUpdate = (checked: boolean) => {
    if (!state?.object) return console.error("State is not initialized");
    const updatedItem = new DictionaryDraftItemRejectionReasons(state.object, {
      containsImage: checked
    });
    const updatedState = new EditFormState<DictionaryDraftItemRejectionReasons>(state, {
      object: updatedItem,
      isValid: dataValidity.current.baseData
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onContainsTextUpdate = (checked: boolean) => {
    if (!state?.object) return console.error("State is not initialized");
    const updatedItem = new DictionaryDraftItemRejectionReasons(state.object, {
      containsText: checked
    });
    const updatedState = new EditFormState<DictionaryDraftItemRejectionReasons>(state, {
      object: updatedItem,
      isValid: dataValidity.current.baseData
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onStatusUpdate = (updatedStatus: IDictionaryItemStatus) => {
    if (!state?.object) return console.error("State is not initialized");
    const updatedItem = new DictionaryDraftItemRejectionReasons(state.object, {
      status: updatedStatus,
      deleted: !updatedStatus.isAvailableToEndUsers && !updatedStatus.isAvailableToStaffUsers
    });
    const updatedState = new EditFormState<DictionaryDraftItemRejectionReasons>(state, {
      object: updatedItem,
      isValid: dataValidity.current.objectTypes && dataValidity.current.baseData
    });
    setState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    if (!state) return console.error("State is not set");
    if (!state.object) return console.error("State object is not set");
    if (state.isValid) {
      // Check if item identifier is changed, is so, pass the old id
      let oldId = state.initialState?.id;
      props.onUpdate(
        state.object,
        oldId === state.object.id ? undefined : oldId);
    } else {
      console.error("Cannot update: data is invalid");
    }
  };
  //---------------------------------------------------------------------------
  return (
    <DictionaryNodeEditForm
      data={state}
      isReadonly={!props.editModeIsEnabled}
      onInitialValidate={updateValidity}
      onUpdate={onBaseDataUpdate}
      onConfirm={onConfirm}
      onClose={props.onClose}
      newUi={true}
    >
      <InputDropdownMultiselect
        ui={{ ...tmpUiInputDropdownContentUnitType, disabled: !props.editModeIsEnabled }}
        data={state?.object?.objectTypes}
        options={tmpObjectTypes}
        sortOptions={false}
        hideDescription={true}
        onInitialValidate={(isValid: boolean) => dataValidity.current.objectTypes = isValid}
        onUpdate={onContentUnitTypesUpdate}
      />
      <InputDropdownMultiselect
        ui={{
          ...tmpUiInputDropdownAdRank,
          disabled: !props.editModeIsEnabled || !adRanksAvailable
        }}
        data={state?.object?.adRanks}
        options={appUiContext.dictionaries?.adRanks}
        sortOptions={false}
        hideDescription={true}
        onUpdate={onAdRanksUpdate}
      />
      <InputCheckBox
        ui={{
          ...tmpUiInputContainsImage,
          disabled:
            !props.editModeIsEnabled ||
            !(isApplicableAdLanguageSet || isApplicableAdModule || isApplicableParagraph)
        }}
        data={state?.object?.containsImage}
        onChange={onContainsImageUpdate}
      />
      <InputCheckBox
        ui={{
          ...tmpUiInputContainsText,
          disabled:
            !props.editModeIsEnabled ||
            !(isApplicableAdLanguageSet || isApplicableAdModule || isApplicableParagraph)
        }}
        data={state?.object?.containsText}
        onChange={onContainsTextUpdate}
      />
      <DictionaryItemStatus
        data={state?.object?.status}
        isReadOnly={!props.editModeIsEnabled}
        onUpdate={onStatusUpdate}
      />
    </DictionaryNodeEditForm>
  );
}

const tmpUiInputDropdownContentUnitType: IUiInputDropdown = {
  id: "InputDropdownContentUnitType",
  directoryId: "",
  elementType: "",
  dictionaryId: "",
  caption: { en: "Unit type" },
  hint: { en: "Content unit types where rejection reason is applicable" },
  placeholder: { en: "Select items..." },
  validation: {
    required: {
      value: 1,
      message: { en: "Select at least one item" }
    }
  }
}
const tmpUiInputDropdownAdRank: IUiInputDropdown = {
  id: "InputDropdownAdRank",
  directoryId: "",
  elementType: "",
  dictionaryId: "",
  caption: { en: "Ad Ranks" },
  hint: { en: "Ad Ranks rejection reason is applicable to" },
  placeholder: { en: "Select items..." },
  validation: undefined
}
const tmpUiInputContainsImage: IUiInputCheckbox = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: { en: "Image" },
  hint: { en: "Content unit must contain image" },
}
const tmpUiInputContainsText: IUiInputCheckbox = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: { en: "Text" },
  hint: { en: "Content unit must contain text" },
}

const tmpObjectTypes: IUiDictionaryItem[] = [{
  id: "AdLanguageSet",
  index: 0,
  name: { en: "Ad Language Set" },
  description: { en: "Ad Language Set" },
}, {
  id: "AdModule",
  index: 0,
  name: { en: "Ad Module" },
  description: { en: "Ad Module" },
}, {
  id: "ContentText",
  index: 0,
  name: { en: "Text" },
  description: { en: "Text" },
}, {
  id: "ContentImage",
  index: 0,
  name: { en: "Image" },
  description: { en: "Image" },
}, {
  id: "Address",
  index: 0,
  name: { en: "Address" },
  description: { en: "Address" },
}, {
  id: "Contact",
  index: 0,
  name: { en: "Contact" },
  description: { en: "Contact" },
}]