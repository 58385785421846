import { useContext } from "react";
import { NavigateOptions, useLocation, useNavigate } from "react-router";
import { AppUiContextStore } from "../context/app-ui-context/AppUiContextProvider";
import { NotificationContext } from "../context/notification-context/NotificationContextProvider";

export default function useNavigateWithContext() {
  // Custom hook to navigate using current context
  // E.g. while navigating we need to keep lang parameter if user specified query string in URL manually
  const navigate = useNavigate();
  const { appUiContext } = useContext(AppUiContextStore);
  const { notificationContext, notificationContextDispatch } = useContext(NotificationContext);
  const location = useLocation();
  //---------------------------------------------------------------------------
  function customNavigate(path: string, navigateOptions?: NavigateOptions, ignoreNotificationContext?: boolean) {
    // Check if current location has unsaved changes
    //console.log("useNavigateWithContext: customNavigate", notificationContext);
    if (!ignoreNotificationContext && notificationContext?.unsavedChanges && notificationContext.unsavedChanges.pathUnsavedChanges) {
      if (location.pathname === notificationContext.unsavedChanges.pathUnsavedChanges) {
        // Update notification context so confirmation dialog is displayed
        // We aren't navigating anywhere yet
        notificationContextDispatch({ type: "UnsavedChangesShowNotification", pathDestination: path });
        return;
      };
    };
    //-------------------------------------------------------------------------
    // If path already contains "/", strip it
    if (path.substring(0, 1) === "/") {
      path = path.substring(1);
    }
    //-------------------------------------------------------------------------
    // Check whether current ui context says we need to preserve 'lang' parameter
    const localeQueryString = appUiContext.locale.queryString ? appUiContext.locale.queryString : ""; // e.g. "?lang=uk"
    //-------------------------------------------------------------------------
    // Check whether path contains hash
    let hash: string | undefined = undefined;
    let hashParts = path.split('#');
    //console.log("hashParts:", hashParts);
    if (hashParts.length == 2) {
      path = hashParts[0];
      hash = hashParts[1];
    }
    //-------------------------------------------------------------------------
    // Check whether path contains query string as well and handle it
    const pathParts = path.split('?');
    const pathSearchParamsAsString = path.split('?')[1];
    var newQueryString = "";
    if (pathSearchParamsAsString || localeQueryString) {
      newQueryString += "?"
    };
    if (pathSearchParamsAsString) {
      newQueryString += pathSearchParamsAsString;
    };
    if (localeQueryString) {
      newQueryString += (pathSearchParamsAsString ? "&" : '') + localeQueryString.slice(1);
    };
    //-------------------------------------------------------------------------
    const newPath = pathSearchParamsAsString ? pathParts[0] : path;
    //-------------------------------------------------------------------------
    let pathToNavigate = `/${newPath}${newQueryString}`;
    if (hash) {
      pathToNavigate += `#${hash}`;
    }
    console.log("NavigateTo: " + pathToNavigate);
    navigate(pathToNavigate, navigateOptions);
  }
  //---------------------------------------------------------------------------
  return customNavigate;
}

