import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../context/app-auth-context/AppAuthContext";
import { apiGetPrivate, checkResponse } from "../../../utils/api";
import { apiBasePath, pathGetNewBusiness } from "../../../utils/apiPathConstant";
import { ClassCustomerBusiness, ICustomerBusiness } from "../business-editor/ICustomerBusiness";
import { stringFormatter } from "../../../utils/common";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  localeId: string;
}

export async function getNewBusinessAsync(props: IProps): Promise<ClassCustomerBusiness> {
  if (!props.authConfig) throw new Error("getNewBusinessAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  let response = await apiGetPrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${stringFormatter(pathGetNewBusiness, [props.localeId])}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getNewBusinessAsync", props.abortSignal?.aborted);
  let business = new ClassCustomerBusiness(response?.content as ICustomerBusiness);
  return business;
};