import { useContext, useEffect, useRef, useState } from "react";
import DictionaryEditorWrapper from "../../../common/dictionary-editor-wrapper/DictionaryEditorWrapper";
import useBeforeUnload from "../../../../../hooks/useBeforeUnload";
import { IDictionaryDraftFilter } from "../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter";
import { ILockable, TLocker } from "../../../../common/locks/TLocker";
import getDictionaryDraft from "../common/functions/getDictionaryDraft";
import { useMsal } from "@azure/msal-react";
import { AppAuthContextStore } from "../../../../../context/app-auth-context/AppAuthContext";
import { pathAdminConsole, pathAdministratorConsole } from "../../../AdminConsole";
import { pathDictionaries } from "../../AdministratorConsole";
import { DraftItemStateEnum } from "../../../common/dictionary-draft-models/DraftItemStateEnum";
import { dictonaryDraftStatesFullDetails } from "../common/settings/dictonaryDraftStatesFullDetails";
import updateDictionaryDraft from "../common/functions/updateDictionaryDraft";
import deleteDictionaryDraft from "../common/functions/deleteDictionaryDraft";
import RegionSelector from "./region-selector/RegionSelector";
import { IRegion } from "../locales-editor/getCultures";
import getRegions from "./getRegions";
import publishDictionaryDraft from "../common/functions/publishDictionaryDraft";
import { RegionsDictionaryDraft, RegionsDictionaryDraftItem } from "./regions-editor-node/RegionsEditorNode";
import { INewRegionData, IRegionsDictionaryDraft, IRegionsEditorState, RegionsEditorState } from "./RegionsEditor";
import { RegionsEditorNodeV2 } from "./regions-editor-node/RegionsEditorNodeV2";
import { DictionaryDraft } from "../../../common/dictionary-draft-models/DictionaryDraft";
import { useAbortController } from "../../../../../hooks/useAbortController";
import { EHttpStatusCode } from "../../../../../utils/HttpStatusCodes";


export default function RegionsEditorV2() {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const [state, setState] = useState<RegionsEditorState>();
  useBeforeUnload(state?.isUpdatedInSession);
  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState<IRegion[]>();
  const [newRegionDialogOpen, setNewRegionDialogOpen] = useState(false);
  const [newRegionData, setNewRegionData] = useState<INewRegionData>();
  const abortController = useAbortController("RegionsEditorV2");
  //---------------------------------------------------------------------------
  useEffect(() => {
    getDraft(false);
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //---------------------------------------------------------------------------
  const onFilterUpdate = (updatedFilter: IDictionaryDraftFilter) => {
    setState(new RegionsEditorState(
      state as IRegionsEditorState,
      { filter: updatedFilter }
    ));
  };
  //---------------------------------------------------------------------------
  const stateFromApiResponse = (response: any, editModeIsEnabled: boolean) => {
    const dictionaryDraft = new RegionsDictionaryDraft(response as IRegionsDictionaryDraft);
    const initialDraft = new RegionsDictionaryDraft(response as IRegionsDictionaryDraft);
    console.log("dictionaryDraft:", dictionaryDraft);
    return new RegionsEditorState({
      basePath: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}`,
      numberOfApprovals: 2,
      filter: {
        draftState: DraftItemStateEnum.All,
        stateGroups: dictonaryDraftStatesFullDetails,
        selectedGroups: dictonaryDraftStatesFullDetails.map(g => g.id), // switch on all the options (groups)
        searchById: false
      },
      editModeIsEnabled: editModeIsEnabled,
      breadcrumb: {
        id: dictionaryDraft.dictionaryId,
        caption: { en: `${dictionaryDraft.dictionaryId}` },
        path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}/${dictionaryDraft.dictionaryId}`
      },
      draft: dictionaryDraft,
      draftSessionInitial: initialDraft,
      //draftSessionInitial: JSON.parse(JSON.stringify(dictionaryDraft)),
      isUpdated: false,
      isUpdatedInSession: false,
      isValid: false,
      isReadyToPublish: false
    });
  }
  //---------------------------------------------------------------------------
  const errorState = () => {
    return new RegionsEditorState({
      basePath: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}`,
      numberOfApprovals: 2,
      filter: {
        draftState: DraftItemStateEnum.All,
        stateGroups: dictonaryDraftStatesFullDetails,
        selectedGroups: dictonaryDraftStatesFullDetails.map(g => g.id), // switch on all the options (groups)
        searchById: false
      },
      editModeIsEnabled: false,
      breadcrumb: undefined,
      isUpdated: false,
      isUpdatedInSession: false,
      isValid: false,
      isReadyToPublish: false
    });
  }
  //---------------------------------------------------------------------------
  const getDraft = (lockIsNeeded: boolean) => {
    console.log("RegionsEditor: getting draft...")
    setIsLoading(true);
    let controller = abortController.newController("getDictionaryDraft");
    getDictionaryDraft(
      instance, "SupportedRegions", lockIsNeeded, appAuthContext.config, controller.signal
    ).then(data => {
      let locker = (data as ILockable).locker;
      if (locker) 
        locker.errorPrefix = `Regions dictionary`;
      !controller.aborted && setState(stateFromApiResponse(data, !!(locker && locker.statusCode == EHttpStatusCode.OK)));
    }).catch(error => {
      console.error(error);
      !controller.aborted && setState(errorState());
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const retrieveRegions = () => {
    setIsLoading(true);
    let controller = abortController.newController("getRegions");
    getRegions(
      instance, appAuthContext.config, controller.signal
    ).then(regions => {
      !controller.aborted && setRegions(regions);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onEnableEditMode = () => {
    getDraft(true);
  };
  //---------------------------------------------------------------------------
  const onDisableEditMode = () => {
    setState(new RegionsEditorState(
      state as IRegionsEditorState, {
      draft: state ? new RegionsDictionaryDraft(state?.draftSessionInitial) : undefined,
      editModeIsEnabled: false
    }));
  };
  //---------------------------------------------------------------------------
  const onSaveDraft = () => {
    if (!state?.draft) {
      console.error("Cannot save dictonary draft: state is not set");
      return;
    }
    console.log("onSaveDraft:", state.draft);
    setIsLoading(true);
    let controller = abortController.newController("updateDictionaryDraft");
    updateDictionaryDraft(
      instance,
      state.draft,
      appAuthContext.config
    ).then(data => {
      !controller.aborted && setState(stateFromApiResponse(data, true));
    }).catch(error => console.error(error)
    ).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onDeleteDraft = () => {
    setIsLoading(true);
    let controller = abortController.newController("deleteDictionaryDraft");
    deleteDictionaryDraft(
      instance,
      "SupportedRegions",
      appAuthContext.config
    ).then(data => {
      !controller.aborted && setState(stateFromApiResponse(data, false));
    }).catch(error => console.error(error)
    ).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onPublishDraft = () => {
    setIsLoading(true);
    let controller = abortController.newController("publishDictionaryDraft");
    publishDictionaryDraft(
      instance,
      "SupportedRegions",
      appAuthContext.config
    ).then(data => {
      !controller.aborted && setState(stateFromApiResponse(data, false));
    }).catch(error => console.error(error)).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onRegionUpdate = (updatedRegion: RegionsDictionaryDraftItem) => {
    if (!state?.draft) {
      console.error("Data is beign updated but state is not set");
      return;
    }
    const updatedRegions = state.draft.items.map(item => item.id == updatedRegion.id ? updatedRegion : item);
    const updatedDraft = new RegionsDictionaryDraft(state.draft);
    updatedDraft.items = updatedRegions;
    const updatedEditorState = new RegionsEditorState(state, {
      draft: updatedDraft
    });
    setState(updatedEditorState);
  };
  //---------------------------------------------------------------------------
  const onSubRegionAdded = (addedRegion: RegionsDictionaryDraftItem) => {
    if (!state?.draft) {
      console.error("Data is beign updated but state is not set");
      return;
    }
    state.draft.items.push(addedRegion);
    const updatedEditorState = new RegionsEditorState(state, {
      draft: state.draft
    });
    setState(updatedEditorState);
  };
  //--------------------------------------------------------------------------- Add new root item
  const onNewRegionAdd = (parentPath?: string) => {
    if (!regions) {
      // get regions list from API
      retrieveRegions();
    };
    setNewRegionData({
      path: parentPath
    });
    setNewRegionDialogOpen(true);
  };
  //---------------------------------------------------------------------------
  const onNewRegionCancel = () => {
    setNewRegionData(undefined);
    setNewRegionDialogOpen(false);
  };
  //---------------------------------------------------------------------------
  const onNewRegionSelect = (selectedRegion?: IRegion) => {
    setNewRegionDialogOpen(false);
    const regionData: INewRegionData = {
      ...newRegionData as INewRegionData,
      region: selectedRegion
    };
    setNewRegionData(regionData);
    // Open edit form right here if it's a root region
    // if (!regionData.path)
    //   setNewRootRegion(true);
  };
  //---------------------------------------------------------------------------
  const onDeleteItem = (deletedItemId: string) => {
    if (!state?.draft) {
      console.error("Draft items are being updated, but state is not set");
      return;
    }
    let updatedRegions = state.draft.items.filter(rr => rr.id != deletedItemId);
    const updatedDraft = new RegionsDictionaryDraft(state.draft);
    updatedDraft.items = updatedRegions;
    const updatedEditorState = new RegionsEditorState(state, {
      draft: updatedDraft
    });
    setState(updatedEditorState);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "optionDisableEditMode":
        onDisableEditMode();
        break;
      case "optionSave":
        onSaveDraft();
        break;
      case "optionDraftDelete":
        onDeleteDraft();
        break;
      case "optionPublish":
        onPublishDraft();
        break;
      case "optionAdd":
        onNewRegionAdd();
        break;
      default:
        break;
    };
  };
  //---------------------------------------------------------------------------
  const filteredDictionaryItems =
    state?.draft?.items?.filter(item => item.checkFilter(state.filter));
  // const filteredDictionaryItems = state?.draft?.items?.filter(item => {
  //   if (state.filter)
  //     return CheckFilter(state.filter, item);
  //   else
  //     return item;
  // });
  //---------------------------------------------------------------------------
  let rootItems: RegionsDictionaryDraftItem[] = [];
  let otherItems: RegionsDictionaryDraftItem[] = [];
  filteredDictionaryItems && DictionaryDraft.getSiblings(
    filteredDictionaryItems,
    rootItems, otherItems
  );
  //const rootItems = filteredDictionaryItems?.filter(item => item.path == undefined);
  //---------------------------------------------------------------------------
  let rootItemsToDisplay = rootItems?.map(item => {
    //let items = otherItems?.filter(child => child.path?.startsWith(item.id));
    let data = item;//new RegionsDictionaryDraftItem(item);
    let items = data.filterAllNodesUnderThis(otherItems);
    return (
      <RegionsEditorNodeV2
        key={item.id}
        stateGroups={state?.filter.stateGroups}
        data={data}
        items={items}
        editModeIsEnabled={state?.editModeIsEnabled}
        rootIsLocked={state?.draft.rootIsLocked}
        filter={state?.filter}
        newSubRegion={newRegionData}
        onUpdate={onRegionUpdate}
        onDelete={onDeleteItem}
        onSubRegionAdd={onNewRegionAdd}
        onSubRegionAdded={onSubRegionAdded}
      />);
  });
  //---------------------------------------------------------------------------
  if (newRegionDialogOpen && state?.regionIds) return (
    <RegionSelector
      data={regions}
      unavailableRegions={state.regionIds}
      onSelect={onNewRegionSelect}
      onClose={onNewRegionCancel}
    />
  ); else return (
    <DictionaryEditorWrapper
      setup={{
        mode: "Editor",
        role: 'Administrator',
        entityType: "Dictionary"
      }}
      state={state}
      isLoading={isLoading}
      onFilterUpdate={onFilterUpdate}
      onEnterEditMode={onEnableEditMode}
      onOptionSelect={onOptionSelect}
    >
      {rootItemsToDisplay}
    </DictionaryEditorWrapper>
  );
}