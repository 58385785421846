import { useContext, useState } from 'react';
import GetName from '../../../../../common/functions/GetName';
import IconButton from '../../../../../common/icon-button/IconButton';
import { TUiIconButton } from '../../../../../common/icon-button/TUiIconButton';
import { TUiStaffRole } from '../../../TUiAdministratorConsole';
import UserRoleEditorPopup from '../../user-role-editor-popup/UserRoleEditorPopup';
import styles from './UserRole.module.css';
import CombineStyles from '../../../../../../utils/combineStyles';
import { IUiDictionaryItemMlRegion } from '../../../../../common/dictionaries/IUiDictionaryItem';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';

export function GetRegionById(
  regionId: string,
  regions: IUiDictionaryItemMlRegion[]
): IUiDictionaryItemMlRegion | undefined {
  let result: IUiDictionaryItemMlRegion | undefined = undefined;
  for (let i = 0; i < regions.length; i++) {
    if (!result) {
      if (regions[i].id == regionId) {
        result = regions[i];
        break;
      } else if (regions[i].items && regions[i].items.length > 0) {
        result = GetRegionById(regionId, regions[i].items);
      };
    };
  };
  return result;
}

const editIconButton: TUiIconButton = {
  id: "",
  directoryId: "",
  elementType: "",
  iconFile: "pencil",
  inline: false,
  hint: undefined,
  visible: true,
  disabled: false,
  action: null
}

export interface IStaffRole {
  locales?: string[];
  regions?: string[];
}

interface IProps {
  staffRole: TUiStaffRole;
  data?: IStaffRole;
  onUpdate?: (updatedData: IStaffRole) => void;
}

export default function UserRole(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [popupOpen, setPopupOpen] = useState(false);
  //---------------------------------------------------------------------------
  const onUpdate = (updatedData: IStaffRole) => {
    setPopupOpen(false);
    props.onUpdate && props.onUpdate(updatedData);
  };
  //---------------------------------------------------------------------------
  const assignedLocaleElements = appUiContext.dictionaries?.supportedLocales?.filter(language => props.data?.locales?.includes(language.id))
    .map(language => (
      <li key={language.id}>
        {GetName(appUiContext, language.id, language.name)}
      </li>
    ));
  //---------------------------------------------------------------------------
  const assignedRegionElements = props.data?.regions?.sort().map(regionId => {
    let displayRegionName = `{{${regionId}.Name}}`;
    const regionData = appUiContext.dictionaries?.supportedRegions ? GetRegionById(regionId, appUiContext.dictionaries.supportedRegions) : undefined;
    if (regionData?.name) {
      displayRegionName = GetName(appUiContext, regionData.id, regionData.name);
    };
    return (
      <li
        key={regionId}
        className={CombineStyles([
          !regionData ? styles.nonexistent : "",
          regionData && (!regionData.status || !regionData?.status.isAvailableToStaffUsers) ? styles.deactivated : ""
        ])}
      >
        {displayRegionName}
      </li>);
  });
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <div className={styles.roleName}>
        {props.staffRole.name}
        {(props.data?.locales || props.data?.regions) && props.onUpdate &&
          <IconButton
            ui={editIconButton}
            inline={false}
            clickHandler={() => setPopupOpen(true)}
          />}
      </div>
      {props.data?.locales &&
        <ul className={styles.localesList}>
          {assignedLocaleElements}
        </ul>}
      {props.data?.regions &&
        <ul className={styles.localesList}>
          {assignedRegionElements}
        </ul>}

      {props.data && popupOpen &&
        <UserRoleEditorPopup
          staffRole={props.staffRole}
          data={props.data}
          onClose={() => setPopupOpen(false)}
          onConfirm={onUpdate}
        />}

    </div>
  );
}