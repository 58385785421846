//import styles from './BusinessMarkerAdvanced.module.css';
import { useEffect, useRef } from "react";
import { IBusinessLocationUpdate, IBusinessMarker } from "../BusinessMarkers";
import { TGeoPoint } from "../../../map/geo-json/GeoJson";
import styles from "./BusinessMarkerAdvanced.module.css";

interface IProps {
  map: google.maps.Map;
  businessMarker: IBusinessMarker;
  index?: number;
  isEditable?: boolean;
  isHighlighted?: boolean;
  onLocationUpdate?: (businessLocationUpdate: IBusinessLocationUpdate) => void;
  onClick?: (businessId: string, locationId: string) => void;
}

export default function BusinessMarkerAdvanced(props: IProps) {
  const marker = useRef<google.maps.marker.AdvancedMarkerElement>();
  const dragListener = useRef<google.maps.MapsEventListener>();
  const defaultMarkerOptions: google.maps.marker.PinElementOptions = {
    background: 'var(--clr_accent_main)',
    borderColor: 'black',
    glyphColor: 'black'
  };
  const highlightedMarkerOptions: google.maps.marker.PinElementOptions = {
    background: 'var(--clr_accent_main)',
    borderColor: 'black',
    glyphColor: 'black',
    scale: 1.5
  };
  const editableMarkerOptions: google.maps.marker.PinElementOptions = {
    background: 'var(--clr_accent_attention)',
    borderColor: 'black',
    glyphColor: 'black',
    scale: 1.5
  };
  //---------------------------------------------------------------------------
  const clearListener = () => {
    if (dragListener.current) {
      //console.log("AdvancedMarker: removing dragend listener");
      dragListener.current?.remove();
      dragListener.current = undefined;
    };
  };
  //--------------------------------------------------------------------------- Marker, clean up
  useEffect(() => {
    // Check if marker already exists to prevent duplication on double render
    if (!marker.current) {
      const pin = new google.maps.marker.PinElement(defaultMarkerOptions);
      pin.className = styles.pin;
      marker.current = new google.maps.marker.AdvancedMarkerElement({
        content: pin.element,
        zIndex: 100
      });
      //-----------------------------------------------------------------------
      // Click on marker event
      marker.current.addListener("gmp-click", function onMarkerClick() {
        props.onClick && props.onClick(props.businessMarker.businessId, props.businessMarker.locationId);
      });
    };
    //-------------------------------------------------------------------------
    return (() => {
      clearListener();
      if (marker.current) {
        marker.current.map = undefined;
      };
    });
  }, []);
  //--------------------------------------------------------------------------- Position and looks
  useEffect(() => {
    if (!marker.current) return;
    marker.current.map = props.map;
    if (props.businessMarker.geolocation) {
      const point = new TGeoPoint(props.businessMarker.geolocation);
      marker.current.position = point.latLngLiteral;
    };
    marker.current.title = props.businessMarker.name;
  }, [props.map, props.businessMarker, props.index]);
  //--------------------------------------------------------------------------- Dragging
  useEffect(() => {
    if (!marker.current) return;
    const markerIsDraggable = props.isEditable === true && props.onLocationUpdate != undefined;
    if (markerIsDraggable) {
      // Make marker draggable and listen to position change
      // Change marker appearance
      marker.current.content = (new google.maps.marker.PinElement(editableMarkerOptions)).element;
      marker.current.gmpDraggable = true;
      //console.log("AdvancedMarker: adding dragend listener");
      clearListener();
      dragListener.current = marker.current.addListener("dragend", onDragEnd);
    } else {
      // Make marker static and remove onDrag listener
      // Apply default marker appearance
      marker.current.gmpDraggable = false;
      marker.current.content = (new google.maps.marker.PinElement(defaultMarkerOptions)).element;
      clearListener();
    };
  }, [props.isEditable, props.onLocationUpdate]);
  //--------------------------------------------------------------------------- Highlighting
  useEffect(() => {
    // NOTE: For some reason, just updating business markers does not work, it needs to be a prop that changes,
    // otherwise marker appearance is not affected
    if (!marker.current) return;
    if (props.businessMarker.isHighlighted) {
      marker.current.content = (new google.maps.marker.PinElement(highlightedMarkerOptions)).element;
    } else {
      marker.current.content = (new google.maps.marker.PinElement(defaultMarkerOptions)).element;
    };
  }, [props.isHighlighted]);
  //---------------------------------------------------------------------------
  const onDragEnd = (e: google.maps.MapMouseEvent) => {
    //console.log('AdvancedMarker.onDragEnd:', e.latLng)
    if (e.latLng && props.onLocationUpdate) {
      //console.log(e.latLng.lat(), e.latLng.lng())
      // Pass update
      const businessLocationUpdate: IBusinessLocationUpdate = {
        businessId: props.businessMarker.businessId,
        locationId: props.businessMarker.locationId,
        location: TGeoPoint.fromLatLng(e.latLng.lat(), e.latLng.lng())
      };
      props.onLocationUpdate(businessLocationUpdate);
    };
  };
  //---------------------------------------------------------------------------
  return null;
}