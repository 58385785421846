import { IUiInteractiveForm } from "../../../../common/forms/IUiInteractiveForm";
import { IUiInputText } from "../../../../common/input-v2/input-text/InputText";
import { IUiMenuContent } from "../../../../common/options/menus/IUiMenuContent";
import { IUiTranslationEditor } from "../../../common/directory-translator/translation-editor/IUiTranslationEditor";

export interface IUiContentItemEditform extends IUiInteractiveForm {
  inputId: IUiInputText;
  textTranslationEditor: IUiTranslationEditor;
}

const tmpMenuContent: IUiMenuContent = {
  id: '',
  directoryId: '',
  elementType: '',
  hint: undefined,
  options: [{
    id: 'OptionClose',
    directoryId: '',
    elementType: '',
    caption: { en: 'Close' },
    hint: undefined,
  }, {
    id: 'OptionCancel',
    directoryId: '',
    elementType: '',
    caption: { en: 'Cancel' },
    hint: undefined,
  }, {
    id: 'OptionOk',
    directoryId: '',
    elementType: '',
    caption: { en: 'Ok' },
    hint: undefined,
  }]
}

export const tmpUiContentItemEditform: IUiContentItemEditform = {
  id: '',
  directoryId: '',
  elementType: '',
  caption: undefined,
  hint: undefined,
  menuContent: tmpMenuContent,
  inputId: {
    id: '',
    directoryId: '',
    elementType: '',
    caption: { en: 'Id' }
  },
  textTranslationEditor: {
    id: '',
    caption: { en: 'Text' },
    inputLocale: {
      id: '',
      directoryId: '',
      elementType: '',
      dictionaryId: '',
      caption: { en: 'Locale'},
      hint: undefined,
      placeholder: { en: 'Select locale'}
    },
    inputSource: {
      id: '',
      directoryId: '',
      elementType: '',
      caption: { en: 'Source' }
    }
  }
};