import styles from "./FormOptionPopup.module.css";
import { CSSProperties, useContext } from "react";
import CombineStyles from "../../../../../utils/combineStyles";
import { TUiMenuOption } from "../../../menu/menu-content/menu-option/TUiMenuOption";
import ButtonCaption from "../../../button-caption/ButtonCaption";
import { ButtonType } from "../../../button/ButtonType";
import { ScreenType, useAppScreenContext } from "../../../../../context/app-screen-context/AppScreenProvider";
import GetHint from "../../../functions/GetHint";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";

interface IProps {
  ui: TUiMenuOption;
  isDropdown: boolean;
  onSelect: (optionId: string) => void;
}

export default function FormOptionPopup(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  //---------------------------------------------------------------------------
  const customCaption = undefined;
  const customColor = undefined;
  const spin = false;
  //---------------------------------------------------------------------------
  const calculatedStyle: CSSProperties = {
    //backgroundColor: customColor ? customColor : undefined,
    color: props.ui.customColor,
    display: props.ui.visible ? "flex" : "none"
  };
  //---------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    styles.popup,
    screenType == ScreenType.Desktop ? styles.desktop : styles.mobile,
    props.isDropdown ? styles.dropdown : "",
    props.ui.disabled ? styles.disabled : ""
  ]);
  //---------------------------------------------------------------------------
  return (
    <div
      id={props.ui.id}
      title={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      className={containerStyles}
      style={calculatedStyle}
      onClick={() => props.onSelect(props.ui.id)}
    >
      <ButtonCaption
        type={ButtonType.Popup}
        ui={props.ui}
        customCaption={customCaption}
        displayIcon={true}
        isDropdown={props.isDropdown}
      />
    </div>
  );
}