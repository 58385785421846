import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { EDirectoryType, IDirectoryListItem } from "./DirectoryList";
import { apiBasePath, pathDefaultContentDirectoryDraftGetList, pathWidgetDirectoryDraftGetList } from "../../../../../utils/apiPathConstant";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";

interface IProps {
  msalInstanse: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  directoryType: EDirectoryType;
}

export default async function getDirectoriesListAsync(props: IProps): Promise<IDirectoryListItem[]> {
  if (!props.authConfig) throw new Error("getDirectoriesListAsync: auth config is not provided");
  let apiPath: string;
  switch (props.directoryType) {
    case "Widgets":
      apiPath = pathWidgetDirectoryDraftGetList;
      break;
    case "Defaults":
      apiPath = pathDefaultContentDirectoryDraftGetList;
      break;
  };
  //---------------------------------------------------------------------------
  const uri = `${apiBasePath}${apiPath}`;
  const response = await apiGetPrivate(
    props.msalInstanse,
    props.authConfig,
    uri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getDirectoriesListAsync");
  return (response?.content as IDirectoryListItem[]);
}