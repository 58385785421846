import React, { useContext, useEffect, useRef, useState } from "react";
import { AppUiContextStore } from "../../context/app-ui-context/AppUiContextProvider";
import { TUiMenuOption } from "../common/menu/menu-content/menu-option/TUiMenuOption";
import { getTUiMenuContent, TUiMenuContent } from "../common/menu/menu-content/TUiMenuContent";
import BusinessPresenter from "./business-presenter/BusinessPresenter";
import styles from "./HomePageLayout.module.css";
import SearchPanel from "./search-panel/SearchPanel";
import { GetCategoryByPath } from "../../utils/locationUtils";
import { useLocation } from "react-router-dom";
import { IUiHomepage } from "./IUiHomepage";
import ScrollableContainer from "../common/containers/scrollable-container/ScrollableContainer";
import { ClassAdLanguageSetPublished } from "./ClassAdLanguageSetPublished";
import MainMenu from "../common/options/menus/main-menu/MainMenu";
import { IUiOption } from "../common/options/IUiOption";
import { IUiMenuContent } from "../common/options/menus/IUiMenuContent";
import PopupMenu from "../common/popup-v2/popup-menu/PopupMenu";
import { UserSettings } from "../../utils/UserSettings";
import { AppAuthContextStore } from "../../context/app-auth-context/AppAuthContext";

const optionHomepage = "OptionHomepage";

interface IProps {
  ui?: IUiHomepage;
  regionalHomepage?: ClassAdLanguageSetPublished;
}

export default function HomePageLayout(props: IProps) {
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const { appAuthContext } = useContext(AppAuthContextStore);
  const location = useLocation();
  const firstRender = useRef(true);
  //const [selectedCategory, setSelectedCategory] = useState<string>("Accommodation");
  const [selectedCategory, setSelectedCategory] = useState<string>(UserSettings.getHomepageTab);  
  const scrollableDiv = useRef<HTMLDivElement>(null);
  const [searchPanelIsCollapsed, setSearchPanelIsCollapsed] = useState(false);
  const [mainMenuPopupOpen, setMainMenuPopupOpen] = useState(false);
  //--------------------------------------------------------------------------- First render
  console.log("HomePageLayout.tab:", selectedCategory);
  //--------------------------------------------------------------------------- First render
  useEffect(() => {
    firstRender.current = false;
  }, []);
  //--------------------------------------------------------------------------- First render
  useEffect(() => {
    let tab = UserSettings.getHomepageTab()
    if (tab)
      setSelectedCategory(tab);
  }, [appAuthContext.user]);
  //------------------------------------------------------- Check changes in URL
  useEffect(() => {
    let currentCategory = GetCategoryByPath(location);
    if (currentCategory) {
      selectCategory(currentCategory);
      appUiContextDispatch({ type: "SetCurrentPagePath", value: `${optionHomepage}.${currentCategory}` });
    }
    // if (!currentCategory)
    //   currentCategory = "Accommodation";
    // selectCategory(currentCategory);
    // appUiContextDispatch({ type: "SetCurrentPagePath", value: `${optionHomepage}.${currentCategory}` });
  }, [location.search]);
  //---------------------------------------------------------------------------
  const selectCategory = (value: string) => {
    setSelectedCategory(value);
    UserSettings.setHomepageTab(value);
  }
  //---------------------------------------------------------------------------
  const scrollToTheTop = () => {
    if (scrollableDiv.current) {
      setSearchPanelIsCollapsed(false);
      scrollableDiv.current.scrollTo({ top: 0, behavior: 'smooth' });
    };
  };
  //--------------------------------------------------------------------------- React to Content scrolling
  const onContentScrollDown = () => {
    // If user scrolls down, collapse the search panel
    setSearchPanelIsCollapsed(true);
  };
  //---------------------------------------------------------------------------
  const onContentScrollUp = () => {
    // If user scrolls up and search panel is expanded, collapse it
    setSearchPanelIsCollapsed(true);
  };
  //---------------------------------------------------------------------------
  const onContentScrolledToTheTop = () => {
    // If user scrolled up to the top, expand the search panel
    setSearchPanelIsCollapsed(false);
  };
  //---------------------------------------------------------------------------
  const refreshState = (newState: boolean) => {
    console.log('refresh: ', searchPanelIsCollapsed, newState)
    setSearchPanelIsCollapsed(newState);
  }
  //---------------------------------------------------------------------------
  const onMainMenuOptionSelect = (selectedOptionId: string) => {
    setMainMenuPopupOpen(false);
    selectCategory(selectedOptionId);
  };
  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  appUiContext.dictionaries?.businessTags?.filter(item => !item.path).forEach(item => {
    updatedOptions.push({
      id: item.id,
      directoryId: "",
      elementType: "",
      caption: item.name,
      hint: item.description,
      iconId: GetIconForRootBusinessTag(item.id),
      action: `?cat=${item.id}`
    });
  });
  const menuContent: IUiMenuContent = {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: updatedOptions
  };
  const selectedOption = updatedOptions.find(o => o.id == selectedCategory);
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>

      {/* Main menu */}
      <MainMenu
        ui={menuContent}
        selectedOptionId={selectedCategory}
        onSelect={onMainMenuOptionSelect}
      />

      {/* Search Panel */}
      {props.ui &&
        <SearchPanel
          ui={props.ui?.searchPanel}
          data={props.regionalHomepage}
          isCollapsed={searchPanelIsCollapsed}
          selectedCategory={selectedCategory}
          onStateUpdate={refreshState}
          selectedMainMenuOption={selectedOption}
          onMainMenuOptionSelect={() => setMainMenuPopupOpen(true)}
        />}

      {/* Content */}
      <ScrollableContainer
        elementRef={scrollableDiv}
        className={styles.container}
        onScrollDown={onContentScrollDown}
        onScrollUp={onContentScrollUp}
        onScrolledToTop={onContentScrolledToTheTop}
      >
        <div className={styles.main}>
          <BusinessPresenter
            ui={props.ui?.businessPresenter}
            category={selectedCategory ? selectedCategory : undefined}
            refresh={firstRender.current}
            onFirstPage={scrollToTheTop}
            parent={scrollableDiv.current}
          />
        </div>
      </ScrollableContainer>

      {mainMenuPopupOpen &&
        <PopupMenu
          ui={getTUiMenuContent(menuContent)}
          displayIcon={true}
          onClose={() => setMainMenuPopupOpen(false)}
          onSelect={onMainMenuOptionSelect}
        />}

    </React.Fragment>
  )
}

function GetIconForRootBusinessTag(businessTagId: string) {
  switch (businessTagId) {
    case "Accommodation":
      return "accommodation";
    case "Entertainment":
      return "entertainment";
    case "Food":
      return "food";
    case "Job":
      return "job";
    case "Transport":
      return "transport";
    case "Extra":
      return "extra";
    default:
      return "";
  };
}

{/* {props.ui && mobile &&
  <SearchPanelMobile
    ui={props.ui?.searchPanel}
    data={props.regionalHomepage}
    collapsed={searchPanelCollapsed}
    selectedCategory={selectedCategory}
    onMagnifierClick={onMagnifierClick}
    onScrollUp={onSearchScrollUp}
    onTransitionStart={onTransitionStart}
    onTransitionEnd={onTransitionEnd}
  />} */}

{/* {(screenType === ScreenType.Desktop) &&
          <div className={styles.sidebar}>
            Here will be banners,<br />news feed etc
          </div>} */}