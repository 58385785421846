import { IUiMenuContent } from "../../components/common/options/menus/IUiMenuContent";

export interface IUiListColumnsManager {
  menuContent: IUiMenuContent;
}

export const uiListColumnsManager: IUiListColumnsManager = {
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionContextMenu",
      directoryId: "",
      elementType: "",
      caption: { en: "Open context menu" },
      hint: undefined,
    }, {
      id: "OptionSortPresets",
      directoryId: "",
      elementType: "",
      caption: { en: "Select sorting" },
      hint: undefined,
    }, {
      id: "OptionSort",
      directoryId: "",
      elementType: "",
      caption: { en: "Set sorting" },
      hint: undefined,
    }, {
      id: "OptionFilter",
      directoryId: "",
      elementType: "",
      caption: { en: "Set filters" },
      hint: undefined,
    }, {
      id: "OptionFilterPresets",
      directoryId: "",
      elementType: "",
      caption: { en: "Select filter" },
      hint: undefined,
    }, {
      id: "OptionListModeList",
      directoryId: "",
      elementType: "",
      caption: { en: "List view" },
      hint: undefined,
    }, {
      id: "OptionListModeGrid",
      directoryId: "",
      elementType: "",
      caption: { en: "Grid view" },
      hint: undefined,
    }, {
      id: "OptionSetSimplifiedMode",
      directoryId: "",
      elementType: "",
      caption: { en: "Basic mode" },
      hint: undefined,
    }, {
      id: "OptionSetAndvancedMode",
      directoryId: "",
      elementType: "",
      caption: { en: "Set advanced mode" },
      hint: undefined,
    }, {
      id: "OptionReset",
      directoryId: "",
      elementType: "",
      caption: { en: "Restore defaults" },
      hint: undefined,
    }]
  }
};