import { ReactNode, useEffect, useRef, useState } from 'react';
import styles from './DictionaryNodeEditForm.module.css';
import { TUiMenuContent } from '../../../../../common/menu/menu-content/TUiMenuContent';
import { TUiInputCheckbox } from '../../../../../common/input/input-checkbox/TUiInputCheckbox';
import { TUiMenuOption } from '../../../../../common/menu/menu-content/menu-option/TUiMenuOption';
import FormOptions from '../../../../../common/form-options-bar/FormOptions';
import useClickOutsideDiv from '../../../../../../hooks/useClickOutsideDiv';
import InputGroup from '../../../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import InputText, { IUiInputText } from '../../../../../common/input-v2/input-text/InputText';
import PopupMessage from '../../../../../common/popup-v2/popup-message/PopupMessage';
import { DictionaryEditorDraftItem } from '../models/DictionaryEditorDraftItem';
import { DraftItemStateEnum } from '../../../../common/dictionary-draft-models/DraftItemStateEnum';
import { IEditFormState } from '../../../../../common/edit-form/IEditFormState';

export interface IUiDictionaryEditForm {
  inputId: IUiInputText;
  inputName: IUiInputText;
  inputDescription: IUiInputText;
  inputParent: IUiInputText;
  inputItemsOrderedManually: TUiInputCheckbox;
  formOptions: TUiMenuContent;
}
const tmpUi: IUiDictionaryEditForm = {
  inputId: {
    id: "DictionaryNodeIdInput",
    directoryId: '',
    elementType: "",
    disabled: true,
    visible: true,
    caption: { en: "Id" },
    placeholder: { en: "Type Id here..." },
    defaultValue: undefined,
    hint: { en: "System Id visible to administrators and system translators" },
    validation: {
      required: {
        value: 1,
        message: {
          id: "DictionaryNodeEditForm_Id_ValidationMessage_Required",
          hint: "Id cannot be empty"
        }
      },
      unique: {
        message: {
          id: "DictionaryNodeEditForm_Id_ValidationMessage_Unique",
          hint: "Entry with this Id already exists"
        }
      }
    }
  },
  inputName: {
    id: "DictionaryNodeNameInput",
    directoryId: '',
    elementType: "",
    disabled: false,
    visible: true,
    caption: { en: "Name" },
    placeholder: { en: "Type Name here..." },
    defaultValue: undefined,
    hint: { en: "Name in English visible to end users" },
    validation: {
      required: {
        value: 1,
        message: {
          id: "DictionaryNodeEditForm_Name_ValidationMessage_Required",
          hint: "Provide a name in English"
        }
      }
    }
  },
  inputDescription: {
    id: "DictionaryNodeDescriptionInput",
    directoryId: '',
    elementType: "",
    disabled: false,
    visible: true,
    caption: { en: "Description" },
    placeholder: { en: "Type Description here..." },
    defaultValue: undefined,
    hint: { en: "Detailed description in English visible to end users" },
    validation: {
      required: {
        value: 1,
        message: {
          id: "DictionaryNodeEditForm_Description_ValidationMessage_Required",
          hint: "Provide a description in English"
        }
      }
    }
  },
  inputItemsOrderedManually: {
    id: '',
    caption: { en: "Manual Order" },
    hint: undefined,
    disabled: false
  },
  inputParent: {
    id: "",
    directoryId: '',
    elementType: "",
    disabled: false,
    visible: true,
    caption: { en: "Parent" },
    placeholder: undefined,
    defaultValue: undefined,
    hint: undefined,
    validation: undefined
  },
  formOptions: {
    id: '',
    directoryId: '',
    elementType: '',
    visible: true,
    disabled: false,
    options: [{
      id: 'optionCancel',
      directoryId: '',
      elementType: '',
      visible: true,
      disabled: false,
      canHideCaption: false,
      index: 0,
      priorityLevel: 0,
      caption: { en: 'Cancel' },
      iconFile: "close",
      hint: undefined,
      isDefault: false,
      action: undefined
    }, {
      id: 'optionOk',
      directoryId: '',
      elementType: '',
      visible: true,
      disabled: false,
      canHideCaption: false,
      index: 0,
      priorityLevel: 0,
      caption: { en: 'Ok' },
      iconFile: "check",
      hint: undefined,
      isDefault: true,
      action: undefined
    }, {
      id: "optionClose",
      directoryId: "",
      elementType: "",
      index: 1,
      priorityLevel: 1,
      caption: { en: "Close" },
      hint: null,
      iconFile: "close",
      isDefault: false,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }],
    optionGroups: []
  }
}

const discardChangesConfirmationDialogMenu: TUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  visible: true,
  disabled: false,
  options: [{
    id: "optionDiscard",
    directoryId: "",
    elementType: "",
    index: 1,
    priorityLevel: 1,
    caption: { en: "Discard" },
    hint: null,
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    canHideCaption: false,
    action: null
  }, {
    id: "optionCancel",
    directoryId: "",
    elementType: "",
    index: 1,
    priorityLevel: 1,
    caption: { en: "Cancel" },
    hint: null,
    iconFile: "",
    isDefault: false,
    disabled: false,
    visible: true,
    canHideCaption: false,
    action: null
  }],
  optionGroups: []
}

interface IValidityState {
  id?: boolean;
  name?: boolean;
  description?: boolean;
}

interface IProps {
  //ui: IUiDictionaryEditForm;
  data?: IEditFormState<DictionaryEditorDraftItem>;
  children?: ReactNode;
  isReadonly?: boolean;
  onInitialValidate?: (isValid: boolean) => void;
  onUpdate: (updatedData: DictionaryEditorDraftItem, isValid: boolean) => void;
  onConfirm: () => void;
  onClose: () => void;
  newUi?: boolean;
}

export default function DictionaryNodeEditForm(props: IProps) {
  const [discardChangesDialogOpen, setDiscardChangesDialogOpen] = useState(false);
  const dataValidity = useRef<IValidityState>({ id: undefined, name: undefined, description: undefined }); // To control if all the inputs are valid
  const editFormDivRef = useClickOutsideDiv({
    handler: onCloseWithUnsavedChanges,
    unsavedChanges: props.data?.isUpdated
  });
  // This form can provide states:
  // - isNew
  // - isEdited
  // - isTranslationEdited
  const isReadonly = props.isReadonly == undefined ? false : props.isReadonly;
  // We can edit Id only if it's a new item (not published yet)
  const idIsReadonly = !!props.data?.object && !props.data?.object?.isNew;
  //--------------------------------------------------------------------------- Scroll into view
  useEffect(() => {
    if (editFormDivRef.current) {
      editFormDivRef.current.scrollIntoView({ behavior: 'smooth' });
    };
  }, [editFormDivRef.current]);
  //---------------------------------------------------------------------------
  function onCloseWithUnsavedChanges(unsavedChanges?: boolean) {
    if (unsavedChanges)
      setDiscardChangesDialogOpen(true);
    else
      props.onClose();
  };
  //---------------------------------------------------------------------------
  const getValidity = (): boolean => {
    const idIsValid = dataValidity.current.id == undefined ? false : dataValidity.current.id;
    const nameIsValid = dataValidity.current.name == undefined ? false : dataValidity.current.name;
    const descriptonIsValid = dataValidity.current.description == undefined ? false : dataValidity.current.description;
    return idIsValid && nameIsValid && descriptonIsValid;
  };
  //---------------------------------------------------------------------------
  const updateInitialValidity = (item: string, isValid: boolean) => {
    switch (item) {
      case "id":
        dataValidity.current.id = isValid;
        break;
      case "name":
        dataValidity.current.name = isValid;
        break;
      case "description":
        dataValidity.current.description = isValid;
        break;
    };
    props.onInitialValidate && props.onInitialValidate(getValidity());
  };
  //---------------------------------------------------------------------------
  const onUpdateId = (updatedValue: string, isValid: boolean) => {
    if (idIsReadonly) {
      console.error("Cannot update Id if it's an existing item");
    } else if (props.data?.object) {
      //-----------------------------------------------------------------------
      // Update existing item
      const updatedItem = new DictionaryEditorDraftItem(props.data.object, {
        id: updatedValue
      });
      dataValidity.current.id = isValid;
      const dataIsValid = getValidity();
      props.onUpdate(updatedItem, dataIsValid);
    } else {
      //-----------------------------------------------------------------------
      // Create a new item
      const updatedItem = new DictionaryEditorDraftItem(
        props.data?.object ? {
          ...props.data.object,
          id: updatedValue
        } : {
          id: updatedValue,
          draftState: DraftItemStateEnum.IsNew
        });
      updatedItem.isNew = true;
      dataValidity.current.id = isValid;
      const dataIsValid = getValidity();
      props.onUpdate(updatedItem, dataIsValid);
    };
  };
  //---------------------------------------------------------------------------
  const onUpdateName = (updatedValue: string, isValid: boolean) => {
    if (props?.data?.object) {
      const updatedItem = new DictionaryEditorDraftItem(props.data.object, { name: updatedValue });
      dataValidity.current.name = isValid;
      const dataIsValid = getValidity();
      props.onUpdate(updatedItem, dataIsValid);
    };
  };
  //---------------------------------------------------------------------------
  const onUpdateDescription = (updatedValue: string, isValid: boolean) => {
    if (props?.data?.object) {
      const updatedItem = new DictionaryEditorDraftItem(props.data.object);
      updatedItem.description = updatedValue;
      dataValidity.current.description = isValid;
      const dataIsValid = getValidity();
      updatedItem.refresh("en");
      props.onUpdate(updatedItem, dataIsValid);
    };
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    if (props.data && props?.data.isValid) {
      props.onConfirm();
    } else console.error("Ok option should not be available: data is not valid");
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "optionOk":
        onConfirm();
        break;
      case "optionCancel":
      case "optionClose":
        onCloseWithUnsavedChanges(props.data?.isUpdated);
        break;
    }
  };
  //---------------------------------------------------------------------------
  const onDiscardChangesDialogOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "optionDiscard":
        props.onClose();
        break;
      case "optionCancel":
        setDiscardChangesDialogOpen(false);
        break;
    }
  };
  //---------------------------------------------------------------------------
  //console.log(props.data?.isUpdated, props.data?.isValid, getValidity())
  var updatedOptions: TUiMenuOption[] = [];
  tmpUi.formOptions.options.forEach(option => {
    switch (option.id) {
      case "optionOk":
        if (!isReadonly) {
          const updatedOkOption: TUiMenuOption = {
            ...option,
            disabled: !(props.data?.isUpdated && props.data?.isValid && getValidity())
          };
          updatedOptions.push(updatedOkOption);
        };
        break;
      //-----------------------------------------------------------------------
      case "optionCancel":
        if (!isReadonly)
          updatedOptions.push(option);
        break;
      //-----------------------------------------------------------------------
      case "optionClose":
        if (isReadonly)
          updatedOptions.push(option);
        break;
    };
  });
  //---------------------------------------------------------------------------
  if (props.newUi) return (
    <div className={styles.wrapper}>
      <div
        ref={editFormDivRef}
        className={styles.container}
      >
        <div className={styles.inputs}>
          <InputGroup>
            <InputText
              key={tmpUi.inputId.id}
              ui={{ ...tmpUi.inputId, disabled: isReadonly || idIsReadonly }}
              data={props.data?.object?.id}
              onInitialValidate={(isValid: boolean) => updateInitialValidity("id", isValid)}
              onUpdate={onUpdateId}
            />
            <InputText
              ui={{ ...tmpUi.inputName, disabled: isReadonly }}
              data={props.data?.object?.name ? props.data.object.name : ""}
              onInitialValidate={(isValid: boolean) => updateInitialValidity("name", isValid)}
              onUpdate={onUpdateName}
            />
            <InputText
              ui={{ ...tmpUi.inputDescription, disabled: isReadonly }}
              data={props.data?.object?.description ? props.data.object.description : ""}
              onInitialValidate={(isValid: boolean) => updateInitialValidity("description", isValid)}
              onUpdate={onUpdateDescription}
            />

            {props.children}

          </InputGroup>
        </div>

        <FormOptions
          ui={{ ...tmpUi.formOptions, options: updatedOptions }}
          applyMobileStyle={false}
          onSelect={onOptionSelect}
        />

      </div>

      <PopupMessage
        id='DictionaryNodeDiscardChangesConfirmationMessage'
        type='Confirmation'
        header={`Administrator Console. Dictionary Editor`}
        message='Please confirm you are going to discard unsaved changes'
        optionsMenuContent={discardChangesConfirmationDialogMenu}
        show={discardChangesDialogOpen}
        onOptionSelect={onDiscardChangesDialogOptionSelect}
        onCancel={() => onDiscardChangesDialogOptionSelect("optionCancel")}
      />

    </div>
  ); else return (
    <tr>
      <td colSpan={100}>
        <div
          ref={editFormDivRef}
          className={styles.container}
        >
          {/* <ValidationWrapper isValid={true}>
            <BusinessTagParentEditor
          ui={tmpBusinessTagParentInput}
          data={props.parent}
          rootNodes={props.rootNodes}
          currentItemPath={tag?.path}
          onConfirm={isNew ? undefined : onUpdateParent}
        />
          </ValidationWrapper> */}
          <div className={styles.inputs}>
            <InputGroup>
              <InputText
                key={tmpUi.inputId.id}
                ui={{ ...tmpUi.inputId, disabled: !props.data?.isNew }}
                data={props.data?.object?.id}
                onInitialValidate={(isValid: boolean) => dataValidity.current.id = isValid}
                onUpdate={onUpdateId}
              />
              <InputText
                ui={{ ...tmpUi.inputName, disabled: false }}
                data={props.data?.object?.name ? props.data.object.name : ""}
                onInitialValidate={(isValid: boolean) => dataValidity.current.name = isValid}
                onUpdate={onUpdateName}
              />
              <InputText
                ui={{ ...tmpUi.inputDescription, disabled: false }}
                data={props.data?.object?.description ? props.data.object.description : ""}
                onInitialValidate={(isValid: boolean) => dataValidity.current.description = isValid}
                onUpdate={onUpdateDescription}
              />

              {props.children}

            </InputGroup>
          </div>

          <FormOptions
            ui={{ ...tmpUi.formOptions, options: updatedOptions }}
            applyMobileStyle={false}
            onSelect={onOptionSelect}
          />

        </div>
      </td>

      <PopupMessage
        id='DictionaryNodeDiscardChangesConfirmationMessage'
        type='Confirmation'
        header={`Administrator Console. Dictionary Editor`}
        message='Please confirm you are going to discard unsaved changes'
        optionsMenuContent={discardChangesConfirmationDialogMenu}
        show={discardChangesDialogOpen}
        onOptionSelect={onDiscardChangesDialogOptionSelect}
        onCancel={() => onDiscardChangesDialogOptionSelect("optionCancel")}
      />

    </tr>
  );
}