import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathWidgetDirectoryDraftDelete } from "../../../../../utils/apiPathConstant";
import { apiDeletePrivate, checkResponse } from "../../../../../utils/api";
import { stringFormatter } from "../../../../../utils/common";
import { IDirectoryTranslationDraft } from "./DirectoryTranslatorState";

export default function deleteWidgetDirectoryDraft(
  msalInstanse: IPublicClientApplication,
  directoryId: string,
  localeId: string,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<IDirectoryTranslationDraft> {
  return new Promise((resolve, reject) => {
    const uri = `${apiBasePath}${stringFormatter(pathWidgetDirectoryDraftDelete, [directoryId, localeId])}`;
    //-------------------------------------------------------------------------
    apiDeletePrivate(
      msalInstanse,
      authConfig,
      uri,
      abortSignal
    ).then(response => {
      checkResponse(response, "deleteWidgetDirectoryDraft");
      resolve(response?.content as IDirectoryTranslationDraft);
    }).catch(error =>
      reject(error)
    );
  });
}