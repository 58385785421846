import { ReactNode } from 'react';
import styles from './DictionaryNodeCell.module.css';
import CombineStyles from '../../../../utils/combineStyles';

interface IProps {
  editModeIsEnabled: boolean;
  children: ReactNode;
  onSelect?: () => void;
}

export default function DictionaryNodeCell(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <td
      className={CombineStyles([
        styles.container
      ])}
    >
    <span
      className={props.editModeIsEnabled ? styles.clickableContent : ""}
      onClick={props.editModeIsEnabled ? props.onSelect : undefined}
    >
      {props.children}
      </span>
    </td>
  );
}