import { useContext, useState } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { WizardState } from '../IWizardState';
import { IUiBusinessWizard } from '../IUiBusinessWizardV2';
import GetDescription from '../../../common/functions/GetDescription';
import WizardStepWrapper from '../step-wrapper/WizardStepWrapper';
import { IUiOption } from '../../../common/options/IUiOption';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import InputText from '../../../common/input-v2/input-text/InputText';

interface IProps {
  state: WizardState;
  ui: IUiBusinessWizard;
  onUpdate: (state: WizardState) => void;
  onExit: () => void;
  onSubmit: () => void;
}

export default function StepPublish(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [email, setEmail] = useState<string>(props.state.data.email ? props.state.data.email : '');
  const stepUi = props.ui.stepsDictionary.find(item => item.id == props.state.selectedStep);
  const prompt = GetDescription(appUiContext, props.state.selectedStep, stepUi?.description);
  //---------------------------------------------------------------------------
  const onUpdate = (value: string) => {
    setEmail(value);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    let updatedState: WizardState;
    switch (optionId) {
      case "OptionBack":
        updatedState = props.state.moveToPreviousStep();
        props.onUpdate(updatedState);
        break;
      case "OptionSubmit":
        props.onSubmit();
        break;
      default:
        throw new Error(`StepPublish: Unknown optionId ${optionId}`);
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionBack":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowLeft",
          isDisabled: false
        });
        break;
      case "OptionSubmit":
        updatedOptions.push({
          ...option,
          iconId: "send",
          isDisabled: false,
          isDefault: true
        });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      alignLeft={true}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <WizardStepWrapper
      ui={props.ui}
      promptString={prompt}
      optionsElement={formOptions}
      onExit={props.onExit}
    >
      <InputText
        ui={{ ...props.ui.inputEmail, hideCaption: true, hideHint: true }}
        data={email}
        isFocused={true}
        onUpdate={onUpdate}
      />
    </WizardStepWrapper>
  );
}