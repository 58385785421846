import { useContext } from 'react';
import styles from './DraftHistory.module.css';
import React from 'react';
import { AppAuthContextStore } from '../../../../../../context/app-auth-context/AppAuthContext';
import { IDocumentDraftHistoryRecord } from '../../../../common/dictionary-draft-models/IDocumentDraftHistoryRecord';

const IsMinDatetime = (datetimeString: string) => {
  const datetime = new Date(datetimeString);
  return (datetime.getFullYear() == 1);
}

const GetDateTimeString = (datetimeString: string) => {
  const datetime = new Date(datetimeString);
  const str = datetime.toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ");
  return str;
}

interface IProps {
  currentStatus: IDocumentDraftHistoryRecord;
  historyItems: IDocumentDraftHistoryRecord[];
  numberOfApprovals: number;
}

export default function DraftHistory(props: IProps) {
  const { appAuthContext } = useContext(AppAuthContextStore);
  //--------------------------------------------------------------------------- Current status
  let status = "";
  switch (props.currentStatus.step) {
    case "Draft":
      status = "Draft";
      break;
    case "FirstApproval":
      status = `Waiting for approval: 2 of 3`;
      break;
    case "FinalApproval":
      status = `Waiting for approval: ${props.numberOfApprovals + 1} of ${props.numberOfApprovals + 1}`;
      break;
    default:
      status = "Undefined";
      break;
  };
  //--------------------------------------------------------------------------- History
  let historyItems: IDocumentDraftHistoryRecord[] = [];
  if (props.currentStatus.step != "Draft" && props.historyItems) {
    let i = props.historyItems.length - 1;
    historyItems = [];
    while (i >= 0) {
      const historyItem = props.historyItems[i];
      historyItems.unshift(historyItem);
      i = (historyItem.step == "Draft") ? -1 : i - 1;
    }
  };
  const historyElements = historyItems?.map(item => {
    let caption: string;
    switch (item.step) {
      case "Draft":
        caption = "1st Approver";
        break;
      case "FirstApproval":
        caption = "2nd Approver";
        break;
      case "FinalApproval":
        caption = `${props.numberOfApprovals + 1}${props.numberOfApprovals + 1 == 2 ? 'nd' : 'rd'} Approver`;
        break;
      default:
        caption = "Undefined";
        break;
    };
    return (
      <div key={item.createdAt} className={styles.historyItem}>
        <span>
          {caption}:&nbsp;
        </span>
        <span className={styles.name}>
          {item.staffUser ? `${item.staffUser.userPrincipalName} ${item.staffUser.userId == appAuthContext.user?.userIdentity.id ? " (@Me)" : ""}` : "--"}
        </span>
      </div>);
  });
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.status}>
        {status}
      </div>
      {props.currentStatus.step == "Draft" ?
        <React.Fragment>
          <span>
            Current Editor:&nbsp;
          </span>
          <span className={styles.name}>
            {props.currentStatus.staffUser.userPrincipalName},
          </span>
          {IsMinDatetime(props.currentStatus.savedAt) ?
            <span>
              &nbsp;Not saved&nbsp;
            </span>
            :
            <React.Fragment>
              <span>
                &nbsp;Saved at:&nbsp;
              </span>
              <span className={styles.name}>
                {GetDateTimeString(props.currentStatus.savedAt)}
              </span>
            </React.Fragment>}
        </React.Fragment>
        :
        <React.Fragment>
          {historyElements}
        </React.Fragment>
      }
    </div>
  );
}