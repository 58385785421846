import Icon from '../../../../../../../common/icon/Icon';
import styles from './DictionaryNodeBadge.module.css';

interface IProps {
  iconId: string;
  color?: string;
}

export default function DictionaryNodeBadge(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      style={{ color: props.color }}
    >
      <Icon imageName={props.iconId} />
    </div>
  );
}