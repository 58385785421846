import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, submitNewCategoryRequests } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
import { apiPostPrivate, checkResponse } from "../../../utils/api";
import { IBusinessTagRequest } from "../../../context/business-context/IBusinessContext";

interface IProps {
  msalInstanse: IPublicClientApplication;
  authConfig?: IAuthConfig;
  businessId: string;
  businessTagRequests: IBusinessTagRequest[];
  abortSignal?: AbortSignal;
}

export default async function submitBusinessTagRequestsAsync(props: IProps): Promise<IBusinessTagRequest[] | undefined> {
  if (!props.authConfig) throw new Error("submitBusinessTagRequestsAsync: auth context is not provided");
  //---------------------------------------------------------------------------
  // Get only requests for tags used in the business
  const requestedTags: IBusinessTagRequest[] = [];
  props.businessTagRequests.forEach(request => {
    if (request.businessIds.includes(props.businessId)) {
      requestedTags.push(request);
    };
  });
  if (requestedTags.length == 0) return undefined;
  //---------------------------------------------------------------------------
  const response = await apiPostPrivate(
    props.msalInstanse,
    props.authConfig,
    `${apiBasePath}${stringFormatter(submitNewCategoryRequests, [props.businessId])}`,
    requestedTags,
    undefined,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "submitBusinessTagRequestsAsync", props.abortSignal?.aborted);
  const updatedRequests = response?.content as IBusinessTagRequest[];
  return updatedRequests;
};