import { EDraftItemState } from "../../../../../context/directory-editor-context/EDraftItemState";
import { IDraftStateResult } from "../../../../../context/directory-editor-context/IDirectoryDraftAbstract";
import { DirectoryDraftItem, IDirectoryDraftItem } from "../../../../../context/directory-editor-context/IDirectoryDraftItem";
import { v4 as uuidv4 } from "uuid";

// Common dictionary item. Other dictionaries with additional attributes should extend this interface
export interface IDictionaryItem<T> {
  id: string;
  path?: string; // For hierarchical dictionaries
  name?: T;
  description?: T;
  status?: IDictionaryItemStatus;
}

export interface IDictionaryItemStatus {
  editors: boolean;
  viewers: boolean;
}

export interface IDictionaryDraftItem extends IDirectoryDraftItem, IDictionaryItem<string> {
  guid?: string; // Unique guid to make it easier to data uniqueness (to use only in editor, we don't need it in db)
  original?: any;
  newPath?: string;
  oldPath?: string;
}

export class DictionaryDraftItem extends DirectoryDraftItem implements IDictionaryDraftItem {
  guid!: string;
  id!: string;
  path?: string;
  draftState!: EDraftItemState;
  name?: string;
  description?: string;
  status?: IDictionaryItemStatus;
  original?: DictionaryDraftItem;
  newPath?: string;
  oldPath?: string;
  //---------------------------------------------------------------------------
  get fullPath(): string {
    return this.path ? `${this.path}.${this.id}` : this.id;
  };
  get level(): number {
    return this.fullPath ? (this.fullPath.match(/\./g) || []).length : 0;
  };
  get isInactive(): boolean {
    return this.isDeleted || this.isRepositionedOld || !!this.newPath || !this.status || (!this.status.editors && !this.status.viewers);
  };
  //---------------------------------------------------------------------------
  constructor(source: IDictionaryDraftItem) {
    super(source);
    Object.assign(this, source);
    if (this.isNew && !this.guid) {
      this.guid = uuidv4();
    };
    if (this.original) {
      this.original = new DictionaryDraftItem(this.original);
    };
    if (this.description == null) this.description = undefined;
  };
  //---------------------------------------------------------------------------
  static getNew(source: IDictionaryDraftItemInitial): DictionaryDraftItem {
    const newItem = new DictionaryDraftItem({
      ...source,
      guid: uuidv4(),
      draftState: source.draftState ? source.draftState : EDraftItemState.IsNew
    });
    return newItem;
  };
  //---------------------------------------------------------------------------
  static getReadonly(source: IDictionaryDraftItem): DictionaryDraftItem {
    const newItem = new DictionaryDraftItem(source);
    return newItem;
  };
  //---------------------------------------------------------------------------
  getUpdated(update: IDictionaryDraftItemUpdate): DictionaryDraftItem {
    const updatedItem = new DictionaryDraftItem(this);
    Object.assign(updatedItem, update);
    updatedItem.id = updatedItem.id.replace(' ', '').replace('.', '').replace(',', '').replace(';', '').replace('/', '').replace('\\', '');
    updatedItem.description == '' ? updatedItem.description = undefined : updatedItem.description;
    updatedItem.refreshDraftState();
    return updatedItem;
  };
  //---------------------------------------------------------------------------
  refreshDraftState() {
    if (!this.name) {
      this.isNoTranslation = true;
    } else {
      this.isNoTranslation = false;
    };
  };
  //---------------------------------------------------------------------------
  checkChangesAndValidate(initial?: IDictionaryDraftItem): IDraftStateResult {
    let isUpdatedInSession = false;
    let isUpdated = false;
    let isValid = true;
    //-------------------------------------------------------------------------
    if (!initial) {
      // New item as compared to session initial state
      isUpdatedInSession = true;
    } else if (this.id !== initial.id) {
      isUpdatedInSession = true;
    } else if (this.name !== initial.name) {
      isUpdatedInSession = true;
    } else if (this.description != initial.description) {
      isUpdatedInSession = true;
    } else if (JSON.stringify(this.status) !== JSON.stringify(initial.status)) {
      isUpdatedInSession = true;
    } else if (this.path != initial.path) {
      isUpdatedInSession = true;
    }
    //-------------------------------------------------------------------------
    if (!this.original) {
      // New item as compared to original
      isUpdated = true;
    } else {
      if (this.id !== this.original.id) {
        // Is it possible at all?
        isUpdated = true;
      };
      if (this.name !== this.original.name || this.description != this.original.description) {
        isUpdated = true;
      };
      if (JSON.stringify(this.status) !== JSON.stringify(this.original.status)) {
        isUpdated = true;
      };
      if (this.path != this.original.path) {
        isUpdated = true;
      };
    };
    //-------------------------------------------------------------------------
    if (this.isNoTranslation || this.hasOnlyChild) {
      isValid = false;
    };
    //-------------------------------------------------------------------------
    this.isNothing = this.getIsNothing();
    return {
      isUpdatedInSession,
      isUpdated,
      isValid
    };
  };
  //---------------------------------------------------------------------------
  keywordFilterConditionIsMet(keyword: string): boolean {
    let result = this.id.toLowerCase().includes(keyword);
    if (this.name) {
      result = result || this.name.toLowerCase().includes(keyword);
    };
    if (this.description) {
      result = result || this.description.toLowerCase().includes(keyword);
    };
    return result;
  };
}

export interface IDictionaryDraftItemInitial {
  id: string;
  path?: string;
  name?: string;
  description?: string;
  draftState?: EDraftItemState;
  status?: IDictionaryItemStatus;
}

export interface IDictionaryDraftItemUpdate {
  id?: string;
  path?: string;
  name?: string;
  description?: string;
  status?: IDictionaryItemStatus;
}