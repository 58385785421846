import { IUiListItem } from "../../../components/common/input/input-dropdown/InputDropdown";
import { IBusinessTagRequest } from "../IBusinessContext";

export const GetUpdatedBusinessTagsDictionary = (
  businessTagsDictionary: IUiListItem[],
  tagRequest: IBusinessTagRequest
) => {
  //---------------------------------------------------------------------------
  const newDictionaryItem: IUiListItem = {
    id: tagRequest.id,
    name: { en: tagRequest.name },
    path: `${tagRequest.path}.${tagRequest.id}`, //tagRequest.path,
    isRequested: true,
    selected: false,
    isSubmitted: tagRequest.status != undefined
  };
  //-------------------------------------------------------------------------
  // Parse tag request path and loop through path items
  let dictionaryItems: IUiListItem[] | undefined = businessTagsDictionary;
  const requestedTagPathItems = tagRequest.path.split('.');
  for (let i = 0; i < requestedTagPathItems.length; i++) {
    //console.log(requestedTagPathItems[i])
    const dictionaryItem: IUiListItem | undefined = dictionaryItems?.find(t => t.id == requestedTagPathItems[i]);
    //console.log(dictionaryItem)
    if (dictionaryItem) {
      // Check if it's the last item in the path (we processed all path items) 
      if (i == requestedTagPathItems.length - 1) {
        // It's a tag where we should add a child
        if (dictionaryItem.items) {
          // If current dictionary item already has sub-items
          // Check if it's already present in the list of tags
          const index = dictionaryItem.items.findIndex(tr => tr.id == newDictionaryItem.id);
          //console.log(index)
          if (index == -1) {
            // Add it if it's not there yet
            dictionaryItem.items.push(newDictionaryItem);
          } else {
            // Update if it's there
            dictionaryItem.items[index] = newDictionaryItem;
          };
        } else {
          // Current dictionary item does not have children yet
          dictionaryItem.items = [newDictionaryItem];
        };
      } else {
        // Search current tag children
        dictionaryItems = dictionaryItem.items;
      };
    } else {
      console.error(`GetUpdatedBusinessTagsDictionary: requested tag path item [${requestedTagPathItems[i]}] not found`)
      break;
    };
  };
};

export const UpdateBusinessTags = (
  businessTag: IUiListItem,
  newTagRequest: IBusinessTagRequest
): IUiListItem => {
  //---------------------------------------------------------------------------
  const requestedBusinessTag: IUiListItem = {
    id: newTagRequest.id,
    name: { native: newTagRequest.name },
    isRequested: true,
    selected: false,
    isSubmitted: newTagRequest.status != undefined
  };
  //---------------------------------------------------------------------------
  // Get a copy of the root business tag
  const updatedBusinessTag: IUiListItem = JSON.parse(JSON.stringify(businessTag));
  console.log(updatedBusinessTag)
  //---------------------------------------------------------------------------
  const pathItems = newTagRequest.path.split('.');
  let tags = updatedBusinessTag.items;
  for (let i = 0; i < pathItems.length; i++) {
    const pathItem = pathItems[i];
    const tag = tags?.find(t => t.id == pathItem);
    console.log(i, pathItem, tag)
    if (tag) {
      if (i == pathItems.length - 1) {
        // It's a tag where we should add a child
        if (tag.items) {
          // Check if it's already present in the list of tags
          const existingTrIndex = tag.items.findIndex(tr => tr.id == requestedBusinessTag.id);
          if (existingTrIndex == -1) {
            // Add it if it's not there yet
            tag.items.push(requestedBusinessTag);
          } else {
            // Update if it's there
            tag.items[existingTrIndex] = requestedBusinessTag;
          };
        } else
          tag.items = [requestedBusinessTag];
        console.log(tag);
      } else {
        tags = tag.items;
      };
    } else break;
  }
  //---------------------------------------------------------------------------
  return updatedBusinessTag;
}