import React, { useContext } from "react";
import { ScreenType, useAppScreenContext } from "../../../../context/app-screen-context/AppScreenProvider";
import CombineStyles from "../../../../utils/combineStyles";
import GetText from "../../functions/GetText";
import GetValidationMessage from "../../functions/GetValidationMessage";
import InputCaption from "../input-caption/InputCaption";
import InputPrompt from "../input-prompt/InputPrompt";
import InputValidationMessage from "../input-validation-message/InputValidationMessage";
import { IValidationMessage } from "../input-validation/TUiValidation";
import styles from "./InputContainer.module.css";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";

export interface IInput {
  id: string;
  caption: any;
  hint: any;
}

interface IProps {
  ui: IInput;
  hideCaption: boolean;
  hideHint: boolean;
  isValid: boolean;
  validationMessages?: IValidationMessage[]; // caller should provide it because it depends on particular input
  children: React.ReactNode;
}

export default function InputContainer(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  //---------------------------------------------------------------------------
  const hint = GetText({
    appUiContext: appUiContext, 
    elementId: props.ui.id,
    elementPart: "Hint",
    text: props.ui.hint,
  });
  //---------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    screenType == ScreenType.Mobile ? styles.mobile : ""
  ]);
  //---------------------------------------------------------------------------
  const validationMessages = props.validationMessages?.filter(message => message !== undefined)
    .map((message, index) => {
      let result =
        <InputValidationMessage
          key={message.id ? message.id : `InputValidationMessage_${index}_key`}
          string={GetValidationMessage(appUiContext, message.id, message.value)}
        />;
      return result;
    });
  //---------------------------------------------------------------------------
  return (
    <div id={props.ui.id} className={containerStyles}>
      {!props.hideCaption &&
        <InputCaption id={props.ui.id} text={props.ui.caption} />
      }
      <div className={styles.content}>
        {props.children}
        {!props.hideHint && props.isValid &&
          <InputPrompt string={hint} />
        }
        {!props.isValid &&
          validationMessages
        }
      </div>
    </div>
  );
}