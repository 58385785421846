import { useContext } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { WizardState } from '../IWizardState';
import { IUiBusinessWizard } from '../IUiBusinessWizardV2';
import GetDescription from '../../../common/functions/GetDescription';
import WizardStepWrapper from '../step-wrapper/WizardStepWrapper';
import { IUiOption } from '../../../common/options/IUiOption';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import { ClassAdModulePublished } from '../../../homepage/ClassAdLanguageSetPublished';
import AdModule from '../../../common/ad-modules/AdModule';
import styles from './StepPreview.module.css';
import { IUiMenuContent } from '../../../common/options/menus/IUiMenuContent';

interface IProps {
  state: WizardState;
  ui: IUiBusinessWizard;
  onUpdate: (state: WizardState) => void;
  onOpenEditor: () => void;
  onExit: () => void;
}

export default function StepPreviewBusinessPage(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const stepUi = props.ui.stepsDictionary.find(item => item.id == props.state.selectedStep);
  const stepData = props.state.steps.find(item => item.stepId == props.state.selectedStep);
  const prompt = GetDescription(appUiContext, props.state.selectedStep, stepUi?.description);
  const isCompleted = stepData?.isCompleted ? true : false;
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    let updatedState: WizardState;
    switch (optionId) {
      case "OptionBack":
        updatedState = props.state.moveToPreviousStep();
        props.onUpdate(updatedState);
        break;
      case "OptionFinish/OptionPublish":
        updatedState = props.state.moveToNextStep(!isCompleted);
        props.onUpdate(updatedState);
        break;
      case "OptionFinish/OptionOpenEditor":
        props.onOpenEditor();
        break;
      default:
        throw new Error(`StepPreviewBusinessPage: Unknown optionId ${optionId}`);
    };
  };
  //---------------------------------------------------------------------------
  const subMenuOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionPublish":
        subMenuOptions.push({
          ...option,
          iconId: "send",
        });
        break;
      case "OptionOpenEditor":
        subMenuOptions.push({
          ...option,
          iconId: "pencil"
        });
        break;
    };
  });
  const subMenu: IUiMenuContent = {
    id: `${props.ui.menuContent.id}.Submenu`,
    directoryId: props.ui.menuContent.directoryId,
    elementType: props.ui.menuContent.elementType,
    hint: props.ui.menuContent.hint,
    options: subMenuOptions
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionBack":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowLeft",
          isDisabled: false
        });
        break;
      case "OptionFinish":
        updatedOptions.push({
          ...option,
          iconId: "checkeredFlag",
          isDisabled: !props.state.adLanguageSet,
          isDefault: true,
          action: subMenu
        });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      alignLeft={true}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  const adModules = props.state.adLanguageSet?.adModules?.filter(x => x.usage.isBusinessPage).map(adModule => {
    const adModulePublished = new ClassAdModulePublished(adModule);
    return (
      <AdModule
        key={adModulePublished.id}
        adModule={adModulePublished}
        isReadonly={true}
        isClickable={false}
        useExternalLayout={false}
      />
    )
  });
  //---------------------------------------------------------------------------
  return (
    <WizardStepWrapper
      ui={props.ui}
      promptString={prompt}
      optionsElement={formOptions}
      onExit={props.onExit}
    >
      <div className={styles.modules}>
        {adModules}
      </div>
    </WizardStepWrapper>
  );
}