import styles from "./InputDropdown.module.css";
import { TUiInputDropdown } from "./TUiInputDropdown";
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import InputDropdownPopup from "./input-dropdown-popup/InputDropdownPopup";
import InputContainer from "../input-container/InputContainer";
import GetName from "../../functions/GetName";
import { IValidationMessage } from "../input-validation/TUiValidation";
import CombineStyles from "../../../../utils/combineStyles";
import Icon from "../../icon/Icon";
import GetPlaceholder from "../../functions/GetPlaceholder";
import { IUiDictionaryItem } from "../../dictionaries/IUiDictionaryItem";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";

// NOTE: Refactor to accept dictionary id and get available values from appUiContext.dictionaries!

export interface IUiListItem {
  id: string;
  group?: boolean;//number;
  name?: any; // text
  description?: any; // text
  path?: string;
  searchString?: any; // text
  items?: IUiListItem[];
  selected: boolean;
  isRequested?: boolean;
  isSubmitted?: boolean;
}

export function getIUiListItem(i: IUiDictionaryItem): IUiListItem {
  return {
    id: i.id,
    group: false,
    name: i.name,
    description: i.description,
    path: i.path,
    searchString: undefined,
    items: undefined,
    selected: false,
    isRequested: false,
    isSubmitted: false
  };
}

const GetItemById = (targetItemId: string, items: IUiListItem[]): IUiListItem | undefined => {
  var resultItem: IUiListItem | undefined = undefined;
  //---------------------------------------------------------------------------
  if (items && items.length > 0) {
    for (let i = 0; i < items.length; i++) {
      const currentItem: IUiListItem = items[i];
      if (currentItem.id == targetItemId) {
        resultItem = items[i];
        break;
      } else {
        // Look in sub-items
        if (currentItem.items && currentItem.items?.length > 0) {
          const subItem = GetItemById(targetItemId, currentItem.items);
          if (subItem) {
            resultItem = subItem;
            break;
          };
        };
      };
    };
  };
  //---------------------------------------------------------------------------
  return resultItem;
}

interface IProps {
  ui: TUiInputDropdown;
  data?: string; // option id
  disabled: boolean;
  isFlat?: boolean; // works only if disabled=true, means border won't be displayed
  isAutoComplete: boolean;
  hideCaption?: boolean;
  hideHint?: boolean;
  hideDropdownDescriptions?: boolean;
  sortSuggestions?: boolean;
  isValid?: boolean;
  showAlert?: boolean;
  onUpdate?: (optionId: string, isValid: boolean, elementId: string) => void;
}

export default function InputDropdown(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [selectedItem, setSelectedItem] = useState<IUiListItem>();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const inputAreaId = `${props.ui.id}_inputArea`;
  //---------------------------------------------------------------------------
  const isFlat = props.isFlat == undefined ? false : props.isFlat;
  const isClearable = props.ui.validation?.required ? false : true;
  const hideCaption = props.hideCaption == undefined ? false : props.hideCaption;
  const hideHint = props.hideHint == undefined ? false : props.hideHint;
  const showAlert = (props.showAlert === undefined) ? false : props.showAlert;
  //--------------------------------------------------------------------------- Set default value
  useEffect(() => {
    var defaultItemId: string | undefined = undefined;
    if (props.data) {
      defaultItemId = props.data;
    } else if (props.ui.defaultValue) {
      defaultItemId = props.ui.defaultValue;
    };
    //-------------------------------------------------------------------------
    //const defaultItem: IUiListItem | undefined = props.data ? GetItemById(props.data, props.ui.availableValues) : undefined;
    let defaultItem: IUiListItem | undefined = defaultItemId ? GetItemById(defaultItemId, props.ui.availableValues) : undefined;
    //console.log(props.data)
    if (props.data && !defaultItem) {
      defaultItem = {
        id: props.data,
        name: null,
        description: null,
        selected: false
      };
      console.log(defaultItem)
    };
    //-------------------------------------------------------------------------
    setSelectedItem(defaultItem);
    setIsValid(true);
    if (defaultItem) {
      //setSelectedItem(defaultItem);
      //setIsValid(true);
    } else if (props.ui.validation?.required) {
      setIsValid(false);
    };
  }, [props.data]);
  //--------------------------------------------------------------------------- Set validation state based on props
  useEffect(() => {
    // If parent components passes the state it should prevail
    if (props.isValid != undefined) {
      setIsValid(props.isValid);
    };
  }, [props.isValid]);
  //--------------------------------------------------------------------------- 
  // Display list of suggestions (available values)
  const showSuggestions = () => {
    setPopupOpen(true);
  };
  //---------------------------------------------------------------------------
  // If user selects an item from the list of suggestions
  const onOptionSelect = (option: IUiListItem) => {
    //console.log(option)
    setPopupOpen(false);
    setSelectedItem(option);
    setIsValid(true);
    //-------------------------------------------------------------------------
    if (props.onUpdate) {
      props.onUpdate(option.id, true, props.ui.id);
    };
  };
  //---------------------------------------------------------------------------
  const onEmptyValueSelect = () => {
    setPopupOpen(false);
    if (!props.ui.validation?.required) {
      setSelectedItem(undefined);
      setIsValid(true);
      //-------------------------------------------------------------------------
      if (props.onUpdate) {
        props.onUpdate("", true, props.ui.id);
      };
    };
  };
  //---------------------------------------------------------------------------
  const onClosePopup = () => {
    setPopupOpen(false);
  };
  //---------------------------------------------------------------------------
  const validationMessage: IValidationMessage | undefined = (isValid || props.disabled) ? undefined : {
    id: props.ui.validation?.required?.errorMessageId,
    value: props.ui.validation?.required?.errorMessage
  } as IValidationMessage;
  //---------------------------------------------------------------------------
  const inputAreaStyles: CSSProperties = {
    border: props.disabled ? '' : isValid ? (showAlert ? '3px solid var(--clr_accent_attention)' : '') : '3px solid var(--clr_accent_wrong)'
  };
  //---------------------------------------------------------------------------
  return (
    <InputContainer
      ui={props.ui}
      hideCaption={hideCaption}
      hideHint={hideHint}
      isValid={isValid}
      validationMessages={validationMessage ? [validationMessage] : []}
    >
      <div
        id={inputAreaId}
        className={CombineStyles([
          styles.container,
          props.disabled ? styles.disabled : "",
          isFlat ? styles.flat : ""
        ])}
        style={inputAreaStyles}
      >
        <div
          className={CombineStyles([styles.value, selectedItem ? "" : styles.placeholder])}
          onClick={showSuggestions}
        >
          <div className={CombineStyles([styles.valueName, isFlat ? styles.flat : ""])}>
            {selectedItem ?
              GetName(appUiContext, props.data ? props.data : props.ui.id, selectedItem?.name)
              :
              GetPlaceholder(appUiContext, props.ui.id, props.ui.placeholder)}
          </div>
          {!props.disabled &&
            <div className={styles.option}>
              <Icon imageName="downArrowSolid" />
            </div>}
        </div>
      </div>
      {popupOpen && !props.disabled && props.ui.availableValues.length > 0 &&
        <InputDropdownPopup
          ui={props.ui}
          id={`${props.ui.id}_popup`}
          anchorId={inputAreaId}
          selectedOptionId={selectedItem?.id}
          isAutoComplete={props.isAutoComplete}
          hideDropdownDescriptions={props.hideDropdownDescriptions}
          sortSuggestions={props.sortSuggestions}
          onSelect={onOptionSelect}
          onEmptyValueSelect={onEmptyValueSelect}
          onClose={onClosePopup}
        />}
    </InputContainer>
  );
}