import { useContext } from "react";
import { ScreenType, useAppScreenContext } from "../../../../context/app-screen-context/AppScreenProvider";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import CombineStyles from "../../../../utils/combineStyles";
import GetText from "../../functions/GetText";
import styles from "./InputCaption.module.css";

interface IProps {
  id: string;
  text: any;
}

export default function InputCaption(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  //-----------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    screenType === ScreenType.Mobile ? styles.mobile : ""
  ]);
  //-----------------------------------------------------------------
  return (
    <div className={containerStyles}>
      {GetText({
        appUiContext: appUiContext, 
        elementId: props.id,
        elementPart: "Caption",
        text: props.text,
      })}
    </div>
  );
}