import React, { CSSProperties } from 'react';
import styles from './DictionaryTranslatorValue.module.css';
import { IValue } from '../DictionaryTranslatorNodeV1';
import CombineStyles from '../../../../../../utils/combineStyles';
import { ELogLevel, LogInformation } from '../../../../../../utils/logging';

interface IProps {
  draftLocale?: string;
  data?: IValue;
  editModeIsEnabled: boolean;
  style?: CSSProperties;
  maxWidthEm?: string;
  onDoubleClick: () => void;
  onSelectText: () => void;
}

export default function DictionaryTranslatorValue(props: IProps) {
  //---------------------------------------------------------------------------
  //LogInformation(ELogLevel.Information, props.data?.fallbackAccepted, props.data?.locale, props.draftLocale, props.data?.value);
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>

      {/* Locale for current value */}
      <td
        className={styles.text}
        style={props.style}
        onDoubleClick={props.onDoubleClick}
      >
        {props.data?.locale !== props.draftLocale &&
          <span
            className={props.editModeIsEnabled ? styles.clickable : ""}
            onClick={props.onSelectText}
          >
            {props.data?.locale}
          </span>}
      </td>

      {/* Value */}
      <td
        className={styles.text}
        style={{ ...props.style, maxWidth: props.maxWidthEm ? `${props.maxWidthEm}em` : "" }}
        onDoubleClick={props.onDoubleClick}
      >
        <span
          className={props.editModeIsEnabled ? styles.clickable : ""}
          onClick={props.onSelectText}
        >
          {props.data?.value}
        </span>
      </td>

    </React.Fragment>
  );
}