import React from "react";
import CombineStyles from "../../../utils/combineStyles";
import styles from "./wikiSpinner.module.css";

interface IProps {
  show: boolean;
  fullScreen?: boolean;
  color?: string;
  zIndex?: number;
}

export default function WikiSpinner(props: IProps) {
  const fullScreen = props.fullScreen == undefined ? false : props.fullScreen;
  //---------------------------------------------------------------------------
  return (props.show ?
    <div
      id="wiki-spinner"
      className={CombineStyles([
        styles.container,
        fullScreen ? styles.fullscreen : ""
      ])}
      style={{ zIndex: props.zIndex ? props.zIndex : "" }}
    >

      <div
        className={styles.background}
        style={{ backgroundColor: props.color ? props.color : undefined }}
      />

      <div className={styles.spinner}>
        <div className={styles.spinnerBox}>
          {/* <Icon
            imageName="spinner"
            spin={true}
          /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%" height="100%"
            viewBox="0 0 16 16"
          >
            <path
              fill="currentColor"
              d="M2.501 8a5.5 5.5 0 1 1 5.5 5.5A.75.75 0 0 0 8 15a7 7 0 1 0-7-7a.75.75 0 0 0 1.501 0"
            />
          </svg>
        </div>
      </div>

    </div>
    :
    <></>
  );
};
