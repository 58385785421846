import React, { useState } from 'react';
import ApiKeyEditForm, { IUiApiKeyEditForm } from '../apikey-edit-form/ApiKeyEditForm';
import styles from './ApiKeyCard.module.css';
import Icon from '../../../../common/icon/Icon';

export class ApiKey {
  name!: string;
  createdBy!: string; // User UPN
  private _createdOn!: Date;
  private _expiresOn?: Date;
  get createdOn(): Date {
    return this._createdOn;
  }
  set createdOn(value: any) {
    if (value != undefined)
      this._createdOn = new Date(value);
  }
  get expiresOn(): Date | undefined {
    return this._expiresOn;
  }
  set expiresOn(value: any) {
    if (value != undefined)
      this._expiresOn = new Date(value);
    else
      this._expiresOn = undefined;
  }
  description?: string;
  value?: string;
  displayValue?: string;
  isRequired!: boolean;
  //-------------------------------------------------------------------------------------
  constructor(source: any, spread?: any) {
    if (source !== undefined || spread !== undefined) {
      Object.assign(this, source, spread);
    }
  }
  //-------------------------------------------------------------------------------------
  static fromArray(source: any): ApiKey[] {
    let result: ApiKey[] = [];
    if (source)
      source.forEach((item: any) => result.push(new ApiKey(item)));
    return result;
  }
  //-------------------------------------------------------------------------------------
  toJSON() {
    let result = {
      ...this,
      expiresOn: this._expiresOn,
      _expiresOn: null,
      createdOn: this._createdOn,
      _createdOn: null,
    };
    delete result["_createdOn"];
    delete result["_expiresOn"];
    return result;
  }
}

export function CheckExpirationDate(dateToCheck?: Date): "IsOkay" | "ExpiresSoon" | "Expired" {
  if (dateToCheck) {
    const intervalInDays = 15;
    //---------------------------------------------------------------------------
    const dateDiffInDays = (a: Date, b: Date) => {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }
    //---------------------------------------------------------------------------
    const currentDate = new Date();
    const expirationDate = new Date(dateToCheck);
    if (dateDiffInDays(currentDate, expirationDate) < intervalInDays) {
      if (expirationDate < currentDate) {
        return "Expired"
      } else {
        return "ExpiresSoon"
      };
    } else
      return "IsOkay";
  } else return "IsOkay";
}

interface IProps {
  ui: IUiApiKeyEditForm;
  data?: ApiKey;
  onClose?: () => void;
  onUpdate: (updatedApiKey: ApiKey, isNew: boolean) => void;
}

export default function ApiKeyCard(props: IProps) {
  const [editMode, setEditMode] = useState(props.data ? false : true);
  //---------------------------------------------------------------------------
  const onClose = () => {
    if (props.data) {
      setEditMode(false);
    } else {
      props.onClose && props.onClose();
    };
  };
  //---------------------------------------------------------------------------
  const onEdit = () => {
    setEditMode(true);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedApiKey: ApiKey, isNew: boolean) => {
    setEditMode(false);
    props.onUpdate(updatedApiKey, isNew);
  };
  //---------------------------------------------------------------------------
  let expirationDateStyle = "";
  if (props.data?.expiresOn) {
    switch (CheckExpirationDate(props.data.expiresOn)) {
      case "ExpiresSoon":
        expirationDateStyle = styles.attention;
        break;
      case "Expired":
        expirationDateStyle = styles.expired;
        break;
    };
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      {editMode ?
        <ApiKeyEditForm
          ui={props.ui}
          data={props.data}
          onClose={onClose}
          onUpdate={onUpdate}
        />
        :
        <React.Fragment>
          <div className={styles.details}>
            <div className={styles.name}>
              {props.data?.name}
            </div>
            {props.data?.description &&
              <div>
                {props.data.description}
              </div>}
            <div>
              <span className={styles.system}>Created on:</span>
              &nbsp;
              <span>{props.data?.createdOn ? new Date(props.data.createdOn).toLocaleString() : "--"}</span>
            </div>
            <div>
              <span className={styles.system}>Created by:</span>
              &nbsp;
              <span>{props.data?.createdBy}</span>
            </div>
            <div>
              <span className={styles.system}>Expires on:</span>
              &nbsp;
              <span className={expirationDateStyle}>
                {props.data?.expiresOn ? new Date(props.data.expiresOn).toLocaleString() : "--"}
              </span>
            </div>
          </div>
          <div className={styles.options}>
            <div className={styles.editOption} onClick={onEdit}>
              <Icon imageName="pencil" />
            </div>
            {props.data?.isRequired &&
              <div className={styles.isRequired}>
                <Icon imageName="star" />
              </div>}
          </div>
        </React.Fragment>}
    </div>
  );
}