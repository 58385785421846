export default function () {
  var accentMainColour = "var(--clr_accent_main)";
  //---------------------------------------------------------------------------
  return (
    <svg
      width="100%" height="100%"
      viewBox="0 0 207 264"
      xmlns="http://www.w3.org/2000/svg">

      {/* magnifying glass */}
      <g
        transform="matrix(.99729 0 0 .99787 -728.266 -2287.816)"
        fill={accentMainColour}
      >
        <path d="M833.88 2292.69a103.634 102.96 0 0 0-103.633 102.959 103.634 102.96 0 0 0 103.633 102.96 103.634 102.96 0 0 0 103.635-102.96 103.634 102.96 0 0 0-103.635-102.96Zm0 31.491a71.937 71.469 0 0 1 71.937 71.468 71.937 71.469 0 0 1-71.937 71.469 71.937 71.469 0 0 1-71.936-71.47 71.937 71.469 0 0 1 71.936-71.467z" />
        <rect width="97.82" height="32.427" x="2569.835" y="515.926" ry="16.213" rx="16.181" transform="matrix(.5194 .85453 -.85618 .51667 0 0)" />
      </g>

      {/* i letter */}
      <g
        transform="matrix(.99729 0 0 .99787 -728.266 -2287.816)"
        fill="currentColor"
      >
        <ellipse cy="2395.649" cx="833.881" rx="16.429" ry="16.323" />
        <path d="M833.235 2420.19c-8.744 0-15.784 6.993-15.784 15.68v21.51a64.048 63.631 0 0 0 15.138 1.88 64.048 63.631 0 0 0 16.43-2.162v-21.228c0-8.687-7.04-15.68-15.784-15.68zm15.783 84.623a111.26 110.536 0 0 1-16.43 1.352 111.26 110.536 0 0 1-15.137-1.154v36.56c0 8.687 7.04 15.68 15.784 15.68s15.783-6.993 15.783-15.68z" />
      </g>

    </svg>);
}