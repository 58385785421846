import { group } from "console";
import { ClassDirectoryItemStateGroup, IDirectoryDraftItemStateGroup } from "./directory-editor-settings/IDirectoryDraftItemStateGroup";
import { EDraftItemState } from "./EDraftItemState";
import { ClassDirectoryEditorContext, IDirectoryDraftFilter, IDirectoryEditorContextData } from "./IDirectoryEditorContextData";
import { DirectoryEditorState, IDirectoryEditorStateInitial, IDirectoryEditorStateUpdate } from "./IDirectoryEditorState";

interface IInitialize {
  type: "Initialize";
  data: IDirectoryEditorStateInitial;
}
interface IUpdate {
  type: "Update";
  data: IDirectoryEditorStateUpdate;
}
interface IDisableEditMode {
  type: "DisableEditMode";
};
interface IUpdateFilter {
  type: "UpdateFilter";
  updatedStateGroups?: ClassDirectoryItemStateGroup[];
  keyword?: string;
}
interface ICleanup {
  type: "Cleanup";
}
/* interface ISetDictionariesList {
  type: "SetDictionariesList";
  data: IDictionariesListItem[];
} */

export type Actions = IInitialize | IUpdate | IDisableEditMode | IUpdateFilter | ICleanup; // | ISetDictionariesList;

export const DirectoryEditorContextReducer = (state: ClassDirectoryEditorContext, action: Actions) => {
  var updatedState: ClassDirectoryEditorContext = state;
  var updatedEditorState: DirectoryEditorState;
  switch (action.type) {
    //-------------------------------------------------------------------------
    case "Initialize":
      const allStateGroups = action.data.stateGroups.map(group => new ClassDirectoryItemStateGroup(group));
      updatedEditorState = DirectoryEditorState.getInitialState(action.data);
      updatedState = new ClassDirectoryEditorContext({
        id: action.data.id,
        directoryType: action.data.directoryType,
        basePath: action.data.basePath,
        name: action.data.name,
        description: action.data.description,
        editorState: updatedEditorState,
        editMode: action.data.editMode,
        filter: {
          stateGroups: allStateGroups,
          draftState: EDraftItemState.All
        },
        dictionariesList: action.data.dictionariesList,
        dictionaryOriginals: action.data.dictionaryOriginals,
        isInitialized: true,
        stateGroups: allStateGroups
      });
      updatedState.updateStateGroups();
      break;
    //-------------------------------------------------------------------------
    case "Update":
      updatedEditorState = DirectoryEditorState.getUpdatedState(state.editorState, action.data);
      updatedState = new ClassDirectoryEditorContext({
        ...state,
        id: updatedEditorState.draft.id,
        name: updatedEditorState.draft.name?.toString(), // NOTE: temporary, update for ITranslation
        description: updatedEditorState.draft.description?.toString(), // NOTE: temporary, update for ITranslation
        editorState: updatedEditorState,
        editMode: action.data.editMode == undefined ? state.editMode : action.data.editMode
      });
      updatedState.updateStateGroups();
      break;
    //-------------------------------------------------------------------------
    case "DisableEditMode":
      updatedEditorState = DirectoryEditorState.restoreInitialState(state.editorState);
      updatedState = new ClassDirectoryEditorContext({
        ...state,
        editorState: updatedEditorState,
        editMode: false
      });
      updatedState.updateStateGroups();
      break;
    //-------------------------------------------------------------------------
    case "UpdateFilter":
      const updatedFilter: IDirectoryDraftFilter = {
        draftState: EDraftItemState.None,
        stateGroups: action.updatedStateGroups ? action.updatedStateGroups : state.filter.stateGroups,
        keyword: action.keyword !== undefined ? action.keyword.toLocaleLowerCase() : state.filter.keyword
      };
      updatedState = new ClassDirectoryEditorContext({
        ...state,
        filter: updatedFilter
      });
      updatedState.updateFilterDraftState();
      break;
    //-------------------------------------------------------------------------
    case "Cleanup":
      updatedState = {} as ClassDirectoryEditorContext;
      break;
  };
  return updatedState;
}