//import styles from './IconFilter.module.css';

interface IProps {
  expanded: boolean;
}

export default function IconSort(props: IProps) {
  const unit = 10;
  const transform = props.expanded ? "scale(1,-1) translate(0,-50)" : undefined;
  //---------------------------------------------------------------------------
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="1em" height="1em"
      viewBox="0 0 50 50">
      <g
        fill="currentColor"
        stroke="currentColor"
        strokeLinejoin="round"
        transform={transform}
      >
        <rect
          rx={5}
          width={50}
          height={unit}
          x="0"
          y="0"
        />
        <rect
          rx={5}
          width={30}
          height={unit}
          x="0"
          y="20"
        />
        <rect
          rx={5}
          width={10}
          height={unit}
          x="0"
          y="40"
        />
      </g>
    </svg>
  );
}