import { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import styles from "./PageSignInOut.module.css";
import { removeUserLastSignIn, removeUserLocally } from "../../context/app-auth-context/user";
import { useMsal } from "@azure/msal-react";
import { AppAuthContextStore } from "../../context/app-auth-context/AppAuthContext";
import { InteractionStatus } from "@azure/msal-browser";
import useNavigateWithContext from "../../hooks/useNavigateWithContext";

export const authActionParam = 'action';
export const flagSignIn = 'in';
export const flagSignOut = 'out';

export function PageSignInOut() {
  const { instance, inProgress } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const navigate = useNavigateWithContext();
  const location = useLocation();
  const currentAction = useRef<string>("empty");
  // currentAction value and { postLogoutRedirectUri: "/signinout?action=signedout" } is a workaround for double render problem for the component
  // and consequest issue with 'interation in progress' error on the second render
  //---------------------------------------------------------------------------
  useEffect(() => {
    console.log(location)
    console.log("signinout: ", currentAction, inProgress)
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const action = searchParams.get(authActionParam);
      //console.log("signinout: ", searchParams, action)
      switch (action) {
        //---------------------------------------------------------------------
        case flagSignOut:
          console.log("signinout: trying to sign user out...")
          if (appAuthContext.user && currentAction.current == "empty" && inProgress !== InteractionStatus.Logout) {
            console.log("signinout: user is here and logout is not started yet...")
            currentAction.current = "signOut";
            removeUserLocally(appAuthContext.user?.userIdentity.id);
            removeUserLastSignIn(appAuthContext.user?.userIdentity.id);
            instance.logoutRedirect({ postLogoutRedirectUri: "/signinout?action=signedout" });
          } else {
            !appAuthContext.user && console.log("signinout: no user...")
            inProgress === InteractionStatus.Logout && console.log("signinout: it's already is signing out...")
            navigate("/");
          };
          break;
        //---------------------------------------------------------------------
        case "signedout":
          console.log("signinout: user is signed out...", currentAction.current)
          currentAction.current = "empty";
          navigate("/");
          break;
      };
    } else {
      console.log("signinout: no action...");
      navigate("/");
    };

    // 
    // const pathParts = location.pathname.split(`/`);
    // if (pathParts.length > 0) {
    //   let direction = pathParts[pathParts.length - 1];
    //   switch (direction) {
    //     case flagSignIn: {

    //     } break;
    //     case flagSignOut: {
    //       console.log("Trying to sign user out...")
    //       removeUserLocally(appAuthContext.user?.userIdentity.id);
    //       removeUserLastSignIn(appAuthContext.user?.userIdentity.id);
    //       // Log out
    //       console.log(inProgress)
    //       if (appAuthContext.user && inProgress !== InteractionStatus.Logout) {
    //         console.log("user is here and logout is not started yet...")
    //         instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    //       } else if (inProgress == InteractionStatus.None) {
    //         console.log("Somewhere in between...", appAuthContext.user)
    //         //navigate("/");
    //       };
    //     } break;
    //   }
    // }
  }, [location, inProgress, appAuthContext.user]);
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        Logging out...
      </div>
    </div>
  );
}