import { useContext } from 'react';
import { IUiOption } from '../../../IUiOption';
import styles from './MainMenuOption.module.css';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import GetCaption from '../../../../functions/GetCaption';
import Icon from '../../../../icon/Icon';
import CombineStyles from '../../../../../../utils/combineStyles';

interface IProps {
  ui: IUiOption;
  isSelected: boolean;
  onSelect: (optionId: string) => void;
}

export default function MainMenuOption(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    props.onSelect(props.ui.id);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        props.isSelected ? styles.selected : ""
      ])}
      onClick={onSelect}
    >

      {props.ui.iconId &&
        <div className={styles.icon}>
          <Icon imageName={props.ui.iconId} />
        </div>}

      {GetCaption(appUiContext, props.ui.id, props.ui.caption)}

    </div>
  );
}