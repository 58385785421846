import { ChangeEvent, useContext, useRef, useState } from 'react';
import styles from './LocaleSelector.module.css';
import FormOptions from '../../../../../common/form-options-bar/FormOptions';
import { TUiMenuContent } from '../../../../../common/menu/menu-content/TUiMenuContent';
import { TUiMenuOption } from '../../../../../common/menu/menu-content/menu-option/TUiMenuOption';
import { IUiDictionaryItem } from '../../../../../common/dictionaries/IUiDictionaryItem';
import LocalesList from './locales-list/LocalesList';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';

export function CheckKeyword(dictionaryItem: IUiDictionaryItem, keyword: string): boolean {
  let searchstring = keyword.toLocaleLowerCase();
  let id = dictionaryItem.id.includes(searchstring);
  let name = JSON.stringify(dictionaryItem.name).toLowerCase().includes(searchstring);
  //let nativeName = culture.nativeName.toLocaleLowerCase().includes(searchstring);
  return (id || name);
}

const tmpUi: TUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  visible: true,
  disabled: false,
  options: [{
    id: "OkButton",
    directoryId: "",
    elementType: "",
    index: 0,
    caption: { en: "Ok" },
    hint: null,
    priorityLevel: 0,
    iconFile: "check",
    isDefault: false,
    disabled: true,
    visible: true,
    canHideCaption: false,
    action: null
  }, {
    id: "CancelButton",
    directoryId: "",
    elementType: "",
    index: 0,
    caption: { en: "Cancel" },
    hint: null,
    priorityLevel: 0,
    iconFile: "close",
    isDefault: false,
    disabled: false,
    visible: true,
    canHideCaption: false,
    action: null
  }],
  optionGroups: []
}

export interface IFilterLocales {
  showOnlySupported: boolean;
  keyword?: string;
}

interface IProps {
  selectedLocales?: string[];
  showNativeNames?: boolean;
  onSelect: (selectedCulture: IUiDictionaryItem) => void;
  onClose: () => void;
}

export default function LocaleSelector(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [showOnlySupported, setShowOnlySupported] = useState(false);
  const [showOnlyNotSupported, setShowOnlyNotSupported] = useState(false);
  const [keyword, setKeyword] = useState<string>("");
  const [selectedLocale, setSelectedLocale] = useState<IUiDictionaryItem>();
  const availableOptions = useRef<IUiDictionaryItem[] | undefined>(appUiContext.dictionaries?.locales);
  //---------------------------------------------------------------------------
  const onSupportedLocalesSwitch = () => {
    setShowOnlySupported(!showOnlySupported);
    setFilteredOptions(!showOnlySupported, showOnlyNotSupported, keyword);
  };
  //---------------------------------------------------------------------------
  const onUnsupportedLocalesSwitch = () => {
    setShowOnlyNotSupported(!showOnlyNotSupported);
    setFilteredOptions(showOnlySupported, !showOnlyNotSupported, keyword);
  };
  //---------------------------------------------------------------------------
  const onKeywordUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedKeyword = event.target.value;
    if (setFilteredOptions(showOnlySupported, showOnlyNotSupported, updatedKeyword))
      setKeyword(updatedKeyword);
  };
  //---------------------------------------------------------------------------
  function setFilteredOptions(showOnlySupported: boolean, showOnlyNotSupported: boolean, keyword: string): boolean {
    let updatedAvailableOptions = appUiContext.dictionaries?.locales;
    //-------------------------------------------------------------------------
    if (showOnlySupported || showOnlyNotSupported) {
      updatedAvailableOptions = updatedAvailableOptions?.filter(item => {
        const supportedItem = appUiContext.dictionaries?.supportedLocales?.find(l => l.id == item.id);
        return showOnlySupported ? !!supportedItem : !supportedItem;
      });
    };
    //-------------------------------------------------------------------------
    if (keyword) {
      updatedAvailableOptions = updatedAvailableOptions?.filter(item => {
        return CheckKeyword(item, keyword);
      });
    };
    //-------------------------------------------------------------------------
    availableOptions.current = updatedAvailableOptions;
    if (updatedAvailableOptions && updatedAvailableOptions.length > 0)
      return true;
    else
      return false;
  };
  //---------------------------------------------------------------------------
  const onLocaleSelect = (selectedLocale: IUiDictionaryItem, confirmed: boolean) => {
    //console.log(selectedLocale, confirmed)
    if (confirmed) {
      props.onSelect(selectedLocale);
    } else {
      setSelectedLocale(selectedLocale);
    };
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OkButton":
        selectedLocale && props.onSelect(selectedLocale);
        break;
      case "CancelButton":
        props.onClose();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: TUiMenuOption[] = [];
  tmpUi.options.forEach(option => {
    switch (option.id) {
      case "OkButton":
        updatedOptions.push({
          ...option,
          disabled: !selectedLocale,
          isDefault: !!selectedLocale
        })
        break;
      case "CancelButton":
        updatedOptions.push(option);
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      {props.showNativeNames ?
        <div
          className={styles.checkbox}
          onClick={onSupportedLocalesSwitch}
        >
          <input
            type="checkbox"
            checked={showOnlySupported}
            readOnly
          />
          <span>
            Show only supported locales
          </span>
        </div>
        :
        <div
          className={styles.checkbox}
          onClick={onUnsupportedLocalesSwitch}
        >
          <input
            type="checkbox"
            checked={showOnlyNotSupported}
            readOnly
          />
          <span>
            Show only not supported locales
          </span>
        </div>}

      <div className={styles.filter}>
        <input
          value={keyword}
          onChange={onKeywordUpdate}
        />
      </div>

      <div className={styles.content}>
        <LocalesList
          selectedLocales={selectedLocale ? [selectedLocale?.id] : undefined}
          usedLocales={props.selectedLocales}
          options={availableOptions.current}
          showNativeNames={props.showNativeNames}
          onSelect={onLocaleSelect}
        />
      </div>

      <FormOptions
        ui={{
          ...tmpUi,
          options: updatedOptions
        }}
        applyMobileStyle={false}
        onSelect={onOptionSelect}
      />

    </div>
  );
}