import { IAppUiContext } from "../../../context/app-ui-context/AppUiContextProvider";
import GetText from "./GetText";

export default function GetDescription(
  appUiContext: IAppUiContext,
  id: string,
  text: any,
  forceLocale?: string
): string {
  return (
    GetText({
      appUiContext: appUiContext,
      elementId: id,
      elementPart: "Description",
      text: text,
      forceLocale: forceLocale
    })
  );
}