import { EDraftItemState } from "../../../../../context/directory-editor-context/EDraftItemState";
import { IDraftStateResult } from "../../../../../context/directory-editor-context/IDirectoryDraftAbstract";
import { DictionaryDraftItem, IDictionaryDraftItem, IDictionaryItemStatus } from "../../dictionaries/common-dictionary/IDictionaryDraftItem";
import { v4 as uuidv4 } from "uuid";

export interface IDictionaryItemContent {
  dictionaryId?: string;
  isRequired: boolean;
  isMutuallyExclusive: boolean;
  isSortedManually: boolean;
}

export interface IBusinessTagsDictionaryDraftItem extends IDictionaryDraftItem {
  content?: IDictionaryItemContent;
  subDictionaryId?: string; // Only for subdictionary items
}

export class BusinessTagsDictionaryDraftItem extends DictionaryDraftItem implements IBusinessTagsDictionaryDraftItem {
  //guid!: string;
  //id!: string;
  //draftState!: EDraftItemState;
  //name?: string;
  //description?: string;
  content?: IDictionaryItemContent;
  subDictionaryId?: string;
  original?: BusinessTagsDictionaryDraftItem;
  //---------------------------------------------------------------------------
  get fullPath(): string {
    return this.path ? `${this.path}.${this.id}` : this.id;
  };
  //---------------------------------------------------------------------------
  constructor(source: IBusinessTagsDictionaryDraftItem) {
    super(source);
    Object.assign(this, source);
    if (this.isNew && !this.guid) {
      this.guid = uuidv4();
    };
    if (this.original) {
      this.original = new BusinessTagsDictionaryDraftItem(this.original);
    };
  };
  //---------------------------------------------------------------------------
  static getNew(source: IBusinessTagsDictionaryDraftItemInitial): BusinessTagsDictionaryDraftItem {
    const newItem = new BusinessTagsDictionaryDraftItem({
      ...source,
      guid: uuidv4(),
      draftState: source.draftState ? source.draftState : EDraftItemState.IsNew
    });
    return newItem;
  };
  //---------------------------------------------------------------------------
  getUpdated(update: IBusinessTagsDictionaryDraftItemUpdate): BusinessTagsDictionaryDraftItem {
    const updatedItem = new BusinessTagsDictionaryDraftItem(this);
    Object.assign(updatedItem, update);
    updatedItem.id = updatedItem.id.replace(' ', '').replace('.', '').replace(',', '').replace(';', '').replace('/', '').replace('\\', '');
    updatedItem.description == '' ? updatedItem.description = undefined : updatedItem.description;
    return updatedItem;
  };
  //---------------------------------------------------------------------------
  checkChangesAndValidate(initial?: IBusinessTagsDictionaryDraftItem): IDraftStateResult {
    // Original must be provided for this type of dictionary
    let isUpdatedInSession = false;
    let isUpdated = false;
    let isValid = true;
    //-------------------------------------------------------------------------
    if (!initial) {
      // New item
      //console.log(this.id, '1');
      isUpdatedInSession = true;
    } else if (this.id !== initial.id) {
      //console.log(this.id, '2');
      isUpdatedInSession = true;
    } else if (this.name !== initial.name) {
      //console.log(this.id, '3');
      isUpdatedInSession = true;
    } else if (this.description !== initial.description) {
      //console.log(this.id, '4');
      isUpdatedInSession = true;
    } else if (JSON.stringify(this.content) !== JSON.stringify(initial.content)) {
      //console.log(this, '5', initial);
      isUpdatedInSession = true;
    } else if (JSON.stringify(this.status) !== JSON.stringify(initial.status)) {
      //console.log(this.id, '6');
      isUpdatedInSession = true;
    } else if (this.path !== initial.path) {
      //console.log(this.id, '7', this.path, initial.path);
      isUpdatedInSession = true;
    };
    //-------------------------------------------------------------------------
    if (!this.original) {
      if (!this.subDictionaryId) {
        console.log(this.id, '1');
        isUpdated = true;
      }
    } else if (this.id !== this.original.id) {
      console.log(this.id, '2');
      isUpdated = true;
    } else if (this.name !== this.original.name) {
      console.log(this.id, '3');
      isUpdated = true;
    } else if (this.description !== this.original.description) {
      console.log(this.id, '4');
      isUpdated = true;
    } else if (JSON.stringify(this.content) !== JSON.stringify(this.original.content)) {
      console.log(this.id, '5');
      isUpdated = true;
    } else if (JSON.stringify(this.status) !== JSON.stringify(this.original.status)) {
      console.log(this.id, '6');
      isUpdated = true;
    } else if (this.path !== this.original.path) {
      console.log(this.id, '7');
      isUpdated = true;
    };
    //-------------------------------------------------------------------------
    if (this.isNoTranslation) {
      isValid = false;
    };
    //-------------------------------------------------------------------------
    return {
      isUpdatedInSession,
      isUpdated,
      isValid
    };
  };
}

export interface IBusinessTagsDictionaryDraftItemInitial {
  id: string;
  path: string;
  name?: string;
  description?: string;
  content?: IDictionaryItemContent;
  status?: IDictionaryItemStatus;
  draftState?: EDraftItemState;
}

export interface IBusinessTagsDictionaryDraftItemUpdate {
  id?: string;
  name?: string;
  description?: string;
  content?: IDictionaryItemContent;
  status?: IDictionaryItemStatus;
}