import { useContext, useEffect, useState } from 'react';
import { IUiDictionaryEditorNodeWrapper } from '../../../../administrator-console/dictionaries/common/dictionary-node-wrapper/DictionaryEditorNodeWrapper';
import { IDictionaryDraftItemStateGroup } from '../../../../administrator-console/dictionaries/common/settings/IDictionaryDraftItemStateGroup';
import DictionaryNodeSystemId from '../../../../common/dictionary-node-cell-systemid/DictionaryNodeSystemId';
import DictionaryNodeWrapperV2 from '../../../../common/dictionary-node-wrapper/DictionaryNodeWrapperV2';
import { DictionaryTranslationDraftItem } from '../../DictionaryTranslationDraftItem';
import DictionaryTranslatorValue from '../../dictionary-translator-node/dictionary-translator-value/DictionaryTranslatorValue';
import { IValue } from '../../dictionary-translator-node/DictionaryTranslatorNodeV2';
import DictionaryNodeBadges from '../../../../administrator-console/dictionaries/common/dictionary-node-wrapper/dictionary-node-badges/DictionaryNodeBadges';
import React from 'react';
import DictionaryTranslatorNodeEditForm from '../../dictionary-translator-node-edit-form/DictionaryTranslatorNodeEditForm';
import GetTranslationWithLocale from '../../../../../common/functions/GetTranslationWithLocale';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import { IDictionaryDraftFilter } from '../../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter';

interface IProps {
  ui: IUiDictionaryEditorNodeWrapper;
  data: DictionaryTranslationDraftItem;
  stateGroups?: IDictionaryDraftItemStateGroup[];
  draftLocale?: string;
  sourceLocale?: string;
  isHierarchical?: boolean;
  indent?: number;
  editModeIsEnabled?: boolean;
  filterState?: IDictionaryDraftFilter;
  onUpdate: (updatedItem: DictionaryTranslationDraftItem) => void;
}

export default function HierarchicalTranslatorNode(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [collapsed, setCollapsed] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [nameValue, setNameValue] = useState<IValue>();
  const [descriptionValue, setDescriptionValue] = useState<IValue>();
  //---------------------------------------------------------------------------
  const editModeIsEnabled = props.editModeIsEnabled == undefined ? false : props.editModeIsEnabled;
  const indent = props.indent ? props.indent : 0;
  const isHierarchical = props.isHierarchical == undefined ? false : props.isHierarchical;
  //---------------------------------------------------------------------------
  useEffect(() => {
    //-------------------------------------------------------------------------
    // Determine what value to dislay for name
    // 1. Display current draft value if it's provided
    let value: IValue = {
      value: props.data.name?.value,
      locale: props.draftLocale,
      fallbackAccepted: props.data.name?.fallbackAccepted
    };
    // 2. Pick a value from published data using fallback logic otherwise
    //console.log(props.draftLocale, props.data.name.fallbackAccepted, props.data.dictionaryDraftItemPublished);
    if (props.data.name.fallbackAccepted && props.data.publishedVersion && props.draftLocale) {
      const valueWithLocale = GetTranslationWithLocale(
        appUiContext.sysSettings,
        props.data.id,
        props.data.publishedVersion.name,
        "Name",
        props.draftLocale);
      //console.log("valueWithLocale:", valueWithLocale);
      value.value = valueWithLocale.value;
      value.locale = valueWithLocale.locale;
      value.fallbackAccepted = props.data.name?.fallbackAccepted;
    };
    setNameValue(value);
    //-------------------------------------------------------------------------
    value = {
      value: props.data.description?.value,
      locale: props.draftLocale,
      fallbackAccepted: props.data.description?.fallbackAccepted
    };
    if (props.data.description.fallbackAccepted && props.data.publishedVersion && props.draftLocale) {
      const valueWithLocale = GetTranslationWithLocale(
        appUiContext.sysSettings,
        props.data.id,
        props.data.publishedVersion.description,
        "Description",
        props.draftLocale);
      value.value = valueWithLocale.value;
      value.locale = valueWithLocale.locale;
      value.fallbackAccepted = props.data.description?.fallbackAccepted;
    };
    setDescriptionValue(value);
    //---------------------------------------------------------------------------
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onToggle = () => {
    setCollapsed(!collapsed);
  };
  //---------------------------------------------------------------------------
  const onEdit = () => {
    setEditMode(!!props.editModeIsEnabled);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedItem: DictionaryTranslationDraftItem) => {
    setEditMode(false);
    props.onUpdate(updatedItem);
  };
  //---------------------------------------------------------------------------
  const onSubItemUpdate = (updatedSubItem: DictionaryTranslationDraftItem) => {
    console.log("sub-item updated!")
    const updatedSubItems = props.data.items?.map(item => item.id == updatedSubItem.id ? updatedSubItem : item);
    const updatedItem = new DictionaryTranslationDraftItem(props.data);
    updatedItem.items = updatedSubItems;
    updatedItem.hasTranslationEdited = true;
    if (updatedSubItem.isNoTranslation || updatedSubItem.hasNoTranslation)
      updatedItem.hasNoTranslation = true;
    if (updatedSubItem.isFallbackAccepted || updatedSubItem.hasFallbackAccepted)
      updatedItem.hasFallbackAccepted = true;
    props.onUpdate(updatedItem);
  };
  //---------------------------------------------------------------------------
  const getValue = () => {
    if (props.sourceLocale == "i18n")
      return props.data.id;
    if (props.data.publishedVersion.name && props.sourceLocale) {
      let descriptor = Object.getOwnPropertyDescriptor(
        props.data.publishedVersion.name,
        props.sourceLocale);
      if (descriptor && descriptor.value)
        return descriptor.value;
    }
    return "--";
  }
  //---------------------------------------------------------------------------
  let childNodesToDisplay = props.data.filteredItems?.map(item => {
    return (
      <HierarchicalTranslatorNode
        ui={props.ui}
        key={item.id}
        stateGroups={props.stateGroups}
        draftLocale={props.draftLocale}
        sourceLocale={props.sourceLocale}
        isHierarchical={props.isHierarchical}
        data={item}
        indent={indent + 1}
        editModeIsEnabled={props.editModeIsEnabled}
        onUpdate={onSubItemUpdate}
      />);
  });
  //---------------------------------------------------------------------------
  if (editMode && props.draftLocale) return (
    <tr>
      <td colSpan={100}>
        <DictionaryTranslatorNodeEditForm
          draftLocale={props.draftLocale}
          sourceLocale={props.sourceLocale}
          data={props.data}
          onUpdate={onUpdate}
          onClose={() => setEditMode(false)}
        />
      </td>
    </tr>
  );
  else return (
    <React.Fragment>
      <DictionaryNodeWrapperV2
        editModeIsEnabled={editModeIsEnabled}
        indent={indent}
      >

        {/* System Id */}
        <DictionaryNodeSystemId
          data={getValue()}
          isHierarchical={isHierarchical}
          indent={indent}
          isExpandable={childNodesToDisplay ? childNodesToDisplay.length > 0 : false}
          isCollapsed={collapsed}
          editModeIsEnabled={editModeIsEnabled}
          onToggle={onToggle}
          onEdit={onEdit}
        />

        {/* Name */}
        <DictionaryTranslatorValue
          draftLocale={props.draftLocale}
          data={nameValue}
          editModeIsEnabled={editModeIsEnabled}
          //style={calculatedStyles}
          maxWidthEm='8'
          onDoubleClick={onEdit}
          onSelectText={onEdit}
        />

        {/* Description */}
        <DictionaryTranslatorValue
          draftLocale={props.draftLocale}
          data={descriptionValue}
          editModeIsEnabled={editModeIsEnabled}
          //style={calculatedStyles}
          maxWidthEm='6'
          onDoubleClick={onEdit}
          onSelectText={onEdit}
        />

        {/* Badges to indicate node draft state */}
        <DictionaryNodeBadges
          stateGroups={props.stateGroups}
          draftState={props.data.draftState}
        />

      </DictionaryNodeWrapperV2>

      {!collapsed && childNodesToDisplay}

    </React.Fragment>
  );
}