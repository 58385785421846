import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { IUiAdPageEditor } from "../business-space/adpage-editor/IUiAdPageEditor";
import FormOptions from "../common/form-options-bar/FormOptions";
import { getTUiMenuContent } from "../common/menu/menu-content/TUiMenuContent";
import { IUiOption } from "../common/options/IUiOption";
import { IUiMenuContent } from "../common/options/menus/IUiMenuContent";
import { pathAdminConsole, pathAds } from "../admin-console/AdminConsole";
import { useContext, useEffect, useState } from "react";
import ConsoleLayout from "../common/console-layout/ConsoleLayout";
import { useAbortController } from "../../hooks/useAbortController";
import { TUiBreadcrumb } from "../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb";
import { AppUiContextStore } from "../../context/app-ui-context/AppUiContextProvider";
import GetName from "../common/functions/GetName";
import { useMsal } from "@azure/msal-react";
import { AppAuthContextStore } from "../../context/app-auth-context/AppAuthContext";
import ConsoleTabLayout from "../common/console-layout/console-tab-layout/ConsoleTabLayout";
import useNavigateWithContext from "../../hooks/useNavigateWithContext";
import { pathAdCampaigns } from "../business-space/business-editor/BusinessEditor";
import SectionDetails from "./section-details/SectionDetails";
import getAdLanguageSetAsync from "../../context/business-context/functions/getAdLanguageSetAsync";
import { updateAdLanguageSetAsync } from "../../context/business-context/functions/updateAdLanguageSetAsync";
import { ImageEditorContext } from "../common/image-editor/image-editor-context/ImageEditorContextProvider";
import { createAdLanguageSetAsync } from "../../context/business-context/functions/createAdLanguageSetAsync";
import PopupAdPageLanguage, { INewAdPageState } from "../business-space/adpage-editor/popup-adpage-language/PopupAdPageLanguage";
import { deleteAdLanguageSetAsync } from "../../context/business-context/functions/deleteAdLanguageSetAsync";
import { AdLanguageSet, IAdLanguageSetListItem } from "./IAdLanguageSet";
import { sendAdLanguageSetForApprovalAsync } from "../../context/business-context/functions/sendAdLanguageSetForApprovalAsync";
import { activateAdLanguageSetAsync } from "../../context/business-context/functions/activateAdLanguageSetAsync";
import { deactivateAdLanguageSetAsync } from "../../context/business-context/functions/deactivateAdLanguageSetAsync";
import SectionBusinessPage from "./section-business-page/SectionBusinessPage";
import { BusinessContext } from "../../context/business-context/BusinessContextProvider";
import { ClassSystemBusinessRegional } from "../admin-console/regional-homepages/regional-homepage-editor/ISystemBusinessRegional";
import { EUserRole } from "../../context/business-context/IBusinessContext";
import { ClassCustomerBusiness } from "../business-space/business-editor/ICustomerBusiness";
import SectionSearch from "./section-search/SectionSearch";
import SectionHomepageBanner from "./section-homepage-banner/SectionHomepageBanner";
import SectionAllModules from "./section-all-modules/SectionAllModules";
import SectionEmailModules from "./section-email/SectionEmailModules";
import PopupTemplates from "./popup-templates/PopupTemplates";
import { getAdWithNewTemplateAsync } from "../../context/business-context/functions/getAdWithNewTemplateAsync";
import { submitBusinessWithAdAsync } from "../business-space/business-wizard/functions/submitBusinessWithAdAsync";
import { NotificationContext } from "../../context/notification-context/NotificationContextProvider";

const componentName = "Ad Content Editor";
export const pathDetails = "details";
export const pathAllModules = "all";
export const pathBusinessPage = "businesspage";
export const pathSearchResultsModule = "searchresults";
export const pathHomepageBanner = "homepage";
export const pathEmailModule = "email";

interface IProps {
  ui: IUiAdPageEditor;
  useHash?: boolean;
}

export default function AdContentEditor(props: IProps) {
  const { role, businessId, adLanguageSetId } = useParams();
  const { instance } = useMsal();
  const { appAuthContext, appAuthContextDispatch } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const { imageEditorContext } = useContext(ImageEditorContext);
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const { notificationContextDispatch } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [newAdLanguageSetState, setNewAdLanguageSetState] = useState<INewAdPageState>();
  const [templatesPopupOpen, setTemplatePopupOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigateWithContext();
  const abortController = useAbortController("AdContentEditor");
  //---------------------------------------------------------------------------
  const userRole: EUserRole = role as EUserRole;
  const businessType = businessContext.businessState?.business.businessType;
  const business = businessContext.businessState?.business;
  const adLanguageSet = businessContext.adLanguageSetState?.adLanguageSet;
  const locationPath = location.pathname.replace(/\//, "");
  const isSystem = locationPath.includes(pathAdminConsole);
  const basePath = businessId ? locationPath.split(businessId)[0].slice(0, -1) : ""; // slice removes last slash from the path
  const adPagePath = `${basePath}/${businessId}/${pathAds}/${adLanguageSetId}`;
  const isNew = businessContext.adLanguageSetState?.isNew ? true : false;
  const userCantEdit = (businessContext.userRole == "administrator") ||
    (isSystem && (
      !(business as ClassSystemBusinessRegional)?.status ||
      !(business as ClassSystemBusinessRegional).status?.isAvailableToStaffUsers));
  //--------------------------------------------------------------------------- Data, AbortController
  useEffect(() => {
    if (adLanguageSetId && !adLanguageSet) {
      setIsLoading(true);
      let controller = abortController.newController("getAdLanguageSet");
      getAdLanguageSetAsync({
        msalInstance: instance,
        authConfig: appAuthContext.config,
        isSystem: isSystem,
        getBusinessData: !business, // Get business data as well, if business context is not set
        businessId: businessId,
        adLanguageSetId: adLanguageSetId,
        abortSignal: controller.signal
      }).then(response => {
        if (response.business && userRole) {
          // Set business the whole business context
          businessContextDispatch({
            type: "SetBusinessState",
            userRole: userRole,
            basePath: basePath,
            business: response.business
          });
        };
        //---------------------------------------------------------------------
        // Set ad language set
        businessContextDispatch({ type: "SetAdLanguageSet", adLanguageSet: response.ad });
      }).catch(error => {
        !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    };
    //-------------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //--------------------------------------------------------------------------- Breadcrumb business
  useEffect(() => {
    if (!businessContext.businessState) return;
    // Breacrumb: business name / region name
    const businessPath = `/${businessContext.basePath}`;
    let businessBreadcrumb: TUiBreadcrumb | undefined = appUiContext.breadcrumbs.find(item => item.path == businessPath);
    if (!businessBreadcrumb) {
      if (isSystem) {
        const systemBusiness = business as ClassSystemBusinessRegional;
        const region = appUiContext.dictionaries?.supportedRegions?.find(r => r.id == systemBusiness.regionId);
        const regionName = systemBusiness.regionId ? GetName(appUiContext, systemBusiness.regionId, region?.name) : undefined;
        //-----------------------------------------------------------------------
        businessBreadcrumb = {
          id: systemBusiness.id,
          caption: { en: regionName },
          path: `/${basePath}/${systemBusiness.id}`
        };
      } else {
        const customerBusiness = business as ClassCustomerBusiness;
        businessBreadcrumb = {
          id: customerBusiness.id,
          caption: { en: customerBusiness.name },
          path: businessPath
        };
      };
      businessBreadcrumb && appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: businessBreadcrumb });
    };
  }, [businessContext.businessState]);
  //--------------------------------------------------------------------------- Breadcrumb ad set
  useEffect(() => {
    if (!adLanguageSet) return;
    //-------------------------------------------------------------------------
    // Breacrumb: ad page locale
    const localeDictionaryItem = appUiContext.dictionaries?.supportedLocales?.find(item =>
      item.id === adLanguageSet.localeId);
    const adLanguageSetBreadcrumb: TUiBreadcrumb = {
      id: localeDictionaryItem?.id ? localeDictionaryItem.id : adLanguageSet.id,
      caption: localeDictionaryItem?.name,
      path: `/${basePath}/${businessId}/${pathAds}/${adLanguageSet.id}`
    };
    //-------------------------------------------------------------------------
    // Update location if it's changed
    const adLanguageSetLocation = location.pathname.split(adLanguageSet.id)[0] + adLanguageSet.id;
    if (adLanguageSetLocation !== adLanguageSetBreadcrumb.path) {
      adLanguageSetBreadcrumb.path && navigate(adLanguageSetBreadcrumb.path, { replace: true });
    };
    //-------------------------------------------------------------------------
    // Update ui context
    appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: adLanguageSetBreadcrumb });
  }, [adLanguageSet]);
  //---------------------------------------------------------------------------
  const getSectionPath = (sectionId: string) => {
    return `/${adPagePath}/${sectionId}`; // replace slash in the middle with # in case of using hashes in ConsoleLayout
  };
  //---------------------------------------------------------------------------
  const onClose = () => {
    if (businessId) {
      businessContextDispatch({ type: "ClearAdLanguageSetState" });
      //-----------------------------------------------------------------------
      //const path = `${basePath}/${businessId}#${pathAdCampaigns}`;
      const path = !!props.useHash ? `${basePath}/${businessId}#${pathAdCampaigns}` : `${basePath}/${businessId}/${pathAdCampaigns}`;
      navigate(path);
    } else console.error("Cannot navigate to business editor: business id is unknown");
  };
  //---------------------------------------------------------------------------
  const onDelete = () => {
    const business = businessContext.businessState?.business;
    if (!business) return;
    if (!adLanguageSet) return;
    //-------------------------------------------------------------------------
    setIsLoading(true);
    let controller = abortController.newController("deleteAdLanguageSetAsync");
    deleteAdLanguageSetAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      businessType: business.businessType,
      businessId: business.id,
      adCampaignId: adLanguageSet.adCampaignId,
      adLanguageSetId: adLanguageSet.id,
      abortSignal: controller.signal
    }).then(response => {
      businessContextDispatch({ type: "SetAds", ads: response as IAdLanguageSetListItem[] });
      // Navigate to business editor
      onClose();
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onSave = () => {
    if (!adLanguageSet) return;
    setIsLoading(true);
    //-------------------------------------------------------------------------
    if (businessContext.adLanguageSetState?.isNew && businessContext.businessState?.isNew) {
      // We got here from the business wizard, so all the objects are new (not saved yet)
      let controller = abortController.newController("submitBusinessWithAdAsync");
      submitBusinessWithAdAsync({
        msalInstance: instance,
        authContext: appAuthContext,
        imageEditorContext: imageEditorContext,
        abortSignal: controller.signal,
        business: businessContext.businessState.business,
        adLanguageSet: adLanguageSet
      }).then(response => {
        console.log("saveBusinessWithAdAsync response", response);
        businessContextDispatch({
          type: "SetContext",
          business: response.updatedBusiness,
          adLanguageSet: response.updatedAdLanguageSet,
          isNew: false
        });
        //-----------------------------------------------------------------------
        // Update user profile
        if (response.updatedUserRoles)
          appAuthContextDispatch({ type: "UpdateUserRoles", value: response.updatedUserRoles });
        //-----------------------------------------------------------------------
        // Update business tag requests, if there are any
        if (response.updatedBusinessTagRequests) {
          businessContextDispatch({ type: "SetBusinessTagRequests", requests: response.updatedBusinessTagRequests });
        };
      }).catch(error => {
        !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
      }).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
      return;
    };
    //-------------------------------------------------------------------------
    let controller = abortController.newController("updateAdLanguageSetAsync");
    updateAdLanguageSetAsync({
      msalInstance: instance,
      authContext: appAuthContext,
      imageEditorContext: imageEditorContext,
      isSystem: isSystem,
      businessId: businessId,
      adCampaignId: adLanguageSet.adCampaignId,
      adLanguageSet: adLanguageSet,
      abortSignal: controller.signal
    }).then(response => {
      businessContextDispatch({ type: "SetAdLanguageSet", adLanguageSet: response });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onTranslate = () => {
    if (!adLanguageSet) return;
    // Ask user to select ad set locale
    // Determine available languages list:
    // - current ad set locale is unavailable (we create a copy in the same locale using Edit option)
    // - language is unavailable if there is a draft page for the language
    // published pages are ignored because we can create copies for published pages 
    const supportedLocales = appUiContext.dictionaries?.supportedLocales;
    const existingAds = businessContext.ads;
    // Get ads from the same ad campaign in draft status
    let unavailableLocales = existingAds?.filter(adLanguageSet =>
      adLanguageSet.adCampaignId == adLanguageSet.adCampaignId &&
      !adLanguageSet.status.isReadonly
    ).map(adLanguageSet => adLanguageSet.localeId);
    // Add current ad set locale to the list of unavailable locales
    if (unavailableLocales)
      unavailableLocales.push(adLanguageSet.localeId);
    else
      unavailableLocales = [adLanguageSet.localeId];
    // Get available locales
    const availableLocales = supportedLocales?.filter(item => !unavailableLocales?.includes(item.id));
    setNewAdLanguageSetState({
      popupIsOpen: true,
      availableLocales: availableLocales ? availableLocales : [],
      originalAdPageId: adLanguageSet.id
    });
  };
  //---------------------------------------------------------------------------
  const createNewAdLanguageSet = (selectedLocale: string) => {
    if (!businessContext.businessState) return;
    // Close select language dialog
    setNewAdLanguageSetState({ ...newAdLanguageSetState as INewAdPageState, popupIsOpen: false });
    //-------------------------------------------------------------------------
    if (!adLanguageSet) return;
    const business = businessContext.businessState.business;
    if (!business) return;
    //-------------------------------------------------------------------------
    // Call API
    setIsLoading(true);
    let controller = abortController.newController("createAdLanguageSet");
    createAdLanguageSetAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      businessType: business.businessType,
      localeId: selectedLocale,
      businessId: business.id,
      adCampaignId: adLanguageSet.adCampaignId,
      originalAdLanguageSetId: newAdLanguageSetState?.originalAdPageId,
      abortSignal: controller.signal
    }).then(response => {
      businessContextDispatch({ type: "SetAdLanguageSet", adLanguageSet: response });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() =>
      !controller.aborted && setIsLoading(false)
    );
  };
  //---------------------------------------------------------------------------
  const createCopyToEdit = () => {
    // For editing a draft we don't need an option in the menu,
    // but if the ad set is readonly, we need to create a copy to edit
    if (!businessContext.businessState) return;
    if (!adLanguageSet) return;
    //-------------------------------------------------------------------------
    // Call API
    setIsLoading(true);
    let controller = abortController.newController("createAdLanguageSetCopy");
    const business = businessContext.businessState.business;
    createAdLanguageSetAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      businessType: business.businessType,
      businessId: business.id,
      adCampaignId: adLanguageSet.adCampaignId,
      localeId: adLanguageSet.localeId,
      originalAdLanguageSetId: adLanguageSet.id,
      abortSignal: controller.signal
    }).then(response => {
      businessContextDispatch({ type: "SetAdLanguageSet", adLanguageSet: response });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() =>
      !controller.aborted && setIsLoading(false)
    );
  };
  //---------------------------------------------------------------------------
  const sendForApproval = (needToPublish: boolean) => {
    if (!businessContext.businessState) return;
    if (!businessContext.adLanguageSetState) return;
    if (!adLanguageSet) return;
    //-------------------------------------------------------------------------
    const adLanguageSetToPass = businessContext.adLanguageSetState.isChanged ? adLanguageSet : undefined;
    //-------------------------------------------------------------------------
    // Call API
    setIsLoading(true);
    let controller = abortController.newController("sendAdLanguageSetForApproval");
    sendAdLanguageSetForApprovalAsync({
      msalInstance: instance,
      authContext: appAuthContext,
      imageEditorContext: imageEditorContext,
      businessContext: businessContext,
      adCampaignId: adLanguageSet.adCampaignId,
      adLanguageSetId: adLanguageSet.id,
      needToPublish: needToPublish,
      adLanguageSet: adLanguageSetToPass,
      abortSignal: controller.signal
    }).then(response => {
      console.log(response)
      businessContextDispatch({ type: "SetAdLanguageSet", adLanguageSet: response.adLanguageSet });
      // NOTE: handle new business tag requests!
      //---------------------------------------------------------------------
      // If ad cannot be published, i.e. there are errors
      if (response.error) {
        notificationContextDispatch({ type: "SetError", header: componentName, message: "There are errors in the content. Please fix them and try again" });
      };
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onActivate = () => {
    if (!businessContext.businessState) return;
    if (!adLanguageSet) return;
    setIsLoading(true);
    let controller = abortController.newController("onAdLanguageSetActivate");
    activateAdLanguageSetAsync({
      msalInstance: instance,
      authContext: appAuthContext,
      business: businessContext.businessState.business,
      adCampaignId: adLanguageSet.adCampaignId,
      adLanguageSetId: adLanguageSetId,
      abortSignal: controller.signal,
      respondWith: 'object'
    }).then(response => {
      businessContextDispatch({ type: "SetAdLanguageSet", adLanguageSet: response as AdLanguageSet });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onDeactivate = () => {
    if (!businessContext.businessState) return;
    if (!adLanguageSet) return;
    setIsLoading(true);
    let controller = abortController.newController("onAdLanguageSetDeactivate");
    deactivateAdLanguageSetAsync({
      msalInstance: instance,
      authContext: appAuthContext,
      business: businessContext.businessState.business,
      adCampaignId: adLanguageSet.adCampaignId,
      adLanguageSetId: adLanguageSetId,
      abortSignal: controller.signal,
      respondWith: 'object'
    }).then(response => {
      businessContextDispatch({ type: "SetAdLanguageSet", adLanguageSet: response as AdLanguageSet });
    }).catch(error => {
      !controller.aborted && notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onTemplateSelect = (selectedTemplateId: string) => {
    setTemplatePopupOpen(false);
    setIsLoading(true);
    let controller = abortController.newController("getAdWithNewTemplateAsync");
    getAdWithNewTemplateAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      businessId: businessId,
      templateId: selectedTemplateId,
      adLanguageSet: adLanguageSet,
      abortSignal: controller.signal
    }).then(updatedAdLanguageSet => {
      businessContextDispatch({ type: "UpdateAdLanguageSet", adLanguageSet: updatedAdLanguageSet });
    }).catch(error => {
      notificationContextDispatch({ type: "SetError", header: componentName, message: error });
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OptionClose":
        onClose();
        break;
      case "AdPageEditor.MenuContent.OptionDelete":
        onDelete();
        break;
      case "OptionSave":
        onSave();
        break;
      case "AdPageEditor.MenuContent.OptionEdit":
        createCopyToEdit();
        break;
      case "AdPageEditor.MenuContent.OptionTemplate":
        setTemplatePopupOpen(true);
        break;
      case "AdPageEditor.MenuContent.OptionTranslate":
        onTranslate();
        break;
      case "AdPageEditor.MenuContent.OptionActivate":
        onActivate();
        break;
      case "AdPageEditor.MenuContent.OptionDeactivate":
        onDeactivate();
        break;
      case "AdPageEditor.MenuContent.OptionPublish/AdPageEditor.MenuContent.OptionSendPublish":
        sendForApproval(true);
        break;
      case "AdPageEditor.MenuContent.OptionPublish/AdPageEditor.MenuContent.OptionSend":
        sendForApproval(false);
        break;
    };
  };
  //--------------------------------------------------------------------------- Publish submenu
  const publishSubMenuOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "AdPageEditor.MenuContent.OptionSendPublish":
        publishSubMenuOptions.push({
          ...option,
          iconId: "send",
        });
        break;
      case "AdPageEditor.MenuContent.OptionSend":
        publishSubMenuOptions.push({
          ...option,
          iconId: "send"
        });
        break;
    };
  });
  const publishSubMenu: IUiMenuContent = {
    id: `${props.ui.menuContent.id}.PublishSubmenu`,
    directoryId: props.ui.menuContent.directoryId,
    elementType: props.ui.menuContent.elementType,
    hint: props.ui.menuContent.hint,
    options: publishSubMenuOptions
  };
  //--------------------------------------------------------------------------- Console menu options
  const menuContentSections: IUiMenuContent = {
    ...props.ui.menuContent,
    id: `${props.ui.menuContent.id}_Sections`,
    options: []
  };
  //--------------------------------------------------------------------------- Editor options
  const menuContentOptions: IUiMenuContent = {
    ...props.ui.menuContent,
    id: `${props.ui.menuContent.id}_Options`,
    options: []
  };
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      //----------------------------------------------------------------------- Form Sections
      case "AdPageEditor.MenuContent.OptionDetails":
        menuContentSections.options.push({
          ...option,
          iconId: "adPageDetails",
          action: getSectionPath(pathDetails)
        });
        break;
      case "AdPageEditor.MenuContent.OptionAllModules":
        menuContentSections.options.push({
          ...option,
          iconId: "modules",
          action: getSectionPath(pathAllModules)
        });
        break;
      case "AdPageEditor.MenuContent.OptionSearchResultsModule":
        if (!isSystem) {
          menuContentSections.options.push({
            ...option,
            iconId: "search",
            action: getSectionPath(pathSearchResultsModule)
          });
        };
        break;
      case "AdPageEditor.MenuContent.OptionBusinessPage":
        if (!isSystem) {
          menuContentSections.options.push({
            ...option,
            iconId: "businessPage",
            action: getSectionPath(pathBusinessPage)
          });
        };
        break;
      case "AdPageEditor.MenuContent.OptionHomepageBanner":
        if (!isSystem) {
          menuContentSections.options.push({
            ...option,
            iconId: "banner",
            action: getSectionPath(pathHomepageBanner)
          });
        };
        break;
      case "AdPageEditor.MenuContent.OptionEmailModule":
        if (!isSystem) {
          menuContentSections.options.push({
            ...option,
            iconId: "envelope",
            action: getSectionPath(pathEmailModule)
          });
        };
        break;
      //----------------------------------------------------------------------- Form options
      case "OptionClose":
        menuContentOptions.options.push({
          ...option,
          iconId: "close"
        });
        break;
      case "AdPageEditor.MenuContent.OptionDelete":
        if (adLanguageSet && !isNew && !userCantEdit && !isLoading) {
          menuContentOptions.options.push({
            ...option,
            iconId: "delete",
            isDisabled: !!adLanguageSet.status.isReadonly || userCantEdit
          });
        };
        break;
      case "AdPageEditor.MenuContent.OptionEdit":
        if (adLanguageSet && !userCantEdit && adLanguageSet.status.isReadonly && !isLoading) {
          menuContentOptions.options.push({
            ...option,
            iconId: "pencil",
            isDisabled: userCantEdit
          });
        };
        break;
      case "AdPageEditor.MenuContent.OptionTemplate":
        if (adLanguageSet && !userCantEdit && !adLanguageSet.status.isReadonly && !isLoading) {
          menuContentOptions.options.push({
            ...option,
            iconId: "template",
            isDisabled: !businessContext.adLanguageSetState?.isReadyToPublish || userCantEdit,
          });
        };
        break;
      case "AdPageEditor.MenuContent.OptionTranslate":
        if (adLanguageSet && !userCantEdit && !isLoading) {
          menuContentOptions.options.push({
            ...option,
            iconId: "translator",
            isDisabled: userCantEdit || isNew
          });
        };
        break;
      case "AdPageEditor.MenuContent.OptionActivate":
        if (adLanguageSet && !userCantEdit && !isLoading) {
          if (adLanguageSet.status.isApproved && !adLanguageSet.status.isPublished && !adLanguageSet?.isLocked) {
            menuContentOptions.options.push({
              ...option,
              iconId: "eyeOpened",
              isDisabled: !adLanguageSet?.status.statusId.startsWith("NA_DEAC_") || userCantEdit
            });
          };
        };
        break;
      case "AdPageEditor.MenuContent.OptionDeactivate":
        if (adLanguageSet && !userCantEdit && !isLoading) {
          if (adLanguageSet.status.isApproved && adLanguageSet.status.isPublished && !adLanguageSet?.isLocked) {
            menuContentOptions.options.push({
              ...option,
              iconId: "eyeClosed",
              isDisabled: !adLanguageSet.status.isPublished || userCantEdit
            });
          };
        };
        break;
      case "AdPageEditor.MenuContent.OptionPublish":
        if (businessContext.adLanguageSetState && adLanguageSet && !userCantEdit && !isLoading) {
          if (!adLanguageSet.status.isReadonly && !adLanguageSet?.isLocked) {
            menuContentOptions.options.push({
              ...option,
              iconId: "publish",
              isDisabled: !businessContext.adLanguageSetState.isReadyToPublish || userCantEdit,
              action: publishSubMenu
            });
          };
        };
        break;
      case "OptionSave":
        if (adLanguageSet && !userCantEdit && !isLoading) {
          let saveIsUnavailable = businessContext.adLanguageSetState ?
            !(businessContext.adLanguageSetState.isChanged || businessContext.adLanguageSetState.isNew) || isLoading
            : true;
          saveIsUnavailable = businessContext.adLanguageSetState?.isValid ? saveIsUnavailable : true;
          menuContentOptions.options.push({
            ...option,
            iconId: "check",
            isDisabled: saveIsUnavailable || userCantEdit,
            isDefault: !saveIsUnavailable
          });
        };
        break;
    };
  });
  //---------------------------------------------------------------------------
  const formOptionElements =
    <FormOptions
      ui={getTUiMenuContent(menuContentOptions)}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <ConsoleLayout
      ui={{
        header: "Ad Language Set",
        menuContent: getTUiMenuContent(menuContentSections)
      }}
      path={location.pathname}>
      <ConsoleTabLayout
        toolbar={formOptionElements}
        allowScroll={true}
        isLoading={isLoading}
      >

        {adLanguageSet &&
          <Routes>

            <Route index element={
              <SectionDetails
                ui={props.ui.sectionDetails}
              />}
            />

            <Route path={pathDetails} element={
              <SectionDetails
                ui={props.ui.sectionDetails}
              />}
            />

            <Route path={pathAllModules} element={
              <SectionAllModules
                ui={props.ui.sectionBusinessPage}
                isReadonly={adLanguageSet.status.isReadonly || userCantEdit}
              />}
            />

            <Route path={pathBusinessPage} element={
              <SectionBusinessPage
                ui={props.ui.sectionBusinessPage}
                isReadonly={adLanguageSet.status.isReadonly || userCantEdit}
              />}
            />

            {!isSystem &&
              <Route path={pathSearchResultsModule} element={
                <SectionSearch
                  ui={props.ui.sectionBusinessPage}
                  isReadonly={adLanguageSet.status.isReadonly || userCantEdit}
                />}
              />}

            {!isSystem &&
              <Route path={pathHomepageBanner} element={
                <SectionHomepageBanner
                  ui={props.ui.sectionBusinessPage}
                  isReadonly={adLanguageSet.status.isReadonly || userCantEdit}
                />}
              />}

            {!isSystem &&
              <Route path={pathEmailModule} element={
                <SectionEmailModules
                  ui={props.ui.sectionBusinessPage}
                  isReadonly={adLanguageSet.status.isReadonly || userCantEdit}
                />}
              />}

          </Routes>}

        {newAdLanguageSetState && newAdLanguageSetState.popupIsOpen &&
          <PopupAdPageLanguage
            ui={props.ui.sectionDetails.inputAdPageLanguage}
            data={newAdLanguageSetState.availableLocales}
            onSelect={createNewAdLanguageSet}
            onClose={() => setNewAdLanguageSetState(undefined)}
          />}

        {adLanguageSet?.designTemplateId && businessContext.businessState && templatesPopupOpen &&
          <PopupTemplates
            businessType={businessContext.businessState.business.businessType}
            selectedTemplate={adLanguageSet.designTemplateId}
            onSelect={onTemplateSelect}
            onClose={() => setTemplatePopupOpen(false)}
          />}

      </ConsoleTabLayout>
    </ConsoleLayout>
  );
}