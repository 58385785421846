import { CSSProperties, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import styles from './AdModuleContainer.module.css';
import { IAdModule } from '../IAdModule';
import { EImageSize } from '../../image-editor/TImageData';
import AdModuleBackground from '../ad-module-background/AdModuleBackground';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { getRgbaFromRgb, getRgbaThemeColors } from '../../../../utils/getRgbFromColorName';
import AdModuleStatus from '../ad-module-status/AdModuleStatus';
import { getUnitColor } from '../AdModule';
import { IDesignTemplateAdModule } from '../../design-templates/IDesignTemplateAdModule';

interface IProps {
  adModule: IAdModule;
  externalDesignTemplate?: IDesignTemplateAdModule;
  useExternalLayout: boolean;
  maxImageSize?: EImageSize;
  minHeightEm?: number; 
  isReadonly: boolean;
  children: ReactNode;
  highlightedPath?: string[];
  onClick?: () => void;
}

export default function AdModuleContainer(props: IProps) {
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const containerRef = useRef<HTMLDivElement>(null);
  let rgbColors = appUiContext.rgbColors;
  const isPhotoblock = props.adModule.adModuleType === "Photoblock";
  const [moduleIsHighlighted, setModuleIsHighlighted] = useState(false);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (!rgbColors) {
      rgbColors = getRgbaThemeColors();
      appUiContextDispatch({ type: "SetRgbaColors", value: rgbColors });
    };
  }, [rgbColors]);
  //--------------------------------------------------------------------------- Highlighted
  useEffect(() => {
    if (!containerRef.current) return;
    if (props.highlightedPath) {
      const isHighlighted = props.highlightedPath.includes(props.adModule.id);
      setModuleIsHighlighted(isHighlighted);
      if (isHighlighted)
        containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    } else {
      setModuleIsHighlighted(false);
    };
  }, [containerRef.current, props.highlightedPath]);
  //---------------------------------------------------------------------------'rgba(0, 255, 0, 0.2)'
  let backgroundIsHighlighColor: string | undefined = undefined;
  if (moduleIsHighlighted && props.highlightedPath?.includes('Background') && rgbColors) {
    backgroundIsHighlighColor = props.adModule.image?.status?.isApproved ?
      getRgbaFromRgb(rgbColors.accentMain, 0.4) :
      props.adModule.image?.status?.isInTrouble ?
        getRgbaFromRgb(rgbColors.accentWrong, 0.4) :
        getRgbaFromRgb(rgbColors.accentAttention, 0.4);
  };
  //---------------------------------------------------------------------------
  let borderColor = getUnitColor(props.adModule.status);
  const dynamicStyles: CSSProperties = {
    flex: props.useExternalLayout ? `1 1 100%` : undefined, // NOTE: add widthPercent in design template
    aspectRatio: !props.useExternalLayout && props.adModule.adModuleType == "Photoblock" ? `2 / 1` : undefined, // NOTE: add aspectratio in design template
    borderColor: moduleIsHighlighted ? borderColor : undefined,
    boxShadow: moduleIsHighlighted ? `0 0 3px 1px ${borderColor}` : undefined,
    cursor: props.onClick ? 'pointer' : undefined,
    zIndex: moduleIsHighlighted ? 15 : undefined
  };
  //---------------------------------------------------------------------------
  return (
    <div
      ref={containerRef}
      className={styles.container}
      style={dynamicStyles}
      onClick={props.onClick}
    >
      {isPhotoblock ?
        <AdModuleBackground
          data={props.adModule}
          externalDesignTemplate={props.externalDesignTemplate?.image}
          maxImageSize={props.maxImageSize ? props.maxImageSize : "Medium"}
          minHeightEm={props.minHeightEm}
          highlightColor={backgroundIsHighlighColor}
        >
          {props.children}
        </AdModuleBackground>
        :
        <div className={styles.simpleContainer}>
          {props.children}
        </div>}

      {props.adModule.status &&
        <div
          title={props.adModule.status.statusId}
          className={styles.status}
          style={{ color: props.adModule.status.statusId == "Draft" ? 'var(--clr_accent_neutral)' : borderColor }}
        >
          <AdModuleStatus
            data={props.adModule.status}
          />
        </div>}
    </div>
  );
}