import { useState } from 'react';
import { Status } from "@googlemaps/react-wrapper";
import GoogleMapsWrapper from '../GoogleMapsWrapper';
import MapError from '../map-error/MapError';
import { IBusinessMarker, IBusinessLocationUpdate } from '../business-markers/BusinessMarkers';
import MapSpinner from '../map-spinner/MapSpinner';
import MapItself, { IExternalAutocomplete, IMapArea } from '../map-itself/MapItself';

// https://medium.com/@cristhiangrojas/react-google-maps-markers-typescript-6f8d1d1d44d3
// https://thomasstep.com/blog/using-google-maps-and-search-with-react


interface IProps {
  businesses?: IBusinessMarker[];
  currentLocation?: google.maps.LatLngLiteral | true;
  defaultMapArea?: IMapArea;
  isSearchEnabled?: boolean; // Embedded search input
  externalAutocomplete?: IExternalAutocomplete;
  onMapClick?: (position: google.maps.LatLngLiteral) => void;
  onZoomChange?: (updatedZoom: number) => void;
  //onMapAreaSelected?: (selectedPlace: IMapArea) => void;
  onMapAreaChange?: (updatedMapArea: IMapArea) => void;
  onBusinessLocationUpdate?: (businessLocationUpdate: IBusinessLocationUpdate) => void;
  onBusinessMarkerClick?: (businessId: string, locationId: string) => void;
}

export default function GoogleMap(props: IProps) {
  const [mapStatus, setMapStatus] = useState<Status>(Status.LOADING);
  //---------------------------------------------------------------------------
  const updateMapStatus = (status: Status) => {
    setMapStatus(status);
  };
  //---------------------------------------------------------------------------
  //console.log("GoogleMap.props:", props);
  //---------------------------------------------------------------------------
  return (
    <GoogleMapsWrapper callback={updateMapStatus}>
      {mapStatus == Status.SUCCESS ?
        <MapItself
          currentLocation={props.currentLocation}
          businesses={props.businesses}
          defaultMapArea={props.defaultMapArea}
          isSearchEnabled={props.isSearchEnabled}
          //center={props.center}
          //zoom={props.zoom}
          //bounds={props.bounds}
          externalAutocomplete={props.externalAutocomplete}
          onMapClick={props.onMapClick}
          onZoomChange={props.onZoomChange}
          onMapAreaChange={props.onMapAreaChange}
          onBusinessLocationUpdate={props.onBusinessLocationUpdate}
          onBusinessMarkerClick={props.onBusinessMarkerClick}
         />
        :
        mapStatus == Status.FAILURE ?
          <MapError />
          :
          <MapSpinner />}
    </GoogleMapsWrapper>
  );
}