import { IUiDirectoryEditorNode } from "../../../../common/directory-node-wrapper/IUiDirectoryTranslatorNode";
import { IUiEditformBusinessTagsDictionaryItem } from "../item-edit-form/IUiEditformBusinessTagsDictionaryItem";

export interface IUiBusinessTagsDictionaryEditorNode extends IUiDirectoryEditorNode {
  editForm: IUiEditformBusinessTagsDictionaryItem;
}

export const tmpUiBusinessTagsDictionaryEditorNode: IUiBusinessTagsDictionaryEditorNode = {
  editForm: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: undefined,
    hint: undefined,
    menuContent: {
      id: "",
      directoryId: "",
      elementType: "",
      hint: undefined,
      options: [{
        id: "OptionClose",
        directoryId: "",
        elementType: "",
        caption: { en: "Close" },
        hint: undefined
      }, {
        id: "OptionCancel",
        directoryId: "",
        elementType: "",
        caption: { en: "Cancel" },
        hint: undefined
      }, {
        id: "OptionOk",
        directoryId: "",
        elementType: "",
        caption: { en: "Ok" },
        hint: undefined
      }]
    },
    inputParent: {
      id: "DictionaryNodeIdInput",
      directoryId: "",
      elementType: "",
      caption: { en: "Parent" },
      hint: { en: "Parent node in the hierarchy" },
      placeholder: { en: "Select Parent node here..." },
      dictionaryId: ""
    },
    inputId: {
      id: "DictionaryNodeIdInput",
      directoryId: '',
      elementType: "",
      disabled: true,
      visible: true,
      caption: { en: "Id" },
      placeholder: { en: "Type Id here..." },
      defaultValue: undefined,
      hint: { en: "System Id visible to administrators and system translators" },
      validation: {
        required: {
          value: 3,
          message: { en: "Provide an Identifier as long as 3 characters at least" }
        },
        // regexMatch: {
        //   regexPattern: "^[a-zA-Z0-9-_]*$", // letters in both cases, digits, dash, underscore
        //   message: { en: "Identifier must not iclude spaces, dots, commas, slashes" }
        // },
        unique: {
          message: { en: "Entry with this Id already exists" }
        }
      }
    },
    inputName: {
      id: "DictionaryItemNameInput",
      directoryId: '',
      elementType: "",
      disabled: false,
      visible: true,
      caption: { en: "Name" },
      placeholder: { en: "Type Name here..." },
      defaultValue: undefined,
      hint: { en: "Name in English visible to end users" },
      validation: {
        required: {
          value: 3,
          message: { en: "Provide a name as long as 3 characters at least" }
        },
        unique: {
          message: { en: "Entry with this Name already exists" }
        }
      }
    },
    inputDescription: {
      id: "DictionaryNodeDescriptionInput",
      directoryId: '',
      elementType: "",
      disabled: false,
      visible: true,
      caption: { en: "Description" },
      placeholder: { en: "Type Description here..." },
      defaultValue: undefined,
      hint: { en: "Detailed description in English visible to end users" },
      // validation: {
      //   required: {
      //     value: 1,
      //     message: {
      //       id: "DictionaryNodeEditForm_Description_ValidationMessage_Required",
      //       hint: "Provide a description in English"
      //     }
      //   }
      // }
    },
    inputDictionary: {
      id: "tmpUiBusinessTagEditForm.InputDictionary",
      directoryId: "",
      elementType: "",
      caption: { en: "Dictionary" },
      placeholder: { en: "No dictionary" },
      hint: { en: "Select dictionary containing sub-tags for the category"},
      dictionaryId: "",
      inputSearch: {
        id: "tmpUiBusinessTagEditForm.InputDictionarySearch",
        directoryId: "",
        elementType: "",
        caption: { en: "Search" },
        placeholder: { en: "Type dictionary Id..." },
        hint: { en: "Search dictionary" },
      }
    },
    inputIsRequired: {
      id: "tmpUiBusinessTagEditForm.InputIsRequired",
      directoryId: "",
      elementType: "",
      caption: { en: "Required" },
      hint: { en: "Check if user has to select something in this category" },
    },
    inputIsMutuallyExclusive: {
      id: "tmpUiBusinessTagEditForm.InputIsMutuallyExclusive",
      directoryId: "",
      elementType: "",
      caption: { en: "Exclusive" },
      hint: { en: "Check if user can select only one tag in this category" },
    },
    inputIsSortedManually: {
      id: "tmpUiBusinessTagEditForm.InputIsSortedManually",
      directoryId: "",
      elementType: "",
      caption: { en: "Sorted manually" },
      hint: { en: "Check if tags in this category should not be sorted automatically" },
    }
  },
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionEdit",
      directoryId: "",
      elementType: "",
      caption: { en: "Edit" },
      hint: undefined
    }, {
      id: "OptionDelete",
      directoryId: "",
      elementType: "",
      caption: { en: "Delete" },
      hint: undefined
    }, {
      id: "OptionAdd",
      directoryId: "",
      elementType: "",
      caption: { en: "Add sub-item" },
      hint: undefined
    }]
  }
};