export enum DraftItemStateEnum {
  None = 0, //0

  IsNoTranslation = 1 << 0, //1
  HasNoTranslation = 1 << 1, //2

  IsTranslationEdited = 1 << 2, //4
  HasTranslationEdited = 1 << 3, //8

  IsEdited = 1 << 4, //16
  HasEdited = 1 << 5, //32

  IsRepositionedOld = 1 << 6, //64 
  HasRepositionedOld = 1 << 7, //128 

  IsRepositionedNew = 1 << 8, //256
  HasRepositionedNew = 1 << 9, //512

  IsDeleted = 1 << 10, //1024
  HasDeleted = 1 << 11, //2048

  IsNew = 1 << 12, //4096
  HasNew = 1 << 13, //8192

  IsNothing = 1 << 14, //16384
  HasNothing = 1 << 15, //32768

  HasOnlyChild = 1 << 16, //65,536
  HasNoChildren = 1 << 17, //131,072 for items marked as Group

  IsReordered = 1 << 18, //262,143
  HasReordered = 1 << 19,

  IsFallbackAccepted = 1 << 20,
  HasFallbackAccepted = 1 << 21,

  All = ~(~0 << 22)
}