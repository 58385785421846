import { ScreenType, useAppScreenContext } from "../../../context/app-screen-context/AppScreenProvider";
import CombineStyles from "../../../utils/combineStyles";
import styles from "./ApplicationFooter.module.css";
import SysInfo from "./sys-info/SysInfo";
import { TUiApplicationFooter } from "./TUiApplicationFooter";
import { RemoveUIRelatedDataFromLS } from "../../../utils/storage";
import IconButton from "../../common/icon-button/IconButton";
import { TUiIconButton } from "../../common/icon-button/TUiIconButton";
import { useEffect, useState } from "react";
import { LogInterceptor } from "../../../utils/logging";

let btnClearLs: TUiIconButton = {
  id: 'tempButtonToDeleteUIDataFromLS',
  directoryId: "",
  elementType: "",
  iconFile: "brush",
  hint: { en: "Clear UI from LS" },
  disabled: false,
  visible: true,
  inline: false,
  action: null,
};

let btnLogStart: TUiIconButton = {
  id: 'btnLogInterceptorStart',
  directoryId: "",
  elementType: "",
  iconFile: "recordStart",
  hint: { en: "Start log interception" },
  disabled: false,
  visible: true,
  inline: false,
  action: null,
};

let btnLogStop: TUiIconButton = {
  id: 'btnLogInterceptorStop',
  directoryId: "",
  elementType: "",
  //iconFile: "recordStop",
  iconFile: "recordStart",
  hint: { en: "Stop log interception" },
  disabled: false,
  visible: true,
  inline: false,
  action: null,
};

interface IProps {
  ui: TUiApplicationFooter | undefined;
}

export default function ApplicationFooter(props: IProps) {
  const screenType = useAppScreenContext();
  const [logInterceptor, setLogInterceptor] = useState(LogInterceptor.create);
  const [logInterceptorButton, setLogInterceptorButton] = useState<TUiIconButton>(btnLogStart);
  useEffect(() => {
    return () => {
      if (logInterceptor.active)
        logInterceptor.stop();
    }
  }, []);
  //---------------------------------------------------------------------------
  const toggleLogInterceptor = () => {
    logInterceptor.toggle();
    setLogInterceptorButton(logInterceptor.active ? btnLogStop : btnLogStart);
  }
  //---------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    screenType == ScreenType.Mobile ? styles.mobile : ""
  ]);

  //---------------------------------------------------------------------------
  return (
    <footer className={containerStyles}>
      <div className={styles.section}>
        Copyright ©2021 Miramark
      </div>
      {/* <ButtonLink
        ui={btnObj}
        onClick={RemoveUIRelatedDataFromLS}
      /> */}
      <div className={styles.section}>
        <SysInfo />
        <div className={logInterceptor.active ? styles.recordButtonRed : undefined}>
          <IconButton
            ui={logInterceptorButton}
            inline={false}
            clickHandler={toggleLogInterceptor}
          />
        </div>
        <IconButton
          ui={btnClearLs}
          inline={false}
          clickHandler={RemoveUIRelatedDataFromLS}
        />
      </div>
    </footer>
  );
}
