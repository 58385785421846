import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../app-auth-context/AppAuthContext";
import { Business } from "../../../components/business-space/business-editor/Business";
import { IAdLanguageSetListItem } from "../../../components/ad-content-editor/IAdLanguageSet";
import { apiBasePath, pathAdLanguageSetDelete, pathDeleteAdLanguageSetSystem, removeAdpage } from "../../../utils/apiPathConstant";
import { apiDeletePrivate, checkResponse } from "../../../utils/api";
import { stringFormatter } from "../../../utils/common";
import { EBusinessType } from "../IBusiness";

interface IProps {
  msalInstance: IPublicClientApplication,
  authConfig?: IAuthConfig | null,
  businessType: EBusinessType,
  businessId?: string,
  adCampaignId?: string,
  adLanguageSetId?: string,
  abortSignal?: AbortSignal
}

export async function deleteAdLanguageSetAsync(props: IProps): Promise<Business | IAdLanguageSetListItem[]> {
  //---------------------------------------------------------------------------
  if (!props.authConfig) throw new Error("deleteAdLanguageSetAsync: auth config is not provided");
  if (!props.businessId) throw new Error("deleteAdLanguageSetAsync: Business Id is not provided");
  if (!props.adCampaignId) throw new Error("deleteAdLanguageSetAsync: Ad campaign Id is not provided");
  if (!props.adLanguageSetId) throw new Error("deleteAdLanguageSetAsync: Ad language set Id is not provided");
  //---------------------------------------------------------------------------
  const isSystem = props.businessType !== "Business";
  const uri = isSystem ?
    pathDeleteAdLanguageSetSystem :
    pathAdLanguageSetDelete;
  const response = await apiDeletePrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${stringFormatter(uri, [props.businessId, props.adCampaignId, props.adLanguageSetId])}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "deleteAdLanguageSetAsync", props.abortSignal?.aborted);
  return response?.content as IAdLanguageSetListItem[];
}