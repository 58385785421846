import { IUiInteractiveForm } from "../../forms/IUiInteractiveForm";
import { IUiInputText } from "../../input-v2/input-text/InputText";

export interface IUiBusinessTagsPopup extends IUiInteractiveForm {
  inputSearch: IUiInputText;
}

export const tmpUiBusinessTagsPopup: IUiBusinessTagsPopup = {
  id: '',
  directoryId: '',
  elementType: '',
  caption: { en: 'Business Tags' },
  hint: { en: 'Select business tags' },
  menuContent: {
    id: '',
    directoryId: '',
    elementType: '',
    hint: undefined,
    options:[{
      id: 'OptionOk',
      directoryId: '',
      elementType: '',
      caption: { en: 'Ok' },
      hint: undefined
    }, {
      id: 'OptionCancel',
      directoryId: '',
      elementType: '',
      caption: { en: 'Cancel' },
      hint: undefined
    }, {
      id: 'OptionRequest',
      directoryId: '',
      elementType: '',
      caption: { en: 'Request a new tag' },
      hint: undefined
    }]
  },
  inputSearch: {
    id: '',
    directoryId: '',
    elementType: '',
    placeholder: { en: 'Type to search tags...' },
  }
}