import { useContext } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { WizardState } from '../IWizardState';
import { IUiBusinessWizard } from '../IUiBusinessWizardV2';
import GetDescription from '../../../common/functions/GetDescription';
import WizardStepWrapper from '../step-wrapper/WizardStepWrapper';
import { IUiOption } from '../../../common/options/IUiOption';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import { ClassAdModulePublished } from '../../../homepage/ClassAdLanguageSetPublished';
import AdModule from '../../../common/ad-modules/AdModule';
import styles from './StepPreviewHomepage.module.css';
import { ScreenType, useAppScreenContext } from '../../../../context/app-screen-context/AppScreenProvider';
import CombineStyles from '../../../../utils/combineStyles';

interface IProps {
  state: WizardState;
  ui: IUiBusinessWizard;
  onUpdate: (state: WizardState) => void;
  onExit: () => void;
}

export default function StepPreviewHomepage(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const stepUi = props.ui.stepsDictionary.find(item => item.id == props.state.selectedStep);
  const stepData = props.state.steps.find(item => item.stepId == props.state.selectedStep);
  const prompt = GetDescription(appUiContext, props.state.selectedStep, stepUi?.description);
  const isCompleted = stepData?.isCompleted ? true : false;
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    let updatedState: WizardState;
    switch (optionId) {
      case "OptionBack":
        updatedState = props.state.moveToPreviousStep();
        props.onUpdate(updatedState);
        break;
      case "OptionNext":
        updatedState = props.state.moveToNextStep(!isCompleted);
        props.onUpdate(updatedState);
        break;
      default:
        throw new Error(`StepPreviewSearch: Unknown optionId ${optionId}`);
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionBack":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowLeft",
          isDisabled: false
        });
        break;
      case "OptionNext":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowRight",
          isDisabled: !props.state.adLanguageSet,
          isDefault: true
        });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      alignLeft={true}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  const adModules = props.state.adLanguageSet?.adModules?.filter(x => x.usage.isHomepage).map(adModule => {
    const adModulePublished = new ClassAdModulePublished(adModule);
    return (
      <AdModule
        key={adModulePublished.id}
        adModule={adModulePublished}
        isReadonly={true}
        isClickable={false}
        useExternalLayout={true}
      />
    )
  });
  //---------------------------------------------------------------------------
  return (
    <WizardStepWrapper
      ui={props.ui}
      promptString={prompt}
      optionsElement={formOptions}
      onExit={props.onExit}
    >
      <div className={CombineStyles([
        styles.modules,
        screenType == ScreenType.Mobile ? styles.mobile : ''
      ])}>
        {adModules}
      </div>
    </WizardStepWrapper>
  );
}