import { useContext, useEffect, useRef, useState } from 'react';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import { TUiBreadcrumb } from '../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { TUiCaption } from '../../../common/captions/TUiCaption';
import ConsoleTabLayout from '../../../common/console-layout/console-tab-layout/ConsoleTabLayout';
import ConsoleTabContent from '../../../common/console-layout/console-tab-layout/console-tab-content/ConsoleTabContent';
import styles from './ApiKeys.module.css';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { pathApiKey } from '../AdministratorConsole';
import { pathAdminConsole, pathAdministratorConsole } from '../../AdminConsole';
import ApiKeyCard, { ApiKey } from './apikey-card/ApiKeyCard';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import getApiKeys from './getApiKeys';
import { TUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import { TUiMenuOption } from '../../../common/menu/menu-content/menu-option/TUiMenuOption';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { IUiApiKeyEditForm } from './apikey-edit-form/ApiKeyEditForm';
import updateApiKey from './updateApiKey';
import { useAbortController } from '../../../../hooks/useAbortController';
import ConsoleContent from '../../../common/console-layout/console-content/ConsoleContent';
import GetHint from '../../../common/functions/GetHint';

export interface IUiApiKeys {
  breadcrumb: TUiBreadcrumb;
  prompt: TUiCaption;
  listMenuContent: TUiMenuContent;
  apiKeyEditForm: IUiApiKeyEditForm;
}

interface IProps {
  ui: IUiApiKeys;
}

export default function ApiKeys(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const [data, setData] = useState<ApiKey[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [newKeyState, setNewKeyState] = useState(false);
  const abortController = useAbortController("ApiKeys");
  //--------------------------------------------------------------------------- Get data
  useEffect(() => {
    setIsLoading(true);
    let controller = abortController.newController("getApiKeys");
    getApiKeys(instance, appAuthContext.config, controller.signal)
      .then(apiKeys => {
        !controller.aborted && setData(apiKeys);
      })
      .catch(error =>
        console.error(error)
      )
      .finally(() => {
        !controller.aborted && setIsLoading(false);
      });
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //--------------------------------------------------------------------------- Set breadcrumb
  useEffect(() => {
    console.log(props.ui.breadcrumb)
    if (props.ui.breadcrumb) {
      appUiContextDispatch({
        type: "UpdateBreadcrumbsTrail",
        value: {
          id: props.ui.breadcrumb.id,
          caption: props.ui.breadcrumb.caption,
          path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathApiKey}`
        }
      });
    };
  }, [props.ui.breadcrumb]);
  //---------------------------------------------------------------------------
  const onAddNewKey = () => {
    setNewKeyState(true);
  };
  //---------------------------------------------------------------------------
  const onApiKeyUpdate = (updatedApiKey: ApiKey, isNew: boolean) => {
    // Updated key can be new and can be updated existing key, but name can't be edited
    // Backend must take care of:
    // - convert date to UTC
    // - set user
    // - set datetime of created/updated
    //console.log(updatedApiKey, isNew)
    //-------------------------------------------------------------------------
    // Check if key with the name does not exist already
    if (isNew) {
      const existingKey = data?.find(key => key.name == updatedApiKey.name);
      if (existingKey) {
        console.error(`API Key [${updatedApiKey.name}] already exist`);
        return;
      }
    };
    // Call API to save changes in key vault
    setIsLoading(true);
    let controller = abortController.newController("updateApiKey");
    updateApiKey(
      instance,
      updatedApiKey,
      isNew,
      appAuthContext.config,
      controller.signal
    )
      .then(apiKeys => {
        setNewKeyState(false);
        setData(apiKeys);
      }).catch(error =>
        console.error(error)
      ).finally(() => {
        !controller.aborted && setIsLoading(false);
      });
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "Administrator_ApiKeys_OptionAddApiKey":
        onAddNewKey();
        break;
    };
  };
  //--------------------------------------------------------------------------- Render List Content
  const listElements = data?.map(apiKey => (
    <ApiKeyCard
      key={apiKey.name}
      ui={props.ui.apiKeyEditForm}
      data={apiKey}
      onUpdate={onApiKeyUpdate}
    />
  ));
  //--------------------------------------------------------------------------- Render List options
  const updatedOptions: TUiMenuOption[] = [];
  props.ui.listMenuContent.options.forEach(option => {
    switch (option.id) {
      case "Administrator_ApiKeys_OptionAddApiKey":
        updatedOptions.push(option);
        break;
    }
  });
  const listOptions = props.ui.listMenuContent ?
    <FormOptions
      ui={{ ...props.ui.listMenuContent, options: updatedOptions }}
      optionIdVisibleOnMobile={"Administrator_ApiKeys_OptionAddApiKey"}
      onSelect={onOptionSelect}
    />
    : undefined;
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={GetHint(appUiContext, props.ui.prompt.id, props.ui.prompt.text)}
      formOptions={listOptions}
      containerWidthPercent={70}
    >
        <div className={styles.container}>
          <WikiSpinner show={isLoading} />
          {listElements}
          {newKeyState &&
            <ApiKeyCard
              key={"newKey"}
              ui={props.ui.apiKeyEditForm}
              onClose={() => setNewKeyState(false)}
              onUpdate={onApiKeyUpdate}
            />}
        </div>
      </ConsoleContent>
  );
}