import { useContext, useEffect, useState } from 'react';
import GetCaption from '../../../../../common/functions/GetCaption';
import PopupDialog from '../../../../../common/popup-v2/popup-dialog/PopupDialog';
import { IUiEditformDictionaryDetails } from './IUiEditformDictionaryDetails';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import FormOptions from '../../../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../../../common/menu/menu-content/TUiMenuContent';
import { IUiOption } from '../../../../../common/options/IUiOption';
import { DirectoryEditorContext } from '../../../../../../context/directory-editor-context/DirectoryEditorContextProvider';
import InputsGroupWrapper from '../../../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import InputText from '../../../../../common/input-v2/input-text/InputText';
import styles from './PopupDictionaryDetails.module.css';
import { EditFormState } from '../../../../../common/edit-form/IEditFormState';
import { CustomDictionaryDraft } from '../IDictionaryDraft';
import InputCheckBox from '../../../../../common/input-v2/input-checkbox/InputCheckBox';

interface IDictionaryDetails {
  id: string;
  name: string;
  description: string;
  isHierarchical: boolean;
  isSortedManually: boolean;
}

interface IProps {
  ui: IUiEditformDictionaryDetails;
  onClose: () => void;
}

export default function PopupDictionaryDetails(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { directoryEditorContext, directoryEditorContextDispatch } = useContext(DirectoryEditorContext);
  const [editorState, setEditorState] = useState<EditFormState<IDictionaryDetails>>();
  const dictionaryDraft = directoryEditorContext.editorState.draft as CustomDictionaryDraft;
  const dictionaryIsNew = dictionaryDraft.documentId && dictionaryDraft.id !== dictionaryDraft.documentId;
  const idIsReadonly = !directoryEditorContext?.editMode || !dictionaryIsNew;
  //--------------------------------------------------------------------------- EditorState
  useEffect(() => {
    if (!directoryEditorContext?.editorState.draft) return;
    const updatedObject: IDictionaryDetails = {
      id: directoryEditorContext.editorState.draft.id,
      name: directoryEditorContext.editorState.draft.name as string,
      description: directoryEditorContext.editorState.draft.description as string,
      isHierarchical: directoryEditorContext.editorState.draft.isHierarchical as boolean,
      isSortedManually: directoryEditorContext.editorState.draft.isSortedManually as boolean
    };
    setEditorState(new EditFormState<IDictionaryDetails>({
      object: updatedObject,
      isUpdated: false,
      isValid: true
    }));
  }, [directoryEditorContext]);
  //---------------------------------------------------------------------------
  const onIdUpdate = (updatedId: string, isValid: boolean) => {
    if (!editorState) return;
    const updatedEditorState = new EditFormState<IDictionaryDetails>(editorState, {
      object: {
        ...editorState.object as IDictionaryDetails,
        id: updatedId.replace(' ', ''),
      }
    });
    setEditorState(updatedEditorState);
  };
  //---------------------------------------------------------------------------
  const onNameUpdate = (updatedName: string, isValid: boolean) => {
    if (!editorState) return;
    const updatedEditorState = new EditFormState<IDictionaryDetails>(editorState, {
      object: {
        ...editorState.object as IDictionaryDetails,
        name: updatedName
      }
    });
    setEditorState(updatedEditorState);
  };
  //---------------------------------------------------------------------------
  const onDescriptionUpdate = (updatedDescription: string, isValid: boolean) => {
    if (!editorState) return;
    const updatedEditorState = new EditFormState<IDictionaryDetails>(editorState, {
      object: {
        ...editorState.object as IDictionaryDetails,
        description: updatedDescription
      }
    });
    setEditorState(updatedEditorState);
  };
  //---------------------------------------------------------------------------
  const onIsHierarchicalUpdate = (value: boolean) => {
    if (!editorState) return;
    const updatedEditorState = new EditFormState<IDictionaryDetails>(editorState, {
      object: {
        ...editorState.object as IDictionaryDetails,
        isHierarchical: value
      }
    });
    setEditorState(updatedEditorState);
  };
  //---------------------------------------------------------------------------
  const onIsSortedManuallyUpdate = (value: boolean) => {
    if (!editorState) return;
    const updatedEditorState = new EditFormState<IDictionaryDetails>(editorState, {
      object: {
        ...editorState.object as IDictionaryDetails,
        isSortedManually: value
      }
    });
    setEditorState(updatedEditorState);
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    if (!editorState?.object) return;
    if (!directoryEditorContext?.editorState.draft) return;
    const validId = editorState.object.id;
    const validName = editorState.object.name.replace(/\s+/g, ' ').trim();
    const validDescription = editorState.object.description.replace(/\s+/g, ' ').trim();
    const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({
      id: validId,
      name: validName,
      description: validDescription,
      isHierarchical: editorState.object.isHierarchical,
      isSortedManually: editorState.object.isSortedManually
    });
    directoryEditorContextDispatch({
      type: "Update",
      data: {
        id: validId,
        name: validName,
        description: validDescription,
        draft: updatedDraft
      }
    });
    props.onClose();
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionClose":
      case "OptionCancel":
        props.onClose();
        break;
      case "OptionOk":
        onConfirm();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionClose":
        if (!directoryEditorContext?.editMode) {
          updatedOptions.push({ ...option, iconId: "close" });
        };
        break;
      case "OptionCancel":
        if (directoryEditorContext?.editMode) {
          updatedOptions.push({ ...option, iconId: "close" });
        };
        break;
      case "OptionOk":
        if (directoryEditorContext.editMode) {
          updatedOptions.push({
            ...option,
            iconId: "check",
            isDisabled: !editorState?.isUpdated || !editorState?.isValid,
            isDefault: editorState?.isUpdated
          });
        };
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id={props.ui.id}
      header={GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      toolbarContent={formOptions}
      isModal={true}
      isDraggable={false}
      onClose={props.onClose}
    >
      <div className={styles.container}>
        <InputsGroupWrapper>
          <InputText
            ui={{ ...props.ui.inputId, disabled: idIsReadonly }}
            data={editorState?.object?.id ? editorState.object.id : ''}
            isFocused={true}
            onUpdate={onIdUpdate}
          />
          <InputText
            ui={{...props.ui.inputName, disabled: !directoryEditorContext?.editMode}}
            data={editorState?.object?.name ? editorState.object.name : ''}
            onUpdate={onNameUpdate}
          />
          <InputText
            ui={{...props.ui.inputDescription, disabled: !directoryEditorContext?.editMode}}
            data={editorState?.object?.description ? editorState.object.description : ''}
            onUpdate={onDescriptionUpdate}
          />
          <InputCheckBox
            ui={{
              ...props.ui.inputIsHierarchical,
              disabled: !directoryEditorContext.editMode
            }}
            data={editorState?.object?.isHierarchical}
            onChange={onIsHierarchicalUpdate}
          />
          <InputCheckBox
            ui={{
              ...props.ui.inputIsSortedManually,
              disabled: !directoryEditorContext.editMode
            }}
            data={editorState?.object?.isSortedManually}
            onChange={onIsSortedManuallyUpdate}
          />
        </InputsGroupWrapper>
      </div>
    </PopupDialog>
  );
}