import { DraftItemStateEnum } from "../../../../common/dictionary-draft-models/DraftItemStateEnum";
import { IDictionaryDraftItemStateGroup } from "./IDictionaryDraftItemStateGroup";

export const dictonaryDraftStatesLessDetails: IDictionaryDraftItemStateGroup[] = [{
  id: 'incorrect',
  name: "Incorrect",
  iconId: 'attention',
  color: 'yellow',
  states: [{
    draftState: DraftItemStateEnum.IsNoTranslation,
    fontColor: 'undefined'
  }, {
    draftState: DraftItemStateEnum.HasNoTranslation,
    fontColor: 'undefined'
  }, {
    draftState: DraftItemStateEnum.HasOnlyChild,
    fontColor: 'undefined'
  }, {
    draftState: DraftItemStateEnum.HasNoChildren,
    fontColor: 'undefined'
  }]
}, {
  id: 'new',
  name: "New",
  color: 'green',
  states: [{
    draftState: DraftItemStateEnum.IsNew,
    fontColor: 'green'
  }, {
    draftState: DraftItemStateEnum.HasNew,
    fontColor: 'undefined'
  }]
}, {
  id: 'deleted',
  name: "Deleted",
  color: 'red',
  states: [{
    draftState: DraftItemStateEnum.IsDeleted,
    textDecoration: 'line-through'
  }, {
    draftState: DraftItemStateEnum.HasDeleted,
    fontColor: 'undefined'
  }]
}, {
  id: 'repositioned',
  name: "Repositioned",
  color: 'blue',
  states: [{
    draftState: DraftItemStateEnum.IsRepositionedNew
  }, {
    draftState: DraftItemStateEnum.HasRepositionedNew,
    fontColor: 'undefined'
  }, {
    draftState: DraftItemStateEnum.IsRepositionedOld,
    textDecoration: 'line-through'
  }, {
    draftState: DraftItemStateEnum.HasRepositionedOld,
    fontColor: 'undefined'
  }]
}, {
  id: 'updated',
  name: "Updated",
  color: 'yellow',
  states: [{
    draftState: DraftItemStateEnum.IsTranslationEdited
  }, {
    draftState: DraftItemStateEnum.HasTranslationEdited,
    fontColor: 'orange',
    fontWeight: 'bold'
  }, {
    draftState: DraftItemStateEnum.IsEdited
  }, {
    draftState: DraftItemStateEnum.HasEdited,
    fontColor: 'orange',
    fontWeight: 'bold'
  }]
}, {
  id: 'nothing',
  name: "Nothing",
  states: [{
    draftState: DraftItemStateEnum.HasNothing,
    fontColor: 'undefined'
  }, {
    draftState: DraftItemStateEnum.IsNothing
  }]
}]