import { IUiSelectorBusinessTags } from "../../business-console/business-tags-selector/IUiSelectorBusinessTags";
import { IUiDictionaryItem } from "../../common/dictionaries/IUiDictionaryItem";
import { IUiInteractiveForm } from "../../common/forms/IUiInteractiveForm";
import { IUiInputDropdown } from "../../common/input-v2/input-dropdown/InputDropdown";
import { IUiInputText } from "../../common/input-v2/input-text/InputText";
import { IUiInput } from "../../common/input-v2/IUiInput";
import { IUiMenuContent } from "../../common/options/menus/IUiMenuContent";
import { IUiInputListContacts } from "../business-editor/section-contacts/contacts-editor/IUiInputListContacts";
import { IUiBusinessLocationEditor, tmpUiBusinessLocationEditor } from "../business-editor/section-location/business-location-editor/IUiBusinessLocationEditor";
import { EWizardStep } from "./IWizardState";

export interface IUiBusinessWizard {
  stepsDictionary: IUiWizardStepsDictionaryItem[];
  menuContent: IUiMenuContent;
  inputLocale: IUiInputDropdown;
  inputAdRank: IUiInputDropdown;
  inputName: IUiInputText;
  inputDescription: IUiInputText;
  inputLogo: IUiInput;
  inputListTags?: IUiSelectorBusinessTags;
  inputLocationData: IUiBusinessLocationEditor;
  inputListContacts?: IUiInputListContacts;
  inputEmail: IUiInputText;
  unsavedChangesDialog: IUiInteractiveForm;
  savedWizardDataDialog: IUiInteractiveForm;
}

export const tmpUiBusinessWizard: IUiBusinessWizard = {
  stepsDictionary: [{
    id: "StepLocale",
    name: { en: "Ad Locale" },
    description: { en: "Select a language for your ad" }
  }, {
    id: "StepAdRank",
    name: { en: "Ad Rank" },
    description: { en: "Decide what ad campaign rank you need for your ad. The higher the rank, the more visible your ad will be but it will cost you more" }
  }, {
    id: "StepName",
    name: { en: "Business Name" },
    description: { en: "Provide a short but informative description of your business, make sure it is in the selected language" }
  }, {
    id: "StepDescription",
    name: { en: "Short Description" },
    description: { en: "Provide a short description of your business. This will show up in search results and in RikiWiki homepage banner" }
  }, {
    id: "StepLogo",
    name: { en: "Business Logo" },
    description: { en: "Upload an image you prefer to be your business logo, if you have any. Otherwise don't worry, it's optional" }
  }, {
    id: "StepTags",
    name: { en: "Business Tags" },
    description: { en: "Describe your business key features using business tags dictionary. These will be used by end users to find your business" }
  }, {
    id: "StepLocation",
    name: { en: "Locations" },
    description: { en: "Specify where your customers could find your business. It could be one or more location" }
  }, {
    id: "StepContacts",
    name: { en: "Contacts" },
    description: { en: "Specify how do you prefer your customers contact your business. Add one or more phone numbers, emails etc" }
  }, {
    id: "StepBackgroundImage",
    name: { en: "Background Image" },
    description: { en: "Select an image as a background for RikiWiki homepage banner" }
  }, {
    id: "StepPreviewSearchResults",
    name: { en: "Preview Search" },
    description: { en: "Finally we are getting there! This is how your business will be represented in RikiWiki search results" }
  }, {
    id: "StepPreviewHomepageBanner",
    name: { en: "Preview Homepage Banner" },
    description: { en: "Check out how you ad banner will look on RikiWiki homepage" }
  }, {
    id: "StepPreviewBusinessPage",
    name: { en: "Preview Business Page" },
    description: { en: "This is what RikiWiki users will see once they selected your business in search results or clicked/tapped homepage banner. \n Now you can submit your ad to RikiWiki. \n If you are not happy with the results, you can get back to previous steps to make some changes. Or, alternatively, you can open our enhanced editor and make your ad perfect." }
  }, {
    id: "StepPublish",
    name: { en: "Publish" },
    description: { en: "Please, confirm or provide a new email address to receive notifications about your ad status. \n It's okay if you didn't do that, but in this case you'll need to visit RikiWiki for getting udpates." }
  }],
  menuContent: {
    id: '',
    directoryId: '',
    elementType: '',
    hint: undefined,
    options: [{
      id: 'OptionExit',
      directoryId: '',
      elementType: '',
      caption: { en: 'Exit' },
      hint: { en: '' }
    }, {
      id: 'OptionBack',
      directoryId: '',
      elementType: '',
      caption: { en: 'Back' },
      hint: { en: '' }
    }, {
      id: 'OptionSkip',
      directoryId: '',
      elementType: '',
      caption: { en: 'Skip' },
      hint: { en: '' }
    }, {
      id: 'OptionNext',
      directoryId: '',
      elementType: '',
      caption: { en: 'Next' },
      hint: { en: '' }
    }, {
      id: 'OptionFinish',
      directoryId: '',
      elementType: '',
      caption: { en: 'Finish' },
      hint: { en: '' }
    }, {
      id: 'OptionPublish',
      directoryId: '',
      elementType: '',
      caption: { en: 'Publish' },
      hint: { en: '' }
    }, {
      id: 'OptionOpenEditor',
      directoryId: '',
      elementType: '',
      caption: { en: 'Open Editor' },
      hint: { en: '' }
    }, {
      id: 'OptionSubmit',
      directoryId: '',
      elementType: '',
      caption: { en: 'Submit' },
      hint: { en: '' }
    }]
  },
  inputLocale: {
    id: 'BusinessWizard.InputLocale',
    directoryId: '',
    elementType: '',
    caption: { en: 'Language' },
    hint: { en: 'Select the language for your ad' },
    placeholder: { en: 'Select language...' },
    dictionaryId: "SupportedLocales",
    validation: {
      required: {
        value: 1,
        message: { en: 'Please, select the language for your ad' }
      }
    }
  },
  inputAdRank: {
    id: 'BusinessWizard.InputAdRank',
    directoryId: '',
    elementType: '',
    caption: { en: 'Ad Rank' },
    hint: { en: 'Select RikiWiki rank for your ad' },
    placeholder: { en: 'Select Ad Rank...' },
    dictionaryId: "AdRanks",
    validation: {
      required: {
        value: 1,
        message: { en: 'Please, select rank for your ad' }
      }
    }
  },
  inputName: {
    id: 'BusinessWizard.InputName',
    directoryId: '',
    elementType: '',
    caption: { en: 'Business name' },
    hint: { en: 'Specify your business name, it should be in ad language' },
    placeholder: { en: 'Type business name...' },
    validation: {
      required: {
        value: 3,
        message: { en: 'Business name is required' }
      }
    }
  },
  inputDescription: {
    id: 'BusinessWizard.InputDescription',
    directoryId: '',
    elementType: '',
    caption: { en: 'Description' },
    hint: { en: 'Specify your business short description, it should be in ad language' },
    placeholder: { en: 'Type here...' },
    validation: {
      required: {
        value: 3,
        message: { en: 'Description is required' }
      }
    }
  },
  inputLogo: {
    id: '',
    directoryId: '',
    elementType: '',
  },
  inputListTags: undefined,
  inputLocationData: tmpUiBusinessLocationEditor,
  inputListContacts: undefined,
  inputEmail: {
    id: '',
    directoryId: '',
    elementType: '',
    caption: undefined,
    hint: undefined,
    placeholder: { en: 'Type/paste email address...' },
  },
  unsavedChangesDialog: {
    id: '',
    directoryId: '',
    elementType: '',
    caption: { en: 'Business Wizard' },
    hint: { en: 'You have unsaved changes. Are you sure you want to discard them?' },
    menuContent: {
      id: '',
      directoryId: '',
      elementType: '',
      hint: undefined,
      options: [{
        id: 'OptionDiscard',
        directoryId: '',
        elementType: '',
        caption: { en: 'Discard' },
        hint: undefined
      }, {
        id: 'OptionSaveForLater',
        directoryId: '',
        elementType: '',
        caption: { en: 'Save for Later' },
        hint: { en: '' }
      }, {
        id: 'OptionCancel',
        directoryId: '',
        elementType: '',
        caption: { en: 'Cancel' },
        hint: { en: '' }
      }]
    }
  },
  savedWizardDataDialog: {
    id: '',
    directoryId: '',
    elementType: '',
    caption: { en: 'Business Wizard' },
    hint: { en: 'You have saved wizard data. Would you like to restore it?' },
    menuContent: {
      id: '',
      directoryId: '',
      elementType: '',
      hint: undefined,
      options: [{
        id: 'OptionRestore',
        directoryId: '',
        elementType: '',
        caption: { en: 'Restore' },
        hint: undefined
      }, {
        id: 'OptionNew',
        directoryId: '',
        elementType: '',
        caption: { en: 'Start New' },
        hint: undefined
      }]
    }
  }
};

export interface IUiWizardStepsDictionaryItem extends IUiDictionaryItem {
  id: EWizardStep;
}