import CombineStyles from '../../../../../utils/combineStyles';
import Icon from '../../../../common/icon/Icon';
import { EDictionaryType } from '../../../common/dictionary-draft-models/EDictionaryType';
import styles from './DictionaryCard.module.css';
import { TDictionaryListItem } from './TDictionaryListItem';

interface IProps {
  data: TDictionaryListItem;
  onSelect: (dictionaryId: EDictionaryType) => void;
}

export default function DictionaryCard(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      onClick={() => props.onSelect(props.data.dictionaryId)}
    >
      <div className={styles.icon}>
        {props.data.icon &&
          <Icon imageName={props.data.icon} />}
      </div>
      <div className={styles.name}>
        {props.data.name}
      </div>
      <div className={CombineStyles([styles.icon, styles.draftExistsIcon])}>
        {props.data.draftExists &&
          <Icon imageName="pencil" />}
      </div>
    </div>
  );
}