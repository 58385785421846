import React, { useContext, useEffect, useRef } from 'react';
import { EWizardStep, WizardState } from '../IWizardState';
import styles from './WizardProgress.module.css';
import { ScreenType } from '../../../../context/app-screen-context/AppScreenProvider';
import CombineStyles from '../../../../utils/combineStyles';
import { IUiWizardStepsDictionaryItem } from '../IUiBusinessWizardV2';
import GetName from '../../../common/functions/GetName';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import WizardProgressItem from './wizard-progress-item/WizardProgressItem';

interface IProps {
  ui: IUiWizardStepsDictionaryItem[];
  wizardState: WizardState;
  mode: ScreenType;
  onUpdate: (state?: WizardState) => void;
  onOpenAsPopup?: () => void;
}

export default function WizardProgress(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const selectedItemDivRef = useRef<HTMLDivElement>(null);
  const isMobile = props.mode == ScreenType.Mobile;
  const selectedStepIndex = props.wizardState.steps.findIndex(step => step.stepId == props.wizardState.selectedStep);
  const selectedStep = props.wizardState.steps[selectedStepIndex];
  const selectedStepUi = props.ui.find(item => item.id == selectedStep.stepId);
  //--------------------------------------------------------------------------- Scroll to selected item
  useEffect(() => {
    if (selectedItemDivRef.current) {
      selectedItemDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };
  }, [selectedItemDivRef.current]);
  //---------------------------------------------------------------------------
  const onStepSelect = (stepId: EWizardStep) => {
    if (stepId == props.wizardState.selectedStep) 
      props.onUpdate();
    const updatedState = props.wizardState.setStepById(stepId);
    props.onUpdate(updatedState);
  };
  //---------------------------------------------------------------------------
  let stepsToDisplay = props.wizardState.steps;
  if (isMobile) {
    // Display only 5 steps at a time
    let firstStepIndex = selectedStepIndex - 2 >= 0 ? selectedStepIndex - 2 : selectedStepIndex - 1 >= 0 ? selectedStepIndex - 1 : selectedStepIndex;
    stepsToDisplay = props.wizardState.steps.slice(firstStepIndex, firstStepIndex + 5);
  };
  //---------------------------------------------------------------------------
  const stepElements = stepsToDisplay.map((step, index) => {
    const stepsDictionaryItem = props.ui.find(item => item.id == step.stepId);
    if (!stepsDictionaryItem) {
      throw new Error(`WizardProgress: Step ${step.stepId} not found in UI data`);
    };
    const isSelected = step.stepId == props.wizardState.selectedStep;
    const isSelectable = (step.isCompleted || step.index == props.wizardState.lastCompletedStepIndex + 1);
    return (
      <WizardProgressItem
        key={step.stepId}
        ui={stepsDictionaryItem}
        wizardState={props.wizardState}
        step={{
          ...step,
          isFirstDisplayed: index == 0,
          isLastDisplayed: index == stepsToDisplay.length - 1
        }}
        isSelectable={isSelectable}
        isSelected={isSelected}
        selectedDivRef={isSelected ? selectedItemDivRef : undefined}
        mode={props.mode}
        onSelect={onStepSelect}
      />);
  });
  //---------------------------------------------------------------------------
  if (isMobile) return (
    <React.Fragment>

      <div
        className={CombineStyles([
          styles.container,
          styles.mobile
        ])}
        onClick={props.onOpenAsPopup}
      >
        {stepElements}
      </div>

      <div
        className={styles.title}
        onClick={props.onOpenAsPopup}
      >
        {selectedStepUi ? GetName(appUiContext, selectedStepUi.id, selectedStepUi.name) : selectedStep.stepId}
      </div>

    </React.Fragment>
  ); else return (
    <div className={styles.container}>
      {stepElements}
    </div>
  );
}