import React from "react";
import CombineStyles from "../../../../utils/combineStyles";
import Icon from "../../icon/Icon";
import { TUiMenuOption } from "../../menu/menu-content/menu-option/TUiMenuOption";
import styles from "./ImageEditorOption.module.css";

interface IProps {
  ui: TUiMenuOption;
  imageName: string,
  onSelect: () => void;
}

export default function StandardImageEditorOption(props: IProps) {
  //---------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    props.ui.disabled ? styles.disabled : ""
  ]);
  //---------------------------------------------------------------------------
  if (props.ui.visible){
    //console.log("StandardImageEditorOption.props.imageName:", props.imageName)
    return (
      <div
        key={props.imageName}
        className={containerStyles}
        //style={{ fontSize: "2em" }}
        onClick={props.ui.disabled ? undefined : props.onSelect}
      >
        <Icon imageName={props.imageName} />
      </div>
    );
  }
  else
    return (<React.Fragment></React.Fragment>);
}