import { IAdUnitText } from "../../../../common/ad-modules/IAdModule";
import { ClassAdUnitStatus, IAdUnitStatus } from "../../../../common/ad-modules/IAdUnit";

const ContactTypeArray = ["Email", "Website", "Mobilephone", "Landline", "Fax", "Whatsapp", "Telegram", "Twitter", "Skype", "Facebook", "Linkedin"] as const;
type TContactTypeTuple = typeof ContactTypeArray;
export type TContactType = TContactTypeTuple[number];

export function IsValidContactType(value: string): value is TContactType {
  return ContactTypeArray.includes(value as TContactType)
}

export interface IContact<T> {
  id: string;
  contactType: string; //TContactType;
  value?: T;
  description?: T;
  status?: IAdUnitStatus;
}

export class Contact<T> implements IContact<T> {
  id!: string;
  contactType!: string; //TContactType;
  value?: T;
  description?: T;
  status?: IAdUnitStatus;
  //---------------------------------------------------------------------------
  constructor(source: IContact<T>) {
    if (source)
      Object.assign(this, source);
  };
  //---------------------------------------------------------------------------
  static createContact(source: IContact<IAdUnitText>): Contact<string> {
    const result = new Contact<string>({
      id: source.id,
      contactType: source.contactType,
      value: source.value?.text,
      description: source.description?.text,
      status: source.status
    });
    return result;
  };
  //---------------------------------------------------------------------------
  static createContacts(sources?: IContact<IAdUnitText>[]): Contact<string>[] {
    if (!sources) return [];
    const results = sources.map(source => Contact.createContact(source));
    return results;
  }
  //---------------------------------------------------------------------------
  static getUpdatedContact(
    addressBlockContact: IContact<IAdUnitText>, // Contact that already in the selected list
    businessContact: IContact<string>           // Contact that just added to the list
  ): IContact<IAdUnitText> {
    const valueIsUpdated = addressBlockContact.value?.text !== businessContact.value;
    const descriptionIsUpdated = addressBlockContact.description?.text !== businessContact.description;
    const result: IContact<IAdUnitText> = {
      id: addressBlockContact.id,
      contactType: addressBlockContact.contactType,
      value: {
        type: "Contact",
        text: businessContact.value,
        status: valueIsUpdated ? new ClassAdUnitStatus("Draft") : addressBlockContact.value?.status
      },
      description: businessContact.description ? {
        type: "Description",
        text: businessContact.description,
        status: descriptionIsUpdated ? new ClassAdUnitStatus("Draft") : addressBlockContact.description?.status
      } : undefined,
      status: valueIsUpdated || descriptionIsUpdated ?
        new ClassAdUnitStatus("Draft") :
        addressBlockContact.status
    };
    return result;
  }
  //---------------------------------------------------------------------------
  static fromArray(source: any): Contact<string>[] {
    let result: Contact<string>[] = [];
    if (source)
      source.forEach((item: any) => result.push(new Contact(item)));
    return result;
  }
}