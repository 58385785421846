import React from "react";

interface Props {
  isCollapsed: boolean;
}

export default function ConsoleMenuTogglerIcon(props: Props) {
  return (
    <React.Fragment>
      {props.isCollapsed && (
        <span>
          <span
            className="iconify"
            data-icon="fa-solid:angle-double-right"
          ></span>
        </span>
      )}
      {!props.isCollapsed && (
        <span>
          <span
            className="iconify"
            data-icon="fa-solid:angle-double-left"
          ></span>
        </span>
      )}
    </React.Fragment>
  );
}