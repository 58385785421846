import { RefObject, useEffect, useRef } from "react";
// https://www.robinwieruch.de/react-hook-detect-click-outside-component/

interface IProps {
  handler: (unsavedChanges?: boolean) => void,
  noop?: boolean,
  unsavedChanges?: boolean,
  excludeDivRef?: RefObject<HTMLDivElement>
}

export default function useClickOutsideDiv(props: IProps) {
  const ref = useRef<HTMLDivElement>(null);
  //---------------------------------------------------------------------------
  useEffect(() => {
    const listener = (event: any) => {
      const clickIsOutsideDiv = !ref.current?.contains(event.target);
      const excludedDivClicked = props.excludeDivRef?.current?.contains(event.target);
      //-----------------------------------------------------------------------
      // Exclude portal (popup) click from listening
      // because popup can be opened by the div that listens for click outside
      // otherwise there will be some other element click first
      const portalElement = document.getElementById("portal");
      const popupIsClicked = portalElement?.contains(event.target);
      //-----------------------------------------------------------------------
      if (clickIsOutsideDiv && !props.noop && !excludedDivClicked && !popupIsClicked) {
        props.handler(props.unsavedChanges);
      };
    };
    //-------------------------------------------------------------------------
    // Here we need to use the latest event in the order of firing (pointerup)
    // otherwise, e.g. if click outside happens on some control this control hander is not exexuting.
    // We make exclusion for case when there are unsaved changes
    // because in this case control handler fires before unsaved changes dialog is shown
    const eventName: string = props.unsavedChanges ? "pointerdown" : "pointerup";
    document.addEventListener(eventName, listener);
    window.addEventListener("wheel", listener);
    //-------------------------------------------------------------------------
    return () => {
      document.removeEventListener(eventName, listener);
      window.removeEventListener("wheel", listener);
    };
  }, [props.noop, props.unsavedChanges, props.excludeDivRef]);
  //---------------------------------------------------------------------------
  return ref;
}