import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathDeleteAdModuleSystem } from "../../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../../utils/common";
import { apiDeletePrivate, checkResponse } from "../../../../../utils/api";
import { ClassAdModule, IAdModule } from "../../../../common/ad-modules/IAdModule";

interface IProps {
  msalInstanse: IPublicClientApplication,
  businessId?: string,
  adModuleId?: string,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
}

export default async function deleteAdModuleAsync(props: IProps): Promise<ClassAdModule[]> {
  if (!props.authConfig) throw new Error("deleteAdModuleAsync: auth config is not provided");
  if (!props.businessId) throw new Error("deleteAdModuleAsync: business Id is not provided");
  if (!props.adModuleId) throw new Error("deleteAdModuleAsync: ad module Id is not provided");
  //---------------------------------------------------------------------------
  const uri = `${apiBasePath}${stringFormatter(pathDeleteAdModuleSystem, [props.businessId, props.adModuleId])}`;
  const response = await apiDeletePrivate(
    props.msalInstanse,
    props.authConfig,
    uri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "deleteAdModuleAsync", props.abortSignal?.aborted);
  let responseItems = response?.content as IAdModule[];
  const adModules: ClassAdModule[] = [];
  responseItems.forEach(item => adModules.push(new ClassAdModule(item)));
  return (adModules);
}