import React from "react";
import Icon from "../icon/Icon";
import styles from "./Checkbox.module.css";
import CombineStyles from "../../../utils/combineStyles";

interface IProps {
  checked?: boolean;
  threeState?: boolean;
  disabled?: boolean;
  widthEm?: number; //em
  collapseRightMargin?: boolean;
  onClick?: () => void;
}

export default function Checkbox(props: IProps) {
  const disabled = props.disabled == undefined ? false : props.disabled;
  const collapseRightMargin = props.collapseRightMargin != undefined ? props.collapseRightMargin : false;
  //---------------------------------------------------------------------------
  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled && props.onClick) {
      event.stopPropagation();
      props.onClick();
    };
  };
  //---------------------------------------------------------------------------
  let value = props.checked;
  if (value == undefined && !props.threeState) {
    value = false;
  }
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        disabled ? styles.disabled : ''
      ])}
      style={{
        width: props.widthEm ? `${props.widthEm}em` : undefined,
        marginRight: collapseRightMargin ? 0 : undefined
      }}
      onClick={onClick}
    >
      <div className={styles.icon} style={{ display: value == true ? "flex" : "none" }}>
        <Icon imageName="checkboxChecked" />
      </div>
      <div className={styles.icon} style={{ display: value == false ? "flex" : "none" }}>
        <Icon imageName="checkbox" />
      </div>
      <div className={styles.icon} style={{ display: value == undefined ? "flex" : "none" }}>
        <Icon imageName="checkboxGray" />
      </div>
    </div >
  );
}