export default function IconList() {
  const unit = 10;
  //---------------------------------------------------------------------------
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="1em" height="1em"
      viewBox="0 0 50 50">
      <g
        fill="currentColor"
        stroke="currentColor"
        strokeLinejoin="round"
      >
        <rect
          rx="5"
          width={unit}
          height={unit}
          x="0"
          y="0"
        />
        <rect
          rx="5"
          width={35}
          height={unit}
          x="15"
          y="0"
        />
        <rect
          rx="5"
          width={unit}
          height={unit}
          x="0"
          y="20"
        />
        <rect
          rx="5"
          width={35}
          height={unit}
          x="15"
          y="20"
        />
        <rect
          rx="5"
          width={unit}
          height={unit}
          x="0"
          y="40"
        />
        <rect
          rx="5"
          width={35}
          height={unit}
          x="15"
          y="40"
        />
      </g>
    </svg>
  );
}