import { IUiValidation, IUiValidationMessage, IValidationState } from "./IUiValidation";

interface IProps {
  elementId: string;
  validation?: IUiValidation;
  data: any;
  isUnique?: boolean;
  validationState?: IValidationState;
}

export function ValidateInput(props: IProps): IValidationState {
  const validationState: IValidationState = {
    isValid: true,
    validationMessages: []
  };
  //---------------------------------------------------------------------------
  if (!props.validation) {
    return validationState;
  };
  //---------------------------------------------------------------------------
  if (props.validation.required) {
    let tooShort = false;
    if (typeof props.data === "string" && props.data.length < props.validation.required.value) {
      tooShort = true;
    };
    if (!props.data || tooShort) {
      validationState.isValid = false;
      const validationMessage: IUiValidationMessage = {
        id: `${props.elementId}.Validation.Required.Message`,
        message: props.validation.required.message
      };
      validationState.validationMessages.push(validationMessage);
    };
  };
  //---------------------------------------------------------------------------
  if (props.validation.regexMatch) {
    const regex = new RegExp(props.validation.regexMatch.regexPattern, 'g');
    const isValid = regex.test(props.data);
    if (!isValid) {
      validationState.isValid = false;
      const validationMessage: IUiValidationMessage = {
        id: `${props.elementId}.Validation.RegexMatch.Message`,
        message: props.validation.regexMatch.message
      };
      validationState.validationMessages.push(validationMessage);
    };
  };
  //---------------------------------------------------------------------------
  if ((props.isUnique !== undefined) && props.validation.unique) {
    if (props.isUnique == false) {
      validationState.isValid = false;
      const validationMessage: IUiValidationMessage = {
        id: `${props.elementId}.Validation.Unique.Message`,
        message: props.validation.unique.message
      };
      validationState.validationMessages.push(validationMessage);
    };
  };
  //---------------------------------------------------------------------------
  return validationState;
}

export function ValidateDropdownInput(props: IProps): IValidationState {
  const validationState: IValidationState = {
    isValid: true,
    validationMessages: []
  };
  //---------------------------------------------------------------------------
  if (!props.validation) {
    return validationState;
  };
  //---------------------------------------------------------------------------
  if (props.validation.required) {
    //console.log(props.data)
    if (!props.data) {
      validationState.isValid = false;
      const validationMessage: IUiValidationMessage = {
        id: `${props.elementId}.Validation.Required.Message`,
        message: props.validation.required.message
      };
      validationState.validationMessages.push(validationMessage);
    };
  };
  //---------------------------------------------------------------------------
  return validationState;
}

export function ValidateUnique(props: IProps): IValidationState {
  const validationState: IValidationState = props.validationState ? props.validationState : {
    isValid: true,
    validationMessages: []
  };
  if (props.validation?.unique && props.isUnique == false) {
    validationState.isValid = false;
    const validationMessage: IUiValidationMessage = {
      id: `${props.elementId}.Validation.Unique.Message`,
      message: props.validation.unique.message
    };
    validationState.validationMessages.push(validationMessage);
  };
  return validationState;
}