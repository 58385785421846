import { useEffect, useRef } from "react";

export default function useFaviconAlert() {
  const favicon = useRef<HTMLLinkElement>();
  const titleToRestore = useRef<string>();
  const title = useRef<string>();
  const alertIcon = useRef(false);
  const timer = useRef<NodeJS.Timeout>();
  //--------------------------------------------------------------------------- Clean up
  useEffect(() => {
    return (() => {
      if (timer)
        clearInterval(timer.current);
      if (titleToRestore.current)
        document.title = titleToRestore.current;
      if (favicon.current)
        favicon.current.href = "/favicon.svg";
    });
  }, []);
  //---------------------------------------------------------------------------
  const setAlert = (isOn: boolean) => {
    if (!favicon.current) {
      favicon.current = document.getElementById("favicon") as HTMLLinkElement;
      console.log(favicon.current)
    };
    //-------------------------------------------------------------------------
    if (isOn) {
      titleToRestore.current = document.title;
      title.current = document.title + " - ";
      timer.current = setInterval(() => {
        if (favicon.current) {
          alertIcon.current = !alertIcon.current;
          favicon.current.href = alertIcon.current ?
            "/favicon_alert.svg" :
            "/favicon.svg";
        };
        if (title.current) {
          const firstChar = title.current.substring(0, 1);
          title.current = title.current.substring(1) + firstChar;
          document.title = title.current;
        };
      }, 500);
    } else {
      clearInterval(timer.current);
      if (titleToRestore.current) {
        favicon.current.href = "/favicon.svg";
        document.title = titleToRestore.current;
        title.current = undefined;
      }
    };
  };
  //---------------------------------------------------------------------------
  return setAlert;
}