//import styles from './.module.css';

import { TMove } from "../sorter-editor/SorterEditor";

interface IProps {
  move: TMove;
}

export default function (props: IProps) {
  const transform = props.move == "Down" ? "scale(1,-1) translate(0,-14)" : undefined;
  //---------------------------------------------------------------------------
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="1em" height="1em"
      viewBox="0 0 14 14">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.646.146a.5.5 0 0 1 .708 0l3.5 3.5a.5.5 0 0 1-.354.854H8V13a1 1 0 1 1-2 0V4.5H3.5a.5.5 0 0 1-.354-.854z"
        clipRule="evenodd"
        transform={transform}
      />
    </svg>
  );
}