import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../context/app-auth-context/AppAuthContext";
import { apiGetPrivate, checkResponse } from "../../../utils/api";
import { apiBasePath, pathGetBusiness } from "../../../utils/apiPathConstant";
import { stringFormatter } from "../../../utils/common";
import { ClassCustomerBusiness } from "../business-editor/ICustomerBusiness";
import { IAdLanguageSetListItem } from "../../ad-content-editor/IAdLanguageSet";

interface IResponse {
  business: ClassCustomerBusiness;
  ads: IAdLanguageSetListItem[];
}

interface IProps {
  msalInstance: IPublicClientApplication,
  businessId?: string,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
}

export default async function getBusinessAsync(props: IProps): Promise<IResponse> {
  if (!props.authConfig) throw new Error("getBusinessAsync: auth config is not provided");
  if (!props.businessId) throw new Error("getBusinessAsync: business id is not provided");
  //---------------------------------------------------------------------------
  const uri = `${apiBasePath}${stringFormatter(pathGetBusiness, [props.businessId])}`;
  const response = await apiGetPrivate(
    props.msalInstance,
    props.authConfig,
    uri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getBusinessAsync", props.abortSignal?.aborted);
  const responseData: IResponse = {
    business: new ClassCustomerBusiness(response?.content.business),
    ads: response?.content.ads as IAdLanguageSetListItem[]
  };
  return responseData;
}