import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathGetListAdModuleSystem } from "../../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../../utils/common";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";
import { EHttpStatusCode } from "../../../../../utils/HttpStatusCodes";
import { ClassAdModule, IAdModule } from "../../../../common/ad-modules/IAdModule";

interface IProps {
  msalInstanse: IPublicClientApplication,
  businessId?: string,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
}

export default async function getListAdModulesAsync(props: IProps): Promise<ClassAdModule[]> {
  if (!props.authConfig) throw new Error("getListAdModulesAsync: auth config is not provided");
  if (!props.businessId) throw new Error("getListAdModulesAsync: business Id is not provided");
  //---------------------------------------------------------------------------
  const uri = `${apiBasePath}${stringFormatter(pathGetListAdModuleSystem, [props.businessId])}`;
  const response = await apiGetPrivate(
    props.msalInstanse,
    props.authConfig,
    uri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getListAdModulesAsync", props.abortSignal?.aborted, [EHttpStatusCode.NotFound]);
  let responseItems = response?.content as IAdModule[];
  const adModules: ClassAdModule[] = [];
  responseItems.forEach(item => adModules.push(new ClassAdModule(item)));
  return (adModules);
}