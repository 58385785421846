import React, { useContext, useEffect, useState } from "react";
import { useAppScreenContext } from "../../../../context/app-screen-context/AppScreenProvider";
import { ImageHandler } from "../../../user-console/user-images/image-library/image-list-card/ImageListCard";
import { ImageList } from "../../../user-console/user-images/image-library/image-list/ImageList";
import { TImageLibraryItem } from "../../../user-console/user-images/image-library/TImageLibrary";
import PopupDialog from "../../popup-v2/popup-dialog/PopupDialog";
import styles from "./ImageSelectorPopup.module.css";
import { ButtonIds, OkCancelToolbar } from "../../popup-v2/ok-cancel-toolbar/OkCancelToolbar";
import { IUiUserImagesList } from "../../../user-console/user-images/image-library/image-list/IUiUserImagesList";
import { IUiInputText } from "../../input-v2/input-text/InputText";
import { TUiMenuOption } from "../../menu/menu-content/menu-option/TUiMenuOption";
import { ImageEditorContext } from "../image-editor-context/ImageEditorContextProvider";
//----------------------------------------------------------------------------
const btnAddNewImage: TUiMenuOption = {
  id: "Add_New_Image",
  directoryId: "",
  elementType: "",
  caption: { en: "Add new image..." },
  hint: { en: "Add new image..." },
  iconFile: "plus",
  isDefault: false,
  disabled: false,
  visible: true,
  action: undefined,
  index: 0,
  priorityLevel: 0,
  canHideCaption: false
}
//----------------------------------------------------------------------------
const tmpSearchInputText: IUiInputText = {
  id: "uiInputText",
  directoryId: "uiInputText",
  elementType: "uiInputText",
  disabled: false,
  visible: true,
  caption: { en: "Image description" },
  hint: { en: "" },
  placeholder: {
    en: "Type to filter images by description",
    ru: "Фильтр по описанию"
  },
  defaultValue: "Image1",
  validation: undefined
}
//----------------------------------------------------------------------------
const imageListUi: IUiUserImagesList = {
  inputImageSearch: tmpSearchInputText
}
//----------------------------------------------------------------------------
interface IProps {
  canSelectItem: boolean;
  selectedItemId?: string;
  itemIdToHide?: string; // if image list is shown to replace this image
  onSelectImage: ImageHandler;
  onCancel: () => void;
}
//----------------------------------------------------------------------------
export function ImageSelectorPopup(props: IProps) {
  const screenType = useAppScreenContext();
  const { imageEditorContext } = useContext(ImageEditorContext);
  const [selectedItem, setSelectedItem] = useState<TImageLibraryItem>();
  const [selectedItemId, setSelectedItemId] = useState(props.selectedItemId);
  const [addImageSignal, setAddImageSignal] = useState(!imageEditorContext.userImages || imageEditorContext.userImages.length == 0);
  //-[addImageSignal]----------------------------------------------------
  useEffect(() => {
    if (addImageSignal) {
      setAddImageSignal(false);
    }
  }, [addImageSignal]);
  //---------------------------------------------------------------------------
  const onPreSelectedImageFound = (image: TImageLibraryItem) => {
    setSelectedItem(image);
    let divId = `ImageListCard_${image.id}`;
    document.getElementById(divId)?.scrollIntoView({ behavior: 'smooth' });
  }
  //---------------------------------------------------------------------------
  const onCancel = () => {
    props.onCancel();
  }
  //---------------------------------------------------------------------------
  const onOk = () => {
    selectedItem && props.onSelectImage(selectedItem);
    //props.onCancel();
  }
  const onAddNewImage = () => {
    setAddImageSignal(true);
  }
  //---------------------------------------------------------------------------
  const onSelectImage = (item: TImageLibraryItem) => {
    setSelectedItem(item);
    setSelectedItemId(item.id);
    //console.log("ImageSelectorPopup.onSelectImage:", item);
  }
  //---------------------------------------------------------------------------
  const toolbar =
    <OkCancelToolbar
      dialogButtons="SelectCancel"
      dialogType="Confirmation"
      btnOptional={btnAddNewImage}
      disabledButtonIds={selectedItem ? undefined : [ButtonIds.select]}
      onCancel={onCancel}
      onOk={onOk}
      onOptional={onAddNewImage}
    />;
  return (
    <PopupDialog
      id='ImageLibrary'
      header={"Image Library"}
      toolbarContent={toolbar}
      isDraggable={false}
      isModal={true}
      onClose={onCancel}
    >
      {/* <ImageSelector onSelectImage={props.onSelectImage} onCancel={onCancel} /> */}
      <div className={styles.scrollContainer}>
        <ImageList
          ui={imageListUi}
          addImageSignal={addImageSignal}
          clearContextOnUnmount={true}
          canSelectItem={props.canSelectItem}
          selectedItemId={selectedItemId}
          itemIdToHide={props.itemIdToHide}
          onSelectImage={onSelectImage}
          onPreSelectedImageFound={onPreSelectedImageFound}
        />
      </div>
    </PopupDialog>
  );
}