import { useContext, useEffect, useState } from 'react';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';
import { ImageEditorContextProvider } from '../../common/image-editor/image-editor-context/ImageEditorContextProvider';
import { CommonBusinessContextProvider } from '../../common/common-business-context/CommonBusinessContextProvider';
import { IUiApplication_BusinessConsole, IUiBusinessConsole } from './IUiBusinessConsole';
import { TUiNames } from '../../../context/app-ui-context/AppUiCache';
import React from 'react';
import WikiSpinner from '../../app-layout/spinner/wikiSpinner';
import { Route, Routes } from 'react-router';
import NavigateWithContext from '../../common/navigate-with-context/NavigateWithContext';
import BusinessOwnerConsole from './business-owner-console/BusinessOwnerConsole';
import { TUiBreadcrumb } from '../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import BusinessEditor from '../business-editor/BusinessEditor';
import BusinessWizard from '../business-wizard/BusinessWizard';
import { MsalTemplateWrapper } from '../../../context/app-auth-context/MsalTemplateWrapper';
import { AppAuthContextStore } from '../../../context/app-auth-context/AppAuthContext';
import BusinessWizardV2 from '../business-wizard/BusinessWizardV2';
import BusinessContextProvider from '../../../context/business-context/BusinessContextProvider';
import AdContentEditor from '../../ad-content-editor/AdContentEditor';
import { IUiMenuContent } from '../../common/options/menus/IUiMenuContent';
import { IUiOption } from '../../common/options/IUiOption';
import MainMenu from '../../common/options/menus/main-menu/MainMenu';
import useNavigateWithContext from '../../../hooks/useNavigateWithContext';
import { tmpUiBusinessWizard } from '../business-wizard/IUiBusinessWizardV2';

const uiName: TUiNames = "businessConsole";
export const pathBusinessConsole = "business";
export const pathBusinessWizard = "wizard";
export const pathAdpageWizard = "adwizard";
//export const pathBusinessWizardV2 = "wizard2";
export const pathBusinessOwnerConsole = "owner";
export const pathBusinessTranslatorConsole = "translator";
export const pathBusinesses = "businesses";
export const pathAds = "ads";
export const pathMessages = "messages";
export const pathBusinessNew = 'new';
const optionIdOwner = "BusinessConsole.MenuContent.OptionOwner";
const optionIdTranslator = "BusinessConsole.MenuContent.OptionTranslator";
export const pathWallet = "wallet";
export const pathStats = "stats";

export default function BusinessConsole() {
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const { appAuthContext } = useContext(AppAuthContextStore);
  const [ui, setUi] = useState<IUiBusinessConsole>();
  const [uiIsLoading, setUiIsLoading] = useState(false);
  const navigate = useNavigateWithContext();
  //--------------------------------------------------------------------------- Get UI from backend
  useEffect(() => {
    if (!appAuthContext.user) return;
    if (!ui && appUiContext.uiCache?.checkAuthorization(uiName)) {
      setUiIsLoading(true);
      appUiContext.uiCache.getUiCallback<IUiApplication_BusinessConsole>(uiName, setUpUi, () => setUiIsLoading(false));
    };
  }, [appAuthContext.user]);
  //---------------------------------------------------------------------------
  function setUpUi(appUi: IUiApplication_BusinessConsole) {
    // Set dictionaries
    if (appUi?.dictionaries) {
      appUiContextDispatch({ type: "UpdateDictionaries", value: appUi.dictionaries });
    };
    //-------------------------------------------------------------------------
    let breadcrumbOwner: TUiBreadcrumb | undefined = undefined;
    let breadcrumbTranslator: TUiBreadcrumb | undefined = undefined;
    appUi.businessConsole.menuContent.options.forEach(option => {
      switch (option.id) {
        case "BusinessConsole.MenuContent.OptionOwner":
          breadcrumbOwner = {
            id: option.id,
            caption: option.caption,
            path: `/${pathBusinessConsole}/${pathBusinessOwnerConsole}`
          };
          break;
        case "BusinessConsole.MenuContent.OptionTranslator":
          breadcrumbTranslator = {
            id: option.id,
            caption: option.caption,
            path: `/${pathBusinessConsole}/${pathBusinessTranslatorConsole}`
          };
          break;
      };
    });
    //-------------------------------------------------------------------------
    // Set console UI
    setUi({
      ...appUi.businessConsole,
      businessOwnerConsole: {
        ...appUi.businessConsole.businessOwnerConsole,
        breadcrumb: breadcrumbOwner
      },
      breadcrumbTranslator: breadcrumbTranslator
    });
  };
  //---------------------------------------------------------------------------
  const getPath = (optionId: string) => {
    switch (optionId) {
      case optionIdOwner:
        return `/${pathBusinessConsole}/${pathBusinessOwnerConsole}`;
      case optionIdTranslator:
        return `/${pathBusinessConsole}/${pathBusinessTranslatorConsole}`;
    };
  };
  //---------------------------------------------------------------------------
  const onMainMenuOptionSelect = (optionId: string) => {
    const path = getPath(optionId);
    navigate(path ? path : "/");
  };
  //---------------------------------------------------------------------------
  // Here should be logic to determine which role to render for the user:
  // if they do not have owner role, check translator, if no translator, check next one...
  // if there are no roles at all, render owner assuming user came from business wizard
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  let selectedOptionId = "undefined";
  let path: string;
  ui?.menuContent.options.forEach(option => {
    switch (option.id) {
      case optionIdOwner:
        if (appAuthContext.user?.businessRoles?.owner) {
          path = getPath(option.id) ?? "undefined";
          selectedOptionId = location.pathname.startsWith(path) ? option.id : selectedOptionId;
          updatedOptions.push({
            ...option,
            iconId: "businessOwner",
            action: path
          });
        };
        break;
      case optionIdTranslator:
        if (appAuthContext.user?.businessRoles?.translator) {
          path = getPath(option.id) ?? "undefined";
          selectedOptionId = location.pathname.startsWith(path) ? option.id : selectedOptionId;
          updatedOptions.push({
            ...option,
            iconId: "translator",
            action: path
          });
        };
        break;
    };
  });
  const updatedMenuContent: IUiMenuContent = { ...ui?.menuContent as IUiMenuContent, options: updatedOptions };
  //---------------------------------------------------------------------------
  return (
    <MsalTemplateWrapper componentName="BusinessConsole">

      <MainMenu
        ui={updatedMenuContent}
        selectedOptionId={selectedOptionId}
        onSelect={onMainMenuOptionSelect}
      />

      <BusinessContextProvider>
        <ImageEditorContextProvider>
          <WikiSpinner show={uiIsLoading} />
          {ui &&
            <Routes>

              <Route
                index
                element={
                  <NavigateWithContext to={`/${pathBusinessConsole}/${pathBusinessOwnerConsole}`} />}
              />

              <Route
                path={`${pathBusinessOwnerConsole}/*`}
                element={
                  <BusinessOwnerConsole
                    ui={{
                      ...ui.businessOwnerConsole,
                      businessList: ui.businessList
                    }}
                  />}
              />

              <Route
                path={`:role/${pathBusinesses}/:businessId/*`}
                element={
                  <BusinessEditor ui={ui.businessEditor} />}
              />

              <Route
                path={`/:role/${pathBusinesses}/:businessId/${pathAds}/:adLanguageSetId/*`}
                element={
                  <AdContentEditor
                    ui={ui.adPageEditor}
                    useHash={false}
                  />} />

              {/* <Route
                path={pathBusinessWizard}
                element={
                  <BusinessWizard type="Business" />}
              />
              <Route
                path={pathAdpageWizard}
                element={
                  <BusinessWizard type="AdPage" />}
              /> */}

              <Route
                path={`:role/${pathBusinessWizard}/*`}
                element={
                  <BusinessWizardV2
                    ui={{
                      ...tmpUiBusinessWizard,
                      inputListTags: ui.businessEditor.sectionTags.selectorBusinessTags,
                      inputListContacts: ui.businessEditor.sectionContacts.inputListContacts
                    }}
                  />}
              />

            </Routes>}
        </ImageEditorContextProvider>
      </BusinessContextProvider>
    </MsalTemplateWrapper>
  );
}