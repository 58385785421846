import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { ApiKey } from "./apikey-card/ApiKeyCard";
import { apiBasePath, pathUpdateApiKey } from "../../../../utils/apiPathConstant";
import { apiPostPrivate, checkResponse } from "../../../../utils/api";
import { stringFormatter } from "../../../../utils/common";

export default async function updateApiKey(
  msalInstance: IPublicClientApplication,
  apiKey: ApiKey,
  isNew: boolean,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal,
): Promise<ApiKey[]> {
  return new Promise((resolve, reject) => {
    const uri = `${apiBasePath}${stringFormatter(pathUpdateApiKey, [isNew ? 'true' : 'false'])}`;
    apiPostPrivate(
      msalInstance,
      authConfig,
      uri,
      apiKey,
      undefined,
      abortSignal
    ).then(response => {
      checkResponse(response, "updateApiKey", abortSignal?.aborted);
      resolve(ApiKey.fromArray(response?.content));
    }).catch(error =>
      reject(error)
    );
  });
}