import Icon from '../../../../common/icon/Icon';
import { IItemStateGroup } from '../../../administrator-console/dictionaries/business-tags-editor/BusinessTagsEditor';
import styles from './LegendItem.module.css';

interface IProps {
  ui: IItemStateGroup;
  enabled: boolean;
  onSwitch: (enabled: boolean) => void;
}

export default function LegendItem(props: IProps) {
  //---------------------------------------------------------------------------
  const onClick = () => {
    props.onSwitch(!props.enabled);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      style={{
        color: props.enabled ? props.ui.color : 'var(--clr_font_system)'
      }}
      onClick={onClick}
    >
      <div style={{
        opacity: '.7',
        marginLeft: '0'
      }}>
        <Icon imageName='circle' />
      </div>
      <div style={{
        //fontStyle: props.ui.fontStyle,
        //textDecoration: props.ui.textDecoration
      }}>
        {props.ui.name}
      </div>
    </div>
  );
}