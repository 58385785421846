import { IUiInteractiveForm } from "../../../../common/forms/IUiInteractiveForm";
import { IUiInputDropdown } from "../../../../common/input-v2/input-dropdown/InputDropdown";
import { IUiInputText } from "../../../../common/input-v2/input-text/InputText";

interface IUiEditformRejectionReason extends IUiInteractiveForm {
  inputRejectionReason: IUiInputDropdown;
  inputRejectionReasonDetails: IUiInputText;
}

export const tmpUiEditFormRejection: IUiEditformRejectionReason = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: { en: "Rejection reason" },
  hint: { en: "Rejection reason" },
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionOk",
      directoryId: "",
      elementType: "",
      caption: { en: "Ok" },
      hint: { en: "Confirm" }
    }, {
      id: "OptionCancel",
      directoryId: "",
      elementType: "",
      caption: { en: "Cancel" },
      hint: { en: "Cancel" },
    }]
  },
  inputRejectionReason: {
    id: "InputRejectionReason",
    directoryId: "",
    elementType: "",
    dictionaryId: "",
    caption: { en: "Reason" },
    hint: { en: "Rejection reason" },
    placeholder: { en: "Select a reason..." },
    validation: {
      required: {
        value: 1,
        message: { en: "Reason is required" }
      }
    }
  },
  inputRejectionReasonDetails: {
    id: "",
    directoryId: "",
    elementType: "",
    caption: { en: "Comment" },
    hint: { en: "Additional info on the rejection reason" },
    placeholder: { en: "Type here..." }
  }
}