import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router";
import { AppAuthContextStore } from "../../context/app-auth-context/AppAuthContext";
import { AppUiContextStore } from "../../context/app-ui-context/AppUiContextProvider";
import WikiSpinner from "../app-layout/spinner/wikiSpinner";
import { CommonBusinessContextProvider } from "../common/common-business-context/CommonBusinessContextProvider";
import { ImageEditorContextProvider } from "../common/image-editor/image-editor-context/ImageEditorContextProvider";
import NavigateWithContext from "../common/navigate-with-context/NavigateWithContext";
import { AdminConsoleContextProvider } from "./admin-console-context/AdminConsoleContextProvider";
import AdministratorConsole from "./administrator-console/AdministratorConsole";
import ContentManagerConsole from "./content-manager-console/ContentManagerConsole";
import ModeratorConsole from "./moderator-console/ModeratorConsole";
import SystemTranslatorConsole from "./system-translator-console/SystemTranslatorConsole";
import { TUiNames } from "../../context/app-ui-context/AppUiCache";
import { MsalTemplateWrapper } from "../../context/app-auth-context/MsalTemplateWrapper";
import { AbortControllerWrapper, useAbortController } from "../../hooks/useAbortController";
import { IUiAdminConsole, IUiApplication_AdminConsole } from "./IUiAdminConsole";
import RegionalHomepageEditor from "./regional-homepages/regional-homepage-editor/RegionalHomepageEditor";
import { IUiOption } from "../common/options/IUiOption";
import AdContentEditor from "../ad-content-editor/AdContentEditor";
import BusinessContextProvider from "../../context/business-context/BusinessContextProvider";
import BusinessTagsConsole from "./administrator-console/business-tags-console/BusinessTagsConsole";
import DirectoryEditorContextProvider from "../../context/directory-editor-context/DirectoryEditorContextProvider";
import MainMenu from "../common/options/menus/main-menu/MainMenu";
import { IUiMenuContent } from "../common/options/menus/IUiMenuContent";
import useNavigateWithContext from "../../hooks/useNavigateWithContext";

export const pathAdminConsole = "adminco";
//-----------------------------------------------------------------------------
export const pathAdministratorConsole = "administrator";
export const optionIdAdministratorConsole = "AdminConsole.MenuContent.OptionAdministrator";
export const pathAdministratorConsoleFull = `${pathAdminConsole}/${pathAdministratorConsole}`;
export const pathBusinessTagsConsole = "businesstagsconsole";
//-----------------------------------------------------------------------------
export const pathContentManagerConsole = "contentmanager";
export const optionIdContentManagerConsole = "AdminConsole.MenuContent.OptionContentManager";
export const pathContentManagerConsoleFull = `${pathAdminConsole}/${pathContentManagerConsole}`;
//-----------------------------------------------------------------------------
export const pathRegionalHomepages = "regionhomepages";
export const optionIdRegionalHomepages = "AdministratorConsole.MenuContent.OptionRegionalHomepages";
export const pathFullRegionalHomepages = `/${pathAdminConsole}/${pathAdministratorConsole}/${pathRegionalHomepages}`;
export const pathAds = "ads";
//-----------------------------------------------------------------------------
export const pathModeratorConsole = "moderator";
export const optionIdModeratorConsole = "AdminConsole.MenuContent.OptionModerator";
export const pathModeratorConsolePathFull = pathAdminConsole + "/" + pathModeratorConsole;
export const pathContentManagerConsolePathFull = pathAdminConsole + "/" + pathContentManagerConsole;
//-----------------------------------------------------------------------------
export const pathSystemTranslator = "translator";
export const optionIdSystemTranslatorConsole = "AdminConsole.MenuContent.OptionSystemTranslator";

const uiName: TUiNames = "adminConsole";

export default function AdminConsole() {
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const [isLoading, setIsLoading] = useState(false);
  const [ui, setUi] = useState<IUiAdminConsole>();
  const abortController = useAbortController("AdminConsole");
  const navigate = useNavigateWithContext();
  const location = useLocation();
  //--------------------------------------------------------------------------- AbortController
  useEffect(() => {
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //--------------------------------------------------------------------------- Get admin console UI
  useEffect(() => {
    if (ui || !appUiContext.uiCache?.checkAuthorization(uiName)) {
      setIsLoading(false);
      return;
    };
    //-------------------------------------------------------------------------
    setIsLoading(true);
    const controller = abortController.newController("GetUi");
    appUiContext.uiCache.getUiCallback<IUiApplication_AdminConsole>(
      uiName,
      (ui) => setConsoleUi(ui, controller),
      () => { !controller.aborted && setIsLoading(false); },
      controller.signal
    );
  }, [appAuthContext]);
  //---------------------------------------------------------------------------
  function setConsoleUi(consoleUi: IUiApplication_AdminConsole, controller: AbortControllerWrapper) {
    if (!controller.aborted) {
      // Set dictionaries
      if (consoleUi?.dictionaries) {
        appUiContextDispatch({ type: "UpdateDictionaries", value: consoleUi.dictionaries });
      };
      //-------------------------------------------------------------------------
      setUi(consoleUi.adminConsole);
    }
  };
  //---------------------------------------------------------------------------
  const getPath = (optionId: string) => {
    switch (optionId) {
      case optionIdAdministratorConsole:
        return `/${pathAdministratorConsoleFull}`;
      case "AdminConsole.MenuContent.OptionModerator":
        return `/${pathAdminConsole}/${pathModeratorConsole}`;
      case "AdminConsole.MenuContent.OptionContentManager":
        return `/${pathAdminConsole}/${pathContentManagerConsole}`;
      case optionIdSystemTranslatorConsole:
        return `/${pathAdminConsole}/${pathSystemTranslator}`;
    };
  };
  //---------------------------------------------------------------------------
  const onMainMenuOptionSelect = (optionId: string) => {
    const path = getPath(optionId);
    navigate(path ? path : "/");
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  let selectedOptionId = "undefined";
  let path: string;
  ui?.menuContent.options.forEach(option => {
    switch (option.id) {
      case optionIdAdministratorConsole:
        if (appAuthContext.user?.staffRoles?.administrator) {
          path = getPath(option.id) ?? "undefined";
          selectedOptionId = location.pathname.startsWith(path) ? option.id : selectedOptionId;
          updatedOptions.push({
            ...option,
            iconId: "administrator",
            action: path
          });
        };
        break;
      case "AdminConsole.MenuContent.OptionModerator":
        if (appAuthContext.user?.staffRoles?.moderator || appAuthContext.user?.staffRoles?.superModerator) {
          path = getPath(option.id) ?? "undefined";
          selectedOptionId = location.pathname.startsWith(path) ? option.id : selectedOptionId;
          updatedOptions.push({
            ...option,
            iconId: "moderator",
            action: path
          });
        };
        break;
      case "AdminConsole.MenuContent.OptionContentManager":
        if (appAuthContext.user?.staffRoles?.contentManager) {
          path = getPath(option.id) ?? "undefined";
          selectedOptionId = location.pathname.startsWith(path) ? option.id : selectedOptionId;
          updatedOptions.push({
            ...option,
            iconId: "systemContent",
            action: path
          });
        };
        break;
      case optionIdSystemTranslatorConsole:
        if (appAuthContext.user?.staffRoles?.systemTranslator) {
          path = getPath(option.id) ?? "undefined";
          selectedOptionId = location.pathname.startsWith(path) ? option.id : selectedOptionId;
          updatedOptions.push({
            ...option,
            iconId: "translator",
            action: path
          });
        };
        break;
    };
  });
  const updatedMenuContent: IUiMenuContent = { ...ui?.menuContent as IUiMenuContent, options: updatedOptions };
  //---------------------------------------------------------------------------
  return (
    <MsalTemplateWrapper componentName="AdminConsole">

      <MainMenu
        ui={updatedMenuContent}
        selectedOptionId={selectedOptionId}
        onSelect={onMainMenuOptionSelect}
      />

      <WikiSpinner show={isLoading} />

      {appAuthContext.user?.staffRoles &&
        <AdminConsoleContextProvider>
          <ImageEditorContextProvider>
            <BusinessContextProvider>
              <DirectoryEditorContextProvider>
                <Routes>

                  {// Administrator
                    appAuthContext.user.staffRoles?.administrator &&
                    <React.Fragment>
                      <Route
                        index
                        element={
                          <AdministratorConsole
                            optionsForBreadcrumb={ui?.menuContent.options}
                          />} />
                      <Route
                        path={`${pathAdministratorConsole}/*`}
                        element={
                          <AdministratorConsole
                            optionsForBreadcrumb={ui?.menuContent.options}
                          />} />
                      <Route path={`${pathAdministratorConsole}/${pathBusinessTagsConsole}/*`} element={
                        <BusinessTagsConsole
                          ui={ui?.businessTagsConsole}
                          optionsForBreadcrumb={ui?.menuContent.options}
                        />} />
                    </React.Fragment>}

                  {// Moderator
                    (appAuthContext.user.staffRoles?.moderator || appAuthContext.user.staffRoles.superModerator) &&
                    <React.Fragment>
                      <Route
                        index
                        element={
                          <ModeratorConsole
                            optionsForBreadcrumb={ui?.menuContent.options}
                          />} />
                      <Route
                        path={`${pathModeratorConsole}/*`}
                        element={
                          <ModeratorConsole
                            optionsForBreadcrumb={ui?.menuContent.options}
                          />} />
                    </React.Fragment>}

                  {// System Translator
                    appAuthContext.user.staffRoles.systemTranslator &&
                    <React.Fragment>
                      <Route index element={<NavigateWithContext to={`/${pathAdminConsole}/${pathSystemTranslator}`} />} />
                      <Route
                        path={`${pathSystemTranslator}/*`}
                        element={
                          <SystemTranslatorConsole
                            optionsForBreadcrumb={ui?.menuContent.options}
                          />} />
                    </React.Fragment>}

                  {// Content Manager
                    appAuthContext.user.staffRoles.contentManager &&
                    <React.Fragment>
                      <Route index element={<NavigateWithContext to={`/${pathAdminConsole}/${pathContentManagerConsole}`} />} />
                      <Route
                        path={`${pathContentManagerConsole}/*`}
                        element={
                          <ContentManagerConsole
                            optionsForBreadcrumb={ui?.menuContent.options}
                          />} />
                    </React.Fragment>}

                  {// Regional Homepages
                    (appAuthContext.user.staffRoles?.administrator || appAuthContext.user.staffRoles.contentManager) &&
                    <React.Fragment>
                      {// Regional Homepage business Editor
                        ui?.regionalHomepageEditor &&
                        <Route
                          //path={`:role/${pathRegionalHomepages}/:systemBusinessId`}
                          path={`:role/${pathRegionalHomepages}/:systemBusinessId/*`}
                          element={
                            <RegionalHomepageEditor
                              ui={ui.regionalHomepageEditor}
                            />} />}

                      {// Content editor
                        ui?.adPageEditor &&
                        <Route
                          path={`/:role/${pathRegionalHomepages}/:businessId/${pathAds}/:adLanguageSetId/*`}
                          element={
                            <AdContentEditor
                              ui={ui.adPageEditor}
                              useHash={false}
                            />} />}
                    </React.Fragment>}

                </Routes>
              </DirectoryEditorContextProvider>
            </BusinessContextProvider>
          </ImageEditorContextProvider>
        </AdminConsoleContextProvider>}

    </MsalTemplateWrapper>
  );
}
