import React, { useContext } from "react";
import CombineStyles from "../../../utils/combineStyles";
import { ButtonType } from "../button/ButtonType";
import { TUiButton } from "../button/TUiButton";
import GetText from "../functions/GetText";
import Icon, { IconSize } from "../icon/Icon";
import styles from "./ButtonCaption.module.css";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";

interface IProps {
  ui: TUiButton;
  type: ButtonType; // Toolbar | Popup
  customCaption?: string; // This could be passed in case if caption should be dynamic (only in case of dropdown button with options provided)
  displayIcon: boolean;
  isDropdown?: boolean;
  spin?: boolean;
}


export default function ButtonCaption(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  //-------------------------------------------------------------------------
  const spin = (props.spin === undefined) ? false : props.spin;
  // Determine if caption should be displayed
  const showCaption = (props.type === ButtonType.Popup) ? true : props.ui.canHideCaption ? false : true;
  const isDropdown = props.isDropdown == undefined ? false : props.isDropdown;
  //-------------------------------------------------------------------------
  // Dropdown icon (on the right)
  const optionsIcon = (props.type === ButtonType.Popup) ?
    <div
      className={CombineStyles([
        styles.optionsButton,
        styles.popup
      ])}>
      <Icon
        imageName="rightArrow"
        size={IconSize.Small}
        reserveSpace={false}
      />
    </div>
    :
    <div>
      <Icon
        imageName="downArrowSolid"
        size={IconSize.Small}
        reserveSpace={false}
      />
    </div>
  //-------------------------------------------------------------------------
  return (
    <React.Fragment>
      {props.displayIcon && props.ui.iconFile &&
        <Icon
          imageName={props.ui.iconFile}
          size={IconSize.Small}
          reserveSpace={props.type === ButtonType.Popup}
          spin={spin}
        />}

      {showCaption &&
        <div>
          {props.customCaption ?
            props.customCaption
            :
            GetText({
              appUiContext: appUiContext,
              elementId: props.ui.id,
              elementPart: "Caption",
              text: props.ui.caption,
            })}
        </div>}

      {isDropdown &&
        optionsIcon}

    </React.Fragment>
  );
}