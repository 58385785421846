import { useMsal } from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import { AppAuthContextStore } from "../../context/app-auth-context/AppAuthContext";
import { ScreenType, useAppScreenContext } from "../../context/app-screen-context/AppScreenProvider";
import { apiGet } from "../../utils/api";
import { apiBasePath, getB2cHealthStatus, getCosmosDbHealthStatus, getHealthStatus, getStorageHealthStatus } from "../../utils/apiPathConstant";
import { HealthStatusCosmosDb } from "./cosmos-db-health-status/HealthStatusCosmosDb";
import { CosmosDbServiceHealthStatus } from "./CosmosDBHealthStatus";
import { HealthStatusB2c } from "./HealthStatusB2c";
import { HealthStatusCactus } from "./HealthStatusCactus";
import { HealthStatusHeader } from "./HealthStatusHeader";
import { HealthStatusMessages } from "./HealthStatusMessages";
import { HealthStatusStorage } from "./HealthStatusStorage";
import { RikiWikiHealthStatus, ServiceHealthStatus } from "./RikiWikiHealthStatus";
import styles from "./StatusPage.module.css";
import { useAbortController } from "../../hooks/useAbortController";

export function StatusPage() {
  const screenType = useAppScreenContext();
  const [healthStatus, setHealthStatus] = useState<RikiWikiHealthStatus>();
  const [spinner, setSpinner] = useState(false);

  const [b2cHealthStatus, setB2cHealthStatus] = useState<ServiceHealthStatus>();
  const [storageHealthStatus, setStorageHealthStatus] = useState<ServiceHealthStatus>();
  const [cosmosDbHealthStatus, setCosmosDbHealthStatus] = useState<CosmosDbServiceHealthStatus>();

  const [b2cSpinner, setB2cSpinner] = useState(false);
  const [storageSpinner, setStorageSpinner] = useState(false);
  const [cosmosDbSpinner, setCosmosDbSpinner] = useState(false);

  const abortController = useAbortController("StatusPage");

  useEffect(() => {
    //getStatusesAsync();
    getOverallStatus();
  }, [])

  useEffect(() => {
    console.log("StatusPage.useEffect.healthStatus:", healthStatus);
  }, [healthStatus]);

  const getB2cStatus = () => {
    // call API
    setB2cSpinner(true);
    apiGet(`${apiBasePath}${getB2cHealthStatus}`)
      .then((response) => {
        if (response && response.status === 200) {
          setB2cHealthStatus(response.content);
        }
        else {
          console.error(response);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setB2cSpinner(false);
      });
  }

  const getStorageStatus = () => {
    // call API
    setStorageSpinner(true);
    apiGet(`${apiBasePath}${getStorageHealthStatus}`)
      .then((response) => {
        if (response && response.status === 200) {
          setStorageHealthStatus(response.content);
        }
        else {
          console.error(response);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setStorageSpinner(false);
      });
  }

  //-------------------------------------------------------------
  const getCosmosDbStatus = () => {
    // call API
    setCosmosDbSpinner(true);
    apiGet(`${apiBasePath}${getCosmosDbHealthStatus}`)
      .then((response) => {
        if (response && response.status === 200) {
          let status: CosmosDbServiceHealthStatus = CosmosDbServiceHealthStatus.FromJsonDirect(response.content);
          setCosmosDbHealthStatus(status);
          console.log("CosmosDbHealthStatus:", status);
        }
        else {
          console.error(response);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setCosmosDbSpinner(false);
      });
  }

  //-------------------------------------------------------------
  const getStatusesAsync = () => {
    getB2cStatus();
    getStorageStatus();
    return;

    // call API
    setSpinner(true);
    apiGet(`${apiBasePath}${getHealthStatus}`)
      .then((response) => {
        if (response && response.status === 200) {
          setHealthStatus(response.content);
        }
        else {
          console.error(response);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setSpinner(false);
      });
  }

  //-------------------------------------------------------------
  const getOverallStatus = () => {
    setSpinner(true);
    let controller = abortController.newController("getOverallStatus");
    apiGet(`${apiBasePath}${getHealthStatus}`, controller.signal)
      .then(response => {
        if (!response)
          throw Error("response from getHealthStatus is undefined");
        if (controller.aborted)
          throw Error("call to getHealthStatus API was aborted");
        console.log(response.content);
        if (response.status === 200) {
          return setHealthStatus(response.content);
        }
        try {
          setHealthStatus(response.content);
        }
        catch (error) {
          console.error(response.content);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        !controller.aborted && setSpinner(false);
      });
  }
  //-------------------------------------------------------------
  let flow = screenType == ScreenType.Mobile ? "column" : "row";
  let wrap = screenType == ScreenType.Mobile ? "nowrap" : "wrap";
  //-------------------------------------------------------------
  return (
    <div style={{ display: 'flex', flexFlow: 'column', overflow: 'hidden' }}>
      <HealthStatusHeader
        status={healthStatus?.overallStatus ?? "undefined"}
        errorPhrase={healthStatus?.errorPhrase}
        onRefreshStatus={getOverallStatus}
      />
      <div
        className={styles.container}
/*         style={{
          flexFlow: screenType == ScreenType.Mobile ? "column" : "row", 
          flexWrap: screenType == ScreenType.Mobile ? "nowrap" : "wrap"
        }}
 */      >
        {healthStatus && healthStatus.identityServiceStatus &&
          <HealthStatusB2c status={healthStatus.identityServiceStatus} spinner={spinner} />
        }
        {healthStatus && healthStatus.storageServiceStatus &&
          <HealthStatusStorage status={healthStatus.storageServiceStatus} spinner={spinner} />
        }
        {healthStatus && healthStatus.dictionaryServiceStatus &&
          <HealthStatusCactus status={healthStatus.dictionaryServiceStatus} spinner={spinner} />
        }
        {healthStatus && healthStatus.messageServiceStatus &&
          <HealthStatusMessages status={healthStatus.messageServiceStatus} spinner={spinner} />
        }
        {healthStatus && healthStatus.databaseServiceStatus &&
          <HealthStatusCosmosDb status={healthStatus.databaseServiceStatus} spinner={spinner} />
        }
      </div>
    </div>
  );
}