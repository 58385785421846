import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";
import { apiBasePath, pathGetBusinessTagsDictionaryDraft } from "../../../../../utils/apiPathConstant";
import { BusinessTagsDictionaryDraft } from "../business-tags-editor/IBusinessTagsDictionaryDraft";
import { IDictionary } from "../../../../../context/directory-editor-context/IDirectoryEditorContextData";
import { IBusinessTagsDictionaryResponseContent } from "./IBusinessTagsDictionaryResponseContent";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
  lockRequired?: boolean;
}

export async function getBusinessTagsDictionaryDraftAsync(props: IProps): Promise<IBusinessTagsDictionaryResponseContent> {
  if (!props.authConfig) throw new Error("getBusinessTagsDictionaryDraftAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  let apiUri = `${apiBasePath}${pathGetBusinessTagsDictionaryDraft}`;
  if (props.lockRequired) {
    apiUri += "?lockRequired=true";
  };
  const response = await apiGetPrivate(
    props.msalInstance,
    props.authConfig,
    apiUri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getBusinessTagsDictionaryDraftAsync", props.abortSignal?.aborted);
  const draft = new BusinessTagsDictionaryDraft().initialize(response?.content.draft);  
  return {
    draft: draft,
    subDictionariesList: response?.content.subDictionariesList,
    subDictionaryOriginals: response?.content.subDictionaryOriginals as IDictionary[]
  };
}