import { TLocker } from "../../../common/locks/TLocker";
import { IDirectoryDraft } from "../directory-editor/IDirectoryDraft";

/* export interface IDirectoryTranslationDraftItem extends IDirectoryDraftItem {
}

export class DirectoryTranslationDraftItem implements IDirectoryTranslationDraftItem {
  id!: string;
  draftState!: any;
  //---------------------------------------------------------------------------
  constructor(source: IDirectoryTranslationDraftItem) {
    Object.assign(this, source);
  };
} */

export interface IDirectoryTranslationDraft<TItem> extends IDirectoryDraft<TItem> {  
}

interface IDirectoryTranslationDraftSpread<TItem> {
  items?: TItem[];
}

export class DirectoryTranslationDraft<TItem> implements IDirectoryTranslationDraft<TItem> {
  directoryId!: string;
  locale!: string;
  items!: TItem[];
  datetimeSaved?: string;
  log!: any;
  locker?: TLocker;
  //---------------------------------------------------------------------------
  constructor(source: IDirectoryTranslationDraft<TItem>) {
    Object.assign(this, source);
  }
  //---------------------------------------------------------------------------
  getUpdatedState(update: IDirectoryTranslationDraftSpread<TItem>): DirectoryTranslationDraft<TItem> {
    const updatedData = new DirectoryTranslationDraft<TItem>(this);
    Object.assign(updatedData, update);
    return updatedData;
  }
}