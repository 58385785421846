import { IAppUiContext } from "../../../context/app-ui-context/AppUiContextProvider";
import { IUiDictionaryItem } from "../../common/dictionaries/IUiDictionaryItem";
import { Flag, Flags } from "../../common/icon/FlagIcon";

//--------------------------------------------------------------------------
export class TSearchLanguage {
  id!: string;
  groupId!: string;
  flag?: Flag;
  dictionaryItem?: IUiDictionaryItem;
  index: number = -1;
  //------------------------------------------------------------------------
  constructor(id: string, uiContext: IAppUiContext, flags: Flags) {
    let dictionary = uiContext.dictionaries?.locales as IUiDictionaryItem[];
    this.id = id;
    this.index = uiContext.preferredLanguages.findIndex(item => item == id);
    if (this.index < 0) {
      this.index = uiContext.preferredLanguages.length;
    }
    let dictionaryItem = dictionary.find(item => item.id == id);
    this.dictionaryItem = dictionaryItem;
    if (!dictionaryItem?.path)
      this.groupId = id;
    else if (dictionaryItem.path.indexOf('.') < 0)
      this.groupId = dictionaryItem.path;
    else
      this.groupId = dictionaryItem.path.split('.')[0]
    this.flag = flags.getFlagByLocale(id);
  }
}
//--------------------------------------------------------------------------
export class TSearchLanguageGroup {
  id!: string;
  dictionaryItem?: IUiDictionaryItem;
  items: TSearchLanguage[] = [];
  private _index: number = -1;
  get index(): number {
    let result = this._index;
    this.items.forEach(item => {
      if (item.index < result) {
        result = item.index;
      }
    });
    return result;
  }
  //------------------------------------------------------------------------
  constructor(id: string, uiContext: IAppUiContext) {
    let dictionary = uiContext.dictionaries?.locales as IUiDictionaryItem[];
    this.id = id;
    this._index = uiContext.preferredLanguages.findIndex(item => item == id);
    if (this._index < 0) {
      this._index = uiContext.preferredLanguages.length;
    }
    this.dictionaryItem = dictionary.find(item => item.id == id);
  }
  //------------------------------------------------------------------------
  singleLanguage(): TSearchLanguage | undefined {
    if (this.items.length == 1)
      return this.items[0];
    return undefined;
  }
  //------------------------------------------------------------------------
  contains(locale: string): boolean {
    if (locale == this.id) {
      return true;
    }
    return this.items.findIndex(item => item.id == locale) >= 0;
  }
  //------------------------------------------------------------------------
  compare(other: TSearchLanguageGroup): number {
    return this.index - other.index;
  }
}
//--------------------------------------------------------------------------------
export class TSearchLanguageGroups {
  items: TSearchLanguageGroup[] = [];
  //-------------------------------------------------------------------------------
  sortByContext() {
    this.items.sort((i1, i2) => i1.compare(i2));
  }
  //-------------------------------------------------------------------------------
  constructor(locales: string[], uiContext: IAppUiContext, flags: Flags) {
    locales.forEach(locale => {
      let language = new TSearchLanguage(locale, uiContext, flags);
      let group = this.items.find(group => group.id == language.groupId);
      if (!group) {
        group = new TSearchLanguageGroup(language.groupId, uiContext);
        this.items.push(group);
      }
      group.items.push(language);
    })
  }
  //-------------------------------------------------------------------------------
  groupByLocale(locale: string) {
    for (let item of this.items) {
      if (item.id == locale) {
        return item;
      }
      for (let subItem of item.items)
        if (subItem.id == locale) {
          return item;
        }
    }
  }
  //-------------------------------------------------------------------------------
  moveToTop(locale: string) {
    let item = this.groupByLocale(locale);
    if (!item)
      return;
    let itemIndex = this.items.indexOf(item);
    if (itemIndex == 0)
      return;
    let firstItem = this.items[0];
    this.items[itemIndex] = firstItem;
    this.items[0] = item;
  }
}
