import { useContext, useEffect, useRef, useState } from 'react';
import { IUiInputListLanguages } from '../InputListLanguages';
import styles from './LanguagesList.module.css';
import { AppUiContextStore } from '../../../../../../context/app-ui-context/AppUiContextProvider';
import LanguagesListItem from './languages-list-item/LanguagesListItem';
//import getCultures, { ICulture, TCulture } from '../../../../../admin-console/administrator-console/dictionaries/locales-editor/getCultures';
import { useAbortController } from '../../../../../../hooks/useAbortController';
//import { useMsal } from '@azure/msal-react';
//import { AppAuthContextStore } from '../../../../../../context/app-auth-context/AppAuthContext';
import PopupDialog from '../../../../../common/popup-v2/popup-dialog/PopupDialog';
//import CulturesList from '../../../../../admin-console/administrator-console/dictionaries/locales-editor/cultures-list/CulturesList';
import WikiSpinner from '../../../../../app-layout/spinner/wikiSpinner';
import CombineStyles from '../../../../../../utils/combineStyles';
import { ScreenType, useAppScreenContext } from '../../../../../../context/app-screen-context/AppScreenProvider';
import GetName from '../../../../../common/functions/GetName';
import LocaleSelector from '../../../../../admin-console/administrator-console/dictionaries/locales-editor/locale-selector/LocaleSelector';
import { IUiDictionaryItem } from '../../../../../common/dictionaries/IUiDictionaryItem';

interface IProps {
  ui: IUiInputListLanguages;
  data?: string[];
  onUseBrowserSettings: () => void;
  onUpdate: (updatedLocalesList?: string[]) => void;
}

export default function LanguagesList(props: IProps) {
  // const { instance } = useMsal();
  // const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  //const [cultures, setCultures] = useState<ICulture[]>();
  const [culturesPopupOpen, setCulturesPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const abortController = useAbortController("UserPreferences.LanguagesList");
  const currentPosition = useRef<number>();
  //--------------------------------------------------------------------------- Abortcontroller
  useEffect(() => {
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //--------------------------------------------------------------------------- Get cultures
  /* useEffect(() => {
    // Get cultures to display language native names
    // and to display list of languages for selecting
    retrieveCultures();
  }, [appUiContext.locale]); */
  //---------------------------------------------------------------------------
  const onAdd = (currentIndex: number) => {
    setCulturesPopupOpen(true);
    currentPosition.current = currentIndex;
  };
  //---------------------------------------------------------------------------
  /* const retrieveCultures = () => {
    if (appUiContext.dictionaries?.locales) {
      setCultures(TCulture.getCultureList(appUiContext.dictionaries.locales, appUiContext));
      return;
    }
    console.log("LanguagesList: getting cultures...")
    setIsLoading(true);
    let controller = abortController.newController("retrieveCultures");
    getCultures(
      instance, appAuthContext.config, controller.signal
    ).then(cultures => {
      !controller.aborted && setCultures(cultures);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  }; */
  //---------------------------------------------------------------------------
  /* const onCultureSelected = (selectedCulture: ICulture) => {
    setCulturesPopupOpen(false);
    const updatedArray = props.data ? props.data.map(x => x) : [];
    const position = currentPosition.current == undefined ? updatedArray.length : currentPosition.current + 1;
    updatedArray.splice(position, 0, selectedCulture.id);
    props.onUpdate(updatedArray);
  }; */
  //---------------------------------------------------------------------------
  const onLocaleSelect = (selectedLocale: IUiDictionaryItem) => {
    setCulturesPopupOpen(false);
    const updatedArray = props.data ? props.data.map(x => x) : [];
    const position = currentPosition.current == undefined ? updatedArray.length : currentPosition.current + 1;
    updatedArray.splice(position, 0, selectedLocale.id);
    props.onUpdate(updatedArray);
  };
  //---------------------------------------------------------------------------
  const onDelete = (currentIndex: number) => {
    if (!props.data) return;
    const updatedArray = props.data.map(x => x);
    updatedArray.splice(currentIndex, 1);
    if (updatedArray.length > 0)
      props.onUpdate(updatedArray);
    else
      props.onUpdate(undefined);
  };
  //---------------------------------------------------------------------------
  const onMoveUp = (currentIndex: number) => {
    if (!props.data) return;
    const itemToMove = props.data[currentIndex];
    const updatedArray = props.data.map(x => x);
    updatedArray.splice(currentIndex, 1);
    updatedArray.splice(currentIndex - 1, 0, itemToMove);
    props.onUpdate(updatedArray);
  };
  //---------------------------------------------------------------------------
  const onMoveDown = (currentIndex: number) => {
    if (!props.data) return;
    const itemToMove = props.data[currentIndex];
    const updatedArray = props.data.map(x => x);
    updatedArray.splice(currentIndex, 1);
    updatedArray.splice(currentIndex + 1, 0, itemToMove);
    props.onUpdate(updatedArray);
  };
  //---------------------------------------------------------------------------
  /* const getCultureName = (localeId: string, cultures?: ICulture[]): string | undefined => {
    if (!cultures) return undefined;
    for (let i = 0; i < cultures.length; i++) {
      const culture = cultures[i];
      if (culture.id == localeId) {
        //const name = culture.nativeName;
        const name = culture.nativeName[0].toUpperCase() + culture.nativeName.slice(1);
        return name;
      } else {
        let result = getCultureName(localeId, culture.subCultures);
        if (result)
          return result;
      };
    };
  }; */
  //---------------------------------------------------------------------------
  const listItems = props.data?.map((locale, index) => {
    //const localeName = getCultureName(locale, cultures);
    const dictionaryItem = appUiContext.dictionaries?.locales?.find(di => di.id == locale);
    const dictionaryItemSupported = appUiContext.dictionaries?.supportedLocales?.find(di => di.id == locale);
    return (
      <LanguagesListItem
        key={locale}
        isFirst={index == 0}
        isLast={props.data && index == props.data.length - 1}
        isNotSupported={!dictionaryItemSupported}
        //data={localeName ? localeName : locale}
        data={GetName(appUiContext, locale, dictionaryItem?.name)}
        onAdd={() => onAdd(index)}
        onDelete={() => onDelete(index)}
        onMoveUp={() => onMoveUp(index)}
        onMoveDown={() => onMoveDown(index)}
      />);
  });
  //---------------------------------------------------------------------------
  //const optionAddLanguage = props.ui.menuContent.options.find(o => o.id == "InputListPreferredLanguages.MenuContent.OptionAdd");
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      <div>
        <table>
          <tbody>
            {listItems}
          </tbody>
        </table>
      </div>

      {/* <div
        className={styles.option}
        //onClick={props.onUseBrowserSettings}
      >
        {GetCaption(
          appUiContext,
          "InputListPreferredLanguages.MenuContent.OptionAdd", //OptionUseBrowserSettings
          optionAddLanguage?.caption)}
      </div> */}

      {culturesPopupOpen &&
        <PopupDialog
          id=''
          header={'Select language'}
          isModal={true}
          isDraggable={false}
          fixHeight={true}
          onClose={() => setCulturesPopupOpen(false)}
        >
          <WikiSpinner show={isLoading} />
          <div className={CombineStyles([
            styles.culturesList,
            screenType == ScreenType.Desktop ? styles.desktop : ""
          ])}>
            <LocaleSelector
              selectedLocales={props.data}
              showNativeNames={true}
              onSelect={onLocaleSelect}
              onClose={() => setCulturesPopupOpen(false)}
            />
          </div>
        </PopupDialog>}

      {/* {culturesPopupOpen &&
        <PopupDialog
          id=''
          header={'Select language'}
          isModal={true}
          isDraggable={false}
          onClose={() => setCulturesPopupOpen(false)}
        >
          <WikiSpinner show={isLoading} />
          <div className={CombineStyles([
            styles.culturesList,
            screenType == ScreenType.Desktop ? styles.desktop : ""
          ])}>
            <CulturesList
              data={cultures}
              selectedLocales={props.data}
              showNativeNames={true}
              onSelect={onCultureSelected}
              onClose={() => setCulturesPopupOpen(false)}
            />
          </div>
        </PopupDialog>} */}

    </div>
  );
}