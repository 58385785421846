import { CSSProperties, ReactNode, useRef, useState } from 'react';
import styles from './DictionaryEditorNodeWrapper.module.css';
import React from 'react';
import { IDictionaryDraftItemStateGroup } from '../settings/IDictionaryDraftItemStateGroup';
import DictionaryNodeBadges from './dictionary-node-badges/DictionaryNodeBadges';
import CombineStyles from '../../../../../../utils/combineStyles';
import { TUiMenuContent } from '../../../../../common/menu/menu-content/TUiMenuContent';
import { TUiMenuOption } from '../../../../../common/menu/menu-content/menu-option/TUiMenuOption';
import { DictionaryEditorDraftItem } from '../models/DictionaryEditorDraftItem';
import DictionaryNodeToggler from './dictionary-node-toggler/DictionaryNodeToggler';
import DictionaryNodeMenu from './dictionary-node-menu/DictionaryNodeMenu';
import DictionaryNodeItemWrapper from './dictionary-node-item-wrapper/DictionaryNodeItemWrapper';
import DictionaryNodeStatus from './dictionary-node-status/DictionaryNodeStatus';
import { IDictionaryItemStatus } from '../../../../../common/dictionaries/IUiDictionaryItem';

export interface IUiDictionaryEditorNodeWrapper {
  contextMenu: TUiMenuContent;
}
export const dictionaryNodeWrapperTmpUi: IUiDictionaryEditorNodeWrapper = {
  contextMenu: {
    id: "",
    directoryId: "",
    elementType: "",
    visible: true,
    disabled: false,
    options: [{
      id: "OptionEdit",
      directoryId: "",
      elementType: "",
      index: 0,
      priorityLevel: 0,
      iconFile: "pencil",
      caption: { en: "Edit" },
      hint: null,
      isDefault: false,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }, {
      id: "OptionAdd",
      directoryId: "",
      elementType: "",
      index: 0,
      priorityLevel: 0,
      iconFile: "plus",
      caption: { en: "Add Child Item" },
      hint: null,
      isDefault: false,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }, {
      id: "OptionDelete",
      directoryId: "",
      elementType: "",
      index: 0,
      priorityLevel: 0,
      iconFile: "delete",
      caption: { en: "Delete" },
      hint: null,
      isDefault: false,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }, {
      id: "OptionDeactivate",
      directoryId: "",
      elementType: "",
      index: 0,
      priorityLevel: 0,
      iconFile: "eyeClosed",
      caption: { en: "Deactivate" },
      hint: null,
      isDefault: false,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }, {
      id: "OptionActivate",
      directoryId: "",
      elementType: "",
      index: 0,
      priorityLevel: 0,
      iconFile: "eyeOpened",
      caption: { en: "Activate" },
      hint: null,
      isDefault: false,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }],
    optionGroups: []
  }
}

interface IDraggedOver {
  isDraggedOver: boolean;
  part?: "top" | "bottom";
}

interface IProps {
  stateGroups?: IDictionaryDraftItemStateGroup[];
  data: DictionaryEditorDraftItem;
  status?: IDictionaryItemStatus;
  showStatus?: boolean; // Temporary solution to show status in the editor node until it's applied to all dictionaries
  isHierarchical?: boolean;
  isCollapsed?: boolean;
  isOrderedManually?: boolean;
  children?: ReactNode; // Additional data to display (besides group toggler, id, name, state and options)
  editModeIsEnabled?: boolean;
  indent?: number;
  onToggle?: () => void;
  onEdit: () => void;
  onAddChild?: () => void;
  onDelete: () => void;
  onDeactivate?: () => void;
  onActivate?: () => void;
}

export default function DictionaryEditorNodeWrapper(props: IProps) {
  const [draggedOver, setDraggedOver] = useState<IDraggedOver>({ isDraggedOver: false });
  const containerDiv = useRef<HTMLTableRowElement>(null);
  //---------------------------------------------------------------------------
  const isHierarchical = props.isHierarchical == undefined ? false : props.isHierarchical;
  const editModeIsEnabled = props.editModeIsEnabled == undefined ? false : props.editModeIsEnabled;
  const isOrderedManually = props.isOrderedManually == undefined ? false : props.isOrderedManually;
  const indent = props.indent ? props.indent : 0;
  //---------------------------------------------------------------------------
  const onEdit = () => {
    if (editModeIsEnabled) {
      props.onEdit();
    };
  };
  //---------------------------------------------------------------------------
  const onContextMenuOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OptionEdit":
        props.onEdit();
        break;
      case "OptionAdd":
        if (isHierarchical && props.onAddChild) {
          props.onAddChild();
        };
        break;
      case "OptionDelete":
        props.onDelete();
        break;
      case "OptionDeactivate":
        props.onDeactivate && props.onDeactivate();
        break;
      case "OptionActivate":
        props.onActivate && props.onActivate();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    if (containerDiv.current) {
      e.preventDefault();
      const headerDivRect = containerDiv.current.getBoundingClientRect();
      if (e.nativeEvent.offsetY < headerDivRect.height / 2) {
        // Top part of the box
        setDraggedOver({ isDraggedOver: true, part: "top" });
      } else {
        // Bottom part of the box
        setDraggedOver({ isDraggedOver: true, part: "bottom" });
      };
    };
  }
  //---------------------------------------------------------------------------
  const onDragLeave = () => {
    setDraggedOver({ isDraggedOver: false });
  }
  //---------------------------------------------------------------------------
  const updatedContextMenuOptions: TUiMenuOption[] = [];
  dictionaryNodeWrapperTmpUi.contextMenu.options.forEach(option => {
    switch (option.id) {
      case "OptionEdit":
        updatedContextMenuOptions.push(option);
        break;
      case "OptionAdd":
        if (props.onAddChild) {
          updatedContextMenuOptions.push(option);
        };
        break;
      case "OptionDelete":
        if (!props.data.publishedVersion) {
          updatedContextMenuOptions.push(option);
        };
        break;
      case "OptionDeactivate":
        if (props.onDeactivate && props.data.publishedVersion && !props.data.isDeleted) {
          updatedContextMenuOptions.push(option);
        };
        break;
      case "OptionActivate":
        if (props.onActivate && props.data.publishedVersion && props.data.isDeleted) {
          updatedContextMenuOptions.push(option);
        };
        break;
    };
  });
  //--------------------------------------------------------------------------- Determine styles
  const calculatedStyles: CSSProperties = { color: 'inherit' };
  props.stateGroups?.forEach(stateGroup => {
    stateGroup.states?.forEach(state => {
      if (state.draftState === (props.data.draftState & state.draftState)) {
        calculatedStyles.color = state.fontColor ? state.fontColor : stateGroup.color;
        calculatedStyles.fontWeight = state.fontWeight;
        calculatedStyles.textDecoration = state.textDecoration;
      };
    });
  });
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <tr
        ref={containerDiv}
        className={CombineStyles([
          styles.container,
          props.editModeIsEnabled ? styles.editable : ""
        ])}
        style={{
          //color: draggedOver.isDraggedOver ? draggedOver.part == 'top' ? 'purple' : 'yellow' : undefined,
          borderTop: draggedOver.part == "top" ? '3em solid transparent' : 'undefined',
          borderBottom: draggedOver.part == "bottom" ? '3em solid transparent' : 'undefined',
        }}
        draggable={isOrderedManually && editModeIsEnabled}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
      >

        {/* System Id */}
        <td
          className={props.isHierarchical ? styles.nopadding : ""}
          onDoubleClick={onEdit}
        >
          <div
            className={styles.firstColumn}
            style={{ paddingLeft: `${indent * 1.2}em` }}
          >

            {/* Toggler for hierarchical dictionaries */}
            {isHierarchical &&
              <DictionaryNodeToggler
                isCollapsed={(props.isCollapsed == undefined || !props.onToggle) ? true : props.isCollapsed}
                onToggle={props.onToggle}
              />}

            {/* Index for dictionaries with manual order */}
            {isOrderedManually &&
              <span className={styles.system}>
                {props.data.index}
              </span>}

            {/* Item Id */}
            <span
              className={CombineStyles([
                editModeIsEnabled ? styles.clickableName : "",
                props.data.deleted ? styles.deleted : ""
              ])}
              style={calculatedStyles}
              onClick={onEdit}
            >
              {props.data.id}
            </span>

          </div>
        </td>

        {/* Item Name (in English) */}
        <td
          className={props.data.deleted ? styles.deleted : ""}
          style={calculatedStyles}
          onDoubleClick={onEdit}
        >
          <DictionaryNodeItemWrapper>
            <span
              className={editModeIsEnabled ? styles.clickableName : ""}
              onClick={onEdit}
            >
              {props.data.name}
            </span>
          </DictionaryNodeItemWrapper>
        </td>

        {/* Item additional data provided by particular dictionary */}
        <td onDoubleClick={onEdit}>
          <DictionaryNodeItemWrapper>
            {props.children}
          </DictionaryNodeItemWrapper>
        </td>

        {props.showStatus &&
          <DictionaryNodeStatus data={props.status} />}

        {/* Badges to indicate node draft state */}
        <DictionaryNodeBadges
          stateGroups={props.stateGroups}
          draftState={props.data.draftState}
        />

        {/* Context menu options */}
        {editModeIsEnabled &&
          <DictionaryNodeMenu
            ui={{ ...dictionaryNodeWrapperTmpUi.contextMenu, options: updatedContextMenuOptions }}
            data={props.data}
            onSelect={onContextMenuOptionSelect}
          />}
      </tr>
    </React.Fragment>
  );
}