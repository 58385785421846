import { useEffect, useState } from 'react';
import { ScreenType, useAppScreenContext } from '../../../../context/app-screen-context/AppScreenProvider';
import { ButtonType } from '../../button/ButtonType';
import { TUiMenuOption } from '../../menu/menu-content/menu-option/TUiMenuOption';
import React from 'react';
import FormOptionToolbarDesktop from './form-option-toolbar-desktop/FormOptionToolbarDesktop';
import FormOptionToolbarMobile from './form-option-toolbar-mobile/FormOptionToolbarMobile';
import FormOptionPopup from './form-option-popup/FormOptionPopup';
import { TUiMenuContent } from '../../menu/menu-content/TUiMenuContent';
import PopupMenu from '../../popup-v2/popup-menu/PopupMenu';

interface IProps {
  ui: TUiMenuOption;
  type: ButtonType;
  applyMobileStyle?: boolean;
  onSelect: (optionId: string) => void;
}

export default function FormOptionV2(props: IProps) {
  const screenType = useAppScreenContext();
  const [subMenu, setSubMenu] = useState<TUiMenuContent>();
  const [popupOpen, setPopupOpen] = useState(false);
  const applyMobileStyle = props.applyMobileStyle == undefined ? true : props.applyMobileStyle;
  //--------------------------------------------------------------------------- Set up option mode
  useEffect(() => {
    if (props.ui.action) {
      setSubMenu(props.ui.action);
    };
  }, [props.ui.action]);
  //--------------------------------------------------------------------------- Handle option selection
  const onSelect = (optionId: string) => {
    if (!props.ui.disabled) {
      if (subMenu) {
        // If action is provided, it should be menu content, so open the sub-menu
        setPopupOpen(true);
      } else {
        // If there are no submenu, respond with selected option Id
        props.onSelect(optionId);
      };
    };
  };
  //--------------------------------------------------------------------------- Handle sub-menu option selection
  const onSubmenuOptionSelect = (subOptionId: string) => {
    console.log('onSuboptionSelect', subOptionId, `${props.ui.id}/${subOptionId}`)
    setPopupOpen(false);
    // Check if prefix is a dots button id: if so, we need to remove it
    if (props.ui.id == "DotsButton")
      props.onSelect(subOptionId);
    else
      props.onSelect(`${props.ui.id}/${subOptionId}`);
  };
  //--------------------------------------------------------------------------- Render
  var option: JSX.Element;
  if (props.type == ButtonType.Popup) {
    option =
      <FormOptionPopup
        ui={props.ui}
        isDropdown={subMenu != undefined}
        onSelect={onSelect}
      />;
  } else if (screenType === ScreenType.Mobile && applyMobileStyle) {
    option =
      <FormOptionToolbarMobile
        ui={props.ui}
        onSelect={onSelect}
      />;
  } else {
    option =
      <FormOptionToolbarDesktop
        ui={props.ui}
        isDropdown={subMenu != undefined}
        onSelect={onSelect}
      />;
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      {option}
      {popupOpen && subMenu &&
        <PopupMenu
          ui={subMenu}
          anchorId={props.ui.id}
          displayIcon={true}
          onClose={() => setPopupOpen(false)}
          onSelect={onSubmenuOptionSelect}
        />}
    </React.Fragment>
  );
}