export default function maximise(popupRef?: React.RefObject<HTMLDivElement>) {
  //console.log('maximize!')
  const popup = popupRef?.current;
  if (popup) {
    //-----------------------------------------------------------------------
    // Initial styles are already applied
    const initialRect = popup.getBoundingClientRect();
    console.log("maximize.initialRect", initialRect);
    //-----------------------------------------------------------------------
    // Apply final styles
    popup.style.width = '100%';
    popup.style.height = '100%';
    popup.style.maxWidth = '100%';
    popup.style.maxHeight = '100%';
    const finalRect = popup.getBoundingClientRect();
    console.log("maximize.finalRect", finalRect);
    //-----------------------------------------------------------------------
    // Calc the delta
    var invertWidth = initialRect.width / finalRect.width;
    var inverHeight = initialRect.height / finalRect.height;
    //---------------------------------------------------------------------
    // Invert
    popup.style.transition = '';
    const popupTransform = popup.style.transform;
    popup.style.transform = `${popupTransform} scaleX(${invertWidth}) scaleY(${inverHeight})`;
    console.log("popup.style.transform.old:", popupTransform);
    console.log("popup.style.transform.set:", popup.style.transform);
    //---------------------------------------------------------------------
    // Play
    const tt = '1000ms ease-out';
    //-----------------------------------------------------------------------
    requestAnimationFrame(() => {
      popup.style.transition = `transform ${tt}, opacity ${tt}`;
      popup.style.transform = popupTransform ? popupTransform : '';
      popup.style.opacity = '1';
    });
  };
}