import { useState } from 'react';
import { ContentItemTranslationDraft } from '../content-directory-translator/IContentItemTranslationDraft';
import DirectoryNodeWrapper from '../../../common/directory-node-wrapper/DirectoryNodeWrapper';
import DirectoryNodeAttribute from '../../../common/directory-node-attribute/DirectoryNodeAttribute';
import ContentItemEditform from '../content-item-editform/ContentItemEditform';
import { tmpUiContentItemEditform } from '../content-item-editform/IUiContentItemEditform';

interface IProps {
  //stateGroups: IDirectoryDraftItemStateGroup[];
  data: ContentItemTranslationDraft;
  draftLocale: string;
  sourceLocale?: string;
  editModeIsEnabled: boolean;
  onUpdate: (updatedContentItem: ContentItemTranslationDraft) => void;
}

export default function ContentDirectoryNode(props: IProps) {
  const [editMode, setEditMode] = useState(false);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    setEditMode(true);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedContentItem: ContentItemTranslationDraft) => {
    console.log(updatedContentItem)
    setEditMode(false);
    props.onUpdate(updatedContentItem);
  };
  //---------------------------------------------------------------------------
  if (editMode) return (
    <ContentItemEditform
      ui={tmpUiContentItemEditform}
      data={props.data}
      draftLocale={props.draftLocale}
      sourceLocale={props.sourceLocale}
      editModeIsEnabled={props.editModeIsEnabled}
      onUpdate={onUpdate}
      onClose={() => setEditMode(false)}
    />
  );
  else return (
    <DirectoryNodeWrapper
      //stateGroups={props.stateGroups}
      data={props.data}
    >
      <DirectoryNodeAttribute onClick={onSelect}>
        {props.data.id}
      </DirectoryNodeAttribute>
    </DirectoryNodeWrapper>
  );
}