import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { ScreenType, useAppScreenContext } from "../../../context/app-screen-context/AppScreenProvider";
import CombineStyles from "../../../utils/combineStyles";
import { ButtonType } from "../../common/button/ButtonType";
import EditFormOptionButton from "../../common/edit-form-options/edit-form-option-button/EditFormOptionButton";
import { LocationData } from "../../common/map/MapWrapper";
import { TUiMenuOption } from "../../common/menu/menu-content/menu-option/TUiMenuOption";
import styles from "./BusinessSearchEditor.module.css";
import SectionMainCategory from "./section-main-category/SectionMainCategory";
import SectionMap from "./section-map/SectionMap";
import { TGeoPoint, TLocationData } from "../../common/map/geo-json/GeoJson";
import { ELogLevel, LogInformation } from "../../../utils/logging";
import { BusinessSearchResult } from "../business-search-list/BusinessSearchResult";
import { IBusinessMarker } from "../../common/google-maps/business-markers/BusinessMarkers";
import { BusinessSearchParams, paramSearchMode } from "./IBusinessSearchParams";
import { getFromLS, setToLS } from "../../../utils/storage";
import { AppAuthContextStore } from "../../../context/app-auth-context/AppAuthContext";
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";
import { IUiSearchConsole } from "../IUiSearchConsole";

const logLevel: ELogLevel = ELogLevel.None;

const optionApply: TUiMenuOption = {
  id: "StartSearch",
  directoryId: "",
  elementType: "",
  index: 1,
  priorityLevel: 0,
  iconFile: "search",
  caption: { en: "Search" },
  hint: undefined,
  visible: true,
  disabled: false,
  canHideCaption: false,
  isDefault: true,
  action: undefined
}

interface IProps {
  ui: IUiSearchConsole;
  businessSearchResult?: BusinessSearchResult;
  businessMarkers?: IBusinessMarker[];
  highlightedBusinessId?: string;
  userPosition?: TGeoPoint;
  onSearchStart: (updatedBusinessSearchParams?: BusinessSearchParams) => void;
}

export default function BusinessSearchEditor(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { appAuthContext } = useContext(AppAuthContextStore);
  const screenType = useAppScreenContext();
  const [businessSearchParams, setBusinessSearchParams] = useState<BusinessSearchParams>();
  const zoom = useRef<number>();
  const location = useLocation();
  const businessTagsDictionary = appUiContext.dictionaries?.businessTags;
  const rootTags = businessTagsDictionary?.filter(item => !item.path);
  //---------------------------------------------------------------------------
  useEffect(() => {
    // Get search parameters from URL
    let searchParams: URLSearchParams | undefined = undefined;
    if (location.search)
      searchParams = new URLSearchParams(location.search);
    else
      searchParams = getSearchParamsFromLS();
    const businessSearchParams = BusinessSearchParams.getSearchParamsFromUrl(searchParams);
    setBusinessSearchParams(businessSearchParams);
    props.onSearchStart(businessSearchParams);
    //-------------------------------------------------------------------------
    // Check if 'auto' parameter is included
    if (businessSearchParams.searchModeAuto) 
    {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete(paramSearchMode);
      window.history.replaceState(null, '', newUrl);
      props.onSearchStart(businessSearchParams);
    };
  }, [location.search]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    LogInformation(logLevel, "BusinessSearchEditor.useEffect[businessSearchParams]:", businessSearchParams)
  }, [businessSearchParams]);
  //---------------------------------------------------------------------------
  const getSearchParamsFromLS = (): URLSearchParams | undefined => {
    let searchParams = getFromLS(getSearchParamsLsKey());
    LogInformation(logLevel, "SearchConsole searchParams from LS:", searchParams);
    if (!searchParams)
      return undefined;
    let result = new URLSearchParams(searchParams as string);
    LogInformation(logLevel, "SearchConsole URLSearchParams from LS:", result);
    return result;
  }
  //---------------------------------------------------------------------------
  const getSearchParamsLsKey = (): string => {
    let userId = appAuthContext.user?.userIdentity.id ?? "anonymous";
    return `usr.${userId}.searchParams`;
  }
  //---------------------------------------------------------------------------
  const onApply = () => {
    let searchParams = new BusinessSearchParams(businessSearchParams);
    if (searchParams.useLocationArea && zoom.current === 0) {
      searchParams.useLocationArea = false;
    }
    console.warn("BusinessSearchEditor.onApply.businessSearchParams:", searchParams);
    props.onSearchStart(searchParams);
    refreshUrl(searchParams);
  };
  //---------------------------------------------------------------------------
  const generateUpdatedUrl = () => {
    //const categorylist = JSON.stringify(businessSearchParams.tagTree);
    //To use for Encode purpose and chage in URLSearchparam for cat 
    //var encodedbase64cat = window.btoa(unescape(encodeURIComponent(categorylist)));  
    //LogInformation(logLevel, encodedbase64cat)
    // let url = new URLSearchParams({ cat: categorylist || "", location: businessSearchParams.locationName || "", mode: "auto" }).toString();
    // LogInformation(logLevel, url);
    // if (history) {
    //   var newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${url}&bounds=${JSON.stringify(businessSearchParams.locationBoundsToDisplay)}`;
    //   if (businessSearchParams.locationBoundsToDisplay) {
    //     window.history.pushState({ path: newurl }, '', newurl);
    //   }
    // }
  }
  //---------------------------------------------------------------------------
  const onMapStateUpdate = (switchedOn: boolean) => {
    let updatedSearchParams = new BusinessSearchParams(
      businessSearchParams,
      { useLocationArea: switchedOn }
    );
    setBusinessSearchParams(updatedSearchParams);
  }
  //---------------------------------------------------------------------------
  const onMapLocationUpdate = (locationData: LocationData) => {
    LogInformation(logLevel, "location area updated:", locationData);
    zoom.current = locationData.zoom;
    let updatedSearchParams = new BusinessSearchParams(
      businessSearchParams,
      {
        //locationArea: locationData.mapArea,
        locationBoundsToDisplay: locationData.mapBounds,
      });
    setBusinessSearchParams(updatedSearchParams);
  };
  //---------------------------------------------------------------------------
  const onLocationUpdateV2 = (locationData: TLocationData) => {
    LogInformation(logLevel, "location area updated:", locationData);
    zoom.current = locationData.zoom;
    let updatedSearchParams = new BusinessSearchParams(
      businessSearchParams, {
      locationName: locationData.address,
      locationBoundsToDisplay: locationData.mapBoundsLiteral,
      //locationArea: locationData.mapBoundsLiteral ? TPolygonArea.createGoogleFrameLiteral(locationData.mapBoundsLiteral) : undefined,
      //tagTree: businessSearchParamsRef.current.tagTree,
    });
    setBusinessSearchParams(updatedSearchParams);
  };
  //---------------------------------------------------------------------------
  const onSelectedTagsUpdate = (selectedTags?: string[]) => {
    const updatedSearchParams = new BusinessSearchParams({
      ...businessSearchParams,
      tags: selectedTags
    });
    setBusinessSearchParams(updatedSearchParams);
  };
  //--------------------------------------------- Update URL based on search parameters
  const refreshUrl = (searchParams?: BusinessSearchParams) => {
    let newUrl = searchParams
      ? searchParams.getUrl(window.location.href)
      : BusinessSearchParams.getCleanUrl(window.location.href);
    window.history.replaceState(null, '', newUrl);
    setToLS(getSearchParamsLsKey(), newUrl.searchParams.toString());
  }
  //--------------------------------------------------------------------------- Render main categories as sections
  let rootTagElements: ReactNode[] = [];
  rootTags?.forEach(rootTag => {
    rootTagElements.push(
      <SectionMainCategory
        key={rootTag.id}
        ui={props.ui.businessTagsSelector}
        rootTag={rootTag}
        selectedTags={businessSearchParams?.tags ? businessSearchParams.tags : []}
        onUpdate={onSelectedTagsUpdate}
      />
    )
  });
  //---------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    screenType == ScreenType.Mobile ? styles.mobile : ""
  ]);
  //---------------------------------------------------------------------------
  const optionsStyles = CombineStyles([
    styles.options,
    screenType == ScreenType.Mobile ? styles.mobile : ""
  ]);
  //---------------------------------------------------------------------------
  //console.log("BusinessSearchEditor.render.props:", props);
  //---------------------------------------------------------------------------
  return (
    <div className={containerStyles}>
      <div className={styles.sections}>

        {businessSearchParams &&
          <SectionMap
            locationNameToDisplay={businessSearchParams?.locationName}
            locationAreaToDisplay={businessSearchParams?.locationBoundsToDisplay}
            businessSearchResult={props.businessSearchResult}
            businessMarkers={props.businessMarkers}
            userPosition={props.userPosition}
            switchedOn={businessSearchParams ? businessSearchParams.useLocationArea === true : false}
            onStateUpdate={onMapStateUpdate}
            onLocationUpdate={onMapLocationUpdate}
            onLocationUpdateV2={onLocationUpdateV2}
            highlightedBusinessId={props.highlightedBusinessId}
          />}

        {rootTagElements}

        <div className={styles.optionsPlaceholder} />
      </div>

      <div className={optionsStyles}>
        <EditFormOptionButton
          type={ButtonType.Toolbar}
          ui={optionApply}
          visible={true}
          customColor={'var(--clr_accent_main)'}
          clickHandler={onApply}
        />
      </div>

    </div>
  );
}