import React from 'react';
import { IUiListItem } from '../../../common/input/input-dropdown/InputDropdown';
import SelectedSubTagCard from './selected-subtag-card/SelectedSubTagCard';
//import styles from './SubTag.module.css'; // Use it if children styles should differ from parent

const getDictionaryItem = (dictionaryItem: IUiListItem, path: string) => {
  if (dictionaryItem.path == path) return dictionaryItem;
  //console.log(dictionaryItem, path)
  //---------------------------------------------------------------------------
  const pathItems = path.split('.');
  if (dictionaryItem.id != pathItems[0]) return undefined;
  //---------------------------------------------------------------------------
  let dictionaryItemsToSearch: IUiListItem[] | undefined = dictionaryItem.items;
  for (let i = 1; i < pathItems.length; i++) {
    const dictionaryItem = dictionaryItemsToSearch?.find(item => item.id == pathItems[i]);
    //console.log(pathItems[i], dictionaryItemsToSearch, dictionaryItem)
    if (!dictionaryItem) return undefined;
    //-------------------------------------------------------------------------
    if (i < pathItems.length - 1) {
      dictionaryItemsToSearch = dictionaryItem.items;
    } else {
      return dictionaryItem;
    };
  }
};

interface ISelectedItem {
  dictionaryItem: IUiListItem;
  isSelectableGroup: boolean;
  isDoubleChecked: boolean;
}

interface IProps {
  ui: IUiListItem;
  selectedTags?: string[];
  path: string;
  onUpdate?: (selectedTags?: string[]) => void;
}

export default function SelectedSubTags(props: IProps) {
  //---------------------------------------------------------------------------
  const onDelete = (tagPath: string) => {
    if (!tagPath) return;
    // Delete selected tag and all its children if it's a selectable group
    const updatedSelectedTags = props.selectedTags?.filter(x => !x.startsWith(tagPath));
    console.log(tagPath, updatedSelectedTags)
    props.onUpdate && props.onUpdate(updatedSelectedTags);
  };
  //---------------------------------------------------------------------------
  let tagsToDisplay: ISelectedItem[] = [];
  // Display subtabs, but not the root tag itself
  props.selectedTags?.filter(x => x.startsWith(props.ui.id) && x !== props.ui.id).forEach(tagPath => {
    const dictionaryItem = getDictionaryItem(props.ui, tagPath);
    if (!dictionaryItem) return;
    tagsToDisplay.push({
      dictionaryItem: dictionaryItem,
      isSelectableGroup: !dictionaryItem.group && !!dictionaryItem.items && dictionaryItem.items.length > 0,
      isDoubleChecked: false
    });
  });
  //---------------------------------------------------------------------------
  // Exclude selectable group sub-items, if there are any
  const selectableGroups = tagsToDisplay.filter(item => item.isSelectableGroup);
  selectableGroups.forEach(group => {
    // Remove selected sub-items if they are all selected
    const subItems = tagsToDisplay.filter(item =>
      item.dictionaryItem.path &&
      group.dictionaryItem.path &&
      item.dictionaryItem.path.startsWith(group.dictionaryItem.path) &&
      item.dictionaryItem.path != group.dictionaryItem.path
    );
    if (subItems && group.dictionaryItem.items && subItems.length == group.dictionaryItem.items.length) {
      group.isDoubleChecked = true;
      tagsToDisplay = tagsToDisplay.filter(item => !subItems.includes(item));
    };
  });
  //---------------------------------------------------------------------------
  const childrenElements = tagsToDisplay.map(item =>
    <SelectedSubTagCard
      key={item.dictionaryItem.path}
      tag={item.dictionaryItem}
      isDoubleChecked={item.isDoubleChecked}
      onDelete={props.onUpdate ? onDelete : undefined}
    />
  );
  //---------------------------------------------------------------------------
  // For now we render all children as flat list
  return (
    <React.Fragment>
      {childrenElements}
    </React.Fragment>
  );
}