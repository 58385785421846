import React, { useContext, useEffect, useRef, useState } from 'react';
import DictionaryItemEditform from '../../../dictionaries/common-dictionary/common-dictionary-item-editform/DictionaryItemEditform';
import { IUiEditformBusinessTagsDictionaryItem } from './IUiEditformBusinessTagsDictionaryItem';
import { DirectoryEditorContext } from '../../../../../../context/directory-editor-context/DirectoryEditorContextProvider';
import { EditFormState } from '../../../../../common/edit-form/IEditFormState';
import { BusinessTagsDictionaryDraftItem, IBusinessTagsDictionaryDraftItemUpdate, IDictionaryItemContent } from '../IBusinessTagsDictionaryDraftItem';
import { BusinessTagsDictionaryItemValidator, IBusinessTagsDictionaryItemValidationState } from './BusinessTagsDictionaryItemValidator';
import InputDropdown from '../../../../../common/input-v2/input-dropdown/InputDropdown';
import { IUiDictionaryItem } from '../../../../../common/dictionaries/IUiDictionaryItem';
import InputCheckBox from '../../../../../common/input-v2/input-checkbox/InputCheckBox';
import { IDictionaryItemStatus } from '../../../dictionaries/common-dictionary/IDictionaryDraftItem';
import { EDraftItemState } from '../../../../../../context/directory-editor-context/EDraftItemState';

interface IProps {
  ui: IUiEditformBusinessTagsDictionaryItem;
  data?: BusinessTagsDictionaryDraftItem;
  isNew?: boolean;
  isReadonly?: boolean;
  onUpdate: (updatedItem: BusinessTagsDictionaryDraftItem, oldId?: string) => void;
  onClose: () => void;
}

export default function BusinessTagsDictionaryItemEditForm(props: IProps) {
  const { directoryEditorContext } = useContext(DirectoryEditorContext);
  const [editorState, setEditorState] = useState<EditFormState<BusinessTagsDictionaryDraftItem>>();
  const [validationState, setValidationState] = useState<IBusinessTagsDictionaryItemValidationState>();
  const formValidator = useRef<BusinessTagsDictionaryItemValidator>();
  const dictionaryItem = editorState?.object;
  const dictionaryItemInitial = editorState?.initialState;
  const isReadonly = props.isReadonly == undefined ? false : props.isReadonly;
  //--------------------------------------------------------------------------- Data, Validation
  useEffect(() => {
    formValidator.current = new BusinessTagsDictionaryItemValidator(props.ui);
    //-------------------------------------------------------------------------
    const validationState = formValidator.current.initialize(props.data);
    setValidationState(validationState);
    //-------------------------------------------------------------------------
    const state = new EditFormState<BusinessTagsDictionaryDraftItem>({
      object: props.data,
      isNew: props.isNew ? props.isNew : false,
      isValid: false
    });
    setEditorState(state);
  }, []);
  //---------------------------------------------------------------------------
  const onIdUpdate = (value: string) => {
    if (isReadonly) return;
    if (!editorState?.object) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    //-------------------------------------------------------------------------
    // Update name as well if it'a a new item
    const updates: IBusinessTagsDictionaryDraftItemUpdate = {
      id: value,
      name: editorState.isNew ? value : editorState.object.name
    };
    const updatedItem = editorState.object.getUpdated(updates);
    //-------------------------------------------------------------------------
    // Validate both values
    let updatedValidationState = formValidator.current.validateId(
      updatedItem.guid,
      value, // Check if id is unique
      //updatedItem.fullPath, // Check if path is unique
      validationState,
      directoryEditorContext.editorState.draft.items
    );
    if (updates.name) {
      updatedValidationState = formValidator.current.validateName(
        updatedItem.guid,
        updates.name,
        updatedValidationState,
        directoryEditorContext.editorState.draft.items
      );
    };
    setValidationState(updatedValidationState);
    //-------------------------------------------------------------------------
    const updatedState = new EditFormState<BusinessTagsDictionaryDraftItem>(editorState, {
      object: updatedItem as BusinessTagsDictionaryDraftItem,
      isValid: updatedValidationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onNameUpdate = (value: string) => {
    if (isReadonly) return;
    if (!editorState?.object) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    //-------------------------------------------------------------------------
    const updatedItem = editorState.object.getUpdated({ name: value });
    const itemsToValidateName = directoryEditorContext.editorState.draft.items.filter(item => item.path === updatedItem.path);
    const updatedValidationState = formValidator.current.validateName(
      updatedItem.guid,
      value,
      validationState,
      itemsToValidateName
    );
    setValidationState(updatedValidationState);
    //-------------------------------------------------------------------------
    const updatedState = new EditFormState<BusinessTagsDictionaryDraftItem>(editorState, {
      object: updatedItem as BusinessTagsDictionaryDraftItem,
      isValid: updatedValidationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onDescriptionUpdate = (value: string) => {
    if (isReadonly) return;
    if (!editorState?.object) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    //-------------------------------------------------------------------------
    const updatedItem = editorState.object.getUpdated({ description: value });
    const updatedValidationState = formValidator.current.validateDescription(
      updatedItem.guid,
      value,
      validationState,
      directoryEditorContext.editorState.draft.items
    );
    setValidationState(updatedValidationState);
    //-------------------------------------------------------------------------
    const updatedState = new EditFormState<BusinessTagsDictionaryDraftItem>(editorState, {
      object: updatedItem as BusinessTagsDictionaryDraftItem,
      isValid: true //updatedValidationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onDictionarySelect = (value?: string) => {
    if (isReadonly) return;
    if (!editorState?.object) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    //-------------------------------------------------------------------------
    const subDictionary = directoryEditorContext.dictionaryOriginals?.find(d => d.dictionaryId === value);
    if (value && !subDictionary) {
      console.error(`Dictionary original id=[${value}] is not provided by directory editor context`);
      return;
    };
    //-------------------------------------------------------------------------
    const updatedItem = editorState.object.getUpdated({
      content: {
        dictionaryId: value,
        isRequired: false,
        isMutuallyExclusive: false,
        isSortedManually: subDictionary ? subDictionary.isSortedManually : false
      }
    });
    //-------------------------------------------------------------------------
    const updatedState = new EditFormState<BusinessTagsDictionaryDraftItem>(editorState, {
      object: updatedItem as BusinessTagsDictionaryDraftItem,
      isValid: true //updatedValidationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onIsRequiredUpdate = (value: boolean) => {
    if (isReadonly) return;
    if (!editorState?.object) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    //-------------------------------------------------------------------------
    const updatedItem = editorState.object.getUpdated({
      content: {
        ...editorState.object.content as IDictionaryItemContent,
        isRequired: value
      }
    });
    //-------------------------------------------------------------------------
    const updatedState = new EditFormState<BusinessTagsDictionaryDraftItem>(editorState, {
      object: updatedItem as BusinessTagsDictionaryDraftItem,
      isValid: true //updatedValidationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onIsExlclusiveUpdate = (value: boolean) => {
    if (isReadonly) return;
    if (!editorState?.object) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    //-------------------------------------------------------------------------
    const updatedItem = editorState.object.getUpdated({
      content: {
        ...editorState.object.content as IDictionaryItemContent,
        isMutuallyExclusive: value
      }
    });
    //-------------------------------------------------------------------------
    const updatedState = new EditFormState<BusinessTagsDictionaryDraftItem>(editorState, {
      object: updatedItem as BusinessTagsDictionaryDraftItem,
      isValid: true //updatedValidationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onIsSortedManuallyUpdate = (value: boolean) => {
    if (isReadonly) return;
    if (!editorState?.object) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    //-------------------------------------------------------------------------
    const updatedItem = editorState.object.getUpdated({
      content: {
        ...editorState.object.content as IDictionaryItemContent,
        isSortedManually: value
      }
    });
    //-------------------------------------------------------------------------
    const updatedState = new EditFormState<BusinessTagsDictionaryDraftItem>(editorState, {
      object: updatedItem as BusinessTagsDictionaryDraftItem,
      isValid: true //updatedValidationState.isValid
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onStatusUpdate = (updatedStatus: IDictionaryItemStatus) => {
    if (isReadonly) return;
    if (!editorState?.object) return;
    if (!formValidator.current) return;
    if (!validationState) return;
    //-------------------------------------------------------------------------
    const updatedItem = editorState.object.getUpdated({
      status: updatedStatus
    });
    //-------------------------------------------------------------------------
    const updatedState = new EditFormState<BusinessTagsDictionaryDraftItem>(editorState, {
      object: updatedItem as BusinessTagsDictionaryDraftItem,
      isValid: true
    });
    setEditorState(updatedState);
  };
  //---------------------------------------------------------------------------
  const onConfirm = () => {
    if (isReadonly) return;
    if (!dictionaryItem) return;
    if (!validationState?.isValid) return;
    //-------------------------------------------------------------------------
    dictionaryItem.draftState = EDraftItemState.None;
    if (dictionaryItem.original) {
      if (dictionaryItem.name != dictionaryItem.original?.name) {
        dictionaryItem.isTranslationEdited = true;
      };
      if (dictionaryItem.description != dictionaryItem.original?.description) {
        dictionaryItem.isTranslationEdited = true;
      };
      //---------------------------------------------------------------------
      if (JSON.stringify(dictionaryItem.content) !== JSON.stringify(dictionaryItem.original.content)) {
        dictionaryItem.isEdited = true;
      };
      if (JSON.stringify(dictionaryItem.status) !== JSON.stringify(dictionaryItem.original.status)) {
        dictionaryItem.isEdited = true;
      };
    } else {
      dictionaryItem.isNew = true;
    };
    //-------------------------------------------------------------------------
    props.onUpdate(dictionaryItem);
  };
  //---------------------------------------------------------------------------
  const dictionaryOptions: IUiDictionaryItem[] = [];
  directoryEditorContext.dictionariesList?.forEach(item => {
    const dictionaryItem: IUiDictionaryItem = {
      id: item.dictionaryId,
      name: { en: item.name },
      description: { en: item.description },
      index: 0,
      searchString: [item.dictionaryId.toLowerCase(), item.name.toLowerCase(), item.description.toLowerCase()], // to make search available
      isDisabled: !item.originalExists
    };
    dictionaryOptions.push(dictionaryItem);
  });
  //---------------------------------------------------------------------------
  return (
    <DictionaryItemEditform
      ui={props.ui}
      data={editorState}
      isReadonly={!directoryEditorContext.editMode || isReadonly}
      validationState={validationState}
      onIdUpdate={onIdUpdate}
      onNameUpdate={onNameUpdate}
      onDescriptionUpdate={onDescriptionUpdate}
      onStatusUpdate={onStatusUpdate}
      onConfirm={onConfirm}
      onClose={props.onClose}
    >
      {editorState?.object?.path && // Unavailable for root items
        <React.Fragment>
          <InputDropdown
            ui={{
              ...props.ui.inputDictionary,
              disabled: !directoryEditorContext.editMode || isReadonly
            }}
            data={editorState?.object?.content?.dictionaryId}
            isAutoComplete={true}
            options={dictionaryOptions}
            onUpdate={onDictionarySelect}
          />

          <InputCheckBox
            ui={{
              ...props.ui.inputIsRequired,
              disabled: !directoryEditorContext.editMode || isReadonly
            }}
            data={editorState?.object?.content?.isRequired}
            onChange={onIsRequiredUpdate}
          />

          <InputCheckBox
            ui={{
              ...props.ui.inputIsMutuallyExclusive,
              disabled: !directoryEditorContext.editMode || isReadonly
            }}
            data={editorState?.object?.content?.isMutuallyExclusive}
            onChange={onIsExlclusiveUpdate}
          />

          <InputCheckBox
            ui={{
              ...props.ui.inputIsSortedManually,
              disabled: true
            }}
            data={editorState?.object?.content?.isSortedManually}
            onChange={onIsSortedManuallyUpdate}
          />

        </React.Fragment>}
    </DictionaryItemEditform>
  );
}