import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApiKey, CheckExpirationDate } from '../apikey-card/ApiKeyCard';
import styles from './ApiKeyEditForm.module.css';
import { TUiInputText } from '../../../../common/input/input-text/TUiInputText';
import InputDatetime, { IUiInputDatetime } from '../../../../common/input/input-datetime/InputDatetime';
import { TUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import InputText from '../../../../common/input/input-text/InputText';
import { TUiMenuOption } from '../../../../common/menu/menu-content/menu-option/TUiMenuOption';
import FormOptions from '../../../../common/form-options-bar/FormOptions';
import useClickOutsideDiv from '../../../../../hooks/useClickOutsideDiv';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import getApiKey from '../getApiKey';
import WikiSpinner from '../../../../app-layout/spinner/wikiSpinner';
import { useAbortController } from '../../../../../hooks/useAbortController';

export interface IUiApiKeyEditForm {
  inputName: TUiInputText;
  inputDescription: TUiInputText;
  inputCreatedOn: IUiInputDatetime;
  inputCreatedBy: TUiInputText;
  inputExpiresOn: IUiInputDatetime;
  inputValue: TUiInputText;
  formOptions: TUiMenuContent;
}

interface IState {
  isNew: boolean;
  isValid: boolean;
  data?: ApiKey;
  showKey: boolean;
}

interface IProps {
  ui: IUiApiKeyEditForm;
  data?: ApiKey;
  onClose: () => void;
  onUpdate: (updatedApiKey: ApiKey, isNew: boolean) => void;
}

export default function ApiKeyEditForm(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const editFormDivRef = useClickOutsideDiv({ handler: props.onClose });
  // If data is not provided, it's a new key
  const [state, setState] = useState<IState>({
    isNew: props.data ? false : true,
    isValid: props.data ? true : false,
    data: props.data,
    showKey: props.data ? false : true
  });
  const [isLoading, setIsLoading] = useState(false);
  const abortController = useAbortController("ApiKeyEditForm");
  //---------------------------------------------------------------------------
  useEffect(() => {
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //--------------------------------------------------------------------------- Make it smooth
  useEffect(() => {
    if (editFormDivRef.current) {
      editFormDivRef.current.scrollIntoView({ behavior: 'smooth' });
    };
  }, [editFormDivRef.current]);
  //---------------------------------------------------------------------------
  const onUpdateName = (updatedValue: string) => {
    setState({
      ...state,
      isValid: (updatedValue.length > 5),
      data: new ApiKey(state.data, { name: updatedValue.replace(' ', '') })
    });
  };
  //---------------------------------------------------------------------------
  const onUpdateDescription = (updatedValue: string) => {
    setState({
      ...state,
      data: new ApiKey(state.data, { description: updatedValue })
    });
  };
  //---------------------------------------------------------------------------
  const onUpdateExpirationDatetime = (updatedValue: Date) => {
    setState({
      ...state,
      data: new ApiKey(state.data, { expiresOn: updatedValue })
    });
  };
  //---------------------------------------------------------------------------
  const onUpdateValue = (updatedValue: string) => {
    setState({
      ...state,
      data: new ApiKey(state.data, { value: updatedValue })
    });
  };
  //---------------------------------------------------------------------------
  const onConfirmEdit = () => {
    if (state.data && state.isValid) {
      props.onUpdate(state.data, state.isNew);
    };
  };
  //---------------------------------------------------------------------------
  const onShowApiKey = () => {
    if (state.data?.value) {
      setState({
        ...state,
        showKey: true
      });
      return;
    }
    // Call API to get the secret value
    setIsLoading(true);
    let controller = abortController.newController("getApiKey");
    getApiKey(
      instance, state.data?.name, appAuthContext.config, controller.signal
    ).then(apiKey => {
      if (!controller.aborted)
        setState({
          ...state,
          data: apiKey,
          showKey: true
        });
    }).catch(error =>
      console.error(error)
    ).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onHideApiKey = () => {
    setState({
      ...state,
      showKey: false
    });
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "CancelButton":
        props.onClose();
        break;
      case "OkButton":
        onConfirmEdit();
        break;
    };
  };
  //--------------------------------------------------------------------------- Render Form options
  const updatedOptions: TUiMenuOption[] = [];
  props.ui.formOptions.options.forEach(option => {
    switch (option.id) {
      case "CancelButton":
        updatedOptions.push(option);
        break;
      case "OkButton":
        updatedOptions.push({ ...option, disabled: !state.isValid });
        break;
    }
  });
  const listOptions = props.ui.formOptions ?
    <FormOptions
      ui={{ ...props.ui.formOptions, options: updatedOptions }}
      optionIdVisibleOnMobile={"Administrator_ApiKeys_OptionAddApiKey"}
      applyMobileStyle={false}
      onSelect={onOptionSelect}
    />
    : undefined;
  //---------------------------------------------------------------------------
  return (
    <div ref={editFormDivRef} className={styles.container}>
      <WikiSpinner show={isLoading} />
      {state.isNew ?
        <React.Fragment>
          <div className={styles.header}>
            Create new API Key
          </div>
          <InputText
            ui={props.ui.inputName}
            data={state.data?.name}
            disabled={!state.isNew}
            onUpdate={onUpdateName}
          />
        </React.Fragment>
        :
        <div className={styles.header}>
          <span className={styles.highlight}>
            {props.data?.name} {props.data?.isRequired ? "(Is Required)" : undefined}
          </span>
        </div>}
      <InputText
        ui={props.ui.inputDescription}
        data={state.data?.description}
        disabled={false}
        onUpdate={onUpdateDescription}
      />
      <InputDatetime
        ui={props.ui.inputExpiresOn}
        data={state.data?.expiresOn}
        isWarning={CheckExpirationDate(state.data?.expiresOn) == "ExpiresSoon"}
        isInvalid={CheckExpirationDate(state.data?.expiresOn) == "Expired"}
        onUpdate={onUpdateExpirationDatetime}
      />
      {state.showKey ?
        <React.Fragment>
          <InputText
            ui={props.ui.inputValue}
            //data={state.data?.value}
            data={state.data?.displayValue}
            disabled={false}
            onUpdate={onUpdateValue}
          />
          {!state.isNew &&
            <a className={styles.apikeyoption} onClick={onHideApiKey}>
              Hide API Key
            </a>}
        </React.Fragment>
        :
        <a className={styles.apikeyoption} onClick={onShowApiKey}>
          Show API Key
        </a>}
      {listOptions}
    </div>
  );
}