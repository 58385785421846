import React, { useState } from 'react';
import styles from './RequestDetails.module.css';
import RequestStatusBadge from '../request-status/request-status-badge/RequestStatusBadge';
import { IAdLanguageSetRequest } from '../IRegionalHomepageRequest';

interface IProps {
  data?: IAdLanguageSetRequest;
  regionName?: string;
  localeName?: string;
  businessName?: string;
  adRankName?: string;
}

export default function RequestDetails(props: IProps) {
  if (!props.data) return null;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const actualLogRecord = props.data.log.records.find(r => r.id == props.data?.log.actualRecordId);
  //---------------------------------------------------------------------------
  let statusDetails = undefined;
  switch (props.data.status) {
    case "New":
    case "Replaced":
      break;
    case "Assigned":
      statusDetails = StageDescription(
        actualLogRecord?.updatedAt ? actualLogRecord?.updatedAt : "",
        actualLogRecord?.assignedTo.displayName);
      break;
    case "Approved":
    case "Rejected":
      statusDetails = StageDescription(
        props.data.dateTimeResolved,
        actualLogRecord?.assignedTo.displayName);
      break;
  };
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>

      {/* Created */}
      <div>
        <RequestStatusBadge data={"Created"} />
      </div>
      <div className={styles.details}>
        {StageDescription(props.data.dateTimeCreated, props.data.createdBy.displayName)}
      </div>

      {/* Current status */}
      <div>
        <RequestStatusBadge
          data={props.data.status}
          isApproved={props.data.status == "Approved" ? true : undefined}
          isRejected={props.data.status == "Rejected" ? true : undefined} />
      </div>
      <div className={styles.details}>
        {statusDetails}
      </div>

      {/* Region */}
      {props.regionName &&
        <React.Fragment>
          <div className={styles.caption}>
            Region
          </div>
          <div>
            {props.regionName}
          </div>
        </React.Fragment>}

      {/* Business name */}
      {props.businessName &&
        <React.Fragment>
          <div className={styles.caption}>
            Business Name
          </div>
          <div>
            {props.businessName}
          </div>
        </React.Fragment>}

      {/* Ad Rank name */}
      {props.adRankName &&
        <React.Fragment>
          <div className={styles.caption}>
            Ad Rank
          </div>
          <div className={styles.adrank}>
            {props.adRankName}
          </div>
        </React.Fragment>}

      {/* Locale */}
      <div className={styles.caption}>
        Locale
      </div>
      <div>
        {props.localeName}
      </div>

      {isCollapsed ?
        /* Show 'more...' option */
        <div
          className={styles.option}
          onClick={() => setIsCollapsed(false)}>
          Show more...
        </div>
        :
        <React.Fragment>

          {/* Business Id */}
          <div className={styles.caption}>
            Business Id
          </div>
          <div>
            {props.data.businessId}
          </div>

          {/* Ad Campaign Id */}
          <div className={styles.caption}>
            Ad Campaign Id
          </div>
          <div>
            {props.data.adCampaignId}
          </div>

          {/* Ad Language Set Id */}
          <div className={styles.caption}>
            Advertisement Id
          </div>
          <div>
            {props.data.adLanguageSetId}
          </div>

          {/* Show 'less...' option */}
          <div
            className={styles.option}
            onClick={() => setIsCollapsed(true)}>
            Show less...
          </div>

        </React.Fragment>}

    </div>
  );
}

function StageDescription(dateTime: string, userName?: string) {
  return (
    <React.Fragment>
      <span>
        {new Date(dateTime).toLocaleString()}
      </span>
      <span className={styles.caption}>
        by
      </span>
      <span>
        {userName}
      </span>
    </React.Fragment>
  );
}