import { ReactPlugin, useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ClassAdLanguageSetPublished, ClassAdModulePublished } from "../components/homepage/ClassAdLanguageSetPublished";
import { v4 as createNewGuid } from "uuid";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
//----------------------------------------------------------------------------
export class TrackPageViewNames {
  static businessPage = "BusinessPage";
  static homePage = "HomePage";
  static searchResultsCard = "SearchResultsCard";
  static banner = "Banner";
}
//----------------------------------------------------------------------------
export type EPageViewType = 'Banner' | "HomePage" | "SearchResults" | "BusinessPage";
//----------------------------------------------------------------------------
export interface ITrackable {
  businessId: string;
  localeId: string;
  text: string;
  adCampaignId?: string;
  adLanguageSetId?: string;
}
  //---------------------------------------------------------------------
  export interface IPageViewProperties {
  pageViewId: string;
  businessId: string;
  localeId: string;
  adCampaignId?: string;
  adLanguageSetId?: string;
  text: string;
  refUri: string;
  pageViewType: EPageViewType;
  chargePoints?: number;
}
  //---------------------------------------------------------------------
  export class PageViewProperties implements IPageViewProperties {
  pageViewId!: string;
  businessId!: string;
  localeId!: string;
  adCampaignId?: string;
  adLanguageSetId?: string;
  text!: string;
  refUri!: string;
  pageViewType!: EPageViewType;
  chargePoints?: number;
  //---------------------------------------------------------------------
  private _browserTime?: Date;
  get browserTime() {
    return this._browserTime;
  }
  set browserTime(value: any) {
    if (value)
      this._browserTime = new Date(value);
    else
      this._browserTime = undefined;
  }
  //---------------------------------------------------------------------
  private _registerTimeUtc?: Date;
  get registerTimeUtc() {
    return this._registerTimeUtc;
  }
  set registerTimeUtc(value: any) {
    if (value)
      this._registerTimeUtc = new Date(value);
    else
      this._registerTimeUtc = undefined;
  }
  //---------------------------------------------------------------------
  clientCity!: string;
  clientCountryOrRegion!: string;
  clientStateOrProvince!: string;
  clientType!: string;
  //---------------------------------------------------------------------
  toJSON() {
    let result = {
      ...this,
      registerTimeUtc: null,
      clientCity: null,
      clientCountryOrRegion: null,
      clientStateOrProvince: null,
      clientType: null,
      browserTime: this._browserTime?.toISOString(),
      _browserTime: null,
      _registerTimeUtc: null,
    };
    delete result["registerTimeUtc"];
    delete result["_registerTimeUtc"];
    delete result["clientCity"];
    delete result["clientCountryOrRegion"];
    delete result["clientStateOrProvince"];
    delete result["clientType"];
    delete result["_browserTime"];
    if (!this._browserTime)
      delete result["browserTime"];
    return result;
  }
  //---------------------------------------------------------------------
  static fromITrackable(source: ITrackable, trackType: EPageViewType) {
    let result = new PageViewProperties();
    result.browserTime = new Date();//.toISOString();
    result.pageViewId = createNewGuid();
    result.businessId = source.businessId;
    result.adCampaignId = source.adCampaignId;
    result.adLanguageSetId = source.adLanguageSetId;
    result.localeId = source.localeId;
    result.text = source.text;
    result.refUri = location.href;
    result.pageViewType = trackType;
    switch (result.pageViewType) {
      case "BusinessPage":
        result.chargePoints = 10;//source.adRank?.pointsPerEngagement;
        break;
      case "Banner":
        result.chargePoints = 1;//source.adRank?.pointsPerView;
        break;
      case "HomePage":
        result.chargePoints = 2;//source.adRank?.pointsPerView;
        break;
      case "SearchResults":
        result.chargePoints = 0;//source.adRank?.pointsPerView;
        break;
    }
    if (result.chargePoints == undefined) {
      result.chargePoints = 0;
    }
    return result;
  }
  //------------------------------------------------------------------
  static fromArray(source?: any) {
    let result: PageViewProperties[] = [];
    source?.forEach((item: any) => {
      let pvp = new PageViewProperties();
      Object.assign(pvp, item);
      result.push(pvp);
    });
    return result;
  }
  //------------------------------------------------------------------
  equal(source: ITrackable, trackType: EPageViewType) {
    return (
      this.businessId == source.businessId &&
      this.localeId == source.localeId &&
      this.pageViewType == trackType
    );
  }
}
//----------------------------------------------------------------------------
export class Tracker {
  appInsights!: ReactPlugin;
  trackPageView?: PageViewProperties;
  //--------------------------------------------------------------------------
  constructor(appInsights: ReactPlugin) {
    //console.log("Tracker.constructor", appInsights);
    this.appInsights = appInsights;
  }
  //--------------------------------------------------------------------------
  track(pageViewType: EPageViewType, source?: ITrackable) {
    if (!source) return;
    if (this.trackPageView?.equal(source, pageViewType))
      return;
    if (!this.appInsights)
      return console.error("Tracker.track: appInsights is undefined");
    this.trackPageView = PageViewProperties.fromITrackable(source, pageViewType);
    console.log("Tracker.trackPageView:", this.trackPageView);
    this.appInsights.trackPageView({
      name: this.trackPageView?.pageViewType,
      pageType: this.trackPageView?.pageViewType,
      properties: this.trackPageView.toJSON(),
    });
    // window.dataLayer.push({
    //   event: 'pageview',
    //   page: this.trackPageView.toJSON()
    // });    
  }
}
//----------------------------------------------------------------------------
export function useTracker(): Tracker | undefined {
  const appInsights = useAppInsightsContext();
  const tracker = useRef<Tracker>();
  if (!tracker.current) {
    tracker.current = new Tracker(appInsights);
  }
  //--------------------------------------------------------------------------
  return tracker.current;
}