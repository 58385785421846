import { useContext } from 'react';
import { EditFormSection } from '../../common/console-layout/edit-form-sections/EditFormSection';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';
import GetHint from '../../common/functions/GetHint';
import { IUiInteractiveForm } from '../../common/forms/IUiInteractiveForm';
import { BusinessContext } from '../../../context/business-context/BusinessContextProvider';
import { IUiSectionBusinessPage } from '../../business-space/adpage-editor/section-business-page/IUiSectionBusinessPage';
import AdModulesEditor from '../ad-modules-editor/AdModulesEditor';
import { ClassAdModule } from '../../common/ad-modules/IAdModule';
import styles from './SectionSearch.module.css';
import AdStatus from '../ad-status/AdStatus';

interface IUiSectionSearchResultsCard extends IUiInteractiveForm {
}
const tmpUi: IUiSectionSearchResultsCard = {
  id: "SectionSearchResultsCard",
  directoryId: "",
  elementType: "",
  caption: undefined,
  hint: { en: "Search results module is displayed in business search results list" },
  menuContent: {
    id: "MenuContent",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: []
  }
};

interface IProps {
  ui: IUiSectionBusinessPage;
  isReadonly: boolean;
}

export default function SectionSearch(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { businessContext } = useContext(BusinessContext);
  const adLanguageSet = businessContext.adLanguageSetState?.adLanguageSet;
  const searchModules: ClassAdModule[] = adLanguageSet?.adModules ? adLanguageSet.adModules.filter(adModule =>
    adModule.adModuleType == "SearchResultsModule"
  ) : [];
  //---------------------------------------------------------------------------
  return (
    <EditFormSection
      id={tmpUi.id}
      caption={GetHint(appUiContext, tmpUi.id, tmpUi.hint)}
    >
      <div className={styles.container}>
        <AdStatus data={adLanguageSet?.status} />
        <AdModulesEditor
          ui={props.ui.adModule}
          adModules={searchModules}
          isReadonly={props.isReadonly}
          useExternalLayout={false}
        />
      </div>
    </EditFormSection>
  );
}