import { useContext } from 'react';
import GetCaption from '../../functions/GetCaption';
import PopupDialog from '../../popup-v2/popup-dialog/PopupDialog';
import styles from './TextEditorPopup.module.css';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { tmpTextEditorPopup } from '../IUiTextEditor';
import TextEditor from '../text-editor/TextEditor';
import { ScreenType, useAppScreenContext } from '../../../../context/app-screen-context/AppScreenProvider';
import CombineStyles from '../../../../utils/combineStyles';

interface IProps {
  //ui: IUiTextEditor;
  data?: string;
  onUpdate: (updatedText: string) => void;
  onClose: () => void;
}

export default function TextEditorPopup(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const ui = tmpTextEditorPopup;
  //---------------------------------------------------------------------------
  return (
    <PopupDialog
      id={ui.id}
      header={GetCaption(appUiContext, ui.id, ui.caption)}
      isModal={true}
      isDraggable={false}
      onClose={props.onClose}
    >
      <div className={CombineStyles([
        styles.container,
        screenType == ScreenType.Mobile ? styles.mobile : ""
      ])}>
        <TextEditor
          ui={ui}
          data={props.data}
          onUpdate={props.onUpdate}
          onClose={props.onClose}
        />
      </div>
    </PopupDialog>
  );
}