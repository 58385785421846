import React, { CSSProperties, useContext, useState } from 'react';
import CommonDictionaryItemEditForm from '../common-dictionary-item-editform/CommonDictionaryItemEditForm';
import DirectoryNodeWrapper from '../../../../common/directory-node-wrapper/DirectoryNodeWrapper';
import DirectoryNodeAttribute from '../../../../common/directory-node-attribute/DirectoryNodeAttribute';
import { IUiDictionaryEditorNode } from './IUiDictionaryEditorNode';
import { IUiOption } from '../../../../../common/options/IUiOption';
import { DirectoryEditorContext } from '../../../../../../context/directory-editor-context/DirectoryEditorContextProvider';
import { DictionaryDraftItem } from '../IDictionaryDraftItem';
import DictionaryItemIcon from '../../../business-tags-console/business-tags-editor/editor-node/DictionaryItemIcon';
import { getNodeLevel } from '../../../business-tags-console/business-tags-editor/editor-node/BusinessTagsDictionaryEditorNode';

interface IProps {
  ui: IUiDictionaryEditorNode;
  data: DictionaryDraftItem;
  dictionaryItems?: DictionaryDraftItem[]; // Alternative way to provide sub-items, in case if it's displayed as sub-dictionary
  isReadonly?: boolean;
  customStyles?: CSSProperties;
  onUpdate?: (updatedDraftItem: DictionaryDraftItem, replaceId?: string, itemPathToCheck?: string) => void;
  onAdd?: (
    updatedDraftItem: DictionaryDraftItem,
    parentItem: DictionaryDraftItem,
    shift: number
  ) => void;
  onReposition?: (updatedItem: DictionaryDraftItem, oldItem: DictionaryDraftItem) => void;
  onOptionSelect?: (draftItemId: string, selectedOptionId: string) => void;
}

export default function CommonDictionaryEditorNode(props: IProps) {
  const { directoryEditorContext } = useContext(DirectoryEditorContext);
  const [editMode, setEditMode] = useState(false);
  const [newSubItem, setNewSubItem] = useState<DictionaryDraftItem>();
  const [isExpanded, setIsExpanded] = useState(false);
  const nodeLevel = getNodeLevel(props.data.fullPath);
  let isReadonly = props.isReadonly == undefined ? false : props.isReadonly;
  isReadonly = isReadonly || !directoryEditorContext.editMode;
  const isDeactivated = props.data.isRepositionedOld || (!props.data.status?.editors && !props.data.status?.viewers);
  const filterConditionIsMet = directoryEditorContext.filterConditionIsMet(props.data);
  //--------------------------------------------------------------------------- Sub-items
  // If dictionary items provided in props, it's a priority. Otherwise get from the context
  const dictionaryItems = (props.dictionaryItems ? props.dictionaryItems : directoryEditorContext.editorState?.draft.items) as DictionaryDraftItem[];
  let subItems = dictionaryItems.filter(item =>
    item.path === props.data.fullPath && item.id !== props.data.id && directoryEditorContext.filterConditionIsMet(item));
  //---------------------------------------------------------------------------
  const onClick = () => {
    setEditMode(true);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedDraftItem: DictionaryDraftItem, itemPathToCheck?: string) => {
    setEditMode(false);
    if (!props.onUpdate) return;
    const replaceId = updatedDraftItem.id !== props.data.id ? props.data.id : undefined;
    props.onUpdate(updatedDraftItem, replaceId, itemPathToCheck);
  };
  //---------------------------------------------------------------------------
  const onReposition = (updatedDraftItem: DictionaryDraftItem, oldItem: DictionaryDraftItem) => {
    setEditMode(false);
    if (!props.onReposition) return;
    props.onReposition(updatedDraftItem, oldItem);
  };
  //---------------------------------------------------------------------------
  const subItemAdd = (newItem: DictionaryDraftItem) => {
    setNewSubItem(undefined);
    if (!props.onAdd) return;
    props.onAdd(newItem, props.data, subItems.length);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionEdit":
        setEditMode(true);
        break;
      case "OptionAdd":
        const newItem = DictionaryDraftItem.getNew({
          id: "",
          path: props.data.fullPath,
          status: {
            editors: true,
            viewers: false
          }
        });
        setNewSubItem(newItem);
        break;
      default:
        props.onOptionSelect && props.onOptionSelect(props.data.fullPath, optionId);
        break;
    };
  };
  //--------------------------------------------------------------------------- Sub-items
  const subItemNodes = subItems?.map(subItem =>
    <CommonDictionaryEditorNode
      key={subItem.fullPath}
      ui={props.ui}
      data={subItem as DictionaryDraftItem}
      dictionaryItems={dictionaryItems}
      isReadonly={props.isReadonly}
      customStyles={props.customStyles}
      onUpdate={props.onUpdate}
      onAdd={props.onAdd}
      onReposition={props.onReposition}
      onOptionSelect={props.onOptionSelect}
    />
  );
  //--------------------------------------------------------------------------- Node options
  let updatedMenuContent;
  if (!isReadonly) {
    const updatedOptions: IUiOption[] = [];
    props.ui.menuContent.options.forEach(option => {
      switch (option.id) {
        case "OptionEdit":
          updatedOptions.push({ ...option, iconId: "pencil" });
          break;
        case "OptionDelete":
          if (props.data.isNew) {
            updatedOptions.push({ ...option, iconId: "delete" });
          };
          break;
        case "OptionAdd":
          if (directoryEditorContext.editorState.draft.isHierarchical) {
            updatedOptions.push({ ...option, iconId: "plus" });
          };
          break;
      };
    });
    updatedMenuContent = { ...props.ui.menuContent, options: updatedOptions };
  } else {
    updatedMenuContent = { ...props.ui.menuContent, options: [] };
  };
  //---------------------------------------------------------------------------
  let customStyles = props.customStyles;
  if (isDeactivated) {
    customStyles = {
      ...customStyles,
      color: 'var(--clr_font_system)',
      textDecoration: 'line-through'
    };
  };
  //---------------------------------------------------------------------------
  if (props.data && (filterConditionIsMet || subItems.length > 0)) {
    if (editMode) return (
      <CommonDictionaryItemEditForm
        ui={props.ui.editForm}
        data={props.data}
        isReadonly={props.isReadonly || props.data.isRepositionedOld}
        onUpdate={onUpdate}
        onReposition={onReposition}
        onClose={() => setEditMode(false)}
      />
    ); else return (
      <React.Fragment>
        <DirectoryNodeWrapper
          ui={{ ...props.ui, menuContent: updatedMenuContent }}
          data={props.data}
          onOptionSelect={onOptionSelect}
        >

          <DirectoryNodeAttribute
            isFirstColumn={true}
            level={nodeLevel}
            isExpandable={subItems.length > 0}
            isExpanded={isExpanded}
            customStyles={customStyles}
            onClick={onClick}
            onToggle={() => setIsExpanded(!isExpanded)}
          >
            {props.data.id}
          </DirectoryNodeAttribute>

          <DirectoryNodeAttribute
            customStyles={customStyles}
            onClick={onClick}
          >
            {props.data.name}
          </DirectoryNodeAttribute>

          <DirectoryNodeAttribute />

          <DirectoryNodeAttribute />

          <DirectoryNodeAttribute>
            <div style={{ height: '100%', fontSize: '1.2em', display: 'flex', gap: '.2em' }}>
              <DictionaryItemIcon
                type='editors'
                enabled={!!props.data.status?.editors}
                hint={"Available for system translators"}
              />
              <DictionaryItemIcon
                type='viewers'
                enabled={!!props.data.status?.viewers}
                hint={"Available for end users"}
              />
            </div>
          </DirectoryNodeAttribute>

        </DirectoryNodeWrapper>

        {isExpanded && subItemNodes}

        {newSubItem &&
          <CommonDictionaryItemEditForm
            ui={props.ui.editForm}
            data={newSubItem}
            isNew={true}
            onUpdate={subItemAdd}
            onClose={() => setNewSubItem(undefined)}
          />}

      </React.Fragment>
    );
  } else return null;
}