import { useContext, useEffect, useRef, useState } from 'react';
import { IUiEditFormPreferences } from './IUiEditFormPreferences';
import styles from './Preferences.module.css';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import React from 'react';
import ConsoleTabLayout from '../../../common/console-layout/console-tab-layout/ConsoleTabLayout';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { TUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import { TUiCaption } from '../../../common/captions/TUiCaption';
import { TUiMenuOption } from '../../../common/menu/menu-content/menu-option/TUiMenuOption';
import ConsoleTabContent from '../../../common/console-layout/console-tab-layout/console-tab-content/ConsoleTabContent';
import { AppAuthContextStore } from '../../../../context/app-auth-context/AppAuthContext';
import WikiSpinner from '../../../app-layout/spinner/wikiSpinner';
import { useMsal } from '@azure/msal-react';
import InputsGroupWrapper from '../../../common/input-v2/inputs-group-wrapper/InputsGroupWrapper';
import InputDropdown from '../../../common/input-v2/input-dropdown/InputDropdown';
import { userSessionPreferencesKey } from '../../../../context/app-ui-context/IUserSessionPreferences';
import { clearFromSessionStorage } from '../../../../utils/sessionStorage';
import GoogleMap from '../../../common/google-maps/google-map/GoogleMap';
import InputListLanguages from './input-list-languages/InputListLanguages';
import { updateUserPromise } from '../../../../context/app-auth-context/user';
import { useAbortController } from '../../../../hooks/useAbortController';
import { IEditFormState } from '../../../common/edit-form/IEditFormState';

export interface IUserPreferences {
  rememberMe?: boolean;
  preferredColourTheme?: string;
  preferredLanguage?: string;
  preferredLanguages?: string[];
}

interface IUserPreferencesFormState extends IEditFormState<IUserPreferences> {
  updatedProperties: string[];
}

interface IUserPreferencesFormStateSpread {
  object?: IUserPreferences;
  initialState?: IUserPreferences;
  isNew?: boolean;
  isUpdated?: boolean;
  isValid?: boolean;
}

class UserPreferencesFormState implements IUserPreferencesFormState {
  object?: IUserPreferences;
  initialState?: IUserPreferences;
  updatedProperties!: string[];
  isNew?: boolean;
  isUpdated?: boolean;
  isValid!: boolean;
  //---------------------------------------------------------------------------
  constructor(
    state: IUserPreferencesFormState,
    spread?: IUserPreferencesFormStateSpread
  ) {
    Object.assign(this, state, spread);
    this.checkChanges();
  }
  //---------------------------------------------------------------------------
  checkChanges() {
    const updatedProperties: string[] = [];
    const isUpdated = JSON.stringify(this.object) != JSON.stringify(this.initialState);
    if (isUpdated) {
      // Check what properties are changed
      for (const propertyName in this.object) {
        //console.log(propertyName)
        const a = (this.object as any)[propertyName];
        const b = (this.initialState as any)[propertyName];
        //console.log("a, b", a, b);
        if (JSON.stringify(a) !== JSON.stringify(b)) {
          updatedProperties.push(propertyName);
        };
      };
      console.log('updatedPropertiesList:', updatedProperties);
    };
    //---------------------------------
    this.isUpdated = isUpdated;
    this.updatedProperties = updatedProperties;
  };
}

interface IProps {
  ui?: IUiEditFormPreferences;
}

export default function Preferences(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext, appAuthContextDispatch } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const [state, setState] = useState<UserPreferencesFormState>();
  const [isLoading, setIsLoading] = useState(false);
  const abortController = useAbortController("Preferences");
  const inputRef = useRef<HTMLInputElement>(null);
  //--------------------------------------------------------------------------- Breadcrumb
  useEffect(() => {
    if (props.ui?.breadcrumb) {
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: props.ui.breadcrumb });
    };
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //--------------------------------------------------------------------------- State
  useEffect(() => {
    if (appAuthContext.user) {
      const preferencesObject: IUserPreferences = {
        preferredLanguage: appAuthContext.user.preferredLanguage,
        preferredLanguages: appAuthContext.user.preferredLanguages ? appAuthContext.user.preferredLanguages : undefined,
        preferredColourTheme: appAuthContext.user.preferredColourTheme
      };
      const newState = new UserPreferencesFormState({
        object: preferencesObject,
        initialState: JSON.parse(JSON.stringify(preferencesObject)),
        updatedProperties: [],
        isNew: false,
        isUpdated: false,
        isValid: true
      });
      setState(newState);
    };
  }, [appAuthContext.user]);
  //---------------------------------------------------------------------------
  const onUpdateLanguage = (updatedValue?: string) => {
    if (state) {
      const updatedObject: IUserPreferences = {
        ...state.object as IUserPreferences,
        preferredLanguage: updatedValue
      };
      //-----------------------------------------------------------------------
      const updatedState = new UserPreferencesFormState(state, {
        object: updatedObject,
        isValid: true
      });
      setState(updatedState);
    } else console.error("User data is being updated, but state is not set");
  };
  //---------------------------------------------------------------------------
  const onLanguagesListUpdate = (updatedLocales?: string[]) => {
    if (state) {
      const updatedObject: IUserPreferences = {
        ...state.object as IUserPreferences,
        preferredLanguages: updatedLocales
      };
      //-----------------------------------------------------------------------
      const updatedState = new UserPreferencesFormState(state, {
        object: updatedObject,
        isValid: true
      });
      setState(updatedState);
    } else console.error("User data is being updated, but state is not set");
  };
  //---------------------------------------------------------------------------
  const onUpdateTheme = (updatedValue?: string) => {
    if (state) {
      const updatedObject: IUserPreferences = {
        ...state.object as IUserPreferences,
        preferredColourTheme: updatedValue
      };
      //-----------------------------------------------------------------------
      const updatedState = new UserPreferencesFormState(state, {
        object: updatedObject,
        isValid: true
      });
      setState(updatedState);
    } else console.error("User data is being updated, but state is not set");
  };
  //---------------------------------------------------------------------------
  const onSave = () => {
    setIsLoading(true);
    //-------------------------------------------------------------------------
    // Clean up session storage entries if there are any
    if (appAuthContext.user?.userIdentity.id) {
      const sessionStorageKey = `${userSessionPreferencesKey}.${appAuthContext.user?.userIdentity.id}`;
      clearFromSessionStorage(sessionStorageKey);
    }
    else {
      console.error("User Profile is rendered, but auth context is not set");
    }
    //-------------------------------------------------------------------------
    if (appAuthContext.user && state?.object) {
      let updatedUser = {
        ...appAuthContext.user,
        //rememberMe: userConsoleContext.userPreferencesContext?.userPreferences.rememberMe,
        preferredLanguage: state.object.preferredLanguage,
        preferredLanguages: state.object.preferredLanguages,
        preferredColourTheme: state.object.preferredColourTheme
      };
      //-----------------------------------------------------------------------
      let controller = abortController.newController("updateUser");
      updateUserPromise(instance, updatedUser, appAuthContext.config, controller?.signal)
        .then(updated => {
          updatedUser && 
            appAuthContextDispatch({ type: "SetUser", value: updatedUser });
          alert("User Preferences Saved Successfully.");
        })
        .catch(error => {
          alert(`User Preferences Save failed: ${error}`);
        })
        .finally(() => {
          !controller?.aborted && setIsLoading(false);
        });
    }
    else {
      console.error("Cannot update auth context because it is not set");
      setIsLoading(false);
    }
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OptionSave":
        onSave();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onPlaceSelect = (response: google.maps.places.PlaceResult) => {
    console.log(response)
  };
  //--------------------------------------------------------------------------- Edit form options
  let updatedMenuContent: TUiMenuContent | undefined = undefined;
  let editFormOptions = undefined;
  let formCaption: TUiCaption | undefined = undefined;
  if (props.ui) {
    formCaption = {
      id: props.ui.id,
      directoryId: props.ui.directoryId,
      elementType: props.ui.elementType,
      text: props.ui.hint
    };
    if (props.ui?.menuContent) {
      const mc = props.ui.menuContent;
      const updatedOptions: TUiMenuOption[] = [];
      const saveDisabled = !state?.isUpdated || !state?.isValid;
      mc.options.forEach(option => {
        switch (option.id) {
          case "OptionSave":
            updatedOptions.push({
              id: option.id,
              directoryId: option.directoryId,
              elementType: option.elementType,
              caption: option.caption,
              hint: option.hint,
              iconFile: "check",
              isDefault: !saveDisabled,
              disabled: saveDisabled,
              visible: true,
              index: 0,
              priorityLevel: 0,
              canHideCaption: false,
              action: undefined
            });
            break;
        };
      });
      updatedMenuContent = {
        id: mc.id,
        directoryId: mc.directoryId,
        elementType: mc.elementType,
        options: updatedOptions,
        visible: true,
        disabled: false,
        optionGroups: []
      };
      editFormOptions =
        <FormOptions
          ui={updatedMenuContent}
          optionIdVisibleOnMobile={"OptionSave"}
          onSelect={onOptionSelect}
        />;
    };
  };
  //---------------------------------------------------------------------------
  const scrollDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollDivRef.current) {
      scrollDivRef.current.addEventListener("wheel", () => {
        console.log('wheel')
      });
    };
  }, [scrollDivRef.current]);
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <WikiSpinner show={isLoading} />
      <ConsoleTabLayout
        toolbar={editFormOptions}
        allowScroll={true}
      >
        <ConsoleTabContent prompt={formCaption}>
          {props.ui &&
            <div className={styles.container}>
              <InputsGroupWrapper>

                <InputListLanguages
                  ui={props.ui.inputListLanguages}
                  data={state?.object?.preferredLanguages}
                  onUpdate={onLanguagesListUpdate}
                />

                <InputDropdown
                  ui={props.ui.inputLanguage}
                  data={state?.object?.preferredLanguage ? state?.object.preferredLanguage : ''}
                  options={appUiContext.dictionaries?.supportedLocales}
                  isAutoComplete={false}
                  hideOptionDescription={true}
                  sortOptions={true}
                  //showAlert={state?.updatedProperties.includes("preferredLanguage")}
                  onUpdate={onUpdateLanguage}
                />

                <InputDropdown
                  ui={props.ui.inputTheme}
                  data={state?.object?.preferredColourTheme ? state?.object.preferredColourTheme : ''}
                  options={appUiContext.dictionaries?.colourThemes}
                  isAutoComplete={false}
                  hideOptionDescription={true}
                  sortOptions={true}
                  //showAlert={state?.updatedProperties.includes("preferredLanguage")}
                  onUpdate={onUpdateTheme}
                />

              </InputsGroupWrapper>

              {/* <InputLocation
                elementRef={inputRef}
                ui={{ id: "", elementType: "", directoryId: "" }}
                onMapOptionSelect={() => { }}
                onClear={() => {}}
              /> */}

              <div style={{ height: '20em' }}>
                <GoogleMap
                  businesses={[]}
                  currentLocation={true}
                  isSearchEnabled={true}
                  externalAutocomplete={{
                    inputRef: inputRef,
                    options: {
                      fields: ["geometry", "name", "place_id"]
                    },
                    onMapAreaSelectCallback: onPlaceSelect
                  }}
                />
              </div>

            </div>}
        </ConsoleTabContent>
      </ConsoleTabLayout>
    </React.Fragment>
  );
}