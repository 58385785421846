import { CSSProperties, useContext } from 'react';
import { EDraftItemState } from '../../../../../context/directory-editor-context/EDraftItemState';
import DirectoryNodeBadge from './directory-node-badge/DirectoryNodeBadge';
import styles from './DirectoryNodeBadges.module.css';
import { DirectoryEditorContext } from '../../../../../context/directory-editor-context/DirectoryEditorContextProvider';

interface IProps {
  draftState: EDraftItemState;
}

export default function DirectoryNodeBadges(props: IProps) {
  const { directoryEditorContext } = useContext(DirectoryEditorContext);
  //---------------------------------------------------------------------------
  // Apply styles and provide badges (icons) based on current item draft state
  const calcStyles: CSSProperties = {
    color: 'inherit'
  };
  const badges: JSX.Element[] = [];
  directoryEditorContext.filter?.stateGroups?.forEach(stateGroup => {
    let conditionIsMet = false;
    //-------------------------------------------------------------------------
    stateGroup.states?.forEach(state => {
      // Check if the state is applied to the current node 
      //console.log(state.draftState, props.data.draftState)
      if (state.draftState === (props.draftState & state.draftState)) {
        conditionIsMet = true;
        //console.log(props.data.id, group.name, state.draftState, props.data.draftState)
        calcStyles.color = state.fontColor ? state.fontColor : stateGroup.color;
        calcStyles.fontWeight = state.fontWeight;
        calcStyles.textDecoration = state.textDecoration;
        //---------------------------------------------------------------------
        // Provide an icon if it's a rule for the state
        if (!stateGroup.iconId && state.iconId) {
          badges.push(
            <DirectoryNodeBadge
              key={stateGroup.id + state.draftState}
              iconId={state.iconId}
              color={state.iconColor ? state.iconColor : stateGroup.color}
              title={stateGroup.name}
            />
          );
        }
      };
    });
    //-------------------------------------------------------------------------
    // If group icon is provided we display only one badge for a group
    if (stateGroup.iconId && conditionIsMet) {
      badges.push(
        <DirectoryNodeBadge
          key={stateGroup.id}
          iconId={stateGroup.iconId}
          color={stateGroup.color}
          title={stateGroup.name}
        />
      );
    };
  });
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      {badges}
    </div>
  );
}