import { ReactNode, useContext } from 'react';
import { IUiOption } from '../../options/IUiOption';
import styles from './ContextMenuOption.module.css';
import IconSort from '../icons/IconSort';
import GetCaption from '../../functions/GetCaption';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import IconFilter from '../icons/IconFilter';
import IconReset from '../icons/IconReset';
import IconList from '../icons/IconList';
import IconGrid from '../icons/IconGrid';
import { ListManagerContext } from '../../../../context/list-manager-context/ListManagerContextProvider';
import { EListMode, EManagerMode } from '../../../../context/list-manager-context/IListManagerContextData';
import CombineStyles from '../../../../utils/combineStyles';
import IconGear from '../icons/IconGear';
import IconGears from '../icons/IconGears';
import IconSortSimplified from '../icons/IconSortSimplified';

interface IProps {
  ui: IUiOption;
  onSelect: (optionId: string) => void;
}

export default function ContextMenuOption(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const { listManagerContext } = useContext(ListManagerContext);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    props.onSelect(props.ui.id);
  };
  //---------------------------------------------------------------------------
  let iconElement: ReactNode = undefined;
  let isDisabled = false;
  switch (props.ui.id) {
    case "OptionSetSimplifiedMode":
      iconElement = <IconGear />;
      isDisabled = listManagerContext.managerMode === EManagerMode.Basic;
      break;
    case "OptionSetAndvancedMode":
      iconElement = <IconGears />;
      isDisabled = listManagerContext.managerMode === EManagerMode.Advanced;
      break;
    case "OptionSort":
      iconElement = <IconSort expanded={false} />;
      break;
    case "OptionSortPresets":
      iconElement = <IconSortSimplified />;
      break;
    case "OptionFilter":
      iconElement = <IconFilter expanded={false} />;
      break;
    case "OptionReset":
      iconElement = <IconReset />;
      break;
    case "OptionListModeList":
      iconElement = <IconList />;
      isDisabled = listManagerContext.currentListMode === props.ui.id as EListMode;
      break;
    case "OptionListModeGrid":
      iconElement = <IconGrid />;
      isDisabled = listManagerContext.currentListMode === props.ui.id as EListMode;
      break;
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={CombineStyles([
        styles.container,
        isDisabled ? styles.disabled : ''
      ])}
      onClick={isDisabled ? undefined : onSelect}
    >
      <div className={styles.icon}>
        {iconElement}
      </div>
      <div className={styles.caption}>
        {GetCaption(appUiContext, props.ui.id, props.ui.caption)}
      </div>
    </div>
  );
}