import React, { CSSProperties, ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import GetText from "../../functions/GetText";
import InputBox, { EInputValueType, TEnterKeyHint } from "../input-box/InputBox";
import InputContainer from "../input-container/InputContainer";
import { IValidationMessage } from "../input-validation/TUiValidation";
import styles from "./InputText.module.css";
import { TUiInputText } from "./TUiInputText";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";

interface IProps {
  ui: TUiInputText;
  valueType?: EInputValueType; // Ignored in case if multiline=true
  enterKeyHint?: TEnterKeyHint;
  data?: string;
  counter?: number;
  hideCaption?: boolean;
  hideHint?: boolean;
  multiline?: boolean;
  rows?: number;
  focused?: boolean;
  disabled?: boolean;
  showAlert?: boolean;
  showInvalid?: boolean;
  validationMessages?: IValidationMessage[]; // This should be provided in case if showInvalid = true
  onUpdate?: (value: string, isValid: boolean) => void;
  onEnter?: () => void; // 'Enter' key press handler. Ignored in case of multiline input - because in this case it's just line break
  onLeave?: () => void;
}

export default function InputText(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [data, setData] = useState<string>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [validationMessages, setValidationMessages] = useState<IValidationMessage[]>([]);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const focusIsSet = useRef<boolean>(false);
  //---------------------------------------------------------------------------
  const hideCaption = (props.hideCaption === undefined) ? false : props.hideCaption;
  const hideHint = (props.hideHint === undefined) ? false : props.hideHint;
  const multiline = (props.multiline === undefined) ? false : props.multiline;
  const focused = (props.focused === undefined) ? false : props.focused;
  const disabled = (props.disabled === undefined) ? false : props.disabled;
  const showAlert = (props.showAlert === undefined) ? false : props.showAlert;
  //--------------------------------------------------------------------------- Validate component usage
  if (props.multiline && props.onEnter) {
    throw "Cannot use onEnter event with multiline input!"
  }
  //--------------------------------------------------------------------------- Update state if props data is changed
  useEffect(() => {
    setData(props.data);
    if (props.data) {
      // We need this to rerender the input in case if parent component passes the same data in props
      // This is needed in case if input works as search string input
      // and we need to suppress invalid input
      // I.e. if parent component passes counter value, this means we need clean up the data and only then update the state
      // See InputDropdownPopup component as an example
      if (props.counter) {
        const newData = props.data.split(`${props.counter}`)[0];
        //console.log(newData)
        setData(newData);
      } else {
        setData(props.data);
      };
    }
    //-------------------------------------------------------------------------
    InputIsValid(props.data ? props.data : "");
  }, [props.data]);
  //--------------------------------------------------------------------------- Handle focus
  useEffect(() => {
    // If input should be focused: set focus to the input and set cursor at the end of the string 
    // UPDATE: made it fire on every props change because for some reason input loses focus after re-render if parent component made some changes
    // like enabled/disabled an option on the form
    // 2023.05.29 UPDATE: No need to run it on every props change because the issue seems to solve itself)))
    if (!props.ui.disabled && focused && textAreaRef.current && !focusIsSet.current) {
      const endOfTheString = textAreaRef.current.value ? textAreaRef.current.value.length : 0;
      textAreaRef.current.focus();
      textAreaRef.current.setSelectionRange(endOfTheString, endOfTheString);
      focusIsSet.current = true;
    };
  }, [textAreaRef.current, props.focused]);
  //---------------------------------------------------------------------------
  const onEditTextArea = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    onEdit(e.target.value);
  };
  //---------------------------------------------------------------------------
  const onEdit = (value: string) => {
    setData(value);
    if (props.onUpdate)
      props.onUpdate(value, InputIsValid(value));
  };
  //---------------------------------------------------------------------------
  const onKeyDownTextArea = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    switch (event.key) {
      case "Tab":
        console.log(event.key)
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "Tab":
        console.log(event.key)
        break;
      case "Enter":
        props.onEnter && props.onEnter();
        break;
    };
  };
  // Probably we need update object on input exit or use throttle
  //---------------------------------------------------------------------------
  function InputIsValid(value: string): boolean {
    var isValid: boolean = true;
    var validationMessages: IValidationMessage[] = [];
    if (props.ui.validation) {
      //-----------------------------------------------------------------------
      if (props.ui.validation.required) {
        if (value.length > 0) {
          isValid = true;
        } else {
          console.log(props.ui.validation)
          isValid = false;
          validationMessages = [{
            id: props.ui.validation.required.errorMessageId,
            value: props.ui.validation.required.errorMessage
          }];
          console.log(validationMessages)
          setValidationMessages(validationMessages);
        }
      };
    };
    //-------------------------------------------------------------------------
    if (props.showInvalid) {
      isValid = false;
      if (props.validationMessages) {
        const updatedMessages = validationMessages.concat(props.validationMessages);
        setValidationMessages(updatedMessages);
      };
    };
    //-------------------------------------------------------------------------
    setIsValid(isValid);
    return isValid;
  };
  //---------------------------------------------------------------------------
  const placeholder = GetText({
    appUiContext: appUiContext, 
    elementId: props.ui.id,
    elementPart: "Placeholder",
    text: props.ui.placeholder,
  });
  //---------------------------------------------------------------------------
  var calculatedStyle: CSSProperties | undefined = undefined;
  if (showAlert) {
    calculatedStyle = { border: '3px solid var(--clr_accent_attention)' };
  };
  if (!isValid) {
    calculatedStyle = { border: '3px solid var(--clr_accent_wrong)' };
  };
  //---------------------------------------------------------------------------
  const inputElement = multiline ?
    <div className={styles.valueTextarea}>
      <textarea
        ref={textAreaRef}
        id={props.ui.id}
        className={styles.input}
        style={calculatedStyle}
        placeholder={placeholder}
        value={data ? data : ""}
        rows={props.rows ? props.rows : 5}
        inputMode="text"
        onBlur={props.onLeave ? props.onLeave : undefined}
        onChange={onEditTextArea}
        onKeyDown={onKeyDownTextArea}
      />
    </div>
    :
    <InputBox
      id={props.ui.id}
      valueType={props.valueType}
      enterKeyHint={props.enterKeyHint}
      placeholder={placeholder}
      value={data ? data : ""}
      dropdownButtonEm={0}
      allowInput={true}
      focused={props.focused}
      showAlert={showAlert}
      showInvalid={!isValid}
      onChange={onEdit}
      onKeyDown={onKeyDown}
      onInputFinished={props.onLeave ? props.onLeave : undefined}
    />;
  //---------------------------------------------------------------------------
  const valueElement = disabled ?
    <div className={styles.value}>
      {props.data}
    </div>
    :
    inputElement;
  //---------------------------------------------------------------------------
  return (
    <InputContainer
      ui={props.ui}
      hideCaption={hideCaption}
      hideHint={hideHint}
      isValid={isValid}
      validationMessages={validationMessages ? validationMessages : undefined}
    >
      {valueElement}
    </InputContainer>
  );
}