import CombineStyles from "../../../utils/combineStyles";
import Icon, { IconSize } from "../../common/icon/Icon";
import { TUiMenuOption } from "../../common/menu/menu-content/menu-option/TUiMenuOption";
import styles from "./FloatingOptionMobile.module.css";
import { CSSProperties } from "react";

const magnifierOption: TUiMenuOption = {
  id: "optionSearch",
  directoryId: "",
  elementType: "",
  index: 1,
  priorityLevel: 1,
  caption: { en: "Search" },
  hint: null,
  iconFile: "search",
  isDefault: false,
  disabled: false,
  visible: true,
  canHideCaption: false,
  action: null
}

interface IProps {
  //ui: TUiMenuOption;
  opacity?: number;
  onSelect: (optionId: string) => void;
}

export default function FormOptionToolbarMobile(props: IProps) {
  const spin = false;
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (!props.opacity) return;
    props.onSelect(magnifierOption.id);
  };
  //---------------------------------------------------------------------------
  const calculatedStyle: CSSProperties = {
    backgroundColor: magnifierOption.customColor,
    display: magnifierOption.visible ? "flex" : "none",
    opacity: props.opacity != undefined ? `${props.opacity}` : ""
  };
  //---------------------------------------------------------------------------
  const containerStyle = CombineStyles([
    styles.container,
    magnifierOption.isDefault ? styles.default : "",
    magnifierOption.disabled ? styles.disabled : ""
  ]);
  //---------------------------------------------------------------------------
  if (props.opacity == 0) return null;
  return (
    <div
      id={magnifierOption.id}
      className={containerStyle}
      style={calculatedStyle}
      onClick={props.opacity ? onSelect : undefined}
    >
      {magnifierOption.iconFile &&
        <Icon
          imageName={magnifierOption.iconFile}
          size={IconSize.Small}
          reserveSpace={false}
          spin={spin}
        />}
    </div>
  );
}