import { useContext } from "react";
import { ListManagerContext } from "../../../../context/list-manager-context/ListManagerContextProvider";
import CombineStyles from "../../../../utils/combineStyles";
import { IListColumn } from "../../../common/lists/IListColumn";
import styles from "./BusinessSearchSortItem.module.css";
import IconSort from "../../../common/lists/icons/IconSort";
//---------------------------------------------------------------------------------
interface IProps {
  listColumn: IListColumn;
  onSelect: () => void;
}
//---------------------------------------------------------------------------------
export function BusinessSearchSortItem(props: IProps) {
  const { listManagerContext, listManagerContextDispatch } = useContext(ListManagerContext);
  //---------------------------------------------------------------------------------
  let selected = listManagerContext.selectedSortColumn?.id == props.listColumn.id;
  let asc = props.listColumn.sorter?.sort ?? "Asc";
  //---------------------------------------------------------------------------------
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    !selected && listManagerContextDispatch({
      type: "UpdateSorter",
      updatedColumn: props.listColumn
    });
    props.onSelect();
  }
  //---------------------------------------------------------------------------------
  const containerStyles = CombineStyles([
    styles.container,
    listManagerContext.selectedSortColumn?.id == props.listColumn.id ? styles.selected : ""
  ]);
  //---------------------------------------------------------------------------------
  return (
    <div className={containerStyles} onClick={onClick} >
      <IconSort expanded={asc == "Asc"}/>
      {props.listColumn.name}
    </div>
  );
}
