import styles from './DictionaryEditorFilter.module.css';
import { DraftItemStateEnum } from '../../dictionary-draft-models/DraftItemStateEnum';
import { IDictionaryDraftItemStateGroup } from '../../../administrator-console/dictionaries/common/settings/IDictionaryDraftItemStateGroup';
import React from 'react';
import { dictonaryDraftStatesFullDetails } from '../../../administrator-console/dictionaries/common/settings/dictonaryDraftStatesFullDetails';
import LegendItem from '../dictionary-editor-legend-item/LegendItem';
import { dictonaryDraftStatesLessDetails } from '../../../administrator-console/dictionaries/common/settings/dictonaryDraftStatesLessDetails';
import { TUiInputText } from '../../../../common/input/input-text/TUiInputText';


export interface IDictionaryDraftFilter {
  text?: string;
  draftState: DraftItemStateEnum;
  stateGroups: IDictionaryDraftItemStateGroup[];
  selectedGroups: string[];
  searchById: boolean;
}

export interface IUiDictionaryEditorFilter {
  searchInput: TUiInputText;
}

interface IProps {
  ui: IUiDictionaryEditorFilter;
  data?: IDictionaryDraftFilter;
  showGroupingsSelector: boolean;
  onUpdate: (updatedFilter: IDictionaryDraftFilter) => void;
}

export default function DictionaryEditorFilter(props: IProps) {
  //---------------------------------------------------------------------------
  const setGroupingType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const grouping: IDictionaryDraftItemStateGroup[] = (event.target.value == "fullDetails") ? dictonaryDraftStatesFullDetails : dictonaryDraftStatesLessDetails;
    const updatedFilter: IDictionaryDraftFilter = {
      ...props.data as IDictionaryDraftFilter,
      draftState: DraftItemStateEnum.All,
      stateGroups: grouping,
      selectedGroups: grouping.map(g => g.id)
    };
    props.onUpdate(updatedFilter);
  };
  //---------------------------------------------------------------------------
  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value != undefined && value !== "") {
      props.onUpdate({
        ...props.data as IDictionaryDraftFilter,
        text: value.toLowerCase()
      });
    }
    else {
      props.onUpdate({
        ...props.data as IDictionaryDraftFilter,
        text: undefined
      });
    };
  };
  //---------------------------------------------------------------------------
  const onSearchByIdUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onUpdate({
      ...props.data as IDictionaryDraftFilter,
      searchById: e.target.checked
    });
  };
  //--------------------------------------------------------------------------- Filter condition update
  const onLegendItemSwitch = (draftStateId: string, enabled: boolean) => {
    if (props.data) {
      let draftState: DraftItemStateEnum = props.data.draftState;
      //-------------------------------------------------------------------------
      // Update groups list to check if all the groups are switched on
      let updatedGroupsList: string[] = [];
      if (props.data.selectedGroups.includes(draftStateId)) {
        updatedGroupsList = props.data.selectedGroups.filter(ct => ct != draftStateId);
      } else {
        updatedGroupsList = props.data.selectedGroups.concat(draftStateId);
      };
      //-------------------------------------------------------------------------
      if (updatedGroupsList.length == props.data.stateGroups.length) {
        draftState = DraftItemStateEnum.All;
      } else if (updatedGroupsList.length == 0) {
        draftState = DraftItemStateEnum.None;
      } else {
        // Get selected state group data
        const stateGroup = props.data.stateGroups.find(g => g.id == draftStateId);
        // Get selected group states
        stateGroup?.states?.forEach(state => {
          if (enabled) {
            draftState |= state.draftState;
          } else {
            draftState &= ~state.draftState;
          };
        });
      };
      //-------------------------------------------------------------------------
      props.onUpdate({
        ...props.data as IDictionaryDraftFilter,
        draftState: draftState,
        selectedGroups: updatedGroupsList
      })
    };
  };
  //--------------------------------------------------------------------------- Legend
  const legendItems = props.data?.stateGroups.map(item => (
    <LegendItem
      key={item.id}
      ui={item}
      enabled={props.data ? props.data?.selectedGroups.includes(item.id) : false}
      onSwitch={(enabled: boolean) => onLegendItemSwitch(item.id, enabled)}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>

      <div className={styles.container}>
        {props.showGroupingsSelector &&
          <select
            title='selectStateGroups'
            style={{ marginBottom: '1em', border: 'none' }}
            onChange={setGroupingType}
          >
            <option value="fullDetails">Full Details</option>
            <option value="grouped">Less Details</option>
          </select>}
        <div className={styles.legend}>
          {legendItems}
        </div>
      </div>

      {/* <div className={styles.container}> */}
      {/* <TagSearchInput
          ui={props.ui.searchInput}
          onChange={onSearchInputChange}
        /> */}
      <input
        title='searchString'
        type='text'
        defaultValue={props.data?.text}
        placeholder='Type search keyword here...'
        //style={{ marginBottom: '.5em', border: 'none' }}
        onChange={onSearchInputChange}
      />
      {/* <div className={styles.switcher}>
          <input
            title='searchMode'
            type='checkbox'
            defaultChecked={props.data?.searchById}
            onChange={onSearchByIdUpdate}
          />
          <label>
            Search by Id
          </label>
        </div> */}
      {/* </div> */}

    </React.Fragment>
  );
}