import React from "react";
import { TUiCaption } from "../../captions/TUiCaption";
import Caption from "../../captions/Caption";
import styles from "./EditFormSectionHeader.module.css"
//---------------------------------------------------------------------------
interface IProps {
  caption: TUiCaption | string,
}
//---------------------------------------------------------------------------
export function EditFormSectionHeader(props: IProps) {
  const captionText = (typeof (props.caption) == "string")
    ? { "text": { "en": props.caption } } as TUiCaption
    : props.caption;
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      {captionText &&
        <div className={styles.caption}>
          <Caption ui={captionText} />
        </div>}
    </React.Fragment>
  );
}