import { IFilterItemOption } from "../../../common/lists/filter-editor/IFilterItem";

export const validatorStatus = (
  updatedOptionId: string,
  newValue: boolean,
  optionsToValidate: IFilterItemOption[]
): IFilterItemOption[] => {
  // This func should return updated list of options  
  let updatedOptions: IFilterItemOption[] = optionsToValidate;
  switch (updatedOptionId) {
    //-------------------------------------------------------------------
    case "New":
    case "Uncompleted":
    case "Approved":
    case "Rejected":
      if (newValue == true) {
        // Unselect and make enabled all other options
        updatedOptions = optionsToValidate?.map(o => o.id == updatedOptionId ?
          o :
          { ...o, isSelected: false, isDisabled: false }
        );
      };
      break;
    //-------------------------------------------------------------------
    case "Completed":
      if (newValue == true) {
        // Make options approved and rejected disabled and unselect the rest
        updatedOptions = optionsToValidate?.map(o => {
          if (o.id == updatedOptionId) {
            return o;
          } else if (["Approved", "Rejected"].includes(o.id)) {
            return { ...o, isSelected: false, isDisabled: true };
          } else {
            return { ...o, isSelected: false, isDisabled: false };
          };
        });
      } else {
        // Enable approved/rejected options
        updatedOptions = optionsToValidate?.map(o => ["Approved", "Rejected"].includes(o.id) ?
          { ...o, isSelected: false, isDisabled: false } :
          o
        );
      };
      break;
  };
  return updatedOptions;
};