import ConsoleContent from '../common/console-layout/console-content/ConsoleContent';
import styles from './TestComponent.module.css';

interface IProps {
}

export default function TestComponent(props: IProps) {
  const sidebarContent =
    <div>
      Sidebar
    </div>
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={undefined}
      //formOptions={formOptions}
      sidebarChildren={sidebarContent}
    >
      Container
    </ConsoleContent>
  );
}