import { IAdCampaign } from "../../../../context/business-context/IAdCampaign";
import { IBusiness, IBusinessPaths } from "../../../../context/business-context/IBusiness";
import { IDictionaryItemStatus } from "../../../common/dictionaries/IUiDictionaryItem";

export interface ISystemBusinessRegional extends IBusiness {
  regionId: string;
  status?: IDictionaryItemStatus;
}

export class ClassSystemBusinessRegional implements ISystemBusinessRegional {
  id!: string;
  businessType!: "SystemBusinessRegional";
  regionId!: string;
  paths!: IBusinessPaths;
  datetimeCreated!: string;
  adCampaigns!: IAdCampaign[];
  status?: IDictionaryItemStatus;
  //---------------------------------------------------------------------
  constructor(data: ISystemBusinessRegional) {
    Object.assign(this, data);
  };
  //---------------------------------------------------------------------
  validate(): boolean {
    return true;
  };
}