import { useContext } from "react";
import { IUiMenuContent } from "../../../../common/options/menus/IUiMenuContent";
import PopupTest from "../../../../common/popup-v2/popup/PopupTest";
import MainMenuPopupOption from "./main-menu-popup-option/MainMenuPopupOption";
import styles from "./MainMenuPopup.module.css";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";
import { ScreenType, useAppScreenContext } from "../../../../../context/app-screen-context/AppScreenProvider";
import PopupHeader from "../../../../common/popup-v2/popup-header/PopupHeader";
import GetHint from "../../../../common/functions/GetHint";
/*
Homepage
  All
  Stay
    <...> (currently opened business page)
  Food
  ...
Place an Ad
  <completed wizard steps>
Business Editor (based on current business context)
  Owner
    Business List
    Messages
    Business <...> (currently opened business)
    Ad Language Set <...> (currently opened ad language set)
  Translator
    ...
Admin Console
  Administrator
    Users
    Dictionaries
    Regional Homepages
    Business Tag Requests
    API Keys
  Moderator
    Customer Ads
    Regional Homepages
  Content Manager
    Regional Homepages
  Translator
    Dictionaries
      <dictionaries?>
    Widgets
      <directories?>
    Default Content
      <directories?>

Current user position is highlighted and reflected by breadcrumbs
*/

interface IProps {
  show: boolean;
  ui: IUiMenuContent;
  selectedOptionPath?: string;
  onSelect: (selectedOptionId: string) => void;
  onClose: () => void;
}

export default function MainMenuPopup(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  //--------------------------------------------------------------------------- Menu options
  const menuOptions = props.ui.options.map((option) => (
    <MainMenuPopupOption
      key={option.id}
      ui={option}
      selectedOptionPath={props.selectedOptionPath}
      onSelect={props.onSelect}
      onClose={props.onClose}
    />
  ));
  //---------------------------------------------------------------------------
  if (props.show)
    return (
      <PopupTest
        anchorId="MainMenuButton"
        id="mainMenuPopup"
        closeOnClickOutside={true}
        onClose={props.onClose}
      >

        {screenType == ScreenType.Mobile &&
          <PopupHeader
            header={GetHint(appUiContext, props.ui.id, props.ui.hint)}
            onClose={props.onClose}
            onBack={props.onClose}
          />}

        <div className={styles.container}>
          {menuOptions}
        </div>

      </PopupTest>
    );
  else return null;
}