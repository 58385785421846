import { IRequestRejectionReason } from "../../admin-console/moderator-console/approval-request-checklist/IApprovalRequestUnit";

export type EAdUnitStatus = "Draft" | "PendingApproval" | "Approved" | "Rejected" | "Invalid";

export interface IAdUnit {
  status?: ClassAdUnitStatus;
}

export interface IAdUnitStatus {
  statusId: EAdUnitStatus;
  rejectionReason?: IRequestRejectionReason;
  isReadonly: boolean;
  isApproved: boolean;
  isInTrouble: boolean;
  isCompleted: boolean;
}

export class ClassAdUnitStatus implements IAdUnitStatus {
  statusId!: EAdUnitStatus;
  rejectionReason?: IRequestRejectionReason;
  get isReadonly() {
    switch (this.statusId) {
      case "Draft":
      case "Invalid":
        return false;
      default:
        return true;
    };
  };
  get isApproved() {
    switch (this.statusId) {
      case "Approved":
        return true;
      default:
        return false;
    };
  };
  get isInTrouble() {
    switch (this.statusId) {
      case "Rejected":
      case "Invalid":
        return true;
      default:
        return false;
    };
  };
  get isCompleted() {
    switch (this.statusId) {
      case "Approved":
      case "Rejected":
        return true;
      default:
        return false;
    };
  };
  //---------------------------------------------------------------------------
  constructor(statusId: EAdUnitStatus, rejectionReason?: IRequestRejectionReason) {
    this.statusId = statusId;
    this.rejectionReason = rejectionReason;
  }; 
}