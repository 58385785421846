import Icon, { IconSize } from '../../icon/Icon';
import styles from './PopupHeader.module.css';

interface IProps {
  header: string;
  onClose: () => void;
  onBack?: () => void;
}

export default function PopupHeader(props: IProps) {
  //console.log(props.onBack)
  //---------------------------------------------------------------------------
  return (
    <div id="popup_header" className={styles.container}>
      {props.onBack ?
        <div className={styles.backButton} onClick={props.onBack}>
          <Icon
            imageName="backArrow"
            size={IconSize.Small}
            reserveSpace={true}
          />
        </div>
        :
        <div className={styles.buttonPlaceholder} />
      }
      <div className={styles.headerText}>
        {props.header}
      </div>
      {props.onClose &&
        <div className={styles.closeButton} onClick={props.onClose}>
          <Icon
            imageName="close"
            size={IconSize.Small}
            reserveSpace={true}
          />
        </div>
      }
    </div>
  );
}