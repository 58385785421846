import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { BusinessTagsDictionaryDraft } from "../business-tags-editor/IBusinessTagsDictionaryDraft";
import { apiDeletePrivate, checkResponse } from "../../../../../utils/api";
import { apiBasePath, pathDeleteBusinessTagsDictionaryDraft } from "../../../../../utils/apiPathConstant";
import { IBusinessTagsDictionaryResponseContent } from "./IBusinessTagsDictionaryResponseContent";

interface IProps {
  msalInstance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  abortSignal?: AbortSignal;
}

export async function deleteBusinessTagsDictionaryDraftAsync(props: IProps): Promise<IBusinessTagsDictionaryResponseContent> {
  if (!props.authConfig) throw new Error("deleteBusinessTagsDictionaryDraftAsync: auth config is not provided");
  //---------------------------------------------------------------------------
  const response = await apiDeletePrivate(
    props.msalInstance,
    props.authConfig,
    `${apiBasePath}${pathDeleteBusinessTagsDictionaryDraft}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "deleteBusinessTagsDictionaryDraftAsync", props.abortSignal?.aborted);
  const draft = new BusinessTagsDictionaryDraft().initialize(response?.content.draft);  
  return {
    draft: draft,
    subDictionariesList: response?.content.subDictionaries,
    subDictionaryOriginals: response?.content.subDictionaryOriginals
  };
}