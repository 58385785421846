interface IProps {
  pointTo: 'Left' | 'Right' | 'Up' | 'Down';
}

export default function IconAngle(props: IProps) {
  let transform = '';
  switch (props.pointTo) {
    case 'Left':
      transform = "scale(-1,1) translate(-24,0)";
      break;
    case 'Up':
      break;
    case 'Down':
      break;
  };
  //---------------------------------------------------------------------------
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="1em" height="1em"
      viewBox="0 0 24 48">
      <g transform={transform}>
        <polyline
          points="3,3 24,24 3,45"
          fill="none"
          stroke="currentColor"
          strokeWidth={6}
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}