import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathGetSystemBusinessRegional } from "../../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../../utils/common";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";
import { IAdLanguageSetListItem } from "../../../../ad-content-editor/IAdLanguageSet";
import { ISystemBusinessRegional } from "../ISystemBusinessRegional";

export interface IGetBusinessResponse {
  business: ISystemBusinessRegional;
  ads: IAdLanguageSetListItem[];
}

export default function getRegionalHomepageBusiness(
  msalInstanse: IPublicClientApplication,
  businessId?: string,
  authConfig?: IAuthConfig,
  abortSignal?: AbortSignal
): Promise<IGetBusinessResponse> {
  return new Promise((resolve, reject) => {
    if (!businessId)
      return reject("Cannot get regional homepage business: id is not provided");
    //-------------------------------------------------------------------------
    const uri = `${apiBasePath}${stringFormatter(pathGetSystemBusinessRegional, [businessId])}`;
    apiGetPrivate(
      msalInstanse,
      authConfig,
      uri,
      abortSignal
    ).then(response => {
      checkResponse(response, "getRegionalHomepageBusiness", abortSignal?.aborted);
      //const business = new SystemBusiness(response?.content.business);
      const responseData: IGetBusinessResponse = {
        business: response?.content.business as ISystemBusinessRegional,
        ads: response?.content.ads as IAdLanguageSetListItem[]
      };
      resolve(responseData);
    }).catch(error =>
      reject(error)
    );
  });
}