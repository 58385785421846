import styles from './DropTarget.module.css';

export const dropTargetId = 'droptarget';

interface IProps {
}

export default function DropTarget(props: IProps) {
  //---------------------------------------------------------------------------
  return (
    <div
      id={dropTargetId}
      className={styles.container}
    >
    </div>
  );
}