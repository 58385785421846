import { ReactNode } from "react";
import ChecklistItemAdModule from "./checklist-item-admodule/ChecklistItemAdModule";
import { ClassAdModule } from "../../../common/ad-modules/IAdModule";
import { AdLanguageSet } from "../../../ad-content-editor/IAdLanguageSet";
import React from "react";
import CheckListItemComplexIssue from "./checklist-item-complex-issue/CheckListItemComplexIssue";
import { IAdUnit } from "../../../common/ad-modules/IAdUnit";

interface IProps {
  data?: AdLanguageSet;
  isReadonly: boolean;
  onHover?: (path: string[]) => void;
  onReview?: (updatedAdLanguageSet: AdLanguageSet) => void;
}

export default function ChecklistAdLanguageSet(props: IProps) {
  //---------------------------------------------------------------------------
  const onAdModuleReview = (updatedAdModule: ClassAdModule) => {
    if (props.data?.adModules == undefined) return;
    const updatedAdLanguageSet = new AdLanguageSet({
      ...props.data,
      adModules: props.data.adModules.map(adModule => adModule.id == updatedAdModule.id ? updatedAdModule : adModule)
    });
    props.onReview?.(updatedAdLanguageSet);
  };
  //---------------------------------------------------------------------------
  const onComplexIssueReview = (updatedUnit: IAdUnit) => {
    if (props.isReadonly) return;
    if (!props.data) return;
    if (!props.data.issues) return;
    const updatedIssues = props.data.issues.map(issue =>
      issue.status?.rejectionReason?.reasonId == updatedUnit.status?.rejectionReason?.reasonId ?
        updatedUnit :
        issue);
    const updatedAdLanguageSet = new AdLanguageSet({
      ...props.data,
      issues: updatedIssues
    });
    props.onReview?.(updatedAdLanguageSet);
  };
  //---------------------------------------------------------------------------
  // Checklist items for ad modules
  let checklistItems: ReactNode[] = [];
  let setCollapsed = false; // Make first uncompleted module expanded
  props.data?.adModules?.forEach(adModule => {
    const isCompleted = adModule.status?.statusId != "PendingApproval";
    const adModuleItem =
      <ChecklistItemAdModule
        key={adModule.id}
        data={adModule}
        isReadonly={props.isReadonly}
        isCollapsed={setCollapsed || isCompleted}
        onHover={props.onHover}
        onReview={onAdModuleReview}
      />;
    checklistItems.push(adModuleItem);
    setCollapsed = isCompleted ? false : true;
  });
  //---------------------------------------------------------------------------
  // Render complex issue checklist items
  const complexIssueItems = props.data?.issues?.map(complexIssue =>
    <CheckListItemComplexIssue
      key={`${props.data?.id}_${complexIssue.status?.rejectionReason?.reasonId}`}
      unitType="AdLanguageSet"
      data={complexIssue}
      isReadonly={props.isReadonly}
      onReview={onComplexIssueReview}
    />
  );
  checklistItems = checklistItems.concat(complexIssueItems);
  //---------------------------------------------------------------------------
  if (props.data) return (
    <React.Fragment>
      {checklistItems}
    </React.Fragment>
  ); else return null;
}