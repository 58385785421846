import { useRef } from "react";
import { ScreenType } from "../../../../context/app-screen-context/AppScreenProvider";
import { ImageTransformation, TCanvasState } from "../image-editor-history/ImageEditorHistory";

const handleQuarterSize = 2;
const handleSemiSize = handleQuarterSize * 2;
const handleSize = handleSemiSize * 2;

export enum ECropDirection {
  None = 0,
  NorthWest = 1,
  North = 2,
  NorthEast = 3,
  East = 4,
  SouthEast = 5,
  South = 6,
  SouthWest = 7,
  West = 8,
  Move = 9,
  Pinch = 10,
}

export enum EImageEditorAction {
  None = 0,
  CropStart = 1,
  CropConfirm = 2,
  CropDiscard = 3,
  RotateClockwise = 4,
  RotateCounterclockwise = 5,
  FlipVertical = 6,
  FlipHorizontal = 7,
  Undo = 8,
  Redo = 9,
  UrlAndBlobSave = 11,
  ImageDescriptionStart = 12,
  ImageDescriptionConfirm = 13,
  ImageDescriptionDiscard = 14,
  SelectImage = 15,
  SelectImageFromGallery = 16,
  ImageFromGalleryDiscard = 17,
  ToggleFullScreenMode = 18,
  RemoveImage = 19,
  EnterEditMode = 20,
}

export class Point {
  x: number;
  y: number;
  i?: number;
  j?: number;
  static clientWidth: number;
  static clientHeight: number;
  static offscreenWidth: number;
  static offscreenHeight: number;
  static imageRect: Rect;
  //------------------------------------------------------------------------------------
  constructor(x: number, y: number, i?: number, j?: number) {
    this.x = x;
    this.y = y;
    this.i = i;
    this.j = j;
  }
  //------------------------------------------------------------------------------------
  static setCanvasSize = (cvs: HTMLCanvasElement | null) => {
    if (cvs) {
      if (cvs.width != Point.clientWidth)
        cvs.width = Point.clientWidth;
      if (cvs.height != Point.clientHeight)
        cvs.height = Point.clientHeight;
    }
  }
  //------------------------------------------------------------------------------------
  transformIJ(mx: DOMMatrix): Point {
    if (this.i && this.j) {
      return new Point(
        Math.round(this.i * mx.a + this.j * mx.c),
        Math.round(this.i * mx.b + this.j * mx.d)
      );
    }
    return new Point(0, 0);
  }
  //------------------------------------------------------------------------------------
  getIndexInRect(mx: DOMMatrix): number {
    if (this.i && this.j) {
      let p = this.transformIJ(mx);
      for (let k = 0; k < Rect.standartPointsIJ.length; k++) {
        if (Rect.standartPointsIJ[k].x == p.x && Rect.standartPointsIJ[k].y == p.y)
          return k;
      }
    }
    return -1;
  }
  //------------------------------------------------------------------------------------
  clientToImage(mx: DOMMatrix, scale: number): Point {
    let x = (this.x - Point.clientWidth / 2) * scale;
    let y = (this.y - Point.clientHeight / 2) * scale;

    let imx = mx.inverse();

    let tx = x * imx.a + y * imx.c + Point.imageRect.left + Point.imageRect.width / 2;
    let ty = x * imx.b + y * imx.d + Point.imageRect.top + Point.imageRect.height / 2;

    //console.log("Point.clientToImage tx, ty, cw, ch:", tx, ty, Point.imageRect.width, Point.imageRect.height);
    return new Point(tx, ty, this.i, this.j);
  }
  //------------------------------------------------------------------------------------
  clientToOffscreen(mx: DOMMatrix, scale: number): Point {
    let x = (this.x - Point.clientWidth / 2);
    let y = (this.y - Point.clientHeight / 2);

    let imx = mx.inverse();

    //let tx = x * imx.a + y * imx.c + (Point.imageRect.left + Point.imageRect.width / 2) / scale;
    //let ty = x * imx.b + y * imx.d + (Point.imageRect.top + Point.imageRect.height / 2) / scale;
    let tx = x * imx.a + y * imx.c + Point.offscreenWidth / 2;// + (Point.imageRect.left + Point.imageRect.width / 2) / scale;
    let ty = x * imx.b + y * imx.d + Point.offscreenHeight / 2;// + (Point.imageRect.top + Point.imageRect.height / 2) / scale;

    return new Point(tx, ty, this.i, this.j);
  }
  //------------------------------------------------------------------------------------
  imageToClient(imageMX: DOMMatrix, scale: number): Point {
    let x = this.x - (Point.imageRect.left + Point.imageRect.width / 2);
    let y = this.y - (Point.imageRect.top + Point.imageRect.height / 2);

    let imx = imageMX;

    let tx = (x * imx.a + y * imx.c) / scale + Point.clientWidth / 2;
    let ty = (x * imx.b + y * imx.d) / scale + Point.clientHeight / 2;

    return new Point(tx, ty, this.i, this.j);
  }
  //------------------------------------------------------------------------------------
  clientToCanvas(mx: DOMMatrix, scale: number): Point {
    let p = this.clientToImage(mx, scale);
    return p.imageToCanvas(mx, scale);
  }
  //------------------------------------------------------------------------------------
  imageToCanvas(mx: DOMMatrix, scale: number): Point {
    let x = this.x - (Point.imageRect.left + Point.imageRect.width / 2);
    let y = this.y - (Point.imageRect.top + Point.imageRect.height / 2);
    return new Point(x, y, this.i, this.j);
  }
  //------------------------------------------------------------------------------------
  offscreenToCanvas(mx: DOMMatrix, scale: number): Point {
    let x = this.x - Point.offscreenWidth / 2;
    let y = this.y - Point.offscreenHeight / 2;
    //let x = this.x - (Point.imageRect.left + Point.imageRect.width / 2) / scale;
    //let y = this.y - (Point.imageRect.top + Point.imageRect.height / 2) / scale;
    return new Point(x, y, this.i, this.j);
  }
}
//------------------------------------------------------------------------------------
export class Rect {
  //------------------------------------------------------------------------------------
  static standartPointsIJ: Point[] = [
    new Point(-1, -1),
    new Point(1, -1),
    new Point(1, 1),
    new Point(-1, 1),
  ];
  //------------------------------------------------------------------------------------
  points: Point[];
  //----------------------------------------------------------------------------------
  constructor(left: number, top: number, right: number, bottom: number) {
    this.points = [
      new Point(left, top, -1, -1),
      new Point(right, top, 1, -1),
      new Point(right, bottom, 1, 1),
      new Point(left, bottom, -1, 1)
    ];
  }
  //----------------------------------------------------------------------------------
  clone(): Rect {
    return new Rect(this.left, this.top, this.right, this.bottom);
  }
  //----------------------------------------------------------------------------------
  get left(): number {
    return this.points[0].x;
  }
  //------------------------------------------------------------------------------------
  get top(): number {
    return this.points[0].y;
  }
  //------------------------------------------------------------------------------------
  get right(): number {
    return this.points[1].x;
  }
  //------------------------------------------------------------------------------------
  get bottom(): number {
    return this.points[2].y;
  }
  //------------------------------------------------------------------------------------
  get width(): number {
    return Math.abs(this.right - this.left);
  }
  //------------------------------------------------------------------------------------
  get height(): number {
    return Math.abs(this.bottom - this.top);
  }
  //------------------------------------------------------------------------------------
  get centerX(): number {
    return (this.points[1].x + this.points[0].x) / 2;
  }
  //------------------------------------------------------------------------------------
  get centerY(): number {
    return (this.points[0].y + this.points[2].y) / 2;
  }
  //------------------------------------------------------------------------------------
  get domRect(): DOMRect {
    return new DOMRect(this.left, this.top, this.width, this.height);
  }
  //------------------------------------------------------------------------------------
  get asString(): string {
    return `[l: ${this.left}, t: ${this.top}, b: ${this.bottom}, r: ${this.right}, w: ${this.width}, h: ${this.height}]`;
  }
  //------------------------------------------------------------------------------------
  clientToImage(mx: DOMMatrix, scale: number): Rect {
    //console.log("clientToImage.mx:", mx);
    let tempRect = new Rect(0, 0, 0, 0);
    for (let i = 0; i < tempRect.points.length; i++) {
      let p: Point = this.points[i].clientToImage(mx, scale);
      let index = p.getIndexInRect(mx.inverse());
      tempRect.points[index].x = p.x;
      tempRect.points[index].y = p.y;
    }
    //console.log("clientToImage.return:", tempRect);
    return tempRect;
  };
  //------------------------------------------------------------------------------------
  clientToOffscreen(mx: DOMMatrix, scale: number): Rect {
    let tempRect = new Rect(0, 0, 0, 0);
    for (let i = 0; i < tempRect.points.length; i++) {
      let p: Point = this.points[i].clientToOffscreen(mx, scale);
      let index = p.getIndexInRect(mx.inverse());
      tempRect.points[index].x = p.x;
      tempRect.points[index].y = p.y;
    }
    return tempRect;
  };
  //------------------------------------------------------------------------------------
  imageToClient(imageMX: DOMMatrix, scale: number): Rect {
    let tempRect = new Rect(0, 0, 0, 0);
    for (let i = 0; i < tempRect.points.length; i++) {
      let p: Point = this.points[i].imageToClient(imageMX, scale);
      let index = p.getIndexInRect(imageMX);
      tempRect.points[index].x = p.x;
      tempRect.points[index].y = p.y;
    }
    return tempRect;
  };
  //------------------------------------------------------------------------------------
  imageToCanvas(mx: DOMMatrix, scale: number): Rect {
    let resultRect = new Rect(0, 0, 0, 0);
    for (let i = 0; i < resultRect.points.length; i++) {
      let p: Point = this.points[i].imageToCanvas(mx, scale);
      let index = p.getIndexInRect(mx);
      resultRect.points[i].x = p.x;
      resultRect.points[i].y = p.y;
    }
    return resultRect;
  };
  //------------------------------------------------------------------------------------
  offscreenToCanvas(mx: DOMMatrix, scale: number): Rect {
    let resultRect = new Rect(0, 0, 0, 0);
    for (let i = 0; i < resultRect.points.length; i++) {
      let p: Point = this.points[i].offscreenToCanvas(mx, scale);
      let index = p.getIndexInRect(mx);
      resultRect.points[i].x = p.x;
      resultRect.points[i].y = p.y;
    }
    return resultRect;
  };
  //------------------------------------------------------------------------------------
  clientToCanvas(mx: DOMMatrix, scale: number): Rect {
    let tl = new DOMPoint(this.left * scale, this.top * scale).matrixTransform(mx);
    let rb = new DOMPoint(this.right * scale, this.bottom * scale).matrixTransform(mx);
    let tempRect = new Rect(tl.x, tl.y, rb.x, rb.y);
    let resultRect = new Rect(tl.x, tl.y, rb.x, rb.y);
    for (let i = 0; i < tempRect.points.length; i++) {
      let p: Point = tempRect.points[i];
      let index = p.getIndexInRect(mx);
      resultRect.points[index].x = p.x;
      resultRect.points[index].y = p.y;
    }
    return resultRect;
  };
  //------------------------------------------------------------------------------------
  checkBoundsExceedingR(source: Rect) {
    if (this.left < source.left) {
      this.points[0].x = source.left;
      this.points[3].x = source.left;
    }
    if (this.right > source.right) {
      this.points[1].x = source.right;
      this.points[2].x = source.right;
    }
    if (this.top < source.top) {
      this.points[0].y = source.top;
      this.points[1].y = source.top;
    }
    if (this.bottom > source.bottom) {
      this.points[2].y = source.bottom;
      this.points[3].y = source.bottom;
    }
  }
};
//------------------------------------------------------------------------------------
export class CropStrip extends DOMRect {
  cropDirection: ECropDirection;
  cursor: string;
  constructor(cropDirection: ECropDirection, left: number, top: number, width: number, height: number) {
    super(left, top, width, height);
    this.cropDirection = cropDirection;
    switch (cropDirection) {
      case ECropDirection.NorthWest:
        this.cursor = 'nwse-resize';
        break;
      case ECropDirection.North:
        this.cursor = 'ns-resize';
        break;
      case ECropDirection.NorthEast:
        this.cursor = 'nesw-resize';
        break;
      case ECropDirection.East:
        this.cursor = 'ew-resize';
        break;
      case ECropDirection.SouthEast:
        this.cursor = 'nwse-resize';
        break;
      case ECropDirection.South:
        this.cursor = 'ns-resize';
        break;
      case ECropDirection.SouthWest:
        this.cursor = 'nesw-resize';
        break;
      case ECropDirection.West:
        this.cursor = 'ew-resize';
        break;
      case ECropDirection.Move:
        this.cursor = 'move';
        break;
      default:
        this.cursor = '';
        break;
    }
  }
  pointInStrip(x: number, y: number): boolean {
    return x >= this.left && x <= this.right && y >= this.top && y <= this.bottom;
  }
}
//------------------------------------------------------------------------------------
export class CursorStrips {
  stripArray: CropStrip[];
  static screenType: ScreenType;
  moveStrip: CropStrip;
  //------------------------------------------------------------------------------------
  constructor(clientRect: DOMRect) {
    let d: number = CursorStrips.screenType == ScreenType.Desktop ? 16 : 32;
    let d2 = d * 2;
    this.moveStrip = new CropStrip(ECropDirection.Move, clientRect.left + d, clientRect.top + d, clientRect.width - d2, clientRect.height - d2);
    this.stripArray = [
      new CropStrip(ECropDirection.NorthWest, clientRect.left - d, clientRect.top - d, d2, d2),
      new CropStrip(ECropDirection.North, clientRect.left + d, clientRect.top - d, clientRect.width - d2, d2),
      new CropStrip(ECropDirection.NorthEast, clientRect.right - d, clientRect.top - d, d2, d2),
      new CropStrip(ECropDirection.East, clientRect.right - d, clientRect.top + d, d2, clientRect.height - d2),
      new CropStrip(ECropDirection.SouthEast, clientRect.right - d, clientRect.bottom - d, d2, d2),
      new CropStrip(ECropDirection.South, clientRect.left + d, clientRect.bottom - d, clientRect.width - d2, d2),
      new CropStrip(ECropDirection.SouthWest, clientRect.left - d, clientRect.bottom - d, d2, d2),
      new CropStrip(ECropDirection.West, clientRect.left - d, clientRect.top + d, d2, clientRect.height - d2),
      this.moveStrip,
    ];
  }
  //------------------------------------------------------------------------------------
  getStripByPoint(offsetX: number, offsetY: number): CropStrip | undefined {
    for (let i = 0; i < this.stripArray.length; i++) {
      let cs = this.stripArray[i];
      if (cs.pointInStrip(offsetX, offsetY)) {
        return cs;
      }
    }
    return undefined;
  }
  //------------------------------------------------------------------------------------
  drawStrips(ctx: CanvasRenderingContext2D) {
    for (let i = 0; i < this.stripArray.length; i++) {
      let cs = this.stripArray[i];
      ctx.strokeRect(cs.left, cs.top, cs.width, cs.height);
    }
  }
}
//------------------------------------------------------------------------------------
export class ImageCrop {
  clientRect: Rect;
  imageRect: Rect;
  strips: CursorStrips;
  cropStart?: TCropStart;
  cropStrip?: CropStrip;
  moving: boolean = false;
  changedByUser: boolean = false;
  //------------------------------------------------------------------------------------
  constructor(
    imageRect: Rect,
    clientRect: Rect) {
    this.clientRect = clientRect;
    this.imageRect = imageRect;
    this.strips = new CursorStrips(clientRect.domRect);
  }
  //------------------------------------------------------------------------------------
  clone(imageRect?: Rect, clientRect?: Rect): ImageCrop {
    let crop = new ImageCrop(
      imageRect ? imageRect : this.imageRect,
      clientRect ? clientRect : this.clientRect
    );
    crop.cropStart = this.cropStart;
    crop.cropStrip = this.cropStrip;
    crop.moving = this.moving;
    crop.changedByUser = this.changedByUser;
    return crop;
  }
  //------------------------------------------------------------------------------------
  static initCrop(rect: DOMRect, matrix: DOMMatrix, scale: number): ImageCrop {
    let w = rect.width;
    let h = rect.height;
    let indentX: number = Math.round(w / 20);
    let indentY: number = Math.round(h / 20);
    if (indentX < indentY)
      indentY = indentX;
    else if (indentY < indentX)
      indentX = indentY;
    let clientRect = new Rect(indentX, indentY, w - indentX, h - indentY);
    let cropImageRect = clientRect.clientToImage(matrix, scale);
    let crop: ImageCrop = new ImageCrop(cropImageRect, clientRect);
    crop.cropStart = {
      clientX: indentX,
      clientY: indentY,
    };
    return crop;
  }
  //------------------------------------------------------------------------------------
  newClientRectOnResize(imageMX: DOMMatrix, scale: number) {
    //console.log("newClientRectOnResize.scale:", scale);
    //console.log("newClientRectOnResize.before:", this.clientRect);
    this.clientRect = this.imageRect.imageToClient(imageMX, scale);
    //console.log("newClientRectOnResize.after:", this.clientRect);
    this.strips = new CursorStrips(this.clientRect.domRect);
  }
};
//------------------------------------------------------------------------------------
export type TCropStart = {
  clientX: number;
  clientY: number;
  clientRectSnap?: Rect;
}
//---------------------------------------------------------------------------------------
class MovePoints {
  startX1: number = 0;
  startX2: number = 0;
  startY1: number = 0;
  startY2: number = 0;
  moveX1: number = 0;
  moveX2: number = 0;
  moveY1: number = 0;
  moveY2: number = 0;
  startCenterX: number = 0;
  startCenterY: number = 0;

  setStartingPoint(x1: number, x2: number, y1: number, y2: number, cx: number, cy: number) {
    this.startX1 = this.moveX1 = x1;
    this.startX2 = this.moveX2 = x2;
    this.startY1 = this.moveY1 = y1;
    this.startY2 = this.moveY2 = y2;
    this.startCenterX = cx;
    this.startCenterY = cy;
  }

  get resizeX(): number {
    return Math.abs(this.startX2 - this.startX1) - Math.abs(this.moveX2 - this.moveX1);
  }
  get resizeY(): number {
    return Math.abs(this.startY2 - this.startY1) - Math.abs(this.moveY2 - this.moveY1);
  }

  get shiftX(): number {
    return this.startCenterX + this.moveMeanX - this.startMeanX;
  }
  get shiftY(): number {
    return this.startCenterY + this.moveMeanY - this.startMeanY;
  }

  get startMeanX(): number {
    return (this.startX2 + this.startX1) / 2;
  }
  get startMeanY(): number {
    return (this.startY2 + this.startY1) / 2;
  }

  get moveMeanX(): number {
    return (this.moveX2 + this.moveX1) / 2;
  }
  get moveMeanY(): number {
    return (this.moveY2 + this.moveY1) / 2;
  }
}
//---------------------------------------------------------------------------------------
class OverlaySize {
  rect!: DOMRect;
  scale: number = 1;
  state!: TCanvasState;
  matrix!: DOMMatrix;
  private _justCreated: boolean = true;
  //------------------------------------------------------------------------------------
  constructor(backCanvas: HTMLCanvasElement, transform: ImageTransformation) {
    let rect = backCanvas.getBoundingClientRect();
    let state = transform.getCanvasState(backCanvas);
    this.check(rect, transform, state);
    this._justCreated = true;
  }
  //------------------------------------------------------------------------------------
  get width(): number {
    return this.rect?.width || 1;
  }
  get height(): number {
    return this.rect?.height || 1;
  }
  //------------------------------------------------------------------------------------
  check(rect: DOMRect, transform: ImageTransformation, state: TCanvasState) {
    if (this.rect && this.rect.width == rect.width && this.rect.height == rect.height) {
      if (this._justCreated) {
        this._justCreated = false;
        return true;
      }
      return false;
    }
    this.rect = rect;
    Point.clientWidth = rect.width;
    Point.clientHeight = rect.height;
    let wr = transform.getWidthRotated();
    this.scale = wr / this.width;
    this.state = state;
    this.matrix = DOMMatrix.fromMatrix(state.matrix);
    this.matrix.e /= this.scale;
    this.matrix.f /= this.scale;
    return true;
  }
}
//---------------------------------------------------------------------------------------
export type CropEvent = React.PointerEvent<HTMLCanvasElement>;
//---------------------------------------------------------------------------------------
class CropEvents {
  startEvents: CropEvent[] = [];
  //moveEvents: CropEvent[] = [];
  // extraCropEvents - to filter additional finger touches during crop and do mot react to it
  extraEvents: CropEvent[] = [];
  //-------------------------------------------------------------------------------------
  get count() {
    return this.startEvents.length;
  }
  //-------------------------------------------------------------------------------------
  isExtraEvent(e: CropEvent): boolean {
    return this.extraEvents.findIndex(ce => ce.nativeEvent.pointerId == e.nativeEvent.pointerId) >= 0;
  }
  //-------------------------------------------------------------------------------------
  addEvent(e: CropEvent): boolean {
    // check if this is extra event
    if (this.startEvents.length == 2) {
      if (!this.extraEvents.find(ce => ce.nativeEvent.pointerId == e.nativeEvent.pointerId)) {
        this.extraEvents.push(e);
        return false;
      }
    }
    if (!this.startEvents.find(ce => ce.nativeEvent.pointerId == e.nativeEvent.pointerId)) {
      this.startEvents.push(e);
      //this.moveEvents.push(e);
    }
    return true;
  }
  //-------------------------------------------------------------------------------------
  removeEvent(e: CropEvent): boolean {
    let i = this.startEvents.findIndex(ce => ce.nativeEvent.pointerId == e.nativeEvent.pointerId);
    if (i >= 0 && i < this.startEvents.length) {
      this.startEvents.splice(i, 1);
      //this.moveEvents.splice(i, 1);
      return true;
    }
    return false;
  }
  //-------------------------------------------------------------------------------------
  removeExtraEvent(e: CropEvent): boolean {
    let i = this.extraEvents.findIndex(ce => ce.nativeEvent.pointerId == e.nativeEvent.pointerId);
    if (i >= 0 && i < this.extraEvents.length) {
      this.extraEvents.splice(i, 1);
      return true;
    }
    return false;
  }
}
//---------------------------------------------------------------------------------------
export function useCropEvents(
  backCanvas: HTMLCanvasElement,
  transform: ImageTransformation
): [OverlaySize, MovePoints, CropEvents] {
  const size = useRef(new OverlaySize(backCanvas, transform));
  const movePoints = useRef(new MovePoints());
  const cropEvents = useRef(new CropEvents());
  return [size.current, movePoints.current, cropEvents.current];
}
//---------------------------------------------------------------------------------------
export class OffscreenImage {
  bitmap: HTMLImageElement;
  offscreen: ImageBitmap;
  constructor(bitmap: HTMLImageElement, offscreen: ImageBitmap) {
    this.bitmap = bitmap;
    this.offscreen = offscreen;
  }
  //---------------------------------------------------------------------------------------
  changeNeeded(bitmap: HTMLImageElement, width: number, height: number): boolean {
    if (bitmap != this.bitmap) {
      return true;
    }
    if (width != this.offscreen.width) {
      return true;
    }
    if (height != this.offscreen.height) {
      return true;
    }
    return false;
  }
}
//---------------------------------------------------------------------------------------
export function drawHandle(ctx: CanvasRenderingContext2D, x: number, y: number, size: number) {
  ctx.fillRect(x - handleSemiSize, y - handleSemiSize, handleSize, handleSize);
};
//---------------------------------------------------------------------------------------
export function redrawTopCanvas(
  cvs?: HTMLCanvasElement | null,
  crop?: ImageCrop) {

  let ctx = cvs?.getContext('2d');
  if (!cvs || !ctx)
    return;

  let br = cvs.getBoundingClientRect();
  ctx.clearRect(0, 0, br.width, br.height);

  if (!crop)
    return;

  ctx.save();
  try {
    ctx.strokeStyle = "white";
    ctx.fillStyle = "white";
    ctx.lineWidth = 1;
    ctx.strokeRect(
      crop.clientRect.left,
      crop.clientRect.top,
      crop.clientRect.width,
      crop.clientRect.height
    );
    if (crop.cropStrip) {
      ctx.lineWidth = handleSemiSize;
      ctx.beginPath();
      switch (crop.cropStrip.cropDirection) {
        case ECropDirection.NorthWest:
          ctx.strokeStyle = "lightseagreen";
          ctx.moveTo(crop.clientRect.left, crop.clientRect.bottom);
          ctx.lineTo(crop.clientRect.left, crop.clientRect.top);
          ctx.lineTo(crop.clientRect.right, crop.clientRect.top);
          break;
        case ECropDirection.North:
          ctx.strokeStyle = "lightseagreen";
          ctx.moveTo(crop.clientRect.left, crop.clientRect.top);
          ctx.lineTo(crop.clientRect.right, crop.clientRect.top);
          break;
        case ECropDirection.NorthEast:
          ctx.moveTo(crop.clientRect.left, crop.clientRect.top);
          ctx.lineTo(crop.clientRect.right, crop.clientRect.top);
          ctx.lineTo(crop.clientRect.right, crop.clientRect.bottom);
          break;
        case ECropDirection.East:
          ctx.moveTo(crop.clientRect.right, crop.clientRect.top);
          ctx.lineTo(crop.clientRect.right, crop.clientRect.bottom);
          break;
        case ECropDirection.SouthEast:
          ctx.moveTo(crop.clientRect.left, crop.clientRect.bottom);
          ctx.lineTo(crop.clientRect.right, crop.clientRect.bottom);
          ctx.lineTo(crop.clientRect.right, crop.clientRect.top);
          break;
        case ECropDirection.South:
          ctx.moveTo(crop.clientRect.right, crop.clientRect.bottom);
          ctx.lineTo(crop.clientRect.left, crop.clientRect.bottom);
          break;
        case ECropDirection.SouthWest:
          ctx.moveTo(crop.clientRect.right, crop.clientRect.bottom);
          ctx.lineTo(crop.clientRect.left, crop.clientRect.bottom);
          ctx.lineTo(crop.clientRect.left, crop.clientRect.top);
          break;
        case ECropDirection.West:
          ctx.moveTo(crop.clientRect.left, crop.clientRect.bottom);
          ctx.lineTo(crop.clientRect.left, crop.clientRect.top);
          break;
        case ECropDirection.Move:
          ctx.rect(
            crop.clientRect.left,
            crop.clientRect.top,
            crop.clientRect.width,
            crop.clientRect.height
          );
          break;
      }
      ctx.stroke();
    }
    drawHandle(ctx, crop.clientRect.left, crop.clientRect.top, 11);
    drawHandle(ctx, crop.clientRect.right, crop.clientRect.top, 11);
    drawHandle(ctx, crop.clientRect.right, crop.clientRect.bottom, 11);
    drawHandle(ctx, crop.clientRect.left, crop.clientRect.bottom, 11);
  }
  finally {
    ctx.restore();
  }
  drawGrid(ctx, crop);
}
//---------------------------------------------------------------------------------------
export function redrawMidCanvasMatrix(
  mx: DOMMatrix,
  scale: number,
  cvs?: HTMLCanvasElement | null,
  crop?: ImageCrop,
  offscreen?: ImageBitmap) {

  let ctx = cvs?.getContext('2d');

  if (!cvs || !ctx || !crop || !offscreen)
    return;

  //console.log("redrawMidCanvasMatrix");
  ctx.save();
  try {
    let br = cvs.getBoundingClientRect();
    ctx.clearRect(0, 0, br.width, br.height);
    ctx.setTransform(mx);
    let imgRect = crop.clientRect.clientToOffscreen(mx, scale);
    let cvsRect = imgRect.offscreenToCanvas(mx, scale);
    // console.log("redrawMidCanvasMatrix.mx:", mx);
    // console.log("redrawMidCanvasMatrix.BoundingClientRect:", br);
    // console.log("redrawMidCanvasMatrix.imgRect(clientToOffscreen)):", imgRect);
    // console.log("redrawMidCanvasMatrix.imgRect.offscreenToCanvas:", cvsRect);
    // console.log("Point.imageRect:", Point.imageRect);
    // console.log("Point.ClientWidth:", Point.clientWidth);
    // console.log("Point.ClientHeight:", Point.clientHeight);
    ctx.drawImage(
      offscreen,
      imgRect.left, imgRect.top, imgRect.width, imgRect.height,
      cvsRect.left, cvsRect.top, cvsRect.width, cvsRect.height
    );
  }
  finally {
    ctx.restore();
  }
}
//---------------------------------------------------------------------------------------
export function testMidCanvasIPad(
  mx: DOMMatrix,
  scale: number,
  cvs?: HTMLCanvasElement | null,
  crop?: ImageCrop,
  offscreen?: ImageBitmap) {

  let ctx = cvs?.getContext('2d');

  if (!cvs || !ctx)
    return;

  ctx.save();
  try {
    let br = cvs.getBoundingClientRect();
    //ctx.clearRect(0, 0, br.width, br.height);
    ctx.setTransform(mx);
    if (crop) {
      if (offscreen) {
        let imgRect = crop.clientRect.clientToOffscreen(mx, scale);
        let cvsRect = imgRect.offscreenToCanvas(mx, scale);
        let mxs: string = `mx: [a:${mx.a}, b:${mx.b}, c:${mx.d}, b:${mx.d}, e:${mx.e}, f:${mx.f}]`;
        let brs: string = `br: [t:${br.top}, l:${br.left}, b:${br.bottom}, r:${br.right}, w:${br.width}, h:${br.height}]`;
        let imgRectS: string = `imgRect: ${imgRect.asString}`;
        let cvsRectS: string = `cvsRect: ${cvsRect.asString}`;
        let totalS: string = `redrawMidCanvasMatrix.totalS:\n${mxs}\n${brs}\n${imgRectS}\n${cvsRectS}`;
        //console.log(totalS);
      }
    }
  }
  finally {
    ctx.restore();
  }
}

function drawGrid(ctx: CanvasRenderingContext2D, crop: ImageCrop) {
  ctx.save();
  try {
    ctx.strokeStyle = "white";//"silver";
    ctx.lineWidth = 1;
    let w3 = crop.clientRect.width / 3;
    let h3 = crop.clientRect.height / 3;
    ctx.beginPath();
    ctx.moveTo(crop.clientRect.left, crop.clientRect.top + h3);
    ctx.lineTo(crop.clientRect.right, crop.clientRect.top + h3);
    ctx.moveTo(crop.clientRect.left, crop.clientRect.top + 2 * h3);
    ctx.lineTo(crop.clientRect.right, crop.clientRect.top + 2 * h3);
    ctx.moveTo(crop.clientRect.left + w3, crop.clientRect.top);
    ctx.lineTo(crop.clientRect.left + w3, crop.clientRect.bottom);
    ctx.moveTo(crop.clientRect.left + 2 * w3, crop.clientRect.top);
    ctx.lineTo(crop.clientRect.left + 2 * w3, crop.clientRect.bottom);
    ctx.stroke();
  }
  finally {
    ctx.restore();
  }
}
