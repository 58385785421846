import { useMsal } from '@azure/msal-react';
import styles from './SubDictionariesList.module.css';
import { useContext, useEffect, useState } from 'react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import { AppUiContextStore } from '../../../../../context/app-ui-context/AppUiContextProvider';
import useNavigateWithContext from '../../../../../hooks/useNavigateWithContext';
import { useAbortController } from '../../../../../hooks/useAbortController';
import ConsoleContent from '../../../../common/console-layout/console-content/ConsoleContent';
import WikiSpinner from '../../../../app-layout/spinner/wikiSpinner';
import { IUiOption } from '../../../../common/options/IUiOption';
import { optionIdDictionariesList, pathDictionaries } from '../BusinessTagsConsole';
import { TUiBreadcrumb } from '../../../../common/breadcrumbs-trail/breadcrumb/TUiBreadcrumb';
import { pathAdminConsole, pathAdministratorConsole, pathBusinessTagsConsole } from '../../../AdminConsole';
import { getCustomDictionariesListAsync } from '../functions/getCustomDictionariesListAsync';
import { IDictionariesListItem } from '../../../common/dictionary-draft-models/IDictionariesListItem';
import { IUiSubDictionariesList } from './IUiSubDictionariesList';
import GetHint from '../../../../common/functions/GetHint';
import FormOptions from '../../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../../common/menu/menu-content/TUiMenuContent';
import DictionariesListItem from './list-item/DictionariesListItem';
import { pathDictionaryNew } from '../sub-dictionary-editor/SubDictionaryEditor';

interface IProps {
  ui: IUiSubDictionariesList;
  optionsForBreadcrumb?: IUiOption[];
}

export default function SubDictionariesList(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const navigate = useNavigateWithContext();
  const [dictionaries, setDictionaries] = useState<IDictionariesListItem[]>();
  const [isLoading, setIsLoading] = useState(true);
  const abortController = useAbortController("SubDictionariesList");
  //--------------------------------------------------------------------------- Data, breadcrumb, cleanup
  useEffect(() => {
    let controller = abortController.newController("getDictionariesListAsync");
    getCustomDictionariesListAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal
    }).then(items => {
      setDictionaries(items);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    //-------------------------------------------------------------------------
    const option = props.optionsForBreadcrumb?.find(o => o.id == optionIdDictionariesList);
    if (option) {
      const breadcrumb: TUiBreadcrumb = {
        id: option.id,
        caption: option.caption,
        path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathBusinessTagsConsole}/${pathDictionaries}`
      };
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: breadcrumb });
    };
    //-------------------------------------------------------------------------
    return () => {
      abortController.abortOnUnmount();
    };
  }, []);
  //---------------------------------------------------------------------------
  const onDictionarySelect = (draftId: string) => {
    navigate(`${pathAdminConsole}/${pathAdministratorConsole}/${pathBusinessTagsConsole}/${pathDictionaries}/${draftId}`);
  };
  //---------------------------------------------------------------------------
  const onDictionaryCreate = () => {
    navigate(`${pathAdminConsole}/${pathAdministratorConsole}/${pathBusinessTagsConsole}/${pathDictionaries}/${pathDictionaryNew}`);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionClose":
        navigate(`${pathAdminConsole}/${pathAdministratorConsole}`);
        break;
      case "BusinessTagsDictionariesList.MenuContent.OptionCreate":
        onDictionaryCreate();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const listElements = dictionaries?.map(item => (
    <DictionariesListItem
      key={item.dictionaryId}
      data={item}
      onSelect={onDictionarySelect}
    />
  ));
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionClose":
        updatedOptions.push({ ...option, iconId: "close" });
        break;
      case "BusinessTagsDictionariesList.MenuContent.OptionCreate":
        updatedOptions.push({ ...option, iconId: "plus" });
        break;
    };
  });
  const listOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      formOptions={listOptions}
      containerWidthPercent={70}
    >
      <WikiSpinner show={isLoading} />
      <div className={styles.container}>
        {listElements}
      </div>
    </ConsoleContent>
  );
}