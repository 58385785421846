import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { ReactNode, useEffect, useLayoutEffect, useReducer, useRef } from "react";
import { AppAuthContextStore, IAppAuthContext } from "./AppAuthContext";
import { AppAuthContextReducer } from "./AppAuthContextReducer";
import { getUserLocally, getUserRemotely, updateUserPromise } from "./user";
import { TImageData } from "../../components/common/image-editor/TImageData";
import { ServiceWorkerContextProvider } from "../service-worker-context/ServiceWorkerContextProvider";
import { useAbortController } from "../../hooks/useAbortController";
import getUserGeopositionAsync from "./getUserGeoPositionAsync";
import { setInterval } from "timers/promises";
import { Navigate, useNavigate } from "react-router";
import useNavigateWithContext from "../../hooks/useNavigateWithContext";

interface Props {
  initialState: IAppAuthContext;
  children?: ReactNode;
}

export default function AppAuthContextProvider(props: Props) {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [appAuthContext, appAuthContextDispatch] = useReducer(
    AppAuthContextReducer,
    props.initialState
  );
  const abortController = useAbortController("AppAuthContextProvider");
  //const tokenChannel = useRef<MessageChannel>(); // for service worker
  //--------------------------------------------------------------------------- User geoposition
  useEffect(() => {
    let controller = abortController.newController("getCurrentGeoLocation");
    getUserGeopositionAsync({abortController: controller}).then(position => {
      appAuthContextDispatch({ type: "SetUserGeoposition", position: position });
    });
    //-----------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //---------------------------------------------------------------------------
  useLayoutEffect(() => {
    console.log("trying to sort user out...");
    // Determine whether user is already authenticated
    const currentUserAccount = instance.getActiveAccount();
    //console.log(currentUserAccount);
    //console.warn("AppAuthContextProvider.useEffect[isAuthenticated]:", isAuthenticated);
    //-----------------------------------------------------------------------  
    if (!currentUserAccount) {
      console.warn("Active user is not set!");
      return;
    }
    //-----------------------------------------------------------------------  
    console.log(`User is already authenticated: ${currentUserAccount.localAccountId}`);
    //console.log(`appAuthContext:`, appAuthContext);
    // User is authenticated. Handle user profile data
    if (appAuthContext?.user) {
      if (appAuthContext.user.userIdentity.id === currentUserAccount.localAccountId) {
        // User context is already set
        console.log(`User context is already set`);
        return;
      };
    };
    //-----------------------------------------------------------------------  
    // By default use local user profile
    // So the home page will not retrieve user profile from API on each reload
    // and hopefully will not interfere with silent acquiring token by MSAL when user session is restored (in case of KMSI)
    console.log("Trying to use local user profile...");
    const localUser = getUserLocally(currentUserAccount.localAccountId);
    if (localUser) {
      //console.log("AppAuthContextProvider.useEffect[isAuthenticated].localUser.activeAccount:", instance.getActiveAccount());
      appAuthContextDispatch({ type: "SetUser", value: localUser });
    } else if (props.initialState.config) {
      //---------------------------------------------------------------------
      // Otherwise retrieve user data from API
      console.log("Trying to get user from api...");
      //console.warn("AppAuthContextProvider.useEffect[isAuthenticated]: call to getUserRemotely");
      let controller = abortController.newController("getUserRemotely");
      getUserRemotely(instance, props.initialState.config, controller.signal)
        .then(user => {
          let newUser = instance.getActiveAccount()?.idTokenClaims?.["newUser"];
          if (newUser) {
            user.preferredLanguage = navigator.language;
            user.preferredLanguages = navigator.languages.map(x => x);
            updateUserPromise(instance, user, props.initialState.config);
          }
          console.log("getUserRemotely.User:", user);
          !controller.aborted && appAuthContextDispatch({ type: "SetUser", value: user });
        })
        .catch((error) => {
          console.error("Cannot retrieve user profile both locally and remotely. Error: " + error);
        });
    };
  }, [isAuthenticated]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    //console.log("AppAuthContextProvider.useEffect[instance, config, user]:",
    //instance, appAuthContext.config, appAuthContext.user);
    if (!instance || !appAuthContext.config || !appAuthContext.user) {
      return;
    }
    //console.log("AppAuthContextProvider.useEffect[instance].activeAccount:", instance.getActiveAccount());
    TImageData.config = appAuthContext.config;
    TImageData.instance = instance;
    //https://felixgerschau.com/how-to-communicate-with-service-workers/
    // if (!tokenChannel.current) {
    //   //console.log("AppAuthContextProvider.useEffect[instance, config, user]: no token channel, create one...");
    //   tokenChannel.current = new MessageChannel();
    //   // First we initialize the channel by sending
    //   // the port to the Service Worker (this also
    //   // transfers the ownership of the port)
    //   navigator.serviceWorker.controller?.postMessage({
    //     messageType: 'messageChannelOpen',
    //   }, [tokenChannel.current.port2]);
    // }
    // else {
    //   //console.log("AppAuthContextProvider.useEffect[instance, config, user]: token channel exists:", tokenChannel.current);
    // }

    // // Listen to the response
    // tokenChannel.current.port1.onmessage = (event) => {
    //   // Print the result
    //   console.log("AppAuthContextProvider.tokenChannel. Message from SW. Event:", event);
    //   const userAccount = instance.getActiveAccount();
    //   if (userAccount && appAuthContext.config) {
    //     console.log("AppAuthContextProvider.tokenChannel. Requesting msal token...");
    //     const accessTokenRequest = {
    //       scopes: appAuthContext.config.apiScopes,
    //       account: userAccount,
    //       forceRefresh: true,
    //       redirectUri: "/" //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#block_iframe_reload
    //     };
    //     instance.acquireTokenSilent(accessTokenRequest as SilentRequest)
    //       .then((accessToken) => {
    //         console.log("AppAuthContextProvider.tokenChannel. Token acquired. Sending to SW...", accessToken.accessToken);
    //         tokenChannel.current?.port1.postMessage(accessToken.accessToken);
    //       })
    //   }
    //   else {
    //     console.log("userAccount or appAuthContext.config is undefined");
    //   }
    // }
    return (() => {
      //console.log("AppAuthContextProvider.useEffect[instance, appAuthContext.config].return");
      //navigator.serviceWorker.controller?.postMessage({ messageType: 'tokenChannelClose' });
      //tokenChannel.current?.port1.close();
    });
  }, [instance, appAuthContext.config, appAuthContext.user]);
  //---------------------------------------------------------------------------
  //let userType = TUserType.fromLS("AppAuthContextProvider");
  let redirectPage = location.pathname == '/redirect';
  let userExists = appAuthContext.user != undefined && appAuthContext.user != null;
  let renderChildren = true;
  //if (userType && userType.userType === EUserType.Authenticated)
  //renderChildren = userExists;
  //let renderChildren = !redirectPage;
  /*let renderChildren = !redirectPage && 
    (userType == EUserType.Anonymous || userExists);*/
  //console.warn("appAuthContext.user/redirect:", userType, userExists, redirectPage);
  if (!renderChildren) {
    //let currentNavEntries = window.navigation.entries();
  }
  //---------------------------------------------------------------------------
  return (
    <AppAuthContextStore.Provider
      value={{ appAuthContext, appAuthContextDispatch }}
    >
      <ServiceWorkerContextProvider
        user={appAuthContext.user}
        config={appAuthContext.config}
        instance={instance}
      >
        {renderChildren && props.children}
      </ServiceWorkerContextProvider>
    </AppAuthContextStore.Provider>
  );
}
