import { TUiMenuContent } from "../../../common/menu/menu-content/TUiMenuContent";
import { IUiDictionaryEditorFilter } from "./dictionary-editor-filter/DictionaryEditorFilter";
import { IUiDictionaryEditorHeader } from "./dictionary-editor-header/DictionaryEditorHeader";

export interface IUiDictionaryEditor {
  headerUi: IUiDictionaryEditorHeader;
  filterUi: IUiDictionaryEditorFilter;
  confirmationDialogMenu: TUiMenuContent;
  optionsMenu: TUiMenuContent;
}

export const dictionaryEditorWrapperUi: IUiDictionaryEditor = {
  headerUi: {
    inputDictionaryId: {
      id: "",
      directoryId: "",
      elementType: "",
      caption: { en: "Dictionary Id" },
      hint: { en: "Dictionary Id" },
      disabled: true,
      visible: true,
      placeholder: undefined,
      defaultValue: undefined,
      validation: undefined
    },
    inputDraftLocale: {
      id: "",
      directoryId: "",
      caption: { en: "Draft Locale" },
      hint: undefined,
      availableValues: [{
        id: "en",
        name: { en: "English" },
        selected: false
      }, {
        id: "uk",
        name: { en: "Ukrainian" },
        selected: false
      }, {
        id: "ru",
        name: { en: "Russian" },
        selected: false
      }],
      defaultValue: "",
      placeholder: "",
      multiSelect: false,
      validation: undefined
    },
    inputSourceLocale: {
      id: "",
      directoryId: "",
      caption: { en: "Source Locale" },
      hint: undefined,
      availableValues: [{
        id: "i18n",
        name: { en: "System Id" },
        selected: false
      }, {
        id: "en",
        name: { en: "English" },
        selected: false
      }, {
        id: "uk",
        name: { en: "Ukrainian" },
        selected: false
      }, {
        id: "ru",
        name: { en: "Russian" },
        selected: false
      }],
      defaultValue: "",
      placeholder: "",
      multiSelect: false,
      validation: { required: { value: 1, errorMessageId: "", errorMessage: "" } }
    },
  },
  filterUi: {
    searchInput: {
      id: "BusinessTagsSearchInput",
      directoryId: "",
      elementType: "",
      disabled: false,
      visible: true,
      caption: {},
      hint: { en: "This input is for filtering tags tree" },
      placeholder: { en: "Type keyword to search..." },
      defaultValue: {},
      validation: undefined
    }
  },
  confirmationDialogMenu: {
    id: "",
    directoryId: "",
    elementType: "",
    visible: true,
    disabled: false,
    options: [{
      id: "optionOk",
      directoryId: "",
      elementType: "",
      index: 1,
      priorityLevel: 1,
      caption: { en: "Ok" },
      hint: null,
      iconFile: "",
      isDefault: false,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }, {
      id: "optionCancel",
      directoryId: "",
      elementType: "",
      index: 1,
      priorityLevel: 1,
      caption: { en: "Cancel" },
      hint: null,
      iconFile: "",
      isDefault: false,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }],
    optionGroups: []
  },
  optionsMenu: {
    id: "",
    directoryId: "",
    elementType: "",
    visible: true,
    disabled: false,
    options: [{
      id: "optionClose",
      directoryId: "",
      elementType: "",
      index: 1,
      priorityLevel: 1,
      caption: { en: "Close" },
      hint: null,
      iconFile: "close",
      isDefault: false,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }, {
      id: "optionDraftDelete",
      directoryId: "",
      elementType: "",
      index: 1,
      priorityLevel: 1,
      caption: { en: "Delete draft" },
      hint: null,
      iconFile: "delete",
      isDefault: false,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }, {
      id: "optionEnableEditMode",
      directoryId: "",
      elementType: "",
      index: 1,
      priorityLevel: 1,
      caption: { en: "Enable Edit Mode" },
      hint: null,
      iconFile: "pencil",
      isDefault: false,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }, {
      id: "optionDisableEditMode",
      directoryId: "",
      elementType: "",
      index: 1,
      priorityLevel: 1,
      caption: { en: "Exit Edit Mode" },
      hint: null,
      iconFile: "pencil",
      isDefault: false,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }, {
      id: "optionAdd",
      directoryId: "",
      elementType: "",
      index: 1,
      priorityLevel: 1,
      caption: { en: "Add Root Item" },
      hint: null,
      iconFile: "plus",
      isDefault: false,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }, {
      id: "optionPublish",
      directoryId: "",
      elementType: "",
      index: 1,
      priorityLevel: 1,
      caption: { en: "Publish" },
      hint: null,
      iconFile: "publish",
      isDefault: false,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }, {
      id: "optionSave",
      directoryId: "",
      elementType: "",
      index: 1,
      priorityLevel: 1,
      caption: { en: "Save Draft" },
      hint: null,
      iconFile: "check",
      isDefault: true,
      disabled: false,
      visible: true,
      canHideCaption: false,
      action: null
    }],
    optionGroups: []
  }
}