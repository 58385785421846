import { useContext, useEffect, useRef, useState } from "react";
import { BusinessSearchListItem } from "./business-search-card/BusinessSearchListItem";
import { BusinessSearchPager } from "./business-search-paging/BusinessSearchPager";
import { BusinessSearchResult } from "./BusinessSearchResult";
import WikiSpinner from "../../app-layout/spinner/wikiSpinner";
import { ListManagerContext } from "../../../context/list-manager-context/ListManagerContextProvider";
import ListColumnsManager from "../../common/lists/list-columns-manager/ListColumnsManager";
import { SearchColumnIds, initialSearchListColumns } from "./Filters";
import { EManagerMode, IFilterDynamicOptions } from "../../../context/list-manager-context/IListManagerContextData";
import { BusinessSearchCard } from "./business-search-card/BusinessSearchCard";
import styles from "./BusinessSearchList.module.css";
import { BusinessSearchSorter } from "./business-search-sorter/BusinessSearchSorter";
//------------------------------------------------------------------------------------
interface IProps {
  businessSearchResult?: BusinessSearchResult;
  onBusinessHover?: (id: string) => void;
  onScrollToTop: () => void;
}
//------------------------------------------------------------------------------------
const logging = false;
//------------------------------------------------------------------------------------
export default function BusinessSearchList(props: IProps) {
  const { listManagerContext, listManagerContextDispatch } = useContext(ListManagerContext);
  const [percents, setPercents] = useState<boolean>(false);
  const [businessesPerPage, setBusinessesPerPage] = useState(5);
  const [businessesForPage, setBusinessesForPage] = useState<BusinessSearchListItem[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBusinessId, setSelectedBusinessId] = useState<string>();
  const locales = useRef<string[]>();
  //-----------------------------------------------------------------------------------
  useEffect(() => {
    // Initialize list manager context
    logging && console.log("BusinessSearchList.useEffect[]");
    listManagerContextDispatch({
      type: "Initialize",
      configuration: {
        listId: "BusinessSearchList",
        managerMode: EManagerMode.Basic,
        listColumns: initialSearchListColumns
      }
    });
  }, []);
  //-[businessSearchResult]-----------------------------------------------------------
  useEffect(() => {
    logging && console.log("BusinessSearchList.useEffect[businessSearchResult]:", props.businessSearchResult);
    if (!props.businessSearchResult)
      return;

    props.businessSearchResult.businessesPerPage = businessesPerPage;
    if (props.businessSearchResult.isLocalesDiffer(locales.current)) {
      locales.current = props.businessSearchResult.locales
      let options = locales.current.map(locale => ({ id: locale, isDynamic: true }));
      logging && console.log("BusinessSearchList.useEffect[props.businessSearchResult, businessesPerPage].options:", options);
      const dynamicOptions: IFilterDynamicOptions[] = [{
        columnId: SearchColumnIds.locale.id,
        options: options
      }];
      listManagerContextDispatch({
        type: "AddDynamicFilterOptions",
        dynamicOptions: dynamicOptions
      });
    }
    else {
      resort(`useEffect[props.businessSearchResult, businessesPerPage], BP: [${businessesPerPage}]`);
    }
  }, [props.businessSearchResult, businessesPerPage]);
  //-[listManagerContext.lastAction]----------------------------
  useEffect(() => {
    //logging && console.log("useEffect[listManagerContext.lastAction]:", listManagerContext.lastAction);
    if (!props.businessSearchResult || !listManagerContext.lastAction)
      return;
    let source = `useEffect[lastAction]: [${listManagerContext.lastAction.type}]`;
    switch (listManagerContext.lastAction.type) {
      case "SetMode": {
        if (listManagerContext.managerMode == EManagerMode.Basic) {
          props.businessSearchResult.filterRemove();
        }
        resort(source);
      } break;
      case "RestoreDefaults":
      case "ClearFilter": {
        props.businessSearchResult.filterRemove();
        resort(source);
      } break;
      case "Initialize":
      case "AddDynamicFilterOptions":
      case "UpdateSorter":
      case "UpdateTwoColumns":
      case "UpdateFilterOption":
        resort(source);
        break;
    }
  }, [listManagerContext.lastAction]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    props.onScrollToTop();
  }, [businessesForPage]);
  //---------------------------------------------------------------------------
  const resort = (source?: string) => {
    logging && source && console.log("resort.source:", source);
    if (props.businessSearchResult) {
      switch (listManagerContext.managerMode) {
        case EManagerMode.Basic: {
          let column = listManagerContext.selectedSortColumn ?? listManagerContext.defaultSortColumn;
          //column && props.businessSearchResult.sortBasic(column);
          column && props.businessSearchResult.sortBasicV2(listManagerContext.sortSteps, column);
        } break;
        case EManagerMode.Advanced: {
          props.businessSearchResult.filterAndSort(listManagerContext);
        } break;
      }
      onPageSelect(0);
    }
  }
  //---------------------------------------------------------------------------
  const onPercent = () => {
    setPercents(!percents);
  }
  //---------------------------------------------------------------------------
  const setPageIndex = (index: number) => {
    if (props.businessSearchResult) {
      props.businessSearchResult.currentPageIndex = index;
      let businesses = props.businessSearchResult?.getBusinessesForPage();
      //logging && console.log(`setPageIndex[${index}]. setBusinessesForPage:`, businesses);
      setBusinessesForPage(businesses);
    }
  }
  //---------------------------------------------------------------------------
  const onPageSelect = (index: number) => {
    let businessIds = props.businessSearchResult?.getBusinessIdsForPageWithoutDetails(
      index, businessesPerPage);
    getBusinessDetails(index, businessIds);
  }
  //---------------------------------------------------------------------------
  const getBusinessDetails = (pageIndex: number, businessIds?: string[]) => {
    if (!businessIds) {
      return setPageIndex(pageIndex);
    }
    if (!props.businessSearchResult) {
      return console.error("Trying to get business details while businessSearchResult is undefined");
    }
    setIsLoading(true);
    props.businessSearchResult.queryBusinessDetails(businessIds)
      .then(() => {
        //logging && console.log("BusinessSearchList.getBusinessDetails.queryBusinessDetails.then", pageIndex);
        setPageIndex(pageIndex);
      })
      .catch(error => {
        console.error("BusinessSearchList.getBusinessDetails:", error);
      })
      .finally(() => {
        //logging && console.log("BusinessSearchList.getBusinessDetails.setIsLoading(false)");
        setIsLoading(false);
      })
  }
  //---------------------------------------------------------------------------
  const onBusinessSelect = (id: string) => {
    setSelectedBusinessId(id);
  }
  //---------------------------------------------------------------------------
  let items = businessesForPage?.map(item =>
    <BusinessSearchCard
      key={item.uiId}
      data={item}
      percents={percents}
      onHover={props.onBusinessHover}
    />
  );
  //---------------------------------------------------------------------------
  let countTotal = props.businessSearchResult?.items.length ?? 0;
  let countFiltered = props.businessSearchResult?.itemsFiltered.length ?? 0;
  let countPage = props.businessSearchResult?.getPageCount(businessesPerPage) ?? 0;
  //---------------------------------------------------------------------------
  //logging && console.log("BusinessSearchList.render:", businessesForPage?.map(b => b.businessId));
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <WikiSpinner show={isLoading} />
      <div className={styles.filter}>
        {listManagerContext.managerMode == EManagerMode.Advanced &&
          <ListColumnsManager
            numberTotal={countFiltered}
            numberFiltered={countFiltered <= businessesPerPage ? countFiltered : businessesPerPage}
            expandFilters={true}
            expandSorters={true}
            showBasicModeSwitch={true}
          />
        }
        {listManagerContext.managerMode == EManagerMode.Basic &&
          <BusinessSearchSorter
            numberFiltered={countFiltered <= businessesPerPage ? countFiltered : businessesPerPage}
            numberTotal={countTotal}
          />
        }
      </div>

      {items && items.length > 0 && props.businessSearchResult &&
        <BusinessSearchPager
          pageCount={countPage}
          maxPageCountToDisplay={100}
          selectedIndex={props.businessSearchResult.currentPageIndex}
          onPageSelect={onPageSelect}
        />}
      {items}
      {items && items.length > 0 && props.businessSearchResult &&
        <BusinessSearchPager
          pageCount={countPage}
          maxPageCountToDisplay={100}
          selectedIndex={props.businessSearchResult.currentPageIndex}
          onPageSelect={onPageSelect}
        />}
    </div>
  );
}