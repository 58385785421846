import { IUiInteractiveForm } from "../../../common/forms/IUiInteractiveForm";

export interface IUiUserList extends IUiInteractiveForm {

}

export const hardcodedUiUserList: IUiUserList = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: { en: "Users List" },
  hint: { en: "List of staff users: you can add new users and edit their roles and permissions" },
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "StaffUsersList.MenuContent.AddUser",
      directoryId: "",
      elementType: "",
      caption: { en: "Add User" },
      hint: { en: "Add User" }
    }]
  }
}