import styles from './CurrentLocationMarkerAdvanced.module.css';
import { useEffect, useLayoutEffect, useRef, useState } from "react";

// How to set accuracy circle: https://medium.com/100-days-in-kyoto-to-create-a-web-app-with-google/day-12-showing-user-location-on-embedded-google-maps-with-geolocation-api-and-react-a8ea40d1e891

interface ICurrentPosition {
  position: google.maps.LatLngLiteral;
  accuracy: number;
}

interface IProps {
  map: google.maps.Map;
  position: google.maps.LatLngLiteral | true; // If =true we need to determine position using browser API
  isEditable?: boolean;
}

export default function CurrentLocationMarkerAdvanced(props: IProps) {
  const marker = useRef<google.maps.marker.AdvancedMarkerElement>();
  const accuracyCircle = useRef<google.maps.Circle>();
  const [currentPosition, setCurrentPosition] = useState<ICurrentPosition>();
  //--------------------------------------------------------------------------- Current position
  useEffect(() => {
    // Create marker
    if (!marker.current) {
      const div = document.createElement('div');
      div.className = styles.dot;
      marker.current = new google.maps.marker.AdvancedMarkerElement({
        map: props.map,
        content: div,
        title: "Your current location"
      });
    }
    /*
    //-------------------------------------------------------------------------
    // Determine how we to get current position
    if (props.position == true) {
      // We have to get it from browser
      navigator.geolocation.getCurrentPosition(cp => {
        setCurrentPosition({
          position: { lat: cp.coords.latitude, lng: cp.coords.longitude },
          accuracy: cp.coords.accuracy
        });
      }, error => {
        console.error(`Unable to get current location: ${error}`);
      });
    } else {
      // We are provided with current position already
      setCurrentPosition({
        position: props.position,
        accuracy: 0
      });
    };
    */
  }, []);
  //---------------------------------------------------------------------------
  useEffect(() => {
    //-------------------------------------------------------------------------
    // Determine how we to get current position
    if (props.position == true) {
      // We have to get it from browser
      navigator.geolocation.getCurrentPosition(cp => {
        setCurrentPosition({
          position: { lat: cp.coords.latitude, lng: cp.coords.longitude },
          accuracy: cp.coords.accuracy
        });
      }, error => {
        console.error(`Unable to get current location: ${error}`);
      });
    } else {
      // We are provided with current position already
      setCurrentPosition({
        position: props.position,
        accuracy: 0
      });
    };
  }, [props.position]);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (marker.current && currentPosition?.position) {
      //console.log('current position', currentPosition?.position)
      // If marker is already exists, update the position
      marker.current.position = currentPosition.position;
      //console.log("useEffect[currentPosition].marker.current.position = ", marker.current?.position);
    };
    //-------------------------------------------------------------------------
    // Draw a circle to display accuracy radius
    if (currentPosition && currentPosition?.accuracy > 0) {
      // Accuracy is not a 100%
      accuracyCircle.current = new google.maps.Circle({
        map: props.map,
        center: currentPosition.position,
        fillColor: 'blue',
        fillOpacity: .3,
        radius: currentPosition.accuracy,
        strokeColor: 'blue',
        strokeOpacity: .3,
        strokeWeight: 1,
        zIndex: 1
      });
    };
  }, [currentPosition]);
//---------------------------------------------------------------------------
  return null;
}