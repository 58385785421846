import { ReactNode, useContext, useState } from 'react';
import styles from './PopupTemplates.module.css';
import { AppUiContextStore } from '../../../context/app-ui-context/AppUiContextProvider';
import { IUiDictionaryItem } from '../../common/dictionaries/IUiDictionaryItem';
import { EBusinessType } from '../../../context/business-context/IBusiness';
import PopupDialog from '../../common/popup-v2/popup-dialog/PopupDialog';
import { IUiInteractiveForm } from '../../common/forms/IUiInteractiveForm';
import GetCaption from '../../common/functions/GetCaption';
import { IUiOption } from '../../common/options/IUiOption';
import FormOptions from '../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../common/menu/menu-content/TUiMenuContent';
import TemplatesListItem from './templates-list-item/TemplatesListItem';

interface IProps {
  businessType: EBusinessType;
  selectedTemplate: string;
  onSelect: (selectedTemplate: string) => void;
  onClose: () => void;
}

export default function PopupTemplates(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [state, setState] = useState(props.selectedTemplate);
  let prefix = "";
  switch (props.businessType) {
    case "Business":
      prefix = "CustomerBusiness";
      break;
    case "SystemBusinessRegional":
      prefix = "RegionalHomepage";
      break;
  };
  //---------------------------------------------------------------------------
  const onSelect = (selectedItem: IUiDictionaryItem) => {
    setState(selectedItem.id);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionOk":
        props.onSelect(state);
        break;
      case "OptionCancel":
        props.onClose();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const availableTemplates = appUiContext.dictionaries?.adDesignTemplates?.filter(t => t.id.startsWith(prefix));
  const templateOptions: ReactNode[] = [];
  availableTemplates?.forEach(templateId => {
    templateOptions.push(
    <TemplatesListItem
      data={templateId}
      isSelected={state === templateId.id}
      onSelect={onSelect}
    />);
  });
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  selectTemplateForm.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionOk":
        updatedOptions.push({
          ...option,
          iconId: 'check',
          isDisabled: state == props.selectedTemplate,
          isDefault: state !== props.selectedTemplate
        });
        break;
      case "OptionCancel":
        updatedOptions.push({
          ...option,
          iconId: 'close'
        });
        break;
    };
  });
  const options =
   <FormOptions
      ui={getTUiMenuContent({
        ...selectTemplateForm.menuContent, 
        options: updatedOptions
      })}
      onSelect={onOptionSelect}
   />;
  //---------------------------------------------------------------------------
  if (availableTemplates) return (
    <PopupDialog
      id={selectTemplateForm.id}
      header={GetCaption(appUiContext, selectTemplateForm.id, selectTemplateForm.caption)}
      isModal={true}
      isDraggable={false}
      toolbarContent={options}
      onClose={props.onClose}      
    >
      <div className={styles.list}>
      {templateOptions}
      </div>
    </PopupDialog>
  ); else return null;
}

const selectTemplateForm: IUiInteractiveForm = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: {en: "Select Ad Template"},
  hint: undefined,
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: [{
      id: "OptionOk",
      directoryId: "",
      elementType: "",
      caption: { en: "OK"},
      hint: undefined,
    }, {
      id: "OptionCancel",
      directoryId: "",
      elementType: "",
      caption: { en: "Cancel"},
      hint: undefined,
    }]
  }
};