import React, { useEffect, useState } from 'react';
import styles from './TranslationEditorInput.module.css';
import RawInputText from '../../../../../common/input-v2/raw-inputs/RawInputText';
import InputWrapper from '../../../../../common/input-v2/input-wrapper/InputWrapper';
import { IValidationState } from '../../../../../common/input-v2/input-validation/IUiValidation';

export interface IUiTranslationEditor {
  groupId: string;
  id: string;
  caption: string;
  placeholder?: any;
}

interface IProps {
  ui: IUiTranslationEditor;
  data?: string;
  isSelected: boolean;
  isEditable: boolean;
  onSelect: () => void;
  onUpdate?: (updatedTranslation?: string) => void;
}

export default function TranslationEditorInput(props: IProps) {
  const [validationState, setValidationState] = useState<IValidationState>();
  //---------------------------------------------------------------------------
  useEffect(() => {
    validateValue(props.data);
  }, [props.data]);
  //---------------------------------------------------------------------------
  const onSelect = () => {
    if (!props.isSelected) {
      props.onSelect();
    };
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedValue: string) => {
    if (props.isEditable) {
      validateValue(updatedValue);
      if (updatedValue == "") {
        props.onUpdate && props.onUpdate(undefined);
      } else {
        props.onUpdate && props.onUpdate(updatedValue);
      };
    };
  };
  //---------------------------------------------------------------------------
  const validateValue = (value?: string) => {
    if (value && value != "") {
      setValidationState({ isValid: true, validationMessages: [] });
    } else {
      setValidationState({ isValid: false, validationMessages: [{ id: "EmptyValue", message: { en: "Value cannot be empty" } }] });
    };
  };
  //---------------------------------------------------------------------------
  return (
    <tr
      className={styles.container}
      style={{ color: props.isSelected ? 'inherit' : 'var(--clr_font_system)' }}
      onClick={onSelect}
    >
      <td className={styles.radio}>
        <input
          type="radio"
          id={props.ui.id}
          name={props.ui.groupId}
          checked={props.isSelected}
          readOnly
        />
      </td>
      <td className={styles.caption}>
        <label>
          {props.ui.caption}
        </label>
      </td>
      <td>
        {(!props.isEditable || (props.isEditable && props.isSelected)) &&
          <InputWrapper
            hideCaption={true}
            hideHint={true}
            validation={validationState}
          >
            <RawInputText
              ui={{
                id: "", directoryId: "", elementType: "",
                placeholder: props.ui.placeholder,
                hideCaption: true,
                hideHint: true,
                disabled: !props.isEditable || !props.isSelected
              }}
              data={props.data}
              isFocused={props.isSelected}
              isValid={validationState?.isValid}
              onUpdate={props.isEditable ? onUpdate : undefined}
            />
          </InputWrapper>}
        {/* {(!props.isEditable || (props.isEditable && props.isSelected)) &&
          <RawInputText
            ui={{
              id: "", directoryId: "", elementType: "",
              placeholder: props.ui.placeholder,
              hideCaption: true,
              hideHint: true,
              disabled: !props.isEditable || !props.isSelected
            }}
            data={props.data}
            isFocused={props.isSelected}
            onUpdate={props.isEditable ? onUpdate : undefined}
          />} */}
      </td>
    </tr>
  );
}