import { useContext, useEffect, useState } from "react";
import { EDirectoryType, IDictionary } from "../../../../../context/directory-editor-context/IDirectoryEditorContextData";
import { pathAdminConsole, pathAdministratorConsole, pathBusinessTagsConsole } from "../../../AdminConsole";
import DirectoryEditorWrapper from "../../../common/directory-editor/directory-editor-wrapper/DirectoryEditorWrapper";
import { tmpUiDirectoryEditor } from "../../../common/directory-editor/IUiDirectoryEditor";
import { useAbortController } from "../../../../../hooks/useAbortController";
import { DirectoryEditorContext } from "../../../../../context/directory-editor-context/DirectoryEditorContextProvider";
import { getBusinessTagsDictionaryDraftAsync } from "../functions/getBusinessTagsDictionaryDraftAsync";
import { useMsal } from "@azure/msal-react";
import { AppAuthContextStore } from "../../../../../context/app-auth-context/AppAuthContext";
import { dictonaryDraftStatesFullDetails } from "../../../../../context/directory-editor-context/directory-editor-settings/directoryDraftStatesFullDetails";
import { BusinessTagsDictionaryDraftItem } from "./IBusinessTagsDictionaryDraftItem";
import BusinessTagsDictionaryEditorNode from "./editor-node/BusinessTagsDictionaryEditorNode";
import { tmpUiBusinessTagsDictionaryEditorNode } from "./editor-node/IUiBusinessTagsDictionaryEditorNode";
import { saveBusinessTagsDictionaryDraftAsync } from "../functions/saveBusinessTagsDictionaryDraftAsync";
import { BusinessTagsDictionaryDraft } from "./IBusinessTagsDictionaryDraft";
import { deleteBusinessTagsDictionaryDraftAsync } from "../functions/deleteBusinessTagsDictionaryDraftAsync";
import { IDirectoryEditorStateInitial } from "../../../../../context/directory-editor-context/IDirectoryEditorState";
import PopupDictionaryDetails from "../../dictionaries/common-dictionary/popup-dictionary-details/PopupDictionaryDetails";
import { tmpUiEditformDictionaryDetails } from "../../dictionaries/common-dictionary/popup-dictionary-details/IUiEditformDictionaryDetails";
import { publishBusinessTagsDictionaryDraftAsync } from "../functions/publishBusinessTagsDictionaryDraftAsync";
import { EDraftItemState } from "../../../../../context/directory-editor-context/EDraftItemState";

interface IProps {
}

export default function BusinessTagsEditor(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { directoryEditorContext, directoryEditorContextDispatch } = useContext(DirectoryEditorContext);
  const abortController = useAbortController("BusinessTagsDictionaryEditor");
  const [isLoading, setIsLoading] = useState(false);
  const [detailsPopupOpen, setDetailsPopupOpen] = useState(false);
  const directoryType: EDirectoryType = "Dictionary";
  const basePath = `/${pathAdminConsole}/${pathAdministratorConsole}/${pathBusinessTagsConsole}`;
  const dictionaryDraft = directoryEditorContext?.editorState?.draft as BusinessTagsDictionaryDraft;
  let dictionaryItems: BusinessTagsDictionaryDraftItem[] = directoryEditorContext?.editorState?.draft?.items ?
    directoryEditorContext.editorState.draft.items as BusinessTagsDictionaryDraftItem[] :
    [];
  //-------------------------Id----------Name-------------                             Status------Badges------ContextMenu
  let gridTemplateColumns = 'min-content minmax(2em, 50%) minmax(2em, 50%) min-content min-content min-content minmax(2em, 1%)'; // Id, Name, badges, context 
  //--------------------------------------------------------------------------- Data, cleanup
  useEffect(() => {
    getDraft(false);
    //-------------------------------------------------------------------------
    return (() => {
      abortController.abortOnUnmount();
      directoryEditorContextDispatch({ type: "Cleanup" })
    });
  }, []);
  //---------------------------------------------------------------------------
  const getDraft = (lockRequired: boolean) => {
    let controller = abortController.newController("getBusinessTagsDictionaryDraftAsync");
    setIsLoading(true);
    getBusinessTagsDictionaryDraftAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      lockRequired
    }).then(response => {
      const initialState: IDirectoryEditorStateInitial = {
        directoryType,
        basePath: `${basePath}`,
        id: response.draft.dictionaryId,
        name: response.draft.name,
        description: response.draft.description,
        isNew: false,
        editMode: lockRequired,
        draft: response.draft,
        stateGroups: dictonaryDraftStatesFullDetails,
        dictionariesList: response.subDictionariesList,
        dictionaryOriginals: response.subDictionaryOriginals
      };
      addSubDictionaries(response.draft, response.subDictionaryOriginals);
      directoryEditorContextDispatch({ type: "Initialize", data: initialState });
    }).catch(error => {
      console.error(error)
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const addSubDictionaries = (draft: BusinessTagsDictionaryDraft, subDictionaries: IDictionary[]) => {
    // Iterate through items with subdictionary and add subdictionary items to the draft
    draft.items.forEach(item => {
      if (item.content && item.content.dictionaryId) {
        addSubDictionary(draft.items, item.fullPath, item.content?.dictionaryId, subDictionaries);
      };
    });
  };
  //---------------------------------------------------------------------------
  const addSubDictionary = (items: BusinessTagsDictionaryDraftItem[], path: string, subDictionaryId: string, subDictionaries: IDictionary[]) => {
    const subDictionary = subDictionaries.find(subDictionary => subDictionary.dictionaryId === subDictionaryId);
    subDictionary?.items.forEach(subItem => {
      const subDictionaryItem = new BusinessTagsDictionaryDraftItem({
        subDictionaryId: subDictionary.dictionaryId,
        id: subItem.id,
        path: `${path}${subItem.path ? "." + subItem.path : ""}`,
        name: subItem.name,
        description: subItem.description,
        status: subItem.status,
        draftState: EDraftItemState.None
      });
      items.push(subDictionaryItem);
    });
  };
  //---------------------------------------------------------------------------
  const saveDraft = () => {
    let controller = abortController.newController("saveBusinessTagsDictionaryDraftAsync");
    setIsLoading(true);
    saveBusinessTagsDictionaryDraftAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal,
      draft: directoryEditorContext.editorState.draft as BusinessTagsDictionaryDraft
    }).then(response => {
      const initialState: IDirectoryEditorStateInitial = {
        directoryType,
        basePath: `${basePath}`,
        id: response.draft.dictionaryId,
        name: response.draft.name,
        description: response.draft.description,
        isNew: false,
        editMode: true,
        draft: response.draft,
        stateGroups: dictonaryDraftStatesFullDetails,
        dictionariesList: response.subDictionariesList,
        dictionaryOriginals: response.subDictionaryOriginals
      };
      directoryEditorContextDispatch({ type: "Initialize", data: initialState });
    }).catch(error => {
      console.error(error)
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const deleteDraft = () => {
    let controller = abortController.newController("deleteBusinessTagsDictionaryDraftAsync");
    setIsLoading(true);
    deleteBusinessTagsDictionaryDraftAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal
    }).then(response => {
      const initialState: IDirectoryEditorStateInitial = {
        directoryType,
        basePath: `${basePath}`,
        id: response.draft.dictionaryId,
        name: response.draft.name,
        description: response.draft.description,
        isNew: false,
        editMode: false,
        draft: response.draft,
        stateGroups: dictonaryDraftStatesFullDetails,
        dictionariesList: response.subDictionariesList,
        dictionaryOriginals: response.subDictionaryOriginals
      };
      directoryEditorContextDispatch({ type: "Initialize", data: initialState });
    }).catch(error => {
      console.error(error)
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const publishDraft = () => {
    let controller = abortController.newController("publishBusinessTagsDictionaryDraftAsync");
    setIsLoading(true);
    publishBusinessTagsDictionaryDraftAsync({
      msalInstance: instance,
      authConfig: appAuthContext.config,
      abortSignal: controller.signal
    }).then(response => {
      const initialState: IDirectoryEditorStateInitial = {
        directoryType,
        basePath: `${basePath}`,
        id: response.draft.dictionaryId,
        name: response.draft.name,
        description: response.draft.description,
        isNew: false,
        editMode: false,
        draft: response.draft,
        stateGroups: dictonaryDraftStatesFullDetails,
        dictionariesList: response.subDictionariesList,
        dictionaryOriginals: response.subDictionaryOriginals
      };
      addSubDictionaries(response.draft, response.subDictionaryOriginals);
      directoryEditorContextDispatch({ type: "Initialize", data: initialState });
    }).catch(error => {
      console.error(error)
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onDraftOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionEditModeEnable":
        getDraft(true);
        break;
      case "OptionSave":
        saveDraft();
        break;
      case "OptionDelete":
        deleteDraft();
        break;
      case "OptionPublish":
        publishDraft();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onItemAdd = (
    newItem: BusinessTagsDictionaryDraftItem,
    parentItem: BusinessTagsDictionaryDraftItem,
    shift: number
  ) => {
    if (!dictionaryItems) return;
    //-------------------------------------------------------------------------
    let updatedItems = dictionaryItems.concat(newItem);
    if (newItem.content?.dictionaryId) {
      if (directoryEditorContext.dictionaryOriginals)
        addSubDictionary(updatedItems, newItem.fullPath, newItem.content.dictionaryId, directoryEditorContext.dictionaryOriginals);
      else
        console.error(`Subdictionary id=[${newItem.content?.dictionaryId}] not loaded`);
    };
    //-------------------------------------------------------------------------
    const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({
      items: updatedItems
    });
    directoryEditorContextDispatch({
      type: "Update",
      data: {
        draft: updatedDraft
      }
    });
  };
  //---------------------------------------------------------------------------
  const onItemUpdate = (updatedItem: BusinessTagsDictionaryDraftItem, replaceId?: string) => {
    if (!dictionaryItems) return;
    const idToFind = replaceId ? replaceId : updatedItem.id;
    let updatedItems = dictionaryItems.map(item => item.id === idToFind ? updatedItem : item);
    //-------------------------------------------------------------------------
    if (updatedItem.content?.dictionaryId) {
      // Remove subdictionary items from the draft
      updatedItems = updatedItems.filter(item => !(item.path === updatedItem.fullPath && item.id != updatedItem.id));
      if (directoryEditorContext.dictionaryOriginals)
        addSubDictionary(updatedItems, updatedItem.fullPath, updatedItem.content.dictionaryId, directoryEditorContext.dictionaryOriginals);
      else
        console.error(`Subdictionary id=[${updatedItem.content?.dictionaryId}] not loaded`);
    };
    //-------------------------------------------------------------------------
    const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({ items: updatedItems });
    directoryEditorContextDispatch({
      type: "Update",
      data: {
        draft: updatedDraft
      }
    });
  };
  //---------------------------------------------------------------------------
  const deleteItem = (deletedItemId: string) => {
    if (!directoryEditorContext?.editorState.draft) return;
    let updatedItems = dictionaryItems.filter(item => item.id !== deletedItemId);
    //-------------------------------------------------------------------------
    const itemToDelete = dictionaryItems.find(item => item.id === deletedItemId) as BusinessTagsDictionaryDraftItem;
    if (itemToDelete?.content?.dictionaryId) {
      updatedItems = updatedItems.filter(item => item.path !== itemToDelete.fullPath);
    };
    //-------------------------------------------------------------------------
    const updatedDraft = directoryEditorContext.editorState.draft.getUpdated({ items: updatedItems });
    directoryEditorContextDispatch({
      type: "Update",
      data: {
        draft: updatedDraft
      }
    });
  };
  //---------------------------------------------------------------------------
  const onItemOptionSelect = (draftItemId: string, optionId: string) => {
    switch (optionId) {
      case "OptionDelete":
        deleteItem(draftItemId);
        break;
    };
  };
  //---------------------------------------------------------------------------
  const items = dictionaryItems.filter(item => !item.path).map(item => {
    return (
      <BusinessTagsDictionaryEditorNode
        key={item.id}
        ui={tmpUiBusinessTagsDictionaryEditorNode}
        data={item as BusinessTagsDictionaryDraftItem}
        onUpdate={onItemUpdate}
        onAdd={onItemAdd}
        onOptionSelect={onItemOptionSelect}
      />
    );
  });
  //---------------------------------------------------------------------------
  return (
    <DirectoryEditorWrapper
      ui={tmpUiDirectoryEditor}
      gridTemplateColumns={gridTemplateColumns}
      isLoading={isLoading}
      //onSelectSourceLocale={onSelectSourceLocale}
      onDetailsEdit={() => setDetailsPopupOpen(true)}
      onOptionSelect={onDraftOptionSelect}
    >
      {items}

      {detailsPopupOpen &&
        <PopupDictionaryDetails
          ui={tmpUiEditformDictionaryDetails}
          onClose={() => setDetailsPopupOpen(false)}
        />}

    </DirectoryEditorWrapper>
  );
}