import React, { useContext, useEffect, useRef, useState } from "react";
import { pathSearchConsole } from "../../../App";
import useNavigateWithContext from "../../../hooks/useNavigateWithContext";
import SearchDetails from "./search-details/SearchDetails";
import { Buffer } from 'buffer';
import { AppUiContextStore } from "../../../context/app-ui-context/AppUiContextProvider";
import styles from "./SearchPanel.module.css";
import CombineStyles from "../../../utils/combineStyles";
import { ScreenType, useAppScreenContext } from "../../../context/app-screen-context/AppScreenProvider";
import { TLocationData, TPolygonArea } from "../../common/map/geo-json/GeoJson";
import { IUiSearchPanel } from "./IUiSearchPanel";
import { ELogLevel, LogInformation } from "../../../utils/logging";
import ScrollableContainer from "../../common/containers/scrollable-container/ScrollableContainer";
import FloatingOptionMobile from "./FloatingOptionMobile";
import { IAdModule } from "../../common/ad-modules/IAdModule";
import AdModuleBackground from "../../common/ad-modules/ad-module-background/AdModuleBackground";
import AdModuleHeader from "../../common/ad-modules/ad-module-header/AdModuleHeader";
import { paramSearchCategories, paramSearchLocationAddress, paramSearchLocationBounds, paramSearchUseArea } from "../../search-console/business-search-editor/IBusinessSearchParams";
import { IAdLanguageSet } from "../../ad-content-editor/IAdLanguageSet";
import { IUiOption } from "../../common/options/IUiOption";

const logLevel: ELogLevel = ELogLevel.None;

interface IProps {
  ui: IUiSearchPanel;
  data?: IAdLanguageSet; // ClassAdLanguageSetPublished;
  isCollapsed: boolean;
  selectedCategory?: string;
  onStateUpdate: (collapsed: boolean) => void;
  selectedMainMenuOption?: IUiOption;
  onMainMenuOptionSelect?: () => void;
}

export default function SearchPanel(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const navigate = useNavigateWithContext();
  const [adModule, setAdModule] = useState<IAdModule>();
  const divRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState<boolean>();
  const isMobile = screenType == ScreenType.Mobile;
  //--------------------------------------------------------------------------- Regional homepage block  
  useEffect(() => {
    // Handle URL in browser address bar: if it's not a master URL, replace it with master one
    // let newPathToDisplay = getPathToDisplay(location.pathname, props.data?.path);
    // if (newPathToDisplay)
    //   window.history.replaceState(null, '', newPathToDisplay);
    //-------------------------------------------------------------------------
    if (props.data?.adModules) {
      const adBlock = props.data.adModules[0] as IAdModule;
      setAdModule(adBlock);
    } else {
      setAdModule(undefined);
    };
  }, [props.data]);
  //--------------------------------------------------------------------------- Update isCollapsed based on props
  useEffect(() => {
    setIsCollapsed(props.isCollapsed);
  }, [props.isCollapsed]);
  //---------------------------------------------------------------------------
  const collapse = () => {
    setIsCollapsed(true);
    if (props.isCollapsed == false)
      props.onStateUpdate(true);
  };
  //---------------------------------------------------------------------------
  const expand = () => {
    setIsCollapsed(false);
    if (props.isCollapsed == true)
      props.onStateUpdate(false);
  };
  //---------------------------------------------------------------------------
  const onSearch = (
    searchByLocation?: string,
    area?: google.maps.LatLngBoundsLiteral | TPolygonArea
  ) => {
    var queryStringItems: string[] = [];
    //------------------------------------------------------------------------- Category
    var catValue: string | undefined = undefined;
    if (props.selectedCategory) {
      const selectedTags: string[] = [props.selectedCategory];
      const selectedTagsString = JSON.stringify(selectedTags);
      const selectedTagsEncoded = Buffer.from(selectedTagsString, 'utf8').toString('base64');
      catValue = selectedTagsEncoded;
    };
    const categoryQuery = catValue ? `cat=${catValue}` : undefined;
    LogInformation(logLevel, categoryQuery)
    if (categoryQuery)
      queryStringItems.push(categoryQuery);
    //------------------------------------------------------------------------- Location
    const locationQuery = (searchByLocation && searchByLocation != "") ? `location=${searchByLocation}` : undefined;
    if (locationQuery)
      queryStringItems.push(locationQuery);
    //------------------------------------------------------------------------- Bounds
    const boundsQuery = area ? `bounds=${JSON.stringify(area)}` : undefined;
    if (boundsQuery)
      queryStringItems.push(boundsQuery);
    //------------------------------------------------------------------------- Mode
    const modeQuery = 'mode=auto';
    queryStringItems.push(modeQuery);
    //-------------------------------------------------------------------------
    const searchQuery = `?${queryStringItems.join('&')}`;
    const navigatePath = `/${pathSearchConsole}${searchQuery}`;
    navigate(navigatePath);
  };
  //---------------------------------------------------------------------------
  const onSearchV2 = (locationData?: TLocationData) => {
    LogInformation(logLevel, "SearchPanel.onSearchV2.locationData:", locationData);
    let queryStringItems: string[] = [];
    //------------------------------------------------------------------------- Category
    let catValue: string | undefined = undefined;
    if (props.selectedCategory) {
      const selectedTags: string[] = [props.selectedCategory];
      const selectedTagsString = JSON.stringify(selectedTags);
      const selectedTagsEncoded = Buffer.from(selectedTagsString, 'utf8').toString('base64');
      catValue = selectedTagsEncoded;
    };
    const categoryQuery = catValue ? `${paramSearchCategories}=${catValue}` : undefined;
    LogInformation(logLevel, categoryQuery)
    if (categoryQuery)
      queryStringItems.push(categoryQuery);
    //------------------------------------------------------------------------- Location
    if (locationData) {
      const addressQuery = (locationData.address && locationData.address != "")
        ? `${paramSearchLocationAddress}=${locationData.address}`
        : undefined;
      LogInformation(logLevel, "SearchPanel.onSearchV2.addressQuery:", addressQuery);
      if (addressQuery)
        queryStringItems.push(addressQuery);
      //------------------------------------------------------------------------- Bounds
      const boundsQuery = locationData.mapBoundsLiteral ? `${paramSearchLocationBounds}=${JSON.stringify(locationData.mapBoundsLiteral)}` : undefined;
      LogInformation(logLevel, "SearchPanel.onSearchV2.boundsQuery:", boundsQuery);
      if (boundsQuery) {
        queryStringItems.push(boundsQuery);
        queryStringItems.push(`${paramSearchUseArea}=true`);
      }
      else {
        queryStringItems.push(`${paramSearchUseArea}=false`);
      }
    }
    //------------------------------------------------------------------------- Mode
    const modeQuery = 'mode=auto';
    queryStringItems.push(modeQuery);
    LogInformation(logLevel, "SearchPanel.onSearchV2.queryStringItems:", queryStringItems);
    //-------------------------------------------------------------------------
    const searchQuery = `?${queryStringItems.join('&')}`;
    LogInformation(logLevel, "SearchPanel.onSearchV2.searchQuery:", searchQuery);
    const navigatePath = `/${pathSearchConsole}${searchQuery}`;
    LogInformation(logLevel, "SearchPanel.onSearchV2.navigatePath:", navigatePath);

    navigate(navigatePath);
  };
  //---------------------------------------------------------------------------
  //const designTemplate = appUiContext.adBlockDesigns.find(item => item.id === adModule?.design?.designTemplateId);
  //---------------------------------------------------------------------------
  const containerStyle = CombineStyles([
    styles.container,
    screenType == ScreenType.Mobile ? styles.mobile : "",
    isCollapsed ? styles.collapsed : ""
  ]);
  //---------------------------------------------------------------------------
  if (!adModule) return null;
  return (
    <ScrollableContainer
      elementRef={divRef}
      className={containerStyle}
      onScrollUp={expand}
      onScrollDown={collapse}
    >

      <FloatingOptionMobile
        opacity={isMobile && isCollapsed ? 0.707 : 0}
        onSelect={expand}
      />

      <AdModuleBackground
        data={adModule}
        //designTemplate={designTemplate}
        maxImageSize="Large"
      >
        <div
          className={styles.searchBlockBackgroundCover}
          style={{ opacity: isCollapsed ? '1' : undefined }}
        />
        <SearchDetails
          ui={props.ui}
          onSearch={onSearch}
          onSearchV2={onSearchV2}
          selectedMainMenuOption={props.selectedMainMenuOption}
          onMainMenuOptionSelect={props.onMainMenuOptionSelect}
        />
        <div className={CombineStyles([
          styles.regionalHomepageText,
          isCollapsed ? styles.collapsed : ""
        ])}>
          <AdModuleHeader
            adModule={adModule}
            isReadonly={true}
            isClickable={false}
          />
        </div>

      </AdModuleBackground>

    </ScrollableContainer>
  );
}