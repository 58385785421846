import { IRgbColors } from "../context/app-ui-context/AppUiContextProvider";

export const getRgbFromColorName = (colorKeyword: string): string => {

  // CREATE TEMPORARY ELEMENT
  let el = document.createElement('div');

  // APPLY COLOR TO TEMPORARY ELEMENT
  el.style.color = colorKeyword;

  // APPEND TEMPORARY ELEMENT
  //document.body.appendChild(el);
  const app = document.getElementById('app');
  app?.appendChild(el);

  // RESOLVE COLOR AS RGB() VALUE
  let rgbValue = window.getComputedStyle(el).color;

  // REMOVE TEMPORARY ELEMENT
  //document.body.removeChild(el);
  app?.removeChild(el);

  return rgbValue;
}

export const getRgbFromColorNameWithOpacity = (colorKeyword: string, opacity: number) => {
  const color = getRgbFromColorName(colorKeyword);
  return color.replace(')', `, ${opacity})`);
}

export const getRgbaFromRgb = (rgb: string, opacity: number): string => {
  return rgb.replace(')', `, ${opacity})`);
}

export const getRgbaThemeColors = (): IRgbColors => {
  // Create temporary element
  let el = document.createElement('div');
  // Append temporary element to the element where color theme is applied
  const app = document.getElementById('app');
  app?.appendChild(el);
  //---------------------------------------------------------------------------
  // APPLY COLOR TO TEMPORARY ELEMENT
  el.style.color = "var(--clr_accent_main)";
  // RESOLVE COLOR AS RGB() VALUE
  let accentMain = window.getComputedStyle(el).color;
  //---------------------------------------------------------------------------
  el.style.color = "var(--clr_accent_wrong)";
  let accentWrong = window.getComputedStyle(el).color;
  //---------------------------------------------------------------------------
  el.style.color = "var(--clr_accent_attention)";
  let accentAttention = window.getComputedStyle(el).color;
  //---------------------------------------------------------------------------
  // Remove temporary element
  app?.removeChild(el);
  return {
    accentMain: accentMain,
    accentWrong: accentWrong,
    accentAttention: accentAttention
  };
}