import { useEffect } from "react";
import styles from "./PageRedirect.module.css";
import useNavigateWithContext from "../../hooks/useNavigateWithContext";

export function PageRedirect() {
  const navigate = useNavigateWithContext();
  //---------------------------------------------------------------------------
  useEffect(() => {
    //---------------------------------------------------------------------------
    let id: NodeJS.Timeout | undefined = undefined;
    //---------------------------------------------------------------------------
    //let userType = TUserType.fromLS("PageRedirect");
    // if (userType.loginFailed) {
    //   navigate("/");
    //   return;
    // }
    //---------------------------------------------------------------------------
    id = setTimeout(() => {
      console.warn("Timeout elapsed: navigating to homepage...");
      navigate("/");
    }, 3000);
    //---------------------------------------------------------------------------
    return () => {
      id && clearTimeout(id);
    }
  }, []);
  //console.log("Redirect.location:", window.location.toString());
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        Please wait for sign in process to complete...
      </div>
    </div>
  );
}