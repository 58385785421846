import { IUiInputList } from "../../../../common/input-v2/input-list/IUiInputList";

export interface IUiInputListStaffRoles extends IUiInputList {

}

export const hardcodedInputListUserRoles: IUiInputListStaffRoles = {
  id: "",
  directoryId: "",
  elementType: "",
  caption: { en: "Staff Roles" },
  hint: undefined,
  menuContent: {
    id: "",
    directoryId: "",
    elementType: "",
    hint: undefined,
    options: []
  }
}