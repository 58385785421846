import { AbortControllerWrapper } from "../../hooks/useAbortController";

interface IProps {
  abortController?: AbortControllerWrapper;
}

export default async function getUserGeopositionAsync(props?: IProps): Promise<GeolocationPosition | undefined> {
  return new Promise<GeolocationPosition | undefined>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(position => {
      //---------------------------------------------------------------------
      // Current user geolocation is retrieved
      // Do something only if request was not aborted
      if (props?.abortController && !props.abortController.aborted) {
        console.log("getUserGeopositionAsync:", position)
        resolve(position);
      } else {
        resolve(undefined);
      };
    }, error => {
      //---------------------------------------------------------------------
      // Current user geolocation cannot be retrieved for some reason
      console.error(`Unable to get current user location: ${error}`);
      resolve(undefined);
    });
  });
}