import { useContext, useEffect, useState } from 'react';
import DictionaryEditorWrapper from '../../../common/dictionary-editor-wrapper/DictionaryEditorWrapper';
import { IDictionaryDraftFilter } from '../../../common/dictionary-editor-wrapper/dictionary-editor-filter/DictionaryEditorFilter';
import { DictionaryEditorDraftItem } from '../common/models/DictionaryEditorDraftItem';
import { ILockable } from '../../../../common/locks/TLocker';
import { DraftItemStateEnum } from '../../../common/dictionary-draft-models/DraftItemStateEnum';
import { IPublishedDictionary } from '../../../common/dictionary-draft-models/IPublishedDictionary';
import { useAbortController } from '../../../../../hooks/useAbortController';
import getDictionaryDraft from '../common/functions/getDictionaryDraft';
import { useMsal } from '@azure/msal-react';
import { AppAuthContextStore } from '../../../../../context/app-auth-context/AppAuthContext';
import { pathAdminConsole, pathAdministratorConsole } from '../../../AdminConsole';
import { pathDictionaries } from '../../AdministratorConsole';
import { dictonaryDraftStatesFullDetails } from '../common/settings/dictonaryDraftStatesFullDetails';
import CommonDictionaryEditorNode from './common-dictionary-editor-node/CommonDictionaryEditorNode';
import { CommonDictionaryEditorState, ICommonDictionaryEditorState } from '../common/models/CommonDictionaryEditorState';
import CommonDictionaryItemEditForm from './common-dictionary-item-editform/CommonDictionaryItemEditForm';
import updateDictionaryDraft from '../common/functions/updateDictionaryDraft';
import deleteDictionaryDraft from '../common/functions/deleteDictionaryDraft';
import { EDictionaryType } from '../../../common/dictionary-draft-models/EDictionaryType';
import publishDictionaryDraft from '../common/functions/publishDictionaryDraft';
import { EHttpStatusCode } from '../../../../../utils/HttpStatusCodes';

interface IProps {
}

export default function CommonDictionaryEditor(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const [state, setState] = useState<CommonDictionaryEditorState>();
  const [isLoading, setIsLoading] = useState(true);
  const [newItem, setNewItem] = useState(false);
  const abortController = useAbortController("DictionaryEditor");
  //--------------------------------------------------------------------------- Data, abortcontroller
  useEffect(() => {
    getDraft(false);
    //-------------------------------------------------------------------------
    return () => {
      abortController.abortOnUnmount();
    }
  }, []);
  //---------------------------------------------------------------------------
  const newDraftState = (dictionaryDraft: any, editModeIsEnabled: boolean) => {
    let state = new CommonDictionaryEditorState({
      basePath: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}`, // remove???
      numberOfApprovals: 2,
      filter: {
        draftState: DraftItemStateEnum.All,
        stateGroups: dictonaryDraftStatesFullDetails,
        selectedGroups: dictonaryDraftStatesFullDetails.map(g => g.id), // switch on all the options (groups)
        searchById: false
      },
      editModeIsEnabled: editModeIsEnabled,
      breadcrumb: {
        id: dictionaryDraft.dictionaryId,
        caption: { en: `${dictionaryDraft.name}` },
        path: `/${pathAdminConsole}/${pathAdministratorConsole}/${pathDictionaries}/${dictionaryDraft.dictionaryId}`
      },
      draft: dictionaryDraft,
      draftSessionInitial: JSON.parse(JSON.stringify(dictionaryDraft)),
      isUpdated: false,
      isUpdatedInSession: false,
      isValid: false,
      isReadyToPublish: false
    });
    return state;
  };
  //---------------------------------------------------------------------------
  const getDraft = (lockIsNeeded: boolean) => {
    console.log("LocalesEditor: getting draft...")
    setIsLoading(true);
    let controller = abortController.newController("getDictionaryDraft");
    getDictionaryDraft(
      instance, "RequestRejectionReasons", lockIsNeeded, appAuthContext.config, controller.signal
    ).then(data => {
      let locker = (data as ILockable).locker;
      if (locker) 
        locker.errorPrefix = `Rejection Reasons dictionary`;
      const editorState = newDraftState(data, !!(locker && locker.statusCode == EHttpStatusCode.OK));
      !controller.aborted && setState(editorState);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onSaveDraft = () => {
    if (!state?.draft) {
      console.error("Cannot save dictonary draft: state is not set");
      return;
    };
    setIsLoading(true);
    let controller = abortController.newController("updateDictionaryDraft");
    updateDictionaryDraft(
      instance,
      state.draft,
      appAuthContext.config
    ).then(dictionaryDraft => {
      !controller.aborted && setState(newDraftState(dictionaryDraft, true));
    }).catch(error =>
      console.error(error)
    ).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onDeleteDraft = () => {
    if (!state?.draft) {
      console.error("Cannot delete dictonary draft: state is not set");
      return;
    };
    setIsLoading(true);
    let controller = abortController.newController("deleteDictionaryDraft");
    deleteDictionaryDraft(
      instance,
      state.draft.dictionaryId as EDictionaryType,
      appAuthContext.config
    ).then(dictionaryDraft => {
      !controller.aborted && setState(newDraftState(dictionaryDraft, false));
    }).catch(error => console.error(error)
    ).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onPublishDraft = () => {
    if (!state?.draft) {
      console.error("Cannot publish dictonary draft: state is not set");
      return;
    };
    setIsLoading(true);
    let controller = abortController.newController("publishDictionaryDraft");
    publishDictionaryDraft(
      instance,
      state.draft.dictionaryId as EDictionaryType,
      appAuthContext.config
    ).then(dictionaryDraft => {
      !controller.aborted && setState(newDraftState(dictionaryDraft, false));
    }).catch(error => console.error(error)
    ).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
  };
  //---------------------------------------------------------------------------
  const onFilterUpdate = (updatedFilter: IDictionaryDraftFilter) => {
    setState(new CommonDictionaryEditorState(
      state as ICommonDictionaryEditorState,
      { filter: updatedFilter }
    ));
  };
  //---------------------------------------------------------------------------
  const onEnableEditMode = () => {
    getDraft(true);
  };
  //---------------------------------------------------------------------------
  const onDisableEditMode = () => {
    setState(new CommonDictionaryEditorState(
      state as ICommonDictionaryEditorState, {
      draft: state?.draftSessionInitial,
      editModeIsEnabled: false
    }));
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedDraftItem: DictionaryEditorDraftItem) => {
    if (state?.draft.items) {
      const updatedItems = state.draft.items.map(item => item.id == updatedDraftItem.id ? updatedDraftItem : item);
      const updatedState = new CommonDictionaryEditorState(state, {
        draft: {
          ...state.draft,
          items: updatedItems
        }
      });
      setState(updatedState);
      setNewItem(false);
    } else {
      console.error("New element is being updated, but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onHardDeleteItem = (deletedItemId: string) => {
    if (state) {
      const updatedItems = state.draft.items?.filter(item => item.id !== deletedItemId);
      const updatedState = new CommonDictionaryEditorState(state, {
        draft: {
          ...state.draft,
          items: updatedItems
        }
      });
      setState(updatedState);
    } else {
      console.error("New element is being deleted, but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onAddNewItem = () => {
    setNewItem(true);
  };
  //---------------------------------------------------------------------------
  const onNewItemConfirmed = (newItem: DictionaryEditorDraftItem) => {
    if (state?.draft.items) {
      // Add new item to the list
      const updatedItems = state.draft.items.concat(newItem);
      const updatedState = new CommonDictionaryEditorState(state, {
        draft: {
          ...state.draft,
          items: updatedItems
        }
      });
      setState(updatedState);
      setNewItem(false);
    } else {
      console.error("New element is being added, but state is not set");
    };
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "optionDisableEditMode":
        onDisableEditMode();
        break;
      case "optionSave":
        onSaveDraft();
        break;
      case "optionDraftDelete":
        onDeleteDraft();
        break;
      case "optionPublish":
        onPublishDraft();
        break;
      case "optionAdd":
        onAddNewItem();
        break;
      default:
        break;
    };
  };
  //---------------------------------------------------------------------------
  const filteredDictionaryItems = state?.draft.items?.filter(item => {
    let resultText = true;
    let resultState = true;
    //-----------------------------------------------------------------------
    // Filter by text
    if (state?.filter.text) {
      const searchString = state.filter.text.toLocaleLowerCase();
      const name = item.name ? item.name : "";
      resultText = item.id.toLocaleLowerCase().includes(searchString) || (name.toLocaleLowerCase().includes(searchString));
    };
    //-----------------------------------------------------------------------
    // Filter by state
    if (state && state.filter.draftState !== DraftItemStateEnum.All) {
      resultState = (item.draftState & state.filter.draftState) != DraftItemStateEnum.None;
      //console.log(item.id, state.filter.draftState, item.draftState)
    };
    //-----------------------------------------------------------------------
    return resultText && resultState;
  });
  const dictionaryItemsToDisplay = filteredDictionaryItems?.map(item => {
    let publishedVersion = (state?.draft.publishedVersion as IPublishedDictionary<DictionaryEditorDraftItem>)?.items.find(di => di.id == item.id);
    let itemData = new DictionaryEditorDraftItem({
      ...item,
      publishedVersion: publishedVersion
    });
    return <></>
    /* return (
      <CommonDictionaryEditorNode
        key={item.id}
        stateGroups={state?.filter.stateGroups}
        data={itemData}
        editModeIsEnabled={state?.editModeIsEnabled}
        onUpdate={onUpdate}
        onDelete={onHardDeleteItem}
      />
    ); */
  });
  //---------------------------------------------------------------------------
  return (
    <DictionaryEditorWrapper
      setup={{
        mode: "Editor",
        role: 'Administrator',
        entityType: "Dictionary"
      }}
      state={state}
      isLoading={isLoading}
      onFilterUpdate={onFilterUpdate}
      onEnterEditMode={onEnableEditMode}
      onOptionSelect={onOptionSelect}
    >
      {dictionaryItemsToDisplay}

      {/* {newItem &&
        <CommonDictionaryItemEditForm
          onUpdate={onNewItemConfirmed}
          onClose={() => setNewItem(false)}
        />} */}

    </DictionaryEditorWrapper>
  );
}