import { IPublicClientApplication } from "@azure/msal-browser";
import { IAppAuthContext } from "../../../../context/app-auth-context/AppAuthContext";
import { ClassCustomerBusiness, ICustomerBusiness } from "../../business-editor/ICustomerBusiness";
import { AdLanguageSet, IAdLanguageSet } from "../../../ad-content-editor/IAdLanguageSet";
import { IBusiness } from "../../../../context/business-context/IBusiness";
import { TUserBusinessRoles } from "../../../../context/app-auth-context/TUser";
import { IBusinessTagRequest } from "../../../../context/business-context/IBusinessContext";
import { ClassImageEditorContext } from "../../../common/image-editor/image-editor-context/ImageEditorContextProvider";
import { apiPostPrivate, checkResponse } from "../../../../utils/api";
import { apiBasePath, pathSubmitBusinessWithAd } from "../../../../utils/apiPathConstant";
import { EHttpStatusCode } from "../../../../utils/HttpStatusCodes";

interface IResponse {
  updatedBusiness: ClassCustomerBusiness;
  updatedUserRoles: TUserBusinessRoles;
  updatedBusinessTagRequests?: IBusinessTagRequest[];
  updatedAdLanguageSet: AdLanguageSet;
}

interface IProps {
  msalInstance: IPublicClientApplication;
  authContext?: IAppAuthContext;
  imageEditorContext?: ClassImageEditorContext;
  abortSignal?: AbortSignal;
  business?: IBusiness;
  adLanguageSet: AdLanguageSet;
  businessTagRequests?: IBusinessTagRequest[];
  publish?: boolean;
  email?: string;
}

export async function submitBusinessWithAdAsync(props: IProps): Promise<IResponse> {
  if (!props.authContext) throw new Error("submitBusinessWithAdAsync: auth context is not provided");
  if (!props.business) throw new Error("submitBusinessWithAdAsync: business object is not provided");
  if (!props.imageEditorContext) throw new Error("submitBusinessWithAdAsync: image editor context is not provided");
  //---------------------------------------------------------------------------
  // Upload business images
  await props.imageEditorContext.uploadAllImagesForAsync(
    props.business.id,
    props.authContext,
    props.msalInstance,
    true
  );
  //---------------------------------------------------------------------------
  // Upload ad language set images
  await props.imageEditorContext.uploadAllImagesForAsync(
    props.adLanguageSet.id,
    props.authContext,
    props.msalInstance,
    true
  );
  //---------------------------------------------------------------------------
  // Save business data
  let apiUri = `${apiBasePath}${pathSubmitBusinessWithAd}`;
  if (props.publish) apiUri += "?publish=true";
  const response = await apiPostPrivate(
    props.msalInstance,
    props.authContext.config,
    apiUri,
    {
      business: props.business,
      // business tag requests
      adLanguageSet: props.adLanguageSet,
      email: props.email
    },
    undefined,
    props.abortSignal
  );
  checkResponse(response, "submitBusinessWithAdAsync", props.abortSignal?.aborted, [EHttpStatusCode.UnprocessableContent]);
  //---------------------------------------------------------------------------
  return {
    updatedBusiness: new ClassCustomerBusiness(response?.content.updatedBusiness as ICustomerBusiness),
    updatedUserRoles: response?.content.updatedUserRoles,
    //updatedBusinessTagRequests: response?.updatedBusinessTagRequests,
    updatedAdLanguageSet: new AdLanguageSet(response?.content.updatedAdLanguageSet as IAdLanguageSet)
  };
}