import { ReactNode } from "react";
import styles from "./ConsoleHeader.module.css";

interface IProps {
  children: ReactNode;
}

export default function ConsoleHeader(props: IProps) {
  return (
    <div className={styles.container}>
      {props.children}
    </div>
  );
}