import { useContext, useState } from 'react';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { WizardState } from '../IWizardState';
import { IUiBusinessWizard } from '../IUiBusinessWizardV2';
import GetDescription from '../../../common/functions/GetDescription';
import WizardStepWrapper from '../step-wrapper/WizardStepWrapper';
import { IUiOption } from '../../../common/options/IUiOption';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import BusinessTagsSelector from '../../../business-console/business-tags-selector/BusinessTagsSelector';
import styles from './StepTags.module.css';

interface IProps {
  state: WizardState;
  ui: IUiBusinessWizard;
  onUpdate: (state: WizardState) => void;
  onExit: () => void;
}

export default function StepTags(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const [tags, setTags] = useState<string[]>(props.state.data.tags);
  const stepUi = props.ui.stepsDictionary.find(item => item.id == props.state.selectedStep);
  const stepData = props.state.steps.find(item => item.stepId == props.state.selectedStep);
  const prompt = GetDescription(appUiContext, props.state.selectedStep, stepUi?.description);
  const isUpdated = tags !== props.state.data.tags;
  const stepIsCompleted = stepData?.isCompleted ? true : false;
  const isValid = tags && tags.length > 0;
  //---------------------------------------------------------------------------
  const onUpdate = (tags?: string[]) => {
    if (!tags) return;
    setTags(tags);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    let updatedState: WizardState;
    switch (optionId) {
      case "OptionBack":
        updatedState = isUpdated && isValid ?
          props.state.updateTags(tags, "Back") :
          props.state.moveToPreviousStep();
        break;
      case "OptionNext":
        if (!isValid) return;
        updatedState = isUpdated ?
          props.state.updateTags(tags, "Next") :
          props.state.moveToNextStep(!stepIsCompleted);
        break;
      default:
        throw new Error(`StepTags: Unknown optionId ${optionId}`);
    };
    props.onUpdate(updatedState);
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "OptionBack":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowLeft",
          isDisabled: false
        });
        break;
      case "OptionNext":
        updatedOptions.push({
          ...option,
          iconId: "doubleArrowRight",
          isDisabled: !isValid,
          isDefault: isValid
        });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({ ...props.ui.menuContent, options: updatedOptions })}
      applyMobileStyle={false}
      alignLeft={true}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------
  return (
    <WizardStepWrapper
      ui={props.ui}
      promptString={prompt}
      optionsElement={formOptions}
      onExit={props.onExit}
    >
      <div className={styles.tags}>
        {props.ui.inputListTags &&
          <BusinessTagsSelector
            ui={props.ui.inputListTags}
            selectedtags={tags}
            onUpdate={onUpdate}
          />}
      </div>
    </WizardStepWrapper>
  );
}