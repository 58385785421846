import React, { ChangeEvent, useState } from 'react';
import { IRegion } from '../../locales-editor/getCultures';
import { TUiMenuContent } from '../../../../../common/menu/menu-content/TUiMenuContent';
import { TUiMenuOption } from '../../../../../common/menu/menu-content/menu-option/TUiMenuOption';
import styles from '../../locales-editor/cultures-list/CulturesList.module.css';
import FormOptions from '../../../../../common/form-options-bar/FormOptions';
import RegionsListItem from './regions-list-item/RegionsListItem';

const tmpPopupMenuContent: TUiMenuContent = {
  id: "",
  directoryId: "",
  elementType: "",
  visible: true,
  disabled: false,
  options: [{
    id: 'CancelButton',
    directoryId: '',
    elementType: '',
    visible: true,
    disabled: false,
    canHideCaption: false,
    index: 0,
    priorityLevel: 0,
    caption: { en: 'Cancel' },
    iconFile: "close",
    hint: undefined,
    isDefault: false,
    action: undefined
  }, {
    id: 'SkipButton',
    directoryId: '',
    elementType: '',
    visible: true,
    disabled: false,
    canHideCaption: false,
    index: 0,
    priorityLevel: 0,
    caption: { en: 'Skip' },
    iconFile: "doubleArrowRight",
    hint: undefined,
    isDefault: true,
    action: undefined
  }, {
    id: 'OkButton',
    directoryId: '',
    elementType: '',
    visible: true,
    disabled: false,
    canHideCaption: false,
    index: 0,
    priorityLevel: 0,
    caption: { en: 'Ok' },
    iconFile: "check",
    hint: undefined,
    isDefault: true,
    action: undefined
  }],
  optionGroups: []
}

export function CheckKeyword(region: IRegion, keyword: string): boolean {
  let searchstring = keyword.toLowerCase();
  let id = region.id.includes(searchstring);
  let name = region.name.toLowerCase().includes(searchstring);
  return (id || name);
}

interface IProps {
  data?: IRegion[];
  unavailableRegions: string[];
  onSelect: (selectedRegion?: IRegion) => void;
  onClose: () => void;
}

export default function RegionSelector(props: IProps) {
  const [keyword, setKeyword] = useState<string>();
  const [selectedRegion, setSelectedRegion] = useState<IRegion>();
  //---------------------------------------------------------------------------
  const onFilterUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };
  //---------------------------------------------------------------------------
  const onRegionSelect = (selectedRegion: IRegion, confirmed: boolean) => {
    if (confirmed) {
      props.onSelect(selectedRegion);
    } else {
      //const updatedRegions = selectedRegions.concat(selectedRegion);
      setSelectedRegion(selectedRegion);
    };
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (selectedOptionId: string) => {
    switch (selectedOptionId) {
      case "OkButton":
        selectedRegion && props.onSelect(selectedRegion);
        break;
      case "SkipButton":
        props.onSelect();
        break;
      case "CancelButton":
        props.onClose();
        break;
    };
  };
  //---------------------------------------------------------------------------
  const itemsToDisplay = keyword ? props.data?.filter(item => {
    return CheckKeyword(item, keyword);
  }) : props.data;
  const listItems = itemsToDisplay?.map(region => (
    <RegionsListItem
      key={region.id}
      data={region}
      unavailableRegions={props.unavailableRegions}
      selectedRegion={selectedRegion}
      //keyword={keyword}
      onSelect={onRegionSelect}
    />
  ));
  //---------------------------------------------------------------------------
  const updatedOptions: TUiMenuOption[] = [];
  tmpPopupMenuContent.options.forEach(option => {
    switch (option.id) {
      case "OkButton":
        updatedOptions.push({
          ...option,
          disabled: !selectedRegion,
          isDefault: !!selectedRegion
        });
        break;
      case "SkipButton":
        updatedOptions.push({
          ...option,
          isDefault: !selectedRegion
        });
        break;
      case "CancelButton":
        updatedOptions.push(option);
        break;
    };
  });
  //---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.prompt}>
          Countries List
        </div>
        <div className={styles.filter}>
          <input onChange={onFilterUpdate} />
        </div>
        <div className={styles.content}>
          {listItems}
        </div>
        <FormOptions
          ui={{
            ...tmpPopupMenuContent,
            options: updatedOptions
          }}
          onSelect={onOptionSelect}
        />
      </div>
    </div>
  );
}