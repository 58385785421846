import React, { ReactNode, useContext, useLayoutEffect, useRef } from "react";
import { ScreenType, useAppScreenContext } from "../../../../../context/app-screen-context/AppScreenProvider";
import { AppUiContextStore } from "../../../../../context/app-ui-context/AppUiContextProvider";
import Caption from "../../../captions/Caption";
import { TUiCaption } from "../../../captions/TUiCaption";
import styles from "./ConsoleTabContent.module.css";
import CombineStyles from "../../../../../utils/combineStyles";

interface IProps {
  prompt?: TUiCaption | string;
  editForm?: boolean;
  mobileLayout?: boolean;
  children?: ReactNode;
}

export default function ConsoleTabContent(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const screenType = useAppScreenContext();
  const containerRef = useRef<HTMLDivElement>(null);
  //---------------------------------------------------------------------------
  // If 'editForm' prop is not provided, assume it to be false
  const editForm = (props.editForm == undefined) ? false : props.editForm;
  const promptText = (typeof (props.prompt) == "string") ? { "text": { "en": props.prompt } } as TUiCaption : props.prompt;
  const mobileLayout = props.mobileLayout == undefined ? false : props.mobileLayout;
  //---------------------------------------------------------------------------
  useLayoutEffect(() => {
    // Align elements on the form in case of desktop screen
    if (screenType == ScreenType.Desktop) {
      // Assuming each element should have an inner element with classname '*_caption_*
      // Determine the lengthiest of captions on the form
      const editFormElements = containerRef.current?.children;
      if (editForm && editFormElements) {
        var captionElements: HTMLElement[] = [];
        var maxLength = 0;
        //-----------------------------------------------------------------------
        // Create a list of all captions and calc max caption length
        for (var i = 0; i < editFormElements.length; i++) {
          const editFormElement = editFormElements[i] as HTMLElement;
          const captionElement = editFormElement.children[0] as HTMLElement;
          if (captionElement.className.includes('_caption_')) {
            captionElements.push(captionElement);
            captionElement.style.minWidth = 'fit-content';
            if (captionElement.clientWidth && (captionElement.clientWidth > maxLength)) maxLength = captionElement.clientWidth;
            continue;
          }
        };
        //-----------------------------------------------------------------------
        //console.log(maxLength)
        // If max caption length is too long, make it 1/3 of the current form width 
        if (maxLength > containerRef.current.clientWidth / 3) maxLength = containerRef.current.clientWidth / 5;
        // Make all captions of the same length
        for (var i = 0; i < captionElements.length; i++) {
          captionElements[i].style.minWidth = `${maxLength}px`;
          captionElements[i].style.maxWidth = `${maxLength}px`;
        };
      };
    };
  }, [appUiContext, screenType]);
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      {props.prompt && promptText &&
        <div className={styles.prompt}>
          <Caption ui={promptText} />
        </div>}
      <div
        ref={containerRef}
        className={CombineStyles([
          styles.content,
          screenType == ScreenType.Mobile && mobileLayout ? styles.mobile : ""
        ])}
      >
        {props.children}
      </div>
    </React.Fragment>
  );
}
