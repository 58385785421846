import CombineStyles from '../../../../../../utils/combineStyles';
import Icon from '../../../../../common/icon/Icon';
import { IDictionariesListItem } from '../../../../common/dictionary-draft-models/IDictionariesListItem';
import styles from './DictionariesListItem.module.css';

interface IProps {
  data: IDictionariesListItem;
  onSelect: (draftId: string) => void;
}

export default function DictionariesListItem(props: IProps) {
  const dictionaryName = props.data.name ? props.data.name : props.data.dictionaryId;
  const dictionaryDescription = props.data.description ?
    props.data.description :
    props.data.originalExists ? "No description" : "New dictionary";
  //---------------------------------------------------------------------------
  const onSelect = () => {
    props.onSelect(props.data.draftId ? props.data.draftId : props.data.dictionaryId);
  };
  //---------------------------------------------------------------------------
  return (
    <div
      className={styles.container}
      onClick={onSelect}
    >
      <div className={styles.details}>
        <div className={styles.name}>
          {dictionaryName}
        </div>
        <div>
          {dictionaryDescription}
        </div>
      </div>

      {!props.data.originalExists &&
        <div className={styles.new}>
          <Icon imageName="new" />
        </div>}

      {props.data.draftExists &&
        <div className={styles.option}>
          <Icon imageName="pencil" />
        </div>}
    </div>
  );
}