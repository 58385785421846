import { IAppUiContext } from "../../../context/app-ui-context/AppUiContextProvider";
import GetText from "./GetText";

export default function GetName(appUiContext: IAppUiContext, id: string, text: any, forceLocale?: string): string {
  return (
    GetText({
      appUiContext: appUiContext, 
      elementId: id,
      elementPart: "Name",
      text: text,
      forceLocale: forceLocale
    })
  );
}
