import { CSSProperties, ReactNode, useState } from 'react';
import styles from './AdModuleBackground.module.css';
import { SmoothImageLoader } from '../../image-editor/SmoothImageLoader';
import { EImageSize } from '../../image-editor/TImageData';
import React from 'react';
import { IAdModule } from '../IAdModule';
import CombineStyles from '../../../../utils/combineStyles';
import { IDesignTemplateImage } from '../../design-templates/IDesignTemplateImage';
import { IImageAnimation } from '../../design-templates/IAnimation';

interface IProps {
  data?: IAdModule;
  externalDesignTemplate?: IDesignTemplateImage;
  minHeightEm?: number; 
  maxImageSize: EImageSize;
  children: ReactNode;
  highlightColor?: string;
}

export default function AdModuleBackground(props: IProps) {
  const [backgroundUrl, setBackgroundUrl] = useState<string>();
  const imageData = props.data?.image?.imageData;
  const designTemplate: IDesignTemplateImage | undefined = props.externalDesignTemplate ? props.externalDesignTemplate : props.data?.design?.image;
  const animationTemplate: IImageAnimation | undefined = designTemplate?.animation;
  let hoverable: boolean = false;
  let hoverStyle: string = "";
  let animation: string = "";
  //---------------------------------------------------------------------------rgba(255, 0, 0, 0.2)
  let highlightStyles: CSSProperties | undefined = undefined;
  if (props.highlightColor) {
    highlightStyles = {
      background: `repeating-linear-gradient(-45deg, ${props.highlightColor}, ${props.highlightColor} 20px, rgba(0, 0, 0, 0) 20px, rgba(0, 0, 0, 0) 40px)`
    };
  };
  //---------------------------------------------------------------------------
  const containerStyles: CSSProperties = {
    borderRadius: 'inherit',
    minHeight: props.minHeightEm ? `${props.minHeightEm}em` : undefined
  };
  //---------------------------------------------------------------------------
  const imageContainerStyles: CSSProperties = {
    borderRadius: 'inherit',
    backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : undefined,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionX: designTemplate ? designTemplate.positionH : 'center',
    backgroundPositionY: designTemplate ? designTemplate.positionV : 'center',
    transitionProperty: 'transform',
    transitionDuration: designTemplate?.animation?.duration
  };
  //--------------------------------------------------------------------------- Set up styles (animation) based on template
  if (animationTemplate) {
    switch (animationTemplate.start) {
      //----------------------------------------------------------------------- on hover
      case "OnHover":
        hoverable = true;
        hoverStyle = animationTemplate.type.toLowerCase();
        imageContainerStyles.transition = `transform ${animationTemplate.duration}`;
        break;
      //----------------------------------------------------------------------- on start (on render)
      case "OnRender":
        imageContainerStyles.transition = undefined;
        switch (animationTemplate.type) {
          //-------------------------------------------------------------------
          case "ZoomIn":
            animation = `zoomIn ${animationTemplate.duration} ${animationTemplate.delay} ${animationTemplate.repeat}`;
            break;
          //-------------------------------------------------------------------
          case "ZoomOut":
            animation = `zoomOut ${animationTemplate.duration} ${animationTemplate.delay} ${animationTemplate.repeat}`;
            break;
          //-------------------------------------------------------------------
          case "ZoomInAndOut":
            animation = `zoomInAndOut ${animationTemplate.duration} ${animationTemplate.delay} ${animationTemplate.repeat}`;
            break;
        };
        imageContainerStyles.animation = animation;
        break;
    };
  };
  //---------------------------------------------------------------------------
  if (props.data?.image) return (
    <div
      className={CombineStyles([
        styles.container,
        hoverable ? CombineStyles([styles.hoverable, styles[hoverStyle]]) : ""
      ])}
      style={containerStyles}
    >
      <SmoothImageLoader
        imageData={imageData}
        sourceSize={"Thumbnail"}
        targetSize={props.maxImageSize}
        onLoad={setBackgroundUrl}
      />

      <div
        className={styles.image}
        style={imageContainerStyles}
      />

      {/* NOTE: we need separate text div here because we need independent from background image text animation */}
      <div
        className={styles.content}
        style={highlightStyles}
      >
        {props.children}
      </div>
    </div>
  ); else return (
    <div
      className={CombineStyles([
        styles.container,
        hoverable ? CombineStyles([styles.hoverable, styles[hoverStyle]]) : ""
      ])}
      style={containerStyles}
    >
      <div className={styles.content}>
        {props.children}
      </div>
    </div>
  );
}