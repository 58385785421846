import { useRef } from "react";
//-------------------------------------------------------------------------------
export class AbortControllerWrapper {
  //-----------------------------------------------------------------------------
  private _index: number;
  private _abortController: AbortController;
  private _operationName?: string;
  private _parent: AbortControllerManager;
  //-----------------------------------------------------------------------------
  constructor(parent: AbortControllerManager, operationName?: string) {
    //console.log("AbortController.constructor:", operationName);
    this._parent = parent;
    this._abortController = new AbortController();
    this._operationName = operationName;
    this._index = parent.controllers.length;
    parent.controllers.push(this);
  }
  //-----------------------------------------------------------------------------
  get index(): number {
    return this._index;
  }
  //-----------------------------------------------------------------------------
  get signal(): AbortSignal | undefined {
    return this._abortController.signal;
  }
  //-----------------------------------------------------------------------------
  get aborted(): boolean {
    return this._abortController.signal.aborted;
  }
  //-----------------------------------------------------------------------------
  get state(): string {
    return `${this._operationName}: aborted=${this.aborted}`;
  }
  //-----------------------------------------------------------------------------
  get operationName(): string | undefined {
    return this._operationName;
  }
  //-----------------------------------------------------------------------------
  abort(reason?: any) {
    this._abortController.abort(reason);
  }
  // //-----------------------------------------------------------------------------
  // reset(fromWhere?: string): AbortSignal {
  //   console.log(`reset[${fromWhere}]:`, this._abortController);
  //   if (!this._abortController || this._abortController.signal.aborted)
  //     this._abortController = new AbortController();
  //   return this._abortController.signal;
  // }
  //-----------------------------------------------------------------------------
  abortOnUnmount(componentName?: string) {
    let name = componentName ? componentName
      : this._operationName ? this._operationName
        : "unknown";
    console.log(`AbortController: ${name} was unmounted`);
    this._abortController.abort(`${name} was unmounted`);
  }
  //-----------------------------------------------------------------------------
  remove(log?: boolean) {
    if (this._parent.controllers.length > 0 && this._parent.controllers.length > this._index) {
      log === true && console.log("AbortController.remove:", this._operationName);
      this._parent.controllers.splice(this._index, 1);
    }
  }
}
//-------------------------------------------------------------------------------
export class AbortControllerManager {
  //-----------------------------------------------------------------------------
  private _controllers: AbortControllerWrapper[] = [];
  private _componentName?: string
  //-----------------------------------------------------------------------------
  constructor(componentName?: string) {
    //console.log(`AbortController.constructor[${componentName}]`);
    this._componentName = componentName;
  }
  //-----------------------------------------------------------------------------
  get controllers(): AbortControllerWrapper[] {
    return this._controllers;
  }
  //-----------------------------------------------------------------------------
  get state(): string | undefined {
    let result = "";
    this._controllers.forEach((controller, index) => {
      result += controller.state;
      if (index < this.controllers.length - 1)
        result += ', ';
    });
    return result == "" ? undefined : result;
  }
  //-----------------------------------------------------------------------------
  newController(operationName?: string): AbortControllerWrapper {
    let controller = new AbortControllerWrapper(this, operationName);
    return controller;
  }
  //-----------------------------------------------------------------------------
  getController(operationName?: string): AbortControllerWrapper | undefined {
    return this._controllers.find(controller => controller.operationName === operationName);
  }
  //-----------------------------------------------------------------------------
  abort(reason?: any) {
    //console.log(`AbortController.abort:`, reason);
    this._controllers.forEach(controller => controller.abort(reason));
  }
  //-----------------------------------------------------------------------------
  abortOnUnmount(componentName?: string) {
    if (this._controllers.length > 0) {
      let name = componentName ? componentName
        : this._componentName ? this._componentName
          : "unknown";
      this.abort(`${name} was unmounted`);
    }
  }
}
//-----------------------------------------------------------------------------
export function useAbortController(componentName?: string): AbortControllerManager {
  const ref = useRef(new AbortControllerManager(componentName));
  return ref.current;
}
