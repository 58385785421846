import { apiPost } from "../../../utils/api";
import { apiBasePath, identityService } from "../../../utils/apiPathConstant";
import { getFromLS, lsIdentityServiceData, setToLS } from "../../../utils/storage";
import { GetIdentityServiceData } from "./GetIdentityServiceData";
import { IAuthConfig } from "../AppAuthContext";
import { getFromSessionStorage, saveToSessionStorage } from "../../../utils/sessionStorage";

export default function getIdentityServiceConfig(): Promise<IAuthConfig | undefined> {
  return new Promise((resolve, reject) => {
    // Try to get cached data
    //const cachedData = getFromLS(lsIdentityServiceData);
    const cachedData = getFromSessionStorage(lsIdentityServiceData);
    if (cachedData) {
      //-----------------------------------------------------------------------
      // Identity service data is already cached
      let data = GetIdentityServiceData(cachedData).config;
      console.log("getIdentityServiceConfig: cached data is retrieved:");//, data);
      //resolve(GetIdentityServiceData(cachedData).config);
      resolve(data);
    } else {
      //-----------------------------------------------------------------------
      // Identity service data is not cached, so get it from API    
      apiPost(
        `${apiBasePath}${identityService}`
      ).then(response => {
        if (response.status == 200 && response.content) {
          console.log("getIdentityServiceConfig: remote data is retrieved");
          //setToLS(lsIdentityServiceData, response.content);
          saveToSessionStorage(lsIdentityServiceData, response.content);
          resolve(GetIdentityServiceData(response.content).config);
        }
        else
          throw `getIdentityServiceConfig: API did not respond properly [${response.status}]`;
      }).catch(error => {
        //reject(error)
        console.error(`getIdentityServiceConfig: API did not respond properly [${error}]`);
        resolve(undefined);
      });
    };
  });
}