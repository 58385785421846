import { ReactNode } from 'react';
import styles from './DictionaryNodeWrapper.module.css';
import CombineStyles from '../../../../utils/combineStyles';

interface IProps {
  editModeIsEnabled: boolean;
  isHierarchical?: boolean;
  isCollapsed?: boolean;
  indent?: number;
  children: ReactNode;
  onSelect?: () => void;
}

export default function DictionaryNodeWrapper(props: IProps) {
  const isHierarchical = props.isHierarchical == undefined ? false : props.isHierarchical;
  const indent = props.indent ? props.indent : 0;
  //---------------------------------------------------------------------------
  return (
    <tr
      className={CombineStyles([
        styles.container,
        props.editModeIsEnabled ? styles.editable : ""
      ])}
      onDoubleClick={props.editModeIsEnabled ? props.onSelect : undefined}
    >
      {props.children}
    </tr>
  );
}