import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../context/app-auth-context/AppAuthContext";
import { apiGetPrivate, checkResponse } from "../../../../utils/api";
import { apiBasePath, pathGetCustomerAdRequest } from "../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../utils/common";
import { ICustomerAdRequest } from "../customer-ads/request-form/ICustomerAdRequest";

interface IProps {
  msalInstanse: IPublicClientApplication;
  authConfig?: IAuthConfig;
  requestId?: string;
  localeId?: string;
  abortSignal?: AbortSignal;
}

export async function getCustomerAdRequestAsync(props: IProps): Promise<ICustomerAdRequest> {
  if (!props.authConfig) throw new Error("getCustomerAdRequestAsync: auth config is not provided");
  if (!props.requestId) throw new Error("getCustomerAdRequestAsync: request id is not provided");
  if (!props.localeId) throw new Error("getCustomerAdRequestAsync: locale id is not provided");
  //---------------------------------------------------------------------------
  const uri = stringFormatter(pathGetCustomerAdRequest, [props.requestId, props.localeId]);
  const response = await apiGetPrivate(
    props.msalInstanse,
    props.authConfig,
    `${apiBasePath}${uri}`,
    props.abortSignal
  );
  //---------------------------------------------------------------------------
  checkResponse(response, "getCustomerAdRequestAsync", props.abortSignal?.aborted);
  const request = response?.content as ICustomerAdRequest;
  return request;
}