import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathDeleteSystemBusinessRegionalPath } from "../../../../../../utils/apiPathConstant";
import { stringFormatter } from "../../../../../../utils/common";
import { apiDeletePrivate, checkResponse, IApiResponse } from "../../../../../../utils/api";

interface IProps {
  msalIntance: IPublicClientApplication;
  authConfig?: IAuthConfig;
  businessId?: string;
  path: string;
  abortSignal?: AbortSignal;
}

export default async function deleteBusinessPathAsync(props: IProps): Promise<void | IApiResponse> {
  if (!props.authConfig) throw new Error("deleteBusinessPathAsync: auth config is not provided");
  if (!props.businessId) throw new Error("deleteBusinessPathAsync: business Id is not provided");
  //---------------------------------------------------------------------------
  const uri = `${apiBasePath}${stringFormatter(pathDeleteSystemBusinessRegionalPath, [
    props.businessId,
    props.path
  ])}`;
  const response = await apiDeletePrivate(
    props.msalIntance,
    props.authConfig,
    uri,
    props.abortSignal
  );
  //---------------------------------------------------------------------------  
  checkResponse(response, "deleteBusinessPathAsync", props.abortSignal?.aborted);
  return response;
}