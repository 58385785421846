import { IUiInteractiveForm } from "../../common/forms/IUiInteractiveForm";
import { IUiInputText } from "../../common/input-v2/input-text/InputText";

export interface IUiAdDetails extends IUiInteractiveForm {
  inputTitle: IUiInputText;
  inputDescription: IUiInputText;
}

export const tmpUiAdDetails: IUiAdDetails = {
  id: '',
  directoryId: '',
  elementType: '',
  caption: { en: 'Ad Details' },
  hint: { en: 'Please note changes here would affect both Search result module and Homepage banner' },
  menuContent: {
    id: '',
    directoryId: '',
    elementType: '',
    hint: undefined,
    options: [{
      id: 'OptionOk',
      directoryId: '',
      elementType: '',
      caption: { en: 'Ok' },
      hint: undefined
    }, {
      id: 'OptionCancel',
      directoryId: '',
      elementType: '',
      caption: { en: 'Cancel' },
      hint: undefined
    }]
  },
  inputTitle: {
    id: '',
    directoryId: '',
    elementType: '',
    caption: { en: 'Ad Title' },
    hint: { en: 'Ad title commonly is a business name in current ad language' },
    placeholder: { en: 'Type here...' },
    validation: {
      required: {
        value: 3,
        message: { en: 'Title is required' }
      }
    }
  },
  inputDescription: {
    id: '',
    directoryId: '',
    elementType: '',
    caption: { en: 'Short description' },
    hint: { en: 'Provide short business description in current ad language' },
    placeholder: { en: 'Type here...' },
    validation: {
      required: {
        value: 5,
        message: { en: 'Description is required' }
      }
    }
  }
}