import { useContext, useEffect, useState } from 'react';
import styles from './ListColumnsManager.module.css';
import React from 'react';
import FilterEditor from '../filter-editor/FilterEditor';
import SorterEditor, { TMove } from '../sorter-editor/SorterEditor';
import { ListManagerContext } from '../../../../context/list-manager-context/ListManagerContextProvider';
import { ISorterItem } from '../sorter-editor/ISorterItem';
import { IListColumn } from '../IListColumn';
import { EListMode, EManagerMode } from '../../../../context/list-manager-context/IListManagerContextData';
import ListColumnsManagerHeader from './list-columns-manager-header/ListColumnsManagerHeader';
import { IUiOption } from '../../options/IUiOption';
import ContextMenuOption from '../context-menu-option/ContextMenuOption';
import PresetOption from '../preset-option/PresetOption';

export interface IListTotals {
  total?: number;
  filtered?: number;
}

interface IProps {
  numberTotal?: number;
  numberFiltered?: number;
  expandSorters?: boolean;
  expandFilters?: boolean;
  showBasicModeSwitch?: boolean;
}

export default function ListColumnsManager(props: IProps) {
  const { listManagerContext, listManagerContextDispatch } = useContext(ListManagerContext);
  const [expandedOptionId, setExpandedOptionId] = useState<string>();
  const filtersAreApplied = props.numberFiltered != props.numberTotal;
  //--------------------------------------------------------------------------- expandSorters/expandFilters
  useEffect(() => {
    if (props.expandSorters)
      setExpandedOptionId("OptionSort");
    else if (props.expandFilters)
      setExpandedOptionId("OptionFilter");
    else
      setExpandedOptionId(undefined);
  }, [props.expandSorters, props.expandFilters]);
  //---------------------------------------------------------------------------
  const onContextMenuToggle = () => {
    if (expandedOptionId == "OptionContextMenu")
      setExpandedOptionId(undefined);
    else
      setExpandedOptionId("OptionContextMenu");
  };
  //---------------------------------------------------------------------------
  const onFiltersToggle = () => {
    if (expandedOptionId == "OptionFilter")
      setExpandedOptionId(undefined);
    else
      setExpandedOptionId("OptionFilter");
  };
  //---------------------------------------------------------------------------
  const onSortersToggle = (optionId: string) => {
    if (expandedOptionId == optionId)
      setExpandedOptionId(undefined);
    else
      setExpandedOptionId(optionId);
  };
  //---------------------------------------------------------------------------
  const onRestoreDefaults = () => {
    listManagerContextDispatch({
      type: "RestoreDefaults"
    });
  };
  //---------------------------------------------------------------------------
  const onFilterOptionUpdate = (columnId: string, filterItemOptionId: string) => {
    listManagerContextDispatch({
      type: "UpdateFilterOption",
      columnId: columnId,
      filterItemOptionId: filterItemOptionId
    });
  };
  //---------------------------------------------------------------------------
  const onFilterKeywordUpdate = (columnId: string, keyword: string) => {
    listManagerContextDispatch({
      type: "UpdateFilterKeyword",
      columnId: columnId,
      keyword: keyword
    });
  };
  //---------------------------------------------------------------------------
  const onFilterDatetimeStartUpdate = (columnId: string, datetime: string) => {
    listManagerContextDispatch({
      type: "UpdateFilterDatetimeStart",
      columnId: columnId,
      datetime: datetime
    });
  };
  //---------------------------------------------------------------------------
  const onFilterDatetimeEndUpdate = (columnId: string, datetime: string) => {
    listManagerContextDispatch({
      type: "UpdateFilterDatetimeEnd",
      columnId: columnId,
      datetime: datetime
    });
  };
  //---------------------------------------------------------------------------
  const onFilterClear = (columnId: string) => {
    listManagerContextDispatch({
      type: "ClearFilter",
      columnId: columnId
    });
  };
  //---------------------------------------------------------------------------
  const onFilterRestore = (columnId: string) => {
    listManagerContextDispatch({
      type: "RestoreFilter",
      columnId: columnId
    });
  };
  //---------------------------------------------------------------------------
  const onSortingOrderUpdate = (updatedColumn: IListColumn) => {
    //console.log('onSortingOrderUpdate')
    listManagerContextDispatch({
      type: "UpdateSorter",
      updatedColumn: updatedColumn
    });
  };
  //---------------------------------------------------------------------------
  const onSortingIndexUpdate = (columnId: string, moved: TMove) => {
    // Extract a pair of items that need to be swapped
    const sortableColumns = listManagerContext.sortableColumns;
    const firstColumnIndex = sortableColumns.findIndex(c => c.id == columnId);
    const firstColumnToUpdate = sortableColumns[firstColumnIndex];
    if (!firstColumnToUpdate.sorter) return;
    //-------------------------------------------------------------------------
    const oldIndex = firstColumnToUpdate.sorter.order;
    const newIndex = (moved == "Up") ? oldIndex - 1 : oldIndex + 1;
    const displacedColumn = sortableColumns.find(c => c.sorter?.order == newIndex);
    if (!displacedColumn?.sorter) return;
    //-------------------------------------------------------------------------
    const firstUpdatedColumn: IListColumn = {
      ...firstColumnToUpdate,
      sorter: {
        ...firstColumnToUpdate.sorter as ISorterItem,
        order: newIndex
      }
    };
    const secondUpdatedColumn: IListColumn = {
      ...displacedColumn,
      sorter: {
        ...displacedColumn.sorter as ISorterItem,
        order: oldIndex
      }
    };
    //-------------------------------------------------------------------------
    listManagerContextDispatch({
      type: "UpdateTwoColumns",
      firstColumn: firstUpdatedColumn,
      secondColumn: secondUpdatedColumn
    });
  };
  //---------------------------------------------------------------------------
  const onHeaderOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionContextMenu":
        onContextMenuToggle();
        break;
      case "OptionSetSimplifiedMode":
        setExpandedOptionId(undefined);
        listManagerContextDispatch({ type: "SetMode", mode: EManagerMode.Basic });
        break;
      case "OptionSetAndvancedMode":
        setExpandedOptionId(undefined);
        listManagerContextDispatch({ type: "SetMode", mode: EManagerMode.Advanced });
        break;
      case "OptionSort":
      case "OptionSortPresets":
        onSortersToggle(optionId);
        break;
      case "OptionFilter":
        onFiltersToggle();
        break;
      case "OptionReset":
        if (expandedOptionId == "OptionContextMenu")
          setExpandedOptionId(undefined);
        onRestoreDefaults();
        break;
      case "OptionListModeList":
        listManagerContextDispatch({ type: "SetListMode", listMode: optionId as EListMode });
        break;
      case "OptionListModeGrid":
        listManagerContextDispatch({ type: "SetListMode", listMode: optionId as EListMode });
        break;
    };
  };
  //---------------------------------------------------------------------------
  const onSortPresetSelect = (optionId: string) => {
    setExpandedOptionId(undefined);
    listManagerContextDispatch({ type: "SetSortPreset", optionId: optionId });
  };
  //---------------------------------------------------------------------------
  let contextMenuOptions: IUiOption[] = listManagerContext.availableOptions.filter(o => o.id != "OptionContextMenu");
  const contextMenuElements = contextMenuOptions.map(option =>
    <ContextMenuOption
      key={option.id}
      ui={option}
      onSelect={onHeaderOptionSelect}
    />
  );
  //---------------------------------------------------------------------------
  const filterItemElements = listManagerContext.filterableColumns?.map(column => {
    const isRestorable = listManagerContext.clearedFilters.find(f => f.columnId == column.id);
    return (
      <FilterEditor
        key={column.id}
        column={column}
        onOptionUpdate={onFilterOptionUpdate}
        onKeywordUpdate={onFilterKeywordUpdate}
        onDatetimeStartUpdate={onFilterDatetimeStartUpdate}
        onDatetimeEndUpdate={onFilterDatetimeEndUpdate}
        onClear={onFilterClear}
        onRestore={isRestorable ? onFilterRestore : undefined}
      />)
  });
  //---------------------------------------------------------------------------
  const sortableColumns = listManagerContext.sortableColumns;
  const sorterItemElements = sortableColumns.map(column => column.sorter && (
    <SorterEditor
      key={column.id}
      column={column}
      total={sortableColumns.length}
      onSortingIndexUpdate={onSortingIndexUpdate}
      onSortingOrderUpdate={onSortingOrderUpdate}
    />
  ));
  //---------------------------------------------------------------------------
  let dropdownOptions = undefined;
  switch (expandedOptionId) {
    case "OptionContextMenu":
      dropdownOptions = contextMenuElements;
      break;
    case "OptionFilter":
      dropdownOptions = filterItemElements;
      break;
    case "OptionSort":
      dropdownOptions = sorterItemElements;
      break;
    case "OptionSortPresets":
      dropdownOptions = listManagerContext.sortPresets?.options.map(preset =>
        <PresetOption
          key={preset.id}
          data={preset}
          onSelect={onSortPresetSelect}
        />
      );
      break;
    case "OptionFilterPresets":
      dropdownOptions = listManagerContext.filterPresets?.options.map(preset =>
        <PresetOption
          key={preset.id}
          data={preset}
          onSelect={onSortPresetSelect}
        />
      );
      break;
  };
  //---------------------------------------------------------------------------
  return (
    <React.Fragment>
      <ListColumnsManagerHeader
        totals={{
          total: props.numberTotal,
          filtered: props.numberFiltered
        }}
        expandedOptionId={expandedOptionId}
        showBasicModeSwitch={props.showBasicModeSwitch}
        onOptionSelect={onHeaderOptionSelect}
      />

      {dropdownOptions &&
        <div className={styles.dropdownOptions}>
          {dropdownOptions}
        </div>}

    </React.Fragment>
  );
}