import { UserSettings } from "../../utils/UserSettings";
import { IAppAuthContext, IUserLocation } from "./AppAuthContext";
import getUserGeopositionAsync from "./getUserGeoPositionAsync";
import { TUser, TUserBusinessRoles } from "./TUser";
import { removeUserLocally, setUserLocally } from "./user";

interface ISetUser {
  type: "SetUser";
  value: TUser; // Temporary value, it should Accept claims, pass it to API and get full user object from database
}
interface IRemoveUser {
  type: "RemoveUser"; // This one should be called on sign-out
  value: string; // User Id to remove
}
interface IUpdateUserRoles {
  type: "UpdateUserRoles";
  value: TUserBusinessRoles;
}
interface ISetInProcess {
  type: "SetInProcess";
  value: boolean;
}
interface ISetUserGeoposition {
  type: "SetUserGeoposition";
  position?: GeolocationPosition;
}
interface IRefreshUserGeoposition {
  type: "RefreshUserGeoposition";
}

export type Actions =
  ISetUser | IRemoveUser | IUpdateUserRoles | ISetInProcess |
  ISetUserGeoposition | IRefreshUserGeoposition;

export const AppAuthContextReducer = (
  state: IAppAuthContext,
  action: Actions
) => {
  let updatedState: IAppAuthContext = state;
  switch (action.type) {
    case "SetUser": {
      //console.warn("AppAuthContextReducer.SetUser");
      setUserLocally(action.value.userIdentity.id, action.value);
      updatedState = { ...state, user: action.value };
    } break;
    //-------------------------------------------------------------------------
    case "RemoveUser": {
      removeUserLocally(action.value);
      updatedState = { ...state, user: null };
    } break;
    //-------------------------------------------------------------------------
    case "UpdateUserRoles": {
      if (state.user) {
        const updatedUser = {
          ...state.user,
          businessRoles: action.value
        };
        //---------------------------------------------------------------------
        setUserLocally(state.user.userIdentity.id, updatedUser);
        //---------------------------------------------------------------------
        updatedState = {
          ...state,
          user: updatedUser
        };
      }
      else {
        console.error("could not update user roles because user context is not set");
      };
    } break;
    //-------------------------------------------------------------------------
    case "SetInProcess": {
      updatedState = {
        ...state,
        isInProcess: action.value
      };
    } break;
    //-------------------------------------------------------------------------
    case "SetUserGeoposition": {
      console.log("AppAuthContextReducer.SetUserGeoposition:", action.position);
      if (action.position) {
        updatedState = {
          ...state,
          userLocation: {
            position: action.position
          }
        };
      }
    } break;
    //-------------------------------------------------------------------------
    case "RefreshUserGeoposition": {
      // getUserGeopositionAsync().then(position => {
      //   return {
      //     ...state,
      //     userLocation: {
      //       position: position
      //     }
      //   };
      // });
    } break;
    //-------------------------------------------------------------------------
    default:
      console.error("AppAuthContextReducer: unexpected action type:", action);
  }
  UserSettings.user = updatedState.user;
  return updatedState;
};
