import { IPublicClientApplication } from "@azure/msal-browser";
import { IAuthConfig } from "../../../../../context/app-auth-context/AppAuthContext";
import { apiBasePath, pathGetListRequestRegional } from "../../../../../utils/apiPathConstant";
import { apiGetPrivate, checkResponse } from "../../../../../utils/api";
import { IRegionalHomepageRequestsListItem } from "../requests-list/requests-list-item/IRegionalHomepageRequestsListItem";

export function getListRegionalHomepageRequest(
  msalInstanse: IPublicClientApplication,
  authConfig?: IAuthConfig | null,
  abortSignal?: AbortSignal
): Promise<IRegionalHomepageRequestsListItem[]> {
  return new Promise((resolve, reject) => {
    const uri = `${apiBasePath}${pathGetListRequestRegional}`;
    //-------------------------------------------------------------------------
    apiGetPrivate(
      msalInstanse,
      authConfig,
      uri,
      abortSignal
    ).then(response => {
      checkResponse(response, "getRegionalHomepageRequests", abortSignal?.aborted);
      resolve(response?.content);
    }).catch(error =>
      reject(error)
    );
  });
}