import styles from "./HealthStatusCosmosDb.module.css";
import { DatabaseStatus } from "./DatabaseStatus";
import WikiSpinner from "../../app-layout/spinner/wikiSpinner";
import React, { useState } from "react";
import { StatusToggler } from "../StatusToggler";
import { HealthGauge } from "../HealthGauge";
import { CosmosDbServiceHealthStatus } from "../CosmosDBHealthStatus";

interface IProps {
  status?: CosmosDbServiceHealthStatus
  spinner: boolean;
}

export function HealthStatusCosmosDb(props: IProps) {
  const [collapsed, setCollapsed] = useState(true);
  //---------------------------------------------------------------------
  const onExpand = () => {
    setCollapsed(false);
  }
  //---------------------------------------------------------------------
  const onCollapse = () => {
    setCollapsed(true);
  }
  //---------------------------------------------------------------------
  let databases: JSX.Element[] | undefined = undefined;
  if (props.status) {
    databases = [];
    props.status.databases.forEach(database => {
      databases?.push(<DatabaseStatus status={database} spinner={props.spinner} />);
    });

    // props.status.accountStatuses.forEach(accountStatus => {
    //   accountStatus.databaseStatuses.forEach(database => {
    //     databases?.push(<DatabaseStatus status={database} spinner={props.spinner} />);
    //   });
    // });
  }
  //---------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <WikiSpinner show={props.spinner} />
      <div className={styles.header}>
        {props.status ? props.status.serviceName : "Unknown Service"}
      </div>
      {!props.status &&
        <div className={styles.statusContainer}>
          Unknown Service status is undefined
        </div>
      }
      {props.status &&
        <React.Fragment>
          <div key="HealthGauge" className={styles.statusContainer}>
            <HealthGauge status={props.status} spinner={props.spinner} />
          </div>
          {!props.spinner &&
            <StatusToggler
              caption="Databases"
              count={databases ? databases.length : 0}
              collapsed={collapsed}
              children={databases}
              onCollapse={onCollapse}
              onExpand={onExpand} />
          }
        </React.Fragment>
      }
    </div>
  );
}