import { IFilterItemOption } from "../../../common/lists/filter-editor/IFilterItem";

export const validatorModerator = (
  updatedOptionId: string,
  newValue: boolean,
  optionsToValidate: IFilterItemOption[]
): IFilterItemOption[] => {
  // This func should return updated list of options
  if (newValue == true) {
    let updatedOptions: IFilterItemOption[];
    switch (updatedOptionId) {
      case "Assigned":
      case "Unassigned":
        // unselect all other options
        updatedOptions = optionsToValidate.map(o => o.id == updatedOptionId ? o : { ...o, isSelected: false });
        return updatedOptions;
      default:
        // if it's a particular moderator name, unselect assigned/unassigned options
        updatedOptions = optionsToValidate.map(o => o);
        const optionAssigned = optionsToValidate?.find(o => o.id == "Assigned");
        if (optionAssigned) optionAssigned.isSelected = false;
        const optionUnassigned = optionsToValidate?.find(o => o.id == "Unassigned");
        if (optionUnassigned) optionUnassigned.isSelected = false;
        return updatedOptions;
    };
  } else return optionsToValidate;
};