import { IListColumn } from "../../common/lists/IListColumn";

//------------------------------------------------------------------------------------
export enum EBusinessSortType {
  Source = 0,
  Tags = 1,
  Distance = 2,
}
//------------------------------------------------------------------------------------
export type TBusinessSortType = 'Source' | 'Relevance' | 'Distance';
//------------------------------------------------------------------------------------
export class SearchColumnIds {
  static locale = { id: "locale", name: "Locale"};
  static foundTagCount = { id: "foundTagCount", name: "Relevance"};
  static distance = { id: "minDistance", name: "Distance"};
}
//------------------------------------------------------------------------------------
export const initialSearchListColumns: IListColumn[] = [
  {
    id: SearchColumnIds.locale.id,
    name: SearchColumnIds.locale.name,
    filter: {
      type: "Options",
      options: []
    }
  },
  {
    id: SearchColumnIds.foundTagCount.id,
    name: SearchColumnIds.foundTagCount.name,
    sorter: {
      order: 1,
      sort: "Desc",
      isRequired: true,
      isDefault: true,
    }  
  }, 
  {
    id: SearchColumnIds.distance.id,
    name: SearchColumnIds.distance.name,
    sorter: {
      order: 2,
      sort: "Asc",
      isRequired: true
    }  
  }];