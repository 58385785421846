import styles from './BusinessList.module.css';
import React, { useContext, useEffect, useRef, useState } from "react";
import WikiSpinner from "../../../app-layout/spinner/wikiSpinner";
import { IUiBusinessList } from "./IUiBusinessList";
import { BusinessListItem } from "./business-list-card/IBusinessListItem";
import getBusinessList from "../../functions/getBusinessList";
import { useMsal } from "@azure/msal-react";
import { AppAuthContextStore } from "../../../../context/app-auth-context/AppAuthContext";
import { TUiCaption } from "../../../common/captions/TUiCaption";
import BusinessListCard from "./business-list-card/BusinessListCard";
import { AppUiContextStore } from "../../../../context/app-ui-context/AppUiContextProvider";
import { useAbortController } from "../../../../hooks/useAbortController";
import ConsoleContent from "../../../common/console-layout/console-content/ConsoleContent";
import GetHint from "../../../common/functions/GetHint";
import { IUiOption } from '../../../common/options/IUiOption';
import FormOptions from '../../../common/form-options-bar/FormOptions';
import { getTUiMenuContent } from '../../../common/menu/menu-content/TUiMenuContent';
import useNavigateWithContext from '../../../../hooks/useNavigateWithContext';
import { pathBusinessConsole, pathBusinesses, pathBusinessNew, pathBusinessWizard } from '../BusinessConsole';
import { generatePath } from 'react-router-dom';
import { BusinessContext } from '../../../../context/business-context/BusinessContextProvider';

interface IProps {
  ui: IUiBusinessList;
}

export default function BusinessList(props: IProps) {
  const { instance } = useMsal();
  const { appAuthContext } = useContext(AppAuthContextStore);
  const { appUiContext, appUiContextDispatch } = useContext(AppUiContextStore);
  const { businessContext, businessContextDispatch } = useContext(BusinessContext);
  const [businessListItems, setBusinessListItems] = useState<BusinessListItem[]>();
  const [isLoading, setIsLoading] = useState(false);
  const abortController = useAbortController("BusinessList");
  const navigate = useNavigateWithContext();
  const newBusinessRef = useRef(false);
  const pathBusinessEditor = `${pathBusinessConsole}/:role/${pathBusinesses}/:businessId`;
  //--------------------------------------------------------------------------- Set up breadcrumb and get data
  useEffect(() => {
    if (props.ui?.breadcrumb) {
      appUiContextDispatch({ type: "UpdateBreadcrumbsTrail", value: props.ui.breadcrumb });
    };
    //-------------------------------------------------------------------------
    setIsLoading(true);
    let controller = abortController.newController("getBusinessList");
    getBusinessList(
      instance,
      appAuthContext.config,
      controller.signal
    ).then(businessListItems => {
      //businessContextDispatch({ type: "ClearBusinessContext" });
      setBusinessListItems(businessListItems);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      !controller.aborted && setIsLoading(false);
    });
    return (() => {
      abortController.abortOnUnmount();
    });
  }, []);
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (newBusinessRef.current && businessContext.lastAction?.type == "ClearBusinessContext") {
      // Navigate to business editor
      newBusinessRef.current = false;
      const path = generatePath(
        pathBusinessEditor, {
        role: "owner",
        businessId: pathBusinessNew
      });
      navigate(path);
    }
  }, [businessContext.lastAction])
  //---------------------------------------------------------------------------
  const onNewBusinessCreate = () => {
    newBusinessRef.current = true;
    businessContextDispatch({ type: "ClearBusinessContext" });
  };
  //---------------------------------------------------------------------------
  const onBusinessSelect = (businessId: string) => {
    // Navigate to business editor
    const path = generatePath(
      pathBusinessEditor, {
      role: "owner",
      businessId: businessId
    });
    navigate(path);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "BusinessList.MenuContent.OptionCreateBusiness":
        onNewBusinessCreate();
        break;
      case "BusinessList.MenuContent.OptionCreateBusinessUsingWizard":
        appUiContextDispatch({ type: "SetPathToRemember", value: location.pathname})
        //navigate(`${pathBusinessConsole}/${pathBusinessWizard}`);
        navigate(`${pathBusinessConsole}/owner/${pathBusinessWizard}`);
        break;
    };
  };
  //---------------------------------------------------------------------------
  let formCaption: TUiCaption | undefined = undefined;
  if (props.ui) {
    formCaption = {
      id: props.ui.id,
      directoryId: props.ui.directoryId,
      elementType: props.ui.elementType,
      text: props.ui.hint
    };
  };
  //---------------------------------------------------------------------------
  const updatedOptions: IUiOption[] = [];
  props.ui.menuContent.options.forEach(option => {
    switch (option.id) {
      case "BusinessList.MenuContent.OptionCreateBusiness":
        updatedOptions.push({
          ...option,
          iconId: 'plus'
        });
        break;
      case "BusinessList.MenuContent.OptionCreateBusinessUsingWizard":
        updatedOptions.push({
          ...option,
          iconId: 'magicWand'
        });
        break;
    };
  });
  const formOptions =
    <FormOptions
      ui={getTUiMenuContent({
        ...props.ui.menuContent,
        options: updatedOptions
      })}
      onSelect={onOptionSelect}
    />;
  //---------------------------------------------------------------------------  
  const businessListCards = businessListItems?.map(item => (
    <BusinessListCard
      key={item.id}
      data={item}
      onSelect={onBusinessSelect}
    />
  ));
  //---------------------------------------------------------------------------
  return (
    <ConsoleContent
      prompt={GetHint(appUiContext, props.ui.id, props.ui.hint)}
      formOptions={formOptions}
      containerWidthPercent={70}
    >
      <WikiSpinner show={isLoading} />
      <div className={styles.list}>
        {businessListCards}
      </div>
    </ConsoleContent>
  );
}