import React, { useContext, useState } from 'react';
import { DirectoryEditorContext } from '../../../../../../context/directory-editor-context/DirectoryEditorContextProvider';
import DirectoryNodeWrapper from '../../../../common/directory-node-wrapper/DirectoryNodeWrapper';
import DirectoryNodeAttribute from '../../../../common/directory-node-attribute/DirectoryNodeAttribute';
import { BusinessTagsDictionaryDraftItem } from '../IBusinessTagsDictionaryDraftItem';
import { IUiOption } from '../../../../../common/options/IUiOption';
import BusinessTagsDictionaryItemEditForm from '../item-edit-form/BusinessTagsDictionaryItemEditForm';
import { IUiBusinessTagsDictionaryEditorNode } from './IUiBusinessTagsDictionaryEditorNode';
import DictionaryItemIcon from './DictionaryItemIcon';

export const getNodeLevel = (fullPath: string) => {
  return fullPath ? (fullPath.match(/\./g) || []).length : 0;
};

interface IProps {
  ui: IUiBusinessTagsDictionaryEditorNode;
  data: BusinessTagsDictionaryDraftItem;
  onUpdate: (updatedDraftItem: BusinessTagsDictionaryDraftItem, replaceId?: string) => void;
  onAdd: (
    updatedDraftItem: BusinessTagsDictionaryDraftItem,
    parentItem: BusinessTagsDictionaryDraftItem,
    shift: number
  ) => void;
  onOptionSelect?: (draftItemId: string, selectedOptionId: string) => void;
}

export default function BusinessTagsDictionaryEditorNode(props: IProps) {
  const { directoryEditorContext } = useContext(DirectoryEditorContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newSubItem, setNewSubItem] = useState<BusinessTagsDictionaryDraftItem>();
  const nodeLevel = getNodeLevel(props.data.fullPath);
  const subDictionary = directoryEditorContext.dictionaryOriginals?.find(d => d.dictionaryId === props.data.content?.dictionaryId);
  const filterConditionIsMet = directoryEditorContext.filterConditionIsMet(props.data);
  const isSubDictionary = !!props.data.subDictionaryId;
  const isReadonly = isSubDictionary || !directoryEditorContext.editMode;
  //--------------------------------------------------------------------------- Sub-items
  // Take sub-items from the draft itself
  let subItems = (directoryEditorContext?.editorState?.draft.items as BusinessTagsDictionaryDraftItem[]).filter(item =>
    item.path == props.data.fullPath && item.id !== props.data.id && directoryEditorContext.filterConditionIsMet(item));
  //---------------------------------------------------------------------------
  const enableEditMode = () => {
    setEditMode(true);
  };
  //---------------------------------------------------------------------------
  const onUpdate = (updatedDraftItem: BusinessTagsDictionaryDraftItem) => {
    setEditMode(false);
    const replaceId = updatedDraftItem.id !== props.data.id ? props.data.id : undefined;
    props.onUpdate(updatedDraftItem, replaceId);
  };
  //---------------------------------------------------------------------------
  const onSubItemAdd = () => {
    const newItem = BusinessTagsDictionaryDraftItem.getNew({
      id: "",
      path: props.data.id,
    });
    setNewSubItem(newItem);
  };
  //---------------------------------------------------------------------------
  const subItemAdd = (newSubItem: BusinessTagsDictionaryDraftItem) => {
    props.onAdd(newSubItem, props.data, subItems.length + 1);
    setNewSubItem(undefined);
  };
  //---------------------------------------------------------------------------
  const onOptionSelect = (optionId: string) => {
    switch (optionId) {
      case "OptionEdit":
        enableEditMode();
        break;
      case "OptionAdd":
        onSubItemAdd();
        break;
      default:
        props.onOptionSelect && props.onOptionSelect(props.data.id, optionId);
        break;
    };
  };
  //--------------------------------------------------------------------------- Node options
  let updatedMenuContent;
  if (directoryEditorContext.editMode) {
    const updatedOptions: IUiOption[] = [];
    props.ui.menuContent.options.forEach(option => {
      switch (option.id) {
        case "OptionEdit":
          updatedOptions.push({ ...option, iconId: "pencil" });
          break;
        case "OptionDelete":
          if (props.data.isNew) {
            updatedOptions.push({ ...option, iconId: "delete" });
          };
          break;
        case "OptionAdd":
          if (!props.data.content?.dictionaryId) {
            updatedOptions.push({ ...option, iconId: "plus" });
          };
          break;
      };
    });
    updatedMenuContent = { ...props.ui.menuContent, options: updatedOptions };
  } else {
    updatedMenuContent = { ...props.ui.menuContent, options: [] };
  };
  //--------------------------------------------------------------------------- Sub-items
  const subItemNodes = subItems?.map(subItem =>
    <BusinessTagsDictionaryEditorNode
      key={subItem.id}
      ui={props.ui}
      data={subItem as BusinessTagsDictionaryDraftItem}
      onUpdate={props.onUpdate}
      onAdd={props.onAdd}
      onOptionSelect={props.onOptionSelect}
    />
  );
  //---------------------------------------------------------------------------
  if (props.data && (filterConditionIsMet || subItems.length > 0)) {
    if (editMode) return (
      <BusinessTagsDictionaryItemEditForm
        ui={props.ui.editForm}
        data={props.data}
        isReadonly={isSubDictionary}
        onUpdate={onUpdate}
        onClose={() => setEditMode(false)}
      />
    ); else return (
      <React.Fragment>
        <DirectoryNodeWrapper
          ui={{ ...props.ui, menuContent: updatedMenuContent }}
          data={props.data}
          customStyles={{ color: isSubDictionary ? 'var(--clr_font_system)' : undefined }}
          onOptionSelect={isReadonly ? undefined : onOptionSelect}
        >

          <DirectoryNodeAttribute
            isFirstColumn={true}
            level={nodeLevel}
            isExpandable={subItems.length > 0}
            isExpanded={isExpanded}
            onClick={enableEditMode}
            onToggle={() => setIsExpanded(!isExpanded)}
          >
            {props.data.id}
          </DirectoryNodeAttribute>

          <DirectoryNodeAttribute
            onClick={enableEditMode}
          >
            {props.data.name}
          </DirectoryNodeAttribute>

          <DirectoryNodeAttribute>
            {subDictionary?.name}
          </DirectoryNodeAttribute>

          <DirectoryNodeAttribute>
            {props.data.content &&
              <div style={{ height: '100%', fontSize: '1.2em', display: 'flex', gap: '.2em' }}>
                <DictionaryItemIcon
                  type='required'
                  enabled={!!props.data.content?.isRequired}
                  hint={props.data.content?.isRequired ? "Required" : "Optional"}
                />
                <DictionaryItemIcon
                  type='exclusive'
                  enabled={!!props.data.content?.isMutuallyExclusive}
                  hint={props.data.content?.isMutuallyExclusive ? "Only one item can be selected" : "More than one item can be selected"}
                />
                <DictionaryItemIcon
                  type='manual'
                  enabled={!!props.data.content?.isSortedManually}
                  hint={props.data.content?.isSortedManually ? "Sorted manually" : "Sorted automatically"}
                />
              </div>}
          </DirectoryNodeAttribute>

          <DirectoryNodeAttribute>
            <div style={{ height: '100%', fontSize: '1.2em', display: 'flex', gap: '.2em' }}>
              <DictionaryItemIcon
                type='editors'
                enabled={!!props.data.status?.editors}
                hint={props.data.status?.editors ? "Available for system translators" : "Not available for system translators"}
              />
              <DictionaryItemIcon
                type='viewers'
                enabled={!!props.data.status?.viewers}
                hint={props.data.status?.viewers ? "Available for end users" : "Not available for end users"}
              />
            </div>
          </DirectoryNodeAttribute>

        </DirectoryNodeWrapper>

        {isExpanded && subItemNodes}

        {newSubItem &&
          <BusinessTagsDictionaryItemEditForm
            ui={props.ui.editForm}
            data={newSubItem}
            isNew={true}
            onUpdate={subItemAdd}
            onClose={() => setNewSubItem(undefined)}
          />}

      </React.Fragment>
    );
  } else return null;
}