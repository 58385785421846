import { useState } from "react";
import { UsageBusiness } from "../../../../common/image-editor/TImageUsage";
import styles from "./ImageUsageBusiness.module.css";
import Toggler from "../../../../common/toggler/Toggler";
import { ImageUsage } from "./ImageUsage";
import { TogglerWithCaption } from "../../../../common/toggler/TogglerWithCaption";
//--------------------------------------------------------------------------------
interface IProps {
  usageBusiness: UsageBusiness;
}
//--------------------------------------------------------------------------------
export function ImageUsageBusiness(props: IProps) {
  const [collapsed, setCollapsed] = useState(true);
  //------------------------------------------------------------------------
  const onClick = () => {
    setCollapsed(!collapsed);
  }
  //------------------------------------------------------------------------
  let items = props.usageBusiness.usages?.map(usage =>
    <ImageUsage key={usage.divId} usage={usage} />
  );
  //------------------------------------------------------------------------
  let count = items?.length ?? 0;
  return (
    <div>
      <TogglerWithCaption
        caption={`Business "${props.usageBusiness.businessId}" (${count})`}
        showToggler={count > 0}
        collapsed={collapsed}
        onClick={onClick}
        togglerPosition={"Right"}
      />
      <div className={styles.childrenContainer}>
        {!collapsed && items}
      </div>
    </div>
  );
}