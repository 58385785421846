import { TUiMenuContent } from '../../menu/menu-content/TUiMenuContent';
import styles from './PopupMessage.module.css';
import GetCaption from '../../functions/GetCaption';
import PopupHeader from '../popup-header/PopupHeader';
import Icon from '../../icon/Icon';
import { AppUiContextStore } from '../../../../context/app-ui-context/AppUiContextProvider';
import { useContext } from 'react';
import PopupTest from '../popup/PopupTest';

export type EPopupType = "Confirmation" | "Error";

interface IProps {
  id: string;
  type: EPopupType;
  header: string;
  message: string;
  optionsMenuContent: TUiMenuContent;
  show: boolean;
  onOptionSelect: (selectedOptionId: string) => void;
  onCancel: () => void;
}

export default function PopupMessage(props: IProps) {
  const { appUiContext } = useContext(AppUiContextStore);
  const messageColor = props.type == "Confirmation" ? 'var(--clr_accent_attention)' : 'var(--clr_accent_wrong)';
  //---------------------------------------------------------------------------
  const optionElements = props.optionsMenuContent.options.map(option => (
    <div
      key={option.id}
      className={styles.option}
      onClick={() => props.onOptionSelect(option.id)}
    >
      {GetCaption(appUiContext, option.id, option.caption)}
    </div>
  ));
  //---------------------------------------------------------------------------
  if (props.show)
    return (
      <PopupTest
        id={props.id}
        isModal={true}
        closeOnClickOutside={false}
        animation="SlideFromRight"
        shadowColor={messageColor}
        ignoreFullWindowOnMobile={true}
        fullWindow={false}
        onClose={() => { }}
      //onEnter={props.onEnter}
      //onAnimationEnd={props.onAnimationEnd}
      >

        <PopupHeader
          header={props.header}
          onClose={props.onCancel}
        />

        <div className={styles.container}>

          <div
            className={styles.icon}
            style={{ color: messageColor }}
          >
            <Icon imageName='attention' />
          </div>

          <div className={styles.message}>
            {props.message}
          </div>

        </div>

        <div className={styles.options}>
          {optionElements}
        </div>

      </PopupTest>
    );
  else return null;
}